import classNames from 'classnames'
import constate from 'constate'
import { isEqual } from 'lodash-es'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useAsync } from 'react-use'
import {
    Checkbox,
    DraggablePopupV2,
    MonoIconCommonQuestion16,
    MonoIconCommonSuccess16,
    MonoIconCommonTime16,
    Scrollbar,
    TeamIconForSideBar,
    Tooltip,
    WKAlert,
    WKButton,
    WKCollapse,
    WKCollapseItem,
    WKToast,
} from '../../../../../../ui-lib/src'
import { WKLoadingClockwise } from '../../../../../../ui-lib/src/components/wk-loading/wk-loading-clockwise'
import { isEnglishLanguage, useIsEllipsisActive } from '../../../../../../util/src'
import { WKFrog } from '../../../../kernel/frog'
import { FolderWithAuthorityVO, TeamWithFolderAndAuthorityVO } from '../../../../kernel/interface/type'
import { GetTeamsFoldersRequest } from '../../../../kernel/request/team'
import { useAppContext } from '../../../../main/app-context'
import { useDocInfoContext } from '../../../context/top-area-context'
import style from './ai-duplicate-reference-select-modal.module.less'
import {
    AiDuplicateConfigStatusType,
    GetAiDuplicateConfigStatusRequest,
    GetUserDuplicateConfigRequest,
    ModifyUserDuplicateConfigRequest,
    UserDuplicateTeamConfigVO,
    UserDuplicateTeamScope,
} from './ai-duplicate-reference-select-modal.request'
import { translation } from './ai-duplicate-reference-select-modal.translation'
import guidePic from './assets/ai_duplicate_guide.png'
import guidePicEn from './assets/ai_duplicate_guide_en.png'

enum Step {
    StepGuide,
    StepTeamSelection,
}

function Guide() {
    const appContext = useAppContext()

    useEffect(() => {
        WKFrog.addFrogRecord({
            url: '/expose/AiDuplicate/changeRangeAnnotateExpose',
            eventId: 26416,
            eventName: 'changeRangeAnnotateExpose',
            eventAction: 'expose',
            customExtend: {
                expose_way: appContext.aiService.openBySwitchToAiDuplicate ? 0 : 1,
            },
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div className={style.Guide}>
            <div className={style.textContainer}>
                <div>{translation('Description1')}</div>
                <div className={style.p2}>{translation('Description2')}</div>
            </div>
            {isEnglishLanguage() ? (
                <div className={style['picContainer-en']}>
                    <img src={guidePicEn} className={style.pic} />
                </div>
            ) : (
                <div className={style.picContainer}>
                    <img src={guidePic} className={style.pic} />
                </div>
            )}
        </div>
    )
}

function useTeamSelection() {
    const docContext = useDocInfoContext()
    const teamFoldersResponse = useAsync(async () => {
        if (docContext.docData) {
            return await new GetTeamsFoldersRequest(docContext.docData.orgId ?? '-1').start()
        }
        return undefined
    }, [docContext.docData])
    const currentSelectedTeamFoldersInfo = useAsync(async () => {
        if (docContext.docData) {
            const ret = await new GetUserDuplicateConfigRequest(docContext.docData.orgId ?? '-1').start()
            if (ret.teamConfigList) {
                return ret.teamConfigList.sort((a, b) => {
                    return a.teamId > b.teamId ? 1 : -1
                })
            } else {
                return []
            }
        }
        return undefined
    }, [docContext.docData])
    const configStatus = useAsync(async () => {
        if (docContext.docData) {
            return new GetAiDuplicateConfigStatusRequest(docContext.docData.orgId ?? '-1').start()
        }
        return undefined
    }, [docContext.docData])
    const shouldUseStatusAlert = useMemo(() => {
        return (
            configStatus.value &&
            currentSelectedTeamFoldersInfo.value &&
            currentSelectedTeamFoldersInfo.value.length > 0
        )
    }, [configStatus.value, currentSelectedTeamFoldersInfo.value])

    const shouldShowModal = useMemo(() => {
        return currentSelectedTeamFoldersInfo.value !== undefined
    }, [currentSelectedTeamFoldersInfo.value])

    const initialStep = useMemo(() => {
        if (currentSelectedTeamFoldersInfo.value) {
            if (currentSelectedTeamFoldersInfo.value.length) {
                return Step.StepTeamSelection
            } else {
                return Step.StepGuide
            }
        }
        return undefined
    }, [currentSelectedTeamFoldersInfo.value])
    const [selectedTeams, setSelectedTeams] = useState<Set<TeamWithFolderAndAuthorityVO>>(new Set())
    const [selectedFolders, setSelectedFolders] = useState<Set<FolderWithAuthorityVO>>(new Set())

    useEffect(() => {
        if (currentSelectedTeamFoldersInfo.value && teamFoldersResponse.value) {
            const teamIds = new Set<string>()
            const folderIds = new Set<string>()
            currentSelectedTeamFoldersInfo.value.forEach((teamConfig) => {
                if (teamConfig.scope === UserDuplicateTeamScope.All) {
                    teamIds.add(teamConfig.teamId)
                } else {
                    teamConfig.specificFolderIds.forEach((id) => folderIds.add(id))
                }
            })

            const teams = new Set<TeamWithFolderAndAuthorityVO>()
            const folders = new Set<FolderWithAuthorityVO>()
            teamFoldersResponse.value.forEach((team) => {
                if (teamIds.has(team.teamWithAuthorityVO.id)) {
                    teams.add(team)
                    team.folderWithAuthorityVOList.forEach((folder) => {
                        folders.add(folder)
                    })
                } else {
                    team.folderWithAuthorityVOList.forEach((folder) => {
                        if (folderIds.has(folder.id)) {
                            folders.add(folder)
                        }
                    })
                }
            })

            setSelectedTeams(teams)
            setSelectedFolders(folders)
        }
    }, [currentSelectedTeamFoldersInfo.value, teamFoldersResponse.value])

    const modifySelectedTeam = (modifier: (teams: Set<TeamWithFolderAndAuthorityVO>) => void) => {
        const currentSelectedTeams = new Set(selectedTeams)
        modifier(currentSelectedTeams)
        setSelectedTeams(currentSelectedTeams)
    }

    const modifySelectedFolder = (modifier: (folders: Set<FolderWithAuthorityVO>) => void) => {
        const currentSelectedFolders = new Set(selectedFolders)
        modifier(currentSelectedFolders)
        setSelectedFolders(currentSelectedFolders)
    }

    const newSelectedTeamFolders = useMemo(() => {
        const teamConfigList: UserDuplicateTeamConfigVO[] = []
        if (teamFoldersResponse.value) {
            teamFoldersResponse.value!.forEach((teamFolders) => {
                if (selectedTeams.has(teamFolders)) {
                    teamConfigList.push({
                        teamId: teamFolders.teamWithAuthorityVO.id,
                        specificFolderIds: [],
                        scope: UserDuplicateTeamScope.All,
                    })
                } else {
                    const specificFolderIds = teamFolders.folderWithAuthorityVOList
                        .filter((folder) => selectedFolders.has(folder))
                        .map((folder) => folder.id)
                    if (specificFolderIds.length) {
                        teamConfigList.push({
                            teamId: teamFolders.teamWithAuthorityVO.id,
                            specificFolderIds,
                            scope: UserDuplicateTeamScope.Part,
                        })
                    }
                }
            })
        }
        teamConfigList.sort((a, b) => {
            return a.teamId > b.teamId ? 1 : -1
        })
        return teamConfigList
    }, [selectedFolders, selectedTeams, teamFoldersResponse.value])

    const selectedTeamFoldersChanged = useMemo(() => {
        if (currentSelectedTeamFoldersInfo.value === undefined) {
            return false
        }
        return !isEqual(newSelectedTeamFolders, currentSelectedTeamFoldersInfo.value)
    }, [currentSelectedTeamFoldersInfo.value, newSelectedTeamFolders])

    const updatedConfig = async () => {
        const teamConfigList: UserDuplicateTeamConfigVO[] = []
        teamFoldersResponse.value!.forEach((teamFolders) => {
            if (selectedTeams.has(teamFolders)) {
                teamConfigList.push({
                    teamId: teamFolders.teamWithAuthorityVO.id,
                    specificFolderIds: [],
                    scope: UserDuplicateTeamScope.All,
                })
            } else {
                const specificFolderIds = teamFolders.folderWithAuthorityVOList
                    .filter((folder) => selectedFolders.has(folder))
                    .map((folder) => folder.id)
                if (specificFolderIds.length) {
                    teamConfigList.push({
                        teamId: teamFolders.teamWithAuthorityVO.id,
                        specificFolderIds,
                        scope: UserDuplicateTeamScope.Part,
                    })
                }
            }
        })

        const ret = await new ModifyUserDuplicateConfigRequest(docContext.docData!.orgId, {
            orgId: docContext.docData!.orgId,
            teamConfigList,
        }).start()

        if (ret.effectedFileCount >= 30) {
            const hours = Math.round((ret.effectedFileCount * 2) / 60)
            WKToast.show(
                translation('UpdatedWithHours', {
                    fileCount: ret.effectedFileCount.toString(),
                    hours: hours.toString(),
                    hour: hours === 1 ? 'hour' : 'hours',
                })
            )
        } else if (ret.effectedFileCount > 0) {
            WKToast.show(
                translation('UpdatedWithMinutes', {
                    fileCount: ret.effectedFileCount.toString(),
                    file: ret.effectedFileCount === 1 ? 'file' : 'files',
                    minutes: (ret.effectedFileCount * 2).toString(),
                })
            )
        } else {
            WKToast.show(translation('Updated'))
        }
    }

    const isTeamEmpty = useMemo(() => {
        if (teamFoldersResponse.value) {
            return teamFoldersResponse.value.length === 0
        } else {
            return false
        }
    }, [teamFoldersResponse.value])

    return {
        selectedTeams,
        selectedFolders,
        setSelectedTeams,
        setSelectedFolders,
        modifySelectedTeam,
        modifySelectedFolder,
        teamFoldersResponse,
        currentSelectedTeamFoldersInfo,
        shouldShowModal,
        initialStep,
        updatedConfig,
        isTeamEmpty,
        configStatus,
        shouldUseStatusAlert,
        selectedTeamFoldersChanged,
    }
}

const [TeamSelectionProvider, useTeamSelectionContext] = constate(useTeamSelection)

function FolderItem({ folder, team }: { folder: FolderWithAuthorityVO; team: TeamWithFolderAndAuthorityVO }) {
    const context = useTeamSelectionContext()
    const itemRef = useRef<HTMLDivElement>(null)
    const isEllipsis = useIsEllipsisActive(itemRef.current)
    const checked = context.selectedFolders.has(folder)

    const onToggle = () => {
        const newChecked = !checked
        context.modifySelectedFolder((folders) => {
            if (newChecked) {
                folders.add(folder)
            } else {
                folders.delete(folder)
            }
            if (team.folderWithAuthorityVOList.every((f) => folders.has(f))) {
                context.modifySelectedTeam((teams) => {
                    teams.add(team)
                })
            } else {
                context.modifySelectedTeam((teams) => {
                    teams.delete(team)
                })
            }
        })
    }

    return (
        <div className={style.Folder} onClick={onToggle} data-testid={`ai-duplicate-folder-${folder.id}`}>
            <Checkbox checked={checked} />
            <Tooltip title={folder.name} inactivation={!isEllipsis}>
                <div className={classNames('truncate', style.Text)} ref={itemRef}>
                    {folder.name}
                </div>
            </Tooltip>
        </div>
    )
}

function TeamItem({ team }: { team: TeamWithFolderAndAuthorityVO }) {
    const context = useTeamSelectionContext()

    const onCheckChange = (checked: boolean) => {
        if (checked) {
            context.modifySelectedFolder((folders) => {
                team.folderWithAuthorityVOList.forEach((f) => {
                    folders.add(f)
                })
            })
            context.modifySelectedTeam((teams) => {
                teams.add(team)
            })
        } else {
            context.modifySelectedFolder((folders) => {
                team.folderWithAuthorityVOList.forEach((f) => {
                    folders.delete(f)
                })
            })
            context.modifySelectedTeam((teams) => {
                teams.delete(team)
            })
        }
    }

    const isAllSelected = useMemo(() => {
        return context.selectedTeams.has(team)
    }, [context.selectedTeams, team])

    const isIndeterminate = useMemo(() => {
        if (isAllSelected) {
            return false
        }
        return team.folderWithAuthorityVOList.some((f) => context.selectedFolders.has(f))
    }, [context.selectedFolders, isAllSelected, team.folderWithAuthorityVOList])

    return (
        <WKCollapseItem
            key={team.teamWithAuthorityVO.id}
            value={team.teamWithAuthorityVO.id}
            title={team.teamWithAuthorityVO.name}
            className="mb-1"
            tooltipProps={{
                title: team.teamWithAuthorityVO.name,
            }}
            dataTestId={`ai-duplicate-team-${team.teamWithAuthorityVO.id}`}
            startSlot={
                <>
                    <Checkbox
                        onChange={onCheckChange}
                        checked={isAllSelected}
                        indeterminate={isIndeterminate}
                        stopPropagation={true}
                        testid={`ai-duplicate-team-${team.teamWithAuthorityVO.id}-check`}
                        className="mx-2"
                    />
                    <TeamIconForSideBar className="mr-2" team={team.teamWithAuthorityVO} fixedSize={16} />
                </>
            }
        >
            {team.folderWithAuthorityVOList.length > 0 ? (
                team.folderWithAuthorityVOList.map((folder) => {
                    return <FolderItem key={folder.id} folder={folder} team={team} />
                })
            ) : (
                <div className={style.NoProject}>{translation('NoProjects')}</div>
            )}
        </WKCollapseItem>
    )
}

function TeamSelection() {
    const context = useTeamSelectionContext()
    useEffect(() => {
        WKFrog.addFrogRecord({
            url: '/expose/AiDuplicate/changeRangeExpose',
            eventId: 26417,
            eventName: 'changeRangeExpose',
            eventAction: 'expose',
        })
    }, [])

    const getAlertContent = () => {
        const status = context.configStatus.value!
        if (status.status === AiDuplicateConfigStatusType.Latest) {
            return (
                <WKAlert.WithoutTitle
                    rounded
                    className="mr-6 mb-4"
                    color="success"
                    iconWrapClassName={'self-start'}
                    icon={<MonoIconCommonSuccess16 className="color-$wk-green-7" />}
                    suffixIconSelfStart
                    suffix={
                        <Tooltip
                            title={translation('AlertLatestTooltip')}
                            maxWidth={360}
                            canMouseDownClose={false}
                            firstDelay={100}
                        >
                            <MonoIconCommonQuestion16 className="color-$wk-l2-label-color-gray-8 ml-2 mr-1.5" />
                        </Tooltip>
                    }
                >
                    {translation('AlertLatest')}
                </WKAlert.WithoutTitle>
            )
        } else if (status.status === AiDuplicateConfigStatusType.DocContentChange) {
            return (
                <WKAlert.WithoutTitle
                    rounded
                    className="mr-6 mb-4"
                    iconWrapClassName={'self-start'}
                    icon={<MonoIconCommonTime16 className="color-$wk-brand-7" />}
                    suffixIconSelfStart
                    suffix={
                        <Tooltip
                            title={translation('AlertModifiedTooltip')}
                            maxWidth={360}
                            canMouseDownClose={false}
                            firstDelay={100}
                        >
                            <MonoIconCommonQuestion16 className="color-$wk-l2-label-color-gray-8 ml-2 mr-1.5" />
                        </Tooltip>
                    }
                >
                    {translation('AlertModified', {
                        fileCount: status.count.toString(),
                        file: status.count === 1 ? 'file' : 'files',
                    })}
                </WKAlert.WithoutTitle>
            )
        } else if (status.status === AiDuplicateConfigStatusType.EmptyIndex) {
            return (
                <WKAlert.WithoutTitle
                    rounded
                    className="mr-6 mb-4"
                    iconWrapClassName={'self-start'}
                    icon={<WKLoadingClockwise />}
                    suffixIconSelfStart
                    suffix={
                        <Tooltip
                            title={translation('AlertNewlyAddedTooltip')}
                            maxWidth={360}
                            canMouseDownClose={false}
                            firstDelay={100}
                        >
                            <MonoIconCommonQuestion16 className="color-$wk-l2-label-color-gray-8 ml-2 mr-1.5" />
                        </Tooltip>
                    }
                >
                    {translation('AlertNewlyAdded2', {
                        fileCount: status.count.toString(),
                        file: status.count === 1 ? 'file' : 'files',
                    })}
                </WKAlert.WithoutTitle>
            )
        }
    }

    return (
        <div className={style.TeamSelection}>
            {context.isTeamEmpty ? (
                <div className={style.NoItems}>{translation('NoItems')}</div>
            ) : (
                <Scrollbar>
                    <div className={style.PlaceHolder}></div>
                    {context.shouldUseStatusAlert && getAlertContent()}
                    <WKCollapse selectionMode="multiple">
                        {context.teamFoldersResponse.value?.map((team) => {
                            return <TeamItem team={team} key={team.teamWithAuthorityVO.id} />
                        })}
                    </WKCollapse>
                </Scrollbar>
            )}
        </div>
    )
}

function Footer(props: {
    onClickCancel: () => void
    onClickOK: () => void
    okText: string
    shouldShowTip: boolean
    disableOk: boolean
}) {
    return (
        <div className={style.Footer}>
            {props.shouldShowTip && (
                <div className={style.tip}>
                    <div className={style.text}>{translation('UpdateTimeTip')}</div>
                    <Tooltip title={translation('TipDetail')} maxWidth={360} canMouseDownClose={false} firstDelay={100}>
                        <MonoIconCommonQuestion16 style={{ color: 'var(--wk-l2-label-color-gray-8)' }} />
                    </Tooltip>
                </div>
            )}
            <div className={style.ButtonArea}>
                <WKButton type="secondary" onClick={props.onClickCancel}>
                    {translation('Cancel')}
                </WKButton>
                <WKButton type="primary" onClick={props.onClickOK} disabled={props.disableOk}>
                    {props.okText}
                </WKButton>
            </div>
        </div>
    )
}

function AiDuplicateReferenceSelectModalImpl() {
    const appContext = useAppContext()
    const context = useTeamSelectionContext()
    const [currentStep, setCurrentStep] = useState<Step | undefined>(undefined)

    const onClickOK = () => {
        if (currentStep === Step.StepGuide) {
            setCurrentStep(Step.StepTeamSelection)
        } else {
            WKFrog.addFrogRecord({
                url: '/click/AiDuplicate/changeRangeDoneButton',
                eventId: 26418,
                eventName: 'changeRangeDoneButton',
                eventAction: 'click',
            })
            context.updatedConfig()
            appContext.aiService.setShowAiDuplicateReferencesModal(false)
        }
    }

    useEffect(() => {
        setCurrentStep(context.initialStep)
    }, [context.initialStep])

    const closeModal = () => {
        appContext.aiService.setShowAiDuplicateReferencesModal(false)
    }
    if (!context.shouldShowModal) {
        return null
    }

    return (
        <DraggablePopupV2
            visible
            width={464}
            height={600}
            header={translation('AiDuplicateReferenceSelect')}
            footer={
                <Footer
                    onClickCancel={() => {
                        WKFrog.addFrogRecord({
                            url: '/click/AiDuplicate/changeRangeCancelButton',
                            eventId: 26419,
                            eventName: 'changeRangeCancelButton',
                            eventAction: 'click',
                        })
                        closeModal()
                    }}
                    okText={currentStep === Step.StepTeamSelection ? translation('Save') : translation('Next')}
                    shouldShowTip={currentStep === Step.StepTeamSelection}
                    disableOk={currentStep === Step.StepTeamSelection && !context.selectedTeamFoldersChanged}
                    onClickOK={onClickOK}
                />
            }
            onCancel={() => {
                WKFrog.addFrogRecord({
                    url: '/click/AiDuplicate/changeRangeCloseButton',
                    eventId: 26420,
                    eventName: 'changeRangeCloseButton',
                    eventAction: 'click',
                })
                closeModal()
            }}
            bodyClassName={currentStep == Step.StepGuide ? undefined : style.Body}
        >
            {currentStep == Step.StepGuide && <Guide />}
            {currentStep == Step.StepTeamSelection && <TeamSelection />}
        </DraggablePopupV2>
    )
}

export function AiDuplicateReferenceSelectModal() {
    const appContext = useAppContext()
    const canOpenModal = appContext.aiService.useZustandStore.use.showAiDuplicateReferencesModal()
    if (!canOpenModal) {
        return null
    }

    return (
        <TeamSelectionProvider>
            <AiDuplicateReferenceSelectModalImpl />
        </TeamSelectionProvider>
    )
}
