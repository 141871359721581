import { useEffect, useState } from 'react'

export const ChatMessageLoading = () => {
    const [activeIndex, setActiveIndex] = useState<number>(0)

    useEffect(() => {
        const timer = setInterval(() => {
            setActiveIndex((prev) => (prev === 2 ? 0 : prev + 1))
        }, 250)

        return () => clearInterval(timer)
    }, [])

    return (
        <div className="flex flex-row items-center justify-start p-8px">
            <div className="px-8px py-4px flex flex-row gap-4px">
                {[0, 1, 2].map((index) => (
                    <div
                        key={index}
                        className="w-6px h-6px rounded-full bg-$wk-v2-gray-13"
                        style={{
                            opacity: index === activeIndex ? 1 : index === (activeIndex + 1) % 3 ? 0.6 : 0.3,
                        }}
                    />
                ))}
            </div>
        </div>
    )
}
