import classnames from 'classnames'
import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import { MonoIconControlArrowDown12 } from '../../../icons-v2/mono/12'
import { ExplicitUndefined } from '../../../utils/explicit-undefined'
import { toMixed } from '../../../utils/mixed-value'
import { TriggerContainer, TriggerContainerProps } from './base-components/trigger-container'
import { TriggerFocus, TriggerFocusProps, TriggerFocusRef } from './base-components/trigger-focus'
import classes from './trigger-minimal.module.less'
import { TriggerBaseProps, TriggerBaseRef } from './type'
import { useCommonTrigger } from './use-common-trigger'

export interface TriggerMinimalProps extends TriggerBaseProps {
    // TriggerContainerProps
    classNameWhenOpen?: TriggerContainerProps['classNameWhenOpen']
    isOpenPick: TriggerContainerProps['isOpenPick']
    // 私有配置
    label?: React.ReactNode
    weight?: 'regular' | 'medium' | 'semibold'
    size?: 'small' | 'large'
    isMixed?: boolean
    lineClamp?: 1 | 2 | 3 // 1的效果和undefined一致
    onlyArrow?: boolean
    classNameLabel?: string
    classNameArrow?: string
}
export interface TriggerMinimalRef extends TriggerBaseRef {}

function _TriggerMinimal(props: TriggerMinimalProps, ref?: React.Ref<TriggerMinimalRef>) {
    const {
        className,
        disabled,
        dataTestIds,
        openAction,
        closeAction,
        classNameWhenOpen,
        isOpenPick,
        label,
        weight = 'regular',
        size = 'small',
        isMixed,
        lineClamp,
        onlyArrow,
        classNameLabel,
        classNameArrow,
        ...otherProps
    } = props
    const triggerRef = useRef<TriggerFocusRef>(null)
    const containerRef = useRef<HTMLDivElement>(null)
    const commonTrigger = useCommonTrigger({ isOpenPick, openAction, closeAction })

    useImperativeHandle(
        ref,
        () => ({
            getTriggerElement: () => triggerRef.current?.getContainer(),
            triggerRect: () => triggerRef.current?.getContainer().getBoundingClientRect()!,
            focus: () => triggerRef.current?.getFocusElement().focus(),
            getContainer: () => containerRef.current!,
        }),
        []
    )
    return (
        <TriggerContainer<ExplicitUndefined<TriggerContainerProps>>
            ref={containerRef}
            className={classnames(classes.template, [className], {
                [classes.disabled]: disabled,
                [classes.regular]: weight === 'regular',
                [classes.medium]: weight === 'medium',
                [classes.semibold]: weight === 'semibold',
                [classes.small]: size === 'small',
                [classes.large]: size === 'large',
                [classes.lineClamp2]: lineClamp === 2,
                [classes.lineClamp3]: lineClamp === 3,
                [classes.onlyArrow]: onlyArrow,
            })}
            isOpenPick={isOpenPick}
            classNameWhenOpen={classnames(classNameWhenOpen, classes.isOpenPick)}
            disabled={disabled}
            dataTestIds={dataTestIds}
            {...otherProps}
        >
            <TriggerFocus<ExplicitUndefined<TriggerFocusProps>>
                ref={triggerRef}
                className={classes.trigger}
                dataTestIds={dataTestIds}
                disabled={disabled}
                onClick={commonTrigger.click}
                onMouseDown={commonTrigger.mousedown}
                onEnter={openAction}
                onBlankSpace={openAction}
                outlineMode={'outset'}
            >
                <div className={classnames(classes.label, classNameLabel)}>{isMixed ? toMixed() : label}</div>
                <span className={classnames(classes.arrow, classNameArrow)}>
                    <MonoIconControlArrowDown12 />
                </span>
            </TriggerFocus>
            {props.children}
        </TriggerContainer>
    )
}

export const TriggerMinimal = forwardRef(_TriggerMinimal)
