import {
    MonoIconCanvasLightning16,
    MonoIconCommonaddPicture16,
    MonoIconCommonrandom16,
    MonoIconPanelReset16,
    Tooltip,
    WKButton,
    WKIconButton,
} from '../../../../../../ui-lib/src'
import { CircleProgressDot } from './ai-gen-progress-dot'
import { ConfigDesignSystemAndPlatform } from './ai-gen-ui-config-design-system'
import style from './ai-gen-ui-footer.module.less'
import { translation } from './ai-gen-ui-footer.translation'
import { useAIGenUIInContext } from './use-ai-gen-ui'

function RandomExampleButton({ onClick }: { onClick: () => void }) {
    return (
        <Tooltip title={translation('randomPrompt')}>
            <WKIconButton
                data-testid="apply-example"
                type="secondary"
                className="w-6 h-6 p-1 shrink-0"
                icon={<MonoIconCommonrandom16 className="text-[var(--wk-gray-11)]" />}
                onClick={onClick}
            />
        </Tooltip>
    )
}

function UploadImageButton({ onClick, disabled }: { onClick: () => void; disabled: boolean }) {
    return (
        <Tooltip title={translation('UploadImage')}>
            <WKIconButton
                data-testid="upload image"
                type="secondary"
                className="w-6 h-6 p-1 shrink-0 mr-1"
                icon={
                    <MonoIconCommonaddPicture16
                        className={disabled ? '!color-[var(--wk-gray-6)]' : 'color-[var(--wk-gray-11)]'}
                    />
                }
                onClick={onClick}
            />
        </Tooltip>
    )
}

function LoadingButtonContent({
    finishedTaskCount,
    taskQuantity,
}: {
    finishedTaskCount: number
    taskQuantity: number
}) {
    return (
        <>
            <div className="w-2.5 h-2.5 rounded-4 border border-white bg-transparent p-0.25 flex justify-center items-center">
                <CircleProgressDot size={10} progress={finishedTaskCount === taskQuantity ? 100 : undefined} />
            </div>
            <span>
                {translation('InGen')}
                {`(${Math.min(finishedTaskCount + 1, taskQuantity)}/${taskQuantity})`}
            </span>
        </>
    )
}

function RetryButtonContent() {
    return (
        <>
            <MonoIconPanelReset16 />
            <span>{translation('Retry')}</span>
        </>
    )
}

function DefaultButtonContent() {
    return (
        <>
            <MonoIconCanvasLightning16 className="color-white" />
            <span>{translation('Gen')}</span>
        </>
    )
}

function GenBtnContent() {
    const { shouldRetry, waitForResult, taskQuantity, finishedTaskCount } = useAIGenUIInContext()

    if (waitForResult) {
        return <LoadingButtonContent finishedTaskCount={finishedTaskCount} taskQuantity={taskQuantity} />
    }

    if (shouldRetry) {
        return <RetryButtonContent />
    }

    return <DefaultButtonContent />
}

export function AIGenUIByCodeFooter() {
    const {
        disableGen,
        callAIGen,
        waitForResult,
        applyExample,
        triggerUploadImage,
        disableUploadImage,
        inputPrompt,
        imageUrl,
        styleOption,
    } = useAIGenUIInContext()

    const buttonClassName = waitForResult ? style['ai-gen-ui-btn-gen-loading'] : style['ai-gen-ui-btn-gen-v2']

    return (
        <div className={style['ai-gen-ui-btn-container']}>
            <RandomExampleButton onClick={applyExample} />
            <UploadImageButton onClick={triggerUploadImage} disabled={disableUploadImage} />
            <div className="w-full pr-2 flex-1 overflow-hidden">
                <ConfigDesignSystemAndPlatform />
            </div>
            <WKButton
                className={buttonClassName}
                dataTestId="ai-gen-ui-btn"
                type="primary"
                onClick={() => callAIGen(inputPrompt, imageUrl, styleOption)}
                disabled={disableGen}
            >
                <GenBtnContent />
            </WKButton>
        </div>
    )
}
