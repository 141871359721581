import { memo, useCallback, useEffect, useRef, useState } from 'react'
import { MonoIconCommonError16, MonoIconCommonLoading16, WKTypography } from '../../../../../../ui-lib/src'
import { imgFileUrl } from '../../ai/ai-gen-ui/ai-gen-file-info'
import { NodePreview } from '../component/node-preview'
import { useChatbotAIInputInContext } from './use-chat-input'

const MutiLayerNameWithoutImage = () => {
    const { selectedNodes } = useChatbotAIInputInContext()
    const getNodeNamesByLength = useCallback(
        (length: number) => {
            const nodeNames = selectedNodes
                .slice(0, length)
                .map((node) => node.name)
                .join('、')
            return nodeNames
        },
        [selectedNodes]
    )
    const ref = useRef<HTMLDivElement>(null)
    const [displayNumber, setDisplayNumber] = useState(0)
    const getTextWidth = useCallback((text: string) => {
        const span = document.createElement('span')
        document.body.appendChild(span)
        span.style.fontSize = '12px'
        span.appendChild(document.createTextNode(text))
        const width = span.offsetWidth
        document.body.removeChild(span)
        return width
    }, [])

    useEffect(() => {
        if (!ref.current) {
            return
        }
        const wrapperWidth = ref.current.clientWidth
        const firstNodeWidth = (ref.current.firstChild as HTMLSpanElement).clientWidth
        const gapWidth = 6 + 6
        const maxWidth = wrapperWidth - firstNodeWidth - gapWidth
        let i = 1
        while (i <= selectedNodes.length) {
            // 名字区域的长度
            const nodeNamesWidth = getTextWidth(getNodeNamesByLength(i))
            // +n 的长度
            const plusWidth = getTextWidth(`+${selectedNodes.length - i}`)
            if (nodeNamesWidth + plusWidth > maxWidth) {
                setDisplayNumber(Math.max(i - 1, 1))
                break
            }
            if (i === selectedNodes.length) {
                setDisplayNumber(i)
                break
            }
            i++
        }
    }, [getNodeNamesByLength, getTextWidth, selectedNodes])

    return (
        <div className="flex-1 flex gap-1.5 items-center overflow-auto" ref={ref}>
            <WKTypography.Text className="shrink-0" color="placeholder">
                已选中
            </WKTypography.Text>
            <WKTypography.Text color="secondary" className="truncate block!">
                {getNodeNamesByLength(displayNumber)}
            </WKTypography.Text>
            {displayNumber < selectedNodes.length && (
                <WKTypography.Text className="shrink-0" color="placeholder">
                    +{selectedNodes.length - displayNumber}
                </WKTypography.Text>
            )}
        </div>
    )
}

const LayerName = () => {
    const { uploadedImageUrl, selectedNodes } = useChatbotAIInputInContext()
    if (selectedNodes.length <= 1) {
        const node = selectedNodes[0]
        return (
            <WKTypography.Text className="!flex truncate" color="placeholder">
                已选中&nbsp;
                <WKTypography.Text className="truncate !inline-block" color="secondary">
                    {node?.name}
                </WKTypography.Text>
            </WKTypography.Text>
        )
    } else {
        if (uploadedImageUrl) {
            // 如果有上传的图片，这里只展示图层个数
            return (
                <WKTypography.Text className="!flex truncate" color="placeholder">
                    已选中&nbsp;
                    <WKTypography.Text className="truncate !inline-block" color="secondary">
                        {selectedNodes.length}
                    </WKTypography.Text>
                    &nbsp;个图层
                </WKTypography.Text>
            )
        } else {
            // 如果没有图片，这里展示选中图层的名字，计算可以展示的个数，后面 +n
            return <MutiLayerNameWithoutImage />
        }
    }
}

const UploadedImageContent = memo(function UploadedImageContent({
    imageFile,
    uploadedStatus,
}: {
    imageFile: File
    uploadedStatus: string | null
}) {
    if (imageFile) {
        switch (uploadedStatus) {
            case 'success':
            case 'over-size':
                return (
                    <img
                        src={imgFileUrl(imageFile)}
                        alt={imageFile.name}
                        data-testid="Image uploaded for AI generation"
                        className="w-full h-full object-contain bg-transparent"
                    />
                )
            case 'pending':
                return (
                    <div className="flex justify-center items-center w-full h-full">
                        <MonoIconCommonLoading16 className="animate-spin color-[var(--wk-l2-label-color-gray-8)]" />
                    </div>
                )
            default:
                return <></>
        }
    }
    return null
})

function AttachFileItem({
    preview,
    label,
    removeAction,
}: {
    preview: React.ReactNode
    label: React.ReactNode
    removeAction: () => void
}) {
    return (
        <div className="w-full min-w-40.875 h-auto rounded flex flex-row gap-2 items-center">
            <div className="w-54px h-54px shrink-0 rounded-0.75 bg-[var(--wk-v2-gray-2)]">{preview}</div>
            <div data-testid="Attach Label" className="flex flex-col p-1.75 grow truncate">
                {label}
            </div>
            <div
                data-testid="Remove Attach File"
                className="color-[var(--wk-v2-gray-6)] w-4 h-4"
                onClick={removeAction}
            >
                <MonoIconCommonError16 />
            </div>
        </div>
    )
}

export function UploadedImageAndSelectedNode() {
    const { uploadedImageUrl, imageMetadata, selectedNodes, uploadedStatus, triggerRemoveImage, imageFile } =
        useChatbotAIInputInContext()

    if (uploadedImageUrl || imageMetadata || selectedNodes.length > 0) {
        return (
            <div className="w-full flex flex-col">
                <div className="flex items-center">
                    {uploadedImageUrl && imageMetadata && imageFile && (
                        <AttachFileItem
                            preview={<UploadedImageContent imageFile={imageFile} uploadedStatus={uploadedStatus} />}
                            label={imageFile.name}
                            removeAction={triggerRemoveImage}
                        />
                    )}
                    {uploadedImageUrl && imageMetadata && selectedNodes.length > 0 && (
                        <div className="w-0.25 h-11.5 mx-2 shrink-0 bg-[var(--wk-v2-gray-2)]"></div>
                    )}
                    {selectedNodes.length > 0 && (
                        <AttachFileItem
                            preview={<NodePreview ids={selectedNodes.map((node) => node.node)} size={45} />}
                            label={<LayerName />}
                            removeAction={() => {}}
                        />
                    )}
                </div>
                <div className="w-auto h-0.25 my-2 bg-[var(--wk-v2-gray-2)]"></div>
            </div>
        )
    } else {
        return <></>
    }
}
