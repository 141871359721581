import { RadioIconButtonGroupProvider, WKRadioIconButtonGroupContextProps } from './use-radio-icon-group-context'

export interface WKRadioIconButtonGroupProps extends WKRadioIconButtonGroupContextProps {
    children: React.ReactNode
}

const styles = {
    base: 'flex-none inline-flex flex-row items-center gap-2px box-border bg-$wk-l2-fill-color-gray-1 rounded-$wk-radius-default border-solid border-$wk-gray-1 border-2 has-[:focus-visible]:border-$wk-brand-7',
}

export const WKRadioIconButtonGroup = (props: WKRadioIconButtonGroupProps) => {
    const { children, ...otherProps } = props

    return (
        <RadioIconButtonGroupProvider {...otherProps}>
            <div className={styles.base}>{children}</div>
        </RadioIconButtonGroupProvider>
    )
}
