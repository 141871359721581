import { FC, useCallback, useEffect, useRef, useState } from 'react'
import {
    MonoIconCommonError16,
    MonoIconCommonstop16,
    MonoIconPanelArrowUp16,
    WKIconButton,
    WKTypography,
} from '../../../../../../ui-lib/src'
import { WKImage } from '../../../../../../ui-lib/src/components/wk-image'

import classNames from 'classnames'
import { useAppContext } from '../../../../main/app-context'
import {
    ChatbotImageSelectButton,
    ChatbotInputHelpersProvider,
    ChatbotInputProps,
    useChatbotInputHelpers,
} from './chatbot-input-helpers'

const LineHeight = 22

const _Divider = () => {
    return <div className="w-1px h-4 bg-$wk-gray-3"></div>
}

const HTMLTextArea = () => {
    const textareaRef = useRef<HTMLTextAreaElement>(null)
    const { setText, text, rows, setRows, useInlineStyle, setUseInlineStyle, sendMessage } = useChatbotInputHelpers()

    const [inputHistory, setInputHistory] = useState<string[]>([
        '选中所有图层',
        '选中所有文本图层',
        '增大选中文本图层的字体大小',
    ])
    const [showHistory, setShowHistory] = useState(false)
    const [selectedHistoryIndex, _setSelectedHistoryIndex] = useState(-1)

    const handleHistorySelect = (historyText: string) => {
        setText(historyText)
        setShowHistory(false)
    }

    const chatbotService = useAppContext().chatbotService
    const enablePresetInput = chatbotService.stateStore.use.chatbotDebugSetting().enablePresetInput

    useEffect(() => {
        // 根据内容的换行符决定行数
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto'
            const scrollHeight = Math.min(textareaRef.current.scrollHeight, 66)
            textareaRef.current.style.height = `${scrollHeight}px`
            const _rows = Math.floor(scrollHeight / LineHeight)
            setRows(_rows)
            if (_rows > 1) {
                setUseInlineStyle(false)
            }
        }
    }, [text, setRows, setUseInlineStyle])

    const [initWidth, setInitWidth] = useState(0)

    useEffect(() => {
        const textarea = textareaRef.current
        if (textarea) {
            // 记录最开始的宽度，为了后面从多行变一行的时候使用
            setInitWidth(textarea.clientWidth)
        }
    }, [textareaRef])

    useEffect(() => {
        // 根据单行内容的宽度计算是否要使用多行样式
        // 创建一个 span 来包裹 内容，然后计算出 span 的宽度
        if (text.split('\n').length > 1) {
            return
        }
        const span = document.createElement('span')
        span.innerHTML = text
        span.style.position = 'absolute'
        span.style.left = '0'
        span.style.fontSize = '13px'
        span.style.bottom = '0'
        span.style.zIndex = '1000'
        document.body.appendChild(span)
        const spanWidth = span.clientWidth
        // 留一点 buffer，提前换行
        if (spanWidth >= initWidth - 15) {
            setUseInlineStyle(false)
        } else {
            setUseInlineStyle(true)
        }
        document.body.removeChild(span)
    }, [text, initWidth, rows, setUseInlineStyle])
    const [isComposing, setIsComposing] = useState(false)

    return (
        <>
            <textarea
                data-testid="chatbot-input-textarea"
                rows={1}
                autoFocus
                onDoubleClick={() => setShowHistory((prev) => !prev)}
                onBlur={() => {
                    setTimeout(() => {
                        setShowHistory(false)
                    }, 200)
                }}
                onCompositionStart={() => setIsComposing(true)}
                onCompositionUpdate={() => setIsComposing(true)}
                onCompositionEnd={() => setIsComposing(false)}
                onKeyDown={(e) => {
                    if (isComposing) {
                        return
                    }
                    if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault()
                        e.stopPropagation()
                        sendMessage()

                        if (enablePresetInput) {
                            // 记录输入历史
                            setInputHistory((prev) => [...prev, text])
                        }
                    }
                }}
                ref={textareaRef}
                placeholder="问我任何问题…"
                onChange={(e) => {
                    setText(e.target.value)
                }}
                style={{
                    lineHeight: `${LineHeight}px`,
                }}
                value={text}
                className={classNames(
                    'flex-1 text-13px p-0 bg-transparent outline-none border-none resize-none max-h-66px placeholder-$wk-gray-8',
                    !useInlineStyle ? 'absolute top-3 left-4 right-4' : ''
                )}
            />
            {enablePresetInput && showHistory && inputHistory.length > 0 && (
                <div className="absolute bottom-full left-0 w-full bg-white shadow-lg rounded-md border border-$wk-l2-stroke-color-gray-3 max-h-60 overflow-y-auto">
                    {inputHistory
                        .slice()
                        .reverse()
                        .map((historyText, index) => (
                            <div
                                key={index}
                                className={classNames(
                                    'px-3 py-2 cursor-pointer hover:bg-$wk-l2-fill-color-gray-1',
                                    selectedHistoryIndex === index ? 'bg-$wk-l2-gray-color-2' : ''
                                )}
                                onClick={() => handleHistorySelect(historyText)}
                            >
                                <WKTypography.Text className="truncate block">{historyText}</WKTypography.Text>
                            </div>
                        ))}
                </div>
            )}
        </>
    )
}

const SendAndPause = () => {
    const { answering, sendMessageActive, sendMessage } = useChatbotInputHelpers()
    if (answering) {
        return <WKIconButton type="primary" size="small" icon={<MonoIconCommonstop16 />} />
    }
    return (
        <WKIconButton
            data-testid="chatbot-input-send-button"
            disabled={!sendMessageActive}
            className="rounded-full"
            type="deepBlue"
            size="medium"
            icon={<MonoIconPanelArrowUp16 />}
            onClick={() => {
                sendMessage()
            }}
        />
    )
}

const _Input = () => {
    const { answering, useInlineStyle, rows } = useChatbotInputHelpers()
    return (
        <div
            className={classNames(
                'flex relative flex-col gap-2 items-center px-4 py-3 rounded-3px focus-within:(ring-2 ring-$wk-brand-7)',
                answering ? 'bg-$wk-gray-3' : 'bg-$wk-l2-fill-color-gray-1'
            )}
        >
            {!useInlineStyle && <div style={{ height: `${rows * LineHeight}px` }}></div>}
            <div className={classNames('flex w-full gap-2 items-center', !useInlineStyle ? 'justify-between' : '')}>
                <ChatbotImageSelectButton />
                {useInlineStyle && <_Divider />}
                <HTMLTextArea />
                <SendAndPause />
            </div>
        </div>
    )
}

const EmptyBlock = () => {
    // 当预览区域出现的时候，聊天区域需要上移
    // 当预览区域消失的时候，聊天区域需要下移
    const { scrollBy } = useChatbotInputHelpers()
    useEffect(() => {
        scrollBy(86)
        return () => {
            scrollBy(-86)
        }
    }, [scrollBy])
    return <></>
}

const MutiLayerNameWithoutImage = () => {
    const { nodeIds } = useChatbotInputHelpers()
    const getNodeNamesByLength = useCallback(
        (length: number) => {
            const nodeNames = nodeIds
                .slice(0, length)
                .map((id) => window.motiff.getNodeById(id)?.name)
                .join('、')
            return nodeNames
        },
        [nodeIds]
    )
    const ref = useRef<HTMLDivElement>(null)
    const [displayNumber, setDisplayNumber] = useState(0)
    const getTextWidth = useCallback((text: string) => {
        const span = document.createElement('span')
        document.body.appendChild(span)
        span.style.fontSize = '12px'
        span.appendChild(document.createTextNode(text))
        const width = span.offsetWidth
        document.body.removeChild(span)
        return width
    }, [])

    useEffect(() => {
        if (!ref.current) {
            return
        }
        const wrapperWidth = ref.current.clientWidth
        const firstNodeWidth = (ref.current.firstChild as HTMLSpanElement).clientWidth
        const gapWidth = 6 + 6
        const maxWidth = wrapperWidth - firstNodeWidth - gapWidth
        let i = 1
        while (i <= nodeIds.length) {
            // 名字区域的长度
            const nodeNamesWidth = getTextWidth(getNodeNamesByLength(i))
            // +n 的长度
            const plusWidth = getTextWidth(`+${nodeIds.length - i}`)
            if (nodeNamesWidth + plusWidth > maxWidth) {
                setDisplayNumber(Math.max(i - 1, 1))
                break
            }
            if (i === nodeIds.length) {
                setDisplayNumber(i)
                break
            }
            i++
        }
    }, [getNodeNamesByLength, getTextWidth, nodeIds])

    return (
        <div className="flex-1 flex gap-1.5 items-center overflow-auto" ref={ref}>
            <WKTypography.Text className="shrink-0" color="placeholder">
                已选中
            </WKTypography.Text>
            <WKTypography.Text color="secondary" className="truncate block!">
                {getNodeNamesByLength(displayNumber)}
            </WKTypography.Text>
            {displayNumber < nodeIds.length && (
                <WKTypography.Text className="shrink-0" color="placeholder">
                    +{nodeIds.length - displayNumber}
                </WKTypography.Text>
            )}
        </div>
    )
}

const LayerName = () => {
    const { image, nodeIds } = useChatbotInputHelpers()
    if (nodeIds.length <= 1) {
        const node = window.motiff.getNodeById(nodeIds[0])
        return (
            <WKTypography.Text className="!flex truncate" color="placeholder">
                已选中&nbsp;
                <WKTypography.Text className="truncate !inline-block" color="secondary">
                    {node?.name}
                </WKTypography.Text>
            </WKTypography.Text>
        )
    } else {
        if (image) {
            // 如果有上传的图片，这里只展示图层个数
            return (
                <WKTypography.Text className="!flex truncate" color="placeholder">
                    已选中&nbsp;
                    <WKTypography.Text className="truncate !inline-block" color="secondary">
                        {nodeIds.length}
                    </WKTypography.Text>
                    &nbsp;个图层
                </WKTypography.Text>
            )
        } else {
            // 如果没有图片，这里展示选中图层的名字，计算可以展示的个数，后面 +n
            return <MutiLayerNameWithoutImage />
        }
    }
}

const FilesAndSelections = () => {
    const { image, setImage, imageDataUrl, selectionsPreviewInfo } = useChatbotInputHelpers()

    if (image || selectionsPreviewInfo) {
        return (
            <div className="flex gap-2 items-center">
                <EmptyBlock />
                {image && (
                    <div className="p-3 ring flex-1 ring-$wk-gray-3 rounded-4px flex gap-2 items-center truncate">
                        <div className="flex-1 flex items-center gap-1.5 truncate">
                            <WKImage
                                backgroundColor="var(--wk-l2-gray-color-2)"
                                src={imageDataUrl}
                                width={54}
                                height={54}
                                objectFit="contain"
                            />
                            <WKTypography.Text size={12} className="flex-1 truncate !block" color="secondary">
                                {image.name}
                            </WKTypography.Text>
                        </div>
                        <MonoIconCommonError16
                            className="color-$wk-l2-label-color-gray-6 hover:color-$wk-l2-label-color-gray-8"
                            onClick={() => setImage(undefined)}
                        />
                    </div>
                )}
                {selectionsPreviewInfo && (
                    <div
                        data-testid="selection-preview-info"
                        className="p-3 ring flex-1 ring-$wk-gray-3 rounded-4px flex gap-2 items-center truncate"
                    >
                        <div className="flex-1 flex items-center gap-1.5 truncate">
                            <div className="shrink-0">
                                <img
                                    className="w-54px! h-54px! bg-$wk-l2-gray-color-2 object-contain"
                                    src={selectionsPreviewInfo.base64 ?? ''}
                                />
                            </div>
                            <LayerName />
                        </div>
                        {/* <MonoIconCommonError16 className="color-$wk-l2-label-color-gray-6 cursor-not-allowed" /> */}
                    </div>
                )}
            </div>
        )
    } else {
        return <></>
    }
}

const _ChatbotInput = () => {
    return (
        <div className="p-6 flex flex-col gap-2">
            {/* 素材 */}
            <FilesAndSelections />
            {/* 输入框 */}
            <_Input />
        </div>
    )
}

export const ChatbotInput: FC<ChatbotInputProps> = (props) => {
    return (
        <ChatbotInputHelpersProvider {...props}>
            <_ChatbotInput />
        </ChatbotInputHelpersProvider>
    )
}
