import { useCallback, useEffect } from 'react'
import { LocalStorageKey } from '../../../app/src/web-storage/local-storage/config'
import { useLocalStorageState } from '../../../app/src/web-storage/local-storage/hooks'

export const useSwitchTheme = () => {
    const [theme, setTheme] = useLocalStorageState(LocalStorageKey.PrimaryTheme, 'light')
    const toggleTheme = useCallback(() => {
        setTheme(theme === 'light' ? 'dark' : 'light')
    }, [theme, setTheme])

    useEffect(() => {
        const doc = document.documentElement
        const currentSubTheme = doc.getAttribute('theme')?.split(' ')[1]
        const subTheme = currentSubTheme ?? 'design'
        if (theme === 'dark') {
            doc.setAttribute('theme', `dark ${subTheme}`)
        } else {
            doc.setAttribute('theme', `light ${subTheme}`)
        }
    }, [theme])

    return { theme, toggleTheme }
}
