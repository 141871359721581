import classNames from 'classnames'
import * as React from 'react'
import { MonoIconCommonDevMode16, Tooltip, WKPopover } from '../../../../../../ui-lib/src'
import { RoleStatus } from '../../../../kernel/interface/type'
import { BatchMoveDocModal } from '../../../../share/batch-move-doc-modal'
import { useDocInfoContext } from '../../../context/top-area-context'
import { translation } from './dev-mode-switch.translation'
import styles from './index.module.less'
import { ShortcutKey, shortcutLabelMap } from '../../../../kernel/interface/shortcut-key'

interface DevModeSwitchProps {
    checked?: boolean
    onClick: (checked: boolean, event: React.MouseEvent<HTMLButtonElement>) => void
    disabled?: boolean
    testid?: string
}
export function DevModeSwitch({ onClick, disabled, checked, testid }: DevModeSwitchProps) {
    const ref = React.useRef<HTMLDivElement>(null)

    const triggerRect = () => {
        const rect = ref.current!.getBoundingClientRect()
        const gap = 8
        return { top: rect.top - gap, bottom: rect.bottom + gap, left: rect.left, right: rect.right }
    }

    const handleClick = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            onClick(!checked, event)
        },
        [checked, onClick]
    )

    const { docData, getCurrentDocOrganization } = useDocInfoContext()
    const [visible, setVisible] = React.useState<boolean>(false)

    if (docData?.draft && docData.orgId == '-1') {
        return (
            <>
                <WKPopover
                    contents={
                        <DevModeSwitchToolTipForDraft
                            isOwner={docData?.role == RoleStatus.Owner}
                            onClick={() => setVisible(true)}
                        />
                    }
                    triggerRect={() => ref.current!.getBoundingClientRect()}
                    testId="dev-mode-switch-pop"
                >
                    <DevModeSwitchPure
                        onClickButton={handleClick}
                        disabled={disabled}
                        checked={checked}
                        testid={testid}
                        ref={ref}
                    />
                </WKPopover>
                {visible && (
                    <BatchMoveDocModal
                        docs={[docData]}
                        forceHideTipBanner={true}
                        onClose={() => setVisible(false)}
                        organization={getCurrentDocOrganization()!}
                    />
                )}
            </>
        )
    } else {
        return (
            <Tooltip
                title={translation('DevMode')}
                shortcut={shortcutLabelMap[ShortcutKey.DevMode]}
                triggerRect={triggerRect}
            >
                <DevModeSwitchPure
                    onClickButton={handleClick}
                    disabled={disabled}
                    checked={checked}
                    testid={testid}
                    ref={ref}
                />
            </Tooltip>
        )
    }
}

interface DevModeSwitchPureProps extends React.HTMLAttributes<HTMLDivElement> {
    checked?: boolean
    onClickButton: (event: React.MouseEvent<HTMLButtonElement>) => void
    disabled?: boolean
    testid?: string
    useInSkeleton?: boolean
}

function _DevModeSwitchPure(
    { onClickButton, disabled, checked, testid, useInSkeleton, ...otherProps }: DevModeSwitchPureProps,
    ref: React.Ref<HTMLDivElement>
) {
    const position = checked ? { left: 24 } : { left: 4 }

    return (
        <div
            className={classNames(
                checked ? styles.wrapperTurnOn : styles.wrapperTurnOff,
                styles.wrapper,
                useInSkeleton && '!bg-$wk-gray-3'
            )}
            ref={ref}
            {...otherProps}
        >
            <button
                data-testid={testid}
                aria-checked={checked}
                role="switch"
                onClick={onClickButton}
                disabled={disabled}
                className={classNames(
                    styles.switch,
                    checked ? styles.switchTurnOn : styles.switchTurnOff,
                    styles.focus
                )}
            >
                <div
                    className={classNames(
                        styles.dot,
                        checked ? styles.dotTurnOn : styles.dotTurnOff,
                        useInSkeleton && '!color-$wk-l2-label-color-gray-6'
                    )}
                    style={position}
                >
                    <MonoIconCommonDevMode16 />
                </div>
            </button>
        </div>
    )
}

export const DevModeSwitchPure = React.forwardRef(_DevModeSwitchPure)

const DevModeSwitchToolTipForDraft = ({ isOwner, onClick }: { isOwner: boolean; onClick: () => void }) => {
    return (
        <div className={styles.tooltip}>
            <div>{translation('DevModeTip')}</div>
            {isOwner && (
                <div className={styles.button} onClick={onClick}>
                    {translation('MoveFile')}
                </div>
            )}
        </div>
    )
}
