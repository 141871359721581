import { LoaderFunctionArgs } from 'react-router-dom'
import { WKToast } from '../../../../../../../ui-lib/src/components/wk-toast'
import { TraceableAbortSignal } from '../../../../../../../util/src/abort-controller/traceable-abort-controller'
import { transaction } from '../../../../../../../util/src/abort-controller/traceable-transaction'
import { getCommitId } from '../../../../../../../util/src/build-info'
import { action, RESET$ } from '../../../../../../../util/src/ccstate-helper/action'
import { traceable } from '../../../../../../../util/src/ccstate-helper/traceable'
import { environment } from '../../../../../environment'
import { AppIds } from '../../../../../kernel/frog/enums'
import { tryInitFrog } from '../../../../../kernel/frog/frog-service'
import { disableSentry, tryInitSentry } from '../../../../../kernel/sentry'
import { featureSwitchManager } from '../../../../../kernel/switch/core'
import { startSync$ } from '../../../../../space/app/zustand/space-state-start-sync'
import { WK } from '../../../../../window'
import { initGA$ } from '../../../../atoms/ga'
import { setupNotify$ } from '../../../../atoms/notify'
import { startAuthenticationFlow$ } from '../../../2-auth/web'
import { bootstrapCommon$ } from '../common'
import { enhancedLocalStorage } from '../../../../../web-storage/local-storage/storage'
import { LocalStorageKey } from '../../../../../web-storage/local-storage/config'

const setupTheme = () => {
    const primaryTheme = enhancedLocalStorage.getSerializedItem(LocalStorageKey.PrimaryTheme)

    if (primaryTheme == 'dark') {
        document.documentElement.setAttribute('theme', 'dark design')
    } else {
        document.documentElement.setAttribute('theme', 'light design')
        enhancedLocalStorage.setSerializedItem(LocalStorageKey.PrimaryTheme, 'light')
    }
}

/**
 * 开启 webapp bootstrap 流程
 */
export const [bootstrapWebappFlow$, setupBootstrapWebappFlow$] = traceable(
    'hulh01@kanyun.com',
    action(async ({ set }, signal: TraceableAbortSignal, args: LoaderFunctionArgs) => {
        set(bootstrapCommon$, signal)

        const { act } = transaction(signal)

        act('set notify atom', () => {
            set(setupNotify$)

            return () => {
                set(setupNotify$, RESET$)
            }
        })

        // TODO:(hulh01) 待业务决策是否需要 cleanup
        set(startSync$, signal)

        // TODO:(hulh01) 完成一些 trace 功能
        act('inject trace to WK', () => {
            WK.showTracePanel = () => {}
            WK.getUnsolvedResource = () => ''

            return () => {
                delete WK.showTracePanel
                delete WK.getUnsolvedResource
            }
        })

        act('init featureSwitch', () => {
            featureSwitchManager.init()

            return () => {
                featureSwitchManager.clearLocalSwitches()
            }
        })

        act('inject global toast', () => {
            WKToast.injectGlobal()

            return () => {
                WKToast.ejectGlobal()
            }
        })

        act('set up theme', () => {
            setupTheme()
        })

        act('enable sentry', () => {
            tryInitSentry()

            return () => {
                disableSentry()
            }
        })

        act('add commit id to window', () => {
            WK.commitId = getCommitId() ?? ''

            return () => {
                delete WK.commitId
            }
        })

        act('enable frog', () => {
            tryInitFrog(environment.isAbroad ? AppIds.MotiffAbroad : AppIds.Motiff)
        })

        act('add some listeners to document / window', () => {
            // 禁止页面上的 wheel scale 行为，包括 mac 鼠标的 smart zoom
            document.addEventListener(
                'wheel',
                (e) => {
                    if (e.metaKey || e.ctrlKey) {
                        e.preventDefault()
                    }
                },
                { passive: false, signal }
            )

            // 禁止 context menu 事件弹出浏览器右键
            document.addEventListener(
                'contextmenu',
                (e) => {
                    e.preventDefault()
                },
                { signal }
            )
        })

        act('init React GA', () => {
            set(initGA$)
        })

        // 开启登录流程
        await set(startAuthenticationFlow$, signal, args)

        return null
    })
)
