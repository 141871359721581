import { Wukong } from '@wukong/bridge-proto'
import { useRef } from 'react'
import {
    MonoIconPanelIndependentCorners16,
    MonoIconPanelLBCornersRadius16,
    MonoIconPanelLTCornersRadius16,
    MonoIconPanelRBCornersRadius16,
    MonoIconPanelRTCornersRadius16,
    Tooltip,
} from '../../../../../../ui-lib/src'
import { IconButton } from '../../atom/button/icon-button'
import { SingleGrid } from '../../atom/grid/single-grid'
import { ScrubbableInputNumber } from '../../atom/inputs/scrubbable-input-number'
import { useFloatVariablePanel } from '../primitive-variable/use-float-variable-panel'
import { translation } from './corner-radius.translation'
import { CornerSmoothing } from './corner-smoothing'
import { useCornerRadius } from './use-corner-radius'

export function CornerRadius() {
    const {
        cornerRadius,
        cornerSmoothing,
        independentCornerRadius,
        cornerRadiusVariable,
        onChange,
        onChangeNew,
        onAttachFloatVar,
        onDetachFloatVar,
    } = useCornerRadius()
    const showCornerRadius = cornerRadius?.show
    const showCornerSmoothing = cornerSmoothing?.show
    const showIndependentCornerRadius = independentCornerRadius?.show
    const expandIndependentCornerRadius = independentCornerRadius?.expand || false
    const contentRef = useRef<HTMLDivElement>(null)

    const cornerRadiusVariablePicker = useFloatVariablePanel({
        requiredScopes: [Wukong.DocumentProto.VariableScope.CORNER_RADIUS],
        selectedVariable: cornerRadiusVariable,
        selectedVariableFallbackFloatValue: cornerRadius?.value ?? 0,
        createEnable: true,
        defaultCreateValue: cornerRadius?.value ?? 0,
        onVariableSelected: (id) => {
            onAttachFloatVar(id, Wukong.DocumentProto.VariableField.CORNER_RADIUS)
        },
        onVariableDetach: () => {
            onDetachFloatVar(Wukong.DocumentProto.VariableField.CORNER_RADIUS)
        },
    })

    return (
        <>
            {showCornerRadius ? (
                <Tooltip title={translation('CornerRadius')} canMouseDownClose>
                    <SingleGrid.Item start={29} span={22} ref={contentRef}>
                        <ScrubbableInputNumber
                            disabled={expandIndependentCornerRadius}
                            labelTestId="coordinate-radius-label"
                            testId="corner-radius-input"
                            icon={<MonoIconPanelLTCornersRadius16 />}
                            isMixed={cornerRadius?.mixed}
                            value={typeof cornerRadius?.value === 'number' ? cornerRadius.value : undefined}
                            onChange={onChangeNew('cornerRadius')}
                            min={0}
                            scrubbingDisabled={cornerRadius?.mixed}
                            useVariable={cornerRadiusVariablePicker.useVariable}
                        />
                    </SingleGrid.Item>
                </Tooltip>
            ) : null}
            {showIndependentCornerRadius ? (
                <SingleGrid.Item start={51} span={12} horizontalCenter>
                    <Tooltip title={translation('IndependentCorners')}>
                        <IconButton
                            onChange={onChange('independentCorners')}
                            selected={expandIndependentCornerRadius}
                            icon={<MonoIconPanelIndependentCorners16 />}
                            dataTestId="independentCorners-icon"
                            tabTestId="icon-corners-focus"
                        />
                    </Tooltip>
                </SingleGrid.Item>
            ) : null}
            {showCornerSmoothing && !showIndependentCornerRadius ? <CornerSmoothing onChange={onChange} /> : null}
            {cornerRadiusVariablePicker.renderPanel()}
        </>
    )
}

export function IndependentCornerRadius() {
    const {
        cornerSmoothing,
        independentCornerRadius,
        topLeftRadius,
        topRightRadius,
        bottomRightRadius,
        bottomLeftRadius,
        topLeftRadiusVariable,
        topRightRadiusVariable,
        bottomRightRadiusVariable,
        bottomLeftRadiusVariable,
        onChange,
        onChangeNew,
        onAttachFloatVar,
        onDetachFloatVar,
    } = useCornerRadius()
    const showCornerSmoothing = cornerSmoothing?.show
    const expandIndependentCornerRadius = independentCornerRadius?.expand || false

    const topLeftRadiusVariablePicker = useFloatVariablePanel({
        requiredScopes: [Wukong.DocumentProto.VariableScope.CORNER_RADIUS],
        selectedVariable: topLeftRadiusVariable,
        selectedVariableFallbackFloatValue: topLeftRadius?.value ?? 0,
        createEnable: true,
        defaultCreateValue: topLeftRadius?.value ?? 0,
        onVariableSelected: (id) => {
            onAttachFloatVar(id, Wukong.DocumentProto.VariableField.RECTANGLE_TOP_LEFT_CORNER_RADIUS)
        },
        onVariableDetach: () => {
            onDetachFloatVar(Wukong.DocumentProto.VariableField.RECTANGLE_TOP_LEFT_CORNER_RADIUS)
        },
    })

    const topRightRadiusVariablePicker = useFloatVariablePanel({
        requiredScopes: [Wukong.DocumentProto.VariableScope.CORNER_RADIUS],
        selectedVariable: topRightRadiusVariable,
        selectedVariableFallbackFloatValue: topRightRadius?.value ?? 0,
        createEnable: true,
        defaultCreateValue: topRightRadius?.value ?? 0,
        onVariableSelected: (id) => {
            onAttachFloatVar(id, Wukong.DocumentProto.VariableField.RECTANGLE_TOP_RIGHT_CORNER_RADIUS)
        },
        onVariableDetach: () => {
            onDetachFloatVar(Wukong.DocumentProto.VariableField.RECTANGLE_TOP_RIGHT_CORNER_RADIUS)
        },
    })

    const bottomRightRadiusVariablePicker = useFloatVariablePanel({
        requiredScopes: [Wukong.DocumentProto.VariableScope.CORNER_RADIUS],
        selectedVariable: bottomRightRadiusVariable,
        selectedVariableFallbackFloatValue: bottomRightRadius?.value ?? 0,
        createEnable: true,
        defaultCreateValue: bottomRightRadius?.value ?? 0,
        onVariableSelected: (id) => {
            onAttachFloatVar(id, Wukong.DocumentProto.VariableField.RECTANGLE_BOTTOM_RIGHT_CORNER_RADIUS)
        },
        onVariableDetach: () => {
            onDetachFloatVar(Wukong.DocumentProto.VariableField.RECTANGLE_BOTTOM_RIGHT_CORNER_RADIUS)
        },
    })

    const bottomLeftRadiusVariablePicker = useFloatVariablePanel({
        requiredScopes: [Wukong.DocumentProto.VariableScope.CORNER_RADIUS],
        selectedVariable: bottomLeftRadiusVariable,
        selectedVariableFallbackFloatValue: bottomLeftRadius?.value ?? 0,
        createEnable: true,
        defaultCreateValue: bottomLeftRadius?.value ?? 0,
        onVariableSelected: (id) => {
            onAttachFloatVar(id, Wukong.DocumentProto.VariableField.RECTANGLE_BOTTOM_LEFT_CORNER_RADIUS)
        },
        onVariableDetach: () => {
            onDetachFloatVar(Wukong.DocumentProto.VariableField.RECTANGLE_BOTTOM_LEFT_CORNER_RADIUS)
        },
    })

    if (!expandIndependentCornerRadius) {
        return null
    }
    return (
        <>
            <SingleGrid>
                <Tooltip title={translation('TopLeft')}>
                    <SingleGrid.Item start={5} span={22} className="bg-$wk-l2-fill-color-gray-1 rounded-3px">
                        <ScrubbableInputNumber
                            isMixed={topLeftRadius?.mixed}
                            labelTestId="coordinate-top-left-radius-label"
                            value={typeof topLeftRadius?.value === 'number' ? topLeftRadius.value : undefined}
                            onChange={onChangeNew('topLeftRadius')}
                            min={0}
                            scrubbingDisabled={topLeftRadius?.mixed}
                            icon={<MonoIconPanelLTCornersRadius16 />}
                            useVariable={topLeftRadiusVariablePicker.useVariable}
                        />
                    </SingleGrid.Item>
                </Tooltip>
                <Tooltip title={translation('TopRight')}>
                    <SingleGrid.Item start={29} span={22} className="bg-$wk-l2-fill-color-gray-1 rounded-3px">
                        <ScrubbableInputNumber
                            isMixed={topRightRadius?.mixed}
                            labelTestId="coordinate-top-right-radius-label"
                            value={typeof topRightRadius?.value === 'number' ? topRightRadius.value : undefined}
                            onChange={onChangeNew('topRightRadius')}
                            min={0}
                            scrubbingDisabled={topRightRadius?.mixed}
                            icon={<MonoIconPanelRTCornersRadius16 />}
                            useVariable={topRightRadiusVariablePicker.useVariable}
                        />
                    </SingleGrid.Item>
                </Tooltip>
                {showCornerSmoothing && <CornerSmoothing onChange={onChange} />}
            </SingleGrid>
            <SingleGrid>
                <Tooltip title={translation('BottomLeft')}>
                    <SingleGrid.Item start={5} span={22} className="bg-$wk-l2-fill-color-gray-1 rounded-3px">
                        <ScrubbableInputNumber
                            isMixed={bottomLeftRadius?.mixed}
                            labelTestId="coordinate-bottom-left-radius-label"
                            value={typeof bottomLeftRadius?.value === 'number' ? bottomLeftRadius.value : undefined}
                            onChange={onChangeNew('bottomLeftRadius')}
                            min={0}
                            scrubbingDisabled={bottomLeftRadius?.mixed}
                            icon={<MonoIconPanelLBCornersRadius16 />}
                            useVariable={bottomLeftRadiusVariablePicker.useVariable}
                        />
                    </SingleGrid.Item>
                </Tooltip>
                <Tooltip title={translation('BottomRight')}>
                    <SingleGrid.Item start={29} span={22} className="bg-$wk-l2-fill-color-gray-1 rounded-3px">
                        <ScrubbableInputNumber
                            isMixed={bottomRightRadius?.mixed}
                            labelTestId="coordinate-bottom-right-radius-label"
                            value={typeof bottomRightRadius?.value === 'number' ? bottomRightRadius.value : undefined}
                            onChange={onChangeNew('bottomRightRadius')}
                            min={0}
                            scrubbingDisabled={bottomRightRadius?.mixed}
                            icon={<MonoIconPanelRBCornersRadius16 />}
                            useVariable={bottomRightRadiusVariablePicker.useVariable}
                        />
                    </SingleGrid.Item>
                </Tooltip>
            </SingleGrid>
            {topLeftRadiusVariablePicker.renderPanel()}
            {topRightRadiusVariablePicker.renderPanel()}
            {bottomRightRadiusVariablePicker.renderPanel()}
            {bottomLeftRadiusVariablePicker.renderPanel()}
        </>
    )
}
