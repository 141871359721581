import { Wukong } from '@wukong/bridge-proto'
import { Checkbox, MonoIconCommonWarningLine16 } from '../../../../../../../ui-lib/src'
import Tooltip from '../../../../../../../ui-lib/src/components/tooltip/tooltip'
import { featureSwitchManager } from '../../../../../kernel/switch/core'
import { useSelectedVariablesEditorContext } from '../selected-variables-editor-context'
import {
    VariableScopeCheckboxKey2Label,
    VariableScopeCheckboxKeyDepth,
    VariableScopeCheckboxValue,
    VariableScopeCheckboxValueList,
} from '../utils'
import styles from './index.module.less'
import { translation } from './index.translation'

export function SelectedVariablesEditorScope({
    scopeCheckboxValues,
}: {
    scopeCheckboxValues: VariableScopeCheckboxValue[]
}) {
    const { onChangeScopes } = useSelectedVariablesEditorContext()

    return (
        <div data-testid="selected-variables-editor-scope" className="px-4">
            {scopeCheckboxValues.map((value, index) => (
                <div
                    key={value.key}
                    data-testid="selected-variables-editor-scope-item"
                    className="flex py-1 items-center"
                    style={{ paddingLeft: VariableScopeCheckboxKeyDepth[value.key] * 24 }}
                >
                    <Checkbox
                        containerTestId={`scope-checkbox-${
                            value.mixed ? 'mixed' : value.checked ? 'checked' : 'unchecked'
                        }`}
                        checked={value.checked}
                        indeterminate={value.mixed}
                        label={VariableScopeCheckboxKey2Label[value.key]}
                        onChange={(checked) => onChangeScopes(value.key, checked)}
                    />
                    {index === 0 && (
                        <Tooltip
                            title={translation(featureSwitchManager.isEnabled('float-variable') ? 'HintV2' : 'Hint')}
                            firstDelay={100}
                        >
                            <MonoIconCommonWarningLine16
                                data-testid="selected-variables-editor-scope-hint-icon"
                                className="color-$wk-l2-label-color-gray-8 ml-1"
                            />
                        </Tooltip>
                    )}
                </div>
            ))}
        </div>
    )
}

function ScopeLabel({ type }: { type: Wukong.DocumentProto.VariableResolvedDataType }) {
    switch (type) {
        case Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_COLOR:
            return translation('ColorScopeLabel')
        case Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_FLOAT:
            return translation('NumberScopeLabel')
        default:
            return null
    }
}
export function SelectedVariablesEditorScopeV2({ valueList }: { valueList: VariableScopeCheckboxValueList }) {
    return (
        <div>
            {valueList.map(({ type, values }) => (
                <div key={type} className={styles['scope-section']}>
                    <div data-testid="selected-variables-editor-scope-label" className="px-4">
                        <div className="py-[6px] text-$wk-gray-13 wk-text-12 wk-font-semibold">
                            <ScopeLabel type={type} />
                        </div>
                    </div>
                    <SelectedVariablesEditorScope scopeCheckboxValues={values} />
                </div>
            ))}
        </div>
    )
}
