import {
    UpdateLibraryPublishModalAllChangesCheckedWasmCall,
    UpdateLibraryPublishModalChangeCheckedCommand,
    UpdatePublishHiddenCommand,
    Wukong,
} from '@wukong/bridge-proto'
import classNames from 'classnames'
import { isNil } from 'lodash-es'
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'
import { useDebounce } from 'react-use'
import {
    Checkbox,
    DropdownNoTriggerSingleLevelRef,
    DropdownV2,
    MonoIconCommonArrowLeft16,
    MonoIconCommonArrowRight16,
    MonoIconCommonInfoLine16,
    MonoIconPanelTarget16,
    Tooltip,
    WKCollapse,
    WKCollapseItem,
    WKIconButton,
} from '../../../../../../../ui-lib/src'
import { isEnglishLanguage, usePrevious } from '../../../../../../../util/src'
import { CommitType } from '../../../../../document/command/commit-type'
import { NodeId } from '../../../../../document/node/node'
import { isStyleNode } from '../../../../../document/node/node-assertion'
import { isWindows } from '../../../../../kernel/util/ua'
import { LibraryThumbnailImage } from '../../../../../share/component-style-library/library-thumbnail-image'
import { LibraryTestId } from '../../../../../window'
import { useCommand } from '../../../../context/document-context'
import { LibraryChangeTypeLabel } from '../../library-service/type'
import styles from './list.module.less'
import { translation } from './list.translation'

const COLLAPSE_KEY = 'collapse'

interface ChangeSelectListProps {
    checkCount: number
    allChangeCount: number
    changes: Wukong.DocumentProto.IVPublishModalChangeItem[]
    filterText?: string
    control: Wukong.DocumentProto.IVPublishModalCollapseControl | null | undefined
    setCollapse: (val: boolean) => void
    hasContent: boolean | undefined | null
    variableSetTitle?: Wukong.DocumentProto.IVPublishModalCollapseControl | null | undefined
    styleTitle?: Wukong.DocumentProto.IVPublishModalCollapseControl | null | undefined
    componentTitle?: Wukong.DocumentProto.IVPublishModalCollapseControl | null | undefined
    showDetailFn?: (change: Wukong.DocumentProto.IVPublishModalChangeItem) => void
    variableSetToVariableChangeMap?: Record<string, Wukong.DocumentProto.IVPublishModalChangeItem[]>
    variableSetToVariableHiddenMap?: Record<string, Wukong.DocumentProto.IVPublishModalCommonItem[]>
}

const wrapCollapse = (val: boolean | null | undefined) => {
    return val ? [COLLAPSE_KEY] : []
}

const wrapSetCollapse = (fn: (val: boolean) => void): ((key: string | string[]) => void) => {
    return (key) => {
        if (Array.isArray(key) && key[0] === COLLAPSE_KEY) {
            fn(true)
        } else if (key === COLLAPSE_KEY) {
            fn(true)
        } else {
            fn(false)
        }
    }
}

const getStyleFromControl = (
    control:
        | Wukong.DocumentProto.IVPublishModalNormalControl
        | Wukong.DocumentProto.IVPublishModalCollapseControl
        | null
        | undefined
): React.CSSProperties => {
    return {
        position: 'absolute',
        left: 0,
        right: 0,
        top: control?.top ?? undefined,
        height: control?.height ?? undefined,
    }
}

export const ChangeSelectListV2 = ({
    checkCount,
    allChangeCount,
    changes,
    filterText,
    control,
    variableSetTitle,
    styleTitle,
    componentTitle,
    setCollapse,
    hasContent,
    showDetailFn,
    variableSetToVariableChangeMap,
    variableSetToVariableHiddenMap,
}: ChangeSelectListProps) => {
    const command = useCommand()
    const collapse = Boolean(control?.expand)
    useAutoCollapseControl(hasContent, filterText || '', collapse, setCollapse)

    const indeterminate = checkCount > 0 && checkCount < allChangeCount
    const checkAll = checkCount === allChangeCount

    const debouncedIndeterminate = useDebounceValue(indeterminate)
    const debouncedCheckAll = useDebounceValue(checkAll)

    const onChangeItemChecked = (item: Wukong.DocumentProto.IVPublishModalChangeItem) => {
        const changeKey = item.changeKey
        if (isNil(changeKey)) {
            return
        }

        command.invokeBridge(
            CommitType.Noop,
            UpdateLibraryPublishModalChangeCheckedCommand,
            Wukong.DocumentProto.Arg_UpdateLibraryPublishModalChangeChecked.create({
                changeKey,
                checked: !item.checked,
            })
        )
    }

    const onToggleAllChange = () => {
        if (!debouncedCheckAll) {
            command.invokeBridge(
                CommitType.Noop,
                UpdateLibraryPublishModalAllChangesCheckedWasmCall,
                Wukong.DocumentProto.Arg_UpdateLibraryPublishModalAllChangesChecked.create({
                    allChecked: true,
                })
            )
        } else {
            command.invokeBridge(
                CommitType.Noop,
                UpdateLibraryPublishModalAllChangesCheckedWasmCall,
                Wukong.DocumentProto.Arg_UpdateLibraryPublishModalAllChangesChecked.create({
                    allChecked: false,
                })
            )
        }
    }

    const checkboxList = (
        <>
            {variableSetTitle?.visible && (
                <div style={getStyleFromControl(variableSetTitle)} className={styles.changesTitle}>
                    {translation('VariableSet')}
                </div>
            )}
            {componentTitle?.visible && (
                <div style={getStyleFromControl(componentTitle)} className={styles.changesTitle}>
                    {translation('Component')}
                </div>
            )}
            {styleTitle?.visible && (
                <div style={getStyleFromControl(styleTitle)} className={styles.changesTitle}>
                    {translation('Style')}
                </div>
            )}
            {changes.map((change) => (
                <ChangeSelectListItemV2
                    key={change.changeKey}
                    change={change}
                    onClick={onChangeItemChecked}
                    itemWrapperStyle={{ paddingLeft: 52 }}
                    showDetailFn={showDetailFn!}
                    variablesCount={variableSetToVariableChangeMap?.[change.nodeId]?.length}
                    hiddenCount={variableSetToVariableHiddenMap?.[change.nodeId]?.length}
                />
            ))}
        </>
    )

    return filterText ? (
        <>
            {control?.visible && (
                <WKCollapse
                    selectionMode="multiple"
                    selection={wrapCollapse(collapse)}
                    onSelectionChange={wrapSetCollapse(setCollapse)}
                    style={getStyleFromControl(control)}
                >
                    <WKCollapseItem
                        key={COLLAPSE_KEY}
                        value={COLLAPSE_KEY}
                        title={translation('Changes')}
                        headerClassName="px-22px"
                    />
                </WKCollapse>
            )}
            {checkboxList}
        </>
    ) : (
        <>
            {control?.visible && (
                <div className={styles.checkbox} style={getStyleFromControl(control)}>
                    <Checkbox
                        checked={debouncedCheckAll}
                        indeterminate={debouncedIndeterminate}
                        onChange={onToggleAllChange}
                        label={
                            <span className={classNames('wk-font-medium', styles.changeLable)}>
                                {translation('Changes')}{' '}
                                <span className="color-$wk-l2-label-color-gray-8 wk-font-regular">{` (${checkCount}/${allChangeCount})`}</span>
                            </span>
                        }
                    />
                </div>
            )}
            {checkboxList}
        </>
    )
}

const getChangeTooltipMessage = (change: Wukong.DocumentProto.IVPublishModalChangeItem) => {
    const isEnglish = isEnglishLanguage()
    if (isStyleNode(change.thumbnailData?.type)) {
        if (change.changeType === Wukong.DocumentProto.LibraryChangeType.LIBRARY_CHANGE_TYPE_MOVEMENT) {
            return translation('StylePublished', {
                file: isEnglish
                    ? change.docNameOfMovedFrom
                        ? change.docNameOfMovedFrom
                        : 'this file'
                    : change.docNameOfMovedFrom
                    ? `${change.docNameOfMovedFrom}`
                    : '原文件',
            })
        }
        if (change.docNameOfMovedTo) {
            return translation('StyleMoved', { file: change.docNameOfMovedTo })
        }
    } else {
        if (change.changeType === Wukong.DocumentProto.LibraryChangeType.LIBRARY_CHANGE_TYPE_MOVEMENT) {
            return translation('ComponentPublished', {
                file: isEnglish
                    ? change.docNameOfMovedFrom
                        ? change.docNameOfMovedFrom
                        : 'this file'
                    : change.docNameOfMovedFrom
                    ? `${change.docNameOfMovedFrom}`
                    : '原文件',
            })
        }
        if (change.docNameOfMovedTo) {
            return translation('ComponentMoved', { file: change.docNameOfMovedTo })
        }

        if (change.isVariantInsideMovedAway) {
            return `${translation('GcbIjj')}`
        }

        if (change.isVariantInsideMoveIn) {
            return `${translation('EBeFOz')}`
        }
    }

    return undefined
}
export const VariableHiddenList = (props: { list: Wukong.DocumentProto.IVPublishModalCommonItem[] }) => {
    const { list } = props
    const [collapse, setCollapse] = useState(false)
    return (
        <>
            <WKCollapse
                selectionMode="multiple"
                selection={wrapCollapse(collapse)}
                onSelectionChange={wrapSetCollapse(setCollapse)}
            >
                <WKCollapseItem
                    key={COLLAPSE_KEY}
                    value={COLLAPSE_KEY}
                    headerClassName="px-22px"
                    title={
                        <>
                            {translation('Hidden')}
                            {list.length > 0 && (
                                <span className="color-$wk-l2-label-color-gray-8 wk-font-regular">
                                    {' '}
                                    ({list.length})
                                </span>
                            )}
                        </>
                    }
                />
            </WKCollapse>
            {collapse && (
                <div className={classNames(styles.item, styles.wontPublish, styles.grayItem, styles.noHover)}>
                    {translation('VarWontBePublished')}
                </div>
            )}
            {collapse &&
                list.map((item) => (
                    <PublishHiddenMenuWrapper
                        hasLocal={item.hasLocal}
                        key={item.nodeId}
                        nodeId={item.nodeId}
                        publishHidden={item.publishHidden}
                    >
                        <div
                            className={classNames(styles.item, styles.hiddenItem, styles.wontPublish, styles.grayItem)}
                        >
                            <div className={styles.itemIcon}></div>
                            <div className={styles.itemThumbail}>
                                <LibraryThumbnailImage thumbnailData={item.thumbnailData} />
                            </div>
                            <div className={`${styles.itemName} truncate`}>{item.name}</div>
                        </div>
                    </PublishHiddenMenuWrapper>
                ))}
        </>
    )
}

export const VariableChangeListItem = (props: { change: Wukong.DocumentProto.IVPublishModalChangeItem }) => {
    const { change } = props
    const { hasLocal, nodeId, publishHidden, changeType, name, thumbnailData } = change
    const tooltipMessage = getChangeTooltipMessage(change)

    return (
        <PublishHiddenMenuWrapper hasLocal={hasLocal} nodeId={nodeId} publishHidden={publishHidden}>
            <div className={classNames(styles.item)} data-testid={LibraryTestId.PublishModal.VariableDetail}>
                <div className={styles.itemThumbail}>
                    <LibraryThumbnailImage thumbnailData={thumbnailData} />
                </div>
                <div className={`${styles.itemName} truncate`}>{name}</div>
                <div className={classNames(styles.itemMark, 'flex items-center')}>
                    {!!tooltipMessage && (
                        <Tooltip title={tooltipMessage}>
                            <MonoIconCommonInfoLine16 className="mr-2 color-$wk-l2-label-color-gray-13" />
                        </Tooltip>
                    )}
                    {
                        LibraryChangeTypeLabel[
                            changeType ?? Wukong.DocumentProto.LibraryChangeType.LIBRARY_CHANGE_TYPE_ADD
                        ]
                    }
                </div>
            </div>
        </PublishHiddenMenuWrapper>
    )
}

export const ChangeSelectListItemV2 = (props: {
    change: Wukong.DocumentProto.IVPublishModalChangeItem
    onClick?: (item: Wukong.DocumentProto.IVPublishModalChangeItem) => void
    itemWrapperStyle?: React.CSSProperties
    showDetailFn: (change: Wukong.DocumentProto.IVPublishModalChangeItem) => void
    variablesCount?: number
    hiddenCount?: number
}) => {
    const { change, onClick, itemWrapperStyle, showDetailFn, variablesCount, hiddenCount } = props
    const { hasLocal, nodeId, publishHidden, changeType, name, checked, thumbnailData, control } = change
    const tooltipMessage = getChangeTooltipMessage(change)

    return (
        <PublishHiddenMenuWrapper
            hasLocal={hasLocal}
            nodeId={nodeId}
            publishHidden={publishHidden}
            style={getStyleFromControl(control)}
        >
            <div
                onClick={() => onClick?.(change)}
                className={classNames(styles.item)}
                data-testid={checked ? LibraryTestId.PublishModal.SelectedPublishItem : undefined}
                style={itemWrapperStyle}
            >
                <div className={styles.itemCheckbox}>
                    <Checkbox checked={Boolean(checked)} />
                </div>
                <div className={styles.itemThumbail}>
                    <LibraryThumbnailImage thumbnailData={thumbnailData} className={styles.itemThumbailImage} />
                </div>
                <div className={`${styles.itemName} truncate`}>{name}</div>
                <div
                    className={classNames(
                        styles.itemMark,
                        'flex items-center',
                        changeType === Wukong.DocumentProto.LibraryChangeType.LIBRARY_CHANGE_TYPE_REMOVE &&
                            'color-$wk-l2-label-color-gray-6'
                    )}
                >
                    {!!tooltipMessage && (
                        <Tooltip title={tooltipMessage}>
                            <MonoIconCommonInfoLine16 className="mr-2 color-$wk-l2-label-color-gray-13" />
                        </Tooltip>
                    )}
                    {
                        LibraryChangeTypeLabel[
                            changeType ?? Wukong.DocumentProto.LibraryChangeType.LIBRARY_CHANGE_TYPE_ADD
                        ]
                    }
                </div>
                {change.thumbnailData?.type === Wukong.DocumentProto.NodeType.NODE_TYPE_VARIABLE_SET &&
                    (variablesCount || hiddenCount) &&
                    change.changeType !== Wukong.DocumentProto.LibraryChangeType.LIBRARY_CHANGE_TYPE_REMOVE && (
                        <div
                            className={styles.collectionContainer}
                            data-testid={LibraryTestId.PublishModal.VariableSetDetail}
                            onClick={(e) => {
                                e.stopPropagation()
                                showDetailFn(change)
                            }}
                        >
                            <div className={styles.collectionWrapper}>
                                {variablesCount
                                    ? translation('VariableUpdate', {
                                          count: variablesCount + '',
                                          suffix: variablesCount !== 1 ? 's' : '',
                                      })
                                    : translation('VariableHidden', {
                                          count: hiddenCount + '',
                                          suffix: hiddenCount !== 1 ? 's' : '',
                                      })}
                            </div>
                            <WKIconButton
                                size="small"
                                icon={<MonoIconCommonArrowRight16 className="color-$wk-l2-label-color-gray-8" />}
                                className={`shrink-0 ${styles.noHover}`}
                            />
                        </div>
                    )}
            </div>
        </PublishHiddenMenuWrapper>
    )
}

export const ChangeSelectListItem = (props: {
    change: Wukong.DocumentProto.IVPublishModalChangeItem
    onClick: (item: Wukong.DocumentProto.IVPublishModalChangeItem) => void
}) => {
    const { change, onClick } = props
    const { hasLocal, nodeId, publishHidden, changeType, name, checked, thumbnailData, control } = change
    const tooltipMessage = getChangeTooltipMessage(change)

    return (
        <PublishHiddenMenuWrapper
            hasLocal={hasLocal}
            nodeId={nodeId}
            publishHidden={publishHidden}
            style={getStyleFromControl(control)}
        >
            <div
                onClick={() => onClick(change)}
                className={classNames(styles.item)}
                data-testid={checked ? LibraryTestId.PublishModal.SelectedPublishItem : undefined}
            >
                <div className={styles.itemCheckbox}>
                    <Checkbox checked={Boolean(checked)} />
                </div>
                <div className={styles.itemThumbail}>
                    <LibraryThumbnailImage thumbnailData={thumbnailData} className={styles.itemThumbailImage} />
                </div>
                <div className={`${styles.itemName} truncate`}>{name}</div>
                <div className={classNames(styles.itemMark, 'flex items-center')}>
                    {!!tooltipMessage && (
                        <Tooltip title={tooltipMessage}>
                            <MonoIconCommonInfoLine16 className="mr-2 color-$wk-l2-label-color-gray-13" />
                        </Tooltip>
                    )}
                    {
                        LibraryChangeTypeLabel[
                            changeType ?? Wukong.DocumentProto.LibraryChangeType.LIBRARY_CHANGE_TYPE_ADD
                        ]
                    }
                </div>
            </div>
        </PublishHiddenMenuWrapper>
    )
}

interface UnchangeListProps {
    setCollapse: (val: boolean) => void
    list: Wukong.DocumentProto.IVPublishModalCommonItem[]
    filterText?: string
    control: Wukong.DocumentProto.IVPublishModalCollapseControl | null | undefined
    hasContent: boolean | null | undefined
    showDetailFn?: (item: Wukong.DocumentProto.IVPublishModalCommonItem) => void
    variableSetToVariableHiddenMap?: Record<string, Wukong.DocumentProto.IVPublishModalCommonItem[]>
}

export const UnchangeListV2 = ({
    list,
    filterText,
    control,
    setCollapse,
    hasContent,
    showDetailFn,
    variableSetToVariableHiddenMap,
}: UnchangeListProps) => {
    const collapse = Boolean(control?.expand)
    useAutoCollapseControl(hasContent, filterText || '', collapse, setCollapse)

    const renderItem = (item: Wukong.DocumentProto.IVPublishModalCommonItem) => {
        const { nodeId, publishHidden, thumbnailData } = item

        return (
            <PublishHiddenMenuWrapper
                hasLocal={item.hasLocal}
                key={nodeId}
                nodeId={item.nodeId}
                publishHidden={Boolean(publishHidden)}
                style={getStyleFromControl(item.control)}
            >
                <div
                    className={classNames(styles.item, styles.unchangeItem)}
                    data-testid={LibraryTestId.PublishModal.UnchangeItem}
                >
                    <div className={styles.itemIcon}></div>
                    <div className={styles.itemThumbail}>
                        <LibraryThumbnailImage className={styles.itemThumbailImage} thumbnailData={thumbnailData} />
                    </div>
                    <div className={`${styles.itemName} truncate`}>{item.name}</div>
                    {item.thumbnailData?.type === Wukong.DocumentProto.NodeType.NODE_TYPE_VARIABLE_SET &&
                        variableSetToVariableHiddenMap?.[nodeId]?.length && (
                            <div
                                className={styles.collectionContainer}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    showDetailFn?.(item)
                                }}
                            >
                                <div className={styles.collectionWrapper}>
                                    {translation('VariableHidden', {
                                        count: variableSetToVariableHiddenMap[nodeId].length + '',
                                        suffix: variableSetToVariableHiddenMap[nodeId].length !== 1 ? 's' : '',
                                    })}
                                </div>
                                <WKIconButton
                                    size="small"
                                    icon={<MonoIconCommonArrowRight16 className="color-$wk-l2-label-color-gray-8" />}
                                    className={`shrink-0 ${styles.noHover}`}
                                />
                            </div>
                        )}
                </div>
            </PublishHiddenMenuWrapper>
        )
    }

    return (
        <>
            {control?.visible && (
                <WKCollapse
                    selectionMode="multiple"
                    selection={wrapCollapse(collapse)}
                    onSelectionChange={wrapSetCollapse(setCollapse)}
                    style={getStyleFromControl(control)}
                >
                    <WKCollapseItem
                        key={COLLAPSE_KEY}
                        value={COLLAPSE_KEY}
                        title={translation('Unchanged')}
                        headerClassName="px-22px"
                    />
                </WKCollapse>
            )}
            {list.map(renderItem)}
        </>
    )
}

interface HiddenListProps {
    setCollapse: (val: boolean) => void
    list: Wukong.DocumentProto.IVPublishModalCommonItem[]
    filterText?: string
    control: Wukong.DocumentProto.IVPublishModalCollapseControl | null | undefined
    tipControl: Wukong.DocumentProto.IVPublishModalNormalControl | null | undefined
    hasContent: boolean | null | undefined
}

export const HiddenList = ({ list, filterText, control, tipControl, setCollapse, hasContent }: HiddenListProps) => {
    const collapse = Boolean(control?.expand)
    useAutoCollapseControl(hasContent, filterText || '', collapse, setCollapse)

    return (
        <>
            {control?.visible && (
                <WKCollapse
                    selectionMode="multiple"
                    selection={wrapCollapse(collapse)}
                    onSelectionChange={wrapSetCollapse(setCollapse)}
                    style={getStyleFromControl(control)}
                >
                    <WKCollapseItem
                        key={COLLAPSE_KEY}
                        value={COLLAPSE_KEY}
                        title={translation('Hidden')}
                        headerClassName="px-22px"
                    />
                </WKCollapse>
            )}
            {tipControl?.visible && (
                <div
                    className={`${styles.item} ${styles.grayItem} ${styles.noHover}`}
                    style={{ ...getStyleFromControl(tipControl), paddingLeft: 52 }}
                >
                    {translation("TheseWon'tBe")}
                </div>
            )}
            {collapse &&
                list.map((item) => (
                    <PublishHiddenMenuWrapper
                        hasLocal={item.hasLocal}
                        key={item.nodeId}
                        nodeId={item.nodeId}
                        publishHidden={item.publishHidden}
                        style={getStyleFromControl(item.control)}
                    >
                        <div
                            className={classNames(styles.item, styles.hiddenItem, styles.grayItem)}
                            style={{ height: item.control?.height ?? undefined, top: item.control?.top ?? undefined }}
                        >
                            <div className={styles.itemIcon}></div>
                            <div className={styles.itemThumbail}>
                                <LibraryThumbnailImage
                                    thumbnailData={item.thumbnailData}
                                    className={styles.itemThumbailImage}
                                />
                            </div>
                            <div className={`${styles.itemName} truncate`}>{item.name}</div>
                        </div>
                    </PublishHiddenMenuWrapper>
                ))}
        </>
    )
}

export const VariableDetail = (props: {
    variableSet: Wukong.DocumentProto.IVPublishModalChangeItem | Wukong.DocumentProto.IVPublishModalCommonItem
    onBack: () => void
    variableChanges: Wukong.DocumentProto.IVPublishModalChangeItem[]
    variableHiddens: Wukong.DocumentProto.IVPublishModalCommonItem[]
}) => {
    const { variableSet, onBack, variableChanges, variableHiddens } = props
    return (
        <div className={styles.variableDetailContainer}>
            <div className={styles.variableDetailTitle}>
                <div data-testid={LibraryTestId.PublishModal.BackBtnFromVariableDetail} onClick={onBack}>
                    <WKIconButton
                        size="small"
                        icon={<MonoIconCommonArrowLeft16 className="color-$wk-l2-label-color-gray-11" />}
                    />
                </div>
                <div className={`${styles.variableName} truncate`}>{variableSet.name}</div>
                <div className={styles.variableCount}>
                    {translation('VariableUpdate', {
                        count: variableChanges.length + '',
                        suffix: variableChanges.length !== 1 ? 's' : '',
                    })}
                </div>
            </div>
            <div className={styles.variableDetailContent}>
                {variableChanges.map((variableChange) => (
                    <VariableChangeListItem key={variableChange.changeKey} change={variableChange} />
                ))}
                {variableHiddens.length > 0 && <VariableHiddenList list={variableHiddens} />}
            </div>
        </div>
    )
}

export interface PublishHiddenMenuWrapperProps {
    hasLocal: boolean | undefined | null
    nodeId: string | undefined | null
    publishHidden: boolean | undefined | null
    children: React.ReactNode | undefined
    style?: React.CSSProperties
}

export const InvalidList = (props: {
    setCollapse: (val: boolean) => void
    displayList: Wukong.DocumentProto.IVPublishModalInvalidItem[]
    filterText?: string
    onClickAim: (targetId: NodeId) => void
    control: Wukong.DocumentProto.IVPublishModalCollapseControl | null | undefined
    hasContent: boolean | null | undefined
}) => {
    const { displayList, filterText, setCollapse, control, hasContent } = props
    const collapse = Boolean(control?.expand)
    useAutoCollapseControl(hasContent, filterText || '', collapse, setCollapse)

    const renderItem = (item: Wukong.DocumentProto.IVPublishModalInvalidItem) => {
        const needTargetSourceNode = [
            Wukong.DocumentProto.PublishMixinInvalidChangeType
                .PUBLISH_MIXIN_INVALID_CHANGE_TYPE_VARIANT_PROPERTY_CONFLICT,
            Wukong.DocumentProto.PublishMixinInvalidChangeType
                .PUBLISH_MIXIN_INVALID_CHANGE_TYPE_COMPONENT_PROPERTY_CONFLICT,
            Wukong.DocumentProto.PublishMixinInvalidChangeType
                .PUBLISH_MIXIN_INVALID_CHANGE_TYPE_UNUSED_COMPONENT_PROPERTY,
        ].includes(item.invalidType)

        const getInvalidReason = () => {
            switch (item.invalidType) {
                case Wukong.DocumentProto.PublishMixinInvalidChangeType
                    .PUBLISH_MIXIN_INVALID_CHANGE_TYPE_VARIANT_PROPERTY_CONFLICT:
                case Wukong.DocumentProto.PublishMixinInvalidChangeType
                    .PUBLISH_MIXIN_INVALID_CHANGE_TYPE_COMPONENT_PROPERTY_CONFLICT:
                    return translation('ConflictingPropertyValues')
                case Wukong.DocumentProto.PublishMixinInvalidChangeType
                    .PUBLISH_MIXIN_INVALID_CHANGE_TYPE_UNUSED_COMPONENT_PROPERTY:
                    return translation('UnusedProperties')
                case Wukong.DocumentProto.PublishMixinInvalidChangeType
                    .PUBLISH_MIXIN_INVALID_CHANGE_TYPE_TEXT_MISSING_FONT:
                    return translation('MissingFonts')
            }
        }

        return (
            <PublishHiddenMenuWrapper
                hasLocal={item.hasLocal}
                key={item.nodeId}
                nodeId={item.nodeId}
                publishHidden={item.publishHidden}
                style={getStyleFromControl(item.control)}
            >
                <div className={styles.item}>
                    {needTargetSourceNode && (
                        <WKIconButton
                            type="primary"
                            icon={<MonoIconPanelTarget16 />}
                            className={styles.itemIcon}
                            onClick={() => {
                                const nodeId = item.nodeId
                                if (nodeId) {
                                    props.onClickAim(nodeId)
                                }
                            }}
                        />
                    )}
                    {!needTargetSourceNode && <div className={styles.itemIcon} />}
                    <div className={styles.itemThumbail}>
                        <LibraryThumbnailImage
                            className={styles.itemThumbailImage}
                            thumbnailData={item.thumbnailData}
                            fontMissing={
                                item.invalidType ===
                                Wukong.DocumentProto.PublishMixinInvalidChangeType
                                    .PUBLISH_MIXIN_INVALID_CHANGE_TYPE_TEXT_MISSING_FONT
                            }
                        />
                    </div>
                    <div className={`${styles.itemName} truncate`}>{item.name}</div>
                    <div className={styles.itemMark}>{getInvalidReason()}</div>
                </div>
            </PublishHiddenMenuWrapper>
        )
    }

    return (
        <>
            {control?.visible && (
                <WKCollapse
                    selectionMode="multiple"
                    selection={wrapCollapse(collapse)}
                    onSelectionChange={wrapSetCollapse(setCollapse)}
                    style={getStyleFromControl(control)}
                >
                    <WKCollapseItem
                        key={COLLAPSE_KEY}
                        value={COLLAPSE_KEY}
                        title={translation('InvalidAssets')}
                        headerClassName="px-22px"
                    />
                </WKCollapse>
            )}
            {collapse && displayList.map(renderItem)}
        </>
    )
}

export const PublishHiddenMenuWrapper: React.FC<PublishHiddenMenuWrapperProps> = ({
    hasLocal,
    nodeId,
    publishHidden = false,
    children,
    style,
}: PropsWithChildren<PublishHiddenMenuWrapperProps>) => {
    const command = useCommand()
    const menuRef = useRef<DropdownNoTriggerSingleLevelRef>(null)
    const [triggerRect, setTriggerRect] = useState({ top: 0, left: 0, right: 0, bottom: 0 })

    const handleClick = () => {
        if (!nodeId || !hasLocal) return

        command.DEPRECATED_invokeBridge(UpdatePublishHiddenCommand, {
            id: nodeId,
            publishHidden: !publishHidden,
        })
        command.commitUndo()
    }

    if (!hasLocal || !nodeId) return <div style={style}>{children}</div>

    return (
        <>
            <div
                onMouseDown={(event) => {
                    const { button, ctrlKey } = event
                    if (button === 2 || (button == 0 && ctrlKey && !isWindows())) {
                        event.stopPropagation()
                        const { clientX, clientY } = event
                        setTriggerRect({ top: clientY, left: clientX, right: clientX, bottom: clientY })
                        menuRef.current?.open()
                    }
                }}
                onContextMenu={(event) => {
                    event.preventDefault()
                    event.stopPropagation()
                }}
                style={style}
            >
                {children}
            </div>
            <DropdownV2.NoTriggerSingleLevel triggerRect={triggerRect} ref={menuRef} onChange={handleClick}>
                <DropdownV2.NoTriggerSingleLevel.Option value="publishHidden">
                    {publishHidden ? translation('ShowWhenPublishing') : translation('HideWhenPublishing')}
                </DropdownV2.NoTriggerSingleLevel.Option>
            </DropdownV2.NoTriggerSingleLevel>
        </>
    )
}

export function useAutoCollapseControl(
    hasContent: boolean | null | undefined,
    filterText: string,
    currentCollapse: boolean,
    setCollapse: (val: boolean) => void
) {
    const collapseBeforeSearch = useRef<boolean>()
    const search = !!filterText
    const prevSearch = usePrevious(search)

    useEffect(() => {
        if (!prevSearch && search) {
            // 搜索前，存储当前 collapse 状态
            collapseBeforeSearch.current = currentCollapse
        } else if (prevSearch && !search && currentCollapse && collapseBeforeSearch.current === false) {
            // 结束搜索后，还原搜索前的 collapse 状态
            setCollapse(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, prevSearch])

    useEffect(() => {
        if (!!filterText && hasContent && !currentCollapse) {
            setCollapse(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterText])
}

function useDebounceValue<T>(value: T, wait = 30) {
    const [debounced, setDebounced] = useState(value)
    useDebounce(() => setDebounced(value), wait, [value])

    return debounced
}
