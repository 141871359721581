import { Wukong } from '@wukong/bridge-proto'
import { useCallback, useMemo, useRef, useState } from 'react'
import {
    Checkbox,
    DraggablePopupV2,
    MonoIconPanelOverlayBottomCenter16,
    MonoIconPanelOverlayCentered16,
    MonoIconPanelOverlayTopLeft16,
    Position,
    Select,
    Tooltip,
} from '../../../../../../../ui-lib/src'
import { SolidPaint } from '../../../../../document/node/node'
import { SelectIconGroup } from '../../../atom/button/select-button-group'
import { PureBlock } from '../../../atom/color-block/pure'
import { SingleGrid } from '../../../atom/grid/single-grid'
import { InputOptionsForUndoSquash } from '../../../atom/inputs/components/formatted-input'
import { HexInput } from '../../../atom/inputs/hex-input'
import { ScrubbableInputPercent } from '../../../atom/inputs/scrubbable-input-percent'
import { Value } from '../../../atom/inputs/utils/type'
import { useRenderColorSpace } from '../../../color-profile'
import { BlendContent } from '../../../design/blend/blend-content'
import { hex2rgb, rgb2hex } from '../../../design/blend/color-picker/utils/color-translate'
import { InteractionTypeWithMixed } from '../../prototype-interaction/constants'
import styles from './overlay-settings.module.less'
import { translation } from './overlay-settings.translation'

import InteractionType = Wukong.DocumentProto.InteractionType
import OverlayPositionType = Wukong.DocumentProto.OverlayPositionType
import OverlayBackgroundInteraction = Wukong.DocumentProto.OverlayBackgroundInteraction
import OverlayBackgroundType = Wukong.DocumentProto.OverlayBackgroundType
import OverlayBackgroundAppearance = Wukong.DocumentProto.IOverlayBackgroundAppearance

export const OverlayPositionType2Label: Record<OverlayPositionType, string> = {
    [OverlayPositionType.OVERLAY_POSITION_TYPE_CENTER]: translation('Center'),
    [OverlayPositionType.OVERLAY_POSITION_TYPE_TOP_LEFT]: translation('TopLeft'),
    [OverlayPositionType.OVERLAY_POSITION_TYPE_TOP_CENTER]: translation('TopCenter'),
    [OverlayPositionType.OVERLAY_POSITION_TYPE_TOP_RIGHT]: translation('TopRight'),
    [OverlayPositionType.OVERLAY_POSITION_TYPE_BOTTOM_LEFT]: translation('BottomLeft'),
    [OverlayPositionType.OVERLAY_POSITION_TYPE_BOTTOM_CENTER]: translation('BottomCenter'),
    [OverlayPositionType.OVERLAY_POSITION_TYPE_BOTTOM_RIGHT]: translation('BottomRight'),
    [OverlayPositionType.OVERLAY_POSITION_TYPE_MANUAL]: translation('Manual'),
}

const positionTypeMenus = Object.values(OverlayPositionType).map((item) => ({
    value: item,
    label: OverlayPositionType2Label[item as OverlayPositionType],
    splitLineTop: item === OverlayPositionType.OVERLAY_POSITION_TYPE_MANUAL,
}))

const iconGroupOptions = [
    {
        key: OverlayPositionType.OVERLAY_POSITION_TYPE_CENTER,
        icon: <MonoIconPanelOverlayCentered16 />,
        testId: 'overlay-position-type-select-icon-center',
    },
    {
        key: OverlayPositionType.OVERLAY_POSITION_TYPE_TOP_LEFT,
        icon: <MonoIconPanelOverlayTopLeft16 />,
        testId: 'overlay-position-type-select-icon-top-left',
    },
    {
        key: OverlayPositionType.OVERLAY_POSITION_TYPE_BOTTOM_CENTER,
        icon: <MonoIconPanelOverlayBottomCenter16 />,
        testId: 'overlay-position-type-select-icon-bottom-center',
    },
]

export const InteractionActionOverlaySettings = ({
    type,
    positionType,
    backgroundInteraction,
    backgroundAppearance,
    onChangePositionType,
    onChangeBackgroundInteraction,
    onChangeBackgroundAppearance,
}: {
    type: InteractionTypeWithMixed
    positionType: OverlayPositionType
    backgroundInteraction: OverlayBackgroundInteraction
    backgroundAppearance: OverlayBackgroundAppearance
    onChangePositionType: (positionType: OverlayPositionType) => void
    onChangeBackgroundInteraction: (backgroundInteraction: OverlayBackgroundInteraction) => void
    onChangeBackgroundAppearance: (
        backgroundAppearance: OverlayBackgroundAppearance,
        options?: InputOptionsForUndoSquash
    ) => void
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [position, setPosition] = useState<Position>()
    const bgColorRef = useRef<HTMLDivElement>(null)
    const isCheckedBackgroundInteraction =
        backgroundInteraction === OverlayBackgroundInteraction.OVERLAY_BACKGROUND_INTERACTION_CLOSE_ON_CLICK_OUTSIDE
    const isCheckedBackgroundType =
        backgroundAppearance.overlayBackgroundType === OverlayBackgroundType.OVERLAY_BACKGROUND_TYPE_SOLID_COLOR
    const colorSpace = useRenderColorSpace()
    const paint: SolidPaint = useMemo(() => {
        const color = backgroundAppearance.backgroundColor
        return {
            type: Wukong.DocumentProto.PaintType.PAINT_TYPE_SOLID_PAINT,
            color,
            visible: true,
            opacity: color.a / 255,
        }
    }, [backgroundAppearance])

    const onChangeInteraction = useCallback(
        (v: boolean) => {
            onChangeBackgroundInteraction(Number(v))
        },
        [onChangeBackgroundInteraction]
    )

    const onChangeType = useCallback(
        (v: boolean) => {
            onChangeBackgroundAppearance({
                overlayBackgroundType: Number(v),
                backgroundColor: backgroundAppearance.backgroundColor,
            })
        },
        [backgroundAppearance, onChangeBackgroundAppearance]
    )

    const onChangeColor = useCallback(
        (value: Wukong.DocumentProto.IRGB, options?: InputOptionsForUndoSquash) => {
            onChangeBackgroundAppearance(
                {
                    overlayBackgroundType: backgroundAppearance.overlayBackgroundType,
                    backgroundColor: { ...value, a: backgroundAppearance.backgroundColor.a },
                },
                options
            )
        },
        [backgroundAppearance, onChangeBackgroundAppearance]
    )

    const onChangeOpacity = useCallback(
        (value: Value, options?: InputOptionsForUndoSquash) => {
            if (typeof value === 'number') {
                onChangeBackgroundAppearance(
                    {
                        overlayBackgroundType: backgroundAppearance.overlayBackgroundType,
                        backgroundColor: { ...backgroundAppearance.backgroundColor, a: (value / 100) * 255 },
                    },
                    options
                )
            }
        },
        [backgroundAppearance, onChangeBackgroundAppearance]
    )

    const onClose = useCallback(() => {
        setIsOpen(false)
    }, [])

    const onClickColorBlock = useCallback(() => {
        if (bgColorRef.current) {
            const { top, left } = bgColorRef.current.getBoundingClientRect()
            setPosition({ top, left: left - 40 })
        }
        setIsOpen((v) => !v)
    }, [])

    return (
        <div className="pl-10 pr-4">
            <div className="py-2 text-gray wk-font-medium">{translation('OverlaySettings')}</div>
            <div className="flex justify-between items-center">
                <Select.MinimalSingleLevel
                    value={positionType}
                    label={<div className="py-2">{OverlayPositionType2Label[positionType]}</div>}
                    onChange={onChangePositionType}
                    dataTestIds={{
                        triggerFocus: 'overlay-position-type-select-trigger',
                    }}
                >
                    {positionTypeMenus.map((item, index) => {
                        return (
                            <Select.MinimalSingleLevel.Option
                                key={index}
                                value={item.value}
                                splitLineTop={item.splitLineTop}
                            >
                                {item.label}
                            </Select.MinimalSingleLevel.Option>
                        )
                    })}
                </Select.MinimalSingleLevel>
                <div className="w-68px h-6" data-testid="overlay-position-type-select-icon-group">
                    <SelectIconGroup
                        optionValue={iconGroupOptions.map((v) => v.key)}
                        onClickIcon={onChangePositionType}
                    >
                        {iconGroupOptions.map((item) => (
                            <Tooltip key={item.key} title={OverlayPositionType2Label[item.key]}>
                                <SelectIconGroup.Item
                                    value={item.key}
                                    selected={positionType === item.key}
                                    icon={item.icon}
                                    dataTestId={item.testId}
                                />
                            </Tooltip>
                        ))}
                    </SelectIconGroup>
                </div>
            </div>
            {type !== InteractionType.INTERACTION_TYPE_ON_HOVER &&
                type !== InteractionType.INTERACTION_TYPE_ON_PRESS && (
                    <Checkbox
                        containerTestId="overlay-background-interaction-checkbox"
                        checked={isCheckedBackgroundInteraction}
                        className="py-2 w-fit"
                        label={translation('CloseOutside')}
                        onChange={onChangeInteraction}
                    />
                )}
            <Checkbox
                containerTestId="overlay-background-type-checkbox"
                checked={isCheckedBackgroundType}
                className="py-2 w-fit"
                label={translation('AddBackground')}
                onChange={onChangeType}
            />
            {isCheckedBackgroundType && (
                <>
                    <SingleGrid className={styles.bgColor} ref={bgColorRef} testId="overlay-background-color">
                        <SingleGrid.Item start={1} span={7} className="bg-$wk-l2-fill-color-gray-1 rounded-l-3px">
                            <PureBlock
                                rgb={paint.color}
                                opacity={paint.opacity}
                                colorSpace={colorSpace}
                                onClick={onClickColorBlock}
                                className={styles.colorBlock}
                                dataTestId="overlay-background-color-block"
                            />
                        </SingleGrid.Item>
                        <SingleGrid.Item start={8} span={18} className="bg-$wk-l2-fill-color-gray-1">
                            <HexInput
                                value={rgb2hex(paint.color.r, paint.color.g, paint.color.b)}
                                onChange={(v) => onChangeColor(hex2rgb(String(v)))}
                                testId="overlay-background-color-hex"
                            />
                        </SingleGrid.Item>
                        <SingleGrid.Item start={26} span={14} className="bg-$wk-l2-fill-color-gray-1 rounded-r-3px">
                            <ScrubbableInputPercent
                                value={paint.opacity}
                                onChange={onChangeOpacity}
                                min={0}
                                max={100}
                                testId="overlay-background-color-opacity"
                            />
                        </SingleGrid.Item>
                    </SingleGrid>
                    <DraggablePopupV2
                        visible={isOpen}
                        position={position}
                        onCancel={onClose}
                        header={translation('Background')}
                        styleType="editor"
                        footer={null}
                        positionRightBase
                        bodyClassName="p-0"
                        testId="overlay-background-color-popup"
                        overflowVisible
                    >
                        <BlendContent
                            data={paint}
                            colorSpace={colorSpace}
                            onChangeColor={onChangeColor}
                            onChangeOpacity={(v) => onChangeOpacity(v * 100)}
                            enterClose={onClose}
                        />
                    </DraggablePopupV2>
                </>
            )}
        </div>
    )
}
