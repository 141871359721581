import { Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { useRef, useState } from 'react'
import {
    DropdownV2,
    MonoIconCommonEnterRight16 as IconCollapse,
    MonoIconCommonEnterDown16 as IconExpand,
    InputV2,
    Scrollbar,
} from '../../../../../ui-lib/src'
import { useAppContext } from '../../../main/app-context'
import { ALL_GROUP_LABEL } from '../../../main/service/local-variable-editor-service'
import { useViewState } from '../../../view-state-bridge/use-view-state'
import { useLocalVariableDragContext, useLocalVariableGroupDragContext } from './local-variable-drag-context'
import styles from './local-variable-editor-sidebar.module.less'
import { translation } from './local-variable-editor-sidebar.translation'

function useGroupViewState() {
    return useViewState('localVariableGroup')
}
function useVariablesInCollectionCount() {
    return useGroupViewState()?.variablesInCollectionCount ?? 0
}
function useGroups() {
    return useGroupViewState()?.groups ?? []
}
function useSelectedGroups() {
    return useGroups().filter((g) => g.selected)
}
function useSelectedGroupKeys() {
    return useSelectedGroups().map((g) => g.key)
}

// 侧边栏每个分组
function SidebarGroup({ group }: { group: Wukong.DocumentProto.ILocalVariableGroupItem }) {
    const service = useAppContext().variableService.localVariableEditorService
    // 选中的分组
    const selectedGroupKeys = useSelectedGroupKeys()
    // 是否多选
    const isMultiSelected = selectedGroupKeys.length > 1
    // 重命名编辑状态
    const [isEditing, setIsEditing] = useState(false)
    // 右键菜单状态
    const [contextMenuState, setContextMenuState] = useState<{ x: number; y: number } | null>(null)
    // 拖拽到的分组
    const { dragToGroupHighlight, dragVariableEnterGroup, dragVariableLeaveGroup } = useLocalVariableDragContext()
    // 分组之间拖拽
    const { dragTarget, dragStart, dragEnterGroup, dragLeaveGroup } = useLocalVariableGroupDragContext()
    // 分组 DOM 元素
    const divRef = useRef<HTMLDivElement>(null)

    return (
        <>
            <div
                key={group.key}
                ref={divRef}
                style={{
                    paddingLeft: group.indent * 24,
                }}
                className={classNames(
                    styles['sidebar-group'],
                    styles['group-item'],
                    selectedGroupKeys.includes(group.key) && styles.selected,
                    dragToGroupHighlight === group.key && styles.highlight,
                    dragTarget.display === 'group' && dragTarget.groupKey === group.key && styles.highlight
                )}
                onMouseDown={(e) => {
                    service.mouseDownToSelectGroup(group.key, e)
                    if (divRef.current) {
                        dragStart(e, group.key, divRef.current)
                    }
                }}
                onMouseEnter={() => {
                    dragVariableEnterGroup(group.key)
                    if (divRef.current) {
                        dragEnterGroup(group.key, divRef.current)
                    }
                }}
                onMouseLeave={() => {
                    dragVariableLeaveGroup()
                    dragLeaveGroup()
                }}
                onClick={(e) => {
                    service.clickToSelectGroup(group.key, e)
                }}
                onDoubleClick={() => {
                    setIsEditing(true)
                }}
                onContextMenu={(e) => {
                    setContextMenuState({ x: e.clientX, y: e.clientY })
                    e.preventDefault()
                }}
                data-testid="local-variable-editor-sidebar-group"
                data-selected={selectedGroupKeys.includes(group.key)}
                data-indent={group.indent}
            >
                <div
                    className={styles['group-prefix']}
                    data-testid="local-variable-editor-sidebar-group-icon"
                    onClick={() =>
                        group.collapsed ? service.expandGroup(group.key) : service.collapseGroup(group.key)
                    }
                >
                    {group.hasSubGroups && (
                        <div className={styles['group-pre-icon']}>
                            {group.collapsed ? (
                                <IconCollapse data-testid="local-variable-editor-sidebar-group-icon-collapse" />
                            ) : (
                                <IconExpand data-testid="local-variable-editor-sidebar-group-icon-expand" />
                            )}
                        </div>
                    )}
                </div>
                <div
                    style={{
                        overflow: 'hidden',
                        flex: 1,
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {isEditing ? (
                        <InputV2
                            value={group.name}
                            onBlur={(e) => {
                                service.renameGroup(group, e.target.value)
                                setIsEditing(false)
                            }}
                            onKeyDown={(e) => {
                                e.stopPropagation()
                            }}
                            autoFocus
                            dataTestIds={{
                                input: 'local-variable-editor-sidebar-group-name-input',
                            }}
                        />
                    ) : (
                        <span data-testid="local-variable-editor-sidebar-group-name">{group.name}</span>
                    )}
                </div>
            </div>
            {/* 右键菜单 */}
            {contextMenuState ? (
                <DropdownV2.NoTriggerSingleLevel
                    isOpenState={true}
                    onClose={() => setContextMenuState(null)}
                    onChange={(value) => {
                        switch (value) {
                            case translation('复制分组'): {
                                service.duplicateSelectedGroups()
                                return
                            }
                            case translation('取消分组'): {
                                service.ungroupSelectedGroups()
                                return
                            }
                            case translation('删除分组'): {
                                service.deleteSelectedGroups()
                                return
                            }
                        }
                    }}
                    triggerRect={{
                        left: contextMenuState.x,
                        right: contextMenuState.x,
                        top: contextMenuState.y,
                        bottom: contextMenuState.y,
                    }}
                >
                    <DropdownV2.NoTriggerSingleLevel.Option
                        value={translation('复制分组')}
                        splitLineBottom={selectedGroupKeys.length > 1}
                    >
                        <span data-testid="local-variable-editor-sidebar-group-context-menu-duplicate">
                            {isMultiSelected ? translation('复制分组复数') : translation('复制分组')}
                        </span>
                    </DropdownV2.NoTriggerSingleLevel.Option>
                    {!isMultiSelected ? (
                        <DropdownV2.NoTriggerSingleLevel.Option value={translation('取消分组')} splitLineBottom={true}>
                            <span data-testid="local-variable-editor-sidebar-group-context-menu-ungroup">
                                {translation('取消分组')}
                            </span>
                        </DropdownV2.NoTriggerSingleLevel.Option>
                    ) : null}
                    <DropdownV2.NoTriggerSingleLevel.Option value={translation('删除分组')}>
                        <span data-testid="local-variable-editor-sidebar-group-context-menu-delete">
                            {isMultiSelected ? translation('删除分组复数') : translation('删除分组')}
                        </span>
                    </DropdownV2.NoTriggerSingleLevel.Option>
                </DropdownV2.NoTriggerSingleLevel>
            ) : null}
        </>
    )
}

// 侧边栏
export function Sidebar() {
    const service = useAppContext().variableService.localVariableEditorService
    // 变量总数
    const variablesCount = useVariablesInCollectionCount()
    // 所有分组
    const groups = useGroups()
    // 选中的分组
    const selectedGroupKeys = useSelectedGroupKeys()
    // 拖拽变量到的分组
    const { dragToGroupHighlight, dragVariableEnterGroup, dragVariableLeaveGroup } = useLocalVariableDragContext()
    // 分组之间拖拽
    const { dragTarget, dragEnterGroup, dragLeaveGroup } = useLocalVariableGroupDragContext()
    // 侧边栏 DOM 元素
    const divRef = useRef<HTMLDivElement>(null)
    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%', position: 'relative' }}>
            <div
                ref={divRef}
                className={classNames(
                    styles['sidebar-group'],
                    styles['group-all'],
                    selectedGroupKeys.includes(ALL_GROUP_LABEL) && styles.selected,
                    dragToGroupHighlight === ALL_GROUP_LABEL && styles.highlight,
                    dragTarget.display === 'group' && dragTarget.groupKey === ALL_GROUP_LABEL && styles.highlight
                )}
                onMouseDown={() => {
                    service.clearGroupSelection()
                }}
                onMouseEnter={() => {
                    dragVariableEnterGroup(ALL_GROUP_LABEL)
                    if (divRef.current) {
                        dragEnterGroup(ALL_GROUP_LABEL, divRef.current)
                    }
                }}
                onMouseLeave={() => {
                    dragVariableLeaveGroup()
                    dragLeaveGroup()
                }}
                data-selected={selectedGroupKeys.includes(ALL_GROUP_LABEL)}
                data-testid="local-variable-editor-sidebar-all"
            >
                <div style={{ color: 'var(--wk-l2-label-color-gray-13)' }}>{translation('侧边栏全部变量')}</div>
                <div style={{ color: 'var(--wk-l2-label-color-gray-8)' }}>{variablesCount}</div>
            </div>
            <div style={{ overflowX: 'hidden', flexGrow: 1, width: '100%' }}>
                {/* 第一个分组是全部，不展示 */}
                <Scrollbar>
                    {groups.map((group, index) => index > 0 && <SidebarGroup key={group.key} group={group} />)}
                    {dragTarget.display === 'line' && (
                        <div
                            style={{
                                position: 'absolute',
                                right: 0,
                                top: dragTarget.top,
                                left: dragTarget.left + 16, // 和名称对齐，空出箭头
                                height: '2px',
                                backgroundColor: 'var(--wk-gray-13)',
                                zIndex: 100,
                                pointerEvents: 'none',
                            }}
                        />
                    )}
                </Scrollbar>
            </div>
        </div>
    )
}
