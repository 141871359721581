import { translation } from './tree.translation'
import classNames from 'classnames'
import { FC, useMemo, useRef } from 'react'
import {
    MonoIconCommonAdd16,
    MonoIconCommonInfoLine16,
    MulticolorIconCommonFolder16,
    ScrollView,
    Tooltip,
    WKCollapse,
    WKCollapseItem,
    WKIconButton,
} from '../../../../ui-lib/src'
import { splitGrapheme, useIsEllipsisActive } from '../../../../util/src'
import { compareRole } from '../../kernel/interface/user-roles'
import {
    FolderWithAuthorityWithCheckLimit,
    TeamWithAuthorityWithCheckLimit,
    TeamWithFolderAndAuthorityWithCheckLimit,
    useBatchMoveDocModalContext,
} from './context'
import { FolderWithAuthorityVO, RoleStatus, TeamWithAuthorityVO } from '../../kernel/interface/type'

export const StyledNameHtml: FC<{
    name: string
    pattern: string
    ignoreTitleReplace?: boolean
    showReachLimitInfo: boolean
    type: 'team' | 'folder'
}> = ({ name, pattern, ignoreTitleReplace, showReachLimitInfo, type }) => {
    const headerRef = useRef<HTMLDivElement>(null)
    const isEllipsis = useIsEllipsisActive(headerRef.current)
    return (
        <div className="w-0 flex-1 flex">
            <Tooltip title={name} inactivation={!isEllipsis}>
                <div className="truncate" ref={headerRef}>
                    {ignoreTitleReplace
                        ? name
                        : splitGrapheme(name).map(({ segment: i }, idx) => {
                              if (pattern.includes(i)) {
                                  return (
                                      <span key={i + idx} className="color-$wk-brand-7">
                                          {i}
                                      </span>
                                  )
                              }
                              return i
                          })}
                </div>
            </Tooltip>
            {showReachLimitInfo ? (
                <div className="pl-1 flex color-$wk-l2-label-color-gray-8 justify-center items-center">
                    <Tooltip
                        firstDelay={100}
                        title={
                            type == 'folder'
                                ? translation('MoveFildReachFolderLimitWarning')
                                : translation('MoveFileReachTeamLimitWarning')
                        }
                    >
                        <MonoIconCommonInfoLine16 />
                    </Tooltip>
                </div>
            ) : null}
        </div>
    )
}

const FolderTreeItem: FC<FolderWithAuthorityWithCheckLimit & { itemIndent?: 0 | 12 }> = (props) => {
    const { defaultSelectedFolderId, setUserSelectedFolder, userSelectedFolder, qs } = useBatchMoveDocModalContext()
    const folder = props.folderWithAuthorityVO
    const isDraft = props.isDraft
    const selected = defaultSelectedFolderId == folder.id
    return (
        <div
            onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setUserSelectedFolder({
                    id: folder.id,
                    name: folder.name!,
                    teamId: folder.teamId!,
                    orgId: folder.orgId,
                    reachLimit: props.reachLimit,
                    isDraft: !!isDraft,
                })
            }}
            className={classNames(
                'list-none relative h-8 hover:bg-$wk-gray-13-4 rounded-3px pl-3 flex items-center',
                userSelectedFolder?.id == folder.id && '!bg-$wk-l2-fill-color-brand-2',
                isDraft && 'wk-font-semibold'
            )}
            data-testid="folder-details"
        >
            <ScrollView.Item className="flex h-full w-full items-center justify-between" uniqueKey={folder.id}>
                {props.itemIndent == 12 && <div className="w-3 h-full shrink-0" />}
                {!isDraft && <MulticolorIconCommonFolder16 className="mr-1 shrink-0" />}
                <StyledNameHtml name={folder.name} pattern={qs} showReachLimitInfo={props.reachLimit} type={'folder'} />
                {selected && (
                    <div className="color-$wk-l2-label-color-gray-6 shrink-0 mr-2">
                        {translation('CurrentLocation')}
                    </div>
                )}
            </ScrollView.Item>
        </div>
    )
}

const TeamTreeItem: FC<{
    ignoreTitleReplace?: boolean
    onClickAddFolder?: (teamId: string) => void
    team: TeamWithAuthorityWithCheckLimit
    folders: FolderWithAuthorityWithCheckLimit[]
    itemIndent?: 0 | 12
    isShared: boolean
    className?: string
}> = (props) => {
    const { onClickAddFolder, team, folders, ignoreTitleReplace, itemIndent = 0 } = props
    const { qs } = useBatchMoveDocModalContext()
    const teamVO = team.teamWithAuthorityVO!
    const teamId = teamVO.id ?? ''
    return (
        <WKCollapseItem
            itemIndent={itemIndent}
            value={teamId}
            itemRadius="default"
            dataTestId="team-details"
            className={props.className}
            headerClassName="pr-1! h-8!"
            title={
                <div data-testid={`team-name-${teamVO.name}`} className="flex group items-center">
                    <StyledNameHtml
                        ignoreTitleReplace={ignoreTitleReplace}
                        name={teamVO.name!}
                        pattern={qs}
                        showReachLimitInfo={team.reachLimit && !props.isShared}
                        type={'team'}
                    />
                    {onClickAddFolder && compareRole(teamVO.role!, RoleStatus.Viewer) > 0 && (
                        <Tooltip title={translation('NewProject')}>
                            <WKIconButton
                                className="group-hover:block hidden"
                                data-testid="batch-move-doc-modal-add-folder-btn"
                                size="small"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    onClickAddFolder?.(teamVO.id ?? '')
                                }}
                                icon={<MonoIconCommonAdd16 />}
                            />
                        </Tooltip>
                    )}
                </div>
            }
        >
            {folders.map((item) => {
                return (
                    <FolderTreeItem
                        itemIndent={itemIndent}
                        key={item.folderWithAuthorityVO.id}
                        {...{ ...item, reachLimit: item.reachLimit && props.isShared }}
                    />
                )
            })}
        </WKCollapseItem>
    )
}

const TeamsFolders: FC<{
    teamsFolders: TeamWithFolderAndAuthorityWithCheckLimit[]
    itemIndent?: 0 | 12
    className?: string
}> = ({ teamsFolders, itemIndent = 0, className }) => {
    const { handleClickAddFolder } = useBatchMoveDocModalContext()
    return (
        <>
            {teamsFolders?.map((item) => {
                return (
                    <TeamTreeItem
                        itemIndent={itemIndent}
                        className={className}
                        isShared={false}
                        folders={item.folderWithAuthorityVOList}
                        team={item.teamWithAuthorityVO}
                        onClickAddFolder={() => {
                            handleClickAddFolder(
                                item.teamWithAuthorityVO.teamWithAuthorityVO.id,
                                item.teamWithAuthorityVO.teamWithAuthorityVO.orgId
                            )
                        }}
                        key={item.teamWithAuthorityVO.teamWithAuthorityVO.id}
                        {...item}
                    />
                )
            })}
        </>
    )
}

const WorkspacesTeamsFolders: FC = () => {
    const { result, setResult } = useBatchMoveDocModalContext()
    const selection = useMemo(() => {
        return Object.entries(result?.openStatusMap ?? {})
            .filter(([_, open]) => open)
            .map(([key]) => key)
    }, [result?.openStatusMap])
    const onSelectionChange = (selections: string[]) => {
        setResult((r) => ({
            ...r!,
            openStatusMap: selections.reduce((acc, key) => ({ ...acc, [key]: true }), {}),
        }))
    }
    const teamsFolders = result?.teamsAndFolders
    const worspacesAndTeamIds = result?.worspacesAndTeamIds
    return (
        <>
            {worspacesAndTeamIds?.map(({ workspaceId, workspaceName, teamIds }) => {
                const itemTeamsFolders =
                    teamsFolders?.filter((item) => teamIds.includes(item.teamWithAuthorityVO.teamWithAuthorityVO.id)) ??
                    []
                return (
                    <WKCollapseItem
                        key={workspaceId}
                        itemRadius="default"
                        value={workspaceId}
                        title={workspaceName || translation('Others')}
                    >
                        <WKCollapse
                            selectionMode="multiple"
                            selection={selection}
                            onSelectionChange={onSelectionChange}
                        >
                            <TeamsFolders itemIndent={12} className={'pt-1'} teamsFolders={itemTeamsFolders} />
                        </WKCollapse>
                    </WKCollapseItem>
                )
            })}
        </>
    )
}

export const WKTeamFolderTree = () => {
    const { result, setResult, isEnterprise, organization } = useBatchMoveDocModalContext()
    const teamFolders = result?.teamsAndFolders
    const shareFolders = result?.sharedFolders
    const selection = useMemo(() => {
        return Object.entries(result?.openStatusMap ?? {})
            .filter(([_, open]) => open)
            .map(([key]) => key)
    }, [result?.openStatusMap])
    const workspaceSelection = useMemo(() => {
        return Object.entries(result?.workspaceOpenStatusMap ?? {})
            .filter(([_, open]) => open)
            .map(([key]) => key)
    }, [result?.workspaceOpenStatusMap])
    const onSelectionChange = (selections: string[]) => {
        setResult((r) => ({ ...r!, openStatusMap: selections.reduce((acc, key) => ({ ...acc, [key]: true }), {}) }))
    }
    const onWorkspaceSelectionChange = (selections: string[]) => {
        setResult((r) => ({
            ...r!,
            workspaceOpenStatusMap: selections.reduce((acc, key) => ({ ...acc, [key]: true }), {}),
        }))
    }
    if (teamFolders?.length == 0 && 0 == shareFolders?.length && !result?.showDraft) {
        return (
            <div className="text-center color-$wk-l2-label-color-gray-6 mt-10 wk-font-medium">
                {translation('NoResultsFound')}
            </div>
        )
    }

    return (
        <WKCollapse
            style={{ display: 'flex', flexDirection: 'column', gap: 8 }}
            selectionMode={'multiple'}
            selection={isEnterprise ? workspaceSelection : selection}
            onSelectionChange={isEnterprise ? onWorkspaceSelectionChange : onSelectionChange}
        >
            {organization.draftFolderId && result?.showDraft && (
                <FolderTreeItem
                    folderWithAuthorityVO={
                        {
                            id: organization.draftFolderId,
                            name: translation('Drafts'),
                        } as FolderWithAuthorityVO
                    }
                    isDraft={true}
                    reachLimit={false}
                />
            )}
            {!!shareFolders?.length && (
                <TeamTreeItem
                    isShared={true}
                    team={{
                        reachLimit: false,
                        teamWithAuthorityVO: {
                            name: translation('SharedProjects'),
                            id: 'shareFolders',
                        } as TeamWithAuthorityVO,
                    }}
                    folders={shareFolders}
                    ignoreTitleReplace={true}
                />
            )}
            {isEnterprise ? (
                <WorkspacesTeamsFolders />
            ) : (
                <TeamsFolders itemIndent={0} teamsFolders={teamFolders ?? []} />
            )}
        </WKCollapse>
    )
}
