import { CurrentPageSetSelectionCommandWasmCall, ZoomToFitNodesAtMaxScaleByIdsCommand } from '@wukong/bridge-proto'
import { useState } from 'react'
import {
    MonoIconCommonArrowDown16,
    MonoIconCommonArrowUp16,
    MonoIconPanelTarget16,
    WKIconButton,
} from '../../../../../../ui-lib/src'
import { CommitType } from '../../../../document/command/commit-type'
import { useCommand } from '../../../context/document-context'
import { AssistantMessage } from './common'

interface ChatAssistantMessageProps {
    message: AssistantMessage
}

interface ThinkingContentProps {
    thinking: string
    thinkingDuration: number
}

const ThinkingContent = (props: ThinkingContentProps) => {
    const { thinking, thinkingDuration } = props
    const [collapsed, setCollapsed] = useState(false)

    return (
        <div className="flex flex-col items-start p-8px gap-4px text-gray">
            <div className="flex flex-row items-center h-20px gap-4px">
                <div className="hover:text-$wk-v2-gray-11 active:text-$wk-v2-gray-11">
                    is thinking...{thinkingDuration ? `${(thinkingDuration / 1000).toFixed(2)}s` : ''}
                </div>
                {collapsed ? (
                    <MonoIconCommonArrowDown16 className="w-12px h-12px" onClick={() => setCollapsed(!collapsed)} />
                ) : (
                    <MonoIconCommonArrowUp16 className="w-12px h-12px" onClick={() => setCollapsed(!collapsed)} />
                )}
            </div>
            {!collapsed ? (
                <div className="flex flex-row items-start gap-10px">
                    <div className="w-1px bg-$wk-v2-gray-3 self-stretch"></div>
                    <div>{thinking}</div>
                </div>
            ) : null}
        </div>
    )
}

export const ChatAssistantMessage = (props: ChatAssistantMessageProps) => {
    const { message } = props
    const command = useCommand()

    const onTargetClick = (id: string) => {
        if (!id) {
            return
        }

        command.invokeBridge(CommitType.CommitUndo, CurrentPageSetSelectionCommandWasmCall, {
            selection: [id],
        })

        command.invokeBridge(CommitType.Noop, ZoomToFitNodesAtMaxScaleByIdsCommand, {
            nodeIds: [id],
        })
    }
    return (
        <div className="flex flex-col items-start p-8px gap-4px">
            <ThinkingContent thinking={message.thinking} thinkingDuration={message.thinking_duration} />
            <div className="px-8px whitespace-pre-wrap">{message.content}</div>
            <div className="w-full flex flex-row items-center justify-between">
                {message.ui.map((ui) => {
                    return ui.state === 'creating' ? (
                        <div
                            key={ui.uuid}
                            className="box-border w-180px h-220px bg-$wk-v2-gray-3 p-16px flex items-center justify-center text-gray"
                        >
                            生成中...
                        </div>
                    ) : (
                        <div key={ui.uuid} className="relative">
                            <WKIconButton
                                size="medium"
                                type="border"
                                className="absolute right-0 top-0"
                                icon={<MonoIconPanelTarget16 onClick={() => onTargetClick(ui.nodeId)} />}
                            ></WKIconButton>

                            <img
                                className="box-border w-180px h-220px object-contain p-16px bg-$wk-v2-gray-1 rounded-$wk-radius-default"
                                src={ui.preview || ui.previewResourceId}
                            />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
