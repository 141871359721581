import { CurrentPageSetSelectionCommandWasmCall } from '@wukong/bridge-proto'
import { useState } from 'react'
import {
    MonoIconLayerComponent16,
    MonoIconPanelParentLevel16,
    Tooltip,
    useEllipsisTooltip,
    WKIconButton,
} from '../../../../../../ui-lib/src'
import { CommitType } from '../../../../document/command/commit-type'
import { useViewState } from '../../../../view-state-bridge'
import { useCommand } from '../../../context/document-context'
import { Title } from '../../atom/title/title'
import styles from './index.module.less'
import { translation } from './index.translation'

export function BelongingComponentPanel() {
    const { componentOrSetId, componentOrSetName } = useViewState('belongingComponentPanelState')!

    const { inactivation, ellipsisRef, onmouseenter } = useEllipsisTooltip<HTMLDivElement>()

    const command = useCommand()
    const jumpToOuterComponentOrSet = () => {
        componentOrSetId &&
            command.invokeBridge(CommitType.CommitUndo, CurrentPageSetSelectionCommandWasmCall, {
                selection: [componentOrSetId],
            })
    }

    const [isHovered, setIsHovered] = useState<boolean>(false)

    return (
        <div
            data-testid="belonging-component-panel"
            className={'pt-8px pb-8px'}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <Title>
                <Title.Left>{translation('BelongingComponent')}</Title.Left>
            </Title>
            <div className={styles.componentNameArea}>
                <MonoIconLayerComponent16 style={{ color: 'var(--wk-l2-label-color-gray-8)' }} />
                <Tooltip title={componentOrSetName} inactivation={inactivation}>
                    <div
                        ref={ellipsisRef}
                        onMouseEnter={onmouseenter}
                        className={styles.name}
                        data-testid="belonging-component-name"
                    >
                        {componentOrSetName}
                    </div>
                </Tooltip>
                {isHovered && (
                    <Tooltip title={translation('SelectComponent')}>
                        <WKIconButton
                            data-testid="select-component"
                            className={styles.selectComponent}
                            icon={<MonoIconPanelParentLevel16 style={{ color: 'var(--wk-l2-label-color-gray-8)' }} />}
                            onClick={jumpToOuterComponentOrSet}
                        />
                    </Tooltip>
                )}
            </div>
        </div>
    )
}
