import { useCallback, useEffect, useMemo, useState } from 'react'
import { FontInfo } from '../../../../document/node/node'
import { usePageSignal } from '../../../../external-store/atoms/page-signal'
import {
    AIGenCreateDesignSystem,
    AIGenDeleteDesignSystem,
    AIGenModifyDesignSystem,
    AIGenSelectDesignSystem,
    AIGenUserDesignSystems,
    StyleConfig,
} from '../../../../kernel/request/ai-gen'
import { LocalStorageKey } from '../../../../web-storage/local-storage/config'
import { useLocalStorageState } from '../../../../web-storage/local-storage/hooks'
import { useFontManagerService } from '../../../context/document-context'
import { rgb2hex } from '../../design/blend/color-picker/utils/color-translate'
import { hexToRgb } from '../../design/html-importer/utils/utils'
import {
    AutoStylingBorderRadiusMap,
    BuiltinRemix,
    COLORS,
    ConfigKey,
    Contrast,
    ContrastMap,
    DesignSystem,
    DesignSystemMap,
    MAXIMUM_USER_REMIXES,
    Platform,
    PlatformMap,
    Remix,
    UserRemix,
    antdColorNameWithIndex,
} from './constants'
import { MaterialDesignTheme, materialDesignThemes } from './material-design-themes'
import { translation } from './use-ai-gen-platform-and-design-system.translation'

// Helper functions
export function isUserRemix(remix: Remix): remix is UserRemix {
    return !remix.builtin
}

export function isBuiltinRemix(remix: Remix): remix is BuiltinRemix {
    return remix.builtin
}

function displayDesignSystem(designSystem: DesignSystem, platform: Platform): string {
    switch (designSystem) {
        case DesignSystemMap.custom:
            if (platform === PlatformMap.app) {
                return translation('mobileAuto')
            } else {
                return translation('webAuto')
            }
        case DesignSystemMap.apple:
            return 'iOS UI'
        case DesignSystemMap.md3:
            return 'Material Design'
        case DesignSystemMap.antd:
            return 'Ant Design'
        case DesignSystemMap.shadcn:
            return 'shadcn/UI'
    }
}

export function materialContrastToNumber(contrast: string): number {
    switch (contrast as Contrast) {
        case ContrastMap.medium:
            return 0.1
        case ContrastMap.high:
            return 0.2
        default:
            return 0.0
    }
}

function calculateColorLinkFromPrimary(primaryColor: string) {
    // Step 1: Parse the hex color to RGB
    const { r, g, b } = hexToRgb(primaryColor)

    return '#' + rgb2hex(r * 0.865, g * 0.865, b * 0.865)
}

function styleConfigToRemix({
    builtinConfigs,
    userConfigs,
}: {
    builtinConfigs: StyleConfig[]
    userConfigs: StyleConfig[]
    lastUsedConfig: number
}) {
    const newMap = new Map<number, Remix>()
    const configs = [
        ...builtinConfigs.map((config) => ({
            ...config,
            builtin: true,
        })),
        ...userConfigs,
    ].filter((config) => config.designSystem !== DesignSystemMap.apple)
    for (const styleConfig of configs) {
        try {
            const configObj = JSON.parse(styleConfig.config)
            // Detail Info goes to: https://www.notion.so/kanyun/style-config-1a566f1452e2802a8de2fb65ba18d68e
            if (styleConfig.designSystem === DesignSystemMap.md3) {
                configObj.mode = configObj.modeOfThisPage
                configObj.modeOfThisPage = undefined
                configObj.contrast = configObj.contrastOfThisPage
                configObj.contrastOfThisPage = undefined
                configObj.primaryColor = configObj.sourceColor
                configObj.sourceColor = undefined
            }
            if (styleConfig.designSystem === DesignSystemMap.shadcn) {
                configObj.cornerSize = configObj.radius.toString()
                configObj.radius = undefined
                configObj.primaryColor = configObj.color
                configObj.color = undefined
            }
            if (styleConfig.designSystem === DesignSystemMap.antd) {
                configObj.primaryColor = configObj.colorPrimary
                configObj.colorPrimary = undefined
            }
            if (styleConfig.designSystem === DesignSystemMap.custom) {
                configObj.cornerSize =
                    AutoStylingBorderRadiusMap[configObj.cornerSize as keyof typeof AutoStylingBorderRadiusMap]
            }
            const newConfig = new Map()
            for (const [key, value] of Object.entries(configObj)) {
                if (value !== undefined && value !== null) {
                    newConfig.set(key as ConfigKey, value)
                }
            }
            if (styleConfig.designSystem === DesignSystemMap.custom && styleConfig.builtin) {
                if (styleConfig.platform === PlatformMap.app) {
                    styleConfig.name = translation('mobileAuto')
                } else {
                    styleConfig.name = translation('webAuto')
                }
            }
            newMap.set(styleConfig.id, {
                platform: styleConfig.platform as Platform,
                designSystem: styleConfig.designSystem as DesignSystem,
                name: styleConfig.name,
                id: styleConfig.id,
                builtin: styleConfig.builtin,
                config: newConfig,
            })
        } catch (e) {
            console.error(e)
        }
    }
    return newMap
}

export function usePlatformAndDesignSystem({
    styleConfigID,
    styleConfigName,
    onSelectRemix,
}: {
    styleConfigID?: number
    styleConfigName?: string
    onSelectRemix?: (remix: Remix) => void
}) {
    /*
    一次更新流程:
    1. 根据基底的Remix, 建立一个TemporaryRemix, 如果是builtin, id为TEMPORARY_CREATING; 如果是user, id为原id
    2. 修改时的内容都将从TemporaryRemix中读取, 有: tempRemixName, tempRemixConfig
    3. 修改完毕, 等待后台保存成功再清空temp, 否则提示失败
    */
    const [{ id: selectedRemixID, name: cachedRemixName }, setSelectedRemix] = useLocalStorageState(
        LocalStorageKey.AIGenDropdownSelectedConfig,
        { id: -1, name: translation('mobileAuto') }
    )

    useEffect(() => {
        if (styleConfigID !== undefined && styleConfigName !== undefined) {
            setSelectedRemix({
                id: styleConfigID,
                name: styleConfigName,
            })
            const remix = {
                id: styleConfigID,
                name: styleConfigName,
                // TODO(chaibowen@kanyun.com): cache more information to avoid such as any
                platform: null,
                designSystem: null,
                builtin: null,
                config: new Map(),
                // any here to make sure pass the type check
            } as any
            onSelectRemix?.(remix)
        }
        // TODO(hulh@kanyun.com): setSelectedRemix not wrap under callback which caused infinite effect
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [styleConfigID, styleConfigName, onSelectRemix])
    const [remixes, setRemixes] = useState<Map<number, Remix>>(() => new Map())
    const [temporaryRemix, setTemporaryRemix] = useState<UserRemix | null>(null)
    const [waitForModification, setWaitForModification] = useState<boolean>(false)
    const [customColorInfo, setCustomColorInfo] = useState<string | null>(null)
    const [openedColorPicker, setOpenedColorPicker] = useState<'primary' | 'antd-custom-derived' | 'none'>('none')
    const pageSignal = usePageSignal()
    const disableCreatingRemix = useMemo(() => {
        const userRemixes = remixes
            .entries()
            .filter(([_, remix]) => isUserRemix(remix))
            .toArray()
        return userRemixes.length >= MAXIMUM_USER_REMIXES
    }, [remixes])
    const fontManagerService = useFontManagerService()
    const [customizedDerivedColor, setCustomizedDerivedColor] = useState<Map<string, string>>(new Map())
    const [isSelectedCustomColor, setIsSelectedCustomColor] = useState(false)
    const selectedDesignSystem = remixes.get(selectedRemixID)?.designSystem ?? null
    const selectedPlatform = remixes.get(selectedRemixID)?.platform ?? null
    const [fontInfo, setFontInfo] = useState<FontInfo | null>(null)

    const dropdownMenuDisplayValue = useMemo(() => {
        const remix = remixes.get(selectedRemixID)
        if (remix) {
            if (isBuiltinRemix(remix) && remix.designSystem === DesignSystemMap.custom) {
                if (remix.platform === PlatformMap.app) {
                    return translation('mobileAuto')
                } else {
                    return translation('webAuto')
                }
            } else {
                return remix.name
            }
        } else {
            return cachedRemixName
        }
    }, [cachedRemixName, remixes, selectedRemixID])

    const tempRemixDesignSystem = temporaryRemix?.designSystem ?? null
    const tempRemixPlatform = temporaryRemix?.platform ?? null

    const tempRemixName = useMemo(
        function () {
            if (temporaryRemix) {
                return temporaryRemix.name
            } else {
                return null
            }
        },
        [temporaryRemix]
    )

    const tempRemixConfig = useMemo(
        function () {
            if (temporaryRemix?.config) {
                return temporaryRemix.config
            } else {
                return null
            }
        },
        [temporaryRemix]
    )

    const primaryColor = tempRemixConfig?.get('primaryColor')?.toUpperCase() ?? null
    const mode = tempRemixConfig?.get('mode') ?? null
    const contrast = tempRemixConfig?.get('contrast') ?? null

    const currentMaterialDesignTheme = useCallback(() => {
        if (primaryColor !== null && mode !== null && contrast !== null) {
            const firstLevel = materialDesignThemes[primaryColor as keyof typeof materialDesignThemes]
            if (firstLevel) {
                const select = contrast === ContrastMap.standard ? mode : `${mode}-${contrast.toLowerCase()}-contrast`
                return firstLevel[select as keyof typeof firstLevel] as MaterialDesignTheme
            }
        }
    }, [primaryColor, mode, contrast])

    const antdDesignPalette = useMemo(() => {
        const colorPrimary = primaryColor ?? '#1677FF'
        const colorSuccess = customizedDerivedColor.get(antdColorNameWithIndex[0].index) ?? '#52C41A'
        const colorWarning = customizedDerivedColor.get(antdColorNameWithIndex[1].index) ?? '#FAAD14'
        const colorError = customizedDerivedColor.get(antdColorNameWithIndex[2].index) ?? '#FF4D4F'
        const colorLink =
            customizedDerivedColor.get(antdColorNameWithIndex[3].index) ??
            (mode === 'light' ? primaryColor : calculateColorLinkFromPrimary(colorPrimary))
        const colorTextBase =
            customizedDerivedColor.get(antdColorNameWithIndex[4].index) ?? (mode === 'light' ? '#000000' : '#FFFFFF')
        const colorBackgroundBase =
            customizedDerivedColor.get(antdColorNameWithIndex[5].index) ?? (mode === 'light' ? '#FFFFFF' : '#000000')
        return {
            colorPrimary,
            colorSuccess,
            colorWarning,
            colorError,
            colorLink,
            colorTextBase,
            colorBackgroundBase,
        }
    }, [primaryColor, mode, customizedDerivedColor])

    const getStyleConfig = useCallback(() => {
        const remix = remixes.get(selectedRemixID)
        if (remix === undefined) {
            return null
        }
        return { id: remix.id, platform: remix.platform }
    }, [remixes, selectedRemixID])

    // 这里会将所有名称统一的对接到类Auto Styling的名称上
    const fetchAndSetRemixes = useCallback(
        function () {
            pageSignal.throwIfAborted()
            const req = new AIGenUserDesignSystems()
            req.start()
                .then((styleConfigs) => {
                    const newMap = styleConfigToRemix(styleConfigs)
                    setRemixes((old) => {
                        for (const [key, value] of newMap.entries()) {
                            old.set(key, value)
                        }
                        return new Map(old)
                    })
                    if (styleConfigs.lastUsedConfig && styleConfigID === undefined) {
                        const lastUsedConfig = newMap.get(styleConfigs.lastUsedConfig)
                        if (lastUsedConfig) {
                            onSelectRemix?.(lastUsedConfig)
                            setSelectedRemix((old) => {
                                if (old.id !== -1) {
                                    return old
                                }
                                return {
                                    id: lastUsedConfig.id,
                                    name: lastUsedConfig.name,
                                }
                            })
                        } else {
                            onSelectRemix?.({
                                platform: PlatformMap.app,
                                designSystem: DesignSystemMap.custom,
                                id: styleConfigs.builtinConfigs[0].id,
                                name: translation('mobileAuto'),
                                builtin: true,
                                config: new Map(),
                            })
                            setSelectedRemix((old) => {
                                if (old.id !== -1) {
                                    return old
                                }
                                return {
                                    id: styleConfigs.builtinConfigs[0].id,
                                    name: translation('mobileAuto'),
                                }
                            })
                        }
                    }
                })
                .catch(() => {
                    // IGNORE remix fetch error
                })
            return () => {
                req.cancel()
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [pageSignal]
    )

    useEffect(
        function setRemixesOnEveryMount() {
            return fetchAndSetRemixes()
        },
        [fetchAndSetRemixes]
    )

    const dropdownSelectOption = useCallback(
        function (id: number) {
            setSelectedRemix({ id, name: remixes.get(id)?.name ?? null })
            onSelectRemix?.(remixes.get(id)!)
        },
        [setSelectedRemix, remixes, onSelectRemix]
    )

    const appRemixes = useMemo(
        function () {
            const builtinRemixes = remixes
                .values()
                .filter((remix) => remix.platform === PlatformMap.app && isBuiltinRemix(remix))
            const userRemixes = remixes
                .values()
                .filter((remix) => remix.platform === PlatformMap.app && isUserRemix(remix))
            return [...builtinRemixes, ...Array.from(userRemixes).sort((a, b) => a.name.localeCompare(b.name))]
        },
        [remixes]
    )

    const webRemixes = useMemo(
        function () {
            const builtinRemixes = remixes
                .values()
                .filter((remix) => remix.platform === PlatformMap.web && isBuiltinRemix(remix))
            const userRemixes = remixes
                .values()
                .filter((remix) => remix.platform === PlatformMap.web && isUserRemix(remix))
            return [...builtinRemixes, ...Array.from(userRemixes).sort((a, b) => a.name.localeCompare(b.name))]
        },
        [remixes]
    )

    const creatingOrEditingRemix = useMemo(
        function () {
            if (temporaryRemix) {
                if (temporaryRemix.id === -1) {
                    return { label: translation('remix'), value: 'Remix' } as const
                } else {
                    return { label: translation('save'), value: 'Save' } as const
                }
            } else {
                return null
            }
        },
        [temporaryRemix]
    )

    const editorTitle = useMemo(
        function () {
            if (temporaryRemix) {
                const { designSystem, platform } = temporaryRemix
                const designSystemDisplayValue = displayDesignSystem(designSystem, platform)
                return temporaryRemix.id === -1
                    ? `${translation('remix')} ${designSystemDisplayValue}`
                    : `${translation('editStyling')}`
            }
        },
        [temporaryRemix]
    )

    const createTempRemix = useCallback(
        function (id: number) {
            const baseRemix = remixes.get(id)
            if (baseRemix) {
                if (isBuiltinRemix(baseRemix)) {
                    setTemporaryRemix({
                        platform: baseRemix.platform,
                        designSystem: baseRemix.designSystem,
                        name: `${baseRemix.name} - ${translation('remix')}`,
                        id: -1,
                        builtin: false,
                        config: new Map(baseRemix.config),
                    })
                } else {
                    const baseRemixColors = COLORS.get(baseRemix.designSystem) as readonly string[] | null
                    const baseRemixPrimaryColor = baseRemix.config.get('primaryColor')
                    if (baseRemixColors && baseRemixPrimaryColor && !baseRemixColors.includes(baseRemixPrimaryColor)) {
                        setIsSelectedCustomColor(true)
                        setCustomColorInfo(baseRemixPrimaryColor)
                    }
                    setTemporaryRemix({
                        ...baseRemix,
                        config: new Map(baseRemix.config),
                        builtin: false,
                    } as UserRemix)
                }
            }
        },
        [remixes]
    )

    const isTempRemixChanged: null | boolean = useMemo(() => {
        if (temporaryRemix) {
            if (temporaryRemix.id === -1) {
                return true
            } else {
                // if field all the same with the baseRemix, return false
                const baseRemix = remixes.get(temporaryRemix.id)
                if (baseRemix === undefined) {
                    return null
                }
                for (const [key, value] of temporaryRemix.config.entries()) {
                    if (baseRemix.config.get(key) !== value) {
                        return true
                    }
                }
                return temporaryRemix.name !== baseRemix.name
            }
        } else {
            return null
        }
    }, [temporaryRemix, remixes])

    const closeAllColorPickers = useCallback(() => {
        setOpenedColorPicker('none')
    }, [])

    const openPrimaryColorPicker = useCallback(() => {
        setOpenedColorPicker('primary')
    }, [])

    const openAntdCustomDerivedColorPicker = useCallback(() => {
        setOpenedColorPicker('antd-custom-derived')
    }, [])

    const closePrimaryColorPicker = useCallback(() => {
        setOpenedColorPicker((opend) => (opend === 'primary' ? 'none' : opend))
    }, [])

    const closeAntdCustomDerivedColorPicker = useCallback(() => {
        setOpenedColorPicker((opend) => (opend === 'antd-custom-derived' ? 'none' : opend))
    }, [])

    const discardTempRemix = useCallback(function () {
        setTemporaryRemix(null)
        setCustomizedDerivedColor(new Map())
        setCustomColorInfo(null)
        setIsSelectedCustomColor(false)
        setOpenedColorPicker('none')
        setWaitForModification(false)
        setFontInfo(null)
    }, [])

    const finishCreatingOrEditRemix = useCallback(
        async function () {
            setWaitForModification(true)
            if (temporaryRemix) {
                const config = new Map<string, unknown>(temporaryRemix.config)
                if (temporaryRemix.designSystem === DesignSystemMap.md3) {
                    config.set('modeOfThisPage', config.get('mode') ?? 'light')
                    config.set('contrastOfThisPage', config.get('contrast') ?? 'standard')
                    config.delete('mode')
                    config.delete('contrast')
                    config.set('sourceColor', config.get('primaryColor') ?? '#B33B15')
                } else if (temporaryRemix.designSystem === DesignSystemMap.shadcn) {
                    config.set('radius', config.get('cornerSize') ?? 0.5)
                    config.set('color', config.get('primaryColor') ?? '#18181B')
                    config.delete('cornerSize')
                    config.delete('primaryColor')
                } else if (temporaryRemix.designSystem === DesignSystemMap.antd) {
                    config.set('colorPrimary', config.get('primaryColor') ?? '#1677FF')
                    config.delete('primaryColor')
                    for (const [key, value] of Object.entries(antdDesignPalette)) {
                        config.set(key, value)
                    }
                } else if (temporaryRemix.designSystem === DesignSystemMap.custom) {
                    config.set(
                        'cornerSize',
                        AutoStylingBorderRadiusMap[config.get('cornerSize') as keyof typeof AutoStylingBorderRadiusMap]
                    )
                }
                if (temporaryRemix.id === -1) {
                    const createReq = new AIGenCreateDesignSystem(
                        temporaryRemix.platform,
                        temporaryRemix.designSystem,
                        temporaryRemix.name,
                        JSON.stringify(
                            Object.fromEntries(
                                config.entries().map(([key, value]) => {
                                    if (key.includes('color') && typeof value === 'string') {
                                        return [key, value.toUpperCase()]
                                    } else {
                                        return [key, value]
                                    }
                                })
                            )
                        )
                    )
                    await createReq
                        .start()
                        .then((createdRemixID) => {
                            const createdRemix = {
                                platform: temporaryRemix.platform as Platform,
                                designSystem: temporaryRemix.designSystem as DesignSystem,
                                name: temporaryRemix.name,
                                id: Number.parseInt(createdRemixID),
                                config: new Map(temporaryRemix.config),
                                builtin: false,
                            }
                            setRemixes((old) => new Map(old.set(Number.parseInt(createdRemixID), createdRemix)))
                            onSelectRemix?.(createdRemix)
                            setSelectedRemix({
                                id: Number.parseInt(createdRemixID),
                                name: temporaryRemix.name,
                            })
                            setWaitForModification(false)
                        })
                        .catch(() => {})
                } else {
                    const updateReq = new AIGenModifyDesignSystem(
                        temporaryRemix.id,
                        temporaryRemix.platform,
                        temporaryRemix.designSystem,
                        temporaryRemix.name,
                        JSON.stringify(Object.fromEntries(config.entries()))
                    )
                    await updateReq
                        .start()
                        .then(() => {
                            setWaitForModification(false)
                        })
                        .catch(() => {})
                    const updatedRemix = {
                        platform: temporaryRemix.platform as Platform,
                        designSystem: temporaryRemix.designSystem as DesignSystem,
                        name: temporaryRemix.name,
                        id: temporaryRemix.id,
                        config: new Map(temporaryRemix.config),
                        builtin: false,
                    }
                    setRemixes((old) => new Map(old.set(temporaryRemix.id, updatedRemix)))
                    onSelectRemix?.(updatedRemix)
                    setSelectedRemix({ id: temporaryRemix.id, name: temporaryRemix.name })
                }
                setTemporaryRemix(null)
                setCustomizedDerivedColor(new Map())
                setCustomColorInfo(null)
                setIsSelectedCustomColor(false)
                setOpenedColorPicker('none')
                setFontInfo(null)
            }
        },
        [antdDesignPalette, onSelectRemix, setSelectedRemix, temporaryRemix]
    )

    const deleteRemix = useCallback(
        async function () {
            if (temporaryRemix && temporaryRemix.id !== -1) {
                const id = temporaryRemix.id
                const req = new AIGenDeleteDesignSystem(id)
                await req.start()
                setRemixes((old) => {
                    old.delete(id)
                    return new Map(old)
                })
                setTemporaryRemix(null)
                if (temporaryRemix.platform === PlatformMap.app) {
                    onSelectRemix?.(appRemixes[0])
                    setSelectedRemix({ id: appRemixes[0].id, name: appRemixes[0].name })
                } else {
                    onSelectRemix?.(webRemixes[0])
                    setSelectedRemix({ id: webRemixes[0].id, name: webRemixes[0].name })
                }
            }
        },
        [appRemixes, onSelectRemix, setSelectedRemix, temporaryRemix, webRemixes]
    )

    const updateRemixName = useCallback(
        function (newName: string) {
            if (temporaryRemix) {
                setTemporaryRemix({
                    ...temporaryRemix,
                    name: newName,
                })
            }
        },
        [temporaryRemix]
    )

    const updateRemixConfig = useCallback(
        function (key: string, value: string) {
            if (temporaryRemix) {
                temporaryRemix.config.set(key, value)
                setTemporaryRemix({ ...temporaryRemix })
            }
        },
        [temporaryRemix]
    )

    const getFontFace = useCallback(
        async function () {
            if (fontInfo) {
                const fontUrl = await fontManagerService.getFontFileUrl(fontInfo.family, fontInfo.styles[0])
                const fontFace = new FontFace(fontInfo.family, `url(${fontUrl})`)
                const fontFaceLoaded = await fontFace.load()
                return fontFaceLoaded
            }
            return null
        },
        [fontInfo, fontManagerService]
    )

    const selectDesignSystem = useCallback((id: number) => {
        const selectCofigReq = new AIGenSelectDesignSystem(id)
        selectCofigReq.start().catch(() => {
            // Ignore Select Config Request Error
        })
    }, [])

    return {
        fontInfo,
        remixes,
        setFontInfo,
        tempRemixName,
        antdDesignPalette,
        selectedRemixID,
        fetchAndSetRemixes,
        disableCreatingRemix,
        isSelectedCustomColor,
        setIsSelectedCustomColor,
        customColorInfo,
        setCustomColorInfo,
        tempRemixConfig,
        tempRemixDesignSystem,
        tempRemixPlatform,
        finishCreatingOrEditRemix,
        createTempRemix,
        selectedDesignSystem,
        selectedPlatform,
        deleteRemix,
        openedColorPicker,
        openPrimaryColorPicker,
        openAntdCustomDerivedColorPicker,
        closeAllColorPickers,
        closePrimaryColorPicker,
        closeAntdCustomDerivedColorPicker,
        waitForModification,
        dropdownMenuDisplayValue,
        dropdownSelectOption,
        showDesignSystemEditor: temporaryRemix !== null,
        discardTempRemix,
        editorTitle,
        creatingOrEditingRemix,
        appRemixes,
        webRemixes,
        updateRemixName,
        updateRemixConfig,
        isTempRemixChanged,
        getStyleConfig,
        customizedDerivedColor,
        setCustomizedDerivedColor,
        currentMaterialDesignTheme,
        getFontFace,
        selectDesignSystem,
        cachedRemixName,
    } as const
}
