import { ApplyAISearchInsertNodeCommand, Wukong } from '@wukong/bridge-proto'
import { useRef } from 'react'
import {
    MonoIconLayerComponent16,
    MonoIconLayerFrame16,
    MonoIconLayerGroup16,
    MonoIconLayerInstance16,
    WKButton,
} from '../../../../../../ui-lib/src'
import { CommitType } from '../../../../document/command/commit-type'
import { generateMotiffDocumentNodeUrl } from '../../../../document/util/url'
import { WkCLog } from '../../../../kernel/clog/wukong/instance'
import { AISearchCandidate, AISearchDocumentResult } from '../../../../kernel/interface/ai-search'
import { useCommand } from '../../../context/document-context'
import { translation } from './ai-search-result-file-node.translation'

type AISearchNodeType =
    | Wukong.DocumentProto.NodeType.NODE_TYPE_COMPONENT
    | Wukong.DocumentProto.NodeType.NODE_TYPE_INSTANCE
    | Wukong.DocumentProto.NodeType.NODE_TYPE_FRAME
    | Wukong.DocumentProto.NodeType.NODE_TYPE_GROUP

const AISearchNodeTypeIcons: Record<AISearchNodeType, React.ReactNode> = {
    [Wukong.DocumentProto.NodeType.NODE_TYPE_COMPONENT]: <MonoIconLayerComponent16 />,
    [Wukong.DocumentProto.NodeType.NODE_TYPE_INSTANCE]: <MonoIconLayerInstance16 />,
    [Wukong.DocumentProto.NodeType.NODE_TYPE_FRAME]: <MonoIconLayerFrame16 />,
    [Wukong.DocumentProto.NodeType.NODE_TYPE_GROUP]: <MonoIconLayerGroup16 />,
}

const AISearchNodeTypeIcon = ({ nodeType }: { nodeType: AISearchNodeType }) => {
    const icon = AISearchNodeTypeIcons[nodeType]
    if (!icon) {
        return null
    }

    return <span className="flex-none h-16px w-16px color-$wk-l2-label-color-gray-6">{icon}</span>
}

export function AISearchResultFileNode({
    file,
    candidate,
}: {
    file: AISearchDocumentResult
    candidate: AISearchCandidate
}) {
    const command = useCommand()
    const docId = file.documentVO.id
    const isApplying = useRef(false)

    const onApplyNode = async () => {
        if (isApplying.current) {
            // 防止重复点击
            return
        }

        try {
            isApplying.current = true
            const response = await fetch(candidate.nodeDataUrl)
            if (response.ok) {
                const nodeData = await response.json()
                command.invokeBridge(CommitType.CommitUndo, ApplyAISearchInsertNodeCommand, {
                    info: JSON.stringify({
                        nodes: { [docId]: nodeData.nodes },
                        blobs: { [docId]: nodeData.blobs },
                    }),
                    docId: docId,
                    rootId: candidate.nodeId,
                })
            }
        } catch (error) {
            WkCLog.log(`Apply ai search insert node failed, url: ${candidate.nodeDataUrl}`)
        } finally {
            isApplying.current = false
        }
    }

    const onOpenFile = () => {
        window.open(generateMotiffDocumentNodeUrl(docId, candidate.nodeId), '_blank')
    }

    return (
        <div className="group flex flex-col gap-8px relative">
            <div className="hidden group-hover:block absolute left-0 top-0 w-242px h-180px bg-$wk-gray-13-15">
                <div className="absolute right-8px top-8px flex flex-row items-center">
                    <WKButton
                        data-testid={'ai-search-result-file-open-' + candidate.nodeId}
                        type="primary"
                        white
                        className="!color-$wk-l2-label-color-gray-11 active:!bg-$wk-gray-3 active:!text-black"
                        size="small"
                        onClick={onOpenFile}
                    >
                        {translation('OpenFile')}
                    </WKButton>
                    <WKButton
                        data-testid={'ai-search-result-file-insert-' + candidate.nodeId}
                        type="primary"
                        white
                        className="!color-$wk-l2-label-color-gray-11 active:!bg-$wk-gray-3 active:!text-black"
                        size="small"
                        onClick={onApplyNode}
                    >
                        {translation('Insert')}
                    </WKButton>
                </div>
            </div>
            <img
                src={candidate.nodeThumbnailUrl}
                className="box-border w-242px h-180px object-contain bg-$wk-gray-3 p-16px rounded-3px"
            />
            <div className="flex flex-row items-center gap-4px w-242px overflow-hidden">
                <AISearchNodeTypeIcon nodeType={candidate.nodeType} />
                <div className="wk-font-regular wk-text-12 text-black overflow-hidden text-ellipsis whitespace-nowrap">
                    {candidate.nodeName}
                </div>
            </div>
        </div>
    )
}
