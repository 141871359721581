import { MonoIconCommonAdd16, WKIconButton } from '../../../../../../ui-lib/src'

interface ChatHeaderProps {
    onCreate: () => void
}
export const ChatHeader = (props: ChatHeaderProps) => {
    const { onCreate } = props
    return (
        <div className="w-full h-40px box-border flex items-center justify-between px-10px">
            <div className="">New Chat</div>
            <WKIconButton icon={<MonoIconCommonAdd16 />} onClick={onCreate} />
        </div>
    )
}
