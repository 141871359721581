import { FeatureSwitchConfig, SwitchEnvironmentScopeType, SwitchStrategyType } from '../feature-switch-config'

const Config: FeatureSwitchConfig = {
    name: 'paste-proto-to-ui',
    owner: '',
    description: '',
    strategies: [
        {
            config: {
                type: SwitchStrategyType.SPECIFIC_USER_LIST,
                userList: [
                    '6e0d717d148ad555a886889dd29521beb6c4f86c',
                    '93b2d50b70abd736fe8799361dc94170a0598567',
                    '07de847f05c0a1ab68862dd89660254ba39d540c',
                    'a016fbbfa2e137ebffd52005dccbad8d7e6df177',
                    'c25a7c619bd3d207ef342fb9328434439de70bd6',
                    '20dff2aaca522ac44bf55db0acda5212222716a4',
                    '8924f0f3c2562122c6dce85e95a7e585a82ba76c',
                    '848154b842d852a02cd56aae93f06e95617a3f5b',
                    '7a6d4891e0ed690ca1612ab6125d09ea8140eb5c',
                    'd7e45b450b5cd8fb9d806cfa0ace29746b996408',
                    'b67959a4aed14cd4cfb9c523449d62f3165ce575',
                    'cc448a05f972eaa52d0745e77d4b9fa4a9cafbc5',
                    'f7963b3f7e23de6c70e2dbe090cbce97183df403',
                    '3c80e31e15ffc00ad42f561d834062e3aa1163f1',
                    '57434b1c0afd109373efd66ab0cc5ed1569a4333',
                    '4d9483c205d8e43f3dbc03ca154248669f3320fd',
                    'a2c0199b5d34e5b9fd73c148b260dbe0f2a671be',
                ],
            },
            env: SwitchEnvironmentScopeType.TESTING,
        },
    ],
    alwaysDisableInTests: false,
}
export default Config
