import { MotiffApi } from '@motiffcom/plugin-api-types'
import { NodePluginData } from '../document/util/constant'

// TODO(chenyn): 支持定义类型
export interface WkObject {
    e2e: any
    disableToast: any
    enableToast: any
    disableMirrorToast: any
    enableMirrorToast: any
    wasmCall: any
    wasmCallLogs: any
    wasmCallLogsMonitorStatus: any
    wasmCallLogsMonitorBlackList: any
    jsCall: any
    doc: any
    docMessage: any
    allMessages: any
    getNode: any
    getChildren: any
    pushMetric: any
    getUsedMemory: any
    currentDocument: any
    getPagesCount: any
    currentPage: any
    currentPageChildren: any
    currentSelection: any
    getEditorMode: any
    getUndoStatus: any
    clearRefNode: any
    resetGeometry: any
    getWSStatus: any
    reconnectWS: any
    disconnectWS: any
    disableWSAutoReconnect: any
    getDBStatus: any
    getAIRecognizeState: any
    getBridgeMetric: any
    viewState: any
    updateViewState: any
    environment: any
    getUserConfig: any
    setUserConfig: any
    userInfo: any
    commitId: any
    releaseTag: any
    performance: any
    exportFigJson: any
    importFigJsonFromFile: any
    exportFigJsonToFile: any
    export: any
    locate: any
    overrideFeatureSwitch: any
    getFeatureSwitches: any
    printCurrentTreeRenderObject: any
    canvasScreenshot: any
    canvasScreenshotAsBase64: any
    canvasNodeScreenshot: any
    canvasNodeScreenshotAsBlob: any
    showMarkInfo: any
    downloadMarkInfo: any
    setViewport: any
    getPageWorldBounds: any
    aiCopilot: any
    schemaVersion: any
    getNotLoadedImageCount: any
    importAiRecognizeResult: any
    receiveSynergyMessage: any
    receiveCloseWebSocketFrame: any
    setAIUserId: any
    downloadBridgeRecording: any
    saveLocalEnabledFeatures: any
    saveLocalDisabledFeatures: any
    latestImportedDocId: any
    getRenderMetric: any
    showTracePanel: any
    cursorData: any
    appendStyleNodes2Container: any
    setAllMissFonts: any
    optionCopy: any
    zoomCenter: any
    moveViewport: any
    dragNode: any
    switchPage: any
    selectAll: any
    dragTangent: any
    dragVertex: any
    deletePage: any
    scale: any
    deleteAll: any
    deleteAllAndUndo: any
    addFillsToAll: any
    changeExportFormat: any
    scaleSpeedEvalFunction: any
    getUnsolvedResource: any
    getNodeSVGText: any
    onBatchOperation: any
    onBatchOperationWithBase64: any
    pasteProto: any
    loseContext: any
    restoreContext: any
    isCreatingFile: any
    prototypeStore: any
    printImageDiagnosis: any
    printBitmapDiagnosis: any
    rewritePublishProperties: any
    regenerateContentHash: any
    detachAllSelectedInstance: any
    aiGenUIJob: any
    detachFeatureSupport: any
    wheelEventDebugger: {
        // process variables
        isNonPixelDeltaMode: () => boolean
        isWheelDeltaSupported: () => boolean
        isFractionalDelta: () => boolean
        isLikelyTrackpad: () => boolean
        // final result
        originalDelta: () => [number, number]
        finalDelta: () => [number, number]
        // options
        deltaAbsRange: () => number
        changeDeltaAbsRange: () => (v: number) => void
    }
    simplifyAll: () => Promise<void>
    exportDiff: () => Promise<void>
    showOriginalHTML: () => void
    downloadNodeHTML: (enableFlex?: boolean, nodes?: MotiffApi.FrameNode[]) => Promise<void>
    experiment1: (content: string) => Promise<any>
    forceUpdateTextLayoutData: (nodeId?: string) => void
    uploadNodeAsHTML: () => Promise<void>
    openNodePrototypeHTMLInIFrame: () => Promise<void>
    getCanvas: () => HTMLCanvasElement | null
    getColorSpace: () => string
    variableLocalEditor: any
    orgId: string
    initialDocSchemaVersion: number
    variableChangeMode: any
    variableChangeColor: any
    importHTML: (
        ids: number[],
        useFlex: boolean,
        useTextRealSize: boolean,
        width: number,
        needScreenshot: boolean
    ) => Promise<void>
    parseHTMLToProto: (userHTML: string, width: number, useFlex: boolean, useTextRealSize: boolean) => Promise<string>
    importHTMLFromString: (userHTMLs: string[]) => Promise<void>
    exportLibraryDataByNodeId: (nodeId: string, type: 'component' | 'style' | 'variable' | 'variableCollection') => any
}

declare global {
    interface Window {
        wukong: Partial<WkObject>
        motiff: MotiffApi.PluginAPI
    }
}

// 在 node/worker 中定义 global this
if (!self.document) {
    self.window = self
}

// window = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : {};
const _: typeof window = (typeof window === undefined ? global : window) as any

// 初始化 wk 对象
export const WK = (_.wukong = {
    ..._.wukong,
    performance: new Map(),
})

WK.detachFeatureSupport = () => {
    delete WK.getFeatureSwitches
    delete WK.saveLocalDisabledFeatures
    delete WK.saveLocalEnabledFeatures
}
