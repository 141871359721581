import { MonoIconCommonInfoLine16, Tooltip } from '../../../../../../ui-lib/src'
import { CornerSizeMap, DesignSystemMap, ShadcnUIRadiusMap } from './constants'
import { translation } from './remix-design-system-corner-size.translation'
import { SelectingButton } from './selecting-button'
import { usePlatformAndDesignSystemInContext } from './use-platform-and-design-system-context'

function TooltipForShadcn() {
    const { tempRemixDesignSystem } = usePlatformAndDesignSystemInContext()
    if (tempRemixDesignSystem !== DesignSystemMap.shadcn) {
        return null
    }

    return (
        <Tooltip
            overlay={translation('cornerSizeToolTip')
                .split('\n')
                .map((line) => (
                    <p className="wk-break-word p-0 m-0" key={line}>
                        {line.trim()}
                    </p>
                ))}
        >
            <MonoIconCommonInfoLine16 className="text-[var(--wk-l2-label-color-gray-8)]" />
        </Tooltip>
    )
}

export function RemixDesignSystemCornerSize() {
    const { updateRemixConfig, tempRemixConfig, tempRemixDesignSystem } = usePlatformAndDesignSystemInContext()

    const cornerSizes =
        tempRemixDesignSystem === DesignSystemMap.shadcn
            ? Object.values(ShadcnUIRadiusMap).map((size) => ({ label: size, value: size }))
            : Object.values(CornerSizeMap).map((size) => ({ label: translation(size), value: size }))

    if (tempRemixConfig === null) {
        return null
    }

    return (
        <div className="flex flex-col gap-2 mb-0.5">
            <div className="flex flex-row gap-1 items-center">
                <span className="wk-text-12 wk-font-semibold">{translation('cornerSize')}</span>
                <TooltipForShadcn />
            </div>
            <div className="flex flex-row gap-2">
                {cornerSizes.map(({ label, value }) => {
                    const isSelected = () => {
                        return value.toString() === tempRemixConfig.get('cornerSize')
                    }
                    const onClick = () => {
                        const newConfig = value.toString()
                        updateRemixConfig('cornerSize', newConfig)
                    }
                    return (
                        <SelectingButton
                            key={value}
                            onClick={onClick}
                            isSelected={isSelected()}
                            extraClass="h-8 w-15.5"
                        >
                            <span className="">{label}</span>
                        </SelectingButton>
                    )
                })}
            </div>
        </div>
    )
}
