
import * as $protobuf from "protobufjs/dist/light/protobuf.min";

const $root = ($protobuf.roots["default"] || ($protobuf.roots["default"] = new $protobuf.Root()))
.addJSON({
  Wukong: {
    nested: {
                  NotifyProto: {

                options: {

                    optimize_for: 'LITE_RUNTIME',

                    java_package: 'com.yuanli.wukong.notify.connector.proto',

                    java_outer_classname: 'MessageProtos',

                },

                nested: {

                    MessageTypeCode: {

                        values: {

                            TC_: 0,

                            TC_Ping: 100,

                            TC_Pong: 101,

                            TC_Auth: 200,

                            TC_AuthResult: 201,

                            TC_UserConnected: 300,

                            TC_UserDisconnected: 301,

                            TC_UserRefreshLease: 302,

                            TC_Error: 500,

                            TC_BusinessMessage: 1000,

                            TC_SubscriptionEnable: 2000,

                            TC_SubscriptionDisable: 2001,

                        },

                    },

                    BusinessCode: {

                        values: {

                            UNKNOWN: 0,

                            HELLO_WORLD: 1,

                            COMMENT: 2,

                            MIRROR: 3,

                            BUSINESS_ENTITY_PROPERTY_CHANGE: 4,

                            BUSINESS_ENTITY_RELATION_CHANGE: 5,

                            MESSAGE_CENTER_MESSAGE: 7,

                        },

                    },

                    MessageProto: {

                        fields: {

                            messageType: {

                                type: 'MessageTypeCode',

                                id: 1,

                            },

                            payload: {

                                type: 'bytes',

                                id: 4,

                            },

                        },

                    },

                    BusinessMessageProto: {

                        fields: {

                            businessCode: {

                                type: 'BusinessCode',

                                id: 1,

                            },

                            receiverUserId: {

                                type: 'int64',

                                id: 2,

                            },

                            receiverSid: {

                                type: 'int64',

                                id: 3,

                            },

                            payload: {

                                type: 'bytes',

                                id: 4,

                            },

                            messageId: {

                                type: 'string',

                                id: 5,

                            },

                        },

                    },

                    SubscriptionProto: {

                        fields: {

                            orgId: {

                                type: 'string',

                                id: 1,

                            },

                            subscribeFilters: {

                                keyType: 'int32',

                                type: 'SubscriptionFilterCollectionProto',

                                id: 2,

                            },

                        },

                    },

                    SubscriptionFilterCollectionProto: {

                        fields: {

                            filters: {

                                rule: 'repeated',

                                type: 'SubscriptionFilterProto',

                                id: 1,

                            },

                        },

                    },

                    SubscriptionFilterProto: {

                        fields: {

                            filterParameters: {

                                rule: 'repeated',

                                type: 'SubscriptionFilterParameterProto',

                                id: 1,

                            },

                        },

                    },

                    SubscriptionFilterParameterProto: {

                        fields: {

                            name: {

                                type: 'string',

                                id: 1,

                            },

                            value: {

                                type: 'string',

                                id: 2,

                            },

                        },

                    },

                    ErrorProto: {

                        fields: {

                            errorCode: {

                                type: 'ErrorCode',

                                id: 1,

                            },

                            subCode: {

                                type: 'int32',

                                id: 2,

                            },

                        },

                    },

                    ErrorCode: {

                        values: {

                            ERROR_CODE_UNKNOWN: 0,

                            ERROR_CODE_DECODE_ERROR: 1,

                            ERROR_CODE_UNKNOWN_MESSAGE: 2,

                            ERROR_CODE_CONNECT_ERROR: 3,

                            ERROR_CODE_HEARTBEAT_LOST: 4,

                            ERROR_CODE_SERVER_DOWN: 5,

                            ERROR_CODE_SERVER_INTERNAL_ERROR: 6,

                            ERROR_CODE_ILLEGAL_URI: 7,

                        },

                    },

                    KickProto: {

                        fields: {

                            kickCode: {

                                type: 'KickCode',

                                id: 1,

                            },

                            subCode: {

                                type: 'int32',

                                id: 2,

                            },

                        },

                    },

                    KickCode: {

                        values: {

                            KICK_CODE_UNKNOWN: 0,

                            KICK_CODE_ILLEGAL_REQUEST: 1,

                            KICK_CODE_DOC_DELETED: 2,

                            KICK_CODE_PERMISSION_LOST: 3,

                            KICK_CODE_SERVER_ERROR: 4,

                            KICK_CODE_PARTITION_MOVED: 5,

                        },

                    },

                    PingProto: {

                        fields: {

                            timestamp: {

                                type: 'int64',

                                id: 1,

                            },

                        },

                    },

                    PongProto: {

                        fields: {

                            pingTimestamp: {

                                type: 'int64',

                                id: 1,

                            },

                            timestamp: {

                                type: 'int64',

                                id: 2,

                            },

                        },

                    },

                    AuthProto: {

                        fields: {

                            ticketToken: {

                                type: 'string',

                                id: 1,

                            },

                            secretKey: {

                                type: 'string',

                                id: 2,

                            },

                        },

                    },

                    AuthResultProto: {

                        fields: {

                            status: {

                                type: 'int32',

                                id: 1,

                            },

                            sessionId: {

                                type: 'int64',

                                id: 2,

                            },

                        },

                    },

                    UserConnectedProto: {

                        fields: {

                            userId: {

                                type: 'int64',

                                id: 1,

                            },

                            sessionId: {

                                type: 'int64',

                                id: 2,

                            },

                        },

                    },

                    UserDisconnectedProto: {

                        fields: {

                            userId: {

                                type: 'int64',

                                id: 1,

                            },

                            sessionId: {

                                type: 'int64',

                                id: 2,

                            },

                        },

                    },

                    HelloWorldProto: {

                        fields: {

                            msg: {

                                type: 'string',

                                id: 1,

                            },

                        },

                    },

                    Comment: {

                        fields: {

                            id: {

                                type: 'int64',

                                id: 1,

                            },

                            docId: {

                                type: 'string',

                                id: 2,

                            },

                            parentId: {

                                type: 'int64',

                                id: 3,

                            },

                            owner: {

                                type: 'UserProfile',

                                id: 4,

                            },

                            message: {

                                type: 'string',

                                id: 5,

                            },

                            resolved: {

                                type: 'bool',

                                id: 6,

                            },

                            deleted: {

                                type: 'bool',

                                id: 7,

                            },

                            createdTime: {

                                type: 'int64',

                                id: 8,

                            },

                            updatedTime: {

                                type: 'int64',

                                id: 9,

                            },

                            page: {

                                type: 'string',

                                id: 10,

                            },

                            position: {

                                type: 'Position',

                                id: 11,

                            },

                            mentionedUsers: {

                                rule: 'repeated',

                                type: 'UserProfile',

                                id: 12,

                            },

                            reactions: {

                                rule: 'repeated',

                                type: 'Reaction',

                                id: 13,

                            },

                            messageText: {

                                type: 'string',

                                id: 14,

                            },

                            seq: {

                                type: 'int32',

                                id: 15,

                            },

                            lastEditTime: {

                                type: 'int64',

                                id: 16,

                            },

                            uuid: {

                                type: 'string',

                                id: 17,

                            },

                        },

                    },

                    UserProfile: {

                        fields: {

                            id: {

                                type: 'int64',

                                id: 1,

                            },

                            nickname: {

                                type: 'string',

                                id: 2,

                            },

                            avatarId: {

                                type: 'string',

                                id: 3,

                            },

                            email: {

                                type: 'string',

                                id: 4,

                            },

                            desensitizedPhone: {

                                type: 'string',

                                id: 5,

                            },

                            avatarBackgroundColor: {

                                type: 'string',

                                id: 6,

                            },

                        },

                    },

                    Reaction: {

                        fields: {

                            owner: {

                                type: 'UserProfile',

                                id: 1,

                            },

                            message: {

                                type: 'string',

                                id: 2,

                            },

                            id: {

                                type: 'int64',

                                id: 3,

                            },

                        },

                    },

                    Position: {

                        fields: {

                            nodeId: {

                                type: 'string',

                                id: 1,

                            },

                            x: {

                                type: 'double',

                                id: 2,

                            },

                            y: {

                                type: 'double',

                                id: 3,

                            },

                            offsetX: {

                                type: 'double',

                                id: 4,

                            },

                            offsetY: {

                                type: 'double',

                                id: 5,

                            },

                            anchorX: {

                                type: 'double',

                                id: 6,

                            },

                            anchorY: {

                                type: 'double',

                                id: 7,

                            },

                            deleted: {

                                type: 'bool',

                                id: 8,

                            },

                            hasAnchor: {

                                type: 'bool',

                                id: 9,

                            },

                        },

                    },

                    Mirror: {

                        fields: {

                            docId: {

                                type: 'string',

                                id: 1,

                            },

                            frameId: {

                                type: 'string',

                                id: 2,

                            },

                            userId: {

                                type: 'int64',

                                id: 3,

                            },

                            frameName: {

                                type: 'string',

                                id: 4,

                            },

                        },

                    },

                    BusinessEntity: {

                        fields: {

                            entityType: {

                                type: 'EntityType',

                                id: 1,

                            },

                            entityId: {

                                type: 'string',

                                id: 2,

                            },

                            ancestors: {

                                rule: 'repeated',

                                type: 'BusinessEntity',

                                id: 3,

                            },

                        },

                    },

                    BusinessEntityPropertiesChange: {

                        fields: {

                            businessEntity: {

                                type: 'BusinessEntity',

                                id: 1,

                            },

                            changedProperties: {

                                keyType: 'string',

                                type: 'string',

                                id: 2,

                            },

                        },

                    },

                    EntityType: {

                        values: {

                            UNKNOWN_ENTITY_TYPE: 0,

                            USER: 1,

                            DOC: 2,

                            FOLDER: 3,

                            TEAM: 4,

                            ORG: 5,

                            LIBRARY: 6,

                            DOC_PROTOTYPE: 7,

                            WORKSPACE: 8,

                        },

                    },

                    BusinessEntityRelation: {

                        fields: {

                            one: {

                                type: 'BusinessEntity',

                                id: 1,

                            },

                            another: {

                                type: 'BusinessEntity',

                                id: 2,

                            },

                            relation: {

                                type: 'Relation',

                                id: 3,

                            },

                        },

                    },

                    BusinessEntityRelationChange: {

                        fields: {

                            relation: {

                                type: 'BusinessEntityRelation',

                                id: 1,

                            },

                            changeType: {

                                type: 'RelationChangeType',

                                id: 2,

                            },

                        },

                    },

                    RelationChangeType: {

                        values: {

                            UNKNOWN_CHANGE_TYPE: 0,

                            ADD: 1,

                            REMOVE: 2,

                            UPDATE: 3,

                        },

                    },

                    Relation: {

                        values: {

                            UNKNOWN_Relation: 0,

                            AUTHORIZATION: 1,

                            BELONG_TO: 2,

                            ACCESS: 3,

                            LIBRARY_SUBSCRIPTION: 4,

                            DEFAULT_LIBRARY_SUBSCRIPTION: 5,

                        },

                    },

                    MessageCenterProto: {

                        fields: {

                            type: {

                                type: 'MessageCenterMessageTypeProto',

                                id: 1,

                            },

                            payload: {

                                type: 'bytes',

                                id: 2,

                            },

                            orgId: {

                                type: 'string',

                                id: 3,

                            },

                            receiverUserId: {

                                type: 'int64',

                                id: 4,

                            },

                        },

                    },

                    MessageCenterNewMessageProto: {

                        fields: {

                            type: {

                                type: 'MessageCenterNewMessageTypeProto',

                                id: 1,

                            },

                            payload: {

                                type: 'bytes',

                                id: 2,

                            },

                            messageId: {

                                type: 'int64',

                                id: 3,

                            },

                            messageCreateTimeMillion: {

                                type: 'int64',

                                id: 4,

                            },

                        },

                    },

                    MessageCenterModifyMessageProto: {

                        fields: {

                            messageId: {

                                type: 'int64',

                                id: 1,

                            },

                            modifyType: {

                                type: 'MessageCenterMessageModifyTypeProto',

                                id: 2,

                            },

                        },

                    },

                    MessageCenterCommentProto: {

                        fields: {

                            publishUser: {

                                type: 'UserProfile',

                                id: 1,

                            },

                            id: {

                                type: 'int64',

                                id: 2,

                            },

                            mentionedUsers: {

                                rule: 'repeated',

                                type: 'UserProfile',

                                id: 3,

                            },

                            resolved: {

                                type: 'bool',

                                id: 4,

                            },

                            messageText: {

                                type: 'string',

                                id: 5,

                            },

                            createTime: {

                                type: 'int64',

                                id: 6,

                            },

                            docId: {

                                type: 'string',

                                id: 7,

                            },

                            type: {

                                type: 'MessageCenterCommentTypeProto',

                                id: 8,

                            },

                            docName: {

                                type: 'string',

                                id: 9,

                            },

                            thumbnail: {

                                type: 'string',

                                id: 10,

                            },

                            docBackgroundColor: {

                                type: 'string',

                                id: 11,

                            },

                            parentId: {

                                type: 'int64',

                                id: 12,

                            },

                            pageId: {

                                type: 'string',

                                id: 13,

                            },

                            nodeId: {

                                type: 'string',

                                id: 14,

                            },

                            thumbnailInfo: {

                                type: 'string',

                                id: 15,

                            },

                        },

                    },

                    MessageCenterCommentTypeProto: {

                        values: {

                            UNKNOWN_MESSAGE_CENTER_COMMENT_TYPE: 0,

                            MESSAGE_CENTER_NEW_COMMENT_TYPE: 1,

                            MESSAGE_CENTER_REPLY_COMMENT_TYPE: 2,

                            MESSAGE_CENTER_MENTION_COMMENT_TYPE: 3,

                        },

                    },

                    MessageCenterJoinRequestProto: {

                        fields: {

                            requestUser: {

                                type: 'UserProfile',

                                id: 1,

                            },

                            id: {

                                type: 'int64',

                                id: 2,

                            },

                            reason: {

                                type: 'string',

                                id: 3,

                            },

                            roleToBe: {

                                type: 'UserRoleEnum',

                                id: 4,

                            },

                            resource: {

                                type: 'MessageCenterResource',

                                id: 5,

                            },

                        },

                    },

                    MessageCenterJoinApplyProto: {

                        fields: {

                            processUser: {

                                type: 'UserProfile',

                                id: 1,

                            },

                            joinRequest: {

                                type: 'MessageCenterJoinRequestProto',

                                id: 2,

                            },

                            approved: {

                                type: 'bool',

                                id: 3,

                            },

                            actualRole: {

                                type: 'UserRoleEnum',

                                id: 4,

                            },

                        },

                    },

                    MessageCenterSeatApplicationProto: {

                        fields: {

                            id: {

                                type: 'int64',

                                id: 1,

                            },

                            requestUser: {

                                type: 'UserProfile',

                                id: 2,

                            },

                            resource: {

                                type: 'MessageCenterResource',

                                id: 3,

                            },

                            seatType: {

                                type: 'SeatTypeEnum',

                                id: 4,

                            },

                            autoUpgrade: {

                                type: 'bool',

                                id: 5,

                            },

                            gracePeriodDay: {

                                type: 'int32',

                                id: 6,

                            },

                            reason: {

                                type: 'string',

                                id: 7,

                            },

                        },

                    },

                    MessageCenterSeatApplyResolvedProto: {

                        fields: {

                            operatorUser: {

                                type: 'UserProfile',

                                id: 1,

                            },

                            requestUser: {

                                type: 'UserProfile',

                                id: 2,

                            },

                            resource: {

                                type: 'MessageCenterResource',

                                id: 3,

                            },

                            approved: {

                                type: 'bool',

                                id: 4,

                            },

                            seatType: {

                                type: 'SeatTypeEnum',

                                id: 5,

                            },

                        },

                    },

                    MessageCenterSeatUpdateProto: {

                        fields: {

                            operatorUser: {

                                type: 'UserProfile',

                                id: 1,

                            },

                            operatedUser: {

                                type: 'UserProfile',

                                id: 2,

                            },

                            resource: {

                                type: 'MessageCenterResource',

                                id: 3,

                            },

                            upgrade: {

                                type: 'bool',

                                id: 4,

                            },

                            seatType: {

                                type: 'SeatTypeEnum',

                                id: 5,

                            },

                        },

                    },

                    MessageCenterInvitationProto: {

                        fields: {

                            inviteUser: {

                                type: 'UserProfile',

                                id: 1,

                            },

                            roleEnum: {

                                type: 'UserRoleEnum',

                                id: 2,

                            },

                            resource: {

                                type: 'MessageCenterResource',

                                id: 3,

                            },

                            inviteRedirectUrl: {

                                type: 'string',

                                id: 4,

                            },

                        },

                    },

                    MessageCenterAssetHandover: {

                        fields: {

                            processUser: {

                                type: 'UserProfile',

                                id: 1,

                            },

                            fromUser: {

                                type: 'UserProfile',

                                id: 2,

                            },

                            toUser: {

                                type: 'UserProfile',

                                id: 3,

                            },

                            resources: {

                                rule: 'repeated',

                                type: 'MessageCenterResource',

                                id: 4,

                            },

                            fromTeam: {

                                type: 'MessageCenterResource',

                                id: 5,

                            },

                        },

                    },

                    MessageCenterMarkOpened: {

                        fields: {

                            lastOpenedTime: {

                                type: 'int64',

                                id: 1,

                            },

                        },

                    },

                    PluginEditInvitationProto: {

                        fields: {

                            inviteUser: {

                                type: 'UserProfile',

                                id: 1,

                            },

                            role: {

                                type: 'string',

                                id: 2,

                            },

                            orgResource: {

                                type: 'MessageCenterResource',

                                id: 3,

                            },

                            pluginId: {

                                type: 'string',

                                id: 4,

                            },

                            pluginName: {

                                type: 'string',

                                id: 5,

                            },

                        },

                    },

                    MessageCenterResource: {

                        fields: {

                            id: {

                                type: 'string',

                                id: 1,

                            },

                            type: {

                                type: 'EntityType',

                                id: 2,

                            },

                            name: {

                                type: 'string',

                                id: 3,

                            },

                            iconOrThumb: {

                                type: 'string',

                                id: 4,

                            },

                            docBackgroundColor: {

                                type: 'string',

                                id: 5,

                            },

                            thumbnailInfo: {

                                type: 'string',

                                id: 6,

                            },

                            iconBackgroundColor: {

                                type: 'string',

                                id: 7,

                            },

                        },

                    },

                    UserRoleEnum: {

                        values: {

                            NONE: 0,

                            VIEWER: 1,

                            EDITOR: 2,

                            ADMIN: 3,

                            OWNER: 4,

                            VIEW_PROTOTYPE: 5,

                        },

                    },

                    SeatTypeEnum: {

                        values: {

                            UNKNOWN_SEAT_TYPE: 0,

                            VIEWER_SEAT_TYPE: 1,

                            DEVELOPER_SEAT_TYPE: 2,

                            DESIGNER_SEAT_TYPE: 3,

                        },

                    },

                    SeatUpgradeTypeEnum: {

                        values: {

                            UPGRADE_TYPE_UNKNOWN: 0,

                            NEED_APPROVE: 1,

                            AUTO_UPGRADE: 2,

                        },

                    },

                    MessageCenterMessageTypeProto: {

                        values: {

                            UNKNOWN_MESSAGE_CENTER_MESSAGE_TYPE: 0,

                            NEW_MESSAGE_CENTER_MESSAGE_TYPE: 1,

                            MODIFY_MESSAGE_CENTER_MESSAGE_TYPE: 2,

                        },

                    },

                    MessageCenterMessageModifyTypeProto: {

                        values: {

                            UNKNOWN_MESSAGE_CENTER_MESSAGE_MODIFY_TYPE: 0,

                            MESSAGE_CENTER_MESSAGE_READ: 1,

                            MESSAGE_CENTER_MESSAGE_DELETE: 2,

                            MESSAGE_CENTER_MESSAGE_RECOVER: 3,

                        },

                    },

                    MessageCenterNewMessageTypeProto: {

                        values: {

                            UNKNOWN_MESSAGE_CENTER_NEW_MESSAGE_TYPE: 0,

                            NEW_COMMENT_MESSAGE_CENTER_MESSAGE_TYPE: 1,

                            REPLY_COMMENT_MESSAGE_CENTER_MESSAGE_TYPE: 2,

                            MENTION_COMMENT_MESSAGE_CENTER_MESSAGE_TYPE: 3,

                            JOIN_REQUEST_MESSAGE_CENTER_MESSAGE_TYPE: 4,

                            JOIN_APPLY_MESSAGE_CENTER_MESSAGE_TYPE: 5,

                            INVITATION_MESSAGE_CENTER_MESSAGE_TYPE: 6,

                            ASSET_HANDOVER_MESSAGE_CENTER_MESSAGE_TYPE: 7,

                            MARK_OPENED_MESSAGE_CENTER_MESSAGE_TYPE: 8,

                            SEAT_APPLICATION_MESSAGE_CENTER_MESSAGE_TYPE: 9,

                            SEAT_APPLY_RESOLVED_MESSAGE_CENTER_MESSAGE_TYPE: 10,

                            SEAT_UPDATE_MESSAGE_CENTER_MESSAGE_TYPE: 11,

                            PLUGIN_EDIT_INVITATION_MESSAGE_CENTER_MESSAGE_TYPE: 12,

                        },

                    },

                },

            },

                  ServerProto: {

                options: {

                    optimize_for: 'LITE_RUNTIME',

                    java_package: 'com.yuanli.wukong.synergy.proto',

                    java_outer_classname: 'SynergyProtos',

                },

                nested: {

                    SynergyOperationIndex: {

                        fields: {

                            minTxId: {

                                type: 'int64',

                                id: 1,

                            },

                            maxTxId: {

                                type: 'int64',

                                id: 2,

                            },

                            epoch: {

                                type: 'int32',

                                id: 3,

                            },

                        },

                    },

                    SynergyOperationId: {

                        fields: {

                            userId: {

                                type: 'int64',

                                id: 1,

                            },

                            sid: {

                                type: 'int32',

                                id: 2,

                            },

                            requestId: {

                                type: 'int32',

                                id: 3,

                            },

                        },

                    },

                    CompressType: {

                        values: {

                            COMPRESS_TYPE_NO_COMPRESS: 0,

                            COMPRESS_TYPE_ZLIB: 1,

                            COMPRESS_TYPE_LZ4: 2,

                            COMPRESS_TYPE_SNAPPY: 3,

                            COMPRESS_TYPE_BROTLI: 4,

                        },

                    },

                    SynergyMessageTypeCode: {

                        values: {

                            TC_: 0,

                            TC_Ping: 300,

                            TC_Pong: 301,

                            TC_Document: 1000,

                            TC_Auth: 2001,

                            TC_AuthResult: 2002,

                            TC_BatchOperation: 1500,

                            TC_BatchOperationAck: 1501,

                            TC_Membership: 2003,

                            TC_UpdateUserProperties: 2004,

                            TC_SyncUserProperties: 2005,

                            TC_UserEnter: 2006,

                            TC_UserQuit: 2007,

                            TC_UserInactive: 2008,

                            TC_UserActive: 2009,

                            TC_MirrorPreview: 2010,

                            TC_UserSynergyRoleChange: 2011,

                            TC_SceneGraphQuery: 2012,

                            TC_SceneGraphReply: 2013,

                            TC_AuthChanged: 2014,

                            TC_UserSessionChanged: 2015,

                        },

                    },

                    SynergyMessageProto: {

                        oneofs: {

                            _serverPayload: {

                                oneof: ['serverPayload'],

                            },

                            _finalFragment: {

                                oneof: ['finalFragment'],

                            },

                            _checksum: {

                                oneof: ['checksum'],

                            },

                            _prevChecksum: {

                                oneof: ['prevChecksum'],

                            },

                            _docHash: {

                                oneof: ['docHash'],

                            },

                            _sid: {

                                oneof: ['sid'],

                            },

                            _index: {

                                oneof: ['index'],

                            },

                            _onlyDiff: {

                                oneof: ['onlyDiff'],

                            },

                            _pagedArchive: {

                                oneof: ['pagedArchive'],

                            },

                            _payloadBeforeCompressSize: {

                                oneof: ['payloadBeforeCompressSize'],

                            },

                        },

                        fields: {

                            type: {

                                type: 'int32',

                                id: 1,

                            },

                            header: {

                                rule: 'repeated',

                                type: 'HeaderProto',

                                id: 2,

                            },

                            payload: {

                                type: 'bytes',

                                id: 3,

                            },

                            version: {

                                type: 'int32',

                                id: 4,

                            },

                            serverPayload: {

                                type: 'bytes',

                                id: 5,

                                options: {

                                    proto3_optional: true,

                                },

                            },

                            finalFragment: {

                                type: 'bool',

                                id: 6,

                                options: {

                                    proto3_optional: true,

                                },

                            },

                            checksum: {

                                type: 'int64',

                                id: 7,

                                options: {

                                    proto3_optional: true,

                                },

                            },

                            prevChecksum: {

                                type: 'int64',

                                id: 8,

                                options: {

                                    proto3_optional: true,

                                },

                            },

                            docHash: {

                                type: 'uint64',

                                id: 9,

                                options: {

                                    proto3_optional: true,

                                },

                            },

                            compressType: {

                                type: 'CompressType',

                                id: 10,

                            },

                            sid: {

                                type: 'int32',

                                id: 11,

                                options: {

                                    proto3_optional: true,

                                },

                            },

                            index: {

                                type: 'SynergyOperationIndex',

                                id: 12,

                                options: {

                                    proto3_optional: true,

                                },

                            },

                            opLogs: {

                                rule: 'repeated',

                                type: 'bytes',

                                id: 13,

                            },

                            onlyDiff: {

                                type: 'bool',

                                id: 14,

                                options: {

                                    proto3_optional: true,

                                },

                            },

                            pagedArchive: {

                                type: 'bool',

                                id: 15,

                                options: {

                                    proto3_optional: true,

                                },

                            },

                            payloadBeforeCompressSize: {

                                type: 'int32',

                                id: 16,

                                options: {

                                    proto3_optional: true,

                                },

                            },

                        },

                    },

                    HeaderProto: {

                        fields: {

                            key: {

                                type: 'string',

                                id: 1,

                            },

                            value: {

                                type: 'string',

                                id: 2,

                            },

                        },

                    },

                    ErrorProto: {

                        fields: {

                            errorCode: {

                                type: 'ErrorCode',

                                id: 1,

                            },

                            subCode: {

                                type: 'int32',

                                id: 2,

                            },

                        },

                    },

                    ErrorCode: {

                        values: {

                            ERROR_CODE_UNKNOWN: 0,

                            ERROR_CODE_DECODE_ERROR: 1,

                            ERROR_CODE_UNKNOWN_MESSAGE: 2,

                            ERROR_CODE_CONNECT_ERROR: 3,

                            ERROR_CODE_HEARTBEAT_LOST: 4,

                            ERROR_CODE_SERVER_DOWN: 5,

                            ERROR_CODE_SERVER_INTERNAL_ERROR: 6,

                            ERROR_CODE_ILLEGAL_URI: 7,

                        },

                    },

                    PingProto: {

                        fields: {

                            timestamp: {

                                type: 'int64',

                                id: 1,

                            },

                        },

                    },

                    PongProto: {

                        fields: {

                            pingTimestamp: {

                                type: 'int64',

                                id: 1,

                            },

                            timestamp: {

                                type: 'int64',

                                id: 2,

                            },

                        },

                    },

                    RejectReason: {

                        values: {

                            REJECT_REASON_UNKNOWN: 0,

                            REJECT_REASON_NODE_ALREADY_EXIST: 1,

                            REJECT_REASON_NODE_NOT_EXIST: 2,

                            REJECT_REASON_PARENT_NOT_EXIST: 3,

                            REJECT_REASON_CYCLE_CONFLICT: 4,

                            REJECT_REASON_ILLEGAL_ARGUMENT: 5,

                        },

                    },

                    AuthProto: {

                        fields: {

                            docId: {

                                type: 'string',

                                id: 1,

                            },

                            userId: {

                                type: 'int64',

                                id: 2,

                            },

                            nickname: {

                                type: 'string',

                                id: 3,

                            },

                            avatarId: {

                                type: 'string',

                                id: 4,

                            },

                            lastOperationID: {

                                type: 'SynergyOperationId',

                                id: 5,

                            },

                            ticketToken: {

                                type: 'string',

                                id: 6,

                            },

                            secretKey: {

                                type: 'string',

                                id: 7,

                            },

                            operationIndex: {

                                type: 'SynergyOperationIndex',

                                id: 8,

                            },

                        },

                    },

                    AuthResultProto: {

                        fields: {

                            status: {

                                type: 'int32',

                                id: 1,

                            },

                            sessionId: {

                                type: 'int32',

                                id: 2,

                            },

                            role: {

                                type: 'string',

                                id: 3,

                            },

                            results: {

                                rule: 'repeated',

                                type: 'AuthResultItem',

                                id: 4,

                            },

                        },

                    },

                    AuthFeatureType: {

                        values: {

                            UNKNOWN_AUTH_FEATURE_TYPE: 0,

                            DEV_MODE: 1,

                        },

                    },

                    AuthResultItem: {

                        oneofs: {

                            _payload: {

                                oneof: ['payload'],

                            },

                        },

                        fields: {

                            authFeatureType: {

                                type: 'int32',

                                id: 1,

                            },

                            hasAccess: {

                                type: 'bool',

                                id: 2,

                            },

                            payload: {

                                type: 'bytes',

                                id: 3,

                                options: {

                                    proto3_optional: true,

                                },

                            },

                        },

                    },

                    AuthChangedProto: {

                        fields: {

                            changeResults: {

                                rule: 'repeated',

                                type: 'AuthResultItem',

                                id: 1,

                            },

                        },

                    },

                    EditorType: {

                        values: {

                            UNKNOWN: 0,

                            Design: 1,

                            Dev: 2,

                        },

                    },

                    UserSessionChanged: {

                        oneofs: {

                            _editorType: {

                                oneof: ['editorType'],

                            },

                        },

                        fields: {

                            editorType: {

                                type: 'EditorType',

                                id: 1,

                                options: {

                                    proto3_optional: true,

                                },

                            },

                        },

                    },

                    DevModeCiphers: {

                        fields: {

                            ciphers: {

                                rule: 'repeated',

                                type: 'bytes',

                                id: 1,

                            },

                        },

                    },

                    ImportedCheckpointProto: {

                        fields: {

                            rootNodeId: {

                                type: 'string',

                                id: 1,

                            },

                            node: {

                                rule: 'repeated',

                                type: 'NodeProto',

                                id: 2,

                            },

                        },

                    },

                    NodeProto: {

                        fields: {

                            id: {

                                type: 'string',

                                id: 1,

                            },

                            property: {

                                keyType: 'int32',

                                type: 'bytes',

                                id: 5,

                            },

                        },

                    },

                    UserInactiveProto: {

                        fields: {},

                    },

                    UserActiveProto: {

                        fields: {},

                    },

                    MirrorPreviewProto: {

                        fields: {

                            docId: {

                                type: 'string',

                                id: 1,

                            },

                            nodeId: {

                                type: 'string',

                                id: 2,

                            },

                        },

                    },

                    SceneGraphQuery: {

                        fields: {

                            nodeId: {

                                type: 'string',

                                id: 1,

                            },

                            depth: {

                                type: 'int32',

                                id: 2,

                            },

                        },

                    },

                    SceneGraphQueriesProto: {

                        fields: {

                            docId: {

                                type: 'string',

                                id: 1,

                            },

                            sceneGraphQueries: {

                                rule: 'repeated',

                                type: 'SceneGraphQuery',

                                id: 2,

                            },

                        },

                    },

                },

            },

      DocumentProto:
{
  "options": {
    "optimize_for": "LITE_RUNTIME",
    "java_package": "com.yuanli.wukong.synergy.proto",
    "java_outer_classname": "ClientDocumentProtos"
  },
  "nested": {
    "AICandidateComponentType": {
      "values": {
        "A_I_CANDIDATE_COMPONENT_TYPE_NONE": 0,
        "A_I_CANDIDATE_COMPONENT_TYPE_COMPONENT": 1,
        "A_I_CANDIDATE_COMPONENT_TYPE_COLOR": 2,
        "A_I_CANDIDATE_COMPONENT_TYPE_FONT": 3,
        "A_I_CANDIDATE_COMPONENT_TYPE_EFFECT": 4
      }
    },
    "AIPoweredReason": {
      "values": {
        "A_I_POWERED_REASON_NONE": 0,
        "A_I_POWERED_REASON_CREATED": 1,
        "A_I_POWERED_REASON_ADD_AUTO_LAYOUT": 2,
        "A_I_POWERED_REASON_FROM_RECTANGLE": 3,
        "A_I_POWERED_REASON_FROM_VECTOR": 4,
        "A_I_POWERED_REASON_ROOT_FRAME": 5
      }
    },
    "ActionEnabledType": {
      "values": {
        "ACTION_ENABLED_TYPE_NONE": 0,
        "ACTION_ENABLED_TYPE_TOGGLE_SHOWN": 1,
        "ACTION_ENABLED_TYPE_TOGGLE_LOCKED": 2,
        "ACTION_ENABLED_TYPE_OUTLINE_STROKE": 3,
        "ACTION_ENABLED_TYPE_FLATTEN": 4,
        "ACTION_ENABLED_TYPE_REMOVE_FILL": 5,
        "ACTION_ENABLED_TYPE_REMOVE_STROKE": 6,
        "ACTION_ENABLED_TYPE_SWAP_FILL_AND_STROKE": 7,
        "ACTION_ENABLED_TYPE_USE_AS_MASK": 8,
        "ACTION_ENABLED_TYPE_GROUP_SELECTION": 9,
        "ACTION_ENABLED_TYPE_FRAME_SELECTION": 10,
        "ACTION_ENABLED_TYPE_UNGROUP_SELECTION": 11,
        "ACTION_ENABLED_TYPE_BRING_TO_FRONT": 12,
        "ACTION_ENABLED_TYPE_BRING_FORWARD": 13,
        "ACTION_ENABLED_TYPE_SEND_BACKWARD": 14,
        "ACTION_ENABLED_TYPE_SEND_TO_BACK": 15,
        "ACTION_ENABLED_TYPE_ADD_AUTO_LAYOUT": 16,
        "ACTION_ENABLED_TYPE_BOOL_SELECTION": 17,
        "ACTION_ENABLED_TYPE_UNION": 18,
        "ACTION_ENABLED_TYPE_SUBTRACT": 19,
        "ACTION_ENABLED_TYPE_INTERSECT": 20,
        "ACTION_ENABLED_TYPE_EXCLUDE": 21,
        "ACTION_ENABLED_TYPE_FLIP_HORIZONTAL": 22,
        "ACTION_ENABLED_TYPE_FLIP_VERTICAL": 23,
        "ACTION_ENABLED_TYPE_COPY": 24,
        "ACTION_ENABLED_TYPE_PASTE_HERE": 25,
        "ACTION_ENABLED_TYPE_PASTE_TO_REPLACE": 26,
        "ACTION_ENABLED_TYPE_REMOVE_AUTO_LAYOUT": 27,
        "ACTION_ENABLED_TYPE_TOGGLE_FULL_SCREEN": 28,
        "ACTION_ENABLED_TYPE_COMPONENT_SELECTION": 29,
        "ACTION_ENABLED_TYPE_RENAME": 30,
        "ACTION_ENABLED_TYPE_RESET_PROPERTIES": 31,
        "ACTION_ENABLED_TYPE_DETACH_INSTANCE": 32,
        "ACTION_ENABLED_TYPE_PUBLISH_SELECTED_NODES": 33,
        "ACTION_ENABLED_TYPE_GO_TO_SELECTED_INSTANCE_MAIN_COMPONENT": 34,
        "ACTION_ENABLED_TYPE_COPY_PASTE": 35,
        "ACTION_ENABLED_TYPE_COPY_AS_LINK": 36,
        "ACTION_ENABLED_TYPE_COPY_AS_TEXT": 37,
        "ACTION_ENABLED_TYPE_COPY_AS_S_V_G": 38,
        "ACTION_ENABLED_TYPE_COPY_AS_P_N_G": 39,
        "ACTION_ENABLED_TYPE_COPY_PROPERTIES": 40,
        "ACTION_ENABLED_TYPE_PASTE_PROPERTIES": 41,
        "ACTION_ENABLED_TYPE_CAN_USE_ALIGN": 42,
        "ACTION_ENABLED_TYPE_CAN_USE_PIXEL_ALIGN": 43,
        "ACTION_ENABLED_TYPE_CAN_USE_PACK": 44,
        "ACTION_ENABLED_TYPE_CAN_USE_DISTRIBUTE_DIRECTION": 45,
        "ACTION_ENABLED_TYPE_CAN_USE_DISTRIBUTE_H_V": 46,
        "ACTION_ENABLED_TYPE_UNDOABLE": 47,
        "ACTION_ENABLED_TYPE_REDOABLE": 48,
        "ACTION_ENABLED_TYPE_CLEAR_SELECT_NODES": 49,
        "ACTION_ENABLED_TYPE_COLOR_PICK": 50,
        "ACTION_ENABLED_TYPE_DUPLICATE_SELECTION": 51,
        "ACTION_ENABLED_TYPE_REMOVE_SELECT_NODES": 52,
        "ACTION_ENABLED_TYPE_SELECT_ALL": 53,
        "ACTION_ENABLED_TYPE_SELECT_INVERSE": 54,
        "ACTION_ENABLED_TYPE_PIXEL_GRID": 55,
        "ACTION_ENABLED_TYPE_RULER": 56,
        "ACTION_ENABLED_TYPE_LAYOUT_GRID": 57,
        "ACTION_ENABLED_TYPE_TOGGLEN_SHOW_SLICE": 58,
        "ACTION_ENABLED_TYPE_TOGGLE_SHOW_COMMENT": 59,
        "ACTION_ENABLED_TYPE_SHOW_SIDEBAR": 60,
        "ACTION_ENABLED_TYPE_TOGGLEN_MEMORY_USAGE": 61,
        "ACTION_ENABLED_TYPE_ZOOM_IN_AND_OUT": 62,
        "ACTION_ENABLED_TYPE_PAGE_UP": 63,
        "ACTION_ENABLED_TYPE_PAGE_DOWN": 64,
        "ACTION_ENABLED_TYPE_ZOOM_TO_PREV_FRAME": 65,
        "ACTION_ENABLED_TYPE_ZOOM_TO_NEXT_FRAME": 66,
        "ACTION_ENABLED_TYPE_VIEW_NEXT_FRAME": 67,
        "ACTION_ENABLED_TYPE_VIEW_PREV_FRAME": 68,
        "ACTION_ENABLED_TYPE_CANCEL_MASK": 69,
        "ACTION_ENABLED_TYPE_TIDY_UP": 70,
        "ACTION_ENABLED_TYPE_LAYER_SELECTION": 71,
        "ACTION_ENABLED_TYPE_ENTER_AI_LAYOUT": 72,
        "ACTION_ENABLED_TYPE_EXIT_AI_LAYOUT": 73,
        "ACTION_ENABLED_TYPE_SHOW_SHORTCUT_PANEL": 74,
        "ACTION_ENABLED_TYPE_AI_CREATE_AUTO_LAYOUT": 75,
        "ACTION_ENABLED_TYPE_HIDE_OTHER_LAYERS": 76,
        "ACTION_ENABLED_TYPE_REMOVE_ALL_AUTO_LAYOUT": 77
      }
    },
    "ActiveUserRole": {
      "values": {
        "ACTIVE_USER_ROLE_NONE": 0,
        "ACTIVE_USER_ROLE_CURRENT_MYSELF": 1,
        "ACTIVE_USER_ROLE_OTHER_MYSELF": 2,
        "ACTIVE_USER_ROLE_OBSERVED": 3
      }
    },
    "AddAutoLayoutOrigin": {
      "values": {
        "ADD_AUTO_LAYOUT_ORIGIN_PANEL": 0,
        "ADD_AUTO_LAYOUT_ORIGIN_SHORTCUT": 1,
        "ADD_AUTO_LAYOUT_ORIGIN_CONTEXT_MENU": 2,
        "ADD_AUTO_LAYOUT_ORIGIN_PLUGIN": 3,
        "ADD_AUTO_LAYOUT_ORIGIN_OTHERS": 4
      }
    },
    "AiAlignType": {
      "values": {
        "AI_ALIGN_TYPE_NONE": 0,
        "AI_ALIGN_TYPE_SINGLE_ELEMENT": 1,
        "AI_ALIGN_TYPE_MULTI_ELEMENT": 2
      }
    },
    "AiScene": {
      "values": {
        "AI_SCENE_AI_TOOL_BOX": 0,
        "AI_SCENE_AI_DESIGN_SYSTEM": 1,
        "AI_SCENE_AI_LAB": 2
      }
    },
    "AlignType": {
      "values": {
        "ALIGN_TYPE_LEFT": 0,
        "ALIGN_TYPE_HORIZONTAL_CENTERS": 1,
        "ALIGN_TYPE_RIGHT": 2,
        "ALIGN_TYPE_TOP": 3,
        "ALIGN_TYPE_VERTICAL_CENTERS": 4,
        "ALIGN_TYPE_BOTTOM": 5
      }
    },
    "AndroidUnit": {
      "values": {
        "ANDROID_UNITPX": 0,
        "ANDROID_UNITDP": 1
      }
    },
    "AutoLayoutDisplayType": {
      "values": {
        "LD_Disabled": 0,
        "LD_Empty": 1,
        "LD_Show": 2
      }
    },
    "AutoLayoutEditType": {
      "values": {
        "ED_None": 0,
        "ED_Add": 1,
        "ED_Delete": 2
      }
    },
    "AutoLayoutHoverMenuItem": {
      "values": {
        "AUTO_LAYOUT_HOVER_MENU_ITEM_NONE": 0,
        "AUTO_LAYOUT_HOVER_MENU_ITEM_WIDTH_FILL_CONTAINER": 1,
        "AUTO_LAYOUT_HOVER_MENU_ITEM_WIDTH_HUG": 2,
        "AUTO_LAYOUT_HOVER_MENU_ITEM_HEIGHT_FILL_CONTAINER": 3,
        "AUTO_LAYOUT_HOVER_MENU_ITEM_HEIGHT_HUG": 4,
        "AUTO_LAYOUT_HOVER_MENU_ITEM_HORIZONTAL_GAP": 5,
        "AUTO_LAYOUT_HOVER_MENU_ITEM_VERTICAL_GAP": 6,
        "AUTO_LAYOUT_HOVER_MENU_ITEM_HORIZONTAL_PADDING": 7,
        "AUTO_LAYOUT_HOVER_MENU_ITEM_LEFT_PADDING": 8,
        "AUTO_LAYOUT_HOVER_MENU_ITEM_RIGHT_PADDING": 9,
        "AUTO_LAYOUT_HOVER_MENU_ITEM_VERTICAL_PADDING": 10,
        "AUTO_LAYOUT_HOVER_MENU_ITEM_TOP_PADDING": 11,
        "AUTO_LAYOUT_HOVER_MENU_ITEM_BOTTOM_PADDING": 12
      }
    },
    "AutoLayoutMoveChildrenType": {
      "values": {
        "AUTO_LAYOUT_MOVE_CHILDREN_TYPE_UP": 0,
        "AUTO_LAYOUT_MOVE_CHILDREN_TYPE_DOWN": 1,
        "AUTO_LAYOUT_MOVE_CHILDREN_TYPE_LEFT": 2,
        "AUTO_LAYOUT_MOVE_CHILDREN_TYPE_RIGHT": 3
      }
    },
    "AutoLayoutWHType": {
      "values": {
        "WH_Child": 0,
        "WH_Parent": 1,
        "WH_Both": 2,
        "WH_Either": 3,
        "WH_None": 4
      }
    },
    "AutoLayoutWHValue": {
      "values": {
        "WH_Fixed": 0,
        "WH_Fill": 1,
        "WH_Hug": 2,
        "WH_Mixed": 3
      }
    },
    "BackendType": {
      "values": {
        "BACKEND_TYPE_NULL": 0,
        "BACKEND_TYPE_G_L": 1,
        "BACKEND_TYPE_W_G_P_U": 2
      }
    },
    "BaseAttributePropEditType": {
      "values": {
        "BASE_ATTRIBUTE_PROP_EDIT_TYPE_NONE": 0,
        "BASE_ATTRIBUTE_PROP_EDIT_TYPE_ENABLE": 1,
        "BASE_ATTRIBUTE_PROP_EDIT_TYPE_DISABLE": 2,
        "BASE_ATTRIBUTE_PROP_EDIT_TYPE_SECONDARY": 3
      }
    },
    "BaseAttributePropValueType": {
      "values": {
        "BASE_ATTRIBUTE_PROP_VALUE_TYPE_EMPTY": 0,
        "BASE_ATTRIBUTE_PROP_VALUE_TYPE_NORMAL": 1,
        "BASE_ATTRIBUTE_PROP_VALUE_TYPE_MIXED": 2
      }
    },
    "BlendMode": {
      "values": {
        "BLEND_MODE_PASS_THROUGH": 0,
        "BLEND_MODE_NORMAL": 1,
        "BLEND_MODE_DARKEN": 2,
        "BLEND_MODE_MULTIPLY": 3,
        "BLEND_MODE_COLOR_BURN": 4,
        "BLEND_MODE_LIGHTEN": 5,
        "BLEND_MODE_SCREEN": 6,
        "BLEND_MODE_COLOR_DODGE": 7,
        "BLEND_MODE_OVERLAY": 8,
        "BLEND_MODE_SOFT_LIGHT": 9,
        "BLEND_MODE_HARD_LIGHT": 10,
        "BLEND_MODE_DIFFERENCE": 11,
        "BLEND_MODE_EXCLUSION": 12,
        "BLEND_MODE_HUE": 13,
        "BLEND_MODE_SATURATION": 14,
        "BLEND_MODE_COLOR": 15,
        "BLEND_MODE_LUMINOSITY": 16,
        "BLEND_MODE_LINEAR_BURN": 17,
        "BLEND_MODE_LINEAR_DODGE": 18
      }
    },
    "BooleanOperation": {
      "values": {
        "BOOLEAN_OPERATION_UNION": 0,
        "BOOLEAN_OPERATION_INTERSECT": 1,
        "BOOLEAN_OPERATION_SUBTRACT": 2,
        "BOOLEAN_OPERATION_EXCLUDE": 3
      }
    },
    "BridgeAction": {
      "values": {
        "BRIDGE_ACTION_OPEN_DOC": 0,
        "BRIDGE_ACTION_WASM_CALL_BEGIN": 1,
        "BRIDGE_ACTION_WASM_CALL_END": 2,
        "BRIDGE_ACTION_JS_CALL_BEGIN": 4,
        "BRIDGE_ACTION_JS_CALL_END": 5,
        "BRIDGE_ACTION_UNKNOWN": 6
      }
    },
    "CanvasSearchMode": {
      "values": {
        "CANVAS_SEARCH_MODE_SEARCH": 0,
        "CANVAS_SEARCH_MODE_REPLACE": 1
      }
    },
    "CanvasSearchNodeTypeFilter": {
      "values": {
        "CANVAS_SEARCH_NODE_TYPE_FILTER_ALL": 0,
        "CANVAS_SEARCH_NODE_TYPE_FILTER_TEXT": 1,
        "CANVAS_SEARCH_NODE_TYPE_FILTER_FRAME": 2,
        "CANVAS_SEARCH_NODE_TYPE_FILTER_COMPONENT": 3,
        "CANVAS_SEARCH_NODE_TYPE_FILTER_INSTANCE": 4,
        "CANVAS_SEARCH_NODE_TYPE_FILTER_IMAGE": 5,
        "CANVAS_SEARCH_NODE_TYPE_FILTER_SHAPE": 6,
        "CANVAS_SEARCH_NODE_TYPE_FILTER_OTHER": 7
      }
    },
    "CanvasSearchResultRenderItemType": {
      "values": {
        "CANVAS_SEARCH_RESULT_RENDER_ITEM_TYPE_HEADER": 0,
        "CANVAS_SEARCH_RESULT_RENDER_ITEM_TYPE_LAYER_ITEM": 1
      }
    },
    "CanvasSearchScope": {
      "values": {
        "CANVAS_SEARCH_SCOPE_CURRENT_PAGE": 0,
        "CANVAS_SEARCH_SCOPE_DOC": 1
      }
    },
    "ClassListStatus": {
      "values": {
        "CLASS_LIST_STATUS_UNKNOWN": 0,
        "CLASS_LIST_STATUS_CLASS_NAME_EXISTED_SKIP": 1,
        "CLASS_LIST_STATUS_CLEAR_CLASS_NAME": 2,
        "CLASS_LIST_STATUS_CLASS_NAME_UPDATED": 3
      }
    },
    "ClipboardCopyType": {
      "values": {
        "CLIPBOARD_COPY_TYPE_NORMAL": 0,
        "CLIPBOARD_COPY_TYPE_PROPERTY": 1,
        "CLIPBOARD_COPY_TYPE_AS_TEXT": 2,
        "CLIPBOARD_COPY_TYPE_AS_LINK": 3,
        "CLIPBOARD_COPY_TYPE_AS_P_N_G": 4,
        "CLIPBOARD_COPY_TYPE_AS_S_V_G": 5
      }
    },
    "ClipboardNodeTreeContentType": {
      "values": {
        "CLIPBOARD_NODE_TREE_CONTENT_TYPE_NODES": 0,
        "CLIPBOARD_NODE_TREE_CONTENT_TYPE_PROPERTIES": 1,
        "CLIPBOARD_NODE_TREE_CONTENT_TYPE_PROTOTYPE_INTERACTIONS": 2
      }
    },
    "ClipboardPasteType": {
      "values": {
        "CLIPBOARD_PASTE_TYPE_NORMAL": 0,
        "CLIPBOARD_PASTE_TYPE_PROPERTY": 1,
        "CLIPBOARD_PASTE_TYPE_TEXT_MATCH_STYLE": 2,
        "CLIPBOARD_PASTE_TYPE_PASTE_HERE": 3,
        "CLIPBOARD_PASTE_TYPE_PASTE_TO_REPLACE": 4,
        "CLIPBOARD_PASTE_TYPE_PASTE_OVER_SELECTION": 5
      }
    },
    "CodeRatio": {
      "values": {
        "CODE_RATIO_ONE_THIRD": 0,
        "CODE_RATIO_ONE_HALF": 1,
        "CODE_RATIO_ONE": 2,
        "CODE_RATIO_TWO": 3,
        "CODE_RATIO_THREE": 4
      }
    },
    "CodeType": {
      "values": {
        "CODE_TYPE_CSS": 0,
        "CODE_TYPE_I_O_S": 1,
        "CODE_TYPE_ANDROID": 2
      }
    },
    "ColorMode": {
      "values": {
        "COLOR_MODE_DO_NOT_USE_ME_ZERO": 0,
        "COLOR_MODE_H_E_X": 1,
        "COLOR_MODE_R_G_B": 2,
        "COLOR_MODE_C_S_S": 3,
        "COLOR_MODE_H_S_L": 4,
        "COLOR_MODE_H_S_B": 5
      }
    },
    "ColorPickCommandType": {
      "values": {
        "CP_Null": 0,
        "CP_DisplayColorPick": 1,
        "CP_DismissColorPick": 2,
        "CP_OpenContinuePick": 3,
        "CP_CloseContinuePick": 4,
        "CP_SwitchColorPickVisible": 5,
        "CP_PickColorEnd": 6,
        "CP_EnablePick": 7,
        "CP_DisablePick": 8,
        "CP_ConflictAppear": 9,
        "CP_ConflictDismiss": 10
      }
    },
    "CompSetPropConflictSelectionType": {
      "values": {
        "COMP_SET_PROP_CONFLICT_SELECTION_TYPE_SOME_VARIANTS": 0,
        "COMP_SET_PROP_CONFLICT_SELECTION_TYPE_THIS_VARIANT": 1,
        "COMP_SET_PROP_CONFLICT_SELECTION_TYPE_THESE_VARIANTS": 2,
        "COMP_SET_PROP_CONFLICT_SELECTION_TYPE_THIS_INSTANCE": 3,
        "COMP_SET_PROP_CONFLICT_SELECTION_TYPE_THESE_INSTANCES": 4
      }
    },
    "CompSetPropConflictType": {
      "values": {
        "COMP_SET_PROP_CONFLICT_TYPE_EMPTY": 0,
        "COMP_SET_PROP_CONFLICT_TYPE_INVALID_NAME": 1,
        "COMP_SET_PROP_CONFLICT_TYPE_REPEAT_NAME": 2,
        "COMP_SET_PROP_CONFLICT_TYPE_MISSING_PROP": 3
      }
    },
    "ComponentPickerTrigger": {
      "values": {
        "COMPONENT_PICKER_TRIGGER_NONE": 0,
        "COMPONENT_PICKER_TRIGGER_INSTANCE_SWAP_VALUE": 1,
        "COMPONENT_PICKER_TRIGGER_PREFERRED_VALUES": 2,
        "COMPONENT_PICKER_TRIGGER_REPLACE_INSTANCE": 3
      }
    },
    "ComponentPropNodeField": {
      "values": {
        "COMPONENT_PROP_NODE_FIELD_VISIBLE": 0,
        "COMPONENT_PROP_NODE_FIELD_TEXT_DATA": 1,
        "COMPONENT_PROP_NODE_FIELD_OVERRIDDEN_SYMBOL_ID": 2
      }
    },
    "ComponentPropType": {
      "values": {
        "COMPONENT_PROP_TYPE_BOOL": 0,
        "COMPONENT_PROP_TYPE_TEXT": 1,
        "COMPONENT_PROP_TYPE_INSTANCE_SWAP": 2
      }
    },
    "CompressType": {
      "values": {
        "COMPRESS_TYPE_NO_COMPRESS": 0,
        "COMPRESS_TYPE_ZLIB": 1,
        "COMPRESS_TYPE_LZ4": 2,
        "COMPRESS_TYPE_SNAPPY": 3,
        "COMPRESS_TYPE_BROTLI": 4
      }
    },
    "ComputedStackModeType": {
      "values": {
        "COMPUTED_STACK_MODE_TYPE_NONE": 0,
        "COMPUTED_STACK_MODE_TYPE_HORIZONTAL": 1,
        "COMPUTED_STACK_MODE_TYPE_VERTICAL": 2,
        "COMPUTED_STACK_MODE_TYPE_WRAP": 3
      }
    },
    "ConnectionType": {
      "values": {
        "CONNECTION_TYPE_NONE": 0,
        "CONNECTION_TYPE_INTERNAL_NODE": 1,
        "CONNECTION_TYPE_URL": 2,
        "CONNECTION_TYPE_BACK": 3,
        "CONNECTION_TYPE_CLOSE": 4,
        "CONNECTION_TYPE_SET_VARIABLE": 5,
        "CONNECTION_TYPE_UPDATE_MEDIA_RUNTIME": 6,
        "CONNECTION_TYPE_CONDITIONAL": 7
      }
    },
    "ConstraintType": {
      "values": {
        "CONSTRAINT_TYPE_MIN": 0,
        "CONSTRAINT_TYPE_CENTER": 1,
        "CONSTRAINT_TYPE_MAX": 2,
        "CONSTRAINT_TYPE_STRETCH": 3,
        "CONSTRAINT_TYPE_SCALE": 4,
        "CONSTRAINT_TYPE_MIXED": 5
      }
    },
    "ContextMenuAutoLayoutType": {
      "values": {
        "CONTEXT_MENU_AUTO_LAYOUT_TYPE_DISABLE": 0,
        "CONTEXT_MENU_AUTO_LAYOUT_TYPE_ADD": 1,
        "CONTEXT_MENU_AUTO_LAYOUT_TYPE_DELETE": 2
      }
    },
    "CssUnit": {
      "values": {
        "CSS_UNITPX": 0,
        "CSS_UNITREM": 1
      }
    },
    "CursorMode": {
      "values": {
        "CURSOR_MODE_ARROW": 0,
        "CURSOR_MODE_HAND_LOOSE": 1,
        "CURSOR_MODE_HAND_HOLD": 2,
        "CURSOR_MODE_DRAW": 3,
        "CURSOR_MODE_SCALE": 4,
        "CURSOR_MODE_CROSS": 5,
        "CURSOR_MODE_ROTATE": 6,
        "CURSOR_MODE_ROTATE_ARROW": 7,
        "CURSOR_MODE_PEN": 8,
        "CURSOR_MODE_PAINT": 9,
        "CURSOR_MODE_PAINT_MINUS": 10,
        "CURSOR_MODE_PAINT_PLUS": 11,
        "CURSOR_MODE_BEND": 12,
        "CURSOR_MODE_BEND_VERTEX": 13,
        "CURSOR_MODE_PEN_MINUS": 14,
        "CURSOR_MODE_PEN_PLUS": 15,
        "CURSOR_MODE_PEN_DOT": 16,
        "CURSOR_MODE_TEXT": 17,
        "CURSOR_MODE_ROUNDED_CORNER": 18,
        "CURSOR_MODE_COUNT": 19,
        "CURSOR_MODE_RATIO": 20,
        "CURSOR_MODE_PLACE_IMAGE": 21,
        "CURSOR_MODE_MOVE_VERTICAL": 22,
        "CURSOR_MODE_MOVE_HORIZONTAL": 23,
        "CURSOR_MODE_COPY": 24,
        "CURSOR_MODE_VECTOR_MOVE": 25,
        "CURSOR_MODE_VECTOR_ADJUST": 26,
        "CURSOR_MODE_GUIDE_VERTICAL": 27,
        "CURSOR_MODE_GUIDE_HORIZONTAL": 28,
        "CURSOR_MODE_GUIDE_DELETE": 29,
        "CURSOR_MODE_SCALE_TOOL": 30,
        "CURSOR_MODE_DRAG_UP_DOWN": 31,
        "CURSOR_MODE_DRAG_UP": 32,
        "CURSOR_MODE_COLOR_PICK": 33,
        "CURSOR_MODE_COMMENT": 34,
        "CURSOR_MODE_VECTOR_ADJUST_MOVE": 35,
        "CURSOR_MODE_VECTOR_ADJUST_GUIDE": 36,
        "CURSOR_MODE_ARROW_DROP": 37,
        "CURSOR_MODE_DRAWING": 38,
        "CURSOR_MODE_HAND_POINT": 39,
        "CURSOR_MODE_TEXT_MISSING_FONT": 40,
        "CURSOR_MODE_ZOOM_IN": 41,
        "CURSOR_MODE_ZOOM_OUT": 42,
        "CURSOR_MODE_ANCHOR_POINT_ADSORPTION": 43,
        "CURSOR_MODE_AI_DUPLICATE": 44,
        "CURSOR_MODE_TOUCH_POINT_DEFAULT": 45,
        "CURSOR_MODE_TOUCH_POINT_CLICK": 46,
        "CURSOR_MODE_MOVE_ABSOLUTE": 47,
        "CURSOR_MODE_SYSTEM_DEFAULT": 48
      }
    },
    "DebouncedMovingBoundsPositionHold": {
      "values": {
        "DEBOUNCED_MOVING_BOUNDS_POSITION_HOLD_NOT_IN_MOVING": 0,
        "DEBOUNCED_MOVING_BOUNDS_POSITION_HOLD_MOVING_AND_NOT_HOLD": 1,
        "DEBOUNCED_MOVING_BOUNDS_POSITION_HOLD_MOVING_AND_HOLD": 2
      }
    },
    "DevAndReadonlyColorMode": {
      "values": {
        "DEV_AND_READONLY_COLOR_MODE_DO_NOT_USE_ME_ZERO": 0,
        "DEV_AND_READONLY_COLOR_MODE_H_E_X": 1,
        "DEV_AND_READONLY_COLOR_MODE_R_G_B": 2,
        "DEV_AND_READONLY_COLOR_MODE_H_S_L": 4,
        "DEV_AND_READONLY_COLOR_MODE_H_S_B": 5,
        "DEV_AND_READONLY_COLOR_MODE_U_I_COLOR": 6,
        "DEV_AND_READONLY_COLOR_MODE_SWIFT_U_I": 7,
        "DEV_AND_READONLY_COLOR_MODE_A_R_G_B": 8,
        "DEV_AND_READONLY_COLOR_MODE_COMPOSE": 9
      }
    },
    "DevModeInspectBoxModelShowType": {
      "values": {
        "DEV_MODE_INSPECT_BOX_MODEL_SHOW_TYPE_NONE": 0,
        "DEV_MODE_INSPECT_BOX_MODEL_SHOW_TYPE_LAYOUT": 1,
        "DEV_MODE_INSPECT_BOX_MODEL_SHOW_TYPE_TEXT": 2,
        "DEV_MODE_INSPECT_BOX_MODEL_SHOW_TYPE_COMMON_ASSET": 3,
        "DEV_MODE_INSPECT_BOX_MODEL_SHOW_TYPE_IMAGE_ASSET": 4
      }
    },
    "DevModeInspectCodeType": {
      "values": {
        "DEV_MODE_INSPECT_CODE_TYPE_CSS": 0,
        "iOS_UI_Kit": 1,
        "Android_XML": 2,
        "iOS_Swift_UI": 3,
        "Android_Compose": 4
      }
    },
    "DevModeInspectNodeBasicInfoType": {
      "values": {
        "DEV_MODE_INSPECT_NODE_BASIC_INFO_TYPE_COMPONENT": 0,
        "DEV_MODE_INSPECT_NODE_BASIC_INFO_TYPE_VARIANT": 1,
        "DEV_MODE_INSPECT_NODE_BASIC_INFO_TYPE_COMPONENT_SET": 2,
        "DEV_MODE_INSPECT_NODE_BASIC_INFO_TYPE_INSTANCE": 3,
        "DEV_MODE_INSPECT_NODE_BASIC_INFO_TYPE_FRAME": 4,
        "DEV_MODE_INSPECT_NODE_BASIC_INFO_TYPE_GROUP": 5,
        "DEV_MODE_INSPECT_NODE_BASIC_INFO_TYPE_RECTANGLE": 6,
        "DEV_MODE_INSPECT_NODE_BASIC_INFO_TYPE_LINE": 7,
        "DEV_MODE_INSPECT_NODE_BASIC_INFO_TYPE_ELLIPSE": 8,
        "DEV_MODE_INSPECT_NODE_BASIC_INFO_TYPE_POLYGON": 9,
        "DEV_MODE_INSPECT_NODE_BASIC_INFO_TYPE_STAR": 10,
        "DEV_MODE_INSPECT_NODE_BASIC_INFO_TYPE_BOOL_UNION": 11,
        "DEV_MODE_INSPECT_NODE_BASIC_INFO_TYPE_BOOL_INTERSECT": 12,
        "DEV_MODE_INSPECT_NODE_BASIC_INFO_TYPE_BOOL_EXCLUDE": 13,
        "DEV_MODE_INSPECT_NODE_BASIC_INFO_TYPE_BOOL_SUBTRACT": 14,
        "DEV_MODE_INSPECT_NODE_BASIC_INFO_TYPE_PATH": 15,
        "DEV_MODE_INSPECT_NODE_BASIC_INFO_TYPE_TEXT": 16,
        "DEV_MODE_INSPECT_NODE_BASIC_INFO_TYPE_SLICE": 17
      }
    },
    "DevModeInspectSelectionInfoType": {
      "values": {
        "DEV_MODE_INSPECT_SELECTION_INFO_TYPE_NONE": 0,
        "DEV_MODE_INSPECT_SELECTION_INFO_TYPE_SINGLE": 1,
        "DEV_MODE_INSPECT_SELECTION_INFO_TYPE_MULTI": 2
      }
    },
    "DevModeInspectType": {
      "values": {
        "DEV_MODE_INSPECT_TYPE_CODE": 0,
        "DEV_MODE_INSPECT_TYPE_PROPERTY": 1
      }
    },
    "DeviceRotation": {
      "values": {
        "DEVICE_ROTATION_NONE": 0,
        "CCW_90": 1
      }
    },
    "DeviceSupportDisplayP3Level": {
      "values": {
        "DEVICE_SUPPORT_DISPLAY_P3_LEVEL_NONE": 0,
        "DEVICE_SUPPORT_DISPLAY_P3_LEVEL_OTHER": 1,
        "DEVICE_SUPPORT_DISPLAY_P3_LEVEL_CURRENT": 2
      }
    },
    "Directionality": {
      "values": {
        "DIRECTIONALITY_LTR": 0,
        "DIRECTIONALITY_RTL": 1
      }
    },
    "DirectionalityIntent": {
      "values": {
        "DIRECTIONALITY_INTENT_IMPLICIT": 0,
        "DIRECTIONALITY_INTENT_EXPLICIT": 1
      }
    },
    "DisplayPanelType": {
      "values": {
        "DISPLAY_PANEL_TYPE_DESIGN": 0,
        "DISPLAY_PANEL_TYPE_PROTOTYPE": 1,
        "DISPLAY_PANEL_TYPE_INSPECT": 2,
        "DISPLAY_PANEL_TYPE_COMMENT": 3,
        "DISPLAY_PANEL_TYPE_EXPORT": 4,
        "DISPLAY_PANEL_TYPE_DEV_MODE_INSPECT": 5,
        "DISPLAY_PANEL_TYPE_AI": 6
      }
    },
    "DistributeType": {
      "values": {
        "DISTRIBUTE_TYPE_HORIZONTAL_SPACING": 0,
        "DISTRIBUTE_TYPE_VERTICAL_SPACING": 1,
        "DISTRIBUTE_TYPE_LEFT": 2,
        "DISTRIBUTE_TYPE_HORIZONTAL_CENTERS": 3,
        "DISTRIBUTE_TYPE_RIGHT": 4,
        "DISTRIBUTE_TYPE_TOP": 5,
        "DISTRIBUTE_TYPE_VERTICAL_CENTERS": 6,
        "DISTRIBUTE_TYPE_BOTTOM": 7
      }
    },
    "DocMode": {
      "values": {
        "DOC_MODE_DEFAULT": 0,
        "DOC_MODE_PROTOTYPE": 1
      }
    },
    "DocumentColorProfile": {
      "values": {
        "DOCUMENT_COLOR_PROFILES_R_G_B": 0,
        "DOCUMENT_COLOR_PROFILE_DISPLAY_P3": 1
      }
    },
    "DownloadErrorType": {
      "values": {
        "DOWNLOAD_ERROR_TYPE_NONE": 0,
        "DOWNLOAD_ERROR_TYPE_NO_FILE": 1,
        "DOWNLOAD_ERROR_TYPE_DECODE": 2
      }
    },
    "DownloadFontType": {
      "values": {
        "DOWNLOAD_FONT_TYPE_DEFAULT": 0,
        "DOWNLOAD_FONT_TYPE_PREVIEW": 1,
        "DOWNLOAD_FONT_TYPE_SET_FONT": 2
      }
    },
    "DragMoveMode": {
      "values": {
        "DRAG_MOVE_MODE_NORMAL": 0,
        "DRAG_MOVE_MODE_VERTICAL": 1,
        "DRAG_MOVE_MODE_HORIZONTAL": 2,
        "DRAG_MOVE_MODE_COPY": 3,
        "DRAG_MOVE_MODE_DROP_IGNORE_W_H": 4,
        "DRAG_MOVE_MODE_MOVE_ABSOLUTE": 5
      }
    },
    "DragingTargetType": {
      "values": {
        "DRAGING_TARGET_TYPE_NONE": 0,
        "DRAGING_TARGET_TYPE_NODE": 1,
        "DRAGING_TARGET_TYPE_BACK": 2,
        "DRAGING_TARGET_TYPE_CLOSE": 3
      }
    },
    "EasingType": {
      "values": {
        "EASING_TYPE_OUT_CUBIC": 0,
        "EASING_TYPE_IN_CUBIC": 1,
        "EASING_TYPE_INOUT_CUBIC": 2,
        "EASING_TYPE_LINEAR": 3,
        "EASING_TYPE_IN_BACK_CUBIC": 4,
        "EASING_TYPE_OUT_BACK_CUBIC": 5,
        "EASING_TYPE_INOUT_BACK_CUBIC": 6,
        "EASING_TYPE_CUSTOM_CUBIC": 7,
        "EASING_TYPE_SPRING": 8,
        "EASING_TYPE_GENTLE_SPRING": 9,
        "EASING_TYPE_CUSTOM_SPRING": 10,
        "EASING_TYPE_SPRING_PRESET_ONE": 11,
        "EASING_TYPE_SPRING_PRESET_TWO": 12,
        "EASING_TYPE_SPRING_PRESET_THREE": 13
      }
    },
    "EditingPaintType": {
      "values": {
        "EDITING_PAINT_TYPE_FILL": 0,
        "EDITING_PAINT_TYPE_STROKE": 1,
        "EDITING_PAINT_TYPE_FILL_STYLE": 2,
        "EDITING_PAINT_TYPE_STROKE_STYLE": 3,
        "EDITING_PAINT_TYPE_LOCAL_STYLE": 4,
        "EDITING_PAINT_TYPE_SELECT_COLOR_STYLE": 5
      }
    },
    "EditorMode": {
      "values": {
        "EM_Move": 0,
        "EM_Scale": 1,
        "EM_Frame": 2,
        "EM_Rectangle": 3,
        "EM_Line": 4,
        "EM_Arrow": 5,
        "EM_Ellipse": 6,
        "EM_Polygon": 7,
        "EM_Star": 8,
        "EM_PlaceImage": 9,
        "EM_Text": 10,
        "EM_PEN": 11,
        "EM_BendTool": 12,
        "EM_PaintBucket": 13,
        "EM_HandTool": 14,
        "EM_Comment": 15,
        "EM_Slice": 16,
        "EM_Copilot": 17,
        "EM_MenuPlaceImage": 18,
        "EM_DevModeInspect": 19,
        "EM_AiDuplicate": 20
      }
    },
    "EditorStateFromModule": {
      "values": {
        "EDITOR_STATE_FROM_MODULE_NONE": 0,
        "EDITOR_STATE_FROM_MODULE_FILL": 1,
        "EDITOR_STATE_FROM_MODULE_STROKE": 2,
        "EDITOR_STATE_FROM_MODULE_SELECT_COLOR": 3,
        "EDITOR_STATE_FROM_MODULE_EFFECT": 4,
        "EDITOR_STATE_FROM_MODULE_TEXT": 5,
        "EDITOR_STATE_FROM_MODULE_LAYOUT_GRID": 6
      }
    },
    "EditorType": {
      "values": {
        "EDITOR_TYPE_DO_NOT_USE_ME_ZERO": 0,
        "EDITOR_TYPE_DESIGN": 1,
        "EDITOR_TYPE_DEV": 2
      }
    },
    "EffectStyleIconType": {
      "values": {
        "EFFECT_STYLE_ICON_TYPE_DEFAULT": 0,
        "EFFECT_STYLE_ICON_TYPE_BACKGROUND_BLUR": 1,
        "EFFECT_STYLE_ICON_TYPE_LAYER_BLUR": 2,
        "EFFECT_STYLE_ICON_TYPE_INNER_BUTTOM_RIGHT": 3,
        "EFFECT_STYLE_ICON_TYPE_INNER_BUTTOM": 4,
        "EFFECT_STYLE_ICON_TYPE_INNER_BUTTOM_LEFT": 5,
        "EFFECT_STYLE_ICON_TYPE_INNER_LEFT": 6,
        "EFFECT_STYLE_ICON_TYPE_INNER_TOP_LEFT": 7,
        "EFFECT_STYLE_ICON_TYPE_INNER_TOP": 8,
        "EFFECT_STYLE_ICON_TYPE_INNER_TOP_RIGHT": 9,
        "EFFECT_STYLE_ICON_TYPE_INNER_RIGHT": 10,
        "EFFECT_STYLE_ICON_TYPE_DROP_BUTTOM_RIGHT": 11,
        "EFFECT_STYLE_ICON_TYPE_DROP_BUTTOM": 12,
        "EFFECT_STYLE_ICON_TYPE_DROP_BUTTOM_LEFT": 13,
        "EFFECT_STYLE_ICON_TYPE_DROP_LEFT": 14,
        "EFFECT_STYLE_ICON_TYPE_DROP_TOP_LEFT": 15,
        "EFFECT_STYLE_ICON_TYPE_DROP_TOP": 16,
        "EFFECT_STYLE_ICON_TYPE_DROP_TOP_RIGHT": 17,
        "EFFECT_STYLE_ICON_TYPE_DROP_RIGHT": 18
      }
    },
    "EffectType": {
      "values": {
        "EFFECT_TYPE_DROP_SHADOW": 0,
        "EFFECT_TYPE_INNER_SHADOW": 1,
        "EFFECT_TYPE_LAYER_BLUR": 2,
        "EFFECT_TYPE_BACKGROUND_BLUR": 3
      }
    },
    "ExportConstraintType": {
      "values": {
        "EXPORT_CONSTRAINT_TYPE_SCALE": 0,
        "EXPORT_CONSTRAINT_TYPE_WIDTH": 1,
        "EXPORT_CONSTRAINT_TYPE_HEIGHT": 2,
        "EXPORT_CONSTRAINT_TYPE_LARGER_SIDE": 3
      }
    },
    "ExportDocumentType": {
      "values": {
        "EXPORT_DOCUMENT_TYPE_SKETCH": 0,
        "EXPORT_DOCUMENT_TYPE_FIGMA": 1
      }
    },
    "ExportFormatType": {
      "values": {
        "EXPORT_FORMAT_TYPE_JPG": 0,
        "EXPORT_FORMAT_TYPE_PNG": 1,
        "EXPORT_FORMAT_TYPE_SVG": 2,
        "EXPORT_FORMAT_TYPE_PDF": 3,
        "EXPORT_FORMAT_TYPE_WEBP": 4,
        "EXPORT_FORMAT_TYPE_AVIF": 5
      }
    },
    "ExportOperationTarget": {
      "values": {
        "EXPORT_OPERATION_TARGET_WHOLE_PAGE": 0,
        "EXPORT_OPERATION_TARGET_SELECTED_NODES": 1
      }
    },
    "ExternalPromiseType": {
      "values": {
        "EXTERNAL_PROMISE_TYPE_VOID": 0,
        "EXTERNAL_PROMISE_TYPE_STRING": 1,
        "EXTERNAL_PROMISE_TYPE_BYTES": 2
      }
    },
    "FigNodeProps": {
      "values": {
        "FIG_NODE_PROPS_DO_NOT_USE_ME_ZERO": 0,
        "FIG_NODE_PROPSID": 1,
        "FIG_NODE_PROPSNAME": 2,
        "FIG_NODE_PROPSTYPE": 3,
        "FIG_NODE_PROPSVISIBLE": 4,
        "FIG_NODE_PROPSOPACITY": 5,
        "FIG_NODE_PROPSIS_MASK": 6,
        "FIG_NODE_PROPSIS_MASK_OUTLINE": 7,
        "FIG_NODE_PROPSSTROKE_WEIGHT": 8,
        "FIG_NODE_PROPSSTROKE_ALIGN": 9,
        "FIG_NODE_PROPSSIZE": 10,
        "FIG_NODE_PROPSRELATIVE_TRANSFORM": 11,
        "FIG_NODE_PROPSROTATION": 12,
        "FIG_NODE_PROPSABSOLUTE_BOUNDING_BOX": 13,
        "FIG_NODE_PROPSBLEND_MODE": 14,
        "FIG_NODE_PROPSABSOLUTE_RENDER_BOUNDS": 15,
        "FIG_NODE_PROPSFILLS": 16,
        "FIG_NODE_PROPSSTROKES": 17,
        "FIG_NODE_PROPSEFFECTS": 18,
        "FIG_NODE_PROPSCLIPS_CONTENT": 19,
        "FIG_NODE_PROPSCORNER_RADIUS": 20,
        "FIG_NODE_PROPSRECTANGLE_CORNER_RADII": 21,
        "FIG_NODE_PROPSLAYOUT_MODE": 22,
        "FIG_NODE_PROPSPRIMARY_AXIS_SIZING_MODE": 23,
        "FIG_NODE_PROPSCOUNTER_AXIS_SIZING_MODE": 24,
        "FIG_NODE_PROPSPRIMARY_AXIS_ALIGN_ITEMS": 25,
        "FIG_NODE_PROPSCOUNTER_AXIS_ALIGN_ITEMS": 26,
        "FIG_NODE_PROPSPADDING_LEFT": 27,
        "FIG_NODE_PROPSPADDING_RIGHT": 28,
        "FIG_NODE_PROPSPADDING_TOP": 29,
        "FIG_NODE_PROPSPADDING_BOTTOM": 30,
        "FIG_NODE_PROPSITEM_SPACING": 31,
        "FIG_NODE_PROPSITEM_REVERSE_Z_INDEX": 32,
        "FIG_NODE_PROPSLAYOUT_ALIGN": 33,
        "FIG_NODE_PROPSLAYOUT_POSITIONING": 34,
        "FIG_NODE_PROPSLAYOUT_GROW": 35,
        "FIG_NODE_PROPSCHARACTERS": 36,
        "FIG_NODE_PROPSSTYLE": 37,
        "FIG_NODE_PROPSTEXT_DATA": 38,
        "FIG_NODE_PROPSVECTOR_NETWORK": 39,
        "FIG_NODE_PROPSSTART_CAP": 40,
        "FIG_NODE_PROPSEND_CAP": 41,
        "FIG_NODE_PROPSINNER_RADIUS": 42,
        "FIG_NODE_PROPSPOINT_COUNT": 43,
        "FIG_NODE_PROPSBOOLEAN_OPERATION": 44,
        "FIG_NODE_PROPSARC_DATA": 45,
        "FIG_NODE_PROPSPUBLISH_HIDDEN": 46,
        "FIG_NODE_PROPSMAIN_COMPONENT_ID": 47,
        "FIG_NODE_PROPSEDITED_PROPS": 48,
        "FIG_NODE_PROPSPUBLISH_FILE": 49,
        "FIG_NODE_PROPSPUBLISH_ID": 50,
        "FIG_NODE_PROPSDESCRIPTION": 51,
        "FIG_NODE_PROPSCOMPONENT_SET_PROPS": 52,
        "FIG_NODE_PROPSVARIANT_PROPS": 53,
        "FIG_NODE_PROPSSTROKE_STYLE_PUBLISH_FILE": 54,
        "FIG_NODE_PROPSFILL_STYLE_PUBLISH_FILE": 55,
        "FIG_NODE_PROPSEFFECT_STYLE_PUBLISH_FILE": 56,
        "FIG_NODE_PROPSTEXT_STYLE_PUBLISH_FILE": 57,
        "FIG_NODE_PROPSHAS_STROKE_STYLE": 58,
        "FIG_NODE_PROPSHAS_FILL_STYLE": 59,
        "FIG_NODE_PROPSHAS_EFFECT_STYLE": 60,
        "FIG_NODE_PROPSHAS_TEXT_STYLE": 61,
        "FIG_NODE_PROPSTEXT_BOUNDS": 62,
        "FIG_NODE_PROPSSKELETON_PATH": 63,
        "FIG_NODE_PROPSLAYOUT_WRAP": 64,
        "FIG_NODE_PROPSLOCKED": 65
      }
    },
    "FileType": {
      "values": {
        "FILE_TYPE_PNG": 0,
        "FILE_TYPE_JPEG": 1,
        "FILE_TYPE_WEBP": 2,
        "FILE_TYPE_SVG": 3,
        "FILE_TYPE_PDF": 4,
        "FILE_TYPE_AVIF": 5
      }
    },
    "FloatSpaceInputType": {
      "values": {
        "FLOAT_SPACE_INPUT_TYPE_NONE": 0,
        "FLOAT_SPACE_INPUT_TYPE_TOP": 1,
        "FLOAT_SPACE_INPUT_TYPE_RIGHT": 2,
        "FLOAT_SPACE_INPUT_TYPE_BOTTOM": 3,
        "FLOAT_SPACE_INPUT_TYPE_LEFT": 4,
        "FLOAT_SPACE_INPUT_TYPE_VERTICAL": 5,
        "FLOAT_SPACE_INPUT_TYPE_HORIZONTAL": 6,
        "FLOAT_SPACE_INPUT_TYPE_ALL": 7,
        "FLOAT_SPACE_INPUT_TYPE_SPACE": 8,
        "FLOAT_SPACE_INPUT_TYPE_COUNTER_SPACE": 9
      }
    },
    "FlowListForSelectionShowType": {
      "values": {
        "FLOW_LIST_FOR_SELECTION_SHOW_TYPE_ADD": 0,
        "FLOW_LIST_FOR_SELECTION_SHOW_TYPE_EDIT": 1,
        "FLOW_LIST_FOR_SELECTION_SHOW_TYPE_REMOVE": 2
      }
    },
    "FocusView": {
      "values": {
        "FOCUS_VIEW_CANVAS": 0,
        "FOCUS_VIEW_TEXT_EDITING": 1
      }
    },
    "FontSlant": {
      "values": {
        "UPRIGHT_SLANT": 0,
        "ITALIC_SLANT": 1,
        "OBLIQUE_SLANT": 2
      }
    },
    "FontStyle": {
      "values": {
        "FONT_STYLE_NORMAL": 0,
        "FONT_STYLE_ITALIC": 1
      }
    },
    "FontWeight": {
      "values": {
        "INVISIBLE_WEIGHT": 0,
        "THIN_WEIGHT": 100,
        "EXTRA_LIGHT_WEIGHT": 200,
        "LIGHT_WEIGHT": 300,
        "NORMAL_WEIGHT": 400,
        "MEDIUM_WEIGHT": 500,
        "SEMI_BOLD_WEIGHT": 600,
        "BOLD_WEIGHT": 700,
        "EXTRA_BOLD_WEIGHT": 800,
        "BLACK_WEIGHT": 900,
        "EXTRA_BLACK_WEIGHT": 1000
      }
    },
    "FontWidth": {
      "values": {
        "INVISIBLE_WIDTH": 0,
        "ULTRA_CONDENSED_WIDTH": 1,
        "EXTRA_CONDENSED_WIDTH": 2,
        "CONDENSED_WIDTH": 3,
        "SEMI_CONDENSED_WIDTH": 4,
        "NORMAL_WIDTH": 5,
        "SEMI_EXPANDED_WIDTH": 6,
        "EXPANDED_WIDTH": 7,
        "EXTRA_EXPANDED_WIDTH": 8,
        "ULTRA_EXPANDED_WIDTH": 9
      }
    },
    "FuzzTestOperationType": {
      "values": {
        "CREATE_OPERATION_TYPE_START": 0,
        "FUZZ_TEST_OPERATION_TYPE_DRAW_FRAME": 1,
        "FUZZ_TEST_OPERATION_TYPE_DRAW_RECTANGLE": 2,
        "FUZZ_TEST_OPERATION_TYPE_DRAW_ELLIPSE": 3,
        "FUZZ_TEST_OPERATION_TYPE_DRAW_TEXT": 4,
        "FUZZ_TEST_OPERATION_TYPE_DUPLICATE_NODES": 5,
        "FUZZ_TEST_OPERATION_TYPE_OPTION_COPY_NODES": 6,
        "FUZZ_TEST_OPERATION_TYPE_PASTE": 7,
        "FUZZ_TEST_OPERATION_TYPE_A_I_DUPLICATE": 8,
        "FUZZ_TEST_OPERATION_TYPE_FLUSH_A_I_DUPLICATE": 9,
        "CREATE_OPERATION_TYPE_END": 10,
        "REMOVE_OPREATION_TYPE_START": 300,
        "FUZZ_TEST_OPERATION_TYPE_REMOVE_NODES": 301,
        "FUZZ_TEST_OPERATION_TYPE_CUT_NODES": 302,
        "FUZZ_TEST_OPERATION_TYPE_DELETE_PAGE": 303,
        "REMOVE_OPREATION_TYPE_END": 304,
        "UPDATE_OPERATION_TYPE_START": 600,
        "FUZZ_TEST_OPERATION_TYPE_UPDATE_LOCKED": 601,
        "FUZZ_TEST_OPERATION_TYPE_UPDATE_VISIBLE": 602,
        "FUZZ_TEST_OPERATION_TYPE_SCALE": 603,
        "FUZZ_TEST_OPERATION_TYPE_UPDATE_CONSTRAINTS": 604,
        "FUZZ_TEST_OPERATION_TYPE_UPDATE_SIZE": 605,
        "FUZZ_TEST_OPERATION_TYPE_FLATTEN": 606,
        "FUZZ_TEST_OPERATION_TYPE_OUTLINE_STROKE": 607,
        "FUZZ_TEST_OPERATION_TYPE_UPDATE_STROKES": 608,
        "FUZZ_TEST_OPERATION_TYPE_UPDATE_FILLS": 609,
        "FUZZ_TEST_OPERATION_TYPE_UPDATE_EFFECTS": 610,
        "FUZZ_TEST_OPERATION_TYPE_COPY": 611,
        "FUZZ_TEST_OPERATION_TYPE_MAKE_BOOL_NODE": 612,
        "FUZZ_TEST_OPERATION_TYPE_MAKE_GROUP_NODE": 613,
        "FUZZ_TEST_OPERATION_TYPE_MAKE_COMPONENT_NODE": 614,
        "FUZZ_TEST_OPERATION_TYPE_MAKE_COMPONENT_SET_NODE": 615,
        "FUZZ_TEST_OPERATION_TYPE_CREATE_AND_CHANGE_CURRENT_PAGE": 616,
        "FUZZ_TEST_OPERATION_TYPE_CHANGE_CURRENT_PAGE": 617,
        "FUZZ_TEST_OPERATION_TYPE_CREATE_STYLE": 618,
        "FUZZ_TEST_OPERATION_TYPE_ATTACH_STYLE_TO_LAYOUT_MIXIN": 619,
        "FUZZ_TEST_OPERATION_TYPE_ADD_AUTO_LAYOUT": 620,
        "FUZZ_TEST_OPERATION_TYPE_REMOVE_AUTO_LAYOUT": 621,
        "FUZZ_TEST_OPERATION_TYPE_UNDO": 622,
        "FUZZ_TEST_OPERATION_TYPE_REDO": 623,
        "FUZZ_TEST_OPERATION_TYPE_DRAG_AND_MOVE": 624,
        "FUZZ_TEST_OPERATION_TYPE_DRAG_BOUNDS": 625,
        "FUZZ_TEST_OPERATION_TYPE_MOVE_NODES_TO_PAGE": 626,
        "FUZZ_TEST_OPERATION_TYPE_MAKE_MASK": 627,
        "FUZZ_TEST_OPERATION_TYPE_REPLACE_MAIN_COMPONENT": 628,
        "FUZZ_TEST_OPERATION_TYPE_CREATE_AND_APPLY_INST_SWAP_PROP": 629,
        "FUZZ_TEST_OPERATION_TYPE_CREATE_INST_SWAP_PROP": 630,
        "FUZZ_TEST_OPERATION_TYPE_APPLY_INST_SWAP_PROP": 631,
        "FUZZ_TEST_OPERATION_TYPE_EDIT_INST_SWAP_PROP": 632,
        "FUZZ_TEST_OPERATION_TYPE_DETACH_COMPONENT_PROP": 633,
        "FUZZ_TEST_OPERATION_TYPE_UPDATE_INSTANCE_SWAP_ASSIGNMENT": 634,
        "FUZZ_TEST_OPERATION_TYPE_RESET_ALL_MODIFICATIONS_ON_INST": 635,
        "UPDATE_OPERATION_TYPE_END": 636,
        "SPECIAL_OPERATION_TYPE_START": 900,
        "FUZZ_TEST_OPERATION_TYPE_SYNERGY": 901,
        "FUZZ_TEST_OPERATION_TYPE_CHANGE_CONTEXT": 902,
        "SPECIAL_OPERATION_TYPE_END": 903
      }
    },
    "GuideAxis": {
      "values": {
        "GUIDE_AXIS_X": 0,
        "GUIDE_AXIS_Y": 1
      }
    },
    "HyperlinkEditingMode": {
      "values": {
        "HYPERLINK_EDITING_MODE_NONE": 0,
        "HYPERLINK_EDITING_MODE_CREATE": 1,
        "HYPERLINK_EDITING_MODE_EDIT": 2
      }
    },
    "HyperlinkTargetType": {
      "values": {
        "HYPERLINK_TARGET_TYPE_URL": 0,
        "HYPERLINK_TARGET_TYPE_NODE": 1
      }
    },
    "IOSUnit": {
      "values": {
        "I_O_S_UNITPX": 0,
        "I_O_S_UNITPT": 1
      }
    },
    "ImageFormat": {
      "values": {
        "IMAGE_FORMAT_JPG": 0,
        "IMAGE_FORMAT_PNG": 1,
        "IMAGE_FORMAT_WEBP": 2,
        "IMAGE_FORMAT_AVIF": 3
      }
    },
    "ImageState": {
      "values": {
        "IMAGE_STATE_LOADING": 0,
        "IMAGE_STATE_SUCCESSFUL": 1,
        "IMAGE_STATE_FAILURE": 2
      }
    },
    "ImageType": {
      "values": {
        "IMAGE_TYPE_P_R_E_V_I_E_W": 0,
        "IMAGE_TYPE_O_R_I_G_I_N": 1
      }
    },
    "IndependentStrokeType": {
      "values": {
        "INDEPENDENT_STROKE_TYPE_NONE": 0,
        "INDEPENDENT_STROKE_TYPE_CUSTOM": 1,
        "INDEPENDENT_STROKE_TYPE_ALL": 2,
        "INDEPENDENT_STROKE_TYPE_TOP": 3,
        "INDEPENDENT_STROKE_TYPE_RIGHT": 4,
        "INDEPENDENT_STROKE_TYPE_BOTTOM": 5,
        "INDEPENDENT_STROKE_TYPE_LEFT": 6
      }
    },
    "InputMode": {
      "values": {
        "INPUT_MODE_START": 0,
        "INPUT_MODE_UPDATE": 1,
        "INPUT_MODE_END": 2,
        "INPUT_MODE_FOCUS": 3,
        "INPUT_MODE_BLUR": 4
      }
    },
    "InstanceResetType": {
      "values": {
        "INSTANCE_RESET_TYPE_ALL": 0,
        "INSTANCE_RESET_TYPE_NAME": 1,
        "INSTANCE_RESET_TYPE_SIZE": 2,
        "INSTANCE_RESET_TYPE_OTHERS": 3,
        "INSTANCE_RESET_TYPE_TEXT": 4,
        "INSTANCE_RESET_TYPE_FILLS": 5,
        "INSTANCE_RESET_TYPE_STROKES": 6,
        "INSTANCE_RESET_TYPE_EFFECTS": 7,
        "INSTANCE_RESET_TYPE_PROTOTYPE_INTERACTIONS": 8
      }
    },
    "InstanceSwapPreferredValueType": {
      "values": {
        "INSTANCE_SWAP_PREFERRED_VALUE_TYPE_COMPONENT": 0,
        "INSTANCE_SWAP_PREFERRED_VALUE_TYPE_STATE_GROUP": 1
      }
    },
    "InteractionActionPropValueType": {
      "values": {
        "INTERACTION_ACTION_PROP_VALUE_TYPE_EMPTY": 0,
        "INTERACTION_ACTION_PROP_VALUE_TYPE_NORMAL": 1,
        "INTERACTION_ACTION_PROP_VALUE_TYPE_MIXED": 2
      }
    },
    "InteractionType": {
      "values": {
        "INTERACTION_TYPE_ON_CLICK": 0,
        "INTERACTION_TYPE_AFTER_TIMEOUT": 1,
        "INTERACTION_TYPE_MOUSE_IN": 2,
        "INTERACTION_TYPE_MOUSE_OUT": 3,
        "INTERACTION_TYPE_ON_HOVER": 4,
        "INTERACTION_TYPE_MOUSE_DOWN": 5,
        "INTERACTION_TYPE_MOUSE_UP": 6,
        "INTERACTION_TYPE_ON_PRESS": 7,
        "INTERACTION_TYPE_NONE": 8,
        "INTERACTION_TYPE_DRAG": 9,
        "INTERACTION_TYPE_ON_KEY_DOWN": 10,
        "INTERACTION_TYPE_ON_VOICE": 11,
        "INTERACTION_TYPE_ON_MEDIA_HIT": 12,
        "INTERACTION_TYPE_ON_MEDIA_END": 13,
        "INTERACTION_TYPE_MOUSE_ENTER": 14,
        "INTERACTION_TYPE_MOUSE_LEAVE": 15
      }
    },
    "JsCallCode": {
      "values": {
        "JS_CALL_CODE_DO_NOT_USE_ME_ZERO": 0,
        "JCC_getURLSearchParams": 1095,
        "JCC_cmdSetURLSearchParams": 1108,
        "JCC_generateUrlWithSearchParams": 1118,
        "JCC_emDocumentNodeEvents": 4010,
        "JCC_clipboardReadItems": 128,
        "JCC_clipboardReadFiles": 130,
        "JCC_clipboardWriteFiles": 148,
        "JCC_sendBatchOperation": 136,
        "JCC_saveRepository": 137,
        "JCC_docMode": 138,
        "JCC_getWindowClientInnerSize": 139,
        "JCC_getLeftPanelWidthUserConfig": 140,
        "JCC_getRightPanelWidthUserConfig": 141,
        "JCC_viewState": 5000,
        "JCC_": 10000,
        "JCC_downloadImage": 10002,
        "JCC_traceEvent": 10005,
        "JCC_clipboardReadImageInfos": 10006,
        "JCC_getJsStack": 10008,
        "JCC_requestAnimationFrame": 10009,
        "JCC_toastShow": 10010,
        "JCC_downloadFont": 10011,
        "JCC_isFeatureEnabled": 10012,
        "JCC_cmdTryEditText": 10013,
        "JCC_cmdCheckFontMissing": 10014,
        "JCC_showTitleInput": 10015,
        "JCC_setTimeout": 10016,
        "JCC_clearTimeout": 10017,
        "JCC_setInterval": 10018,
        "JCC_clearInterval": 10019,
        "JCC_clearRepository": 10024,
        "JCC_updateCommentMetaPosition": 10025,
        "JCC_removeComment": 10026,
        "JCC_removeDraftComment": 10027,
        "JCC_createComment": 10028,
        "JCC_abortDrawingComment": 10029,
        "JCC_getTimestampForPerformanceMeasurement": 10031,
        "JCC_createCanvasToRender": 10032,
        "JCC_renderToCanvas": 10033,
        "JCC_finishRendering": 10034,
        "JCC_clipboardWriteCanvasImageBlob": 10035,
        "JCC_clipboardReadProxyFiles": 10036,
        "JCC_addImagesInSVG": 10037,
        "JCC_updateSyncCurrentUsedPublishMixinInFileLibraryList": 10038,
        "JCC_fetchRemoteLibraryChanges": 10039,
        "JCC_readPixels": 10040,
        "JCC_generateLibraryThumbnailKey": 10041,
        "JCC_generateLibraryThumbnail": 10042,
        "JCC_startLibraryPublishModalPublishChecked": 10043,
        "JCC_changeSelection": 10044,
        "JCC_syncRemoteDocLibraryNodeKeys": 10045,
        "JCC_handleTextMissingFont": 10046,
        "JCC_jumpToOriginDocument": 10047,
        "JCC_getTimestampForDurationCalculation": 10048,
        "JCC_setTimeoutForCommand": 10049,
        "JCC_isTabVisible": 10050,
        "JCC_showRecoverComponentModal": 10053,
        "JCC_enqueueMicrotask": 10054,
        "JCC_requestAiLayout": 10055,
        "JCC_pushMetric": 10056,
        "JCC_allImageLoaded": 10057,
        "JCC_replayCanvasEvent": 10058,
        "JCC_readFontInJs": 10059,
        "JCC_replayServiceReplay": 10060,
        "JCC_toggleLibraryMainModal": 10062,
        "JCC_closeLibraryMainModal": 10063,
        "JCC_wsUpdateCurrentMousePosition": 10064,
        "JCC_wsUpdateCurrentSelections": 10065,
        "JCC_wsUpdateCurrentPageId": 10066,
        "JCC_wsUpdateCurrentViewport": 10067,
        "JCC_wsUpdateCurrentObserving": 10068,
        "JCC_wsUpdateCurrentActive": 10069,
        "JCC_wsUpdateCurrentInactive": 10070,
        "JCC_refreshPage": 10072,
        "JCC_wsUpdateCurrentCursorMode": 10074,
        "JCC_commentEsc": 10075,
        "JCC_changeSynergyState": 10076,
        "JCC_PreExportFinish": 10077,
        "JCC_AddExportImage": 10078,
        "JCC_UpdateExportImageNum": 10079,
        "JCC_startClipboardPaste": 10080,
        "JCC_getRenderType": 10081,
        "JCC_startLibraryReplace": 10084,
        "JCC_showLastUsedPlugin": 10085,
        "JCC_checkComponentStyleMovement": 10086,
        "JCC_shouldPrecompileShader": 10088,
        "JCC_evalJsScript": 10090,
        "JCC_openHyperlinkDelay": 10091,
        "JCC_cancelLastOpenHyperlink": 10092,
        "JCC_callAiLayout": 10093,
        "JCC_mouseEnterHyperlink": 10094,
        "JCC_mouseLeaveHyperlink": 10095,
        "JCC_parseHyperlink": 10096,
        "JCC_isValidUrl": 10097,
        "JCC_startClipboardCopy": 10098,
        "JCC_pickImages": 10099,
        "JCC_addFrog": 10100,
        "JCC_historyModeGetPageArchive": 10101,
        "JCC_naturalSortStringArray": 10102,
        "JCC_getMicrosecondTimestampForPerformanceMeasurement": 10104,
        "JCC_toggleBackToInstanceToast": 10105,
        "JCC_clipboardWriteItems": 10106,
        "JCC_writeExportDocument": 10107,
        "JCC_getVectorPanelParsedValue": 10108,
        "JCC_applyCursor": 10109,
        "JCC_getCdnImageUrl": 10110,
        "JCC_changeExceedEditorToastStatus": 10111,
        "JCC_updateFocusView": 10112,
        "JCC_onObservingModeChange": 10113,
        "JCC_changeCanvasColorSpace": 10114,
        "JCC_benchmarkImageDownloadTime": 10115,
        "JCC_startViewportAnimation": 10116,
        "JCC_callAiAlign": 10117,
        "JCC_copyWasmMemToWebBlob": 10118,
        "JCC_generateTempCommentIdForCreating": 10119,
        "JCC_getEditingContentProtected": 10120,
        "JCC_setTimeoutToReloadImage": 10121,
        "JCC_scrollSelectedNodeIntoLayerPanelView": 10122,
        "JCC_generateDocumentNodeUrl": 10123,
        "JCC_callAiCreateAutolayout": 10124,
        "JCC_closeLibraryModalAfterPublish": 10125,
        "JCC_canvasSearchInputFocusAndSelectAll": 10126,
        "JCC_canvasSearchResultListScrollTopTo": 10127,
        "JCC_updateDevModeExportUserConfigToJS": 10128,
        "JCC_getDevModeExportUserConfigFromJS": 10129,
        "JCC_getWallClock": 10130,
        "JCC_getDevModeCodeConfigFromJS": 10131,
        "JCC_callAiDuplicate": 10132,
        "JCC_isCreatingFile": 10133,
        "JCC_pluginShowToast": 10134,
        "JCC_getGPUWorkaround": 10135,
        "JCC_fetchRemoteComponentSetDataForRecover": 10136,
        "JCC_previewServerQuery": 10137,
        "JCC_sendPrototypePresentOpenRecord": 10138,
        "JCC_downloadBase64": 10139,
        "JCC_getPrototypePresentUrlParams": 10141,
        "JCC_setPrototypePresentUrlParams": 10142,
        "JCC_pushBitmap": 10144,
        "JCC_uploadBitmapToGPU": 10145,
        "JCC_showTooltip": 10146,
        "JCC_hideTooltip": 10147,
        "JCC_getValidTransitionTypes": 10148,
        "JCC_openLink": 10149,
        "JCC_freeBitmap": 10150,
        "JCC_GetShowEditorUnAccessibleBannerFromJs": 10151,
        "JCC_prototypePresentHideUI": 10152,
        "JCC_prototypePresentShowUI": 10153,
        "JCC_prepareUploadTexture": 10154,
        "JCC_checkShouldBlockDevMode": 10155,
        "JCC_switchBrowserFullScreen": 10156,
        "JCC_checkIsAIRecognizeDocument": 10157,
        "JCC_prototypePresentClickRootFrameShowBar": 10158,
        "JCC_openPresentInEditor": 10159,
        "JCC_checkAiCredits": 10160,
        "JCC_trySwitchToAiDuplicate": 10161,
        "JCC_getShowFreezeAlert": 10162,
        "JCC_setLocalEditorType": 10163,
        "JCC_getLocalEditorType": 10164,
        "JCC_downloadPrototypeDeviceFrame": 10165,
        "JCC_metricRenderDuration": 10166,
        "JCC_getUserConfig2": 10167,
        "JCC_setUserConfig2": 10168,
        "JCC_loadBenchResult": 10169,
        "JCC_saveBenchResult": 10170,
        "JCC_decodeJsonApplyAiDuplicateResultDataV2": 10173,
        "JCC_decodeJsonAiDuplicateResult": 10174,
        "JCC_decodeJsonNodeBlobInfoV2": 10175,
        "JCC_decodeJsonAIRecognizeResultWithEncodedNodesV2": 10176,
        "JCC_decodeJsonPluginArcTextState": 10177,
        "JCC_removeNullContent": 10178,
        "JCC_pluginShowUI": 10179,
        "JCC_closePlugin": 10180,
        "JCC_postMessageToIFrame": 10181,
        "JCC_postMessageFromIFrame": 10182,
        "JCC_pluginUIShow": 10183,
        "JCC_pluginUIHide": 10184,
        "JCC_pluginUIClose": 10185,
        "JCC_pluginUIResize": 10186,
        "JCC_pluginUIReposition": 10187,
        "JCC_pluginEventNotify": 10188,
        "JCC_flushSandboxMicrotasks": 10189,
        "JCC_getPluginRuntimeCode": 10190,
        "JCC_pluginNotify": 10191,
        "JCC_pluginNotifyCallback": 10192,
        "JCC_pluginApiFetch": 10193,
        "JCC_pluginApiFetchCallback": 10194,
        "JCC_pluginCancelNotify": 10195,
        "JCC_pluginApiGetAsync": 10196,
        "JCC_pluginApiGetAsyncCallback": 10197,
        "JCC_pluginApiSetAsync": 10198,
        "JCC_pluginApiSetAsyncCallback": 10199,
        "JCC_pluginApiDeleteAsync": 10200,
        "JCC_pluginApiDeleteAsyncCallback": 10201,
        "JCC_pluginApiKeysAsync": 10202,
        "JCC_pluginApiKeysAsyncCallback": 10203,
        "JCC_pluginApiLoadFontCallback": 10204,
        "JCC_pluginApiListAvailableFontsAsyncCallback": 10205,
        "JCC_saveVersionHistoryAsync": 10206,
        "JCC_saveVersionHistoryAsyncCallback": 10207,
        "JCC_pluginApiResolveExternalPromise": 10208,
        "JCC_pluginApiCreateExternalPromise": 10209,
        "JCC_getLoadedImageBitmapBytes": 10210,
        "JCC_getImageBytesAsyncCallback": 10211,
        "JCC_getImageSizeAsyncCallback": 10212,
        "JCC_getBackendType": 10213,
        "JCC_pluginApiCreateImage": 10214,
        "JCC_pluginDropEventNotify": 10215,
        "JCC_measureControlTextWidth": 10216,
        "JCC_createControlTextBitmap": 10217,
        "JCC_pluginApiExportAsync": 10218,
        "JCC_logPluginScriptError": 10219,
        "JCC_createControlFont": 10220,
        "JCC_readMagnifierBuffer": 10221,
        "JCC_pluginApiGetFullAvatarImageUrl": 10222,
        "JCC_readSyncWGPU": 10223,
        "JCC_refreshPageWithDialog": 10224,
        "JCC_getCanvasInfo": 10225,
        "JCC_importRemoteLibNodeByKeyAsync": 10226,
        "JCC_importRemoteLibNodeByKeyAsyncCallback": 10227,
        "JCC_pluginDropEventNeeded": 10228,
        "JCC_traceGpuLostAndCrash": 10229,
        "JCC_loadFallbackFontSync": 10230,
        "JCC_detachSelectedInstanceReport": 10231,
        "JCC_LocalVariableEditor_SetLatestSelectedCollection": 10232,
        "JCC_LocalVariableEditor_GetLatestSelectedCollection": 10233,
        "JCC_pluginSaveEventsToHostServiceContext": 10234,
        "JCC_LocalVariableEditor_GetCollectionCollapsedGroupKeys": 10235,
        "JCC_LocalVariableEditor_SetCollectionCollapsedGroupKeys": 10236,
        "JCC_sendEditorTypeToSynergy": 10237,
        "JCC_getMotiffScopeConfig": 10238,
        "JCC_openChatbotModal": 10239,
        "JCC_pluginEventNotifyV2": 10240,
        "JCC_asyncFocusAndActiveCommentWithAnimation": 10241,
        "JCC_publishLibrary": 10242
      }
    },
    "KeyCode": {
      "values": {
        "KEY_CODE_DO_NOT_USE_ME_ZERO": 0,
        "KEY_CODE_BACK_SPACE": 8,
        "KEY_CODE_TAB": 9,
        "KEY_CODE_CLEAR": 12,
        "KEY_CODE_ENTER": 13,
        "KEY_CODE_SHIFT": 16,
        "KEY_CODE_CONTROL": 17,
        "KEY_CODE_ALT": 18,
        "KEY_CODE_PAUSE_BREAK": 19,
        "KEY_CODE_CAPE_LOCK": 20,
        "KEY_CODE_ESC": 27,
        "KEY_CODE_SPACE": 32,
        "KEY_CODE_PAGE_UP": 33,
        "KEY_CODE_PAGE_DOWN": 34,
        "KEY_CODE_END": 35,
        "KEY_CODE_HOME": 36,
        "KEY_CODE_LEFT_ARROW": 37,
        "KEY_CODE_UP_ARROW": 38,
        "KEY_CODE_RIGHT_ARROW": 39,
        "KEY_CODE_DOWN_ARROW": 40,
        "KEY_CODE_INSERT": 45,
        "KEY_CODE_DELETE": 46,
        "KEY_CODE_NUM0": 48,
        "KEY_CODE_NUM1": 49,
        "KEY_CODE_NUM2": 50,
        "KEY_CODE_NUM3": 51,
        "KEY_CODE_NUM4": 52,
        "KEY_CODE_NUM5": 53,
        "KEY_CODE_NUM6": 54,
        "KEY_CODE_NUM7": 55,
        "KEY_CODE_NUM8": 56,
        "KEY_CODE_NUM9": 57,
        "KEY_CODE_A": 65,
        "KEY_CODE_B": 66,
        "KEY_CODE_C": 67,
        "KEY_CODE_D": 68,
        "KEY_CODE_E": 69,
        "KEY_CODE_F": 70,
        "KEY_CODE_G": 71,
        "KEY_CODE_H": 72,
        "KEY_CODE_I": 73,
        "KEY_CODE_J": 74,
        "KEY_CODE_K": 75,
        "KEY_CODE_L": 76,
        "KEY_CODE_M": 77,
        "KEY_CODE_N": 78,
        "KEY_CODE_O": 79,
        "KEY_CODE_P": 80,
        "KEY_CODE_Q": 81,
        "KEY_CODE_R": 82,
        "KEY_CODE_S": 83,
        "KEY_CODE_T": 84,
        "KEY_CODE_U": 85,
        "KEY_CODE_V": 86,
        "KEY_CODE_W": 87,
        "KEY_CODE_X": 88,
        "KEY_CODE_Y": 89,
        "KEY_CODE_Z": 90,
        "KEY_CODE_META_LEFT": 91,
        "KEY_CODE_META_RIGHT": 93,
        "KEY_CODE_NUMPAD0": 96,
        "KEY_CODE_NUMPAD1": 97,
        "KEY_CODE_NUMPAD2": 98,
        "KEY_CODE_NUMPAD3": 99,
        "KEY_CODE_NUMPAD4": 100,
        "KEY_CODE_NUMPAD5": 101,
        "KEY_CODE_NUMPAD6": 102,
        "KEY_CODE_NUMPAD7": 103,
        "KEY_CODE_NUMPAD8": 104,
        "KEY_CODE_NUMPAD9": 105,
        "KEY_CODE_MULTIPLY": 106,
        "KEY_CODE_ADD": 107,
        "KEY_CODE_SUBTRACT": 109,
        "KEY_CODE_DECIMAL_POINT": 110,
        "KEY_CODE_DIVIDE": 111,
        "KEY_CODE_F1": 112,
        "KEY_CODE_F2": 113,
        "KEY_CODE_F3": 114,
        "KEY_CODE_F4": 115,
        "KEY_CODE_F5": 116,
        "KEY_CODE_F6": 117,
        "KEY_CODE_F7": 118,
        "KEY_CODE_F8": 119,
        "KEY_CODE_F9": 120,
        "KEY_CODE_F10": 121,
        "KEY_CODE_F11": 122,
        "KEY_CODE_F12": 123,
        "KEY_CODE_NUM_LOCK": 144,
        "KEY_CODE_SCROLL_LOCK": 145,
        "KEY_CODE_SEMI_COLON": 186,
        "KEY_CODE_EQUALS": 187,
        "KEY_CODE_COMMA": 188,
        "KEY_CODE_DASH": 189,
        "KEY_CODE_PERIOD": 190,
        "KEY_CODE_FORWARD_SLASH": 191,
        "KEY_CODE_TILDE": 192,
        "KEY_CODE_OPEN_BRACKET": 219,
        "KEY_CODE_BACKSLASH": 220,
        "KEY_CODE_CLOSED_BRACKET": 221,
        "KEY_CODE_QUOTE": 222,
        "KEY_CODE_FOR_TEST": 1000,
        "KEY_CODE_FOR_TEST_OTHER": 1001
      }
    },
    "KeyEventType": {
      "values": {
        "KEY_EVENT_TYPE_KEY_DOWN": 0,
        "KEY_EVENT_TYPE_KEY_UP": 1,
        "KEY_EVENT_TYPE_UNKNOWN": 2
      }
    },
    "KeyMouseState": {
      "values": {
        "KEY_MOUSE_STATE_D_O_W_N": 0,
        "KEY_MOUSE_STATE_U_P": 1
      }
    },
    "KeyboardEventTraceSource": {
      "values": {
        "KEYBOARD_EVENT_TRACE_SOURCE_UNKNOWN": 0,
        "KEYBOARD_EVENT_TRACE_SOURCE_KEYBOARD_RECEIVER": 1,
        "KEYBOARD_EVENT_TRACE_SOURCE_FOCUS_VIEW_MANAGER": 2,
        "KEYBOARD_EVENT_TRACE_SOURCE_CANVAS_SEARCH": 3,
        "KEYBOARD_EVENT_TRACE_SOURCE_TEXT_INPUT": 4,
        "KEYBOARD_EVENT_TRACE_SOURCE_BASE_INPUT_COMPONENT": 5
      }
    },
    "LayerPanelItemType": {
      "values": {
        "LAYER_PANEL_ITEM_TYPE_NODE": 0,
        "LAYER_PANEL_ITEM_TYPE_SECTION": 1,
        "LAYER_PANEL_ITEM_TYPE_SEE_ALL_LAYERS": 2
      }
    },
    "LayerPanelMaskLineStatus": {
      "values": {
        "LAYER_PANEL_MASK_LINE_STATUS_START": 0,
        "LAYER_PANEL_MASK_LINE_STATUS_MIDDLE": 1,
        "LAYER_PANEL_MASK_LINE_STATUS_END": 2
      }
    },
    "LayoutGridPattern": {
      "values": {
        "LAYOUT_GRID_PATTERN_GRID": 0,
        "LAYOUT_GRID_PATTERN_ROWS": 1,
        "LAYOUT_GRID_PATTERN_COLUMNS": 2
      }
    },
    "LayoutGridStyleIconType": {
      "values": {
        "LAYOUT_GRID_STYLE_ICON_TYPE_DEFAULT": 0,
        "LAYOUT_GRID_STYLE_ICON_TYPE_GRID": 1,
        "LAYOUT_GRID_STYLE_ICON_TYPE_ROW": 2,
        "LAYOUT_GRID_STYLE_ICON_TYPE_COLUMN": 3
      }
    },
    "LayoutOrigin": {
      "values": {
        "LAYOUT_ORIGIN_TOP_LEFT": 0,
        "LAYOUT_ORIGIN_TOP": 1,
        "LAYOUT_ORIGIN_TOP_RIGHT": 2,
        "LAYOUT_ORIGIN_LEFT": 3,
        "LAYOUT_ORIGIN_CENTER": 4,
        "LAYOUT_ORIGIN_RIGHT": 5,
        "LAYOUT_ORIGIN_BOTTOM_LEFT": 6,
        "LAYOUT_ORIGIN_BOTTOM": 7,
        "LAYOUT_ORIGIN_BOTTOM_RIGHT": 8,
        "LAYOUT_ORIGIN_NONE": 9
      }
    },
    "LibraryChangeType": {
      "values": {
        "LIBRARY_CHANGE_TYPE_DO_NOT_USE_ME_ZERO": 0,
        "LIBRARY_CHANGE_TYPE_ADD": 1,
        "LIBRARY_CHANGE_TYPE_UPDATE": 2,
        "LIBRARY_CHANGE_TYPE_REMOVE": 3,
        "LIBRARY_CHANGE_TYPE_REORDER": 4,
        "LIBRARY_CHANGE_TYPE_UNCHANGE": 5,
        "LIBRARY_CHANGE_TYPE_MOVEMENT": 6
      }
    },
    "LibraryLayoutSizeType": {
      "values": {
        "LIBRARY_LAYOUT_SIZE_TYPE_DEFAULT": 0,
        "LIBRARY_LAYOUT_SIZE_TYPE_SMALL": 1,
        "LIBRARY_LAYOUT_SIZE_TYPE_WIDE": 2
      }
    },
    "LibraryMainModalRoutePathType": {
      "values": {
        "LIBRARY_MAIN_MODAL_ROUTE_PATH_TYPE_NONE": 0,
        "LIBRARY_MAIN_MODAL_ROUTE_PATH_TYPE_CURRENT_LOCAL": 1,
        "LIBRARY_MAIN_MODAL_ROUTE_PATH_TYPE_REMOTE_LIBRARY_TOTAL_LIST": 2,
        "LIBRARY_MAIN_MODAL_ROUTE_PATH_TYPE_REMOTE_MISS_LIBRARY_DETAIL": 3,
        "LIBRARY_MAIN_MODAL_ROUTE_PATH_TYPE_REMOTE_LIBRARY_DETAIL": 4,
        "LIBRARY_MAIN_MODAL_ROUTE_PATH_TYPE_REMOTE_LIBRARY_CHANGE_LIST": 5,
        "LIBRARY_MAIN_MODAL_ROUTE_PATH_TYPE_REPLACE_LIBRARY_DETAIL": 6
      }
    },
    "LibraryNotificationType": {
      "values": {
        "LIBRARY_NOTIFICATION_TYPE_NONE": 0,
        "LIBRARY_NOTIFICATION_TYPE_COMPONENT_MOVED": 1,
        "LIBRARY_NOTIFICATION_TYPE_STYLE_MOVED": 2,
        "LIBRARY_NOTIFICATION_TYPE_REFERENCE_COMPONENT_MOVED": 3,
        "LIBRARY_NOTIFICATION_TYPE_REFERENCE_STYLE_MOVED": 4,
        "LIBRARY_NOTIFICATION_TYPE_REFERENCE_COMPONENT_STYLE_MOVED": 5,
        "LIBRARY_NOTIFICATION_TYPE_REFERENCE_UPDATED": 6,
        "LIBRARY_NOTIFICATION_TYPE_REFERENCE_COMPONENT_UPDATED": 7,
        "LIBRARY_NOTIFICATION_TYPE_REFERENCE_STYLE_UPDATED": 8,
        "LIBRARY_NOTIFICATION_TYPE_REFERENCE_VARIABLE_UPDATED": 9,
        "LIBRARY_NOTIFICATION_TYPE_VARIABLE_AND_STYLE_UPDATED": 10,
        "LIBRARY_NOTIFICATION_TYPE_VARIABLE_AND_COMPONENT_UPDATED": 11,
        "LIBRARY_NOTIFICATION_TYPE_VARIABLE_AND_COMPONENT_AND_STYLE_UPDATED": 12
      }
    },
    "LibraryPublishModalControlType": {
      "values": {
        "LIBRARY_PUBLISH_MODAL_CONTROL_TYPE_INVALID": 0,
        "LIBRARY_PUBLISH_MODAL_CONTROL_TYPE_CHANGED": 1,
        "LIBRARY_PUBLISH_MODAL_CONTROL_TYPE_UNCHANGE": 2,
        "LIBRARY_PUBLISH_MODAL_CONTROL_TYPE_HIDDEN": 3
      }
    },
    "LibraryReplaceModalItemDisplayType": {
      "values": {
        "LIBRARY_REPLACE_MODAL_ITEM_DISPLAY_TYPE_NONE": 0,
        "LIBRARY_REPLACE_MODAL_ITEM_DISPLAY_TYPE_DIVIDER": 1,
        "LIBRARY_REPLACE_MODAL_ITEM_DISPLAY_TYPE_STYLE_TITLE": 2,
        "LIBRARY_REPLACE_MODAL_ITEM_DISPLAY_TYPE_COMPONENT_TITLE": 3,
        "LIBRARY_REPLACE_MODAL_ITEM_DISPLAY_TYPE_STYLE_REPLACE_ITEM": 4,
        "LIBRARY_REPLACE_MODAL_ITEM_DISPLAY_TYPE_COMPONENT_SET_REPLACE_ITEM": 5,
        "LIBRARY_REPLACE_MODAL_ITEM_DISPLAY_TYPE_COMPONENT_REPLACE_ITEM": 6
      }
    },
    "LibraryShareScopeType": {
      "values": {
        "LIBRARY_SHARE_SCOPE_TYPE_TEAM": 0,
        "LIBRARY_SHARE_SCOPE_TYPE_ORG": 1,
        "LIBRARY_SHARE_SCOPE_TYPE_WORKSPACE": 2
      }
    },
    "LineType": {
      "values": {
        "LINE_TYPE_PLAIN": 0,
        "LINE_TYPE_ORDERED_LIST": 1,
        "LINE_TYPE_UNORDERED_LIST": 2,
        "LINE_TYPE_BLOCKQUOTE": 3,
        "LINE_TYPE_HEADER": 4
      }
    },
    "LoadFontStatus": {
      "values": {
        "LOAD_FONT_STATUS_LOADING": 0,
        "LOAD_FONT_STATUS_LOADED": 1,
        "LOAD_FONT_STATUS_FAILED": 2
      }
    },
    "LocalStorageKey": {
      "values": {
        "LOCAL_STORAGE_KEY_EDITOR_TYPE": 0
      }
    },
    "LocalStyleEditingNameType": {
      "values": {
        "LOCAL_STYLE_EDITING_NAME_TYPE_NONE": 0,
        "LOCAL_STYLE_EDITING_NAME_TYPE_STYLE": 1,
        "LOCAL_STYLE_EDITING_NAME_TYPE_FOLDER": 2
      }
    },
    "LocalStyleSelectionType": {
      "values": {
        "LOCAL_STYLE_SELECTION_TYPE_NONE": 0,
        "LOCAL_STYLE_SELECTION_TYPE_TEXT": 1,
        "LOCAL_STYLE_SELECTION_TYPE_PAINT": 2,
        "LOCAL_STYLE_SELECTION_TYPE_EFFECT": 3,
        "LOCAL_STYLE_SELECTION_TYPE_LAYOUT_GRID": 4
      }
    },
    "LocalVariableDragPosition": {
      "values": {
        "LOCAL_VARIABLE_DRAG_POSITION_BEFORE": 0,
        "LOCAL_VARIABLE_DRAG_POSITION_AFTER": 1,
        "LOCAL_VARIABLE_DRAG_POSITION_INTO": 2
      }
    },
    "LocalVariableEditorEditingType": {
      "values": {
        "LOCAL_VARIABLE_EDITOR_EDITING_TYPE_NONE": 0,
        "LOCAL_VARIABLE_EDITOR_EDITING_TYPE_COLOR_VALUE": 1,
        "LOCAL_VARIABLE_EDITOR_EDITING_TYPE_DETAIL": 2,
        "LOCAL_VARIABLE_EDITOR_EDITING_TYPE_COLOR_VALUE_ALIAS": 3,
        "LOCAL_VARIABLE_EDITOR_EDITING_TYPE_NUMBER_VALUE_ALIAS": 5
      }
    },
    "LocalVariableEditorRenamingType": {
      "values": {
        "LOCAL_VARIABLE_EDITOR_RENAMING_TYPE_NONE": 0,
        "LOCAL_VARIABLE_EDITOR_RENAMING_TYPE_VARIABLE": 1,
        "LOCAL_VARIABLE_EDITOR_RENAMING_TYPE_COLLECTION": 2
      }
    },
    "LocalVariableTableItemType": {
      "values": {
        "LOCAL_VARIABLE_TABLE_ITEM_TYPE_G_R_O_U_P": 0,
        "LOCAL_VARIABLE_TABLE_ITEM_TYPE_V_A_R_I_A_B_L_E": 1
      }
    },
    "LooperRotateMode": {
      "values": {
        "LOOPER_ROTATE_MODE_LINER": 0,
        "LOOPER_ROTATE_MODE_SINE": 1
      }
    },
    "MediaAction": {
      "values": {
        "MEDIA_ACTION_PLAY": 0,
        "MEDIA_ACTION_PAUSE": 1,
        "MEDIA_ACTION_TOGGLE_PLAY_PAUSE": 2,
        "MEDIA_ACTION_MUTE": 3,
        "MEDIA_ACTION_UNMUTE": 4,
        "MEDIA_ACTION_TOGGLE_MUTE_UNMUTE": 5,
        "MEDIA_ACTION_SKIP_FORWARD": 6,
        "MEDIA_ACTION_SKIP_BACKWARD": 7,
        "MEDIA_ACTION_SKIP_TO": 8
      }
    },
    "MetricImageType": {
      "values": {
        "METRIC_IMAGE_TYPE_ORIGIN": 0,
        "METRIC_IMAGE_TYPE_PREVIEW": 1,
        "METRIC_IMAGE_TYPE_EMOJI": 2
      }
    },
    "MetricRenderDurationItemType": {
      "values": {
        "METRIC_RENDER_DURATION_ITEM_TYPE_DOM_TREE_DIRTY": 0,
        "METRIC_RENDER_DURATION_ITEM_TYPE_VIEWPORT_SCALE_CHANGED": 1,
        "METRIC_RENDER_DURATION_ITEM_TYPE_VIEWPORT_TRANSLATION_CHANGED": 2,
        "METRIC_RENDER_DURATION_ITEM_TYPE_OTHER": 3
      }
    },
    "MirrorViewportScaling": {
      "values": {
        "SCALE_DOWN_WIDTH": 0,
        "MIN_ZOOM": 1
      }
    },
    "MixedCheckState": {
      "values": {
        "MIXED_CHECK_STATE_TRUE": 0,
        "MIXED_CHECK_STATE_FALSE": 1,
        "MIXED_CHECK_STATE_MIXED": 2
      }
    },
    "MouseEventCallbackType": {
      "values": {
        "MOUSE_EVENT_CALLBACK_TYPE_I_N": 0,
        "MOUSE_EVENT_CALLBACK_TYPE_M_O_V_E": 1,
        "MOUSE_EVENT_CALLBACK_TYPE_D_O_W_N": 2,
        "DRAG_START": 3,
        "MOUSE_EVENT_CALLBACK_TYPE_D_R_A_G": 4,
        "DRAG_END": 5,
        "MOUSE_EVENT_CALLBACK_TYPE_U_P": 6,
        "MOUSE_EVENT_CALLBACK_TYPE_O_U_T": 7,
        "MOUSE_EVENT_CALLBACK_TYPE_C_L_I_C_K": 8,
        "DOUBLE_CLICK": 9,
        "TRIPLE_CLICK": 10,
        "QUADRA_CLICK": 11,
        "CONTEXT_MENU": 12,
        "MOUSE_EVENT_CALLBACK_TYPE_W_H_E_E_L": 13
      }
    },
    "MouseEventType": {
      "values": {
        "MOUSE_EVENT_TYPE_MOVE": 0,
        "MOUSE_EVENT_TYPE_DOWN": 1,
        "MOUSE_EVENT_TYPE_UP": 2,
        "MOUSE_EVENT_TYPE_DOUBLE_CLICK": 3,
        "MOUSE_EVENT_TYPE_CONTEXT_MENU": 4,
        "MOUSE_EVENT_TYPE_CLICK": 5,
        "MOUSE_EVENT_TYPE_LEAVE": 6
      }
    },
    "MoveDirection": {
      "values": {
        "MOVE_DIRECTION_L_E_F_T": 0,
        "MOVE_DIRECTION_R_I_G_H_T": 1,
        "MOVE_DIRECTION_U_P": 2,
        "MOVE_DIRECTION_D_O_W_N": 3,
        "MOVE_DIRECTION_H_O_M_E": 4,
        "MOVE_DIRECTION_E_N_D": 5,
        "MOVE_DIRECTION_NONE": 6
      }
    },
    "MovingTargetType": {
      "values": {
        "MOVING_TARGET_TYPE_COMMENT": 0,
        "MOVING_TARGET_TYPE_COMMENT_ANCHOR": 1,
        "MOVING_TARGET_TYPE_DRAFT_COMMENT": 2,
        "MOVING_TARGET_TYPE_DRAFT_COMMENT_ANCHOR": 3
      }
    },
    "MultiHoverBorderColorType": {
      "values": {
        "MULTI_HOVER_BORDER_COLOR_TYPE_BLUE": 0,
        "MULTI_HOVER_BORDER_COLOR_TYPE_PURPLE": 1
      }
    },
    "NavigationType": {
      "values": {
        "NAVIGATION_TYPE_NAVIGATE": 0,
        "NAVIGATION_TYPE_OVERLAY": 1,
        "NAVIGATION_TYPE_SWAP": 2,
        "NAVIGATION_TYPE_SWAP_STATE": 3,
        "NAVIGATION_TYPE_SCROLL_TO": 4
      }
    },
    "NodeEventType": {
      "values": {
        "NODE_EVENT_TYPE_CREATED": 0,
        "NODE_EVENT_TYPE_UPDATED": 1,
        "NODE_EVENT_TYPE_REMOVED": 2
      }
    },
    "NodeIconType": {
      "values": {
        "NODE_ICON_TYPE_COMPONENT": 0,
        "NODE_ICON_TYPE_COMPONENT_ABSOLUTE": 1,
        "NODE_ICON_TYPE_INSTANCE": 2,
        "NODE_ICON_TYPE_INSTANCE_ABSOLUTE": 3,
        "NODE_ICON_TYPE_VARIANT": 4,
        "NODE_ICON_TYPE_VARIANT_ABSOLUTE": 5,
        "NODE_ICON_TYPE_FRAME": 6,
        "NODE_ICON_TYPE_FRAME_ABSOLUTE": 7,
        "NODE_ICON_TYPE_GROUP": 8,
        "NODE_ICON_TYPE_GROUP_ABSOLUTE": 9,
        "NODE_ICON_TYPE_TEXT": 10,
        "NODE_ICON_TYPE_TEXT_ABSOLUTE": 11,
        "NODE_ICON_TYPE_POLYGON": 12,
        "NODE_ICON_TYPE_POLYGON_ABSOLUTE": 13,
        "NODE_ICON_TYPE_STAR": 14,
        "NODE_ICON_TYPE_STAR_ABSOLUTE": 15,
        "NODE_ICON_TYPE_VECTOR": 16,
        "NODE_ICON_TYPE_VECTOR_ABSOLUTE": 17,
        "NODE_ICON_TYPE_BOOL_UNION": 18,
        "NODE_ICON_TYPE_BOOL_UNION_ABSOLUTE": 19,
        "NODE_ICON_TYPE_BOOL_INTERSECT": 20,
        "NODE_ICON_TYPE_BOOL_INTERSECT_ABSOLUTE": 21,
        "NODE_ICON_TYPE_BOOL_SUBTRACT": 22,
        "NODE_ICON_TYPE_BOOL_SUBTRACT_ABSOLUTE": 23,
        "NODE_ICON_TYPE_BOOL_EXCLUDE": 24,
        "NODE_ICON_TYPE_BOOL_EXCLUDE_ABSOLUTE": 25,
        "NODE_ICON_TYPE_SLICE": 43,
        "NODE_ICON_TYPE_SLICE_ABSOLUTE": 44,
        "NODE_ICON_TYPE_AUTO_LAYOUT_TOP": 26,
        "NODE_ICON_TYPE_AUTO_LAYOUT_TOP_ABSOLUTE": 27,
        "NODE_ICON_TYPE_AUTO_LAYOUT_CENTER_VERTICAL": 28,
        "NODE_ICON_TYPE_AUTO_LAYOUT_CENTER_VERTICAL_ABSOLUTE": 29,
        "NODE_ICON_TYPE_AUTO_LAYOUT_BOTTOM": 30,
        "NODE_ICON_TYPE_AUTO_LAYOUT_BOTTOM_ABSOLUTE": 31,
        "NODE_ICON_TYPE_AUTO_LAYOUT_LEFT": 32,
        "NODE_ICON_TYPE_AUTO_LAYOUT_LEFT_ABSOLUTE": 33,
        "NODE_ICON_TYPE_AUTO_LAYOUT_CENTER_HORIZONTAL": 34,
        "NODE_ICON_TYPE_AUTO_LAYOUT_CENTER_HORIZONTAL_ABSOLUTE": 35,
        "NODE_ICON_TYPE_AUTO_LAYOUT_RIGHT": 36,
        "NODE_ICON_TYPE_AUTO_LAYOUT_RIGHT_ABSOLUTE": 37,
        "NODE_ICON_TYPE_IMAGE": 38,
        "NODE_ICON_TYPE_IMAGE_ABSOLUTE": 39,
        "NODE_ICON_TYPE_MASK": 40,
        "NODE_ICON_TYPE_MASK_ABSOLUTE": 41,
        "NODE_ICON_TYPE_CUSTOM": 42,
        "NODE_ICON_TYPE_A_I_HORIZONTAL_BOTTOM_ABSOLUTE": 47,
        "NODE_ICON_TYPE_A_I_HORIZONTAL_BOTTOM": 48,
        "NODE_ICON_TYPE_A_I_HORIZONTAL_CENTER_ABSOLUTE": 49,
        "NODE_ICON_TYPE_A_I_HORIZONTAL_CENTER": 50,
        "NODE_ICON_TYPE_A_I_HORIZONTAL_TOP_ABSOLUTE": 51,
        "NODE_ICON_TYPE_A_I_HORIZONTAL_TOP": 52,
        "NODE_ICON_TYPE_A_I_VERTICAL_CENTER_ABSOLUTE": 53,
        "NODE_ICON_TYPE_A_I_VERTICAL_CENTER": 54,
        "NODE_ICON_TYPE_A_I_VERTICAL_LEFT_ABSOLUTE": 55,
        "NODE_ICON_TYPE_A_I_VERTICAL_LEFT": 56,
        "NODE_ICON_TYPE_A_I_VERTICAL_RIGHT_ABSOLUTE": 57,
        "NODE_ICON_TYPE_A_I_VERTICAL_RIGHT": 58,
        "NODE_ICON_TYPE_A_I_FRAME_ABSOLUTE": 61,
        "NODE_ICON_TYPE_A_I_FRAME": 62,
        "NODE_ICON_TYPE_A_I_GROUP_ABSOLUTE": 59,
        "NODE_ICON_TYPE_A_I_GROUP": 60,
        "NODE_ICON_TYPE_CANDIDATE_COMPONENT": 63,
        "NODE_ICON_TYPE_AUTO_LAYOUT_WRAP_LEFT": 64,
        "NODE_ICON_TYPE_AUTO_LAYOUT_WRAP_LEFT_ABSOLUTE": 65,
        "NODE_ICON_TYPE_AUTO_LAYOUT_WRAP_CENTER": 66,
        "NODE_ICON_TYPE_AUTO_LAYOUT_WRAP_CENTER_ABSOLUTE": 67,
        "NODE_ICON_TYPE_AUTO_LAYOUT_WRAP_RIGHT": 68,
        "NODE_ICON_TYPE_AUTO_LAYOUT_WRAP_RIGHT_ABSOLUTE": 69,
        "NODE_ICON_TYPE_A_I_AUTO_LAYOUT_WRAP_LEFT": 70,
        "NODE_ICON_TYPE_A_I_AUTO_LAYOUT_WRAP_LEFT_ABSOLUTE": 71,
        "NODE_ICON_TYPE_A_I_AUTO_LAYOUT_WRAP_CENTER": 72,
        "NODE_ICON_TYPE_A_I_AUTO_LAYOUT_WRAP_CENTER_ABSOLUTE": 73,
        "NODE_ICON_TYPE_A_I_AUTO_LAYOUT_WRAP_RIGHT": 74,
        "NODE_ICON_TYPE_A_I_AUTO_LAYOUT_WRAP_RIGHT_ABSOLUTE": 75,
        "NODE_ICON_TYPE_CANDIDATE_COMPONENT_ABSOLUTE": 76
      }
    },
    "NodeProps": {
      "values": {
        "NP_": 0,
        "NP_id": 1,
        "NP_childrenIds": 2,
        "NP_activatedPluginType": 3,
        "NP_activedCommentId": 4,
        "NP_aiCandidateComponentData": 5,
        "NP_aiLayoutEntryMenuState": 6,
        "NP_aiPoweredNodeId": 7,
        "NP_aiPoweredReason": 8,
        "NP_allFonts": 9,
        "NP_allowIconAutoDetection": 10,
        "NP_ancestorPathBeforeDeletion": 11,
        "NP_attrPanelStyleEditorState": 12,
        "NP_autoLayoutHoverMenuItem": 13,
        "NP_autoLayoutInvisibleNodes": 14,
        "NP_autoLayoutSpaceInput": 15,
        "NP_autoRename": 16,
        "NP_availableFonts": 17,
        "NP_backgrounds": 18,
        "NP_batchExportPopupState": 19,
        "NP_bigNudgeAmount": 20,
        "NP_blendMode": 21,
        "NP_booleanOperation": 22,
        "NP_borderBottomWeight": 23,
        "NP_borderLeftWeight": 24,
        "NP_borderRightWeight": 25,
        "NP_borderStrokeWeightsIndependent": 26,
        "NP_borderTopWeight": 27,
        "NP_bottomLeftRadius": 28,
        "NP_bottomRightRadius": 29,
        "NP_canvasRightClickMenuVisible": 30,
        "NP_canvasSearchParam": 31,
        "NP_canvasSearchResult": 32,
        "NP_canvasSearchResultLayerItemForScroll": 33,
        "NP_canvasSearchResultSelection": 34,
        "NP_canvasSearchResultTextContentMatchSelection": 35,
        "NP_canvasSearchResultViewport": 36,
        "NP_children": 37,
        "NP_clipsContent": 38,
        "NP_coactorMembershipMap": 39,
        "NP_coactorMouseMap": 40,
        "NP_coactorObservedMap": 41,
        "NP_coactorSelectionsMap": 42,
        "NP_coactorViewportMap": 43,
        "NP_colorMode": 44,
        "NP_colorPickState": 45,
        "NP_commentClusterResult": 46,
        "NP_commentCreatedTime": 47,
        "NP_commentHeadCount": 48,
        "NP_commentMetaPosition": 49,
        "NP_commentPageId": 50,
        "NP_commentRenderPage": 51,
        "NP_commentVisible": 52,
        "NP_componentPropAssignments": 53,
        "NP_componentPropDefs": 54,
        "NP_componentPropRefs": 55,
        "NP_componentPropSelectionState": 56,
        "NP_componentThumbnailDragging": 57,
        "NP_constrainProportions": 58,
        "NP_constraints": 59,
        "NP_contentHash": 60,
        "NP_controlFontLoadChangeId": 61,
        "NP_copiedFromId": 62,
        "NP_cornerRadius": 63,
        "NP_cornerSmoothing": 64,
        "NP_currentPageId": 65,
        "NP_currentViewingFrameId": 66,
        "NP_cursorWithinCanvas": 67,
        "NP_dashCap": 68,
        "NP_dashPattern": 69,
        "NP_debouncedMovingBoundsPositionHold": 70,
        "NP_derivedSymbolData": 71,
        "NP_description": 72,
        "NP_detachOpticalSizeFromFontSize": 73,
        "NP_devModeAvailability": 74,
        "NP_devModeBatchExportPopupState": 75,
        "NP_devModeDisplayPanels": 76,
        "NP_devModeExportCompress": 77,
        "NP_devModeHoveredNode": 78,
        "NP_devModeInspectCodeConfig": 79,
        "NP_devModeInspectCodeType": 80,
        "NP_devModeInspectExportConfig": 81,
        "NP_devModeInspectType": 82,
        "NP_devModeLayerPanelHoveredNodeId": 83,
        "NP_devicePixelRatio": 84,
        "NP_displayPanels": 85,
        "NP_docId": 86,
        "NP_docReadonly": 87,
        "NP_documentColorProfile": 88,
        "NP_documentColorProfileMeta": 89,
        "NP_domRegionBounds": 90,
        "NP_domRegionPath": 91,
        "NP_draftComment": 92,
        "NP_dragMoveMode": 93,
        "NP_dragWithSpace": 94,
        "NP_drawingComment": 95,
        "NP_editInfo": 96,
        "NP_editedProps": 97,
        "NP_editingNameState": 98,
        "NP_editingPaint": 99,
        "NP_editingTextNodeState": 100,
        "NP_editingVectorPenState": 101,
        "NP_editingVectorState": 102,
        "NP_editorMode": 103,
        "NP_editorType": 104,
        "NP_effectStyleId": 105,
        "NP_effects": 106,
        "NP_enableLayoutGrid": 107,
        "NP_enablePixelGrid": 108,
        "NP_endCap": 109,
        "NP_endingAngle": 110,
        "NP_enterLayerPanelArea": 111,
        "NP_expandBoundsRotationCollisionRange": 112,
        "NP_expanded": 113,
        "NP_exportSettings": 114,
        "NP_fallbackFontDownloadedSignal": 115,
        "NP_fillGeometry": 116,
        "NP_fillStyleId": 117,
        "NP_fills": 118,
        "NP_fontName": 119,
        "NP_fontSize": 120,
        "NP_fontVariations": 121,
        "NP_forceDisplayAllInteractions": 122,
        "NP_forceShowGuildLineForDevMode": 123,
        "NP_freezeSelectionPaint": 124,
        "NP_fromFig": 125,
        "NP_fromSketch": 126,
        "NP_fullscreen": 127,
        "NP_gridStyleId": 128,
        "NP_guides": 129,
        "NP_handToolDraggingState": 130,
        "NP_handleMirroring": 131,
        "NP_hasBeenOpened": 132,
        "NP_height": 133,
        "NP_historyMode": 134,
        "NP_hoverPopupHyperlink": 135,
        "NP_hoveredCandidateIconNode": 136,
        "NP_hoveredCommentId": 137,
        "NP_hoveredElId": 138,
        "NP_hoveredGuideIndex": 139,
        "NP_hoveredGuideInfo": 140,
        "NP_hoveredHyperlink": 141,
        "NP_hoveredInteractionKeys": 142,
        "NP_hoveredMultiNodeIds": 143,
        "NP_hoveredNodeId": 144,
        "NP_hyperlink": 145,
        "NP_hyperlinkEditingMode": 146,
        "NP_imageLoadedSignal": 147,
        "NP_imageName": 148,
        "NP_imageSearchProgress": 149,
        "NP_imageStates": 150,
        "NP_independentCorners": 151,
        "NP_index": 152,
        "NP_infiniteSelectionPaint": 153,
        "NP_innerRadius": 154,
        "NP_insertNodeState": 155,
        "NP_inspectCodeType": 156,
        "NP_inspectState": 157,
        "NP_interactBounds": 158,
        "NP_inverseZoomDirection": 159,
        "NP_isAIRecognizeDocument": 160,
        "NP_isCommonAsset": 161,
        "NP_isDocImportedFromAI": 162,
        "NP_isDocImportedFromFigma": 163,
        "NP_isDocImportedFromSketch": 164,
        "NP_isFromClipboard": 165,
        "NP_isImageAsset": 166,
        "NP_isViewportAnimating": 167,
        "NP_isViewportZooming": 168,
        "NP_key": 169,
        "NP_lastCreatedNodeId": 170,
        "NP_lastEditedStrokeCap": 171,
        "NP_lastMouseEventPositionForDevMode": 172,
        "NP_lastestComponentPropIncreaseId": 173,
        "NP_layerPanelScrollTop": 174,
        "NP_layoutGrids": 175,
        "NP_letterSpacing": 176,
        "NP_libraryAssetPanelStateChangeId": 177,
        "NP_libraryContentVOChangeId": 178,
        "NP_libraryMainModalRoutePath": 179,
        "NP_libraryMoveHistory": 180,
        "NP_libraryMoveInfo": 181,
        "NP_libraryPublishModalStateChangeId": 182,
        "NP_libraryReplaceModalStateChangeId": 183,
        "NP_libraryUpgradeStateChangeId": 184,
        "NP_libraryUpgradedCount": 185,
        "NP_lineHeight": 186,
        "NP_localStyleEditingState": 187,
        "NP_localVariableEditorEditingState": 188,
        "NP_localVariableEditorRenamingState": 189,
        "NP_localVariableEditorSelectedCollectionId": 190,
        "NP_localVariableEditorSelectedGroupKeys": 191,
        "NP_localVariableEditorSelectedVariableIds": 192,
        "NP_locked": 193,
        "NP_mainComponentId": 194,
        "NP_mainMenuVisible": 195,
        "NP_mask": 196,
        "NP_maskOutline": 197,
        "NP_maxLines": 198,
        "NP_maxSize": 199,
        "NP_memoryUsage": 200,
        "NP_minSize": 201,
        "NP_mirrorFrameViewState": 202,
        "NP_mirrorNodeId": 203,
        "NP_mirrorScreenScrollY": 204,
        "NP_mirrorViewportScaling": 205,
        "NP_motiffScopeExpandationData": 206,
        "NP_motiffScopeSettings": 207,
        "NP_mouseCameraPosition": 208,
        "NP_mouseDown": 209,
        "NP_mouseDownCameraPosition": 210,
        "NP_mouseDownWorldPosition": 211,
        "NP_mouseInDragging": 212,
        "NP_mouseRightClickWorldPosition": 213,
        "NP_mouseWorldPosition": 214,
        "NP_movingComment": 215,
        "NP_multiHoverBorderColor": 216,
        "NP_multipleRenameParams": 217,
        "NP_name": 218,
        "NP_needLayoutOnce": 219,
        "NP_nodePreviewTrack": 220,
        "NP_nodesWithPrototypeInteractions": 221,
        "NP_nonePersistentSelectedTextRange": 222,
        "NP_notificationMovedComponentStyleNodeIds": 223,
        "NP_numberOfFixedChildren": 224,
        "NP_opacity": 225,
        "NP_openingDetailLibraryIdInfo": 226,
        "NP_operateSystem": 227,
        "NP_overlayBackgroundAppearance": 228,
        "NP_overlayBackgroundInteraction": 229,
        "NP_overlayPositionType": 230,
        "NP_overrideId": 231,
        "NP_ownerPage": 232,
        "NP_paints": 233,
        "NP_paragraphIndent": 234,
        "NP_paragraphSpacing": 235,
        "NP_parent": 236,
        "NP_parentInfo": 237,
        "NP_paymentUserPlanStatus": 238,
        "NP_pendingMirrorState": 239,
        "NP_placingImageState": 240,
        "NP_pluginData": 241,
        "NP_pointCount": 242,
        "NP_popupState": 243,
        "NP_previewDocumentLoadedState": 244,
        "NP_previewDocumentNodeState": 245,
        "NP_propsAreBubbled": 246,
        "NP_prototypeAnimation": 247,
        "NP_prototypeAnimationValue": 248,
        "NP_prototypeBackgroundColor": 249,
        "NP_prototypeDevice": 250,
        "NP_prototypeInteractionAddDragingState": 251,
        "NP_prototypeInteractionShadowRecorder": 252,
        "NP_prototypeInteractions": 253,
        "NP_prototypeOverlaySettingDragingState": 254,
        "NP_prototypePresentDeviceFrameRegion": 255,
        "NP_prototypePresentDisableDefaultKeyboardNav": 256,
        "NP_prototypePresentHideUI": 257,
        "NP_prototypePresentHotspotHintAnimateState": 258,
        "NP_prototypePresentScaling": 259,
        "NP_prototypePresentShowDevice": 260,
        "NP_prototypePresentShowHotspotHint": 261,
        "NP_prototypePresentShowProtoSidebar": 262,
        "NP_prototypeStartingPoint": 263,
        "NP_prototypeStartingPointDragingState": 264,
        "NP_prototypeStartingPointEditingState": 265,
        "NP_prototypingVisibility": 266,
        "NP_publishFile": 267,
        "NP_publishHidden": 268,
        "NP_publishId": 269,
        "NP_quickSwitchToHandTool": 270,
        "NP_quickSwitchToZoomTool": 271,
        "NP_rawComputedStyledTextSegments": 272,
        "NP_rawLibraryThumbnailTargetNodeId": 273,
        "NP_rawVectorUseSkeletonAsOutline": 274,
        "NP_readOnlySelectedTextRange": 275,
        "NP_relativeTransform": 276,
        "NP_remoteLibraryChangesChangeId": 277,
        "NP_replaceTargetLibraryIdInfo": 278,
        "NP_rightClickMenuState": 279,
        "NP_rightClickSource": 280,
        "NP_rightClickState": 281,
        "NP_rotationInDragging": 282,
        "NP_scaleFactor": 283,
        "NP_scaleToolPanel": 284,
        "NP_scrollBehavior": 285,
        "NP_scrollDirection": 286,
        "NP_scrollOffset": 287,
        "NP_selectLayoutGridState": 288,
        "NP_selectedGradientColorStopIndex": 289,
        "NP_selectedGuideIndex": 290,
        "NP_selectedGuideInfo": 291,
        "NP_selectedInteractionKeys": 292,
        "NP_selectedReverseIndex": 293,
        "NP_selectedTextRange": 294,
        "NP_selection": 295,
        "NP_selectionPaint": 296,
        "NP_selectionRect": 297,
        "NP_shouldActiveVariantPropsInput": 298,
        "NP_showComment": 299,
        "NP_showHoverPopupHyperlink": 300,
        "NP_showLocalVariableEditor": 301,
        "NP_showMultiplayerCursor": 302,
        "NP_showMultipleRenameModal": 303,
        "NP_showRuler": 304,
        "NP_showShortcutPanel": 305,
        "NP_showSidebar": 306,
        "NP_showSlice": 307,
        "NP_sidebarPanels": 308,
        "NP_simplifiedInstanceIdsSeeingAllLayers": 309,
        "NP_simplifyInstancePanels": 310,
        "NP_simplifyInstanceRightPanelManualModify": 311,
        "NP_sizeHoverMenuItem": 312,
        "NP_sizeOverride": 313,
        "NP_smallNudgeAmount": 314,
        "NP_snapToPixel": 315,
        "NP_stackChildAlignSelf": 316,
        "NP_stackChildPrimaryGrow": 317,
        "NP_stackCounterAlignContent": 318,
        "NP_stackCounterAlignItems": 319,
        "NP_stackCounterSizing": 320,
        "NP_stackCounterSpacing": 321,
        "NP_stackHorizontalPadding": 322,
        "NP_stackMode": 323,
        "NP_stackPaddingBottom": 324,
        "NP_stackPaddingRight": 325,
        "NP_stackPositioning": 326,
        "NP_stackPrimaryAlignItems": 327,
        "NP_stackPrimarySizing": 328,
        "NP_stackReverseZIndex": 329,
        "NP_stackSpacing": 330,
        "NP_stackVerticalPadding": 331,
        "NP_stackWrap": 332,
        "NP_startCap": 333,
        "NP_startingAngle": 334,
        "NP_strokeAlign": 335,
        "NP_strokeGeometry": 336,
        "NP_strokeJoin": 337,
        "NP_strokeMiterAngle": 338,
        "NP_strokeStyleId": 339,
        "NP_strokeWeight": 340,
        "NP_strokes": 341,
        "NP_styleId": 342,
        "NP_styleOverrideTable": 343,
        "NP_subEditorMode": 344,
        "NP_subscribedLibraryIds": 345,
        "NP_symbolData": 346,
        "NP_syncProgress": 347,
        "NP_syncStatus": 348,
        "NP_targetFrameId": 349,
        "NP_testNotStyleSquares": 350,
        "NP_textAlignHorizontal": 351,
        "NP_textAlignVertical": 352,
        "NP_textAutoResize": 353,
        "NP_textCase": 354,
        "NP_textData": 355,
        "NP_textDecoration": 356,
        "NP_textLayoutData": 357,
        "NP_textLayoutsStable": 358,
        "NP_textSearch": 359,
        "NP_textSettingTabKey": 360,
        "NP_textStyleId": 361,
        "NP_textTruncation": 362,
        "NP_thumbnailId": 363,
        "NP_titleInputHandle": 364,
        "NP_topExtendBoolMenuVisible": 365,
        "NP_topLeftRadius": 366,
        "NP_topRightRadius": 367,
        "NP_treeInteractBoundsInParent": 368,
        "NP_type": 369,
        "NP_variableCodeSyntax": 370,
        "NP_variableConsumptionMap": 371,
        "NP_variableDataValues": 372,
        "NP_variableModeBySetMap": 373,
        "NP_variableModeInLogicMap": 374,
        "NP_variableResolvedType": 375,
        "NP_variableScopes": 376,
        "NP_variableSetCollapsedGroupKeys": 377,
        "NP_variableSetId": 378,
        "NP_variableSetModes": 379,
        "NP_variantPropKey2OrderedValuesMap": 380,
        "NP_variantProperties": 381,
        "NP_vectorData": 382,
        "NP_viewport": 383,
        "NP_visible": 384,
        "NP_width": 385,
        "NP_windowInnerHeight": 386,
        "NP_zoomToolDraggingState": 387
      }
    },
    "NodeType": {
      "values": {
        "NODE_TYPE_VECTOR": 0,
        "NODE_TYPE_DOCUMENT": 1,
        "NODE_TYPE_RECTANGLE": 2,
        "NODE_TYPE_STAR": 3,
        "NODE_TYPE_POLYGON": 4,
        "NODE_TYPE_LINE": 5,
        "NODE_TYPE_ELLIPSE": 6,
        "NODE_TYPE_PAGE": 7,
        "NODE_TYPE_FRAME": 8,
        "NODE_TYPE_GROUP": 9,
        "NODE_TYPE_BOOL_OPERATION": 10,
        "NODE_TYPE_TEXT": 11,
        "NODE_TYPE_COMPONENT_SET": 12,
        "NODE_TYPE_COMPONENT": 13,
        "NODE_TYPE_INSTANCE": 14,
        "NODE_TYPE_PAINT_STYLE": 15,
        "NODE_TYPE_TEXT_STYLE": 16,
        "NODE_TYPE_EFFECT_STYLE": 17,
        "NODE_TYPE_STYLE_CONTAINER": 18,
        "NODE_TYPE_COMMENT": 20,
        "NODE_TYPE_SLICE": 22,
        "NODE_TYPE_LAYOUT_GRID_STYLE": 23,
        "NODE_TYPE_VARIABLE": 24,
        "NODE_TYPE_VARIABLE_SET": 25,
        "NODE_TYPE_INVALID": 99
      }
    },
    "NotificationMoveNodeType": {
      "values": {
        "NOTIFICATION_MOVE_NODE_TYPE_NONE": 0,
        "NOTIFICATION_MOVE_NODE_TYPE_STYLE": 1,
        "NOTIFICATION_MOVE_NODE_TYPE_COMPONENT": 2
      }
    },
    "NumberUnit": {
      "values": {
        "NUMBER_UNIT_PIXELS": 0,
        "NUMBER_UNIT_PERCENT": 1,
        "NUMBER_UNIT_AUTO": 2
      }
    },
    "OperateSystemType": {
      "values": {
        "OPERATE_SYSTEM_TYPE_NONE": 0,
        "OPERATE_SYSTEM_TYPE_MAC": 1,
        "OPERATE_SYSTEM_TYPE_IOS": 2,
        "OPERATE_SYSTEM_TYPE_WINDOWS": 3,
        "OPERATE_SYSTEM_TYPE_ANDROID": 4,
        "OPERATE_SYSTEM_TYPE_LINUX": 5
      }
    },
    "OverlayBackgroundInteraction": {
      "values": {
        "OVERLAY_BACKGROUND_INTERACTION_NONE": 0,
        "OVERLAY_BACKGROUND_INTERACTION_CLOSE_ON_CLICK_OUTSIDE": 1
      }
    },
    "OverlayBackgroundType": {
      "values": {
        "OVERLAY_BACKGROUND_TYPE_NONE": 0,
        "OVERLAY_BACKGROUND_TYPE_SOLID_COLOR": 1
      }
    },
    "OverlayPositionType": {
      "values": {
        "OVERLAY_POSITION_TYPE_CENTER": 0,
        "OVERLAY_POSITION_TYPE_TOP_LEFT": 1,
        "OVERLAY_POSITION_TYPE_TOP_CENTER": 2,
        "OVERLAY_POSITION_TYPE_TOP_RIGHT": 3,
        "OVERLAY_POSITION_TYPE_BOTTOM_LEFT": 4,
        "OVERLAY_POSITION_TYPE_BOTTOM_CENTER": 5,
        "OVERLAY_POSITION_TYPE_BOTTOM_RIGHT": 6,
        "OVERLAY_POSITION_TYPE_MANUAL": 7
      }
    },
    "PackType": {
      "values": {
        "PACK_TYPE_HORIZONTAL": 0,
        "PACK_TYPE_VERTICAL": 1
      }
    },
    "PaintPositionType": {
      "values": {
        "PAINT_POSITION_TYPE_FILL": 0,
        "PAINT_POSITION_TYPE_STROKE": 1,
        "PAINT_POSITION_TYPE_STYLE": 2
      }
    },
    "PaintStyleMatchType": {
      "values": {
        "PAINT_STYLE_MATCH_TYPE_S_T_R_O_K_E": 0,
        "PAINT_STYLE_MATCH_TYPE_F_I_L_L": 1
      }
    },
    "PaintStyleType": {
      "values": {
        "PAINT_STYLE_TYPE_FILL": 0,
        "PAINT_STYLE_TYPE_STROKE": 1
      }
    },
    "PaintType": {
      "values": {
        "PAINT_TYPE_SOLID_PAINT": 0,
        "PAINT_TYPE_GRADIENT_LINEAR": 1,
        "PAINT_TYPE_GRADIENT_RADIAL": 2,
        "PAINT_TYPE_GRADIENT_ANGULAR": 3,
        "PAINT_TYPE_GRADIENT_DIAMOND": 4,
        "PAINT_TYPE_IMAGE_PAINT": 5,
        "PAINT_TYPE_EMOJI": 6
      }
    },
    "PaintUsingType": {
      "values": {
        "PAINT_USING_TYPE_NONE": 0,
        "PAINT_USING_TYPE_FILL": 1,
        "PAINT_USING_TYPE_STROKE": 2
      }
    },
    "PluginApiImportLibNodeType": {
      "values": {
        "PLUGIN_API_IMPORT_LIB_NODE_TYPE_COMPONENT": 0,
        "PLUGIN_API_IMPORT_LIB_NODE_TYPE_COMPONENT_SET": 1,
        "PLUGIN_API_IMPORT_LIB_NODE_TYPE_STYLE": 2
      }
    },
    "PluginDocumentChangeOrigin": {
      "values": {
        "PLUGIN_DOCUMENT_CHANGE_ORIGIN_LOCAL": 0,
        "PLUGIN_DOCUMENT_CHANGE_ORIGIN_REMOTE": 1
      }
    },
    "PluginDocumentChangeProperty": {
      "values": {
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_NAME": 0,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_WIDTH": 1,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_HEIGHT": 2,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_BACKGROUNDS": 3,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_RELATIVE_TRANSFORM": 4,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_POINT_COUNT": 5,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_MIN_WIDTH": 6,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_MAX_WIDTH": 7,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_MIN_HEIGHT": 8,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_MAX_HEIGHT": 9,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_PARENT": 10,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_PLUGIN_DATA": 11,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_CONSTRAINTS": 12,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_LOCKED": 13,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_VISIBLE": 14,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_OPACITY": 15,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_BLEND_MODE": 16,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_LAYOUT_GRIDS": 17,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_GUIDES": 18,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_CHARACTERS": 19,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_STYLED_TEXT_SEGMENTS": 20,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_EFFECTS": 21,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_EXPORT_SETTINGS": 22,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_ARC_DATA": 23,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_AUTO_RENAME": 24,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_FONT_NAME": 25,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_INNER_RADIUS": 26,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_FONT_SIZE": 27,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_LINE_HEIGHT": 28,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_PARAGRAPH_INDENT": 29,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_PARAGRAPH_SPACING": 30,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_LETTER_SPACING": 31,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_TEXT_ALIGN_HORIZONTAL": 32,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_TEXT_ALIGN_VERTICAL": 33,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_TEXT_CASE": 34,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_TEXT_DECORATION": 35,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_TEXT_AUTO_RESIZE": 36,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_TEXT_TRUNCATION": 37,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_MAX_LINES": 38,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_FILLS": 39,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_TOP_LEFT_RADIUS": 40,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_TOP_RIGHT_RADIUS": 41,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_BOTTOM_LEFT_RADIUS": 42,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_BOTTOM_RIGHT_RADIUS": 43,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_CONSTRAIN_PROPORTIONS": 44,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_STROKES": 45,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_STROKE_WEIGHT": 46,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_STROKE_ALIGN": 47,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_STROKE_CAP": 48,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_STROKE_JOIN": 49,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_STROKE_MITER_LIMIT": 50,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_BOOLEAN_OPERATION": 51,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_DASH_PATTERN": 52,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_HANDLE_MIRRORING": 53,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_CORNER_RADIUS": 54,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_CORNER_SMOOTHING": 55,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_X": 56,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_Y": 57,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_CLIPS_CONTENT": 58,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_TYPE": 59,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_OVERLAY_POSITION_TYPE": 60,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_OVERLAY_BACKGROUND_INTERACTION": 61,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_OVERLAY_BACKGROUND": 62,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_EXPANDED": 63,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_FILL_STYLE_ID": 64,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_STROKE_STYLE_ID": 65,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_TEXT_STYLE_ID": 66,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_EFFECT_STYLE_ID": 67,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_GRID_STYLE_ID": 68,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_DESCRIPTION": 69,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_PADDING_LEFT": 70,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_PADDING_TOP": 71,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_PADDING_RIGHT": 72,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_PADDING_BOTTOM": 73,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_ITEM_SPACING": 74,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_COUNTER_AXIS_SPACING": 75,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_LAYOUT_ALIGN": 76,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_COUNTER_AXIS_SIZING_MODE": 77,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_PRIMARY_AXIS_SIZING_MODE": 78,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_PRIMARY_AXIS_ALIGN_ITEMS": 79,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_COUNTER_AXIS_ALIGN_ITEMS": 80,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_COUNTER_AXIS_ALIGN_CONTENT": 81,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_LAYOUT_GROW": 82,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_ITEM_REVERSE_Z_INDEX": 83,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_HYPERLINK": 84,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_STROKE_TOP_WEIGHT": 85,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_STROKE_BOTTOM_WEIGHT": 86,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_STROKE_LEFT_WEIGHT": 87,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_STROKE_RIGHT_WEIGHT": 88,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_REACTIONS": 89,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_WIDGET_SYNCED_STATE": 90,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_COMPONENT_PROPERTY_DEFINITIONS": 91,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_COMPONENT_PROPERTY_REFERENCES": 92,
        "PLUGIN_DOCUMENT_CHANGE_PROPERTY_COMPONENT_PROPERTIES": 93
      }
    },
    "PluginDocumentChangeType": {
      "values": {
        "PLUGIN_DOCUMENT_CHANGE_TYPE_NONE": 0,
        "PLUGIN_DOCUMENT_CHANGE_TYPE_CREATE_CHANGE": 1,
        "PLUGIN_DOCUMENT_CHANGE_TYPE_DELETE_CHANGE": 2,
        "PLUGIN_DOCUMENT_CHANGE_TYPE_PROPERTY_CHANGE": 3,
        "PLUGIN_DOCUMENT_CHANGE_TYPE_STYLE_CREATE_CHANGE": 4,
        "PLUGIN_DOCUMENT_CHANGE_TYPE_STYLE_DELETE_CHANGE": 5,
        "PLUGIN_DOCUMENT_CHANGE_TYPE_STYLE_PROPERTY_CHANGE": 6
      }
    },
    "PluginEventNotificationStatus": {
      "values": {
        "PLUGIN_EVENT_NOTIFICATION_STATUS_NONE": 0,
        "PLUGIN_EVENT_NOTIFICATION_STATUS_EXECUTING_CALLBACK": 1
      }
    },
    "PluginEventType": {
      "values": {
        "PLUGIN_EVENT_TYPE_CURRENT_PAGE_CHANGE": 0,
        "PLUGIN_EVENT_TYPE_SELECTION_CHANGE": 1,
        "PLUGIN_EVENT_TYPE_DOCUMENT_CHANGE": 2,
        "PLUGIN_EVENT_TYPE_TEXT_REVIEW": 3,
        "PLUGIN_EVENT_TYPE_DROP": 4,
        "PLUGIN_EVENT_TYPE_CLOSE": 5,
        "PLUGIN_EVENT_TYPE_RUN": 6,
        "PLUGIN_EVENT_TYPE_STYLE_CHANGE": 7,
        "PLUGIN_EVENT_TYPE_NODE_CHANGE": 8,
        "PLUGIN_EVENT_TYPE_TIMER_START": 9,
        "PLUGIN_EVENT_TYPE_TIMER_PAUSE": 10,
        "PLUGIN_EVENT_TYPE_TIMER_STOP": 11,
        "PLUGIN_EVENT_TYPE_TIMER_DONE": 12,
        "PLUGIN_EVENT_TYPE_TIMER_RESUME": 13,
        "PLUGIN_EVENT_TYPE_TIMER_ADJUST": 14
      }
    },
    "PluginType": {
      "values": {
        "PLUGIN_TYPE_SKEW_TOOL": 0,
        "PLUGIN_TYPE_ARC_TEXT": 1,
        "PLUGIN_TYPE_LOOPER": 2,
        "PLUGIN_TYPE_ROTATE_COPY": 3,
        "PLUGIN_TYPE_A_E": 4,
        "PLUGIN_TYPE_FILL_RULE_EDITOR": 5,
        "PLUGIN_TYPE_UNSPLASH": 6,
        "PLUGIN_TYPE_ICONPARK": 7,
        "PLUGIN_TYPE_ADJUST_MOUSE_SCALE_SPEED": 8,
        "PLUGIN_TYPE_GENERATIVE_DESIGN": 9,
        "PLUGIN_TYPE_WHEEL_EVENT_DEBUG": 10,
        "PLUGIN_TYPE_A_I": 11
      }
    },
    "PopupStateType": {
      "values": {
        "POPUP_STATE_TYPE_NONE": 0,
        "POPUP_STATE_TYPE_FILLS": 1,
        "POPUP_STATE_TYPE_STROKES": 2,
        "POPUP_STATE_TYPE_TEXT": 3,
        "POPUP_STATE_TYPE_STROKES_ADVANCE": 4,
        "POPUP_STATE_TYPE_SELECT_COLOR": 5,
        "POPUP_STATE_TYPE_EFFECTS": 6,
        "POPUP_STATE_TYPE_PAINT_STYLE": 7,
        "POPUP_STATE_TYPE_PAINT_STYLE_EDITOR": 8,
        "POPUP_STATE_TYPE_PAINT_STYLE_EDITOR_PAINT": 9,
        "POPUP_STATE_TYPE_STYLE_MANAGE": 10,
        "POPUP_STATE_TYPE_TEXT_STYLE": 11,
        "POPUP_STATE_TYPE_TEXT_STYLE_EDITOR": 12,
        "POPUP_STATE_TYPE_EFFECT_STYLE_EDIT": 13,
        "POPUP_STATE_TYPE_EFFECT_STYLE_SELECT": 14,
        "POPUP_STATE_TYPE_EFFECT_STYLE_EDIT_INSIDE_SELECT": 15,
        "POPUP_STATE_TYPE_AUTO_LAYOUT": 16,
        "POPUP_STATE_TYPE_CURRENT_PAGE_BACKGROUND_EDIT": 17,
        "POPUP_STATE_TYPE_REPLACE_INSTANCE": 18,
        "POPUP_STATE_TYPE_CORNER_SMOOTHING": 19,
        "POPUP_STATE_TYPE_STYLE_VIEWER": 20,
        "POPUP_STATE_TYPE_BATCH_EXPORT": 21,
        "POPUP_STATE_TYPE_LAYOUT_GRID": 22,
        "POPUP_STATE_TYPE_LAYOUT_GRID_STYLE": 23,
        "POPUP_STATE_TYPE_LAYOUT_GRID_STYLE_EDITOR": 24,
        "POPUP_STATE_TYPE_LAYOUT_GRID_STYLE_SELECT": 25,
        "POPUP_STATE_TYPE_CREATE_TEXT_STYLE": 26,
        "POPUP_STATE_TYPE_CREATE_PAINT_STYLE": 27,
        "POPUP_STATE_TYPE_CREATE_EFFECT_STYLE": 28,
        "POPUP_STATE_TYPE_CREATE_LAYOUT_GRID_STYLE": 29,
        "POPUP_STATE_TYPE_PLUGIN_ICONPARK_STYLE": 30,
        "POPUP_STATE_TYPE_DEV_MODE_CODE_CONFIG": 31,
        "POPUP_STATE_TYPE_CANDIDATE_COMPONENT_REPLACE": 32,
        "POPUP_STATE_TYPE_DEV_MODE_CODE_CONFIG_MAIN_MENU": 33,
        "POPUP_STATE_TYPE_CURRENT_PAGE_PROTOTYPE_BACKGROUND_EDIT": 34,
        "POPUP_STATE_TYPE_PROTOTYPE_INTERACTION": 35,
        "POPUP_STATE_TYPE_PROTOTYPE_STARTING_POINT_DESC": 36,
        "POPUP_STATE_TYPE_CANVAS_PROTOTYPE_INTERACTION": 37,
        "POPUP_STATE_TYPE_CREATE_COMPONENT_BOOL_PROP_OF_PROP_AREA": 38,
        "POPUP_STATE_TYPE_CREATE_COMPONENT_BOOL_PROP_OF_LAYER_AREA": 39,
        "POPUP_STATE_TYPE_EDIT_COMPONENT_BOOL_PROP": 40,
        "POPUP_STATE_TYPE_COMPONENT_CONFIG": 41,
        "POPUP_STATE_TYPE_CREATE_COMPONENT_TEXT_PROP_OF_PROP_AREA": 42,
        "POPUP_STATE_TYPE_CREATE_COMPONENT_TEXT_PROP_OF_TEXT_AREA": 43,
        "POPUP_STATE_TYPE_A_I_DESIGN_LINT_TEXT_STYLE": 44,
        "POPUP_STATE_TYPE_A_I_DESIGN_LINT_PAINT_STYLE": 45,
        "POPUP_STATE_TYPE_A_I_GEN_U_I": 46,
        "POPUP_STATE_TYPE_EDIT_COMPONENT_TEXT_PROP": 47,
        "POPUP_STATE_TYPE_CREATE_COMPONENT_VARIANT_PROP_OF_PROP_AREA": 48,
        "POPUP_STATE_TYPE_CREATE_COMPONENT_INSTANCE_SWAP_PROP_OF_PROP_AREA": 49,
        "POPUP_STATE_TYPE_EDIT_COMPONENT_VARIANT_PROP": 50,
        "POPUP_STATE_TYPE_EDIT_COMPONENT_INSTANCE_SWAP_PROP": 51,
        "POPUP_STATE_TYPE_CREATE_COMPONENT_INSTANCE_SWAP_PROP_OF_INSTANCE_AREA": 52,
        "POPUP_STATE_TYPE_INSTANCE_SWAP_ASSIGNMENT_COMPONENT_PICKER": 53,
        "POPUP_STATE_TYPE_CREATE_COMPONENT_PUBLIC_PROP_OF_PROP_AREA": 54
      }
    },
    "PosType": {
      "values": {
        "POS_TYPE_BEFORE_CHAR": 0,
        "POS_TYPE_AFTER_CHAR": 1
      }
    },
    "PresetCodeType": {
      "values": {
        "PRESET_CODE_TYPE_WEB": 0,
        "PRESET_CODE_TYPE_I_O_S": 1,
        "PRESET_CODE_TYPE_ANDROID": 2
      }
    },
    "PreviewScene": {
      "values": {
        "PREVIEW_SCENE_P_R_O_T_O_T_Y_P_E": 0,
        "PREVIEW_SCENE_M_I_R_R_O_R": 1,
        "VIEW_WITH_MOBILE": 2
      }
    },
    "PrimitiveVariableType": {
      "values": {
        "PRIMITIVE_VARIABLE_TYPE_BOOLEAN": 0,
        "PRIMITIVE_VARIABLE_TYPE_FLOAT": 1,
        "PRIMITIVE_VARIABLE_TYPE_STRING": 2
      }
    },
    "ProtoEmptyPlaceholder": {
      "values": {
        "PROTO_EMPTY_PLACEHOLDER_DO_NOT_USE_ME_ZERO": 0,
        "PROTO_EMPTY_PLACEHOLDER_DEFAULT": 1
      }
    },
    "ProtoValueType": {
      "values": {
        "PROTO_VALUE_TYPE_DO_NOT_USE_ME_ZERO": 0,
        "PROTO_VALUE_TYPE_NULLPTR": 1,
        "PROTO_VALUE_TYPE_HAS_VALUE": 2
      }
    },
    "PrototypeAnimationType": {
      "values": {
        "PROTOTYPE_ANIMATION_TYPE_NONE": 0,
        "PROTOTYPE_ANIMATION_TYPE_BACK": 1,
        "PROTOTYPE_ANIMATION_TYPE_NAVIGATION_TO": 2,
        "PROTOTYPE_ANIMATION_TYPE_OPEN_OVERLAY": 3,
        "PROTOTYPE_ANIMATION_TYPE_CLOSE_OVERLAY": 4,
        "PROTOTYPE_ANIMATION_TYPE_SWAP_OVERLAY": 5,
        "PROTOTYPE_ANIMATION_TYPE_SCROLL_TO": 6,
        "PROTOTYPE_ANIMATION_TYPE_SWITCH_DEVICE_FRAME": 7
      }
    },
    "PrototypeDeviceType": {
      "values": {
        "PROTOTYPE_DEVICE_TYPE_NONE": 0,
        "PROTOTYPE_DEVICE_TYPE_PRESET": 1,
        "PROTOTYPE_DEVICE_TYPE_CUSTOM": 2,
        "PROTOTYPE_DEVICE_TYPE_PRESENTATION": 3
      }
    },
    "PrototypePresentScaling": {
      "values": {
        "MIN_ZOOM": 0,
        "SCALE_DOWN": 1,
        "SCALE_DOWN_WIDTH": 2,
        "FULL_CONTAIN": 3
      }
    },
    "PublishMixinInvalidChangeType": {
      "values": {
        "PUBLISH_MIXIN_INVALID_CHANGE_TYPE_VARIANT_PROPERTY_CONFLICT": 0,
        "PUBLISH_MIXIN_INVALID_CHANGE_TYPE_COMPONENT_PROPERTY_CONFLICT": 1,
        "PUBLISH_MIXIN_INVALID_CHANGE_TYPE_UNUSED_COMPONENT_PROPERTY": 2,
        "PUBLISH_MIXIN_INVALID_CHANGE_TYPE_TEXT_MISSING_FONT": 3
      }
    },
    "PublishStatus": {
      "values": {
        "PUBLISH_STATUS_UNPUBLISHED": 0,
        "PUBLISH_STATUS_CURRENT": 1,
        "PUBLISH_STATUS_CHANGED": 2
      }
    },
    "QueryCategory": {
      "values": {
        "QUERY_CATEGORY_NODE_PROP": 0,
        "QUERY_CATEGORY_VIEW_STATE": 1
      }
    },
    "RangeType": {
      "values": {
        "RANGE_TYPE_PRESENTER": 0,
        "RANGE_TYPE_HANDLER": 1,
        "RANGE_TYPE_COMMAND": 2,
        "RANGE_TYPE_STAGE": 3
      }
    },
    "RemoteLibraryUpgradeType": {
      "values": {
        "REMOTE_LIBRARY_UPGRADE_TYPE_NONE": 0,
        "REMOTE_LIBRARY_UPGRADE_TYPE_ONLY_STYLE": 1,
        "REMOTE_LIBRARY_UPGRADE_TYPE_ONLY_COMPONENT": 2,
        "REMOTE_LIBRARY_UPGRADE_TYPE_COMPONENT_AND_STYLE": 3,
        "REMOTE_LIBRARY_UPGRADE_TYPE_ONLY_VARIABLE": 4,
        "REMOTE_LIBRARY_UPGRADE_TYPE_VARIABLE_AND_STYLE": 5,
        "REMOTE_LIBRARY_UPGRADE_TYPE_VARIABLE_AND_COMPONENT": 6,
        "REMOTE_LIBRARY_UPGRADE_TYPE_VARIABLE_AND_COMPONENT_AND_STYLE": 7
      }
    },
    "RenderCursorTarget": {
      "values": {
        "RENDER_CURSOR_TARGET_CANVAS": 0,
        "RENDER_CURSOR_TARGET_CUSTOM": 1
      }
    },
    "RenderType": {
      "values": {
        "RENDER_TYPE_NORMAL": 0,
        "RENDER_TYPE_PROTOTYPE": 1
      }
    },
    "ReplaceLibraryItemType": {
      "values": {
        "REPLACE_LIBRARY_ITEM_TYPE_NONE": 0,
        "REPLACE_LIBRARY_ITEM_TYPE_STYLE": 1,
        "REPLACE_LIBRARY_ITEM_TYPE_COMPONENT": 2,
        "REPLACE_LIBRARY_ITEM_TYPE_COMPONENT_SET": 3
      }
    },
    "RetCode_SetAliasForVariableCommand": {
      "values": {
        "RET_CODE__SET_ALIAS_FOR_VARIABLE_COMMAND_SUCCEED": 0,
        "RET_CODE__SET_ALIAS_FOR_VARIABLE_COMMAND_VARIABLE_NOT_EXIST": 1,
        "RET_CODE__SET_ALIAS_FOR_VARIABLE_COMMAND_VARIABLE_ALIAS_NOT_EXIST": 2,
        "RET_CODE__SET_ALIAS_FOR_VARIABLE_COMMAND_VARIABLE_ALIAS_DATA_TYPE_NOT_MATCH": 3,
        "RET_CODE__SET_ALIAS_FOR_VARIABLE_COMMAND_CANNOT_SET_ALIAS_FOR_SELF": 4,
        "RET_CODE__SET_ALIAS_FOR_VARIABLE_COMMAND_CANNOT_SET_ALIAS_DUE_TO_CIRCULAR_DEPENDENCIES": 5
      }
    },
    "RightClickSetThumbnailStatus": {
      "values": {
        "RIGHT_CLICK_SET_THUMBNAIL_STATUS_HIDDEN": 0,
        "RIGHT_CLICK_SET_THUMBNAIL_STATUS_SET_AS": 1,
        "RIGHT_CLICK_SET_THUMBNAIL_STATUS_SET_AS_DEFAULT": 2
      }
    },
    "RightClickSource": {
      "values": {
        "RIGHT_CLICK_SOURCE_NONE": 0,
        "RIGHT_CLICK_SOURCE_CANVAS": 1,
        "RIGHT_CLICK_SOURCE_CANVAS_WITH_CTR_OR_CMD": 2,
        "RIGHT_CLICK_SOURCE_TOP_BAR": 3,
        "RIGHT_CLICK_SOURCE_DESIGN_MODE_PAGE_PANEL": 4,
        "RIGHT_CLICK_SOURCE_DESIGN_MODE_LAYER_PANEL": 5,
        "RIGHT_CLICK_SOURCE_DEV_MODE_PAGE_PANEL": 6,
        "RIGHT_CLICK_SOURCE_DEV_MODE_LAYER_PANEL": 7,
        "RIGHT_CLICK_SOURCE_DESIGN_PANEL": 8,
        "RIGHT_CLICK_SOURCE_BUBBLE": 9,
        "RIGHT_CLICK_SOURCE_A_I_COMPONENT_PUBLISH_PANEL": 10,
        "RIGHT_CLICK_SOURCE_MOTIFF_SCOPE": 11
      }
    },
    "RotateCopyEndState": {
      "values": {
        "ROTATE_COPY_END_STATE_FINISH": 0,
        "ROTATE_COPY_END_STATE_CANCEL": 1
      }
    },
    "RowsColsLayoutGridAlign": {
      "values": {
        "ROWS_COLS_LAYOUT_GRID_ALIGN_MIN": 0,
        "ROWS_COLS_LAYOUT_GRID_ALIGN_MAX": 1,
        "ROWS_COLS_LAYOUT_GRID_ALIGN_CENTER": 2,
        "ROWS_COLS_LAYOUT_GRID_ALIGN_STRETCH": 3
      }
    },
    "ScaleItem": {
      "values": {
        "SCALE_ITEMX4": 0,
        "SCALE_ITEMX3": 1,
        "SCALE_ITEMX2": 2,
        "x1_5": 3,
        "SCALE_ITEMX1": 4
      }
    },
    "ScaleMode": {
      "values": {
        "SCALE_MODE_FILL": 0,
        "SCALE_MODE_FIT": 1,
        "SCALE_MODE_CROP": 2,
        "SCALE_MODE_TILE": 3
      }
    },
    "ScrollBehavior": {
      "values": {
        "SCROLL_BEHAVIOR_SCROLLS": 0,
        "SCROLL_BEHAVIOR_FIXED_WHEN_CHILD_OF_SCROLLING_FRAME": 1,
        "SCROLL_BEHAVIOR_STICKY_SCROLLS": 2
      }
    },
    "ScrollDirection": {
      "values": {
        "SCROLL_DIRECTION_NONE": 0,
        "SCROLL_DIRECTION_HORIZONTAL": 1,
        "SCROLL_DIRECTION_VERTICAL": 2,
        "SCROLL_DIRECTION_BOTH": 3
      }
    },
    "SectionType": {
      "values": {
        "SECTION_TYPE_SCROLLS": 0,
        "SECTION_TYPE_FIXED": 1
      }
    },
    "SelectedReversedIndexesType": {
      "values": {
        "SELECTED_REVERSED_INDEXES_TYPE_FILL": 0,
        "SELECTED_REVERSED_INDEXES_TYPE_STROKE": 1,
        "SELECTED_REVERSED_INDEXES_TYPE_EFFECT": 2,
        "SELECTED_REVERSED_INDEXES_TYPE_LAYOUT_GRID": 3
      }
    },
    "SelectionFillType": {
      "values": {
        "SELECTION_FILL_TYPE_EMPTY": 0,
        "SELECTION_FILL_TYPE_NORMAL": 1,
        "SELECTION_FILL_TYPE_STYLE": 2,
        "SELECTION_FILL_TYPE_MIXED": 3
      }
    },
    "SelectionHyperlinkType": {
      "values": {
        "SELECTION_HYPERLINK_TYPE_DISABLE": 0,
        "SELECTION_HYPERLINK_TYPE_EMPTY_LINK": 1,
        "SELECTION_HYPERLINK_TYPE_SAME_LINK": 2,
        "SELECTION_HYPERLINK_TYPE_MIXED_LINK": 3
      }
    },
    "SelectionStrokeEndPointShowType": {
      "values": {
        "SELECTION_STROKE_END_POINT_SHOW_TYPE_NONE": 0,
        "SELECTION_STROKE_END_POINT_SHOW_TYPE_DISABLED": 1,
        "SELECTION_STROKE_END_POINT_SHOW_TYPE_SINGLE": 2,
        "SELECTION_STROKE_END_POINT_SHOW_TYPE_PAIR": 3
      }
    },
    "SelectionStrokeType": {
      "values": {
        "SELECTION_STROKE_TYPE_EMPTY": 0,
        "SELECTION_STROKE_TYPE_NORMAL": 1,
        "SELECTION_STROKE_TYPE_STYLE": 2,
        "SELECTION_STROKE_TYPE_MIXED": 3,
        "SELECTION_STROKE_TYPE_DISABLED": 4,
        "SELECTION_STROKE_TYPE_HIDDEN": 5
      }
    },
    "SelectionTextLineType": {
      "values": {
        "SELECTION_TEXT_LINE_TYPE_NONE": 0,
        "SELECTION_TEXT_LINE_TYPE_SAME": 1,
        "SELECTION_TEXT_LINE_TYPE_MIXED": 2
      }
    },
    "SelectionType": {
      "values": {
        "SELECTION_TYPE_NODE": 0,
        "SELECTION_TYPE_GUIDE": 1
      }
    },
    "SerTcFormat": {
      "values": {
        "SER_TC_FORMAT_NONE": 0,
        "ASTC_4x4": 1,
        "SER_TC_FORMAT_B_C7": 2
      }
    },
    "SidebarPanelType": {
      "values": {
        "SIDEBAR_PANEL_TYPE_LAYER": 0,
        "SIDEBAR_PANEL_TYPE_ASSETS": 1
      }
    },
    "SizeHoverMenuItem": {
      "values": {
        "SIZE_HOVER_MENU_ITEM_NONE": 0,
        "SIZE_HOVER_MENU_ITEM_WIDTH": 1,
        "SIZE_HOVER_MENU_ITEM_HEIGHT": 2
      }
    },
    "SizeOverride": {
      "values": {
        "SIZE_OVERRIDE_NONE": 0,
        "SIZE_OVERRIDE_FALSE": 1,
        "SIZE_OVERRIDE_TRUE": 2
      }
    },
    "StackAlign": {
      "values": {
        "STACK_ALIGN_STACK_ALIGN_MIN": 0,
        "STACK_ALIGN_STACK_ALIGN_CENTER": 1,
        "STACK_ALIGN_STACK_ALIGN_MAX": 2,
        "STACK_ALIGN_STACK_ALIGN_BASE_LINE": 3
      }
    },
    "StackCounterAlign": {
      "values": {
        "STACK_COUNTER_ALIGN_AUTO": 0,
        "STACK_COUNTER_ALIGN_STRETCH": 1,
        "STACK_COUNTER_ALIGN_MIN": 2,
        "STACK_COUNTER_ALIGN_CENTER": 3,
        "STACK_COUNTER_ALIGN_MAX": 4,
        "STACK_COUNTER_ALIGN_BASE_LINE": 5
      }
    },
    "StackCounterAlignContent": {
      "values": {
        "STACK_COUNTER_ALIGN_CONTENT_AUTO": 0,
        "STACK_COUNTER_ALIGN_CONTENT_SPACE_BETWEEN": 1
      }
    },
    "StackJustify": {
      "values": {
        "STACK_JUSTIFY_STACK_JUSTIFY_MIN": 0,
        "STACK_JUSTIFY_STACK_JUSTIFY_CENTER": 1,
        "STACK_JUSTIFY_STACK_JUSTIFY_MAX": 2,
        "STACK_JUSTIFY_STACK_JUSTIFY_SPACE_EVENTLY": 3
      }
    },
    "StackMode": {
      "values": {
        "STACK_MODE_NONE": 0,
        "STACK_MODE_HORIZONTAL": 1,
        "STACK_MODE_VERTICAL": 2
      }
    },
    "StackPositioning": {
      "values": {
        "STACK_POSITIONING_AUTO": 0,
        "STACK_POSITIONING_ABSOLUTE": 1
      }
    },
    "StackSize": {
      "values": {
        "STACK_SIZE_STACK_SIZE_RESIZE_TO_FIT_WITH_IMPLICIT_SIZE": 0,
        "STACK_SIZE_STACK_SIZE_RESIZE_TO_FIT": 1,
        "STACK_SIZE_STACK_SIZE_FIXED": 2
      }
    },
    "StackWrap": {
      "values": {
        "STACK_WRAP_NO_WRAP": 0,
        "STACK_WRAP_WRAP": 1
      }
    },
    "StrokeAlign": {
      "values": {
        "STROKE_ALIGN_CENTER": 0,
        "STROKE_ALIGN_INSIDE": 1,
        "STROKE_ALIGN_OUTSIDE": 2
      }
    },
    "StrokeCap": {
      "values": {
        "STROKE_CAP_NONE": 0,
        "STROKE_CAP_ROUND": 1,
        "STROKE_CAP_SQUARE": 2,
        "STROKE_CAP_ARROW_LINES": 3,
        "STROKE_CAP_ARROW_EQUILATERAL": 4,
        "STROKE_CAP_TRIANGLE_FILLED": 5,
        "STROKE_CAP_DIAMOND_FILLED": 6,
        "STROKE_CAP_CIRCLE_FILLED": 7
      }
    },
    "StrokeJoin": {
      "values": {
        "STROKE_JOIN_MITER": 0,
        "STROKE_JOIN_BEVEL": 1,
        "STROKE_JOIN_ROUND": 2
      }
    },
    "StrokeModeType": {
      "values": {
        "STROKE_MODE_TYPE_SOLID": 0,
        "STROKE_MODE_TYPE_DASH": 1
      }
    },
    "StyleSheetStatus": {
      "values": {
        "STYLE_SHEET_STATUS_UNKNOWN": 0,
        "STYLE_SHEET_STATUS_STYLE_SHEET_NOT_FOUND": 1,
        "STYLE_SHEET_STATUS_CSS_RULE_EXISTED_SKIP": 2,
        "STYLE_SHEET_STATUS_STYLE_SHEET_UPDATED": 3
      }
    },
    "SubEditorMode": {
      "values": {
        "SUB_EDITOR_MODE_NORMAL": 0,
        "SUB_EDITOR_MODE_HAND_TOOL": 1,
        "SUB_EDITOR_MODE_ZOOM_TOOL": 2
      }
    },
    "SupportedSchemaVersionRange": {
      "values": {
        "SUPPORTED_SCHEMA_VERSION_RANGE_DO_NOT_USE_ME_ZERO": 0,
        "SUPPORTED_SCHEMA_VERSION_RANGE_MIN": 12,
        "SUPPORTED_SCHEMA_VERSION_RANGE_MAX": 12
      }
    },
    "SwitchGroupFrameNameTo": {
      "values": {
        "SWITCH_GROUP_FRAME_NAME_TO_GROUP": 0,
        "SWITCH_GROUP_FRAME_NAME_TO_FRAME": 1
      }
    },
    "SyncStatus": {
      "values": {
        "SYNC_STATUS_UP_TO_DATE": 0,
        "SYNC_STATUS_OUT_OF_DATE": 1,
        "SYNC_STATUS_SYNCING": 2
      }
    },
    "SynergyBatchOperationSourceType": {
      "values": {
        "SYNERGY_BATCH_OPERATION_SOURCE_TYPE_NORMAL": 0,
        "SYNERGY_BATCH_OPERATION_SOURCE_TYPE_OFFLINE": 1
      }
    },
    "SynergyOperationType": {
      "values": {
        "SYNERGY_OPERATION_TYPE_CREATE": 0,
        "SYNERGY_OPERATION_TYPE_UPDATE": 1,
        "SYNERGY_OPERATION_TYPE_REMOVE": 2
      }
    },
    "SynergyState": {
      "values": {
        "SYNERGY_STATE_OFFLINE": 0,
        "SYNERGY_STATE_ONLINE": 1,
        "SYNERGY_STATE_SYNCING": 2
      }
    },
    "TestEnum": {
      "values": {
        "TEST_ENUMA": 0,
        "TEST_ENUMB": 2
      }
    },
    "TextAlignHorizontal": {
      "values": {
        "TEXT_ALIGN_HORIZONTAL_LEFT": 0,
        "TEXT_ALIGN_HORIZONTAL_CENTER": 1,
        "TEXT_ALIGN_HORIZONTAL_RIGHT": 2,
        "TEXT_ALIGN_HORIZONTAL_JUSTIFIED": 3
      }
    },
    "TextAlignVertical": {
      "values": {
        "TEXT_ALIGN_VERTICAL_TOP": 0,
        "TEXT_ALIGN_VERTICAL_CENTER": 1,
        "TEXT_ALIGN_VERTICAL_BOTTOM": 2
      }
    },
    "TextAutoResize": {
      "values": {
        "TEXT_AUTO_RESIZE_NONE": 0,
        "TEXT_AUTO_RESIZE_WIDTH_AND_HEIGHT": 1,
        "TEXT_AUTO_RESIZE_HEIGHT": 2
      }
    },
    "TextCase": {
      "values": {
        "TEXT_CASE_ORIGINAL": 0,
        "TEXT_CASE_UPPER": 1,
        "TEXT_CASE_LOWER": 2,
        "TEXT_CASE_TITLE": 3
      }
    },
    "TextDecoration": {
      "values": {
        "TEXT_DECORATION_NONE": 0,
        "TEXT_DECORATION_UNDERLINE": 1,
        "TEXT_DECORATION_STRIKETHROUGH": 2
      }
    },
    "TextLanguage": {
      "values": {
        "TEXT_LANGUAGE_EMOJI": 0,
        "TEXT_LANGUAGE_EN": 1,
        "TEXT_LANGUAGE_SYMBOLS": 2,
        "TEXT_LANGUAGE_SYMBOLS2": 3,
        "TEXT_LANGUAGE_MATH": 4,
        "TEXT_LANGUAGE_ZH_HANS": 5,
        "TEXT_LANGUAGE_ZH_HANT": 6,
        "TEXT_LANGUAGE_JAPANESE": 7,
        "TEXT_LANGUAGE_KOREAN": 8,
        "TEXT_LANGUAGE_HEBREW": 9,
        "TEXT_LANGUAGE_TAMIL": 10,
        "TEXT_LANGUAGE_THAI": 11,
        "TEXT_LANGUAGE_TAI_LE": 12,
        "TEXT_LANGUAGE_NEW_TAI_LUE": 13,
        "TEXT_LANGUAGE_TAI_THAM": 14,
        "TEXT_LANGUAGE_TAI_VIET": 15,
        "TEXT_LANGUAGE_LAO": 16,
        "TEXT_LANGUAGE_KHMER": 17,
        "TEXT_LANGUAGE_BENGALI": 18,
        "TEXT_LANGUAGE_GUJARATI": 19,
        "TEXT_LANGUAGE_TELUGU": 20,
        "TEXT_LANGUAGE_KANNADA": 21,
        "TEXT_LANGUAGE_MALAYALAM": 22,
        "TEXT_LANGUAGE_JAVANESE": 23,
        "TEXT_LANGUAGE_GEORGIAN": 24,
        "TEXT_LANGUAGE_DEVANAGARI": 25,
        "TEXT_LANGUAGE_ARABIC": 26
      }
    },
    "TextListOptionsType": {
      "values": {
        "TEXT_LIST_OPTIONS_TYPE_NONE": 0,
        "TEXT_LIST_OPTIONS_TYPE_ORDERED": 1,
        "TEXT_LIST_OPTIONS_TYPE_UNORDERED": 2
      }
    },
    "TextMatchType": {
      "values": {
        "TEXT_MATCH_TYPE_NAME": 0,
        "TEXT_MATCH_TYPE_TEXT": 1
      }
    },
    "TextOperationGranularity": {
      "values": {
        "TEXT_OPERATION_GRANULARITY_CHARACTER": 0,
        "TEXT_OPERATION_GRANULARITY_LINE": 1,
        "TEXT_OPERATION_GRANULARITY_WORD": 2
      }
    },
    "TextSettingTabKey": {
      "values": {
        "TEXT_SETTING_TAB_KEY_BASICS": 0,
        "TEXT_SETTING_TAB_KEY_VARIABLE": 1
      }
    },
    "TextTruncation": {
      "values": {
        "TEXT_TRUNCATION_DISABLED": 0,
        "TEXT_TRUNCATION_ENDING": 1
      }
    },
    "TidyType": {
      "values": {
        "TIDY_TYPE_NO_TIDY": 0,
        "TIDY_TYPE_HORIZONTAL": 1,
        "TIDY_TYPE_VERTICAL": 2,
        "TIDY_TYPE_MULTI_DIM": 3
      }
    },
    "ToReplaceComponentType": {
      "values": {
        "TO_REPLACE_COMPONENT_TYPE_COMPONENT": 0,
        "TO_REPLACE_COMPONENT_TYPE_STYLE": 1
      }
    },
    "ToastType": {
      "values": {
        "TOAST_TYPE_DEFAULT": 0,
        "TOAST_TYPE_ERROR": 1
      }
    },
    "ToggleDocumentStateCommandEnum": {
      "values": {
        "TOGGLE_DOCUMENT_STATE_COMMAND_ENUM_DO_NOT_USE_ME_ZERO": 0,
        "TOGGLE_DOCUMENT_STATE_COMMAND_ENUM_SNAP_TO_PIXEL": 2,
        "TOGGLE_DOCUMENT_STATE_COMMAND_ENUM_ENABLE_PIXEL_GRID": 3,
        "TOGGLE_DOCUMENT_STATE_COMMAND_ENUM_SHOW_RULER": 4,
        "TOGGLE_DOCUMENT_STATE_COMMAND_ENUM_ENABLE_LAYOUT_GRID": 5
      }
    },
    "TransitionType": {
      "values": {
        "TRANSITION_TYPE_INSTANT_TRANSITION": 0,
        "TRANSITION_TYPE_DISSOLVE": 1,
        "TRANSITION_TYPE_FADE": 2,
        "TRANSITION_TYPE_SLIDE_FROM_LEFT": 3,
        "TRANSITION_TYPE_SLIDE_FROM_RIGHT": 4,
        "TRANSITION_TYPE_SLIDE_FROM_TOP": 5,
        "TRANSITION_TYPE_SLIDE_FROM_BOTTOM": 6,
        "TRANSITION_TYPE_PUSH_FROM_LEFT": 7,
        "TRANSITION_TYPE_PUSH_FROM_RIGHT": 8,
        "TRANSITION_TYPE_PUSH_FROM_TOP": 9,
        "TRANSITION_TYPE_PUSH_FROM_BOTTOM": 10,
        "TRANSITION_TYPE_MOVE_FROM_LEFT": 11,
        "TRANSITION_TYPE_MOVE_FROM_RIGHT": 12,
        "TRANSITION_TYPE_MOVE_FROM_TOP": 13,
        "TRANSITION_TYPE_MOVE_FROM_BOTTOM": 14,
        "TRANSITION_TYPE_SLIDE_OUT_TO_LEFT": 15,
        "TRANSITION_TYPE_SLIDE_OUT_TO_RIGHT": 16,
        "TRANSITION_TYPE_SLIDE_OUT_TO_TOP": 17,
        "TRANSITION_TYPE_SLIDE_OUT_TO_BOTTOM": 18,
        "TRANSITION_TYPE_MOVE_OUT_TO_LEFT": 19,
        "TRANSITION_TYPE_MOVE_OUT_TO_RIGHT": 20,
        "TRANSITION_TYPE_MOVE_OUT_TO_TOP": 21,
        "TRANSITION_TYPE_MOVE_OUT_TO_BOTTOM": 22,
        "TRANSITION_TYPE_MAGIC_MOVE": 23,
        "TRANSITION_TYPE_SMART_ANIMATE": 24,
        "TRANSITION_TYPE_SCROLL_ANIMATE": 25
      }
    },
    "TriggerDevice": {
      "values": {
        "TRIGGER_DEVICE_KEYBOARD": 0,
        "TRIGGER_DEVICE_UNKNOWN_CONTROLLER": 1,
        "TRIGGER_DEVICE_XBOX_ONE": 2,
        "TRIGGER_DEVICE_P_S4": 3,
        "TRIGGER_DEVICE_SWITCH_PRO": 4
      }
    },
    "UpdateMinSizeAndMaxSizeCommandEnum": {
      "values": {
        "UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MIN_WIDTH": 0,
        "UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MAX_WIDTH": 1,
        "UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MIN_HEIGHT": 2,
        "UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MAX_HEIGHT": 3
      }
    },
    "UpdateSelectionFrameDirectionCommandEnum": {
      "values": {
        "UPDATE_SELECTION_FRAME_DIRECTION_COMMAND_ENUM_HORIZONTAL": 0,
        "UPDATE_SELECTION_FRAME_DIRECTION_COMMAND_ENUM_VERTICAL": 1
      }
    },
    "UpdateSelectionPaintType": {
      "values": {
        "UPDATE_SELECTION_PAINT_TYPE_FILLS": 0,
        "UPDATE_SELECTION_PAINT_TYPE_STROKES": 1
      }
    },
    "UpdateVectorNetworkRegionType": {
      "values": {
        "UPDATE_VECTOR_NETWORK_REGION_TYPE_R_E_G_I_O_N": 0,
        "UPDATE_VECTOR_NETWORK_REGION_TYPE_L_O_O_P": 1
      }
    },
    "UpgradePublishMixinVersionType": {
      "values": {
        "UPGRADE_PUBLISH_MIXIN_VERSION_TYPE_COMPONENT": 0,
        "UPGRADE_PUBLISH_MIXIN_VERSION_TYPE_COMPONENT_SET": 1,
        "UPGRADE_PUBLISH_MIXIN_VERSION_TYPE_STYLE": 2,
        "UPGRADE_PUBLISH_MIXIN_VERSION_TYPE_VARIABLE": 3
      }
    },
    "UserSynergyRole": {
      "values": {
        "USER_SYNERGY_ROLE_UNKNOWN_USER_SYNERGY_ROLE": 0,
        "USER_SYNERGY_ROLE_EDITOR": 1,
        "USER_SYNERGY_ROLE_VIEWER": 2
      }
    },
    "VComponentPropType": {
      "values": {
        "V_COMPONENT_PROP_TYPE_BOOL": 0,
        "V_COMPONENT_PROP_TYPE_TEXT": 1,
        "V_COMPONENT_PROP_TYPE_INSTANCE_SWAP": 2,
        "V_COMPONENT_PROP_TYPE_VARIANT": 3
      }
    },
    "VDetachOpticalSizeFromFontSize": {
      "values": {
        "V_DETACH_OPTICAL_SIZE_FROM_FONT_SIZE_AUTO": 0,
        "V_DETACH_OPTICAL_SIZE_FROM_FONT_SIZE_DETACH": 1,
        "V_DETACH_OPTICAL_SIZE_FROM_FONT_SIZE_MIXED": 2
      }
    },
    "VDevModeLayerPanelToggleChildAction": {
      "values": {
        "V_DEV_MODE_LAYER_PANEL_TOGGLE_CHILD_ACTION_NONE": 0,
        "V_DEV_MODE_LAYER_PANEL_TOGGLE_CHILD_ACTION_EXPAND": 1,
        "V_DEV_MODE_LAYER_PANEL_TOGGLE_CHILD_ACTION_COLLAPSE": 2
      }
    },
    "VLayerPanelLockStatus": {
      "values": {
        "V_LAYER_PANEL_LOCK_STATUS_NONE": 0,
        "V_LAYER_PANEL_LOCK_STATUS_UNLOCKED": 1,
        "V_LAYER_PANEL_LOCK_STATUS_LOCKED": 2,
        "V_LAYER_PANEL_LOCK_STATUS_ANCESTOR_LOCKED": 3
      }
    },
    "VLayerPanelMaskType": {
      "values": {
        "V_LAYER_PANEL_MASK_TYPE_NONE": 0,
        "V_LAYER_PANEL_MASK_TYPE_START": 1,
        "V_LAYER_PANEL_MASK_TYPE_MIDDLE": 2,
        "V_LAYER_PANEL_MASK_TYPE_END": 3
      }
    },
    "VLayerPanelNodeExpandStauts": {
      "values": {
        "V_LAYER_PANEL_NODE_EXPAND_STAUTS_NONE": 0,
        "V_LAYER_PANEL_NODE_EXPAND_STAUTS_EXPANDED": 1,
        "V_LAYER_PANEL_NODE_EXPAND_STAUTS_UN_EXPANDED": 2
      }
    },
    "VLayerPanelVisibleStatus": {
      "values": {
        "V_LAYER_PANEL_VISIBLE_STATUS_NONE": 0,
        "V_LAYER_PANEL_VISIBLE_STATUS_INVISIBLE": 1,
        "V_LAYER_PANEL_VISIBLE_STATUS_VISIBLE": 2,
        "V_LAYER_PANEL_VISIBLE_STATUS_ANCESTOR_INVISIBLE": 3,
        "V_LAYER_PANEL_VISIBLE_STATUS_INVISIBLE_FOR_DOC_READ_ONLY": 4
      }
    },
    "VLibraryAssetPanelDisplayMode": {
      "values": {
        "V_LIBRARY_ASSET_PANEL_DISPLAY_MODE_GRID": 0,
        "V_LIBRARY_ASSET_PANEL_DISPLAY_MODE_LIST": 1
      }
    },
    "VLibraryAssetPanelItemType": {
      "values": {
        "V_LIBRARY_ASSET_PANEL_ITEM_TYPE_HEADER": 0,
        "V_LIBRARY_ASSET_PANEL_ITEM_TYPE_FOLDER": 1,
        "V_LIBRARY_ASSET_PANEL_ITEM_TYPE_COMPONENT": 2
      }
    },
    "VLibraryType": {
      "values": {
        "V_LIBRARY_TYPE_LOCAL": 0,
        "V_LIBRARY_TYPE_LOCAL_HIDDEN": 1,
        "V_LIBRARY_TYPE_INUSE": 2,
        "V_LIBRARY_TYPE_OTHER": 3
      }
    },
    "VSelectionEffectType": {
      "values": {
        "V_SELECTION_EFFECT_TYPE_NONE": 0,
        "V_SELECTION_EFFECT_TYPE_NORMAL": 1,
        "V_SELECTION_EFFECT_TYPE_STYLE": 2,
        "V_SELECTION_EFFECT_TYPE_MIXED": 3
      }
    },
    "VSelectionLayoutGridType": {
      "values": {
        "V_SELECTION_LAYOUT_GRID_TYPE_EMPTY": 0,
        "V_SELECTION_LAYOUT_GRID_TYPE_NORMAL": 1,
        "V_SELECTION_LAYOUT_GRID_TYPE_STYLE": 2,
        "V_SELECTION_LAYOUT_GRID_TYPE_MIXED": 3
      }
    },
    "VTextStateType": {
      "values": {
        "V_TEXT_STATE_TYPE_NONE": 0,
        "V_TEXT_STATE_TYPE_NORMAL": 1,
        "V_TEXT_STATE_TYPE_MIXED": 2
      }
    },
    "VTextStyleStateType": {
      "values": {
        "V_TEXT_STYLE_STATE_TYPE_NONE": 0,
        "V_TEXT_STYLE_STATE_TYPE_NORMAL": 1,
        "V_TEXT_STYLE_STATE_TYPE_STYLE": 2,
        "V_TEXT_STYLE_STATE_TYPE_MIXED": 3
      }
    },
    "ValidateAIReplaceSceneErrorType": {
      "values": {
        "VALIDATE_A_I_REPLACE_SCENE_ERROR_TYPE_NO_ERROR": 0,
        "VALIDATE_A_I_REPLACE_SCENE_ERROR_TYPE_NO_NODE": 1,
        "VALIDATE_A_I_REPLACE_SCENE_ERROR_TYPE_IN_INSTANCE": 2
      }
    },
    "ValidateInstanceSwapValueScene": {
      "values": {
        "VALIDATE_INSTANCE_SWAP_VALUE_SCENE_NONE": 0,
        "VALIDATE_INSTANCE_SWAP_VALUE_SCENE_CREATE_INSTANCE_SWAP": 1,
        "VALIDATE_INSTANCE_SWAP_VALUE_SCENE_CREATE_AND_APPLY_INSTANCE_SWAP": 2,
        "VALIDATE_INSTANCE_SWAP_VALUE_SCENE_APPLY_INSTANCE_SWAP": 3,
        "VALIDATE_INSTANCE_SWAP_VALUE_SCENE_UPDATE_INSTANCE_SWAP": 4,
        "VALIDATE_INSTANCE_SWAP_VALUE_SCENE_UPDATE_ASSIGNMENT": 5
      }
    },
    "VariableCodePlatform": {
      "values": {
        "VARIABLE_CODE_PLATFORM_W_E_B": 0,
        "VARIABLE_CODE_PLATFORM_A_N_D_R_O_I_D": 1,
        "VARIABLE_CODE_PLATFORM_I_O_S": 2
      }
    },
    "VariableConsumptionItemType": {
      "values": {
        "VARIABLE_CONSUMPTION_ITEM_TYPE_NONE": 0,
        "VARIABLE_CONSUMPTION_ITEM_TYPE_VARIABLE": 1,
        "VARIABLE_CONSUMPTION_ITEM_TYPE_MIXED": 2
      }
    },
    "VariableDataType": {
      "values": {
        "VARIABLE_DATA_TYPE_BOOLEAN": 0,
        "VARIABLE_DATA_TYPE_FLOAT": 1,
        "VARIABLE_DATA_TYPE_STRING": 2,
        "VARIABLE_DATA_TYPE_ALIAS": 3,
        "VARIABLE_DATA_TYPE_COLOR": 4,
        "VARIABLE_DATA_TYPE_EXPRESSION": 5,
        "VARIABLE_DATA_TYPE_MAP": 6,
        "VARIABLE_DATA_TYPE_SYMBOL_ID": 7,
        "VARIABLE_DATA_TYPE_FONT_STYLE": 8,
        "VARIABLE_DATA_TYPE_TEXT_DATA": 9,
        "VARIABLE_DATA_TYPE_INVALID": 10
      }
    },
    "VariableField": {
      "values": {
        "VARIABLE_FIELD_M_I_S_S_I_N_G": 0,
        "CORNER_RADIUS": 1,
        "PARAGRAPH_SPACING": 2,
        "PARAGRAPH_INDENT": 3,
        "STROKE_WEIGHT": 4,
        "STACK_SPACING": 5,
        "STACK_PADDING_LEFT": 6,
        "STACK_PADDING_TOP": 7,
        "STACK_PADDING_RIGHT": 8,
        "STACK_PADDING_BOTTOM": 9,
        "VARIABLE_FIELD_V_I_S_I_B_L_E": 10,
        "TEXT_DATA": 11,
        "VARIABLE_FIELD_W_I_D_T_H": 12,
        "VARIABLE_FIELD_H_E_I_G_H_T": 13,
        "RECTANGLE_TOP_LEFT_CORNER_RADIUS": 14,
        "RECTANGLE_TOP_RIGHT_CORNER_RADIUS": 15,
        "RECTANGLE_BOTTOM_LEFT_CORNER_RADIUS": 16,
        "RECTANGLE_BOTTOM_RIGHT_CORNER_RADIUS": 17,
        "BORDER_TOP_WEIGHT": 18,
        "BORDER_BOTTOM_WEIGHT": 19,
        "BORDER_LEFT_WEIGHT": 20,
        "BORDER_RIGHT_WEIGHT": 21,
        "VARIANT_PROPERTIES": 22,
        "STACK_COUNTER_SPACING": 23,
        "MIN_WIDTH": 24,
        "MAX_WIDTH": 25,
        "MIN_HEIGHT": 26,
        "MAX_HEIGHT": 27,
        "FONT_FAMILY": 28,
        "FONT_STYLE": 29,
        "FONT_VARIATIONS": 30,
        "VARIABLE_FIELD_O_P_A_C_I_T_Y": 31,
        "FONT_SIZE": 32,
        "LETTER_SPACING": 33,
        "LINE_HEIGHT": 34
      }
    },
    "VariableResolvedDataType": {
      "values": {
        "VARIABLE_RESOLVED_DATA_TYPE_BOOLEAN": 0,
        "VARIABLE_RESOLVED_DATA_TYPE_FLOAT": 1,
        "VARIABLE_RESOLVED_DATA_TYPE_STRING": 2,
        "VARIABLE_RESOLVED_DATA_TYPE_COLOR": 3,
        "VARIABLE_RESOLVED_DATA_TYPE_MAP": 4,
        "VARIABLE_RESOLVED_DATA_TYPE_SYMBOL_ID": 5,
        "VARIABLE_RESOLVED_DATA_TYPE_FONT_STYLE": 6,
        "VARIABLE_RESOLVED_DATA_TYPE_TEXT_DATA": 7,
        "VARIABLE_RESOLVED_DATA_TYPE_INVALID": 8
      }
    },
    "VariableScope": {
      "values": {
        "ALL_SCOPES": 0,
        "ALL_FILLS": 1,
        "FRAME_FILL": 2,
        "SHAPE_FILL": 3,
        "TEXT_FILL": 4,
        "VARIABLE_SCOPE_S_T_R_O_K_E": 5,
        "EFFECT_COLOR": 6,
        "FONT_FAMILY": 7,
        "FONT_STYLE": 8,
        "TEXT_CONTENT": 9,
        "CORNER_RADIUS": 10,
        "WIDTH_HEIGHT": 11,
        "VARIABLE_SCOPE_G_A_P": 12,
        "STROKE_FLOAT": 13,
        "EFFECT_FLOAT": 14,
        "VARIABLE_SCOPE_O_P_A_C_I_T_Y": 15,
        "FONT_SIZE": 16,
        "LINE_HEIGHT": 17,
        "LETTER_SPACING": 18,
        "PARAGRAPH_SPACING": 19,
        "PARAGRAPH_INDENT": 20,
        "FONT_VARIATIONS": 21
      }
    },
    "VariableSetModeConflictType": {
      "values": {
        "VARIABLE_SET_MODE_CONFLICT_TYPE_NONE": 0,
        "VARIABLE_SET_MODE_CONFLICT_TYPE_PUBLISH": 1,
        "VARIABLE_SET_MODE_CONFLICT_TYPE_UPDATE": 2
      }
    },
    "VariableSetModeSelectionType": {
      "values": {
        "VARIABLE_SET_MODE_SELECTION_TYPE_VALUE": 0,
        "VARIABLE_SET_MODE_SELECTION_TYPE_DEFAULT": 1,
        "VARIABLE_SET_MODE_SELECTION_TYPE_MIXED": 2
      }
    },
    "VectorHandleMirror": {
      "values": {
        "VECTOR_HANDLE_MIRROR_NONE": 0,
        "VECTOR_HANDLE_MIRROR_ANGLE": 1,
        "VECTOR_HANDLE_MIRROR_ANGLE_AND_LENGTH": 2
      }
    },
    "ViewStateToken": {
      "values": {
        "VIEW_STATE_TOKEN_FRAME_PRESET_STATE": 0,
        "VIEW_STATE_TOKENACTION_ENABLED_ADD_AUTO_LAYOUT_STATUS": 1,
        "VIEW_STATE_TOKENACTION_ENABLED_ADD_PROTOTYPE_STARTING_POINT": 2,
        "VIEW_STATE_TOKENACTION_ENABLED_ADD_VARIANT": 3,
        "VIEW_STATE_TOKENACTION_ENABLED_AI_ADD_AUTO_LAYOUT": 4,
        "VIEW_STATE_TOKENACTION_ENABLED_BOOL_SELECTION_STATUS": 5,
        "VIEW_STATE_TOKENACTION_ENABLED_BRING_FORWARD_STATUS": 6,
        "VIEW_STATE_TOKENACTION_ENABLED_BRING_TO_FRONT_STATUS": 7,
        "VIEW_STATE_TOKENACTION_ENABLED_CAN_USE_ALIGN_STATUS": 8,
        "VIEW_STATE_TOKENACTION_ENABLED_CAN_USE_DISTRIBUTE_DIRECTION_STATUS": 9,
        "VIEW_STATE_TOKENACTION_ENABLED_CAN_USE_DISTRIBUTE_H_V_STATUS": 10,
        "VIEW_STATE_TOKENACTION_ENABLED_CAN_USE_PACK_STATUS": 11,
        "VIEW_STATE_TOKENACTION_ENABLED_CAN_USE_PIXEL_ALIGN_STATUS": 12,
        "VIEW_STATE_TOKENACTION_ENABLED_CANCEL_MASK_STATUS": 13,
        "VIEW_STATE_TOKENACTION_ENABLED_CLEAR_SELECT_NODES_STATUS": 14,
        "VIEW_STATE_TOKENACTION_ENABLED_COLOR_PICK_STATUS": 15,
        "VIEW_STATE_TOKENACTION_ENABLED_COMPONENT_SELECTION_STATUS": 16,
        "VIEW_STATE_TOKENACTION_ENABLED_COPY_AS_LINK_STATUS": 17,
        "VIEW_STATE_TOKENACTION_ENABLED_COPY_AS_P_N_G_STATUS": 18,
        "VIEW_STATE_TOKENACTION_ENABLED_COPY_AS_S_V_G_STATUS": 19,
        "VIEW_STATE_TOKENACTION_ENABLED_COPY_AS_TEXT_STATUS": 20,
        "VIEW_STATE_TOKENACTION_ENABLED_COPY_PASTE_STATUS": 21,
        "VIEW_STATE_TOKENACTION_ENABLED_COPY_PROPERTIES_STATUS": 22,
        "VIEW_STATE_TOKENACTION_ENABLED_COPY_STATUS": 23,
        "VIEW_STATE_TOKENACTION_ENABLED_DELETE_PROTOTYPE_STARTING_POINT": 24,
        "VIEW_STATE_TOKENACTION_ENABLED_DETACH_INSTANCE_STATUS": 25,
        "VIEW_STATE_TOKENACTION_ENABLED_DUPLICATE_SELECTION_STATUS": 26,
        "VIEW_STATE_TOKENACTION_ENABLED_ENTER_AI_LAYOUT_STATUS": 27,
        "VIEW_STATE_TOKENACTION_ENABLED_EXCLUDE_STATUS": 28,
        "VIEW_STATE_TOKENACTION_ENABLED_EXIT_AI_LAYOUT_STATUS": 29,
        "VIEW_STATE_TOKENACTION_ENABLED_FLIP_HORIZONTAL_STATUS": 30,
        "VIEW_STATE_TOKENACTION_ENABLED_FLIP_VERTICAL_STATUS": 31,
        "VIEW_STATE_TOKENACTION_ENABLED_FRAME_SELECTION_STATUS": 32,
        "VIEW_STATE_TOKENACTION_ENABLED_GO_TO_SELECTED_INSTANCE_MAIN_COMPONENT_STATUS": 33,
        "VIEW_STATE_TOKENACTION_ENABLED_GROUP_SELECTION_STATUS": 34,
        "VIEW_STATE_TOKENACTION_ENABLED_INTERSECT_STATUS": 35,
        "VIEW_STATE_TOKENACTION_ENABLED_LAYOUT_GRID_STATUS": 36,
        "VIEW_STATE_TOKENACTION_ENABLED_MORE_LAYOUT_OPTION_STATUS": 37,
        "VIEW_STATE_TOKENACTION_ENABLED_MOVE_NODES_TO_OTHER_PAGE": 38,
        "VIEW_STATE_TOKENACTION_ENABLED_OUTLINE_STROKE_STATUS": 39,
        "VIEW_STATE_TOKENACTION_ENABLED_PAGE_DOWN_STATUS": 40,
        "VIEW_STATE_TOKENACTION_ENABLED_PAGE_UP_STATUS": 41,
        "VIEW_STATE_TOKENACTION_ENABLED_PASTE_HERE_STATUS": 42,
        "VIEW_STATE_TOKENACTION_ENABLED_PASTE_PROPERTIES_STATUS": 43,
        "VIEW_STATE_TOKENACTION_ENABLED_PASTE_TO_REPLACE_STATUS": 44,
        "VIEW_STATE_TOKENACTION_ENABLED_PIXEL_GRID_STATUS": 45,
        "VIEW_STATE_TOKENACTION_ENABLED_PUBLISH_SELECTED_NODES_STATUS": 46,
        "VIEW_STATE_TOKENACTION_ENABLED_REDOABLE_STATUS": 47,
        "VIEW_STATE_TOKENACTION_ENABLED_REMOVE_ALL_PROTOTYPE_INTERACTIONS": 48,
        "VIEW_STATE_TOKENACTION_ENABLED_REMOVE_AUTO_LAYOUT_STATUS": 49,
        "VIEW_STATE_TOKENACTION_ENABLED_REMOVE_FILL_STATUS": 50,
        "VIEW_STATE_TOKENACTION_ENABLED_REMOVE_PROTOTYPE_INTERACTION": 51,
        "VIEW_STATE_TOKENACTION_ENABLED_REMOVE_SELECT_NODES_STATUS": 52,
        "VIEW_STATE_TOKENACTION_ENABLED_REMOVE_STROKE_STATUS": 53,
        "VIEW_STATE_TOKENACTION_ENABLED_RENAME_STATUS": 54,
        "VIEW_STATE_TOKENACTION_ENABLED_RESET_PROPERTIES_STATUS": 55,
        "VIEW_STATE_TOKENACTION_ENABLED_RULER_STATUS": 56,
        "VIEW_STATE_TOKENACTION_ENABLED_SELECT_ALL_STATUS": 57,
        "VIEW_STATE_TOKENACTION_ENABLED_SELECT_INVERSE_STATUS": 58,
        "VIEW_STATE_TOKENACTION_ENABLED_SEND_BACKWARD_STATUS": 59,
        "VIEW_STATE_TOKENACTION_ENABLED_SEND_TO_BACK_STATUS": 60,
        "VIEW_STATE_TOKENACTION_ENABLED_SHOW_SIDEBAR_STATUS": 61,
        "VIEW_STATE_TOKENACTION_ENABLED_SUBTRACT_STATUS": 62,
        "VIEW_STATE_TOKENACTION_ENABLED_SWAP_FILL_AND_STROKE_STATUS": 63,
        "VIEW_STATE_TOKENACTION_ENABLED_TOGGLE_FULL_SCREEN_STATUS": 64,
        "VIEW_STATE_TOKENACTION_ENABLED_TOGGLE_LOCKED_STATUS": 65,
        "VIEW_STATE_TOKENACTION_ENABLED_TOGGLE_SHOW_COMMENT_STATUS": 66,
        "VIEW_STATE_TOKENACTION_ENABLED_TOGGLE_SHOWN_STATUS": 67,
        "VIEW_STATE_TOKENACTION_ENABLED_TOGGLEN_MEMORY_USAGE_STATUS": 68,
        "VIEW_STATE_TOKENACTION_ENABLED_TOGGLEN_SHOW_SLICE_STATUS": 69,
        "VIEW_STATE_TOKENACTION_ENABLED_UNDOABLE_STATUS": 70,
        "VIEW_STATE_TOKENACTION_ENABLED_UNGROUP_SELECTION_STATUS": 71,
        "VIEW_STATE_TOKENACTION_ENABLED_UNION_STATUS": 72,
        "VIEW_STATE_TOKENACTION_ENABLED_USE_AS_MASK_STATUS": 73,
        "VIEW_STATE_TOKENACTION_ENABLED_VIEW_NEXT_FRAME_STATUS": 74,
        "VIEW_STATE_TOKENACTION_ENABLED_VIEW_PREV_FRAME_STATUS": 75,
        "VIEW_STATE_TOKENACTION_ENABLED_ZOOM_IN_AND_OUT_STATUS": 76,
        "VIEW_STATE_TOKENACTION_ENABLED_ZOOM_TO_NEXT_FRAME_STATUS": 77,
        "VIEW_STATE_TOKENACTION_ENABLED_ZOOM_TO_PREV_FRAME_STATUS": 78,
        "VIEW_STATE_TOKENACTIVED_COMMENT": 79,
        "VIEW_STATE_TOKENAI_CHAT_SELECTED_NODE_SYSTEM": 80,
        "VIEW_STATE_TOKENAI_LAYOUT_ENTRY_MENU_STATE": 81,
        "VIEW_STATE_TOKENAI_SEARCH_SELECTION": 82,
        "VIEW_STATE_TOKENARC_DATA": 83,
        "VIEW_STATE_TOKENARRANGE_STATE": 84,
        "VIEW_STATE_TOKENATTR_PANEL_EDITING_STYLE_VIEW_STATE": 85,
        "VIEW_STATE_TOKENATTR_PANEL_STYLE_EDITOR_STATE": 86,
        "VIEW_STATE_TOKENAUTO_LAYOUT": 87,
        "VIEW_STATE_TOKENAVAILABLE_FONTS": 88,
        "VIEW_STATE_TOKENBASE_ATTRIBUTE_V2": 89,
        "VIEW_STATE_TOKENBATCH_EXPORT_POPUP_STATE": 90,
        "VIEW_STATE_TOKENBELONGING_COMPONENT_PANEL_STATE": 91,
        "VIEW_STATE_TOKENBLEND_MODE_VIEW": 92,
        "VIEW_STATE_TOKENCANDIDATE_COMPONENT_PUBLISH_STATE": 93,
        "VIEW_STATE_TOKENCANDIDATE_COMPONENT_REPLACE_STATE": 94,
        "VIEW_STATE_TOKENCANVAS_RIGHT_CLICK_MENU_VISIBLE": 95,
        "VIEW_STATE_TOKENCANVAS_SEARCH_HIGH_LIGHT_TITLE": 96,
        "VIEW_STATE_TOKENCANVAS_SEARCH_PARAM": 97,
        "VIEW_STATE_TOKENCANVAS_SEARCH_RESULT_LAYER_ITEM_FOR_SCROLL": 98,
        "VIEW_STATE_TOKENCANVAS_SEARCH_RESULT_RENDER_INFO": 99,
        "VIEW_STATE_TOKENCLIPS_CONTENT": 100,
        "VIEW_STATE_TOKENCOACTOR_MEMBERSHIP_MAP": 101,
        "VIEW_STATE_TOKENCOLOR_PICK": 102,
        "VIEW_STATE_TOKENCOMMENT_INTERACTIVE": 103,
        "VIEW_STATE_TOKENCOMMENTS_POSITION": 104,
        "VIEW_STATE_TOKENCOMPONENT_INSTANCE_SWAP_PROP_PREFER_VALUE_STATE": 105,
        "VIEW_STATE_TOKENCOMPONENT_INSTANCE_SWAP_PROP_STATE": 106,
        "VIEW_STATE_TOKENCOMPONENT_PANEL_STATE": 107,
        "VIEW_STATE_TOKENCOMPONENT_PROP_VIEW_STATE": 108,
        "VIEW_STATE_TOKENCONSTRAIN_PROPORTIONS": 109,
        "VIEW_STATE_TOKENCONSTRAINTS_STATE": 110,
        "VIEW_STATE_TOKENCOPY_PASTE_STATE": 111,
        "VIEW_STATE_TOKENCORNER_RADIUS": 112,
        "VIEW_STATE_TOKENCORNER_SMOOTHING": 113,
        "VIEW_STATE_TOKENCURRENT_PAGE_BACKGROUND": 114,
        "VIEW_STATE_TOKENCURRENT_PAGE_COACTOR_MOUSE_LIST": 115,
        "VIEW_STATE_TOKENCURRENT_PAGE_ID": 116,
        "VIEW_STATE_TOKENCURRENT_VIEWPORT": 117,
        "VIEW_STATE_TOKENDESIGN_PANEL_ITEM_SHOW": 118,
        "VIEW_STATE_TOKENDEV_MODE_ALLOW_ICON_AUTO_DETECTION_VIEW_STATE": 119,
        "VIEW_STATE_TOKENDEV_MODE_BATCH_EXPORT_VIEW_STATE": 120,
        "VIEW_STATE_TOKENDEV_MODE_CODE_CONFIG": 121,
        "VIEW_STATE_TOKENDEV_MODE_DISPLAY_PANELS": 122,
        "VIEW_STATE_TOKENDEV_MODE_HOVER_NODE_LABEL_VIEW_STATE": 123,
        "VIEW_STATE_TOKENDEV_MODE_INSPECT_BOX_MODEL_VIEW_STATE": 124,
        "VIEW_STATE_TOKENDEV_MODE_INSPECT_CODE_TYPE_VIEW_STATE": 125,
        "VIEW_STATE_TOKENDEV_MODE_INSPECT_CODE_VIEW_STATE": 126,
        "VIEW_STATE_TOKENDEV_MODE_INSPECT_COMPONENT_INSTANCE_SUBORDINATE_VIEW_STATE": 127,
        "VIEW_STATE_TOKENDEV_MODE_INSPECT_COMPONENT_VIEW_STATE": 128,
        "VIEW_STATE_TOKENDEV_MODE_INSPECT_CONTENT_VIEW_STATE": 129,
        "VIEW_STATE_TOKENDEV_MODE_INSPECT_EFFECT_VIEW_STATE": 130,
        "VIEW_STATE_TOKENDEV_MODE_INSPECT_EXPORT_VIEW_STATE": 131,
        "VIEW_STATE_TOKENDEV_MODE_INSPECT_FILL_VIEW_STATE": 132,
        "VIEW_STATE_TOKENDEV_MODE_INSPECT_LAYOUT_VIEW_STATE": 133,
        "VIEW_STATE_TOKENDEV_MODE_INSPECT_NODE_BASIC_INFO_VIEW_STATE": 134,
        "VIEW_STATE_TOKENDEV_MODE_INSPECT_SELECTION_INFO_VIEW_STATE": 135,
        "VIEW_STATE_TOKENDEV_MODE_INSPECT_STROKE_VIEW_STATE": 136,
        "VIEW_STATE_TOKENDEV_MODE_INSPECT_TEXT_VIEW_STATE": 137,
        "VIEW_STATE_TOKENDEV_MODE_INSPECT_TYPE_VIEW_STATE": 138,
        "VIEW_STATE_TOKENDEV_MODE_LAYER_PANEL": 139,
        "VIEW_STATE_TOKENDEV_MODE_LAYER_PANEL_TOGGLE_CHILD_ACTION": 140,
        "VIEW_STATE_TOKENDEV_MODE_SELECTED_SWITCHABLE_DELIVERY_UNIT_VIEW_STATE": 141,
        "VIEW_STATE_TOKENDISPLAY_PANELS": 142,
        "VIEW_STATE_TOKENDOC_READONLY": 143,
        "VIEW_STATE_TOKENDOCUMENT_COLOR_PROFILE": 144,
        "VIEW_STATE_TOKENDOCUMENT_LOADED": 145,
        "VIEW_STATE_TOKENDOCUMENT_PAGE_LIST": 146,
        "VIEW_STATE_TOKENDOCUMENT_USING_FONT_NAME": 147,
        "VIEW_STATE_TOKENDRAFT_COMMENT": 148,
        "VIEW_STATE_TOKENDRAWING_COMMENT": 149,
        "VIEW_STATE_TOKENEDITOR_MODE_STATE": 150,
        "VIEW_STATE_TOKENEDITOR_TYPE": 151,
        "VIEW_STATE_TOKENEXPORT_INFO": 152,
        "VIEW_STATE_TOKENEXPORT_SETTINGS_STATE": 153,
        "VIEW_STATE_TOKENFULLSCREEN": 154,
        "VIEW_STATE_TOKENGLOBAL_STATE": 155,
        "VIEW_STATE_TOKENHAS_SELECTION": 156,
        "VIEW_STATE_TOKENHISTORY_MODE": 157,
        "VIEW_STATE_TOKENHOVERED_COMMENT": 158,
        "VIEW_STATE_TOKENHYPERLINK_EDITING_STATE": 159,
        "VIEW_STATE_TOKENHYPERLINK_HOVER_POPUP_STATE": 160,
        "VIEW_STATE_TOKENIMAGE_STATES": 161,
        "VIEW_STATE_TOKENINDEPENDENT_CORNER_RADIUS": 162,
        "VIEW_STATE_TOKENINSPECT_EFFECT_VIEW_STATE": 163,
        "VIEW_STATE_TOKENINSPECT_SELECTION_CODE_VIEW_STATE": 164,
        "VIEW_STATE_TOKENINSPECT_SELECTION_COMPONENT_INFO_VIEW_STATE": 165,
        "VIEW_STATE_TOKENINSPECT_SELECTION_COMPONENT_PROP_INFO_VIEW_STATE": 166,
        "VIEW_STATE_TOKENINSPECT_SELECTION_FILL_VIEW_STATE": 167,
        "VIEW_STATE_TOKENINSPECT_SELECTION_TEXT_CONTENT_VIEW_STATE": 168,
        "VIEW_STATE_TOKENINSPECT_SELECTION_TEXT_TYPOGRAPHY_VIEW_STATE": 169,
        "VIEW_STATE_TOKENINSPECT_SELECTION_VARIANT_VIEW_STATE": 170,
        "VIEW_STATE_TOKENIS_PREVIEW_PENDING": 171,
        "VIEW_STATE_TOKENLAYER_PANEL": 172,
        "VIEW_STATE_TOKENLAYER_PANEL_A_I_LAYOUT_COLOR_LINE": 173,
        "VIEW_STATE_TOKENLAYER_SELECTION": 174,
        "VIEW_STATE_TOKENLIBRARIES_COLOR_VARIABLE": 175,
        "VIEW_STATE_TOKENLIBRARIES_PRIMITIVE_VARIABLE": 176,
        "VIEW_STATE_TOKENLIBRARY_ASSET_PANEL": 177,
        "VIEW_STATE_TOKENLIBRARY_CURRENT_LOCAL_MODAL": 178,
        "VIEW_STATE_TOKENLIBRARY_EFFECT_STYLE_STATE": 179,
        "VIEW_STATE_TOKENLIBRARY_GLOBAL_STATE": 180,
        "VIEW_STATE_TOKENLIBRARY_LAYOUT_GRID_STYLE_STATE": 181,
        "VIEW_STATE_TOKENLIBRARY_PAINT_STYLE_STATE": 182,
        "VIEW_STATE_TOKENLIBRARY_REMOTE_LIST_MODAL": 183,
        "VIEW_STATE_TOKENLIBRARY_REPLACE_MODAL_VIEW_STATE": 184,
        "VIEW_STATE_TOKENLIBRARY_TEXT_STYLE_STATE": 185,
        "VIEW_STATE_TOKENLIBRARY_UPGRADE_MODAL": 186,
        "VIEW_STATE_TOKENLOCAL_STYLE_EDITING_STATE_V2": 187,
        "VIEW_STATE_TOKENLOCAL_STYLE_NODE_STATE": 188,
        "VIEW_STATE_TOKENLOCAL_VARIABLE_COLLECTION": 189,
        "VIEW_STATE_TOKENLOCAL_VARIABLE_COUNT": 190,
        "VIEW_STATE_TOKENLOCAL_VARIABLE_EDITOR_EDITING_STATE": 191,
        "VIEW_STATE_TOKENLOCAL_VARIABLE_EDITOR_RENAMING_STATE": 192,
        "VIEW_STATE_TOKENLOCAL_VARIABLE_GROUP": 193,
        "VIEW_STATE_TOKENLOCAL_VARIABLE_TABLE": 194,
        "VIEW_STATE_TOKENMAIN_MENU_VISIBLE": 195,
        "VIEW_STATE_TOKENMASK_PANEL_STATE": 196,
        "VIEW_STATE_TOKENMEMORY_USAGE": 197,
        "VIEW_STATE_TOKENMIRROR_FRAME_VIEW_STATE": 198,
        "VIEW_STATE_TOKENMOTIFF_SCOPE": 199,
        "VIEW_STATE_TOKENMOTIFF_SCOPE_REFRESH_TRIGGER": 200,
        "VIEW_STATE_TOKENMULTIPLE_RENAME": 201,
        "VIEW_STATE_TOKENNODE_PREVIEW_TRACKS_CHANGED": 202,
        "VIEW_STATE_TOKENOPENING_LIBRARY_IN_USE_COUNT_INFO": 203,
        "VIEW_STATE_TOKENPAGE_TOP_AREA": 204,
        "VIEW_STATE_TOKENPANEL_STATE_UNDER_A_I_LAYOUT": 205,
        "VIEW_STATE_TOKENPLUGIN_AE_EXPORT_ENABLED": 206,
        "VIEW_STATE_TOKENPLUGIN_ARC_TEXT": 207,
        "VIEW_STATE_TOKENPLUGIN_FILL_RULE_EDITOR_STATE": 208,
        "VIEW_STATE_TOKENPLUGIN_LOOPER_ENABLED": 209,
        "VIEW_STATE_TOKENPLUGIN_ROTATE_COPY_VIEW_STATE": 210,
        "VIEW_STATE_TOKENPLUGIN_STATE": 211,
        "VIEW_STATE_TOKENPOLYGON_STAR": 212,
        "VIEW_STATE_TOKENPOPUP_STATE": 213,
        "VIEW_STATE_TOKENPRESENT_DISABLE_DEFAULT_KEYBOARD_NAV": 214,
        "VIEW_STATE_TOKENPRESENT_FRAME_COUNTER_VIEW_STATE": 215,
        "VIEW_STATE_TOKENPRESENT_HAS_CANVAS_MARGIN_TOP": 216,
        "VIEW_STATE_TOKENPRESENT_HIDE_U_I": 217,
        "VIEW_STATE_TOKENPRESENT_SHOW_DEVICE_VIEW_STATE": 218,
        "VIEW_STATE_TOKENPRESENT_SHOW_HOTSPOT_HINT_VIEW_STATE": 219,
        "VIEW_STATE_TOKENPRESENT_SHOW_PROTO_SIDEBAR": 220,
        "VIEW_STATE_TOKENPREVIEW_DOCUMENT_LOADED_STATE": 221,
        "VIEW_STATE_TOKENPREVIEW_DOCUMENT_NODE_STATE": 222,
        "VIEW_STATE_TOKENPREVIEW_FLOWS_VIEW_STATE": 223,
        "VIEW_STATE_TOKENPROTOTYPE_BACKGROUND_COLOR": 224,
        "VIEW_STATE_TOKENPROTOTYPE_DEVICE": 225,
        "VIEW_STATE_TOKENPROTOTYPE_FLOW_LIST": 226,
        "VIEW_STATE_TOKENPROTOTYPE_FLOW_LIST_FOR_SELECTION": 227,
        "VIEW_STATE_TOKENPROTOTYPE_PANEL_SCROLL_BEHAVIOR_VIEW_STATE": 228,
        "VIEW_STATE_TOKENPROTOTYPE_PRESENT_CANVAS_PADDING_VIEW_STATE": 229,
        "VIEW_STATE_TOKENPROTOTYPE_PRESENT_DEVICE_FRAME_REGION_VIEW_STATE": 230,
        "VIEW_STATE_TOKENPROTOTYPE_PRESENT_SCALING_VIEW_STATE": 231,
        "VIEW_STATE_TOKENPROTOTYPE_STARTING_POINT_EDITING_NAME_VIEW_STATE": 232,
        "VIEW_STATE_TOKENPROTOTYPING_VISIBLE": 233,
        "VIEW_STATE_TOKENPUBLIC_PROP_INSTANCE_STATE": 234,
        "VIEW_STATE_TOKENPUBLISH_MODAL": 235,
        "VIEW_STATE_TOKENREPLACE_INSTANCE_STATE": 236,
        "VIEW_STATE_TOKENRIGHT_CLICK_MENU_STATE": 237,
        "VIEW_STATE_TOKENRIGHT_CLICK_SET_THUMBNAIL_STATUS": 238,
        "VIEW_STATE_TOKENSCALE_TOOL_PANEL": 239,
        "VIEW_STATE_TOKENSELECT_CHANGE_TIME": 240,
        "VIEW_STATE_TOKENSELECTED_EFFECTS": 241,
        "VIEW_STATE_TOKENSELECTED_FILLS": 242,
        "VIEW_STATE_TOKENSELECTED_GRADIENT_COLOR_STOP_INDEX": 243,
        "VIEW_STATE_TOKENSELECTED_LAYOUT_GRIDS": 244,
        "VIEW_STATE_TOKENSELECTED_STROKES": 245,
        "VIEW_STATE_TOKENSELECTION_COLOR_SCOPE": 246,
        "VIEW_STATE_TOKENSELECTION_EFFECT": 247,
        "VIEW_STATE_TOKENSELECTION_FILL": 248,
        "VIEW_STATE_TOKENSELECTION_HYPERLINK_STATE": 249,
        "VIEW_STATE_TOKENSELECTION_LAYOUT_GRID_STATE": 250,
        "VIEW_STATE_TOKENSELECTION_PAINT": 251,
        "VIEW_STATE_TOKENSELECTION_PROTOTYPE_INTERACTION": 252,
        "VIEW_STATE_TOKENSELECTION_STROKE_V2": 253,
        "VIEW_STATE_TOKENSELECTION_TEXT": 254,
        "VIEW_STATE_TOKENSELECTION_TEXT_LINE": 255,
        "VIEW_STATE_TOKENSHOW_COMMENTS": 256,
        "VIEW_STATE_TOKENSHOW_DEV_MODE_INSPECT_EXPORT": 257,
        "VIEW_STATE_TOKENSHOW_LOCAL_VARIABLE_EDITOR": 258,
        "VIEW_STATE_TOKENSHOW_MULTIPLAYER_CURSOR": 259,
        "VIEW_STATE_TOKENSHOW_MULTIPLE_RENAME_MODAL": 260,
        "VIEW_STATE_TOKENSHOW_SHORTCUT_PANEL": 261,
        "VIEW_STATE_TOKENSHOW_SIDEBAR": 262,
        "VIEW_STATE_TOKENSHOW_SLICE": 263,
        "VIEW_STATE_TOKENSIDEBAR_PANELS": 264,
        "VIEW_STATE_TOKENSINGLE_SELECTION": 265,
        "VIEW_STATE_TOKENSMART_SELECTION": 266,
        "VIEW_STATE_TOKENSYNC_PROGRESS": 267,
        "VIEW_STATE_TOKENTEXT_EDITING_STATE": 268,
        "VIEW_STATE_TOKENTEXT_SEARCH": 269,
        "VIEW_STATE_TOKENTHUMBNAIL": 270,
        "VIEW_STATE_TOKENTOP_AREA": 271,
        "VIEW_STATE_TOKENTOP_EXTEND_BOOL_MENU_VISIBLE": 272,
        "VIEW_STATE_TOKENVARIABLE_CONSUMPTION_MAP_STATE": 273,
        "VIEW_STATE_TOKENVARIABLE_CREATE": 274,
        "VIEW_STATE_TOKENVARIABLE_LAYER_USING_MODE": 275,
        "VIEW_STATE_TOKENVARIABLE_MODE_ENTRIES_IN_PAGE": 276,
        "VIEW_STATE_TOKENVARIABLE_MODE_ENTRIES_IN_SELECT": 277,
        "VIEW_STATE_TOKENVECTOR_PANEL": 278,
        "VIEW_STATE_TOKENVISIBLE_VIEWPORT": 279
      }
    },
    "ViewportAnimationKeyframeType": {
      "values": {
        "VIEWPORT_ANIMATION_KEYFRAME_TYPE_NONE": 0,
        "VIEWPORT_ANIMATION_KEYFRAME_TYPE_RUNNING": 1,
        "VIEWPORT_ANIMATION_KEYFRAME_TYPE_END": 2,
        "VIEWPORT_ANIMATION_KEYFRAME_TYPE_CANCEL": 3
      }
    },
    "WasmCallCode": {
      "values": {
        "WASM_CALL_CODE_DO_NOT_USE_ME_ZERO": 0,
        "WCC_createImageThumbnail": 103,
        "WCC_createGradientThumbnail": 108,
        "WCC_createPaintStyleThumbnail": 116,
        "WCC_createPaintStylePreview": 117,
        "WCC_createEffectStylePreview": 119,
        "WCC_createTextStyleThumbnail": 120,
        "WCC_createTextStylePreview": 121,
        "WCC_emDocuemntGetDocumentNodeId": 4040,
        "WCC_": 20000,
        "WCC_cmdCanvasClipboardCut": 20001,
        "WCC_cmdCanvasClipboardCopy": 20002,
        "WCC_cmdCanvasClipboardPaste": 20003,
        "WCC_cmdDuplicateSelection": 20004,
        "WCC_cmdCanvasClipboardPasteHere": 20005,
        "WCC_cmdCanvasClipboardPasteToReplace": 20006,
        "WCC_cmdCanvasClipboardPasteOverSelection": 20007,
        "WCC_cmdUpdateMainComponent": 20008,
        "WCC_cmdScrollSelectionIntoView": 20010,
        "WCC_cmdCanvasClipboardCopySelectionAsText": 20011,
        "WCC_cmdCanvasClipboardCopySelectionAsPNG": 20012,
        "WCC_cmdCanvasClipboardCopySelectionAsSVG": 20013,
        "WCC_cmdCanvasClipboardCopySelectionAsLink": 20014,
        "WCC_cmdChangeEditorMode": 20015,
        "WCC_startRecordingAllocationJsStack": 20017,
        "WCC_stopRecordingAllocationJsStack": 20018,
        "WCC_hoverAutoLayoutWHItem": 20019,
        "WCC_addAutoLayout": 20021,
        "WCC_removeAutoLayout": 20022,
        "WCC_updateAutoLayoutBatch": 20023,
        "WCC_updateAutoLayoutChildBatch": 20024,
        "WCC_cmdAutoLayoutMoveChildren": 20026,
        "WCC_setAutoLayoutWH": 20027,
        "WCC_clearAutoLayoutFloatInput": 20028,
        "WCC_nextAutoLayoutFloatInput": 20029,
        "WCC_setAutoLayoutAdvancePanel": 20030,
        "WCC_cmdUpdateWindowInnerHeight": 20031,
        "WCC_cmdUpdateLayerPanelScrollTop": 20032,
        "WCC_cmdLayerPanelEnterEditing": 20033,
        "WCC_cmdLayerPanelCancelEditing": 20034,
        "WCC_cmdLayerPanelRename": 20035,
        "WCC_cmdLayerPanelTabRename": 20036,
        "WCC_cmdLayerPanelShiftTabRename": 20037,
        "WCC_exeColorPickCommand": 20038,
        "WCC_cmdLayerPanelLeftClick": 20039,
        "WCC_cmdLayerPanelRightClick": 20040,
        "WCC_cmdLayerPanelClickBlank": 20041,
        "WCC_cmdLayerPanelCommandClick": 20042,
        "WCC_cmdLayerPanelShiftClick": 20043,
        "WCC_cmdLayerPanelEnterHover": 20044,
        "WCC_cmdLayerPanelCancelHover": 20045,
        "WCC_cmdCurrentPageBackground": 20046,
        "WCC_cmdLayerPanelEnterArea": 20047,
        "WCC_cmdLayerPanelExpandClick": 20048,
        "WCC_cmdLayerPanelOptionExpandClick": 20049,
        "WCC_cmdLayerPanelUpdateVisible": 20050,
        "WCC_cmdLayerPanelUpdateLock": 20051,
        "WCC_cmdLayerPanelCollapse": 20052,
        "WCC_cmdGetContainerIdByIndent": 20053,
        "WCC_cmdDragSelectionToNodeIndent": 20054,
        "WCC_cmdUpdateDocReadonly": 20055,
        "WCC_cmdToggleShownForSelectedNodes": 20056,
        "WCC_cmdToggleLockedForSelectedNodes": 20057,
        "WCC_cmdUpdateCursorWithinCanvas": 20058,
        "WCC_tabVisible": 20059,
        "WCC_tabHidden": 20060,
        "WCC_cmdUpdateColorMode": 20061,
        "WCC_cmdRemoveFillForSelectedNodes": 20062,
        "WCC_cmdRemoveStrokeForSelectedNodes": 20063,
        "WCC_cmdSwapFillStrokeForSelectedNodes": 20064,
        "WCC_cmdUpdateInspectState": 20065,
        "WCC_onBatchOperation": 20068,
        "WCC_onBatchOperationAck": 20069,
        "WCC_downloadImageRet": 20070,
        "WCC_cmdArrangeAlign": 20071,
        "WCC_cmdArrangeDistribute": 20072,
        "WCC_cmdArrangePack": 20073,
        "WCC_cmdBatchUpdateBlendMode": 20074,
        "WCC_cmdBatchUpdateOpacity": 20075,
        "WCC_cmdAddExportSettingsToSelection": 20076,
        "WCC_cmdDeleteExportSettingsToSelection": 20077,
        "WCC_cmdUpdateExportSettingsToSelection": 20078,
        "WCC_cmdBatchUpdateVisible": 20079,
        "WCC_cmdArrangeRoundToPixel": 20080,
        "WCC_cmdSetThumbnailId": 20081,
        "WCC_cmdToggleSelectionMaskOutline": 20082,
        "WCC_cmdPlaceImage": 20085,
        "WCC_cmdDropImage": 20086,
        "WCC_cmdTextUpdateTextAlignHorizontal": 20087,
        "WCC_cmdTextUpdateTextAlignVertical": 20088,
        "WCC_cmdTextUpdateAutoResize": 20089,
        "WCC_cmdTextUpdateParagraphIndent": 20090,
        "WCC_cmdTextUpdateParagraphSpacing": 20091,
        "WCC_cmdUpdateFontName": 20092,
        "WCC_cmdUpdateFontSize": 20093,
        "WCC_cmdUpdateTextDecoration": 20094,
        "WCC_cmdUpdateLineHeight": 20095,
        "WCC_cmdUpdateLetterSpacing": 20096,
        "WCC_cmdUpdateTextCase": 20097,
        "WCC_cmdUpdateTextStyleId": 20099,
        "WCC_cmdDetachTextStyleId": 20100,
        "WCC_cmdToggleSelectionFontBold": 20101,
        "WCC_cmdToggleSelectionFontItalic": 20102,
        "WCC_cmdIncreaseSelectionFontWeight": 20103,
        "WCC_cmdDecreaseSelectionFontWeight": 20104,
        "WCC_cmdIncreaseSelectionFontSize": 20105,
        "WCC_cmdDecreaseSelectionFontSize": 20106,
        "WCC_cmdIncreaseSelectionLineHeight": 20107,
        "WCC_cmdDecreaseSelectionLineHeight": 20108,
        "WCC_cmdIncreaseSelectionLetterSpacing": 20109,
        "WCC_cmdDecreaseSelectionLetterSpacing": 20110,
        "WCC_cmdToggleSelectionDeleteLine": 20111,
        "WCC_cmdToggleSelectionUnderLine": 20112,
        "WCC_cmdUpdateSelectionFillPaints": 20113,
        "WCC_cmdUpdateSelectionFillStyleId": 20114,
        "WCC_cmdDetachSelectionFillStyleId": 20115,
        "WCC_cmdDeleteSelectionFillStyleId": 20116,
        "WCC_cmdUpdateSelectionFillPaintsForEmpty": 20117,
        "WCC_cmdUpdateSelectionFillPaintForSameOrAdd": 20118,
        "WCC_cmdDeleteTextStyleNode": 20119,
        "WCC_getLastOperationIndex": 20126,
        "WCC_onDocument": 20128,
        "WCC_emDocumentUpdateNodeAttr_v2": 20132,
        "WCC_onAnimationFrame": 20142,
        "WCC_dispatchMouseEvent": 20143,
        "WCC_dispatchWheelEvent": 20144,
        "WCC_resizeCanvas": 20145,
        "WCC_cmdTextInput": 20147,
        "WCC_cmdTextEndEditing": 20151,
        "WCC_cmdTextKeyboardOperation": 20152,
        "WCC_cmdCurrentPageSetSelection": 20153,
        "WCC_groupSelection": 20154,
        "WCC_ungroupSelection": 20155,
        "WCC_convertFrameToGroup": 20156,
        "WCC_frameResizeToFit": 20157,
        "WCC_convertGroupToFrame": 20158,
        "WCC_frameSelection": 20159,
        "WCC_compoundAdjustLayout": 20161,
        "WCC_boolSelection": 20162,
        "WCC_cmdMoveViewportWhenOutsideCanvas": 20164,
        "WCC_cmdScaleViewportByWheel": 20165,
        "WCC_cmdDeletePage": 20166,
        "WCC_cmdSetCurrentPage": 20167,
        "WCC_cmdDuplicatePage": 20168,
        "WCC_cmdUpdatePageName": 20169,
        "WCC_cmdPageEnterEditing": 20170,
        "WCC_cmdPageCancelEditing": 20171,
        "WCC_cmdChangeScaleToolPanel": 20173,
        "WCC_cmdApplyAIRecognizeResult": 20174,
        "WCC_componentSelection": 20175,
        "WCC_componentSetSelection": 20176,
        "WCC_batchComponentSelection": 20177,
        "WCC_addVariantForSelectedComponent": 20178,
        "WCC_detachSelectedInstance": 20179,
        "WCC_cmdCreatePage": 20180,
        "WCC_cmdPageUp": 20181,
        "WCC_cmdPageDown": 20182,
        "WCC_cmdCanvasClipboardCopyProperties": 20183,
        "WCC_cmdCanvasClipboardPasteProperties": 20184,
        "WCC_cmdApplyAIRecognizeReplaceableResult": 20185,
        "WCC_cmdEditImageScaleMode": 20189,
        "WCC_cmdCancelPlacingImage": 20190,
        "WCC_cmdPlaceAllImages": 20191,
        "WCC_getLineHeight": 20192,
        "WCC_cmdStartEditingImage": 20193,
        "WCC_cmdEndEditingImage": 20194,
        "WCC_cmdVectorClearEditing": 20197,
        "WCC_loseGpu": 20208,
        "WCC_restoreGpu": 20209,
        "WCC_cmdStartEditingGradient": 20211,
        "WCC_cmdEndEditingGradient": 20212,
        "WCC_cmdToggleMaskSelection": 20213,
        "WCC_cmdFlatten": 20218,
        "WCC_cmdOutlineStroke": 20220,
        "WCC_cmdReplaceCurrentPageMissingFonts": 20222,
        "WCC_cmdInspectMissingFont": 20223,
        "WCC_cmdSetViewport": 20225,
        "WCC_cmdMoveViewport": 20226,
        "WCC_cmdZoomAtViewportCenter": 20227,
        "WCC_cmdZoomInByLevelAtViewportCenter": 20228,
        "WCC_cmdZoomOutByLevelAtViewportCenter": 20229,
        "WCC_cmdZoomToFitNodesAndScreen": 20232,
        "WCC_cmdZoomToSelection": 20233,
        "WCC_cmdZoomToAll": 20234,
        "WCC_confirmTitleInput": 20237,
        "WCC_toggleShowComment": 20241,
        "WCC_syncCommentsMeta": 20248,
        "WCC_mouseEnterComment": 20251,
        "WCC_mouseLeaveComment": 20252,
        "WCC_clickedOnComment": 20253,
        "WCC_finishMovingComment": 20254,
        "WCC_submitDraftComment": 20255,
        "WCC_cmdUpdateSelectionBaseAttribut": 20258,
        "WCC_cmdBatchUpdateBaseAttribute": 20259,
        "WCC_cmdSelectionColorsInspectColor": 20260,
        "WCC_cmdSelectionColorsInspectStyle": 20261,
        "WCC_cmdSelectionColorsReplaceColor": 20262,
        "WCC_cmdSelectionColorsReplaceColorWithStyle": 20263,
        "WCC_cmdSelectionColorsReplaceStyle": 20264,
        "WCC_cmdSelectionColorsDetachStyle": 20265,
        "WCC_cmdUpdateFreezeSelectionPaint": 20266,
        "WCC_cmdUpdateInfiniteSelectionPaint": 20267,
        "WCC_cmdAddStrokePaintsForSelection": 20268,
        "WCC_cmdAddStrokeStyleIdForSelection": 20269,
        "WCC_cmdUpdateUsableFontInfos": 20271,
        "WCC_cmdApplyPickedColor": 20275,
        "WCC_deactiveComment": 20276,
        "WCC_removeDraftComment": 20277,
        "WCC_cmdUpdateSelectionCornerRadius": 20278,
        "WCC_cmdUpdateSelectionIndependentCornerRadius": 20279,
        "WCC_cmdUpdateSelectionCornerSmoothing": 20280,
        "WCC_cmdExpandSelectionIndependentCornerRadius": 20281,
        "WCC_cmdUpdateSelectionConstraints": 20282,
        "WCC_onClipboardReadProxyFiles": 20283,
        "WCC_setStrokeWeightForSelection": 20285,
        "WCC_setIndependentStrokeTypeForSelection": 20286,
        "WCC_setDocumentWatchpoints": 20287,
        "WCC_cmdUpdateLibraryContentVO": 20288,
        "WCC_cmdUpdateOthersRemoteLibraryChanges": 20290,
        "WCC_buildLibraryStyleUpdateVO": 20293,
        "WCC_buildLibraryComponentUpdateVO": 20294,
        "WCC_generateLibraryPublishMixinThumbnail": 20295,
        "WCC_cmdPublishHidden": 20296,
        "WCC_cmdAimLibraryNode": 20297,
        "WCC_cmdLibraryPublishModalPublishChecked": 20298,
        "WCC_cmdEndLibraryPublishModalPublishChecked": 20299,
        "WCC_cmdOpenLibraryPublishModal": 20300,
        "WCC_cmdCloseLibraryPublishModal": 20301,
        "WCC_cmdUpdateLibraryPublishModalScrollableSize": 20302,
        "WCC_cmdUpdateLibraryPublishModalFilterText": 20303,
        "WCC_cmdUpdateLibraryPublishModalDescription": 20304,
        "WCC_cmdUpdateLibraryPublishModalChangeChecked": 20305,
        "WCC_cmdUpdateLibraryPublishModalAllChangesChecked": 20306,
        "WCC_updateLocalStyleSelection": 20307,
        "WCC_updateLocalStyleEditingStyleId": 20308,
        "WCC_updateLocalStyleEditingName": 20309,
        "WCC_updateLocalStyleNodeName": 20310,
        "WCC_updateLocalStyleFolderName": 20311,
        "WCC_groupLocalStyleSelection": 20312,
        "WCC_ungroupLocalStyleSelection": 20313,
        "WCC_deleteLocalStyleSelection": 20314,
        "WCC_updateTextStyleNodeName": 20315,
        "WCC_updateTextStyleNodeDescription": 20316,
        "WCC_updateTextStyleNodeFontSize": 20317,
        "WCC_updateTextStyleNodeFontName": 20318,
        "WCC_updateTextStyleNodeLineHeight": 20319,
        "WCC_updateTextStyleNodeLetterSpacing": 20320,
        "WCC_updateTextStyleNodeParagraphSpacing": 20321,
        "WCC_updateTextStyleNodeTextDecoration": 20322,
        "WCC_updatePaintStyleNodeName": 20323,
        "WCC_updatePaintStyleNodeDescription": 20324,
        "WCC_updatePaintStyleNodePaints": 20325,
        "WCC_updateEffectStyleNodeName": 20326,
        "WCC_updateEffectStyleNodeDescription": 20327,
        "WCC_updateEffectStyleNodeEffects": 20328,
        "WCC_cmdUpdatePopupState": 20329,
        "WCC_cmdUpdateLibraryPublishModalScrollTop": 20331,
        "WCC_cmdChangeLibraryPublishModalControlExpand": 20332,
        "WCC_clickedOnDraftComment": 20333,
        "WCC_cmdUpdateAttrPanelStyleEditorState": 20334,
        "WCC_cmdUpdateLibraryAssetPanelFilterText": 20335,
        "WCC_cmdUpdateLibraryAssetPanelDisplayMode": 20336,
        "WCC_cmdUpdateLibraryAssetPanelScrollTop": 20338,
        "WCC_cmdDropComponentV2": 20339,
        "WCC_cmdSetDisplayPanels": 20340,
        "WCC_cmdSelectDisplayPanel": 20341,
        "WCC_cmdUpdateLibraryAssetPanelScrollableSize": 20342,
        "WCC_cmdSetStrokeCapForSelection": 20343,
        "WCC_cmdUpdateSubscribedLibraryIds": 20344,
        "WCC_cmdGetSelectionNodeIds": 20345,
        "WCC_cmdSelectInverse": 20347,
        "WCC_cmdBatchUpdateNodeKeys": 20348,
        "WCC_cmdViewLibraryUpgradeNotification": 20349,
        "WCC_cmdDismissLibraryUpgradeNotification": 20350,
        "WCC_cmdResetSelectionNodeInInstance": 20352,
        "WCC_cmdSetSelectedReveredIndexes": 20356,
        "WCC_cmdClearSelectedReversedIndexes": 20357,
        "WCC_cmdGoToSelectedInstanceMainComponent": 20358,
        "WCC_cmdPushSelectedInstanceChangesToMainComponent": 20359,
        "WCC_cmdUpdateVectorPanelX": 20360,
        "WCC_cmdUpdateVectorPanelY": 20361,
        "WCC_cmdUpdateVectorPanelHandleMirror": 20362,
        "WCC_cmdUpdateVectorPanelCornerRadius": 20363,
        "WCC_commitSynergyStore": 20364,
        "WCC_copyCommentLink": 20365,
        "WCC_cmdDeleteStyleNode": 20367,
        "WCC_cmdUpdateSelectedGradientColorStopIndex": 20368,
        "WCC_cmdUpdateSelectionStrokePaints": 20369,
        "WCC_cmdUpdateSelectionStrokeAlign": 20370,
        "WCC_cmdUpdateSelectionStrokeDashCap": 20371,
        "WCC_cmdUpdateSelectionStrokeDashGap": 20372,
        "WCC_cmdUpdateSelectionStrokeDashLen": 20373,
        "WCC_cmdUpdateSelectionStrokeJoin": 20374,
        "WCC_cmdUpdateSelectionStrokeMiterAngle": 20375,
        "WCC_cmdUpdateSelectionStrokeMode": 20376,
        "WCC_cmdSwitchStrokeEndPointCap": 20377,
        "WCC_cmdUpdateSelectionClipsContent": 20378,
        "WCC_cmdPublishSelectedNodes": 20379,
        "WCC_cmdUpdateSelectedNodeDescription": 20380,
        "WCC_cmdUpdateSelectionConstrainProportions": 20381,
        "WCC_cmdLocateNodeInDocumentForDebugOnly": 20382,
        "WCC_cmdResetSelectedInstancesByType": 20383,
        "WCC_cmdUpdateSelectionFrameDirection": 20384,
        "WCC_importNodeFromLibray": 20385,
        "WCC_applyEffectStyleForSelection": 20386,
        "WCC_removeEffectStyleForSelection": 20387,
        "WCC_detachEffectStyleForSelection": 20388,
        "WCC_updateEffectsForSelection": 20389,
        "WCC_updateSelectedComponentSetProps": 20390,
        "WCC_updateSelectedVariantsProps": 20391,
        "WCC_selectConflictVariants": 20392,
        "WCC_replaceSelectedInstanceMainComp": 20393,
        "WCC_toggleReplaceInstancePanel": 20394,
        "WCC_updateShowSlice": 20396,
        "WCC_cmdUpdateSelectionPointCount": 20398,
        "WCC_cmdBatchUpdatePointCount": 20399,
        "WCC_cmdUpdateSelectionInnerRadius": 20400,
        "WCC_cmdBatchUpdateInnerRadius": 20401,
        "WCC_cmdUpdateCanvasRightClickMenuVisible": 20402,
        "WCC_cmdUpdateMainMenuVisible": 20403,
        "WCC_cmdUpdateTopExtendBoolMenuVisible": 20404,
        "WCC_cmdUpdateSelectionArcStartingAngle": 20405,
        "WCC_cmdBatchUpdateArcStartingAngle": 20406,
        "WCC_cmdUpdateSelectionArcSweep": 20407,
        "WCC_cmdBatchUpdateArcSweep": 20408,
        "WCC_cmdFlipVertical": 20409,
        "WCC_cmdFlipHorizontal": 20410,
        "WCC_cmdShowStartEditingHandlerForPaintStyle": 20411,
        "WCC_cmdResizeViewport": 20414,
        "WCC_cmdUpdateUserConfig": 20415,
        "WCC_cmdExportImageToCanvas": 20416,
        "WCC_cmdExportPngToData": 20417,
        "WCC_cmdExportSvg": 20418,
        "WCC_emDocumentGetNode": 20423,
        "WCC_emDocumentGetNodeAttr": 20424,
        "WCC_cmdEnterToggleEditingState": 20425,
        "WCC_cmdRecoverComponent": 20427,
        "WCC_cmdExportImageToBinaryData": 20431,
        "WCC_cmdLoadDocument": 20434,
        "WCC_checkUnderEditorMode": 20438,
        "WCC_toggleShowRuler": 20439,
        "WCC_cmdRename": 20443,
        "WCC_cmdDragLocalSelectionStyleCommand": 20445,
        "WCC_cmdRemoveSelectedNodes": 20446,
        "WCC_cmdSearchReplaceInstance": 20447,
        "WCC_cmdChangeLibraryMainModalRoutePath": 20448,
        "WCC_cmdGetSingleMissLibraryContent": 20449,
        "WCC_cmdAddImageSource": 20450,
        "WCC_cmdToggleSelectionChildren": 20453,
        "WCC_cmdToggleSelectionParent": 20454,
        "WCC_cmdToggleSelectionNodes": 20456,
        "WCC_cmdSelectionAllNodes": 20457,
        "WCC_cmdZoomToNextFrame": 20458,
        "WCC_cmdZoomToPrevFrame": 20459,
        "WCC_cmdViewNextFrame": 20460,
        "WCC_cmdViewPrevFrame": 20461,
        "WCC_cmdUpdateInspectCodeType": 20462,
        "WCC_cmdSelectSidebarPanel": 20463,
        "WCC_cmdCloseMultipleRenameModal": 20464,
        "WCC_cmdSetMultipleRenameParams": 20465,
        "WCC_cmdCreateFrameTemplateCommand": 20466,
        "WCC_cmdUpdateFrameTemplateCommand": 20467,
        "WCC_cmdMultipleRename": 20468,
        "WCC_exportDocumentJSON": 20473,
        "WCC_loadFontInJs": 20475,
        "WCC_skewSelectedNodes": 20476,
        "WCC_dispatchKeyboardEvent": 20477,
        "WCC_triggerAllKeyUpBindings": 20478,
        "WCC_cmdSetMirrorNode": 20479,
        "WCC_toggleFullscreen": 20482,
        "WCC_cmdSetCurrentUserActiveState": 20483,
        "WCC_downloadAllFailedImageRet": 20485,
        "WCC_getUsedMemory": 20486,
        "WCC_cmdUpdateMouseCameraPosition": 20488,
        "WCC_cmdUpdateCoactorObserving": 20489,
        "WCC_cmdApplyArcTextToSelection": 20490,
        "WCC_cmdGetPagesCount": 20491,
        "WCC_cmdPreExport": 20495,
        "WCC_toggleShowSidebar": 20496,
        "WCC_setReleaseVersion": 20498,
        "WCC_exportSynergyDocument": 20500,
        "WCC_onBridgeStatusChange": 20501,
        "WCC_onDocIdChange": 20502,
        "WCC_onUserIdChange": 20503,
        "WCC_onSessionIdChange": 20504,
        "WCC_onUserRoleChange": 20505,
        "WCC_onMembershipChange": 20506,
        "WCC_onSyncUserProperties": 20507,
        "WCC_onUserEnter": 20508,
        "WCC_onUserQuit": 20509,
        "WCC_looperApply": 20510,
        "WCC_updateActivatedPluginType": 20514,
        "WCC_updatePreviewHash": 20515,
        "WCC_getCurrentSchemaVersion": 20516,
        "WCC_resetGeometry": 20517,
        "WCC_exportFigJson": 20518,
        "WCC_printUsage": 20519,
        "WCC_MoveSelectionNodesFrontOrBackCommand": 20520,
        "WCC_cmdUndoRedo": 20521,
        "WCC_cmdUpdateLibraryReplaceModalScrollTop": 20522,
        "WCC_cmdUpdateLibraryReplaceModalScrollableSize": 20523,
        "WCC_cmdOpenLibraryReplaceModal": 20524,
        "WCC_cmdCloseLibraryReplaceModal": 20525,
        "WCC_cmdUpdateLibraryReplaceModalTargetLibrary": 20526,
        "WCC_cmdUpdateLibraryReplaceModalChecked": 20527,
        "WCC_cmdOpenLibraryDetailPage": 20528,
        "WCC_cmdStartLibraryReplace": 20529,
        "WCC_cmdReplaceLibrary": 20530,
        "WCC_cmdMoveSelectionForwardOrBackward": 20531,
        "WCC_cmdExportCanvasPngToData": 20532,
        "WCC_cmdAeRasterization": 20533,
        "WCC_cmdAeExport": 20534,
        "WCC_cmdTryExitScaleToolOnInputEntered": 20535,
        "WCC_rotateCopyStartPreview": 20536,
        "WCC_rotateCopyPreview": 20537,
        "WCC_showMarkInfo": 20538,
        "WCC_getAIComponentIdMap": 20540,
        "WCC_cmdPublishMovedComponentNodes": 20541,
        "WCC_cmdDismissComponentMoveNotification": 20542,
        "WCC_cmdHandleNeverMovableNodes": 20543,
        "WCC_getCoverImageData": 20544,
        "WCC_getPrototypeCoverImageData": 20545,
        "WCC_getPageWorldBounds": 20546,
        "WCC_getAiCopilotInfo": 20547,
        "WCC_applyAiCopilot": 20548,
        "WCC_cleanAiCopilotPreviewNode": 20549,
        "WCC_getImageOfFigNode": 20550,
        "WCC_toggleShowShortcutPanel": 20551,
        "WCC_startMovingComment": 20552,
        "WCC_movingComment": 20553,
        "WCC_clickCommentCluster": 20554,
        "WCC_setViewportByAnimationKeyframe": 20555,
        "WCC_updateIsViewportZooming": 20556,
        "WCC_cmdUpdateDevModeInspectType": 20557,
        "WCC_createTextStyleNode": 20558,
        "WCC_createPaintStyleNode": 20559,
        "WCC_createEffectStyleNode": 20560,
        "WCC_cmdUpdateDevModeInspectCodeType": 20561,
        "WCC_loadFont": 20562,
        "WCC_setNotificationMovedComponentStyleNodeIds": 20563,
        "WCC_cmdToggleShowMultiplayerCursor": 20565,
        "WCC_updateFromCreatingFile": 20566,
        "WCC_cmdExportPDF": 20567,
        "WCC_cmdUpdateHyperlink": 20568,
        "WCC_cmdDetachHyperlink": 20569,
        "WCC_cmdChangeSmartSelectionHorizontal": 20573,
        "WCC_cmdChangeSmartSelectionVertical": 20574,
        "WCC_openHyperlinkNode": 20575,
        "WCC_startHyperlinkEditing": 20576,
        "WCC_endHyperlinkEditing": 20577,
        "WCC_cancelHyperlinkEditing": 20578,
        "WCC_cmdEnterAiLayout": 20579,
        "WCC_cmdEnableAiPowered": 20580,
        "WCC_cmdExitAiLayout": 20581,
        "WCC_cmdSaveExitAiLayout": 20582,
        "WCC_startHoveredHyperlinkEditing": 20583,
        "WCC_updateCopilotCursor": 20587,
        "WCC_clearHoverPopupHyperlink": 20588,
        "WCC_makeHoverPopupHyperlinkActive": 20589,
        "WCC_cmdSetComponentThumbnailDragging": 20592,
        "WCC_toggleShowUsedMemory": 20593,
        "WCC_enterHistoryMode": 20594,
        "WCC_leaveHistoryMode": 20595,
        "WCC_checkCanvasRightMenuOpenable": 20596,
        "WCC_onBatchOperationsUnderHistoryMode": 20598,
        "WCC_cmdUnlockAiLayout": 20599,
        "WCC_reorderSelectedComponentSetPropOptions": 20600,
        "WCC_cmdAddLayoutGridForSelection": 20602,
        "WCC_historyModeReloadFailedResources": 20603,
        "WCC_getCurrentPageId": 20604,
        "WCC_resetSelectionAndHistoryVersionId": 20605,
        "WCC_cmdSetCurrentPageInHistoryWasm": 20606,
        "WCC_cmdShouldMoveViewportWhenOutsideCanvas": 20607,
        "WCC_cmdUpdateLayoutGridsForSelection": 20608,
        "WCC_cmdUpdateSelectedVectorNetworkRegion": 20609,
        "WCC_getDocReadonly": 20610,
        "WCC_onMicroTask": 20611,
        "WCC_cmdGetBatchExportConfigs": 20612,
        "WCC_resetWasmStatus": 20613,
        "WCC_getOriginWasmStatus": 20614,
        "WCC_cmdGetClipboardStyleNodeCount": 20615,
        "WCC_cmdCloseBackToInstanceToast": 20617,
        "WCC_cmdOnClickBackToInstanceToast": 20618,
        "WCC_exportDocument": 20622,
        "WCC_cmdUpdateLayotuGridStyleForSelectionForWasm": 20624,
        "WCC_cmdDetachLayotuGridStyleForSelectionForWasm": 20625,
        "WCC_cmdRemoveLayotuGridStyleForSelectionForWasm": 20626,
        "WCC_setDevicePixelRatio": 20627,
        "WCC_cmdUpdateLayoutGridStyleNodeDescription": 20628,
        "WCC_cmdUpdateLayoutGridStyleNodeName": 20629,
        "WCC_toggleEnabledLayoutGrid": 20630,
        "WCC_cmdChangeTextSearch": 20631,
        "WCC_cmdUpdateLayoutGridStyleLayoutGrids": 20632,
        "WCC_cmdDeleteLayoutGridStyleNode": 20633,
        "WCC_cmdAppendInuseOtherDocStyleNodes2StyleContainer": 20634,
        "WCC_cmdRenderCursor": 20635,
        "WCC_cmdChangePlainTextCommand": 20636,
        "WCC_cmdChangeOrderedTextListCommand": 20637,
        "WCC_cmdChangeUnorderedTextListCommand": 20638,
        "WCC_insertPageBefore": 20639,
        "WCC_insertPageAfter": 20640,
        "WCC_NodePreviewSubscribe": 20641,
        "WCC_NodePreviewUnsubscribe": 20642,
        "WCC_cmdBatchUpgradePublishMixinsVersionV3": 20643,
        "WCC_cmdGetAiMarkInfo": 20644,
        "WCC_cmdGetSearchedSortedList": 20645,
        "WCC_cmdCreateLayotuGridStyleNode": 20647,
        "WCC_onUserSynergyRoleChange": 20648,
        "WCC_cmdPrepareExportImage": 20649,
        "WCC_cmdToggleRightClickMenu": 20651,
        "WCC_getUndoStatus": 20653,
        "WCC_cmdUpdateTextTruncation": 20654,
        "WCC_getFocusView": 20655,
        "WCC_cmdUpdateMaxLines": 20656,
        "WCC_cmdUpdateCanvasSearchParam": 20657,
        "WCC_updateDocumentColorProfile": 20658,
        "WCC_cmdResetLibraryAssetPanelExpandKeys": 20659,
        "WCC_cmdFillNodesWithImages": 20660,
        "WCC_cmdDropImageToCenter": 20661,
        "WCC_cmdCanvasSearchPageItemClick": 20664,
        "WCC_cmdCanvasSearchLayerItemClick": 20665,
        "WCC_cmdCanvasSearchLayerItemShiftClick": 20666,
        "WCC_cmdCanvasSearchLayerItemCommandClick": 20667,
        "WCC_cmdCanvasSearchLayerItemEnterHover": 20668,
        "WCC_cmdCanvasSearchLayerItemLeaveHover": 20669,
        "WCC_cmdSyncDocumentColorProfileMeta": 20670,
        "WCC_cmdGetImageDownloadSuccessRatio": 20671,
        "WCC_cmdCanvasSearchTogglePageExpand": 20672,
        "WCC_cmdCanvasSearchNextLayerItem": 20673,
        "WCC_cmdCanvasSearchPrevLayerItem": 20674,
        "WCC_cmdApplyCreateStyle": 20675,
        "WCC_cmdCancelCreateStyle": 20676,
        "WCC_cmdAiAlign": 20677,
        "WCC_cmdMoveNodesToOtherPage": 20678,
        "WCC_getVertexCameraPosition": 20679,
        "WCC_getTangentCameraPosition": 20680,
        "WCC_cmdStartCanvasSearchParam": 20681,
        "WCC_cmdExitCanvasSearchParam": 20682,
        "WCC_debouncedShowGuidelineTimerCallback": 20683,
        "WCC_removeCopiedNodesTimerCallback": 20684,
        "WCC_debouncedMovingBoundsPositionHoldTimerCallback": 20685,
        "WCC_cmdExportEachAsPDFPage": 20686,
        "WCC_cmdGetTopFrameWhichSortByArrange": 20687,
        "WCC_triggerImagePaintPresenter": 20688,
        "WCC_emojiTimerCallback": 20689,
        "WCC_toggleTextCursorSpriteVisible": 20690,
        "WCC_reloadImageTimerCallback": 20691,
        "WCC_cmdUpdateLayerPanelClientHeight": 20692,
        "WCC_cmdCommitUndo": 20693,
        "WCC_cmdAiCreateAutoLayout": 20694,
        "WCC_cmdCommitUndoOrSquash": 20695,
        "WCC_cmdCanvasSearchLayerItemOnShiftEnter": 20696,
        "WCC_cmdCanvasSearchLayerItemOnEnter": 20697,
        "WCC_cmdCanvasSearchEnterTempMultiSelectionMode": 20698,
        "WCC_cmdCanvasSearchLeaveTempMultiSelectionMode": 20699,
        "WCC_getComputedPropsReadCountAndRestCounter": 20700,
        "WCC_cmdPasteProtoAsNode": 20701,
        "WCC_getSingleViewStateValue": 20702,
        "WCC_disableSingleViewState": 20703,
        "WCC_cmdUpdateCanvasSearchResultItemHeight": 20704,
        "WCC_cmdUpdateCanvasSearchResultViewportScrollTop": 20705,
        "WCC_cmdUpdateCanvasSearchResultViewportHeight": 20706,
        "WCC_cmdCanvasSearchResultScrollToLayerItem": 20707,
        "WCC_cmdStartCanvasSearchAndReplace": 20708,
        "WCC_enableSingleViewState": 20709,
        "WCC_switchEditorType": 20711,
        "WCC_cmdCanvasSearchReplaceSelected": 20712,
        "WCC_cmdCanvasSearchReplaceAll": 20713,
        "WCC_cmdAiLibraryMaintain": 20714,
        "WCC_cmdCanvasSearchSelectAll": 20715,
        "WCC_toggleSystemMetric": 20716,
        "WCC_checkContextLost": 20717,
        "WCC_cmdCanvasSearchDeleteSelected": 20718,
        "WCC_cmdSetDevModeDisplayPanels": 20719,
        "WCC_updateDevModeInspectExportConfig": 20722,
        "WCC_cmdDevModeLayerPanelLeftClick": 20724,
        "WCC_devModeExportSelectPresetCodeType": 20725,
        "WCC_devModeExportSelectFileType": 20726,
        "WCC_devModeExportChangeScale": 20727,
        "WCC_devModeDeleteAllExportSettingsToSelection": 20728,
        "WCC_cmdUpdateDevModeCodeConfig": 20729,
        "WCC_cmdDevModeLayerPanelCollapseAllNodes": 20730,
        "WCC_devModeLayerPanelEnterHover": 20731,
        "WCC_devModeLayerPanelCancelHover": 20732,
        "WCC_cmdDevModeLayerPanelRightClick": 20733,
        "WCC_cmdDevModeLayerPanelExpandAllChildren": 20734,
        "WCC_cmdDevModeLayerPanelCollapseAllChildren": 20735,
        "WCC_cmdCandidateComponentConvert": 20736,
        "WCC_cmdSwitchDeliveryUnitByIndex": 20737,
        "WCC_cmdBatchUpdateAutoLayoutMinSizeAndMaxSize": 20738,
        "WCC_cmdDevModeLayerPanelMoveToSelectedNode": 20739,
        "WCC_updatePrototypeAnimationTime": 20740,
        "WCC_cmdApplyAiDuplicate": 20741,
        "WCC_cmdUpdateDevModeBatchExportPopState": 20742,
        "WCC_cmdCandidateComponentReplace": 20744,
        "WCC_cmdCandidateComponentCancelReplace": 20745,
        "WCC_cmdDevModeToggleIconAutoDetection": 20746,
        "WCC_cmdUpdateSizeHoverMenuItem": 20747,
        "WCC_cmdBuildAIComponentData": 20748,
        "WCC_changeEditorModeForDevMode": 20749,
        "WCC_cmdExportImagePixels": 20750,
        "WCC_recoverToDefaultVarient": 20751,
        "WCC_recoverComponentForSelectedInstance": 20752,
        "WCC_cmdBuildAIReplaceNodesData": 20753,
        "WCC_cmdCandidateComponentPublish": 20754,
        "WCC_cmdDevModeLayerPanelCommandClick": 20755,
        "WCC_cmdApplyAIComponentReplace": 20756,
        "WCC_cmdDevModeZoomToFitSelection": 20757,
        "WCC_cmdDevModeLayerPanelShiftClick": 20758,
        "WCC_cmdAIReplacePreviewGenerateData": 20760,
        "WCC_cmdAIReplacePreviewSnapshotCommand": 20761,
        "WCC_cmdCreateComponentPropDefine": 20762,
        "WCC_cmdApplyComponentPropRef": 20763,
        "WCC_cmdCreateAndApplyComponentProp": 20764,
        "WCC_cmdDeleteComponentPropDef": 20765,
        "WCC_cmdDetachComponentPropRef": 20766,
        "WCC_cmdUpdateDevModeExportCompress": 20767,
        "WCC_generateCandidateComponentThumbnail": 20768,
        "WCC_updateScrollBehavior": 20769,
        "WCC_updateScrollOverflow": 20770,
        "WCC_updatePrototypeDevice": 20771,
        "WCC_cmdAddPrototypeStartingPoint": 20772,
        "WCC_cmdDeletePrototypeStartingPoints": 20773,
        "WCC_cmdUpdatePrototypeStartingPointName": 20774,
        "WCC_cmdUpdatePrototypeStartingPointDescription": 20775,
        "WCC_updatePrototypeBackgroundColor": 20776,
        "WCC_cmdEditComponentProp": 20777,
        "WCC_executeRecoverToRemoteDefaultVariant": 20778,
        "WCC_importNodesFromLibray": 20780,
        "WCC_updatePrototypeFlowOrderIndex": 20781,
        "WCC_cmdClearPrototypeStartingPointEditingState": 20782,
        "WCC_generateStyleCandidateComponentThumbnail": 20783,
        "WCC_addSelectionPrototypeInteraction": 20784,
        "WCC_updateSelectionPrototypeInteraction": 20785,
        "WCC_removeSelectionPrototypeInteraction": 20786,
        "WCC_cmdUpdatePrototypeStartingPointNameById": 20787,
        "WCC_cmdDeletePrototypeStartingPointById": 20788,
        "WCC_startEditingPrototypeInteraction": 20789,
        "WCC_endEditingPrototypeInteraction": 20790,
        "WCC_beginPerview": 20792,
        "WCC_changePreviewFrameId": 20793,
        "WCC_previewServerOnQueryReply": 20794,
        "WCC_getPrototypePresentUrlOptions": 20795,
        "WCC_getDeviceOptions": 20796,
        "WCC_navigateToComponentPropOnInstance": 20797,
        "WCC_navigateToComponentPropOnComponent": 20801,
        "WCC_removeSelectionAllPrototypeInteractions": 20802,
        "WCC_runPluginScript": 20803,
        "WCC_cmdResetComponentProp": 20804,
        "WCC_updatePrototypingVisible": 20805,
        "WCC_cmdBuildAISelectedStyleNodeData": 20807,
        "WCC_cmdAIDesignLintTextStyle": 20808,
        "WCC_cmdPrintCurrentTreeRenderObject": 20809,
        "WCC_cmdPrototypeInteractionTargetEnterHover": 20810,
        "WCC_cmdPrototypeInteractionTargetCancelHover": 20811,
        "WCC_cmdSetPrototypeActionAnimationEditHistory": 20812,
        "WCC_cmdSetPrototypeAnimationTransitionTypeEditHistory": 20813,
        "WCC_cmdSetPrototypeAnimationTransitionDurationEditHistory": 20814,
        "WCC_cmdSetPrototypeAnimationEasingTypeEditHistory": 20815,
        "WCC_cmdSetPrototypeAnimationTransitionShouldSmartAnimateEditHistory": 20816,
        "WCC_cmdSetPrototypeAnimationEasingFunctionEditHistory": 20817,
        "WCC_cmdUpdateOverlayPositionType": 20818,
        "WCC_cmdUpdateOverlayBackgroundInteraction": 20819,
        "WCC_cmdUpdateOverlayBackgroundAppearance": 20820,
        "WCC_cmdFilterPageNodes": 20821,
        "WCC_cmdsetSelectionMaybeJumpPage": 20822,
        "WCC_toggleComponentPropPopup": 20823,
        "WCC_cmdGetTextStyleNodeInfoById": 20824,
        "WCC_cmdFindClosestComponentByVariant": 20825,
        "WCC_cmdSetHoverProtoTypeInteraction": 20826,
        "WCC_cmdClearHoverProtoTypeInteraction": 20827,
        "WCC_updateSelectedComponentName": 20828,
        "WCC_setomponentConfigPopup": 20829,
        "WCC_updateComponentDesc": 20830,
        "WCC_restartPresentFlow": 20831,
        "WCC_changePresentFlow": 20832,
        "WCC_cmdToggleAiLayoutEntryMenu": 20833,
        "WCC_cmdSwitchPrototypePresentShowProtoSidebar": 20834,
        "WCC_cmdAIDesignLintUpdateTextStyleId": 20835,
        "WCC_selectPresentScaling": 20836,
        "WCC_forwardPresentFrame": 20837,
        "WCC_backwardPresentFrame": 20838,
        "WCC_gcTextures": 20839,
        "WCC_delayCheckAllImagesLoaded": 20840,
        "WCC_cmdAIDesignLintPaintStyle": 20841,
        "WCC_delayedPrototypeInteractionsCallback": 20842,
        "WCC_generatePrototypeUrlForCopyFlowLink": 20843,
        "WCC_cmdGetPaintStyleNodeInfoById": 20844,
        "WCC_cmdAIDesignLintUpdatePaintStyleId": 20845,
        "WCC_cmdAIDesignLintUpdateAndZoomToSelection": 20846,
        "WCC_lowPerfResizeCanvasForPresent": 20847,
        "WCC_updateUserPlanStatus": 20848,
        "WCC_switchShowDevice": 20849,
        "WCC_generatePrototypeUrlForOpenInEditor": 20850,
        "WCC_cmdSwitchPrototypePresentHideUI": 20851,
        "WCC_onPreparedUploadTexture": 20852,
        "WCC_animatePrototypeHotspotHint": 20853,
        "WCC_printImageDiagnosis": 20854,
        "WCC_getAllLocalStyleAndComponentsCount": 20855,
        "WCC_cmdValidateAIReplaceScene": 20856,
        "WCC_restartPresentFlowFromFirstOne": 20857,
        "WCC_cmdAIDesignLintDeDuplicateNodeIds": 20858,
        "WCC_cmdCleanAIRecognizeData": 20859,
        "WCC_cmdSwitchPrototypePresentDisableDefaultKeyboardNav": 20860,
        "WCC_canOpenPresent": 20861,
        "WCC_cmdRewritePublishProperties": 20862,
        "WCC_cmdRegenerateContentHash": 20863,
        "WCC_switchPrototypeHotspotHint": 20864,
        "WCC_uploadTextureForMotiffLib": 20865,
        "WCC_cmdUpdatePrototypeSelectedInteractionKeys": 20866,
        "WCC_cmdDetachSelectedInstanceRecur": 20867,
        "WCC_cmdExportCanvasPngToBase64": 20868,
        "WCC_getAndClearRenderDurationBuckets": 20869,
        "WCC_applyAIGenCommand": 20870,
        "WCC_cmdDevModeEnterRightClickSelection": 20871,
        "WCC_cmdDevModeLeaveRightClickSelection": 20872,
        "WCC_cmdReGenerateSnapshotForSandBox": 20873,
        "WCC_cmdUpdateFontFamily": 20874,
        "WCC_hideOtherLayers": 20875,
        "WCC_getCurrentPageClipboardData": 20876,
        "WCC_setTextValueForSelected": 20877,
        "WCC_prototypeAnimationFrame": 20878,
        "WCC_createAIGenBaseFrame": 20882,
        "WCC_getBestFullFontName": 20883,
        "WCC_devModeSelectColorsPanelEnterHover": 20884,
        "WCC_onPrototypeDeviceFrameDownload": 20885,
        "WCC_updateNodeValAboutCompPropInInstance": 20886,
        "WCC_createVariantProp": 20887,
        "WCC_selectNodesBySpecificVariantValueWasmCall": 20888,
        "WCC_devModeAvailiablityVerificatoin": 20889,
        "WCC_hoverNodesBySpecificVariantValueWasmCall": 20890,
        "WCC_cmdUpdateUserConfigV2": 20891,
        "WCC_updateTextStyleNodeDetachOpsizeFromFontSizeCommand": 20892,
        "WCC_updateTextStyleNodeFontVariationCommand": 20893,
        "WCC_cmdUpdateDetachOpsizeFromFontSize": 20894,
        "WCC_cmdUpdateFontVariation": 20895,
        "WCC_PrototypeDelayedInteractionsCallback": 20896,
        "WCC_updateTextSettingTabKey": 20897,
        "WCC_isFocusViewEnabled": 20898,
        "WCC_exportToHTML": 20899,
        "WCC_updateTextStyleNodeFontFamily": 20900,
        "WCC_windowEventTrace": 20901,
        "WCC_getFullStyledTextSegments": 20902,
        "WCC_getComputedFills": 20903,
        "WCC_getComputedStrokes": 20904,
        "WCC_toggleComponentPicker": 20905,
        "WCC_validateInstanceSwapValue": 20906,
        "WCC_updateSimplifyInstancePanels": 20907,
        "WCC_loadPluginScript": 20908,
        "WCC_destroyEditor": 20909,
        "WCC_extendSimplifyInstanceRightPanel": 20910,
        "WCC_updateInstanceBubbled": 20912,
        "WCC_highlightNodesByIdsWasmCall": 20913,
        "WCC_checkTextStyleNodeMissFontInfo": 20914,
        "WCC_cmdSetPrototypeTransitionResetScrollPositionEditHistory": 20915,
        "WCC_updateSimplifyInstanceIdsSeeingAllLayers": 20916,
        "WCC_createColorVariable": 20917,
        "WCC_createVariableSet": 20918,
        "WCC_updateColorVariableValue": 20919,
        "WCC_removeVariable_deprecated": 20922,
        "WCC_removeVariableSet_deprecated": 20923,
        "WCC_destroyJSSandbox": 20924,
        "WCC_convertCompSetIdToPrimaryCompId": 20925,
        "WCC_reorderComponentPropDefs": 20926,
        "WCC_addVariableSetMode_deprecated": 20927,
        "WCC_setVariableSetModeName_deprecated": 20928,
        "WCC_deleteVariableSetMode_deprecated": 20929,
        "WCC_setVariableModeBySetForPage": 20930,
        "WCC_setVariableModeBySetForSelection": 20931,
        "WCC_resetDefaultVariableModeBySetForPage": 20932,
        "WCC_resetDefaultVariableModeBySetForSelection": 20933,
        "WCC_setAliasForVariable": 20937,
        "WCC_loadFontInJsFailed": 20938,
        "WCC_renameVariableSet_deprecated": 20939,
        "WCC_renameVariable_deprecated": 20940,
        "WCC_cmdUpdateComponentPropSelectionState": 20941,
        "WCC_onGetLoadedImageBitmapBytesCommand": 20942,
        "WCC_updateVariantPropForPublicInstance": 20943,
        "WCC_insertVariableBetween_deprecated": 20944,
        "WCC_isThisNameInvalidForCreatingVariable": 20947,
        "WCC_forceUpdateTextLayoutData": 20948,
        "WCC_buildLibraryVariableSetUpdateVO": 20949,
        "WCC_buildLibraryVariableUpdateVO": 20950,
        "WCC_cmdSelectionColorsReplaceStyleWithColor": 20951,
        "WCC_getSingleLocalVariable": 20952,
        "WCC_getSingleLocalVariableSet": 20953,
        "WCC_pluginApiDefaultVariant": 20954,
        "WCC_pluginApiSymbolOverrides": 20955,
        "WCC_pluginApiComponentPropertyDefinitions": 20956,
        "WCC_getComputedColorVariable": 20957,
        "WCC_pluginApiAddComponentProperty": 20958,
        "WCC_pluginApiDeleteComponentProperty": 20959,
        "WCC_pluginApiEditComponentProperty": 20960,
        "WCC_pluginApiResetSymbolOverrides": 20962,
        "WCC_resetGpuState": 20963,
        "WCC_updateVariableDescription": 20964,
        "WCC_markControlFontLoaded": 20965,
        "WCC_rgbaToCode": 20966,
        "WCC_readMagnifierBufferCallback": 20967,
        "WCC_duplicateVariable_deprecated": 20968,
        "WCC_batchUpdateVariableScopes": 20969,
        "WCC_batchUpdateVariablePublishHidden": 20970,
        "WCC_updateVariableCodeSyntax": 20971,
        "WCC_removeVariableCodeSyntax": 20972,
        "WCC_removeAllAutoLayout": 20973,
        "WCC_applyAISearchInsertNode": 20974,
        "WCC_renameSelectedComponentSetProp": 20975,
        "WCC_notifyCanvasSizeChanged": 20976,
        "WCC_cmdGetLatestPopupState": 20977,
        "WCC_cmdUpdateTriggerFilterWithinMotiffScope": 20978,
        "WCC_cmdUpdateMotiffScopeSettings": 20979,
        "WCC_cmdUpdateAllExportSettingsByIds": 20980,
        "WCC_cmdSelectionColorsDetachVariable": 20981,
        "WCC_cmdDetachSelectionPaintStyleIdForEnterCrop": 20982,
        "WCC_LocalVariableEditor_TogglePanel": 20983,
        "WCC_LocalVariableEditor_ToggleVariableDetailPopup": 20984,
        "WCC_LocalVariableEditor_ToggleVariableValuePopup": 20985,
        "WCC_LocalVariableEditor_CloseVariableEditingPopup": 20986,
        "WCC_LocalVariableEditor_StartRenameVariable": 20987,
        "WCC_LocalVariableEditor_AbortRenameVariable": 20988,
        "WCC_LocalVariableEditor_SubmitRenameVariable": 20989,
        "WCC_LocalVariableEditor_StartRenameCollection": 20990,
        "WCC_LocalVariableEditor_AbortRenameCollection": 20991,
        "WCC_LocalVariableEditor_SubmitRenameCollection": 20992,
        "WCC_cmdToggleMotiffScopeNodeExpanded": 20993,
        "WCC_LocalVariableEditor_SelectCollection": 20994,
        "WCC_applyHtmlAIGenCommand": 20995,
        "WCC_getAISearchNodeData": 20996,
        "WCC_LocalVariableEditor_CreateCollection": 20997,
        "WCC_LocalVariableEditor_DeleteCollection": 20998,
        "WCC_generateNewVariableNameForCollection": 20999,
        "WCC_PluginApiGetProps": 21000,
        "WCC_PluginApiSetProps": 21001,
        "WCC_pluginApiUpdateTextNodeContent": 21002,
        "WCC_pluginApiCreateNode": 21003,
        "WCC_pluginApiGroup": 21004,
        "WCC_pluginApiResizeNode": 21005,
        "WCC_pluginApiAppendChild": 21006,
        "WCC_pluginApiUpdateTextProps": 21007,
        "WCC_pluginApiRectToFrame": 21008,
        "WCC_pluginApiCreatePage": 21009,
        "WCC_pluginApiUngroup": 21010,
        "WCC_pluginApiFlatten": 21011,
        "WCC_pluginApiInsertCharacters": 21012,
        "WCC_pluginApiDeleteCharacters": 21013,
        "WCC_pluginApiFindAll": 21014,
        "WCC_pluginApiFindChildren": 21015,
        "WCC_pluginApiCreateStyleNode": 21016,
        "WCC_pluginApiSubtract": 21017,
        "WCC_pluginApiCreateInstance": 21018,
        "WCC_pluginApiGetType": 21019,
        "WCC_pluginApiGetDimensionAndPosition": 21020,
        "WCC_pluginApiSetDimensionAndPosition": 21021,
        "WCC_pluginApiGetChildren": 21022,
        "WCC_pluginApiGetSelection": 21023,
        "WCC_pluginApiSetSelection": 21024,
        "WCC_pluginApiGetParent": 21025,
        "WCC_pluginApiGetOpacity": 21026,
        "WCC_pluginApiSetOpacity": 21027,
        "WCC_pluginApiGetVisible": 21028,
        "WCC_pluginApiSetVisible": 21029,
        "WCC_pluginApiGetHyperlink": 21030,
        "WCC_pluginApiSetHyperlink": 21031,
        "WCC_pluginApiGetRelativeTransform": 21032,
        "WCC_pluginApiSetRelativeTransform": 21033,
        "WCC_pluginApiGetName": 21034,
        "WCC_pluginApiSetName": 21035,
        "WCC_pluginApiSetNameAutoRename": 21036,
        "WCC_pluginApiGetClipsContent": 21037,
        "WCC_pluginApiSetClipsContent": 21038,
        "WCC_pluginApiGetConstraints": 21039,
        "WCC_pluginApiSetConstraints": 21040,
        "WCC_pluginApiGetCurrentPage": 21041,
        "WCC_pluginApiSetCurrentPage": 21042,
        "WCC_pluginApiGetLocalGridStyles": 21043,
        "WCC_pluginApiCreateComponentFromNode": 21044,
        "WCC_pluginApiDetachInstance": 21045,
        "WCC_pluginApiShowUI": 21046,
        "WCC_closePlugin": 21047,
        "WCC_pluginPostMessageToIframe": 21048,
        "WCC_pluginPostMessageFromIframe": 21049,
        "WCC_pluginUIShow": 21050,
        "WCC_pluginUIHide": 21051,
        "WCC_pluginUIClose": 21052,
        "WCC_pluginUIResize": 21053,
        "WCC_pluginUIReposition": 21054,
        "WCC_pluginRegisterEventNotifier": 21055,
        "WCC_pluginCancelEventNotifier": 21056,
        "WCC_flushSandboxMicrotasks": 21057,
        "WCC_pluginApiRemoveNode": 21058,
        "WCC_pluginNotify": 21059,
        "WCC_pluginNotifyCallback": 21060,
        "WCC_pluginCancelNotify": 21061,
        "WCC_pluginApiCloneNodes": 21062,
        "WCC_pluginApiSetPluginData": 21063,
        "WCC_pluginApiGetPluginData": 21064,
        "WCC_pluginApiGetPluginDataKeys": 21065,
        "WCC_pluginApiGetSharedPluginData": 21066,
        "WCC_pluginApiSetSharedPluginData": 21067,
        "WCC_pluginApiGetSharedPluginDataKeys": 21068,
        "WCC_PluginApiSwapMainComponent": 21069,
        "WCC_pluginApiGetHandleMirroring": 21070,
        "WCC_pluginApiSetHandleMirroring": 21071,
        "WCC_pluginApiGetLocalPaintStyles": 21072,
        "WCC_pluginApiGetLocalTextStyles": 21073,
        "WCC_pluginApiGetLocalEffectStyles": 21074,
        "WCC_pluginApiGetSelectionColors": 21075,
        "WCC_pluginApiRescaleCommand": 21076,
        "WCC_pluginApiResizeWithoutConstraints": 21077,
        "WCC_PluginApiUnion": 21078,
        "WCC_PluginApiIntersect": 21079,
        "WCC_PluginApiExclude": 21080,
        "WCC_pluginApiFetch": 21081,
        "WCC_pluginApiFetchCallback": 21082,
        "WCC_getViewport": 21083,
        "WCC_setViewportCenter": 21084,
        "WCC_setViewportZoom": 21085,
        "WCC_scrollAndZoomIntoView": 21086,
        "WCC_pluginApiGetAsync": 21087,
        "WCC_pluginApiGetAsyncCallback": 21088,
        "WCC_pluginApiSetAsync": 21089,
        "WCC_pluginApiSetAsyncCallback": 21090,
        "WCC_pluginApiDeleteAsync": 21091,
        "WCC_pluginApiDeleteAsyncCallback": 21092,
        "WCC_pluginApiKeysAsync": 21093,
        "WCC_pluginApiKeysAsyncCallback": 21094,
        "WCC_pluginApiLoadFont": 21095,
        "WCC_pluginApiGetRangeFontSize": 21096,
        "WCC_pluginApiSetRangeFontSize": 21097,
        "WCC_pluginApiListAvailableFontsAsync": 21098,
        "WCC_pluginApiGetRangeFontName": 21099,
        "WCC_pluginApiSetRangeFontName": 21100,
        "WCC_pluginApiGetFileKey": 21101,
        "WCC_saveVersionHistoryAsync": 21102,
        "WCC_saveVersionHistoryAsyncCallback": 21103,
        "WCC_createNodeFromSvg": 21104,
        "WCC_pluginApiGetRangeTextCase": 21105,
        "WCC_pluginApiSetRangeTextCase": 21106,
        "WCC_pluginApiGetRangeTextDecoration": 21107,
        "WCC_pluginApiSetRangeTextDecoration": 21108,
        "WCC_pluginApiGetRangeLineHeight": 21109,
        "WCC_pluginApiSetRangeLineHeight": 21110,
        "WCC_pluginApiGetRangeLetterSpacing": 21111,
        "WCC_pluginApiSetRangeLetterSpacing": 21112,
        "WCC_pluginApiResolveExternalPromise": 21113,
        "WCC_pluginApiCreateExternalPromise": 21114,
        "WCC_pluginApiEventNotify": 21115,
        "WCC_pluginApiGetRangeFills": 21116,
        "WCC_pluginApiSetRangeFills": 21117,
        "WCC_pluginApiGetRangeHyperlink": 21118,
        "WCC_pluginApiSetRangeHyperlink": 21119,
        "WCC_pluginApiGetRangeFillStyleId": 21120,
        "WCC_pluginApiSetRangeFillStyleId": 21121,
        "WCC_pluginApiGetRangeTextStyleId": 21122,
        "WCC_pluginApiSetRangeTextStyleId": 21123,
        "WCC_getImageByHash": 21124,
        "WCC_getImageBytesAsync": 21125,
        "WCC_getImageSizeAsync": 21126,
        "WCC_pluginApiGetRangeAllFontNames": 21127,
        "WCC_pluginApiGetRangeFontWeight": 21128,
        "WCC_pluginApiGetRangeLineType": 21129,
        "WCC_pluginApiSetRangeLineType": 21130,
        "WCC_pluginApiFindAllCriteria": 21131,
        "WCC_pluginApiGetRangeIndentation": 21132,
        "WCC_pluginApiSetRangeIndentation": 21133,
        "WCC_pluginApiGetStyledTextSegments": 21134,
        "WCC_pluginApiCreateImage": 21135,
        "WCC_pluginApiGetPublishStatus": 21136,
        "WCC_pluginApiDropEventNotify": 21137,
        "WCC_pluginApiExportAsync": 21138,
        "WCC_pluginApiGetInstancesAsync": 21139,
        "WCC_pluginApiCombineAsVariants": 21140,
        "WCC_pluginApiGetIsExposedInstance": 21141,
        "WCC_pluginApiSetIsExposedInstance": 21142,
        "WCC_pluginApiGetExposedInstances": 21143,
        "WCC_pluginApiGetComponentPropertiesOfInstance": 21144,
        "WCC_pluginApiSetComponentPropertiesOfInstance": 21145,
        "WCC_pluginApiGetComponentPropertyReferences": 21146,
        "WCC_pluginApiSetComponentPropertyReferences": 21147,
        "WCC_pluginApiCurrentUser": 21148,
        "WCC_pluginApiIsRemoteLibraryNode": 21149,
        "WCC_pluginApiImportRemoteLibNodeByKeyAsync": 21150,
        "WCC_pluginApiImportRemoteLibNodeByKeyAsyncCallback": 21151,
        "WCC_pluginApiDropEventNeeded": 21152,
        "WCC_pluginApiSetEventNotificationStatus": 21153,
        "WCC_pluginApiDropEventPickInsertionLocation": 21154,
        "WCC_markEvalJsBegin": 21155,
        "WCC_markEvalJsEnd": 21156,
        "WCC_cmdUpdateCenterPoint": 25000,
        "WCC_toggleEnablePixelGrid": 25001,
        "WCC_toggleSnapToPixel": 25002,
        "WCC_cmdSetOperateSystem": 25003,
        "WCC_toggleInverseZoomDirection": 25004,
        "WCC_setSmallNudgeAmount": 25005,
        "WCC_setBigNudgeAmount": 25006,
        "WCC_cmdRotateCopyEndPreview": 25007,
        "WCC_cmdTidyUp": 25008,
        "WCC_getNotLoadedImageCount": 25009,
        "WCC_LocalVariableEditor_CreateVariable": 30000,
        "WCC_LocalVariableEditor_CollapseGroup": 30001,
        "WCC_LocalVariableEditor_ExpandGroup": 30002,
        "WCC_LocalVariableEditor_DirectRenameVariable": 30003,
        "WCC_checkShowRestoreComponentWarning": 30004,
        "WCC_getCurrentEditorType": 30005,
        "WCC_removeAllPrototypeInteractions": 30006,
        "WCC_renderCursorRecord": 30007,
        "WCC_searchLocateNodeWithinMotiffScope": 30008,
        "WCC_getVRAM": 30009,
        "WCC_LocalVariableEditor_CreateCollectionMode": 30010,
        "WCC_LocalVariableEditor_DeleteCollectionMode": 30011,
        "WCC_LocalVariableEditor_RenameCollectionMode": 30012,
        "WCC_LocalVariableEditor_DuplicateCollectionMode": 30013,
        "WCC_LocalVariableEditor_DeleteSelectedVariables": 30014,
        "WCC_LocalVariableEditor_DuplicateSelectedVariables": 30015,
        "WCC_LocalVariableEditor_DragSelectedVariablesCommand": 30016,
        "WCC_LocalVariableEditor_DragSelectedVariablesIntoGroupCommand": 30017,
        "WCC_LocalVariableEditor_DragGroupCommand": 30018,
        "WCC_LocalVariableEditor_AddSelectedVariablesIntoGroupCommand": 30019,
        "WCC_LocalVariableEditor_RenameGroupCommand": 30020,
        "WCC_LocalVariableEditor_DuplicateSelectedGroupsCommand": 30021,
        "WCC_LocalVariableEditor_UngroupSelectedGroupsCommand": 30022,
        "WCC_LocalVariableEditor_DeleteSelectedGroupsCommand": 30023,
        "WCC_LocalVariableEditor_ForceSelectVariableCommand": 30024,
        "WCC_LocalVariableEditor_SafeSelectVariableCommand": 30025,
        "WCC_LocalVariableEditor_MultiSelectVariableCommand": 30026,
        "WCC_LocalVariableEditor_RangeSelectVariableCommand": 30027,
        "WCC_LocalVariableEditor_ClearVariableSelectionCommand": 30028,
        "WCC_LocalVariableEditor_ForceSelectGroupCommand": 30029,
        "WCC_LocalVariableEditor_SafeSelectGroupCommand": 30030,
        "WCC_LocalVariableEditor_MultiSelectGroupCommand": 30031,
        "WCC_LocalVariableEditor_RangeSelectGroupCommand": 30032,
        "WCC_LocalVariableEditor_ClearGroupSelectionCommand": 30033,
        "WCC_setMirrorViewportScaling": 30034,
        "WCC_cmdUpdateBatchExportPopupState": 30035,
        "WCC_setActivedCommentId": 30036,
        "WCC_cmdUpdateSelectedVectorNodeStyleOverrideTable": 30037,
        "WCC_tryHoverOffTargetComment": 30038,
        "WCC_createFloatVariable": 30039,
        "WCC_updateFloatVariableValue": 30040,
        "WCC_onCompactVRAM": 30041,
        "WCC_debouncedShowAutoLayoutHandlerTimerCallback": 30042,
        "WCC_generateNewVariableNameForCollectionAndType": 30043,
        "WCC_forceRecoverFigmaComponentForSelectedInstance": 30044,
        "WCC_cmdAttachCornerRadiusFloatVar": 30045,
        "WCC_cmdDetachCornerRadiusFloatVar": 30046,
        "WCC_batchAttachAutoLayoutVar": 30047,
        "WCC_batchDetachAutoLayoutVar": 30048,
        "WCC_cmdAttachStrokeWeightFloatVar": 30049,
        "WCC_cmdDetachStrokeWeightFloatVar": 30050,
        "WCC_selectionAttachWidthHeightFloatVar": 30051,
        "WCC_selectionDetachWidthHeightFloatVar": 30052,
        "WCC_selectionAttachMinMaxWidthHeightFloatVar": 30053,
        "WCC_selectionDetachMinMaxWidthHeightFloatVar": 30054,
        "WCC_LocalVariableEditor_GetSelectionScrollIndexInTable": 30055,
        "WCC_exportComponentLibraryData": 30056,
        "WCC_exportStyleLibraryData": 30057,
        "WCC_exportVariableLibraryData": 30058,
        "WCC_exportVariableCollectionLibraryData": 30059,
        "WCC_startLibraryPublishModalPublishChecked": 30060,
        "WCC_getDocumentColorProfile": 30061,
        "WCC_applyChatBotAIGen": 30062,
        "WCC_exportHTMLForChatBot": 3006
      }
    },
    "WebSocketKickCode": {
      "values": {
        "WEB_SOCKET_KICK_CODE_DO_NOT_USE_ME_ZERO": 0,
        "ILLEGAL_REQUEST": 4500,
        "DOC_DELETED": 4501,
        "PERMISSION_LOST": 4502,
        "DOC_NOT_EXIST": 4503,
        "DECODE_ERROR": 4504,
        "UNKNOWN_MESSAGE": 4505,
        "ILLEGAL_URI": 4506,
        "RECEIVE_MESSAGE_UNDER_ERROR_STATE": 4507,
        "NO_EDIT_PERMISSION": 4508,
        "RELEASE_NOT_VALID": 4509,
        "SCHEMA_MISMATCH": 4510,
        "DOC_BLOCKING": 4511,
        "DOC_CRASHED": 4512,
        "SYNC_LOST": 4513,
        "CONNECTION_UNAUTHORIZED": 4514,
        "DOCUMENT_REVERSION": 4515
      }
    },
    "WindingRule": {
      "values": {
        "WINDING_RULE_NONZERO": 0,
        "WINDING_RULE_EVENODD": 1
      }
    },
    "ZoomToolState": {
      "values": {
        "ZOOM_TOOL_STATE_NONE": 0,
        "ZOOM_TOOL_STATE_ZOOM_IN": 1,
        "ZOOM_TOOL_STATE_ZOOM_OUT": 2
      }
    },
    "AICandidateComponentData": {
      "fields": {
        "repeats": {
          "id": 6,
          "type": "int32"
        },
        "componentTypeName": {
          "id": 5,
          "type": "string"
        },
        "unrecoverable": {
          "id": 4,
          "type": "bool"
        },
        "toReplaceId": {
          "id": 3,
          "type": "string"
        },
        "candidateId": {
          "id": 2,
          "type": "string"
        },
        "type": {
          "id": 1,
          "type": "AICandidateComponentType"
        }
      }
    },
    "AICandidateComponentReplaceCommandParam": {
      "fields": {
        "targetId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_targetId": {
          "oneof": [
            "targetId"
          ]
        }
      }
    },
    "AIChatSelectedNodes": {
      "fields": {
        "nodeInfos": {
          "id": 1,
          "rule": "repeated",
          "type": "SelectedNodeInfoForAI"
        }
      }
    },
    "AIComponentCreatedId": {
      "fields": {
        "createdComponentId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "createdCardId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_createdComponentId": {
          "oneof": [
            "createdComponentId"
          ]
        },
        "_createdCardId": {
          "oneof": [
            "createdCardId"
          ]
        }
      }
    },
    "AIDesignLintUpdateAndZoomToSelectionCommandParam": {
      "fields": {
        "nodeIds": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "AIDesignLintUpdatePaintStyleIdParam": {
      "fields": {
        "type": {
          "id": 3,
          "type": "PaintStyleMatchType"
        },
        "nodeIds": {
          "id": 2,
          "rule": "repeated",
          "type": "string"
        },
        "paintStyleId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_paintStyleId": {
          "oneof": [
            "paintStyleId"
          ]
        }
      }
    },
    "AIDesignLintUpdateTextStyleIdParam": {
      "fields": {
        "ranges": {
          "id": 2,
          "rule": "repeated",
          "type": "TextRange"
        },
        "textStyleId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_textStyleId": {
          "oneof": [
            "textStyleId"
          ]
        }
      }
    },
    "AIGenParam": {
      "fields": {
        "alreadyPlacedFrame": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "part": {
          "id": 1,
          "type": "AIGenResult"
        }
      },
      "oneofs": {
        "_alreadyPlacedFrame": {
          "oneof": [
            "alreadyPlacedFrame"
          ]
        }
      }
    },
    "AIGenResult": {
      "fields": {
        "schemaVersion": {
          "id": 9,
          "type": "int32"
        },
        "blobs": {
          "id": 8,
          "keyType": "int64",
          "type": "BlobData"
        },
        "nodes": {
          "id": 1,
          "rule": "repeated",
          "type": "SynergyNode"
        }
      }
    },
    "AIGenReturn": {
      "fields": {
        "rootFrame": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_rootFrame": {
          "oneof": [
            "rootFrame"
          ]
        }
      }
    },
    "AILayoutColorLine": {
      "fields": {
        "lineTopOffset": {
          "id": 2,
          "type": "int32"
        },
        "lineSize": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "AILibraryMaintainCommandParam": {
      "fields": {
        "currentLibraryDocId": {
          "id": 3,
          "type": "string"
        },
        "isBuildIndex": {
          "id": 2,
          "type": "bool"
        },
        "encodedData": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "AIRecognizeResult": {
      "fields": {
        "debugIconPage": {
          "id": 9,
          "type": "IconPage"
        },
        "debugTextPage": {
          "id": 7,
          "type": "TextPage"
        },
        "debugColorPage": {
          "id": 6,
          "type": "ColorPage"
        },
        "textPage": {
          "id": 3,
          "type": "TextPage"
        },
        "iconPage": {
          "id": 5,
          "type": "IconPage"
        },
        "effectPage": {
          "id": 4,
          "type": "EffectPage"
        },
        "debugEffectPage": {
          "id": 8,
          "type": "EffectPage"
        },
        "colorPage": {
          "id": 2,
          "type": "ColorPage"
        },
        "pages": {
          "id": 1,
          "rule": "repeated",
          "type": "Page"
        }
      }
    },
    "AIRecognizeResultWithEncodedNodes": {
      "fields": {
        "nodes": {
          "id": 2,
          "keyType": "string",
          "type": "string"
        },
        "blobs": {
          "id": 3,
          "keyType": "string",
          "type": "string"
        },
        "aiResult": {
          "id": 1,
          "type": "AIRecognizeResult"
        }
      }
    },
    "AIRecognizeResultWithEncodedNodesV2": {
      "fields": {
        "nodes": {
          "id": 2,
          "keyType": "string",
          "type": "BridgeProtoStringMap"
        },
        "blobs": {
          "id": 3,
          "keyType": "string",
          "type": "BridgeProtoStringMap"
        },
        "aiResult": {
          "id": 1,
          "type": "AIRecognizeResult"
        }
      }
    },
    "AIRecognizeResultWithNodes": {
      "fields": {
        "nodes": {
          "id": 2,
          "type": "string"
        },
        "result": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "AIReplacePreviewGenerateDataCommandParam": {
      "fields": {
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "AIReplacePreviewGenerateDataCommandRet": {
      "fields": {
        "protoData": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "AIReplacePreviewSnapshotCommandParam": {
      "fields": {
        "replaceData": {
          "id": 3,
          "type": "ApplyAIComponentReplaceCommandParam"
        },
        "containerId": {
          "id": 2,
          "type": "string"
        },
        "protoDataBase64": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "AIReplacePreviewSnapshotCommandRet": {
      "fields": {
        "newId": {
          "id": 2,
          "type": "string"
        },
        "imgBase64": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "AIReplaceResult": {
      "fields": {
        "icon": {
          "id": 2,
          "keyType": "string",
          "type": "string"
        },
        "text": {
          "id": 1,
          "keyType": "string",
          "type": "string"
        }
      }
    },
    "AISearchNode": {
      "fields": {
        "iconData": {
          "id": 3,
          "type": "NodeIconData"
        },
        "name": {
          "id": 2,
          "type": "string"
        },
        "isPurple": {
          "id": 4,
          "type": "bool"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "AISearchSelection": {
      "fields": {
        "node": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "AISearchNode"
        }
      },
      "oneofs": {
        "_node": {
          "oneof": [
            "node"
          ]
        }
      }
    },
    "AISelectedToPrdCommandArgs": {
      "fields": {
        "useRandomDevice": {
          "id": 2,
          "type": "bool"
        },
        "randomSeed": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "AbsoluteWorldPosition": {
      "fields": {
        "worldY": {
          "id": 2,
          "type": "float"
        },
        "worldX": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "ActiveUserProto": {
      "fields": {
        "userProfile": {
          "id": 4,
          "type": "UserProfileProto"
        },
        "properties": {
          "id": 3,
          "type": "UserPropertiesProto"
        },
        "entryTime": {
          "id": 5,
          "type": "int64"
        },
        "sessionId": {
          "id": 2,
          "type": "int32"
        },
        "userId": {
          "id": 1,
          "type": "int64"
        }
      }
    },
    "AeBlur": {
      "fields": {
        "type": {
          "id": 2,
          "type": "int32"
        },
        "radius": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "AeData": {
      "fields": {
        "hostApp": {
          "id": 42,
          "type": "string"
        },
        "aeuxVersion": {
          "id": 41,
          "type": "string"
        },
        "lineHeight": {
          "id": 40,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "fontSize": {
          "id": 37,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "stringValue": {
          "id": 34,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "kind": {
          "id": 33,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "masterId": {
          "id": 31,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "outerRad": {
          "id": 28,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "isStar": {
          "id": 27,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "fontName": {
          "id": 36,
          "options": {
            "proto3_optional": true
          },
          "type": "AeFontName"
        },
        "stroke": {
          "id": 20,
          "rule": "repeated",
          "type": "AeStroke"
        },
        "roundness": {
          "id": 18,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "layers": {
          "id": 17,
          "rule": "repeated",
          "type": "AeData"
        },
        "pointCount": {
          "id": 26,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "shouldBreakMaskChain": {
          "id": 16,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "symbolFrame": {
          "id": 32,
          "options": {
            "proto3_optional": true
          },
          "type": "AeSymbolFrame"
        },
        "bgColor": {
          "id": 12,
          "rule": "repeated",
          "type": "float"
        },
        "size": {
          "id": 11,
          "rule": "repeated",
          "type": "float"
        },
        "justification": {
          "id": 39,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "trackingAdjusted": {
          "id": 38,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "isMask": {
          "id": 9,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "id": {
          "id": 3,
          "type": "string"
        },
        "innerShadow": {
          "id": 23,
          "rule": "repeated",
          "type": "AeShadow"
        },
        "booleanOperation": {
          "id": 8,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "opacity": {
          "id": 6,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "rotation": {
          "id": 10,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "path": {
          "id": 21,
          "options": {
            "proto3_optional": true
          },
          "type": "AeProtoPath"
        },
        "innerRad": {
          "id": 29,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "flip": {
          "id": 15,
          "rule": "repeated",
          "type": "int32"
        },
        "layerCount": {
          "id": 14,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "polyScale": {
          "id": 30,
          "rule": "repeated",
          "type": "float"
        },
        "blur": {
          "id": 24,
          "rule": "repeated",
          "type": "AeBlur"
        },
        "name": {
          "id": 2,
          "type": "string"
        },
        "isVisible": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "frame": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "AeFrame"
        },
        "shadow": {
          "id": 22,
          "rule": "repeated",
          "type": "AeShadow"
        },
        "textColor": {
          "id": 35,
          "rule": "repeated",
          "type": "float"
        },
        "bgBlur": {
          "id": 25,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "fill": {
          "id": 19,
          "rule": "repeated",
          "type": "AeFill"
        },
        "blendMode": {
          "id": 7,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "rasterizeList": {
          "id": 13,
          "rule": "repeated",
          "type": "string"
        },
        "type": {
          "id": 1,
          "type": "string"
        }
      },
      "oneofs": {
        "_lineHeight": {
          "oneof": [
            "lineHeight"
          ]
        },
        "_fontSize": {
          "oneof": [
            "fontSize"
          ]
        },
        "_stringValue": {
          "oneof": [
            "stringValue"
          ]
        },
        "_kind": {
          "oneof": [
            "kind"
          ]
        },
        "_masterId": {
          "oneof": [
            "masterId"
          ]
        },
        "_outerRad": {
          "oneof": [
            "outerRad"
          ]
        },
        "_isStar": {
          "oneof": [
            "isStar"
          ]
        },
        "_fontName": {
          "oneof": [
            "fontName"
          ]
        },
        "_roundness": {
          "oneof": [
            "roundness"
          ]
        },
        "_pointCount": {
          "oneof": [
            "pointCount"
          ]
        },
        "_shouldBreakMaskChain": {
          "oneof": [
            "shouldBreakMaskChain"
          ]
        },
        "_symbolFrame": {
          "oneof": [
            "symbolFrame"
          ]
        },
        "_justification": {
          "oneof": [
            "justification"
          ]
        },
        "_trackingAdjusted": {
          "oneof": [
            "trackingAdjusted"
          ]
        },
        "_isMask": {
          "oneof": [
            "isMask"
          ]
        },
        "_booleanOperation": {
          "oneof": [
            "booleanOperation"
          ]
        },
        "_opacity": {
          "oneof": [
            "opacity"
          ]
        },
        "_rotation": {
          "oneof": [
            "rotation"
          ]
        },
        "_path": {
          "oneof": [
            "path"
          ]
        },
        "_innerRad": {
          "oneof": [
            "innerRad"
          ]
        },
        "_layerCount": {
          "oneof": [
            "layerCount"
          ]
        },
        "_isVisible": {
          "oneof": [
            "isVisible"
          ]
        },
        "_frame": {
          "oneof": [
            "frame"
          ]
        },
        "_bgBlur": {
          "oneof": [
            "bgBlur"
          ]
        },
        "_blendMode": {
          "oneof": [
            "blendMode"
          ]
        }
      }
    },
    "AeFill": {
      "fields": {
        "color": {
          "id": 9,
          "rule": "repeated",
          "type": "float"
        },
        "blendMode": {
          "id": 7,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "opacity": {
          "id": 6,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "gradient": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "AeGradient"
        },
        "gradType": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "endPoint": {
          "id": 3,
          "rule": "repeated",
          "type": "float"
        },
        "startPoint": {
          "id": 2,
          "rule": "repeated",
          "type": "float"
        },
        "enabled": {
          "id": 8,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "type": {
          "id": 1,
          "type": "string"
        }
      },
      "oneofs": {
        "_blendMode": {
          "oneof": [
            "blendMode"
          ]
        },
        "_opacity": {
          "oneof": [
            "opacity"
          ]
        },
        "_gradient": {
          "oneof": [
            "gradient"
          ]
        },
        "_gradType": {
          "oneof": [
            "gradType"
          ]
        },
        "_enabled": {
          "oneof": [
            "enabled"
          ]
        }
      }
    },
    "AeFloatList": {
      "fields": {
        "values": {
          "id": 1,
          "rule": "repeated",
          "type": "float"
        }
      }
    },
    "AeFontName": {
      "fields": {
        "style": {
          "id": 2,
          "type": "string"
        },
        "family": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "AeFrame": {
      "fields": {
        "height": {
          "id": 4,
          "type": "float"
        },
        "width": {
          "id": 3,
          "type": "float"
        },
        "y": {
          "id": 2,
          "type": "float"
        },
        "x": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "AeGradient": {
      "fields": {
        "points": {
          "id": 2,
          "rule": "repeated",
          "type": "AeGradientPoint"
        },
        "length": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "AeGradientPoint": {
      "fields": {
        "rampPoint": {
          "id": 4,
          "type": "float"
        },
        "opacity": {
          "id": 3,
          "type": "float"
        },
        "midPoint": {
          "id": 2,
          "type": "float"
        },
        "color": {
          "id": 1,
          "rule": "repeated",
          "type": "float"
        }
      }
    },
    "AeProtoPath": {
      "fields": {
        "closed": {
          "id": 5,
          "type": "bool"
        },
        "svg": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "outTangents": {
          "id": 3,
          "rule": "repeated",
          "type": "AeFloatList"
        },
        "inTangents": {
          "id": 2,
          "rule": "repeated",
          "type": "AeFloatList"
        },
        "points": {
          "id": 1,
          "rule": "repeated",
          "type": "AeFloatList"
        }
      },
      "oneofs": {
        "_svg": {
          "oneof": [
            "svg"
          ]
        }
      }
    },
    "AeShadow": {
      "fields": {
        "spread": {
          "id": 4,
          "type": "float"
        },
        "blur": {
          "id": 3,
          "type": "float"
        },
        "position": {
          "id": 2,
          "rule": "repeated",
          "type": "float"
        },
        "color": {
          "id": 1,
          "rule": "repeated",
          "type": "float"
        }
      }
    },
    "AeStroke": {
      "fields": {
        "enabled": {
          "id": 12,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "blendMode": {
          "id": 11,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "cap": {
          "id": 8,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "join": {
          "id": 9,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "width": {
          "id": 7,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "strokeDashes": {
          "id": 10,
          "rule": "repeated",
          "type": "float"
        },
        "opacity": {
          "id": 6,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "gradient": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "AeGradient"
        },
        "color": {
          "id": 13,
          "rule": "repeated",
          "type": "float"
        },
        "gradType": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "endPoint": {
          "id": 3,
          "rule": "repeated",
          "type": "float"
        },
        "startPoint": {
          "id": 2,
          "rule": "repeated",
          "type": "float"
        },
        "type": {
          "id": 1,
          "type": "string"
        }
      },
      "oneofs": {
        "_enabled": {
          "oneof": [
            "enabled"
          ]
        },
        "_blendMode": {
          "oneof": [
            "blendMode"
          ]
        },
        "_cap": {
          "oneof": [
            "cap"
          ]
        },
        "_join": {
          "oneof": [
            "join"
          ]
        },
        "_width": {
          "oneof": [
            "width"
          ]
        },
        "_opacity": {
          "oneof": [
            "opacity"
          ]
        },
        "_gradient": {
          "oneof": [
            "gradient"
          ]
        },
        "_gradType": {
          "oneof": [
            "gradType"
          ]
        }
      }
    },
    "AeSymbolFrame": {
      "fields": {
        "id": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "AffectedParamsInUpdateVariationFontEffect": {
      "fields": {
        "fontVariations": {
          "id": 3,
          "rule": "repeated",
          "type": "FontVariation"
        },
        "detachOpticalSizeFromFontSize": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "fontName": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "FontNameRef"
        }
      },
      "oneofs": {
        "_detachOpticalSizeFromFontSize": {
          "oneof": [
            "detachOpticalSizeFromFontSize"
          ]
        },
        "_fontName": {
          "oneof": [
            "fontName"
          ]
        }
      }
    },
    "AiCheckCreditsParam": {
      "fields": {
        "aiScene": {
          "id": 1,
          "type": "AiScene"
        }
      }
    },
    "AiCopilotCandidateNode": {
      "fields": {
        "script": {
          "id": 3,
          "type": "Arg_evalJsScript"
        },
        "docId": {
          "id": 2,
          "type": "string"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "AiDuplicateNodeInfo": {
      "fields": {
        "duplicateCount": {
          "id": 3,
          "type": "int32"
        },
        "nodeJson": {
          "id": 2,
          "type": "string"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "AiDuplicateParam": {
      "fields": {
        "nodeInfos": {
          "id": 2,
          "rule": "repeated",
          "type": "AiDuplicateNodeInfo"
        },
        "docId": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "AiDuplicateResult": {
      "fields": {
        "duplicatedNodeInfos": {
          "id": 1,
          "rule": "repeated",
          "type": "DuplicateNodeInfo"
        }
      }
    },
    "AiDuplicateResultSingleData": {
      "fields": {
        "encodedData": {
          "id": 2,
          "type": "string"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "AiLayoutEntryMenuState": {
      "fields": {
        "position": {
          "id": 2,
          "type": "Vector"
        },
        "opened": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "AiMarkUnit": {
      "fields": {
        "nodeId": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "imageData": {
          "id": 2,
          "type": "string"
        },
        "category": {
          "id": 1,
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "AlignTypeCommandParam": {
      "fields": {
        "type": {
          "id": 1,
          "type": "AlignType"
        }
      }
    },
    "Allocation": {
      "fields": {
        "jsStack": {
          "id": 4,
          "type": "uint32"
        },
        "range": {
          "id": 3,
          "type": "RangeFrame"
        },
        "size": {
          "id": 2,
          "type": "int32"
        },
        "address": {
          "id": 1,
          "type": "uint32"
        }
      }
    },
    "AndroidCodeConfig": {
      "fields": {
        "dpRatio": {
          "id": 3,
          "type": "CodeRatio"
        },
        "pxRatio": {
          "id": 2,
          "type": "CodeRatio"
        },
        "unit": {
          "id": 1,
          "type": "AndroidUnit"
        }
      }
    },
    "ApiError": {
      "fields": {
        "message": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_message": {
          "oneof": [
            "message"
          ]
        }
      }
    },
    "ApiIds": {
      "fields": {
        "ids": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "ApiProps": {
      "fields": {
        "exportSettings": {
          "id": 93,
          "rule": "repeated",
          "type": "ExportSettings"
        },
        "scrollDirection": {
          "id": 91,
          "type": "ScrollDirection"
        },
        "textStyleId": {
          "id": 89,
          "type": "Ret_ApiMixedStyleId"
        },
        "textCase": {
          "id": 88,
          "type": "Ret_ApiGetTextCase"
        },
        "hyperlink": {
          "id": 87,
          "type": "Ret_ApiGetHyperlink"
        },
        "lineHeight": {
          "id": 86,
          "type": "Ret_ApiGetLineHeight"
        },
        "letterSpacing": {
          "id": 85,
          "type": "Ret_ApiGetLetterSpacing"
        },
        "documentColorProfile": {
          "id": 84,
          "type": "DocumentColorProfile"
        },
        "textDecoration": {
          "id": 83,
          "type": "Ret_ApiGetTextDecoration"
        },
        "isMask": {
          "id": 82,
          "type": "bool"
        },
        "blendMode": {
          "id": 81,
          "type": "BlendMode"
        },
        "booleanOperation": {
          "id": 80,
          "type": "BooleanOperation"
        },
        "cornerRadius": {
          "id": 79,
          "type": "Ret_ApiGetMixedFloat"
        },
        "constrainProportions": {
          "id": 78,
          "type": "bool"
        },
        "maxHeight": {
          "id": 77,
          "type": "float"
        },
        "bottomLeftRadius": {
          "id": 72,
          "type": "float"
        },
        "topRightRadius": {
          "id": 71,
          "type": "float"
        },
        "selectedTextRange": {
          "id": 66,
          "options": {
            "proto3_optional": true
          },
          "type": "TextRange"
        },
        "pointCount": {
          "id": 64,
          "type": "int32"
        },
        "maxLines": {
          "id": 62,
          "type": "int32"
        },
        "paragraphSpacing": {
          "id": 61,
          "type": "float"
        },
        "textTruncation": {
          "id": 60,
          "type": "TextTruncation"
        },
        "key": {
          "id": 57,
          "type": "string"
        },
        "stackWrap": {
          "id": 54,
          "type": "StackWrap"
        },
        "locked": {
          "id": 53,
          "type": "bool"
        },
        "maxWidth": {
          "id": 75,
          "type": "float"
        },
        "vectorPaths": {
          "id": 52,
          "rule": "repeated",
          "type": "ApiVectorPath"
        },
        "minHeight": {
          "id": 76,
          "type": "float"
        },
        "effectStyleId": {
          "id": 50,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "effects": {
          "id": 49,
          "rule": "repeated",
          "type": "Effect"
        },
        "gridStyleId": {
          "id": 45,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "cornerSmoothing": {
          "id": 69,
          "type": "float"
        },
        "stackPaddingRight": {
          "id": 14,
          "type": "float"
        },
        "strokeWeight": {
          "id": 33,
          "type": "Ret_ApiGetMixedFloat"
        },
        "layoutGrids": {
          "id": 44,
          "rule": "repeated",
          "type": "LayoutGrid"
        },
        "expanded": {
          "id": 68,
          "type": "bool"
        },
        "strokeStyleId": {
          "id": 42,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "autoRename": {
          "id": 31,
          "type": "bool"
        },
        "strokeBottomWeight": {
          "id": 41,
          "type": "float"
        },
        "removed": {
          "id": 24,
          "type": "bool"
        },
        "stackPrimaryAlignItems": {
          "id": 11,
          "type": "StackJustify"
        },
        "strokeRightWeight": {
          "id": 40,
          "type": "float"
        },
        "strokeTopWeight": {
          "id": 39,
          "type": "float"
        },
        "strokeJoin": {
          "id": 36,
          "type": "Ret_ApiGetStrokeJoin"
        },
        "minWidth": {
          "id": 59,
          "type": "float"
        },
        "hasMissingFont": {
          "id": 30,
          "type": "bool"
        },
        "absoluteTransform": {
          "id": 28,
          "type": "Transform"
        },
        "absoluteBoundingBox": {
          "id": 29,
          "type": "Rect"
        },
        "absoluteRenderBounds": {
          "id": 27,
          "type": "Rect"
        },
        "backgrounds": {
          "id": 26,
          "rule": "repeated",
          "type": "Paint"
        },
        "rotation": {
          "id": 46,
          "type": "float"
        },
        "guides": {
          "id": 25,
          "rule": "repeated",
          "type": "Guide"
        },
        "paints": {
          "id": 43,
          "rule": "repeated",
          "type": "Paint"
        },
        "innerRadius": {
          "id": 65,
          "type": "float"
        },
        "strokeAlign": {
          "id": 37,
          "type": "StrokeAlign"
        },
        "aiPoweredNodeId": {
          "id": 23,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "stackPaddingBottom": {
          "id": 16,
          "type": "float"
        },
        "stackChildPrimaryGrow": {
          "id": 21,
          "type": "int32"
        },
        "bottomRightRadius": {
          "id": 73,
          "type": "float"
        },
        "stackCounterAlignContent": {
          "id": 56,
          "type": "StackCounterAlignContent"
        },
        "stackPrimarySizing": {
          "id": 9,
          "type": "StackSize"
        },
        "stackPositioning": {
          "id": 20,
          "type": "StackPositioning"
        },
        "stackMode": {
          "id": 8,
          "type": "StackMode"
        },
        "stackChildAlignSelf": {
          "id": 19,
          "type": "StackCounterAlign"
        },
        "reactions": {
          "id": 90,
          "rule": "repeated",
          "type": "PrototypeInteraction"
        },
        "fills": {
          "id": 47,
          "type": "Ret_ApiMixedFills"
        },
        "scaleFactor": {
          "id": 92,
          "type": "float"
        },
        "vectorNetwork": {
          "id": 51,
          "type": "VectorNetwork"
        },
        "stackCounterSizing": {
          "id": 10,
          "type": "StackSize"
        },
        "fillStyleId": {
          "id": 48,
          "type": "Ret_ApiMixedStyleId"
        },
        "aiPoweredReason": {
          "id": 22,
          "type": "AIPoweredReason"
        },
        "textAlignVertical": {
          "id": 7,
          "type": "TextAlignVertical"
        },
        "stackHorizontalPadding": {
          "id": 13,
          "type": "float"
        },
        "stackReverseZIndex": {
          "id": 18,
          "type": "bool"
        },
        "strokeCap": {
          "id": 35,
          "type": "Ret_ApiGetStrokeCap"
        },
        "mainComponentId": {
          "id": 63,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "characters": {
          "id": 4,
          "type": "string"
        },
        "strokeLeftWeight": {
          "id": 38,
          "type": "float"
        },
        "stackSpacing": {
          "id": 17,
          "type": "float"
        },
        "dashPattern": {
          "id": 34,
          "rule": "repeated",
          "type": "float"
        },
        "topLeftRadius": {
          "id": 70,
          "type": "float"
        },
        "stackCounterAlignItems": {
          "id": 12,
          "type": "StackAlign"
        },
        "textAlignHorizontal": {
          "id": 6,
          "type": "TextAlignHorizontal"
        },
        "arcData": {
          "id": 67,
          "type": "ArcData"
        },
        "stackCounterSpacing": {
          "id": 55,
          "type": "float"
        },
        "textAutoResize": {
          "id": 5,
          "type": "TextAutoResize"
        },
        "description": {
          "id": 58,
          "type": "string"
        },
        "fontWeight": {
          "id": 2,
          "type": "Ret_ApiGetMixedFloat"
        },
        "stackVerticalPadding": {
          "id": 15,
          "type": "float"
        },
        "fontSize": {
          "id": 3,
          "type": "Ret_ApiGetMixedFloat"
        },
        "strokes": {
          "id": 32,
          "rule": "repeated",
          "type": "Paint"
        },
        "fontName": {
          "id": 1,
          "type": "Ret_ApiGetFontName"
        }
      },
      "oneofs": {
        "_selectedTextRange": {
          "oneof": [
            "selectedTextRange"
          ]
        },
        "_effectStyleId": {
          "oneof": [
            "effectStyleId"
          ]
        },
        "_gridStyleId": {
          "oneof": [
            "gridStyleId"
          ]
        },
        "_strokeStyleId": {
          "oneof": [
            "strokeStyleId"
          ]
        },
        "_aiPoweredNodeId": {
          "oneof": [
            "aiPoweredNodeId"
          ]
        },
        "_mainComponentId": {
          "oneof": [
            "mainComponentId"
          ]
        }
      }
    },
    "ApiSetHandleMirror": {
      "fields": {
        "value": {
          "id": 2,
          "type": "VectorHandleMirror"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "ApiVectorPath": {
      "fields": {
        "data": {
          "id": 2,
          "type": "string"
        },
        "windingRule": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "WindingRule"
        }
      },
      "oneofs": {
        "_windingRule": {
          "oneof": [
            "windingRule"
          ]
        }
      }
    },
    "ApplyAIComponentReplaceCommandParam": {
      "fields": {
        "isManual": {
          "id": 5,
          "type": "bool"
        },
        "nodeIdsArray": {
          "id": 3,
          "rule": "repeated",
          "type": "NodeIdsWrapper"
        },
        "aiReplaceResults": {
          "id": 4,
          "rule": "repeated",
          "type": "AIReplaceResult"
        },
        "toCreateNodeId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "exportedDocument": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "SerializedExportedDocument"
        }
      },
      "oneofs": {
        "_toCreateNodeId": {
          "oneof": [
            "toCreateNodeId"
          ]
        },
        "_exportedDocument": {
          "oneof": [
            "exportedDocument"
          ]
        }
      }
    },
    "ApplyAIRecognizeResultCommandParam": {
      "fields": {
        "templateFileData": {
          "id": 5,
          "type": "string"
        },
        "hideLowUsage": {
          "id": 4,
          "type": "bool"
        },
        "layoutInOnePage": {
          "id": 3,
          "type": "bool"
        },
        "isBuildIndex": {
          "id": 2,
          "type": "bool"
        },
        "encodedData": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "ApplyAiDuplicateResultData": {
      "fields": {
        "nodes": {
          "id": 3,
          "type": "string"
        },
        "keyRelation": {
          "id": 2,
          "type": "string"
        },
        "blobs": {
          "id": 4,
          "type": "string"
        },
        "aiResult": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "ApplyAiDuplicateResultDataV2": {
      "fields": {
        "nodes": {
          "id": 3,
          "keyType": "string",
          "type": "BridgeProtoStringMap"
        },
        "keyRelation": {
          "id": 2,
          "keyType": "string",
          "type": "string"
        },
        "blobs": {
          "id": 4,
          "keyType": "string",
          "type": "BridgeProtoStringMap"
        },
        "aiResult": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "ApplyAiDuplicateResultParam": {
      "fields": {
        "idMap": {
          "id": 2,
          "keyType": "string",
          "type": "string"
        },
        "aiDuplicateResults": {
          "id": 1,
          "rule": "repeated",
          "type": "AiDuplicateResultSingleData"
        }
      }
    },
    "ArcData": {
      "fields": {
        "innerRadius": {
          "id": 3,
          "type": "float"
        },
        "endingAngle": {
          "id": 2,
          "type": "float"
        },
        "startingAngle": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "Arg_AICopilotParam": {
      "fields": {
        "w": {
          "id": 7,
          "type": "float"
        },
        "y": {
          "id": 6,
          "type": "float"
        },
        "x": {
          "id": 5,
          "type": "float"
        },
        "undoable": {
          "id": 9,
          "type": "bool"
        },
        "candidateNode": {
          "id": 3,
          "type": "AiCopilotCandidateNode"
        },
        "mobileScreenNodeId": {
          "id": 2,
          "type": "string"
        },
        "nodeBlobInfo": {
          "id": 4,
          "type": "string"
        },
        "h": {
          "id": 8,
          "type": "float"
        },
        "parentNodeId": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_AIDesignLintDeDuplicateNodeIds": {
      "fields": {
        "nodeIds": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "Arg_AIDesignLintPaintStyleCommand": {
      "fields": {
        "templatePaintStyleIds": {
          "id": 2,
          "rule": "repeated",
          "type": "string"
        },
        "nodeIds": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "Arg_AIDesignLintTextStyleCommand": {
      "fields": {
        "ignoreLineHeight": {
          "id": 3,
          "type": "bool"
        },
        "templateTextStyleIds": {
          "id": 2,
          "rule": "repeated",
          "type": "string"
        },
        "textNodeIds": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "Arg_AddSelectionPrototypeInteraction": {
      "fields": {
        "popupStateType": {
          "id": 2,
          "type": "PopupStateType"
        },
        "interactionType": {
          "id": 1,
          "type": "InteractionType"
        }
      }
    },
    "Arg_AddVariableSetModeCommandParam": {
      "fields": {
        "newModeName": {
          "id": 2,
          "type": "string"
        },
        "variableSetId": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_AiLayout": {
      "fields": {
        "alignType": {
          "id": 1,
          "type": "AiAlignType"
        }
      }
    },
    "Arg_AiMarkInfo": {
      "fields": {
        "postfix": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_AimLibraryNode": {
      "fields": {
        "id": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_id": {
          "oneof": [
            "id"
          ]
        }
      }
    },
    "Arg_ApiAppendChild": {
      "fields": {
        "insertIndex": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "childNodeId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "parentNodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_insertIndex": {
          "oneof": [
            "insertIndex"
          ]
        },
        "_childNodeId": {
          "oneof": [
            "childNodeId"
          ]
        },
        "_parentNodeId": {
          "oneof": [
            "parentNodeId"
          ]
        }
      }
    },
    "Arg_ApiCreateShapeNode": {
      "fields": {
        "nodeType": {
          "id": 2,
          "type": "NodeType"
        },
        "removeFrameFill": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "Arg_ApiCreateStyleNode": {
      "fields": {
        "nodeType": {
          "id": 1,
          "type": "NodeType"
        }
      }
    },
    "Arg_ApiDeleteCharacters": {
      "fields": {
        "end": {
          "id": 3,
          "type": "int32"
        },
        "start": {
          "id": 2,
          "type": "int32"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_ApiDimensionAndPosition": {
      "fields": {
        "w": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "y": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "x": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "h": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_w": {
          "oneof": [
            "w"
          ]
        },
        "_y": {
          "oneof": [
            "y"
          ]
        },
        "_x": {
          "oneof": [
            "x"
          ]
        },
        "_h": {
          "oneof": [
            "h"
          ]
        },
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_ApiGetClipsContent": {
      "fields": {
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_ApiGetName": {
      "fields": {
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_ApiGetNodeIdRef": {
      "fields": {
        "nodeId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "error": {
          "id": 1,
          "type": "ApiError"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_ApiGetPluginData": {
      "fields": {
        "pluginId": {
          "id": 3,
          "type": "string"
        },
        "key": {
          "id": 2,
          "type": "string"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_ApiGetPluginDataKeys": {
      "fields": {
        "pluginId": {
          "id": 2,
          "type": "string"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_ApiGetProps": {
      "fields": {
        "props": {
          "id": 2,
          "rule": "repeated",
          "type": "string"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_ApiGetSharedPluginData": {
      "fields": {
        "key": {
          "id": 3,
          "type": "string"
        },
        "nameSpace": {
          "id": 2,
          "type": "string"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_ApiGetSharedPluginDataKeys": {
      "fields": {
        "nameSpace": {
          "id": 2,
          "type": "string"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_ApiInsertCharacters": {
      "fields": {
        "start": {
          "id": 2,
          "type": "int32"
        },
        "posType": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "PosType"
        },
        "characters": {
          "id": 3,
          "type": "string"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_posType": {
          "oneof": [
            "posType"
          ]
        },
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_ApiNodeId": {
      "fields": {
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_ApiNodeIdRef": {
      "fields": {
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_ApiNotify": {
      "fields": {
        "cancelId": {
          "id": 7,
          "type": "int32"
        },
        "buttonText": {
          "id": 6,
          "type": "string"
        },
        "timeout": {
          "id": 2,
          "type": "int32"
        },
        "buttonActionId": {
          "id": 5,
          "type": "int32"
        },
        "message": {
          "id": 1,
          "type": "string"
        },
        "onDequeueCallbackId": {
          "id": 4,
          "type": "int32"
        },
        "error": {
          "id": 3,
          "type": "bool"
        }
      }
    },
    "Arg_ApiNotifyCallback": {
      "fields": {
        "dequeueReason": {
          "id": 3,
          "type": "string"
        },
        "buttonActionId": {
          "id": 2,
          "type": "int32"
        },
        "onDequeueCallbackId": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "Arg_ApiRectToFrame": {
      "fields": {
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_ApiRelativeTransform": {
      "fields": {
        "value": {
          "id": 2,
          "type": "Transform"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_ApiRemoveNode": {
      "fields": {
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_ApiResize": {
      "fields": {
        "height": {
          "id": 3,
          "type": "float"
        },
        "width": {
          "id": 2,
          "type": "float"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_ApiScaleNodes": {
      "fields": {
        "nodeId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "scaleFactor": {
          "id": 1,
          "type": "float"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_ApiSearchNodeWithSkip": {
      "fields": {
        "skipInvisibleInstanceChildren": {
          "id": 2,
          "type": "bool"
        },
        "currentNodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_currentNodeId": {
          "oneof": [
            "currentNodeId"
          ]
        }
      }
    },
    "Arg_ApiSetBoolean": {
      "fields": {
        "value": {
          "id": 2,
          "type": "bool"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_ApiSetClipsContent": {
      "fields": {
        "value": {
          "id": 2,
          "type": "bool"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_ApiSetComponentPropertyReferences": {
      "fields": {
        "references": {
          "id": 2,
          "type": "ComponentPropertyReferences"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_ApiSetConstraints": {
      "fields": {
        "value": {
          "id": 2,
          "type": "Constraints"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_ApiSetFloat": {
      "fields": {
        "value": {
          "id": 2,
          "type": "float"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_ApiSetHyperlink": {
      "fields": {
        "value": {
          "id": 2,
          "type": "Hyperlink"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_ApiSetInstancePropertiesWithError": {
      "fields": {
        "properties": {
          "id": 2,
          "keyType": "string",
          "type": "PropertyValueOfInstance"
        },
        "instanceId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_instanceId": {
          "oneof": [
            "instanceId"
          ]
        }
      }
    },
    "Arg_ApiSetName": {
      "fields": {
        "name": {
          "id": 2,
          "type": "string"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_ApiSetPluginData": {
      "fields": {
        "value": {
          "id": 3,
          "type": "string"
        },
        "pluginId": {
          "id": 4,
          "type": "string"
        },
        "key": {
          "id": 2,
          "type": "string"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_ApiSetProps": {
      "fields": {
        "targetPropName": {
          "id": 3,
          "type": "string"
        },
        "props": {
          "id": 2,
          "type": "ApiProps"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_ApiSetSelection": {
      "fields": {
        "ids": {
          "id": 2,
          "rule": "repeated",
          "type": "string"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_ApiSetSharedPluginData": {
      "fields": {
        "key": {
          "id": 3,
          "type": "string"
        },
        "value": {
          "id": 4,
          "type": "string"
        },
        "nameSpace": {
          "id": 2,
          "type": "string"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_ApiSetTransform": {
      "fields": {
        "value": {
          "id": 2,
          "type": "Transform"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_ApiTextProps": {
      "fields": {
        "textAlignVertical": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "TextAlignVertical"
        },
        "textAlignHorizontal": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "TextAlignHorizontal"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_textAlignVertical": {
          "oneof": [
            "textAlignVertical"
          ]
        },
        "_textAlignHorizontal": {
          "oneof": [
            "textAlignHorizontal"
          ]
        },
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_ApiTextRange": {
      "fields": {
        "end": {
          "id": 3,
          "type": "uint64"
        },
        "start": {
          "id": 2,
          "type": "uint64"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_ApiTextRangeWithBool": {
      "fields": {
        "value": {
          "id": 4,
          "type": "bool"
        },
        "end": {
          "id": 3,
          "type": "uint64"
        },
        "start": {
          "id": 2,
          "type": "uint64"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_ApiTextRangeWithFills": {
      "fields": {
        "value": {
          "id": 4,
          "rule": "repeated",
          "type": "Paint"
        },
        "end": {
          "id": 3,
          "type": "uint64"
        },
        "start": {
          "id": 2,
          "type": "uint64"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_ApiTextRangeWithFloat": {
      "fields": {
        "value": {
          "id": 4,
          "type": "float"
        },
        "end": {
          "id": 3,
          "type": "uint64"
        },
        "start": {
          "id": 2,
          "type": "uint64"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_ApiTextRangeWithFontName": {
      "fields": {
        "value": {
          "id": 4,
          "type": "FontName"
        },
        "end": {
          "id": 3,
          "type": "uint64"
        },
        "start": {
          "id": 2,
          "type": "uint64"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_ApiTextRangeWithHyperlinkTarget": {
      "fields": {
        "value": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "HyperlinkTarget"
        },
        "end": {
          "id": 3,
          "type": "uint64"
        },
        "start": {
          "id": 2,
          "type": "uint64"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_value": {
          "oneof": [
            "value"
          ]
        },
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_ApiTextRangeWithInt": {
      "fields": {
        "value": {
          "id": 4,
          "type": "int32"
        },
        "end": {
          "id": 3,
          "type": "uint64"
        },
        "start": {
          "id": 2,
          "type": "uint64"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_ApiTextRangeWithLetterSpacing": {
      "fields": {
        "value": {
          "id": 4,
          "type": "LetterSpacing"
        },
        "end": {
          "id": 3,
          "type": "uint64"
        },
        "start": {
          "id": 2,
          "type": "uint64"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_ApiTextRangeWithLineHeight": {
      "fields": {
        "value": {
          "id": 4,
          "type": "LineHeight"
        },
        "end": {
          "id": 3,
          "type": "uint64"
        },
        "start": {
          "id": 2,
          "type": "uint64"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_ApiTextRangeWithLineType": {
      "fields": {
        "value": {
          "id": 4,
          "type": "LineType"
        },
        "end": {
          "id": 3,
          "type": "uint64"
        },
        "start": {
          "id": 2,
          "type": "uint64"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_ApiTextRangeWithString": {
      "fields": {
        "value": {
          "id": 4,
          "type": "string"
        },
        "end": {
          "id": 3,
          "type": "uint64"
        },
        "start": {
          "id": 2,
          "type": "uint64"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_ApiTextRangeWithTextCase": {
      "fields": {
        "value": {
          "id": 4,
          "type": "TextCase"
        },
        "end": {
          "id": 3,
          "type": "uint64"
        },
        "start": {
          "id": 2,
          "type": "uint64"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_ApiTextRangeWithTextDecoration": {
      "fields": {
        "value": {
          "id": 4,
          "type": "TextDecoration"
        },
        "end": {
          "id": 3,
          "type": "uint64"
        },
        "start": {
          "id": 2,
          "type": "uint64"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_ApiUpdateTextNodeContent": {
      "fields": {
        "characters": {
          "id": 2,
          "type": "string"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_ApplayAILayoutCommand": {
      "fields": {
        "figJsonNodes": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_ApplyAISearchInsertNodeCommand": {
      "fields": {
        "info": {
          "id": 3,
          "type": "string"
        },
        "rootId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "docId": {
          "id": 1,
          "type": "string"
        }
      },
      "oneofs": {
        "_rootId": {
          "oneof": [
            "rootId"
          ]
        }
      }
    },
    "Arg_ApplyChatBot": {
      "fields": {
        "selectedNodeIds": {
          "id": 2,
          "rule": "repeated",
          "type": "string"
        },
        "data": {
          "id": 1,
          "rule": "repeated",
          "type": "AIGenParam"
        }
      }
    },
    "Arg_ApplyCursor": {
      "fields": {
        "dataUriFor1x": {
          "id": 5,
          "type": "string"
        },
        "hotspotY": {
          "id": 8,
          "type": "int32"
        },
        "dpr": {
          "id": 6,
          "type": "float"
        },
        "dataUri": {
          "id": 4,
          "type": "string"
        },
        "hash": {
          "id": 3,
          "type": "string"
        },
        "rotation": {
          "id": 2,
          "type": "float"
        },
        "hotspotX": {
          "id": 7,
          "type": "int32"
        },
        "mode": {
          "id": 1,
          "type": "CursorMode"
        }
      }
    },
    "Arg_AttachFloatVarForLayoutMixinParam": {
      "fields": {
        "field": {
          "id": 3,
          "type": "VariableField"
        },
        "variableData": {
          "id": 2,
          "type": "VariableAliasData"
        },
        "layoutMixin": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_BatchAttachFloatVarForLayoutMixinParam": {
      "fields": {
        "fields": {
          "id": 3,
          "rule": "repeated",
          "type": "VariableField"
        },
        "variableData": {
          "id": 2,
          "type": "VariableAliasData"
        },
        "layoutMixin": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_BatchDetachFloatVarForLayoutMixinParam": {
      "fields": {
        "fields": {
          "id": 2,
          "rule": "repeated",
          "type": "VariableField"
        },
        "layoutMixin": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_BatchUpdateVariablePublishHiddenCommandParam": {
      "fields": {
        "params": {
          "id": 1,
          "rule": "repeated",
          "type": "Arg_UpdateVariablePublishHiddenCommandParam"
        }
      }
    },
    "Arg_BatchUpdateVariableScopesCommandParam": {
      "fields": {
        "params": {
          "id": 1,
          "rule": "repeated",
          "type": "Arg_UpdateVariableScopesCommandParam"
        }
      }
    },
    "Arg_CanvasClipboardCopySelectionAsPNG": {
      "fields": {
        "asyncId": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "Arg_ChangeCanvasColorSpace": {
      "fields": {
        "colorProfile": {
          "id": 1,
          "type": "DocumentColorProfile"
        }
      }
    },
    "Arg_ChangeLibraryAssetPanelControlExpand": {
      "fields": {
        "expand": {
          "id": 2,
          "type": "bool"
        },
        "expandKey": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_ChangeLibraryMainModalRoutePath": {
      "fields": {
        "value": {
          "id": 1,
          "type": "LibraryMainModalRoutePathType"
        }
      }
    },
    "Arg_ChangeLibraryPublishModalControlExpand": {
      "fields": {
        "expand": {
          "id": 2,
          "type": "bool"
        },
        "type": {
          "id": 1,
          "type": "LibraryPublishModalControlType"
        }
      }
    },
    "Arg_ColorPickCommand": {
      "fields": {
        "colorPickCommandType": {
          "id": 1,
          "type": "ColorPickCommandType"
        }
      }
    },
    "Arg_CreateColorVariable": {
      "fields": {
        "colorValue": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "RGBA"
        },
        "alias": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "description": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "name": {
          "id": 2,
          "type": "string"
        },
        "variableSetId": {
          "id": 1,
          "type": "string"
        }
      },
      "oneofs": {
        "_colorValue": {
          "oneof": [
            "colorValue"
          ]
        },
        "_alias": {
          "oneof": [
            "alias"
          ]
        },
        "_description": {
          "oneof": [
            "description"
          ]
        }
      }
    },
    "Arg_CreateFloatVariable": {
      "fields": {
        "alias": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "floatValue": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "description": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "name": {
          "id": 2,
          "type": "string"
        },
        "variableSetId": {
          "id": 1,
          "type": "string"
        }
      },
      "oneofs": {
        "_alias": {
          "oneof": [
            "alias"
          ]
        },
        "_floatValue": {
          "oneof": [
            "floatValue"
          ]
        },
        "_description": {
          "oneof": [
            "description"
          ]
        }
      }
    },
    "Arg_CreateVariableSetCommandParam": {
      "fields": {
        "name": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_DeleteAsync": {
      "fields": {
        "cbId": {
          "id": 2,
          "type": "int32"
        },
        "key": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_DeleteAsyncCallback": {
      "fields": {
        "cbId": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "Arg_DetachFloatVarForLayoutMixinParam": {
      "fields": {
        "field": {
          "id": 2,
          "type": "VariableField"
        },
        "layoutMixin": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_DetachSelectedInstanceReport": {
      "fields": {
        "nodeId2ComponentNodeId": {
          "id": 2,
          "keyType": "string",
          "type": "string"
        },
        "docId": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_DevModeExportChangeScale": {
      "fields": {
        "selected": {
          "id": 2,
          "type": "bool"
        },
        "scaleItem": {
          "id": 1,
          "type": "ScaleItem"
        }
      }
    },
    "Arg_DevModeExportSelectFileType": {
      "fields": {
        "fileType": {
          "id": 1,
          "type": "FileType"
        }
      }
    },
    "Arg_DevModeExportSelectPresetCodeType": {
      "fields": {
        "presetCodeType": {
          "id": 1,
          "type": "PresetCodeType"
        }
      }
    },
    "Arg_DownloadPrototypeDeviceFrame": {
      "fields": {
        "deviceImageUrl": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_DuplicateVariableCommandParam": {
      "fields": {
        "newName": {
          "id": 2,
          "type": "string"
        },
        "sourceVariableId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_sourceVariableId": {
          "oneof": [
            "sourceVariableId"
          ]
        }
      }
    },
    "Arg_DuplicateVariableSetModeCommandParam": {
      "fields": {
        "newModeName": {
          "id": 3,
          "type": "string"
        },
        "sourceModeId": {
          "id": 2,
          "type": "string"
        },
        "variableSetId": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_EasingFunction": {
      "fields": {
        "value": {
          "id": 1,
          "rule": "repeated",
          "type": "float"
        }
      }
    },
    "Arg_EasingType": {
      "fields": {
        "value": {
          "id": 1,
          "type": "EasingType"
        }
      }
    },
    "Arg_EnableAIPoweredCommand": {
      "fields": {
        "rootId": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_EndEditingVector": {
      "fields": {
        "keepEditorMode": {
          "id": 2,
          "type": "bool"
        },
        "hard": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "Arg_ExecuteRecoverToRemoteDefaultVariantCommand": {
      "fields": {
        "componentSetExportedDocument": {
          "id": 2,
          "type": "SerializedExportedDocument"
        },
        "recoverInfo": {
          "id": 1,
          "type": "Arg_FetchRemoteComponentSetDataForRecover"
        }
      }
    },
    "Arg_ExitAIPoweredCommand": {
      "fields": {
        "targetNodeId": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "isReopenAutoQuit": {
          "id": 2,
          "type": "bool"
        },
        "disableToast": {
          "id": 1,
          "type": "bool"
        }
      },
      "oneofs": {
        "_targetNodeId": {
          "oneof": [
            "targetNodeId"
          ]
        }
      }
    },
    "Arg_Fetch": {
      "fields": {
        "referrer": {
          "id": 10,
          "type": "string"
        },
        "redirect": {
          "id": 9,
          "type": "string"
        },
        "cache": {
          "id": 8,
          "type": "string"
        },
        "bodyIsBase64": {
          "id": 6,
          "type": "bool"
        },
        "integrity": {
          "id": 11,
          "type": "string"
        },
        "url": {
          "id": 2,
          "type": "string"
        },
        "method": {
          "id": 3,
          "type": "string"
        },
        "headers": {
          "id": 4,
          "keyType": "string",
          "type": "string"
        },
        "credentials": {
          "id": 7,
          "type": "string"
        },
        "body": {
          "id": 5,
          "type": "string"
        },
        "id": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "Arg_FetchCallback": {
      "fields": {
        "blobBase64": {
          "id": 9,
          "type": "string"
        },
        "url": {
          "id": 8,
          "type": "string"
        },
        "type": {
          "id": 7,
          "type": "string"
        },
        "statusText": {
          "id": 6,
          "type": "string"
        },
        "redirected": {
          "id": 4,
          "type": "bool"
        },
        "status": {
          "id": 5,
          "type": "int32"
        },
        "id": {
          "id": 1,
          "type": "int32"
        },
        "ok": {
          "id": 3,
          "type": "bool"
        },
        "headersObject": {
          "id": 2,
          "keyType": "string",
          "type": "string"
        }
      }
    },
    "Arg_FetchRemoteComponentSetDataForRecover": {
      "fields": {
        "comSetContentHash": {
          "id": 4,
          "type": "string"
        },
        "comSetPublishFile": {
          "id": 3,
          "type": "string"
        },
        "comSetNodeDataPath": {
          "id": 5,
          "type": "string"
        },
        "comSetKey": {
          "id": 6,
          "type": "string"
        },
        "comSetPublishId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "instanceNodeIds": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      },
      "oneofs": {
        "_comSetPublishId": {
          "oneof": [
            "comSetPublishId"
          ]
        }
      }
    },
    "Arg_FindAllCriteria": {
      "fields": {
        "pluginId": {
          "id": 8,
          "type": "string"
        },
        "hasSharedPluginDataFilter": {
          "id": 7,
          "type": "bool"
        },
        "sharedPluginDataFilter": {
          "id": 6,
          "options": {
            "proto3_optional": true
          },
          "type": "SharedPluginDataFilter"
        },
        "hasPluginDataFilter": {
          "id": 5,
          "type": "bool"
        },
        "pluginDataFilter": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "PluginDataFilter"
        },
        "types": {
          "id": 2,
          "rule": "repeated",
          "type": "NodeType"
        },
        "hasTypes": {
          "id": 3,
          "type": "bool"
        },
        "currentNodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_sharedPluginDataFilter": {
          "oneof": [
            "sharedPluginDataFilter"
          ]
        },
        "_pluginDataFilter": {
          "oneof": [
            "pluginDataFilter"
          ]
        },
        "_currentNodeId": {
          "oneof": [
            "currentNodeId"
          ]
        }
      }
    },
    "Arg_FrogParam": {
      "fields": {
        "eventName": {
          "id": 9,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "currentPage": {
          "id": 7,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "keyFrom": {
          "id": 6,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "duration": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "needRole": {
          "id": 10,
          "type": "bool"
        },
        "refPage": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "customExtend": {
          "id": 3,
          "keyType": "string",
          "type": "string"
        },
        "eventAction": {
          "id": 8,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "eventId": {
          "id": 2,
          "type": "int32"
        },
        "url": {
          "id": 1,
          "type": "string"
        }
      },
      "oneofs": {
        "_eventName": {
          "oneof": [
            "eventName"
          ]
        },
        "_currentPage": {
          "oneof": [
            "currentPage"
          ]
        },
        "_keyFrom": {
          "oneof": [
            "keyFrom"
          ]
        },
        "_duration": {
          "oneof": [
            "duration"
          ]
        },
        "_refPage": {
          "oneof": [
            "refPage"
          ]
        },
        "_eventAction": {
          "oneof": [
            "eventAction"
          ]
        }
      }
    },
    "Arg_GenerateDocumentNodeUrl": {
      "fields": {
        "nodeId": {
          "id": 2,
          "type": "string"
        },
        "docId": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_GenerateNewVariableNameForCollectionAndType": {
      "fields": {
        "type": {
          "id": 2,
          "type": "VariableResolvedDataType"
        },
        "collectionId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_collectionId": {
          "oneof": [
            "collectionId"
          ]
        }
      }
    },
    "Arg_GetAsync": {
      "fields": {
        "cbId": {
          "id": 2,
          "type": "int32"
        },
        "key": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_GetAsyncCallback": {
      "fields": {
        "cbId": {
          "id": 2,
          "type": "int32"
        },
        "value": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_value": {
          "oneof": [
            "value"
          ]
        }
      }
    },
    "Arg_GetBitmapBytes": {
      "fields": {
        "resourcePixelsHandle": {
          "id": 2,
          "type": "uint32"
        },
        "callbackId": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "Arg_GetCanvasGlyphBitmap": {
      "fields": {
        "scaleY": {
          "id": 7,
          "type": "float"
        },
        "bitmapHeight": {
          "id": 5,
          "type": "int32"
        },
        "bitmapWidth": {
          "id": 4,
          "type": "int32"
        },
        "meta": {
          "id": 8,
          "type": "CanvasControlParagraphMeta"
        },
        "y": {
          "id": 3,
          "type": "float"
        },
        "scaleX": {
          "id": 6,
          "type": "float"
        },
        "x": {
          "id": 2,
          "type": "float"
        },
        "grapheme": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_GetComputedColorVariable": {
      "fields": {
        "ignoreColor": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "nodeId": {
          "id": 1,
          "type": "string"
        }
      },
      "oneofs": {
        "_ignoreColor": {
          "oneof": [
            "ignoreColor"
          ]
        }
      }
    },
    "Arg_GetImageBytesAsync": {
      "fields": {
        "cbId": {
          "id": 2,
          "type": "int32"
        },
        "hash": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_GetImageBytesAsyncCallback": {
      "fields": {
        "success": {
          "id": 3,
          "type": "bool"
        },
        "cbId": {
          "id": 2,
          "type": "int32"
        },
        "bytes": {
          "id": 1,
          "type": "bytes"
        }
      }
    },
    "Arg_GetImageSizeAsync": {
      "fields": {
        "cbId": {
          "id": 2,
          "type": "int32"
        },
        "hash": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_GetImageSizeAsyncCallback": {
      "fields": {
        "success": {
          "id": 4,
          "type": "bool"
        },
        "cbId": {
          "id": 3,
          "type": "int32"
        },
        "height": {
          "id": 2,
          "type": "int32"
        },
        "width": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "Arg_GetSearchedSortedList": {
      "fields": {
        "keyword": {
          "id": 2,
          "type": "string"
        },
        "targetList": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "Arg_GetSingleMissLibraryContent": {
      "fields": {
        "libraryId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "documentId": {
          "id": 1,
          "type": "string"
        }
      },
      "oneofs": {
        "_libraryId": {
          "oneof": [
            "libraryId"
          ]
        }
      }
    },
    "Arg_GetValidTransitionTypes": {
      "fields": {
        "connectionType": {
          "id": 2,
          "type": "ConnectionType"
        },
        "navigationType": {
          "id": 3,
          "type": "NavigationType"
        },
        "interactionType": {
          "id": 1,
          "type": "InteractionType"
        }
      }
    },
    "Arg_IdRef": {
      "fields": {
        "id": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_id": {
          "oneof": [
            "id"
          ]
        }
      }
    },
    "Arg_ImportRemoteLibNodeByKeyAsyncCallbackCommand": {
      "fields": {
        "exportedDocument": {
          "id": 6,
          "type": "SerializedExportedDocument"
        },
        "toCreateNodeId": {
          "id": 5,
          "type": "string"
        },
        "key": {
          "id": 4,
          "type": "string"
        },
        "importNodeType": {
          "id": 3,
          "type": "PluginApiImportLibNodeType"
        },
        "success": {
          "id": 2,
          "type": "bool"
        },
        "cbId": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "Arg_ImportRemoteLibNodeByKeyAsyncCallbackCommandJs": {
      "fields": {
        "importNodeType": {
          "id": 4,
          "type": "PluginApiImportLibNodeType"
        },
        "nodeId": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "success": {
          "id": 2,
          "type": "bool"
        },
        "cbId": {
          "id": 1,
          "type": "int32"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_ImportRemoteLibNodeByKeyAsyncCommand": {
      "fields": {
        "importNodeType": {
          "id": 3,
          "type": "PluginApiImportLibNodeType"
        },
        "key": {
          "id": 2,
          "type": "string"
        },
        "cbId": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "Arg_ImportRemoteLibNodeByKeyAsyncCommandJs": {
      "fields": {
        "key": {
          "id": 5,
          "type": "string"
        },
        "cbId": {
          "id": 1,
          "type": "int32"
        },
        "nodeDataPath": {
          "id": 4,
          "type": "string"
        },
        "importNodeType": {
          "id": 6,
          "type": "PluginApiImportLibNodeType"
        },
        "remoteNodeId": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "remoteDocId": {
          "id": 2,
          "type": "string"
        }
      },
      "oneofs": {
        "_remoteNodeId": {
          "oneof": [
            "remoteNodeId"
          ]
        }
      }
    },
    "Arg_InsertVariableBetweenCommandParam": {
      "fields": {
        "preIndex": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "postIndex": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "variableId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_preIndex": {
          "oneof": [
            "preIndex"
          ]
        },
        "_postIndex": {
          "oneof": [
            "postIndex"
          ]
        },
        "_variableId": {
          "oneof": [
            "variableId"
          ]
        }
      }
    },
    "Arg_IsRepeatVariableNameCommandParam": {
      "fields": {
        "variableName": {
          "id": 2,
          "type": "string"
        },
        "variableSetId": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_JumpToOriginDocument": {
      "fields": {
        "name": {
          "id": 4,
          "type": "string"
        },
        "isShape": {
          "id": 5,
          "type": "bool"
        },
        "fromFig": {
          "id": 3,
          "type": "bool"
        },
        "publishId": {
          "id": 2,
          "type": "string"
        },
        "publishFile": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_KeysAsync": {
      "fields": {
        "cbId": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "Arg_KeysAsyncCallback": {
      "fields": {
        "cbId": {
          "id": 2,
          "type": "int32"
        },
        "keys": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "Arg_LoadFallbackFont": {
      "fields": {
        "fonts": {
          "id": 1,
          "rule": "repeated",
          "type": "FontNameRef"
        }
      }
    },
    "Arg_LoadPluginScript": {
      "fields": {
        "appendOnly": {
          "id": 4,
          "type": "bool"
        },
        "useSandbox": {
          "id": 3,
          "type": "bool"
        },
        "pluginId": {
          "id": 2,
          "type": "string"
        },
        "code": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_MeasureControlTextWidth": {
      "fields": {
        "meta": {
          "id": 2,
          "type": "CanvasControlParagraphMeta"
        },
        "grapheme": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_Metric": {
      "fields": {
        "pushMetricsToServer": {
          "id": 4,
          "type": "bool"
        },
        "labels": {
          "id": 3,
          "keyType": "string",
          "type": "string"
        },
        "value": {
          "id": 2,
          "type": "float"
        },
        "metricName": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_MetricRenderDuration": {
      "fields": {
        "items": {
          "id": 1,
          "rule": "repeated",
          "type": "MetricRenderDurationItem"
        }
      }
    },
    "Arg_MonitorEvents": {
      "fields": {
        "event": {
          "id": 1,
          "rule": "repeated",
          "type": "PluginEventType"
        }
      }
    },
    "Arg_OnGetBitmapBytes": {
      "fields": {
        "success": {
          "id": 4,
          "type": "bool"
        },
        "buffer": {
          "id": 3,
          "type": "bytes"
        },
        "resourcePixelsHandle": {
          "id": 2,
          "type": "uint32"
        },
        "callbackId": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "Arg_OnPreparedUploadTexture": {
      "fields": {
        "items": {
          "id": 1,
          "rule": "repeated",
          "type": "OnPreparedUploadTextureItem"
        }
      }
    },
    "Arg_OnPrototypeDeviceFrameDownload": {
      "fields": {
        "data": {
          "id": 4,
          "type": "bytes"
        },
        "height": {
          "id": 3,
          "type": "int32"
        },
        "width": {
          "id": 2,
          "type": "int32"
        },
        "deviceImageUrl": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_OpenLibraryPublishModal": {
      "fields": {
        "checkedChangeKeys": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "Arg_OpenLink": {
      "fields": {
        "openUrlInNewTab": {
          "id": 2,
          "type": "bool"
        },
        "url": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_PasteProtoAsNode": {
      "fields": {
        "doc": {
          "id": 1,
          "type": "SerializedExportedDocument"
        }
      }
    },
    "Arg_PluginApiExportSetting": {
      "fields": {
        "colorProfile": {
          "id": 7,
          "type": "string"
        },
        "constraint": {
          "id": 6,
          "type": "ExportSettingsConstraints"
        },
        "suffix": {
          "id": 5,
          "type": "string"
        },
        "contentsOnly": {
          "id": 3,
          "type": "bool"
        },
        "id": {
          "id": 1,
          "type": "string"
        },
        "format": {
          "id": 2,
          "type": "string"
        },
        "useAbsoluteBounds": {
          "id": 4,
          "type": "bool"
        }
      }
    },
    "Arg_PluginApiMoveNodes": {
      "fields": {
        "insertIndex": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "parentId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "nodeIds": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      },
      "oneofs": {
        "_insertIndex": {
          "oneof": [
            "insertIndex"
          ]
        },
        "_parentId": {
          "oneof": [
            "parentId"
          ]
        }
      }
    },
    "Arg_PluginApiSwapComponent": {
      "fields": {
        "newMainComponentNodeId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "instanceNodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_newMainComponentNodeId": {
          "oneof": [
            "newMainComponentNodeId"
          ]
        },
        "_instanceNodeId": {
          "oneof": [
            "instanceNodeId"
          ]
        }
      }
    },
    "Arg_PluginCallbackEventCollection": {
      "fields": {
        "events": {
          "id": 1,
          "rule": "repeated",
          "type": "Arg_PluginEvent"
        }
      }
    },
    "Arg_PluginDocumentChange": {
      "fields": {
        "properties": {
          "id": 5,
          "rule": "repeated",
          "type": "PluginDocumentChangeProperty"
        },
        "type": {
          "id": 4,
          "type": "PluginDocumentChangeType"
        },
        "origin": {
          "id": 3,
          "type": "PluginDocumentChangeOrigin"
        },
        "ownerPage": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "id": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_ownerPage": {
          "oneof": [
            "ownerPage"
          ]
        },
        "_id": {
          "oneof": [
            "id"
          ]
        }
      }
    },
    "Arg_PluginDropEvent": {
      "fields": {
        "y": {
          "id": 7,
          "type": "int32"
        },
        "x": {
          "id": 6,
          "type": "int32"
        },
        "nodeId": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "items": {
          "id": 4,
          "rule": "repeated",
          "type": "Arg_PluginDropItem"
        },
        "absoluteY": {
          "id": 9,
          "type": "int32"
        },
        "files": {
          "id": 3,
          "rule": "repeated",
          "type": "Arg_PluginDropFile"
        },
        "dropMetadata": {
          "id": 10,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "absoluteX": {
          "id": 8,
          "type": "int32"
        },
        "offsetY": {
          "id": 2,
          "type": "int32"
        },
        "offsetX": {
          "id": 1,
          "type": "int32"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        },
        "_dropMetadata": {
          "oneof": [
            "dropMetadata"
          ]
        }
      }
    },
    "Arg_PluginDropEventPickInsertionLocation": {
      "fields": {
        "yInCamera": {
          "id": 2,
          "type": "float"
        },
        "xInCamera": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "Arg_PluginDropFile": {
      "fields": {
        "getTextAsyncPromiseId": {
          "id": 4,
          "type": "int32"
        },
        "type": {
          "id": 2,
          "type": "string"
        },
        "getBytesAsyncPromiseId": {
          "id": 3,
          "type": "int32"
        },
        "name": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_PluginDropItem": {
      "fields": {
        "data": {
          "id": 2,
          "type": "string"
        },
        "type": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_PluginEvent": {
      "fields": {
        "dropEvent": {
          "id": 3,
          "type": "Arg_PluginDropEvent"
        },
        "documentChanges": {
          "id": 2,
          "rule": "repeated",
          "type": "Arg_PluginDocumentChange"
        },
        "type": {
          "id": 1,
          "type": "PluginEventType"
        }
      }
    },
    "Arg_PluginEventNotificationStatus": {
      "fields": {
        "status": {
          "id": 1,
          "type": "PluginEventNotificationStatus"
        }
      }
    },
    "Arg_PrepareExportImage": {
      "fields": {
        "pageId": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "usePreview": {
          "id": 2,
          "type": "bool"
        },
        "forPrototypeCoverImage": {
          "id": 4,
          "type": "bool"
        },
        "forCoverImage": {
          "id": 3,
          "type": "bool"
        },
        "nodeIds": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      },
      "oneofs": {
        "_pageId": {
          "oneof": [
            "pageId"
          ]
        }
      }
    },
    "Arg_PrepareUploadTexture": {
      "fields": {
        "items": {
          "id": 1,
          "rule": "repeated",
          "type": "PrepareUploadTextureItem"
        }
      }
    },
    "Arg_PrintImageDiagnosis": {
      "fields": {
        "id": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_id": {
          "oneof": [
            "id"
          ]
        }
      }
    },
    "Arg_PrototypeInteractionTargetEnterHover": {
      "fields": {
        "value": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_value": {
          "oneof": [
            "value"
          ]
        }
      }
    },
    "Arg_PushBitmap": {
      "fields": {
        "imageHash": {
          "id": 3,
          "type": "string"
        },
        "data": {
          "id": 4,
          "type": "bytes"
        },
        "height": {
          "id": 2,
          "type": "int32"
        },
        "width": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "Arg_RGBAtoCode": {
      "fields": {
        "mode": {
          "id": 3,
          "type": "DevAndReadonlyColorMode"
        },
        "a": {
          "id": 2,
          "type": "float"
        },
        "rgb": {
          "id": 1,
          "type": "RGB"
        }
      }
    },
    "Arg_ReadMagnifierBuffer": {
      "fields": {
        "callback": {
          "id": 5,
          "type": "uint64"
        },
        "data": {
          "id": 4,
          "type": "uint64"
        },
        "height": {
          "id": 3,
          "type": "uint32"
        },
        "width": {
          "id": 2,
          "type": "uint32"
        },
        "bufferHandle": {
          "id": 1,
          "type": "uint64"
        }
      }
    },
    "Arg_ReadMagnifierBufferCallback": {
      "fields": {
        "callback": {
          "id": 3,
          "type": "uint64"
        },
        "size": {
          "id": 2,
          "type": "uint32"
        },
        "data": {
          "id": 1,
          "type": "uint64"
        }
      }
    },
    "Arg_ReadSyncWGPU": {
      "fields": {
        "y": {
          "id": 3,
          "type": "int32"
        },
        "x": {
          "id": 2,
          "type": "int32"
        },
        "data": {
          "id": 6,
          "type": "uint64"
        },
        "height": {
          "id": 5,
          "type": "int32"
        },
        "width": {
          "id": 4,
          "type": "int32"
        },
        "textureHandle": {
          "id": 1,
          "type": "uint64"
        }
      }
    },
    "Arg_RecoverComponentCommand": {
      "fields": {
        "componentId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_componentId": {
          "oneof": [
            "componentId"
          ]
        }
      }
    },
    "Arg_RemoveSelectionPrototypeInteraction": {
      "fields": {
        "interactionKeys": {
          "id": 1,
          "rule": "repeated",
          "type": "PrototypeInteractionKey"
        }
      }
    },
    "Arg_RemoveVariableCodeSyntaxCommandParam": {
      "fields": {
        "platform": {
          "id": 2,
          "type": "VariableCodePlatform"
        },
        "variableId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_variableId": {
          "oneof": [
            "variableId"
          ]
        }
      }
    },
    "Arg_RemoveVariableSetModeCommandParam": {
      "fields": {
        "variableSetModeId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "variableSetId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_variableSetModeId": {
          "oneof": [
            "variableSetModeId"
          ]
        },
        "_variableSetId": {
          "oneof": [
            "variableSetId"
          ]
        }
      }
    },
    "Arg_RenameComponentPropProp": {
      "fields": {
        "newName": {
          "id": 3,
          "type": "string"
        },
        "oldName": {
          "id": 2,
          "type": "string"
        },
        "props": {
          "id": 1,
          "rule": "repeated",
          "type": "ComponentSetProp"
        }
      }
    },
    "Arg_RenameVariableCommandParam": {
      "fields": {
        "name": {
          "id": 2,
          "type": "string"
        },
        "variableId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_variableId": {
          "oneof": [
            "variableId"
          ]
        }
      }
    },
    "Arg_RenameVariableSetCommandParam": {
      "fields": {
        "name": {
          "id": 2,
          "type": "string"
        },
        "variableSetId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_variableSetId": {
          "oneof": [
            "variableSetId"
          ]
        }
      }
    },
    "Arg_RenderCursorRecord": {
      "fields": {
        "results": {
          "id": 1,
          "rule": "repeated",
          "type": "RenderCursorResult"
        }
      }
    },
    "Arg_ReplaceLibrary": {
      "fields": {
        "value": {
          "id": 1,
          "rule": "repeated",
          "type": "Arg_ReplaceLibraryItem"
        }
      }
    },
    "Arg_ReplaceLibraryItem": {
      "fields": {
        "content": {
          "id": 4,
          "type": "Arg_startLibraryReplaceItem"
        },
        "exportedDocument": {
          "id": 3,
          "type": "SerializedExportedDocument"
        },
        "targetDocumentId": {
          "id": 2,
          "type": "string"
        },
        "originType": {
          "id": 1,
          "type": "ReplaceLibraryItemType"
        }
      }
    },
    "Arg_ResetDefaultVariableModeBySetCommandParam": {
      "fields": {
        "variableSetIds": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "Arg_Resize": {
      "fields": {
        "height": {
          "id": 2,
          "type": "float"
        },
        "width": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "Arg_ResolveExternalPromise": {
      "fields": {
        "buffer": {
          "id": 3,
          "type": "bytes"
        },
        "type": {
          "id": 2,
          "type": "ExternalPromiseType"
        },
        "id": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "Arg_RewritePublishProperties": {
      "fields": {
        "publishId": {
          "id": 3,
          "type": "string"
        },
        "publishFile": {
          "id": 2,
          "type": "string"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_ScrollAndZoomIntoView": {
      "fields": {
        "nodeIds": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "Arg_SetAliasForVariableCommandParam": {
      "fields": {
        "aliasVariableId": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "variableSetModeId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "variableId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_aliasVariableId": {
          "oneof": [
            "aliasVariableId"
          ]
        },
        "_variableSetModeId": {
          "oneof": [
            "variableSetModeId"
          ]
        },
        "_variableId": {
          "oneof": [
            "variableId"
          ]
        }
      }
    },
    "Arg_SetAsync": {
      "fields": {
        "cbId": {
          "id": 3,
          "type": "int32"
        },
        "value": {
          "id": 2,
          "type": "string"
        },
        "key": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_SetAsyncCallback": {
      "fields": {
        "cbId": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "Arg_SetHoverProtoTypeInteraction": {
      "fields": {
        "keys": {
          "id": 1,
          "rule": "repeated",
          "type": "PrototypeInteractionKey"
        }
      }
    },
    "Arg_SetRangeIndentation": {
      "fields": {
        "indentation": {
          "id": 4,
          "type": "int32"
        },
        "end": {
          "id": 3,
          "type": "uint64"
        },
        "start": {
          "id": 2,
          "type": "uint64"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_SetRangeLineType": {
      "fields": {
        "lineType": {
          "id": 4,
          "type": "LineType"
        },
        "end": {
          "id": 3,
          "type": "uint64"
        },
        "start": {
          "id": 2,
          "type": "uint64"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_SetURLSearchParams": {
      "fields": {
        "replace": {
          "id": 2,
          "type": "bool"
        },
        "params": {
          "id": 1,
          "type": "URLSearchParams"
        }
      }
    },
    "Arg_SetVariableModeBySetCommandParam": {
      "fields": {
        "variableSetModeId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "variableSetIds": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      },
      "oneofs": {
        "_variableSetModeId": {
          "oneof": [
            "variableSetModeId"
          ]
        }
      }
    },
    "Arg_SetVariableSetModeNameCommandParam": {
      "fields": {
        "newModeName": {
          "id": 3,
          "type": "string"
        },
        "variableSetModeId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "variableSetId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_variableSetModeId": {
          "oneof": [
            "variableSetModeId"
          ]
        },
        "_variableSetId": {
          "oneof": [
            "variableSetId"
          ]
        }
      }
    },
    "Arg_SetViewportCenter": {
      "fields": {
        "centerY": {
          "id": 2,
          "type": "float"
        },
        "centerX": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "Arg_SetViewportZoom": {
      "fields": {
        "zoom": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "Arg_ShowRecoverComponentModal": {
      "fields": {
        "fromFig": {
          "id": 2,
          "type": "bool"
        },
        "componentId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_componentId": {
          "oneof": [
            "componentId"
          ]
        }
      }
    },
    "Arg_ShowUI": {
      "fields": {
        "position": {
          "id": 6,
          "options": {
            "proto3_optional": true
          },
          "type": "Arg_reposition"
        },
        "height": {
          "id": 5,
          "type": "float"
        },
        "width": {
          "id": 4,
          "type": "float"
        },
        "title": {
          "id": 3,
          "type": "string"
        },
        "visible": {
          "id": 2,
          "type": "bool"
        },
        "html": {
          "id": 1,
          "type": "string"
        }
      },
      "oneofs": {
        "_position": {
          "oneof": [
            "position"
          ]
        }
      }
    },
    "Arg_SkewSelectedNodes": {
      "fields": {
        "skewY": {
          "id": 2,
          "type": "float"
        },
        "skewX": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "Arg_StartEditingPrototypeInteraction": {
      "fields": {
        "popupStateType": {
          "id": 2,
          "type": "PopupStateType"
        },
        "popupPosition": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "PopupPosition"
        },
        "interactionKeys": {
          "id": 1,
          "rule": "repeated",
          "type": "PrototypeInteractionKey"
        }
      },
      "oneofs": {
        "_popupPosition": {
          "oneof": [
            "popupPosition"
          ]
        }
      }
    },
    "Arg_TraceGpuLostAndCrash": {
      "fields": {
        "vram": {
          "id": 1,
          "type": "uint32"
        }
      }
    },
    "Arg_TransitionType": {
      "fields": {
        "value": {
          "id": 1,
          "type": "TransitionType"
        }
      }
    },
    "Arg_UpdateColorMode": {
      "fields": {
        "colorMode": {
          "id": 1,
          "type": "ColorMode"
        }
      }
    },
    "Arg_UpdateColorVariableValueCommandParam": {
      "fields": {
        "colorRGBAValue": {
          "id": 3,
          "type": "RGBA"
        },
        "variableSetModeId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "variableId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_variableSetModeId": {
          "oneof": [
            "variableSetModeId"
          ]
        },
        "_variableId": {
          "oneof": [
            "variableId"
          ]
        }
      }
    },
    "Arg_UpdateComponentSetProps": {
      "fields": {
        "props": {
          "id": 1,
          "rule": "repeated",
          "type": "ComponentSetProp"
        }
      }
    },
    "Arg_UpdateDocumentColorProfile": {
      "fields": {
        "convertColor": {
          "id": 2,
          "type": "bool"
        },
        "colorProfile": {
          "id": 1,
          "type": "DocumentColorProfile"
        }
      }
    },
    "Arg_UpdateFloatVariableValueCommandParam": {
      "fields": {
        "floatValue": {
          "id": 3,
          "type": "float"
        },
        "variableSetModeId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "variableId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_variableSetModeId": {
          "oneof": [
            "variableSetModeId"
          ]
        },
        "_variableId": {
          "oneof": [
            "variableId"
          ]
        }
      }
    },
    "Arg_UpdateGuideHoveringState": {
      "fields": {
        "interactDistance_": {
          "id": 3,
          "type": "float"
        },
        "cameraY_": {
          "id": 2,
          "type": "float"
        },
        "cameraX_": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "Arg_UpdateInstanceBubble": {
      "fields": {
        "value": {
          "id": 2,
          "type": "bool"
        },
        "nodeIds": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "Arg_UpdateLibraryAssetPanelDisplayMode": {
      "fields": {
        "value": {
          "id": 1,
          "type": "VLibraryAssetPanelDisplayMode"
        }
      }
    },
    "Arg_UpdateLibraryAssetPanelScrollTop": {
      "fields": {
        "value": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "Arg_UpdateLibraryAssetPanelScrollableSize": {
      "fields": {
        "height": {
          "id": 2,
          "type": "float"
        },
        "width": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "Arg_UpdateLibraryPublishModalAllChangesChecked": {
      "fields": {
        "allChecked": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "Arg_UpdateLibraryPublishModalChangeChecked": {
      "fields": {
        "checked": {
          "id": 2,
          "type": "bool"
        },
        "changeKey": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_UpdateLibraryPublishModalScrollTop": {
      "fields": {
        "value": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "Arg_UpdateLibraryPublishModalScrollableSize": {
      "fields": {
        "height": {
          "id": 2,
          "type": "float"
        },
        "width": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "Arg_UpdateLibraryReplaceModalChecked": {
      "fields": {
        "checked": {
          "id": 2,
          "type": "bool"
        },
        "key": {
          "id": 1,
          "type": "LibraryReplaceModalItemCheckedKey"
        }
      }
    },
    "Arg_UpdateNodePrototypeInteraction": {
      "fields": {
        "interactions": {
          "id": 2,
          "rule": "repeated",
          "type": "PrototypeInteraction"
        },
        "node": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_UpdateOverlayBackgroundAppearance": {
      "fields": {
        "backgroundAppearance": {
          "id": 2,
          "type": "OverlayBackgroundAppearance"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_UpdateOverlayBackgroundInteraction": {
      "fields": {
        "backgroundInteraction": {
          "id": 2,
          "type": "OverlayBackgroundInteraction"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_UpdateOverlayPositionType": {
      "fields": {
        "positionType": {
          "id": 2,
          "type": "OverlayPositionType"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_UpdatePrototypeDevice": {
      "fields": {
        "config": {
          "id": 1,
          "type": "PrototypeDevice"
        }
      }
    },
    "Arg_UpdatePrototypeSelectedInteractionKeys": {
      "fields": {
        "interactionKeys": {
          "id": 1,
          "rule": "repeated",
          "type": "PrototypeInteractionKey"
        }
      }
    },
    "Arg_UpdateScrollBehavior": {
      "fields": {
        "behavior": {
          "id": 1,
          "type": "ScrollBehavior"
        }
      }
    },
    "Arg_UpdateScrollOverflow": {
      "fields": {
        "direction": {
          "id": 1,
          "type": "ScrollDirection"
        }
      }
    },
    "Arg_UpdateSelectionPrototypeInteraction": {
      "fields": {
        "interactions": {
          "id": 1,
          "rule": "repeated",
          "type": "PrototypeInteractionWithNodeId"
        }
      }
    },
    "Arg_UpdateVariableCodeSyntaxCommandParam": {
      "fields": {
        "value": {
          "id": 3,
          "type": "string"
        },
        "platform": {
          "id": 2,
          "type": "VariableCodePlatform"
        },
        "variableId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_variableId": {
          "oneof": [
            "variableId"
          ]
        }
      }
    },
    "Arg_UpdateVariableDescriptionCommandParam": {
      "fields": {
        "description": {
          "id": 2,
          "type": "string"
        },
        "variableId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_variableId": {
          "oneof": [
            "variableId"
          ]
        }
      }
    },
    "Arg_UpdateVariablePublishHiddenCommandParam": {
      "fields": {
        "publishHidden": {
          "id": 2,
          "type": "bool"
        },
        "variableId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_variableId": {
          "oneof": [
            "variableId"
          ]
        }
      }
    },
    "Arg_UpdateVariableScopesCommandParam": {
      "fields": {
        "scopes": {
          "id": 2,
          "rule": "repeated",
          "type": "VariableScope"
        },
        "variableId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_variableId": {
          "oneof": [
            "variableId"
          ]
        }
      }
    },
    "Arg_UpdateVariantProps": {
      "fields": {
        "props": {
          "id": 1,
          "rule": "repeated",
          "type": "VariantProp"
        }
      }
    },
    "Arg_UpdateVariantPropsForPublicInstance": {
      "fields": {
        "props": {
          "id": 2,
          "rule": "repeated",
          "type": "VariantProp"
        },
        "instanceId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_instanceId": {
          "oneof": [
            "instanceId"
          ]
        }
      }
    },
    "Arg_UpdateVectorStyleOverrideTableParam": {
      "fields": {
        "styleOverride": {
          "id": 2,
          "type": "StyleOverride"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_UpdateVirtualScrollTop": {
      "fields": {
        "value": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "Arg_UpdateVirtualScrollableSize": {
      "fields": {
        "height": {
          "id": 2,
          "type": "float"
        },
        "width": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "Arg_UploadBitmapToGPU": {
      "fields": {
        "format": {
          "id": 5,
          "type": "SerTcFormat"
        },
        "chunkIndexLeft": {
          "id": 3,
          "type": "uint32"
        },
        "chunkIndexRight": {
          "id": 4,
          "type": "uint32"
        },
        "resourcePixelsHandle": {
          "id": 2,
          "type": "uint32"
        },
        "textureHandle": {
          "id": 1,
          "type": "uint64"
        }
      }
    },
    "Arg_UploadTextureForMotiffLib": {
      "fields": {
        "compressionFormat": {
          "id": 5,
          "type": "SerTcFormat"
        },
        "data": {
          "id": 4,
          "type": "bytes"
        },
        "height": {
          "id": 3,
          "type": "int32"
        },
        "width": {
          "id": 2,
          "type": "int32"
        },
        "textureHandle": {
          "id": 1,
          "type": "uint64"
        }
      }
    },
    "Arg_autoSaveRepository": {
      "fields": {
        "refNodes": {
          "id": 4,
          "keyType": "string",
          "type": "bytes"
        },
        "schemaVersion": {
          "id": 3,
          "type": "int32"
        },
        "blobMap": {
          "id": 2,
          "keyType": "int64",
          "type": "bytes"
        },
        "opMap": {
          "id": 1,
          "keyType": "string",
          "type": "bytes"
        }
      }
    },
    "Arg_buildComponentUpdateVO": {
      "fields": {
        "libraryId": {
          "id": 3,
          "type": "string"
        },
        "key": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "id": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_key": {
          "oneof": [
            "key"
          ]
        },
        "_id": {
          "oneof": [
            "id"
          ]
        }
      }
    },
    "Arg_buildStyleUpdateVO": {
      "fields": {
        "libraryId": {
          "id": 3,
          "type": "string"
        },
        "key": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "id": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_key": {
          "oneof": [
            "key"
          ]
        },
        "_id": {
          "oneof": [
            "id"
          ]
        }
      }
    },
    "Arg_buildVariableSetUpdateVO": {
      "fields": {
        "libraryId": {
          "id": 3,
          "type": "string"
        },
        "key": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "id": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_key": {
          "oneof": [
            "key"
          ]
        },
        "_id": {
          "oneof": [
            "id"
          ]
        }
      }
    },
    "Arg_buildVariableUpdateVO": {
      "fields": {
        "libraryId": {
          "id": 3,
          "type": "string"
        },
        "key": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "id": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_key": {
          "oneof": [
            "key"
          ]
        },
        "_id": {
          "oneof": [
            "id"
          ]
        }
      }
    },
    "Arg_changeSynergyState": {
      "fields": {
        "value": {
          "id": 1,
          "type": "SynergyState"
        }
      }
    },
    "Arg_checkComponentStyleMovement": {
      "fields": {
        "needNotificationMovedNodeIds": {
          "id": 3,
          "rule": "repeated",
          "type": "string"
        },
        "nodeId2StyleId": {
          "id": 2,
          "keyType": "string",
          "type": "string"
        },
        "nodeId2ComponentId": {
          "id": 1,
          "keyType": "string",
          "type": "string"
        }
      }
    },
    "Arg_checkUnderEditorMode": {
      "fields": {
        "modes": {
          "id": 1,
          "rule": "repeated",
          "type": "EditorMode"
        }
      }
    },
    "Arg_clipboardReadProxyFiles": {
      "fields": {
        "callbackId": {
          "id": 2,
          "type": "int32"
        },
        "blobIds": {
          "id": 1,
          "rule": "repeated",
          "type": "int32"
        }
      }
    },
    "Arg_clipboardWriteCanvasImageBlobs": {
      "fields": {
        "asyncId": {
          "id": 2,
          "type": "int32"
        },
        "items": {
          "id": 1,
          "rule": "repeated",
          "type": "WkClipboardCanvasImageBlob"
        }
      }
    },
    "Arg_clipboardWriteFiles": {
      "fields": {
        "files": {
          "id": 1,
          "rule": "repeated",
          "type": "WkClipboardFile"
        }
      }
    },
    "Arg_clipboardWriteItems": {
      "fields": {
        "items": {
          "id": 1,
          "rule": "repeated",
          "type": "WkClipboardItemForWrite"
        }
      }
    },
    "Arg_cmdApplyComponentPropRef": {
      "fields": {
        "propId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "targetNodes": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      },
      "oneofs": {
        "_propId": {
          "oneof": [
            "propId"
          ]
        }
      }
    },
    "Arg_cmdCreateComponentPropDefine": {
      "fields": {
        "name": {
          "id": 3,
          "type": "string"
        },
        "value": {
          "id": 2,
          "type": "VariableAnyValue"
        },
        "preferredValues": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "ComponentPropPreferredValues"
        },
        "targetNode": {
          "id": 4,
          "type": "string"
        },
        "type": {
          "id": 1,
          "type": "ComponentPropType"
        }
      },
      "oneofs": {
        "_preferredValues": {
          "oneof": [
            "preferredValues"
          ]
        }
      }
    },
    "Arg_cmdCreateComponentPropDefineForSelected": {
      "fields": {
        "name": {
          "id": 3,
          "type": "string"
        },
        "value": {
          "id": 2,
          "type": "VariableAnyValue"
        },
        "preferredValues": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "ComponentPropPreferredValues"
        },
        "type": {
          "id": 1,
          "type": "ComponentPropType"
        }
      },
      "oneofs": {
        "_preferredValues": {
          "oneof": [
            "preferredValues"
          ]
        }
      }
    },
    "Arg_cmdCurrentPageBackground": {
      "fields": {
        "paint": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "Paint"
        }
      },
      "oneofs": {
        "_paint": {
          "oneof": [
            "paint"
          ]
        }
      }
    },
    "Arg_cmdDetachComponentPropDefDatas": {
      "fields": {
        "detachDatas": {
          "id": 1,
          "rule": "repeated",
          "type": "DetachComponentPropDefData"
        }
      }
    },
    "Arg_cmdDragSelectionToNodeIndent": {
      "fields": {
        "dragExpandNodeIds": {
          "id": 3,
          "rule": "repeated",
          "type": "string"
        },
        "altKey": {
          "id": 4,
          "type": "bool"
        },
        "indent": {
          "id": 2,
          "type": "int32"
        },
        "item": {
          "id": 1,
          "type": "VLayerPanelItemInfo"
        }
      }
    },
    "Arg_cmdDropComponentV2": {
      "fields": {
        "toCreateNodeId": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "exportedDocument": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "SerializedExportedDocument"
        },
        "altKey": {
          "id": 6,
          "type": "bool"
        },
        "anchorPoint": {
          "id": 5,
          "type": "Vector"
        },
        "mousePoint": {
          "id": 4,
          "type": "Vector"
        },
        "hasLocal": {
          "id": 1,
          "type": "bool"
        }
      },
      "oneofs": {
        "_toCreateNodeId": {
          "oneof": [
            "toCreateNodeId"
          ]
        },
        "_exportedDocument": {
          "oneof": [
            "exportedDocument"
          ]
        }
      }
    },
    "Arg_cmdEditComponentPropData": {
      "fields": {
        "preferredValues": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "ComponentPropPreferredValues"
        },
        "value": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "VariableAnyValue"
        },
        "name": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "propId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_preferredValues": {
          "oneof": [
            "preferredValues"
          ]
        },
        "_value": {
          "oneof": [
            "value"
          ]
        },
        "_name": {
          "oneof": [
            "name"
          ]
        },
        "_propId": {
          "oneof": [
            "propId"
          ]
        }
      }
    },
    "Arg_cmdEndTextEditing": {
      "fields": {
        "keepEmptyNode": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "Arg_cmdGetContainerIdByIndent": {
      "fields": {
        "indent": {
          "id": 2,
          "type": "int32"
        },
        "item": {
          "id": 1,
          "type": "VLayerPanelItemInfo"
        }
      }
    },
    "Arg_cmdHandToolDuringDragging": {
      "fields": {
        "currentY": {
          "id": 2,
          "type": "float"
        },
        "currentX": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "Arg_cmdHandToolStartDragging": {
      "fields": {
        "offsetY": {
          "id": 2,
          "type": "float"
        },
        "offsetX": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "Arg_cmdHoverMultiNodes": {
      "fields": {
        "color": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "MultiHoverBorderColorType"
        },
        "nodeIds": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      },
      "oneofs": {
        "_color": {
          "oneof": [
            "color"
          ]
        }
      }
    },
    "Arg_cmdLayerPanelRename": {
      "fields": {
        "name": {
          "id": 2,
          "type": "string"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_cmdLayerPanelUpdateLock": {
      "fields": {
        "lock": {
          "id": 2,
          "type": "bool"
        },
        "nodeId": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_cmdLayerPanelUpdateVisible": {
      "fields": {
        "visible": {
          "id": 2,
          "type": "bool"
        },
        "nodeId": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_cmdLibraryPublishModalPublishChecked": {
      "fields": {
        "shareScopeType": {
          "id": 1,
          "type": "LibraryShareScopeType"
        }
      }
    },
    "Arg_cmdPassedComponentPropData": {
      "fields": {
        "type": {
          "id": 2,
          "type": "ComponentPropNodeField"
        },
        "propId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_propId": {
          "oneof": [
            "propId"
          ]
        }
      }
    },
    "Arg_cmdReorderComponentPropDefs": {
      "fields": {
        "defIds": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "Arg_cmdResetComponentProp": {
      "fields": {
        "nodeIds": {
          "id": 2,
          "rule": "repeated",
          "type": "string"
        },
        "propId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_propId": {
          "oneof": [
            "propId"
          ]
        }
      }
    },
    "Arg_cmdResetSpecificComponentPropOnInstance": {
      "fields": {
        "defId2EffectdNodeIds": {
          "id": 1,
          "keyType": "string",
          "type": "EffectdNodeIds"
        }
      }
    },
    "Arg_cmdResizeViewport": {
      "fields": {
        "dLeft": {
          "id": 3,
          "type": "float"
        },
        "dTop": {
          "id": 4,
          "type": "float"
        },
        "height": {
          "id": 2,
          "type": "float"
        },
        "width": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "Arg_cmdSetTextValue": {
      "fields": {
        "value": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_cmdShowStartEditingHandlerForPaintStyle": {
      "fields": {
        "freshPopupState": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "PopupState"
        },
        "freshAttrPanelStyleEditorState": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "AttrPanelStyleEditorState"
        },
        "type": {
          "id": 1,
          "type": "string"
        }
      },
      "oneofs": {
        "_freshPopupState": {
          "oneof": [
            "freshPopupState"
          ]
        },
        "_freshAttrPanelStyleEditorState": {
          "oneof": [
            "freshAttrPanelStyleEditorState"
          ]
        }
      }
    },
    "Arg_cmdTextInput": {
      "fields": {
        "value": {
          "id": 2,
          "type": "string"
        },
        "mode": {
          "id": 1,
          "type": "InputMode"
        }
      }
    },
    "Arg_cmdUpdateCoactorObserving": {
      "fields": {
        "value": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        }
      },
      "oneofs": {
        "_value": {
          "oneof": [
            "value"
          ]
        }
      }
    },
    "Arg_cmdUpdateMouseCameraPosition": {
      "fields": {
        "valueType": {
          "id": 3,
          "type": "ProtoValueType"
        },
        "clientY": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "clientX": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        }
      },
      "oneofs": {
        "_clientY": {
          "oneof": [
            "clientY"
          ]
        },
        "_clientX": {
          "oneof": [
            "clientX"
          ]
        }
      }
    },
    "Arg_cmdUpdatePrototypeBackgroundColor": {
      "fields": {
        "paint": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "Paint"
        }
      },
      "oneofs": {
        "_paint": {
          "oneof": [
            "paint"
          ]
        }
      }
    },
    "Arg_cmdUpdatePrototypeFlowOrderIndex": {
      "fields": {
        "nextNodeId": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "prevNodeId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "nodeIds": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      },
      "oneofs": {
        "_nextNodeId": {
          "oneof": [
            "nextNodeId"
          ]
        },
        "_prevNodeId": {
          "oneof": [
            "prevNodeId"
          ]
        }
      }
    },
    "Arg_cmdUpdatePrototypeStartingPointNameById": {
      "fields": {
        "name": {
          "id": 2,
          "type": "string"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_createCanvasToRender": {
      "fields": {
        "height": {
          "id": 2,
          "type": "int32"
        },
        "width": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "Arg_createEffectStylePreview": {
      "fields": {
        "effects": {
          "id": 4,
          "rule": "repeated",
          "type": "Effect"
        },
        "multiple": {
          "id": 3,
          "type": "float"
        },
        "height": {
          "id": 2,
          "type": "float"
        },
        "width": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "Arg_createGradientThumbnail": {
      "fields": {
        "gradientPaint": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "Paint"
        },
        "height": {
          "id": 2,
          "type": "float"
        },
        "width": {
          "id": 1,
          "type": "float"
        }
      },
      "oneofs": {
        "_gradientPaint": {
          "oneof": [
            "gradientPaint"
          ]
        }
      }
    },
    "Arg_createImageThumbnail": {
      "fields": {
        "imagePaint": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "Paint"
        },
        "height": {
          "id": 2,
          "type": "float"
        },
        "width": {
          "id": 1,
          "type": "float"
        }
      },
      "oneofs": {
        "_imagePaint": {
          "oneof": [
            "imagePaint"
          ]
        }
      }
    },
    "Arg_createPaintStylePreview": {
      "fields": {
        "paints": {
          "id": 3,
          "rule": "repeated",
          "type": "Paint"
        },
        "height": {
          "id": 2,
          "type": "float"
        },
        "width": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "Arg_createPaintStyleThumbnail": {
      "fields": {
        "taskId": {
          "id": 4,
          "type": "int32"
        },
        "paints": {
          "id": 3,
          "rule": "repeated",
          "type": "Paint"
        },
        "height": {
          "id": 2,
          "type": "float"
        },
        "width": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "Arg_createPlaceImageCursor": {
      "fields": {
        "devicePixelRatio": {
          "id": 4,
          "type": "float"
        },
        "isSVG": {
          "id": 5,
          "type": "bool"
        },
        "maxSize": {
          "id": 3,
          "type": "int32"
        },
        "imageCount": {
          "id": 2,
          "type": "int32"
        },
        "imageHash": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_createTextStylePreview": {
      "fields": {
        "style": {
          "id": 4,
          "type": "TextStyle"
        },
        "devicePixelRatio": {
          "id": 3,
          "type": "float"
        },
        "height": {
          "id": 2,
          "type": "float"
        },
        "width": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "Arg_createTextStyleThumbnail": {
      "fields": {
        "ignoreChineseFont": {
          "id": 6,
          "type": "bool"
        },
        "taskId": {
          "id": 5,
          "type": "int32"
        },
        "style": {
          "id": 4,
          "type": "TextStyle"
        },
        "devicePixelRatio": {
          "id": 3,
          "type": "float"
        },
        "height": {
          "id": 2,
          "type": "float"
        },
        "width": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "Arg_cursor_compare": {
      "fields": {
        "realRes": {
          "id": 2,
          "type": "CursorMode"
        },
        "testRes": {
          "id": 1,
          "type": "CursorMode"
        }
      }
    },
    "Arg_dispatchMouseEvent": {
      "fields": {
        "acceptable": {
          "id": 13,
          "type": "bool"
        },
        "metaKey": {
          "id": 9,
          "type": "bool"
        },
        "altKey": {
          "id": 8,
          "type": "bool"
        },
        "button": {
          "id": 11,
          "type": "uint32"
        },
        "triggerByKeyboard": {
          "id": 10,
          "type": "bool"
        },
        "movementY": {
          "id": 5,
          "type": "float"
        },
        "clientY": {
          "id": 3,
          "type": "float"
        },
        "clientX": {
          "id": 2,
          "type": "float"
        },
        "movementX": {
          "id": 4,
          "type": "float"
        },
        "buttons": {
          "id": 12,
          "type": "uint32"
        },
        "shiftKey": {
          "id": 7,
          "type": "bool"
        },
        "ctrlKey": {
          "id": 6,
          "type": "bool"
        },
        "type": {
          "id": 1,
          "type": "MouseEventType"
        }
      }
    },
    "Arg_downloadFont": {
      "fields": {
        "type": {
          "id": 5,
          "type": "DownloadFontType"
        },
        "isFallback": {
          "id": 4,
          "type": "bool"
        },
        "loadInBoostrap": {
          "id": 3,
          "type": "bool"
        },
        "textNodeId": {
          "id": 2,
          "type": "string"
        },
        "fontNames": {
          "id": 1,
          "rule": "repeated",
          "type": "FontNameRef"
        }
      }
    },
    "Arg_evalJsScript": {
      "fields": {
        "vars": {
          "id": 2,
          "keyType": "string",
          "type": "string"
        },
        "script": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_exportDocument": {
      "fields": {
        "type": {
          "id": 1,
          "type": "ExportDocumentType"
        }
      }
    },
    "Arg_exportHTML": {
      "fields": {
        "extraMetaInfoKeys": {
          "id": 4,
          "rule": "repeated",
          "type": "string"
        },
        "extraMetaInfoValues": {
          "id": 5,
          "rule": "repeated",
          "type": "string"
        },
        "withJpg": {
          "id": 3,
          "type": "bool"
        },
        "withVariants": {
          "id": 6,
          "type": "bool"
        },
        "enableFlex": {
          "id": 2,
          "type": "bool"
        },
        "nodeIds": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "Arg_exportImage": {
      "fields": {
        "colorProfile": {
          "id": 7,
          "type": "DocumentColorProfile"
        },
        "useAbsoluteBounds": {
          "id": 6,
          "type": "bool"
        },
        "nodeIds": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        },
        "isCompress": {
          "id": 4,
          "type": "bool"
        },
        "imageFormat": {
          "id": 3,
          "type": "ImageFormat"
        },
        "forceClip": {
          "id": 5,
          "type": "bool"
        },
        "constraint": {
          "id": 2,
          "type": "ExportConstraintByFloat"
        }
      }
    },
    "Arg_exportImageOfNodes": {
      "fields": {
        "imageFormat": {
          "id": 3,
          "type": "ImageFormat"
        },
        "forceClip": {
          "id": 4,
          "type": "bool"
        },
        "constraint": {
          "id": 2,
          "type": "ExportConstraintByFloat"
        },
        "nodeIds": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "Arg_exportImagePixels": {
      "fields": {
        "constraint": {
          "id": 2,
          "type": "ExportConstraintByFloat"
        },
        "nodeIds": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "Arg_exportJson": {
      "fields": {
        "shouldCompress": {
          "id": 2,
          "type": "bool"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_exportPDF": {
      "fields": {
        "colorProfile": {
          "id": 2,
          "type": "DocumentColorProfile"
        },
        "nodeIds": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "Arg_exportPng": {
      "fields": {
        "useAbsoluteBounds": {
          "id": 5,
          "type": "bool"
        },
        "forceClip": {
          "id": 4,
          "type": "bool"
        },
        "ancestorClip": {
          "id": 3,
          "type": "bool"
        },
        "constraint": {
          "id": 2,
          "type": "ExportConstraintByFloat"
        },
        "nodeIds": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "Arg_exportPrototypeCoverImage": {
      "fields": {
        "pageId": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_exportSvg": {
      "fields": {
        "colorProfile": {
          "id": 2,
          "type": "DocumentColorProfile"
        },
        "nodeIds": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "Arg_fetchRemoteLibraryChanges": {
      "fields": {
        "key2OldLibraryId": {
          "id": 6,
          "keyType": "string",
          "type": "string"
        },
        "variableSetKeys": {
          "id": 5,
          "rule": "repeated",
          "type": "string"
        },
        "variableKeys": {
          "id": 4,
          "rule": "repeated",
          "type": "string"
        },
        "componentKeys": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        },
        "componentSetKeys": {
          "id": 2,
          "rule": "repeated",
          "type": "string"
        },
        "styleKeys": {
          "id": 3,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "Arg_finishRendering": {
      "fields": {
        "isCompress": {
          "id": 3,
          "type": "bool"
        },
        "imageFormat": {
          "id": 2,
          "type": "ImageFormat"
        },
        "id": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "Arg_focusView": {
      "fields": {
        "focusView": {
          "id": 1,
          "type": "FocusView"
        }
      }
    },
    "Arg_generatePublishMixinThumbnail": {
      "fields": {
        "id": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_id": {
          "oneof": [
            "id"
          ]
        }
      }
    },
    "Arg_generateThumbnail": {
      "fields": {
        "contentHash": {
          "id": 2,
          "type": "string"
        },
        "id": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_getImageOfFigNode": {
      "fields": {
        "magicBounds": {
          "id": 5,
          "type": "Rect"
        },
        "measureTextBounds": {
          "id": 4,
          "type": "bool"
        },
        "nodeBlobInfo": {
          "id": 3,
          "type": "string"
        },
        "nodes": {
          "id": 2,
          "rule": "repeated",
          "type": "AiCopilotCandidateNode"
        },
        "scale": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "Arg_getLineHeight": {
      "fields": {
        "fontSize": {
          "id": 2,
          "type": "float"
        },
        "fontName": {
          "id": 1,
          "type": "FontName"
        }
      }
    },
    "Arg_insertPageAfter": {
      "fields": {
        "prevSibling": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "node": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_prevSibling": {
          "oneof": [
            "prevSibling"
          ]
        },
        "_node": {
          "oneof": [
            "node"
          ]
        }
      }
    },
    "Arg_insertPageBefore": {
      "fields": {
        "nextSibling": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "node": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nextSibling": {
          "oneof": [
            "nextSibling"
          ]
        },
        "_node": {
          "oneof": [
            "node"
          ]
        }
      }
    },
    "Arg_keyValue": {
      "fields": {
        "value": {
          "id": 2,
          "type": "string"
        },
        "key": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_loadDocument": {
      "fields": {
        "buffer": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_loadFont": {
      "fields": {
        "fontName": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "FontName"
        },
        "url": {
          "id": 2,
          "type": "string"
        },
        "data": {
          "id": 1,
          "type": "bytes"
        }
      },
      "oneofs": {
        "_fontName": {
          "oneof": [
            "fontName"
          ]
        }
      }
    },
    "Arg_loadFontInJs": {
      "fields": {
        "textNodeId": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "fontName": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "FontNameRef"
        },
        "isFallback": {
          "id": 5,
          "type": "bool"
        },
        "size": {
          "id": 2,
          "type": "int32"
        },
        "url": {
          "id": 1,
          "type": "string"
        }
      },
      "oneofs": {
        "_textNodeId": {
          "oneof": [
            "textNodeId"
          ]
        },
        "_fontName": {
          "oneof": [
            "fontName"
          ]
        }
      }
    },
    "Arg_loadFontInJsFailed": {
      "fields": {
        "isFallback": {
          "id": 3,
          "type": "bool"
        },
        "textNodeId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "fontName": {
          "id": 1,
          "type": "FontName"
        }
      },
      "oneofs": {
        "_textNodeId": {
          "oneof": [
            "textNodeId"
          ]
        }
      }
    },
    "Arg_naturalSortStringArray": {
      "fields": {
        "targetList": {
          "id": 1,
          "rule": "repeated",
          "type": "NaturalSortStringItem"
        }
      }
    },
    "Arg_navigatoToComponentPropOnComponent": {
      "fields": {
        "defId": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_navigatoToComponentPropOnInstance": {
      "fields": {
        "defId": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_onBatchOperation": {
      "fields": {
        "docHash": {
          "id": 5,
          "type": "uint64"
        },
        "checksum": {
          "id": 3,
          "type": "int64"
        },
        "prevChecksum": {
          "id": 4,
          "type": "int64"
        },
        "compressType": {
          "id": 6,
          "type": "CompressType"
        },
        "serverPayload": {
          "id": 2,
          "type": "bytes"
        },
        "payload": {
          "id": 1,
          "type": "bytes"
        }
      }
    },
    "Arg_onBatchOperationAck": {
      "fields": {
        "checksum": {
          "id": 3,
          "type": "int64"
        },
        "compressType": {
          "id": 5,
          "type": "CompressType"
        },
        "prevChecksum": {
          "id": 4,
          "type": "int64"
        },
        "serverPayload": {
          "id": 2,
          "type": "bytes"
        },
        "payload": {
          "id": 1,
          "type": "bytes"
        }
      }
    },
    "Arg_onBatchOperations": {
      "fields": {
        "compressType": {
          "id": 2,
          "type": "CompressType"
        },
        "opLogs": {
          "id": 1,
          "rule": "repeated",
          "type": "bytes"
        }
      }
    },
    "Arg_onClipboardReadProxyFiles": {
      "fields": {
        "callbackId": {
          "id": 2,
          "type": "int32"
        },
        "files": {
          "id": 1,
          "rule": "repeated",
          "type": "WkClipboardFile"
        }
      }
    },
    "Arg_onDocument": {
      "fields": {
        "onlyDiff": {
          "id": 14,
          "type": "bool"
        },
        "refNodes": {
          "id": 13,
          "keyType": "string",
          "type": "bytes"
        },
        "opLogs": {
          "id": 12,
          "rule": "repeated",
          "type": "bytes"
        },
        "index": {
          "id": 11,
          "type": "SynergyOperationIndex"
        },
        "checksum": {
          "id": 7,
          "type": "int64"
        },
        "sid": {
          "id": 10,
          "type": "int32"
        },
        "docHash": {
          "id": 8,
          "type": "uint64"
        },
        "finalFragment": {
          "id": 6,
          "type": "bool"
        },
        "localOperations": {
          "id": 4,
          "rule": "repeated",
          "type": "bytes"
        },
        "localBlobs": {
          "id": 5,
          "keyType": "int64",
          "type": "bytes"
        },
        "compressType": {
          "id": 9,
          "type": "CompressType"
        },
        "serverPayload": {
          "id": 3,
          "type": "bytes"
        },
        "docId": {
          "id": 2,
          "type": "string"
        },
        "payload": {
          "id": 1,
          "type": "bytes"
        }
      }
    },
    "Arg_onFrameData": {
      "fields": {
        "frame": {
          "id": 1,
          "type": "FrameTracingInfo"
        }
      }
    },
    "Arg_postMessage": {
      "fields": {
        "origin": {
          "id": 2,
          "type": "string"
        },
        "msg": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_publishLibraryComponents": {
      "fields": {
        "shareScope": {
          "id": 8,
          "type": "string"
        },
        "libraryShared": {
          "id": 7,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "libraryId": {
          "id": 6,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "styles": {
          "id": 3,
          "rule": "repeated",
          "type": "LibraryPublishComponentItem"
        },
        "components": {
          "id": 1,
          "rule": "repeated",
          "type": "LibraryPublishComponentItem"
        },
        "variables": {
          "id": 5,
          "rule": "repeated",
          "type": "LibraryPublishComponentItem"
        },
        "moveRemappings": {
          "id": 10,
          "keyType": "string",
          "type": "string"
        },
        "description": {
          "id": 9,
          "type": "string"
        },
        "variableSets": {
          "id": 4,
          "rule": "repeated",
          "type": "LibraryPublishComponentItem"
        },
        "componentSets": {
          "id": 2,
          "rule": "repeated",
          "type": "LibraryPublishComponentItem"
        }
      },
      "oneofs": {
        "_libraryShared": {
          "oneof": [
            "libraryShared"
          ]
        },
        "_libraryId": {
          "oneof": [
            "libraryId"
          ]
        }
      }
    },
    "Arg_publishLibraryManifest": {
      "fields": {
        "unpublishedComponentIds": {
          "id": 4,
          "rule": "repeated",
          "type": "string"
        },
        "moveRemappings": {
          "id": 3,
          "keyType": "string",
          "type": "string"
        },
        "unpublishedComponentSetIds": {
          "id": 8,
          "rule": "repeated",
          "type": "string"
        },
        "publishedVariableCollectionNodeIds": {
          "id": 11,
          "rule": "repeated",
          "type": "string"
        },
        "unpublishedVariableIds": {
          "id": 12,
          "rule": "repeated",
          "type": "string"
        },
        "unpublishedVariableCollectionIds": {
          "id": 10,
          "rule": "repeated",
          "type": "string"
        },
        "publishedComponentSetNodeIds": {
          "id": 9,
          "rule": "repeated",
          "type": "string"
        },
        "unpublishedStyleIds": {
          "id": 6,
          "rule": "repeated",
          "type": "string"
        },
        "publishedVariableNodeIds": {
          "id": 13,
          "rule": "repeated",
          "type": "string"
        },
        "description": {
          "id": 2,
          "type": "string"
        },
        "shareScope": {
          "id": 1,
          "type": "string"
        },
        "publishedComponentNodeIds": {
          "id": 5,
          "rule": "repeated",
          "type": "string"
        },
        "publishedStyleNodeIds": {
          "id": 7,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "Arg_readFontInJs": {
      "fields": {
        "position": {
          "id": 2,
          "type": "int32"
        },
        "ptr": {
          "id": 4,
          "type": "uint64"
        },
        "size": {
          "id": 3,
          "type": "int32"
        },
        "url": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_renderToCanvas": {
      "fields": {
        "ptr": {
          "id": 6,
          "type": "int32"
        },
        "height": {
          "id": 5,
          "type": "int32"
        },
        "colorProfile": {
          "id": 8,
          "type": "DocumentColorProfile"
        },
        "length": {
          "id": 7,
          "type": "int32"
        },
        "width": {
          "id": 4,
          "type": "int32"
        },
        "y": {
          "id": 3,
          "type": "int32"
        },
        "x": {
          "id": 2,
          "type": "int32"
        },
        "id": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "Arg_reorderSelectedComponentSetPropOptions": {
      "fields": {
        "newOptions": {
          "id": 2,
          "rule": "repeated",
          "type": "string"
        },
        "selectedVariantPropKey": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_replaceMissingFonts": {
      "fields": {
        "replaceMissingFonts": {
          "id": 1,
          "rule": "repeated",
          "type": "ReplaceMissingFont"
        }
      }
    },
    "Arg_reposition": {
      "fields": {
        "y": {
          "id": 2,
          "type": "float"
        },
        "x": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "Arg_saveVersionHistory": {
      "fields": {
        "cbId": {
          "id": 3,
          "type": "int32"
        },
        "description": {
          "id": 2,
          "type": "string"
        },
        "title": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_saveVersionHistoryCallback": {
      "fields": {
        "success": {
          "id": 2,
          "type": "bool"
        },
        "cbId": {
          "id": 3,
          "type": "int32"
        },
        "id": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_sendBatchOperations": {
      "fields": {
        "beforeCompressSize": {
          "id": 3,
          "type": "uint32"
        },
        "compressType": {
          "id": 2,
          "type": "CompressType"
        },
        "payload": {
          "id": 1,
          "type": "bytes"
        }
      }
    },
    "Arg_setFontFamily": {
      "fields": {
        "isPreset": {
          "id": 2,
          "type": "bool"
        },
        "value": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_setInterval": {
      "fields": {
        "interval": {
          "id": 2,
          "type": "uint32"
        },
        "callback": {
          "id": 1,
          "type": "WasmCallCode"
        }
      }
    },
    "Arg_setLocalEditorType": {
      "fields": {
        "value": {
          "id": 1,
          "type": "EditorType"
        }
      }
    },
    "Arg_setTimeoutForCommand": {
      "fields": {
        "timeout": {
          "id": 2,
          "type": "uint32"
        },
        "callback": {
          "id": 1,
          "type": "WasmCallCode"
        }
      }
    },
    "Arg_setTimeoutToReloadImage": {
      "fields": {
        "timeout": {
          "id": 2,
          "type": "int32"
        },
        "imageHash": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_showTitleInput": {
      "fields": {
        "editingNodeId": {
          "id": 6,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "rotation": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "text": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "maxWidth": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "position": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "Vector"
        },
        "show": {
          "id": 1,
          "type": "bool"
        }
      },
      "oneofs": {
        "_editingNodeId": {
          "oneof": [
            "editingNodeId"
          ]
        },
        "_rotation": {
          "oneof": [
            "rotation"
          ]
        },
        "_text": {
          "oneof": [
            "text"
          ]
        },
        "_maxWidth": {
          "oneof": [
            "maxWidth"
          ]
        },
        "_position": {
          "oneof": [
            "position"
          ]
        }
      }
    },
    "Arg_startClipboardCopy": {
      "fields": {
        "type": {
          "id": 1,
          "type": "ClipboardCopyType"
        }
      }
    },
    "Arg_startClipboardPaste": {
      "fields": {
        "type": {
          "id": 1,
          "type": "ClipboardPasteType"
        }
      }
    },
    "Arg_startLibraryReplace": {
      "fields": {
        "componentSetList": {
          "id": 5,
          "rule": "repeated",
          "type": "Arg_startLibraryReplaceItem"
        },
        "styleList": {
          "id": 3,
          "rule": "repeated",
          "type": "Arg_startLibraryReplaceItem"
        },
        "componentList": {
          "id": 4,
          "rule": "repeated",
          "type": "Arg_startLibraryReplaceItem"
        },
        "targetDocumentId": {
          "id": 2,
          "type": "string"
        },
        "count": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "Arg_startLibraryReplaceItem": {
      "fields": {
        "target": {
          "id": 2,
          "type": "Arg_startLibraryReplaceTargetItem"
        },
        "origin": {
          "id": 1,
          "type": "Arg_startLibraryReplaceOriginItem"
        }
      }
    },
    "Arg_startLibraryReplaceOriginItem": {
      "fields": {
        "publishFile": {
          "id": 3,
          "type": "string"
        },
        "remoteId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "publishId": {
          "id": 1,
          "type": "string"
        }
      },
      "oneofs": {
        "_remoteId": {
          "oneof": [
            "remoteId"
          ]
        }
      }
    },
    "Arg_startLibraryReplaceTargetItem": {
      "fields": {
        "remoteId": {
          "id": 3,
          "type": "string"
        },
        "contentHash": {
          "id": 4,
          "type": "string"
        },
        "publishId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "nodeDataPath": {
          "id": 1,
          "type": "string"
        }
      },
      "oneofs": {
        "_publishId": {
          "oneof": [
            "publishId"
          ]
        }
      }
    },
    "Arg_switchEditorType": {
      "fields": {
        "type": {
          "id": 1,
          "type": "EditorType"
        }
      }
    },
    "Arg_syncRemoteDocLibraryNodeKeys": {
      "fields": {
        "items": {
          "id": 1,
          "rule": "repeated",
          "type": "Arg_syncRemoteDocLibraryNodeKeysItem"
        }
      }
    },
    "Arg_syncRemoteDocLibraryNodeKeysItem": {
      "fields": {
        "nodeId": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "publishId": {
          "id": 2,
          "type": "string"
        },
        "publishFile": {
          "id": 1,
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_textMeaure": {
      "fields": {
        "skipUpdateParentLayout": {
          "id": 3,
          "type": "bool"
        },
        "inApplyLayout": {
          "id": 2,
          "type": "bool"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Arg_toastInfo": {
      "fields": {
        "dataTestId": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "type": {
          "id": 2,
          "type": "ToastType"
        },
        "value": {
          "id": 1,
          "type": "string"
        }
      },
      "oneofs": {
        "_dataTestId": {
          "oneof": [
            "dataTestId"
          ]
        }
      }
    },
    "Arg_toggleComponentPicker": {
      "fields": {
        "instanceSwapAssignment": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "VInstanceCompProp"
        },
        "popupStateType": {
          "id": 1,
          "type": "PopupStateType"
        }
      },
      "oneofs": {
        "_instanceSwapAssignment": {
          "oneof": [
            "instanceSwapAssignment"
          ]
        }
      }
    },
    "Arg_toggleComponentPropPopup": {
      "fields": {
        "editPopupVal": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "ComponentPropEditPopupVal"
        },
        "popupStateType": {
          "id": 1,
          "type": "PopupStateType"
        }
      },
      "oneofs": {
        "_editPopupVal": {
          "oneof": [
            "editPopupVal"
          ]
        }
      }
    },
    "Arg_traceEvent": {
      "fields": {
        "includesStack": {
          "id": 2,
          "type": "bool"
        },
        "value": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_updateNodeConstraints": {
      "fields": {
        "vertical": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "ConstraintType"
        },
        "horizontal": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "ConstraintType"
        },
        "passIds": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      },
      "oneofs": {
        "_vertical": {
          "oneof": [
            "vertical"
          ]
        },
        "_horizontal": {
          "oneof": [
            "horizontal"
          ]
        }
      }
    },
    "Arg_updateNodeValAboutCompPropInInstance": {
      "fields": {
        "mainComponentId": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "visible": {
          "id": 2,
          "type": "bool"
        },
        "ids": {
          "id": 5,
          "rule": "repeated",
          "type": "string"
        },
        "textVal": {
          "id": 3,
          "type": "string"
        },
        "type": {
          "id": 1,
          "type": "ComponentPropType"
        }
      },
      "oneofs": {
        "_mainComponentId": {
          "oneof": [
            "mainComponentId"
          ]
        }
      }
    },
    "Arg_updateSelectedComponentName": {
      "fields": {
        "newName": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Arg_updateSelectedTextRange": {
      "fields": {
        "keepMemoryPos": {
          "id": 2,
          "type": "bool"
        },
        "range": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "TextRange"
        }
      },
      "oneofs": {
        "_range": {
          "oneof": [
            "range"
          ]
        }
      }
    },
    "Arg_updateSubscribedLibraryIds": {
      "fields": {
        "libraryIds": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "Arg_updateSyncCurrentUsedPublishMixinInFileLibraryList": {
      "fields": {
        "removedDocumentIds": {
          "id": 2,
          "rule": "repeated",
          "type": "string"
        },
        "addedDocumentIds": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "Arg_updateVectorPanelXYCommand": {
      "fields": {
        "shouldParseValue": {
          "id": 2,
          "type": "bool"
        },
        "value": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "Arg_uploadImages": {
      "fields": {
        "data": {
          "id": 1,
          "rule": "repeated",
          "type": "ToUploadImageItem"
        }
      }
    },
    "Arg_validateInstanceSwapValue": {
      "fields": {
        "scene": {
          "id": 5,
          "type": "ValidateInstanceSwapValueScene"
        },
        "affectedNodeIds": {
          "id": 4,
          "rule": "repeated",
          "type": "string"
        },
        "updateDefId": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "newInstanceSwapPropId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "newInstanceSwapValue": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_updateDefId": {
          "oneof": [
            "updateDefId"
          ]
        },
        "_newInstanceSwapPropId": {
          "oneof": [
            "newInstanceSwapPropId"
          ]
        },
        "_newInstanceSwapValue": {
          "oneof": [
            "newInstanceSwapValue"
          ]
        }
      }
    },
    "Arg_writeExportDocumentResult": {
      "fields": {
        "type": {
          "id": 3,
          "type": "ExportDocumentType"
        },
        "size": {
          "id": 2,
          "type": "uint64"
        },
        "ptr": {
          "id": 1,
          "type": "uint64"
        }
      }
    },
    "Args_AddAutoLayout": {
      "fields": {
        "origin": {
          "id": 1,
          "type": "AddAutoLayoutOrigin"
        }
      }
    },
    "Args_AeExportCommand": {
      "fields": {
        "shouldExportImage": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "Args_BenchmarkImageDownloadCommand": {
      "fields": {
        "duration": {
          "id": 2,
          "type": "int64"
        },
        "type": {
          "id": 1,
          "type": "MetricImageType"
        }
      }
    },
    "Args_ConfirmTitleInputCommand": {
      "fields": {
        "name": {
          "id": 2,
          "type": "string"
        },
        "nodeId": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Args_DownloadBase64": {
      "fields": {
        "name": {
          "id": 2,
          "type": "string"
        },
        "dataBase64": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Args_EnterHistoryModeCommand": {
      "fields": {
        "layerPanelScrollTop": {
          "id": 2,
          "type": "int32"
        },
        "windowInnerHeight": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "Args_ExportCanvasPng": {
      "fields": {
        "name": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Args_KeyboardCommand": {
      "fields": {
        "altKey": {
          "id": 3,
          "type": "bool"
        },
        "shiftKey": {
          "id": 2,
          "type": "bool"
        },
        "metaKey": {
          "id": 4,
          "type": "bool"
        },
        "ctrlKey": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "Args_LeaveHistoryModeCommand": {
      "fields": {
        "layerPanelScrollTop": {
          "id": 2,
          "type": "int32"
        },
        "docReadonly": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "Args_ResetSelectionAndHistoryVersionIdCommand": {
      "fields": {
        "pageId": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "historyVersionId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_pageId": {
          "oneof": [
            "pageId"
          ]
        },
        "_historyVersionId": {
          "oneof": [
            "historyVersionId"
          ]
        },
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Args_ResetWasmStatusCommand": {
      "fields": {
        "editorType": {
          "id": 13,
          "type": "EditorType"
        },
        "canvasSearchParam": {
          "id": 12,
          "type": "CanvasSearchParam"
        },
        "enabledViewStateTokens": {
          "id": 11,
          "rule": "repeated",
          "type": "ViewStateToken"
        },
        "showUseMemory": {
          "id": 10,
          "type": "bool"
        },
        "showSnapToPixelGrid": {
          "id": 9,
          "type": "bool"
        },
        "showRuler": {
          "id": 4,
          "type": "bool"
        },
        "showSidebar": {
          "id": 8,
          "type": "bool"
        },
        "showFullScreen": {
          "id": 7,
          "type": "bool"
        },
        "showMultiplayerCursor": {
          "id": 1,
          "type": "bool"
        },
        "showSlice": {
          "id": 6,
          "type": "bool"
        },
        "showPixelGrid": {
          "id": 3,
          "type": "bool"
        },
        "showLayoutGrid": {
          "id": 5,
          "type": "bool"
        },
        "showComment": {
          "id": 2,
          "type": "bool"
        }
      }
    },
    "Args_SelectDisplayPanelCommand": {
      "fields": {
        "value": {
          "id": 1,
          "type": "DisplayPanelType"
        }
      }
    },
    "Args_SelectSidebarPanelCommand": {
      "fields": {
        "value": {
          "id": 1,
          "type": "SidebarPanelType"
        }
      }
    },
    "Args_SetDisplayPanelsCommand": {
      "fields": {
        "value": {
          "id": 1,
          "rule": "repeated",
          "type": "DisplayPanel"
        }
      }
    },
    "Args_SetOperateSystemCommand": {
      "fields": {
        "value": {
          "id": 1,
          "type": "OperateSystemType"
        }
      }
    },
    "Args_UpdateActivatedPluginCommand": {
      "fields": {
        "activatedPluginType": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "PluginType"
        }
      },
      "oneofs": {
        "_activatedPluginType": {
          "oneof": [
            "activatedPluginType"
          ]
        }
      }
    },
    "Args_cmdSetRightClickSource": {
      "fields": {
        "value": {
          "id": 1,
          "type": "RightClickSource"
        }
      }
    },
    "Args_updateVectorPanelHandleMirrorCommand": {
      "fields": {
        "value": {
          "id": 1,
          "type": "VectorHandleMirror"
        }
      }
    },
    "AttachAutoLayoutVarParam": {
      "fields": {
        "var": {
          "id": 3,
          "type": "VariableAliasData"
        },
        "field": {
          "id": 2,
          "type": "VariableField"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "AttachCornerRadiusFloatVarParam": {
      "fields": {
        "variableData": {
          "id": 2,
          "type": "VariableAliasData"
        },
        "field": {
          "id": 1,
          "type": "VariableField"
        }
      }
    },
    "AttachMinMaxWidthHeightFloatVar": {
      "fields": {
        "varId": {
          "id": 3,
          "type": "string"
        },
        "nodeId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "type": {
          "id": 1,
          "type": "UpdateMinSizeAndMaxSizeCommandEnum"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "AttachStrokeWeightFloatVarCommandParams": {
      "fields": {
        "variableData": {
          "id": 2,
          "type": "VariableAliasData"
        },
        "fields": {
          "id": 1,
          "rule": "repeated",
          "type": "VariableField"
        }
      }
    },
    "AttrPanelEditingStyleViewState": {
      "fields": {
        "editingLayoutGridStyle": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "VLayoutGridStyleNode"
        },
        "editingEffectStyle": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "VEffectStyleNode"
        },
        "editingPaintStyle": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "VPaintStyleNode"
        },
        "editingTextStyle": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "VTextStyleNodeState"
        }
      },
      "oneofs": {
        "_editingLayoutGridStyle": {
          "oneof": [
            "editingLayoutGridStyle"
          ]
        },
        "_editingEffectStyle": {
          "oneof": [
            "editingEffectStyle"
          ]
        },
        "_editingPaintStyle": {
          "oneof": [
            "editingPaintStyle"
          ]
        },
        "_editingTextStyle": {
          "oneof": [
            "editingTextStyle"
          ]
        }
      }
    },
    "AttrPanelStyleEditorState": {
      "fields": {
        "isCreate": {
          "id": 5,
          "type": "bool"
        },
        "openFromModule": {
          "id": 3,
          "type": "EditorStateFromModule"
        },
        "openFromNodeId": {
          "id": 4,
          "rule": "repeated",
          "type": "string"
        },
        "selectReverseIndex": {
          "id": 2,
          "rule": "repeated",
          "type": "int32"
        },
        "editingStyleId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_editingStyleId": {
          "oneof": [
            "editingStyleId"
          ]
        }
      }
    },
    "AttributeState": {
      "fields": {
        "disableHeightFlexConstraint": {
          "id": 10,
          "type": "bool"
        },
        "disableLockAspect": {
          "id": 7,
          "type": "bool"
        },
        "disableWidthFlexConstraint": {
          "id": 11,
          "type": "bool"
        },
        "showRemoveAutoLayoutContextMenuItem": {
          "id": 6,
          "type": "bool"
        },
        "disableStackModeToggle": {
          "id": 5,
          "type": "bool"
        },
        "disableAlign": {
          "id": 4,
          "type": "bool"
        },
        "disableH": {
          "id": 3,
          "type": "bool"
        },
        "disableContraint": {
          "id": 9,
          "type": "bool"
        },
        "disableAutoLayoutStackMode": {
          "id": 8,
          "type": "bool"
        },
        "disableW": {
          "id": 2,
          "type": "bool"
        },
        "disableXY": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "AutoLayoutAdvancedItem": {
      "fields": {
        "isMixed": {
          "id": 2,
          "type": "bool"
        },
        "value": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "AutoLayoutAdvancedState": {
      "fields": {
        "isAlignToTextBaseline": {
          "id": 3,
          "type": "AutoLayoutAdvancedItem"
        },
        "isReversedZIndex": {
          "id": 2,
          "type": "AutoLayoutAdvancedItem"
        },
        "show": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "AutoLayoutChildUpdatedParam": {
      "fields": {
        "shouldUpdateParent": {
          "id": 5,
          "type": "bool"
        },
        "stackPositioning": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "StackPositioning"
        },
        "stackChildAlignSelf": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "StackCounterAlign"
        },
        "stackChildPrimaryGrow": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_stackPositioning": {
          "oneof": [
            "stackPositioning"
          ]
        },
        "_stackChildAlignSelf": {
          "oneof": [
            "stackChildAlignSelf"
          ]
        },
        "_stackChildPrimaryGrow": {
          "oneof": [
            "stackChildPrimaryGrow"
          ]
        },
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "AutoLayoutFlex": {
      "fields": {
        "padding": {
          "id": 14,
          "type": "AutoLayoutPadding"
        },
        "hasStackWrap": {
          "id": 5,
          "type": "bool"
        },
        "stackCounterAlignItems": {
          "id": 8,
          "type": "StackAlign"
        },
        "stackPrimarySizing": {
          "id": 7,
          "type": "StackSize"
        },
        "stackChildPrimaryGrow": {
          "id": 1,
          "type": "float"
        },
        "stackCounterAlignContent": {
          "id": 10,
          "type": "StackCounterAlignContentPropValue"
        },
        "isStackModeMixed": {
          "id": 4,
          "type": "bool"
        },
        "isFlexMixed": {
          "id": 11,
          "type": "bool"
        },
        "stackCounterSizing": {
          "id": 9,
          "type": "StackSize"
        },
        "stackPrimaryAlignItems": {
          "id": 6,
          "type": "StackJustifyPropValue"
        },
        "stackCounterSpacing": {
          "id": 13,
          "type": "CounterSpacing"
        },
        "stackSpacing": {
          "id": 12,
          "type": "Spacing"
        },
        "stackMode": {
          "id": 3,
          "type": "ComputedStackModeType"
        },
        "stackChildAlignSelf": {
          "id": 2,
          "type": "StackCounterAlign"
        }
      }
    },
    "AutoLayoutMoveChildrenParam": {
      "fields": {
        "altKey": {
          "id": 2,
          "type": "bool"
        },
        "type": {
          "id": 1,
          "type": "AutoLayoutMoveChildrenType"
        }
      }
    },
    "AutoLayoutPadding": {
      "fields": {
        "bottom": {
          "id": 2,
          "type": "Spacing"
        },
        "right": {
          "id": 4,
          "type": "Spacing"
        },
        "horizontal": {
          "id": 3,
          "type": "Spacing"
        },
        "vertical": {
          "id": 1,
          "type": "Spacing"
        }
      }
    },
    "AutoLayoutPanelState": {
      "fields": {
        "autoLayoutChildIds": {
          "id": 8,
          "rule": "repeated",
          "type": "string"
        },
        "displayType": {
          "id": 1,
          "type": "AutoLayoutDisplayType"
        },
        "flex": {
          "id": 5,
          "type": "AutoLayoutFlex"
        },
        "advance": {
          "id": 4,
          "type": "AutoLayoutAdvancedState"
        },
        "attrState": {
          "id": 9,
          "type": "AttributeState"
        },
        "editType": {
          "id": 3,
          "type": "AutoLayoutEditType"
        },
        "autoLayoutParentIds": {
          "id": 7,
          "rule": "repeated",
          "type": "string"
        },
        "floatInput": {
          "id": 6,
          "type": "FloatSpaceInput"
        },
        "wh": {
          "id": 2,
          "type": "AutoLayoutWH"
        }
      }
    },
    "AutoLayoutUpdatedParam": {
      "fields": {
        "stackCounterAlignContent": {
          "id": 15,
          "options": {
            "proto3_optional": true
          },
          "type": "StackCounterAlignContent"
        },
        "detachVariable": {
          "id": 16,
          "type": "bool"
        },
        "stackWrap": {
          "id": 13,
          "options": {
            "proto3_optional": true
          },
          "type": "StackWrap"
        },
        "stackPaddingRight": {
          "id": 11,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "stackPaddingBottom": {
          "id": 9,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "stackVerticalPadding": {
          "id": 8,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "stackCounterSizing": {
          "id": 6,
          "options": {
            "proto3_optional": true
          },
          "type": "StackSize"
        },
        "stackHorizontalPadding": {
          "id": 10,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "stackPrimarySizing": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "StackSize"
        },
        "stackReverseZIndex": {
          "id": 7,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "stackCounterAlignItems": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "StackAlign"
        },
        "stackPrimaryAlignItems": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "StackJustify"
        },
        "stackCounterSpacing": {
          "id": 14,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "stackSpacing": {
          "id": 12,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "stackMode": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "StackMode"
        }
      },
      "oneofs": {
        "_stackCounterAlignContent": {
          "oneof": [
            "stackCounterAlignContent"
          ]
        },
        "_stackWrap": {
          "oneof": [
            "stackWrap"
          ]
        },
        "_stackPaddingRight": {
          "oneof": [
            "stackPaddingRight"
          ]
        },
        "_stackPaddingBottom": {
          "oneof": [
            "stackPaddingBottom"
          ]
        },
        "_stackVerticalPadding": {
          "oneof": [
            "stackVerticalPadding"
          ]
        },
        "_stackCounterSizing": {
          "oneof": [
            "stackCounterSizing"
          ]
        },
        "_stackHorizontalPadding": {
          "oneof": [
            "stackHorizontalPadding"
          ]
        },
        "_stackPrimarySizing": {
          "oneof": [
            "stackPrimarySizing"
          ]
        },
        "_stackReverseZIndex": {
          "oneof": [
            "stackReverseZIndex"
          ]
        },
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        },
        "_stackCounterAlignItems": {
          "oneof": [
            "stackCounterAlignItems"
          ]
        },
        "_stackPrimaryAlignItems": {
          "oneof": [
            "stackPrimaryAlignItems"
          ]
        },
        "_stackCounterSpacing": {
          "oneof": [
            "stackCounterSpacing"
          ]
        },
        "_stackSpacing": {
          "oneof": [
            "stackSpacing"
          ]
        },
        "_stackMode": {
          "oneof": [
            "stackMode"
          ]
        }
      }
    },
    "AutoLayoutWH": {
      "fields": {
        "isPositionMixed": {
          "id": 7,
          "type": "bool"
        },
        "childVerticalFillContainerDisabled": {
          "id": 2,
          "type": "bool"
        },
        "heightType": {
          "id": 5,
          "type": "AutoLayoutWHValue"
        },
        "isPositionAbsolute": {
          "id": 9,
          "type": "bool"
        },
        "widthType": {
          "id": 4,
          "type": "AutoLayoutWHValue"
        },
        "shouldDisplayAbsolute": {
          "id": 6,
          "type": "bool"
        },
        "whType": {
          "id": 3,
          "type": "AutoLayoutWHType"
        },
        "isPositionDisabled": {
          "id": 8,
          "type": "bool"
        },
        "shouldDisplayWH": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "AutoModeInfo": {
      "fields": {
        "isMixed": {
          "id": 2,
          "type": "bool"
        },
        "mode": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "VariableSetMode"
        }
      },
      "oneofs": {
        "_mode": {
          "oneof": [
            "mode"
          ]
        }
      }
    },
    "AvailableFonts": {
      "fields": {
        "availableFontNameRefs": {
          "id": 2,
          "rule": "repeated",
          "type": "FontNameRef"
        },
        "availableFonts": {
          "id": 1,
          "rule": "repeated",
          "type": "FullFontName"
        }
      }
    },
    "BaseAttributeProp": {
      "fields": {
        "editType": {
          "id": 3,
          "type": "BaseAttributePropEditType"
        },
        "valueType": {
          "id": 2,
          "type": "BaseAttributePropValueType"
        },
        "values": {
          "id": 4,
          "keyType": "string",
          "type": "float"
        },
        "value": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "Baseline": {
      "fields": {
        "lineRawHeight": {
          "id": 8,
          "type": "float"
        },
        "firstCharacter": {
          "id": 6,
          "type": "int32"
        },
        "lineAscent": {
          "id": 5,
          "type": "float"
        },
        "lineY": {
          "id": 3,
          "type": "float"
        },
        "endCharacter": {
          "id": 7,
          "type": "int32"
        },
        "lineHeight": {
          "id": 4,
          "type": "float"
        },
        "width": {
          "id": 2,
          "type": "float"
        },
        "position": {
          "id": 1,
          "type": "Vector"
        }
      }
    },
    "BasicTextStyle": {
      "fields": {
        "refCount": {
          "id": 10,
          "type": "int32"
        },
        "numericLineHeight": {
          "id": 8,
          "type": "float"
        },
        "paragraphSpacing": {
          "id": 6,
          "type": "float"
        },
        "lineHeight": {
          "id": 4,
          "type": "LineHeight"
        },
        "textDecoration": {
          "id": 7,
          "type": "TextDecoration"
        },
        "letterSpacing": {
          "id": 5,
          "type": "LetterSpacing"
        },
        "numericLetterSpacing": {
          "id": 9,
          "type": "float"
        },
        "fontSize": {
          "id": 3,
          "type": "float"
        },
        "fontWeight": {
          "id": 2,
          "type": "string"
        },
        "fontFamily": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "BatchAttachAutoLayoutVarParam": {
      "fields": {
        "params": {
          "id": 1,
          "rule": "repeated",
          "type": "AttachAutoLayoutVarParam"
        }
      }
    },
    "BatchAutoLayoutChildUpdatedParam": {
      "fields": {
        "params": {
          "id": 1,
          "rule": "repeated",
          "type": "AutoLayoutChildUpdatedParam"
        }
      }
    },
    "BatchAutoLayoutUpdatedParam": {
      "fields": {
        "clearFloatInput": {
          "id": 2,
          "type": "bool"
        },
        "params": {
          "id": 1,
          "rule": "repeated",
          "type": "AutoLayoutUpdatedParam"
        }
      }
    },
    "BatchDetachAutoLayoutVarParam": {
      "fields": {
        "params": {
          "id": 1,
          "rule": "repeated",
          "type": "DetachAutoLayoutVarParam"
        }
      }
    },
    "BatchExportConfig": {
      "fields": {
        "exportSettings": {
          "id": 5,
          "rule": "repeated",
          "type": "ExportSettings"
        },
        "height": {
          "id": 4,
          "type": "int32"
        },
        "width": {
          "id": 3,
          "type": "int32"
        },
        "name": {
          "id": 2,
          "type": "string"
        },
        "node": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "BatchExportState": {
      "fields": {
        "configs": {
          "id": 2,
          "rule": "repeated",
          "type": "BatchExportConfig"
        },
        "show": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "BatchUpdateBaseAttributeCommandParam": {
      "fields": {
        "type": {
          "id": 2,
          "type": "string"
        },
        "values": {
          "id": 1,
          "keyType": "string",
          "type": "float"
        }
      }
    },
    "BatchUpdateBlendModeCommandParam": {
      "fields": {
        "nodeIds": {
          "id": 2,
          "rule": "repeated",
          "type": "string"
        },
        "type": {
          "id": 1,
          "type": "BlendMode"
        }
      }
    },
    "BatchUpdateFloatValues": {
      "fields": {
        "values": {
          "id": 1,
          "keyType": "string",
          "type": "float"
        }
      }
    },
    "BatchUpdateMinSizeAndMaxSizeParam": {
      "fields": {
        "direction": {
          "id": 2,
          "type": "MoveDirection"
        },
        "needUnbindFloatVariable": {
          "id": 3,
          "type": "bool"
        },
        "params": {
          "id": 1,
          "rule": "repeated",
          "type": "UpdateMinSizeAndMaxSizeParam"
        }
      }
    },
    "BatchUpdateNodesKeyParam": {
      "fields": {
        "items": {
          "id": 1,
          "rule": "repeated",
          "type": "BatchUpdateNodesKeyParamItem"
        }
      }
    },
    "BatchUpdateNodesKeyParamItem": {
      "fields": {
        "key": {
          "id": 2,
          "type": "string"
        },
        "id": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_id": {
          "oneof": [
            "id"
          ]
        }
      }
    },
    "BatchUpdateOpacityByKeyboardParam": {
      "fields": {
        "key": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "BatchUpdateOpacityCommandParam": {
      "fields": {
        "nodeIds": {
          "id": 2,
          "rule": "repeated",
          "type": "string"
        },
        "opacity": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "BatchUpdatePointCountCommandParam": {
      "fields": {
        "values": {
          "id": 1,
          "keyType": "string",
          "type": "int32"
        }
      }
    },
    "BatchUpdateVisibleCommandParam": {
      "fields": {
        "nodeIds": {
          "id": 2,
          "rule": "repeated",
          "type": "string"
        },
        "visible": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "BelongingComponentPanelState": {
      "fields": {
        "componentOrSetName": {
          "id": 2,
          "type": "string"
        },
        "componentOrSetId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_componentOrSetId": {
          "oneof": [
            "componentOrSetId"
          ]
        }
      }
    },
    "BenchResult": {
      "fields": {
        "readTime": {
          "id": 3,
          "type": "int32"
        },
        "version": {
          "id": 5,
          "type": "int32"
        },
        "objectSize": {
          "id": 2,
          "type": "int32"
        },
        "renderTime": {
          "id": 4,
          "type": "int32"
        },
        "cost": {
          "id": 1,
          "type": "int64"
        }
      }
    },
    "BlendModeView": {
      "fields": {
        "disableVisible": {
          "id": 8,
          "type": "bool"
        },
        "multiVisible": {
          "id": 7,
          "type": "bool"
        },
        "visible": {
          "id": 6,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "passIds": {
          "id": 9,
          "rule": "repeated",
          "type": "string"
        },
        "blendMode": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "BlendMode"
        },
        "multiOpacity": {
          "id": 5,
          "type": "bool"
        },
        "opacity": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "multiBlendMode": {
          "id": 3,
          "type": "bool"
        },
        "show": {
          "id": 1,
          "type": "bool"
        }
      },
      "oneofs": {
        "_visible": {
          "oneof": [
            "visible"
          ]
        },
        "_blendMode": {
          "oneof": [
            "blendMode"
          ]
        },
        "_opacity": {
          "oneof": [
            "opacity"
          ]
        }
      }
    },
    "Blob": {
      "fields": {
        "id": {
          "id": 1,
          "type": "int64"
        }
      }
    },
    "BlobData": {
      "fields": {
        "bytes": {
          "id": 3,
          "type": "bytes"
        },
        "hash": {
          "id": 2,
          "type": "uint32"
        },
        "id": {
          "id": 1,
          "type": "int64"
        }
      }
    },
    "BoolSelectionNodesCommandParam": {
      "fields": {
        "insertIndex": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "newParent": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "op": {
          "id": 1,
          "type": "BooleanOperation"
        }
      },
      "oneofs": {
        "_insertIndex": {
          "oneof": [
            "insertIndex"
          ]
        },
        "_newParent": {
          "oneof": [
            "newParent"
          ]
        }
      }
    },
    "BoolString": {
      "fields": {
        "val2": {
          "id": 2,
          "type": "bool"
        },
        "val1": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "BridgeCall": {
      "fields": {
        "perfNow": {
          "id": 6,
          "type": "uint64"
        },
        "code": {
          "id": 2,
          "type": "uint32"
        },
        "ret": {
          "id": 5,
          "type": "bytes"
        },
        "args": {
          "id": 4,
          "type": "bytes"
        },
        "action": {
          "id": 1,
          "type": "BridgeAction"
        }
      }
    },
    "BridgeCallRecording": {
      "fields": {
        "httpPrefixCommonApi": {
          "id": 9,
          "type": "string"
        },
        "release": {
          "id": 7,
          "type": "string"
        },
        "url": {
          "id": 4,
          "type": "string"
        },
        "timestamp": {
          "id": 3,
          "type": "uint64"
        },
        "recordingVersion": {
          "id": 5,
          "type": "uint64"
        },
        "perfNow": {
          "id": 6,
          "type": "uint64"
        },
        "docId": {
          "id": 2,
          "type": "string"
        },
        "bridgeCalls": {
          "id": 1,
          "rule": "repeated",
          "type": "BridgeCall"
        }
      }
    },
    "BridgeProtoBaseNode": {
      "fields": {
        "value": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_value": {
          "oneof": [
            "value"
          ]
        }
      }
    },
    "BridgeProtoBoolean": {
      "fields": {
        "value": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "BridgeProtoBytes": {
      "fields": {
        "value": {
          "id": 1,
          "type": "bytes"
        }
      }
    },
    "BridgeProtoFloat": {
      "fields": {
        "value": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "BridgeProtoFloatArray": {
      "fields": {
        "value": {
          "id": 1,
          "rule": "repeated",
          "type": "float"
        }
      }
    },
    "BridgeProtoIdRef": {
      "fields": {
        "value": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_value": {
          "oneof": [
            "value"
          ]
        }
      }
    },
    "BridgeProtoInt": {
      "fields": {
        "value": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "BridgeProtoInt64": {
      "fields": {
        "value": {
          "id": 1,
          "type": "int64"
        }
      }
    },
    "BridgeProtoLayoutMixin": {
      "fields": {
        "value": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_value": {
          "oneof": [
            "value"
          ]
        }
      }
    },
    "BridgeProtoString": {
      "fields": {
        "value": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "BridgeProtoStringArray": {
      "fields": {
        "value": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "BridgeProtoStringArrayWithError": {
      "fields": {
        "error": {
          "id": 2,
          "type": "ApiError"
        },
        "value": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "BridgeProtoStringMap": {
      "fields": {
        "value": {
          "id": 1,
          "keyType": "string",
          "type": "string"
        }
      }
    },
    "BridgeProtoStringSet": {
      "fields": {
        "value": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "BridgeProtoTextNode": {
      "fields": {
        "value": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_value": {
          "oneof": [
            "value"
          ]
        }
      }
    },
    "BridgeProtoUint": {
      "fields": {
        "value": {
          "id": 1,
          "type": "uint32"
        }
      }
    },
    "BridgeProtoUint64": {
      "fields": {
        "value": {
          "id": 1,
          "type": "uint64"
        }
      }
    },
    "BuildAIComponentDataCommandParam": {
      "fields": {
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "BuildAIComponentDataCommandRet": {
      "fields": {
        "name": {
          "id": 6,
          "type": "string"
        },
        "thumbnailData": {
          "id": 5,
          "type": "string"
        },
        "jsonData": {
          "id": 4,
          "type": "string"
        },
        "protoData": {
          "id": 3,
          "type": "string"
        },
        "type": {
          "id": 2,
          "type": "string"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "BuildAIReplaceNodesDataCommandParam": {
      "fields": {
        "nodeIdsArray": {
          "id": 1,
          "rule": "repeated",
          "type": "NodeIdsWrapper"
        }
      }
    },
    "BuildAIReplaceNodesDataCommandRet": {
      "fields": {
        "jsonDataArray": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "BuildAISelectedStyleNodeDataCommandParam": {
      "fields": {
        "isCompSet": {
          "id": 2,
          "type": "bool"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "BuildAISelectedStyleNodeDataCommandRet": {
      "fields": {
        "selectedComponentThumbnailData": {
          "id": 4,
          "type": "ThumbnailData"
        },
        "variantState": {
          "id": 5,
          "type": "VariantState"
        },
        "selectedEffectStyle": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "VEffectStyleNode"
        },
        "selectedPaintStyle": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "VPaintStyleNode"
        },
        "selectedTextStyle": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "VTextStyleNode"
        }
      },
      "oneofs": {
        "_selectedEffectStyle": {
          "oneof": [
            "selectedEffectStyle"
          ]
        },
        "_selectedPaintStyle": {
          "oneof": [
            "selectedPaintStyle"
          ]
        },
        "_selectedTextStyle": {
          "oneof": [
            "selectedTextStyle"
          ]
        }
      }
    },
    "CallAILayoutRequest": {
      "fields": {
        "nodeIds": {
          "id": 5,
          "rule": "repeated",
          "type": "string"
        },
        "rootFrameJson": {
          "id": 4,
          "type": "string"
        },
        "rootFrameId": {
          "id": 3,
          "type": "string"
        },
        "pageId": {
          "id": 2,
          "type": "string"
        },
        "docId": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "CallAiAlignRequest": {
      "fields": {
        "docId": {
          "id": 4,
          "type": "string"
        },
        "alignType": {
          "id": 3,
          "type": "AiAlignType"
        },
        "nodeIds": {
          "id": 2,
          "rule": "repeated",
          "type": "string"
        },
        "rootFrameJson": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "CandidateComponentPublishView": {
      "fields": {
        "componentList": {
          "id": 4,
          "rule": "repeated",
          "type": "CandidateComponentReplaceItem"
        },
        "styleList": {
          "id": 3,
          "rule": "repeated",
          "type": "CandidateComponentReplaceItem"
        },
        "selectedComponentId": {
          "id": 6,
          "type": "string"
        },
        "allData": {
          "id": 2,
          "rule": "repeated",
          "type": "ToReplaceComponentData"
        },
        "justConvertNodeId": {
          "id": 5,
          "type": "string"
        },
        "schemaVersion": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "CandidateComponentReplaceItem": {
      "fields": {
        "candidateName": {
          "id": 8,
          "type": "string"
        },
        "type": {
          "id": 9,
          "type": "AICandidateComponentType"
        },
        "candidateComponentScreenShot": {
          "id": 6,
          "type": "bytes"
        },
        "repeats": {
          "id": 10,
          "type": "int32"
        },
        "fontMissing": {
          "id": 4,
          "type": "bool"
        },
        "candidateContentSnapshot": {
          "id": 7,
          "type": "CandidateContentSnapshot"
        },
        "thumbnailData": {
          "id": 3,
          "type": "ThumbnailData"
        },
        "substituteContentSnapshotNodeId": {
          "id": 5,
          "type": "string"
        },
        "name": {
          "id": 2,
          "type": "string"
        },
        "candidateComponentId": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "CandidateComponentState": {
      "fields": {
        "number": {
          "id": 3,
          "type": "int32"
        },
        "candidateType": {
          "id": 4,
          "type": "AICandidateComponentType"
        },
        "isComponent": {
          "id": 2,
          "type": "bool"
        },
        "shouldDisplay": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "CandidateContentItem": {
      "fields": {
        "docName": {
          "id": 2,
          "type": "string"
        },
        "docId": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "CandidateContentSnapshot": {
      "fields": {
        "repeats": {
          "id": 3,
          "rule": "repeated",
          "type": "CandidateContentItem"
        },
        "componentData": {
          "id": 2,
          "type": "CandidateContentItem"
        },
        "id": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "CandidateReplaceInstanceNodeData": {
      "fields": {
        "componentSetMainComponent": {
          "id": 2,
          "type": "string"
        },
        "nodeData": {
          "id": 1,
          "type": "ReplaceInstanceNodeData"
        }
      }
    },
    "CandidateReplaceInstanceState": {
      "fields": {
        "allLibraryList": {
          "id": 2,
          "rule": "repeated",
          "type": "ReplaceInstanceLibrary"
        },
        "allComponentList": {
          "id": 3,
          "rule": "repeated",
          "type": "CandidateReplaceInstanceNodeData"
        },
        "shouldDisplay": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "CanvasClipboardPasteCommandParam": {
      "fields": {
        "matchStyle": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "CanvasControlParagraphMeta": {
      "fields": {
        "fontLoaded": {
          "id": 2,
          "type": "bool"
        },
        "font": {
          "id": 1,
          "type": "ControlParagraphFont"
        }
      }
    },
    "CanvasInfo": {
      "fields": {
        "visible": {
          "id": 4,
          "type": "bool"
        },
        "dpr": {
          "id": 3,
          "type": "float"
        },
        "height": {
          "id": 2,
          "type": "int32"
        },
        "width": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "CanvasSearchHighLightTitle": {
      "fields": {
        "isPurple": {
          "id": 4,
          "type": "bool"
        },
        "left": {
          "id": 5,
          "type": "float"
        },
        "top": {
          "id": 6,
          "type": "float"
        },
        "show": {
          "id": 1,
          "type": "bool"
        },
        "nodeIcon": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "NodeIconData"
        },
        "name": {
          "id": 2,
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeIcon": {
          "oneof": [
            "nodeIcon"
          ]
        }
      }
    },
    "CanvasSearchLayerItem": {
      "fields": {
        "containerName": {
          "id": 11,
          "type": "string"
        },
        "textContentMatch": {
          "id": 9,
          "type": "TextMatch"
        },
        "textContent": {
          "id": 8,
          "type": "string"
        },
        "name": {
          "id": 6,
          "type": "string"
        },
        "nameMatch": {
          "id": 7,
          "type": "TextMatch"
        },
        "nodeId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "matchType": {
          "id": 5,
          "type": "TextMatchType"
        },
        "textMatchIndex": {
          "id": 10,
          "type": "uint64"
        },
        "isPurple": {
          "id": 4,
          "type": "bool"
        },
        "nodeType": {
          "id": 3,
          "type": "NodeType"
        },
        "pageId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        },
        "_pageId": {
          "oneof": [
            "pageId"
          ]
        }
      }
    },
    "CanvasSearchPageResult": {
      "fields": {
        "layerItems": {
          "id": 4,
          "rule": "repeated",
          "type": "CanvasSearchLayerItem"
        },
        "totalCount": {
          "id": 3,
          "type": "uint64"
        },
        "name": {
          "id": 2,
          "type": "string"
        },
        "pageId": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "CanvasSearchParam": {
      "fields": {
        "wholeWords": {
          "id": 7,
          "type": "bool"
        },
        "nodeFilterType": {
          "id": 5,
          "type": "CanvasSearchNodeTypeFilter"
        },
        "matchCase": {
          "id": 6,
          "type": "bool"
        },
        "value": {
          "id": 3,
          "type": "string"
        },
        "mode": {
          "id": 2,
          "type": "CanvasSearchMode"
        },
        "scope": {
          "id": 4,
          "type": "CanvasSearchScope"
        },
        "show": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "CanvasSearchReplaceResult": {
      "fields": {
        "totalCount": {
          "id": 2,
          "type": "int32"
        },
        "hasMissFont": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "CanvasSearchResult": {
      "fields": {
        "countSummary": {
          "id": 5,
          "type": "CanvasSearchResultCountSummary"
        },
        "totalCount": {
          "id": 3,
          "type": "uint64"
        },
        "pageResults": {
          "id": 2,
          "rule": "repeated",
          "type": "CanvasSearchPageResult"
        },
        "hasResultOnOtherPages": {
          "id": 4,
          "type": "bool"
        },
        "pages": {
          "id": 1,
          "type": "CanvasSearchPageResult"
        }
      }
    },
    "CanvasSearchResultCountSummary": {
      "fields": {
        "otherCount": {
          "id": 8,
          "type": "uint64"
        },
        "shapeCount": {
          "id": 7,
          "type": "uint64"
        },
        "imageCount": {
          "id": 6,
          "type": "uint64"
        },
        "componentCount": {
          "id": 4,
          "type": "uint64"
        },
        "instanceCount": {
          "id": 5,
          "type": "uint64"
        },
        "frameCount": {
          "id": 3,
          "type": "uint64"
        },
        "textCount": {
          "id": 2,
          "type": "uint64"
        },
        "all": {
          "id": 1,
          "type": "uint64"
        }
      }
    },
    "CanvasSearchResultHoveringItem": {
      "fields": {
        "matchIndex": {
          "id": 3,
          "type": "uint64"
        },
        "matchType": {
          "id": 2,
          "type": "TextMatchType"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "CanvasSearchResultRenderHeaderItem": {
      "fields": {
        "expandStatus": {
          "id": 4,
          "type": "bool"
        },
        "totalCount": {
          "id": 3,
          "type": "int32"
        },
        "name": {
          "id": 2,
          "type": "string"
        },
        "pageId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_pageId": {
          "oneof": [
            "pageId"
          ]
        }
      }
    },
    "CanvasSearchResultRenderInfo": {
      "fields": {
        "countSummary": {
          "id": 5,
          "type": "CanvasSearchResultCountSummary"
        },
        "totalCount": {
          "id": 3,
          "type": "uint64"
        },
        "hasResultOnOtherPages": {
          "id": 4,
          "type": "bool"
        },
        "renderItems": {
          "id": 2,
          "rule": "repeated",
          "type": "CanvasSearchResultRenderItem"
        },
        "renderItemTotalHeight": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "CanvasSearchResultRenderItem": {
      "fields": {
        "itemHeight": {
          "id": 10,
          "type": "float"
        },
        "isHovered": {
          "id": 7,
          "type": "bool"
        },
        "isSelected": {
          "id": 5,
          "type": "bool"
        },
        "nodeIcon": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "NodeIconData"
        },
        "itemIndex": {
          "id": 8,
          "type": "int32"
        },
        "isIndirectSelected": {
          "id": 6,
          "type": "bool"
        },
        "layerItem": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "CanvasSearchLayerItem"
        },
        "itemStartY": {
          "id": 9,
          "type": "float"
        },
        "headerItem": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "CanvasSearchResultRenderHeaderItem"
        },
        "type": {
          "id": 1,
          "type": "CanvasSearchResultRenderItemType"
        }
      },
      "oneofs": {
        "_nodeIcon": {
          "oneof": [
            "nodeIcon"
          ]
        },
        "_layerItem": {
          "oneof": [
            "layerItem"
          ]
        },
        "_headerItem": {
          "oneof": [
            "headerItem"
          ]
        }
      }
    },
    "CanvasSearchResultSelection": {
      "fields": {
        "isInTempMultiSelectionMode": {
          "id": 6,
          "type": "bool"
        },
        "hoveringItem": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "CanvasSearchResultHoveringItem"
        },
        "selectedPageId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "isSelectionFromSearchResult": {
          "id": 5,
          "type": "bool"
        },
        "isInMultiSelectionMode": {
          "id": 4,
          "type": "bool"
        },
        "expandedPageIds": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      },
      "oneofs": {
        "_hoveringItem": {
          "oneof": [
            "hoveringItem"
          ]
        },
        "_selectedPageId": {
          "oneof": [
            "selectedPageId"
          ]
        }
      }
    },
    "CanvasSearchResultTextContentMatchSelection": {
      "fields": {
        "nodeId2matchIndex": {
          "id": 1,
          "keyType": "string",
          "type": "CanvasSearchResultTextContentMatches"
        }
      }
    },
    "CanvasSearchResultTextContentMatches": {
      "fields": {
        "matchIndices": {
          "id": 1,
          "rule": "repeated",
          "type": "uint32"
        }
      }
    },
    "CanvasSearchResultViewport": {
      "fields": {
        "itemHeights": {
          "id": 3,
          "keyType": "int32",
          "type": "float"
        },
        "scrollTop": {
          "id": 2,
          "type": "float"
        },
        "height": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "CanvasSpaceBound": {
      "fields": {
        "height": {
          "id": 4,
          "type": "float"
        },
        "width": {
          "id": 3,
          "type": "float"
        },
        "y": {
          "id": 2,
          "type": "float"
        },
        "x": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "ChangeScaleToolPanelCommandParam": {
      "fields": {
        "isIncrease": {
          "id": 5,
          "type": "bool"
        },
        "origin": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "LayoutOrigin"
        },
        "scaleFactorPercent": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "height": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "width": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        }
      },
      "oneofs": {
        "_origin": {
          "oneof": [
            "origin"
          ]
        },
        "_scaleFactorPercent": {
          "oneof": [
            "scaleFactorPercent"
          ]
        },
        "_height": {
          "oneof": [
            "height"
          ]
        },
        "_width": {
          "oneof": [
            "width"
          ]
        }
      }
    },
    "ClickCommentClusterParam": {
      "fields": {
        "clusterId": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "ClipboardMeta": {
      "fields": {
        "type": {
          "id": 1,
          "type": "ClipboardNodeTreeContentType"
        }
      }
    },
    "Cluster": {
      "fields": {
        "componentCandidates": {
          "id": 1,
          "rule": "repeated",
          "type": "ComponentCandidate"
        }
      }
    },
    "CoactorMouse": {
      "fields": {
        "currentPageId": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "mouseWorldPosition": {
          "id": 2,
          "type": "CoactorMousePosition"
        },
        "cursor": {
          "id": 1,
          "type": "CursorMode"
        }
      },
      "oneofs": {
        "_currentPageId": {
          "oneof": [
            "currentPageId"
          ]
        }
      }
    },
    "CoactorMouseMap": {
      "fields": {
        "value": {
          "id": 1,
          "keyType": "int32",
          "type": "CoactorMouse"
        }
      }
    },
    "CoactorMousePosition": {
      "fields": {
        "y": {
          "id": 2,
          "type": "float"
        },
        "x": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "CoactorMouseProto": {
      "fields": {
        "currentPageId": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "mouseWorldPosition": {
          "id": 2,
          "type": "OptCoactorMousePosition"
        },
        "cursor": {
          "id": 1,
          "type": "CursorMode"
        }
      },
      "oneofs": {
        "_currentPageId": {
          "oneof": [
            "currentPageId"
          ]
        }
      }
    },
    "CoactorObservedMap": {
      "fields": {
        "value": {
          "id": 1,
          "keyType": "int32",
          "type": "int32"
        }
      }
    },
    "CoactorObservingProto": {
      "fields": {
        "placeholder": {
          "id": 2,
          "type": "ProtoEmptyPlaceholder"
        },
        "value": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        }
      },
      "oneofs": {
        "_value": {
          "oneof": [
            "value"
          ]
        }
      }
    },
    "CoactorSelection": {
      "fields": {
        "value": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "CoactorSelectionProto": {
      "fields": {
        "placeholder": {
          "id": 2,
          "type": "ProtoEmptyPlaceholder"
        },
        "value": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "CoactorViewportMap": {
      "fields": {
        "value": {
          "id": 1,
          "keyType": "int32",
          "type": "CoactorViewportProto"
        }
      }
    },
    "CoactorViewportProto": {
      "fields": {
        "currentPageId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "bound": {
          "id": 1,
          "type": "CanvasSpaceBound"
        }
      },
      "oneofs": {
        "_currentPageId": {
          "oneof": [
            "currentPageId"
          ]
        }
      }
    },
    "CodeBlock": {
      "fields": {
        "isTextSegment": {
          "id": 4,
          "type": "bool"
        },
        "lang": {
          "id": 3,
          "type": "string"
        },
        "title": {
          "id": 2,
          "type": "string"
        },
        "code": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "ColorPage": {
      "fields": {
        "gradientColorClusters": {
          "id": 2,
          "rule": "repeated",
          "type": "Cluster"
        },
        "pureColorClusters": {
          "id": 1,
          "rule": "repeated",
          "type": "Cluster"
        }
      }
    },
    "ColorPickState": {
      "fields": {
        "isContinuePick": {
          "id": 2,
          "type": "bool"
        },
        "isDisplay": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "ColorStop": {
      "fields": {
        "color": {
          "id": 2,
          "type": "RGBA"
        },
        "colorVar": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "VariableAliasData"
        },
        "position": {
          "id": 1,
          "type": "float"
        }
      },
      "oneofs": {
        "_colorVar": {
          "oneof": [
            "colorVar"
          ]
        }
      }
    },
    "CommentAspect": {
      "fields": {
        "commentHeadCount": {
          "id": 4,
          "type": "int32"
        },
        "commentPageId": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "commentCreatedTime": {
          "id": 2,
          "type": "int64"
        },
        "commentVisible": {
          "id": 3,
          "type": "bool"
        },
        "commentMetaPosition": {
          "id": 1,
          "type": "CommentMetaPosition"
        }
      },
      "oneofs": {
        "_commentPageId": {
          "oneof": [
            "commentPageId"
          ]
        }
      }
    },
    "CommentCluster": {
      "fields": {
        "cameraY": {
          "id": 4,
          "type": "float"
        },
        "cameraX": {
          "id": 3,
          "type": "float"
        },
        "commentIds": {
          "id": 2,
          "rule": "repeated",
          "type": "string"
        },
        "clusterId": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "CommentClusterResult": {
      "fields": {
        "clusters": {
          "id": 1,
          "keyType": "string",
          "type": "CommentCluster"
        }
      }
    },
    "CommentCreateParam": {
      "fields": {
        "pageId": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "docId": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "commentId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "commentMetaPosition": {
          "id": 1,
          "type": "CommentMetaPosition"
        }
      },
      "oneofs": {
        "_pageId": {
          "oneof": [
            "pageId"
          ]
        },
        "_docId": {
          "oneof": [
            "docId"
          ]
        },
        "_commentId": {
          "oneof": [
            "commentId"
          ]
        }
      }
    },
    "CommentMetaPosition": {
      "fields": {
        "anchorX": {
          "id": 7,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "offsetY": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "offsetX": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "anchorY": {
          "id": 8,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "nodeId": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "y": {
          "id": 2,
          "type": "float"
        },
        "hasAnchor": {
          "id": 6,
          "type": "bool"
        },
        "x": {
          "id": 1,
          "type": "float"
        }
      },
      "oneofs": {
        "_anchorX": {
          "oneof": [
            "anchorX"
          ]
        },
        "_offsetY": {
          "oneof": [
            "offsetY"
          ]
        },
        "_offsetX": {
          "oneof": [
            "offsetX"
          ]
        },
        "_anchorY": {
          "oneof": [
            "anchorY"
          ]
        },
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "CommentMetaPositionUpdateParam": {
      "fields": {
        "pageId": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "commentMetaPosition": {
          "id": 2,
          "type": "CommentMetaPosition"
        },
        "commentId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_pageId": {
          "oneof": [
            "pageId"
          ]
        },
        "_commentId": {
          "oneof": [
            "commentId"
          ]
        }
      }
    },
    "CommentMoveState": {
      "fields": {
        "targetCommentId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "position": {
          "id": 3,
          "type": "TranslateWorldPosition"
        },
        "targetType": {
          "id": 1,
          "type": "MovingTargetType"
        }
      },
      "oneofs": {
        "_targetCommentId": {
          "oneof": [
            "targetCommentId"
          ]
        }
      }
    },
    "CommentMovingParam": {
      "fields": {
        "position": {
          "id": 1,
          "type": "TranslateCameraPosition"
        }
      }
    },
    "CommentStartMovingParam": {
      "fields": {
        "targetCommentId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "targetType": {
          "id": 1,
          "type": "MovingTargetType"
        }
      },
      "oneofs": {
        "_targetCommentId": {
          "oneof": [
            "targetCommentId"
          ]
        }
      }
    },
    "CommentsMetaSyncParam": {
      "fields": {
        "removedComments": {
          "id": 2,
          "rule": "repeated",
          "type": "string"
        },
        "updatedComments": {
          "id": 1,
          "keyType": "string",
          "type": "CommentAspect"
        }
      }
    },
    "CompSetPropConflict": {
      "fields": {
        "selectionType": {
          "id": 2,
          "type": "CompSetPropConflictSelectionType"
        },
        "type": {
          "id": 1,
          "type": "CompSetPropConflictType"
        }
      }
    },
    "ComponentCandidate": {
      "fields": {
        "instanceOrStyle": {
          "id": 5,
          "type": "bool"
        },
        "fromPublishedComponentFile": {
          "id": 4,
          "type": "bool"
        },
        "sourceDocName": {
          "id": 7,
          "type": "string"
        },
        "sourceDocId": {
          "id": 6,
          "type": "string"
        },
        "repeats": {
          "id": 3,
          "rule": "repeated",
          "type": "ComponentData"
        },
        "componentData": {
          "id": 2,
          "type": "ComponentData"
        },
        "id": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "ComponentContextMenuItemState": {
      "fields": {
        "disablePushChangesToMainComp": {
          "id": 6,
          "type": "bool"
        },
        "disablePublishComponent": {
          "id": 2,
          "type": "bool"
        },
        "disableDetachInstance": {
          "id": 4,
          "type": "bool"
        },
        "disableCreateComponent": {
          "id": 1,
          "type": "bool"
        },
        "disableGoToMainComponent": {
          "id": 5,
          "type": "bool"
        },
        "disableResetProperties": {
          "id": 3,
          "type": "bool"
        }
      }
    },
    "ComponentData": {
      "fields": {
        "docName": {
          "id": 8,
          "type": "string"
        },
        "useStrokePaints": {
          "id": 7,
          "type": "bool"
        },
        "highlightArea": {
          "id": 6,
          "type": "Rect"
        },
        "containerIds": {
          "id": 5,
          "rule": "repeated",
          "type": "string"
        },
        "commonAncestorId": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "docId": {
          "id": 1,
          "type": "string"
        },
        "nodeIds": {
          "id": 3,
          "rule": "repeated",
          "type": "string"
        },
        "screenshotId": {
          "id": 2,
          "type": "string"
        }
      },
      "oneofs": {
        "_commonAncestorId": {
          "oneof": [
            "commonAncestorId"
          ]
        }
      }
    },
    "ComponentGetVO": {
      "fields": {
        "containerNodeId": {
          "id": 18,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "containingFrameBackgroundColor": {
          "id": 17,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "containingFrameName": {
          "id": 16,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "containingFrameId": {
          "id": 15,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "containingPageName": {
          "id": 14,
          "type": "string"
        },
        "containingPageId": {
          "id": 13,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "minNodeHeight": {
          "id": 12,
          "type": "float"
        },
        "name": {
          "id": 3,
          "type": "string"
        },
        "minNodeWidth": {
          "id": 11,
          "type": "float"
        },
        "removed": {
          "id": 8,
          "type": "bool"
        },
        "documentId": {
          "id": 7,
          "type": "string"
        },
        "nodeDataPath": {
          "id": 10,
          "type": "string"
        },
        "thumbnailDataPath": {
          "id": 9,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "libraryId": {
          "id": 6,
          "type": "string"
        },
        "nodeId": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "description": {
          "id": 4,
          "type": "string"
        },
        "contentHash": {
          "id": 2,
          "type": "string"
        },
        "id": {
          "id": 1,
          "type": "string"
        }
      },
      "oneofs": {
        "_containerNodeId": {
          "oneof": [
            "containerNodeId"
          ]
        },
        "_containingFrameBackgroundColor": {
          "oneof": [
            "containingFrameBackgroundColor"
          ]
        },
        "_containingFrameName": {
          "oneof": [
            "containingFrameName"
          ]
        },
        "_containingFrameId": {
          "oneof": [
            "containingFrameId"
          ]
        },
        "_containingPageId": {
          "oneof": [
            "containingPageId"
          ]
        },
        "_thumbnailDataPath": {
          "oneof": [
            "thumbnailDataPath"
          ]
        },
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "ComponentLibraryDataUpdateVO": {
      "fields": {
        "nodeDataV2": {
          "id": 12,
          "type": "string"
        },
        "containingPageName": {
          "id": 8,
          "type": "string"
        },
        "minNodeHeight": {
          "id": 6,
          "type": "float"
        },
        "containerNodeId": {
          "id": 13,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "containingFrameBackgroundColor": {
          "id": 11,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "containingFrameName": {
          "id": 10,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "name": {
          "id": 2,
          "type": "string"
        },
        "minNodeWidth": {
          "id": 5,
          "type": "float"
        },
        "contentHash": {
          "id": 4,
          "type": "string"
        },
        "containingFrameId": {
          "id": 9,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "nodeId": {
          "id": 1,
          "type": "string"
        },
        "description": {
          "id": 3,
          "type": "string"
        },
        "containingPageId": {
          "id": 7,
          "type": "string"
        }
      },
      "oneofs": {
        "_containerNodeId": {
          "oneof": [
            "containerNodeId"
          ]
        },
        "_containingFrameBackgroundColor": {
          "oneof": [
            "containingFrameBackgroundColor"
          ]
        },
        "_containingFrameName": {
          "oneof": [
            "containingFrameName"
          ]
        },
        "_containingFrameId": {
          "oneof": [
            "containingFrameId"
          ]
        }
      }
    },
    "ComponentPanelState": {
      "fields": {
        "variantState": {
          "id": 5,
          "type": "VariantState"
        },
        "instanceState": {
          "id": 4,
          "type": "InstanceState"
        },
        "candidateComponentState": {
          "id": 3,
          "type": "CandidateComponentState"
        },
        "componentState": {
          "id": 2,
          "type": "ComponentState"
        },
        "contextMenuItemState": {
          "id": 1,
          "type": "ComponentContextMenuItemState"
        }
      }
    },
    "ComponentPropAssignment": {
      "fields": {
        "varValue": {
          "id": 3,
          "type": "VariableData"
        },
        "legacyDerivedTextData": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "TextLayoutData"
        },
        "value": {
          "id": 2,
          "type": "ComponentPropValue"
        },
        "defId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_legacyDerivedTextData": {
          "oneof": [
            "legacyDerivedTextData"
          ]
        },
        "_defId": {
          "oneof": [
            "defId"
          ]
        }
      }
    },
    "ComponentPropDef": {
      "fields": {
        "varValue": {
          "id": 9,
          "type": "VariableData"
        },
        "preferredValues": {
          "id": 8,
          "type": "ComponentPropPreferredValues"
        },
        "isDeleted": {
          "id": 7,
          "type": "bool"
        },
        "type": {
          "id": 6,
          "type": "ComponentPropType"
        },
        "parentPropDefId": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "orderIndex": {
          "id": 4,
          "type": "bytes"
        },
        "name": {
          "id": 2,
          "type": "string"
        },
        "initialValue": {
          "id": 3,
          "type": "ComponentPropValue"
        },
        "id": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_parentPropDefId": {
          "oneof": [
            "parentPropDefId"
          ]
        },
        "_id": {
          "oneof": [
            "id"
          ]
        }
      }
    },
    "ComponentPropEditPopupVal": {
      "fields": {
        "unused": {
          "id": 9,
          "type": "bool"
        },
        "isConflict": {
          "id": 8,
          "type": "bool"
        },
        "instanceSwapPropComponentName": {
          "id": 6,
          "type": "string"
        },
        "varValue": {
          "id": 4,
          "type": "VariableData"
        },
        "preferredValues": {
          "id": 7,
          "type": "ComponentPropPreferredValues"
        },
        "variantOptions": {
          "id": 5,
          "rule": "repeated",
          "type": "VariantOption"
        },
        "type": {
          "id": 3,
          "type": "VComponentPropType"
        },
        "name": {
          "id": 2,
          "type": "string"
        },
        "id": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_id": {
          "oneof": [
            "id"
          ]
        }
      }
    },
    "ComponentPropPreferredValues": {
      "fields": {
        "instanceSwapValues": {
          "id": 2,
          "rule": "repeated",
          "type": "InstanceSwapPreferredValue"
        },
        "stringValues": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "ComponentPropRef": {
      "fields": {
        "isDeleted": {
          "id": 5,
          "type": "bool"
        },
        "defId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "componentPropNodeField": {
          "id": 4,
          "type": "ComponentPropNodeField"
        },
        "zombieFallbackName": {
          "id": 3,
          "type": "string"
        },
        "nodeField": {
          "id": 1,
          "type": "int32"
        }
      },
      "oneofs": {
        "_defId": {
          "oneof": [
            "defId"
          ]
        }
      }
    },
    "ComponentPropValue": {
      "fields": {
        "mainComponentId": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "textValue": {
          "id": 2,
          "type": "TextData"
        },
        "boolValue": {
          "id": 1,
          "type": "bool"
        }
      },
      "oneofs": {
        "_mainComponentId": {
          "oneof": [
            "mainComponentId"
          ]
        }
      }
    },
    "ComponentProperty": {
      "fields": {
        "boundVariables": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "VariableBindableComponentProperty"
        },
        "value": {
          "id": 3,
          "type": "string"
        },
        "name": {
          "id": 2,
          "type": "string"
        },
        "preferredValues": {
          "id": 4,
          "rule": "repeated",
          "type": "FigmaSchemaInstanceSwapPreferredValue"
        },
        "type": {
          "id": 1,
          "type": "VComponentPropType"
        }
      },
      "oneofs": {
        "_boundVariables": {
          "oneof": [
            "boundVariables"
          ]
        }
      }
    },
    "ComponentPropertyDefinition": {
      "fields": {
        "defaultValue": {
          "id": 3,
          "type": "string"
        },
        "name": {
          "id": 2,
          "type": "string"
        },
        "preferredValues": {
          "id": 5,
          "rule": "repeated",
          "type": "FigmaSchemaInstanceSwapPreferredValue"
        },
        "variantOptions": {
          "id": 4,
          "rule": "repeated",
          "type": "string"
        },
        "type": {
          "id": 1,
          "type": "VComponentPropType"
        }
      }
    },
    "ComponentPropertyReferences": {
      "fields": {
        "characters": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "mainComponent": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "visible": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_characters": {
          "oneof": [
            "characters"
          ]
        },
        "_mainComponent": {
          "oneof": [
            "mainComponent"
          ]
        },
        "_visible": {
          "oneof": [
            "visible"
          ]
        }
      }
    },
    "ComponentPropsAppliedValue": {
      "fields": {
        "type": {
          "id": 3,
          "type": "ComponentPropType"
        },
        "value": {
          "id": 4,
          "type": "VariableAnyValue"
        },
        "name": {
          "id": 2,
          "type": "string"
        },
        "id": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_id": {
          "oneof": [
            "id"
          ]
        }
      }
    },
    "ComponentRelatedData": {
      "fields": {
        "isFromComponentSet": {
          "id": 7,
          "type": "bool"
        },
        "props": {
          "id": 5,
          "rule": "repeated",
          "type": "ComponentRelatedDisplayProp"
        },
        "thumbnailData": {
          "id": 4,
          "type": "ThumbnailData"
        },
        "variantSetDesc": {
          "id": 3,
          "type": "string"
        },
        "previewBackgroundColor": {
          "id": 6,
          "options": {
            "proto3_optional": true
          },
          "type": "RGB"
        },
        "desc": {
          "id": 2,
          "type": "string"
        },
        "name": {
          "id": 1,
          "type": "string"
        }
      },
      "oneofs": {
        "_previewBackgroundColor": {
          "oneof": [
            "previewBackgroundColor"
          ]
        }
      }
    },
    "ComponentRelatedDisplayProp": {
      "fields": {
        "value": {
          "id": 2,
          "type": "string"
        },
        "name": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "ComponentSetProp": {
      "fields": {
        "optionCounter": {
          "id": 4,
          "keyType": "string",
          "type": "int32"
        },
        "options": {
          "id": 3,
          "rule": "repeated",
          "type": "string"
        },
        "name": {
          "id": 2,
          "type": "string"
        },
        "orderIndex": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "ComponentState": {
      "fields": {
        "simplifyInstancePanels": {
          "id": 7,
          "type": "bool"
        },
        "descUnit": {
          "id": 6,
          "type": "VComponentDescUnit"
        },
        "showPublishButton": {
          "id": 5,
          "type": "bool"
        },
        "couldEditDesc": {
          "id": 4,
          "type": "bool"
        },
        "name": {
          "id": 2,
          "type": "string"
        },
        "desc": {
          "id": 3,
          "type": "string"
        },
        "shouldDisplay": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "ComponentThumbnailDragging": {
      "fields": {
        "hitReplaceOuterInstance": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "hitFrameNode": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "dragging": {
          "id": 1,
          "type": "bool"
        }
      },
      "oneofs": {
        "_hitReplaceOuterInstance": {
          "oneof": [
            "hitReplaceOuterInstance"
          ]
        },
        "_hitFrameNode": {
          "oneof": [
            "hitFrameNode"
          ]
        }
      }
    },
    "ComponentUpdateVO": {
      "fields": {
        "thumbnailData": {
          "id": 15,
          "type": "string"
        },
        "containerNodeId": {
          "id": 16,
          "type": "string"
        },
        "containingFrameBackgroundColor": {
          "id": 13,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "containingFrameName": {
          "id": 12,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "minNodeHeight": {
          "id": 8,
          "type": "float"
        },
        "name": {
          "id": 4,
          "type": "string"
        },
        "minNodeWidth": {
          "id": 7,
          "type": "float"
        },
        "contentHash": {
          "id": 6,
          "type": "string"
        },
        "containingPageId": {
          "id": 9,
          "type": "string"
        },
        "description": {
          "id": 5,
          "type": "string"
        },
        "nodeId": {
          "id": 3,
          "type": "string"
        },
        "containingFrameId": {
          "id": 11,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "containingPageName": {
          "id": 10,
          "type": "string"
        },
        "nodeDataV2": {
          "id": 14,
          "type": "string"
        },
        "libraryId": {
          "id": 2,
          "type": "string"
        },
        "id": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_containingFrameBackgroundColor": {
          "oneof": [
            "containingFrameBackgroundColor"
          ]
        },
        "_containingFrameName": {
          "oneof": [
            "containingFrameName"
          ]
        },
        "_containingFrameId": {
          "oneof": [
            "containingFrameId"
          ]
        },
        "_id": {
          "oneof": [
            "id"
          ]
        }
      }
    },
    "CompoundAdjustLayoutCommandParam": {
      "fields": {
        "runInSystem": {
          "id": 7,
          "type": "bool"
        },
        "rotationInWorld": {
          "id": 6,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "positionY": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "height": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "width": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "positionX": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_rotationInWorld": {
          "oneof": [
            "rotationInWorld"
          ]
        },
        "_positionY": {
          "oneof": [
            "positionY"
          ]
        },
        "_height": {
          "oneof": [
            "height"
          ]
        },
        "_width": {
          "oneof": [
            "width"
          ]
        },
        "_positionX": {
          "oneof": [
            "positionX"
          ]
        },
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "ComputedPropsReadCounter": {
      "fields": {
        "counters": {
          "id": 1,
          "keyType": "string",
          "type": "int64"
        }
      }
    },
    "Constraints": {
      "fields": {
        "vertical": {
          "id": 2,
          "type": "ConstraintType"
        },
        "horizontal": {
          "id": 1,
          "type": "ConstraintType"
        }
      }
    },
    "ContainAnotherMessage": {
      "fields": {
        "val1": {
          "id": 1,
          "type": "SingleFloat"
        }
      }
    },
    "ContainRepeatedMessages": {
      "fields": {
        "val1": {
          "id": 1,
          "rule": "repeated",
          "type": "SingleFloat"
        }
      }
    },
    "ContinuousLayoutSnapshotDigest": {
      "fields": {
        "items": {
          "id": 1,
          "rule": "repeated",
          "type": "ContinuousLayoutSnapshotDigestItem"
        }
      }
    },
    "ContinuousLayoutSnapshotDigestItem": {
      "fields": {
        "isMouseDown": {
          "id": 3,
          "type": "bool"
        },
        "nodeInfo": {
          "id": 4,
          "type": "ContinuousLayoutSnapshotDigestNodeInfo"
        },
        "mouseWorldY": {
          "id": 2,
          "type": "float"
        },
        "mouseWorldX": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "ContinuousLayoutSnapshotDigestNodeInfo": {
      "fields": {
        "children": {
          "id": 5,
          "rule": "repeated",
          "type": "ContinuousLayoutSnapshotDigestNodeInfo"
        },
        "transform": {
          "id": 4,
          "type": "Transform"
        },
        "height": {
          "id": 3,
          "type": "float"
        },
        "width": {
          "id": 2,
          "type": "float"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "ControlParagraphFont": {
      "fields": {
        "style": {
          "id": 2,
          "type": "ControlParagraphFontStyle"
        },
        "id": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "ControlParagraphFontStyle": {
      "fields": {
        "lineHeight": {
          "id": 3,
          "type": "float"
        },
        "fontSize": {
          "id": 2,
          "type": "float"
        },
        "fontWeight": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "CounterSpacing": {
      "fields": {
        "isFollowSpacing": {
          "id": 3,
          "type": "bool"
        },
        "isMixed": {
          "id": 2,
          "type": "bool"
        },
        "value": {
          "id": 1,
          "rule": "repeated",
          "type": "float"
        }
      }
    },
    "CoveredScenario": {
      "fields": {
        "argumentFlags": {
          "id": 4,
          "rule": "repeated",
          "type": "string"
        },
        "documentFlags": {
          "id": 3,
          "rule": "repeated",
          "type": "string"
        },
        "wasmCallDescription": {
          "id": 2,
          "type": "string"
        },
        "wasmCallCode": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "CoveredScenarioReport": {
      "fields": {
        "testCaseDuration": {
          "id": 4,
          "type": "float"
        },
        "isSlow": {
          "id": 5,
          "type": "bool"
        },
        "testCaseName": {
          "id": 3,
          "type": "string"
        },
        "coveredScenarios": {
          "id": 2,
          "rule": "repeated",
          "type": "CoveredScenario"
        },
        "testCaseLocation": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "CreateAIGenBaseFrameParam": {
      "fields": {
        "vectorNetwork": {
          "id": 4,
          "type": "VectorNetwork"
        },
        "height": {
          "id": 3,
          "type": "float"
        },
        "width": {
          "id": 2,
          "type": "float"
        },
        "prompt": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "CreateAIGenBaseFrameReturn": {
      "fields": {
        "frameNode": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "CreateEffectStyleNodeParam": {
      "fields": {
        "visible": {
          "id": 4,
          "type": "bool"
        },
        "effects": {
          "id": 3,
          "rule": "repeated",
          "type": "Effect"
        },
        "description": {
          "id": 2,
          "type": "string"
        },
        "name": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "CreateEffectStyleNodeResult": {
      "fields": {
        "visible": {
          "id": 5,
          "type": "bool"
        },
        "effects": {
          "id": 4,
          "rule": "repeated",
          "type": "Effect"
        },
        "description": {
          "id": 3,
          "type": "string"
        },
        "name": {
          "id": 2,
          "type": "string"
        },
        "id": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_id": {
          "oneof": [
            "id"
          ]
        }
      }
    },
    "CreateLayoutGridStyleNodeParam": {
      "fields": {
        "visible": {
          "id": 4,
          "type": "bool"
        },
        "layoutGrids": {
          "id": 3,
          "rule": "repeated",
          "type": "LayoutGrid"
        },
        "description": {
          "id": 2,
          "type": "string"
        },
        "name": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "CreatePaintStyleNodeParam": {
      "fields": {
        "visible": {
          "id": 4,
          "type": "bool"
        },
        "paints": {
          "id": 3,
          "rule": "repeated",
          "type": "Paint"
        },
        "description": {
          "id": 2,
          "type": "string"
        },
        "name": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "CreatePaintStyleNodeResult": {
      "fields": {
        "visible": {
          "id": 5,
          "type": "bool"
        },
        "paints": {
          "id": 4,
          "rule": "repeated",
          "type": "Paint"
        },
        "description": {
          "id": 3,
          "type": "string"
        },
        "name": {
          "id": 2,
          "type": "string"
        },
        "id": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_id": {
          "oneof": [
            "id"
          ]
        }
      }
    },
    "CreateStyleParams": {
      "fields": {
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "CreateTextStyleNodeParam": {
      "fields": {
        "fontVariations": {
          "id": 10,
          "rule": "repeated",
          "type": "FontVariation"
        },
        "paragraphSpacing": {
          "id": 8,
          "type": "float"
        },
        "letterSpacing": {
          "id": 6,
          "type": "LetterSpacing"
        },
        "description": {
          "id": 2,
          "type": "string"
        },
        "lineHeight": {
          "id": 5,
          "type": "LineHeight"
        },
        "visible": {
          "id": 11,
          "type": "bool"
        },
        "paragraphIndent": {
          "id": 7,
          "type": "float"
        },
        "fontSize": {
          "id": 4,
          "type": "float"
        },
        "detachOpticalSizeFromFontSize": {
          "id": 12,
          "type": "bool"
        },
        "fontName": {
          "id": 3,
          "type": "FontName"
        },
        "textDecoration": {
          "id": 9,
          "type": "TextDecoration"
        },
        "name": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "CreateTextStyleNodeResult": {
      "fields": {
        "visible": {
          "id": 12,
          "type": "bool"
        },
        "fontVariations": {
          "id": 11,
          "rule": "repeated",
          "type": "FontVariation"
        },
        "paragraphSpacing": {
          "id": 9,
          "type": "float"
        },
        "paragraphIndent": {
          "id": 8,
          "type": "float"
        },
        "fontSize": {
          "id": 5,
          "type": "float"
        },
        "detachOpticalSizeFromFontSize": {
          "id": 13,
          "type": "bool"
        },
        "textDecoration": {
          "id": 10,
          "type": "TextDecoration"
        },
        "fontName": {
          "id": 4,
          "type": "FontName"
        },
        "lineHeight": {
          "id": 6,
          "type": "LineHeight"
        },
        "description": {
          "id": 3,
          "type": "string"
        },
        "letterSpacing": {
          "id": 7,
          "type": "LetterSpacing"
        },
        "name": {
          "id": 2,
          "type": "string"
        },
        "id": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_id": {
          "oneof": [
            "id"
          ]
        }
      }
    },
    "CropImagePaintInfo": {
      "fields": {
        "isFromFills": {
          "id": 2,
          "type": "bool"
        },
        "index": {
          "id": 3,
          "type": "int32"
        },
        "isFromStrokes": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "CssCodeConfig": {
      "fields": {
        "remEnableForText": {
          "id": 4,
          "type": "bool"
        },
        "rem": {
          "id": 3,
          "type": "float"
        },
        "ratio": {
          "id": 2,
          "type": "CodeRatio"
        },
        "unit": {
          "id": 1,
          "type": "CssUnit"
        }
      }
    },
    "CurrentEditorContext": {
      "fields": {
        "devicePixelRatio": {
          "id": 7,
          "type": "float"
        },
        "zoom": {
          "id": 6,
          "type": "float"
        },
        "height": {
          "id": 5,
          "type": "float"
        },
        "width": {
          "id": 4,
          "type": "float"
        },
        "y": {
          "id": 3,
          "type": "float"
        },
        "x": {
          "id": 2,
          "type": "float"
        },
        "currentPageIndex": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "CurrentPageSetSelectedGuideCommandParam": {
      "fields": {
        "parentIdRef": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "selectedGuideIndex": {
          "id": 1,
          "type": "int32"
        }
      },
      "oneofs": {
        "_parentIdRef": {
          "oneof": [
            "parentIdRef"
          ]
        }
      }
    },
    "CurrentPageSetSelectionCommandParam": {
      "fields": {
        "selectedGuideInfo": {
          "id": 4,
          "type": "GuideInfo"
        },
        "type": {
          "id": 3,
          "type": "SelectionType"
        },
        "selectedGuideIndex": {
          "id": 2,
          "type": "int32"
        },
        "selection": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "CurrentPageSetSelectionNodesCommandParam": {
      "fields": {
        "selection": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "Cursor": {
      "fields": {
        "rotation": {
          "id": 2,
          "type": "float"
        },
        "mode": {
          "id": 1,
          "type": "CursorMode"
        }
      }
    },
    "CustomAxesStyleInfo": {
      "fields": {
        "style": {
          "id": 2,
          "type": "string"
        },
        "isMissFontCustomStyle": {
          "id": 3,
          "type": "bool"
        },
        "customAxesStyle": {
          "id": 1,
          "type": "VariantFontCustomAxesStyle"
        }
      }
    },
    "Decoration": {
      "fields": {
        "styleId": {
          "id": 3,
          "type": "int32"
        },
        "firstCharacter": {
          "id": 2,
          "type": "int32"
        },
        "rects": {
          "id": 1,
          "rule": "repeated",
          "type": "SkRect"
        }
      }
    },
    "DeletePageCommandParam": {
      "fields": {
        "node": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_node": {
          "oneof": [
            "node"
          ]
        }
      }
    },
    "DerivedSymbolData": {
      "fields": {
        "data": {
          "id": 1,
          "keyType": "string",
          "type": "SynergyNode"
        }
      }
    },
    "DetachAutoLayoutVarParam": {
      "fields": {
        "field": {
          "id": 2,
          "type": "VariableField"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "DetachComponentPropDefData": {
      "fields": {
        "nodeId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "propId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        },
        "_propId": {
          "oneof": [
            "propId"
          ]
        }
      }
    },
    "DetachCornerRadiusFloatVarParam": {
      "fields": {
        "field": {
          "id": 1,
          "type": "VariableField"
        }
      }
    },
    "DetachMinMaxWidthHeightFloatVar": {
      "fields": {
        "nodeId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "type": {
          "id": 1,
          "type": "UpdateMinSizeAndMaxSizeCommandEnum"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "DetachSelectionPaintStyleIdForEnterCropParam": {
      "fields": {
        "paintStyleType": {
          "id": 1,
          "type": "PaintStyleType"
        }
      }
    },
    "DetachSelectionTextStyleIdParam": {
      "fields": {
        "textStyleId": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "DetachStrokeWeightFloatVarCommandParams": {
      "fields": {
        "fields": {
          "id": 1,
          "rule": "repeated",
          "type": "VariableField"
        }
      }
    },
    "DevModeAuthMessage": {
      "fields": {
        "cipherTextsWrappedAsString": {
          "id": 2,
          "rule": "repeated",
          "type": "bytes"
        },
        "hasAccess": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "DevModeInspectBoxModelCommonAsset": {
      "fields": {
        "width": {
          "id": 13,
          "type": "float"
        },
        "bottomBorder": {
          "id": 11,
          "type": "float"
        },
        "bottomLeftRadius": {
          "id": 8,
          "type": "float"
        },
        "bottomRightRadius": {
          "id": 7,
          "type": "float"
        },
        "topRightRadius": {
          "id": 6,
          "type": "float"
        },
        "leftBorder": {
          "id": 12,
          "type": "float"
        },
        "bottomMargin": {
          "id": 3,
          "type": "DevModeInspectBoxModelMargin"
        },
        "topBorder": {
          "id": 9,
          "type": "float"
        },
        "height": {
          "id": 14,
          "type": "float"
        },
        "topLeftRadius": {
          "id": 5,
          "type": "float"
        },
        "topMargin": {
          "id": 1,
          "type": "DevModeInspectBoxModelMargin"
        },
        "leftMargin": {
          "id": 4,
          "type": "DevModeInspectBoxModelMargin"
        },
        "rightBorder": {
          "id": 10,
          "type": "float"
        },
        "rightMargin": {
          "id": 2,
          "type": "DevModeInspectBoxModelMargin"
        }
      }
    },
    "DevModeInspectBoxModelCommonLayout": {
      "fields": {
        "width": {
          "id": 13,
          "type": "float"
        },
        "bottomBorder": {
          "id": 11,
          "type": "float"
        },
        "bottomLeftRadius": {
          "id": 8,
          "type": "float"
        },
        "bottomRightRadius": {
          "id": 7,
          "type": "float"
        },
        "topRightRadius": {
          "id": 6,
          "type": "float"
        },
        "leftBorder": {
          "id": 12,
          "type": "float"
        },
        "bottomMargin": {
          "id": 3,
          "type": "DevModeInspectBoxModelMargin"
        },
        "topBorder": {
          "id": 9,
          "type": "float"
        },
        "height": {
          "id": 14,
          "type": "float"
        },
        "topLeftRadius": {
          "id": 5,
          "type": "float"
        },
        "topMargin": {
          "id": 1,
          "type": "DevModeInspectBoxModelMargin"
        },
        "leftMargin": {
          "id": 4,
          "type": "DevModeInspectBoxModelMargin"
        },
        "rightBorder": {
          "id": 10,
          "type": "float"
        },
        "rightMargin": {
          "id": 2,
          "type": "DevModeInspectBoxModelMargin"
        }
      }
    },
    "DevModeInspectBoxModelImageAsset": {
      "fields": {
        "imageName": {
          "id": 15,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "width": {
          "id": 13,
          "type": "float"
        },
        "bottomBorder": {
          "id": 11,
          "type": "float"
        },
        "bottomLeftRadius": {
          "id": 8,
          "type": "float"
        },
        "bottomRightRadius": {
          "id": 7,
          "type": "float"
        },
        "topRightRadius": {
          "id": 6,
          "type": "float"
        },
        "leftBorder": {
          "id": 12,
          "type": "float"
        },
        "bottomMargin": {
          "id": 3,
          "type": "DevModeInspectBoxModelMargin"
        },
        "topBorder": {
          "id": 9,
          "type": "float"
        },
        "height": {
          "id": 14,
          "type": "float"
        },
        "topLeftRadius": {
          "id": 5,
          "type": "float"
        },
        "topMargin": {
          "id": 1,
          "type": "DevModeInspectBoxModelMargin"
        },
        "imageId": {
          "id": 16,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "leftMargin": {
          "id": 4,
          "type": "DevModeInspectBoxModelMargin"
        },
        "rightBorder": {
          "id": 10,
          "type": "float"
        },
        "rightMargin": {
          "id": 2,
          "type": "DevModeInspectBoxModelMargin"
        }
      },
      "oneofs": {
        "_imageName": {
          "oneof": [
            "imageName"
          ]
        },
        "_imageId": {
          "oneof": [
            "imageId"
          ]
        }
      }
    },
    "DevModeInspectBoxModelLayout": {
      "fields": {
        "width": {
          "id": 17,
          "type": "float"
        },
        "bottomPadding": {
          "id": 15,
          "type": "float"
        },
        "rightPadding": {
          "id": 14,
          "type": "float"
        },
        "bottomBorder": {
          "id": 11,
          "type": "float"
        },
        "bottomLeftRadius": {
          "id": 8,
          "type": "float"
        },
        "bottomRightRadius": {
          "id": 7,
          "type": "float"
        },
        "topRightRadius": {
          "id": 6,
          "type": "float"
        },
        "leftBorder": {
          "id": 12,
          "type": "float"
        },
        "bottomMargin": {
          "id": 3,
          "type": "DevModeInspectBoxModelMargin"
        },
        "topBorder": {
          "id": 9,
          "type": "float"
        },
        "height": {
          "id": 18,
          "type": "float"
        },
        "topLeftRadius": {
          "id": 5,
          "type": "float"
        },
        "leftPadding": {
          "id": 16,
          "type": "float"
        },
        "topMargin": {
          "id": 1,
          "type": "DevModeInspectBoxModelMargin"
        },
        "leftMargin": {
          "id": 4,
          "type": "DevModeInspectBoxModelMargin"
        },
        "topPadding": {
          "id": 13,
          "type": "float"
        },
        "rightBorder": {
          "id": 10,
          "type": "float"
        },
        "rightMargin": {
          "id": 2,
          "type": "DevModeInspectBoxModelMargin"
        }
      }
    },
    "DevModeInspectBoxModelMargin": {
      "fields": {
        "value": {
          "id": 2,
          "type": "float"
        },
        "isGapping": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "DevModeInspectBoxModelText": {
      "fields": {
        "thirdMeasurementLine": {
          "id": 9,
          "type": "int32"
        },
        "secondMeasurementLine": {
          "id": 8,
          "type": "int32"
        },
        "firstMeasurementLine": {
          "id": 7,
          "type": "int32"
        },
        "textStyleNodeName": {
          "id": 6,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "thumbnailData": {
          "id": 5,
          "type": "DevModeInspectTextThumbnailData"
        },
        "fontSize": {
          "id": 3,
          "type": "FloatWithUnitSuffix"
        },
        "fontName": {
          "id": 2,
          "type": "string"
        },
        "lineHeight": {
          "id": 4,
          "type": "FloatWithUnitSuffix"
        },
        "isMixed": {
          "id": 1,
          "type": "bool"
        }
      },
      "oneofs": {
        "_textStyleNodeName": {
          "oneof": [
            "textStyleNodeName"
          ]
        }
      }
    },
    "DevModeInspectCodeConfig": {
      "fields": {
        "compose": {
          "id": 5,
          "type": "AndroidCodeConfig"
        },
        "android": {
          "id": 4,
          "type": "AndroidCodeConfig"
        },
        "swift": {
          "id": 3,
          "type": "IOSCodeConfig"
        },
        "ios": {
          "id": 2,
          "type": "IOSCodeConfig"
        },
        "css": {
          "id": 1,
          "type": "CssCodeConfig"
        }
      }
    },
    "DevModeInspectExportConfig": {
      "fields": {
        "iosConfig": {
          "id": 2,
          "type": "PresetCodeTypeToFileType"
        },
        "androidConfig": {
          "id": 3,
          "type": "PresetCodeTypeToFileType"
        },
        "cssConfig": {
          "id": 1,
          "type": "PresetCodeTypeToFileType"
        }
      }
    },
    "DevModeInspectExportUserConfig": {
      "fields": {
        "iosConfig": {
          "id": 2,
          "type": "PresetCodeTypeToFileTypeUserConfig"
        },
        "androidConfig": {
          "id": 3,
          "type": "PresetCodeTypeToFileTypeUserConfig"
        },
        "cssConfig": {
          "id": 1,
          "type": "PresetCodeTypeToFileTypeUserConfig"
        }
      }
    },
    "DevModeInspectTextThumbnailData": {
      "fields": {
        "data": {
          "id": 3,
          "type": "string"
        },
        "height": {
          "id": 2,
          "type": "float"
        },
        "width": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "DeviceOption": {
      "fields": {
        "styleName": {
          "id": 12,
          "type": "string"
        },
        "deviceName": {
          "id": 11,
          "type": "string"
        },
        "thumbnailUrl": {
          "id": 2,
          "type": "string"
        },
        "offset": {
          "id": 9,
          "type": "Vector"
        },
        "isMobileDevice": {
          "id": 6,
          "type": "bool"
        },
        "radius": {
          "id": 5,
          "type": "float"
        },
        "scaleFactor": {
          "id": 4,
          "type": "float"
        },
        "imageSize": {
          "id": 8,
          "type": "Vector"
        },
        "type": {
          "id": 7,
          "type": "PrototypeDeviceType"
        },
        "presetIdentifier": {
          "id": 3,
          "type": "string"
        },
        "framePresetSize": {
          "id": 10,
          "type": "Vector"
        },
        "url": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "DisplayPanel": {
      "fields": {
        "type": {
          "id": 2,
          "type": "DisplayPanelType"
        },
        "isSelected": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "DistributeTypeCommandParam": {
      "fields": {
        "type": {
          "id": 1,
          "type": "DistributeType"
        }
      }
    },
    "DocModeCommandParam": {
      "fields": {
        "previewScene": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "PreviewScene"
        },
        "docMode": {
          "id": 1,
          "type": "DocMode"
        }
      },
      "oneofs": {
        "_previewScene": {
          "oneof": [
            "previewScene"
          ]
        }
      }
    },
    "DocumentColorProfileMeta": {
      "fields": {
        "monitorSupportDisplayP3Level": {
          "id": 2,
          "type": "DeviceSupportDisplayP3Level"
        },
        "isHostSupportDisplayP3": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "DocumentGetComputedAttrParam": {
      "fields": {
        "computedPropName": {
          "id": 2,
          "type": "string"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "DocumentGetNodeAttrParam": {
      "fields": {
        "prop": {
          "id": 2,
          "type": "NodeProps"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "DocumentGetVO": {
      "fields": {
        "name": {
          "id": 2,
          "type": "string"
        },
        "id": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "DocumentLocalStyleNodeState": {
      "fields": {
        "effectStyleItems": {
          "id": 4,
          "rule": "repeated",
          "type": "LocalEffectStyleItem"
        },
        "layoutGridStyleItems": {
          "id": 5,
          "rule": "repeated",
          "type": "LocalLayoutGridStyleItem"
        },
        "paintStyleItems": {
          "id": 3,
          "rule": "repeated",
          "type": "LocalPaintStyleItem"
        },
        "textStyleItems": {
          "id": 2,
          "rule": "repeated",
          "type": "LocalTextStyleItem"
        },
        "show": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "DocumentUsingFontName": {
      "fields": {
        "infos": {
          "id": 2,
          "rule": "repeated",
          "type": "FontNameInfo"
        },
        "keep": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "DocumentWatchpoints": {
      "fields": {
        "watchedComputedProps": {
          "id": 3,
          "rule": "repeated",
          "type": "string"
        },
        "watchedNodeProps": {
          "id": 2,
          "rule": "repeated",
          "type": "NodeProps"
        },
        "watchedNodes": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "DownloadImageBatchResult": {
      "fields": {
        "results": {
          "id": 1,
          "rule": "repeated",
          "type": "DownloadImageResult"
        }
      }
    },
    "DownloadImageResult": {
      "fields": {
        "usingKtx": {
          "id": 12,
          "type": "bool"
        },
        "resourcePixelsHandle": {
          "id": 9,
          "type": "uint32"
        },
        "colorProfile": {
          "id": 8,
          "type": "DocumentColorProfile"
        },
        "imageId": {
          "id": 2,
          "type": "string"
        },
        "hasGamma": {
          "id": 10,
          "type": "bool"
        },
        "baseUrl": {
          "id": 6,
          "type": "string"
        },
        "imageHeight": {
          "id": 4,
          "type": "int32"
        },
        "chunkBytesList": {
          "id": 11,
          "rule": "repeated",
          "type": "uint32"
        },
        "blob": {
          "id": 5,
          "type": "bytes"
        },
        "imageWidth": {
          "id": 3,
          "type": "int32"
        },
        "errorType": {
          "id": 7,
          "type": "DownloadErrorType"
        },
        "success": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "DownloadTemplateArgs": {
      "fields": {
        "version": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "DownloadTemplateRet": {
      "fields": {
        "themeName": {
          "id": 2,
          "type": "string"
        },
        "doc": {
          "id": 1,
          "type": "SynergyDocument"
        }
      }
    },
    "DraftCommentProps": {
      "fields": {
        "anchorY": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "anchorX": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "hasAnchor": {
          "id": 3,
          "type": "bool"
        },
        "y": {
          "id": 2,
          "type": "float"
        },
        "x": {
          "id": 1,
          "type": "float"
        }
      },
      "oneofs": {
        "_anchorY": {
          "oneof": [
            "anchorY"
          ]
        },
        "_anchorX": {
          "oneof": [
            "anchorX"
          ]
        }
      }
    },
    "DragCreateInstanceCommandParam": {
      "fields": {
        "altKey": {
          "id": 4,
          "type": "bool"
        },
        "anchorPoint": {
          "id": 3,
          "type": "Vector"
        },
        "mousePoint": {
          "id": 2,
          "type": "Vector"
        },
        "componentId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_componentId": {
          "oneof": [
            "componentId"
          ]
        }
      }
    },
    "DragLocalSelectionStyleCommandParam": {
      "fields": {
        "folderName": {
          "id": 3,
          "type": "string"
        },
        "styleId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "indent": {
          "id": 4,
          "type": "int32"
        },
        "isFolder": {
          "id": 1,
          "type": "bool"
        }
      },
      "oneofs": {
        "_styleId": {
          "oneof": [
            "styleId"
          ]
        }
      }
    },
    "DrawingCommentProps": {
      "fields": {
        "drawingEndPosition": {
          "id": 2,
          "type": "AbsoluteWorldPosition"
        },
        "drawingStartPosition": {
          "id": 1,
          "type": "AbsoluteWorldPosition"
        }
      }
    },
    "DropImageParam": {
      "fields": {
        "canvasY": {
          "id": 3,
          "type": "float"
        },
        "canvasX": {
          "id": 2,
          "type": "float"
        },
        "imageInfos": {
          "id": 1,
          "rule": "repeated",
          "type": "ImageInfo"
        }
      }
    },
    "DropImageToCenterParam": {
      "fields": {
        "imageInfos": {
          "id": 1,
          "rule": "repeated",
          "type": "ImageInfo"
        }
      }
    },
    "DummyNodeData": {
      "fields": {
        "collectedChildren": {
          "id": 6,
          "rule": "repeated",
          "type": "DummyNodeData"
        },
        "id": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "expanded": {
          "id": 4,
          "type": "bool"
        },
        "type": {
          "id": 3,
          "type": "string"
        },
        "name": {
          "id": 2,
          "type": "string"
        },
        "hasChildren": {
          "id": 5,
          "type": "bool"
        }
      },
      "oneofs": {
        "_id": {
          "oneof": [
            "id"
          ]
        }
      }
    },
    "DuplicateNodeInfo": {
      "fields": {
        "jsScript": {
          "id": 2,
          "type": "Arg_evalJsScript"
        },
        "indexDataList": {
          "id": 1,
          "rule": "repeated",
          "type": "IndexData"
        }
      }
    },
    "DuplicatePageCommandParam": {
      "fields": {
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "EasingFunctionPropValue": {
      "fields": {
        "show": {
          "id": 3,
          "type": "bool"
        },
        "valueType": {
          "id": 2,
          "type": "InteractionActionPropValueType"
        },
        "value": {
          "id": 1,
          "rule": "repeated",
          "type": "float"
        }
      }
    },
    "EasingTypePropValue": {
      "fields": {
        "show": {
          "id": 3,
          "type": "bool"
        },
        "valueType": {
          "id": 2,
          "type": "InteractionActionPropValueType"
        },
        "value": {
          "id": 1,
          "type": "EasingType"
        }
      }
    },
    "EditComponentPropertyValue": {
      "fields": {
        "preferredValuesChanged": {
          "id": 4,
          "type": "bool"
        },
        "preferredValues": {
          "id": 3,
          "rule": "repeated",
          "type": "FigmaSchemaInstanceSwapPreferredValue"
        },
        "defaultValue": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "name": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_defaultValue": {
          "oneof": [
            "defaultValue"
          ]
        },
        "_name": {
          "oneof": [
            "name"
          ]
        }
      }
    },
    "EditImageScaleModeCommandParam": {
      "fields": {
        "scaleMode": {
          "id": 1,
          "type": "ScaleMode"
        }
      }
    },
    "EditInfo": {
      "fields": {
        "userId": {
          "id": 3,
          "type": "int64"
        },
        "lastEditedAt": {
          "id": 2,
          "type": "int64"
        },
        "createdAt": {
          "id": 1,
          "type": "int64"
        }
      }
    },
    "EditingNameState": {
      "fields": {
        "nodeIds": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "EditingPaint": {
      "fields": {
        "editingStyleId": {
          "id": 7,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "textEnd": {
          "id": 6,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "textStart": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "scaleMode": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "ScaleMode"
        },
        "paintIndex": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "type": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "EditingPaintType"
        },
        "nodeIds": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      },
      "oneofs": {
        "_editingStyleId": {
          "oneof": [
            "editingStyleId"
          ]
        },
        "_textEnd": {
          "oneof": [
            "textEnd"
          ]
        },
        "_textStart": {
          "oneof": [
            "textStart"
          ]
        },
        "_scaleMode": {
          "oneof": [
            "scaleMode"
          ]
        },
        "_paintIndex": {
          "oneof": [
            "paintIndex"
          ]
        },
        "_type": {
          "oneof": [
            "type"
          ]
        }
      }
    },
    "EditingPopupPosition": {
      "fields": {
        "top": {
          "id": 2,
          "type": "int32"
        },
        "left": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "EditingTextNodeState": {
      "fields": {
        "blur": {
          "id": 3,
          "type": "bool"
        },
        "textStyle": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "TextStyle"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_textStyle": {
          "oneof": [
            "textStyle"
          ]
        },
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "EditingVectorPenState": {
      "fields": {
        "lastTangentPosition": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "Vector"
        },
        "afterEscState": {
          "id": 4,
          "type": "bool"
        },
        "lastCreatedVertexIndex": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_lastTangentPosition": {
          "oneof": [
            "lastTangentPosition"
          ]
        },
        "_lastCreatedVertexIndex": {
          "oneof": [
            "lastCreatedVertexIndex"
          ]
        },
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "EditingVectorState": {
      "fields": {
        "isSelectedPendingTangent": {
          "id": 9,
          "type": "bool"
        },
        "activeTangentIndices": {
          "id": 6,
          "rule": "repeated",
          "type": "int32"
        },
        "selectedRegionIndices": {
          "id": 5,
          "rule": "repeated",
          "type": "int32"
        },
        "selectedTangentIndices": {
          "id": 4,
          "rule": "repeated",
          "type": "int32"
        },
        "selectedSegmentIndices": {
          "id": 3,
          "rule": "repeated",
          "type": "int32"
        },
        "nodeType": {
          "id": 10,
          "type": "NodeType"
        },
        "tangentIndexRenderInvisible": {
          "id": 8,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "selectableTangentIndices": {
          "id": 7,
          "rule": "repeated",
          "type": "int32"
        },
        "selectedVertexIndices": {
          "id": 2,
          "rule": "repeated",
          "type": "int32"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_tangentIndexRenderInvisible": {
          "oneof": [
            "tangentIndexRenderInvisible"
          ]
        },
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "EditorModeState": {
      "fields": {
        "editorMode": {
          "id": 1,
          "type": "EditorMode"
        }
      }
    },
    "Effect": {
      "fields": {
        "type": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "EffectType"
        },
        "color": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "RGBA"
        },
        "offset": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "Vector"
        },
        "spread": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "visible": {
          "id": 6,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "colorVar": {
          "id": 9,
          "options": {
            "proto3_optional": true
          },
          "type": "VariableAliasData"
        },
        "spreadVar": {
          "id": 13,
          "options": {
            "proto3_optional": true
          },
          "type": "VariableAliasData"
        },
        "yVar": {
          "id": 11,
          "options": {
            "proto3_optional": true
          },
          "type": "VariableAliasData"
        },
        "blendMode": {
          "id": 7,
          "options": {
            "proto3_optional": true
          },
          "type": "BlendMode"
        },
        "radius": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "showShadowBehindNode": {
          "id": 8,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "xVar": {
          "id": 10,
          "options": {
            "proto3_optional": true
          },
          "type": "VariableAliasData"
        },
        "radiusVar": {
          "id": 12,
          "options": {
            "proto3_optional": true
          },
          "type": "VariableAliasData"
        }
      },
      "oneofs": {
        "_type": {
          "oneof": [
            "type"
          ]
        },
        "_color": {
          "oneof": [
            "color"
          ]
        },
        "_offset": {
          "oneof": [
            "offset"
          ]
        },
        "_spread": {
          "oneof": [
            "spread"
          ]
        },
        "_visible": {
          "oneof": [
            "visible"
          ]
        },
        "_colorVar": {
          "oneof": [
            "colorVar"
          ]
        },
        "_spreadVar": {
          "oneof": [
            "spreadVar"
          ]
        },
        "_yVar": {
          "oneof": [
            "yVar"
          ]
        },
        "_blendMode": {
          "oneof": [
            "blendMode"
          ]
        },
        "_radius": {
          "oneof": [
            "radius"
          ]
        },
        "_showShadowBehindNode": {
          "oneof": [
            "showShadowBehindNode"
          ]
        },
        "_xVar": {
          "oneof": [
            "xVar"
          ]
        },
        "_radiusVar": {
          "oneof": [
            "radiusVar"
          ]
        }
      }
    },
    "EffectPage": {
      "fields": {
        "backgroundBlurCluster": {
          "id": 4,
          "type": "Cluster"
        },
        "layerBlurCluster": {
          "id": 3,
          "type": "Cluster"
        },
        "innerShadowCluster": {
          "id": 2,
          "type": "Cluster"
        },
        "dropShadowCluster": {
          "id": 1,
          "type": "Cluster"
        }
      }
    },
    "EffectWrapper": {
      "fields": {
        "effect": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "Effect"
        },
        "spread": {
          "id": 4,
          "type": "string"
        },
        "radius": {
          "id": 3,
          "type": "string"
        },
        "offsetY": {
          "id": 2,
          "type": "string"
        },
        "offsetX": {
          "id": 1,
          "type": "string"
        }
      },
      "oneofs": {
        "_effect": {
          "oneof": [
            "effect"
          ]
        }
      }
    },
    "EffectdNodeIds": {
      "fields": {
        "value": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "ExpandSelectionIndependentCornerRadiusParam": {
      "fields": {
        "value": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "ExportConstraint": {
      "fields": {
        "value": {
          "id": 2,
          "type": "int32"
        },
        "type": {
          "id": 1,
          "type": "ExportConstraintType"
        }
      }
    },
    "ExportConstraintByFloat": {
      "fields": {
        "value": {
          "id": 2,
          "type": "float"
        },
        "type": {
          "id": 1,
          "type": "ExportConstraintType"
        }
      }
    },
    "ExportImagesNumParams": {
      "fields": {
        "num": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "ExportSettings": {
      "fields": {
        "svgSimplifyStroke": {
          "id": 8,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "svgIdAttribute": {
          "id": 7,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "svgOutlineText": {
          "id": 6,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "constraint": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "ExportConstraint"
        },
        "useAbsoluteBounds": {
          "id": 3,
          "type": "bool"
        },
        "suffix": {
          "id": 4,
          "type": "string"
        },
        "contentsOnly": {
          "id": 2,
          "type": "bool"
        },
        "format": {
          "id": 1,
          "type": "ExportFormatType"
        }
      },
      "oneofs": {
        "_svgSimplifyStroke": {
          "oneof": [
            "svgSimplifyStroke"
          ]
        },
        "_svgIdAttribute": {
          "oneof": [
            "svgIdAttribute"
          ]
        },
        "_svgOutlineText": {
          "oneof": [
            "svgOutlineText"
          ]
        },
        "_constraint": {
          "oneof": [
            "constraint"
          ]
        }
      }
    },
    "ExportSettingsConstraints": {
      "fields": {
        "value": {
          "id": 2,
          "type": "float"
        },
        "type": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "ExportedAeData": {
      "fields": {
        "rasterizedImages": {
          "id": 2,
          "rule": "repeated",
          "type": "RasterizedImage"
        },
        "datas": {
          "id": 1,
          "rule": "repeated",
          "type": "AeData"
        }
      }
    },
    "FigArcData": {
      "fields": {
        "innerRadius": {
          "id": 3,
          "type": "float"
        },
        "endingAngle": {
          "id": 2,
          "type": "float"
        },
        "startingAngle": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "FigColor": {
      "fields": {
        "b": {
          "id": 3,
          "type": "float"
        },
        "g": {
          "id": 2,
          "type": "float"
        },
        "a": {
          "id": 4,
          "type": "float"
        },
        "r": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "FigColorStop": {
      "fields": {
        "position": {
          "id": 2,
          "type": "float"
        },
        "color": {
          "id": 1,
          "type": "FigColor"
        }
      }
    },
    "FigEffect": {
      "fields": {
        "showShadowBehindNode": {
          "id": 8,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "color": {
          "id": 7,
          "options": {
            "proto3_optional": true
          },
          "type": "FigColor"
        },
        "spread": {
          "id": 6,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "blendMode": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "offset": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "Vector"
        },
        "radius": {
          "id": 3,
          "type": "float"
        },
        "visible": {
          "id": 2,
          "type": "bool"
        },
        "type": {
          "id": 1,
          "type": "string"
        }
      },
      "oneofs": {
        "_showShadowBehindNode": {
          "oneof": [
            "showShadowBehindNode"
          ]
        },
        "_color": {
          "oneof": [
            "color"
          ]
        },
        "_spread": {
          "oneof": [
            "spread"
          ]
        },
        "_blendMode": {
          "oneof": [
            "blendMode"
          ]
        },
        "_offset": {
          "oneof": [
            "offset"
          ]
        }
      }
    },
    "FigFontStyle": {
      "fields": {
        "lineHeightPercentFontSize": {
          "id": 11,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "lineHeightPx": {
          "id": 10,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "lineHeightUnit": {
          "id": 9,
          "type": "string"
        },
        "textAlignVertical": {
          "id": 7,
          "type": "string"
        },
        "textAutoResize": {
          "id": 8,
          "type": "string"
        },
        "textAlignHorizontal": {
          "id": 6,
          "type": "string"
        },
        "letterSpacing": {
          "id": 5,
          "type": "float"
        },
        "fontSize": {
          "id": 4,
          "type": "float"
        },
        "fontStyle": {
          "id": 2,
          "type": "string"
        },
        "fontWeight": {
          "id": 3,
          "type": "int32"
        },
        "fontFamily": {
          "id": 1,
          "type": "string"
        }
      },
      "oneofs": {
        "_lineHeightPercentFontSize": {
          "oneof": [
            "lineHeightPercentFontSize"
          ]
        },
        "_lineHeightPx": {
          "oneof": [
            "lineHeightPx"
          ]
        }
      }
    },
    "FigFontStyleOverrideValue": {
      "fields": {
        "fills": {
          "id": 5,
          "rule": "repeated",
          "type": "FigPaint"
        },
        "letterSpacing": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "fontSize": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "fontWeight": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "fontFamily": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_letterSpacing": {
          "oneof": [
            "letterSpacing"
          ]
        },
        "_fontSize": {
          "oneof": [
            "fontSize"
          ]
        },
        "_fontWeight": {
          "oneof": [
            "fontWeight"
          ]
        },
        "_fontFamily": {
          "oneof": [
            "fontFamily"
          ]
        }
      }
    },
    "FigPaint": {
      "fields": {
        "gradientTransform": {
          "id": 12,
          "rule": "repeated",
          "type": "float"
        },
        "type": {
          "id": 1,
          "type": "string"
        },
        "scalingFactor": {
          "id": 8,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "imageTransform": {
          "id": 7,
          "rule": "repeated",
          "type": "float"
        },
        "scaleMode": {
          "id": 6,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "filters": {
          "id": 11,
          "options": {
            "proto3_optional": true
          },
          "type": "ImageFilters"
        },
        "blendMode": {
          "id": 5,
          "type": "string"
        },
        "rotation": {
          "id": 9,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "color": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "FigColor"
        },
        "gradientStops": {
          "id": 13,
          "rule": "repeated",
          "type": "FigColorStop"
        },
        "imageRef": {
          "id": 10,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "opacity": {
          "id": 3,
          "type": "float"
        },
        "visible": {
          "id": 2,
          "type": "bool"
        }
      },
      "oneofs": {
        "_scalingFactor": {
          "oneof": [
            "scalingFactor"
          ]
        },
        "_scaleMode": {
          "oneof": [
            "scaleMode"
          ]
        },
        "_filters": {
          "oneof": [
            "filters"
          ]
        },
        "_rotation": {
          "oneof": [
            "rotation"
          ]
        },
        "_color": {
          "oneof": [
            "color"
          ]
        },
        "_imageRef": {
          "oneof": [
            "imageRef"
          ]
        }
      }
    },
    "FigRect": {
      "fields": {
        "height": {
          "id": 4,
          "type": "float"
        },
        "width": {
          "id": 3,
          "type": "float"
        },
        "y": {
          "id": 2,
          "type": "float"
        },
        "x": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "FigTextData": {
      "fields": {
        "styleOverrideTableValues": {
          "id": 4,
          "rule": "repeated",
          "type": "SynergyNode"
        },
        "styleOverrideTableIds": {
          "id": 3,
          "rule": "repeated",
          "type": "int32"
        },
        "characterStyleIDs": {
          "id": 2,
          "rule": "repeated",
          "type": "int32"
        },
        "characters": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "FigVariantProp": {
      "fields": {
        "value": {
          "id": 2,
          "type": "string"
        },
        "name": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "FigVector": {
      "fields": {
        "y": {
          "id": 2,
          "type": "float"
        },
        "x": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "FigmaSchemaInstanceSwapPreferredValue": {
      "fields": {
        "key": {
          "id": 2,
          "type": "string"
        },
        "type": {
          "id": 1,
          "type": "InstanceSwapPreferredValueType"
        }
      }
    },
    "FileTypeToScale": {
      "fields": {
        "pdfConfig": {
          "id": 6,
          "keyType": "int32",
          "type": "bool"
        },
        "svgConfig": {
          "id": 5,
          "keyType": "int32",
          "type": "bool"
        },
        "pngConfig": {
          "id": 2,
          "keyType": "int32",
          "type": "bool"
        },
        "webpConfig": {
          "id": 4,
          "keyType": "int32",
          "type": "bool"
        },
        "jpegConfig": {
          "id": 3,
          "keyType": "int32",
          "type": "bool"
        },
        "selectedFileType": {
          "id": 1,
          "type": "FileType"
        }
      }
    },
    "FillNodesWithImagesParam": {
      "fields": {
        "imageInfos": {
          "id": 2,
          "rule": "repeated",
          "type": "ImageInfo"
        },
        "nodeIds": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "FilterPageNodesCommandParam": {
      "fields": {
        "nodeIds": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "FilterPageNodesCommandRet": {
      "fields": {
        "nodeIds": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "FindClosestComponentByVariantParam": {
      "fields": {
        "variantProps": {
          "id": 2,
          "rule": "repeated",
          "type": "VariantProp"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "FindClosestComponentByVariantRet": {
      "fields": {
        "nodeData": {
          "id": 1,
          "type": "ReplaceInstanceNodeData"
        }
      }
    },
    "FloatSpaceInput": {
      "fields": {
        "cameraY": {
          "id": 3,
          "type": "float"
        },
        "cameraX": {
          "id": 2,
          "type": "float"
        },
        "type": {
          "id": 1,
          "type": "FloatSpaceInputType"
        }
      }
    },
    "FloatWithUnitSuffix": {
      "fields": {
        "unitSuffix": {
          "id": 2,
          "type": "string"
        },
        "value": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "FlowForPanel": {
      "fields": {
        "nodeId": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "description": {
          "id": 2,
          "type": "string"
        },
        "name": {
          "id": 1,
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "FocusAndActiveCommentWithAnimationParam": {
      "fields": {
        "animationParam": {
          "id": 2,
          "type": "StartViewportAnimationParam"
        },
        "commentId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_commentId": {
          "oneof": [
            "commentId"
          ]
        }
      }
    },
    "FocusOnNodesByIdsCommandParam": {
      "fields": {
        "nodeIds": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "FontInfo": {
      "fields": {
        "styles": {
          "id": 2,
          "rule": "repeated",
          "type": "FullFontName"
        },
        "family": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "FontMetaData": {
      "fields": {
        "fontWeight": {
          "id": 4,
          "type": "int32"
        },
        "fontStyle": {
          "id": 3,
          "type": "FontStyle"
        },
        "fontLineHeight": {
          "id": 2,
          "type": "float"
        },
        "key": {
          "id": 1,
          "type": "FontName"
        }
      }
    },
    "FontName": {
      "fields": {
        "style": {
          "id": 2,
          "type": "string"
        },
        "family": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "FontNameInfo": {
      "fields": {
        "usedNodeCount": {
          "id": 3,
          "type": "int32"
        },
        "fullFontName": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "FullFontName"
        },
        "fontName": {
          "id": 1,
          "type": "FontName"
        }
      },
      "oneofs": {
        "_fullFontName": {
          "oneof": [
            "fullFontName"
          ]
        }
      }
    },
    "FontNameRef": {
      "fields": {
        "style": {
          "id": 2,
          "type": "string"
        },
        "family": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "FontVariation": {
      "fields": {
        "value": {
          "id": 3,
          "type": "float"
        },
        "axisName": {
          "id": 2,
          "type": "string"
        },
        "axisTag": {
          "id": 1,
          "type": "uint32"
        }
      }
    },
    "FontVariationAxis": {
      "fields": {
        "hidden": {
          "id": 6,
          "type": "bool"
        },
        "axisTag": {
          "id": 1,
          "type": "uint32"
        },
        "maxValue": {
          "id": 4,
          "type": "float"
        },
        "defaultValue": {
          "id": 5,
          "type": "float"
        },
        "minValue": {
          "id": 3,
          "type": "float"
        },
        "name": {
          "id": 2,
          "type": "string"
        }
      }
    },
    "FontVariationInstanceAxes": {
      "fields": {
        "value": {
          "id": 7,
          "type": "float"
        },
        "hidden": {
          "id": 6,
          "type": "bool"
        },
        "axisTag": {
          "id": 1,
          "type": "uint32"
        },
        "maxValue": {
          "id": 4,
          "type": "float"
        },
        "defaultValue": {
          "id": 5,
          "type": "float"
        },
        "minValue": {
          "id": 3,
          "type": "float"
        },
        "name": {
          "id": 2,
          "type": "string"
        }
      }
    },
    "FontVariationInstances": {
      "fields": {
        "localizedName": {
          "id": 3,
          "type": "string"
        },
        "name": {
          "id": 2,
          "type": "string"
        },
        "axes": {
          "id": 1,
          "keyType": "string",
          "type": "float"
        }
      }
    },
    "FrameTemplateSize": {
      "fields": {
        "height": {
          "id": 2,
          "type": "float"
        },
        "width": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "FrameTemplateSizeName": {
      "fields": {
        "name": {
          "id": 3,
          "type": "string"
        },
        "height": {
          "id": 2,
          "type": "float"
        },
        "width": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "FrameTracingInfo": {
      "fields": {
        "stages": {
          "id": 4,
          "rule": "repeated",
          "type": "RangeMetric"
        },
        "firstTileRenderTime": {
          "id": 3,
          "type": "int64"
        },
        "endRenderTime": {
          "id": 2,
          "type": "int64"
        },
        "startRenderTime": {
          "id": 1,
          "type": "int64"
        }
      }
    },
    "FullFontName": {
      "fields": {
        "variationInstanceAxes": {
          "id": 9,
          "rule": "repeated",
          "type": "FontVariationInstanceAxes"
        },
        "variationAxisTickValues": {
          "id": 10,
          "keyType": "string",
          "type": "BridgeProtoFloatArray"
        },
        "variable": {
          "id": 7,
          "type": "bool"
        },
        "style": {
          "id": 2,
          "type": "string"
        },
        "italic": {
          "id": 6,
          "type": "bool"
        },
        "weight": {
          "id": 5,
          "type": "int32"
        },
        "width": {
          "id": 4,
          "type": "int32"
        },
        "family": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "FuzzCoreDigest": {
      "fields": {
        "items": {
          "id": 1,
          "rule": "repeated",
          "type": "FuzzCoreDigestItem"
        }
      }
    },
    "FuzzCoreDigestItem": {
      "fields": {
        "strokeGeometryBounds": {
          "id": 10,
          "type": "SkRect"
        },
        "fillGeometryBounds": {
          "id": 9,
          "type": "SkRect"
        },
        "transform": {
          "id": 8,
          "type": "Transform"
        },
        "type": {
          "id": 6,
          "type": "NodeType"
        },
        "name": {
          "id": 5,
          "type": "string"
        },
        "id": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "isReallyVisible": {
          "id": 4,
          "type": "bool"
        },
        "size": {
          "id": 7,
          "type": "Vector"
        },
        "locked": {
          "id": 3,
          "type": "bool"
        },
        "visible": {
          "id": 2,
          "type": "bool"
        }
      },
      "oneofs": {
        "_id": {
          "oneof": [
            "id"
          ]
        }
      }
    },
    "FuzzTestOperation": {
      "fields": {
        "subOps": {
          "id": 2,
          "rule": "repeated",
          "type": "FuzzTestOperationType"
        },
        "op": {
          "id": 1,
          "type": "FuzzTestOperationType"
        }
      }
    },
    "FuzzTestOperationList": {
      "fields": {
        "ops": {
          "id": 1,
          "rule": "repeated",
          "type": "FuzzTestOperation"
        }
      }
    },
    "GPUWorkaround": {
      "fields": {
        "mobileOptimizations": {
          "id": 4,
          "type": "bool"
        },
        "glUnexpectedCompositingBug": {
          "id": 3,
          "type": "bool"
        },
        "glDrawClearReorderingBug": {
          "id": 2,
          "type": "bool"
        },
        "glViewportBug": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "GenerateCandidateComponentThumbnailRet": {
      "fields": {
        "showGrid": {
          "id": 2,
          "type": "bool"
        },
        "name": {
          "id": 3,
          "type": "string"
        },
        "thumbnail": {
          "id": 1,
          "type": "Ret_generatePublishMixinThumbnail"
        }
      }
    },
    "GenerateStyleCandidateComponentThumbnailParam": {
      "fields": {
        "exportedDocument": {
          "id": 2,
          "type": "SerializedExportedDocument"
        },
        "toCreateNodeId": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "GeometryVector": {
      "fields": {
        "vector": {
          "id": 1,
          "rule": "repeated",
          "type": "Blob"
        }
      }
    },
    "GetBestFullFontNameParam": {
      "fields": {
        "family": {
          "id": 3,
          "type": "string"
        },
        "fullFontName": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "FullFontName"
        },
        "fontName": {
          "id": 1,
          "type": "FontName"
        }
      },
      "oneofs": {
        "_fullFontName": {
          "oneof": [
            "fullFontName"
          ]
        }
      }
    },
    "GetBestFullFontNameResult": {
      "fields": {
        "fullFontName": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "FullFontName"
        }
      },
      "oneofs": {
        "_fullFontName": {
          "oneof": [
            "fullFontName"
          ]
        }
      }
    },
    "GetLatestPopupState": {
      "fields": {
        "value": {
          "id": 1,
          "type": "PopupState"
        }
      }
    },
    "Glyph": {
      "fields": {
        "glyphBlob": {
          "id": 6,
          "type": "Blob"
        },
        "advance": {
          "id": 5,
          "type": "float"
        },
        "fontSize": {
          "id": 3,
          "type": "float"
        },
        "styleId": {
          "id": 2,
          "type": "int32"
        },
        "firstCharacter": {
          "id": 4,
          "type": "int32"
        },
        "position": {
          "id": 1,
          "type": "Vector"
        }
      }
    },
    "GlyphInfoLine": {
      "fields": {
        "contentBounds": {
          "id": 3,
          "type": "SkRect"
        },
        "glyphInfos": {
          "id": 2,
          "rule": "repeated",
          "type": "SkRect"
        },
        "lineInfo": {
          "id": 1,
          "type": "SkRect"
        }
      }
    },
    "GridLayoutGrid": {
      "fields": {
        "visible": {
          "id": 3,
          "type": "bool"
        },
        "paints": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "Paint"
        },
        "sectionSize": {
          "id": 1,
          "type": "int32"
        }
      },
      "oneofs": {
        "_paints": {
          "oneof": [
            "paints"
          ]
        }
      }
    },
    "Guide": {
      "fields": {
        "offset": {
          "id": 2,
          "type": "float"
        },
        "axis": {
          "id": 1,
          "type": "GuideAxis"
        }
      }
    },
    "GuideInfo": {
      "fields": {
        "parentIdRef": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "indexOfGuideInParent": {
          "id": 1,
          "type": "int32"
        }
      },
      "oneofs": {
        "_parentIdRef": {
          "oneof": [
            "parentIdRef"
          ]
        }
      }
    },
    "HandToolDraggingState": {
      "fields": {
        "startViewportY": {
          "id": 4,
          "type": "float"
        },
        "startViewportX": {
          "id": 3,
          "type": "float"
        },
        "startOffsetY": {
          "id": 2,
          "type": "float"
        },
        "startOffsetX": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "HitTestResultByWorldPosition": {
      "fields": {
        "nodeBoundingOriginWorldPosition": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "AbsoluteWorldPosition"
        },
        "nodeId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "hasTarget": {
          "id": 1,
          "type": "bool"
        }
      },
      "oneofs": {
        "_nodeBoundingOriginWorldPosition": {
          "oneof": [
            "nodeBoundingOriginWorldPosition"
          ]
        },
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "HoverOrSelectNodesBySpecificVariantValueParam": {
      "fields": {
        "propValue": {
          "id": 2,
          "type": "string"
        },
        "propName": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "HoveredHyperlink": {
      "fields": {
        "nodeId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "hyperlinkBox": {
          "id": 1,
          "type": "HyperlinkBox"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "HtmlAIGenParam": {
      "fields": {
        "originalHtml": {
          "id": 7,
          "type": "string"
        },
        "needProtoData": {
          "id": 6,
          "type": "bool"
        },
        "alreadyPlacedFrame": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "to": {
          "id": 3,
          "rule": "repeated",
          "type": "string"
        },
        "from": {
          "id": 2,
          "rule": "repeated",
          "type": "string"
        },
        "svgData": {
          "id": 5,
          "rule": "repeated",
          "type": "SvgData"
        },
        "part": {
          "id": 1,
          "type": "HtmlAIGenResult"
        }
      },
      "oneofs": {
        "_alreadyPlacedFrame": {
          "oneof": [
            "alreadyPlacedFrame"
          ]
        }
      }
    },
    "HtmlAIGenResult": {
      "fields": {
        "schemaVersion": {
          "id": 9,
          "type": "int32"
        },
        "blobs": {
          "id": 8,
          "keyType": "int64",
          "type": "BlobData"
        },
        "nodes": {
          "id": 1,
          "rule": "repeated",
          "type": "SynergyNode"
        }
      }
    },
    "HtmlAIGenReturn": {
      "fields": {
        "protoData": {
          "id": 4,
          "type": "string"
        },
        "to": {
          "id": 3,
          "rule": "repeated",
          "type": "string"
        },
        "from": {
          "id": 2,
          "rule": "repeated",
          "type": "string"
        },
        "rootId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_rootId": {
          "oneof": [
            "rootId"
          ]
        }
      }
    },
    "Hyperlink": {
      "fields": {
        "nodeId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "url": {
          "id": 1,
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "HyperlinkBox": {
      "fields": {
        "hyperlinkID": {
          "id": 4,
          "type": "int32"
        },
        "url": {
          "id": 2,
          "type": "string"
        },
        "nodeId": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "bounds": {
          "id": 1,
          "type": "SkRect"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "HyperlinkEditingState": {
      "fields": {
        "show": {
          "id": 5,
          "type": "bool"
        },
        "cameraY": {
          "id": 4,
          "type": "float"
        },
        "cameraX": {
          "id": 3,
          "type": "float"
        },
        "editingHyperlink": {
          "id": 2,
          "type": "Hyperlink"
        },
        "mode": {
          "id": 1,
          "type": "HyperlinkEditingMode"
        }
      }
    },
    "HyperlinkHoverPopupState": {
      "fields": {
        "hyperlinkNodeName": {
          "id": 8,
          "type": "string"
        },
        "boxOutSet": {
          "id": 7,
          "type": "float"
        },
        "cameraY": {
          "id": 5,
          "type": "float"
        },
        "boxHeight": {
          "id": 6,
          "type": "float"
        },
        "cameraX": {
          "id": 4,
          "type": "float"
        },
        "hyperlinkNodeType": {
          "id": 9,
          "type": "NodeType"
        },
        "hyperlink": {
          "id": 3,
          "type": "Hyperlink"
        },
        "editable": {
          "id": 2,
          "type": "bool"
        },
        "show": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "HyperlinkTarget": {
      "fields": {
        "value": {
          "id": 2,
          "type": "string"
        },
        "type": {
          "id": 1,
          "type": "HyperlinkTargetType"
        }
      }
    },
    "IOSCodeConfig": {
      "fields": {
        "pxRatio": {
          "id": 2,
          "type": "CodeRatio"
        },
        "ptRatio": {
          "id": 3,
          "type": "CodeRatio"
        },
        "unit": {
          "id": 1,
          "type": "IOSUnit"
        }
      }
    },
    "IconPage": {
      "fields": {
        "clusters": {
          "id": 1,
          "rule": "repeated",
          "type": "Cluster"
        }
      }
    },
    "ImageCursor": {
      "fields": {
        "hotspotY": {
          "id": 3,
          "type": "int32"
        },
        "hotspotX": {
          "id": 2,
          "type": "int32"
        },
        "dataUri": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "ImageFilters": {
      "fields": {
        "highlights": {
          "id": 6,
          "type": "float"
        },
        "tint": {
          "id": 5,
          "type": "float"
        },
        "shadows": {
          "id": 7,
          "type": "float"
        },
        "saturation": {
          "id": 3,
          "type": "float"
        },
        "temperature": {
          "id": 4,
          "type": "float"
        },
        "contrast": {
          "id": 2,
          "type": "float"
        },
        "exposure": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "ImageId": {
      "fields": {
        "type": {
          "id": 5,
          "type": "ImageType"
        },
        "isEmoji": {
          "id": 4,
          "type": "bool"
        },
        "nodeId": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "shouldGeneratePreview": {
          "id": 2,
          "type": "bool"
        },
        "imageId": {
          "id": 1,
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "ImageInfo": {
      "fields": {
        "usingKtx": {
          "id": 15,
          "type": "bool"
        },
        "resourcePixelsHandle": {
          "id": 12,
          "type": "uint32"
        },
        "colorProfile": {
          "id": 11,
          "type": "DocumentColorProfile"
        },
        "imageName": {
          "id": 2,
          "type": "string"
        },
        "yDPI": {
          "id": 10,
          "type": "int32"
        },
        "xDPI": {
          "id": 9,
          "type": "int32"
        },
        "hasGamma": {
          "id": 13,
          "type": "bool"
        },
        "imageId": {
          "id": 1,
          "type": "string"
        },
        "previewId": {
          "id": 8,
          "type": "string"
        },
        "svgBlob": {
          "id": 7,
          "type": "bytes"
        },
        "imageWidth": {
          "id": 3,
          "type": "int32"
        },
        "chunkBytesList": {
          "id": 14,
          "rule": "repeated",
          "type": "uint32"
        },
        "blob": {
          "id": 5,
          "type": "bytes"
        },
        "baseUrl": {
          "id": 6,
          "type": "string"
        },
        "imageHeight": {
          "id": 4,
          "type": "int32"
        }
      }
    },
    "ImageStates": {
      "fields": {
        "imageStates": {
          "id": 1,
          "keyType": "string",
          "type": "ImageState"
        }
      }
    },
    "ImportLibraryNodesCommandParam": {
      "fields": {
        "importNodes": {
          "id": 1,
          "rule": "repeated",
          "type": "ImportSingleLibraryNode"
        }
      }
    },
    "ImportLibraryNodesCommandResult": {
      "fields": {
        "resultIds": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "ImportNodeFromLibraryParam": {
      "fields": {
        "key": {
          "id": 3,
          "type": "string"
        },
        "toCreateNodeId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "exportedDocument": {
          "id": 1,
          "type": "SerializedExportedDocument"
        }
      },
      "oneofs": {
        "_toCreateNodeId": {
          "oneof": [
            "toCreateNodeId"
          ]
        }
      }
    },
    "ImportNodeFromLibraryResult": {
      "fields": {
        "id": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_id": {
          "oneof": [
            "id"
          ]
        }
      }
    },
    "ImportSingleLibraryNode": {
      "fields": {
        "toCreateNodeId": {
          "id": 2,
          "type": "string"
        },
        "exportedDocumentData": {
          "id": 1,
          "type": "bytes"
        }
      }
    },
    "IncreaseValueParam": {
      "fields": {
        "delta": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "IndexData": {
      "fields": {
        "key": {
          "id": 2,
          "type": "string"
        },
        "indexId": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "InsertNodeParam": {
      "fields": {
        "insertIndex": {
          "id": 2,
          "type": "int32"
        },
        "parent": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "InsertNodeState": {
      "fields": {
        "startRelativeTransform": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "Transform"
        },
        "currentNodeId": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "startParentWorldTransform": {
          "id": 5,
          "type": "Transform"
        },
        "startPosition": {
          "id": 2,
          "type": "Vector"
        },
        "parentNodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_startRelativeTransform": {
          "oneof": [
            "startRelativeTransform"
          ]
        },
        "_currentNodeId": {
          "oneof": [
            "currentNodeId"
          ]
        },
        "_parentNodeId": {
          "oneof": [
            "parentNodeId"
          ]
        }
      }
    },
    "InspectEffectViewState": {
      "fields": {
        "styleDescription": {
          "id": 4,
          "type": "string"
        },
        "effects": {
          "id": 2,
          "rule": "repeated",
          "type": "Effect"
        },
        "variables": {
          "id": 5,
          "rule": "repeated",
          "type": "SingleVariable"
        },
        "styleName": {
          "id": 3,
          "type": "string"
        },
        "display": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "InspectSelectionCodeViewState": {
      "fields": {
        "codeType": {
          "id": 1,
          "type": "CodeType"
        }
      }
    },
    "InspectSelectionComponentInfoViewState": {
      "fields": {
        "ownerNodeId": {
          "id": 4,
          "type": "string"
        },
        "ownerNodeName": {
          "id": 3,
          "type": "string"
        },
        "ownerNodeIconData": {
          "id": 2,
          "type": "NodeIconData"
        },
        "show": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "InspectSelectionComponentPropInfoViewState": {
      "fields": {
        "props": {
          "id": 2,
          "rule": "repeated",
          "type": "ComponentRelatedDisplayProp"
        },
        "show": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "InspectSelectionFillItem": {
      "fields": {
        "paints": {
          "id": 3,
          "rule": "repeated",
          "type": "Paint"
        },
        "styleDescription": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "variables": {
          "id": 4,
          "rule": "repeated",
          "type": "SingleVariable"
        },
        "styleName": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_styleDescription": {
          "oneof": [
            "styleDescription"
          ]
        },
        "_styleName": {
          "oneof": [
            "styleName"
          ]
        }
      }
    },
    "InspectSelectionFillViewState": {
      "fields": {
        "inspectSelectionFillItems": {
          "id": 2,
          "rule": "repeated",
          "type": "InspectSelectionFillItem"
        },
        "show": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "InspectSelectionTextContentViewState": {
      "fields": {
        "characters": {
          "id": 2,
          "type": "string"
        },
        "show": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "InspectSelectionTextTypographyViewState": {
      "fields": {
        "paragraphSpacing": {
          "id": 7,
          "type": "float"
        },
        "textStyleNodeNameMap": {
          "id": 5,
          "keyType": "string",
          "type": "string"
        },
        "textAlignHorizontal": {
          "id": 3,
          "type": "TextAlignHorizontal"
        },
        "textStyles": {
          "id": 2,
          "rule": "repeated",
          "type": "TextStyle"
        },
        "fontMetaData": {
          "id": 6,
          "rule": "repeated",
          "type": "FontMetaData"
        },
        "textAlignVertical": {
          "id": 4,
          "type": "TextAlignVertical"
        },
        "show": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "InspectSelectionVariantProps": {
      "fields": {
        "value": {
          "id": 2,
          "type": "string"
        },
        "key": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "InspectSelectionVariantViewState": {
      "fields": {
        "missingPropNames": {
          "id": 3,
          "rule": "repeated",
          "type": "string"
        },
        "props": {
          "id": 2,
          "rule": "repeated",
          "type": "InspectSelectionVariantProps"
        },
        "show": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "InstanceResetAssignmentType": {
      "fields": {
        "defId": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "name": {
          "id": 2,
          "type": "string"
        },
        "defId2EffectdNodeIds": {
          "id": 4,
          "keyType": "string",
          "type": "EffectdNodeIds"
        },
        "type": {
          "id": 1,
          "type": "ComponentPropType"
        }
      },
      "oneofs": {
        "_defId": {
          "oneof": [
            "defId"
          ]
        }
      }
    },
    "InstanceResetPropType": {
      "fields": {
        "name": {
          "id": 2,
          "type": "string"
        },
        "type": {
          "id": 1,
          "type": "InstanceResetType"
        }
      }
    },
    "InstanceState": {
      "fields": {
        "showVariantProp": {
          "id": 16,
          "type": "bool"
        },
        "compDesc": {
          "id": 14,
          "type": "string"
        },
        "compSetDesc": {
          "id": 15,
          "type": "string"
        },
        "resetAssignments": {
          "id": 13,
          "rule": "repeated",
          "type": "InstanceResetAssignmentType"
        },
        "showRecoverComponent": {
          "id": 12,
          "type": "bool"
        },
        "disableReplaceComponent": {
          "id": 11,
          "type": "bool"
        },
        "disableDetachInstance": {
          "id": 7,
          "type": "bool"
        },
        "disableGoToMainComponent": {
          "id": 9,
          "type": "bool"
        },
        "selectOuterInstanceState": {
          "id": 10,
          "options": {
            "proto3_optional": true
          },
          "type": "SelectOuterInstanceState"
        },
        "isMixed": {
          "id": 2,
          "type": "bool"
        },
        "disablePushChangesToMainComp": {
          "id": 8,
          "type": "bool"
        },
        "shouldDisplay": {
          "id": 1,
          "type": "bool"
        },
        "disableMoreActions": {
          "id": 6,
          "type": "bool"
        },
        "name": {
          "id": 4,
          "type": "string"
        },
        "isRemote": {
          "id": 3,
          "type": "bool"
        },
        "resetTypes": {
          "id": 5,
          "rule": "repeated",
          "type": "InstanceResetPropType"
        }
      },
      "oneofs": {
        "_selectOuterInstanceState": {
          "oneof": [
            "selectOuterInstanceState"
          ]
        }
      }
    },
    "InstanceSwapPreferredValue": {
      "fields": {
        "publishId": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "publishFile": {
          "id": 2,
          "type": "string"
        },
        "type": {
          "id": 1,
          "type": "InstanceSwapPreferredValueType"
        }
      },
      "oneofs": {
        "_publishId": {
          "oneof": [
            "publishId"
          ]
        }
      }
    },
    "KeyTrigger": {
      "fields": {
        "triggerDevice": {
          "id": 2,
          "type": "TriggerDevice"
        },
        "keyCodes": {
          "id": 1,
          "rule": "repeated",
          "type": "int32"
        }
      }
    },
    "KeyboardEvent": {
      "fields": {
        "documentActiveElement": {
          "id": 12,
          "type": "string"
        },
        "source": {
          "id": 10,
          "type": "int32"
        },
        "repeat": {
          "id": 9,
          "type": "bool"
        },
        "metaKey": {
          "id": 8,
          "type": "bool"
        },
        "shiftKey": {
          "id": 6,
          "type": "bool"
        },
        "type": {
          "id": 1,
          "type": "KeyEventType"
        },
        "keyCode": {
          "id": 2,
          "type": "int32"
        },
        "ctrlKey": {
          "id": 5,
          "type": "bool"
        },
        "key": {
          "id": 3,
          "type": "string"
        },
        "eventTarget": {
          "id": 11,
          "type": "string"
        },
        "altKey": {
          "id": 7,
          "type": "bool"
        },
        "code": {
          "id": 4,
          "type": "string"
        }
      }
    },
    "KeyboardEventContinuation": {
      "fields": {
        "preventDefault": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "LayerPanelItem": {
      "fields": {
        "type": {
          "id": 2,
          "type": "LayerPanelItemType"
        },
        "id": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_id": {
          "oneof": [
            "id"
          ]
        }
      }
    },
    "LayoutGrid": {
      "fields": {
        "offsetVar": {
          "id": 13,
          "options": {
            "proto3_optional": true
          },
          "type": "VariableAliasData"
        },
        "gutterSizeVar": {
          "id": 12,
          "options": {
            "proto3_optional": true
          },
          "type": "VariableAliasData"
        },
        "countVar": {
          "id": 11,
          "options": {
            "proto3_optional": true
          },
          "type": "VariableAliasData"
        },
        "sectionSize": {
          "id": 8,
          "type": "int32"
        },
        "offset": {
          "id": 7,
          "type": "int32"
        },
        "gutterSize": {
          "id": 6,
          "type": "int32"
        },
        "align": {
          "id": 4,
          "type": "RowsColsLayoutGridAlign"
        },
        "paints": {
          "id": 10,
          "options": {
            "proto3_optional": true
          },
          "type": "Paint"
        },
        "_deprecatedRowsColsLayoutGrid": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "RowsColsLayoutGrid"
        },
        "visible": {
          "id": 9,
          "type": "bool"
        },
        "count": {
          "id": 5,
          "type": "int32"
        },
        "sectionSizeVar": {
          "id": 14,
          "options": {
            "proto3_optional": true
          },
          "type": "VariableAliasData"
        },
        "_deprecatedGridLayoutGrid": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "GridLayoutGrid"
        },
        "pattern": {
          "id": 1,
          "type": "LayoutGridPattern"
        }
      },
      "oneofs": {
        "_offsetVar": {
          "oneof": [
            "offsetVar"
          ]
        },
        "_gutterSizeVar": {
          "oneof": [
            "gutterSizeVar"
          ]
        },
        "_countVar": {
          "oneof": [
            "countVar"
          ]
        },
        "_paints": {
          "oneof": [
            "paints"
          ]
        },
        "__deprecatedRowsColsLayoutGrid": {
          "oneof": [
            "_deprecatedRowsColsLayoutGrid"
          ]
        },
        "_sectionSizeVar": {
          "oneof": [
            "sectionSizeVar"
          ]
        },
        "__deprecatedGridLayoutGrid": {
          "oneof": [
            "_deprecatedGridLayoutGrid"
          ]
        }
      }
    },
    "LayoutGridStyleNodeParam": {
      "fields": {
        "publishHidden": {
          "id": 7,
          "type": "bool"
        },
        "visible": {
          "id": 8,
          "type": "bool"
        },
        "contentHash": {
          "id": 6,
          "type": "string"
        },
        "key": {
          "id": 5,
          "type": "string"
        },
        "description": {
          "id": 4,
          "type": "string"
        },
        "layoutGrids": {
          "id": 3,
          "rule": "repeated",
          "type": "LayoutGrid"
        },
        "type": {
          "id": 2,
          "type": "NodeType"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "LetterSpacing": {
      "fields": {
        "unit": {
          "id": 2,
          "type": "NumberUnit"
        },
        "value": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "LibrariesColorVariable": {
      "fields": {
        "remoteColorLibraries": {
          "id": 3,
          "rule": "repeated",
          "type": "RemoteColorLibrary"
        },
        "localUnknownColorCollections": {
          "id": 2,
          "rule": "repeated",
          "type": "LocalColorCollection"
        },
        "localKnownColorCollections": {
          "id": 1,
          "rule": "repeated",
          "type": "LocalColorCollection"
        }
      }
    },
    "LibrariesPrimitiveVariable": {
      "fields": {
        "remoteLibraries": {
          "id": 2,
          "rule": "repeated",
          "type": "RemotePrimitiveLibrary"
        },
        "localCollections": {
          "id": 1,
          "rule": "repeated",
          "type": "LocalPrimitiveCollection"
        }
      }
    },
    "LibraryEffectStyleGroup": {
      "fields": {
        "items": {
          "id": 2,
          "rule": "repeated",
          "type": "LibraryEffectStyleNodeItem"
        },
        "name": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "LibraryEffectStyleNodeItem": {
      "fields": {
        "fromFig": {
          "id": 8,
          "type": "bool"
        },
        "publishId": {
          "id": 7,
          "type": "string"
        },
        "description": {
          "id": 3,
          "type": "string"
        },
        "key": {
          "id": 5,
          "type": "string"
        },
        "effects": {
          "id": 4,
          "rule": "repeated",
          "type": "Effect"
        },
        "name": {
          "id": 2,
          "type": "string"
        },
        "publishFile": {
          "id": 6,
          "type": "string"
        },
        "id": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_id": {
          "oneof": [
            "id"
          ]
        }
      }
    },
    "LibraryEffectStyleRemoteGroup": {
      "fields": {
        "items": {
          "id": 2,
          "rule": "repeated",
          "type": "StyleGetVO"
        },
        "name": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "LibraryEffectStyleRemoteItem": {
      "fields": {
        "documentId": {
          "id": 3,
          "type": "string"
        },
        "groups": {
          "id": 4,
          "rule": "repeated",
          "type": "LibraryEffectStyleRemoteGroup"
        },
        "libraryName": {
          "id": 2,
          "type": "string"
        },
        "libraryId": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "LibraryEffectStyleState": {
      "fields": {
        "fakeFieldToForceUpdate": {
          "id": 5,
          "type": "bool"
        },
        "subscribedRemoteItems": {
          "id": 3,
          "rule": "repeated",
          "type": "LibraryEffectStyleRemoteItem"
        },
        "inUseUnknownStyleNodeItems": {
          "id": 2,
          "rule": "repeated",
          "type": "LibraryEffectStyleNodeItem"
        },
        "inUseOtherRemoteItems": {
          "id": 4,
          "rule": "repeated",
          "type": "LibraryEffectStyleRemoteItem"
        },
        "localStyleGroups": {
          "id": 1,
          "rule": "repeated",
          "type": "LibraryEffectStyleGroup"
        }
      }
    },
    "LibraryIdInfo": {
      "fields": {
        "documentId": {
          "id": 2,
          "type": "string"
        },
        "libraryId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_libraryId": {
          "oneof": [
            "libraryId"
          ]
        }
      }
    },
    "LibraryLayoutGridStyleGroup": {
      "fields": {
        "items": {
          "id": 2,
          "rule": "repeated",
          "type": "LibraryLayoutGridStyleNodeItem"
        },
        "name": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "LibraryLayoutGridStyleNodeItem": {
      "fields": {
        "fromFig": {
          "id": 8,
          "type": "bool"
        },
        "publishId": {
          "id": 7,
          "type": "string"
        },
        "description": {
          "id": 3,
          "type": "string"
        },
        "key": {
          "id": 5,
          "type": "string"
        },
        "publishFile": {
          "id": 6,
          "type": "string"
        },
        "id": {
          "id": 1,
          "type": "string"
        },
        "layoutGrids": {
          "id": 4,
          "rule": "repeated",
          "type": "LayoutGrid"
        },
        "name": {
          "id": 2,
          "type": "string"
        }
      }
    },
    "LibraryLayoutGridStyleRemoteGroup": {
      "fields": {
        "items": {
          "id": 2,
          "rule": "repeated",
          "type": "StyleGetVO"
        },
        "name": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "LibraryLayoutGridStyleRemoteItem": {
      "fields": {
        "documentId": {
          "id": 3,
          "type": "string"
        },
        "groups": {
          "id": 4,
          "rule": "repeated",
          "type": "LibraryLayoutGridStyleRemoteGroup"
        },
        "libraryName": {
          "id": 2,
          "type": "string"
        },
        "libraryId": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "LibraryLayoutGridStyleState": {
      "fields": {
        "fakeFieldToForceUpdate": {
          "id": 5,
          "type": "bool"
        },
        "subscribedRemoteItems": {
          "id": 3,
          "rule": "repeated",
          "type": "LibraryLayoutGridStyleRemoteItem"
        },
        "inUseUnknownStyleNodeItems": {
          "id": 2,
          "rule": "repeated",
          "type": "LibraryLayoutGridStyleNodeItem"
        },
        "inUseOtherRemoteItems": {
          "id": 4,
          "rule": "repeated",
          "type": "LibraryLayoutGridStyleRemoteItem"
        },
        "localStyleGroups": {
          "id": 1,
          "rule": "repeated",
          "type": "LibraryLayoutGridStyleGroup"
        }
      }
    },
    "LibraryMoveHistoryItem": {
      "fields": {
        "sourceFileKey": {
          "id": 3,
          "type": "string"
        },
        "sourceComponentKey": {
          "id": 2,
          "type": "string"
        },
        "sourcePublishId": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "sourceNodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_sourcePublishId": {
          "oneof": [
            "sourcePublishId"
          ]
        },
        "_sourceNodeId": {
          "oneof": [
            "sourceNodeId"
          ]
        }
      }
    },
    "LibraryMoveInfo": {
      "fields": {
        "pasteFileKey": {
          "id": 2,
          "type": "string"
        },
        "oldKey": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "LibraryOperation": {
      "fields": {
        "variableSetKeys": {
          "id": 6,
          "rule": "repeated",
          "type": "string"
        },
        "variableKeys": {
          "id": 5,
          "rule": "repeated",
          "type": "string"
        },
        "componentKeys": {
          "id": 2,
          "rule": "repeated",
          "type": "string"
        },
        "componentSetKeys": {
          "id": 3,
          "rule": "repeated",
          "type": "string"
        },
        "styleKeys": {
          "id": 4,
          "rule": "repeated",
          "type": "string"
        },
        "operation": {
          "id": 1,
          "type": "LibraryOperationVO"
        }
      }
    },
    "LibraryOperationVO": {
      "fields": {
        "description": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "libraryId": {
          "id": 2,
          "type": "string"
        },
        "operationTime": {
          "id": 4,
          "type": "int64"
        },
        "id": {
          "id": 1,
          "type": "string"
        }
      },
      "oneofs": {
        "_description": {
          "oneof": [
            "description"
          ]
        }
      }
    },
    "LibraryPaintStyleGroup": {
      "fields": {
        "items": {
          "id": 2,
          "rule": "repeated",
          "type": "LibraryPaintStyleNodeItem"
        },
        "name": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "LibraryPaintStyleNodeItem": {
      "fields": {
        "publishId": {
          "id": 8,
          "type": "string"
        },
        "contentHash": {
          "id": 9,
          "type": "string"
        },
        "fromFig": {
          "id": 3,
          "type": "bool"
        },
        "paints": {
          "id": 5,
          "rule": "repeated",
          "type": "Paint"
        },
        "key": {
          "id": 6,
          "type": "string"
        },
        "description": {
          "id": 4,
          "type": "string"
        },
        "name": {
          "id": 2,
          "type": "string"
        },
        "publishFile": {
          "id": 7,
          "type": "string"
        },
        "id": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "LibraryPaintStyleRemoteGroup": {
      "fields": {
        "items": {
          "id": 2,
          "rule": "repeated",
          "type": "StyleGetVO"
        },
        "name": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "LibraryPaintStyleRemoteItem": {
      "fields": {
        "documentId": {
          "id": 3,
          "type": "string"
        },
        "groups": {
          "id": 4,
          "rule": "repeated",
          "type": "LibraryPaintStyleRemoteGroup"
        },
        "libraryName": {
          "id": 2,
          "type": "string"
        },
        "libraryId": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "LibraryPaintStyleState": {
      "fields": {
        "fakeFieldToForceUpdate": {
          "id": 5,
          "type": "bool"
        },
        "subscribedRemoteItems": {
          "id": 3,
          "rule": "repeated",
          "type": "LibraryPaintStyleRemoteItem"
        },
        "inUseUnknownStyleNodeItems": {
          "id": 2,
          "rule": "repeated",
          "type": "LibraryPaintStyleNodeItem"
        },
        "inUseOtherRemoteItems": {
          "id": 4,
          "rule": "repeated",
          "type": "LibraryPaintStyleRemoteItem"
        },
        "localStyleGroups": {
          "id": 1,
          "rule": "repeated",
          "type": "LibraryPaintStyleGroup"
        }
      }
    },
    "LibraryPublishComponentItem": {
      "fields": {
        "nodeId": {
          "id": 5,
          "type": "string"
        },
        "removedLibraryVariantKeys": {
          "id": 4,
          "rule": "repeated",
          "type": "string"
        },
        "libraryNodeKey": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "changeType": {
          "id": 2,
          "type": "LibraryChangeType"
        },
        "changeKey": {
          "id": 1,
          "type": "string"
        }
      },
      "oneofs": {
        "_libraryNodeKey": {
          "oneof": [
            "libraryNodeKey"
          ]
        }
      }
    },
    "LibraryReplaceModalItemCheckedKey": {
      "fields": {
        "remoteId": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "publishId": {
          "id": 2,
          "type": "string"
        },
        "displayType": {
          "id": 1,
          "type": "LibraryReplaceModalItemDisplayType"
        }
      },
      "oneofs": {
        "_remoteId": {
          "oneof": [
            "remoteId"
          ]
        }
      }
    },
    "LibraryTextStyleGroup": {
      "fields": {
        "items": {
          "id": 2,
          "rule": "repeated",
          "type": "LibraryTextStyleNodeItem"
        },
        "name": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "LibraryTextStyleNodeItem": {
      "fields": {
        "contentHash": {
          "id": 16,
          "type": "string"
        },
        "publishFile": {
          "id": 14,
          "type": "string"
        },
        "fontVariations": {
          "id": 12,
          "rule": "repeated",
          "type": "FontVariation"
        },
        "fromFig": {
          "id": 3,
          "type": "bool"
        },
        "paragraphSpacing": {
          "id": 6,
          "type": "float"
        },
        "key": {
          "id": 13,
          "type": "string"
        },
        "textDecoration": {
          "id": 9,
          "type": "TextDecoration"
        },
        "fontName": {
          "id": 8,
          "type": "FontName"
        },
        "fontSize": {
          "id": 7,
          "type": "float"
        },
        "missFontInfo": {
          "id": 17,
          "type": "VMissFontInfo"
        },
        "paragraphIndent": {
          "id": 5,
          "type": "float"
        },
        "lineHeight": {
          "id": 10,
          "type": "LineHeight"
        },
        "description": {
          "id": 4,
          "type": "string"
        },
        "letterSpacing": {
          "id": 11,
          "type": "LetterSpacing"
        },
        "name": {
          "id": 2,
          "type": "string"
        },
        "publishId": {
          "id": 15,
          "type": "string"
        },
        "id": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "LibraryTextStyleRemoteGroup": {
      "fields": {
        "items": {
          "id": 2,
          "rule": "repeated",
          "type": "StyleGetVO"
        },
        "name": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "LibraryTextStyleRemoteItem": {
      "fields": {
        "documentId": {
          "id": 3,
          "type": "string"
        },
        "groups": {
          "id": 4,
          "rule": "repeated",
          "type": "LibraryTextStyleRemoteGroup"
        },
        "libraryName": {
          "id": 2,
          "type": "string"
        },
        "libraryId": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "LibraryTextStyleState": {
      "fields": {
        "fakeFieldToForceUpdate": {
          "id": 5,
          "type": "bool"
        },
        "subscribedRemoteItems": {
          "id": 3,
          "rule": "repeated",
          "type": "LibraryTextStyleRemoteItem"
        },
        "inUseUnknownStyleNodeItems": {
          "id": 2,
          "rule": "repeated",
          "type": "LibraryTextStyleNodeItem"
        },
        "inUseOtherRemoteItems": {
          "id": 4,
          "rule": "repeated",
          "type": "LibraryTextStyleRemoteItem"
        },
        "localStyleGroups": {
          "id": 1,
          "rule": "repeated",
          "type": "LibraryTextStyleGroup"
        }
      }
    },
    "LibraryVO": {
      "fields": {
        "shareScope": {
          "id": 4,
          "type": "string"
        },
        "document": {
          "id": 2,
          "type": "DocumentGetVO"
        },
        "shared": {
          "id": 3,
          "type": "bool"
        },
        "id": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "LibraryVariantPair": {
      "fields": {
        "contentHash": {
          "id": 2,
          "type": "string"
        },
        "nodeKey": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "LineHeight": {
      "fields": {
        "unit": {
          "id": 2,
          "type": "NumberUnit"
        },
        "value": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "LocalColorCollection": {
      "fields": {
        "variables": {
          "id": 4,
          "rule": "repeated",
          "type": "LocalColorVariable"
        },
        "name": {
          "id": 3,
          "type": "string"
        },
        "nodeId": {
          "id": 2,
          "type": "string"
        },
        "key": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "LocalColorVariable": {
      "fields": {
        "isLocal": {
          "id": 8,
          "type": "bool"
        },
        "color": {
          "id": 7,
          "options": {
            "proto3_optional": true
          },
          "type": "RGBA"
        },
        "scopes": {
          "id": 6,
          "rule": "repeated",
          "type": "VariableScope"
        },
        "key": {
          "id": 1,
          "type": "string"
        },
        "variableSetId": {
          "id": 3,
          "type": "string"
        },
        "description": {
          "id": 5,
          "type": "string"
        },
        "name": {
          "id": 4,
          "type": "string"
        },
        "nodeId": {
          "id": 2,
          "type": "string"
        }
      },
      "oneofs": {
        "_color": {
          "oneof": [
            "color"
          ]
        }
      }
    },
    "LocalEffectStyleItem": {
      "fields": {
        "styleNode": {
          "id": 6,
          "options": {
            "proto3_optional": true
          },
          "type": "LocalEffectStyleNode"
        },
        "folderName": {
          "id": 4,
          "type": "string"
        },
        "folderMasterStyleId": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "styleId": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "children": {
          "id": 7,
          "rule": "repeated",
          "type": "LocalEffectStyleItem"
        },
        "level": {
          "id": 2,
          "type": "int32"
        },
        "isFolder": {
          "id": 1,
          "type": "bool"
        }
      },
      "oneofs": {
        "_styleNode": {
          "oneof": [
            "styleNode"
          ]
        },
        "_folderMasterStyleId": {
          "oneof": [
            "folderMasterStyleId"
          ]
        },
        "_styleId": {
          "oneof": [
            "styleId"
          ]
        }
      }
    },
    "LocalEffectStyleNode": {
      "fields": {
        "effects": {
          "id": 4,
          "rule": "repeated",
          "type": "Effect"
        },
        "description": {
          "id": 3,
          "type": "string"
        },
        "name": {
          "id": 2,
          "type": "string"
        },
        "id": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_id": {
          "oneof": [
            "id"
          ]
        }
      }
    },
    "LocalLayoutGridStyleItem": {
      "fields": {
        "styleNode": {
          "id": 6,
          "options": {
            "proto3_optional": true
          },
          "type": "LocalLayoutGridStyleNode"
        },
        "folderName": {
          "id": 4,
          "type": "string"
        },
        "folderMasterStyleId": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "styleId": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "children": {
          "id": 7,
          "rule": "repeated",
          "type": "LocalLayoutGridStyleItem"
        },
        "level": {
          "id": 2,
          "type": "int32"
        },
        "isFolder": {
          "id": 1,
          "type": "bool"
        }
      },
      "oneofs": {
        "_styleNode": {
          "oneof": [
            "styleNode"
          ]
        },
        "_folderMasterStyleId": {
          "oneof": [
            "folderMasterStyleId"
          ]
        },
        "_styleId": {
          "oneof": [
            "styleId"
          ]
        }
      }
    },
    "LocalLayoutGridStyleNode": {
      "fields": {
        "layoutGrids": {
          "id": 4,
          "rule": "repeated",
          "type": "LayoutGrid"
        },
        "description": {
          "id": 3,
          "type": "string"
        },
        "name": {
          "id": 2,
          "type": "string"
        },
        "id": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_id": {
          "oneof": [
            "id"
          ]
        }
      }
    },
    "LocalPaintStyleItem": {
      "fields": {
        "styleNode": {
          "id": 6,
          "options": {
            "proto3_optional": true
          },
          "type": "LocalPaintStyleNode"
        },
        "folderName": {
          "id": 4,
          "type": "string"
        },
        "folderMasterStyleId": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "styleId": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "children": {
          "id": 7,
          "rule": "repeated",
          "type": "LocalPaintStyleItem"
        },
        "level": {
          "id": 2,
          "type": "int32"
        },
        "isFolder": {
          "id": 1,
          "type": "bool"
        }
      },
      "oneofs": {
        "_styleNode": {
          "oneof": [
            "styleNode"
          ]
        },
        "_folderMasterStyleId": {
          "oneof": [
            "folderMasterStyleId"
          ]
        },
        "_styleId": {
          "oneof": [
            "styleId"
          ]
        }
      }
    },
    "LocalPaintStyleNode": {
      "fields": {
        "contentHash": {
          "id": 5,
          "type": "string"
        },
        "paints": {
          "id": 4,
          "rule": "repeated",
          "type": "Paint"
        },
        "description": {
          "id": 3,
          "type": "string"
        },
        "name": {
          "id": 2,
          "type": "string"
        },
        "id": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_id": {
          "oneof": [
            "id"
          ]
        }
      }
    },
    "LocalPrimitiveCollection": {
      "fields": {
        "variables": {
          "id": 4,
          "rule": "repeated",
          "type": "LocalPrimitiveVariable"
        },
        "name": {
          "id": 3,
          "type": "string"
        },
        "nodeId": {
          "id": 2,
          "type": "string"
        },
        "key": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "LocalPrimitiveVariable": {
      "fields": {
        "isLocal": {
          "id": 8,
          "type": "bool"
        },
        "data": {
          "id": 7,
          "type": "PrimitiveVariableData"
        },
        "scopes": {
          "id": 6,
          "rule": "repeated",
          "type": "VariableScope"
        },
        "key": {
          "id": 1,
          "type": "string"
        },
        "variableSetId": {
          "id": 3,
          "type": "string"
        },
        "description": {
          "id": 5,
          "type": "string"
        },
        "name": {
          "id": 4,
          "type": "string"
        },
        "nodeId": {
          "id": 2,
          "type": "string"
        }
      }
    },
    "LocalStyleEditingName": {
      "fields": {
        "editingStyleId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "editingFolderName": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "type": {
          "id": 1,
          "type": "LocalStyleEditingNameType"
        }
      },
      "oneofs": {
        "_editingStyleId": {
          "oneof": [
            "editingStyleId"
          ]
        },
        "_editingFolderName": {
          "oneof": [
            "editingFolderName"
          ]
        }
      }
    },
    "LocalStyleEditingState": {
      "fields": {
        "editingStyleId": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "editingName": {
          "id": 2,
          "type": "LocalStyleEditingName"
        },
        "selection": {
          "id": 1,
          "type": "LocalStyleSelection"
        }
      },
      "oneofs": {
        "_editingStyleId": {
          "oneof": [
            "editingStyleId"
          ]
        }
      }
    },
    "LocalStyleSelection": {
      "fields": {
        "styleIds": {
          "id": 3,
          "rule": "repeated",
          "type": "string"
        },
        "folderNames": {
          "id": 2,
          "rule": "repeated",
          "type": "string"
        },
        "type": {
          "id": 1,
          "type": "LocalStyleSelectionType"
        }
      }
    },
    "LocalTextStyleItem": {
      "fields": {
        "styleNode": {
          "id": 6,
          "options": {
            "proto3_optional": true
          },
          "type": "LocalTextStyleNode"
        },
        "folderName": {
          "id": 4,
          "type": "string"
        },
        "folderMasterStyleId": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "styleId": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "children": {
          "id": 7,
          "rule": "repeated",
          "type": "LocalTextStyleItem"
        },
        "level": {
          "id": 2,
          "type": "int32"
        },
        "isFolder": {
          "id": 1,
          "type": "bool"
        }
      },
      "oneofs": {
        "_styleNode": {
          "oneof": [
            "styleNode"
          ]
        },
        "_folderMasterStyleId": {
          "oneof": [
            "folderMasterStyleId"
          ]
        },
        "_styleId": {
          "oneof": [
            "styleId"
          ]
        }
      }
    },
    "LocalTextStyleNode": {
      "fields": {
        "contentHash": {
          "id": 12,
          "type": "string"
        },
        "fontVariations": {
          "id": 11,
          "rule": "repeated",
          "type": "FontVariation"
        },
        "paragraphSpacing": {
          "id": 5,
          "type": "float"
        },
        "lineHeight": {
          "id": 9,
          "type": "LineHeight"
        },
        "description": {
          "id": 3,
          "type": "string"
        },
        "letterSpacing": {
          "id": 10,
          "type": "LetterSpacing"
        },
        "fontName": {
          "id": 7,
          "type": "FontName"
        },
        "textDecoration": {
          "id": 8,
          "type": "TextDecoration"
        },
        "name": {
          "id": 2,
          "type": "string"
        },
        "fontSize": {
          "id": 6,
          "type": "float"
        },
        "missFontInfo": {
          "id": 13,
          "type": "VMissFontInfo"
        },
        "paragraphIndent": {
          "id": 4,
          "type": "float"
        },
        "id": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_id": {
          "oneof": [
            "id"
          ]
        }
      }
    },
    "LocalVariable": {
      "fields": {
        "scopes": {
          "id": 9,
          "rule": "repeated",
          "type": "VariableScope"
        },
        "codeSyntax": {
          "id": 8,
          "rule": "repeated",
          "type": "VariableCodeSyntax"
        },
        "dataValues": {
          "id": 7,
          "keyType": "string",
          "type": "LocalVariableData"
        },
        "dataType": {
          "id": 6,
          "type": "VariableResolvedDataType"
        },
        "position": {
          "id": 5,
          "type": "int32"
        },
        "variableSetId": {
          "id": 4,
          "type": "string"
        },
        "description": {
          "id": 3,
          "type": "string"
        },
        "isPublishable": {
          "id": 10,
          "type": "bool"
        },
        "name": {
          "id": 2,
          "type": "string"
        },
        "id": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "LocalVariableCollectionCollapsedGroupKeys": {
      "fields": {
        "collapsedGroupKeys": {
          "id": 2,
          "rule": "repeated",
          "type": "string"
        },
        "collectionId": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "LocalVariableCollectionViewStateType": {
      "fields": {
        "currentCollection": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "LocalVariableSet"
        },
        "collections": {
          "id": 1,
          "rule": "repeated",
          "type": "LocalVariableSet"
        }
      },
      "oneofs": {
        "_currentCollection": {
          "oneof": [
            "currentCollection"
          ]
        }
      }
    },
    "LocalVariableCreateCommandParam": {
      "fields": {
        "type": {
          "id": 1,
          "type": "VariableResolvedDataType"
        }
      }
    },
    "LocalVariableData": {
      "fields": {
        "key": {
          "id": 9,
          "type": "string"
        },
        "isLocal": {
          "id": 8,
          "type": "bool"
        },
        "isSoftDeleted": {
          "id": 7,
          "type": "bool"
        },
        "aliasSetId": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "aliasName": {
          "id": 6,
          "type": "string"
        },
        "alias": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "resolvedDataType": {
          "id": 2,
          "type": "VariableResolvedDataType"
        },
        "resolvedValue": {
          "id": 3,
          "type": "VariableAnyValue"
        },
        "dataType": {
          "id": 1,
          "type": "VariableDataType"
        }
      },
      "oneofs": {
        "_aliasSetId": {
          "oneof": [
            "aliasSetId"
          ]
        },
        "_alias": {
          "oneof": [
            "alias"
          ]
        }
      }
    },
    "LocalVariableDragCommandParam": {
      "fields": {
        "position": {
          "id": 2,
          "type": "LocalVariableDragPosition"
        },
        "targetVariableId": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "LocalVariableDragGroupCommandParam": {
      "fields": {
        "position": {
          "id": 2,
          "type": "LocalVariableDragPosition"
        },
        "groupKey": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "LocalVariableEditorEditingState": {
      "fields": {
        "editingVariableData": {
          "id": 5,
          "type": "LocalVariableData"
        },
        "editingModeId": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "editingVariableId": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "position": {
          "id": 2,
          "type": "EditingPopupPosition"
        },
        "type": {
          "id": 1,
          "type": "LocalVariableEditorEditingType"
        }
      },
      "oneofs": {
        "_editingModeId": {
          "oneof": [
            "editingModeId"
          ]
        },
        "_editingVariableId": {
          "oneof": [
            "editingVariableId"
          ]
        }
      }
    },
    "LocalVariableEditorEditingStateInternal": {
      "fields": {
        "editingModeId": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "editingVariableId": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "position": {
          "id": 2,
          "type": "EditingPopupPosition"
        },
        "type": {
          "id": 1,
          "type": "LocalVariableEditorEditingType"
        }
      },
      "oneofs": {
        "_editingModeId": {
          "oneof": [
            "editingModeId"
          ]
        },
        "_editingVariableId": {
          "oneof": [
            "editingVariableId"
          ]
        }
      }
    },
    "LocalVariableEditorRenamingState": {
      "fields": {
        "renamingCollectionId": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "renamingVariableId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "type": {
          "id": 1,
          "type": "LocalVariableEditorRenamingType"
        }
      },
      "oneofs": {
        "_renamingCollectionId": {
          "oneof": [
            "renamingCollectionId"
          ]
        },
        "_renamingVariableId": {
          "oneof": [
            "renamingVariableId"
          ]
        }
      }
    },
    "LocalVariableGroupItem": {
      "fields": {
        "hasSubGroups": {
          "id": 6,
          "type": "bool"
        },
        "selected": {
          "id": 4,
          "type": "bool"
        },
        "indent": {
          "id": 3,
          "type": "int32"
        },
        "name": {
          "id": 2,
          "type": "string"
        },
        "collapsed": {
          "id": 5,
          "type": "bool"
        },
        "key": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "LocalVariableGroupViewStateType": {
      "fields": {
        "groups": {
          "id": 2,
          "rule": "repeated",
          "type": "LocalVariableGroupItem"
        },
        "variablesInCollectionCount": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "LocalVariableRenameCommandParam": {
      "fields": {
        "newName": {
          "id": 2,
          "type": "string"
        },
        "idOrKey": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "LocalVariableSet": {
      "fields": {
        "modes": {
          "id": 3,
          "rule": "repeated",
          "type": "VariableSetMode"
        },
        "name": {
          "id": 2,
          "type": "string"
        },
        "id": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "LocalVariableSetItem": {
      "fields": {
        "isLocal": {
          "id": 7,
          "type": "bool"
        },
        "modeSelectionState": {
          "id": 6,
          "type": "VVariableSetModeSelectionState"
        },
        "variableModes": {
          "id": 5,
          "rule": "repeated",
          "type": "VVariableSetModeState"
        },
        "autoMode": {
          "id": 4,
          "type": "AutoModeInfo"
        },
        "name": {
          "id": 3,
          "type": "string"
        },
        "uniqueKey": {
          "id": 2,
          "type": "string"
        },
        "id": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "LocalVariableTableItem": {
      "fields": {
        "variableSelected": {
          "id": 5,
          "type": "bool"
        },
        "variableName": {
          "id": 4,
          "type": "string"
        },
        "variableData": {
          "id": 3,
          "type": "LocalVariable"
        },
        "groupName": {
          "id": 2,
          "type": "string"
        },
        "type": {
          "id": 1,
          "type": "LocalVariableTableItemType"
        }
      }
    },
    "LocalVariableTableViewStateType": {
      "fields": {
        "items": {
          "id": 2,
          "rule": "repeated",
          "type": "LocalVariableTableItem"
        },
        "SHOULD_NOT_USE_THIS_FIELD": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "LocalVariableToggleDetailCommandParam": {
      "fields": {
        "position": {
          "id": 2,
          "type": "EditingPopupPosition"
        },
        "editingVariableId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_editingVariableId": {
          "oneof": [
            "editingVariableId"
          ]
        }
      }
    },
    "LocalVariableToggleValuePopupCommandParam": {
      "fields": {
        "position": {
          "id": 4,
          "type": "EditingPopupPosition"
        },
        "editingModeId": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "editingVariableId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "type": {
          "id": 1,
          "type": "LocalVariableEditorEditingType"
        }
      },
      "oneofs": {
        "_editingModeId": {
          "oneof": [
            "editingModeId"
          ]
        },
        "_editingVariableId": {
          "oneof": [
            "editingVariableId"
          ]
        }
      }
    },
    "LooperFills": {
      "fields": {
        "end": {
          "id": 3,
          "type": "RGB"
        },
        "start": {
          "id": 2,
          "type": "RGB"
        },
        "apply": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "LooperOpacity": {
      "fields": {
        "end": {
          "id": 3,
          "type": "float"
        },
        "start": {
          "id": 2,
          "type": "float"
        },
        "apply": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "LooperRotate": {
      "fields": {
        "sine": {
          "id": 3,
          "type": "float"
        },
        "degree": {
          "id": 2,
          "type": "float"
        },
        "mode": {
          "id": 1,
          "type": "LooperRotateMode"
        }
      }
    },
    "LooperStrokes": {
      "fields": {
        "startBorderWeight": {
          "id": 5,
          "type": "float"
        },
        "applyBorderWeight": {
          "id": 4,
          "type": "bool"
        },
        "endColor": {
          "id": 3,
          "type": "RGB"
        },
        "startColor": {
          "id": 2,
          "type": "RGB"
        },
        "endBorderWeight": {
          "id": 6,
          "type": "float"
        },
        "applyColor": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "MembershipProto": {
      "fields": {
        "activeUsers": {
          "id": 1,
          "rule": "repeated",
          "type": "ActiveUserProto"
        }
      }
    },
    "MemoryReport": {
      "fields": {
        "stringLookup": {
          "id": 3,
          "rule": "repeated",
          "type": "StringLookup"
        },
        "deallocations": {
          "id": 2,
          "rule": "repeated",
          "type": "uint32"
        },
        "allocations": {
          "id": 1,
          "rule": "repeated",
          "type": "Allocation"
        }
      }
    },
    "MetricRenderDurationItem": {
      "fields": {
        "duration": {
          "id": 2,
          "type": "int32"
        },
        "type": {
          "id": 1,
          "type": "MetricRenderDurationItemType"
        }
      }
    },
    "MirrorFrameViewState": {
      "fields": {
        "frameName": {
          "id": 2,
          "type": "string"
        },
        "frameId": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "MirrorViewportScalingParam": {
      "fields": {
        "scaling": {
          "id": 1,
          "type": "MirrorViewportScaling"
        }
      }
    },
    "MissLibraryComponentOrStyleItem": {
      "fields": {
        "fontMissing": {
          "id": 5,
          "type": "bool"
        },
        "thumbnailData": {
          "id": 3,
          "type": "ThumbnailData"
        },
        "renderKey": {
          "id": 4,
          "type": "string"
        },
        "description": {
          "id": 2,
          "type": "string"
        },
        "name": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "MixConstraintsProp": {
      "fields": {
        "mixed": {
          "id": 2,
          "type": "bool"
        },
        "value": {
          "id": 1,
          "type": "ConstraintType"
        }
      }
    },
    "MixIntProp": {
      "fields": {
        "mixed": {
          "id": 2,
          "type": "bool"
        },
        "values": {
          "id": 4,
          "keyType": "string",
          "type": "int32"
        },
        "disable": {
          "id": 3,
          "type": "bool"
        },
        "value": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "MixProp": {
      "fields": {
        "mixed": {
          "id": 2,
          "type": "bool"
        },
        "values": {
          "id": 4,
          "keyType": "string",
          "type": "float"
        },
        "disable": {
          "id": 3,
          "type": "bool"
        },
        "value": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "MotiffScopeConfig": {
      "fields": {
        "autoShowCommon": {
          "id": 3,
          "type": "bool"
        },
        "followSelection": {
          "id": 2,
          "type": "bool"
        },
        "showMainPanel": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "MotiffScopeExpandationData": {
      "fields": {
        "selectedId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "selectedParentChain": {
          "id": 3,
          "rule": "repeated",
          "type": "MotiffScopeParentData"
        },
        "expandedNodeIds": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      },
      "oneofs": {
        "_selectedId": {
          "oneof": [
            "selectedId"
          ]
        }
      }
    },
    "MotiffScopeParentData": {
      "fields": {
        "name": {
          "id": 2,
          "type": "string"
        },
        "id": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_id": {
          "oneof": [
            "id"
          ]
        }
      }
    },
    "MotiffScopeSettings": {
      "fields": {
        "followSelection": {
          "id": 3,
          "type": "bool"
        },
        "showSearchPanel": {
          "id": 2,
          "type": "bool"
        },
        "showMainPanel": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "MoveGuideToAnotherNodeCommandParam": {
      "fields": {
        "newParentIdRef": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "originalGuideInfo": {
          "id": 1,
          "type": "GuideInfo"
        }
      },
      "oneofs": {
        "_newParentIdRef": {
          "oneof": [
            "newParentIdRef"
          ]
        }
      }
    },
    "MoveSelectedVectorNodeCommandParam": {
      "fields": {
        "deltaY": {
          "id": 2,
          "type": "float"
        },
        "deltaX": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "MoveSelectionCommandParam": {
      "fields": {
        "shiftKey": {
          "id": 2,
          "type": "bool"
        },
        "direction": {
          "id": 1,
          "type": "MoveDirection"
        }
      }
    },
    "MoveViewportCommandParam": {
      "fields": {
        "deltaY": {
          "id": 2,
          "type": "float"
        },
        "deltaX": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "MoveViewportWhenOutsideCanvasCommandParam": {
      "fields": {
        "mousePoint": {
          "id": 2,
          "type": "Vector"
        },
        "canvasBounding": {
          "id": 1,
          "type": "Rect"
        }
      }
    },
    "MultipleRenameParams": {
      "fields": {
        "startNum": {
          "id": 3,
          "type": "int32"
        },
        "rename": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "search": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_rename": {
          "oneof": [
            "rename"
          ]
        },
        "_search": {
          "oneof": [
            "search"
          ]
        }
      }
    },
    "NaturalSortStringItem": {
      "fields": {
        "originalIndex": {
          "id": 2,
          "type": "uint32"
        },
        "value": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "NodeBlobInfo": {
      "fields": {
        "blobs": {
          "id": 2,
          "type": "string"
        },
        "nodes": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "NodeBlobInfoV2": {
      "fields": {
        "blobs": {
          "id": 2,
          "keyType": "string",
          "type": "BridgeProtoStringMap"
        },
        "nodes": {
          "id": 1,
          "keyType": "string",
          "type": "BridgeProtoStringMap"
        }
      }
    },
    "NodeIconData": {
      "fields": {
        "path": {
          "id": 2,
          "type": "string"
        },
        "type": {
          "id": 1,
          "type": "NodeIconType"
        }
      }
    },
    "NodeIdsWrapper": {
      "fields": {
        "value": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "NodeInfo": {
      "fields": {
        "children": {
          "id": 3,
          "rule": "repeated",
          "type": "NodeInfo"
        },
        "parentId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "attrs": {
          "id": 1,
          "type": "PartialNode"
        }
      },
      "oneofs": {
        "_parentId": {
          "oneof": [
            "parentId"
          ]
        }
      }
    },
    "NodeLayerItemRange": {
      "fields": {
        "end": {
          "id": 2,
          "type": "uint64"
        },
        "start": {
          "id": 1,
          "type": "uint64"
        }
      }
    },
    "NodePaintsInfo": {
      "fields": {
        "paints": {
          "id": 2,
          "rule": "repeated",
          "type": "Paint"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "NotificationMovedComponentStyleNodeIds": {
      "fields": {
        "nodeIds": {
          "id": 2,
          "rule": "repeated",
          "type": "string"
        },
        "moveNodeType": {
          "id": 1,
          "type": "NotificationMoveNodeType"
        }
      }
    },
    "OnPreparedUploadTextureItem": {
      "fields": {
        "chunkIndexRight": {
          "id": 3,
          "type": "uint32"
        },
        "chunkIndexLeft": {
          "id": 2,
          "type": "uint32"
        },
        "imageHash": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "OperationVec": {
      "fields": {
        "vec": {
          "id": 1,
          "rule": "repeated",
          "type": "SynergyOperation"
        }
      }
    },
    "OptCoactorMousePosition": {
      "fields": {
        "y": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "x": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        }
      },
      "oneofs": {
        "_y": {
          "oneof": [
            "y"
          ]
        },
        "_x": {
          "oneof": [
            "x"
          ]
        }
      }
    },
    "OverlayBackgroundAppearance": {
      "fields": {
        "backgroundColor": {
          "id": 2,
          "type": "RGBA"
        },
        "overlayBackgroundType": {
          "id": 1,
          "type": "OverlayBackgroundType"
        }
      }
    },
    "OverlaySetting": {
      "fields": {
        "positionType": {
          "id": 2,
          "type": "OverlayPositionType"
        },
        "backgroundAppearance": {
          "id": 4,
          "type": "OverlayBackgroundAppearance"
        },
        "backgroundInteraction": {
          "id": 3,
          "type": "OverlayBackgroundInteraction"
        },
        "isShow": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "PackTypeCommandParam": {
      "fields": {
        "type": {
          "id": 1,
          "type": "PackType"
        }
      }
    },
    "Page": {
      "fields": {
        "clusters": {
          "id": 2,
          "rule": "repeated",
          "type": "Cluster"
        },
        "name": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "PageCancelEditingCommandParam": {
      "fields": {
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "PageEnterEditingCommandParam": {
      "fields": {
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Paint": {
      "fields": {
        "emojiCodePoints": {
          "id": 17,
          "rule": "repeated",
          "type": "int32"
        },
        "scaleMode": {
          "id": 9,
          "options": {
            "proto3_optional": true
          },
          "type": "ScaleMode"
        },
        "filters": {
          "id": 14,
          "options": {
            "proto3_optional": true
          },
          "type": "ImageFilters"
        },
        "imageName": {
          "id": 15,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "imageHash": {
          "id": 10,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "scalingFactor": {
          "id": 12,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "type": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "PaintType"
        },
        "imageTransform": {
          "id": 11,
          "options": {
            "proto3_optional": true
          },
          "type": "Transform"
        },
        "gradientTransform": {
          "id": 7,
          "options": {
            "proto3_optional": true
          },
          "type": "Transform"
        },
        "previewHash": {
          "id": 16,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "visible": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "colorVar": {
          "id": 6,
          "options": {
            "proto3_optional": true
          },
          "type": "VariableAliasData"
        },
        "opacity": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "blendMode": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "BlendMode"
        },
        "rotation": {
          "id": 13,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "gradientStops": {
          "id": 8,
          "rule": "repeated",
          "type": "ColorStop"
        },
        "color": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "RGB"
        }
      },
      "oneofs": {
        "_scaleMode": {
          "oneof": [
            "scaleMode"
          ]
        },
        "_filters": {
          "oneof": [
            "filters"
          ]
        },
        "_imageName": {
          "oneof": [
            "imageName"
          ]
        },
        "_imageHash": {
          "oneof": [
            "imageHash"
          ]
        },
        "_scalingFactor": {
          "oneof": [
            "scalingFactor"
          ]
        },
        "_type": {
          "oneof": [
            "type"
          ]
        },
        "_imageTransform": {
          "oneof": [
            "imageTransform"
          ]
        },
        "_gradientTransform": {
          "oneof": [
            "gradientTransform"
          ]
        },
        "_previewHash": {
          "oneof": [
            "previewHash"
          ]
        },
        "_visible": {
          "oneof": [
            "visible"
          ]
        },
        "_colorVar": {
          "oneof": [
            "colorVar"
          ]
        },
        "_opacity": {
          "oneof": [
            "opacity"
          ]
        },
        "_blendMode": {
          "oneof": [
            "blendMode"
          ]
        },
        "_rotation": {
          "oneof": [
            "rotation"
          ]
        },
        "_color": {
          "oneof": [
            "color"
          ]
        }
      }
    },
    "PaintInfo": {
      "fields": {
        "variable": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "SingleVariable"
        },
        "usingNodeCount": {
          "id": 4,
          "type": "int32"
        },
        "usingInfos": {
          "id": 3,
          "rule": "repeated",
          "type": "PaintUsingInfo"
        },
        "paint": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "Paint"
        },
        "originPaint": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "Paint"
        }
      },
      "oneofs": {
        "_variable": {
          "oneof": [
            "variable"
          ]
        },
        "_paint": {
          "oneof": [
            "paint"
          ]
        },
        "_originPaint": {
          "oneof": [
            "originPaint"
          ]
        }
      }
    },
    "PaintPosition": {
      "fields": {
        "index": {
          "id": 2,
          "type": "int32"
        },
        "type": {
          "id": 1,
          "type": "PaintPositionType"
        }
      }
    },
    "PaintStyleInfo": {
      "fields": {
        "usingNodeCount": {
          "id": 4,
          "type": "int32"
        },
        "usingInfos": {
          "id": 3,
          "rule": "repeated",
          "type": "PaintStyleUsingInfo"
        },
        "style": {
          "id": 2,
          "type": "VPaintStyleNode"
        },
        "originStyleId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_originStyleId": {
          "oneof": [
            "originStyleId"
          ]
        }
      }
    },
    "PaintStyleMatchInfo": {
      "fields": {
        "type": {
          "id": 3,
          "type": "PaintStyleMatchType"
        },
        "matchedStyleNodeId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "group": {
          "id": 1,
          "rule": "repeated",
          "type": "NodePaintsInfo"
        }
      },
      "oneofs": {
        "_matchedStyleNodeId": {
          "oneof": [
            "matchedStyleNodeId"
          ]
        }
      }
    },
    "PaintStyleUsingInfo": {
      "fields": {
        "type": {
          "id": 2,
          "type": "PaintUsingType"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "PaintUsingInfo": {
      "fields": {
        "index": {
          "id": 3,
          "type": "int32"
        },
        "type": {
          "id": 2,
          "type": "PaintUsingType"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Param_CopyWasmMemToWebBlob": {
      "fields": {
        "len": {
          "id": 2,
          "type": "uint32"
        },
        "offset": {
          "id": 1,
          "type": "uint32"
        }
      }
    },
    "Param_SelectedNodes": {
      "fields": {
        "ids": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "ParentInfo": {
      "fields": {
        "orderIndex": {
          "id": 2,
          "type": "bytes"
        },
        "parentId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_parentId": {
          "oneof": [
            "parentId"
          ]
        }
      }
    },
    "PartialNode": {
      "fields": {
        "zoomToolDraggingState": {
          "id": 1019,
          "options": {
            "proto3_optional": true
          },
          "type": "ZoomToolDraggingState"
        },
        "windowInnerHeight": {
          "id": 1052,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "viewport": {
          "id": 2004,
          "options": {
            "proto3_optional": true
          },
          "type": "Viewport"
        },
        "variantProperties": {
          "id": 5016,
          "keyType": "string",
          "type": "string"
        },
        "variableSetId": {
          "id": 12223,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "variableModeInLogicMap": {
          "id": 12235,
          "options": {
            "proto3_optional": true
          },
          "type": "VariableModeInLogicMap"
        },
        "variableCodeSyntax": {
          "id": 12226,
          "rule": "repeated",
          "type": "VariableCodeSyntax"
        },
        "type": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "NodeType"
        },
        "treeInteractBoundsInParent": {
          "id": 12101,
          "options": {
            "proto3_optional": true
          },
          "type": "SkRect"
        },
        "topExtendBoolMenuVisible": {
          "id": 1063,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "thumbnailId": {
          "id": 1057,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "textStyleId": {
          "id": 3010,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "textSettingTabKey": {
          "id": 12217,
          "options": {
            "proto3_optional": true
          },
          "type": "TextSettingTabKey"
        },
        "textLayoutData": {
          "id": 3013,
          "options": {
            "proto3_optional": true
          },
          "type": "TextLayoutData"
        },
        "textDecoration": {
          "id": 9004,
          "options": {
            "proto3_optional": true
          },
          "type": "TextDecoration"
        },
        "textCase": {
          "id": 9005,
          "options": {
            "proto3_optional": true
          },
          "type": "TextCase"
        },
        "textAlignVertical": {
          "id": 3003,
          "options": {
            "proto3_optional": true
          },
          "type": "TextAlignVertical"
        },
        "textAlignHorizontal": {
          "id": 3002,
          "options": {
            "proto3_optional": true
          },
          "type": "TextAlignHorizontal"
        },
        "symbolData": {
          "id": 8008,
          "options": {
            "proto3_optional": true
          },
          "type": "SymbolData"
        },
        "styleOverrideTable": {
          "id": 4005,
          "rule": "repeated",
          "type": "StyleOverride"
        },
        "strokeStyleId": {
          "id": 402,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "titleInputHandle": {
          "id": 2021,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "strokeGeometry": {
          "id": 1106,
          "options": {
            "proto3_optional": true
          },
          "type": "GeometryVector"
        },
        "strokeAlign": {
          "id": 405,
          "options": {
            "proto3_optional": true
          },
          "type": "StrokeAlign"
        },
        "startCap": {
          "id": 410,
          "options": {
            "proto3_optional": true
          },
          "type": "StrokeCap"
        },
        "stackSpacing": {
          "id": 5007,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "stackMode": {
          "id": 5013,
          "options": {
            "proto3_optional": true
          },
          "type": "StackMode"
        },
        "stackHorizontalPadding": {
          "id": 5004,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "stackCounterSpacing": {
          "id": 5023,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "strokeJoin": {
          "id": 404,
          "options": {
            "proto3_optional": true
          },
          "type": "StrokeJoin"
        },
        "stackCounterAlignContent": {
          "id": 5024,
          "options": {
            "proto3_optional": true
          },
          "type": "StackCounterAlignContent"
        },
        "stackChildPrimaryGrow": {
          "id": 206,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "smallNudgeAmount": {
          "id": 11601,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "sizeOverride": {
          "id": 8007,
          "options": {
            "proto3_optional": true
          },
          "type": "SizeOverride"
        },
        "sizeHoverMenuItem": {
          "id": 2037,
          "options": {
            "proto3_optional": true
          },
          "type": "SizeHoverMenuItem"
        },
        "simplifyInstanceRightPanelManualModify": {
          "id": 12221,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "simplifiedInstanceIdsSeeingAllLayers": {
          "id": 12222,
          "rule": "repeated",
          "type": "string"
        },
        "sidebarPanels": {
          "id": 1065,
          "rule": "repeated",
          "type": "SidebarPanel"
        },
        "snapToPixel": {
          "id": 1027,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "showSlice": {
          "id": 1060,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "showRuler": {
          "id": 1039,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "showMultipleRenameModal": {
          "id": 2019,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "selectionPaint": {
          "id": 10004,
          "options": {
            "proto3_optional": true
          },
          "type": "VSelectionPaintState"
        },
        "selectedGuideInfo": {
          "id": 12237,
          "options": {
            "proto3_optional": true
          },
          "type": "GuideInfo"
        },
        "variableDataValues": {
          "id": 12225,
          "rule": "repeated",
          "type": "VariableDataValue"
        },
        "showSidebar": {
          "id": 1043,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "selectedGuideIndex": {
          "id": 2013,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "selectLayoutGridState": {
          "id": 2027,
          "options": {
            "proto3_optional": true
          },
          "type": "VSelectionLayoutGridState"
        },
        "scrollOffset": {
          "id": 5021,
          "options": {
            "proto3_optional": true
          },
          "type": "Vector"
        },
        "shouldActiveVariantPropsInput": {
          "id": 1041,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "scrollDirection": {
          "id": 5008,
          "options": {
            "proto3_optional": true
          },
          "type": "ScrollDirection"
        },
        "scaleFactor": {
          "id": 8004,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "rotationInDragging": {
          "id": 1022,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "rightClickState": {
          "id": 1029,
          "options": {
            "proto3_optional": true
          },
          "type": "RightClickState"
        },
        "simplifyInstancePanels": {
          "id": 12220,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "rightClickSource": {
          "id": 1066,
          "options": {
            "proto3_optional": true
          },
          "type": "RightClickSource"
        },
        "stackWrap": {
          "id": 5022,
          "options": {
            "proto3_optional": true
          },
          "type": "StackWrap"
        },
        "rightClickMenuState": {
          "id": 2030,
          "options": {
            "proto3_optional": true
          },
          "type": "RightClickMenuState"
        },
        "remoteLibraryChangesChangeId": {
          "id": 11101,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "relativeTransform": {
          "id": 204,
          "options": {
            "proto3_optional": true
          },
          "type": "Transform"
        },
        "readOnlySelectedTextRange": {
          "id": 2036,
          "options": {
            "proto3_optional": true
          },
          "type": "TextRange"
        },
        "selectedGradientColorStopIndex": {
          "id": 1038,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "rawVectorUseSkeletonAsOutline": {
          "id": 4004,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "showShortcutPanel": {
          "id": 1077,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "quickSwitchToHandTool": {
          "id": 1016,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "publishHidden": {
          "id": 8006,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "prototypeStartingPointEditingState": {
          "id": 1108,
          "options": {
            "proto3_optional": true
          },
          "type": "PrototypeStartingPointEditingState"
        },
        "prototypePresentShowProtoSidebar": {
          "id": 12213,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "prototypePresentShowHotspotHint": {
          "id": 1118,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "prototypePresentShowDevice": {
          "id": 1117,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "variableConsumptionMap": {
          "id": 12230,
          "rule": "repeated",
          "type": "VariableConsumptionMap"
        },
        "stackPrimarySizing": {
          "id": 5011,
          "options": {
            "proto3_optional": true
          },
          "type": "StackSize"
        },
        "prototypePresentDeviceFrameRegion": {
          "id": 1115,
          "options": {
            "proto3_optional": true
          },
          "type": "PrototypePresentDeviceFrameRegion"
        },
        "prototypeOverlaySettingDragingState": {
          "id": 1116,
          "options": {
            "proto3_optional": true
          },
          "type": "PrototypeOverlaySettingDragingState"
        },
        "stackReverseZIndex": {
          "id": 5006,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "prototypeInteractions": {
          "id": 103,
          "rule": "repeated",
          "type": "PrototypeInteraction"
        },
        "prototypeDevice": {
          "id": 2034,
          "options": {
            "proto3_optional": true
          },
          "type": "PrototypeDevice"
        },
        "prototypeAnimation": {
          "id": 1096,
          "options": {
            "proto3_optional": true
          },
          "type": "PrototypeAnimation"
        },
        "strokeMiterAngle": {
          "id": 407,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "propsAreBubbled": {
          "id": 12219,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "previewDocumentLoadedState": {
          "id": 1110,
          "options": {
            "proto3_optional": true
          },
          "type": "PreviewDocumentLoadedState"
        },
        "pluginData": {
          "id": 11500,
          "options": {
            "proto3_optional": true
          },
          "type": "PluginDataStore"
        },
        "pendingMirrorState": {
          "id": 12218,
          "options": {
            "proto3_optional": true
          },
          "type": "PendingMirrorState"
        },
        "strokes": {
          "id": 401,
          "rule": "repeated",
          "type": "Paint"
        },
        "scaleToolPanel": {
          "id": 2015,
          "options": {
            "proto3_optional": true
          },
          "type": "ScaleToolPanel"
        },
        "paymentUserPlanStatus": {
          "id": 1098,
          "options": {
            "proto3_optional": true
          },
          "type": "PaymentUserPlanStatus"
        },
        "parentInfo": {
          "id": 7,
          "options": {
            "proto3_optional": true
          },
          "type": "ParentInfo"
        },
        "paragraphSpacing": {
          "id": 3006,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "paints": {
          "id": 9001,
          "rule": "repeated",
          "type": "Paint"
        },
        "overrideId": {
          "id": 12,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "selectedReverseIndex": {
          "id": 2009,
          "options": {
            "proto3_optional": true
          },
          "type": "SelectedReverseIndex"
        },
        "overlayPositionType": {
          "id": 5025,
          "options": {
            "proto3_optional": true
          },
          "type": "OverlayPositionType"
        },
        "overlayBackgroundInteraction": {
          "id": 5026,
          "options": {
            "proto3_optional": true
          },
          "type": "OverlayBackgroundInteraction"
        },
        "overlayBackgroundAppearance": {
          "id": 5027,
          "options": {
            "proto3_optional": true
          },
          "type": "OverlayBackgroundAppearance"
        },
        "operateSystem": {
          "id": 1075,
          "options": {
            "proto3_optional": true
          },
          "type": "OperateSystemType"
        },
        "openingDetailLibraryIdInfo": {
          "id": 11109,
          "options": {
            "proto3_optional": true
          },
          "type": "LibraryIdInfo"
        },
        "opacity": {
          "id": 440,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "numberOfFixedChildren": {
          "id": 5017,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "nodesWithPrototypeInteractions": {
          "id": 1120,
          "rule": "repeated",
          "type": "string"
        },
        "nodePreviewTrack": {
          "id": 2028,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "textSearch": {
          "id": 12003,
          "options": {
            "proto3_optional": true
          },
          "type": "TextSearch"
        },
        "publishFile": {
          "id": 510,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "needLayoutOnce": {
          "id": 8012,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "mouseWorldPosition": {
          "id": 1014,
          "options": {
            "proto3_optional": true
          },
          "type": "Vector"
        },
        "mouseRightClickWorldPosition": {
          "id": 1054,
          "options": {
            "proto3_optional": true
          },
          "type": "Vector"
        },
        "prototypeInteractionShadowRecorder": {
          "id": 12212,
          "options": {
            "proto3_optional": true
          },
          "type": "PrototypeInteractionShadowRecorder"
        },
        "mouseInDragging": {
          "id": 1015,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "prototypeBackgroundColor": {
          "id": 2033,
          "rule": "repeated",
          "type": "Paint"
        },
        "mouseDownWorldPosition": {
          "id": 1006,
          "options": {
            "proto3_optional": true
          },
          "type": "Vector"
        },
        "isDocImportedFromFigma": {
          "id": 8016,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "mirrorViewportScaling": {
          "id": 12234,
          "options": {
            "proto3_optional": true
          },
          "type": "MirrorViewportScaling"
        },
        "mirrorScreenScrollY": {
          "id": 1071,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "parent": {
          "id": 13,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "maskOutline": {
          "id": 443,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "aiPoweredReason": {
          "id": 12001,
          "options": {
            "proto3_optional": true
          },
          "type": "AIPoweredReason"
        },
        "mainMenuVisible": {
          "id": 1061,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "locked": {
          "id": 102,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "lineHeight": {
          "id": 9006,
          "options": {
            "proto3_optional": true
          },
          "type": "LineHeight"
        },
        "libraryReplaceModalStateChangeId": {
          "id": 11108,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "libraryMoveHistory": {
          "id": 11112,
          "rule": "repeated",
          "type": "LibraryMoveHistoryItem"
        },
        "subscribedLibraryIds": {
          "id": 11104,
          "rule": "repeated",
          "type": "string"
        },
        "styleId": {
          "id": 3012,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "stackPaddingBottom": {
          "id": 5003,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "isViewportAnimating": {
          "id": 2031,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "maxSize": {
          "id": 210,
          "options": {
            "proto3_optional": true
          },
          "type": "Vector"
        },
        "libraryContentVOChangeId": {
          "id": 11100,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "showMultiplayerCursor": {
          "id": 1045,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "isImageAsset": {
          "id": 106,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "id": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "isDocImportedFromAI": {
          "id": 1085,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "prototypePresentHotspotHintAnimateState": {
          "id": 1119,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "isAIRecognizeDocument": {
          "id": 12005,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "inverseZoomDirection": {
          "id": 11600,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "stackPaddingRight": {
          "id": 5005,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "inspectCodeType": {
          "id": 11107,
          "options": {
            "proto3_optional": true
          },
          "type": "CodeType"
        },
        "canvasSearchResult": {
          "id": 1084,
          "options": {
            "proto3_optional": true
          },
          "type": "CanvasSearchResult"
        },
        "insertNodeState": {
          "id": 1004,
          "options": {
            "proto3_optional": true
          },
          "type": "InsertNodeState"
        },
        "prototypeAnimationValue": {
          "id": 1097,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "innerRadius": {
          "id": 1101,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "libraryMoveInfo": {
          "id": 11111,
          "options": {
            "proto3_optional": true
          },
          "type": "LibraryMoveInfo"
        },
        "infiniteSelectionPaint": {
          "id": 10006,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "prototypeStartingPoint": {
          "id": 5019,
          "options": {
            "proto3_optional": true
          },
          "type": "PrototypeStartingPoint"
        },
        "mask": {
          "id": 442,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "index": {
          "id": 16,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "drawingComment": {
          "id": 11009,
          "options": {
            "proto3_optional": true
          },
          "type": "DrawingCommentProps"
        },
        "independentCorners": {
          "id": 434,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "topRightRadius": {
          "id": 431,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "devModeInspectType": {
          "id": 11115,
          "options": {
            "proto3_optional": true
          },
          "type": "DevModeInspectType"
        },
        "imageStates": {
          "id": 1025,
          "options": {
            "proto3_optional": true
          },
          "type": "ImageStates"
        },
        "imageLoadedSignal": {
          "id": 1091,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "hyperlink": {
          "id": 9009,
          "options": {
            "proto3_optional": true
          },
          "type": "Hyperlink"
        },
        "paragraphIndent": {
          "id": 3005,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "ownerPage": {
          "id": 15,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "libraryUpgradedCount": {
          "id": 1078,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "fontVariations": {
          "id": 9008,
          "rule": "repeated",
          "type": "FontVariation"
        },
        "hoveredHyperlink": {
          "id": 2022,
          "options": {
            "proto3_optional": true
          },
          "type": "HoveredHyperlink"
        },
        "selection": {
          "id": 2001,
          "rule": "repeated",
          "type": "string"
        },
        "derivedSymbolData": {
          "id": 8013,
          "options": {
            "proto3_optional": true
          },
          "type": "DerivedSymbolData"
        },
        "devModeDisplayPanels": {
          "id": 12201,
          "rule": "repeated",
          "type": "DisplayPanel"
        },
        "hoveredGuideInfo": {
          "id": 12236,
          "options": {
            "proto3_optional": true
          },
          "type": "GuideInfo"
        },
        "hoveredGuideIndex": {
          "id": 1092,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "hoveredElId": {
          "id": 2007,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "enablePixelGrid": {
          "id": 1028,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "hoveredCandidateIconNode": {
          "id": 12006,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "hoverPopupHyperlink": {
          "id": 2025,
          "options": {
            "proto3_optional": true
          },
          "type": "HoveredHyperlink"
        },
        "historyMode": {
          "id": 2026,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "height": {
          "id": 202,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "showComment": {
          "id": 1044,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "hasBeenOpened": {
          "id": 8014,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "coactorMouseMap": {
          "id": 11401,
          "options": {
            "proto3_optional": true
          },
          "type": "CoactorMouseMap"
        },
        "handToolDraggingState": {
          "id": 1023,
          "options": {
            "proto3_optional": true
          },
          "type": "HandToolDraggingState"
        },
        "guides": {
          "id": 2011,
          "rule": "repeated",
          "type": "Guide"
        },
        "textTruncation": {
          "id": 3015,
          "options": {
            "proto3_optional": true
          },
          "type": "TextTruncation"
        },
        "libraryMainModalRoutePath": {
          "id": 11106,
          "options": {
            "proto3_optional": true
          },
          "type": "LibraryMainModalRoutePathType"
        },
        "variantPropKey2OrderedValuesMap": {
          "id": 8100,
          "options": {
            "proto3_optional": true
          },
          "type": "VariantPropKey2OrderedValuesMap"
        },
        "forceDisplayAllInteractions": {
          "id": 1113,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "fullscreen": {
          "id": 1040,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "commentMetaPosition": {
          "id": 11001,
          "options": {
            "proto3_optional": true
          },
          "type": "CommentMetaPosition"
        },
        "fromSketch": {
          "id": 8011,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "fromFig": {
          "id": 8009,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "textData": {
          "id": 3011,
          "options": {
            "proto3_optional": true
          },
          "type": "TextData"
        },
        "libraryUpgradeStateChangeId": {
          "id": 11105,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "selectedTextRange": {
          "id": 2003,
          "options": {
            "proto3_optional": true
          },
          "type": "TextRange"
        },
        "hoveredInteractionKeys": {
          "id": 2040,
          "rule": "repeated",
          "type": "PrototypeInteractionKey"
        },
        "handleMirroring": {
          "id": 4002,
          "options": {
            "proto3_optional": true
          },
          "type": "VectorHandleMirror"
        },
        "forceShowGuildLineForDevMode": {
          "id": 12205,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "motiffScopeExpandationData": {
          "id": 12233,
          "options": {
            "proto3_optional": true
          },
          "type": "MotiffScopeExpandationData"
        },
        "prototypeInteractionAddDragingState": {
          "id": 1112,
          "options": {
            "proto3_optional": true
          },
          "type": "PrototypeInteractionAddDragingState"
        },
        "fontName": {
          "id": 9003,
          "options": {
            "proto3_optional": true
          },
          "type": "FontName"
        },
        "fillStyleId": {
          "id": 302,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "fillGeometry": {
          "id": 1105,
          "options": {
            "proto3_optional": true
          },
          "type": "GeometryVector"
        },
        "minSize": {
          "id": 209,
          "options": {
            "proto3_optional": true
          },
          "type": "Vector"
        },
        "childrenIds": {
          "id": 8,
          "rule": "repeated",
          "type": "string"
        },
        "fallbackFontDownloadedSignal": {
          "id": 1121,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "expandBoundsRotationCollisionRange": {
          "id": 1067,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "enterLayerPanelArea": {
          "id": 1056,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "endingAngle": {
          "id": 1103,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "endCap": {
          "id": 411,
          "options": {
            "proto3_optional": true
          },
          "type": "StrokeCap"
        },
        "localStyleEditingState": {
          "id": 11300,
          "options": {
            "proto3_optional": true
          },
          "type": "LocalStyleEditingState"
        },
        "hoveredNodeId": {
          "id": 2005,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "enableLayoutGrid": {
          "id": 1050,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "effects": {
          "id": 444,
          "rule": "repeated",
          "type": "Effect"
        },
        "multipleRenameParams": {
          "id": 2020,
          "options": {
            "proto3_optional": true
          },
          "type": "MultipleRenameParams"
        },
        "effectStyleId": {
          "id": 445,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "editingVectorState": {
          "id": 1031,
          "options": {
            "proto3_optional": true
          },
          "type": "EditingVectorState"
        },
        "editingVectorPenState": {
          "id": 1032,
          "options": {
            "proto3_optional": true
          },
          "type": "EditingVectorPenState"
        },
        "startingAngle": {
          "id": 1102,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "stackCounterSizing": {
          "id": 5012,
          "options": {
            "proto3_optional": true
          },
          "type": "StackSize"
        },
        "layerPanelScrollTop": {
          "id": 1053,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "lastMouseEventPositionForDevMode": {
          "id": 12102,
          "options": {
            "proto3_optional": true
          },
          "type": "Vector"
        },
        "movingComment": {
          "id": 11012,
          "options": {
            "proto3_optional": true
          },
          "type": "CommentMoveState"
        },
        "draftComment": {
          "id": 11010,
          "options": {
            "proto3_optional": true
          },
          "type": "DraftCommentProps"
        },
        "isCommonAsset": {
          "id": 105,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "documentColorProfileMeta": {
          "id": 1083,
          "options": {
            "proto3_optional": true
          },
          "type": "DocumentColorProfileMeta"
        },
        "copiedFromId": {
          "id": 6,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "editingPaint": {
          "id": 1013,
          "options": {
            "proto3_optional": true
          },
          "type": "EditingPaint"
        },
        "stackCounterAlignItems": {
          "id": 5010,
          "options": {
            "proto3_optional": true
          },
          "type": "StackAlign"
        },
        "prototypePresentDisableDefaultKeyboardNav": {
          "id": 12215,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "expanded": {
          "id": 11,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "rawComputedStyledTextSegments": {
          "id": 3014,
          "rule": "repeated",
          "type": "StyledTextSegment"
        },
        "prototypePresentHideUI": {
          "id": 12214,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "editingNameState": {
          "id": 1051,
          "options": {
            "proto3_optional": true
          },
          "type": "EditingNameState"
        },
        "clipsContent": {
          "id": 5001,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "editedProps": {
          "id": 1104,
          "rule": "repeated",
          "type": "NodeProps"
        },
        "fontSize": {
          "id": 9002,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "memoryUsage": {
          "id": 1079,
          "options": {
            "proto3_optional": true
          },
          "type": "VMemoryUsage"
        },
        "aiCandidateComponentData": {
          "id": 12004,
          "options": {
            "proto3_optional": true
          },
          "type": "AICandidateComponentData"
        },
        "width": {
          "id": 201,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "domRegionPath": {
          "id": 2039,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "textLayoutsStable": {
          "id": 12238,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "aiLayoutEntryMenuState": {
          "id": 12211,
          "options": {
            "proto3_optional": true
          },
          "type": "AiLayoutEntryMenuState"
        },
        "displayPanels": {
          "id": 1059,
          "rule": "repeated",
          "type": "DisplayPanel"
        },
        "publishId": {
          "id": 511,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "devModeInspectCodeType": {
          "id": 11116,
          "options": {
            "proto3_optional": true
          },
          "type": "DevModeInspectCodeType"
        },
        "dragMoveMode": {
          "id": 1030,
          "options": {
            "proto3_optional": true
          },
          "type": "DragMoveMode"
        },
        "stackChildAlignSelf": {
          "id": 207,
          "options": {
            "proto3_optional": true
          },
          "type": "StackCounterAlign"
        },
        "componentPropRefs": {
          "id": 12208,
          "rule": "repeated",
          "type": "ComponentPropRef"
        },
        "booleanOperation": {
          "id": 6001,
          "options": {
            "proto3_optional": true
          },
          "type": "BooleanOperation"
        },
        "hyperlinkEditingMode": {
          "id": 2023,
          "options": {
            "proto3_optional": true
          },
          "type": "HyperlinkEditingMode"
        },
        "devModeHoveredNode": {
          "id": 12206,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "showHoverPopupHyperlink": {
          "id": 2024,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "aiPoweredNodeId": {
          "id": 12000,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "rawLibraryThumbnailTargetNodeId": {
          "id": 512,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "name": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "canvasSearchResultTextContentMatchSelection": {
          "id": 1086,
          "options": {
            "proto3_optional": true
          },
          "type": "CanvasSearchResultTextContentMatchSelection"
        },
        "scrollBehavior": {
          "id": 104,
          "options": {
            "proto3_optional": true
          },
          "type": "ScrollBehavior"
        },
        "devModeAvailability": {
          "id": 12216,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "blendMode": {
          "id": 441,
          "options": {
            "proto3_optional": true
          },
          "type": "BlendMode"
        },
        "detachOpticalSizeFromFontSize": {
          "id": 9010,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "strokeWeight": {
          "id": 403,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "coactorMembershipMap": {
          "id": 11400,
          "options": {
            "proto3_optional": true
          },
          "type": "VMembershipMap"
        },
        "editInfo": {
          "id": 12204,
          "options": {
            "proto3_optional": true
          },
          "type": "EditInfo"
        },
        "multiHoverBorderColor": {
          "id": 2042,
          "options": {
            "proto3_optional": true
          },
          "type": "MultiHoverBorderColorType"
        },
        "autoLayoutInvisibleNodes": {
          "id": 1090,
          "rule": "repeated",
          "type": "string"
        },
        "bigNudgeAmount": {
          "id": 11602,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "canvasSearchResultLayerItemForScroll": {
          "id": 1089,
          "options": {
            "proto3_optional": true
          },
          "type": "CanvasSearchLayerItem"
        },
        "debouncedMovingBoundsPositionHold": {
          "id": 1021,
          "options": {
            "proto3_optional": true
          },
          "type": "DebouncedMovingBoundsPositionHold"
        },
        "devModeInspectCodeConfig": {
          "id": 11118,
          "options": {
            "proto3_optional": true
          },
          "type": "DevModeInspectCodeConfig"
        },
        "description": {
          "id": 8001,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "testNotStyleSquares": {
          "id": 2029,
          "rule": "repeated",
          "type": "string"
        },
        "placingImageState": {
          "id": 1024,
          "options": {
            "proto3_optional": true
          },
          "type": "PlacingImageState"
        },
        "dashPattern": {
          "id": 406,
          "rule": "repeated",
          "type": "float"
        },
        "selectedInteractionKeys": {
          "id": 2038,
          "rule": "repeated",
          "type": "PrototypeInteractionKey"
        },
        "currentPageId": {
          "id": 1001,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "contentHash": {
          "id": 8005,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "vectorData": {
          "id": 4003,
          "options": {
            "proto3_optional": true
          },
          "type": "Blob"
        },
        "commentRenderPage": {
          "id": 11015,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "cornerSmoothing": {
          "id": 421,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "stackVerticalPadding": {
          "id": 5002,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "controlFontLoadChangeId": {
          "id": 12231,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "dragWithSpace": {
          "id": 1058,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "libraryPublishModalStateChangeId": {
          "id": 11102,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "devModeLayerPanelHoveredNodeId": {
          "id": 2035,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "topLeftRadius": {
          "id": 430,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "componentPropDefs": {
          "id": 12207,
          "rule": "repeated",
          "type": "ComponentPropDef"
        },
        "children": {
          "id": 14,
          "rule": "repeated",
          "type": "string"
        },
        "pointCount": {
          "id": 1100,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "attrPanelStyleEditorState": {
          "id": 1042,
          "options": {
            "proto3_optional": true
          },
          "type": "AttrPanelStyleEditorState"
        },
        "borderRightWeight": {
          "id": 10101,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "editingTextNodeState": {
          "id": 1007,
          "options": {
            "proto3_optional": true
          },
          "type": "EditingTextNodeState"
        },
        "imageName": {
          "id": 7001,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "commentCreatedTime": {
          "id": 11000,
          "options": {
            "proto3_optional": true
          },
          "type": "int64"
        },
        "subEditorMode": {
          "id": 1088,
          "options": {
            "proto3_optional": true
          },
          "type": "SubEditorMode"
        },
        "currentViewingFrameId": {
          "id": 2016,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "colorPickState": {
          "id": 10002,
          "options": {
            "proto3_optional": true
          },
          "type": "ColorPickState"
        },
        "coactorViewportMap": {
          "id": 11403,
          "options": {
            "proto3_optional": true
          },
          "type": "CoactorViewportMap"
        },
        "cornerRadius": {
          "id": 420,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "borderBottomWeight": {
          "id": 10102,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "variableResolvedType": {
          "id": 12224,
          "options": {
            "proto3_optional": true
          },
          "type": "VariableResolvedDataType"
        },
        "motiffScopeSettings": {
          "id": 12232,
          "options": {
            "proto3_optional": true
          },
          "type": "MotiffScopeSettings"
        },
        "layoutGrids": {
          "id": 5018,
          "rule": "repeated",
          "type": "LayoutGrid"
        },
        "quickSwitchToZoomTool": {
          "id": 1018,
          "options": {
            "proto3_optional": true
          },
          "type": "ZoomToolState"
        },
        "canvasSearchResultSelection": {
          "id": 1082,
          "options": {
            "proto3_optional": true
          },
          "type": "CanvasSearchResultSelection"
        },
        "componentThumbnailDragging": {
          "id": 11119,
          "options": {
            "proto3_optional": true
          },
          "type": "ComponentThumbnailDragging"
        },
        "targetFrameId": {
          "id": 2010,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "canvasSearchParam": {
          "id": 1080,
          "options": {
            "proto3_optional": true
          },
          "type": "CanvasSearchParam"
        },
        "ancestorPathBeforeDeletion": {
          "id": 8010,
          "rule": "repeated",
          "type": "string"
        },
        "docReadonly": {
          "id": 1055,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "bottomRightRadius": {
          "id": 433,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "mirrorFrameViewState": {
          "id": 1093,
          "options": {
            "proto3_optional": true
          },
          "type": "MirrorFrameViewState"
        },
        "docId": {
          "id": 1046,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "key": {
          "id": 8002,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "documentColorProfile": {
          "id": 1081,
          "options": {
            "proto3_optional": true
          },
          "type": "DocumentColorProfile"
        },
        "prototypePresentScaling": {
          "id": 1114,
          "options": {
            "proto3_optional": true
          },
          "type": "PrototypePresentScaling"
        },
        "imageSearchProgress": {
          "id": 11113,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "coactorObservedMap": {
          "id": 11404,
          "options": {
            "proto3_optional": true
          },
          "type": "CoactorObservedMap"
        },
        "bottomLeftRadius": {
          "id": 432,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "previewDocumentNodeState": {
          "id": 1111,
          "options": {
            "proto3_optional": true
          },
          "type": "PreviewDocumentNodeState"
        },
        "editorType": {
          "id": 12200,
          "options": {
            "proto3_optional": true
          },
          "type": "EditorType"
        },
        "borderTopWeight": {
          "id": 10100,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "syncStatus": {
          "id": 1047,
          "options": {
            "proto3_optional": true
          },
          "type": "SyncStatus"
        },
        "interactBounds": {
          "id": 12100,
          "options": {
            "proto3_optional": true
          },
          "type": "SkRect"
        },
        "componentPropAssignments": {
          "id": 12209,
          "rule": "repeated",
          "type": "ComponentPropAssignment"
        },
        "borderStrokeWeightsIndependent": {
          "id": 409,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "variableSetModes": {
          "id": 12228,
          "rule": "repeated",
          "type": "VariableSetMode"
        },
        "mainComponentId": {
          "id": 8003,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "inspectState": {
          "id": 10003,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "activedCommentId": {
          "id": 11005,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "constraints": {
          "id": 205,
          "options": {
            "proto3_optional": true
          },
          "type": "Constraints"
        },
        "notificationMovedComponentStyleNodeIds": {
          "id": 11114,
          "options": {
            "proto3_optional": true
          },
          "type": "NotificationMovedComponentStyleNodeIds"
        },
        "gridStyleId": {
          "id": 5014,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "devicePixelRatio": {
          "id": 12002,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "popupState": {
          "id": 1017,
          "options": {
            "proto3_optional": true
          },
          "type": "PopupState"
        },
        "maxLines": {
          "id": 3016,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "autoLayoutHoverMenuItem": {
          "id": 2017,
          "options": {
            "proto3_optional": true
          },
          "type": "AutoLayoutHoverMenuItem"
        },
        "lastCreatedNodeId": {
          "id": 2008,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "hoveredMultiNodeIds": {
          "id": 2041,
          "rule": "repeated",
          "type": "string"
        },
        "commentHeadCount": {
          "id": 11014,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "canvasRightClickMenuVisible": {
          "id": 1062,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "allowIconAutoDetection": {
          "id": 1107,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "textAutoResize": {
          "id": 3004,
          "options": {
            "proto3_optional": true
          },
          "type": "TextAutoResize"
        },
        "allFonts": {
          "id": 1076,
          "keyType": "string",
          "type": "string"
        },
        "backgrounds": {
          "id": 2002,
          "rule": "repeated",
          "type": "Paint"
        },
        "constrainProportions": {
          "id": 203,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "canvasSearchResultViewport": {
          "id": 1087,
          "options": {
            "proto3_optional": true
          },
          "type": "CanvasSearchResultViewport"
        },
        "prototypingVisibility": {
          "id": 12210,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "autoLayoutSpaceInput": {
          "id": 2018,
          "options": {
            "proto3_optional": true
          },
          "type": "FloatSpaceInputType"
        },
        "variableModeBySetMap": {
          "id": 12229,
          "rule": "repeated",
          "type": "VariableModeBySetMap"
        },
        "editorMode": {
          "id": 1002,
          "options": {
            "proto3_optional": true
          },
          "type": "EditorMode"
        },
        "visible": {
          "id": 101,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "selectionRect": {
          "id": 2006,
          "options": {
            "proto3_optional": true
          },
          "type": "Rect"
        },
        "exportSettings": {
          "id": 501,
          "rule": "repeated",
          "type": "ExportSettings"
        },
        "variableScopes": {
          "id": 12227,
          "rule": "repeated",
          "type": "VariableScope"
        },
        "commentVisible": {
          "id": 11016,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "mouseDown": {
          "id": 1035,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "replaceTargetLibraryIdInfo": {
          "id": 11110,
          "options": {
            "proto3_optional": true
          },
          "type": "LibraryIdInfo"
        },
        "prototypeStartingPointDragingState": {
          "id": 1109,
          "options": {
            "proto3_optional": true
          },
          "type": "PrototypeStartingPointDragingState"
        },
        "commentPageId": {
          "id": 11003,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "availableFonts": {
          "id": 1020,
          "options": {
            "proto3_optional": true
          },
          "type": "AvailableFonts"
        },
        "stackPositioning": {
          "id": 208,
          "options": {
            "proto3_optional": true
          },
          "type": "StackPositioning"
        },
        "coactorSelectionsMap": {
          "id": 11402,
          "options": {
            "proto3_optional": true
          },
          "type": "VCoactorSelectionsMap"
        },
        "devModeExportCompress": {
          "id": 11120,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "commentClusterResult": {
          "id": 11013,
          "options": {
            "proto3_optional": true
          },
          "type": "CommentClusterResult"
        },
        "autoRename": {
          "id": 3007,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "letterSpacing": {
          "id": 9007,
          "options": {
            "proto3_optional": true
          },
          "type": "LetterSpacing"
        },
        "borderLeftWeight": {
          "id": 10103,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "devModeInspectExportConfig": {
          "id": 11117,
          "options": {
            "proto3_optional": true
          },
          "type": "DevModeInspectExportConfig"
        },
        "syncProgress": {
          "id": 1049,
          "options": {
            "proto3_optional": true
          },
          "type": "UploadProgress"
        },
        "fills": {
          "id": 301,
          "rule": "repeated",
          "type": "Paint"
        },
        "mirrorNodeId": {
          "id": 1070,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "lastEditedStrokeCap": {
          "id": 400,
          "options": {
            "proto3_optional": true
          },
          "type": "StrokeCap"
        },
        "activatedPluginType": {
          "id": 1073,
          "options": {
            "proto3_optional": true
          },
          "type": "PluginType"
        },
        "dashCap": {
          "id": 408,
          "options": {
            "proto3_optional": true
          },
          "type": "StrokeCap"
        },
        "libraryAssetPanelStateChangeId": {
          "id": 11103,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "hoveredCommentId": {
          "id": 11004,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "stackPrimaryAlignItems": {
          "id": 5009,
          "options": {
            "proto3_optional": true
          },
          "type": "StackJustify"
        },
        "isDocImportedFromSketch": {
          "id": 8015,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "freezeSelectionPaint": {
          "id": 10005,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        }
      },
      "oneofs": {
        "_zoomToolDraggingState": {
          "oneof": [
            "zoomToolDraggingState"
          ]
        },
        "_windowInnerHeight": {
          "oneof": [
            "windowInnerHeight"
          ]
        },
        "_viewport": {
          "oneof": [
            "viewport"
          ]
        },
        "_variableSetId": {
          "oneof": [
            "variableSetId"
          ]
        },
        "_variableModeInLogicMap": {
          "oneof": [
            "variableModeInLogicMap"
          ]
        },
        "_type": {
          "oneof": [
            "type"
          ]
        },
        "_treeInteractBoundsInParent": {
          "oneof": [
            "treeInteractBoundsInParent"
          ]
        },
        "_topExtendBoolMenuVisible": {
          "oneof": [
            "topExtendBoolMenuVisible"
          ]
        },
        "_thumbnailId": {
          "oneof": [
            "thumbnailId"
          ]
        },
        "_textStyleId": {
          "oneof": [
            "textStyleId"
          ]
        },
        "_textSettingTabKey": {
          "oneof": [
            "textSettingTabKey"
          ]
        },
        "_textLayoutData": {
          "oneof": [
            "textLayoutData"
          ]
        },
        "_textDecoration": {
          "oneof": [
            "textDecoration"
          ]
        },
        "_textCase": {
          "oneof": [
            "textCase"
          ]
        },
        "_textAlignVertical": {
          "oneof": [
            "textAlignVertical"
          ]
        },
        "_textAlignHorizontal": {
          "oneof": [
            "textAlignHorizontal"
          ]
        },
        "_symbolData": {
          "oneof": [
            "symbolData"
          ]
        },
        "_strokeStyleId": {
          "oneof": [
            "strokeStyleId"
          ]
        },
        "_titleInputHandle": {
          "oneof": [
            "titleInputHandle"
          ]
        },
        "_strokeGeometry": {
          "oneof": [
            "strokeGeometry"
          ]
        },
        "_strokeAlign": {
          "oneof": [
            "strokeAlign"
          ]
        },
        "_startCap": {
          "oneof": [
            "startCap"
          ]
        },
        "_stackSpacing": {
          "oneof": [
            "stackSpacing"
          ]
        },
        "_stackMode": {
          "oneof": [
            "stackMode"
          ]
        },
        "_stackHorizontalPadding": {
          "oneof": [
            "stackHorizontalPadding"
          ]
        },
        "_stackCounterSpacing": {
          "oneof": [
            "stackCounterSpacing"
          ]
        },
        "_strokeJoin": {
          "oneof": [
            "strokeJoin"
          ]
        },
        "_stackCounterAlignContent": {
          "oneof": [
            "stackCounterAlignContent"
          ]
        },
        "_stackChildPrimaryGrow": {
          "oneof": [
            "stackChildPrimaryGrow"
          ]
        },
        "_smallNudgeAmount": {
          "oneof": [
            "smallNudgeAmount"
          ]
        },
        "_sizeOverride": {
          "oneof": [
            "sizeOverride"
          ]
        },
        "_sizeHoverMenuItem": {
          "oneof": [
            "sizeHoverMenuItem"
          ]
        },
        "_simplifyInstanceRightPanelManualModify": {
          "oneof": [
            "simplifyInstanceRightPanelManualModify"
          ]
        },
        "_snapToPixel": {
          "oneof": [
            "snapToPixel"
          ]
        },
        "_showSlice": {
          "oneof": [
            "showSlice"
          ]
        },
        "_showRuler": {
          "oneof": [
            "showRuler"
          ]
        },
        "_showMultipleRenameModal": {
          "oneof": [
            "showMultipleRenameModal"
          ]
        },
        "_selectionPaint": {
          "oneof": [
            "selectionPaint"
          ]
        },
        "_selectedGuideInfo": {
          "oneof": [
            "selectedGuideInfo"
          ]
        },
        "_showSidebar": {
          "oneof": [
            "showSidebar"
          ]
        },
        "_selectedGuideIndex": {
          "oneof": [
            "selectedGuideIndex"
          ]
        },
        "_selectLayoutGridState": {
          "oneof": [
            "selectLayoutGridState"
          ]
        },
        "_scrollOffset": {
          "oneof": [
            "scrollOffset"
          ]
        },
        "_shouldActiveVariantPropsInput": {
          "oneof": [
            "shouldActiveVariantPropsInput"
          ]
        },
        "_scrollDirection": {
          "oneof": [
            "scrollDirection"
          ]
        },
        "_scaleFactor": {
          "oneof": [
            "scaleFactor"
          ]
        },
        "_rotationInDragging": {
          "oneof": [
            "rotationInDragging"
          ]
        },
        "_rightClickState": {
          "oneof": [
            "rightClickState"
          ]
        },
        "_simplifyInstancePanels": {
          "oneof": [
            "simplifyInstancePanels"
          ]
        },
        "_rightClickSource": {
          "oneof": [
            "rightClickSource"
          ]
        },
        "_stackWrap": {
          "oneof": [
            "stackWrap"
          ]
        },
        "_rightClickMenuState": {
          "oneof": [
            "rightClickMenuState"
          ]
        },
        "_remoteLibraryChangesChangeId": {
          "oneof": [
            "remoteLibraryChangesChangeId"
          ]
        },
        "_relativeTransform": {
          "oneof": [
            "relativeTransform"
          ]
        },
        "_readOnlySelectedTextRange": {
          "oneof": [
            "readOnlySelectedTextRange"
          ]
        },
        "_selectedGradientColorStopIndex": {
          "oneof": [
            "selectedGradientColorStopIndex"
          ]
        },
        "_rawVectorUseSkeletonAsOutline": {
          "oneof": [
            "rawVectorUseSkeletonAsOutline"
          ]
        },
        "_showShortcutPanel": {
          "oneof": [
            "showShortcutPanel"
          ]
        },
        "_quickSwitchToHandTool": {
          "oneof": [
            "quickSwitchToHandTool"
          ]
        },
        "_publishHidden": {
          "oneof": [
            "publishHidden"
          ]
        },
        "_prototypeStartingPointEditingState": {
          "oneof": [
            "prototypeStartingPointEditingState"
          ]
        },
        "_prototypePresentShowProtoSidebar": {
          "oneof": [
            "prototypePresentShowProtoSidebar"
          ]
        },
        "_prototypePresentShowHotspotHint": {
          "oneof": [
            "prototypePresentShowHotspotHint"
          ]
        },
        "_prototypePresentShowDevice": {
          "oneof": [
            "prototypePresentShowDevice"
          ]
        },
        "_stackPrimarySizing": {
          "oneof": [
            "stackPrimarySizing"
          ]
        },
        "_prototypePresentDeviceFrameRegion": {
          "oneof": [
            "prototypePresentDeviceFrameRegion"
          ]
        },
        "_prototypeOverlaySettingDragingState": {
          "oneof": [
            "prototypeOverlaySettingDragingState"
          ]
        },
        "_stackReverseZIndex": {
          "oneof": [
            "stackReverseZIndex"
          ]
        },
        "_prototypeDevice": {
          "oneof": [
            "prototypeDevice"
          ]
        },
        "_prototypeAnimation": {
          "oneof": [
            "prototypeAnimation"
          ]
        },
        "_strokeMiterAngle": {
          "oneof": [
            "strokeMiterAngle"
          ]
        },
        "_propsAreBubbled": {
          "oneof": [
            "propsAreBubbled"
          ]
        },
        "_previewDocumentLoadedState": {
          "oneof": [
            "previewDocumentLoadedState"
          ]
        },
        "_pluginData": {
          "oneof": [
            "pluginData"
          ]
        },
        "_pendingMirrorState": {
          "oneof": [
            "pendingMirrorState"
          ]
        },
        "_scaleToolPanel": {
          "oneof": [
            "scaleToolPanel"
          ]
        },
        "_paymentUserPlanStatus": {
          "oneof": [
            "paymentUserPlanStatus"
          ]
        },
        "_parentInfo": {
          "oneof": [
            "parentInfo"
          ]
        },
        "_paragraphSpacing": {
          "oneof": [
            "paragraphSpacing"
          ]
        },
        "_overrideId": {
          "oneof": [
            "overrideId"
          ]
        },
        "_selectedReverseIndex": {
          "oneof": [
            "selectedReverseIndex"
          ]
        },
        "_overlayPositionType": {
          "oneof": [
            "overlayPositionType"
          ]
        },
        "_overlayBackgroundInteraction": {
          "oneof": [
            "overlayBackgroundInteraction"
          ]
        },
        "_overlayBackgroundAppearance": {
          "oneof": [
            "overlayBackgroundAppearance"
          ]
        },
        "_operateSystem": {
          "oneof": [
            "operateSystem"
          ]
        },
        "_openingDetailLibraryIdInfo": {
          "oneof": [
            "openingDetailLibraryIdInfo"
          ]
        },
        "_opacity": {
          "oneof": [
            "opacity"
          ]
        },
        "_numberOfFixedChildren": {
          "oneof": [
            "numberOfFixedChildren"
          ]
        },
        "_nodePreviewTrack": {
          "oneof": [
            "nodePreviewTrack"
          ]
        },
        "_textSearch": {
          "oneof": [
            "textSearch"
          ]
        },
        "_publishFile": {
          "oneof": [
            "publishFile"
          ]
        },
        "_needLayoutOnce": {
          "oneof": [
            "needLayoutOnce"
          ]
        },
        "_mouseWorldPosition": {
          "oneof": [
            "mouseWorldPosition"
          ]
        },
        "_mouseRightClickWorldPosition": {
          "oneof": [
            "mouseRightClickWorldPosition"
          ]
        },
        "_prototypeInteractionShadowRecorder": {
          "oneof": [
            "prototypeInteractionShadowRecorder"
          ]
        },
        "_mouseInDragging": {
          "oneof": [
            "mouseInDragging"
          ]
        },
        "_mouseDownWorldPosition": {
          "oneof": [
            "mouseDownWorldPosition"
          ]
        },
        "_isDocImportedFromFigma": {
          "oneof": [
            "isDocImportedFromFigma"
          ]
        },
        "_mirrorViewportScaling": {
          "oneof": [
            "mirrorViewportScaling"
          ]
        },
        "_mirrorScreenScrollY": {
          "oneof": [
            "mirrorScreenScrollY"
          ]
        },
        "_parent": {
          "oneof": [
            "parent"
          ]
        },
        "_maskOutline": {
          "oneof": [
            "maskOutline"
          ]
        },
        "_aiPoweredReason": {
          "oneof": [
            "aiPoweredReason"
          ]
        },
        "_mainMenuVisible": {
          "oneof": [
            "mainMenuVisible"
          ]
        },
        "_locked": {
          "oneof": [
            "locked"
          ]
        },
        "_lineHeight": {
          "oneof": [
            "lineHeight"
          ]
        },
        "_libraryReplaceModalStateChangeId": {
          "oneof": [
            "libraryReplaceModalStateChangeId"
          ]
        },
        "_styleId": {
          "oneof": [
            "styleId"
          ]
        },
        "_stackPaddingBottom": {
          "oneof": [
            "stackPaddingBottom"
          ]
        },
        "_isViewportAnimating": {
          "oneof": [
            "isViewportAnimating"
          ]
        },
        "_maxSize": {
          "oneof": [
            "maxSize"
          ]
        },
        "_libraryContentVOChangeId": {
          "oneof": [
            "libraryContentVOChangeId"
          ]
        },
        "_showMultiplayerCursor": {
          "oneof": [
            "showMultiplayerCursor"
          ]
        },
        "_isImageAsset": {
          "oneof": [
            "isImageAsset"
          ]
        },
        "_id": {
          "oneof": [
            "id"
          ]
        },
        "_isDocImportedFromAI": {
          "oneof": [
            "isDocImportedFromAI"
          ]
        },
        "_prototypePresentHotspotHintAnimateState": {
          "oneof": [
            "prototypePresentHotspotHintAnimateState"
          ]
        },
        "_isAIRecognizeDocument": {
          "oneof": [
            "isAIRecognizeDocument"
          ]
        },
        "_inverseZoomDirection": {
          "oneof": [
            "inverseZoomDirection"
          ]
        },
        "_stackPaddingRight": {
          "oneof": [
            "stackPaddingRight"
          ]
        },
        "_inspectCodeType": {
          "oneof": [
            "inspectCodeType"
          ]
        },
        "_canvasSearchResult": {
          "oneof": [
            "canvasSearchResult"
          ]
        },
        "_insertNodeState": {
          "oneof": [
            "insertNodeState"
          ]
        },
        "_prototypeAnimationValue": {
          "oneof": [
            "prototypeAnimationValue"
          ]
        },
        "_innerRadius": {
          "oneof": [
            "innerRadius"
          ]
        },
        "_libraryMoveInfo": {
          "oneof": [
            "libraryMoveInfo"
          ]
        },
        "_infiniteSelectionPaint": {
          "oneof": [
            "infiniteSelectionPaint"
          ]
        },
        "_prototypeStartingPoint": {
          "oneof": [
            "prototypeStartingPoint"
          ]
        },
        "_mask": {
          "oneof": [
            "mask"
          ]
        },
        "_index": {
          "oneof": [
            "index"
          ]
        },
        "_drawingComment": {
          "oneof": [
            "drawingComment"
          ]
        },
        "_independentCorners": {
          "oneof": [
            "independentCorners"
          ]
        },
        "_topRightRadius": {
          "oneof": [
            "topRightRadius"
          ]
        },
        "_devModeInspectType": {
          "oneof": [
            "devModeInspectType"
          ]
        },
        "_imageStates": {
          "oneof": [
            "imageStates"
          ]
        },
        "_imageLoadedSignal": {
          "oneof": [
            "imageLoadedSignal"
          ]
        },
        "_hyperlink": {
          "oneof": [
            "hyperlink"
          ]
        },
        "_paragraphIndent": {
          "oneof": [
            "paragraphIndent"
          ]
        },
        "_ownerPage": {
          "oneof": [
            "ownerPage"
          ]
        },
        "_libraryUpgradedCount": {
          "oneof": [
            "libraryUpgradedCount"
          ]
        },
        "_hoveredHyperlink": {
          "oneof": [
            "hoveredHyperlink"
          ]
        },
        "_derivedSymbolData": {
          "oneof": [
            "derivedSymbolData"
          ]
        },
        "_hoveredGuideInfo": {
          "oneof": [
            "hoveredGuideInfo"
          ]
        },
        "_hoveredGuideIndex": {
          "oneof": [
            "hoveredGuideIndex"
          ]
        },
        "_hoveredElId": {
          "oneof": [
            "hoveredElId"
          ]
        },
        "_enablePixelGrid": {
          "oneof": [
            "enablePixelGrid"
          ]
        },
        "_hoveredCandidateIconNode": {
          "oneof": [
            "hoveredCandidateIconNode"
          ]
        },
        "_hoverPopupHyperlink": {
          "oneof": [
            "hoverPopupHyperlink"
          ]
        },
        "_historyMode": {
          "oneof": [
            "historyMode"
          ]
        },
        "_height": {
          "oneof": [
            "height"
          ]
        },
        "_showComment": {
          "oneof": [
            "showComment"
          ]
        },
        "_hasBeenOpened": {
          "oneof": [
            "hasBeenOpened"
          ]
        },
        "_coactorMouseMap": {
          "oneof": [
            "coactorMouseMap"
          ]
        },
        "_handToolDraggingState": {
          "oneof": [
            "handToolDraggingState"
          ]
        },
        "_textTruncation": {
          "oneof": [
            "textTruncation"
          ]
        },
        "_libraryMainModalRoutePath": {
          "oneof": [
            "libraryMainModalRoutePath"
          ]
        },
        "_variantPropKey2OrderedValuesMap": {
          "oneof": [
            "variantPropKey2OrderedValuesMap"
          ]
        },
        "_forceDisplayAllInteractions": {
          "oneof": [
            "forceDisplayAllInteractions"
          ]
        },
        "_fullscreen": {
          "oneof": [
            "fullscreen"
          ]
        },
        "_commentMetaPosition": {
          "oneof": [
            "commentMetaPosition"
          ]
        },
        "_fromSketch": {
          "oneof": [
            "fromSketch"
          ]
        },
        "_fromFig": {
          "oneof": [
            "fromFig"
          ]
        },
        "_textData": {
          "oneof": [
            "textData"
          ]
        },
        "_libraryUpgradeStateChangeId": {
          "oneof": [
            "libraryUpgradeStateChangeId"
          ]
        },
        "_selectedTextRange": {
          "oneof": [
            "selectedTextRange"
          ]
        },
        "_handleMirroring": {
          "oneof": [
            "handleMirroring"
          ]
        },
        "_forceShowGuildLineForDevMode": {
          "oneof": [
            "forceShowGuildLineForDevMode"
          ]
        },
        "_motiffScopeExpandationData": {
          "oneof": [
            "motiffScopeExpandationData"
          ]
        },
        "_prototypeInteractionAddDragingState": {
          "oneof": [
            "prototypeInteractionAddDragingState"
          ]
        },
        "_fontName": {
          "oneof": [
            "fontName"
          ]
        },
        "_fillStyleId": {
          "oneof": [
            "fillStyleId"
          ]
        },
        "_fillGeometry": {
          "oneof": [
            "fillGeometry"
          ]
        },
        "_minSize": {
          "oneof": [
            "minSize"
          ]
        },
        "_fallbackFontDownloadedSignal": {
          "oneof": [
            "fallbackFontDownloadedSignal"
          ]
        },
        "_expandBoundsRotationCollisionRange": {
          "oneof": [
            "expandBoundsRotationCollisionRange"
          ]
        },
        "_enterLayerPanelArea": {
          "oneof": [
            "enterLayerPanelArea"
          ]
        },
        "_endingAngle": {
          "oneof": [
            "endingAngle"
          ]
        },
        "_endCap": {
          "oneof": [
            "endCap"
          ]
        },
        "_localStyleEditingState": {
          "oneof": [
            "localStyleEditingState"
          ]
        },
        "_hoveredNodeId": {
          "oneof": [
            "hoveredNodeId"
          ]
        },
        "_enableLayoutGrid": {
          "oneof": [
            "enableLayoutGrid"
          ]
        },
        "_multipleRenameParams": {
          "oneof": [
            "multipleRenameParams"
          ]
        },
        "_effectStyleId": {
          "oneof": [
            "effectStyleId"
          ]
        },
        "_editingVectorState": {
          "oneof": [
            "editingVectorState"
          ]
        },
        "_editingVectorPenState": {
          "oneof": [
            "editingVectorPenState"
          ]
        },
        "_startingAngle": {
          "oneof": [
            "startingAngle"
          ]
        },
        "_stackCounterSizing": {
          "oneof": [
            "stackCounterSizing"
          ]
        },
        "_layerPanelScrollTop": {
          "oneof": [
            "layerPanelScrollTop"
          ]
        },
        "_lastMouseEventPositionForDevMode": {
          "oneof": [
            "lastMouseEventPositionForDevMode"
          ]
        },
        "_movingComment": {
          "oneof": [
            "movingComment"
          ]
        },
        "_draftComment": {
          "oneof": [
            "draftComment"
          ]
        },
        "_isCommonAsset": {
          "oneof": [
            "isCommonAsset"
          ]
        },
        "_documentColorProfileMeta": {
          "oneof": [
            "documentColorProfileMeta"
          ]
        },
        "_copiedFromId": {
          "oneof": [
            "copiedFromId"
          ]
        },
        "_editingPaint": {
          "oneof": [
            "editingPaint"
          ]
        },
        "_stackCounterAlignItems": {
          "oneof": [
            "stackCounterAlignItems"
          ]
        },
        "_prototypePresentDisableDefaultKeyboardNav": {
          "oneof": [
            "prototypePresentDisableDefaultKeyboardNav"
          ]
        },
        "_expanded": {
          "oneof": [
            "expanded"
          ]
        },
        "_prototypePresentHideUI": {
          "oneof": [
            "prototypePresentHideUI"
          ]
        },
        "_editingNameState": {
          "oneof": [
            "editingNameState"
          ]
        },
        "_clipsContent": {
          "oneof": [
            "clipsContent"
          ]
        },
        "_fontSize": {
          "oneof": [
            "fontSize"
          ]
        },
        "_memoryUsage": {
          "oneof": [
            "memoryUsage"
          ]
        },
        "_aiCandidateComponentData": {
          "oneof": [
            "aiCandidateComponentData"
          ]
        },
        "_width": {
          "oneof": [
            "width"
          ]
        },
        "_domRegionPath": {
          "oneof": [
            "domRegionPath"
          ]
        },
        "_textLayoutsStable": {
          "oneof": [
            "textLayoutsStable"
          ]
        },
        "_aiLayoutEntryMenuState": {
          "oneof": [
            "aiLayoutEntryMenuState"
          ]
        },
        "_publishId": {
          "oneof": [
            "publishId"
          ]
        },
        "_devModeInspectCodeType": {
          "oneof": [
            "devModeInspectCodeType"
          ]
        },
        "_dragMoveMode": {
          "oneof": [
            "dragMoveMode"
          ]
        },
        "_stackChildAlignSelf": {
          "oneof": [
            "stackChildAlignSelf"
          ]
        },
        "_booleanOperation": {
          "oneof": [
            "booleanOperation"
          ]
        },
        "_hyperlinkEditingMode": {
          "oneof": [
            "hyperlinkEditingMode"
          ]
        },
        "_devModeHoveredNode": {
          "oneof": [
            "devModeHoveredNode"
          ]
        },
        "_showHoverPopupHyperlink": {
          "oneof": [
            "showHoverPopupHyperlink"
          ]
        },
        "_aiPoweredNodeId": {
          "oneof": [
            "aiPoweredNodeId"
          ]
        },
        "_rawLibraryThumbnailTargetNodeId": {
          "oneof": [
            "rawLibraryThumbnailTargetNodeId"
          ]
        },
        "_name": {
          "oneof": [
            "name"
          ]
        },
        "_canvasSearchResultTextContentMatchSelection": {
          "oneof": [
            "canvasSearchResultTextContentMatchSelection"
          ]
        },
        "_scrollBehavior": {
          "oneof": [
            "scrollBehavior"
          ]
        },
        "_devModeAvailability": {
          "oneof": [
            "devModeAvailability"
          ]
        },
        "_blendMode": {
          "oneof": [
            "blendMode"
          ]
        },
        "_detachOpticalSizeFromFontSize": {
          "oneof": [
            "detachOpticalSizeFromFontSize"
          ]
        },
        "_strokeWeight": {
          "oneof": [
            "strokeWeight"
          ]
        },
        "_coactorMembershipMap": {
          "oneof": [
            "coactorMembershipMap"
          ]
        },
        "_editInfo": {
          "oneof": [
            "editInfo"
          ]
        },
        "_multiHoverBorderColor": {
          "oneof": [
            "multiHoverBorderColor"
          ]
        },
        "_bigNudgeAmount": {
          "oneof": [
            "bigNudgeAmount"
          ]
        },
        "_canvasSearchResultLayerItemForScroll": {
          "oneof": [
            "canvasSearchResultLayerItemForScroll"
          ]
        },
        "_debouncedMovingBoundsPositionHold": {
          "oneof": [
            "debouncedMovingBoundsPositionHold"
          ]
        },
        "_devModeInspectCodeConfig": {
          "oneof": [
            "devModeInspectCodeConfig"
          ]
        },
        "_description": {
          "oneof": [
            "description"
          ]
        },
        "_placingImageState": {
          "oneof": [
            "placingImageState"
          ]
        },
        "_currentPageId": {
          "oneof": [
            "currentPageId"
          ]
        },
        "_contentHash": {
          "oneof": [
            "contentHash"
          ]
        },
        "_vectorData": {
          "oneof": [
            "vectorData"
          ]
        },
        "_commentRenderPage": {
          "oneof": [
            "commentRenderPage"
          ]
        },
        "_cornerSmoothing": {
          "oneof": [
            "cornerSmoothing"
          ]
        },
        "_stackVerticalPadding": {
          "oneof": [
            "stackVerticalPadding"
          ]
        },
        "_controlFontLoadChangeId": {
          "oneof": [
            "controlFontLoadChangeId"
          ]
        },
        "_dragWithSpace": {
          "oneof": [
            "dragWithSpace"
          ]
        },
        "_libraryPublishModalStateChangeId": {
          "oneof": [
            "libraryPublishModalStateChangeId"
          ]
        },
        "_devModeLayerPanelHoveredNodeId": {
          "oneof": [
            "devModeLayerPanelHoveredNodeId"
          ]
        },
        "_topLeftRadius": {
          "oneof": [
            "topLeftRadius"
          ]
        },
        "_pointCount": {
          "oneof": [
            "pointCount"
          ]
        },
        "_attrPanelStyleEditorState": {
          "oneof": [
            "attrPanelStyleEditorState"
          ]
        },
        "_borderRightWeight": {
          "oneof": [
            "borderRightWeight"
          ]
        },
        "_editingTextNodeState": {
          "oneof": [
            "editingTextNodeState"
          ]
        },
        "_imageName": {
          "oneof": [
            "imageName"
          ]
        },
        "_commentCreatedTime": {
          "oneof": [
            "commentCreatedTime"
          ]
        },
        "_subEditorMode": {
          "oneof": [
            "subEditorMode"
          ]
        },
        "_currentViewingFrameId": {
          "oneof": [
            "currentViewingFrameId"
          ]
        },
        "_colorPickState": {
          "oneof": [
            "colorPickState"
          ]
        },
        "_coactorViewportMap": {
          "oneof": [
            "coactorViewportMap"
          ]
        },
        "_cornerRadius": {
          "oneof": [
            "cornerRadius"
          ]
        },
        "_borderBottomWeight": {
          "oneof": [
            "borderBottomWeight"
          ]
        },
        "_variableResolvedType": {
          "oneof": [
            "variableResolvedType"
          ]
        },
        "_motiffScopeSettings": {
          "oneof": [
            "motiffScopeSettings"
          ]
        },
        "_quickSwitchToZoomTool": {
          "oneof": [
            "quickSwitchToZoomTool"
          ]
        },
        "_canvasSearchResultSelection": {
          "oneof": [
            "canvasSearchResultSelection"
          ]
        },
        "_componentThumbnailDragging": {
          "oneof": [
            "componentThumbnailDragging"
          ]
        },
        "_targetFrameId": {
          "oneof": [
            "targetFrameId"
          ]
        },
        "_canvasSearchParam": {
          "oneof": [
            "canvasSearchParam"
          ]
        },
        "_docReadonly": {
          "oneof": [
            "docReadonly"
          ]
        },
        "_bottomRightRadius": {
          "oneof": [
            "bottomRightRadius"
          ]
        },
        "_mirrorFrameViewState": {
          "oneof": [
            "mirrorFrameViewState"
          ]
        },
        "_docId": {
          "oneof": [
            "docId"
          ]
        },
        "_key": {
          "oneof": [
            "key"
          ]
        },
        "_documentColorProfile": {
          "oneof": [
            "documentColorProfile"
          ]
        },
        "_prototypePresentScaling": {
          "oneof": [
            "prototypePresentScaling"
          ]
        },
        "_imageSearchProgress": {
          "oneof": [
            "imageSearchProgress"
          ]
        },
        "_coactorObservedMap": {
          "oneof": [
            "coactorObservedMap"
          ]
        },
        "_bottomLeftRadius": {
          "oneof": [
            "bottomLeftRadius"
          ]
        },
        "_previewDocumentNodeState": {
          "oneof": [
            "previewDocumentNodeState"
          ]
        },
        "_editorType": {
          "oneof": [
            "editorType"
          ]
        },
        "_borderTopWeight": {
          "oneof": [
            "borderTopWeight"
          ]
        },
        "_syncStatus": {
          "oneof": [
            "syncStatus"
          ]
        },
        "_interactBounds": {
          "oneof": [
            "interactBounds"
          ]
        },
        "_borderStrokeWeightsIndependent": {
          "oneof": [
            "borderStrokeWeightsIndependent"
          ]
        },
        "_mainComponentId": {
          "oneof": [
            "mainComponentId"
          ]
        },
        "_inspectState": {
          "oneof": [
            "inspectState"
          ]
        },
        "_activedCommentId": {
          "oneof": [
            "activedCommentId"
          ]
        },
        "_constraints": {
          "oneof": [
            "constraints"
          ]
        },
        "_notificationMovedComponentStyleNodeIds": {
          "oneof": [
            "notificationMovedComponentStyleNodeIds"
          ]
        },
        "_gridStyleId": {
          "oneof": [
            "gridStyleId"
          ]
        },
        "_devicePixelRatio": {
          "oneof": [
            "devicePixelRatio"
          ]
        },
        "_popupState": {
          "oneof": [
            "popupState"
          ]
        },
        "_maxLines": {
          "oneof": [
            "maxLines"
          ]
        },
        "_autoLayoutHoverMenuItem": {
          "oneof": [
            "autoLayoutHoverMenuItem"
          ]
        },
        "_lastCreatedNodeId": {
          "oneof": [
            "lastCreatedNodeId"
          ]
        },
        "_commentHeadCount": {
          "oneof": [
            "commentHeadCount"
          ]
        },
        "_canvasRightClickMenuVisible": {
          "oneof": [
            "canvasRightClickMenuVisible"
          ]
        },
        "_allowIconAutoDetection": {
          "oneof": [
            "allowIconAutoDetection"
          ]
        },
        "_textAutoResize": {
          "oneof": [
            "textAutoResize"
          ]
        },
        "_constrainProportions": {
          "oneof": [
            "constrainProportions"
          ]
        },
        "_canvasSearchResultViewport": {
          "oneof": [
            "canvasSearchResultViewport"
          ]
        },
        "_prototypingVisibility": {
          "oneof": [
            "prototypingVisibility"
          ]
        },
        "_autoLayoutSpaceInput": {
          "oneof": [
            "autoLayoutSpaceInput"
          ]
        },
        "_editorMode": {
          "oneof": [
            "editorMode"
          ]
        },
        "_visible": {
          "oneof": [
            "visible"
          ]
        },
        "_selectionRect": {
          "oneof": [
            "selectionRect"
          ]
        },
        "_commentVisible": {
          "oneof": [
            "commentVisible"
          ]
        },
        "_mouseDown": {
          "oneof": [
            "mouseDown"
          ]
        },
        "_replaceTargetLibraryIdInfo": {
          "oneof": [
            "replaceTargetLibraryIdInfo"
          ]
        },
        "_prototypeStartingPointDragingState": {
          "oneof": [
            "prototypeStartingPointDragingState"
          ]
        },
        "_commentPageId": {
          "oneof": [
            "commentPageId"
          ]
        },
        "_availableFonts": {
          "oneof": [
            "availableFonts"
          ]
        },
        "_stackPositioning": {
          "oneof": [
            "stackPositioning"
          ]
        },
        "_coactorSelectionsMap": {
          "oneof": [
            "coactorSelectionsMap"
          ]
        },
        "_devModeExportCompress": {
          "oneof": [
            "devModeExportCompress"
          ]
        },
        "_commentClusterResult": {
          "oneof": [
            "commentClusterResult"
          ]
        },
        "_autoRename": {
          "oneof": [
            "autoRename"
          ]
        },
        "_letterSpacing": {
          "oneof": [
            "letterSpacing"
          ]
        },
        "_borderLeftWeight": {
          "oneof": [
            "borderLeftWeight"
          ]
        },
        "_devModeInspectExportConfig": {
          "oneof": [
            "devModeInspectExportConfig"
          ]
        },
        "_syncProgress": {
          "oneof": [
            "syncProgress"
          ]
        },
        "_mirrorNodeId": {
          "oneof": [
            "mirrorNodeId"
          ]
        },
        "_lastEditedStrokeCap": {
          "oneof": [
            "lastEditedStrokeCap"
          ]
        },
        "_activatedPluginType": {
          "oneof": [
            "activatedPluginType"
          ]
        },
        "_dashCap": {
          "oneof": [
            "dashCap"
          ]
        },
        "_libraryAssetPanelStateChangeId": {
          "oneof": [
            "libraryAssetPanelStateChangeId"
          ]
        },
        "_hoveredCommentId": {
          "oneof": [
            "hoveredCommentId"
          ]
        },
        "_stackPrimaryAlignItems": {
          "oneof": [
            "stackPrimaryAlignItems"
          ]
        },
        "_isDocImportedFromSketch": {
          "oneof": [
            "isDocImportedFromSketch"
          ]
        },
        "_freezeSelectionPaint": {
          "oneof": [
            "freezeSelectionPaint"
          ]
        }
      }
    },
    "PathTreeHolder": {
      "fields": {
        "value": {
          "id": 1,
          "type": "bytes"
        }
      }
    },
    "PathTreeStruct": {
      "fields": {
        "tree": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "PaymentUserPlanStatus": {
      "fields": {
        "devModeAvailable": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "PendingMirrorState": {
      "fields": {
        "needResetOverlayAndBackHistory": {
          "id": 2,
          "type": "bool"
        },
        "targetNodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_targetNodeId": {
          "oneof": [
            "targetNodeId"
          ]
        }
      }
    },
    "PlaceImageParam": {
      "fields": {
        "imageInfos": {
          "id": 1,
          "rule": "repeated",
          "type": "ImageInfo"
        }
      }
    },
    "PlacingImageInfo": {
      "fields": {
        "svgBlob": {
          "id": 6,
          "type": "bytes"
        },
        "yDPI": {
          "id": 5,
          "type": "int32"
        },
        "xDPI": {
          "id": 4,
          "type": "int32"
        },
        "imageHash": {
          "id": 2,
          "type": "string"
        },
        "previewHash": {
          "id": 3,
          "type": "string"
        },
        "imageName": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "PlacingImageState": {
      "fields": {
        "imageInfos": {
          "id": 1,
          "rule": "repeated",
          "type": "PlacingImageInfo"
        }
      }
    },
    "PluginArcTextState": {
      "fields": {
        "shouldSync": {
          "id": 7,
          "type": "bool"
        },
        "enableGenerateBtn": {
          "id": 6,
          "type": "bool"
        },
        "reversePath": {
          "id": 4,
          "type": "bool"
        },
        "followPath": {
          "id": 3,
          "type": "bool"
        },
        "isEmpty": {
          "id": 5,
          "type": "bool"
        },
        "angle": {
          "id": 2,
          "type": "int32"
        },
        "radius": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "PluginData": {
      "fields": {
        "data": {
          "id": 2,
          "keyType": "string",
          "type": "string"
        },
        "id": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "PluginDataFilter": {
      "fields": {
        "keys": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "PluginDataStore": {
      "fields": {
        "store": {
          "id": 1,
          "keyType": "string",
          "type": "PluginData"
        }
      }
    },
    "PluginFillRuleEditorState": {
      "fields": {
        "isEmpty": {
          "id": 4,
          "type": "bool"
        },
        "vectorNetwork": {
          "id": 3,
          "type": "VectorNetwork"
        },
        "height": {
          "id": 2,
          "type": "float"
        },
        "width": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "PluginLooperData": {
      "fields": {
        "strokes": {
          "id": 9,
          "type": "LooperStrokes"
        },
        "opacity": {
          "id": 7,
          "type": "LooperOpacity"
        },
        "rotate": {
          "id": 6,
          "type": "LooperRotate"
        },
        "repeatTime": {
          "id": 1,
          "type": "int32"
        },
        "height": {
          "id": 5,
          "type": "float"
        },
        "fills": {
          "id": 8,
          "type": "LooperFills"
        },
        "width": {
          "id": 4,
          "type": "float"
        },
        "translateY": {
          "id": 3,
          "type": "float"
        },
        "translateX": {
          "id": 2,
          "type": "float"
        }
      }
    },
    "PluginRotateCopyData": {
      "fields": {
        "copyCount": {
          "id": 3,
          "type": "int32"
        },
        "totalRotateAngle": {
          "id": 2,
          "type": "float"
        },
        "radius": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "PluginRotateCopyViewState": {
      "fields": {
        "baseNodeName": {
          "id": 4,
          "type": "string"
        },
        "inPreviewStage": {
          "id": 3,
          "type": "bool"
        },
        "radius": {
          "id": 2,
          "type": "float"
        },
        "validNodeSelected": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "PluginViewState": {
      "fields": {
        "enableContextMenuPlugin": {
          "id": 2,
          "type": "bool"
        },
        "enableLastPlugin": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "PopupPosition": {
      "fields": {
        "y": {
          "id": 2,
          "type": "float"
        },
        "x": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "PopupState": {
      "fields": {
        "multiPopup": {
          "id": 3,
          "rule": "repeated",
          "type": "PopupState"
        },
        "interactionPopupPosition": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "PopupPosition"
        },
        "reciprocalIndex": {
          "id": 2,
          "type": "int32"
        },
        "type": {
          "id": 1,
          "type": "PopupStateType"
        }
      },
      "oneofs": {
        "_interactionPopupPosition": {
          "oneof": [
            "interactionPopupPosition"
          ]
        }
      }
    },
    "PrepareUploadTextureItem": {
      "fields": {
        "chunkIndexRight": {
          "id": 3,
          "type": "uint32"
        },
        "format": {
          "id": 4,
          "type": "SerTcFormat"
        },
        "chunkIndexLeft": {
          "id": 2,
          "type": "uint32"
        },
        "imageHash": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "PresetCodeTypeToFileType": {
      "fields": {
        "androidConfig": {
          "id": 4,
          "type": "FileTypeToScale"
        },
        "webConfig": {
          "id": 2,
          "type": "FileTypeToScale"
        },
        "iosConfig": {
          "id": 3,
          "type": "FileTypeToScale"
        },
        "selectedPresetCodeType": {
          "id": 1,
          "type": "PresetCodeType"
        }
      }
    },
    "PresetCodeTypeToFileTypeUserConfig": {
      "fields": {
        "androidSelectedFileType": {
          "id": 4,
          "type": "FileType"
        },
        "iosSelectedFileType": {
          "id": 3,
          "type": "FileType"
        },
        "webSelectedFileType": {
          "id": 2,
          "type": "FileType"
        },
        "selectedPresetCodeType": {
          "id": 1,
          "type": "PresetCodeType"
        }
      }
    },
    "PreviewDocumentLoadedState": {
      "fields": {
        "documentReady": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "PreviewDocumentNodeState": {
      "fields": {
        "startingPointNodeId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "prototypePageId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_startingPointNodeId": {
          "oneof": [
            "startingPointNodeId"
          ]
        },
        "_prototypePageId": {
          "oneof": [
            "prototypePageId"
          ]
        }
      }
    },
    "PreviewFlowInfo": {
      "fields": {
        "nodeId": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "description": {
          "id": 2,
          "type": "string"
        },
        "name": {
          "id": 1,
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "PreviewNodeImage": {
      "fields": {
        "textBounds": {
          "id": 7,
          "type": "Rect"
        },
        "isText": {
          "id": 6,
          "type": "bool"
        },
        "background": {
          "id": 5,
          "type": "string"
        },
        "fontDesc": {
          "id": 4,
          "type": "PreviewTextNodeFontDesc"
        },
        "height": {
          "id": 3,
          "type": "float"
        },
        "width": {
          "id": 2,
          "type": "float"
        },
        "image": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "PreviewTextNodeFontDesc": {
      "fields": {
        "hasStyleName": {
          "id": 5,
          "type": "bool"
        },
        "fontStyleName": {
          "id": 4,
          "type": "string"
        },
        "fontSize": {
          "id": 3,
          "type": "float"
        },
        "fontStyle": {
          "id": 2,
          "type": "string"
        },
        "fontFamily": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "PrimitiveVariableData": {
      "fields": {
        "floatValue": {
          "id": 5,
          "type": "float"
        },
        "textValue": {
          "id": 4,
          "type": "string"
        },
        "boolValue": {
          "id": 3,
          "type": "bool"
        },
        "isMixed": {
          "id": 2,
          "type": "bool"
        },
        "type": {
          "id": 1,
          "type": "PrimitiveVariableType"
        }
      }
    },
    "PropertyValueOfInstance": {
      "fields": {
        "variableAlias": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "VariableAliasOfComponentProperty"
        },
        "booleanValue": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "stringValue": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_variableAlias": {
          "oneof": [
            "variableAlias"
          ]
        },
        "_booleanValue": {
          "oneof": [
            "booleanValue"
          ]
        },
        "_stringValue": {
          "oneof": [
            "stringValue"
          ]
        }
      }
    },
    "PrototypeAction": {
      "fields": {
        "mediaSkipByAmount": {
          "id": 18,
          "type": "float"
        },
        "mediaSkipToTime": {
          "id": 17,
          "type": "float"
        },
        "openUrlInNewTab": {
          "id": 16,
          "type": "bool"
        },
        "mediaAction": {
          "id": 14,
          "type": "MediaAction"
        },
        "extraScrollOffset": {
          "id": 13,
          "type": "Vector"
        },
        "transitionResetVideoPosition": {
          "id": 15,
          "type": "bool"
        },
        "easingFunction": {
          "id": 12,
          "rule": "repeated",
          "type": "float"
        },
        "transitionResetInteractiveComponents": {
          "id": 20,
          "type": "bool"
        },
        "transitionPreserveScroll": {
          "id": 11,
          "type": "bool"
        },
        "transitionNodeID": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "connectionURL": {
          "id": 8,
          "type": "string"
        },
        "navigationType": {
          "id": 10,
          "type": "NavigationType"
        },
        "transitionResetScrollPosition": {
          "id": 19,
          "type": "bool"
        },
        "connectionType": {
          "id": 7,
          "type": "ConnectionType"
        },
        "transitionShouldSmartAnimate": {
          "id": 6,
          "type": "bool"
        },
        "transitionDuration": {
          "id": 3,
          "type": "float"
        },
        "easingType": {
          "id": 4,
          "type": "EasingType"
        },
        "transitionType": {
          "id": 2,
          "type": "TransitionType"
        },
        "transitionTimeout": {
          "id": 5,
          "type": "float"
        },
        "overlayRelativePosition": {
          "id": 9,
          "type": "Vector"
        }
      },
      "oneofs": {
        "_transitionNodeID": {
          "oneof": [
            "transitionNodeID"
          ]
        }
      }
    },
    "PrototypeActionShadow": {
      "fields": {
        "transitionResetInteractiveComponents_isModified": {
          "id": 20,
          "type": "bool"
        },
        "mediaSkipToTime_isModified": {
          "id": 17,
          "type": "bool"
        },
        "mediaAction_isModified": {
          "id": 14,
          "type": "bool"
        },
        "navigationType_isModified": {
          "id": 10,
          "type": "bool"
        },
        "overlayRelativePosition_isModified": {
          "id": 9,
          "type": "bool"
        },
        "transitionPreserveScroll_isModified": {
          "id": 11,
          "type": "bool"
        },
        "connectionURL_isModified": {
          "id": 8,
          "type": "bool"
        },
        "transitionResetScrollPosition_isModified": {
          "id": 19,
          "type": "bool"
        },
        "transitionTimeout_isModified": {
          "id": 5,
          "type": "bool"
        },
        "connectionType_isModified": {
          "id": 7,
          "type": "bool"
        },
        "easingFunction_isModified": {
          "id": 12,
          "type": "bool"
        },
        "transitionDuration_isModified": {
          "id": 3,
          "type": "bool"
        },
        "mediaSkipByAmount_isModified": {
          "id": 18,
          "type": "bool"
        },
        "extraScrollOffset_isModified": {
          "id": 13,
          "type": "bool"
        },
        "easingType_isModified": {
          "id": 4,
          "type": "bool"
        },
        "openUrlInNewTab_isModified": {
          "id": 16,
          "type": "bool"
        },
        "transitionShouldSmartAnimate_isModified": {
          "id": 6,
          "type": "bool"
        },
        "transitionType_isModified": {
          "id": 2,
          "type": "bool"
        },
        "transitionResetVideoPosition_isModified": {
          "id": 15,
          "type": "bool"
        },
        "transitionNodeID_isModified": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "PrototypeAnimation": {
      "fields": {
        "overlay": {
          "id": 3,
          "type": "PrototypeOverlay"
        },
        "frame": {
          "id": 2,
          "type": "string"
        },
        "action": {
          "id": 4,
          "type": "PrototypeAction"
        },
        "type": {
          "id": 1,
          "type": "PrototypeAnimationType"
        }
      }
    },
    "PrototypeDevice": {
      "fields": {
        "rotation": {
          "id": 4,
          "type": "DeviceRotation"
        },
        "presetIdentifier": {
          "id": 3,
          "type": "string"
        },
        "size": {
          "id": 2,
          "type": "Vector"
        },
        "type": {
          "id": 1,
          "type": "PrototypeDeviceType"
        }
      }
    },
    "PrototypeEvent": {
      "fields": {
        "mediaHitTime": {
          "id": 7,
          "type": "float"
        },
        "transitionTimeout": {
          "id": 6,
          "type": "float"
        },
        "voiceEventPhrase": {
          "id": 5,
          "type": "string"
        },
        "keyTrigger": {
          "id": 4,
          "type": "KeyTrigger"
        },
        "interactionDuration": {
          "id": 3,
          "type": "float"
        },
        "interactionMaintained": {
          "id": 2,
          "type": "bool"
        },
        "interactionType": {
          "id": 1,
          "type": "InteractionType"
        }
      }
    },
    "PrototypeEventShadow": {
      "fields": {
        "mediaHitTime_isModified": {
          "id": 7,
          "type": "bool"
        },
        "transitionTimeout_isModified": {
          "id": 6,
          "type": "bool"
        },
        "interactionMaintained_isModified": {
          "id": 2,
          "type": "bool"
        },
        "keyTrigger_isModified": {
          "id": 4,
          "type": "bool"
        },
        "interactionDuration_isModified": {
          "id": 3,
          "type": "bool"
        },
        "voiceEventPhrase_isModified": {
          "id": 5,
          "type": "bool"
        },
        "interactionType_isModified": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "PrototypeInteraction": {
      "fields": {
        "stateManagementVersion": {
          "id": 5,
          "type": "int32"
        },
        "isDeleted": {
          "id": 4,
          "type": "bool"
        },
        "actions": {
          "id": 3,
          "rule": "repeated",
          "type": "PrototypeAction"
        },
        "event": {
          "id": 2,
          "type": "PrototypeEvent"
        },
        "id": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_id": {
          "oneof": [
            "id"
          ]
        }
      }
    },
    "PrototypeInteractionAddDragingState": {
      "fields": {
        "startInteractionKey": {
          "id": 6,
          "options": {
            "proto3_optional": true
          },
          "type": "PrototypeInteractionKey"
        },
        "isEditing": {
          "id": 5,
          "type": "bool"
        },
        "targetNodeIdRef": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "targetType": {
          "id": 3,
          "type": "DragingTargetType"
        },
        "startNodeIdRef": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "isDraging": {
          "id": 1,
          "type": "bool"
        }
      },
      "oneofs": {
        "_startInteractionKey": {
          "oneof": [
            "startInteractionKey"
          ]
        },
        "_targetNodeIdRef": {
          "oneof": [
            "targetNodeIdRef"
          ]
        },
        "_startNodeIdRef": {
          "oneof": [
            "startNodeIdRef"
          ]
        }
      }
    },
    "PrototypeInteractionKey": {
      "fields": {
        "interactionId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "sourceNodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_interactionId": {
          "oneof": [
            "interactionId"
          ]
        },
        "_sourceNodeId": {
          "oneof": [
            "sourceNodeId"
          ]
        }
      }
    },
    "PrototypeInteractionSelectedTargetNode": {
      "fields": {
        "selectedNodes": {
          "id": 3,
          "rule": "repeated",
          "type": "PrototypeInteractionTargetNode"
        },
        "isEmpty": {
          "id": 2,
          "type": "bool"
        },
        "isMixed": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "PrototypeInteractionShadow": {
      "fields": {
        "actionShadows": {
          "id": 6,
          "rule": "repeated",
          "type": "PrototypeActionShadow"
        },
        "actions_isModified": {
          "id": 3,
          "type": "bool"
        },
        "event_isModified": {
          "id": 2,
          "type": "bool"
        },
        "eventShadow": {
          "id": 5,
          "type": "PrototypeEventShadow"
        },
        "isDeleted_isModified": {
          "id": 4,
          "type": "bool"
        },
        "id": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_id": {
          "oneof": [
            "id"
          ]
        }
      }
    },
    "PrototypeInteractionShadowRecorder": {
      "fields": {
        "shadows": {
          "id": 1,
          "keyType": "string",
          "type": "PrototypeInteractionShadow"
        }
      }
    },
    "PrototypeInteractionTargetNode": {
      "fields": {
        "disabled": {
          "id": 3,
          "type": "bool"
        },
        "name": {
          "id": 2,
          "type": "string"
        },
        "id": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_id": {
          "oneof": [
            "id"
          ]
        }
      }
    },
    "PrototypeInteractionTargetNodeList": {
      "fields": {
        "list": {
          "id": 1,
          "rule": "repeated",
          "type": "PrototypeInteractionTargetNode"
        }
      }
    },
    "PrototypeInteractionWithNodeId": {
      "fields": {
        "sourceNodeId": {
          "id": 6,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "stateManagementVersion": {
          "id": 5,
          "type": "int32"
        },
        "isDeleted": {
          "id": 4,
          "type": "bool"
        },
        "actions": {
          "id": 3,
          "rule": "repeated",
          "type": "PrototypeAction"
        },
        "event": {
          "id": 2,
          "type": "PrototypeEvent"
        },
        "id": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_sourceNodeId": {
          "oneof": [
            "sourceNodeId"
          ]
        },
        "_id": {
          "oneof": [
            "id"
          ]
        }
      }
    },
    "PrototypeOverlay": {
      "fields": {
        "action": {
          "id": 3,
          "type": "PrototypeAction"
        },
        "trigger": {
          "id": 2,
          "type": "string"
        },
        "overlayDataSource": {
          "id": 4,
          "type": "string"
        },
        "overlay": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "PrototypeOverlaySettingDragingState": {
      "fields": {
        "mouseWorldY": {
          "id": 3,
          "type": "float"
        },
        "mouseWorldX": {
          "id": 2,
          "type": "float"
        },
        "isDraging": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "PrototypePresentDeviceFrameRegion": {
      "fields": {
        "deviceImageRotated": {
          "id": 12,
          "type": "bool"
        },
        "deviceImageRadius": {
          "id": 11,
          "type": "float"
        },
        "deviceImageUrl": {
          "id": 10,
          "type": "string"
        },
        "showDevice": {
          "id": 8,
          "type": "bool"
        },
        "hasDevice": {
          "id": 7,
          "type": "bool"
        },
        "scale": {
          "id": 6,
          "type": "float"
        },
        "deviceFrameSize": {
          "id": 4,
          "type": "Vector"
        },
        "canvasSize": {
          "id": 1,
          "type": "Vector"
        },
        "deviceFrameRegion": {
          "id": 5,
          "type": "SkRect"
        },
        "deviceFrameScreenSize": {
          "id": 2,
          "type": "Vector"
        },
        "deviceImageRegion": {
          "id": 9,
          "type": "SkRect"
        },
        "deviceFrameOffset": {
          "id": 3,
          "type": "Vector"
        }
      }
    },
    "PrototypePresentOpenRecordOption": {
      "fields": {
        "pageId": {
          "id": 2,
          "type": "string"
        },
        "docId": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "PrototypePresentScalingParam": {
      "fields": {
        "scaling": {
          "id": 1,
          "type": "PrototypePresentScaling"
        }
      }
    },
    "PrototypePresentUrlOption": {
      "fields": {
        "disableHotspotHints": {
          "id": 9,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "hideDeviceFrame": {
          "id": 8,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "showProtoSidebar": {
          "id": 7,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "scaling": {
          "id": 6,
          "options": {
            "proto3_optional": true
          },
          "type": "PrototypePresentScaling"
        },
        "hideUI": {
          "id": 11,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "disableDefaultKeyboardNav": {
          "id": 10,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "pageName": {
          "id": 4,
          "type": "string"
        },
        "pageId": {
          "id": 3,
          "type": "string"
        },
        "nodeId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "startingPointNodeId": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "fileId": {
          "id": 1,
          "type": "string"
        }
      },
      "oneofs": {
        "_disableHotspotHints": {
          "oneof": [
            "disableHotspotHints"
          ]
        },
        "_hideDeviceFrame": {
          "oneof": [
            "hideDeviceFrame"
          ]
        },
        "_showProtoSidebar": {
          "oneof": [
            "showProtoSidebar"
          ]
        },
        "_scaling": {
          "oneof": [
            "scaling"
          ]
        },
        "_hideUI": {
          "oneof": [
            "hideUI"
          ]
        },
        "_disableDefaultKeyboardNav": {
          "oneof": [
            "disableDefaultKeyboardNav"
          ]
        },
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        },
        "_startingPointNodeId": {
          "oneof": [
            "startingPointNodeId"
          ]
        }
      }
    },
    "PrototypePresentUrlParam": {
      "fields": {
        "hideUI": {
          "id": 9,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "disableDefaultKeyboardNav": {
          "id": 8,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "disableHotspotHints": {
          "id": 7,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "scaling": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "PrototypePresentScaling"
        },
        "hideDeviceFrame": {
          "id": 6,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "showProtoSidebar": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "startingPointNodeId": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "pageId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_hideUI": {
          "oneof": [
            "hideUI"
          ]
        },
        "_disableDefaultKeyboardNav": {
          "oneof": [
            "disableDefaultKeyboardNav"
          ]
        },
        "_disableHotspotHints": {
          "oneof": [
            "disableHotspotHints"
          ]
        },
        "_scaling": {
          "oneof": [
            "scaling"
          ]
        },
        "_hideDeviceFrame": {
          "oneof": [
            "hideDeviceFrame"
          ]
        },
        "_showProtoSidebar": {
          "oneof": [
            "showProtoSidebar"
          ]
        },
        "_startingPointNodeId": {
          "oneof": [
            "startingPointNodeId"
          ]
        },
        "_pageId": {
          "oneof": [
            "pageId"
          ]
        },
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "PrototypeStartingPoint": {
      "fields": {
        "orderIndex": {
          "id": 3,
          "type": "bytes"
        },
        "description": {
          "id": 2,
          "type": "string"
        },
        "name": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "PrototypeStartingPointDragingState": {
      "fields": {
        "isReadyForRemoved": {
          "id": 6,
          "type": "bool"
        },
        "positionY": {
          "id": 5,
          "type": "float"
        },
        "positionX": {
          "id": 4,
          "type": "float"
        },
        "startPositionY": {
          "id": 3,
          "type": "float"
        },
        "startPositionX": {
          "id": 2,
          "type": "float"
        },
        "idRef": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_idRef": {
          "oneof": [
            "idRef"
          ]
        }
      }
    },
    "PrototypeStartingPointEditingNameViewState": {
      "fields": {
        "positionLeft": {
          "id": 4,
          "type": "float"
        },
        "name": {
          "id": 3,
          "type": "string"
        },
        "positionTop": {
          "id": 5,
          "type": "float"
        },
        "editingIdRef": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "show": {
          "id": 1,
          "type": "bool"
        }
      },
      "oneofs": {
        "_editingIdRef": {
          "oneof": [
            "editingIdRef"
          ]
        }
      }
    },
    "PrototypeStartingPointEditingState": {
      "fields": {
        "editingIdRef": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_editingIdRef": {
          "oneof": [
            "editingIdRef"
          ]
        }
      }
    },
    "PublicPropInstance": {
      "fields": {
        "variantValues": {
          "id": 4,
          "rule": "repeated",
          "type": "VariantProp"
        },
        "compPropValues": {
          "id": 3,
          "rule": "repeated",
          "type": "VInstanceCompProp"
        },
        "nodeId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "name": {
          "id": 1,
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "PublicPropInstanceChain": {
      "fields": {
        "mergedIds": {
          "id": 3,
          "rule": "repeated",
          "type": "string"
        },
        "name": {
          "id": 4,
          "type": "string"
        },
        "isPublic": {
          "id": 2,
          "type": "bool"
        },
        "instances": {
          "id": 1,
          "rule": "repeated",
          "type": "PublicPropInstance"
        }
      }
    },
    "PublicPropInstanceState": {
      "fields": {
        "chains": {
          "id": 1,
          "rule": "repeated",
          "type": "PublicPropInstanceChain"
        }
      }
    },
    "RGB": {
      "fields": {
        "b": {
          "id": 3,
          "type": "float"
        },
        "g": {
          "id": 2,
          "type": "float"
        },
        "r": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "RGBA": {
      "fields": {
        "b": {
          "id": 3,
          "type": "float"
        },
        "g": {
          "id": 2,
          "type": "float"
        },
        "a": {
          "id": 4,
          "type": "float"
        },
        "r": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "RangeArg": {
      "fields": {
        "value": {
          "id": 2,
          "type": "uint32"
        },
        "key": {
          "id": 1,
          "type": "uint32"
        }
      }
    },
    "RangeFrame": {
      "fields": {
        "rangeArgs": {
          "id": 2,
          "rule": "repeated",
          "type": "RangeArg"
        },
        "rangeName": {
          "id": 1,
          "type": "uint32"
        }
      }
    },
    "RangeMetric": {
      "fields": {
        "duration": {
          "id": 5,
          "type": "int64"
        },
        "name": {
          "id": 1,
          "type": "string"
        },
        "ed": {
          "id": 4,
          "type": "int64"
        },
        "count": {
          "id": 6,
          "type": "int64"
        },
        "st": {
          "id": 3,
          "type": "int64"
        },
        "type": {
          "id": 2,
          "type": "RangeType"
        }
      }
    },
    "RasterizedImage": {
      "fields": {
        "id": {
          "id": 2,
          "type": "int32"
        },
        "name": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "ReGenerateSnapshotCommandParam": {
      "fields": {
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Rect": {
      "fields": {
        "height": {
          "id": 4,
          "type": "float"
        },
        "width": {
          "id": 3,
          "type": "float"
        },
        "y": {
          "id": 2,
          "type": "float"
        },
        "x": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "RemoteColorCollection": {
      "fields": {
        "variables": {
          "id": 4,
          "rule": "repeated",
          "type": "RemoteColorVariable"
        },
        "name": {
          "id": 3,
          "type": "string"
        },
        "libraryId": {
          "id": 2,
          "type": "string"
        },
        "id": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "RemoteColorLibrary": {
      "fields": {
        "collections": {
          "id": 4,
          "rule": "repeated",
          "type": "RemoteColorCollection"
        },
        "name": {
          "id": 2,
          "type": "string"
        },
        "documentId": {
          "id": 3,
          "type": "string"
        },
        "id": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "RemoteColorVariable": {
      "fields": {
        "color": {
          "id": 10,
          "options": {
            "proto3_optional": true
          },
          "type": "RGBA"
        },
        "description": {
          "id": 7,
          "type": "string"
        },
        "scope": {
          "id": 8,
          "rule": "repeated",
          "type": "VariableScope"
        },
        "nodeId": {
          "id": 5,
          "type": "string"
        },
        "nodeDataPath": {
          "id": 9,
          "type": "string"
        },
        "libraryId": {
          "id": 2,
          "type": "string"
        },
        "documentId": {
          "id": 4,
          "type": "string"
        },
        "name": {
          "id": 6,
          "type": "string"
        },
        "collectionId": {
          "id": 3,
          "type": "string"
        },
        "id": {
          "id": 1,
          "type": "string"
        }
      },
      "oneofs": {
        "_color": {
          "oneof": [
            "color"
          ]
        }
      }
    },
    "RemotePrimitiveCollection": {
      "fields": {
        "variables": {
          "id": 4,
          "rule": "repeated",
          "type": "RemotePrimitiveVariable"
        },
        "name": {
          "id": 3,
          "type": "string"
        },
        "libraryId": {
          "id": 2,
          "type": "string"
        },
        "id": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "RemotePrimitiveLibrary": {
      "fields": {
        "collections": {
          "id": 4,
          "rule": "repeated",
          "type": "RemotePrimitiveCollection"
        },
        "name": {
          "id": 2,
          "type": "string"
        },
        "documentId": {
          "id": 3,
          "type": "string"
        },
        "id": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "RemotePrimitiveVariable": {
      "fields": {
        "description": {
          "id": 7,
          "type": "string"
        },
        "scope": {
          "id": 8,
          "rule": "repeated",
          "type": "VariableScope"
        },
        "nodeId": {
          "id": 5,
          "type": "string"
        },
        "data": {
          "id": 10,
          "type": "PrimitiveVariableData"
        },
        "nodeDataPath": {
          "id": 9,
          "type": "string"
        },
        "libraryId": {
          "id": 2,
          "type": "string"
        },
        "documentId": {
          "id": 4,
          "type": "string"
        },
        "name": {
          "id": 6,
          "type": "string"
        },
        "collectionId": {
          "id": 3,
          "type": "string"
        },
        "id": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "RemoteVariableSetGroup": {
      "fields": {
        "libraryId": {
          "id": 2,
          "type": "string"
        },
        "items": {
          "id": 3,
          "rule": "repeated",
          "type": "RemoteVariableSetItem"
        },
        "docName": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "RemoteVariableSetItem": {
      "fields": {
        "variableModes": {
          "id": 10,
          "rule": "repeated",
          "type": "VVariableSetModeState"
        },
        "autoMode": {
          "id": 9,
          "type": "AutoModeInfo"
        },
        "documentId": {
          "id": 6,
          "type": "string"
        },
        "nodeDataPath": {
          "id": 7,
          "type": "string"
        },
        "libraryId": {
          "id": 5,
          "type": "string"
        },
        "name": {
          "id": 8,
          "type": "string"
        },
        "uniqueKey": {
          "id": 2,
          "type": "string"
        },
        "remoteNodeId": {
          "id": 4,
          "type": "string"
        },
        "localNodeIds": {
          "id": 3,
          "rule": "repeated",
          "type": "string"
        },
        "modeSelectionState": {
          "id": 11,
          "type": "VVariableSetModeSelectionState"
        },
        "isLocal": {
          "id": 12,
          "type": "bool"
        },
        "key": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "RenderCursorResult": {
      "fields": {
        "styleClassName": {
          "id": 4,
          "type": "string"
        },
        "finalClassList": {
          "id": 5,
          "type": "string"
        },
        "classListStatus": {
          "id": 3,
          "type": "ClassListStatus"
        },
        "styleSheetStatus": {
          "id": 2,
          "type": "StyleSheetStatus"
        },
        "renderTarget": {
          "id": 1,
          "type": "RenderCursorTarget"
        }
      }
    },
    "RenderDurationBuckets": {
      "fields": {
        "buckets": {
          "id": 1,
          "rule": "repeated",
          "type": "int32"
        }
      }
    },
    "RepeatedEnums": {
      "fields": {
        "val1": {
          "id": 1,
          "rule": "repeated",
          "type": "TestEnum"
        }
      }
    },
    "RepeatedFloats": {
      "fields": {
        "val1": {
          "id": 1,
          "rule": "repeated",
          "type": "float"
        }
      }
    },
    "RepeatedInts": {
      "fields": {
        "val1": {
          "id": 1,
          "rule": "repeated",
          "type": "int32"
        }
      }
    },
    "ReplaceDocumentPaintStyleIdParam": {
      "fields": {
        "forceUpdate": {
          "id": 3,
          "type": "bool"
        },
        "newPaintStyleId": {
          "id": 2,
          "type": "string"
        },
        "oldPaintStyleId": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "ReplaceDocumentTextStyleIdParam": {
      "fields": {
        "forceUpdate": {
          "id": 3,
          "type": "bool"
        },
        "newTextStyleId": {
          "id": 2,
          "type": "string"
        },
        "oldTextStyleId": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "ReplaceInstanceLibrary": {
      "fields": {
        "hasDividerBelow": {
          "id": 5,
          "type": "bool"
        },
        "sortingNodes": {
          "id": 7,
          "type": "bool"
        },
        "showLocalNoComponents": {
          "id": 6,
          "type": "bool"
        },
        "tree": {
          "id": 4,
          "type": "ReplaceInstanceTree"
        },
        "docId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "name": {
          "id": 3,
          "type": "string"
        },
        "isSelected": {
          "id": 1,
          "type": "bool"
        }
      },
      "oneofs": {
        "_docId": {
          "oneof": [
            "docId"
          ]
        }
      }
    },
    "ReplaceInstanceNodeData": {
      "fields": {
        "height": {
          "id": 15,
          "type": "float"
        },
        "layoutSizeType": {
          "id": 13,
          "type": "LibraryLayoutSizeType"
        },
        "componentOrSetNodeId": {
          "id": 7,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "name": {
          "id": 8,
          "type": "string"
        },
        "nodeId": {
          "id": 6,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "description": {
          "id": 9,
          "type": "string"
        },
        "thumbnailData": {
          "id": 12,
          "type": "ThumbnailData"
        },
        "key": {
          "id": 5,
          "type": "string"
        },
        "documentId": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "isCompSet": {
          "id": 2,
          "type": "bool"
        },
        "contentHash": {
          "id": 10,
          "type": "string"
        },
        "isSelected": {
          "id": 3,
          "type": "bool"
        },
        "nodeDataPath": {
          "id": 11,
          "type": "string"
        },
        "width": {
          "id": 14,
          "type": "float"
        },
        "isRemote": {
          "id": 1,
          "type": "bool"
        }
      },
      "oneofs": {
        "_componentOrSetNodeId": {
          "oneof": [
            "componentOrSetNodeId"
          ]
        },
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        },
        "_documentId": {
          "oneof": [
            "documentId"
          ]
        }
      }
    },
    "ReplaceInstanceState": {
      "fields": {
        "allLibraryList": {
          "id": 2,
          "rule": "repeated",
          "type": "ReplaceInstanceLibrary"
        },
        "shouldDisplay": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "ReplaceInstanceTree": {
      "fields": {
        "nodes": {
          "id": 3,
          "rule": "repeated",
          "type": "ReplaceInstanceNodeData"
        },
        "folderChildren": {
          "id": 2,
          "rule": "repeated",
          "type": "ReplaceInstanceTree"
        },
        "name": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "ReplaceMissingFont": {
      "fields": {
        "toFontName": {
          "id": 2,
          "type": "FontName"
        },
        "fromFontName": {
          "id": 1,
          "type": "FontName"
        }
      }
    },
    "Req_ApiAddComponentProperty": {
      "fields": {
        "preferredValues": {
          "id": 5,
          "rule": "repeated",
          "type": "FigmaSchemaInstanceSwapPreferredValue"
        },
        "type": {
          "id": 3,
          "type": "VComponentPropType"
        },
        "propertyName": {
          "id": 2,
          "type": "string"
        },
        "defaultValue": {
          "id": 4,
          "type": "string"
        },
        "id": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_id": {
          "oneof": [
            "id"
          ]
        }
      }
    },
    "Req_ApiDeleteComponentProperty": {
      "fields": {
        "propertyName": {
          "id": 2,
          "type": "string"
        },
        "id": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_id": {
          "oneof": [
            "id"
          ]
        }
      }
    },
    "Req_ApiEditComponentProperty": {
      "fields": {
        "newValue": {
          "id": 3,
          "type": "EditComponentPropertyValue"
        },
        "propertyName": {
          "id": 2,
          "type": "string"
        },
        "id": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_id": {
          "oneof": [
            "id"
          ]
        }
      }
    },
    "Ret_AIComponentIdMap": {
      "fields": {
        "idMap": {
          "id": 1,
          "keyType": "string",
          "type": "AIComponentCreatedId"
        }
      }
    },
    "Ret_AICopilotInfo": {
      "fields": {
        "mobileScreenNodeId": {
          "id": 6,
          "type": "string"
        },
        "boundingBox": {
          "id": 5,
          "type": "Rect"
        },
        "screenImg": {
          "id": 4,
          "type": "string"
        },
        "parentId": {
          "id": 1,
          "type": "string"
        },
        "screenRootData": {
          "id": 3,
          "type": "string"
        },
        "docId": {
          "id": 2,
          "type": "string"
        }
      }
    },
    "Ret_AICopilotParam": {
      "fields": {
        "boundingBox": {
          "id": 3,
          "type": "Rect"
        },
        "screenImg": {
          "id": 2,
          "type": "string"
        },
        "screenRootData": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Ret_AICopilotResult": {
      "fields": {
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Ret_AIDesignLintDeDuplicateNodeIds": {
      "fields": {
        "nodeIds": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "Ret_AIDesignLintPaintStyleCommand": {
      "fields": {
        "similarMatchInfos": {
          "id": 2,
          "rule": "repeated",
          "type": "PaintStyleMatchInfo"
        },
        "sameMatchInfos": {
          "id": 1,
          "rule": "repeated",
          "type": "PaintStyleMatchInfo"
        }
      }
    },
    "Ret_AIDesignLintTextStyleCommand": {
      "fields": {
        "similarMatchInfos": {
          "id": 2,
          "rule": "repeated",
          "type": "TextStyleMatchInfo"
        },
        "sameMatchInfos": {
          "id": 1,
          "rule": "repeated",
          "type": "TextStyleMatchInfo"
        }
      }
    },
    "Ret_APILoadFont": {
      "fields": {
        "status": {
          "id": 3,
          "type": "LoadFontStatus"
        },
        "error": {
          "id": 2,
          "type": "ApiError"
        },
        "fontName": {
          "id": 1,
          "type": "FontName"
        }
      }
    },
    "Ret_APiBoolWithError": {
      "fields": {
        "value": {
          "id": 2,
          "type": "bool"
        },
        "error": {
          "id": 1,
          "type": "ApiError"
        }
      }
    },
    "Ret_APiGetSelectionColors": {
      "fields": {
        "emptyValue": {
          "id": 4,
          "type": "bool"
        },
        "paints": {
          "id": 2,
          "rule": "repeated",
          "type": "Paint"
        },
        "styleIds": {
          "id": 3,
          "rule": "repeated",
          "type": "string"
        },
        "error": {
          "id": 1,
          "type": "ApiError"
        }
      }
    },
    "Ret_APiIdRefWithError": {
      "fields": {
        "ids": {
          "id": 2,
          "rule": "repeated",
          "type": "string"
        },
        "error": {
          "id": 1,
          "type": "ApiError"
        }
      }
    },
    "Ret_APiStringWithError": {
      "fields": {
        "value": {
          "id": 2,
          "type": "string"
        },
        "error": {
          "id": 1,
          "type": "ApiError"
        }
      }
    },
    "Ret_AiMarkInfo": {
      "fields": {
        "infos": {
          "id": 1,
          "rule": "repeated",
          "type": "AiMarkUnit"
        }
      }
    },
    "Ret_ApiComponentPropertiesWithError": {
      "fields": {
        "properties": {
          "id": 2,
          "rule": "repeated",
          "type": "ComponentProperty"
        },
        "error": {
          "id": 1,
          "type": "ApiError"
        }
      }
    },
    "Ret_ApiComponentPropertyDefinitionsWithError": {
      "fields": {
        "properties": {
          "id": 2,
          "rule": "repeated",
          "type": "ComponentPropertyDefinition"
        },
        "error": {
          "id": 1,
          "type": "ApiError"
        }
      }
    },
    "Ret_ApiCurrentUser": {
      "fields": {
        "photoUrl": {
          "id": 4,
          "type": "string"
        },
        "color": {
          "id": 5,
          "type": "RGB"
        },
        "name": {
          "id": 3,
          "type": "string"
        },
        "userId": {
          "id": 2,
          "type": "int64"
        },
        "sessionId": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "Ret_ApiGetAllFontNameWithError": {
      "fields": {
        "value": {
          "id": 2,
          "rule": "repeated",
          "type": "FontName"
        },
        "error": {
          "id": 1,
          "type": "ApiError"
        }
      }
    },
    "Ret_ApiGetComponentPropertyReferencesWithError": {
      "fields": {
        "error": {
          "id": 2,
          "type": "ApiError"
        },
        "references": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "ComponentPropertyReferences"
        }
      },
      "oneofs": {
        "_references": {
          "oneof": [
            "references"
          ]
        }
      }
    },
    "Ret_ApiGetFills": {
      "fields": {
        "value": {
          "id": 2,
          "rule": "repeated",
          "type": "Paint"
        },
        "mixed": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "Ret_ApiGetFillsWithError": {
      "fields": {
        "value": {
          "id": 2,
          "type": "Ret_ApiGetFills"
        },
        "error": {
          "id": 1,
          "type": "ApiError"
        }
      }
    },
    "Ret_ApiGetFontName": {
      "fields": {
        "value": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "FontName"
        },
        "mixed": {
          "id": 1,
          "type": "bool"
        }
      },
      "oneofs": {
        "_value": {
          "oneof": [
            "value"
          ]
        }
      }
    },
    "Ret_ApiGetHyperlink": {
      "fields": {
        "value": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "HyperlinkTarget"
        },
        "mixed": {
          "id": 1,
          "type": "bool"
        }
      },
      "oneofs": {
        "_value": {
          "oneof": [
            "value"
          ]
        }
      }
    },
    "Ret_ApiGetHyperlinkWithError": {
      "fields": {
        "value": {
          "id": 2,
          "type": "Ret_ApiGetHyperlink"
        },
        "error": {
          "id": 1,
          "type": "ApiError"
        }
      }
    },
    "Ret_ApiGetIsExposedInstance": {
      "fields": {
        "value": {
          "id": 2,
          "type": "bool"
        },
        "error": {
          "id": 1,
          "type": "ApiError"
        }
      }
    },
    "Ret_ApiGetLetterSpacing": {
      "fields": {
        "value": {
          "id": 2,
          "type": "LetterSpacing"
        },
        "mixed": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "Ret_ApiGetLetterSpacingWithError": {
      "fields": {
        "value": {
          "id": 2,
          "type": "Ret_ApiGetLetterSpacing"
        },
        "error": {
          "id": 1,
          "type": "ApiError"
        }
      }
    },
    "Ret_ApiGetLineHeight": {
      "fields": {
        "value": {
          "id": 2,
          "type": "LineHeight"
        },
        "mixed": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "Ret_ApiGetLineHeightWithError": {
      "fields": {
        "value": {
          "id": 2,
          "type": "Ret_ApiGetLineHeight"
        },
        "error": {
          "id": 1,
          "type": "ApiError"
        }
      }
    },
    "Ret_ApiGetLineType": {
      "fields": {
        "value": {
          "id": 2,
          "type": "LineType"
        },
        "mixed": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "Ret_ApiGetListOptionsWithError": {
      "fields": {
        "value": {
          "id": 2,
          "type": "Ret_ApiGetLineType"
        },
        "error": {
          "id": 1,
          "type": "ApiError"
        }
      }
    },
    "Ret_ApiGetMixedFloat": {
      "fields": {
        "value": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "mixed": {
          "id": 1,
          "type": "bool"
        }
      },
      "oneofs": {
        "_value": {
          "oneof": [
            "value"
          ]
        }
      }
    },
    "Ret_ApiGetMixedFloatWithError": {
      "fields": {
        "value": {
          "id": 2,
          "type": "Ret_ApiGetMixedFloat"
        },
        "error": {
          "id": 1,
          "type": "ApiError"
        }
      }
    },
    "Ret_ApiGetMixedFontNameWithError": {
      "fields": {
        "value": {
          "id": 2,
          "type": "Ret_ApiGetFontName"
        },
        "error": {
          "id": 1,
          "type": "ApiError"
        }
      }
    },
    "Ret_ApiGetMixedHandleMirror": {
      "fields": {
        "value": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "VectorHandleMirror"
        },
        "isMixed": {
          "id": 1,
          "type": "bool"
        }
      },
      "oneofs": {
        "_value": {
          "oneof": [
            "value"
          ]
        }
      }
    },
    "Ret_ApiGetMixedInt": {
      "fields": {
        "value": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "mixed": {
          "id": 1,
          "type": "bool"
        }
      },
      "oneofs": {
        "_value": {
          "oneof": [
            "value"
          ]
        }
      }
    },
    "Ret_ApiGetMixedIntWithError": {
      "fields": {
        "value": {
          "id": 2,
          "type": "Ret_ApiGetMixedInt"
        },
        "error": {
          "id": 1,
          "type": "ApiError"
        }
      }
    },
    "Ret_ApiGetMixedString": {
      "fields": {
        "value": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "mixed": {
          "id": 1,
          "type": "bool"
        }
      },
      "oneofs": {
        "_value": {
          "oneof": [
            "value"
          ]
        }
      }
    },
    "Ret_ApiGetMixedStringWithError": {
      "fields": {
        "value": {
          "id": 2,
          "type": "Ret_ApiGetMixedString"
        },
        "error": {
          "id": 1,
          "type": "ApiError"
        }
      }
    },
    "Ret_ApiGetNodeType": {
      "fields": {
        "type": {
          "id": 1,
          "type": "NodeType"
        }
      }
    },
    "Ret_ApiGetPluginData": {
      "fields": {
        "value": {
          "id": 2,
          "type": "string"
        },
        "error": {
          "id": 1,
          "type": "ApiError"
        }
      }
    },
    "Ret_ApiGetPluginDataKeys": {
      "fields": {
        "keys": {
          "id": 2,
          "rule": "repeated",
          "type": "string"
        },
        "error": {
          "id": 1,
          "type": "ApiError"
        }
      }
    },
    "Ret_ApiGetProps": {
      "fields": {
        "value": {
          "id": 2,
          "type": "ApiProps"
        },
        "error": {
          "id": 1,
          "type": "ApiError"
        }
      }
    },
    "Ret_ApiGetPublishStatus": {
      "fields": {
        "status": {
          "id": 1,
          "type": "PublishStatus"
        }
      }
    },
    "Ret_ApiGetSharedPluginData": {
      "fields": {
        "value": {
          "id": 2,
          "type": "string"
        },
        "error": {
          "id": 1,
          "type": "ApiError"
        }
      }
    },
    "Ret_ApiGetSharedPluginDataKeys": {
      "fields": {
        "keys": {
          "id": 2,
          "rule": "repeated",
          "type": "string"
        },
        "error": {
          "id": 1,
          "type": "ApiError"
        }
      }
    },
    "Ret_ApiGetStrokeCap": {
      "fields": {
        "value": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "StrokeCap"
        },
        "mixed": {
          "id": 1,
          "type": "bool"
        }
      },
      "oneofs": {
        "_value": {
          "oneof": [
            "value"
          ]
        }
      }
    },
    "Ret_ApiGetStrokeJoin": {
      "fields": {
        "value": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "StrokeJoin"
        },
        "mixed": {
          "id": 1,
          "type": "bool"
        }
      },
      "oneofs": {
        "_value": {
          "oneof": [
            "value"
          ]
        }
      }
    },
    "Ret_ApiGetTextCase": {
      "fields": {
        "value": {
          "id": 2,
          "type": "TextCase"
        },
        "mixed": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "Ret_ApiGetTextCaseWithError": {
      "fields": {
        "value": {
          "id": 2,
          "type": "Ret_ApiGetTextCase"
        },
        "error": {
          "id": 1,
          "type": "ApiError"
        }
      }
    },
    "Ret_ApiGetTextDecoration": {
      "fields": {
        "value": {
          "id": 2,
          "type": "TextDecoration"
        },
        "mixed": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "Ret_ApiGetTextDecorationWithError": {
      "fields": {
        "value": {
          "id": 2,
          "type": "Ret_ApiGetTextDecoration"
        },
        "error": {
          "id": 1,
          "type": "ApiError"
        }
      }
    },
    "Ret_ApiIdRefWithError": {
      "fields": {
        "value": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "error": {
          "id": 1,
          "type": "ApiError"
        }
      },
      "oneofs": {
        "_value": {
          "oneof": [
            "value"
          ]
        }
      }
    },
    "Ret_ApiListAvailableFontsAsync": {
      "fields": {
        "fonts": {
          "id": 1,
          "rule": "repeated",
          "type": "FontNameRef"
        }
      }
    },
    "Ret_ApiMixedFills": {
      "fields": {
        "paints": {
          "id": 2,
          "rule": "repeated",
          "type": "Paint"
        },
        "mixed": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "Ret_ApiMixedStyleId": {
      "fields": {
        "styleId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "mixed": {
          "id": 1,
          "type": "bool"
        }
      },
      "oneofs": {
        "_styleId": {
          "oneof": [
            "styleId"
          ]
        }
      }
    },
    "Ret_ApiSetProps": {
      "fields": {
        "error": {
          "id": 1,
          "type": "ApiError"
        }
      }
    },
    "Ret_ApiStyleMixins": {
      "fields": {
        "value": {
          "id": 2,
          "rule": "repeated",
          "type": "string"
        },
        "error": {
          "id": 1,
          "type": "ApiError"
        }
      }
    },
    "Ret_ApiStyledTextSegmentsWithError": {
      "fields": {
        "value": {
          "id": 2,
          "rule": "repeated",
          "type": "StyledTextSegmentExt"
        },
        "error": {
          "id": 1,
          "type": "ApiError"
        }
      }
    },
    "Ret_ApiSymbolOverridesWithError": {
      "fields": {
        "overrides": {
          "id": 2,
          "rule": "repeated",
          "type": "SymbolOverrides"
        },
        "error": {
          "id": 1,
          "type": "ApiError"
        }
      }
    },
    "Ret_ApiUngroup": {
      "fields": {
        "ids": {
          "id": 2,
          "rule": "repeated",
          "type": "string"
        },
        "error": {
          "id": 1,
          "type": "ApiError"
        }
      }
    },
    "Ret_ApplyAIRecognizeResultCommand": {
      "fields": {
        "resultPageId": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Ret_ApplyChatBot": {
      "fields": {
        "rootFrames": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "Ret_BackendTypeCommand": {
      "fields": {
        "wgpuInstanceId": {
          "id": 5,
          "type": "int64"
        },
        "wgpuQueueId": {
          "id": 4,
          "type": "int64"
        },
        "wgpuAdapterId": {
          "id": 2,
          "type": "int64"
        },
        "wgpuDeviceId": {
          "id": 3,
          "type": "int64"
        },
        "type": {
          "id": 1,
          "type": "BackendType"
        }
      }
    },
    "Ret_BatchExportConfigs": {
      "fields": {
        "configs": {
          "id": 1,
          "rule": "repeated",
          "type": "BatchExportConfig"
        }
      }
    },
    "Ret_CopyWasmMemToWebBlob": {
      "fields": {
        "blobID": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "uint32"
        }
      },
      "oneofs": {
        "_blobID": {
          "oneof": [
            "blobID"
          ]
        }
      }
    },
    "Ret_CreateImage": {
      "fields": {
        "error": {
          "id": 2,
          "type": "ApiError"
        },
        "hash": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Ret_GetBitmap": {
      "fields": {
        "data": {
          "id": 3,
          "type": "bytes"
        },
        "height": {
          "id": 2,
          "type": "int32"
        },
        "width": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "Ret_GetComputedColorVariable": {
      "fields": {
        "color": {
          "id": 8,
          "options": {
            "proto3_optional": true
          },
          "type": "RGBA"
        },
        "isLocal": {
          "id": 7,
          "type": "bool"
        },
        "collectionKey": {
          "id": 5,
          "type": "string"
        },
        "nodeId": {
          "id": 4,
          "type": "string"
        },
        "key": {
          "id": 3,
          "type": "string"
        },
        "description": {
          "id": 2,
          "type": "string"
        },
        "isLocalVisible": {
          "id": 6,
          "type": "bool"
        },
        "name": {
          "id": 1,
          "type": "string"
        }
      },
      "oneofs": {
        "_color": {
          "oneof": [
            "color"
          ]
        }
      }
    },
    "Ret_GetDeviceOptionsCommand": {
      "fields": {
        "options": {
          "id": 1,
          "rule": "repeated",
          "type": "DeviceOption"
        }
      }
    },
    "Ret_GetDocumentColorProfile": {
      "fields": {
        "colorProfile": {
          "id": 1,
          "type": "DocumentColorProfile"
        }
      }
    },
    "Ret_GetFileKey": {
      "fields": {
        "fileKey": {
          "id": 2,
          "type": "string"
        },
        "error": {
          "id": 1,
          "type": "ApiError"
        }
      }
    },
    "Ret_GetImageByHash": {
      "fields": {
        "exist": {
          "id": 2,
          "type": "bool"
        },
        "error": {
          "id": 1,
          "type": "ApiError"
        }
      }
    },
    "Ret_GetSearchedSortedList": {
      "fields": {
        "sortedOriginalList": {
          "id": 1,
          "rule": "repeated",
          "type": "int32"
        }
      }
    },
    "Ret_GetSingleMissLibraryContent": {
      "fields": {
        "missingComponents": {
          "id": 2,
          "rule": "repeated",
          "type": "MissLibraryComponentOrStyleItem"
        },
        "missingStyles": {
          "id": 1,
          "rule": "repeated",
          "type": "MissLibraryComponentOrStyleItem"
        }
      }
    },
    "Ret_GetUsedMemoryCommand": {
      "fields": {
        "sceneNodesCount": {
          "id": 2,
          "type": "uint32"
        },
        "value": {
          "id": 1,
          "type": "uint64"
        }
      }
    },
    "Ret_GetVRAM": {
      "fields": {
        "vram": {
          "id": 1,
          "type": "uint32"
        }
      }
    },
    "Ret_GetValidTransitionTypes": {
      "fields": {
        "transitionTypes": {
          "id": 1,
          "rule": "repeated",
          "type": "TransitionType"
        }
      }
    },
    "Ret_GetVertexCameraPosition": {
      "fields": {
        "y": {
          "id": 2,
          "type": "float"
        },
        "x": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "Ret_HTMLForChatBot": {
      "fields": {
        "selectionHTMLs": {
          "id": 2,
          "rule": "repeated",
          "type": "SelectionHTML"
        },
        "rootIds": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "Ret_PluginDropEventPickInsertionLocation": {
      "fields": {
        "absoluteY": {
          "id": 4,
          "type": "float"
        },
        "parentId": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "absoluteX": {
          "id": 3,
          "type": "float"
        },
        "relativeY": {
          "id": 2,
          "type": "float"
        },
        "relativeX": {
          "id": 1,
          "type": "float"
        }
      },
      "oneofs": {
        "_parentId": {
          "oneof": [
            "parentId"
          ]
        }
      }
    },
    "Ret_PushBitmap": {
      "fields": {
        "chunkBytesList": {
          "id": 2,
          "rule": "repeated",
          "type": "uint32"
        },
        "resourcePixelsHandle": {
          "id": 1,
          "type": "uint32"
        }
      }
    },
    "Ret_RGBAtoCode": {
      "fields": {
        "codes": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "Ret_RenderTypeCommand": {
      "fields": {
        "type": {
          "id": 1,
          "type": "RenderType"
        }
      }
    },
    "Ret_ReplaceSelectedInstanceMainComp": {
      "fields": {
        "errorMsg": {
          "id": 2,
          "type": "string"
        },
        "success": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "Ret_SearchReplaceInstance": {
      "fields": {
        "results": {
          "id": 1,
          "rule": "repeated",
          "type": "ReplaceInstanceNodeData"
        }
      }
    },
    "Ret_SetAliasForVariableCommand": {
      "fields": {
        "code": {
          "id": 1,
          "type": "RetCode_SetAliasForVariableCommand"
        }
      }
    },
    "Ret_SkewSelectedNodes": {
      "fields": {
        "noSelection": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "Ret_TextNodeStyle": {
      "fields": {
        "style": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "TextNodeStyle"
        }
      },
      "oneofs": {
        "_style": {
          "oneof": [
            "style"
          ]
        }
      }
    },
    "Ret_ToggleSystemMetric": {
      "fields": {
        "systemFlushCost": {
          "id": 3,
          "type": "int64"
        },
        "commandCost": {
          "id": 2,
          "type": "int64"
        },
        "renderCost": {
          "id": 4,
          "type": "int64"
        },
        "system2Cost": {
          "id": 1,
          "keyType": "string",
          "type": "int64"
        }
      }
    },
    "Ret_Viewport": {
      "fields": {
        "error": {
          "id": 6,
          "type": "ApiError"
        },
        "zoom": {
          "id": 1,
          "type": "float"
        },
        "height": {
          "id": 5,
          "type": "float"
        },
        "width": {
          "id": 4,
          "type": "float"
        },
        "y": {
          "id": 3,
          "type": "float"
        },
        "x": {
          "id": 2,
          "type": "float"
        }
      }
    },
    "Ret_buildComponentUpdateVO": {
      "fields": {
        "variants": {
          "id": 2,
          "rule": "repeated",
          "type": "ComponentUpdateVO"
        },
        "vo": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "ComponentUpdateVO"
        }
      },
      "oneofs": {
        "_vo": {
          "oneof": [
            "vo"
          ]
        }
      }
    },
    "Ret_buildStyleUpdateVO": {
      "fields": {
        "value": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "StyleUpdateVO"
        }
      },
      "oneofs": {
        "_value": {
          "oneof": [
            "value"
          ]
        }
      }
    },
    "Ret_buildVariableSetUpdateVO": {
      "fields": {
        "value": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "VariableSetUpdateVO"
        }
      },
      "oneofs": {
        "_value": {
          "oneof": [
            "value"
          ]
        }
      }
    },
    "Ret_buildVariableUpdateVO": {
      "fields": {
        "value": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "VariableUpdateVO"
        }
      },
      "oneofs": {
        "_value": {
          "oneof": [
            "value"
          ]
        }
      }
    },
    "Ret_clipboardReadFiles": {
      "fields": {
        "files": {
          "id": 1,
          "rule": "repeated",
          "type": "WkClipboardProxyFile"
        }
      }
    },
    "Ret_clipboardReadImageInfos": {
      "fields": {
        "imageInfos": {
          "id": 1,
          "rule": "repeated",
          "type": "ImageInfo"
        }
      }
    },
    "Ret_clipboardReadItems": {
      "fields": {
        "items": {
          "id": 1,
          "rule": "repeated",
          "type": "WkClipboardItem"
        }
      }
    },
    "Ret_createCanvasToRender": {
      "fields": {
        "id": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "Ret_createImage": {
      "fields": {
        "taskId": {
          "id": 2,
          "type": "int32"
        },
        "dataUri": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Ret_dispatchMouseEvent": {
      "fields": {
        "accepted": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "Ret_evalJsScript": {
      "fields": {
        "error": {
          "id": 3,
          "type": "string"
        },
        "result": {
          "id": 2,
          "type": "string"
        },
        "success": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "Ret_exportBinaryData": {
      "fields": {
        "buffer": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Ret_exportComponentLibraryData": {
      "fields": {
        "value": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "ComponentLibraryDataUpdateVO"
        }
      },
      "oneofs": {
        "_value": {
          "oneof": [
            "value"
          ]
        }
      }
    },
    "Ret_exportData": {
      "fields": {
        "dataBase64": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Ret_exportDataToWebBlob": {
      "fields": {
        "dataBase64": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "blobID": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "uint32"
        }
      },
      "oneofs": {
        "_dataBase64": {
          "oneof": [
            "dataBase64"
          ]
        },
        "_blobID": {
          "oneof": [
            "blobID"
          ]
        }
      }
    },
    "Ret_exportHTML": {
      "fields": {
        "data": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Ret_exportImagePixels": {
      "fields": {
        "height": {
          "id": 3,
          "type": "int32"
        },
        "width": {
          "id": 2,
          "type": "int32"
        },
        "data": {
          "id": 1,
          "type": "bytes"
        }
      }
    },
    "Ret_exportJson": {
      "fields": {
        "compressedData": {
          "id": 3,
          "type": "bytes"
        },
        "json": {
          "id": 2,
          "type": "string"
        },
        "isBrotliCompressed": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "Ret_exportStyleLibraryData": {
      "fields": {
        "value": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "StyleLibraryDataUpdateVO"
        }
      },
      "oneofs": {
        "_value": {
          "oneof": [
            "value"
          ]
        }
      }
    },
    "Ret_exportVariableCollectionLibraryData": {
      "fields": {
        "value": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "VariableCollectionLibraryDataUpdateVO"
        }
      },
      "oneofs": {
        "_value": {
          "oneof": [
            "value"
          ]
        }
      }
    },
    "Ret_exportVariableLibraryData": {
      "fields": {
        "value": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "VariableLibraryDataUpdateVO"
        }
      },
      "oneofs": {
        "_value": {
          "oneof": [
            "value"
          ]
        }
      }
    },
    "Ret_finishRendering": {
      "fields": {
        "resourceId": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "Ret_generatePublishMixinThumbnail": {
      "fields": {
        "layoutGridIconType": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "LayoutGridStyleIconType"
        },
        "effectStyleIconType": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "EffectStyleIconType"
        },
        "buffer": {
          "id": 1,
          "type": "string"
        }
      },
      "oneofs": {
        "_layoutGridIconType": {
          "oneof": [
            "layoutGridIconType"
          ]
        },
        "_effectStyleIconType": {
          "oneof": [
            "effectStyleIconType"
          ]
        }
      }
    },
    "Ret_generateThumbnail": {
      "fields": {
        "buffer": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Ret_getAllLocalStyleAndComponentsCountCommand": {
      "fields": {
        "componentCount": {
          "id": 2,
          "type": "int32"
        },
        "styleCount": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "Ret_getComputedFills": {
      "fields": {
        "fills": {
          "id": 1,
          "rule": "repeated",
          "type": "Paint"
        }
      }
    },
    "Ret_getComputedStrokes": {
      "fields": {
        "strokes": {
          "id": 1,
          "rule": "repeated",
          "type": "Paint"
        }
      }
    },
    "Ret_getFullStyledTextSegments": {
      "fields": {
        "segments": {
          "id": 1,
          "rule": "repeated",
          "type": "StyledTextSegment"
        }
      }
    },
    "Ret_getImageOfFigNode": {
      "fields": {
        "images": {
          "id": 1,
          "rule": "repeated",
          "type": "PreviewNodeImage"
        }
      }
    },
    "Ret_getLocalEdtiorType": {
      "fields": {
        "value": {
          "id": 1,
          "type": "EditorType"
        }
      }
    },
    "Ret_isFeatureEnabled": {
      "fields": {
        "isEnabled": {
          "id": 2,
          "type": "bool"
        },
        "key": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "Ret_topFrameWhichShouldExport": {
      "fields": {
        "mixins": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "ReturnTypeWithError": {
      "fields": {
        "error": {
          "id": 1,
          "type": "ApiError"
        }
      }
    },
    "Return_GetImageDownloadSuccessRatioCommand": {
      "fields": {
        "previewDownloadTotalCount": {
          "id": 9,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "originDownloadTotalCount": {
          "id": 6,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "originDownloadSuccessCount": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "previewDownloadSuccessCount": {
          "id": 8,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "originDownloadSuccessRatio": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "emojiDownloadTotalCount": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "emojiDownloadSuccessCount": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "previewDownloadSuccessRatio": {
          "id": 7,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "emojiDownloadSuccessRatio": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        }
      },
      "oneofs": {
        "_previewDownloadTotalCount": {
          "oneof": [
            "previewDownloadTotalCount"
          ]
        },
        "_originDownloadTotalCount": {
          "oneof": [
            "originDownloadTotalCount"
          ]
        },
        "_originDownloadSuccessCount": {
          "oneof": [
            "originDownloadSuccessCount"
          ]
        },
        "_previewDownloadSuccessCount": {
          "oneof": [
            "previewDownloadSuccessCount"
          ]
        },
        "_originDownloadSuccessRatio": {
          "oneof": [
            "originDownloadSuccessRatio"
          ]
        },
        "_emojiDownloadTotalCount": {
          "oneof": [
            "emojiDownloadTotalCount"
          ]
        },
        "_emojiDownloadSuccessCount": {
          "oneof": [
            "emojiDownloadSuccessCount"
          ]
        },
        "_previewDownloadSuccessRatio": {
          "oneof": [
            "previewDownloadSuccessRatio"
          ]
        },
        "_emojiDownloadSuccessRatio": {
          "oneof": [
            "emojiDownloadSuccessRatio"
          ]
        }
      }
    },
    "RightClickLayerSelectionNode": {
      "fields": {
        "isLocked": {
          "id": 5,
          "type": "bool"
        },
        "activity": {
          "id": 4,
          "type": "bool"
        },
        "forwardIcon": {
          "id": 3,
          "type": "NodeIconData"
        },
        "id": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "name": {
          "id": 1,
          "type": "string"
        }
      },
      "oneofs": {
        "_id": {
          "oneof": [
            "id"
          ]
        }
      }
    },
    "RightClickMenuState": {
      "fields": {
        "source": {
          "id": 2,
          "type": "RightClickSource"
        },
        "position": {
          "id": 3,
          "type": "Vector"
        },
        "opened": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "RightClickState": {
      "fields": {
        "hitNodeIds": {
          "id": 3,
          "rule": "repeated",
          "type": "string"
        },
        "inSelectionBound": {
          "id": 2,
          "type": "bool"
        },
        "position": {
          "id": 1,
          "type": "Vector"
        }
      }
    },
    "RotateCopyEndPrevewParam": {
      "fields": {
        "state": {
          "id": 1,
          "type": "RotateCopyEndState"
        }
      }
    },
    "RowsColsLayoutGrid": {
      "fields": {
        "paints": {
          "id": 6,
          "options": {
            "proto3_optional": true
          },
          "type": "Paint"
        },
        "sectionSize": {
          "id": 5,
          "type": "int32"
        },
        "offset": {
          "id": 4,
          "type": "int32"
        },
        "gutterSize": {
          "id": 3,
          "type": "int32"
        },
        "visible": {
          "id": 7,
          "type": "bool"
        },
        "count": {
          "id": 2,
          "type": "int32"
        },
        "align": {
          "id": 1,
          "type": "RowsColsLayoutGridAlign"
        }
      },
      "oneofs": {
        "_paints": {
          "oneof": [
            "paints"
          ]
        }
      }
    },
    "ScaleToolPanel": {
      "fields": {
        "editorModeJustChanged": {
          "id": 4,
          "type": "bool"
        },
        "startHeight": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "startWidth": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "origin": {
          "id": 1,
          "type": "LayoutOrigin"
        }
      },
      "oneofs": {
        "_startHeight": {
          "oneof": [
            "startHeight"
          ]
        },
        "_startWidth": {
          "oneof": [
            "startWidth"
          ]
        }
      }
    },
    "ScaleViewportByWheelCommandParam": {
      "fields": {
        "metaKey": {
          "id": 7,
          "type": "bool"
        },
        "caculatedDeltaY": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "mousePoint": {
          "id": 3,
          "type": "Vector"
        },
        "ctrlKey": {
          "id": 6,
          "type": "bool"
        },
        "deltaY": {
          "id": 1,
          "type": "float"
        },
        "devicePixelRatio": {
          "id": 2,
          "type": "int32"
        },
        "isOverChrome118": {
          "id": 5,
          "type": "bool"
        }
      },
      "oneofs": {
        "_caculatedDeltaY": {
          "oneof": [
            "caculatedDeltaY"
          ]
        }
      }
    },
    "SceneGraphQueriesProto": {
      "fields": {
        "sceneGraphQueries": {
          "id": 1,
          "rule": "repeated",
          "type": "SceneGraphQuery"
        }
      }
    },
    "SceneGraphQuery": {
      "fields": {
        "depth": {
          "id": 2,
          "type": "uint32"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "ScrollBehaviorState": {
      "fields": {
        "scrollPosition": {
          "id": 3,
          "type": "ScrollPositionAttr"
        },
        "overflow": {
          "id": 2,
          "type": "ScrollOverflowAttr"
        },
        "show": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "ScrollOverflowAttr": {
      "fields": {
        "isValid": {
          "id": 5,
          "type": "bool"
        },
        "isActive": {
          "id": 4,
          "type": "bool"
        },
        "isShow": {
          "id": 3,
          "type": "bool"
        },
        "isMixed": {
          "id": 2,
          "type": "bool"
        },
        "direction": {
          "id": 1,
          "type": "ScrollDirection"
        }
      }
    },
    "ScrollPositionAttr": {
      "fields": {
        "isActive": {
          "id": 5,
          "type": "bool"
        },
        "isFixedActive": {
          "id": 4,
          "type": "bool"
        },
        "isShow": {
          "id": 3,
          "type": "bool"
        },
        "isMixed": {
          "id": 2,
          "type": "bool"
        },
        "scrollBehavior": {
          "id": 1,
          "type": "ScrollBehavior"
        }
      }
    },
    "ScrollToTargetNodeList": {
      "fields": {
        "disabledTargets": {
          "id": 3,
          "rule": "repeated",
          "type": "PrototypeInteractionTargetNode"
        },
        "middleTargetsList": {
          "id": 2,
          "rule": "repeated",
          "type": "PrototypeInteractionTargetNodeList"
        },
        "topTargets": {
          "id": 1,
          "rule": "repeated",
          "type": "PrototypeInteractionTargetNode"
        }
      }
    },
    "SearchReplaceInstanceParam": {
      "fields": {
        "componentPickerTrigger": {
          "id": 3,
          "type": "ComponentPickerTrigger"
        },
        "localOnly": {
          "id": 2,
          "type": "bool"
        },
        "value": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "SearchedSortedListItem": {
      "fields": {
        "originalIndex": {
          "id": 2,
          "type": "int32"
        },
        "value": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "SegmentInfo": {
      "fields": {
        "style": {
          "id": 4,
          "type": "BasicTextStyle"
        },
        "start": {
          "id": 2,
          "type": "int32"
        },
        "end": {
          "id": 3,
          "type": "int32"
        },
        "ownerId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_ownerId": {
          "oneof": [
            "ownerId"
          ]
        }
      }
    },
    "SegmentInfoList": {
      "fields": {
        "segments": {
          "id": 1,
          "rule": "repeated",
          "type": "SegmentInfo"
        }
      }
    },
    "SelectOuterInstanceState": {
      "fields": {
        "componentOrSetName": {
          "id": 2,
          "type": "string"
        },
        "outerMostInstanceIds": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "SelectedEffectsState": {
      "fields": {
        "count": {
          "id": 2,
          "type": "uint32"
        },
        "indexes": {
          "id": 1,
          "rule": "repeated",
          "type": "int32"
        }
      }
    },
    "SelectedFillsState": {
      "fields": {
        "count": {
          "id": 2,
          "type": "uint32"
        },
        "indexes": {
          "id": 1,
          "rule": "repeated",
          "type": "int32"
        }
      }
    },
    "SelectedLayoutGridsState": {
      "fields": {
        "count": {
          "id": 2,
          "type": "uint32"
        },
        "indexes": {
          "id": 1,
          "rule": "repeated",
          "type": "int32"
        }
      }
    },
    "SelectedNodeInfoForAI": {
      "fields": {
        "node": {
          "id": 2,
          "type": "string"
        },
        "name": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "SelectedReverseIndex": {
      "fields": {
        "layoutGridsIndex": {
          "id": 4,
          "rule": "repeated",
          "type": "int32"
        },
        "effectsIndex": {
          "id": 3,
          "rule": "repeated",
          "type": "int32"
        },
        "strokesIndex": {
          "id": 2,
          "rule": "repeated",
          "type": "int32"
        },
        "fillsIndex": {
          "id": 1,
          "rule": "repeated",
          "type": "int32"
        }
      }
    },
    "SelectedReversedIndexesParam": {
      "fields": {
        "onlyUpdate": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "indexes": {
          "id": 2,
          "rule": "repeated",
          "type": "int32"
        },
        "type": {
          "id": 1,
          "type": "SelectedReversedIndexesType"
        }
      },
      "oneofs": {
        "_onlyUpdate": {
          "oneof": [
            "onlyUpdate"
          ]
        }
      }
    },
    "SelectedStrokesState": {
      "fields": {
        "count": {
          "id": 2,
          "type": "uint32"
        },
        "indexes": {
          "id": 1,
          "rule": "repeated",
          "type": "int32"
        }
      }
    },
    "SelectionAttachMinMaxWidthHeightFloatVarParams": {
      "fields": {
        "params": {
          "id": 1,
          "rule": "repeated",
          "type": "AttachMinMaxWidthHeightFloatVar"
        }
      }
    },
    "SelectionAttachWidthHeightFloatVarParams": {
      "fields": {
        "heightVarId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "widthVarId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_heightVarId": {
          "oneof": [
            "heightVarId"
          ]
        },
        "_widthVarId": {
          "oneof": [
            "widthVarId"
          ]
        }
      }
    },
    "SelectionColorScope": {
      "fields": {
        "hasText": {
          "id": 3,
          "type": "bool"
        },
        "hasShape": {
          "id": 2,
          "type": "bool"
        },
        "hasFrame": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "SelectionColorsDetachVariableParam": {
      "fields": {
        "dstColor": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "Paint"
        },
        "srcColor": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "Paint"
        }
      },
      "oneofs": {
        "_dstColor": {
          "oneof": [
            "dstColor"
          ]
        },
        "_srcColor": {
          "oneof": [
            "srcColor"
          ]
        }
      }
    },
    "SelectionColorsInspectColorParam": {
      "fields": {
        "color": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "Paint"
        }
      },
      "oneofs": {
        "_color": {
          "oneof": [
            "color"
          ]
        }
      }
    },
    "SelectionColorsReplaceColorWithStyleParam": {
      "fields": {
        "dstStyleId": {
          "id": 2,
          "type": "string"
        },
        "srcColor": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "Paint"
        }
      },
      "oneofs": {
        "_srcColor": {
          "oneof": [
            "srcColor"
          ]
        }
      }
    },
    "SelectionColorsReplaceParam": {
      "fields": {
        "dstColor": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "Paint"
        },
        "srcColor": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "Paint"
        }
      },
      "oneofs": {
        "_dstColor": {
          "oneof": [
            "dstColor"
          ]
        },
        "_srcColor": {
          "oneof": [
            "srcColor"
          ]
        }
      }
    },
    "SelectionColorsReplaceStyleParam": {
      "fields": {
        "dstStyleId": {
          "id": 2,
          "type": "string"
        },
        "srcStyleId": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "SelectionColorsReplaceStyleWithColorParam": {
      "fields": {
        "dstColor": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "Paint"
        },
        "srcStyleId": {
          "id": 1,
          "type": "string"
        }
      },
      "oneofs": {
        "_dstColor": {
          "oneof": [
            "dstColor"
          ]
        }
      }
    },
    "SelectionDetachMinMaxWidthHeightFloatVarParams": {
      "fields": {
        "params": {
          "id": 1,
          "rule": "repeated",
          "type": "DetachMinMaxWidthHeightFloatVar"
        }
      }
    },
    "SelectionDetachWidthHeightFloatVarParams": {
      "fields": {
        "height": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "width": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        }
      },
      "oneofs": {
        "_height": {
          "oneof": [
            "height"
          ]
        },
        "_width": {
          "oneof": [
            "width"
          ]
        }
      }
    },
    "SelectionFillState": {
      "fields": {
        "variables": {
          "id": 7,
          "rule": "repeated",
          "type": "SingleVariable"
        },
        "failIds": {
          "id": 6,
          "rule": "repeated",
          "type": "string"
        },
        "passIds": {
          "id": 5,
          "rule": "repeated",
          "type": "string"
        },
        "fillStyleId": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "paintStyleNode": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "VPaintStyleNode"
        },
        "fills": {
          "id": 2,
          "rule": "repeated",
          "type": "Paint"
        },
        "type": {
          "id": 1,
          "type": "SelectionFillType"
        }
      },
      "oneofs": {
        "_fillStyleId": {
          "oneof": [
            "fillStyleId"
          ]
        },
        "_paintStyleNode": {
          "oneof": [
            "paintStyleNode"
          ]
        }
      }
    },
    "SelectionHTML": {
      "fields": {
        "html": {
          "id": 2,
          "type": "string"
        },
        "selectionIds": {
          "id": 3,
          "rule": "repeated",
          "type": "string"
        },
        "rootId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_rootId": {
          "oneof": [
            "rootId"
          ]
        }
      }
    },
    "SelectionNodeName": {
      "fields": {
        "isText": {
          "id": 3,
          "type": "bool"
        },
        "name": {
          "id": 2,
          "type": "string"
        },
        "id": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "SelectionStrokeAlignState": {
      "fields": {
        "value": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "StrokeAlign"
        },
        "type": {
          "id": 1,
          "type": "SelectionStrokeType"
        }
      },
      "oneofs": {
        "_value": {
          "oneof": [
            "value"
          ]
        }
      }
    },
    "SelectionStrokeDashCapState": {
      "fields": {
        "value": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "StrokeCap"
        },
        "type": {
          "id": 1,
          "type": "SelectionStrokeType"
        }
      },
      "oneofs": {
        "_value": {
          "oneof": [
            "value"
          ]
        }
      }
    },
    "SelectionStrokeDashGapState": {
      "fields": {
        "connected": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "value": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "type": {
          "id": 1,
          "type": "SelectionStrokeType"
        }
      },
      "oneofs": {
        "_connected": {
          "oneof": [
            "connected"
          ]
        },
        "_value": {
          "oneof": [
            "value"
          ]
        }
      }
    },
    "SelectionStrokeDashLenState": {
      "fields": {
        "value": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "type": {
          "id": 1,
          "type": "SelectionStrokeType"
        }
      },
      "oneofs": {
        "_value": {
          "oneof": [
            "value"
          ]
        }
      }
    },
    "SelectionStrokeEndPointStyleState": {
      "fields": {
        "useAllIcon": {
          "id": 4,
          "type": "bool"
        },
        "endPointCount": {
          "id": 5,
          "type": "int32"
        },
        "pairState": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "SelectionStrokePointPairCapState"
        },
        "singleState": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "SelectionStrokePointCapState"
        },
        "showType": {
          "id": 1,
          "type": "SelectionStrokeEndPointShowType"
        }
      },
      "oneofs": {
        "_pairState": {
          "oneof": [
            "pairState"
          ]
        },
        "_singleState": {
          "oneof": [
            "singleState"
          ]
        }
      }
    },
    "SelectionStrokeIndependentStatus": {
      "fields": {
        "leftExist": {
          "id": 4,
          "type": "bool"
        },
        "bottomExist": {
          "id": 3,
          "type": "bool"
        },
        "rightExist": {
          "id": 2,
          "type": "bool"
        },
        "topExist": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "SelectionStrokeIndependentTypeState": {
      "fields": {
        "existStatus": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "SelectionStrokeIndependentStatus"
        },
        "value": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "IndependentStrokeType"
        },
        "type": {
          "id": 1,
          "type": "SelectionStrokeType"
        }
      },
      "oneofs": {
        "_existStatus": {
          "oneof": [
            "existStatus"
          ]
        },
        "_value": {
          "oneof": [
            "value"
          ]
        }
      }
    },
    "SelectionStrokeIndependentWeightState": {
      "fields": {
        "value": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "type": {
          "id": 1,
          "type": "SelectionStrokeType"
        }
      },
      "oneofs": {
        "_value": {
          "oneof": [
            "value"
          ]
        }
      }
    },
    "SelectionStrokeJoinState": {
      "fields": {
        "value": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "StrokeJoin"
        },
        "type": {
          "id": 1,
          "type": "SelectionStrokeType"
        }
      },
      "oneofs": {
        "_value": {
          "oneof": [
            "value"
          ]
        }
      }
    },
    "SelectionStrokeMiterAngleState": {
      "fields": {
        "value": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "type": {
          "id": 1,
          "type": "SelectionStrokeType"
        }
      },
      "oneofs": {
        "_value": {
          "oneof": [
            "value"
          ]
        }
      }
    },
    "SelectionStrokeModeState": {
      "fields": {
        "value": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "StrokeModeType"
        },
        "type": {
          "id": 1,
          "type": "SelectionStrokeType"
        }
      },
      "oneofs": {
        "_value": {
          "oneof": [
            "value"
          ]
        }
      }
    },
    "SelectionStrokeNodeInfoState": {
      "fields": {
        "nodeWithStrokeIds": {
          "id": 2,
          "rule": "repeated",
          "type": "string"
        },
        "passIds": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "SelectionStrokePaintState": {
      "fields": {
        "variables": {
          "id": 5,
          "rule": "repeated",
          "type": "SingleVariable"
        },
        "paintStyleNode": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "VPaintStyleNode"
        },
        "strokeStyleId": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "strokes": {
          "id": 2,
          "rule": "repeated",
          "type": "Paint"
        },
        "type": {
          "id": 1,
          "type": "SelectionStrokeType"
        }
      },
      "oneofs": {
        "_paintStyleNode": {
          "oneof": [
            "paintStyleNode"
          ]
        },
        "_strokeStyleId": {
          "oneof": [
            "strokeStyleId"
          ]
        }
      }
    },
    "SelectionStrokePointCapState": {
      "fields": {
        "capValue": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "StrokeCap"
        },
        "type": {
          "id": 1,
          "type": "SelectionStrokeType"
        }
      },
      "oneofs": {
        "_capValue": {
          "oneof": [
            "capValue"
          ]
        }
      }
    },
    "SelectionStrokePointPairCapState": {
      "fields": {
        "endState": {
          "id": 2,
          "type": "SelectionStrokePointCapState"
        },
        "startState": {
          "id": 1,
          "type": "SelectionStrokePointCapState"
        }
      }
    },
    "SelectionStrokeWeightState": {
      "fields": {
        "leftBorderState": {
          "id": 6,
          "type": "SelectionStrokeIndependentWeightState"
        },
        "topBorderState": {
          "id": 3,
          "type": "SelectionStrokeIndependentWeightState"
        },
        "allBorderState": {
          "id": 2,
          "type": "SelectionStrokeIndependentWeightState"
        },
        "bottomBorderState": {
          "id": 5,
          "type": "SelectionStrokeIndependentWeightState"
        },
        "rightBorderState": {
          "id": 4,
          "type": "SelectionStrokeIndependentWeightState"
        },
        "typeState": {
          "id": 1,
          "type": "SelectionStrokeIndependentTypeState"
        }
      }
    },
    "SelectionStrokeWeightStateWrapper": {
      "fields": {
        "bottomBorderStateValue": {
          "id": 5,
          "type": "string"
        },
        "leftBorderStateValue": {
          "id": 6,
          "type": "string"
        },
        "rightBorderStateValue": {
          "id": 4,
          "type": "string"
        },
        "topBorderStateValue": {
          "id": 3,
          "type": "string"
        },
        "allBorderStateValue": {
          "id": 2,
          "type": "string"
        },
        "weightState": {
          "id": 1,
          "type": "SelectionStrokeWeightState"
        }
      }
    },
    "SelectionTextLineState": {
      "fields": {
        "lineType": {
          "id": 2,
          "type": "LineType"
        },
        "type": {
          "id": 1,
          "type": "SelectionTextLineType"
        }
      }
    },
    "SendCooperationProto": {
      "fields": {
        "payload": {
          "id": 1,
          "type": "bytes"
        }
      }
    },
    "SerBitmap": {
      "fields": {
        "bytes": {
          "id": 3,
          "type": "SerBytes"
        },
        "height": {
          "id": 2,
          "type": "int32"
        },
        "width": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "SerBytes": {
      "fields": {
        "value": {
          "id": 1,
          "type": "bytes"
        }
      }
    },
    "SerializedBatchUpgradePublishMixinsVersionV3Param": {
      "fields": {
        "items": {
          "id": 1,
          "rule": "repeated",
          "type": "SerializedUpgradePublishMixinVersionV3Param"
        }
      }
    },
    "SerializedExportedDocument": {
      "fields": {
        "blobs": {
          "id": 7,
          "keyType": "int64",
          "type": "BlobData"
        },
        "pastePageId": {
          "id": 6,
          "type": "string"
        },
        "pasteExtraRefNodes": {
          "id": 5,
          "rule": "repeated",
          "type": "SerializedExportedNode"
        },
        "isCut": {
          "id": 9,
          "type": "bool"
        },
        "schemaVersion": {
          "id": 8,
          "type": "int32"
        },
        "pasteIsInsideContainer": {
          "id": 4,
          "type": "bool"
        },
        "pasteContainerBounds": {
          "id": 3,
          "type": "SkRect"
        },
        "docId": {
          "id": 2,
          "type": "string"
        },
        "allNodes": {
          "id": 1,
          "rule": "repeated",
          "type": "SerializedExportedNode"
        }
      }
    },
    "SerializedExportedNode": {
      "fields": {
        "nodeProps": {
          "id": 1,
          "type": "PartialNode"
        }
      }
    },
    "SerializedLibraryContentVO": {
      "fields": {
        "movedStyleOldKey2NewKey": {
          "id": 10,
          "keyType": "string",
          "type": "string"
        },
        "movedComponentOldKey2NewKey": {
          "id": 8,
          "keyType": "string",
          "type": "string"
        },
        "variableSets": {
          "id": 6,
          "rule": "repeated",
          "type": "VariableSetGetVO"
        },
        "styles": {
          "id": 3,
          "rule": "repeated",
          "type": "StyleGetVO"
        },
        "movedComponentSetOldKey2NewKey": {
          "id": 9,
          "keyType": "string",
          "type": "string"
        },
        "componentSets": {
          "id": 4,
          "rule": "repeated",
          "type": "ComponentGetVO"
        },
        "variables": {
          "id": 7,
          "rule": "repeated",
          "type": "VariableGetVO"
        },
        "components": {
          "id": 2,
          "rule": "repeated",
          "type": "ComponentGetVO"
        },
        "variants": {
          "id": 5,
          "rule": "repeated",
          "type": "ComponentGetVO"
        },
        "library": {
          "id": 1,
          "type": "LibraryVO"
        }
      }
    },
    "SerializedUpgradePublishMixinVersionV2Param": {
      "fields": {
        "upgradeType": {
          "id": 1,
          "type": "UpgradePublishMixinVersionType"
        },
        "exportedDocument": {
          "id": 5,
          "type": "SerializedExportedDocument"
        },
        "variantPairs": {
          "id": 6,
          "rule": "repeated",
          "type": "LibraryVariantPair"
        },
        "toCreateNodeId": {
          "id": 4,
          "type": "string"
        },
        "nodeKey": {
          "id": 2,
          "type": "string"
        },
        "contentHash": {
          "id": 3,
          "type": "string"
        }
      }
    },
    "SerializedUpgradePublishMixinVersionV3Param": {
      "fields": {
        "upgradeType": {
          "id": 1,
          "type": "UpgradePublishMixinVersionType"
        },
        "remoteDocId": {
          "id": 5,
          "type": "string"
        },
        "variantPairs": {
          "id": 7,
          "rule": "repeated",
          "type": "LibraryVariantPair"
        },
        "toCreateNodeId": {
          "id": 4,
          "type": "string"
        },
        "nodeKey": {
          "id": 2,
          "type": "string"
        },
        "rawExportedDocument": {
          "id": 6,
          "type": "bytes"
        },
        "contentHash": {
          "id": 3,
          "type": "string"
        }
      }
    },
    "SetCurrentPageCommandParam": {
      "fields": {
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "SetIndependentStrokeTypeForSelectionCommandParams": {
      "fields": {
        "type": {
          "id": 1,
          "type": "IndependentStrokeType"
        }
      }
    },
    "SetSelectedAutoLayoutWHParam": {
      "fields": {
        "value": {
          "id": 2,
          "type": "AutoLayoutWHValue"
        },
        "direction": {
          "id": 1,
          "type": "StackMode"
        }
      }
    },
    "SetSelectionMaybeJumpPageParam": {
      "fields": {
        "leftShift": {
          "id": 2,
          "type": "bool"
        },
        "selection": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "SetStrokeCapForSelectionCommandParams": {
      "fields": {
        "endPointCap": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "StrokeCap"
        },
        "startPointCap": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "StrokeCap"
        },
        "allPointCap": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "StrokeCap"
        }
      },
      "oneofs": {
        "_endPointCap": {
          "oneof": [
            "endPointCap"
          ]
        },
        "_startPointCap": {
          "oneof": [
            "startPointCap"
          ]
        },
        "_allPointCap": {
          "oneof": [
            "allPointCap"
          ]
        }
      }
    },
    "SetStrokeWeightForSelectionCommandParams": {
      "fields": {
        "borderAllWeight": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "borderRightWeight": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "borderLeftWeight": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "borderBottomWeight": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "borderTopWeight": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        }
      },
      "oneofs": {
        "_borderAllWeight": {
          "oneof": [
            "borderAllWeight"
          ]
        },
        "_borderRightWeight": {
          "oneof": [
            "borderRightWeight"
          ]
        },
        "_borderLeftWeight": {
          "oneof": [
            "borderLeftWeight"
          ]
        },
        "_borderBottomWeight": {
          "oneof": [
            "borderBottomWeight"
          ]
        },
        "_borderTopWeight": {
          "oneof": [
            "borderTopWeight"
          ]
        }
      }
    },
    "SharedPluginDataFilter": {
      "fields": {
        "keys": {
          "id": 2,
          "rule": "repeated",
          "type": "string"
        },
        "nameSpace": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "ShowTooltipParams": {
      "fields": {
        "y": {
          "id": 2,
          "type": "float"
        },
        "x": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "SidebarPanel": {
      "fields": {
        "type": {
          "id": 2,
          "type": "SidebarPanelType"
        },
        "isSelected": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "SingleEnum": {
      "fields": {
        "nodeType": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "NodeType"
        }
      },
      "oneofs": {
        "_nodeType": {
          "oneof": [
            "nodeType"
          ]
        }
      }
    },
    "SingleFloat": {
      "fields": {
        "val1": {
          "id": 2,
          "type": "float"
        }
      }
    },
    "SingleFloatVariable": {
      "fields": {
        "floatValue": {
          "id": 8,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "isLocal": {
          "id": 7,
          "type": "bool"
        },
        "id": {
          "id": 1,
          "type": "string"
        },
        "isSoftDeleted": {
          "id": 5,
          "type": "bool"
        },
        "key": {
          "id": 6,
          "type": "string"
        },
        "description": {
          "id": 4,
          "type": "string"
        },
        "setId": {
          "id": 3,
          "type": "string"
        },
        "name": {
          "id": 2,
          "type": "string"
        }
      },
      "oneofs": {
        "_floatValue": {
          "oneof": [
            "floatValue"
          ]
        }
      }
    },
    "SingleInt32": {
      "fields": {
        "val1": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "SingleSelection": {
      "fields": {
        "cropImagePaintInfo": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "CropImagePaintInfo"
        },
        "length": {
          "id": 4,
          "type": "int32"
        },
        "name": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "type": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "NodeType"
        },
        "id": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_cropImagePaintInfo": {
          "oneof": [
            "cropImagePaintInfo"
          ]
        },
        "_name": {
          "oneof": [
            "name"
          ]
        },
        "_type": {
          "oneof": [
            "type"
          ]
        },
        "_id": {
          "oneof": [
            "id"
          ]
        }
      }
    },
    "SingleSelectionInfo": {
      "fields": {
        "editInfo": {
          "id": 6,
          "type": "EditInfo"
        },
        "isPurple": {
          "id": 4,
          "type": "bool"
        },
        "typeName": {
          "id": 5,
          "type": "string"
        },
        "nodeIcon": {
          "id": 3,
          "type": "NodeIconData"
        },
        "nodeName": {
          "id": 2,
          "type": "string"
        },
        "nodeId": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "SingleUint32": {
      "fields": {
        "val1": {
          "id": 1,
          "type": "uint32"
        }
      }
    },
    "SingleVariable": {
      "fields": {
        "dataType": {
          "id": 6,
          "type": "VariableResolvedDataType"
        },
        "id": {
          "id": 1,
          "type": "string"
        },
        "isSoftDeleted": {
          "id": 5,
          "type": "bool"
        },
        "description": {
          "id": 4,
          "type": "string"
        },
        "setId": {
          "id": 3,
          "type": "string"
        },
        "name": {
          "id": 2,
          "type": "string"
        }
      }
    },
    "SizeHoverMenuItemParam": {
      "fields": {
        "value": {
          "id": 1,
          "type": "SizeHoverMenuItem"
        }
      }
    },
    "SkRect": {
      "fields": {
        "bottom": {
          "id": 4,
          "type": "float"
        },
        "right": {
          "id": 3,
          "type": "float"
        },
        "top": {
          "id": 2,
          "type": "float"
        },
        "left": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "Spacing": {
      "fields": {
        "isMixed": {
          "id": 2,
          "type": "bool"
        },
        "value": {
          "id": 1,
          "rule": "repeated",
          "type": "float"
        }
      }
    },
    "StackCounterAlignContentPropValue": {
      "fields": {
        "isMixed": {
          "id": 2,
          "type": "bool"
        },
        "value": {
          "id": 1,
          "type": "StackCounterAlignContent"
        }
      }
    },
    "StackJustifyPropValue": {
      "fields": {
        "isMixed": {
          "id": 2,
          "type": "bool"
        },
        "value": {
          "id": 1,
          "type": "StackJustify"
        }
      }
    },
    "StartEditingGradientCommandParam": {
      "fields": {
        "positions": {
          "id": 2,
          "rule": "repeated",
          "type": "PaintPosition"
        },
        "selectedGradientColorStopIndex": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "gradientNodeId": {
          "id": 1,
          "type": "string"
        }
      },
      "oneofs": {
        "_selectedGradientColorStopIndex": {
          "oneof": [
            "selectedGradientColorStopIndex"
          ]
        }
      }
    },
    "StartEditingImageCommandParam": {
      "fields": {
        "notModifyPopupState": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "editingStyleId": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "index": {
          "id": 3,
          "type": "int32"
        },
        "type": {
          "id": 2,
          "type": "EditingPaintType"
        },
        "nodeIds": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      },
      "oneofs": {
        "_notModifyPopupState": {
          "oneof": [
            "notModifyPopupState"
          ]
        },
        "_editingStyleId": {
          "oneof": [
            "editingStyleId"
          ]
        }
      }
    },
    "StartViewportAnimationParam": {
      "fields": {
        "endZoom": {
          "id": 8,
          "type": "float"
        },
        "endY": {
          "id": 7,
          "type": "float"
        },
        "height": {
          "id": 2,
          "type": "float"
        },
        "startX": {
          "id": 3,
          "type": "float"
        },
        "startZoom": {
          "id": 5,
          "type": "float"
        },
        "delay": {
          "id": 10,
          "type": "int32"
        },
        "endX": {
          "id": 6,
          "type": "float"
        },
        "startY": {
          "id": 4,
          "type": "float"
        },
        "duration": {
          "id": 9,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "width": {
          "id": 1,
          "type": "float"
        }
      },
      "oneofs": {
        "_duration": {
          "oneof": [
            "duration"
          ]
        }
      }
    },
    "StringLookup": {
      "fields": {
        "str": {
          "id": 2,
          "type": "string"
        },
        "ptr": {
          "id": 1,
          "type": "uint32"
        }
      }
    },
    "StyleGetVO": {
      "fields": {
        "layoutGridStyleIconType": {
          "id": 17,
          "type": "int32"
        },
        "fontSize": {
          "id": 14,
          "type": "float"
        },
        "effectStyleIconType": {
          "id": 13,
          "type": "int32"
        },
        "removed": {
          "id": 9,
          "type": "bool"
        },
        "documentId": {
          "id": 8,
          "type": "string"
        },
        "nodeDataPath": {
          "id": 11,
          "type": "string"
        },
        "thumbnailDataPath": {
          "id": 10,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "lineHeightValue": {
          "id": 15,
          "type": "float"
        },
        "libraryId": {
          "id": 7,
          "type": "string"
        },
        "sortPosition": {
          "id": 12,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "nodeId": {
          "id": 6,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "description": {
          "id": 5,
          "type": "string"
        },
        "name": {
          "id": 4,
          "type": "string"
        },
        "contentHash": {
          "id": 3,
          "type": "string"
        },
        "type": {
          "id": 2,
          "type": "int32"
        },
        "lineHeightUnit": {
          "id": 16,
          "type": "float"
        },
        "id": {
          "id": 1,
          "type": "string"
        }
      },
      "oneofs": {
        "_thumbnailDataPath": {
          "oneof": [
            "thumbnailDataPath"
          ]
        },
        "_sortPosition": {
          "oneof": [
            "sortPosition"
          ]
        },
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "StyleLibraryDataUpdateVO": {
      "fields": {
        "layoutGridStyleIconType": {
          "id": 12,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "lineHeightUnit": {
          "id": 11,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "effectStyleIconType": {
          "id": 8,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "nodeId": {
          "id": 1,
          "type": "string"
        },
        "description": {
          "id": 4,
          "type": "string"
        },
        "sortPosition": {
          "id": 6,
          "type": "string"
        },
        "nodeDataV2": {
          "id": 7,
          "type": "string"
        },
        "lineHeightValue": {
          "id": 10,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "fontSize": {
          "id": 9,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "contentHash": {
          "id": 5,
          "type": "string"
        },
        "name": {
          "id": 3,
          "type": "string"
        },
        "type": {
          "id": 2,
          "type": "int32"
        }
      },
      "oneofs": {
        "_layoutGridStyleIconType": {
          "oneof": [
            "layoutGridStyleIconType"
          ]
        },
        "_lineHeightUnit": {
          "oneof": [
            "lineHeightUnit"
          ]
        },
        "_effectStyleIconType": {
          "oneof": [
            "effectStyleIconType"
          ]
        },
        "_lineHeightValue": {
          "oneof": [
            "lineHeightValue"
          ]
        },
        "_fontSize": {
          "oneof": [
            "fontSize"
          ]
        }
      }
    },
    "StyleOverride": {
      "fields": {
        "hasFillPaints": {
          "id": 5,
          "type": "bool"
        },
        "hasStyleIdForFill": {
          "id": 4,
          "type": "bool"
        },
        "fillPaints": {
          "id": 3,
          "rule": "repeated",
          "type": "Paint"
        },
        "styleIdForFill": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "styleId": {
          "id": 1,
          "type": "uint32"
        }
      },
      "oneofs": {
        "_styleIdForFill": {
          "oneof": [
            "styleIdForFill"
          ]
        }
      }
    },
    "StyleUpdateVO": {
      "fields": {
        "layoutGridStyleIconType": {
          "id": 15,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "fontSize": {
          "id": 12,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "thumbnailData": {
          "id": 10,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "contentHash": {
          "id": 7,
          "type": "string"
        },
        "sortPosition": {
          "id": 8,
          "type": "string"
        },
        "nodeId": {
          "id": 3,
          "type": "string"
        },
        "description": {
          "id": 6,
          "type": "string"
        },
        "lineHeightValue": {
          "id": 13,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "libraryId": {
          "id": 2,
          "type": "string"
        },
        "nodeDataV2": {
          "id": 9,
          "type": "string"
        },
        "type": {
          "id": 4,
          "type": "int32"
        },
        "name": {
          "id": 5,
          "type": "string"
        },
        "effectStyleIconType": {
          "id": 11,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "lineHeightUnit": {
          "id": 14,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "id": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_layoutGridStyleIconType": {
          "oneof": [
            "layoutGridStyleIconType"
          ]
        },
        "_fontSize": {
          "oneof": [
            "fontSize"
          ]
        },
        "_thumbnailData": {
          "oneof": [
            "thumbnailData"
          ]
        },
        "_lineHeightValue": {
          "oneof": [
            "lineHeightValue"
          ]
        },
        "_effectStyleIconType": {
          "oneof": [
            "effectStyleIconType"
          ]
        },
        "_lineHeightUnit": {
          "oneof": [
            "lineHeightUnit"
          ]
        },
        "_id": {
          "oneof": [
            "id"
          ]
        }
      }
    },
    "StyledTextSegment": {
      "fields": {
        "fontVariations": {
          "id": 14,
          "rule": "repeated",
          "type": "FontVariation"
        },
        "fillStyleId": {
          "id": 12,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "fills": {
          "id": 10,
          "rule": "repeated",
          "type": "Paint"
        },
        "textStyleEditedProps": {
          "id": 13,
          "rule": "repeated",
          "type": "NodeProps"
        },
        "lineHeight": {
          "id": 8,
          "type": "LineHeight"
        },
        "textStyleId": {
          "id": 11,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "letterSpacing": {
          "id": 9,
          "type": "LetterSpacing"
        },
        "detachOpticalSizeFromFontSize": {
          "id": 16,
          "type": "bool"
        },
        "fontName": {
          "id": 5,
          "type": "FontName"
        },
        "textDecoration": {
          "id": 6,
          "type": "TextDecoration"
        },
        "end": {
          "id": 3,
          "type": "int32"
        },
        "textCase": {
          "id": 7,
          "type": "TextCase"
        },
        "fontSize": {
          "id": 4,
          "type": "float"
        },
        "hyperlink": {
          "id": 15,
          "type": "Hyperlink"
        },
        "start": {
          "id": 2,
          "type": "int32"
        },
        "characters": {
          "id": 1,
          "type": "string"
        }
      },
      "oneofs": {
        "_fillStyleId": {
          "oneof": [
            "fillStyleId"
          ]
        },
        "_textStyleId": {
          "oneof": [
            "textStyleId"
          ]
        }
      }
    },
    "StyledTextSegmentExt": {
      "fields": {
        "listOptions": {
          "id": 14,
          "type": "TextListOptions"
        },
        "fillStyleId": {
          "id": 13,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "fills": {
          "id": 11,
          "rule": "repeated",
          "type": "Paint"
        },
        "textStyleId": {
          "id": 12,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "lineHeight": {
          "id": 9,
          "type": "LineHeight"
        },
        "letterSpacing": {
          "id": 10,
          "type": "LetterSpacing"
        },
        "fontName": {
          "id": 5,
          "type": "FontName"
        },
        "textDecoration": {
          "id": 7,
          "type": "TextDecoration"
        },
        "fontWeight": {
          "id": 6,
          "type": "int32"
        },
        "end": {
          "id": 3,
          "type": "int32"
        },
        "textCase": {
          "id": 8,
          "type": "TextCase"
        },
        "fontSize": {
          "id": 4,
          "type": "float"
        },
        "hyperlink": {
          "id": 16,
          "options": {
            "proto3_optional": true
          },
          "type": "HyperlinkTarget"
        },
        "indentation": {
          "id": 15,
          "type": "int32"
        },
        "start": {
          "id": 2,
          "type": "int32"
        },
        "characters": {
          "id": 1,
          "type": "string"
        }
      },
      "oneofs": {
        "_fillStyleId": {
          "oneof": [
            "fillStyleId"
          ]
        },
        "_textStyleId": {
          "oneof": [
            "textStyleId"
          ]
        },
        "_hyperlink": {
          "oneof": [
            "hyperlink"
          ]
        }
      }
    },
    "SubEditorModeState": {
      "fields": {
        "subEditorMode": {
          "id": 1,
          "type": "SubEditorMode"
        }
      }
    },
    "SvgData": {
      "fields": {
        "data": {
          "id": 2,
          "type": "string"
        },
        "parentNodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_parentNodeId": {
          "oneof": [
            "parentNodeId"
          ]
        }
      }
    },
    "SymbolData": {
      "fields": {
        "mainComponentId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "symbolOverrides": {
          "id": 1,
          "keyType": "string",
          "type": "SynergyNode"
        }
      },
      "oneofs": {
        "_mainComponentId": {
          "oneof": [
            "mainComponentId"
          ]
        }
      }
    },
    "SymbolOverrides": {
      "fields": {
        "fields": {
          "id": 2,
          "rule": "repeated",
          "type": "PluginDocumentChangeProperty"
        },
        "id": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_id": {
          "oneof": [
            "id"
          ]
        }
      }
    },
    "SyncUserPropertiesProto": {
      "fields": {
        "properties": {
          "id": 3,
          "type": "UserPropertiesProto"
        },
        "sessionId": {
          "id": 2,
          "type": "int32"
        },
        "userId": {
          "id": 1,
          "type": "int64"
        }
      }
    },
    "SynergyBatchOperation": {
      "fields": {
        "changedNode2Hash": {
          "id": 9,
          "keyType": "string",
          "type": "uint64"
        },
        "currentEditorContext": {
          "id": 10,
          "type": "CurrentEditorContext"
        },
        "createTime": {
          "id": 8,
          "type": "int64"
        },
        "sourceType": {
          "id": 7,
          "type": "SynergyBatchOperationSourceType"
        },
        "schemaVersion": {
          "id": 6,
          "type": "int32"
        },
        "blobs": {
          "id": 5,
          "keyType": "int64",
          "type": "BlobData"
        },
        "proposedDocHash": {
          "id": 4,
          "type": "uint64"
        },
        "index": {
          "id": 3,
          "type": "SynergyOperationIndex"
        },
        "operations": {
          "id": 2,
          "rule": "repeated",
          "type": "SynergyOperation"
        },
        "id": {
          "id": 1,
          "type": "SynergyOperationId"
        }
      }
    },
    "SynergyBatchOperationAck": {
      "fields": {
        "operations": {
          "id": 2,
          "rule": "repeated",
          "type": "SynergyOperation"
        },
        "id": {
          "id": 1,
          "type": "SynergyOperationId"
        }
      }
    },
    "SynergyDocument": {
      "fields": {
        "schemaVersion": {
          "id": 9,
          "type": "int32"
        },
        "blobs": {
          "id": 8,
          "keyType": "int64",
          "type": "BlobData"
        },
        "nodes": {
          "id": 1,
          "rule": "repeated",
          "type": "SynergyNode"
        }
      }
    },
    "SynergyNode": {
      "fields": {
        "fields": {
          "id": 9,
          "rule": "repeated",
          "type": "uint32"
        },
        "partialNode": {
          "id": 7,
          "type": "PartialNode"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "SynergyOperation": {
      "fields": {
        "removedFields": {
          "id": 8,
          "rule": "repeated",
          "type": "uint32"
        },
        "wasmCallRecords": {
          "id": 9,
          "rule": "repeated",
          "type": "WasmCall"
        },
        "changedFields": {
          "id": 7,
          "rule": "repeated",
          "type": "uint32"
        },
        "partialNode": {
          "id": 4,
          "type": "PartialNode"
        },
        "nodeId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "type": {
          "id": 1,
          "type": "SynergyOperationType"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "SynergyOperationId": {
      "fields": {
        "requestId": {
          "id": 3,
          "type": "int32"
        },
        "sid": {
          "id": 2,
          "type": "int32"
        },
        "userId": {
          "id": 1,
          "type": "int64"
        }
      }
    },
    "SynergyOperationIndex": {
      "fields": {
        "epoch": {
          "id": 3,
          "type": "int32"
        },
        "maxTxId": {
          "id": 2,
          "type": "int64"
        },
        "minTxId": {
          "id": 1,
          "type": "int64"
        }
      }
    },
    "TestCountingObject": {
      "fields": {
        "value": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "TestRectWrapper": {
      "fields": {
        "myRect": {
          "id": 1,
          "type": "SkRect"
        }
      }
    },
    "TextBaseLine": {
      "fields": {
        "second": {
          "id": 2,
          "type": "Vector"
        },
        "first": {
          "id": 1,
          "type": "Vector"
        }
      }
    },
    "TextBound": {
      "fields": {
        "y": {
          "id": 4,
          "type": "float"
        },
        "x": {
          "id": 3,
          "type": "float"
        },
        "height": {
          "id": 2,
          "type": "float"
        },
        "width": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "TextCursor": {
      "fields": {
        "cursorY": {
          "id": 4,
          "type": "float"
        },
        "cursorX": {
          "id": 3,
          "type": "float"
        },
        "nodeId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "cursorHeight": {
          "id": 5,
          "type": "float"
        },
        "show": {
          "id": 1,
          "type": "bool"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "TextData": {
      "fields": {
        "lines": {
          "id": 4,
          "rule": "repeated",
          "type": "TextLineData"
        },
        "styleOverrideTable": {
          "id": 3,
          "keyType": "int32",
          "type": "SynergyNode"
        },
        "characterStyleIDs": {
          "id": 2,
          "rule": "repeated",
          "type": "int32"
        },
        "characters": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "TextInitWithTextCommandParam": {
      "fields": {
        "text": {
          "id": 2,
          "type": "string"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "TextLayoutData": {
      "fields": {
        "truncatedHeight": {
          "id": 11,
          "type": "float"
        },
        "fake": {
          "id": 9,
          "type": "bool"
        },
        "hyperlinkBoxes": {
          "id": 8,
          "rule": "repeated",
          "type": "HyperlinkBox"
        },
        "decorations": {
          "id": 6,
          "rule": "repeated",
          "type": "Decoration"
        },
        "glyphInfos": {
          "id": 7,
          "rule": "repeated",
          "type": "GlyphInfoLine"
        },
        "baselines": {
          "id": 3,
          "rule": "repeated",
          "type": "Baseline"
        },
        "glyphs": {
          "id": 5,
          "rule": "repeated",
          "type": "Glyph"
        },
        "layoutSize": {
          "id": 4,
          "type": "Vector"
        },
        "fontMetaData": {
          "id": 2,
          "rule": "repeated",
          "type": "FontMetaData"
        },
        "truncationStartIndex": {
          "id": 10,
          "type": "int32"
        },
        "layoutVersion": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "TextLineData": {
      "fields": {
        "listStartOffset": {
          "id": 7,
          "type": "int32"
        },
        "consistencyStyleId": {
          "id": 6,
          "type": "int32"
        },
        "isFirstLineOfList": {
          "id": 8,
          "type": "bool"
        },
        "downgradeStyleId": {
          "id": 5,
          "type": "int32"
        },
        "directionality": {
          "id": 3,
          "type": "Directionality"
        },
        "directionalityIntent": {
          "id": 4,
          "type": "DirectionalityIntent"
        },
        "indentationLevel": {
          "id": 2,
          "type": "int32"
        },
        "lineType": {
          "id": 1,
          "type": "LineType"
        }
      }
    },
    "TextLineDataExt": {
      "fields": {
        "listStartOffset": {
          "id": 7,
          "type": "int32"
        },
        "consistencyStyleId": {
          "id": 6,
          "type": "int32"
        },
        "isFirstLineOfList": {
          "id": 8,
          "type": "bool"
        },
        "downgradeStyleId": {
          "id": 5,
          "type": "int32"
        },
        "end": {
          "id": 10,
          "type": "int32"
        },
        "directionality": {
          "id": 3,
          "type": "Directionality"
        },
        "directionalityIntent": {
          "id": 4,
          "type": "DirectionalityIntent"
        },
        "start": {
          "id": 9,
          "type": "int32"
        },
        "indentationLevel": {
          "id": 2,
          "type": "int32"
        },
        "lineType": {
          "id": 1,
          "type": "LineType"
        }
      }
    },
    "TextListOptions": {
      "fields": {
        "type": {
          "id": 1,
          "type": "TextListOptionsType"
        }
      }
    },
    "TextMatch": {
      "fields": {
        "end": {
          "id": 2,
          "type": "uint64"
        },
        "start": {
          "id": 1,
          "type": "uint64"
        }
      }
    },
    "TextNodeContentMatch": {
      "fields": {
        "matchIndex": {
          "id": 2,
          "type": "uint64"
        },
        "textContentMatch": {
          "id": 1,
          "type": "TextMatch"
        }
      }
    },
    "TextNodeStyle": {
      "fields": {
        "textStyleId": {
          "id": 7,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "textAlignVertical": {
          "id": 3,
          "type": "TextAlignVertical"
        },
        "textAutoResize": {
          "id": 4,
          "type": "TextAutoResize"
        },
        "textAlignHorizontal": {
          "id": 2,
          "type": "TextAlignHorizontal"
        },
        "paragraphIndent": {
          "id": 5,
          "type": "float"
        },
        "paragraphSpacing": {
          "id": 6,
          "type": "float"
        },
        "style": {
          "id": 1,
          "type": "TextStyle"
        }
      },
      "oneofs": {
        "_textStyleId": {
          "oneof": [
            "textStyleId"
          ]
        }
      }
    },
    "TextPage": {
      "fields": {
        "clusters": {
          "id": 1,
          "rule": "repeated",
          "type": "Cluster"
        }
      }
    },
    "TextPosInfo": {
      "fields": {
        "lineIndex": {
          "id": 3,
          "type": "uint64"
        },
        "posType": {
          "id": 2,
          "type": "PosType"
        },
        "pos": {
          "id": 1,
          "type": "uint64"
        }
      }
    },
    "TextRange": {
      "fields": {
        "memoryPosInfo": {
          "id": 7,
          "options": {
            "proto3_optional": true
          },
          "type": "TextPosInfo"
        },
        "endPosType": {
          "id": 5,
          "type": "PosType"
        },
        "startPosType": {
          "id": 4,
          "type": "PosType"
        },
        "end": {
          "id": 3,
          "type": "uint64"
        },
        "start": {
          "id": 2,
          "type": "uint64"
        },
        "basePos": {
          "id": 6,
          "options": {
            "proto3_optional": true
          },
          "type": "uint64"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_memoryPosInfo": {
          "oneof": [
            "memoryPosInfo"
          ]
        },
        "_basePos": {
          "oneof": [
            "basePos"
          ]
        },
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "TextSearch": {
      "fields": {
        "id": {
          "id": 2,
          "type": "string"
        },
        "value": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "TextStyle": {
      "fields": {
        "hyperlink": {
          "id": 15,
          "type": "Hyperlink"
        },
        "fillStyleId": {
          "id": 12,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "fontVariations": {
          "id": 14,
          "rule": "repeated",
          "type": "FontVariation"
        },
        "letterSpacing": {
          "id": 5,
          "type": "LetterSpacing"
        },
        "textStyleId": {
          "id": 11,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "lineHeight": {
          "id": 4,
          "type": "LineHeight"
        },
        "textStyleEditedProps": {
          "id": 13,
          "rule": "repeated",
          "type": "NodeProps"
        },
        "fills": {
          "id": 7,
          "rule": "repeated",
          "type": "Paint"
        },
        "detachOpticalSizeFromFontSize": {
          "id": 16,
          "type": "bool"
        },
        "fontName": {
          "id": 1,
          "type": "FontName"
        },
        "textDecoration": {
          "id": 3,
          "type": "TextDecoration"
        },
        "textCase": {
          "id": 6,
          "type": "TextCase"
        },
        "fontSize": {
          "id": 2,
          "type": "float"
        }
      },
      "oneofs": {
        "_fillStyleId": {
          "oneof": [
            "fillStyleId"
          ]
        },
        "_textStyleId": {
          "oneof": [
            "textStyleId"
          ]
        }
      }
    },
    "TextStyleDisplayData": {
      "fields": {
        "description": {
          "id": 2,
          "type": "string"
        },
        "name": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "TextStyleMatchInfo": {
      "fields": {
        "matchedStyleNodeId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "group": {
          "id": 1,
          "rule": "repeated",
          "type": "SegmentInfo"
        }
      },
      "oneofs": {
        "_matchedStyleNodeId": {
          "oneof": [
            "matchedStyleNodeId"
          ]
        }
      }
    },
    "TextStyleWrapper": {
      "fields": {
        "lineHeight": {
          "id": 3,
          "type": "string"
        },
        "letterSpacing": {
          "id": 4,
          "type": "string"
        },
        "fontSize": {
          "id": 2,
          "type": "string"
        },
        "inner": {
          "id": 1,
          "type": "TextStyle"
        }
      }
    },
    "TextUpdateStyledTextSegmentsCommandParam": {
      "fields": {
        "skipSaveDefaultTextStyle": {
          "id": 3,
          "type": "bool"
        },
        "styledTextSegments": {
          "id": 2,
          "rule": "repeated",
          "type": "StyledTextSegment"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "Thumbnail": {
      "fields": {
        "thumbnailPaint": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "Paint"
        },
        "isThumbnailIdIncurrentPage": {
          "id": 4,
          "type": "bool"
        },
        "height": {
          "id": 3,
          "type": "float"
        },
        "width": {
          "id": 2,
          "type": "float"
        },
        "id": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_thumbnailPaint": {
          "oneof": [
            "thumbnailPaint"
          ]
        },
        "_id": {
          "oneof": [
            "id"
          ]
        }
      }
    },
    "ThumbnailData": {
      "fields": {
        "height": {
          "id": 7,
          "type": "float"
        },
        "width": {
          "id": 6,
          "type": "float"
        },
        "type": {
          "id": 5,
          "type": "NodeType"
        },
        "variableIconType": {
          "id": 10,
          "options": {
            "proto3_optional": true
          },
          "type": "VariableResolvedDataType"
        },
        "contentHash": {
          "id": 3,
          "type": "string"
        },
        "layoutGridStyleIconType": {
          "id": 9,
          "options": {
            "proto3_optional": true
          },
          "type": "LayoutGridStyleIconType"
        },
        "url": {
          "id": 4,
          "type": "string"
        },
        "effectStyleIconType": {
          "id": 8,
          "options": {
            "proto3_optional": true
          },
          "type": "EffectStyleIconType"
        },
        "id": {
          "id": 2,
          "type": "string"
        },
        "isLocal": {
          "id": 1,
          "type": "bool"
        }
      },
      "oneofs": {
        "_variableIconType": {
          "oneof": [
            "variableIconType"
          ]
        },
        "_layoutGridStyleIconType": {
          "oneof": [
            "layoutGridStyleIconType"
          ]
        },
        "_effectStyleIconType": {
          "oneof": [
            "effectStyleIconType"
          ]
        }
      }
    },
    "TidyUpCommandParam": {
      "fields": {
        "type": {
          "id": 1,
          "type": "TidyType"
        }
      }
    },
    "ToReplaceComponentData": {
      "fields": {
        "name": {
          "id": 5,
          "type": "string"
        },
        "thumbNailData": {
          "id": 4,
          "type": "bytes"
        },
        "protoData": {
          "id": 3,
          "type": "bytes"
        },
        "type": {
          "id": 2,
          "type": "ToReplaceComponentType"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "ToUploadImageItem": {
      "fields": {
        "name": {
          "id": 3,
          "type": "string"
        },
        "data": {
          "id": 2,
          "type": "string"
        },
        "id": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "ToggleDocumentStateCommandParam": {
      "fields": {
        "key": {
          "id": 1,
          "type": "ToggleDocumentStateCommandEnum"
        }
      }
    },
    "ToggleMotiffScopeNodeExpandedCommandParam": {
      "fields": {
        "expanded": {
          "id": 2,
          "type": "bool"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "TopLevelFixedFrame": {
      "fields": {
        "fixedNode": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "VLayerPanelNode"
        },
        "yPosition": {
          "id": 1,
          "type": "int32"
        }
      },
      "oneofs": {
        "_fixedNode": {
          "oneof": [
            "fixedNode"
          ]
        }
      }
    },
    "Transform": {
      "fields": {
        "skewY": {
          "id": 4,
          "type": "float"
        },
        "translateY": {
          "id": 6,
          "type": "float"
        },
        "translateX": {
          "id": 5,
          "type": "float"
        },
        "skewX": {
          "id": 3,
          "type": "float"
        },
        "scaleY": {
          "id": 2,
          "type": "float"
        },
        "scaleX": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "TransitionDurationPropValue": {
      "fields": {
        "show": {
          "id": 3,
          "type": "bool"
        },
        "valueType": {
          "id": 2,
          "type": "InteractionActionPropValueType"
        },
        "value": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "TransitionShouldSmartAnimate": {
      "fields": {
        "show": {
          "id": 3,
          "type": "bool"
        },
        "valueType": {
          "id": 2,
          "type": "InteractionActionPropValueType"
        },
        "value": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "TransitionTypeProtoValue": {
      "fields": {
        "show": {
          "id": 3,
          "type": "bool"
        },
        "valueType": {
          "id": 2,
          "type": "InteractionActionPropValueType"
        },
        "value": {
          "id": 1,
          "type": "TransitionType"
        }
      }
    },
    "TranslateCameraPosition": {
      "fields": {
        "translateY": {
          "id": 2,
          "type": "float"
        },
        "translateX": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "TranslateWorldPosition": {
      "fields": {
        "translateY": {
          "id": 2,
          "type": "float"
        },
        "translateX": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "URLSearchParams": {
      "fields": {
        "production": {
          "id": 8,
          "type": "bool"
        },
        "editorType": {
          "id": 7,
          "type": "string"
        },
        "commentId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "mainComponent": {
          "id": 3,
          "type": "bool"
        },
        "isAdvanceRender": {
          "id": 5,
          "type": "bool"
        },
        "historyVersionId": {
          "id": 6,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "aiRecognize": {
          "id": 4,
          "type": "bool"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_commentId": {
          "oneof": [
            "commentId"
          ]
        },
        "_historyVersionId": {
          "oneof": [
            "historyVersionId"
          ]
        },
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "UndoRedoCommandParam": {
      "fields": {
        "altKey": {
          "id": 4,
          "type": "bool"
        },
        "shiftKey": {
          "id": 3,
          "type": "bool"
        },
        "ctrlKey": {
          "id": 2,
          "type": "bool"
        },
        "metaKey": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "UndoStatus": {
      "fields": {
        "redoable": {
          "id": 2,
          "type": "bool"
        },
        "undoable": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "UpdateAllExportSettingsByIdsCommandParam": {
      "fields": {
        "settings": {
          "id": 2,
          "rule": "repeated",
          "type": "ExportSettings"
        },
        "idRefs": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "UpdateAttrPanelStyleEditorStateParam": {
      "fields": {
        "isCreate": {
          "id": 5,
          "type": "bool"
        },
        "openFromModule": {
          "id": 3,
          "type": "EditorStateFromModule"
        },
        "openFromNodeId": {
          "id": 4,
          "rule": "repeated",
          "type": "string"
        },
        "selectReverseIndex": {
          "id": 2,
          "rule": "repeated",
          "type": "int32"
        },
        "editingStyleId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_editingStyleId": {
          "oneof": [
            "editingStyleId"
          ]
        }
      }
    },
    "UpdateAutoLayoutHoverItemParam": {
      "fields": {
        "value": {
          "id": 1,
          "type": "AutoLayoutHoverMenuItem"
        }
      }
    },
    "UpdateCanvasSearchResultItemHeightParam": {
      "fields": {
        "itemHeight": {
          "id": 2,
          "type": "float"
        },
        "itemIndex": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "UpdateDevModeCodeConfigCommandParam": {
      "fields": {
        "config": {
          "id": 1,
          "type": "DevModeInspectCodeConfig"
        }
      }
    },
    "UpdateDevModeInspectCodeTypeCommandParam": {
      "fields": {
        "codeType": {
          "id": 1,
          "type": "DevModeInspectCodeType"
        }
      }
    },
    "UpdateDevModeInspectTypeCommandParam": {
      "fields": {
        "inspectType": {
          "id": 1,
          "type": "DevModeInspectType"
        }
      }
    },
    "UpdateEffectStyleNodeDescriptionParam": {
      "fields": {
        "description": {
          "id": 2,
          "type": "string"
        },
        "styleId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_styleId": {
          "oneof": [
            "styleId"
          ]
        }
      }
    },
    "UpdateEffectStyleNodeEffectsParam": {
      "fields": {
        "effects": {
          "id": 2,
          "rule": "repeated",
          "type": "Effect"
        },
        "styleId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_styleId": {
          "oneof": [
            "styleId"
          ]
        }
      }
    },
    "UpdateEffectStyleNodeNameParam": {
      "fields": {
        "name": {
          "id": 2,
          "type": "string"
        },
        "styleId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_styleId": {
          "oneof": [
            "styleId"
          ]
        }
      }
    },
    "UpdateEffectsParam": {
      "fields": {
        "clearSelected": {
          "id": 2,
          "type": "bool"
        },
        "effects": {
          "id": 1,
          "rule": "repeated",
          "type": "Effect"
        }
      }
    },
    "UpdateExportSettingsToSelectionCommandParam": {
      "fields": {
        "setting": {
          "id": 2,
          "type": "ExportSettings"
        },
        "index": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "UpdateHoveredGuideCommandParam": {
      "fields": {
        "worldY": {
          "id": 2,
          "type": "float"
        },
        "worldX": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "UpdateInspectCodeTypeCommandParam": {
      "fields": {
        "codeType": {
          "id": 1,
          "type": "CodeType"
        }
      }
    },
    "UpdateLayoutGridStyleLayoutGridsCommandParam": {
      "fields": {
        "layoutGrids": {
          "id": 2,
          "rule": "repeated",
          "type": "LayoutGrid"
        },
        "styleNode": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_styleNode": {
          "oneof": [
            "styleNode"
          ]
        }
      }
    },
    "UpdateLayoutGridStyleNodeDescriptionParam": {
      "fields": {
        "description": {
          "id": 2,
          "type": "string"
        },
        "styleNode": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_styleNode": {
          "oneof": [
            "styleNode"
          ]
        }
      }
    },
    "UpdateLayoutGridStyleNodeNameParam": {
      "fields": {
        "name": {
          "id": 2,
          "type": "string"
        },
        "styleNode": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_styleNode": {
          "oneof": [
            "styleNode"
          ]
        }
      }
    },
    "UpdateLayoutGridsCommandParam": {
      "fields": {
        "clearSelected": {
          "id": 2,
          "type": "bool"
        },
        "layoutGrids": {
          "id": 1,
          "rule": "repeated",
          "type": "LayoutGrid"
        }
      }
    },
    "UpdateLibraryContentVOCommandParam": {
      "fields": {
        "vo": {
          "id": 1,
          "keyType": "string",
          "type": "SerializedLibraryContentVO"
        }
      }
    },
    "UpdateLocalStyleFolderNameCommandParam": {
      "fields": {
        "dstFolderName": {
          "id": 2,
          "type": "string"
        },
        "srcFolderName": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "UpdateLocalStyleNameCommandParam": {
      "fields": {
        "name": {
          "id": 2,
          "type": "string"
        },
        "styleId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_styleId": {
          "oneof": [
            "styleId"
          ]
        }
      }
    },
    "UpdateMainComponentCommandSerializedParam": {
      "fields": {
        "newMainComponentId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_newMainComponentId": {
          "oneof": [
            "newMainComponentId"
          ]
        },
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "UpdateMinSizeAndMaxSizeParam": {
      "fields": {
        "value": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "type": {
          "id": 2,
          "type": "UpdateMinSizeAndMaxSizeCommandEnum"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_value": {
          "oneof": [
            "value"
          ]
        },
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "UpdateMotiffScopeSettingsCommandParam": {
      "fields": {
        "updatedFollowSelection": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "updatedShowMainPanel": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        }
      },
      "oneofs": {
        "_updatedFollowSelection": {
          "oneof": [
            "updatedFollowSelection"
          ]
        },
        "_updatedShowMainPanel": {
          "oneof": [
            "updatedShowMainPanel"
          ]
        }
      }
    },
    "UpdateOthersRemoteLibraryChangesParam": {
      "fields": {
        "key2OldLibraryId": {
          "id": 2,
          "keyType": "string",
          "type": "string"
        },
        "operations": {
          "id": 1,
          "rule": "repeated",
          "type": "LibraryOperation"
        }
      }
    },
    "UpdatePageNameCommandParam": {
      "fields": {
        "name": {
          "id": 2,
          "type": "string"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "UpdatePaintStyleNodeDescriptionParam": {
      "fields": {
        "description": {
          "id": 2,
          "type": "string"
        },
        "styleId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_styleId": {
          "oneof": [
            "styleId"
          ]
        }
      }
    },
    "UpdatePaintStyleNodeNameParam": {
      "fields": {
        "name": {
          "id": 2,
          "type": "string"
        },
        "styleId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_styleId": {
          "oneof": [
            "styleId"
          ]
        }
      }
    },
    "UpdatePaintStyleNodePaintsParam": {
      "fields": {
        "paints": {
          "id": 2,
          "rule": "repeated",
          "type": "Paint"
        },
        "styleId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_styleId": {
          "oneof": [
            "styleId"
          ]
        }
      }
    },
    "UpdatePopupStateParam": {
      "fields": {
        "value": {
          "id": 1,
          "type": "PopupState"
        }
      }
    },
    "UpdatePreviewHashParam": {
      "fields": {
        "previewHash": {
          "id": 2,
          "type": "string"
        },
        "imageHash": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "UpdatePublishHiddenCommandParam": {
      "fields": {
        "publishHidden": {
          "id": 2,
          "type": "bool"
        },
        "id": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_id": {
          "oneof": [
            "id"
          ]
        }
      }
    },
    "UpdateSelectedGradientColorStopIndexParam": {
      "fields": {
        "index": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "UpdateSelectedGuideCommandParam": {
      "fields": {
        "shouldDelete": {
          "id": 3,
          "type": "bool"
        },
        "deltaY": {
          "id": 2,
          "type": "float"
        },
        "deltaX": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "UpdateSelectedVectorNetworkRegionParam": {
      "fields": {
        "loopIndex": {
          "id": 3,
          "type": "int32"
        },
        "regionIndex": {
          "id": 2,
          "type": "int32"
        },
        "type": {
          "id": 1,
          "type": "UpdateVectorNetworkRegionType"
        }
      }
    },
    "UpdateSelectionBaseAttributeParam": {
      "fields": {
        "r": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "h": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "w": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "y": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "x": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        }
      },
      "oneofs": {
        "_r": {
          "oneof": [
            "r"
          ]
        },
        "_h": {
          "oneof": [
            "h"
          ]
        },
        "_w": {
          "oneof": [
            "w"
          ]
        },
        "_y": {
          "oneof": [
            "y"
          ]
        },
        "_x": {
          "oneof": [
            "x"
          ]
        }
      }
    },
    "UpdateSelectionConstraintsCommandParam": {
      "fields": {
        "vertical": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "ConstraintType"
        },
        "horizontal": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "ConstraintType"
        }
      },
      "oneofs": {
        "_vertical": {
          "oneof": [
            "vertical"
          ]
        },
        "_horizontal": {
          "oneof": [
            "horizontal"
          ]
        }
      }
    },
    "UpdateSelectionCornerRadiusParam": {
      "fields": {
        "value": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "UpdateSelectionCornerSmoothingParam": {
      "fields": {
        "value": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "UpdateSelectionFrameDirectionCommandParam": {
      "fields": {
        "direction": {
          "id": 1,
          "type": "UpdateSelectionFrameDirectionCommandEnum"
        }
      }
    },
    "UpdateSelectionIndependentCornerRadiusParam": {
      "fields": {
        "value": {
          "id": 2,
          "type": "float"
        },
        "type": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "UpdateSelectionInnerRadiusCommandParam": {
      "fields": {
        "nodeType": {
          "id": 2,
          "type": "NodeType"
        },
        "value": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "UpdateSelectionLayoutGridStyleNodeParam": {
      "fields": {
        "node": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_node": {
          "oneof": [
            "node"
          ]
        }
      }
    },
    "UpdateSelectionPaintParam": {
      "fields": {
        "skipEmpty": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "paints": {
          "id": 1,
          "rule": "repeated",
          "type": "Paint"
        }
      },
      "oneofs": {
        "_skipEmpty": {
          "oneof": [
            "skipEmpty"
          ]
        }
      }
    },
    "UpdateSelectionStrokeAlignCommandParam": {
      "fields": {
        "strokeAlign": {
          "id": 1,
          "type": "StrokeAlign"
        }
      }
    },
    "UpdateSelectionStrokeDashCapCommandParam": {
      "fields": {
        "strokeDashCap": {
          "id": 1,
          "type": "StrokeCap"
        }
      }
    },
    "UpdateSelectionStrokeDashGapCommandParam": {
      "fields": {
        "strokeDashGap": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "UpdateSelectionStrokeDashLenCommandParam": {
      "fields": {
        "strokeDashLen": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "UpdateSelectionStrokeJoinCommandParam": {
      "fields": {
        "strokeJoin": {
          "id": 1,
          "type": "StrokeJoin"
        }
      }
    },
    "UpdateSelectionStrokeMiterAngleCommandParam": {
      "fields": {
        "strokeMiterAngleDegree": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "UpdateSelectionStrokeModeCommandParam": {
      "fields": {
        "strokeMode": {
          "id": 1,
          "type": "StrokeModeType"
        }
      }
    },
    "UpdateSelectionStrokePaintsParam": {
      "fields": {
        "skipEmpty": {
          "id": 2,
          "type": "bool"
        },
        "paints": {
          "id": 1,
          "rule": "repeated",
          "type": "Paint"
        }
      }
    },
    "UpdateSelectionTextNodeAlignHorizontalParam": {
      "fields": {
        "textAlignHorizontal": {
          "id": 1,
          "type": "TextAlignHorizontal"
        }
      }
    },
    "UpdateSelectionTextNodeAlignVerticalParam": {
      "fields": {
        "textAlignVertical": {
          "id": 1,
          "type": "TextAlignVertical"
        }
      }
    },
    "UpdateSelectionTextNodeAutoResizeParam": {
      "fields": {
        "textAutoResize": {
          "id": 1,
          "type": "TextAutoResize"
        }
      }
    },
    "UpdateSelectionTextNodeFontNameParam": {
      "fields": {
        "isMissFontCustomStyle": {
          "id": 3,
          "type": "bool"
        },
        "customAxesStyle": {
          "id": 2,
          "type": "VariantFontCustomAxesStyle"
        },
        "isPreset": {
          "id": 4,
          "type": "bool"
        },
        "fontName": {
          "id": 1,
          "type": "FontName"
        }
      }
    },
    "UpdateSelectionTextNodeFontSizeParam": {
      "fields": {
        "fontSize": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "UpdateSelectionTextNodeHyperlinkParam": {
      "fields": {
        "originUrl": {
          "id": 2,
          "type": "string"
        },
        "hyperlink": {
          "id": 1,
          "type": "Hyperlink"
        }
      }
    },
    "UpdateSelectionTextNodeLetterSpacingParam": {
      "fields": {
        "letterSpacing": {
          "id": 1,
          "type": "LetterSpacing"
        }
      }
    },
    "UpdateSelectionTextNodeLineHeightParam": {
      "fields": {
        "lineHeight": {
          "id": 1,
          "type": "LineHeight"
        }
      }
    },
    "UpdateSelectionTextNodeMaxLinesParam": {
      "fields": {
        "value": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "UpdateSelectionTextNodeParagraphIndentParam": {
      "fields": {
        "paragraphIndent": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "UpdateSelectionTextNodeParagraphSpacingParam": {
      "fields": {
        "paragraphSpacing": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "UpdateSelectionTextNodeTextCaseParam": {
      "fields": {
        "textCase": {
          "id": 1,
          "type": "TextCase"
        }
      }
    },
    "UpdateSelectionTextNodeTextDecorationParam": {
      "fields": {
        "textDecoration": {
          "id": 1,
          "type": "TextDecoration"
        }
      }
    },
    "UpdateSelectionTextNodeTextTruncationParam": {
      "fields": {
        "textTruncation": {
          "id": 1,
          "type": "TextTruncation"
        }
      }
    },
    "UpdateSelectionTextStyleIdParam": {
      "fields": {
        "textStyleId": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "UpdateTextAutoResizeParam": {
      "fields": {
        "textAutoResize": {
          "id": 2,
          "type": "TextAutoResize"
        },
        "nodeId": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "UpdateTextSettingTabKeyCommandParam": {
      "fields": {
        "tabKey": {
          "id": 1,
          "type": "TextSettingTabKey"
        }
      }
    },
    "UpdateTextStyleNodeDescriptionParam": {
      "fields": {
        "description": {
          "id": 2,
          "type": "string"
        },
        "styleId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_styleId": {
          "oneof": [
            "styleId"
          ]
        }
      }
    },
    "UpdateTextStyleNodeDetachOpsizeFromFontSizeParam": {
      "fields": {
        "detachOpticalSizeFromFontSize": {
          "id": 2,
          "type": "bool"
        },
        "styleId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_styleId": {
          "oneof": [
            "styleId"
          ]
        }
      }
    },
    "UpdateTextStyleNodeFontFamilyParam": {
      "fields": {
        "fontName": {
          "id": 2,
          "type": "FontName"
        },
        "styleId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_styleId": {
          "oneof": [
            "styleId"
          ]
        }
      }
    },
    "UpdateTextStyleNodeFontNameParam": {
      "fields": {
        "fontName": {
          "id": 2,
          "type": "FontName"
        },
        "isMissFontCustomStyle": {
          "id": 4,
          "type": "bool"
        },
        "customAxesStyle": {
          "id": 3,
          "type": "VariantFontCustomAxesStyle"
        },
        "styleId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_styleId": {
          "oneof": [
            "styleId"
          ]
        }
      }
    },
    "UpdateTextStyleNodeFontSizeParam": {
      "fields": {
        "fontSize": {
          "id": 2,
          "type": "float"
        },
        "styleId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_styleId": {
          "oneof": [
            "styleId"
          ]
        }
      }
    },
    "UpdateTextStyleNodeFontVariationParam": {
      "fields": {
        "fontVariation": {
          "id": 2,
          "type": "FontVariation"
        },
        "styleId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_styleId": {
          "oneof": [
            "styleId"
          ]
        }
      }
    },
    "UpdateTextStyleNodeLetterSpacingParam": {
      "fields": {
        "letterSpacing": {
          "id": 2,
          "type": "LetterSpacing"
        },
        "styleId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_styleId": {
          "oneof": [
            "styleId"
          ]
        }
      }
    },
    "UpdateTextStyleNodeLineHeightParam": {
      "fields": {
        "lineHeight": {
          "id": 2,
          "type": "LineHeight"
        },
        "styleId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_styleId": {
          "oneof": [
            "styleId"
          ]
        }
      }
    },
    "UpdateTextStyleNodeNameParam": {
      "fields": {
        "name": {
          "id": 2,
          "type": "string"
        },
        "styleId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_styleId": {
          "oneof": [
            "styleId"
          ]
        }
      }
    },
    "UpdateTextStyleNodeParagraphSpacingParam": {
      "fields": {
        "paragraphSpacing": {
          "id": 2,
          "type": "float"
        },
        "styleId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_styleId": {
          "oneof": [
            "styleId"
          ]
        }
      }
    },
    "UpdateTextStyleNodeTextDecorationParam": {
      "fields": {
        "textDecoration": {
          "id": 2,
          "type": "TextDecoration"
        },
        "styleId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_styleId": {
          "oneof": [
            "styleId"
          ]
        }
      }
    },
    "UpdateTriggerFilterWithinMotiffScopeCommandParam": {
      "fields": {
        "type": {
          "id": 3,
          "type": "QueryCategory"
        },
        "selectedId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "names": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      },
      "oneofs": {
        "_selectedId": {
          "oneof": [
            "selectedId"
          ]
        }
      }
    },
    "UpdateUsableFontInfoParam": {
      "fields": {
        "fontInfos": {
          "id": 1,
          "rule": "repeated",
          "type": "FontInfo"
        }
      }
    },
    "UpdateUserPropertiesProto": {
      "fields": {
        "properties": {
          "id": 1,
          "type": "UserPropertiesProto"
        }
      }
    },
    "UpdateVFParams": {
      "fields": {
        "fontVariation": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "FontVariation"
        },
        "detachOpticalSizeFromFontSize": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "textStyle": {
          "id": 1,
          "type": "TextStyle"
        }
      },
      "oneofs": {
        "_fontVariation": {
          "oneof": [
            "fontVariation"
          ]
        },
        "_detachOpticalSizeFromFontSize": {
          "oneof": [
            "detachOpticalSizeFromFontSize"
          ]
        }
      }
    },
    "UpgradePublishMixinVersionKeyItem": {
      "fields": {
        "id": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "key": {
          "id": 1,
          "type": "string"
        }
      },
      "oneofs": {
        "_id": {
          "oneof": [
            "id"
          ]
        }
      }
    },
    "UploadProgress": {
      "fields": {
        "total": {
          "id": 3,
          "type": "int32"
        },
        "ack": {
          "id": 2,
          "type": "int32"
        },
        "value": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "UploadedImageItem": {
      "fields": {
        "info": {
          "id": 2,
          "type": "ImageInfo"
        },
        "id": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "UserConfig2": {
      "fields": {
        "colorProfile": {
          "id": 15,
          "options": {
            "proto3_optional": true
          },
          "type": "DocumentColorProfile"
        },
        "bigNudgeAmount": {
          "id": 11,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "prototypingVisibility": {
          "id": 12,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "devModeInspectCodeType": {
          "id": 8,
          "options": {
            "proto3_optional": true
          },
          "type": "DevModeInspectCodeType"
        },
        "fontStyle": {
          "id": 16,
          "options": {
            "proto3_optional": true
          },
          "type": "TextNodeStyle"
        },
        "devModeInspectType": {
          "id": 7,
          "options": {
            "proto3_optional": true
          },
          "type": "DevModeInspectType"
        },
        "smallNudgeAmount": {
          "id": 10,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "snapToPixel": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "enableLayoutGrid": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "showSlice": {
          "id": 14,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "inspectCodeType": {
          "id": 6,
          "options": {
            "proto3_optional": true
          },
          "type": "CodeType"
        },
        "lastAILayoutNodeId": {
          "id": 13,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "showSidebar": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "showRuler": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "inverseZoomDirection": {
          "id": 9,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "enablePixelGrid": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        }
      },
      "oneofs": {
        "_colorProfile": {
          "oneof": [
            "colorProfile"
          ]
        },
        "_bigNudgeAmount": {
          "oneof": [
            "bigNudgeAmount"
          ]
        },
        "_prototypingVisibility": {
          "oneof": [
            "prototypingVisibility"
          ]
        },
        "_devModeInspectCodeType": {
          "oneof": [
            "devModeInspectCodeType"
          ]
        },
        "_fontStyle": {
          "oneof": [
            "fontStyle"
          ]
        },
        "_devModeInspectType": {
          "oneof": [
            "devModeInspectType"
          ]
        },
        "_smallNudgeAmount": {
          "oneof": [
            "smallNudgeAmount"
          ]
        },
        "_snapToPixel": {
          "oneof": [
            "snapToPixel"
          ]
        },
        "_enableLayoutGrid": {
          "oneof": [
            "enableLayoutGrid"
          ]
        },
        "_showSlice": {
          "oneof": [
            "showSlice"
          ]
        },
        "_inspectCodeType": {
          "oneof": [
            "inspectCodeType"
          ]
        },
        "_lastAILayoutNodeId": {
          "oneof": [
            "lastAILayoutNodeId"
          ]
        },
        "_showSidebar": {
          "oneof": [
            "showSidebar"
          ]
        },
        "_showRuler": {
          "oneof": [
            "showRuler"
          ]
        },
        "_inverseZoomDirection": {
          "oneof": [
            "inverseZoomDirection"
          ]
        },
        "_enablePixelGrid": {
          "oneof": [
            "enablePixelGrid"
          ]
        }
      }
    },
    "UserEnterProto": {
      "fields": {
        "userProfile": {
          "id": 3,
          "type": "UserProfileProto"
        },
        "entryTime": {
          "id": 4,
          "type": "int64"
        },
        "sessionId": {
          "id": 2,
          "type": "int32"
        },
        "userId": {
          "id": 1,
          "type": "int64"
        }
      }
    },
    "UserProfileProto": {
      "fields": {
        "colorIndex": {
          "id": 3,
          "type": "int32"
        },
        "avatarBackgroundColor": {
          "id": 4,
          "type": "string"
        },
        "avatarId": {
          "id": 2,
          "type": "string"
        },
        "nickname": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "UserPropertiesProto": {
      "fields": {
        "selection": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "CoactorSelectionProto"
        },
        "mouse": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "CoactorMouseProto"
        },
        "observing": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "CoactorObservingProto"
        },
        "viewport": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "CoactorViewportProto"
        }
      },
      "oneofs": {
        "_selection": {
          "oneof": [
            "selection"
          ]
        },
        "_mouse": {
          "oneof": [
            "mouse"
          ]
        },
        "_observing": {
          "oneof": [
            "observing"
          ]
        },
        "_viewport": {
          "oneof": [
            "viewport"
          ]
        }
      }
    },
    "UserQuitProto": {
      "fields": {
        "sessionId": {
          "id": 2,
          "type": "int32"
        },
        "userId": {
          "id": 1,
          "type": "int64"
        }
      }
    },
    "UserSynergyRoleChangeProto": {
      "fields": {
        "role": {
          "id": 3,
          "type": "UserSynergyRole"
        },
        "sessionId": {
          "id": 2,
          "type": "int32"
        },
        "userId": {
          "id": 1,
          "type": "int64"
        }
      }
    },
    "VActiveUser": {
      "fields": {
        "avatarBackgroundColor": {
          "id": 9,
          "type": "string"
        },
        "role": {
          "id": 8,
          "type": "ActiveUserRole"
        },
        "entryTime": {
          "id": 7,
          "type": "int64"
        },
        "colorIndex": {
          "id": 6,
          "type": "int32"
        },
        "color": {
          "id": 5,
          "type": "RGB"
        },
        "sessionId": {
          "id": 2,
          "type": "int32"
        },
        "avatarId": {
          "id": 4,
          "type": "string"
        },
        "nickname": {
          "id": 3,
          "type": "string"
        },
        "userId": {
          "id": 1,
          "type": "int64"
        }
      }
    },
    "VActivedCommentViewState": {
      "fields": {
        "commentAnchorPosition": {
          "id": 7,
          "options": {
            "proto3_optional": true
          },
          "type": "TranslateWorldPosition"
        },
        "hasAnchor": {
          "id": 6,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "commentPosition": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "TranslateWorldPosition"
        },
        "nextCommentId": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "prevCommentId": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "commentId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "display": {
          "id": 1,
          "type": "bool"
        }
      },
      "oneofs": {
        "_commentAnchorPosition": {
          "oneof": [
            "commentAnchorPosition"
          ]
        },
        "_hasAnchor": {
          "oneof": [
            "hasAnchor"
          ]
        },
        "_commentPosition": {
          "oneof": [
            "commentPosition"
          ]
        },
        "_nextCommentId": {
          "oneof": [
            "nextCommentId"
          ]
        },
        "_prevCommentId": {
          "oneof": [
            "prevCommentId"
          ]
        },
        "_commentId": {
          "oneof": [
            "commentId"
          ]
        }
      }
    },
    "VArcData": {
      "fields": {
        "innerRadius": {
          "id": 4,
          "type": "MixProp"
        },
        "sweep": {
          "id": 3,
          "type": "MixProp"
        },
        "startingAngle": {
          "id": 2,
          "type": "MixProp"
        },
        "passIds": {
          "id": 5,
          "rule": "repeated",
          "type": "string"
        },
        "hidden": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "VArrangeState": {
      "fields": {
        "canUseAiAlign": {
          "id": 6,
          "type": "bool"
        },
        "tidyUpType": {
          "id": 5,
          "type": "TidyType"
        },
        "canUsePack": {
          "id": 4,
          "type": "bool"
        },
        "canUseDistribute": {
          "id": 3,
          "type": "bool"
        },
        "canUseAlign": {
          "id": 2,
          "type": "bool"
        },
        "show": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "VAssetSizeInfo": {
      "fields": {
        "height": {
          "id": 2,
          "type": "float"
        },
        "width": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "VAvaiableFonts": {
      "fields": {
        "availableFonts": {
          "id": 1,
          "rule": "repeated",
          "type": "FullFontName"
        }
      }
    },
    "VBaseAttributeV2": {
      "fields": {
        "maxH": {
          "id": 9,
          "type": "BaseAttributeProp"
        },
        "minH": {
          "id": 8,
          "type": "BaseAttributeProp"
        },
        "maxW": {
          "id": 7,
          "type": "BaseAttributeProp"
        },
        "minW": {
          "id": 6,
          "type": "BaseAttributeProp"
        },
        "r": {
          "id": 5,
          "type": "BaseAttributeProp"
        },
        "h": {
          "id": 4,
          "type": "BaseAttributeProp"
        },
        "w": {
          "id": 3,
          "type": "BaseAttributeProp"
        },
        "y": {
          "id": 2,
          "type": "BaseAttributeProp"
        },
        "x": {
          "id": 1,
          "type": "BaseAttributeProp"
        }
      }
    },
    "VClipsContentState": {
      "fields": {
        "value": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "mixed": {
          "id": 3,
          "type": "bool"
        },
        "show": {
          "id": 1,
          "type": "bool"
        }
      },
      "oneofs": {
        "_value": {
          "oneof": [
            "value"
          ]
        }
      }
    },
    "VCoactorMouse": {
      "fields": {
        "sessionId": {
          "id": 3,
          "type": "int32"
        },
        "mouseCameraPosition": {
          "id": 2,
          "type": "CoactorMousePosition"
        },
        "cursor": {
          "id": 1,
          "type": "CursorMode"
        }
      }
    },
    "VCoactorMouseList": {
      "fields": {
        "placeholder": {
          "id": 2,
          "type": "ProtoEmptyPlaceholder"
        },
        "value": {
          "id": 1,
          "rule": "repeated",
          "type": "VCoactorMouse"
        }
      }
    },
    "VCoactorSelectionsMap": {
      "fields": {
        "value": {
          "id": 1,
          "keyType": "int32",
          "type": "CoactorSelection"
        }
      }
    },
    "VColorPick": {
      "fields": {
        "isExecutePickColor": {
          "id": 3,
          "type": "bool"
        },
        "pickColor": {
          "id": 2,
          "type": "RGB"
        },
        "isDisplay": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "VCommentElementViewState": {
      "fields": {
        "commentPosition": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "TranslateWorldPosition"
        },
        "commentId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "commentAnchorPosition": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "TranslateWorldPosition"
        },
        "hasAnchor": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "display": {
          "id": 1,
          "type": "bool"
        }
      },
      "oneofs": {
        "_commentPosition": {
          "oneof": [
            "commentPosition"
          ]
        },
        "_commentId": {
          "oneof": [
            "commentId"
          ]
        },
        "_commentAnchorPosition": {
          "oneof": [
            "commentAnchorPosition"
          ]
        },
        "_hasAnchor": {
          "oneof": [
            "hasAnchor"
          ]
        }
      }
    },
    "VCommentPositionViewState": {
      "fields": {
        "anchorTranslateY": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "hasAnchor": {
          "id": 3,
          "type": "bool"
        },
        "translateY": {
          "id": 2,
          "type": "float"
        },
        "anchorTranslateX": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "translateX": {
          "id": 1,
          "type": "float"
        }
      },
      "oneofs": {
        "_anchorTranslateY": {
          "oneof": [
            "anchorTranslateY"
          ]
        },
        "_anchorTranslateX": {
          "oneof": [
            "anchorTranslateX"
          ]
        }
      }
    },
    "VCommentsPositionViewState": {
      "fields": {
        "fakeFieldToForceUpdate": {
          "id": 3,
          "type": "bool"
        },
        "clusters": {
          "id": 2,
          "keyType": "string",
          "type": "CommentCluster"
        },
        "pins": {
          "id": 1,
          "keyType": "string",
          "type": "VCommentPositionViewState"
        }
      }
    },
    "VComponentDescUnit": {
      "fields": {
        "isDescMixed": {
          "id": 3,
          "type": "bool"
        },
        "desc": {
          "id": 2,
          "type": "string"
        },
        "nodeIds": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "VComponentPropDef": {
      "fields": {
        "unused": {
          "id": 9,
          "type": "bool"
        },
        "instanceSwapPreferredValues": {
          "id": 7,
          "rule": "repeated",
          "type": "VInstanceSwapPreferredValue"
        },
        "isConflict": {
          "id": 8,
          "type": "bool"
        },
        "instanceSwapPropComponentName": {
          "id": 6,
          "type": "string"
        },
        "affectedNodeIds": {
          "id": 10,
          "rule": "repeated",
          "type": "string"
        },
        "varValue": {
          "id": 4,
          "type": "VariableData"
        },
        "variantOptions": {
          "id": 5,
          "rule": "repeated",
          "type": "VariantOption"
        },
        "type": {
          "id": 3,
          "type": "VComponentPropType"
        },
        "name": {
          "id": 2,
          "type": "string"
        },
        "id": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_id": {
          "oneof": [
            "id"
          ]
        }
      }
    },
    "VComponentPropPill": {
      "fields": {
        "detachDatas": {
          "id": 6,
          "rule": "repeated",
          "type": "DetachComponentPropDefData"
        },
        "varValue": {
          "id": 4,
          "type": "VariableData"
        },
        "preferredValues": {
          "id": 5,
          "type": "ComponentPropPreferredValues"
        },
        "type": {
          "id": 3,
          "type": "ComponentPropType"
        },
        "name": {
          "id": 2,
          "type": "string"
        },
        "editedPropId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_editedPropId": {
          "oneof": [
            "editedPropId"
          ]
        }
      }
    },
    "VComponentPropStore": {
      "fields": {
        "defsPanelKey": {
          "id": 28,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "showAddZombiePropBtnOfText": {
          "id": 20,
          "type": "bool"
        },
        "sharedInstanceSwapPropDefOfSelectedNodes": {
          "id": 27,
          "type": "VComponentPropPill"
        },
        "showDisabledPillOfBoolean": {
          "id": 10,
          "type": "bool"
        },
        "sharedTextPropDefOfSelectedNodes": {
          "id": 21,
          "type": "VComponentPropPill"
        },
        "showNormalPillOfInstanceSwap": {
          "id": 26,
          "type": "bool"
        },
        "showApplyIconForApplyInstanceSwapProp": {
          "id": 25,
          "type": "bool"
        },
        "showComponentPropPillOfBoolean": {
          "id": 9,
          "type": "bool"
        },
        "showDisabledPillOfText": {
          "id": 18,
          "type": "bool"
        },
        "showNormalPillOfText": {
          "id": 17,
          "type": "bool"
        },
        "showApplyIconForApplyTextProp": {
          "id": 16,
          "type": "bool"
        },
        "showApplyIconForApplyBoolProp": {
          "id": 4,
          "type": "bool"
        },
        "instanceCompProps": {
          "id": 14,
          "rule": "repeated",
          "type": "VInstanceCompProp"
        },
        "sharedTextValUnit": {
          "id": 22,
          "type": "VTextValUnit"
        },
        "popupVal": {
          "id": 15,
          "type": "VComponentPropDef"
        },
        "showAddZombiePropBtnOfBoolean": {
          "id": 12,
          "type": "bool"
        },
        "componentPropDefs": {
          "id": 8,
          "rule": "repeated",
          "type": "VComponentPropDef"
        },
        "showZombiePillOfText": {
          "id": 19,
          "type": "bool"
        },
        "showApplyIconForCreateInstanceSwapProp": {
          "id": 24,
          "type": "bool"
        },
        "displayApplyIconOfInstanceSwap": {
          "id": 7,
          "type": "bool"
        },
        "showApplyIconForCreateTextProp": {
          "id": 6,
          "type": "bool"
        },
        "showZombiePillOfBoolean": {
          "id": 11,
          "type": "bool"
        },
        "resetAssignments": {
          "id": 23,
          "rule": "repeated",
          "type": "InstanceResetAssignmentType"
        },
        "showApplyIconForCreateBoolProp": {
          "id": 3,
          "type": "bool"
        },
        "displayPropPanelOfComponent": {
          "id": 1,
          "type": "bool"
        },
        "sharedBoolPropDefOfSelectedNodes": {
          "id": 13,
          "type": "VComponentPropPill"
        },
        "showComponentTextArea": {
          "id": 5,
          "type": "bool"
        },
        "showInstanceCompProps": {
          "id": 2,
          "type": "bool"
        }
      },
      "oneofs": {
        "_defsPanelKey": {
          "oneof": [
            "defsPanelKey"
          ]
        }
      }
    },
    "VConstrainProportions": {
      "fields": {
        "value": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "disabled": {
          "id": 4,
          "type": "bool"
        },
        "mixed": {
          "id": 3,
          "type": "bool"
        },
        "show": {
          "id": 1,
          "type": "bool"
        }
      },
      "oneofs": {
        "_value": {
          "oneof": [
            "value"
          ]
        }
      }
    },
    "VConstraintsState": {
      "fields": {
        "disable": {
          "id": 2,
          "type": "bool"
        },
        "vertical": {
          "id": 4,
          "type": "MixConstraintsProp"
        },
        "horizontal": {
          "id": 3,
          "type": "MixConstraintsProp"
        },
        "show": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "VCopyPasteState": {
      "fields": {
        "enableCopyAsText": {
          "id": 4,
          "type": "bool"
        },
        "enablePasteProperties": {
          "id": 2,
          "type": "bool"
        },
        "enableCopyAsSVG": {
          "id": 5,
          "type": "bool"
        },
        "enablePasteToReplace": {
          "id": 3,
          "type": "bool"
        },
        "enableCopyAsLink": {
          "id": 7,
          "type": "bool"
        },
        "enableCopyAsPNG": {
          "id": 6,
          "type": "bool"
        },
        "enableCopyProperties": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "VCornerRadius": {
      "fields": {
        "neverSetValue": {
          "id": 4,
          "type": "bool"
        },
        "value": {
          "id": 3,
          "type": "float"
        },
        "mixed": {
          "id": 2,
          "type": "bool"
        },
        "show": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "VCornerSmoothing": {
      "fields": {
        "selected": {
          "id": 5,
          "type": "bool"
        },
        "neverSetValue": {
          "id": 4,
          "type": "bool"
        },
        "value": {
          "id": 3,
          "type": "float"
        },
        "mixed": {
          "id": 2,
          "type": "bool"
        },
        "show": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "VDesignPanelItemShow": {
      "fields": {
        "localVariables": {
          "id": 23,
          "type": "bool"
        },
        "showMoreProperties": {
          "id": 22,
          "type": "bool"
        },
        "exportPagePanel": {
          "id": 21,
          "type": "bool"
        },
        "background": {
          "id": 17,
          "type": "bool"
        },
        "selectColor": {
          "id": 14,
          "type": "bool"
        },
        "gridLayout": {
          "id": 20,
          "type": "bool"
        },
        "stylesWrap": {
          "id": 18,
          "type": "bool"
        },
        "fills": {
          "id": 12,
          "type": "bool"
        },
        "effects": {
          "id": 15,
          "type": "bool"
        },
        "text": {
          "id": 10,
          "type": "bool"
        },
        "mask": {
          "id": 9,
          "type": "bool"
        },
        "stroke": {
          "id": 13,
          "type": "bool"
        },
        "constraints": {
          "id": 8,
          "type": "bool"
        },
        "autoLayout": {
          "id": 7,
          "type": "bool"
        },
        "belongingComponentOrSet": {
          "id": 6,
          "type": "bool"
        },
        "scale": {
          "id": 1,
          "type": "bool"
        },
        "component": {
          "id": 5,
          "type": "bool"
        },
        "frameTemplate": {
          "id": 19,
          "type": "bool"
        },
        "align": {
          "id": 2,
          "type": "bool"
        },
        "vector": {
          "id": 3,
          "type": "bool"
        },
        "exportPanel": {
          "id": 16,
          "type": "bool"
        },
        "blendMode": {
          "id": 11,
          "type": "bool"
        },
        "attributes": {
          "id": 4,
          "type": "bool"
        }
      }
    },
    "VDevModeBatchExport": {
      "fields": {
        "batchExportItems": {
          "id": 2,
          "rule": "repeated",
          "type": "VDevModeBatchExportItem"
        },
        "show": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "VDevModeBatchExportItem": {
      "fields": {
        "name": {
          "id": 2,
          "type": "string"
        },
        "height": {
          "id": 4,
          "type": "int32"
        },
        "width": {
          "id": 3,
          "type": "int32"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "VDevModeCodeConfig": {
      "fields": {
        "codeConfig": {
          "id": 1,
          "type": "DevModeInspectCodeConfig"
        }
      }
    },
    "VDevModeExportSetting": {
      "fields": {
        "exportSettings": {
          "id": 3,
          "rule": "repeated",
          "type": "ExportSettings"
        },
        "selectionNodeNames": {
          "id": 2,
          "rule": "repeated",
          "type": "string"
        },
        "selectionNodeIds": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "VDevModeHoverNodeLabel": {
      "fields": {
        "visibleViewport": {
          "id": 12,
          "type": "VisibleViewport"
        },
        "heightStackMode": {
          "id": 11,
          "type": "string"
        },
        "height": {
          "id": 9,
          "type": "string"
        },
        "widthStackMode": {
          "id": 10,
          "type": "string"
        },
        "width": {
          "id": 8,
          "type": "string"
        },
        "boundary": {
          "id": 6,
          "type": "Rect"
        },
        "name": {
          "id": 4,
          "type": "string"
        },
        "relatedToComponentInstance": {
          "id": 5,
          "type": "bool"
        },
        "hoveredNodeId": {
          "id": 3,
          "type": "string"
        },
        "isHoverSelectedNode": {
          "id": 2,
          "type": "bool"
        },
        "nodeIcon": {
          "id": 7,
          "type": "NodeIconData"
        },
        "show": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "VDevModeInspectBoxModel": {
      "fields": {
        "imageAssetValue": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "DevModeInspectBoxModelImageAsset"
        },
        "commonAssetValue": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "DevModeInspectBoxModelCommonAsset"
        },
        "textValue": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "DevModeInspectBoxModelText"
        },
        "layoutValue": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "DevModeInspectBoxModelLayout"
        },
        "showType": {
          "id": 1,
          "type": "DevModeInspectBoxModelShowType"
        }
      },
      "oneofs": {
        "_imageAssetValue": {
          "oneof": [
            "imageAssetValue"
          ]
        },
        "_commonAssetValue": {
          "oneof": [
            "commonAssetValue"
          ]
        },
        "_textValue": {
          "oneof": [
            "textValue"
          ]
        },
        "_layoutValue": {
          "oneof": [
            "layoutValue"
          ]
        }
      }
    },
    "VDevModeInspectCode": {
      "fields": {
        "codeBlocks": {
          "id": 2,
          "rule": "repeated",
          "type": "CodeBlock"
        },
        "show": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "VDevModeInspectCodeType": {
      "fields": {
        "codeType": {
          "id": 1,
          "type": "DevModeInspectCodeType"
        }
      }
    },
    "VDevModeInspectComponent": {
      "fields": {
        "componentData": {
          "id": 4,
          "type": "ComponentRelatedData"
        },
        "show": {
          "id": 2,
          "type": "bool"
        },
        "isInstance": {
          "id": 3,
          "type": "bool"
        },
        "isRemote": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "VDevModeInspectComponentInstanceSubordinate": {
      "fields": {
        "masterName": {
          "id": 5,
          "type": "string"
        },
        "targetNodeId": {
          "id": 4,
          "type": "string"
        },
        "isVarient": {
          "id": 3,
          "type": "bool"
        },
        "isComponent": {
          "id": 2,
          "type": "bool"
        },
        "show": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "VDevModeInspectContent": {
      "fields": {
        "characters": {
          "id": 2,
          "type": "string"
        },
        "show": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "VDevModeInspectEffect": {
      "fields": {
        "styleDescription": {
          "id": 4,
          "type": "string"
        },
        "variables": {
          "id": 5,
          "rule": "repeated",
          "type": "SingleVariable"
        },
        "styleName": {
          "id": 3,
          "type": "string"
        },
        "effectWrappers": {
          "id": 2,
          "rule": "repeated",
          "type": "EffectWrapper"
        },
        "show": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "VDevModeInspectExport": {
      "fields": {
        "exportBtnText": {
          "id": 6,
          "type": "string"
        },
        "exportSetting": {
          "id": 7,
          "type": "VDevModeExportSetting"
        },
        "isCompress": {
          "id": 5,
          "type": "bool"
        },
        "scaleConfig": {
          "id": 4,
          "keyType": "int32",
          "type": "bool"
        },
        "presetCodeType": {
          "id": 2,
          "type": "PresetCodeType"
        },
        "fileType": {
          "id": 3,
          "type": "FileType"
        },
        "show": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "VDevModeInspectFill": {
      "fields": {
        "inspectSelectionFillItems": {
          "id": 2,
          "rule": "repeated",
          "type": "InspectSelectionFillItem"
        },
        "show": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "VDevModeInspectLayout": {
      "fields": {
        "space": {
          "id": 8,
          "type": "string"
        },
        "cornerRadius": {
          "id": 6,
          "type": "string"
        },
        "height": {
          "id": 5,
          "type": "string"
        },
        "counterSpacing": {
          "id": 9,
          "type": "string"
        },
        "rotation": {
          "id": 7,
          "type": "string"
        },
        "width": {
          "id": 4,
          "type": "string"
        },
        "y": {
          "id": 3,
          "type": "string"
        },
        "padding": {
          "id": 10,
          "type": "string"
        },
        "x": {
          "id": 2,
          "type": "string"
        },
        "show": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "VDevModeInspectNodeBasicInfo": {
      "fields": {
        "selectionInfo": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "SingleSelectionInfo"
        },
        "show": {
          "id": 1,
          "type": "bool"
        }
      },
      "oneofs": {
        "_selectionInfo": {
          "oneof": [
            "selectionInfo"
          ]
        }
      }
    },
    "VDevModeInspectSelectionInfo": {
      "fields": {
        "type": {
          "id": 1,
          "type": "DevModeInspectSelectionInfoType"
        }
      }
    },
    "VDevModeInspectStroke": {
      "fields": {
        "alignState": {
          "id": 4,
          "type": "SelectionStrokeAlignState"
        },
        "weightStateWrapper": {
          "id": 3,
          "type": "SelectionStrokeWeightStateWrapper"
        },
        "paintState": {
          "id": 2,
          "type": "SelectionStrokePaintState"
        },
        "show": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "VDevModeInspectText": {
      "fields": {
        "paragraphSpacing": {
          "id": 7,
          "type": "string"
        },
        "textStyleDisplayDataMap": {
          "id": 5,
          "keyType": "string",
          "type": "TextStyleDisplayData"
        },
        "textAlignHorizontal": {
          "id": 3,
          "type": "TextAlignHorizontal"
        },
        "textStyles": {
          "id": 2,
          "rule": "repeated",
          "type": "TextStyleWrapper"
        },
        "fontMetaData": {
          "id": 6,
          "rule": "repeated",
          "type": "FontMetaData"
        },
        "textAlignVertical": {
          "id": 4,
          "type": "TextAlignVertical"
        },
        "show": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "VDevModeInspectType": {
      "fields": {
        "inspectType": {
          "id": 1,
          "type": "DevModeInspectType"
        }
      }
    },
    "VDevModeLayerPanel": {
      "fields": {
        "isCollapseAllNodesEnabled": {
          "id": 4,
          "type": "bool"
        },
        "translateHeight": {
          "id": 2,
          "type": "int32"
        },
        "totalHeight": {
          "id": 3,
          "type": "int32"
        },
        "renderList": {
          "id": 1,
          "rule": "repeated",
          "type": "VDevModeLayerPanelNode"
        }
      }
    },
    "VDevModeLayerPanelNode": {
      "fields": {
        "shouldShowIcon": {
          "id": 17,
          "type": "bool"
        },
        "textContent": {
          "id": 16,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "needDarkBackgroundForAsset": {
          "id": 19,
          "type": "bool"
        },
        "assetSizeInfo": {
          "id": 15,
          "options": {
            "proto3_optional": true
          },
          "type": "VAssetSizeInfo"
        },
        "needCoverFitForAsset": {
          "id": 20,
          "type": "bool"
        },
        "isImageAsset": {
          "id": 14,
          "type": "bool"
        },
        "isHovered": {
          "id": 5,
          "type": "bool"
        },
        "isFontBold": {
          "id": 9,
          "type": "bool"
        },
        "nodeIcon": {
          "id": 10,
          "type": "NodeIconData"
        },
        "indent": {
          "id": 8,
          "type": "int32"
        },
        "isVariant": {
          "id": 18,
          "type": "bool"
        },
        "id": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "expandStatus": {
          "id": 7,
          "type": "VLayerPanelNodeExpandStauts"
        },
        "isCommonAsset": {
          "id": 13,
          "type": "bool"
        },
        "isAncestorSelected": {
          "id": 4,
          "type": "bool"
        },
        "name": {
          "id": 1,
          "type": "string"
        },
        "isPurple": {
          "id": 6,
          "type": "bool"
        },
        "isContainer": {
          "id": 12,
          "type": "bool"
        },
        "visibleStatus": {
          "id": 11,
          "type": "VLayerPanelVisibleStatus"
        },
        "isSelected": {
          "id": 3,
          "type": "bool"
        }
      },
      "oneofs": {
        "_textContent": {
          "oneof": [
            "textContent"
          ]
        },
        "_assetSizeInfo": {
          "oneof": [
            "assetSizeInfo"
          ]
        },
        "_id": {
          "oneof": [
            "id"
          ]
        }
      }
    },
    "VDevModeSelectedSwitchableDeliveryUnit": {
      "fields": {
        "show": {
          "id": 4,
          "type": "bool"
        },
        "shouldRecenter": {
          "id": 3,
          "type": "bool"
        },
        "total": {
          "id": 2,
          "type": "int32"
        },
        "index": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "VDisplayPanels": {
      "fields": {
        "displayPanels": {
          "id": 1,
          "rule": "repeated",
          "type": "DisplayPanel"
        }
      }
    },
    "VDocumentColorProfile": {
      "fields": {
        "monitorSupportDisplayP3Level": {
          "id": 3,
          "type": "DeviceSupportDisplayP3Level"
        },
        "isHostSupportDisplayP3": {
          "id": 2,
          "type": "bool"
        },
        "profile": {
          "id": 1,
          "type": "DocumentColorProfile"
        }
      }
    },
    "VDocumentPageList": {
      "fields": {
        "pages": {
          "id": 1,
          "rule": "repeated",
          "type": "VPageItem"
        }
      }
    },
    "VDraftCommentViewState": {
      "fields": {
        "commentPosition": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "TranslateWorldPosition"
        },
        "commentAnchorPosition": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "TranslateWorldPosition"
        },
        "hasAnchor": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "display": {
          "id": 1,
          "type": "bool"
        }
      },
      "oneofs": {
        "_commentPosition": {
          "oneof": [
            "commentPosition"
          ]
        },
        "_commentAnchorPosition": {
          "oneof": [
            "commentAnchorPosition"
          ]
        },
        "_hasAnchor": {
          "oneof": [
            "hasAnchor"
          ]
        }
      }
    },
    "VDrawingCommentViewState": {
      "fields": {
        "drawingEndPosition": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "TranslateWorldPosition"
        },
        "drawingStartPosition": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "TranslateWorldPosition"
        },
        "display": {
          "id": 1,
          "type": "bool"
        }
      },
      "oneofs": {
        "_drawingEndPosition": {
          "oneof": [
            "drawingEndPosition"
          ]
        },
        "_drawingStartPosition": {
          "oneof": [
            "drawingStartPosition"
          ]
        }
      }
    },
    "VEffectStyleNode": {
      "fields": {
        "floatVariables": {
          "id": 7,
          "rule": "repeated",
          "type": "SingleFloatVariable"
        },
        "variables": {
          "id": 6,
          "rule": "repeated",
          "type": "SingleVariable"
        },
        "description": {
          "id": 3,
          "type": "string"
        },
        "key": {
          "id": 5,
          "type": "string"
        },
        "effects": {
          "id": 4,
          "rule": "repeated",
          "type": "Effect"
        },
        "name": {
          "id": 2,
          "type": "string"
        },
        "id": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "VExportInfo": {
      "fields": {
        "displayName": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "nodeIcon": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "NodeIconData"
        },
        "nodeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_displayName": {
          "oneof": [
            "displayName"
          ]
        },
        "_nodeIcon": {
          "oneof": [
            "nodeIcon"
          ]
        },
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        }
      }
    },
    "VExportSettingsState": {
      "fields": {
        "showEditVector": {
          "id": 8,
          "type": "bool"
        },
        "pageName": {
          "id": 6,
          "type": "string"
        },
        "selectionNodeName": {
          "id": 5,
          "rule": "repeated",
          "type": "SelectionNodeName"
        },
        "singleSelectionId": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "exportSettingsState": {
          "id": 2,
          "rule": "repeated",
          "type": "ExportSettings"
        },
        "buttonText": {
          "id": 3,
          "type": "string"
        },
        "pageId": {
          "id": 7,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "exportTarget": {
          "id": 1,
          "type": "ExportOperationTarget"
        }
      },
      "oneofs": {
        "_singleSelectionId": {
          "oneof": [
            "singleSelectionId"
          ]
        },
        "_pageId": {
          "oneof": [
            "pageId"
          ]
        }
      }
    },
    "VFontVariableCustomStyleState": {
      "fields": {
        "styleToCustomAxes": {
          "id": 1,
          "rule": "repeated",
          "type": "CustomAxesStyleInfo"
        }
      }
    },
    "VFontVariationInstanceAxes": {
      "fields": {
        "isMixed": {
          "id": 8,
          "type": "bool"
        },
        "value": {
          "id": 7,
          "type": "float"
        },
        "hidden": {
          "id": 6,
          "type": "bool"
        },
        "axisTag": {
          "id": 1,
          "type": "uint32"
        },
        "maxValue": {
          "id": 4,
          "type": "float"
        },
        "defaultValue": {
          "id": 5,
          "type": "float"
        },
        "minValue": {
          "id": 3,
          "type": "float"
        },
        "name": {
          "id": 2,
          "type": "string"
        }
      }
    },
    "VFramePresetState": {
      "fields": {
        "disabledGroup": {
          "id": 10,
          "type": "bool"
        },
        "sizeMixed": {
          "id": 7,
          "type": "bool"
        },
        "sameSizeWidth": {
          "id": 8,
          "type": "float"
        },
        "stackMode": {
          "id": 6,
          "type": "StackMode"
        },
        "available": {
          "id": 3,
          "type": "bool"
        },
        "allowShowStackModeToggleAndResizeToFitButton": {
          "id": 11,
          "type": "bool"
        },
        "typeMixed": {
          "id": 2,
          "type": "bool"
        },
        "stackModeMixed": {
          "id": 5,
          "type": "bool"
        },
        "disableStackModeToggle": {
          "id": 4,
          "type": "bool"
        },
        "sameSizeHeight": {
          "id": 9,
          "type": "float"
        },
        "type": {
          "id": 1,
          "type": "NodeType"
        }
      }
    },
    "VGlobalState": {
      "fields": {
        "showRuler": {
          "id": 5,
          "type": "bool"
        },
        "rightClickState": {
          "id": 7,
          "options": {
            "proto3_optional": true
          },
          "type": "RightClickState"
        },
        "snapToPixel": {
          "id": 3,
          "type": "bool"
        },
        "enablePixelGrid": {
          "id": 4,
          "type": "bool"
        },
        "enableLayoutGrid": {
          "id": 6,
          "type": "bool"
        },
        "fullscreen": {
          "id": 2,
          "type": "bool"
        },
        "showSidebar": {
          "id": 1,
          "type": "bool"
        }
      },
      "oneofs": {
        "_rightClickState": {
          "oneof": [
            "rightClickState"
          ]
        }
      }
    },
    "VIndependentCornerRadius": {
      "fields": {
        "passIds": {
          "id": 7,
          "rule": "repeated",
          "type": "string"
        },
        "site3": {
          "id": 5,
          "type": "MixProp"
        },
        "site4": {
          "id": 6,
          "type": "MixProp"
        },
        "site2": {
          "id": 4,
          "type": "MixProp"
        },
        "show": {
          "id": 1,
          "type": "bool"
        },
        "site1": {
          "id": 3,
          "type": "MixProp"
        },
        "expand": {
          "id": 2,
          "type": "bool"
        }
      }
    },
    "VInstanceCompProp": {
      "fields": {
        "nodeIds": {
          "id": 9,
          "rule": "repeated",
          "type": "string"
        },
        "isMixed": {
          "id": 8,
          "type": "bool"
        },
        "mainComponentName": {
          "id": 7,
          "type": "string"
        },
        "mainComponentId": {
          "id": 6,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "boolVal": {
          "id": 4,
          "type": "bool"
        },
        "textVal": {
          "id": 5,
          "type": "string"
        },
        "type": {
          "id": 3,
          "type": "VComponentPropType"
        },
        "name": {
          "id": 2,
          "type": "string"
        },
        "defId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_mainComponentId": {
          "oneof": [
            "mainComponentId"
          ]
        },
        "_defId": {
          "oneof": [
            "defId"
          ]
        }
      }
    },
    "VInstanceSwapPreferredValue": {
      "fields": {
        "name": {
          "id": 4,
          "type": "string"
        },
        "thumbnailData": {
          "id": 5,
          "type": "ThumbnailData"
        },
        "publishFile": {
          "id": 3,
          "type": "string"
        },
        "publishId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "type": {
          "id": 1,
          "type": "InstanceSwapPreferredValueType"
        }
      },
      "oneofs": {
        "_publishId": {
          "oneof": [
            "publishId"
          ]
        }
      }
    },
    "VLayerPanel": {
      "fields": {
        "translateHeight": {
          "id": 2,
          "type": "int32"
        },
        "topLevelFixedFrame": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "TopLevelFixedFrame"
        },
        "totalHeight": {
          "id": 3,
          "type": "int32"
        },
        "renderList": {
          "id": 1,
          "rule": "repeated",
          "type": "VLayerPanelItemInfo"
        }
      },
      "oneofs": {
        "_topLevelFixedFrame": {
          "oneof": [
            "topLevelFixedFrame"
          ]
        }
      }
    },
    "VLayerPanelAILayoutColorLine": {
      "fields": {
        "lines": {
          "id": 2,
          "rule": "repeated",
          "type": "AILayoutColorLine"
        },
        "display": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "VLayerPanelItemInfo": {
      "fields": {
        "layerPanelSeeAllLayersInfo": {
          "id": 4,
          "type": "VLayerPanelSeeAllLayers"
        },
        "layerPanelNodeInfo": {
          "id": 2,
          "type": "VLayerPanelNode"
        },
        "layerPanelSectionInfo": {
          "id": 3,
          "type": "VLayerPanelSection"
        },
        "type": {
          "id": 1,
          "type": "LayerPanelItemType"
        }
      }
    },
    "VLayerPanelNode": {
      "fields": {
        "isContainer": {
          "id": 17,
          "type": "bool"
        },
        "visibleStatus": {
          "id": 15,
          "type": "VLayerPanelVisibleStatus"
        },
        "nodeIcon": {
          "id": 14,
          "type": "NodeIconData"
        },
        "isInAILayout": {
          "id": 18,
          "type": "bool"
        },
        "maskTypes": {
          "id": 12,
          "rule": "repeated",
          "type": "VLayerPanelMaskType"
        },
        "indent": {
          "id": 11,
          "type": "int32"
        },
        "isThumbnail": {
          "id": 9,
          "type": "bool"
        },
        "name": {
          "id": 1,
          "type": "string"
        },
        "isAncestorSelected": {
          "id": 6,
          "type": "bool"
        },
        "isFontBold": {
          "id": 13,
          "type": "bool"
        },
        "isHovered": {
          "id": 7,
          "type": "bool"
        },
        "isEditing": {
          "id": 2,
          "type": "bool"
        },
        "lockStatus": {
          "id": 16,
          "type": "VLayerPanelLockStatus"
        },
        "isComponentInsideComponentSet": {
          "id": 5,
          "type": "bool"
        },
        "isPurple": {
          "id": 8,
          "type": "bool"
        },
        "expandStatus": {
          "id": 10,
          "type": "VLayerPanelNodeExpandStauts"
        },
        "id": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "isSelected": {
          "id": 4,
          "type": "bool"
        }
      },
      "oneofs": {
        "_id": {
          "oneof": [
            "id"
          ]
        }
      }
    },
    "VLayerPanelSection": {
      "fields": {
        "isInComponentLike": {
          "id": 6,
          "type": "bool"
        },
        "isAncestorSelected": {
          "id": 5,
          "type": "bool"
        },
        "sectionId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "indent": {
          "id": 4,
          "type": "int32"
        },
        "parentId": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "type": {
          "id": 1,
          "type": "SectionType"
        }
      },
      "oneofs": {
        "_sectionId": {
          "oneof": [
            "sectionId"
          ]
        },
        "_parentId": {
          "oneof": [
            "parentId"
          ]
        }
      }
    },
    "VLayerPanelSeeAllLayers": {
      "fields": {
        "indent": {
          "id": 2,
          "type": "int32"
        },
        "seeAllLayerId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_seeAllLayerId": {
          "oneof": [
            "seeAllLayerId"
          ]
        }
      }
    },
    "VLayoutGridStyleNode": {
      "fields": {
        "floatVariables": {
          "id": 6,
          "rule": "repeated",
          "type": "SingleFloatVariable"
        },
        "description": {
          "id": 3,
          "type": "string"
        },
        "key": {
          "id": 5,
          "type": "string"
        },
        "layoutGrids": {
          "id": 4,
          "rule": "repeated",
          "type": "LayoutGrid"
        },
        "name": {
          "id": 2,
          "type": "string"
        },
        "styleNode": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "VLibraryAssetPanel": {
      "fields": {
        "stickyHeader": {
          "id": 5,
          "type": "VLibraryAssetPanelStckyHeader"
        },
        "totalHeight": {
          "id": 6,
          "type": "float"
        },
        "displayMode": {
          "id": 1,
          "type": "VLibraryAssetPanelDisplayMode"
        },
        "displayItems": {
          "id": 4,
          "rule": "repeated",
          "type": "VLibraryAssetPanelItem"
        },
        "hasContent": {
          "id": 3,
          "type": "bool"
        },
        "filterText": {
          "id": 2,
          "type": "string"
        }
      }
    },
    "VLibraryAssetPanelComponentItem": {
      "fields": {
        "layoutSizeType": {
          "id": 21,
          "type": "LibraryLayoutSizeType"
        },
        "fullWidth": {
          "id": 19,
          "type": "bool"
        },
        "innerHeight": {
          "id": 18,
          "type": "float"
        },
        "innerWidth": {
          "id": 17,
          "type": "float"
        },
        "top": {
          "id": 16,
          "type": "float"
        },
        "left": {
          "id": 15,
          "type": "float"
        },
        "height": {
          "id": 14,
          "type": "float"
        },
        "backgroundColor": {
          "id": 12,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "isLocal": {
          "id": 1,
          "type": "bool"
        },
        "thumbnailData": {
          "id": 11,
          "type": "ThumbnailData"
        },
        "nodeDataPath": {
          "id": 10,
          "type": "string"
        },
        "description": {
          "id": 8,
          "type": "string"
        },
        "localNodeId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "remoteDocId": {
          "id": 6,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "fromFig": {
          "id": 9,
          "type": "bool"
        },
        "inSelection": {
          "id": 4,
          "type": "bool"
        },
        "name": {
          "id": 7,
          "type": "string"
        },
        "width": {
          "id": 13,
          "type": "float"
        },
        "remoteNodeId": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "renderKey": {
          "id": 20,
          "type": "string"
        },
        "publishHidden": {
          "id": 3,
          "type": "bool"
        }
      },
      "oneofs": {
        "_backgroundColor": {
          "oneof": [
            "backgroundColor"
          ]
        },
        "_localNodeId": {
          "oneof": [
            "localNodeId"
          ]
        },
        "_remoteDocId": {
          "oneof": [
            "remoteDocId"
          ]
        },
        "_remoteNodeId": {
          "oneof": [
            "remoteNodeId"
          ]
        }
      }
    },
    "VLibraryAssetPanelFolderItem": {
      "fields": {
        "top": {
          "id": 6,
          "type": "float"
        },
        "expandKey": {
          "id": 3,
          "type": "string"
        },
        "suffixes": {
          "id": 2,
          "rule": "repeated",
          "type": "string"
        },
        "height": {
          "id": 5,
          "type": "float"
        },
        "expand": {
          "id": 4,
          "type": "bool"
        },
        "prefixes": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "VLibraryAssetPanelHeaderItem": {
      "fields": {
        "top": {
          "id": 6,
          "type": "float"
        },
        "height": {
          "id": 5,
          "type": "float"
        },
        "expand": {
          "id": 4,
          "type": "bool"
        },
        "expandKey": {
          "id": 3,
          "type": "string"
        },
        "name": {
          "id": 2,
          "type": "string"
        },
        "libraryType": {
          "id": 1,
          "type": "VLibraryType"
        }
      }
    },
    "VLibraryAssetPanelItem": {
      "fields": {
        "header": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "VLibraryAssetPanelHeaderItem"
        },
        "component": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "VLibraryAssetPanelComponentItem"
        },
        "folder": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "VLibraryAssetPanelFolderItem"
        },
        "type": {
          "id": 1,
          "type": "VLibraryAssetPanelItemType"
        }
      },
      "oneofs": {
        "_header": {
          "oneof": [
            "header"
          ]
        },
        "_component": {
          "oneof": [
            "component"
          ]
        },
        "_folder": {
          "oneof": [
            "folder"
          ]
        }
      }
    },
    "VLibraryAssetPanelStckyHeader": {
      "fields": {
        "folderNames": {
          "id": 4,
          "rule": "repeated",
          "type": "string"
        },
        "libraryName": {
          "id": 3,
          "type": "string"
        },
        "libraryType": {
          "id": 2,
          "type": "VLibraryType"
        },
        "visible": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "VLibraryComponentUpdateModal": {
      "fields": {
        "itemLength": {
          "id": 2,
          "type": "int32"
        },
        "items": {
          "id": 1,
          "rule": "repeated",
          "type": "VLibraryComponentUpdateModalItem"
        }
      }
    },
    "VLibraryComponentUpdateModalItem": {
      "fields": {
        "items": {
          "id": 5,
          "rule": "repeated",
          "type": "VLibraryComponentUpdateModalPublishMixinItem"
        },
        "operationTime": {
          "id": 3,
          "type": "int64"
        },
        "operationId": {
          "id": 2,
          "type": "string"
        },
        "description": {
          "id": 4,
          "type": "string"
        },
        "name": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "VLibraryComponentUpdateModalPublishMixinItem": {
      "fields": {
        "contentHash": {
          "id": 7,
          "type": "string"
        },
        "variantPairs": {
          "id": 11,
          "rule": "repeated",
          "type": "LibraryVariantPair"
        },
        "documentId": {
          "id": 9,
          "type": "string"
        },
        "nodeKey": {
          "id": 8,
          "type": "string"
        },
        "nodeId": {
          "id": 6,
          "type": "string"
        },
        "nodeDataPath": {
          "id": 5,
          "type": "string"
        },
        "collectionId": {
          "id": 14,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "movedOldLibraryId": {
          "id": 12,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "documentName": {
          "id": 3,
          "type": "string"
        },
        "upgradeType": {
          "id": 10,
          "type": "UpgradePublishMixinVersionType"
        },
        "removed": {
          "id": 4,
          "type": "bool"
        },
        "movedOldDocName": {
          "id": 13,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "name": {
          "id": 2,
          "type": "string"
        },
        "thumbnailData": {
          "id": 1,
          "type": "ThumbnailData"
        }
      },
      "oneofs": {
        "_collectionId": {
          "oneof": [
            "collectionId"
          ]
        },
        "_movedOldLibraryId": {
          "oneof": [
            "movedOldLibraryId"
          ]
        },
        "_movedOldDocName": {
          "oneof": [
            "movedOldDocName"
          ]
        }
      }
    },
    "VLibraryCurrentLocalModal": {
      "fields": {
        "currentLocalVariableConut": {
          "id": 5,
          "type": "int32"
        },
        "currentLocalVariableSetCount": {
          "id": 4,
          "type": "int32"
        },
        "currentLocalComponentCount": {
          "id": 2,
          "type": "int32"
        },
        "currentLocalStyleCount": {
          "id": 3,
          "type": "int32"
        },
        "open": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "VLibraryGlobalState": {
      "fields": {
        "showLocalLibraryTabBadge": {
          "id": 4,
          "type": "bool"
        },
        "notificationType": {
          "id": 7,
          "type": "LibraryNotificationType"
        },
        "publishableCount": {
          "id": 3,
          "type": "int32"
        },
        "showBadge": {
          "id": 2,
          "type": "bool"
        },
        "showRemoteLibraryTabBadge": {
          "id": 5,
          "type": "bool"
        },
        "remoteLibraryUpgradeType": {
          "id": 6,
          "type": "RemoteLibraryUpgradeType"
        },
        "enable": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "VLibraryRemoteListModal": {
      "fields": {
        "inUsedDocumentIds": {
          "id": 2,
          "rule": "repeated",
          "type": "string"
        },
        "open": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "VLibraryReplaceModal": {
      "fields": {
        "enableSubmit": {
          "id": 4,
          "type": "bool"
        },
        "displayList": {
          "id": 3,
          "rule": "repeated",
          "type": "VLibraryReplaceModalDisplayItem"
        },
        "containerHeight": {
          "id": 2,
          "type": "float"
        },
        "containerWidth": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "VLibraryReplaceModalDisplayItem": {
      "fields": {
        "top": {
          "id": 9,
          "type": "float"
        },
        "height": {
          "id": 8,
          "type": "float"
        },
        "checked": {
          "id": 6,
          "type": "bool"
        },
        "origin": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "VLibraryReplaceModalItem"
        },
        "remoteId": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "target": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "VLibraryReplaceModalItem"
        },
        "publishId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "visible": {
          "id": 7,
          "type": "bool"
        },
        "displayType": {
          "id": 1,
          "type": "LibraryReplaceModalItemDisplayType"
        }
      },
      "oneofs": {
        "_origin": {
          "oneof": [
            "origin"
          ]
        },
        "_remoteId": {
          "oneof": [
            "remoteId"
          ]
        },
        "_target": {
          "oneof": [
            "target"
          ]
        },
        "_publishId": {
          "oneof": [
            "publishId"
          ]
        }
      }
    },
    "VLibraryReplaceModalItem": {
      "fields": {
        "fontMissing": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "backgroundColor": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "thumbnailData": {
          "id": 2,
          "type": "ThumbnailData"
        },
        "name": {
          "id": 1,
          "type": "string"
        }
      },
      "oneofs": {
        "_fontMissing": {
          "oneof": [
            "fontMissing"
          ]
        },
        "_backgroundColor": {
          "oneof": [
            "backgroundColor"
          ]
        }
      }
    },
    "VLocalStyleEditingState": {
      "fields": {
        "selectionCount": {
          "id": 2,
          "type": "int32"
        },
        "state": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "LocalStyleEditingState"
        }
      },
      "oneofs": {
        "_state": {
          "oneof": [
            "state"
          ]
        }
      }
    },
    "VMaskPanelState": {
      "fields": {
        "checked": {
          "id": 2,
          "type": "MixedCheckState"
        },
        "show": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "VMembershipMap": {
      "fields": {
        "placeHolder": {
          "id": 2,
          "type": "ProtoEmptyPlaceholder"
        },
        "value": {
          "id": 1,
          "keyType": "int32",
          "type": "VActiveUser"
        }
      }
    },
    "VMemoryUsage": {
      "fields": {
        "show": {
          "id": 2,
          "type": "bool"
        },
        "sceneNodesCount": {
          "id": 1,
          "type": "uint32"
        }
      }
    },
    "VMissFontInfo": {
      "fields": {
        "isMissStyle": {
          "id": 2,
          "type": "bool"
        },
        "isMissFamily": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "VMotiffScopeData": {
      "fields": {
        "selectedParentChain": {
          "id": 6,
          "rule": "repeated",
          "type": "MotiffScopeParentData"
        },
        "allPropNames": {
          "id": 5,
          "rule": "repeated",
          "type": "string"
        },
        "doc": {
          "id": 3,
          "type": "DummyNodeData"
        },
        "selectedId": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "showSearchPanel": {
          "id": 2,
          "type": "bool"
        },
        "showMainPanel": {
          "id": 1,
          "type": "bool"
        }
      },
      "oneofs": {
        "_selectedId": {
          "oneof": [
            "selectedId"
          ]
        }
      }
    },
    "VMotiffScopeRefreshTrigger": {
      "fields": {
        "trigger": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "VMultipleRename": {
      "fields": {
        "enableRename": {
          "id": 2,
          "type": "bool"
        },
        "preview": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "VOpeningLibraryInUseCountInfo": {
      "fields": {
        "placeholder": {
          "id": 3,
          "type": "ProtoEmptyPlaceholder"
        },
        "componentCount": {
          "id": 2,
          "type": "int32"
        },
        "styleCount": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "VPageItem": {
      "fields": {
        "isEditing": {
          "id": 4,
          "type": "bool"
        },
        "id": {
          "id": 2,
          "type": "string"
        },
        "isSelected": {
          "id": 3,
          "type": "bool"
        },
        "name": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "VPageTopArea": {
      "fields": {
        "selectionChangeSessionId": {
          "id": 7,
          "type": "string"
        },
        "singleSelectionId": {
          "id": 6,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "showMultipleComponentTool": {
          "id": 3,
          "type": "bool"
        },
        "showEditTool": {
          "id": 5,
          "type": "bool"
        },
        "showResetComponent": {
          "id": 4,
          "type": "bool"
        },
        "showSingleComponentTool": {
          "id": 2,
          "type": "bool"
        },
        "showCropTool": {
          "id": 1,
          "type": "bool"
        }
      },
      "oneofs": {
        "_singleSelectionId": {
          "oneof": [
            "singleSelectionId"
          ]
        }
      }
    },
    "VPaint": {
      "fields": {
        "paint": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "Paint"
        }
      },
      "oneofs": {
        "_paint": {
          "oneof": [
            "paint"
          ]
        }
      }
    },
    "VPaintStyleNode": {
      "fields": {
        "variables": {
          "id": 6,
          "rule": "repeated",
          "type": "SingleVariable"
        },
        "node": {
          "id": 1,
          "type": "string"
        },
        "paints": {
          "id": 4,
          "rule": "repeated",
          "type": "Paint"
        },
        "key": {
          "id": 5,
          "type": "string"
        },
        "description": {
          "id": 3,
          "type": "string"
        },
        "name": {
          "id": 2,
          "type": "string"
        }
      }
    },
    "VPanelStateUnderAILayout": {
      "fields": {
        "disableSizeMode": {
          "id": 5,
          "type": "bool"
        },
        "disableConstraints": {
          "id": 3,
          "type": "bool"
        },
        "disableAbsolutePosition": {
          "id": 4,
          "type": "bool"
        },
        "disableAutoLayout": {
          "id": 2,
          "type": "bool"
        },
        "displayAIPoweredBadge": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "VPolygonStarData": {
      "fields": {
        "showType": {
          "id": 5,
          "type": "NodeType"
        },
        "pointCount": {
          "id": 3,
          "type": "MixIntProp"
        },
        "innerRadius": {
          "id": 4,
          "type": "MixProp"
        },
        "showInnerRadius": {
          "id": 2,
          "type": "bool"
        },
        "show": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "VPresentFrameCounter": {
      "fields": {
        "currentFrameIndex": {
          "id": 6,
          "type": "int32"
        },
        "totalFrameCount": {
          "id": 5,
          "type": "int32"
        },
        "showButton": {
          "id": 1,
          "type": "bool"
        },
        "showNumber": {
          "id": 4,
          "type": "bool"
        },
        "nextFrameNodeId": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "prevFrameNodeId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_nextFrameNodeId": {
          "oneof": [
            "nextFrameNodeId"
          ]
        },
        "_prevFrameNodeId": {
          "oneof": [
            "prevFrameNodeId"
          ]
        }
      }
    },
    "VPresentOptionItemState": {
      "fields": {
        "enable": {
          "id": 2,
          "type": "bool"
        },
        "isShow": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "VPreviewFlows": {
      "fields": {
        "flows": {
          "id": 1,
          "rule": "repeated",
          "type": "PreviewFlowInfo"
        }
      }
    },
    "VPrototypeDevice": {
      "fields": {
        "config": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "PrototypeDevice"
        }
      },
      "oneofs": {
        "_config": {
          "oneof": [
            "config"
          ]
        }
      }
    },
    "VPrototypeFlowList": {
      "fields": {
        "list": {
          "id": 1,
          "rule": "repeated",
          "type": "FlowForPanel"
        }
      }
    },
    "VPrototypeFlowListForSelection": {
      "fields": {
        "value": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "FlowForPanel"
        },
        "showType": {
          "id": 2,
          "type": "FlowListForSelectionShowType"
        },
        "show": {
          "id": 1,
          "type": "bool"
        }
      },
      "oneofs": {
        "_value": {
          "oneof": [
            "value"
          ]
        }
      }
    },
    "VPrototypeInteractionActionAnimation": {
      "fields": {
        "showPreviewArea": {
          "id": 6,
          "type": "bool"
        },
        "easingFunction": {
          "id": 5,
          "type": "EasingFunctionPropValue"
        },
        "transitionDuration": {
          "id": 4,
          "type": "TransitionDurationPropValue"
        },
        "easingType": {
          "id": 3,
          "type": "EasingTypePropValue"
        },
        "transitionShouldSmartAnimate": {
          "id": 2,
          "type": "TransitionShouldSmartAnimate"
        },
        "transitionType": {
          "id": 1,
          "type": "TransitionTypeProtoValue"
        }
      }
    },
    "VPrototypePresentCanvasPadding": {
      "fields": {
        "paddingY": {
          "id": 2,
          "type": "int32"
        },
        "paddingX": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "VPrototypePresentDeviceFrameRegion": {
      "fields": {
        "deviceImageUrl": {
          "id": 5,
          "type": "string"
        },
        "deviceImagePadding": {
          "id": 4,
          "type": "Vector"
        },
        "deviceImageSize": {
          "id": 3,
          "type": "Vector"
        },
        "deviceImageRotated": {
          "id": 2,
          "type": "bool"
        },
        "showDeviceImage": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "VPrototypePresentScaling": {
      "fields": {
        "nextScaling": {
          "id": 3,
          "type": "PrototypePresentScaling"
        },
        "currentScaling": {
          "id": 2,
          "type": "PrototypePresentScaling"
        },
        "validScalingList": {
          "id": 1,
          "rule": "repeated",
          "type": "PrototypePresentScaling"
        }
      }
    },
    "VPublishModal": {
      "fields": {
        "changedComponentTitle": {
          "id": 27,
          "type": "VPublishModalCollapseControl"
        },
        "changedVariableSetTitle": {
          "id": 25,
          "type": "VPublishModalCollapseControl"
        },
        "hasChanges": {
          "id": 14,
          "type": "bool"
        },
        "hasInvalids": {
          "id": 17,
          "type": "bool"
        },
        "hasHiddens": {
          "id": 16,
          "type": "bool"
        },
        "changedStyleTitle": {
          "id": 26,
          "type": "VPublishModalCollapseControl"
        },
        "hasUnchanges": {
          "id": 15,
          "type": "bool"
        },
        "changedControl": {
          "id": 21,
          "type": "VPublishModalCollapseControl"
        },
        "invalidControl": {
          "id": 20,
          "type": "VPublishModalCollapseControl"
        },
        "shareScopeType": {
          "id": 12,
          "type": "LibraryShareScopeType"
        },
        "hiddenTip": {
          "id": 24,
          "type": "VPublishModalNormalControl"
        },
        "filterText": {
          "id": 11,
          "type": "string"
        },
        "changes": {
          "id": 1,
          "rule": "repeated",
          "type": "VPublishModalChangeItem"
        },
        "hiddenControl": {
          "id": 23,
          "type": "VPublishModalCollapseControl"
        },
        "variableUnchanges": {
          "id": 3,
          "rule": "repeated",
          "type": "VPublishModalCommonItem"
        },
        "allChangeCount": {
          "id": 10,
          "type": "int32"
        },
        "unchangeControl": {
          "id": 22,
          "type": "VPublishModalCollapseControl"
        },
        "checkedCount": {
          "id": 9,
          "type": "int32"
        },
        "containerHeight": {
          "id": 19,
          "type": "float"
        },
        "variableHiddens": {
          "id": 6,
          "rule": "repeated",
          "type": "VPublishModalCommonItem"
        },
        "containerWidth": {
          "id": 18,
          "type": "float"
        },
        "description": {
          "id": 13,
          "type": "string"
        },
        "invalids": {
          "id": 7,
          "rule": "repeated",
          "type": "VPublishModalInvalidItem"
        },
        "publishing": {
          "id": 8,
          "type": "bool"
        },
        "variableChanges": {
          "id": 2,
          "rule": "repeated",
          "type": "VPublishModalChangeItem"
        },
        "hiddens": {
          "id": 5,
          "rule": "repeated",
          "type": "VPublishModalCommonItem"
        },
        "unchanges": {
          "id": 4,
          "rule": "repeated",
          "type": "VPublishModalCommonItem"
        }
      }
    },
    "VPublishModalChangeItem": {
      "fields": {
        "variableSetId": {
          "id": 15,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "docNameOfMovedTo": {
          "id": 14,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "docNameOfMovedFrom": {
          "id": 13,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "isVariantInsideMovedAway": {
          "id": 12,
          "type": "bool"
        },
        "isVariantInsideMoveIn": {
          "id": 11,
          "type": "bool"
        },
        "publishHidden": {
          "id": 3,
          "type": "bool"
        },
        "control": {
          "id": 10,
          "type": "VPublishModalNormalControl"
        },
        "thumbnailData": {
          "id": 8,
          "type": "ThumbnailData"
        },
        "changeType": {
          "id": 5,
          "type": "LibraryChangeType"
        },
        "changeKey": {
          "id": 4,
          "type": "string"
        },
        "name": {
          "id": 6,
          "type": "string"
        },
        "hasLocal": {
          "id": 1,
          "type": "bool"
        },
        "checked": {
          "id": 9,
          "type": "bool"
        },
        "nodeId": {
          "id": 2,
          "type": "string"
        },
        "description": {
          "id": 7,
          "type": "string"
        }
      },
      "oneofs": {
        "_variableSetId": {
          "oneof": [
            "variableSetId"
          ]
        },
        "_docNameOfMovedTo": {
          "oneof": [
            "docNameOfMovedTo"
          ]
        },
        "_docNameOfMovedFrom": {
          "oneof": [
            "docNameOfMovedFrom"
          ]
        }
      }
    },
    "VPublishModalCollapseControl": {
      "fields": {
        "visible": {
          "id": 2,
          "type": "bool"
        },
        "top": {
          "id": 4,
          "type": "float"
        },
        "height": {
          "id": 3,
          "type": "float"
        },
        "expand": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "VPublishModalCommonItem": {
      "fields": {
        "control": {
          "id": 7,
          "type": "VPublishModalNormalControl"
        },
        "thumbnailData": {
          "id": 5,
          "type": "ThumbnailData"
        },
        "name": {
          "id": 4,
          "type": "string"
        },
        "publishHidden": {
          "id": 3,
          "type": "bool"
        },
        "nodeId": {
          "id": 2,
          "type": "string"
        },
        "variableSetId": {
          "id": 8,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "description": {
          "id": 6,
          "type": "string"
        },
        "hasLocal": {
          "id": 1,
          "type": "bool"
        }
      },
      "oneofs": {
        "_variableSetId": {
          "oneof": [
            "variableSetId"
          ]
        }
      }
    },
    "VPublishModalInvalidItem": {
      "fields": {
        "control": {
          "id": 8,
          "type": "VPublishModalNormalControl"
        },
        "thumbnailData": {
          "id": 6,
          "type": "ThumbnailData"
        },
        "name": {
          "id": 4,
          "type": "string"
        },
        "publishHidden": {
          "id": 3,
          "type": "bool"
        },
        "nodeId": {
          "id": 2,
          "type": "string"
        },
        "description": {
          "id": 7,
          "type": "string"
        },
        "invalidType": {
          "id": 5,
          "type": "PublishMixinInvalidChangeType"
        },
        "hasLocal": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "VPublishModalNormalControl": {
      "fields": {
        "top": {
          "id": 3,
          "type": "float"
        },
        "height": {
          "id": 2,
          "type": "float"
        },
        "visible": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "VRightClickLayerSelection": {
      "fields": {
        "isShowLayerSelection": {
          "id": 2,
          "type": "bool"
        },
        "rightClickLayerSelectionNodes": {
          "id": 1,
          "rule": "repeated",
          "type": "RightClickLayerSelectionNode"
        }
      }
    },
    "VScaleToolPanel": {
      "fields": {
        "editorModeJustChanged": {
          "id": 8,
          "type": "bool"
        },
        "height": {
          "id": 6,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "origin": {
          "id": 1,
          "type": "LayoutOrigin"
        },
        "disableWidth": {
          "id": 5,
          "type": "bool"
        },
        "width": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "disableHeight": {
          "id": 7,
          "type": "bool"
        },
        "disableScaleFactorPercent": {
          "id": 3,
          "type": "bool"
        },
        "scaleFactorPercent": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        }
      },
      "oneofs": {
        "_height": {
          "oneof": [
            "height"
          ]
        },
        "_width": {
          "oneof": [
            "width"
          ]
        },
        "_scaleFactorPercent": {
          "oneof": [
            "scaleFactorPercent"
          ]
        }
      }
    },
    "VSearchSortFactor": {
      "fields": {
        "uniqKey": {
          "id": 4,
          "type": "string"
        },
        "weight": {
          "id": 3,
          "type": "int32"
        },
        "ranks": {
          "id": 2,
          "rule": "repeated",
          "type": "int32"
        },
        "similarity": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "VSelectionEffectState": {
      "fields": {
        "variables": {
          "id": 7,
          "rule": "repeated",
          "type": "SingleVariable"
        },
        "floatVariables": {
          "id": 8,
          "rule": "repeated",
          "type": "SingleFloatVariable"
        },
        "enableInsertEffectOperation": {
          "id": 5,
          "type": "bool"
        },
        "enableEffectsSpread": {
          "id": 4,
          "type": "bool"
        },
        "effectStyle": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "VSelectionEffectStyleState"
        },
        "effects": {
          "id": 2,
          "rule": "repeated",
          "type": "Effect"
        },
        "enableSelectEffectStyleOperation": {
          "id": 6,
          "type": "bool"
        },
        "type": {
          "id": 1,
          "type": "VSelectionEffectType"
        }
      },
      "oneofs": {
        "_effectStyle": {
          "oneof": [
            "effectStyle"
          ]
        }
      }
    },
    "VSelectionEffectStyleState": {
      "fields": {
        "variables": {
          "id": 6,
          "rule": "repeated",
          "type": "SingleVariable"
        },
        "description": {
          "id": 3,
          "type": "string"
        },
        "key": {
          "id": 5,
          "type": "string"
        },
        "effects": {
          "id": 4,
          "rule": "repeated",
          "type": "Effect"
        },
        "styleName": {
          "id": 2,
          "type": "string"
        },
        "styleId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_styleId": {
          "oneof": [
            "styleId"
          ]
        }
      }
    },
    "VSelectionHyperlinkState": {
      "fields": {
        "sameHyperlink": {
          "id": 2,
          "type": "Hyperlink"
        },
        "type": {
          "id": 1,
          "type": "SelectionHyperlinkType"
        }
      }
    },
    "VSelectionLayoutGridState": {
      "fields": {
        "variables": {
          "id": 5,
          "rule": "repeated",
          "type": "SingleFloatVariable"
        },
        "layoutGridStyleNode": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "VLayoutGridStyleNode"
        },
        "layoutGridStyleId": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "layoutGrids": {
          "id": 2,
          "rule": "repeated",
          "type": "LayoutGrid"
        },
        "type": {
          "id": 1,
          "type": "VSelectionLayoutGridType"
        }
      },
      "oneofs": {
        "_layoutGridStyleNode": {
          "oneof": [
            "layoutGridStyleNode"
          ]
        },
        "_layoutGridStyleId": {
          "oneof": [
            "layoutGridStyleId"
          ]
        }
      }
    },
    "VSelectionPaintState": {
      "fields": {
        "paintInfos": {
          "id": 4,
          "rule": "repeated",
          "type": "PaintInfo"
        },
        "emptyDueToLimitExceeded": {
          "id": 3,
          "type": "bool"
        },
        "uniqueSelectionNodeId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "styleInfos": {
          "id": 5,
          "rule": "repeated",
          "type": "PaintStyleInfo"
        },
        "show": {
          "id": 1,
          "type": "bool"
        }
      },
      "oneofs": {
        "_uniqueSelectionNodeId": {
          "oneof": [
            "uniqueSelectionNodeId"
          ]
        }
      }
    },
    "VSelectionPrototypeInteraction": {
      "fields": {
        "selectedTargetNodeList": {
          "id": 12,
          "rule": "repeated",
          "type": "PrototypeInteractionSelectedTargetNode"
        },
        "isShow": {
          "id": 2,
          "type": "bool"
        },
        "outerTargetNodes": {
          "id": 10,
          "rule": "repeated",
          "type": "PrototypeInteractionTargetNode"
        },
        "selectedInteractionKeys": {
          "id": 9,
          "rule": "repeated",
          "type": "PrototypeInteractionKey"
        },
        "popupPosition": {
          "id": 14,
          "type": "PopupPosition"
        },
        "targetNodes": {
          "id": 8,
          "rule": "repeated",
          "type": "PrototypeInteractionTargetNode"
        },
        "overlaySetting": {
          "id": 13,
          "type": "OverlaySetting"
        },
        "isShowResetButton": {
          "id": 6,
          "type": "bool"
        },
        "disabledDragging": {
          "id": 4,
          "type": "bool"
        },
        "forceHideCanvasPopup": {
          "id": 5,
          "type": "bool"
        },
        "interactions": {
          "id": 7,
          "rule": "repeated",
          "type": "PrototypeInteractionWithNodeId"
        },
        "scrollToTargetNodeList": {
          "id": 11,
          "type": "ScrollToTargetNodeList"
        },
        "isEmpty": {
          "id": 3,
          "type": "bool"
        },
        "isSingleNode": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "VSelectionStrokeState": {
      "fields": {
        "dashLenState": {
          "id": 9,
          "type": "SelectionStrokeDashLenState"
        },
        "miterAngleState": {
          "id": 6,
          "type": "SelectionStrokeMiterAngleState"
        },
        "joinState": {
          "id": 5,
          "type": "SelectionStrokeJoinState"
        },
        "modeState": {
          "id": 7,
          "type": "SelectionStrokeModeState"
        },
        "alignState": {
          "id": 4,
          "type": "SelectionStrokeAlignState"
        },
        "endPointStyleState": {
          "id": 11,
          "type": "SelectionStrokeEndPointStyleState"
        },
        "weightState": {
          "id": 3,
          "type": "SelectionStrokeWeightState"
        },
        "dashCapState": {
          "id": 8,
          "type": "SelectionStrokeDashCapState"
        },
        "paintState": {
          "id": 2,
          "type": "SelectionStrokePaintState"
        },
        "dashGapState": {
          "id": 10,
          "type": "SelectionStrokeDashGapState"
        },
        "showPanel": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "VSelectionTextState": {
      "fields": {
        "missFontInfo": {
          "id": 25,
          "type": "VMissFontInfo"
        },
        "fontSize": {
          "id": 16,
          "type": "VTextFontSizeState"
        },
        "textCase": {
          "id": 21,
          "type": "VTextTextCaseState"
        },
        "lineHeight": {
          "id": 18,
          "type": "VTextLineHeightState"
        },
        "fontStyleName": {
          "id": 15,
          "type": "VTextFontStyleNameState"
        },
        "textStyleNode": {
          "id": 11,
          "options": {
            "proto3_optional": true
          },
          "type": "VTextStyleNode"
        },
        "textDecoration": {
          "id": 17,
          "type": "VTextDecorationState"
        },
        "fontName": {
          "id": 13,
          "type": "VTextFontNameState"
        },
        "selectionFontNames": {
          "id": 12,
          "rule": "repeated",
          "type": "FontName"
        },
        "letterSpacing": {
          "id": 20,
          "type": "VTextLetterSpacingState"
        },
        "textStyleId": {
          "id": 10,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "lineHeightAutoValue": {
          "id": 19,
          "type": "VTextLineHeightAutoValueState"
        },
        "textAlignVertical": {
          "id": 3,
          "type": "VTextAlignVerticalState"
        },
        "customStyleState": {
          "id": 24,
          "type": "VFontVariableCustomStyleState"
        },
        "textTruncation": {
          "id": 7,
          "type": "VTextTextTruncationState"
        },
        "paragraphSpacing": {
          "id": 6,
          "type": "VTextParagraphSpacingState"
        },
        "textAlignHorizontal": {
          "id": 2,
          "type": "VTextAlignHorizontalState"
        },
        "textStyleType": {
          "id": 9,
          "type": "VTextStyleStateType"
        },
        "hasTextSelection": {
          "id": 1,
          "type": "bool"
        },
        "fontFamilyName": {
          "id": 14,
          "type": "VTextFontFamilyNameState"
        },
        "paragraphIndent": {
          "id": 5,
          "type": "VTextParagraphIndentState"
        },
        "tabKey": {
          "id": 23,
          "type": "TextSettingTabKey"
        },
        "variationInfo": {
          "id": 22,
          "type": "VTextFontVariationState"
        },
        "maxLines": {
          "id": 8,
          "type": "VTextMaxLinesState"
        },
        "textAutoResize": {
          "id": 4,
          "type": "VTextAutoResizeState"
        }
      },
      "oneofs": {
        "_textStyleNode": {
          "oneof": [
            "textStyleNode"
          ]
        },
        "_textStyleId": {
          "oneof": [
            "textStyleId"
          ]
        }
      }
    },
    "VSidebarPanels": {
      "fields": {
        "sidebarPanels": {
          "id": 1,
          "rule": "repeated",
          "type": "SidebarPanel"
        }
      }
    },
    "VSmartSelection": {
      "fields": {
        "vertical": {
          "id": 5,
          "type": "float"
        },
        "showVertical": {
          "id": 4,
          "type": "bool"
        },
        "horizontal": {
          "id": 3,
          "type": "float"
        },
        "showHorizontal": {
          "id": 2,
          "type": "bool"
        },
        "enabled": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "VTextAlignHorizontalState": {
      "fields": {
        "value": {
          "id": 2,
          "type": "TextAlignHorizontal"
        },
        "type": {
          "id": 1,
          "type": "VTextStateType"
        }
      }
    },
    "VTextAlignVerticalState": {
      "fields": {
        "value": {
          "id": 2,
          "type": "TextAlignVertical"
        },
        "type": {
          "id": 1,
          "type": "VTextStateType"
        }
      }
    },
    "VTextAutoResizeState": {
      "fields": {
        "value": {
          "id": 2,
          "type": "TextAutoResize"
        },
        "type": {
          "id": 1,
          "type": "VTextStateType"
        }
      }
    },
    "VTextDecorationState": {
      "fields": {
        "value": {
          "id": 2,
          "type": "TextDecoration"
        },
        "type": {
          "id": 1,
          "type": "VTextStateType"
        }
      }
    },
    "VTextEditingState": {
      "fields": {
        "cursorInfo": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "SkRect"
        },
        "textEditing": {
          "id": 1,
          "type": "bool"
        }
      },
      "oneofs": {
        "_cursorInfo": {
          "oneof": [
            "cursorInfo"
          ]
        }
      }
    },
    "VTextFontFamilyNameState": {
      "fields": {
        "localizedFamily": {
          "id": 3,
          "type": "string"
        },
        "family": {
          "id": 2,
          "type": "string"
        },
        "type": {
          "id": 1,
          "type": "VTextStateType"
        }
      }
    },
    "VTextFontNameState": {
      "fields": {
        "customAxesStyle": {
          "id": 3,
          "type": "string"
        },
        "value": {
          "id": 2,
          "type": "FontName"
        },
        "customAxesStyleDisplay": {
          "id": 4,
          "type": "string"
        },
        "type": {
          "id": 1,
          "type": "VTextStateType"
        }
      }
    },
    "VTextFontSizeState": {
      "fields": {
        "value": {
          "id": 2,
          "type": "float"
        },
        "type": {
          "id": 1,
          "type": "VTextStateType"
        }
      }
    },
    "VTextFontStyleNameState": {
      "fields": {
        "localizedStyle": {
          "id": 3,
          "type": "string"
        },
        "style": {
          "id": 2,
          "type": "string"
        },
        "type": {
          "id": 1,
          "type": "VTextStateType"
        }
      }
    },
    "VTextFontVariationState": {
      "fields": {
        "variationAxisTickValues": {
          "id": 4,
          "keyType": "string",
          "type": "BridgeProtoFloatArray"
        },
        "variationAxisValue": {
          "id": 3,
          "rule": "repeated",
          "type": "VFontVariationInstanceAxes"
        },
        "detachOpticalSizeFromFontSize": {
          "id": 2,
          "type": "VDetachOpticalSizeFromFontSize"
        },
        "hasValue": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "VTextLetterSpacingState": {
      "fields": {
        "value": {
          "id": 2,
          "type": "LetterSpacing"
        },
        "type": {
          "id": 1,
          "type": "VTextStateType"
        }
      }
    },
    "VTextLineHeightAutoValueState": {
      "fields": {
        "value": {
          "id": 2,
          "type": "float"
        },
        "type": {
          "id": 1,
          "type": "VTextStateType"
        }
      }
    },
    "VTextLineHeightState": {
      "fields": {
        "value": {
          "id": 2,
          "type": "LineHeight"
        },
        "type": {
          "id": 1,
          "type": "VTextStateType"
        }
      }
    },
    "VTextMaxLinesState": {
      "fields": {
        "value": {
          "id": 2,
          "type": "int32"
        },
        "type": {
          "id": 1,
          "type": "VTextStateType"
        }
      }
    },
    "VTextParagraphIndentState": {
      "fields": {
        "value": {
          "id": 2,
          "type": "float"
        },
        "type": {
          "id": 1,
          "type": "VTextStateType"
        }
      }
    },
    "VTextParagraphSpacingState": {
      "fields": {
        "value": {
          "id": 2,
          "type": "float"
        },
        "type": {
          "id": 1,
          "type": "VTextStateType"
        }
      }
    },
    "VTextStyleNode": {
      "fields": {
        "fontVariations": {
          "id": 11,
          "rule": "repeated",
          "type": "FontVariation"
        },
        "paragraphSpacing": {
          "id": 5,
          "type": "float"
        },
        "lineHeight": {
          "id": 9,
          "type": "LineHeight"
        },
        "description": {
          "id": 3,
          "type": "string"
        },
        "letterSpacing": {
          "id": 10,
          "type": "LetterSpacing"
        },
        "detachOpticalSizeFromFontSize": {
          "id": 13,
          "type": "bool"
        },
        "key": {
          "id": 12,
          "type": "string"
        },
        "fontName": {
          "id": 7,
          "type": "FontName"
        },
        "textDecoration": {
          "id": 8,
          "type": "TextDecoration"
        },
        "name": {
          "id": 2,
          "type": "string"
        },
        "fontSize": {
          "id": 6,
          "type": "float"
        },
        "missFontInfo": {
          "id": 14,
          "type": "VMissFontInfo"
        },
        "paragraphIndent": {
          "id": 4,
          "type": "float"
        },
        "id": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_id": {
          "oneof": [
            "id"
          ]
        }
      }
    },
    "VTextStyleNodeState": {
      "fields": {
        "customAxesStyleDisplay": {
          "id": 5,
          "type": "string"
        },
        "customAxesStyle": {
          "id": 4,
          "type": "string"
        },
        "customStyleState": {
          "id": 3,
          "type": "VFontVariableCustomStyleState"
        },
        "variationInfo": {
          "id": 2,
          "type": "VTextFontVariationState"
        },
        "basic": {
          "id": 1,
          "type": "VTextStyleNode"
        }
      }
    },
    "VTextTextCaseState": {
      "fields": {
        "value": {
          "id": 2,
          "type": "TextCase"
        },
        "type": {
          "id": 1,
          "type": "VTextStateType"
        }
      }
    },
    "VTextTextTruncationState": {
      "fields": {
        "value": {
          "id": 2,
          "type": "TextTruncation"
        },
        "type": {
          "id": 1,
          "type": "VTextStateType"
        }
      }
    },
    "VTextValUnit": {
      "fields": {
        "isMixed": {
          "id": 2,
          "type": "bool"
        },
        "val": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "VTopArea": {
      "fields": {
        "isEditingSinglePaint": {
          "id": 3,
          "type": "bool"
        },
        "isEditVector": {
          "id": 2,
          "type": "bool"
        },
        "editorMode": {
          "id": 1,
          "type": "EditorMode"
        }
      }
    },
    "VVariableSetModeSelectionState": {
      "fields": {
        "selectionType": {
          "id": 3,
          "type": "VariableSetModeSelectionType"
        },
        "isDeleted": {
          "id": 2,
          "type": "bool"
        },
        "selectedMode": {
          "id": 1,
          "type": "VariableSetMode"
        }
      }
    },
    "VVariableSetModeState": {
      "fields": {
        "conflictType": {
          "id": 2,
          "type": "VariableSetModeConflictType"
        },
        "variableSetMode": {
          "id": 1,
          "type": "VariableSetMode"
        }
      }
    },
    "VVectorPanel": {
      "fields": {
        "cornerRadius": {
          "id": 4,
          "type": "VectorPanelFloat"
        },
        "handleMirror": {
          "id": 3,
          "type": "VectorPanelHandleMirror"
        },
        "y": {
          "id": 2,
          "type": "VectorPanelFloat"
        },
        "x": {
          "id": 1,
          "type": "VectorPanelFloat"
        }
      }
    },
    "ValidateAIReplaceSceneCommandParam": {
      "fields": {
        "nodeIdsArray": {
          "id": 2,
          "rule": "repeated",
          "type": "NodeIdsWrapper"
        },
        "container": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_container": {
          "oneof": [
            "container"
          ]
        }
      }
    },
    "ValidateAIReplaceSceneCommandRet": {
      "fields": {
        "resultArray": {
          "id": 3,
          "rule": "repeated",
          "type": "NodeIdsWrapper"
        },
        "errorType": {
          "id": 2,
          "type": "ValidateAIReplaceSceneErrorType"
        },
        "success": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "VariableAliasData": {
      "fields": {
        "dataType": {
          "id": 2,
          "type": "VariableDataType"
        },
        "resolvedDataType": {
          "id": 3,
          "type": "VariableResolvedDataType"
        },
        "value": {
          "id": 1,
          "type": "VariableAliasValue"
        }
      }
    },
    "VariableAliasOfComponentProperty": {
      "fields": {
        "dataType": {
          "id": 2,
          "type": "VariableDataType"
        },
        "id": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_id": {
          "oneof": [
            "id"
          ]
        }
      }
    },
    "VariableAliasValue": {
      "fields": {
        "alias": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_alias": {
          "oneof": [
            "alias"
          ]
        }
      }
    },
    "VariableAnyValue": {
      "fields": {
        "colorValue": {
          "id": 7,
          "type": "RGBA"
        },
        "alias": {
          "id": 6,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "textDataValue": {
          "id": 5,
          "type": "TextData"
        },
        "mainComponentId": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "floatValue": {
          "id": 3,
          "type": "float"
        },
        "textValue": {
          "id": 2,
          "type": "string"
        },
        "boolValue": {
          "id": 1,
          "type": "bool"
        }
      },
      "oneofs": {
        "_alias": {
          "oneof": [
            "alias"
          ]
        },
        "_mainComponentId": {
          "oneof": [
            "mainComponentId"
          ]
        }
      }
    },
    "VariableBindableComponentProperty": {
      "fields": {
        "value": {
          "id": 1,
          "type": "VariableAliasOfComponentProperty"
        }
      }
    },
    "VariableCodeSyntax": {
      "fields": {
        "value": {
          "id": 2,
          "type": "string"
        },
        "platform": {
          "id": 1,
          "type": "VariableCodePlatform"
        }
      }
    },
    "VariableCollectionLibraryDataUpdateVO": {
      "fields": {
        "nodeDataV2": {
          "id": 5,
          "type": "string"
        },
        "contentHash": {
          "id": 4,
          "type": "string"
        },
        "modes": {
          "id": 3,
          "type": "string"
        },
        "name": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "nodeId": {
          "id": 1,
          "type": "string"
        }
      },
      "oneofs": {
        "_name": {
          "oneof": [
            "name"
          ]
        }
      }
    },
    "VariableConsumptionItem": {
      "fields": {
        "variableData": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "VariableAliasData"
        },
        "field": {
          "id": 2,
          "type": "VariableField"
        },
        "type": {
          "id": 1,
          "type": "VariableConsumptionItemType"
        }
      },
      "oneofs": {
        "_variableData": {
          "oneof": [
            "variableData"
          ]
        }
      }
    },
    "VariableConsumptionMap": {
      "fields": {
        "variableData": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "VariableAliasData"
        },
        "variableField": {
          "id": 1,
          "type": "VariableField"
        }
      },
      "oneofs": {
        "_variableData": {
          "oneof": [
            "variableData"
          ]
        }
      }
    },
    "VariableConsumptionMapState": {
      "fields": {
        "floatVariables": {
          "id": 2,
          "rule": "repeated",
          "type": "SingleFloatVariable"
        },
        "items": {
          "id": 1,
          "rule": "repeated",
          "type": "VariableConsumptionItem"
        }
      }
    },
    "VariableCreate": {
      "fields": {
        "localVisibleCollections": {
          "id": 1,
          "rule": "repeated",
          "type": "VariableCreateLocalVisibleCollections"
        }
      }
    },
    "VariableCreateLocalVisibleCollections": {
      "fields": {
        "name": {
          "id": 2,
          "type": "string"
        },
        "id": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "VariableData": {
      "fields": {
        "dataType": {
          "id": 2,
          "type": "VariableDataType"
        },
        "resolvedDataType": {
          "id": 3,
          "type": "VariableResolvedDataType"
        },
        "value": {
          "id": 1,
          "type": "VariableAnyValue"
        }
      }
    },
    "VariableDataLite": {
      "fields": {
        "colorValue": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "RGBA"
        },
        "textValue": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "boolValue": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "floatValue": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "resolvedDataType": {
          "id": 1,
          "type": "VariableResolvedDataType"
        }
      },
      "oneofs": {
        "_colorValue": {
          "oneof": [
            "colorValue"
          ]
        },
        "_textValue": {
          "oneof": [
            "textValue"
          ]
        },
        "_boolValue": {
          "oneof": [
            "boolValue"
          ]
        },
        "_floatValue": {
          "oneof": [
            "floatValue"
          ]
        }
      }
    },
    "VariableDataValue": {
      "fields": {
        "variableData": {
          "id": 2,
          "type": "VariableData"
        },
        "modeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_modeId": {
          "oneof": [
            "modeId"
          ]
        }
      }
    },
    "VariableGetVO": {
      "fields": {
        "nodeDataPath": {
          "id": 14,
          "type": "string"
        },
        "removed": {
          "id": 12,
          "type": "bool"
        },
        "value": {
          "id": 13,
          "keyType": "string",
          "type": "VariableDataLite"
        },
        "contentHash": {
          "id": 9,
          "type": "string"
        },
        "type": {
          "id": 8,
          "options": {
            "proto3_optional": true
          },
          "type": "VariableResolvedDataType"
        },
        "sortPosition": {
          "id": 10,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "nodeId": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "description": {
          "id": 7,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "scope": {
          "id": 11,
          "rule": "repeated",
          "type": "VariableScope"
        },
        "libraryId": {
          "id": 2,
          "type": "string"
        },
        "name": {
          "id": 6,
          "type": "string"
        },
        "collectionId": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "documentId": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "id": {
          "id": 1,
          "type": "string"
        }
      },
      "oneofs": {
        "_type": {
          "oneof": [
            "type"
          ]
        },
        "_sortPosition": {
          "oneof": [
            "sortPosition"
          ]
        },
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        },
        "_description": {
          "oneof": [
            "description"
          ]
        },
        "_collectionId": {
          "oneof": [
            "collectionId"
          ]
        },
        "_documentId": {
          "oneof": [
            "documentId"
          ]
        }
      }
    },
    "VariableLayerModeState": {
      "fields": {
        "hasDeleted": {
          "id": 2,
          "type": "bool"
        },
        "variableSetIds": {
          "id": 3,
          "rule": "repeated",
          "type": "string"
        },
        "mode": {
          "id": 1,
          "type": "VariableSetMode"
        }
      }
    },
    "VariableLayerModeStateVector": {
      "fields": {
        "states": {
          "id": 1,
          "rule": "repeated",
          "type": "VariableLayerModeState"
        }
      }
    },
    "VariableLayerUsingModeMap": {
      "fields": {
        "nodeUsingModes": {
          "id": 1,
          "keyType": "string",
          "type": "VariableLayerModeStateVector"
        }
      }
    },
    "VariableLibraryDataUpdateVO": {
      "fields": {
        "value": {
          "id": 10,
          "type": "string"
        },
        "sortPosition": {
          "id": 9,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "collectionSetNodeId": {
          "id": 8,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "contentHash": {
          "id": 6,
          "type": "string"
        },
        "nodeId": {
          "id": 1,
          "type": "string"
        },
        "scope": {
          "id": 5,
          "type": "string"
        },
        "description": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "collectionId": {
          "id": 7,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "name": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "nodeDataV2": {
          "id": 11,
          "type": "string"
        },
        "type": {
          "id": 4,
          "type": "VariableResolvedDataType"
        }
      },
      "oneofs": {
        "_sortPosition": {
          "oneof": [
            "sortPosition"
          ]
        },
        "_collectionSetNodeId": {
          "oneof": [
            "collectionSetNodeId"
          ]
        },
        "_description": {
          "oneof": [
            "description"
          ]
        },
        "_collectionId": {
          "oneof": [
            "collectionId"
          ]
        },
        "_name": {
          "oneof": [
            "name"
          ]
        }
      }
    },
    "VariableModeBySetMap": {
      "fields": {
        "variableModeId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "variableSetId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_variableModeId": {
          "oneof": [
            "variableModeId"
          ]
        },
        "_variableSetId": {
          "oneof": [
            "variableSetId"
          ]
        }
      }
    },
    "VariableModeEntriesState": {
      "fields": {
        "remoteVariableSets": {
          "id": 4,
          "rule": "repeated",
          "type": "RemoteVariableSetGroup"
        },
        "unknownVariableSets": {
          "id": 3,
          "rule": "repeated",
          "type": "RemoteVariableSetItem"
        },
        "localVariableSets": {
          "id": 2,
          "rule": "repeated",
          "type": "LocalVariableSetItem"
        },
        "show": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "VariableModeInLogicMap": {
      "fields": {
        "value": {
          "id": 1,
          "keyType": "string",
          "type": "string"
        }
      }
    },
    "VariableSetGetVO": {
      "fields": {
        "removed": {
          "id": 7,
          "type": "bool"
        },
        "contentHash": {
          "id": 6,
          "type": "string"
        },
        "nodeId": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "modes": {
          "id": 9,
          "rule": "repeated",
          "type": "VariableSetMode"
        },
        "name": {
          "id": 5,
          "type": "string"
        },
        "nodeDataPath": {
          "id": 10,
          "type": "string"
        },
        "variableCount": {
          "id": 8,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "libraryId": {
          "id": 2,
          "type": "string"
        },
        "documentId": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "id": {
          "id": 1,
          "type": "string"
        }
      },
      "oneofs": {
        "_nodeId": {
          "oneof": [
            "nodeId"
          ]
        },
        "_variableCount": {
          "oneof": [
            "variableCount"
          ]
        },
        "_documentId": {
          "oneof": [
            "documentId"
          ]
        }
      }
    },
    "VariableSetMode": {
      "fields": {
        "modeName": {
          "id": 2,
          "type": "string"
        },
        "modeId": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_modeId": {
          "oneof": [
            "modeId"
          ]
        }
      }
    },
    "VariableSetUpdateVO": {
      "fields": {
        "nodeDataV2": {
          "id": 8,
          "type": "string"
        },
        "removed": {
          "id": 7,
          "type": "bool"
        },
        "contentHash": {
          "id": 6,
          "type": "string"
        },
        "nodeId": {
          "id": 3,
          "type": "string"
        },
        "modes": {
          "id": 5,
          "rule": "repeated",
          "type": "VariableSetMode"
        },
        "name": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "libraryId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "id": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_name": {
          "oneof": [
            "name"
          ]
        },
        "_libraryId": {
          "oneof": [
            "libraryId"
          ]
        },
        "_id": {
          "oneof": [
            "id"
          ]
        }
      }
    },
    "VariableUpdateVO": {
      "fields": {
        "collectionSetNodeId": {
          "id": 11,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "removed": {
          "id": 10,
          "type": "bool"
        },
        "value": {
          "id": 13,
          "keyType": "string",
          "type": "VariableDataLite"
        },
        "contentHash": {
          "id": 9,
          "type": "string"
        },
        "type": {
          "id": 7,
          "type": "VariableResolvedDataType"
        },
        "sortPosition": {
          "id": 12,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "nodeId": {
          "id": 4,
          "type": "string"
        },
        "description": {
          "id": 6,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "scope": {
          "id": 8,
          "rule": "repeated",
          "type": "VariableScope"
        },
        "collectionId": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "name": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "nodeDataV2": {
          "id": 14,
          "type": "string"
        },
        "libraryId": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "id": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        }
      },
      "oneofs": {
        "_collectionSetNodeId": {
          "oneof": [
            "collectionSetNodeId"
          ]
        },
        "_sortPosition": {
          "oneof": [
            "sortPosition"
          ]
        },
        "_description": {
          "oneof": [
            "description"
          ]
        },
        "_collectionId": {
          "oneof": [
            "collectionId"
          ]
        },
        "_name": {
          "oneof": [
            "name"
          ]
        },
        "_libraryId": {
          "oneof": [
            "libraryId"
          ]
        },
        "_id": {
          "oneof": [
            "id"
          ]
        }
      }
    },
    "VariantFontCustomAxesStyle": {
      "fields": {
        "name": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "VariantFontCustomAxis": {
      "fields": {
        "value": {
          "id": 2,
          "type": "float"
        },
        "name": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "VariantFontCustomInfo": {
      "fields": {
        "italic": {
          "id": 2,
          "type": "bool"
        },
        "customAxes": {
          "id": 1,
          "rule": "repeated",
          "type": "VariantFontCustomAxis"
        }
      }
    },
    "VariantOption": {
      "fields": {
        "variantAmount": {
          "id": 2,
          "type": "int32"
        },
        "optionValue": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "VariantProp": {
      "fields": {
        "isMixed": {
          "id": 3,
          "type": "bool"
        },
        "isInvalid": {
          "id": 4,
          "type": "bool"
        },
        "compSetProp": {
          "id": 2,
          "type": "ComponentSetProp"
        },
        "value": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "VariantPropKey2OrderedValuesMap": {
      "fields": {
        "value": {
          "id": 1,
          "keyType": "string",
          "type": "BridgeProtoStringArray"
        }
      }
    },
    "VariantState": {
      "fields": {
        "compSetDescUnit": {
          "id": 13,
          "type": "VComponentDescUnit"
        },
        "isVariantInstance": {
          "id": 8,
          "type": "bool"
        },
        "conflict": {
          "id": 11,
          "type": "CompSetPropConflict"
        },
        "variantProps": {
          "id": 10,
          "rule": "repeated",
          "type": "VariantProp"
        },
        "isRemovedVariantInstance": {
          "id": 9,
          "type": "bool"
        },
        "compSetProps": {
          "id": 6,
          "rule": "repeated",
          "type": "ComponentSetProp"
        },
        "isEmpty": {
          "id": 2,
          "type": "bool"
        },
        "variantDescUnit": {
          "id": 14,
          "type": "VComponentDescUnit"
        },
        "showMoreActions": {
          "id": 4,
          "type": "bool"
        },
        "showVariantProp": {
          "id": 7,
          "type": "bool"
        },
        "showCompSetProp": {
          "id": 5,
          "type": "bool"
        },
        "showAdjustConfig": {
          "id": 12,
          "type": "bool"
        },
        "showCombineAsVariant": {
          "id": 3,
          "type": "bool"
        },
        "shouldDisplay": {
          "id": 1,
          "type": "bool"
        }
      }
    },
    "Vector": {
      "fields": {
        "y": {
          "id": 2,
          "type": "float"
        },
        "x": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "VectorData": {
      "fields": {
        "normalizedSize": {
          "id": 2,
          "type": "Vector"
        },
        "vectorNetwork": {
          "id": 1,
          "type": "VectorNetwork"
        }
      }
    },
    "VectorNetwork": {
      "fields": {
        "regions": {
          "id": 3,
          "rule": "repeated",
          "type": "VectorRegion"
        },
        "segments": {
          "id": 2,
          "rule": "repeated",
          "type": "VectorSegment"
        },
        "vertices": {
          "id": 1,
          "rule": "repeated",
          "type": "VectorVertex"
        }
      }
    },
    "VectorNetworkLoop": {
      "fields": {
        "segmentIndices": {
          "id": 1,
          "rule": "repeated",
          "type": "uint32"
        }
      }
    },
    "VectorPanelFloat": {
      "fields": {
        "value": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "mixed": {
          "id": 1,
          "type": "bool"
        }
      },
      "oneofs": {
        "_value": {
          "oneof": [
            "value"
          ]
        }
      }
    },
    "VectorPanelHandleMirror": {
      "fields": {
        "value": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "VectorHandleMirror"
        },
        "mixed": {
          "id": 1,
          "type": "bool"
        }
      },
      "oneofs": {
        "_value": {
          "oneof": [
            "value"
          ]
        }
      }
    },
    "VectorRegion": {
      "fields": {
        "styleId": {
          "id": 3,
          "type": "uint32"
        },
        "windingRule": {
          "id": 2,
          "type": "WindingRule"
        },
        "loops": {
          "id": 1,
          "rule": "repeated",
          "type": "VectorNetworkLoop"
        }
      }
    },
    "VectorSegment": {
      "fields": {
        "tangentEnd": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "Vector"
        },
        "end": {
          "id": 2,
          "type": "uint32"
        },
        "tangentStart": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "Vector"
        },
        "start": {
          "id": 1,
          "type": "uint32"
        }
      },
      "oneofs": {
        "_tangentEnd": {
          "oneof": [
            "tangentEnd"
          ]
        },
        "_tangentStart": {
          "oneof": [
            "tangentStart"
          ]
        }
      }
    },
    "VectorVertex": {
      "fields": {
        "strokeJoin": {
          "id": 7,
          "type": "StrokeJoin"
        },
        "y": {
          "id": 3,
          "type": "float"
        },
        "strokeCap": {
          "id": 6,
          "type": "StrokeCap"
        },
        "handleMirroring": {
          "id": 1,
          "type": "VectorHandleMirror"
        },
        "cornerRadius": {
          "id": 4,
          "type": "float"
        },
        "x": {
          "id": 2,
          "type": "float"
        }
      }
    },
    "ViewState": {
      "fields": {
        "visibleViewport": {
          "id": 280,
          "options": {
            "proto3_optional": true
          },
          "type": "VisibleViewport"
        },
        "vectorPanel": {
          "id": 279,
          "options": {
            "proto3_optional": true
          },
          "type": "VVectorPanel"
        },
        "variableModeEntriesInSelect": {
          "id": 278,
          "options": {
            "proto3_optional": true
          },
          "type": "VariableModeEntriesState"
        },
        "variableModeEntriesInPage": {
          "id": 277,
          "options": {
            "proto3_optional": true
          },
          "type": "VariableModeEntriesState"
        },
        "variableLayerUsingMode": {
          "id": 276,
          "options": {
            "proto3_optional": true
          },
          "type": "VariableLayerUsingModeMap"
        },
        "variableCreate": {
          "id": 275,
          "options": {
            "proto3_optional": true
          },
          "type": "VariableCreate"
        },
        "topExtendBoolMenuVisible": {
          "id": 273,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "topArea": {
          "id": 272,
          "options": {
            "proto3_optional": true
          },
          "type": "VTopArea"
        },
        "textSearch": {
          "id": 270,
          "options": {
            "proto3_optional": true
          },
          "type": "TextSearch"
        },
        "textEditingState": {
          "id": 269,
          "options": {
            "proto3_optional": true
          },
          "type": "VTextEditingState"
        },
        "syncProgress": {
          "id": 268,
          "options": {
            "proto3_optional": true
          },
          "type": "UploadProgress"
        },
        "singleSelection": {
          "id": 266,
          "options": {
            "proto3_optional": true
          },
          "type": "SingleSelection"
        },
        "showSlice": {
          "id": 264,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "showShortcutPanel": {
          "id": 262,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "showMultipleRenameModal": {
          "id": 261,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "showComments": {
          "id": 257,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "selectionTextLine": {
          "id": 256,
          "options": {
            "proto3_optional": true
          },
          "type": "SelectionTextLineState"
        },
        "selectionText": {
          "id": 255,
          "options": {
            "proto3_optional": true
          },
          "type": "VSelectionTextState"
        },
        "selectionPrototypeInteraction": {
          "id": 253,
          "options": {
            "proto3_optional": true
          },
          "type": "VSelectionPrototypeInteraction"
        },
        "selectionPaint": {
          "id": 252,
          "options": {
            "proto3_optional": true
          },
          "type": "VSelectionPaintState"
        },
        "selectionHyperlinkState": {
          "id": 250,
          "options": {
            "proto3_optional": true
          },
          "type": "VSelectionHyperlinkState"
        },
        "selectionFill": {
          "id": 249,
          "options": {
            "proto3_optional": true
          },
          "type": "SelectionFillState"
        },
        "selectionColorScope": {
          "id": 247,
          "options": {
            "proto3_optional": true
          },
          "type": "SelectionColorScope"
        },
        "selectedStrokes": {
          "id": 246,
          "options": {
            "proto3_optional": true
          },
          "type": "SelectedStrokesState"
        },
        "selectedLayoutGrids": {
          "id": 245,
          "options": {
            "proto3_optional": true
          },
          "type": "SelectedLayoutGridsState"
        },
        "selectedGradientColorStopIndex": {
          "id": 244,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "selectedFills": {
          "id": 243,
          "options": {
            "proto3_optional": true
          },
          "type": "SelectedFillsState"
        },
        "scaleToolPanel": {
          "id": 240,
          "options": {
            "proto3_optional": true
          },
          "type": "VScaleToolPanel"
        },
        "rightClickSetThumbnailStatus": {
          "id": 239,
          "options": {
            "proto3_optional": true
          },
          "type": "RightClickSetThumbnailStatus"
        },
        "selectionEffect": {
          "id": 248,
          "options": {
            "proto3_optional": true
          },
          "type": "VSelectionEffectState"
        },
        "rightClickMenuState": {
          "id": 238,
          "options": {
            "proto3_optional": true
          },
          "type": "RightClickMenuState"
        },
        "publishModal": {
          "id": 236,
          "options": {
            "proto3_optional": true
          },
          "type": "VPublishModal"
        },
        "showDevModeInspectExport": {
          "id": 258,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "prototypeStartingPointEditingNameViewState": {
          "id": 233,
          "options": {
            "proto3_optional": true
          },
          "type": "PrototypeStartingPointEditingNameViewState"
        },
        "prototypePresentCanvasPaddingViewState": {
          "id": 230,
          "options": {
            "proto3_optional": true
          },
          "type": "VPrototypePresentCanvasPadding"
        },
        "prototypePanelScrollBehaviorViewState": {
          "id": 229,
          "options": {
            "proto3_optional": true
          },
          "type": "ScrollBehaviorState"
        },
        "prototypeFlowListForSelection": {
          "id": 228,
          "options": {
            "proto3_optional": true
          },
          "type": "VPrototypeFlowListForSelection"
        },
        "prototypeDevice": {
          "id": 226,
          "options": {
            "proto3_optional": true
          },
          "type": "VPrototypeDevice"
        },
        "prototypeBackgroundColor": {
          "id": 225,
          "options": {
            "proto3_optional": true
          },
          "type": "VPaint"
        },
        "previewFlowsViewState": {
          "id": 224,
          "options": {
            "proto3_optional": true
          },
          "type": "VPreviewFlows"
        },
        "presentShowHotspotHintViewState": {
          "id": 220,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "presentShowDeviceViewState": {
          "id": 219,
          "options": {
            "proto3_optional": true
          },
          "type": "VPresentOptionItemState"
        },
        "presentHideUI": {
          "id": 218,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "presentFrameCounterViewState": {
          "id": 216,
          "options": {
            "proto3_optional": true
          },
          "type": "VPresentFrameCounter"
        },
        "pluginState": {
          "id": 212,
          "options": {
            "proto3_optional": true
          },
          "type": "PluginViewState"
        },
        "replaceInstanceState": {
          "id": 237,
          "options": {
            "proto3_optional": true
          },
          "type": "ReplaceInstanceState"
        },
        "pluginRotateCopyViewState": {
          "id": 211,
          "options": {
            "proto3_optional": true
          },
          "type": "PluginRotateCopyViewState"
        },
        "pluginArcText": {
          "id": 208,
          "options": {
            "proto3_optional": true
          },
          "type": "PluginArcTextState"
        },
        "pluginAeExportEnabled": {
          "id": 207,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "previewDocumentLoadedState": {
          "id": 222,
          "options": {
            "proto3_optional": true
          },
          "type": "PreviewDocumentLoadedState"
        },
        "openingLibraryInUseCountInfo": {
          "id": 204,
          "options": {
            "proto3_optional": true
          },
          "type": "VOpeningLibraryInUseCountInfo"
        },
        "panelStateUnderAILayout": {
          "id": 206,
          "options": {
            "proto3_optional": true
          },
          "type": "VPanelStateUnderAILayout"
        },
        "nodePreviewTracksChanged": {
          "id": 203,
          "options": {
            "proto3_optional": true
          },
          "type": "int32"
        },
        "memoryUsage": {
          "id": 198,
          "options": {
            "proto3_optional": true
          },
          "type": "VMemoryUsage"
        },
        "localVariableCollection": {
          "id": 190,
          "options": {
            "proto3_optional": true
          },
          "type": "LocalVariableCollectionViewStateType"
        },
        "actionEnabledUnionStatus": {
          "id": 73,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "hyperlinkHoverPopupState": {
          "id": 161,
          "options": {
            "proto3_optional": true
          },
          "type": "HyperlinkHoverPopupState"
        },
        "layerSelection": {
          "id": 175,
          "options": {
            "proto3_optional": true
          },
          "type": "VRightClickLayerSelection"
        },
        "libraryRemoteListModal": {
          "id": 184,
          "options": {
            "proto3_optional": true
          },
          "type": "VLibraryRemoteListModal"
        },
        "libraryPaintStyleState": {
          "id": 183,
          "options": {
            "proto3_optional": true
          },
          "type": "LibraryPaintStyleState"
        },
        "maskPanelState": {
          "id": 197,
          "options": {
            "proto3_optional": true
          },
          "type": "VMaskPanelState"
        },
        "actionEnabledShowSidebarStatus": {
          "id": 62,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "inspectSelectionVariantViewState": {
          "id": 171,
          "options": {
            "proto3_optional": true
          },
          "type": "InspectSelectionVariantViewState"
        },
        "libraryCurrentLocalModal": {
          "id": 179,
          "options": {
            "proto3_optional": true
          },
          "type": "VLibraryCurrentLocalModal"
        },
        "candidateComponentReplaceState": {
          "id": 95,
          "options": {
            "proto3_optional": true
          },
          "type": "CandidateReplaceInstanceState"
        },
        "layerPanelAILayoutColorLine": {
          "id": 174,
          "options": {
            "proto3_optional": true
          },
          "type": "VLayerPanelAILayoutColorLine"
        },
        "libraryAssetPanel": {
          "id": 178,
          "options": {
            "proto3_optional": true
          },
          "type": "VLibraryAssetPanel"
        },
        "mainMenuVisible": {
          "id": 196,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "devModeHoverNodeLabelViewState": {
          "id": 124,
          "options": {
            "proto3_optional": true
          },
          "type": "VDevModeHoverNodeLabel"
        },
        "librariesColorVariable": {
          "id": 176,
          "options": {
            "proto3_optional": true
          },
          "type": "LibrariesColorVariable"
        },
        "layerPanel": {
          "id": 173,
          "options": {
            "proto3_optional": true
          },
          "type": "VLayerPanel"
        },
        "arcData": {
          "id": 84,
          "options": {
            "proto3_optional": true
          },
          "type": "VArcData"
        },
        "isPreviewPending": {
          "id": 172,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "inspectSelectionFillViewState": {
          "id": 168,
          "options": {
            "proto3_optional": true
          },
          "type": "InspectSelectionFillViewState"
        },
        "inspectSelectionCodeViewState": {
          "id": 165,
          "options": {
            "proto3_optional": true
          },
          "type": "InspectSelectionCodeViewState"
        },
        "pageTopArea": {
          "id": 205,
          "options": {
            "proto3_optional": true
          },
          "type": "VPageTopArea"
        },
        "inspectEffectViewState": {
          "id": 164,
          "options": {
            "proto3_optional": true
          },
          "type": "InspectEffectViewState"
        },
        "constraintsState": {
          "id": 111,
          "options": {
            "proto3_optional": true
          },
          "type": "VConstraintsState"
        },
        "actionEnabledRemoveStrokeStatus": {
          "id": 54,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "imageStates": {
          "id": 162,
          "options": {
            "proto3_optional": true
          },
          "type": "ImageStates"
        },
        "hoveredComment": {
          "id": 159,
          "options": {
            "proto3_optional": true
          },
          "type": "VCommentElementViewState"
        },
        "globalState": {
          "id": 156,
          "options": {
            "proto3_optional": true
          },
          "type": "VGlobalState"
        },
        "fullscreen": {
          "id": 155,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "previewDocumentNodeState": {
          "id": 223,
          "options": {
            "proto3_optional": true
          },
          "type": "PreviewDocumentNodeState"
        },
        "componentPropViewState": {
          "id": 109,
          "options": {
            "proto3_optional": true
          },
          "type": "VComponentPropStore"
        },
        "editorType": {
          "id": 152,
          "options": {
            "proto3_optional": true
          },
          "type": "EditorType"
        },
        "actionEnabledResetPropertiesStatus": {
          "id": 56,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "editorModeState": {
          "id": 151,
          "options": {
            "proto3_optional": true
          },
          "type": "EditorModeState"
        },
        "currentPageCoactorMouseList": {
          "id": 116,
          "options": {
            "proto3_optional": true
          },
          "type": "VCoactorMouseList"
        },
        "exportSettingsState": {
          "id": 154,
          "options": {
            "proto3_optional": true
          },
          "type": "VExportSettingsState"
        },
        "actionEnabledRedoableStatus": {
          "id": 48,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "drawingComment": {
          "id": 150,
          "options": {
            "proto3_optional": true
          },
          "type": "VDrawingCommentViewState"
        },
        "actionEnabledRenameStatus": {
          "id": 55,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "draftComment": {
          "id": 149,
          "options": {
            "proto3_optional": true
          },
          "type": "VDraftCommentViewState"
        },
        "selectionStrokeV2": {
          "id": 254,
          "options": {
            "proto3_optional": true
          },
          "type": "VSelectionStrokeState"
        },
        "documentColorProfile": {
          "id": 145,
          "options": {
            "proto3_optional": true
          },
          "type": "VDocumentColorProfile"
        },
        "docReadonly": {
          "id": 144,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "aiLayoutEntryMenuState": {
          "id": 82,
          "options": {
            "proto3_optional": true
          },
          "type": "AiLayoutEntryMenuState"
        },
        "displayPanels": {
          "id": 143,
          "options": {
            "proto3_optional": true
          },
          "type": "VDisplayPanels"
        },
        "actionEnabledCopyPasteStatus": {
          "id": 22,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "devModeSelectedSwitchableDeliveryUnitViewState": {
          "id": 142,
          "options": {
            "proto3_optional": true
          },
          "type": "VDevModeSelectedSwitchableDeliveryUnit"
        },
        "historyMode": {
          "id": 158,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "devModeLayerPanel": {
          "id": 140,
          "options": {
            "proto3_optional": true
          },
          "type": "VDevModeLayerPanel"
        },
        "devModeInspectTextViewState": {
          "id": 138,
          "options": {
            "proto3_optional": true
          },
          "type": "VDevModeInspectText"
        },
        "mirrorFrameViewState": {
          "id": 199,
          "options": {
            "proto3_optional": true
          },
          "type": "MirrorFrameViewState"
        },
        "devModeInspectSelectionInfoViewState": {
          "id": 136,
          "options": {
            "proto3_optional": true
          },
          "type": "VDevModeInspectSelectionInfo"
        },
        "librariesPrimitiveVariable": {
          "id": 177,
          "options": {
            "proto3_optional": true
          },
          "type": "LibrariesPrimitiveVariable"
        },
        "devModeInspectContentViewState": {
          "id": 130,
          "options": {
            "proto3_optional": true
          },
          "type": "VDevModeInspectContent"
        },
        "selectedEffects": {
          "id": 242,
          "options": {
            "proto3_optional": true
          },
          "type": "SelectedEffectsState"
        },
        "prototypePresentScalingViewState": {
          "id": 232,
          "options": {
            "proto3_optional": true
          },
          "type": "VPrototypePresentScaling"
        },
        "libraryEffectStyleState": {
          "id": 180,
          "options": {
            "proto3_optional": true
          },
          "type": "LibraryEffectStyleState"
        },
        "devModeInspectComponentViewState": {
          "id": 129,
          "options": {
            "proto3_optional": true
          },
          "type": "VDevModeInspectComponent"
        },
        "polygonStar": {
          "id": 213,
          "options": {
            "proto3_optional": true
          },
          "type": "VPolygonStarData"
        },
        "devModeInspectComponentInstanceSubordinateViewState": {
          "id": 128,
          "options": {
            "proto3_optional": true
          },
          "type": "VDevModeInspectComponentInstanceSubordinate"
        },
        "libraryUpgradeModal": {
          "id": 187,
          "options": {
            "proto3_optional": true
          },
          "type": "VLibraryComponentUpdateModal"
        },
        "devModeInspectCodeViewState": {
          "id": 127,
          "options": {
            "proto3_optional": true
          },
          "type": "VDevModeInspectCode"
        },
        "localVariableGroup": {
          "id": 194,
          "options": {
            "proto3_optional": true
          },
          "type": "LocalVariableGroupViewStateType"
        },
        "activedComment": {
          "id": 80,
          "options": {
            "proto3_optional": true
          },
          "type": "VActivedCommentViewState"
        },
        "devModeBatchExportViewState": {
          "id": 121,
          "options": {
            "proto3_optional": true
          },
          "type": "VDevModeBatchExport"
        },
        "candidateComponentPublishState": {
          "id": 94,
          "options": {
            "proto3_optional": true
          },
          "type": "CandidateComponentPublishView"
        },
        "currentPageBackground": {
          "id": 115,
          "options": {
            "proto3_optional": true
          },
          "type": "VPaint"
        },
        "devModeAllowIconAutoDetectionViewState": {
          "id": 120,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "currentViewport": {
          "id": 118,
          "options": {
            "proto3_optional": true
          },
          "type": "Viewport"
        },
        "designPanelItemShow": {
          "id": 119,
          "options": {
            "proto3_optional": true
          },
          "type": "VDesignPanelItemShow"
        },
        "currentPageId": {
          "id": 117,
          "options": {
            "proto3_optional": true
          },
          "type": "string"
        },
        "actionEnabledPageUpStatus": {
          "id": 42,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "cornerSmoothing": {
          "id": 114,
          "options": {
            "proto3_optional": true
          },
          "type": "VCornerSmoothing"
        },
        "independentCornerRadius": {
          "id": 163,
          "options": {
            "proto3_optional": true
          },
          "type": "VIndependentCornerRadius"
        },
        "cornerRadius": {
          "id": 113,
          "options": {
            "proto3_optional": true
          },
          "type": "VCornerRadius"
        },
        "constrainProportions": {
          "id": 110,
          "options": {
            "proto3_optional": true
          },
          "type": "VConstrainProportions"
        },
        "canvasSearchResultRenderInfo": {
          "id": 100,
          "options": {
            "proto3_optional": true
          },
          "type": "CanvasSearchResultRenderInfo"
        },
        "componentPanelState": {
          "id": 108,
          "options": {
            "proto3_optional": true
          },
          "type": "ComponentPanelState"
        },
        "componentInstanceSwapPropPreferValueState": {
          "id": 106,
          "options": {
            "proto3_optional": true
          },
          "type": "ReplaceInstanceState"
        },
        "prototypeFlowList": {
          "id": 227,
          "options": {
            "proto3_optional": true
          },
          "type": "VPrototypeFlowList"
        },
        "commentsPosition": {
          "id": 105,
          "options": {
            "proto3_optional": true
          },
          "type": "VCommentsPositionViewState"
        },
        "localVariableEditorEditingState": {
          "id": 192,
          "options": {
            "proto3_optional": true
          },
          "type": "LocalVariableEditorEditingState"
        },
        "prototypePresentDeviceFrameRegionViewState": {
          "id": 231,
          "options": {
            "proto3_optional": true
          },
          "type": "VPrototypePresentDeviceFrameRegion"
        },
        "commentInteractive": {
          "id": 104,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "actionEnabledRemoveAutoLayoutStatus": {
          "id": 50,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "inspectSelectionComponentInfoViewState": {
          "id": 166,
          "options": {
            "proto3_optional": true
          },
          "type": "InspectSelectionComponentInfoViewState"
        },
        "coactorMembershipMap": {
          "id": 102,
          "options": {
            "proto3_optional": true
          },
          "type": "VMembershipMap"
        },
        "pluginFillRuleEditorState": {
          "id": 209,
          "options": {
            "proto3_optional": true
          },
          "type": "PluginFillRuleEditorState"
        },
        "clipsContent": {
          "id": 101,
          "options": {
            "proto3_optional": true
          },
          "type": "VClipsContentState"
        },
        "actionEnabledToggleFullScreenStatus": {
          "id": 65,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "canvasSearchResultLayerItemForScroll": {
          "id": 99,
          "options": {
            "proto3_optional": true
          },
          "type": "CanvasSearchLayerItem"
        },
        "blendModeView": {
          "id": 93,
          "options": {
            "proto3_optional": true
          },
          "type": "BlendModeView"
        },
        "canvasSearchParam": {
          "id": 98,
          "options": {
            "proto3_optional": true
          },
          "type": "CanvasSearchParam"
        },
        "documentPageList": {
          "id": 147,
          "options": {
            "proto3_optional": true
          },
          "type": "VDocumentPageList"
        },
        "motiffScopeRefreshTrigger": {
          "id": 201,
          "options": {
            "proto3_optional": true
          },
          "type": "VMotiffScopeRefreshTrigger"
        },
        "devModeInspectStrokeViewState": {
          "id": 137,
          "options": {
            "proto3_optional": true
          },
          "type": "VDevModeInspectStroke"
        },
        "arrangeState": {
          "id": 85,
          "options": {
            "proto3_optional": true
          },
          "type": "VArrangeState"
        },
        "actionEnabledZoomToPrevFrameStatus": {
          "id": 79,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "actionEnabledZoomInAndOutStatus": {
          "id": 77,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "canvasRightClickMenuVisible": {
          "id": 96,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "baseAttributeV2": {
          "id": 90,
          "options": {
            "proto3_optional": true
          },
          "type": "VBaseAttributeV2"
        },
        "showLocalVariableEditor": {
          "id": 259,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "autoLayout": {
          "id": 88,
          "options": {
            "proto3_optional": true
          },
          "type": "AutoLayoutPanelState"
        },
        "attrPanelEditingStyleViewState": {
          "id": 86,
          "options": {
            "proto3_optional": true
          },
          "type": "AttrPanelEditingStyleViewState"
        },
        "actionEnabledFlipHorizontalStatus": {
          "id": 31,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "actionEnabledFrameSelectionStatus": {
          "id": 33,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "aiSearchSelection": {
          "id": 83,
          "options": {
            "proto3_optional": true
          },
          "type": "AISearchSelection"
        },
        "actionEnabledCopyAsLinkStatus": {
          "id": 18,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "actionEnabledOutlineStrokeStatus": {
          "id": 40,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "presentDisableDefaultKeyboardNav": {
          "id": 215,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "actionEnabledPasteHereStatus": {
          "id": 43,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "hyperlinkEditingState": {
          "id": 160,
          "options": {
            "proto3_optional": true
          },
          "type": "HyperlinkEditingState"
        },
        "actionEnabledUseAsMaskStatus": {
          "id": 74,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "actionEnabledViewPrevFrameStatus": {
          "id": 76,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "libraryGlobalState": {
          "id": 181,
          "options": {
            "proto3_optional": true
          },
          "type": "VLibraryGlobalState"
        },
        "actionEnabledCanUsePackStatus": {
          "id": 12,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "aiChatSelectedNodeSystem": {
          "id": 81,
          "options": {
            "proto3_optional": true
          },
          "type": "AIChatSelectedNodes"
        },
        "selectionLayoutGridState": {
          "id": 251,
          "options": {
            "proto3_optional": true
          },
          "type": "VSelectionLayoutGridState"
        },
        "multipleRename": {
          "id": 202,
          "options": {
            "proto3_optional": true
          },
          "type": "VMultipleRename"
        },
        "actionEnabledLayoutGridStatus": {
          "id": 37,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "prototypingVisible": {
          "id": 234,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "actionEnabledCopyAsSVGStatus": {
          "id": 20,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "actionEnabledTogglenMemoryUsageStatus": {
          "id": 69,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "actionEnabledViewNextFrameStatus": {
          "id": 75,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "actionEnabledSendToBackStatus": {
          "id": 61,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "colorPick": {
          "id": 103,
          "options": {
            "proto3_optional": true
          },
          "type": "VColorPick"
        },
        "devModeInspectExportViewState": {
          "id": 132,
          "options": {
            "proto3_optional": true
          },
          "type": "VDevModeInspectExport"
        },
        "canvasSearchHighLightTitle": {
          "id": 97,
          "options": {
            "proto3_optional": true
          },
          "type": "CanvasSearchHighLightTitle"
        },
        "actionEnabledUndoableStatus": {
          "id": 71,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "devModeInspectBoxModelViewState": {
          "id": 125,
          "options": {
            "proto3_optional": true
          },
          "type": "VDevModeInspectBoxModel"
        },
        "devModeInspectFillViewState": {
          "id": 133,
          "options": {
            "proto3_optional": true
          },
          "type": "VDevModeInspectFill"
        },
        "actionEnabledTogglenShowSliceStatus": {
          "id": 70,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "devModeCodeConfig": {
          "id": 122,
          "options": {
            "proto3_optional": true
          },
          "type": "VDevModeCodeConfig"
        },
        "variableConsumptionMapState": {
          "id": 274,
          "options": {
            "proto3_optional": true
          },
          "type": "VariableConsumptionMapState"
        },
        "actionEnabledRemoveSelectNodesStatus": {
          "id": 53,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "batchExportPopupState": {
          "id": 91,
          "options": {
            "proto3_optional": true
          },
          "type": "BatchExportState"
        },
        "actionEnabledToggleLockedStatus": {
          "id": 66,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "pluginLooperEnabled": {
          "id": 210,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "actionEnabledFlipVerticalStatus": {
          "id": 32,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "presentHasCanvasMarginTop": {
          "id": 217,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "actionEnabledSwapFillAndStrokeStatus": {
          "id": 64,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "actionEnabledSendBackwardStatus": {
          "id": 60,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "actionEnabledCopyStatus": {
          "id": 24,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "sidebarPanels": {
          "id": 265,
          "options": {
            "proto3_optional": true
          },
          "type": "VSidebarPanels"
        },
        "actionEnabledSelectAllStatus": {
          "id": 58,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "devModeLayerPanelToggleChildAction": {
          "id": 141,
          "options": {
            "proto3_optional": true
          },
          "type": "VDevModeLayerPanelToggleChildAction"
        },
        "attrPanelStyleEditorState": {
          "id": 87,
          "options": {
            "proto3_optional": true
          },
          "type": "AttrPanelStyleEditorState"
        },
        "localVariableEditorRenamingState": {
          "id": 193,
          "options": {
            "proto3_optional": true
          },
          "type": "LocalVariableEditorRenamingState"
        },
        "documentUsingFontName": {
          "id": 148,
          "options": {
            "proto3_optional": true
          },
          "type": "DocumentUsingFontName"
        },
        "devModeInspectCodeTypeViewState": {
          "id": 126,
          "options": {
            "proto3_optional": true
          },
          "type": "VDevModeInspectCodeType"
        },
        "actionEnabledToggleShowCommentStatus": {
          "id": 67,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "actionEnabledRemovePrototypeInteraction": {
          "id": 52,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "presentShowProtoSidebar": {
          "id": 221,
          "options": {
            "proto3_optional": true
          },
          "type": "VPresentOptionItemState"
        },
        "actionEnabledPublishSelectedNodesStatus": {
          "id": 47,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "actionEnabledRemoveAllPrototypeInteractions": {
          "id": 49,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "localStyleNodeState": {
          "id": 189,
          "options": {
            "proto3_optional": true
          },
          "type": "DocumentLocalStyleNodeState"
        },
        "localVariableCount": {
          "id": 191,
          "options": {
            "proto3_optional": true
          },
          "type": "BridgeProtoInt"
        },
        "actionEnabledCanUseDistributeDirectionStatus": {
          "id": 10,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "smartSelection": {
          "id": 267,
          "options": {
            "proto3_optional": true
          },
          "type": "VSmartSelection"
        },
        "actionEnabledPasteToReplaceStatus": {
          "id": 45,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "actionEnabledZoomToNextFrameStatus": {
          "id": 78,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "belongingComponentPanelState": {
          "id": 92,
          "options": {
            "proto3_optional": true
          },
          "type": "BelongingComponentPanelState"
        },
        "popupState": {
          "id": 214,
          "options": {
            "proto3_optional": true
          },
          "type": "PopupState"
        },
        "actionEnabledAddVariant": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "publicPropInstanceState": {
          "id": 235,
          "options": {
            "proto3_optional": true
          },
          "type": "PublicPropInstanceState"
        },
        "availableFonts": {
          "id": 89,
          "options": {
            "proto3_optional": true
          },
          "type": "VAvaiableFonts"
        },
        "motiffScope": {
          "id": 200,
          "options": {
            "proto3_optional": true
          },
          "type": "VMotiffScopeData"
        },
        "libraryLayoutGridStyleState": {
          "id": 182,
          "options": {
            "proto3_optional": true
          },
          "type": "LibraryLayoutGridStyleState"
        },
        "devModeDisplayPanels": {
          "id": 123,
          "options": {
            "proto3_optional": true
          },
          "type": "VDisplayPanels"
        },
        "libraryReplaceModalViewState": {
          "id": 185,
          "options": {
            "proto3_optional": true
          },
          "type": "VLibraryReplaceModal"
        },
        "thumbnail": {
          "id": 271,
          "options": {
            "proto3_optional": true
          },
          "type": "Thumbnail"
        },
        "devModeInspectEffectViewState": {
          "id": 131,
          "options": {
            "proto3_optional": true
          },
          "type": "VDevModeInspectEffect"
        },
        "inspectSelectionComponentPropInfoViewState": {
          "id": 167,
          "options": {
            "proto3_optional": true
          },
          "type": "InspectSelectionComponentPropInfoViewState"
        },
        "actionEnabledRemoveFillStatus": {
          "id": 51,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "actionEnabledPastePropertiesStatus": {
          "id": 44,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "actionEnabledAiAddAutoLayout": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "actionEnabledSelectInverseStatus": {
          "id": 59,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "actionEnabledCanUseDistributeHVStatus": {
          "id": 11,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "actionEnabledClearSelectNodesStatus": {
          "id": 15,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "selectChangeTime": {
          "id": 241,
          "options": {
            "proto3_optional": true
          },
          "type": "BridgeProtoString"
        },
        "actionEnabledMoveNodesToOtherPage": {
          "id": 39,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "actionEnabledAddAutoLayoutStatus": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "actionEnabledGoToSelectedInstanceMainComponentStatus": {
          "id": 34,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "actionEnabledCanUseAlignStatus": {
          "id": 9,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "actionEnabledMoreLayoutOptionStatus": {
          "id": 38,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "localStyleEditingStateV2": {
          "id": 188,
          "options": {
            "proto3_optional": true
          },
          "type": "VLocalStyleEditingState"
        },
        "actionEnabledBringForwardStatus": {
          "id": 7,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "devModeInspectNodeBasicInfoViewState": {
          "id": 135,
          "options": {
            "proto3_optional": true
          },
          "type": "VDevModeInspectNodeBasicInfo"
        },
        "actionEnabledGroupSelectionStatus": {
          "id": 35,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "inspectSelectionTextTypographyViewState": {
          "id": 170,
          "options": {
            "proto3_optional": true
          },
          "type": "InspectSelectionTextTypographyViewState"
        },
        "copyPasteState": {
          "id": 112,
          "options": {
            "proto3_optional": true
          },
          "type": "VCopyPasteState"
        },
        "actionEnabledAddPrototypeStartingPoint": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "actionEnabledPageDownStatus": {
          "id": 41,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "actionEnabledExcludeStatus": {
          "id": 29,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "showMultiplayerCursor": {
          "id": 260,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "actionEnabledExitAiLayoutStatus": {
          "id": 30,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "actionEnabledEnterAiLayoutStatus": {
          "id": 28,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "actionEnabledPixelGridStatus": {
          "id": 46,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "actionEnabledUngroupSelectionStatus": {
          "id": 72,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "devModeInspectLayoutViewState": {
          "id": 134,
          "options": {
            "proto3_optional": true
          },
          "type": "VDevModeInspectLayout"
        },
        "actionEnabledCopyAsTextStatus": {
          "id": 21,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "exportInfo": {
          "id": 153,
          "options": {
            "proto3_optional": true
          },
          "type": "VExportInfo"
        },
        "actionEnabledBringToFrontStatus": {
          "id": 8,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "actionEnabledCopyPropertiesStatus": {
          "id": 23,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "actionEnabledDuplicateSelectionStatus": {
          "id": 27,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "actionEnabledCopyAsPNGStatus": {
          "id": 19,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "componentInstanceSwapPropState": {
          "id": 107,
          "options": {
            "proto3_optional": true
          },
          "type": "ReplaceInstanceState"
        },
        "actionEnabledComponentSelectionStatus": {
          "id": 17,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "hasSelection": {
          "id": 157,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "actionEnabledSubtractStatus": {
          "id": 63,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "actionEnabledIntersectStatus": {
          "id": 36,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "localVariableTable": {
          "id": 195,
          "options": {
            "proto3_optional": true
          },
          "type": "LocalVariableTableViewStateType"
        },
        "actionEnabledCancelMaskStatus": {
          "id": 14,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "actionEnabledCanUsePixelAlignStatus": {
          "id": 13,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "documentLoaded": {
          "id": 146,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "devModeInspectTypeViewState": {
          "id": 139,
          "options": {
            "proto3_optional": true
          },
          "type": "VDevModeInspectType"
        },
        "showSidebar": {
          "id": 263,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "actionEnabledToggleShownStatus": {
          "id": 68,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "libraryTextStyleState": {
          "id": 186,
          "options": {
            "proto3_optional": true
          },
          "type": "LibraryTextStyleState"
        },
        "inspectSelectionTextContentViewState": {
          "id": 169,
          "options": {
            "proto3_optional": true
          },
          "type": "InspectSelectionTextContentViewState"
        },
        "actionEnabledDetachInstanceStatus": {
          "id": 26,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "actionEnabledRulerStatus": {
          "id": 57,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "actionEnabledBoolSelectionStatus": {
          "id": 6,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "actionEnabledColorPickStatus": {
          "id": 16,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        },
        "FramePresetState": {
          "id": 1,
          "options": {
            "proto3_optional": true
          },
          "type": "VFramePresetState"
        },
        "actionEnabledDeletePrototypeStartingPoint": {
          "id": 25,
          "options": {
            "proto3_optional": true
          },
          "type": "bool"
        }
      },
      "oneofs": {
        "_visibleViewport": {
          "oneof": [
            "visibleViewport"
          ]
        },
        "_vectorPanel": {
          "oneof": [
            "vectorPanel"
          ]
        },
        "_variableModeEntriesInSelect": {
          "oneof": [
            "variableModeEntriesInSelect"
          ]
        },
        "_variableModeEntriesInPage": {
          "oneof": [
            "variableModeEntriesInPage"
          ]
        },
        "_variableLayerUsingMode": {
          "oneof": [
            "variableLayerUsingMode"
          ]
        },
        "_variableCreate": {
          "oneof": [
            "variableCreate"
          ]
        },
        "_topExtendBoolMenuVisible": {
          "oneof": [
            "topExtendBoolMenuVisible"
          ]
        },
        "_topArea": {
          "oneof": [
            "topArea"
          ]
        },
        "_textSearch": {
          "oneof": [
            "textSearch"
          ]
        },
        "_textEditingState": {
          "oneof": [
            "textEditingState"
          ]
        },
        "_syncProgress": {
          "oneof": [
            "syncProgress"
          ]
        },
        "_singleSelection": {
          "oneof": [
            "singleSelection"
          ]
        },
        "_showSlice": {
          "oneof": [
            "showSlice"
          ]
        },
        "_showShortcutPanel": {
          "oneof": [
            "showShortcutPanel"
          ]
        },
        "_showMultipleRenameModal": {
          "oneof": [
            "showMultipleRenameModal"
          ]
        },
        "_showComments": {
          "oneof": [
            "showComments"
          ]
        },
        "_selectionTextLine": {
          "oneof": [
            "selectionTextLine"
          ]
        },
        "_selectionText": {
          "oneof": [
            "selectionText"
          ]
        },
        "_selectionPrototypeInteraction": {
          "oneof": [
            "selectionPrototypeInteraction"
          ]
        },
        "_selectionPaint": {
          "oneof": [
            "selectionPaint"
          ]
        },
        "_selectionHyperlinkState": {
          "oneof": [
            "selectionHyperlinkState"
          ]
        },
        "_selectionFill": {
          "oneof": [
            "selectionFill"
          ]
        },
        "_selectionColorScope": {
          "oneof": [
            "selectionColorScope"
          ]
        },
        "_selectedStrokes": {
          "oneof": [
            "selectedStrokes"
          ]
        },
        "_selectedLayoutGrids": {
          "oneof": [
            "selectedLayoutGrids"
          ]
        },
        "_selectedGradientColorStopIndex": {
          "oneof": [
            "selectedGradientColorStopIndex"
          ]
        },
        "_selectedFills": {
          "oneof": [
            "selectedFills"
          ]
        },
        "_scaleToolPanel": {
          "oneof": [
            "scaleToolPanel"
          ]
        },
        "_rightClickSetThumbnailStatus": {
          "oneof": [
            "rightClickSetThumbnailStatus"
          ]
        },
        "_selectionEffect": {
          "oneof": [
            "selectionEffect"
          ]
        },
        "_rightClickMenuState": {
          "oneof": [
            "rightClickMenuState"
          ]
        },
        "_publishModal": {
          "oneof": [
            "publishModal"
          ]
        },
        "_showDevModeInspectExport": {
          "oneof": [
            "showDevModeInspectExport"
          ]
        },
        "_prototypeStartingPointEditingNameViewState": {
          "oneof": [
            "prototypeStartingPointEditingNameViewState"
          ]
        },
        "_prototypePresentCanvasPaddingViewState": {
          "oneof": [
            "prototypePresentCanvasPaddingViewState"
          ]
        },
        "_prototypePanelScrollBehaviorViewState": {
          "oneof": [
            "prototypePanelScrollBehaviorViewState"
          ]
        },
        "_prototypeFlowListForSelection": {
          "oneof": [
            "prototypeFlowListForSelection"
          ]
        },
        "_prototypeDevice": {
          "oneof": [
            "prototypeDevice"
          ]
        },
        "_prototypeBackgroundColor": {
          "oneof": [
            "prototypeBackgroundColor"
          ]
        },
        "_previewFlowsViewState": {
          "oneof": [
            "previewFlowsViewState"
          ]
        },
        "_presentShowHotspotHintViewState": {
          "oneof": [
            "presentShowHotspotHintViewState"
          ]
        },
        "_presentShowDeviceViewState": {
          "oneof": [
            "presentShowDeviceViewState"
          ]
        },
        "_presentHideUI": {
          "oneof": [
            "presentHideUI"
          ]
        },
        "_presentFrameCounterViewState": {
          "oneof": [
            "presentFrameCounterViewState"
          ]
        },
        "_pluginState": {
          "oneof": [
            "pluginState"
          ]
        },
        "_replaceInstanceState": {
          "oneof": [
            "replaceInstanceState"
          ]
        },
        "_pluginRotateCopyViewState": {
          "oneof": [
            "pluginRotateCopyViewState"
          ]
        },
        "_pluginArcText": {
          "oneof": [
            "pluginArcText"
          ]
        },
        "_pluginAeExportEnabled": {
          "oneof": [
            "pluginAeExportEnabled"
          ]
        },
        "_previewDocumentLoadedState": {
          "oneof": [
            "previewDocumentLoadedState"
          ]
        },
        "_openingLibraryInUseCountInfo": {
          "oneof": [
            "openingLibraryInUseCountInfo"
          ]
        },
        "_panelStateUnderAILayout": {
          "oneof": [
            "panelStateUnderAILayout"
          ]
        },
        "_nodePreviewTracksChanged": {
          "oneof": [
            "nodePreviewTracksChanged"
          ]
        },
        "_memoryUsage": {
          "oneof": [
            "memoryUsage"
          ]
        },
        "_localVariableCollection": {
          "oneof": [
            "localVariableCollection"
          ]
        },
        "_actionEnabledUnionStatus": {
          "oneof": [
            "actionEnabledUnionStatus"
          ]
        },
        "_hyperlinkHoverPopupState": {
          "oneof": [
            "hyperlinkHoverPopupState"
          ]
        },
        "_layerSelection": {
          "oneof": [
            "layerSelection"
          ]
        },
        "_libraryRemoteListModal": {
          "oneof": [
            "libraryRemoteListModal"
          ]
        },
        "_libraryPaintStyleState": {
          "oneof": [
            "libraryPaintStyleState"
          ]
        },
        "_maskPanelState": {
          "oneof": [
            "maskPanelState"
          ]
        },
        "_actionEnabledShowSidebarStatus": {
          "oneof": [
            "actionEnabledShowSidebarStatus"
          ]
        },
        "_inspectSelectionVariantViewState": {
          "oneof": [
            "inspectSelectionVariantViewState"
          ]
        },
        "_libraryCurrentLocalModal": {
          "oneof": [
            "libraryCurrentLocalModal"
          ]
        },
        "_candidateComponentReplaceState": {
          "oneof": [
            "candidateComponentReplaceState"
          ]
        },
        "_layerPanelAILayoutColorLine": {
          "oneof": [
            "layerPanelAILayoutColorLine"
          ]
        },
        "_libraryAssetPanel": {
          "oneof": [
            "libraryAssetPanel"
          ]
        },
        "_mainMenuVisible": {
          "oneof": [
            "mainMenuVisible"
          ]
        },
        "_devModeHoverNodeLabelViewState": {
          "oneof": [
            "devModeHoverNodeLabelViewState"
          ]
        },
        "_librariesColorVariable": {
          "oneof": [
            "librariesColorVariable"
          ]
        },
        "_layerPanel": {
          "oneof": [
            "layerPanel"
          ]
        },
        "_arcData": {
          "oneof": [
            "arcData"
          ]
        },
        "_isPreviewPending": {
          "oneof": [
            "isPreviewPending"
          ]
        },
        "_inspectSelectionFillViewState": {
          "oneof": [
            "inspectSelectionFillViewState"
          ]
        },
        "_inspectSelectionCodeViewState": {
          "oneof": [
            "inspectSelectionCodeViewState"
          ]
        },
        "_pageTopArea": {
          "oneof": [
            "pageTopArea"
          ]
        },
        "_inspectEffectViewState": {
          "oneof": [
            "inspectEffectViewState"
          ]
        },
        "_constraintsState": {
          "oneof": [
            "constraintsState"
          ]
        },
        "_actionEnabledRemoveStrokeStatus": {
          "oneof": [
            "actionEnabledRemoveStrokeStatus"
          ]
        },
        "_imageStates": {
          "oneof": [
            "imageStates"
          ]
        },
        "_hoveredComment": {
          "oneof": [
            "hoveredComment"
          ]
        },
        "_globalState": {
          "oneof": [
            "globalState"
          ]
        },
        "_fullscreen": {
          "oneof": [
            "fullscreen"
          ]
        },
        "_previewDocumentNodeState": {
          "oneof": [
            "previewDocumentNodeState"
          ]
        },
        "_componentPropViewState": {
          "oneof": [
            "componentPropViewState"
          ]
        },
        "_editorType": {
          "oneof": [
            "editorType"
          ]
        },
        "_actionEnabledResetPropertiesStatus": {
          "oneof": [
            "actionEnabledResetPropertiesStatus"
          ]
        },
        "_editorModeState": {
          "oneof": [
            "editorModeState"
          ]
        },
        "_currentPageCoactorMouseList": {
          "oneof": [
            "currentPageCoactorMouseList"
          ]
        },
        "_exportSettingsState": {
          "oneof": [
            "exportSettingsState"
          ]
        },
        "_actionEnabledRedoableStatus": {
          "oneof": [
            "actionEnabledRedoableStatus"
          ]
        },
        "_drawingComment": {
          "oneof": [
            "drawingComment"
          ]
        },
        "_actionEnabledRenameStatus": {
          "oneof": [
            "actionEnabledRenameStatus"
          ]
        },
        "_draftComment": {
          "oneof": [
            "draftComment"
          ]
        },
        "_selectionStrokeV2": {
          "oneof": [
            "selectionStrokeV2"
          ]
        },
        "_documentColorProfile": {
          "oneof": [
            "documentColorProfile"
          ]
        },
        "_docReadonly": {
          "oneof": [
            "docReadonly"
          ]
        },
        "_aiLayoutEntryMenuState": {
          "oneof": [
            "aiLayoutEntryMenuState"
          ]
        },
        "_displayPanels": {
          "oneof": [
            "displayPanels"
          ]
        },
        "_actionEnabledCopyPasteStatus": {
          "oneof": [
            "actionEnabledCopyPasteStatus"
          ]
        },
        "_devModeSelectedSwitchableDeliveryUnitViewState": {
          "oneof": [
            "devModeSelectedSwitchableDeliveryUnitViewState"
          ]
        },
        "_historyMode": {
          "oneof": [
            "historyMode"
          ]
        },
        "_devModeLayerPanel": {
          "oneof": [
            "devModeLayerPanel"
          ]
        },
        "_devModeInspectTextViewState": {
          "oneof": [
            "devModeInspectTextViewState"
          ]
        },
        "_mirrorFrameViewState": {
          "oneof": [
            "mirrorFrameViewState"
          ]
        },
        "_devModeInspectSelectionInfoViewState": {
          "oneof": [
            "devModeInspectSelectionInfoViewState"
          ]
        },
        "_librariesPrimitiveVariable": {
          "oneof": [
            "librariesPrimitiveVariable"
          ]
        },
        "_devModeInspectContentViewState": {
          "oneof": [
            "devModeInspectContentViewState"
          ]
        },
        "_selectedEffects": {
          "oneof": [
            "selectedEffects"
          ]
        },
        "_prototypePresentScalingViewState": {
          "oneof": [
            "prototypePresentScalingViewState"
          ]
        },
        "_libraryEffectStyleState": {
          "oneof": [
            "libraryEffectStyleState"
          ]
        },
        "_devModeInspectComponentViewState": {
          "oneof": [
            "devModeInspectComponentViewState"
          ]
        },
        "_polygonStar": {
          "oneof": [
            "polygonStar"
          ]
        },
        "_devModeInspectComponentInstanceSubordinateViewState": {
          "oneof": [
            "devModeInspectComponentInstanceSubordinateViewState"
          ]
        },
        "_libraryUpgradeModal": {
          "oneof": [
            "libraryUpgradeModal"
          ]
        },
        "_devModeInspectCodeViewState": {
          "oneof": [
            "devModeInspectCodeViewState"
          ]
        },
        "_localVariableGroup": {
          "oneof": [
            "localVariableGroup"
          ]
        },
        "_activedComment": {
          "oneof": [
            "activedComment"
          ]
        },
        "_devModeBatchExportViewState": {
          "oneof": [
            "devModeBatchExportViewState"
          ]
        },
        "_candidateComponentPublishState": {
          "oneof": [
            "candidateComponentPublishState"
          ]
        },
        "_currentPageBackground": {
          "oneof": [
            "currentPageBackground"
          ]
        },
        "_devModeAllowIconAutoDetectionViewState": {
          "oneof": [
            "devModeAllowIconAutoDetectionViewState"
          ]
        },
        "_currentViewport": {
          "oneof": [
            "currentViewport"
          ]
        },
        "_designPanelItemShow": {
          "oneof": [
            "designPanelItemShow"
          ]
        },
        "_currentPageId": {
          "oneof": [
            "currentPageId"
          ]
        },
        "_actionEnabledPageUpStatus": {
          "oneof": [
            "actionEnabledPageUpStatus"
          ]
        },
        "_cornerSmoothing": {
          "oneof": [
            "cornerSmoothing"
          ]
        },
        "_independentCornerRadius": {
          "oneof": [
            "independentCornerRadius"
          ]
        },
        "_cornerRadius": {
          "oneof": [
            "cornerRadius"
          ]
        },
        "_constrainProportions": {
          "oneof": [
            "constrainProportions"
          ]
        },
        "_canvasSearchResultRenderInfo": {
          "oneof": [
            "canvasSearchResultRenderInfo"
          ]
        },
        "_componentPanelState": {
          "oneof": [
            "componentPanelState"
          ]
        },
        "_componentInstanceSwapPropPreferValueState": {
          "oneof": [
            "componentInstanceSwapPropPreferValueState"
          ]
        },
        "_prototypeFlowList": {
          "oneof": [
            "prototypeFlowList"
          ]
        },
        "_commentsPosition": {
          "oneof": [
            "commentsPosition"
          ]
        },
        "_localVariableEditorEditingState": {
          "oneof": [
            "localVariableEditorEditingState"
          ]
        },
        "_prototypePresentDeviceFrameRegionViewState": {
          "oneof": [
            "prototypePresentDeviceFrameRegionViewState"
          ]
        },
        "_commentInteractive": {
          "oneof": [
            "commentInteractive"
          ]
        },
        "_actionEnabledRemoveAutoLayoutStatus": {
          "oneof": [
            "actionEnabledRemoveAutoLayoutStatus"
          ]
        },
        "_inspectSelectionComponentInfoViewState": {
          "oneof": [
            "inspectSelectionComponentInfoViewState"
          ]
        },
        "_coactorMembershipMap": {
          "oneof": [
            "coactorMembershipMap"
          ]
        },
        "_pluginFillRuleEditorState": {
          "oneof": [
            "pluginFillRuleEditorState"
          ]
        },
        "_clipsContent": {
          "oneof": [
            "clipsContent"
          ]
        },
        "_actionEnabledToggleFullScreenStatus": {
          "oneof": [
            "actionEnabledToggleFullScreenStatus"
          ]
        },
        "_canvasSearchResultLayerItemForScroll": {
          "oneof": [
            "canvasSearchResultLayerItemForScroll"
          ]
        },
        "_blendModeView": {
          "oneof": [
            "blendModeView"
          ]
        },
        "_canvasSearchParam": {
          "oneof": [
            "canvasSearchParam"
          ]
        },
        "_documentPageList": {
          "oneof": [
            "documentPageList"
          ]
        },
        "_motiffScopeRefreshTrigger": {
          "oneof": [
            "motiffScopeRefreshTrigger"
          ]
        },
        "_devModeInspectStrokeViewState": {
          "oneof": [
            "devModeInspectStrokeViewState"
          ]
        },
        "_arrangeState": {
          "oneof": [
            "arrangeState"
          ]
        },
        "_actionEnabledZoomToPrevFrameStatus": {
          "oneof": [
            "actionEnabledZoomToPrevFrameStatus"
          ]
        },
        "_actionEnabledZoomInAndOutStatus": {
          "oneof": [
            "actionEnabledZoomInAndOutStatus"
          ]
        },
        "_canvasRightClickMenuVisible": {
          "oneof": [
            "canvasRightClickMenuVisible"
          ]
        },
        "_baseAttributeV2": {
          "oneof": [
            "baseAttributeV2"
          ]
        },
        "_showLocalVariableEditor": {
          "oneof": [
            "showLocalVariableEditor"
          ]
        },
        "_autoLayout": {
          "oneof": [
            "autoLayout"
          ]
        },
        "_attrPanelEditingStyleViewState": {
          "oneof": [
            "attrPanelEditingStyleViewState"
          ]
        },
        "_actionEnabledFlipHorizontalStatus": {
          "oneof": [
            "actionEnabledFlipHorizontalStatus"
          ]
        },
        "_actionEnabledFrameSelectionStatus": {
          "oneof": [
            "actionEnabledFrameSelectionStatus"
          ]
        },
        "_aiSearchSelection": {
          "oneof": [
            "aiSearchSelection"
          ]
        },
        "_actionEnabledCopyAsLinkStatus": {
          "oneof": [
            "actionEnabledCopyAsLinkStatus"
          ]
        },
        "_actionEnabledOutlineStrokeStatus": {
          "oneof": [
            "actionEnabledOutlineStrokeStatus"
          ]
        },
        "_presentDisableDefaultKeyboardNav": {
          "oneof": [
            "presentDisableDefaultKeyboardNav"
          ]
        },
        "_actionEnabledPasteHereStatus": {
          "oneof": [
            "actionEnabledPasteHereStatus"
          ]
        },
        "_hyperlinkEditingState": {
          "oneof": [
            "hyperlinkEditingState"
          ]
        },
        "_actionEnabledUseAsMaskStatus": {
          "oneof": [
            "actionEnabledUseAsMaskStatus"
          ]
        },
        "_actionEnabledViewPrevFrameStatus": {
          "oneof": [
            "actionEnabledViewPrevFrameStatus"
          ]
        },
        "_libraryGlobalState": {
          "oneof": [
            "libraryGlobalState"
          ]
        },
        "_actionEnabledCanUsePackStatus": {
          "oneof": [
            "actionEnabledCanUsePackStatus"
          ]
        },
        "_aiChatSelectedNodeSystem": {
          "oneof": [
            "aiChatSelectedNodeSystem"
          ]
        },
        "_selectionLayoutGridState": {
          "oneof": [
            "selectionLayoutGridState"
          ]
        },
        "_multipleRename": {
          "oneof": [
            "multipleRename"
          ]
        },
        "_actionEnabledLayoutGridStatus": {
          "oneof": [
            "actionEnabledLayoutGridStatus"
          ]
        },
        "_prototypingVisible": {
          "oneof": [
            "prototypingVisible"
          ]
        },
        "_actionEnabledCopyAsSVGStatus": {
          "oneof": [
            "actionEnabledCopyAsSVGStatus"
          ]
        },
        "_actionEnabledTogglenMemoryUsageStatus": {
          "oneof": [
            "actionEnabledTogglenMemoryUsageStatus"
          ]
        },
        "_actionEnabledViewNextFrameStatus": {
          "oneof": [
            "actionEnabledViewNextFrameStatus"
          ]
        },
        "_actionEnabledSendToBackStatus": {
          "oneof": [
            "actionEnabledSendToBackStatus"
          ]
        },
        "_colorPick": {
          "oneof": [
            "colorPick"
          ]
        },
        "_devModeInspectExportViewState": {
          "oneof": [
            "devModeInspectExportViewState"
          ]
        },
        "_canvasSearchHighLightTitle": {
          "oneof": [
            "canvasSearchHighLightTitle"
          ]
        },
        "_actionEnabledUndoableStatus": {
          "oneof": [
            "actionEnabledUndoableStatus"
          ]
        },
        "_devModeInspectBoxModelViewState": {
          "oneof": [
            "devModeInspectBoxModelViewState"
          ]
        },
        "_devModeInspectFillViewState": {
          "oneof": [
            "devModeInspectFillViewState"
          ]
        },
        "_actionEnabledTogglenShowSliceStatus": {
          "oneof": [
            "actionEnabledTogglenShowSliceStatus"
          ]
        },
        "_devModeCodeConfig": {
          "oneof": [
            "devModeCodeConfig"
          ]
        },
        "_variableConsumptionMapState": {
          "oneof": [
            "variableConsumptionMapState"
          ]
        },
        "_actionEnabledRemoveSelectNodesStatus": {
          "oneof": [
            "actionEnabledRemoveSelectNodesStatus"
          ]
        },
        "_batchExportPopupState": {
          "oneof": [
            "batchExportPopupState"
          ]
        },
        "_actionEnabledToggleLockedStatus": {
          "oneof": [
            "actionEnabledToggleLockedStatus"
          ]
        },
        "_pluginLooperEnabled": {
          "oneof": [
            "pluginLooperEnabled"
          ]
        },
        "_actionEnabledFlipVerticalStatus": {
          "oneof": [
            "actionEnabledFlipVerticalStatus"
          ]
        },
        "_presentHasCanvasMarginTop": {
          "oneof": [
            "presentHasCanvasMarginTop"
          ]
        },
        "_actionEnabledSwapFillAndStrokeStatus": {
          "oneof": [
            "actionEnabledSwapFillAndStrokeStatus"
          ]
        },
        "_actionEnabledSendBackwardStatus": {
          "oneof": [
            "actionEnabledSendBackwardStatus"
          ]
        },
        "_actionEnabledCopyStatus": {
          "oneof": [
            "actionEnabledCopyStatus"
          ]
        },
        "_sidebarPanels": {
          "oneof": [
            "sidebarPanels"
          ]
        },
        "_actionEnabledSelectAllStatus": {
          "oneof": [
            "actionEnabledSelectAllStatus"
          ]
        },
        "_devModeLayerPanelToggleChildAction": {
          "oneof": [
            "devModeLayerPanelToggleChildAction"
          ]
        },
        "_attrPanelStyleEditorState": {
          "oneof": [
            "attrPanelStyleEditorState"
          ]
        },
        "_localVariableEditorRenamingState": {
          "oneof": [
            "localVariableEditorRenamingState"
          ]
        },
        "_documentUsingFontName": {
          "oneof": [
            "documentUsingFontName"
          ]
        },
        "_devModeInspectCodeTypeViewState": {
          "oneof": [
            "devModeInspectCodeTypeViewState"
          ]
        },
        "_actionEnabledToggleShowCommentStatus": {
          "oneof": [
            "actionEnabledToggleShowCommentStatus"
          ]
        },
        "_actionEnabledRemovePrototypeInteraction": {
          "oneof": [
            "actionEnabledRemovePrototypeInteraction"
          ]
        },
        "_presentShowProtoSidebar": {
          "oneof": [
            "presentShowProtoSidebar"
          ]
        },
        "_actionEnabledPublishSelectedNodesStatus": {
          "oneof": [
            "actionEnabledPublishSelectedNodesStatus"
          ]
        },
        "_actionEnabledRemoveAllPrototypeInteractions": {
          "oneof": [
            "actionEnabledRemoveAllPrototypeInteractions"
          ]
        },
        "_localStyleNodeState": {
          "oneof": [
            "localStyleNodeState"
          ]
        },
        "_localVariableCount": {
          "oneof": [
            "localVariableCount"
          ]
        },
        "_actionEnabledCanUseDistributeDirectionStatus": {
          "oneof": [
            "actionEnabledCanUseDistributeDirectionStatus"
          ]
        },
        "_smartSelection": {
          "oneof": [
            "smartSelection"
          ]
        },
        "_actionEnabledPasteToReplaceStatus": {
          "oneof": [
            "actionEnabledPasteToReplaceStatus"
          ]
        },
        "_actionEnabledZoomToNextFrameStatus": {
          "oneof": [
            "actionEnabledZoomToNextFrameStatus"
          ]
        },
        "_belongingComponentPanelState": {
          "oneof": [
            "belongingComponentPanelState"
          ]
        },
        "_popupState": {
          "oneof": [
            "popupState"
          ]
        },
        "_actionEnabledAddVariant": {
          "oneof": [
            "actionEnabledAddVariant"
          ]
        },
        "_publicPropInstanceState": {
          "oneof": [
            "publicPropInstanceState"
          ]
        },
        "_availableFonts": {
          "oneof": [
            "availableFonts"
          ]
        },
        "_motiffScope": {
          "oneof": [
            "motiffScope"
          ]
        },
        "_libraryLayoutGridStyleState": {
          "oneof": [
            "libraryLayoutGridStyleState"
          ]
        },
        "_devModeDisplayPanels": {
          "oneof": [
            "devModeDisplayPanels"
          ]
        },
        "_libraryReplaceModalViewState": {
          "oneof": [
            "libraryReplaceModalViewState"
          ]
        },
        "_thumbnail": {
          "oneof": [
            "thumbnail"
          ]
        },
        "_devModeInspectEffectViewState": {
          "oneof": [
            "devModeInspectEffectViewState"
          ]
        },
        "_inspectSelectionComponentPropInfoViewState": {
          "oneof": [
            "inspectSelectionComponentPropInfoViewState"
          ]
        },
        "_actionEnabledRemoveFillStatus": {
          "oneof": [
            "actionEnabledRemoveFillStatus"
          ]
        },
        "_actionEnabledPastePropertiesStatus": {
          "oneof": [
            "actionEnabledPastePropertiesStatus"
          ]
        },
        "_actionEnabledAiAddAutoLayout": {
          "oneof": [
            "actionEnabledAiAddAutoLayout"
          ]
        },
        "_actionEnabledSelectInverseStatus": {
          "oneof": [
            "actionEnabledSelectInverseStatus"
          ]
        },
        "_actionEnabledCanUseDistributeHVStatus": {
          "oneof": [
            "actionEnabledCanUseDistributeHVStatus"
          ]
        },
        "_actionEnabledClearSelectNodesStatus": {
          "oneof": [
            "actionEnabledClearSelectNodesStatus"
          ]
        },
        "_selectChangeTime": {
          "oneof": [
            "selectChangeTime"
          ]
        },
        "_actionEnabledMoveNodesToOtherPage": {
          "oneof": [
            "actionEnabledMoveNodesToOtherPage"
          ]
        },
        "_actionEnabledAddAutoLayoutStatus": {
          "oneof": [
            "actionEnabledAddAutoLayoutStatus"
          ]
        },
        "_actionEnabledGoToSelectedInstanceMainComponentStatus": {
          "oneof": [
            "actionEnabledGoToSelectedInstanceMainComponentStatus"
          ]
        },
        "_actionEnabledCanUseAlignStatus": {
          "oneof": [
            "actionEnabledCanUseAlignStatus"
          ]
        },
        "_actionEnabledMoreLayoutOptionStatus": {
          "oneof": [
            "actionEnabledMoreLayoutOptionStatus"
          ]
        },
        "_localStyleEditingStateV2": {
          "oneof": [
            "localStyleEditingStateV2"
          ]
        },
        "_actionEnabledBringForwardStatus": {
          "oneof": [
            "actionEnabledBringForwardStatus"
          ]
        },
        "_devModeInspectNodeBasicInfoViewState": {
          "oneof": [
            "devModeInspectNodeBasicInfoViewState"
          ]
        },
        "_actionEnabledGroupSelectionStatus": {
          "oneof": [
            "actionEnabledGroupSelectionStatus"
          ]
        },
        "_inspectSelectionTextTypographyViewState": {
          "oneof": [
            "inspectSelectionTextTypographyViewState"
          ]
        },
        "_copyPasteState": {
          "oneof": [
            "copyPasteState"
          ]
        },
        "_actionEnabledAddPrototypeStartingPoint": {
          "oneof": [
            "actionEnabledAddPrototypeStartingPoint"
          ]
        },
        "_actionEnabledPageDownStatus": {
          "oneof": [
            "actionEnabledPageDownStatus"
          ]
        },
        "_actionEnabledExcludeStatus": {
          "oneof": [
            "actionEnabledExcludeStatus"
          ]
        },
        "_showMultiplayerCursor": {
          "oneof": [
            "showMultiplayerCursor"
          ]
        },
        "_actionEnabledExitAiLayoutStatus": {
          "oneof": [
            "actionEnabledExitAiLayoutStatus"
          ]
        },
        "_actionEnabledEnterAiLayoutStatus": {
          "oneof": [
            "actionEnabledEnterAiLayoutStatus"
          ]
        },
        "_actionEnabledPixelGridStatus": {
          "oneof": [
            "actionEnabledPixelGridStatus"
          ]
        },
        "_actionEnabledUngroupSelectionStatus": {
          "oneof": [
            "actionEnabledUngroupSelectionStatus"
          ]
        },
        "_devModeInspectLayoutViewState": {
          "oneof": [
            "devModeInspectLayoutViewState"
          ]
        },
        "_actionEnabledCopyAsTextStatus": {
          "oneof": [
            "actionEnabledCopyAsTextStatus"
          ]
        },
        "_exportInfo": {
          "oneof": [
            "exportInfo"
          ]
        },
        "_actionEnabledBringToFrontStatus": {
          "oneof": [
            "actionEnabledBringToFrontStatus"
          ]
        },
        "_actionEnabledCopyPropertiesStatus": {
          "oneof": [
            "actionEnabledCopyPropertiesStatus"
          ]
        },
        "_actionEnabledDuplicateSelectionStatus": {
          "oneof": [
            "actionEnabledDuplicateSelectionStatus"
          ]
        },
        "_actionEnabledCopyAsPNGStatus": {
          "oneof": [
            "actionEnabledCopyAsPNGStatus"
          ]
        },
        "_componentInstanceSwapPropState": {
          "oneof": [
            "componentInstanceSwapPropState"
          ]
        },
        "_actionEnabledComponentSelectionStatus": {
          "oneof": [
            "actionEnabledComponentSelectionStatus"
          ]
        },
        "_hasSelection": {
          "oneof": [
            "hasSelection"
          ]
        },
        "_actionEnabledSubtractStatus": {
          "oneof": [
            "actionEnabledSubtractStatus"
          ]
        },
        "_actionEnabledIntersectStatus": {
          "oneof": [
            "actionEnabledIntersectStatus"
          ]
        },
        "_localVariableTable": {
          "oneof": [
            "localVariableTable"
          ]
        },
        "_actionEnabledCancelMaskStatus": {
          "oneof": [
            "actionEnabledCancelMaskStatus"
          ]
        },
        "_actionEnabledCanUsePixelAlignStatus": {
          "oneof": [
            "actionEnabledCanUsePixelAlignStatus"
          ]
        },
        "_documentLoaded": {
          "oneof": [
            "documentLoaded"
          ]
        },
        "_devModeInspectTypeViewState": {
          "oneof": [
            "devModeInspectTypeViewState"
          ]
        },
        "_showSidebar": {
          "oneof": [
            "showSidebar"
          ]
        },
        "_actionEnabledToggleShownStatus": {
          "oneof": [
            "actionEnabledToggleShownStatus"
          ]
        },
        "_libraryTextStyleState": {
          "oneof": [
            "libraryTextStyleState"
          ]
        },
        "_inspectSelectionTextContentViewState": {
          "oneof": [
            "inspectSelectionTextContentViewState"
          ]
        },
        "_actionEnabledDetachInstanceStatus": {
          "oneof": [
            "actionEnabledDetachInstanceStatus"
          ]
        },
        "_actionEnabledRulerStatus": {
          "oneof": [
            "actionEnabledRulerStatus"
          ]
        },
        "_actionEnabledBoolSelectionStatus": {
          "oneof": [
            "actionEnabledBoolSelectionStatus"
          ]
        },
        "_actionEnabledColorPickStatus": {
          "oneof": [
            "actionEnabledColorPickStatus"
          ]
        },
        "_FramePresetState": {
          "oneof": [
            "FramePresetState"
          ]
        },
        "_actionEnabledDeletePrototypeStartingPoint": {
          "oneof": [
            "actionEnabledDeletePrototypeStartingPoint"
          ]
        }
      }
    },
    "Viewport": {
      "fields": {
        "height": {
          "id": 5,
          "type": "float"
        },
        "width": {
          "id": 4,
          "type": "float"
        },
        "y": {
          "id": 3,
          "type": "float"
        },
        "x": {
          "id": 2,
          "type": "float"
        },
        "zoom": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "ViewportAnimationKeyframe": {
      "fields": {
        "y": {
          "id": 4,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "x": {
          "id": 3,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "height": {
          "id": 6,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "zoom": {
          "id": 2,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "width": {
          "id": 5,
          "options": {
            "proto3_optional": true
          },
          "type": "float"
        },
        "type": {
          "id": 1,
          "type": "ViewportAnimationKeyframeType"
        }
      },
      "oneofs": {
        "_y": {
          "oneof": [
            "y"
          ]
        },
        "_x": {
          "oneof": [
            "x"
          ]
        },
        "_height": {
          "oneof": [
            "height"
          ]
        },
        "_zoom": {
          "oneof": [
            "zoom"
          ]
        },
        "_width": {
          "oneof": [
            "width"
          ]
        }
      }
    },
    "VisibleViewport": {
      "fields": {
        "paddingBottom": {
          "id": 9,
          "type": "float"
        },
        "paddingTop": {
          "id": 8,
          "type": "float"
        },
        "zoom": {
          "id": 1,
          "type": "float"
        },
        "height": {
          "id": 5,
          "type": "float"
        },
        "width": {
          "id": 4,
          "type": "float"
        },
        "y": {
          "id": 3,
          "type": "float"
        },
        "paddingRight": {
          "id": 7,
          "type": "float"
        },
        "paddingLeft": {
          "id": 6,
          "type": "float"
        },
        "x": {
          "id": 2,
          "type": "float"
        }
      }
    },
    "WCanvasResized": {
      "fields": {
        "devicePixelRatio": {
          "id": 3,
          "type": "float"
        },
        "height": {
          "id": 2,
          "type": "int32"
        },
        "width": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "WasmCall": {
      "fields": {
        "wasmCallSubCode": {
          "id": 3,
          "type": "uint32"
        },
        "wasmCallIndex": {
          "id": 2,
          "type": "uint32"
        },
        "wasmCallCode": {
          "id": 1,
          "type": "WasmCallCode"
        }
      }
    },
    "WheelEvent": {
      "fields": {
        "metaKey": {
          "id": 9,
          "type": "bool"
        },
        "altKey": {
          "id": 8,
          "type": "bool"
        },
        "offsetY": {
          "id": 5,
          "type": "float"
        },
        "deltaZ": {
          "id": 3,
          "type": "float"
        },
        "shiftKey": {
          "id": 7,
          "type": "bool"
        },
        "ctrlKey": {
          "id": 6,
          "type": "bool"
        },
        "deltaY": {
          "id": 2,
          "type": "float"
        },
        "offsetX": {
          "id": 4,
          "type": "float"
        },
        "deltaX": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "WindowClientInnerSize": {
      "fields": {
        "devicePixelRatio": {
          "id": 3,
          "type": "float"
        },
        "innerHeight": {
          "id": 2,
          "type": "int32"
        },
        "innerWidth": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "WindowEventTraceParam": {
      "fields": {
        "data": {
          "id": 4,
          "type": "string"
        },
        "documentActiveElement": {
          "id": 3,
          "type": "string"
        },
        "eventTarget": {
          "id": 2,
          "type": "string"
        },
        "eventType": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "WindowLayout": {
      "fields": {
        "marginRight": {
          "id": 5,
          "type": "int32"
        },
        "marginBottom": {
          "id": 6,
          "type": "int32"
        },
        "marginLeft": {
          "id": 4,
          "type": "int32"
        },
        "marginTop": {
          "id": 3,
          "type": "int32"
        },
        "canvasHeight": {
          "id": 2,
          "type": "int32"
        },
        "canvasWidth": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "WkClipboardCanvasImageBlob": {
      "fields": {
        "type": {
          "id": 2,
          "type": "string"
        },
        "name": {
          "id": 3,
          "type": "string"
        },
        "resourceId": {
          "id": 1,
          "type": "int32"
        }
      }
    },
    "WkClipboardFile": {
      "fields": {
        "buffer": {
          "id": 4,
          "type": "bytes"
        },
        "size": {
          "id": 3,
          "type": "int64"
        },
        "type": {
          "id": 2,
          "type": "string"
        },
        "name": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "WkClipboardItem": {
      "fields": {
        "content": {
          "id": 2,
          "type": "string"
        },
        "type": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "WkClipboardItemForWrite": {
      "fields": {
        "size": {
          "id": 3,
          "type": "uint64"
        },
        "ptr": {
          "id": 2,
          "type": "uint64"
        },
        "type": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "WkClipboardProxyFile": {
      "fields": {
        "size": {
          "id": 3,
          "type": "int64"
        },
        "type": {
          "id": 2,
          "type": "string"
        },
        "blobId": {
          "id": 4,
          "type": "int32"
        },
        "name": {
          "id": 1,
          "type": "string"
        }
      }
    },
    "ZoomAtViewportCenterCommandParam": {
      "fields": {
        "scale": {
          "id": 1,
          "type": "float"
        }
      }
    },
    "ZoomToFitNodesAtMaxScaleByIdsCommandParam": {
      "fields": {
        "nodeIds": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "ZoomToFitNodesByIdsCommandParam": {
      "fields": {
        "nodeIds": {
          "id": 1,
          "rule": "repeated",
          "type": "string"
        }
      }
    },
    "ZoomToolDraggingState": {
      "fields": {
        "worldY": {
          "id": 2,
          "type": "float"
        },
        "state": {
          "id": 3,
          "type": "ZoomToolState"
        },
        "worldX": {
          "id": 1,
          "type": "float"
        }
      }
    }
  }
}
    }
  }
});
export const Wukong = $root.Wukong
Wukong.DocumentProto.VSelectionTextState.ctor = function(arg) {
  this.missFontInfo = Wukong.DocumentProto.VMissFontInfo.create();
  this.fontSize = Wukong.DocumentProto.VTextFontSizeState.create();
  this.textCase = Wukong.DocumentProto.VTextTextCaseState.create();
  this.lineHeight = Wukong.DocumentProto.VTextLineHeightState.create();
  this.fontStyleName = Wukong.DocumentProto.VTextFontStyleNameState.create();
  this.textDecoration = Wukong.DocumentProto.VTextDecorationState.create();
  this.fontName = Wukong.DocumentProto.VTextFontNameState.create();
  this.letterSpacing = Wukong.DocumentProto.VTextLetterSpacingState.create();
  this.lineHeightAutoValue = Wukong.DocumentProto.VTextLineHeightAutoValueState.create();
  this.textAlignVertical = Wukong.DocumentProto.VTextAlignVerticalState.create();
  this.customStyleState = Wukong.DocumentProto.VFontVariableCustomStyleState.create();
  this.textTruncation = Wukong.DocumentProto.VTextTextTruncationState.create();
  this.paragraphSpacing = Wukong.DocumentProto.VTextParagraphSpacingState.create();
  this.textAlignHorizontal = Wukong.DocumentProto.VTextAlignHorizontalState.create();
  this.fontFamilyName = Wukong.DocumentProto.VTextFontFamilyNameState.create();
  this.paragraphIndent = Wukong.DocumentProto.VTextParagraphIndentState.create();
  this.variationInfo = Wukong.DocumentProto.VTextFontVariationState.create();
  this.maxLines = Wukong.DocumentProto.VTextMaxLinesState.create();
  this.textAutoResize = Wukong.DocumentProto.VTextAutoResizeState.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.VSelectionStrokeState.ctor = function(arg) {
  this.dashLenState = Wukong.DocumentProto.SelectionStrokeDashLenState.create();
  this.miterAngleState = Wukong.DocumentProto.SelectionStrokeMiterAngleState.create();
  this.joinState = Wukong.DocumentProto.SelectionStrokeJoinState.create();
  this.modeState = Wukong.DocumentProto.SelectionStrokeModeState.create();
  this.alignState = Wukong.DocumentProto.SelectionStrokeAlignState.create();
  this.endPointStyleState = Wukong.DocumentProto.SelectionStrokeEndPointStyleState.create();
  this.weightState = Wukong.DocumentProto.SelectionStrokeWeightState.create();
  this.dashCapState = Wukong.DocumentProto.SelectionStrokeDashCapState.create();
  this.paintState = Wukong.DocumentProto.SelectionStrokePaintState.create();
  this.dashGapState = Wukong.DocumentProto.SelectionStrokeDashGapState.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.VSelectionPrototypeInteraction.ctor = function(arg) {
  this.popupPosition = Wukong.DocumentProto.PopupPosition.create();
  this.overlaySetting = Wukong.DocumentProto.OverlaySetting.create();
  this.scrollToTargetNodeList = Wukong.DocumentProto.ScrollToTargetNodeList.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.VPublishModalInvalidItem.ctor = function(arg) {
  this.control = Wukong.DocumentProto.VPublishModalNormalControl.create();
  this.thumbnailData = Wukong.DocumentProto.ThumbnailData.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.VPublishModalCommonItem.ctor = function(arg) {
  this.control = Wukong.DocumentProto.VPublishModalNormalControl.create();
  this.thumbnailData = Wukong.DocumentProto.ThumbnailData.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.VPublishModalChangeItem.ctor = function(arg) {
  this.control = Wukong.DocumentProto.VPublishModalNormalControl.create();
  this.thumbnailData = Wukong.DocumentProto.ThumbnailData.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.VPrototypePresentDeviceFrameRegion.ctor = function(arg) {
  this.deviceImagePadding = Wukong.DocumentProto.Vector.create();
  this.deviceImageSize = Wukong.DocumentProto.Vector.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.VLibraryComponentUpdateModalPublishMixinItem.ctor = function(arg) {
  this.thumbnailData = Wukong.DocumentProto.ThumbnailData.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.VPolygonStarData.ctor = function(arg) {
  this.pointCount = Wukong.DocumentProto.MixIntProp.create();
  this.innerRadius = Wukong.DocumentProto.MixProp.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.VDevModeLayerPanelNode.ctor = function(arg) {
  this.nodeIcon = Wukong.DocumentProto.NodeIconData.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.VDevModeInspectExport.ctor = function(arg) {
  this.exportSetting = Wukong.DocumentProto.VDevModeExportSetting.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.VDevModeHoverNodeLabel.ctor = function(arg) {
  this.visibleViewport = Wukong.DocumentProto.VisibleViewport.create();
  this.boundary = Wukong.DocumentProto.Rect.create();
  this.nodeIcon = Wukong.DocumentProto.NodeIconData.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.VDevModeCodeConfig.ctor = function(arg) {
  this.codeConfig = Wukong.DocumentProto.DevModeInspectCodeConfig.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.VComponentPropDef.ctor = function(arg) {
  this.varValue = Wukong.DocumentProto.VariableData.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.UpdateVFParams.ctor = function(arg) {
  this.textStyle = Wukong.DocumentProto.TextStyle.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.UpdateTextStyleNodeFontVariationParam.ctor = function(arg) {
  this.fontVariation = Wukong.DocumentProto.FontVariation.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.VComponentPropPill.ctor = function(arg) {
  this.varValue = Wukong.DocumentProto.VariableData.create();
  this.preferredValues = Wukong.DocumentProto.ComponentPropPreferredValues.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.TextStyleWrapper.ctor = function(arg) {
  this.inner = Wukong.DocumentProto.TextStyle.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.VComponentPropStore.ctor = function(arg) {
  this.sharedInstanceSwapPropDefOfSelectedNodes = Wukong.DocumentProto.VComponentPropPill.create();
  this.sharedTextPropDefOfSelectedNodes = Wukong.DocumentProto.VComponentPropPill.create();
  this.sharedTextValUnit = Wukong.DocumentProto.VTextValUnit.create();
  this.popupVal = Wukong.DocumentProto.VComponentPropDef.create();
  this.sharedBoolPropDefOfSelectedNodes = Wukong.DocumentProto.VComponentPropPill.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.SerBitmap.ctor = function(arg) {
  this.bytes = Wukong.DocumentProto.SerBytes.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.ScrollBehaviorState.ctor = function(arg) {
  this.scrollPosition = Wukong.DocumentProto.ScrollPositionAttr.create();
  this.overflow = Wukong.DocumentProto.ScrollOverflowAttr.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.VLibraryAssetPanel.ctor = function(arg) {
  this.stickyHeader = Wukong.DocumentProto.VLibraryAssetPanelStckyHeader.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Ret_GetImageByHash.ctor = function(arg) {
  this.error = Wukong.DocumentProto.ApiError.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Ret_GetFileKey.ctor = function(arg) {
  this.error = Wukong.DocumentProto.ApiError.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Ret_ApiUngroup.ctor = function(arg) {
  this.error = Wukong.DocumentProto.ApiError.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Ret_ApiStyleMixins.ctor = function(arg) {
  this.error = Wukong.DocumentProto.ApiError.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Ret_ApiSetProps.ctor = function(arg) {
  this.error = Wukong.DocumentProto.ApiError.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Ret_ApiGetSharedPluginData.ctor = function(arg) {
  this.error = Wukong.DocumentProto.ApiError.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Ret_ApiGetMixedIntWithError.ctor = function(arg) {
  this.value = Wukong.DocumentProto.Ret_ApiGetMixedInt.create();
  this.error = Wukong.DocumentProto.ApiError.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Ret_ApiGetMixedFloatWithError.ctor = function(arg) {
  this.value = Wukong.DocumentProto.Ret_ApiGetMixedFloat.create();
  this.error = Wukong.DocumentProto.ApiError.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Ret_ApiGetListOptionsWithError.ctor = function(arg) {
  this.value = Wukong.DocumentProto.Ret_ApiGetLineType.create();
  this.error = Wukong.DocumentProto.ApiError.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Ret_ApiGetLetterSpacingWithError.ctor = function(arg) {
  this.value = Wukong.DocumentProto.Ret_ApiGetLetterSpacing.create();
  this.error = Wukong.DocumentProto.ApiError.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Ret_ApiGetIsExposedInstance.ctor = function(arg) {
  this.error = Wukong.DocumentProto.ApiError.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Ret_ApiGetHyperlinkWithError.ctor = function(arg) {
  this.value = Wukong.DocumentProto.Ret_ApiGetHyperlink.create();
  this.error = Wukong.DocumentProto.ApiError.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Ret_ApiGetComponentPropertyReferencesWithError.ctor = function(arg) {
  this.error = Wukong.DocumentProto.ApiError.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Ret_ApiGetProps.ctor = function(arg) {
  this.value = Wukong.DocumentProto.ApiProps.create();
  this.error = Wukong.DocumentProto.ApiError.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Ret_ApiComponentPropertiesWithError.ctor = function(arg) {
  this.error = Wukong.DocumentProto.ApiError.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Ret_APiStringWithError.ctor = function(arg) {
  this.error = Wukong.DocumentProto.ApiError.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Ret_APiIdRefWithError.ctor = function(arg) {
  this.error = Wukong.DocumentProto.ApiError.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Ret_APiGetSelectionColors.ctor = function(arg) {
  this.error = Wukong.DocumentProto.ApiError.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Ret_APILoadFont.ctor = function(arg) {
  this.error = Wukong.DocumentProto.ApiError.create();
  this.fontName = Wukong.DocumentProto.FontName.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Ret_AICopilotParam.ctor = function(arg) {
  this.boundingBox = Wukong.DocumentProto.Rect.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.PreviewNodeImage.ctor = function(arg) {
  this.textBounds = Wukong.DocumentProto.Rect.create();
  this.fontDesc = Wukong.DocumentProto.PreviewTextNodeFontDesc.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.PluginFillRuleEditorState.ctor = function(arg) {
  this.vectorNetwork = Wukong.DocumentProto.VectorNetwork.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.PluginLooperData.ctor = function(arg) {
  this.strokes = Wukong.DocumentProto.LooperStrokes.create();
  this.opacity = Wukong.DocumentProto.LooperOpacity.create();
  this.rotate = Wukong.DocumentProto.LooperRotate.create();
  this.fills = Wukong.DocumentProto.LooperFills.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.NodeInfo.ctor = function(arg) {
  this.attrs = Wukong.DocumentProto.PartialNode.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.MoveGuideToAnotherNodeCommandParam.ctor = function(arg) {
  this.originalGuideInfo = Wukong.DocumentProto.GuideInfo.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.LocalVariableToggleValuePopupCommandParam.ctor = function(arg) {
  this.position = Wukong.DocumentProto.EditingPopupPosition.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.LocalVariableToggleDetailCommandParam.ctor = function(arg) {
  this.position = Wukong.DocumentProto.EditingPopupPosition.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.LocalVariableTableItem.ctor = function(arg) {
  this.variableData = Wukong.DocumentProto.LocalVariable.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.LocalVariableSetItem.ctor = function(arg) {
  this.modeSelectionState = Wukong.DocumentProto.VVariableSetModeSelectionState.create();
  this.autoMode = Wukong.DocumentProto.AutoModeInfo.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.LocalVariableData.ctor = function(arg) {
  this.resolvedValue = Wukong.DocumentProto.VariableAnyValue.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Ret_ApiGetTextCaseWithError.ctor = function(arg) {
  this.value = Wukong.DocumentProto.Ret_ApiGetTextCase.create();
  this.error = Wukong.DocumentProto.ApiError.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.RemotePrimitiveVariable.ctor = function(arg) {
  this.data = Wukong.DocumentProto.PrimitiveVariableData.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.LocalPrimitiveVariable.ctor = function(arg) {
  this.data = Wukong.DocumentProto.PrimitiveVariableData.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.GetLatestPopupState.ctor = function(arg) {
  this.value = Wukong.DocumentProto.PopupState.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.GenerateStyleCandidateComponentThumbnailParam.ctor = function(arg) {
  this.exportedDocument = Wukong.DocumentProto.SerializedExportedDocument.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.GenerateCandidateComponentThumbnailRet.ctor = function(arg) {
  this.thumbnail = Wukong.DocumentProto.Ret_generatePublishMixinThumbnail.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.FocusAndActiveCommentWithAnimationParam.ctor = function(arg) {
  this.animationParam = Wukong.DocumentProto.StartViewportAnimationParam.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.FindClosestComponentByVariantRet.ctor = function(arg) {
  this.nodeData = Wukong.DocumentProto.ReplaceInstanceNodeData.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.CreateAIGenBaseFrameParam.ctor = function(arg) {
  this.vectorNetwork = Wukong.DocumentProto.VectorNetwork.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.VDevModeInspectComponent.ctor = function(arg) {
  this.componentData = Wukong.DocumentProto.ComponentRelatedData.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Ret_ApiGetPluginData.ctor = function(arg) {
  this.error = Wukong.DocumentProto.ApiError.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.FontNameInfo.ctor = function(arg) {
  this.fontName = Wukong.DocumentProto.FontName.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.ComponentPropsAppliedValue.ctor = function(arg) {
  this.value = Wukong.DocumentProto.VariableAnyValue.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.VariableBindableComponentProperty.ctor = function(arg) {
  this.value = Wukong.DocumentProto.VariableAliasOfComponentProperty.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.LocalVariableEditorEditingStateInternal.ctor = function(arg) {
  this.position = Wukong.DocumentProto.EditingPopupPosition.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.EffectPage.ctor = function(arg) {
  this.backgroundBlurCluster = Wukong.DocumentProto.Cluster.create();
  this.layerBlurCluster = Wukong.DocumentProto.Cluster.create();
  this.innerShadowCluster = Wukong.DocumentProto.Cluster.create();
  this.dropShadowCluster = Wukong.DocumentProto.Cluster.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.CustomAxesStyleInfo.ctor = function(arg) {
  this.customAxesStyle = Wukong.DocumentProto.VariantFontCustomAxesStyle.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.ReplaceInstanceLibrary.ctor = function(arg) {
  this.tree = Wukong.DocumentProto.ReplaceInstanceTree.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Ret_ApiSymbolOverridesWithError.ctor = function(arg) {
  this.error = Wukong.DocumentProto.ApiError.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Ret_AICopilotInfo.ctor = function(arg) {
  this.boundingBox = Wukong.DocumentProto.Rect.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Ret_ApiGetFillsWithError.ctor = function(arg) {
  this.value = Wukong.DocumentProto.Ret_ApiGetFills.create();
  this.error = Wukong.DocumentProto.ApiError.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.GetBestFullFontNameParam.ctor = function(arg) {
  this.fontName = Wukong.DocumentProto.FontName.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.LocalVariableEditorEditingState.ctor = function(arg) {
  this.editingVariableData = Wukong.DocumentProto.LocalVariableData.create();
  this.position = Wukong.DocumentProto.EditingPopupPosition.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.VTextStyleNodeState.ctor = function(arg) {
  this.customStyleState = Wukong.DocumentProto.VFontVariableCustomStyleState.create();
  this.variationInfo = Wukong.DocumentProto.VTextFontVariationState.create();
  this.basic = Wukong.DocumentProto.VTextStyleNode.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.AttachCornerRadiusFloatVarParam.ctor = function(arg) {
  this.variableData = Wukong.DocumentProto.VariableAliasData.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.PrototypeAction.ctor = function(arg) {
  this.extraScrollOffset = Wukong.DocumentProto.Vector.create();
  this.overlayRelativePosition = Wukong.DocumentProto.Vector.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.RemoteVariableSetItem.ctor = function(arg) {
  this.autoMode = Wukong.DocumentProto.AutoModeInfo.create();
  this.modeSelectionState = Wukong.DocumentProto.VVariableSetModeSelectionState.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Ret_ApiGetLineHeightWithError.ctor = function(arg) {
  this.value = Wukong.DocumentProto.Ret_ApiGetLineHeight.create();
  this.error = Wukong.DocumentProto.ApiError.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.BuildAISelectedStyleNodeDataCommandRet.ctor = function(arg) {
  this.selectedComponentThumbnailData = Wukong.DocumentProto.ThumbnailData.create();
  this.variantState = Wukong.DocumentProto.VariantState.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.VDevModeInspectStroke.ctor = function(arg) {
  this.alignState = Wukong.DocumentProto.SelectionStrokeAlignState.create();
  this.weightStateWrapper = Wukong.DocumentProto.SelectionStrokeWeightStateWrapper.create();
  this.paintState = Wukong.DocumentProto.SelectionStrokePaintState.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.ReplaceMissingFont.ctor = function(arg) {
  this.toFontName = Wukong.DocumentProto.FontName.create();
  this.fromFontName = Wukong.DocumentProto.FontName.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Ret_ApiGetMixedStringWithError.ctor = function(arg) {
  this.value = Wukong.DocumentProto.Ret_ApiGetMixedString.create();
  this.error = Wukong.DocumentProto.ApiError.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Arg_MeasureControlTextWidth.ctor = function(arg) {
  this.meta = Wukong.DocumentProto.CanvasControlParagraphMeta.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.PaintStyleInfo.ctor = function(arg) {
  this.style = Wukong.DocumentProto.VPaintStyleNode.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.VPublishModal.ctor = function(arg) {
  this.changedComponentTitle = Wukong.DocumentProto.VPublishModalCollapseControl.create();
  this.changedVariableSetTitle = Wukong.DocumentProto.VPublishModalCollapseControl.create();
  this.changedStyleTitle = Wukong.DocumentProto.VPublishModalCollapseControl.create();
  this.changedControl = Wukong.DocumentProto.VPublishModalCollapseControl.create();
  this.invalidControl = Wukong.DocumentProto.VPublishModalCollapseControl.create();
  this.hiddenTip = Wukong.DocumentProto.VPublishModalNormalControl.create();
  this.hiddenControl = Wukong.DocumentProto.VPublishModalCollapseControl.create();
  this.unchangeControl = Wukong.DocumentProto.VPublishModalCollapseControl.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.UpdateTextStyleNodeFontFamilyParam.ctor = function(arg) {
  this.fontName = Wukong.DocumentProto.FontName.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.ReplaceInstanceNodeData.ctor = function(arg) {
  this.thumbnailData = Wukong.DocumentProto.ThumbnailData.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.VLayerPanelItemInfo.ctor = function(arg) {
  this.layerPanelSeeAllLayersInfo = Wukong.DocumentProto.VLayerPanelSeeAllLayers.create();
  this.layerPanelNodeInfo = Wukong.DocumentProto.VLayerPanelNode.create();
  this.layerPanelSectionInfo = Wukong.DocumentProto.VLayerPanelSection.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Arg_cmdCreateComponentPropDefineForSelected.ctor = function(arg) {
  this.value = Wukong.DocumentProto.VariableAnyValue.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.PrototypeInteractionWithNodeId.ctor = function(arg) {
  this.event = Wukong.DocumentProto.PrototypeEvent.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Arg_UpdateOverlayBackgroundAppearance.ctor = function(arg) {
  this.backgroundAppearance = Wukong.DocumentProto.OverlayBackgroundAppearance.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.CandidateReplaceInstanceNodeData.ctor = function(arg) {
  this.nodeData = Wukong.DocumentProto.ReplaceInstanceNodeData.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.AttachAutoLayoutVarParam.ctor = function(arg) {
  this.var = Wukong.DocumentProto.VariableAliasData.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.ComponentPropEditPopupVal.ctor = function(arg) {
  this.varValue = Wukong.DocumentProto.VariableData.create();
  this.preferredValues = Wukong.DocumentProto.ComponentPropPreferredValues.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Ret_ApiGetTextDecorationWithError.ctor = function(arg) {
  this.value = Wukong.DocumentProto.Ret_ApiGetTextDecoration.create();
  this.error = Wukong.DocumentProto.ApiError.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.VInstanceSwapPreferredValue.ctor = function(arg) {
  this.thumbnailData = Wukong.DocumentProto.ThumbnailData.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.MissLibraryComponentOrStyleItem.ctor = function(arg) {
  this.thumbnailData = Wukong.DocumentProto.ThumbnailData.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.VLayerPanelNode.ctor = function(arg) {
  this.nodeIcon = Wukong.DocumentProto.NodeIconData.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Ret_ApiGetPluginDataKeys.ctor = function(arg) {
  this.error = Wukong.DocumentProto.ApiError.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Arg_RGBAtoCode.ctor = function(arg) {
  this.rgb = Wukong.DocumentProto.RGB.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.VMotiffScopeData.ctor = function(arg) {
  this.doc = Wukong.DocumentProto.DummyNodeData.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Arg_AICopilotParam.ctor = function(arg) {
  this.candidateNode = Wukong.DocumentProto.AiCopilotCandidateNode.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.ActiveUserProto.ctor = function(arg) {
  this.userProfile = Wukong.DocumentProto.UserProfileProto.create();
  this.properties = Wukong.DocumentProto.UserPropertiesProto.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.ControlParagraphFont.ctor = function(arg) {
  this.style = Wukong.DocumentProto.ControlParagraphFontStyle.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.CanvasControlParagraphMeta.ctor = function(arg) {
  this.font = Wukong.DocumentProto.ControlParagraphFont.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Ret_ApiGetAllFontNameWithError.ctor = function(arg) {
  this.error = Wukong.DocumentProto.ApiError.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Arg_GetCanvasGlyphBitmap.ctor = function(arg) {
  this.meta = Wukong.DocumentProto.CanvasControlParagraphMeta.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.DeviceOption.ctor = function(arg) {
  this.offset = Wukong.DocumentProto.Vector.create();
  this.imageSize = Wukong.DocumentProto.Vector.create();
  this.framePresetSize = Wukong.DocumentProto.Vector.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.DownloadTemplateRet.ctor = function(arg) {
  this.doc = Wukong.DocumentProto.SynergyDocument.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Arg_BatchAttachFloatVarForLayoutMixinParam.ctor = function(arg) {
  this.variableData = Wukong.DocumentProto.VariableAliasData.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.VVariableSetModeState.ctor = function(arg) {
  this.variableSetMode = Wukong.DocumentProto.VariableSetMode.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Arg_ExecuteRecoverToRemoteDefaultVariantCommand.ctor = function(arg) {
  this.componentSetExportedDocument = Wukong.DocumentProto.SerializedExportedDocument.create();
  this.recoverInfo = Wukong.DocumentProto.Arg_FetchRemoteComponentSetDataForRecover.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Req_ApiEditComponentProperty.ctor = function(arg) {
  this.newValue = Wukong.DocumentProto.EditComponentPropertyValue.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.OverlayBackgroundAppearance.ctor = function(arg) {
  this.backgroundColor = Wukong.DocumentProto.RGBA.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Arg_exportPng.ctor = function(arg) {
  this.constraint = Wukong.DocumentProto.ExportConstraintByFloat.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.VLibraryAssetPanelComponentItem.ctor = function(arg) {
  this.thumbnailData = Wukong.DocumentProto.ThumbnailData.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.AIReplacePreviewSnapshotCommandParam.ctor = function(arg) {
  this.replaceData = Wukong.DocumentProto.ApplyAIComponentReplaceCommandParam.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Arg_ApiSetComponentPropertyReferences.ctor = function(arg) {
  this.references = Wukong.DocumentProto.ComponentPropertyReferences.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.BasicTextStyle.ctor = function(arg) {
  this.lineHeight = Wukong.DocumentProto.LineHeight.create();
  this.letterSpacing = Wukong.DocumentProto.LetterSpacing.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Ret_Viewport.ctor = function(arg) {
  this.error = Wukong.DocumentProto.ApiError.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Arg_ApiGetNodeIdRef.ctor = function(arg) {
  this.error = Wukong.DocumentProto.ApiError.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.DuplicateNodeInfo.ctor = function(arg) {
  this.jsScript = Wukong.DocumentProto.Arg_evalJsScript.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.VIndependentCornerRadius.ctor = function(arg) {
  this.site3 = Wukong.DocumentProto.MixProp.create();
  this.site4 = Wukong.DocumentProto.MixProp.create();
  this.site2 = Wukong.DocumentProto.MixProp.create();
  this.site1 = Wukong.DocumentProto.MixProp.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.ApiProps.ctor = function(arg) {
  this.textStyleId = Wukong.DocumentProto.Ret_ApiMixedStyleId.create();
  this.textCase = Wukong.DocumentProto.Ret_ApiGetTextCase.create();
  this.hyperlink = Wukong.DocumentProto.Ret_ApiGetHyperlink.create();
  this.lineHeight = Wukong.DocumentProto.Ret_ApiGetLineHeight.create();
  this.letterSpacing = Wukong.DocumentProto.Ret_ApiGetLetterSpacing.create();
  this.textDecoration = Wukong.DocumentProto.Ret_ApiGetTextDecoration.create();
  this.cornerRadius = Wukong.DocumentProto.Ret_ApiGetMixedFloat.create();
  this.strokeWeight = Wukong.DocumentProto.Ret_ApiGetMixedFloat.create();
  this.strokeJoin = Wukong.DocumentProto.Ret_ApiGetStrokeJoin.create();
  this.absoluteTransform = Wukong.DocumentProto.Transform.create();
  this.absoluteBoundingBox = Wukong.DocumentProto.Rect.create();
  this.absoluteRenderBounds = Wukong.DocumentProto.Rect.create();
  this.fills = Wukong.DocumentProto.Ret_ApiMixedFills.create();
  this.vectorNetwork = Wukong.DocumentProto.VectorNetwork.create();
  this.fillStyleId = Wukong.DocumentProto.Ret_ApiMixedStyleId.create();
  this.strokeCap = Wukong.DocumentProto.Ret_ApiGetStrokeCap.create();
  this.arcData = Wukong.DocumentProto.ArcData.create();
  this.fontWeight = Wukong.DocumentProto.Ret_ApiGetMixedFloat.create();
  this.fontSize = Wukong.DocumentProto.Ret_ApiGetMixedFloat.create();
  this.fontName = Wukong.DocumentProto.Ret_ApiGetFontName.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.ComponentPropValue.ctor = function(arg) {
  this.textValue = Wukong.DocumentProto.TextData.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Ret_ApiComponentPropertyDefinitionsWithError.ctor = function(arg) {
  this.error = Wukong.DocumentProto.ApiError.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Ret_ApiGetMixedFontNameWithError.ctor = function(arg) {
  this.value = Wukong.DocumentProto.Ret_ApiGetFontName.create();
  this.error = Wukong.DocumentProto.ApiError.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Arg_ApiTextRangeWithLetterSpacing.ctor = function(arg) {
  this.value = Wukong.DocumentProto.LetterSpacing.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.AIRecognizeResult.ctor = function(arg) {
  this.debugIconPage = Wukong.DocumentProto.IconPage.create();
  this.debugTextPage = Wukong.DocumentProto.TextPage.create();
  this.debugColorPage = Wukong.DocumentProto.ColorPage.create();
  this.textPage = Wukong.DocumentProto.TextPage.create();
  this.iconPage = Wukong.DocumentProto.IconPage.create();
  this.effectPage = Wukong.DocumentProto.EffectPage.create();
  this.debugEffectPage = Wukong.DocumentProto.EffectPage.create();
  this.colorPage = Wukong.DocumentProto.ColorPage.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Ret_ApiGetLetterSpacing.ctor = function(arg) {
  this.value = Wukong.DocumentProto.LetterSpacing.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Ret_ApiIdRefWithError.ctor = function(arg) {
  this.error = Wukong.DocumentProto.ApiError.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Ret_ApiCurrentUser.ctor = function(arg) {
  this.color = Wukong.DocumentProto.RGB.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.VVariableSetModeSelectionState.ctor = function(arg) {
  this.selectedMode = Wukong.DocumentProto.VariableSetMode.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.AttachStrokeWeightFloatVarCommandParams.ctor = function(arg) {
  this.variableData = Wukong.DocumentProto.VariableAliasData.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Allocation.ctor = function(arg) {
  this.range = Wukong.DocumentProto.RangeFrame.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.PrototypeEvent.ctor = function(arg) {
  this.keyTrigger = Wukong.DocumentProto.KeyTrigger.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.AISearchNode.ctor = function(arg) {
  this.iconData = Wukong.DocumentProto.NodeIconData.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.AIRecognizeResultWithEncodedNodesV2.ctor = function(arg) {
  this.aiResult = Wukong.DocumentProto.AIRecognizeResult.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Arg_loadFontInJsFailed.ctor = function(arg) {
  this.fontName = Wukong.DocumentProto.FontName.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.VSelectionHyperlinkState.ctor = function(arg) {
  this.sameHyperlink = Wukong.DocumentProto.Hyperlink.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.HtmlAIGenParam.ctor = function(arg) {
  this.part = Wukong.DocumentProto.HtmlAIGenResult.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.VTextStyleNode.ctor = function(arg) {
  this.lineHeight = Wukong.DocumentProto.LineHeight.create();
  this.letterSpacing = Wukong.DocumentProto.LetterSpacing.create();
  this.fontName = Wukong.DocumentProto.FontName.create();
  this.missFontInfo = Wukong.DocumentProto.VMissFontInfo.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Arg_ApiTextRangeWithLineHeight.ctor = function(arg) {
  this.value = Wukong.DocumentProto.LineHeight.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.AIRecognizeResultWithNodes.ctor = function(arg) {
  this.result = Wukong.DocumentProto.AIRecognizeResult.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Ret_CreateImage.ctor = function(arg) {
  this.error = Wukong.DocumentProto.ApiError.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.AIRecognizeResultWithEncodedNodes.ctor = function(arg) {
  this.aiResult = Wukong.DocumentProto.AIRecognizeResult.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Arg_ImportRemoteLibNodeByKeyAsyncCallbackCommand.ctor = function(arg) {
  this.exportedDocument = Wukong.DocumentProto.SerializedExportedDocument.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.ComponentCandidate.ctor = function(arg) {
  this.componentData = Wukong.DocumentProto.ComponentData.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Arg_cmdCreateComponentPropDefine.ctor = function(arg) {
  this.value = Wukong.DocumentProto.VariableAnyValue.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.VariableDataValue.ctor = function(arg) {
  this.variableData = Wukong.DocumentProto.VariableData.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Arg_ApiTextRangeWithFontName.ctor = function(arg) {
  this.value = Wukong.DocumentProto.FontName.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Arg_AttachFloatVarForLayoutMixinParam.ctor = function(arg) {
  this.variableData = Wukong.DocumentProto.VariableAliasData.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Ret_APiBoolWithError.ctor = function(arg) {
  this.error = Wukong.DocumentProto.ApiError.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.AiCopilotCandidateNode.ctor = function(arg) {
  this.script = Wukong.DocumentProto.Arg_evalJsScript.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.VLibraryReplaceModalItem.ctor = function(arg) {
  this.thumbnailData = Wukong.DocumentProto.ThumbnailData.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.VariableLayerModeState.ctor = function(arg) {
  this.mode = Wukong.DocumentProto.VariableSetMode.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.PresetCodeTypeToFileType.ctor = function(arg) {
  this.androidConfig = Wukong.DocumentProto.FileTypeToScale.create();
  this.webConfig = Wukong.DocumentProto.FileTypeToScale.create();
  this.iosConfig = Wukong.DocumentProto.FileTypeToScale.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.PrototypeAnimation.ctor = function(arg) {
  this.overlay = Wukong.DocumentProto.PrototypeOverlay.create();
  this.action = Wukong.DocumentProto.PrototypeAction.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.PrototypePresentDeviceFrameRegion.ctor = function(arg) {
  this.deviceFrameSize = Wukong.DocumentProto.Vector.create();
  this.canvasSize = Wukong.DocumentProto.Vector.create();
  this.deviceFrameRegion = Wukong.DocumentProto.SkRect.create();
  this.deviceFrameScreenSize = Wukong.DocumentProto.Vector.create();
  this.deviceImageRegion = Wukong.DocumentProto.SkRect.create();
  this.deviceFrameOffset = Wukong.DocumentProto.Vector.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.PrototypeInteraction.ctor = function(arg) {
  this.event = Wukong.DocumentProto.PrototypeEvent.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.ComponentData.ctor = function(arg) {
  this.highlightArea = Wukong.DocumentProto.Rect.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.PrototypeDevice.ctor = function(arg) {
  this.size = Wukong.DocumentProto.Vector.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Arg_UpdateVectorStyleOverrideTableParam.ctor = function(arg) {
  this.styleOverride = Wukong.DocumentProto.StyleOverride.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.VariableAnyValue.ctor = function(arg) {
  this.colorValue = Wukong.DocumentProto.RGBA.create();
  this.textDataValue = Wukong.DocumentProto.TextData.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.VPrototypeInteractionActionAnimation.ctor = function(arg) {
  this.easingFunction = Wukong.DocumentProto.EasingFunctionPropValue.create();
  this.transitionDuration = Wukong.DocumentProto.TransitionDurationPropValue.create();
  this.easingType = Wukong.DocumentProto.EasingTypePropValue.create();
  this.transitionShouldSmartAnimate = Wukong.DocumentProto.TransitionShouldSmartAnimate.create();
  this.transitionType = Wukong.DocumentProto.TransitionTypeProtoValue.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.StyledTextSegmentExt.ctor = function(arg) {
  this.listOptions = Wukong.DocumentProto.TextListOptions.create();
  this.lineHeight = Wukong.DocumentProto.LineHeight.create();
  this.letterSpacing = Wukong.DocumentProto.LetterSpacing.create();
  this.fontName = Wukong.DocumentProto.FontName.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Ret_ApiGetLineHeight.ctor = function(arg) {
  this.value = Wukong.DocumentProto.LineHeight.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.ComponentPropDef.ctor = function(arg) {
  this.varValue = Wukong.DocumentProto.VariableData.create();
  this.preferredValues = Wukong.DocumentProto.ComponentPropPreferredValues.create();
  this.initialValue = Wukong.DocumentProto.ComponentPropValue.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Arg_PluginEvent.ctor = function(arg) {
  this.dropEvent = Wukong.DocumentProto.Arg_PluginDropEvent.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Arg_PluginApiExportSetting.ctor = function(arg) {
  this.constraint = Wukong.DocumentProto.ExportSettingsConstraints.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.AIGenParam.ctor = function(arg) {
  this.part = Wukong.DocumentProto.AIGenResult.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.PrototypeOverlay.ctor = function(arg) {
  this.action = Wukong.DocumentProto.PrototypeAction.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.OverlaySetting.ctor = function(arg) {
  this.backgroundAppearance = Wukong.DocumentProto.OverlayBackgroundAppearance.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.AiLayoutEntryMenuState.ctor = function(arg) {
  this.position = Wukong.DocumentProto.Vector.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.SegmentInfo.ctor = function(arg) {
  this.style = Wukong.DocumentProto.BasicTextStyle.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.SelectionStrokeWeightStateWrapper.ctor = function(arg) {
  this.weightState = Wukong.DocumentProto.SelectionStrokeWeightState.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.ComponentPropAssignment.ctor = function(arg) {
  this.varValue = Wukong.DocumentProto.VariableData.create();
  this.value = Wukong.DocumentProto.ComponentPropValue.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Arg_UpdatePrototypeDevice.ctor = function(arg) {
  this.config = Wukong.DocumentProto.PrototypeDevice.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Ret_ApiGetSharedPluginDataKeys.ctor = function(arg) {
  this.error = Wukong.DocumentProto.ApiError.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.VariableAliasData.ctor = function(arg) {
  this.value = Wukong.DocumentProto.VariableAliasValue.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.PrototypeInteractionShadow.ctor = function(arg) {
  this.eventShadow = Wukong.DocumentProto.PrototypeEventShadow.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.VariableData.ctor = function(arg) {
  this.value = Wukong.DocumentProto.VariableAnyValue.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Arg_UpdateColorVariableValueCommandParam.ctor = function(arg) {
  this.colorRGBAValue = Wukong.DocumentProto.RGBA.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.VConstraintsState.ctor = function(arg) {
  this.vertical = Wukong.DocumentProto.MixConstraintsProp.create();
  this.horizontal = Wukong.DocumentProto.MixConstraintsProp.create();

                Object.assign(this, arg);
                }
            
Wukong.DocumentProto.Ret_ApiStyledTextSegmentsWithError.ctor = function(arg) {
  this.error = Wukong.DocumentProto.ApiError.create();

                Object.assign(this, arg);
                }
            

export { $root as default };

export const { Reader, Writer } = $protobuf;
export const Void = {
    decodeDelimited() {},
    encodeDelimited() {
        return undefined
    },
}
export const LocalVariableEditor_AbortRenameCollectionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_LocalVariableEditor_AbortRenameCollection,
    returnType: Void,
    argumentType: Void,
}
export const LocalVariableEditor_AbortRenameVariableCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_LocalVariableEditor_AbortRenameVariable,
    returnType: Void,
    argumentType: Void,
}
export const LocalVariableEditor_AddSelectedVariablesIntoGroupCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_LocalVariableEditor_AddSelectedVariablesIntoGroupCommand,
    returnType: Void,
    argumentType: Void,
}
export const LocalVariableEditor_ClearGroupSelectionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_LocalVariableEditor_ClearGroupSelectionCommand,
    returnType: Void,
    argumentType: Void,
}
export const LocalVariableEditor_ClearVariableSelectionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_LocalVariableEditor_ClearVariableSelectionCommand,
    returnType: Void,
    argumentType: Void,
}
export const LocalVariableEditor_CloseVariableEditingPopupCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_LocalVariableEditor_CloseVariableEditingPopup,
    returnType: Void,
    argumentType: Void,
}
export const LocalVariableEditor_CollapseGroupCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_LocalVariableEditor_CollapseGroup,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const LocalVariableEditor_CreateCollectionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_LocalVariableEditor_CreateCollection,
    returnType: Void,
    argumentType: Void,
}
export const LocalVariableEditor_CreateCollectionModeCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_LocalVariableEditor_CreateCollectionMode,
    returnType: Void,
    argumentType: Void,
}
export const LocalVariableEditor_CreateVariableCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_LocalVariableEditor_CreateVariable,
    returnType: Void,
    argumentType: Wukong.DocumentProto.LocalVariableCreateCommandParam,
}
export const LocalVariableEditor_DeleteCollectionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_LocalVariableEditor_DeleteCollection,
    returnType: Void,
    argumentType: Void,
}
export const LocalVariableEditor_DeleteCollectionModeCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_LocalVariableEditor_DeleteCollectionMode,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const LocalVariableEditor_DeleteSelectedGroupsCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_LocalVariableEditor_DeleteSelectedGroupsCommand,
    returnType: Void,
    argumentType: Void,
}
export const LocalVariableEditor_DeleteSelectedVariablesCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_LocalVariableEditor_DeleteSelectedVariables,
    returnType: Void,
    argumentType: Void,
}
export const LocalVariableEditor_DirectRenameVariableCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_LocalVariableEditor_DirectRenameVariable,
    returnType: Void,
    argumentType: Wukong.DocumentProto.LocalVariableRenameCommandParam,
}
export const LocalVariableEditor_DragGroupCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_LocalVariableEditor_DragGroupCommand,
    returnType: Void,
    argumentType: Wukong.DocumentProto.LocalVariableDragGroupCommandParam,
}
export const LocalVariableEditor_DragSelectedVariablesCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_LocalVariableEditor_DragSelectedVariablesCommand,
    returnType: Void,
    argumentType: Wukong.DocumentProto.LocalVariableDragCommandParam,
}
export const LocalVariableEditor_DragSelectedVariablesIntoGroupCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_LocalVariableEditor_DragSelectedVariablesIntoGroupCommand,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const LocalVariableEditor_DuplicateCollectionModeCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_LocalVariableEditor_DuplicateCollectionMode,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const LocalVariableEditor_DuplicateSelectedGroupsCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_LocalVariableEditor_DuplicateSelectedGroupsCommand,
    returnType: Void,
    argumentType: Void,
}
export const LocalVariableEditor_DuplicateSelectedVariablesCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_LocalVariableEditor_DuplicateSelectedVariables,
    returnType: Void,
    argumentType: Void,
}
export const LocalVariableEditor_ExpandGroupCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_LocalVariableEditor_ExpandGroup,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const LocalVariableEditor_ForceSelectGroupCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_LocalVariableEditor_ForceSelectGroupCommand,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const LocalVariableEditor_ForceSelectVariableCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_LocalVariableEditor_ForceSelectVariableCommand,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const LocalVariableEditor_GetSelectionScrollIndexInTableCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_LocalVariableEditor_GetSelectionScrollIndexInTable,
    returnType: Wukong.DocumentProto.BridgeProtoInt,
    argumentType: Void,
}
export const LocalVariableEditor_MultiSelectGroupCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_LocalVariableEditor_MultiSelectGroupCommand,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const LocalVariableEditor_MultiSelectVariableCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_LocalVariableEditor_MultiSelectVariableCommand,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const LocalVariableEditor_RangeSelectGroupCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_LocalVariableEditor_RangeSelectGroupCommand,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const LocalVariableEditor_RangeSelectVariableCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_LocalVariableEditor_RangeSelectVariableCommand,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const LocalVariableEditor_RenameCollectionModeCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_LocalVariableEditor_RenameCollectionMode,
    returnType: Void,
    argumentType: Wukong.DocumentProto.LocalVariableRenameCommandParam,
}
export const LocalVariableEditor_RenameGroupCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_LocalVariableEditor_RenameGroupCommand,
    returnType: Void,
    argumentType: Wukong.DocumentProto.LocalVariableRenameCommandParam,
}
export const LocalVariableEditor_SafeSelectGroupCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_LocalVariableEditor_SafeSelectGroupCommand,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const LocalVariableEditor_SafeSelectVariableCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_LocalVariableEditor_SafeSelectVariableCommand,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const LocalVariableEditor_SelectCollectionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_LocalVariableEditor_SelectCollection,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const LocalVariableEditor_StartRenameCollectionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_LocalVariableEditor_StartRenameCollection,
    returnType: Void,
    argumentType: Void,
}
export const LocalVariableEditor_StartRenameVariableCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_LocalVariableEditor_StartRenameVariable,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const LocalVariableEditor_SubmitRenameCollectionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_LocalVariableEditor_SubmitRenameCollection,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const LocalVariableEditor_SubmitRenameVariableCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_LocalVariableEditor_SubmitRenameVariable,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const LocalVariableEditor_TogglePanelCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_LocalVariableEditor_TogglePanel,
    returnType: Void,
    argumentType: Void,
}
export const LocalVariableEditor_ToggleVariableDetailPopupCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_LocalVariableEditor_ToggleVariableDetailPopup,
    returnType: Void,
    argumentType: Wukong.DocumentProto.LocalVariableToggleDetailCommandParam,
}
export const LocalVariableEditor_ToggleVariableValuePopupCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_LocalVariableEditor_ToggleVariableValuePopup,
    returnType: Void,
    argumentType: Wukong.DocumentProto.LocalVariableToggleValuePopupCommandParam,
}
export const LocalVariableEditor_UngroupSelectedGroupsCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_LocalVariableEditor_UngroupSelectedGroupsCommand,
    returnType: Void,
    argumentType: Void,
}
export const MoveSelectionNodesFrontOrBackWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_MoveSelectionNodesFrontOrBackCommand,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoBoolean,
}
export const NodePreviewSubscribeCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_NodePreviewSubscribe,
    returnType: Void,
    argumentType: Void,
}
export const NodePreviewUnsubscribeCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_NodePreviewUnsubscribe,
    returnType: Void,
    argumentType: Void,
}
export const PluginApiExclude  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_PluginApiExclude,
    returnType: Wukong.DocumentProto.Ret_ApiIdRefWithError,
    argumentType: Wukong.DocumentProto.Arg_PluginApiMoveNodes,
}
export const PluginApiGetProps  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_PluginApiGetProps,
    returnType: Wukong.DocumentProto.Ret_ApiGetProps,
    argumentType: Wukong.DocumentProto.Arg_ApiGetProps,
}
export const PluginApiIntersect  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_PluginApiIntersect,
    returnType: Wukong.DocumentProto.Ret_ApiIdRefWithError,
    argumentType: Wukong.DocumentProto.Arg_PluginApiMoveNodes,
}
export const PluginApiSetProps  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_PluginApiSetProps,
    returnType: Wukong.DocumentProto.Ret_ApiSetProps,
    argumentType: Wukong.DocumentProto.Arg_ApiSetProps,
}
export const PluginApiSwapMainComponent  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_PluginApiSwapMainComponent,
    returnType: Wukong.DocumentProto.ReturnTypeWithError,
    argumentType: Wukong.DocumentProto.Arg_PluginApiSwapComponent,
}
export const PluginApiUnion  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_PluginApiUnion,
    returnType: Wukong.DocumentProto.Ret_ApiIdRefWithError,
    argumentType: Wukong.DocumentProto.Arg_PluginApiMoveNodes,
}
export const PrototypeDelayedInteractionsCallback  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_PrototypeDelayedInteractionsCallback,
    returnType: Void,
    argumentType: Void,
}
export const AddAutoLayoutCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_addAutoLayout,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Args_AddAutoLayout,
}
export const AddSelectionPrototypeInteraction  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_addSelectionPrototypeInteraction,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_AddSelectionPrototypeInteraction,
}
export const AddVariableSetModeCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_addVariableSetMode_deprecated,
    returnType: Wukong.DocumentProto.BridgeProtoString,
    argumentType: Wukong.DocumentProto.Arg_AddVariableSetModeCommandParam,
}
export const AddVariantForSelectedComponentWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_addVariantForSelectedComponent,
    returnType: Void,
    argumentType: Void,
}
export const AnimateHotspotHintForJs  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_animatePrototypeHotspotHint,
    returnType: Void,
    argumentType: Void,
}
export const ApplyAIGenCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_applyAIGenCommand,
    returnType: Wukong.DocumentProto.AIGenReturn,
    argumentType: Wukong.DocumentProto.AIGenParam,
}
export const ApplyAISearchInsertNodeCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_applyAISearchInsertNode,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_ApplyAISearchInsertNodeCommand,
}
export const ApplyAiCandidate  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_applyAiCopilot,
    returnType: Wukong.DocumentProto.Ret_AICopilotResult,
    argumentType: Wukong.DocumentProto.Arg_AICopilotParam,
}
export const ApplyChatBotAIGenCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_applyChatBotAIGen,
    returnType: Wukong.DocumentProto.Ret_ApplyChatBot,
    argumentType: Wukong.DocumentProto.Arg_ApplyChatBot,
}
export const ApplyEffectStyleForSelectionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_applyEffectStyleForSelection,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const ApplyHtmlAIGenCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_applyHtmlAIGenCommand,
    returnType: Wukong.DocumentProto.HtmlAIGenReturn,
    argumentType: Wukong.DocumentProto.HtmlAIGenParam,
}
export const BackwardPresentFrameCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_backwardPresentFrame,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoIdRef,
}
export const BatchAttachAutoLayoutVarCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_batchAttachAutoLayoutVar,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BatchAttachAutoLayoutVarParam,
}
export const BatchComponentSelectionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_batchComponentSelection,
    returnType: Void,
    argumentType: Void,
}
export const BatchDetachAutoLayoutVarCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_batchDetachAutoLayoutVar,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BatchDetachAutoLayoutVarParam,
}
export const BatchUpdateVariablePublishHiddenCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_batchUpdateVariablePublishHidden,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_BatchUpdateVariablePublishHiddenCommandParam,
}
export const BatchUpdateVariableScopesCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_batchUpdateVariableScopes,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_BatchUpdateVariableScopesCommandParam,
}
export const BeginPreviewCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_beginPerview,
    returnType: Void,
    argumentType: Void,
}
export const BoolSelectionNodesCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_boolSelection,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BoolSelectionNodesCommandParam,
}
export const BuildLibraryComponentUpdateVOWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_buildLibraryComponentUpdateVO,
    returnType: Wukong.DocumentProto.Ret_buildComponentUpdateVO,
    argumentType: Wukong.DocumentProto.Arg_buildComponentUpdateVO,
}
export const BuildLibraryStyleUpdateVOWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_buildLibraryStyleUpdateVO,
    returnType: Wukong.DocumentProto.Ret_buildStyleUpdateVO,
    argumentType: Wukong.DocumentProto.Arg_buildStyleUpdateVO,
}
export const BuildLibraryVariableSetUpdateVOWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_buildLibraryVariableSetUpdateVO,
    returnType: Wukong.DocumentProto.Ret_buildVariableSetUpdateVO,
    argumentType: Wukong.DocumentProto.Arg_buildVariableSetUpdateVO,
}
export const BuildLibraryVariableUpdateVOWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_buildLibraryVariableUpdateVO,
    returnType: Wukong.DocumentProto.Ret_buildVariableUpdateVO,
    argumentType: Wukong.DocumentProto.Arg_buildVariableUpdateVO,
}
export const CanOpenPresentCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_canOpenPresent,
    returnType: Wukong.DocumentProto.BridgeProtoBoolean,
    argumentType: Void,
}
export const CancelHyperlinkEditing  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cancelHyperlinkEditing,
    returnType: Void,
    argumentType: Void,
}
export const ChangeEditorModeCommandForDevMode  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_changeEditorModeForDevMode,
    returnType: Void,
    argumentType: Wukong.DocumentProto.EditorModeState,
}
export const SelectPresentFlowCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_changePresentFlow,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoIdRef,
}
export const ChangePreviewFrameIdCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_changePreviewFrameId,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const CheckCanvasRightMenuOpenable  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_checkCanvasRightMenuOpenable,
    returnType: Wukong.DocumentProto.BridgeProtoBoolean,
    argumentType: Void,
}
export const CheckContextLostCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_checkContextLost,
    returnType: Void,
    argumentType: Void,
}
export const CheckShowRestoreComponentWarningCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_checkShowRestoreComponentWarning,
    returnType: Wukong.DocumentProto.BridgeProtoBoolean,
    argumentType: Void,
}
export const CheckTextStyleNodeMissFontInfoCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_checkTextStyleNodeMissFontInfo,
    returnType: Wukong.DocumentProto.VMissFontInfo,
    argumentType: Wukong.DocumentProto.BridgeProtoIdRef,
}
export const CleanAiCopilotPreviewNode  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cleanAiCopilotPreviewNode,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const ClearAutoLayoutFloatInputForJs  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_clearAutoLayoutFloatInput,
    returnType: Void,
    argumentType: Void,
}
export const ClearHoverPopupHyperlink  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_clearHoverPopupHyperlink,
    returnType: Void,
    argumentType: Void,
}
export const ClickCommentClusterCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_clickCommentCluster,
    returnType: Void,
    argumentType: Wukong.DocumentProto.ClickCommentClusterParam,
}
export const ClickOnCommentCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_clickedOnComment,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const ClickOnDraftCommentCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_clickedOnDraftComment,
    returnType: Void,
    argumentType: Void,
}
export const AIDesignLintDeDuplicateNodeIdsCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdAIDesignLintDeDuplicateNodeIds,
    returnType: Wukong.DocumentProto.Ret_AIDesignLintDeDuplicateNodeIds,
    argumentType: Wukong.DocumentProto.Arg_AIDesignLintDeDuplicateNodeIds,
}
export const AIDesignLintPaintStyleCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdAIDesignLintPaintStyle,
    returnType: Wukong.DocumentProto.Ret_AIDesignLintPaintStyleCommand,
    argumentType: Wukong.DocumentProto.Arg_AIDesignLintPaintStyleCommand,
}
export const AIDesignLintTextStyleCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdAIDesignLintTextStyle,
    returnType: Wukong.DocumentProto.Ret_AIDesignLintTextStyleCommand,
    argumentType: Wukong.DocumentProto.Arg_AIDesignLintTextStyleCommand,
}
export const AIDesignLintUpdateAndZoomToSelectionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdAIDesignLintUpdateAndZoomToSelection,
    returnType: Void,
    argumentType: Wukong.DocumentProto.AIDesignLintUpdateAndZoomToSelectionCommandParam,
}
export const AIDesignLintUpdatePaintStyleIdCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdAIDesignLintUpdatePaintStyleId,
    returnType: Void,
    argumentType: Wukong.DocumentProto.AIDesignLintUpdatePaintStyleIdParam,
}
export const AIDesignLintUpdateTextStyleIdCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdAIDesignLintUpdateTextStyleId,
    returnType: Void,
    argumentType: Wukong.DocumentProto.AIDesignLintUpdateTextStyleIdParam,
}
export const AIReplacePreviewGenerateDataCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdAIReplacePreviewGenerateData,
    returnType: Wukong.DocumentProto.AIReplacePreviewGenerateDataCommandRet,
    argumentType: Wukong.DocumentProto.AIReplacePreviewGenerateDataCommandParam,
}
export const AIReplacePreviewSnapshotCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdAIReplacePreviewSnapshotCommand,
    returnType: Wukong.DocumentProto.AIReplacePreviewSnapshotCommandRet,
    argumentType: Wukong.DocumentProto.AIReplacePreviewSnapshotCommandParam,
}
export const AddExportSettingsToSelectionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdAddExportSettingsToSelection,
    returnType: Void,
    argumentType: Void,
}
export const AddImageSourceCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdAddImageSource,
    returnType: Void,
    argumentType: Wukong.DocumentProto.ImageInfo,
}
export const AddLayoutGridForSelectionCommandForWasm  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdAddLayoutGridForSelection,
    returnType: Void,
    argumentType: Void,
}
export const AddPrototypeStartingPointCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdAddPrototypeStartingPoint,
    returnType: Void,
    argumentType: Void,
}
export const AddStrokePaintsForSelectionCommandForWasm  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdAddStrokePaintsForSelection,
    returnType: Void,
    argumentType: Void,
}
export const AddStrokeStyleIdForSelectionCommandForWasm  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdAddStrokeStyleIdForSelection,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const AeExportCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdAeExport,
    returnType: Wukong.DocumentProto.ExportedAeData,
    argumentType: Wukong.DocumentProto.Args_AeExportCommand,
}
export const AeRasterizationCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdAeRasterization,
    returnType: Void,
    argumentType: Void,
}
export const AiAlignCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdAiAlign,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_AiLayout,
}
export const AiCreateAutoLayoutCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdAiCreateAutoLayout,
    returnType: Void,
    argumentType: Void,
}
export const AiLibraryMaintainCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdAiLibraryMaintain,
    returnType: Void,
    argumentType: Wukong.DocumentProto.AILibraryMaintainCommandParam,
}
export const AimLibraryNodeWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdAimLibraryNode,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_AimLibraryNode,
}
export const AppendInuseOtherDocStyleNodes2StyleContainerCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdAppendInuseOtherDocStyleNodes2StyleContainer,
    returnType: Void,
    argumentType: Void,
}
export const ApplyAIComponentReplaceCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdApplyAIComponentReplace,
    returnType: Void,
    argumentType: Wukong.DocumentProto.ApplyAIComponentReplaceCommandParam,
}
export const ApplyAIComponentRecognizeResultCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdApplyAIRecognizeReplaceableResult,
    returnType: Wukong.DocumentProto.Ret_ApplyAIRecognizeResultCommand,
    argumentType: Wukong.DocumentProto.ApplyAIRecognizeResultCommandParam,
}
export const ApplyAIRecognizeResultCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdApplyAIRecognizeResult,
    returnType: Void,
    argumentType: Wukong.DocumentProto.ApplyAIRecognizeResultCommandParam,
}
export const ApplyAiDuplicateCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdApplyAiDuplicate,
    returnType: Void,
    argumentType: Wukong.DocumentProto.ApplyAiDuplicateResultParam,
}
export const ApplyArcTextToSelectionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdApplyArcTextToSelection,
    returnType: Void,
    argumentType: Wukong.DocumentProto.PluginArcTextState,
}
export const ApplyComponentPropRefForSelectedCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdApplyComponentPropRef,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_cmdPassedComponentPropData,
}
export const ApplyCreateStyle  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdApplyCreateStyle,
    returnType: Void,
    argumentType: Wukong.DocumentProto.CreateStyleParams,
}
export const ApplyPickedColorCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdApplyPickedColor,
    returnType: Void,
    argumentType: Void,
}
export const ArrangeAlignCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdArrangeAlign,
    returnType: Void,
    argumentType: Wukong.DocumentProto.AlignTypeCommandParam,
}
export const ArrangeDistributeCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdArrangeDistribute,
    returnType: Void,
    argumentType: Wukong.DocumentProto.DistributeTypeCommandParam,
}
export const ArrangePackCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdArrangePack,
    returnType: Void,
    argumentType: Wukong.DocumentProto.PackTypeCommandParam,
}
export const RoundToPixelCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdArrangeRoundToPixel,
    returnType: Void,
    argumentType: Void,
}
export const AttachCornerRadiusFloatVarCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdAttachCornerRadiusFloatVar,
    returnType: Void,
    argumentType: Wukong.DocumentProto.AttachCornerRadiusFloatVarParam,
}
export const AttachStrokeWeightFloatVarCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdAttachStrokeWeightFloatVar,
    returnType: Void,
    argumentType: Wukong.DocumentProto.AttachStrokeWeightFloatVarCommandParams,
}
export const AutoLayoutMoveChildren  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdAutoLayoutMoveChildren,
    returnType: Void,
    argumentType: Wukong.DocumentProto.AutoLayoutMoveChildrenParam,
}
export const BatchUpdateArcStartingAngleCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdBatchUpdateArcStartingAngle,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BatchUpdateFloatValues,
}
export const BatchUpdateArcSweepCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdBatchUpdateArcSweep,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BatchUpdateFloatValues,
}
export const BatchUpdateMinSizeAndMaxSizeWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdBatchUpdateAutoLayoutMinSizeAndMaxSize,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BatchUpdateMinSizeAndMaxSizeParam,
}
export const BatchUpdateBaseAttributeCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdBatchUpdateBaseAttribute,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BatchUpdateBaseAttributeCommandParam,
}
export const BatchUpdateBlendModeCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdBatchUpdateBlendMode,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BatchUpdateBlendModeCommandParam,
}
export const BatchUpdateInnerRadiusCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdBatchUpdateInnerRadius,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BatchUpdateFloatValues,
}
export const BatchUpdateNodesKeyCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdBatchUpdateNodeKeys,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BatchUpdateNodesKeyParam,
}
export const BatchUpdateOpacityCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdBatchUpdateOpacity,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BatchUpdateOpacityCommandParam,
}
export const BatchUpdatePointCountCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdBatchUpdatePointCount,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BatchUpdatePointCountCommandParam,
}
export const BatchUpdateVisibleCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdBatchUpdateVisible,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BatchUpdateVisibleCommandParam,
}
export const BatchUpgradePublishMixinsVersionV3Command  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdBatchUpgradePublishMixinsVersionV3,
    returnType: Void,
    argumentType: Wukong.DocumentProto.SerializedBatchUpgradePublishMixinsVersionV3Param,
}
export const BuildAIComponentDataCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdBuildAIComponentData,
    returnType: Wukong.DocumentProto.BuildAIComponentDataCommandRet,
    argumentType: Wukong.DocumentProto.BuildAIComponentDataCommandParam,
}
export const BuildAIReplaceNodesDataCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdBuildAIReplaceNodesData,
    returnType: Wukong.DocumentProto.BuildAIReplaceNodesDataCommandRet,
    argumentType: Wukong.DocumentProto.BuildAIReplaceNodesDataCommandParam,
}
export const BuildAISelectedStyleNodeDataCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdBuildAISelectedStyleNodeData,
    returnType: Wukong.DocumentProto.BuildAISelectedStyleNodeDataCommandRet,
    argumentType: Wukong.DocumentProto.BuildAISelectedStyleNodeDataCommandParam,
}
export const CancelCreateStyle  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdCancelCreateStyle,
    returnType: Void,
    argumentType: Wukong.DocumentProto.CreateStyleParams,
}
export const CancelPlacingImageCommandWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdCancelPlacingImage,
    returnType: Void,
    argumentType: Void,
}
export const AICandidateComponentCancelReplaceCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdCandidateComponentCancelReplace,
    returnType: Void,
    argumentType: Wukong.DocumentProto.AICandidateComponentReplaceCommandParam,
}
export const AICandidateComponentConvertCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdCandidateComponentConvert,
    returnType: Void,
    argumentType: Void,
}
export const AICandidateComponentPublishCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdCandidateComponentPublish,
    returnType: Void,
    argumentType: Void,
}
export const AICandidateComponentReplaceCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdCandidateComponentReplace,
    returnType: Void,
    argumentType: Wukong.DocumentProto.AICandidateComponentReplaceCommandParam,
}
export const CanvasClipboardCopyCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdCanvasClipboardCopy,
    returnType: Void,
    argumentType: Void,
}
export const CanvasClipboardCopyPropertiesCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdCanvasClipboardCopyProperties,
    returnType: Void,
    argumentType: Void,
}
export const CanvasClipboardCopySelectionAsLinkCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdCanvasClipboardCopySelectionAsLink,
    returnType: Void,
    argumentType: Void,
}
export const CanvasClipboardCopySelectionAsPNGCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdCanvasClipboardCopySelectionAsPNG,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_CanvasClipboardCopySelectionAsPNG,
}
export const CanvasClipboardCopySelectionAsSVGCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdCanvasClipboardCopySelectionAsSVG,
    returnType: Void,
    argumentType: Void,
}
export const CanvasClipboardCopySelectionAsTextCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdCanvasClipboardCopySelectionAsText,
    returnType: Void,
    argumentType: Void,
}
export const CanvasClipboardCutCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdCanvasClipboardCut,
    returnType: Void,
    argumentType: Void,
}
export const CanvasClipboardPasteCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdCanvasClipboardPaste,
    returnType: Void,
    argumentType: Wukong.DocumentProto.CanvasClipboardPasteCommandParam,
}
export const CanvasClipboardPasteHereCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdCanvasClipboardPasteHere,
    returnType: Void,
    argumentType: Void,
}
export const CanvasClipboardPasteOverSelectionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdCanvasClipboardPasteOverSelection,
    returnType: Void,
    argumentType: Void,
}
export const CanvasClipboardPastePropertiesCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdCanvasClipboardPasteProperties,
    returnType: Void,
    argumentType: Void,
}
export const CanvasClipboardPasteToReplaceCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdCanvasClipboardPasteToReplace,
    returnType: Void,
    argumentType: Void,
}
export const CanvasSearchDeleteSelectedCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdCanvasSearchDeleteSelected,
    returnType: Void,
    argumentType: Void,
}
export const CanvasSearchEnterTempMultiSelectionMode  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdCanvasSearchEnterTempMultiSelectionMode,
    returnType: Void,
    argumentType: Void,
}
export const CanvasSearchLayerItemClickCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdCanvasSearchLayerItemClick,
    returnType: Void,
    argumentType: Wukong.DocumentProto.CanvasSearchLayerItem,
}
export const CanvasSearchLayerItemCommandClickCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdCanvasSearchLayerItemCommandClick,
    returnType: Void,
    argumentType: Wukong.DocumentProto.CanvasSearchLayerItem,
}
export const CanvasSearchLayerItemEnterHoverCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdCanvasSearchLayerItemEnterHover,
    returnType: Void,
    argumentType: Wukong.DocumentProto.CanvasSearchLayerItem,
}
export const CanvasSearchLayerItemLeaveHoverCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdCanvasSearchLayerItemLeaveHover,
    returnType: Void,
    argumentType: Wukong.DocumentProto.CanvasSearchLayerItem,
}
export const CanvasSearchLayerItemOnEnter  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdCanvasSearchLayerItemOnEnter,
    returnType: Void,
    argumentType: Void,
}
export const CanvasSearchLayerItemOnShiftEnter  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdCanvasSearchLayerItemOnShiftEnter,
    returnType: Void,
    argumentType: Void,
}
export const CanvasSearchLayerItemShiftClickCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdCanvasSearchLayerItemShiftClick,
    returnType: Void,
    argumentType: Wukong.DocumentProto.CanvasSearchLayerItem,
}
export const CanvasSearchLeaveTempMultiSelectionMode  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdCanvasSearchLeaveTempMultiSelectionMode,
    returnType: Void,
    argumentType: Void,
}
export const CanvasSearchNextLayerItem  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdCanvasSearchNextLayerItem,
    returnType: Void,
    argumentType: Void,
}
export const CanvasSearchPageItemClickCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdCanvasSearchPageItemClick,
    returnType: Void,
    argumentType: Wukong.DocumentProto.CanvasSearchLayerItem,
}
export const CanvasSearchPrevLayerItem  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdCanvasSearchPrevLayerItem,
    returnType: Void,
    argumentType: Void,
}
export const CanvasSearchReplaceAllCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdCanvasSearchReplaceAll,
    returnType: Wukong.DocumentProto.CanvasSearchReplaceResult,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const CanvasSearchReplaceSelectedCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdCanvasSearchReplaceSelected,
    returnType: Wukong.DocumentProto.CanvasSearchReplaceResult,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const CanvasSearchResultScrollToLayerItemCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdCanvasSearchResultScrollToLayerItem,
    returnType: Void,
    argumentType: Wukong.DocumentProto.CanvasSearchLayerItem,
}
export const CanvasSearchSelectAllCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdCanvasSearchSelectAll,
    returnType: Void,
    argumentType: Void,
}
export const CanvasSearchTogglePageExpand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdCanvasSearchTogglePageExpand,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const ChangeEditorModeCommandForJs  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdChangeEditorMode,
    returnType: Void,
    argumentType: Wukong.DocumentProto.EditorModeState,
}
export const ChangeLibraryMainModalRoutePathCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdChangeLibraryMainModalRoutePath,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_ChangeLibraryMainModalRoutePath,
}
export const ChangeLibraryPublishModalControlExpandCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdChangeLibraryPublishModalControlExpand,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_ChangeLibraryPublishModalControlExpand,
}
export const ChangeOrderedTextListCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdChangeOrderedTextListCommand,
    returnType: Void,
    argumentType: Void,
}
export const ChangePlainTextCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdChangePlainTextCommand,
    returnType: Void,
    argumentType: Void,
}
export const ChangeScaleToolPanelCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdChangeScaleToolPanel,
    returnType: Void,
    argumentType: Wukong.DocumentProto.ChangeScaleToolPanelCommandParam,
}
export const ChangeSmartSelectionHorizontalCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdChangeSmartSelectionHorizontal,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoFloat,
}
export const ChangeSmartSelectionVerticalCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdChangeSmartSelectionVertical,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoFloat,
}
export const ChangeTextSearchCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdChangeTextSearch,
    returnType: Void,
    argumentType: Wukong.DocumentProto.TextSearch,
}
export const ChangeUnorderedTextListCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdChangeUnorderedTextListCommand,
    returnType: Void,
    argumentType: Void,
}
export const CleanAIRecognizeDataCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdCleanAIRecognizeData,
    returnType: Void,
    argumentType: Void,
}
export const ClearHoverProtoTypeInteractionWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdClearHoverProtoTypeInteraction,
    returnType: Void,
    argumentType: Void,
}
export const ClearPrototypeStartingPointEditingStateCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdClearPrototypeStartingPointEditingState,
    returnType: Void,
    argumentType: Void,
}
export const ClearSelectedReversedIndexesCommandForJs  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdClearSelectedReversedIndexes,
    returnType: Void,
    argumentType: Void,
}
export const CloseBackToInstanceToastCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdCloseBackToInstanceToast,
    returnType: Void,
    argumentType: Void,
}
export const CloseLibraryPublishModalCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdCloseLibraryPublishModal,
    returnType: Void,
    argumentType: Void,
}
export const CloseLibraryReplaceModalWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdCloseLibraryReplaceModal,
    returnType: Void,
    argumentType: Void,
}
export const CloseMultipleRenameModalCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdCloseMultipleRenameModal,
    returnType: Void,
    argumentType: Void,
}
export const CommitUndo  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdCommitUndo,
    returnType: Void,
    argumentType: Void,
}
export const CommitUndoOrSquash  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdCommitUndoOrSquash,
    returnType: Void,
    argumentType: Void,
}
export const CreateAndApplyComponentPropForSelectedCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdCreateAndApplyComponentProp,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_cmdCreateComponentPropDefineForSelected,
}
export const CreateComponentPropDefineForSelectedCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdCreateComponentPropDefine,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_cmdCreateComponentPropDefineForSelected,
}
export const CreateFrameTemplateCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdCreateFrameTemplateCommand,
    returnType: Void,
    argumentType: Wukong.DocumentProto.FrameTemplateSizeName,
}
export const CreateLayoutGridStyleNode  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdCreateLayotuGridStyleNode,
    returnType: Wukong.DocumentProto.LayoutGridStyleNodeParam,
    argumentType: Wukong.DocumentProto.CreateLayoutGridStyleNodeParam,
}
export const CreatePageCommandWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdCreatePage,
    returnType: Void,
    argumentType: Void,
}
export const ChangeCurrentPageBackgroundCommandWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdCurrentPageBackground,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_cmdCurrentPageBackground,
}
export const CurrentPageSetSelectionCommandWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdCurrentPageSetSelection,
    returnType: Void,
    argumentType: Wukong.DocumentProto.CurrentPageSetSelectionCommandParam,
}
export const DecreaseSelectionFontSizeCommandForWasm  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdDecreaseSelectionFontSize,
    returnType: Void,
    argumentType: Void,
}
export const DecreaseSelectionFontWeightCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdDecreaseSelectionFontWeight,
    returnType: Void,
    argumentType: Void,
}
export const DecreaseSelectionLetterSpacingCommandForWasm  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdDecreaseSelectionLetterSpacing,
    returnType: Void,
    argumentType: Void,
}
export const DecreaseSelectionLineHeightCommandForWasm  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdDecreaseSelectionLineHeight,
    returnType: Void,
    argumentType: Void,
}
export const DeleteComponentPropDefinitionForSelectedCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdDeleteComponentPropDef,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_cmdPassedComponentPropData,
}
export const DeleteExportSettingsToSelectionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdDeleteExportSettingsToSelection,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoInt,
}
export const DeletePageCommandWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdDeletePage,
    returnType: Void,
    argumentType: Wukong.DocumentProto.DeletePageCommandParam,
}
export const DeletePrototypeStartingPointByIdCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdDeletePrototypeStartingPointById,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const DeletePrototypeStartingPointsCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdDeletePrototypeStartingPoints,
    returnType: Void,
    argumentType: Void,
}
export const DeleteSelectionFillStyleIdCommandWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdDeleteSelectionFillStyleId,
    returnType: Void,
    argumentType: Void,
}
export const DeleteStyleNodeCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdDeleteStyleNode,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoIdRef,
}
export const DeleteTextStyleNode  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdDeleteTextStyleNode,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoIdRef,
}
export const DetachComponentPropRefCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdDetachComponentPropRef,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_cmdDetachComponentPropDefDatas,
}
export const DetachCornerRadiusFloatVarCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdDetachCornerRadiusFloatVar,
    returnType: Void,
    argumentType: Wukong.DocumentProto.DetachCornerRadiusFloatVarParam,
}
export const DetachSelectionTextNodeHyperlinkCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdDetachHyperlink,
    returnType: Void,
    argumentType: Void,
}
export const DetachLayoutGridStyleForSelectionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdDetachLayotuGridStyleForSelectionForWasm,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateSelectionLayoutGridStyleNodeParam,
}
export const DetachSelectedInstanceRecurCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdDetachSelectedInstanceRecur,
    returnType: Void,
    argumentType: Void,
}
export const DetachSelectionFillStyleIdCommandWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdDetachSelectionFillStyleId,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateSelectionPaintParam,
}
export const DetachSelectionPaintStyleIdCommandForEnterCrop  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdDetachSelectionPaintStyleIdForEnterCrop,
    returnType: Void,
    argumentType: Wukong.DocumentProto.DetachSelectionPaintStyleIdForEnterCropParam,
}
export const DetachStrokeWeightFloatVarCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdDetachStrokeWeightFloatVar,
    returnType: Void,
    argumentType: Wukong.DocumentProto.DetachStrokeWeightFloatVarCommandParams,
}
export const DetachSelectionTextStyleIdCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdDetachTextStyleId,
    returnType: Void,
    argumentType: Wukong.DocumentProto.DetachSelectionTextStyleIdParam,
}
export const DevModeEnterRightClickSelectionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdDevModeEnterRightClickSelection,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoIdRef,
}
export const DevModeLayerPanelCollapseAllChildrenWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdDevModeLayerPanelCollapseAllChildren,
    returnType: Void,
    argumentType: Void,
}
export const DevModeLayerPanelCollapseAllNodesWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdDevModeLayerPanelCollapseAllNodes,
    returnType: Void,
    argumentType: Void,
}
export const DevModeLayerPanelCommandClickWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdDevModeLayerPanelCommandClick,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoLayoutMixin,
}
export const DevModeLayerPanelExpandAllChildrenWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdDevModeLayerPanelExpandAllChildren,
    returnType: Void,
    argumentType: Void,
}
export const DevModeLayerPanelLeftClickWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdDevModeLayerPanelLeftClick,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoLayoutMixin,
}
export const DevModeLayerPanelMoveToSelectedNode  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdDevModeLayerPanelMoveToSelectedNode,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoLayoutMixin,
}
export const DevModeLayerPanelRightClickWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdDevModeLayerPanelRightClick,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoLayoutMixin,
}
export const DevModeLayerPanelShiftClickWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdDevModeLayerPanelShiftClick,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoLayoutMixin,
}
export const DevModeLeaveRightClickSelectionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdDevModeLeaveRightClickSelection,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoIdRef,
}
export const DevModeToggleIconAutoDetection  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdDevModeToggleIconAutoDetection,
    returnType: Void,
    argumentType: Void,
}
export const DevModeZoomToFitSelectionWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdDevModeZoomToFitSelection,
    returnType: Void,
    argumentType: Void,
}
export const DismissComponentMoveNotificationCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdDismissComponentMoveNotification,
    returnType: Void,
    argumentType: Void,
}
export const DismissLibraryUpgradeNotificationCommandWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdDismissLibraryUpgradeNotification,
    returnType: Void,
    argumentType: Void,
}
export const DragLocalSelectionStyleCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdDragLocalSelectionStyleCommand,
    returnType: Void,
    argumentType: Wukong.DocumentProto.DragLocalSelectionStyleCommandParam,
}
export const LayerPanelDragSelectionToNodeIndentCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdDragSelectionToNodeIndent,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_cmdDragSelectionToNodeIndent,
}
export const DropComponentV2Command  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdDropComponentV2,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_cmdDropComponentV2,
}
export const DropImageCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdDropImage,
    returnType: Void,
    argumentType: Wukong.DocumentProto.DropImageParam,
}
export const DropImageToCenterCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdDropImageToCenter,
    returnType: Void,
    argumentType: Wukong.DocumentProto.DropImageToCenterParam,
}
export const DuplicatePageCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdDuplicatePage,
    returnType: Void,
    argumentType: Wukong.DocumentProto.DuplicatePageCommandParam,
}
export const DuplicateSelection  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdDuplicateSelection,
    returnType: Void,
    argumentType: Void,
}
export const EditComponentPropWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdEditComponentProp,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_cmdEditComponentPropData,
}
export const EditImageScaleModeWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdEditImageScaleMode,
    returnType: Void,
    argumentType: Wukong.DocumentProto.EditImageScaleModeCommandParam,
}
export const EnableAIPoweredCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdEnableAiPowered,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_EnableAIPoweredCommand,
}
export const EndEditingGradientWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdEndEditingGradient,
    returnType: Void,
    argumentType: Void,
}
export const EndEditingImageWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdEndEditingImage,
    returnType: Void,
    argumentType: Void,
}
export const EndLibraryPublishModalPublishCheckedWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdEndLibraryPublishModalPublishChecked,
    returnType: Void,
    argumentType: Void,
}
export const EnterAILayoutCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdEnterAiLayout,
    returnType: Void,
    argumentType: Void,
}
export const ToggleEditingVectorStateCommandForJs  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdEnterToggleEditingState,
    returnType: Void,
    argumentType: Void,
}
export const ExitAILayoutCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdExitAiLayout,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_ExitAIPoweredCommand,
}
export const ExitCanvasSearchCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdExitCanvasSearchParam,
    returnType: Void,
    argumentType: Void,
}
export const ExpandSelectionIndependentCornerRadiusCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdExpandSelectionIndependentCornerRadius,
    returnType: Void,
    argumentType: Wukong.DocumentProto.ExpandSelectionIndependentCornerRadiusParam,
}
export const ExportCanvasPngToBase64  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdExportCanvasPngToBase64,
    returnType: Wukong.DocumentProto.BridgeProtoString,
    argumentType: Void,
}
export const ExportCanvasPngToDataWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdExportCanvasPngToData,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Args_ExportCanvasPng,
}
export const ExportEachAsPDFPage  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdExportEachAsPDFPage,
    returnType: Wukong.DocumentProto.Ret_exportDataToWebBlob,
    argumentType: Wukong.DocumentProto.Arg_exportPDF,
}
export const ExportImagePixels  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdExportImagePixels,
    returnType: Wukong.DocumentProto.Ret_exportImagePixels,
    argumentType: Wukong.DocumentProto.Arg_exportImagePixels,
}
export const ExportImageToBinaryData  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdExportImageToBinaryData,
    returnType: Wukong.DocumentProto.Ret_exportBinaryData,
    argumentType: Wukong.DocumentProto.Arg_exportImageOfNodes,
}
export const ExportImageToCanvas  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdExportImageToCanvas,
    returnType: Wukong.DocumentProto.Ret_createCanvasToRender,
    argumentType: Wukong.DocumentProto.Arg_exportImage,
}
export const ExportToPDF  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdExportPDF,
    returnType: Wukong.DocumentProto.Ret_exportDataToWebBlob,
    argumentType: Wukong.DocumentProto.Arg_exportPDF,
}
export const ExportPngToData  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdExportPngToData,
    returnType: Wukong.DocumentProto.Ret_exportData,
    argumentType: Wukong.DocumentProto.Arg_exportPng,
}
export const ExportToSvg  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdExportSvg,
    returnType: Wukong.DocumentProto.Ret_exportDataToWebBlob,
    argumentType: Wukong.DocumentProto.Arg_exportSvg,
}
export const FillNodesWithImagesCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdFillNodesWithImages,
    returnType: Void,
    argumentType: Wukong.DocumentProto.FillNodesWithImagesParam,
}
export const FilterPageNodesCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdFilterPageNodes,
    returnType: Wukong.DocumentProto.FilterPageNodesCommandRet,
    argumentType: Wukong.DocumentProto.FilterPageNodesCommandParam,
}
export const FindClosestComponentByVariantCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdFindClosestComponentByVariant,
    returnType: Wukong.DocumentProto.FindClosestComponentByVariantRet,
    argumentType: Wukong.DocumentProto.FindClosestComponentByVariantParam,
}
export const FlattenCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdFlatten,
    returnType: Void,
    argumentType: Void,
}
export const FlipHorizontalCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdFlipHorizontal,
    returnType: Void,
    argumentType: Void,
}
export const FlipVerticalCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdFlipVertical,
    returnType: Void,
    argumentType: Void,
}
export const GetMarkInfoCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdGetAiMarkInfo,
    returnType: Wukong.DocumentProto.Ret_AiMarkInfo,
    argumentType: Void,
}
export const BatchExportConfigsCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdGetBatchExportConfigs,
    returnType: Wukong.DocumentProto.Ret_BatchExportConfigs,
    argumentType: Void,
}
export const GetClipboardStyleNodeCountCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdGetClipboardStyleNodeCount,
    returnType: Wukong.DocumentProto.BridgeProtoInt,
    argumentType: Void,
}
export const GetContainerIdByIndentCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdGetContainerIdByIndent,
    returnType: Wukong.DocumentProto.BridgeProtoIdRef,
    argumentType: Wukong.DocumentProto.Arg_cmdGetContainerIdByIndent,
}
export const GetImageDownloadSuccessRatioCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdGetImageDownloadSuccessRatio,
    returnType: Wukong.DocumentProto.Return_GetImageDownloadSuccessRatioCommand,
    argumentType: Void,
}
export const GetLatestPopupStateCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdGetLatestPopupState,
    returnType: Wukong.DocumentProto.GetLatestPopupState,
    argumentType: Void,
}
export const GetPagesCountCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdGetPagesCount,
    returnType: Wukong.DocumentProto.BridgeProtoInt,
    argumentType: Void,
}
export const GetPaintStyleNodeInfoByNodeIdCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdGetPaintStyleNodeInfoById,
    returnType: Wukong.DocumentProto.VPaintStyleNode,
    argumentType: Wukong.DocumentProto.BridgeProtoIdRef,
}
export const GetSearchedSortedListCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdGetSearchedSortedList,
    returnType: Wukong.DocumentProto.Ret_GetSearchedSortedList,
    argumentType: Wukong.DocumentProto.Arg_GetSearchedSortedList,
}
export const GetSelectionNodeIdsCommandForWasm  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdGetSelectionNodeIds,
    returnType: Wukong.DocumentProto.BridgeProtoStringSet,
    argumentType: Void,
}
export const GetSingleMissLibraryContentCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdGetSingleMissLibraryContent,
    returnType: Wukong.DocumentProto.Ret_GetSingleMissLibraryContent,
    argumentType: Wukong.DocumentProto.Arg_GetSingleMissLibraryContent,
}
export const GetTextStyleNodeInfoByNodeIdCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdGetTextStyleNodeInfoById,
    returnType: Wukong.DocumentProto.VTextStyleNode,
    argumentType: Wukong.DocumentProto.BridgeProtoIdRef,
}
export const GetTopFrameWhichSortByArrange  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdGetTopFrameWhichSortByArrange,
    returnType: Wukong.DocumentProto.Ret_topFrameWhichShouldExport,
    argumentType: Void,
}
export const GoToSelectedInstanceMainComponentCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdGoToSelectedInstanceMainComponent,
    returnType: Void,
    argumentType: Void,
}
export const HandleNeverMovableNodesCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdHandleNeverMovableNodes,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoStringSet,
}
export const IncreaseSelectionFontSizeCommandForWasm  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdIncreaseSelectionFontSize,
    returnType: Void,
    argumentType: Void,
}
export const IncreaseSelectionFontWeightCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdIncreaseSelectionFontWeight,
    returnType: Void,
    argumentType: Void,
}
export const IncreaseSelectionLetterSpacingCommandForWasm  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdIncreaseSelectionLetterSpacing,
    returnType: Void,
    argumentType: Void,
}
export const IncreaseSelectionLineHeightCommandForWasm  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdIncreaseSelectionLineHeight,
    returnType: Void,
    argumentType: Void,
}
export const InspectMissingFontsCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdInspectMissingFont,
    returnType: Void,
    argumentType: Wukong.DocumentProto.FontName,
}
export const LayerPanelCancelEditingWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdLayerPanelCancelEditing,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoIdRef,
}
export const LayerPanelCancelHoverCommandWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdLayerPanelCancelHover,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoIdRef,
}
export const LayerPanelClickBlankCommandWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdLayerPanelClickBlank,
    returnType: Void,
    argumentType: Void,
}
export const LayerPanelCollapseWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdLayerPanelCollapse,
    returnType: Void,
    argumentType: Void,
}
export const LayerPanelCommandClickCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdLayerPanelCommandClick,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoIdRef,
}
export const LayerPanelEnterAreaCommandWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdLayerPanelEnterArea,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoBoolean,
}
export const LayerPanelEnterEditingCommandWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdLayerPanelEnterEditing,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoIdRef,
}
export const LayerPanelEnterHoverCommandWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdLayerPanelEnterHover,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoIdRef,
}
export const LayerPanelExpandClickCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdLayerPanelExpandClick,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoIdRef,
}
export const LayerPanelLeftClickCommandWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdLayerPanelLeftClick,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoIdRef,
}
export const LayerPanelOptionExpandClickCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdLayerPanelOptionExpandClick,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoIdRef,
}
export const LayerPanelRenameCommandWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdLayerPanelRename,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_cmdLayerPanelRename,
}
export const LayerPanelRightClickCommandWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdLayerPanelRightClick,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoIdRef,
}
export const LayerPanelShiftClickCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdLayerPanelShiftClick,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoIdRef,
}
export const LayerPanelShiftTabRenameCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdLayerPanelShiftTabRename,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_cmdLayerPanelRename,
}
export const LayerPanelTabRenameCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdLayerPanelTabRename,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_cmdLayerPanelRename,
}
export const LayerPanelUpdateLockCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdLayerPanelUpdateLock,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_cmdLayerPanelUpdateLock,
}
export const LayerPanelUpdateVisibleCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdLayerPanelUpdateVisible,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_cmdLayerPanelUpdateVisible,
}
export const LibraryPublishModalPublishCheckedCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdLibraryPublishModalPublishChecked,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_cmdLibraryPublishModalPublishChecked,
}
export const LocateNodeInDocumentForDebugOnly  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdLocateNodeInDocumentForDebugOnly,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const MoveNodesToOtherPage  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdMoveNodesToOtherPage,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoIdRef,
}
export const MoveSelectionForwardOrBackwardWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdMoveSelectionForwardOrBackward,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoBoolean,
}
export const MoveViewportCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdMoveViewport,
    returnType: Void,
    argumentType: Wukong.DocumentProto.MoveViewportCommandParam,
}
export const MoveViewportWhenOutsideCanvasCommandForJs  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdMoveViewportWhenOutsideCanvas,
    returnType: Void,
    argumentType: Wukong.DocumentProto.MoveViewportWhenOutsideCanvasCommandParam,
}
export const MultipleRenameCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdMultipleRename,
    returnType: Void,
    argumentType: Void,
}
export const OnClickBackToInstanceToastCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdOnClickBackToInstanceToast,
    returnType: Void,
    argumentType: Void,
}
export const OpenLibraryDetailPageCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdOpenLibraryDetailPage,
    returnType: Void,
    argumentType: Wukong.DocumentProto.LibraryIdInfo,
}
export const OpenLibraryPublishModalWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdOpenLibraryPublishModal,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_OpenLibraryPublishModal,
}
export const OpenLibraryReplaceModalWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdOpenLibraryReplaceModal,
    returnType: Void,
    argumentType: Wukong.DocumentProto.LibraryIdInfo,
}
export const OutlineStrokeWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdOutlineStroke,
    returnType: Void,
    argumentType: Void,
}
export const PageCancelEditing  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdPageCancelEditing,
    returnType: Void,
    argumentType: Wukong.DocumentProto.PageCancelEditingCommandParam,
}
export const PageDownCommandWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdPageDown,
    returnType: Void,
    argumentType: Void,
}
export const PageEnterEditing  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdPageEnterEditing,
    returnType: Void,
    argumentType: Wukong.DocumentProto.PageEnterEditingCommandParam,
}
export const PageUpCommandWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdPageUp,
    returnType: Void,
    argumentType: Void,
}
export const PasteProtoAsNode  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdPasteProtoAsNode,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_PasteProtoAsNode,
}
export const PlaceAllImagesCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdPlaceAllImages,
    returnType: Void,
    argumentType: Void,
}
export const PlaceImageCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdPlaceImage,
    returnType: Void,
    argumentType: Wukong.DocumentProto.PlaceImageParam,
}
export const PreExportCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdPreExport,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Param_SelectedNodes,
}
export const PrepareExportImageCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdPrepareExportImage,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_PrepareExportImage,
}
export const PrintCurrentTreeRenderObject  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdPrintCurrentTreeRenderObject,
    returnType: Void,
    argumentType: Void,
}
export const PrototypeInteractionTargetCancelHover  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdPrototypeInteractionTargetCancelHover,
    returnType: Void,
    argumentType: Void,
}
export const PrototypeInteractionTargetEnterHover  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdPrototypeInteractionTargetEnterHover,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_PrototypeInteractionTargetEnterHover,
}
export const UpdatePublishHiddenCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdPublishHidden,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdatePublishHiddenCommandParam,
}
export const PublishMovedComponentNodesCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdPublishMovedComponentNodes,
    returnType: Void,
    argumentType: Void,
}
export const PublishSelectedNodesCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdPublishSelectedNodes,
    returnType: Void,
    argumentType: Void,
}
export const PushSelectedInstanceChangesToMainComponentCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdPushSelectedInstanceChangesToMainComponent,
    returnType: Void,
    argumentType: Void,
}
export const ReGenerateSnapshotCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdReGenerateSnapshotForSandBox,
    returnType: Wukong.DocumentProto.AIReplacePreviewSnapshotCommandRet,
    argumentType: Wukong.DocumentProto.ReGenerateSnapshotCommandParam,
}
export const RecoverComponentCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdRecoverComponent,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_RecoverComponentCommand,
}
export const RegenerateContentHashCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdRegenerateContentHash,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_IdRef,
}
export const RemoveFillForSelectedNodesWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdRemoveFillForSelectedNodes,
    returnType: Void,
    argumentType: Void,
}
export const RemoveLayoutGridStyleForSelectionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdRemoveLayotuGridStyleForSelectionForWasm,
    returnType: Void,
    argumentType: Void,
}
export const RemoveSelectedNodesCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdRemoveSelectedNodes,
    returnType: Void,
    argumentType: Void,
}
export const RemoveStrokeForSelectedNodesWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdRemoveStrokeForSelectedNodes,
    returnType: Void,
    argumentType: Void,
}
export const RenameWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdRename,
    returnType: Void,
    argumentType: Void,
}
export const RenderCursorCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdRenderCursor,
    returnType: Wukong.DocumentProto.Arg_ApplyCursor,
    argumentType: Wukong.DocumentProto.Cursor,
}
export const TextReplaceFontsCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdReplaceCurrentPageMissingFonts,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_replaceMissingFonts,
}
export const ReplaceLibraryItemCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdReplaceLibrary,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_ReplaceLibrary,
}
export const ResetComponentPropOnInstanceCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdResetComponentProp,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_cmdResetSpecificComponentPropOnInstance,
}
export const ResetLibraryAssetPanelExpandKeysCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdResetLibraryAssetPanelExpandKeys,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoStringArray,
}
export const ResetSelectedInstancesByTypeCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdResetSelectedInstancesByType,
    returnType: Void,
    argumentType: Wukong.DocumentProto.InstanceResetPropType,
}
export const ResetSelectionNodeInInstanceCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdResetSelectionNodeInInstance,
    returnType: Void,
    argumentType: Void,
}
export const ResizeViewportCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdResizeViewport,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_cmdResizeViewport,
}
export const RewritePublishPropertiesCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdRewritePublishProperties,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_RewritePublishProperties,
}
export const RotateCopyEndPreviewCommandForJs  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdRotateCopyEndPreview,
    returnType: Void,
    argumentType: Wukong.DocumentProto.RotateCopyEndPrevewParam,
}
export const SaveExitAILayoutCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdSaveExitAiLayout,
    returnType: Void,
    argumentType: Void,
}
export const ScaleViewportByWheelWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdScaleViewportByWheel,
    returnType: Void,
    argumentType: Wukong.DocumentProto.ScaleViewportByWheelCommandParam,
}
export const ScrollSelectionIntoView  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdScrollSelectionIntoView,
    returnType: Void,
    argumentType: Void,
}
export const SearchReplaceInstanceWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdSearchReplaceInstance,
    returnType: Wukong.DocumentProto.Ret_SearchReplaceInstance,
    argumentType: Wukong.DocumentProto.SearchReplaceInstanceParam,
}
export const SelectDisplayPanelWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdSelectDisplayPanel,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Args_SelectDisplayPanelCommand,
}
export const SelectInverseCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdSelectInverse,
    returnType: Void,
    argumentType: Void,
}
export const SelectSidebarPanelCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdSelectSidebarPanel,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Args_SelectSidebarPanelCommand,
}
export const SelectionAllNodesCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdSelectionAllNodes,
    returnType: Void,
    argumentType: Void,
}
export const SelectionColorsDetachStyleWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdSelectionColorsDetachStyle,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const SelectionColorsDetachVariableWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdSelectionColorsDetachVariable,
    returnType: Void,
    argumentType: Wukong.DocumentProto.SelectionColorsDetachVariableParam,
}
export const SelectionColorsInspectColorWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdSelectionColorsInspectColor,
    returnType: Void,
    argumentType: Wukong.DocumentProto.SelectionColorsInspectColorParam,
}
export const SelectionColorsInspectStyleWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdSelectionColorsInspectStyle,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const SelectionColorsReplaceColorWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdSelectionColorsReplaceColor,
    returnType: Void,
    argumentType: Wukong.DocumentProto.SelectionColorsReplaceParam,
}
export const SelectionColorsReplaceColorWithStyleWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdSelectionColorsReplaceColorWithStyle,
    returnType: Void,
    argumentType: Wukong.DocumentProto.SelectionColorsReplaceColorWithStyleParam,
}
export const SelectionColorsReplaceStyleWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdSelectionColorsReplaceStyle,
    returnType: Void,
    argumentType: Wukong.DocumentProto.SelectionColorsReplaceStyleParam,
}
export const SelectionColorsReplaceStyleWithColorWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdSelectionColorsReplaceStyleWithColor,
    returnType: Void,
    argumentType: Wukong.DocumentProto.SelectionColorsReplaceStyleWithColorParam,
}
export const SetComponentThumbnailDraggingCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdSetComponentThumbnailDragging,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoBoolean,
}
export const SetCurrentPageWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdSetCurrentPage,
    returnType: Void,
    argumentType: Wukong.DocumentProto.SetCurrentPageCommandParam,
}
export const SetCurrentPageInHistoryWasmCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdSetCurrentPageInHistoryWasm,
    returnType: Void,
    argumentType: Wukong.DocumentProto.SetCurrentPageCommandParam,
}
export const SetCurrentUserActiveStateWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdSetCurrentUserActiveState,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoBoolean,
}
export const SetDevModeDisplayPanelsWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdSetDevModeDisplayPanels,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Args_SetDisplayPanelsCommand,
}
export const SetDisplayPanelsWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdSetDisplayPanels,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Args_SetDisplayPanelsCommand,
}
export const SetHoverProtoTypeInteractionWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdSetHoverProtoTypeInteraction,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_SetHoverProtoTypeInteraction,
}
export const SetMultipleRenameParamsWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdSetMultipleRenameParams,
    returnType: Void,
    argumentType: Wukong.DocumentProto.MultipleRenameParams,
}
export const SetOperateSystemWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdSetOperateSystem,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Args_SetOperateSystemCommand,
}
export const SetPrototypeAnimationEasingFunctionEditHistory  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdSetPrototypeAnimationEasingFunctionEditHistory,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_EasingFunction,
}
export const SetPrototypeAnimationEasingTypeEditHistory  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdSetPrototypeAnimationEasingTypeEditHistory,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_EasingType,
}
export const SetPrototypeAnimationTransitionDurationEditHistory  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdSetPrototypeAnimationTransitionDurationEditHistory,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoFloat,
}
export const SetPrototypeAnimationTransitionShouldSmartAnimateEditHistory  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdSetPrototypeAnimationTransitionShouldSmartAnimateEditHistory,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoBoolean,
}
export const SetPrototypeAnimationTransitionTypeEditHistory  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdSetPrototypeAnimationTransitionTypeEditHistory,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_TransitionType,
}
export const SetPrototypeTransitionResetScrollPositionEditHistory  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdSetPrototypeTransitionResetScrollPositionEditHistory,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoBoolean,
}
export const SetSelectedReveredIndexesWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdSetSelectedReveredIndexes,
    returnType: Void,
    argumentType: Wukong.DocumentProto.SelectedReversedIndexesParam,
}
export const SetStrokeCapForSelectionWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdSetStrokeCapForSelection,
    returnType: Void,
    argumentType: Wukong.DocumentProto.SetStrokeCapForSelectionCommandParams,
}
export const SetThumbnailIdWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdSetThumbnailId,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const SetViewportCommandForJs  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdSetViewport,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Viewport,
}
export const ShouldMoveViewportWhenOutsideCanvasCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdShouldMoveViewportWhenOutsideCanvas,
    returnType: Wukong.DocumentProto.BridgeProtoBoolean,
    argumentType: Void,
}
export const ShowStartEditingHandlerForPaintStyleCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdShowStartEditingHandlerForPaintStyle,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_cmdShowStartEditingHandlerForPaintStyle,
}
export const StartCanvasSearchAndReplaceCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdStartCanvasSearchAndReplace,
    returnType: Void,
    argumentType: Void,
}
export const StartCanvasSearchCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdStartCanvasSearchParam,
    returnType: Void,
    argumentType: Void,
}
export const StartEditingGradientCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdStartEditingGradient,
    returnType: Void,
    argumentType: Wukong.DocumentProto.StartEditingGradientCommandParam,
}
export const StartEditingImageCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdStartEditingImage,
    returnType: Void,
    argumentType: Wukong.DocumentProto.StartEditingImageCommandParam,
}
export const StartLibraryReplaceWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdStartLibraryReplace,
    returnType: Void,
    argumentType: Void,
}
export const SwapFillStrokeForSelectedNodes  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdSwapFillStrokeForSelectedNodes,
    returnType: Void,
    argumentType: Void,
}
export const SwitchDeliveryUnitByIndex  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdSwitchDeliveryUnitByIndex,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoInt,
}
export const SwitchPrototypePresentDisableDefaultKeyboardNavCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdSwitchPrototypePresentDisableDefaultKeyboardNav,
    returnType: Void,
    argumentType: Void,
}
export const SwitchPrototypePresentHideUICommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdSwitchPrototypePresentHideUI,
    returnType: Void,
    argumentType: Void,
}
export const SwitchPrototypePresentShowProtoSidebar  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdSwitchPrototypePresentShowProtoSidebar,
    returnType: Void,
    argumentType: Void,
}
export const SwitchStrokeEndPointCapCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdSwitchStrokeEndPointCap,
    returnType: Void,
    argumentType: Void,
}
export const SyncDocumentColorProfileMetaCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdSyncDocumentColorProfileMeta,
    returnType: Void,
    argumentType: Wukong.DocumentProto.DocumentColorProfileMeta,
}
export const TextEndEditing  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdTextEndEditing,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_cmdEndTextEditing,
}
export const TextInputCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdTextInput,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_cmdTextInput,
}
export const TextKeyboardOperationCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdTextKeyboardOperation,
    returnType: Void,
    argumentType: Wukong.DocumentProto.KeyboardEvent,
}
export const UpdateSelectionTextNodeAutoResizeCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdTextUpdateAutoResize,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateSelectionTextNodeAutoResizeParam,
}
export const UpdateSelectionTextNodeParagraphIndentCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdTextUpdateParagraphIndent,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateSelectionTextNodeParagraphIndentParam,
}
export const UpdateSelectionTextNodeParagraphSpacingCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdTextUpdateParagraphSpacing,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateSelectionTextNodeParagraphSpacingParam,
}
export const UpdateSelectionTextNodeAlignHorizontalCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdTextUpdateTextAlignHorizontal,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateSelectionTextNodeAlignHorizontalParam,
}
export const UpdateSelectionTextNodeAlignVerticalCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdTextUpdateTextAlignVertical,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateSelectionTextNodeAlignVerticalParam,
}
export const TidyUpCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdTidyUp,
    returnType: Void,
    argumentType: Wukong.DocumentProto.TidyUpCommandParam,
}
export const ToggleAiLayoutEntryMenuCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdToggleAiLayoutEntryMenu,
    returnType: Void,
    argumentType: Wukong.DocumentProto.AiLayoutEntryMenuState,
}
export const ToggleLockedForSelectedNodesCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdToggleLockedForSelectedNodes,
    returnType: Void,
    argumentType: Void,
}
export const ToggleMaskSelectionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdToggleMaskSelection,
    returnType: Void,
    argumentType: Void,
}
export const ToggleMotiffScopeNodeExpandedWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdToggleMotiffScopeNodeExpanded,
    returnType: Void,
    argumentType: Wukong.DocumentProto.ToggleMotiffScopeNodeExpandedCommandParam,
}
export const ToggleRightClickMenuCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdToggleRightClickMenu,
    returnType: Void,
    argumentType: Wukong.DocumentProto.RightClickMenuState,
}
export const ToggleSelectionChildrenCommandForJs  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdToggleSelectionChildren,
    returnType: Void,
    argumentType: Void,
}
export const ToggleSelectionTextNodeDeleteLineCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdToggleSelectionDeleteLine,
    returnType: Void,
    argumentType: Void,
}
export const ToggleSelectionFontBoldCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdToggleSelectionFontBold,
    returnType: Void,
    argumentType: Void,
}
export const ToggleSelectionFontItalicCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdToggleSelectionFontItalic,
    returnType: Void,
    argumentType: Void,
}
export const ToggleSelectionMaskOutline  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdToggleSelectionMaskOutline,
    returnType: Void,
    argumentType: Void,
}
export const ToggleSelectionNodesCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdToggleSelectionNodes,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoBoolean,
}
export const ToggleSelectionParentCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdToggleSelectionParent,
    returnType: Void,
    argumentType: Void,
}
export const ToggleSelectionTextNodeUnderLineCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdToggleSelectionUnderLine,
    returnType: Void,
    argumentType: Void,
}
export const ToggleShowMultiplayerCursorWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdToggleShowMultiplayerCursor,
    returnType: Void,
    argumentType: Void,
}
export const ToggleShownForSelectedNodesWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdToggleShownForSelectedNodes,
    returnType: Void,
    argumentType: Void,
}
export const TryExitScaleToolOnInputEnteredCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdTryExitScaleToolOnInputEntered,
    returnType: Void,
    argumentType: Void,
}
export const UndoRedoCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUndoRedo,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UndoRedoCommandParam,
}
export const UnlockAILayoutCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUnlockAiLayout,
    returnType: Void,
    argumentType: Void,
}
export const UpdateExportSettingsByIdsCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateAllExportSettingsByIds,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateAllExportSettingsByIdsCommandParam,
}
export const UpdateAttrPanelEditorStateCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateAttrPanelStyleEditorState,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateAttrPanelStyleEditorStateParam,
}
export const UpdateBatchExportPopupStateCommandWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateBatchExportPopupState,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoBoolean,
}
export const UpdateCanvasSearchParamCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateCanvasSearchParam,
    returnType: Void,
    argumentType: Wukong.DocumentProto.CanvasSearchParam,
}
export const UpdateCanvasSearchResultItemHeight  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateCanvasSearchResultItemHeight,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateCanvasSearchResultItemHeightParam,
}
export const UpdateCanvasSearchResultViewportHeight  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateCanvasSearchResultViewportHeight,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoFloat,
}
export const UpdateCanvasSearchResultViewportScrollTop  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateCanvasSearchResultViewportScrollTop,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoFloat,
}
export const UpdateCenterPointPositionCommandForJs  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateCenterPoint,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoFloat,
}
export const UpdateCoactorObservingCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateCoactorObserving,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_cmdUpdateCoactorObserving,
}
export const UpdateColorModeCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateColorMode,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_UpdateColorMode,
}
export const UpdateComponentPropSelectionStateWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateComponentPropSelectionState,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoBoolean,
}
export const UpdateCursorWithinCanvasCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateCursorWithinCanvas,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoBoolean,
}
export const UpdateSelectionTextNodeDetachOpsizeFromFontSizeCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateDetachOpsizeFromFontSize,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoBoolean,
}
export const UpdateDevModeBatchExportPopupStateCommandWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateDevModeBatchExportPopState,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoBoolean,
}
export const UpdateDevModeCodeConfigCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateDevModeCodeConfig,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateDevModeCodeConfigCommandParam,
}
export const UpdateDevModeExportCompressCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateDevModeExportCompress,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoBoolean,
}
export const UpdateDevModeInspectCodeTypeCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateDevModeInspectCodeType,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateDevModeInspectCodeTypeCommandParam,
}
export const UpdateDevModeInspectTypeCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateDevModeInspectType,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateDevModeInspectTypeCommandParam,
}
export const UpdateDocReadonlyCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateDocReadonly,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoBoolean,
}
export const UpdateExportSettingsToSelectionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateExportSettingsToSelection,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateExportSettingsToSelectionCommandParam,
}
export const UpdateSelectionTextNodeFontFamilyCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateFontFamily,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_setFontFamily,
}
export const UpdateSelectionTextNodeFontNameCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateFontName,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateSelectionTextNodeFontNameParam,
}
export const UpdateSelectionTextNodeFontSizeCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateFontSize,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateSelectionTextNodeFontSizeParam,
}
export const UpdateSelectionTextNodeFontVariationCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateFontVariation,
    returnType: Void,
    argumentType: Wukong.DocumentProto.FontVariation,
}
export const UpdateFrameTemplateCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateFrameTemplateCommand,
    returnType: Void,
    argumentType: Wukong.DocumentProto.FrameTemplateSize,
}
export const UpdateFreezeSelectionPaintCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateFreezeSelectionPaint,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoBoolean,
}
export const UpdateSelectionTextNodeHyperlinkCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateHyperlink,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateSelectionTextNodeHyperlinkParam,
}
export const UpdateInfiniteSelectionPaintWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateInfiniteSelectionPaint,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoBoolean,
}
export const UpdateInspectCodeTypeCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateInspectCodeType,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateInspectCodeTypeCommandParam,
}
export const InspectStateCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateInspectState,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoBoolean,
}
export const UpdateLayerPanelClientHeightCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateLayerPanelClientHeight,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoInt,
}
export const UpdateLayerPanelScrollTopWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateLayerPanelScrollTop,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoInt,
}
export const UpdateLayotuGridStyleForSelectionCommandForWasm  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateLayotuGridStyleForSelectionForWasm,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateSelectionLayoutGridStyleNodeParam,
}
export const UpdateLayoutGridStyleLayoutGridsCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateLayoutGridStyleLayoutGrids,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateLayoutGridStyleLayoutGridsCommandParam,
}
export const UpdateLayoutGridStyleDescriptionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateLayoutGridStyleNodeDescription,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateLayoutGridStyleNodeDescriptionParam,
}
export const UpdateLayoutGridStyleNameCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateLayoutGridStyleNodeName,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateLayoutGridStyleNodeNameParam,
}
export const UpdateLayoutGridsCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateLayoutGridsForSelection,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateLayoutGridsCommandParam,
}
export const UpdateSelectionTextNodeLetterSpacingCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateLetterSpacing,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateSelectionTextNodeLetterSpacingParam,
}
export const UpdateLibraryAssetPanelDisplayModeCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateLibraryAssetPanelDisplayMode,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_UpdateLibraryAssetPanelDisplayMode,
}
export const UpdateLibraryAssetPanelFilterTextCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateLibraryAssetPanelFilterText,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const UpdateLibraryAssetPanelScrollTopCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateLibraryAssetPanelScrollTop,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_UpdateLibraryAssetPanelScrollTop,
}
export const UpdateLibraryAssetPanelScrollableSizeCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateLibraryAssetPanelScrollableSize,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_UpdateLibraryAssetPanelScrollableSize,
}
export const UpdateLibraryContentVOCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateLibraryContentVO,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateLibraryContentVOCommandParam,
}
export const UpdateLibraryPublishModalAllChangesCheckedWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateLibraryPublishModalAllChangesChecked,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_UpdateLibraryPublishModalAllChangesChecked,
}
export const UpdateLibraryPublishModalChangeCheckedCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateLibraryPublishModalChangeChecked,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_UpdateLibraryPublishModalChangeChecked,
}
export const UpdateLibraryPublishModalDescriptionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateLibraryPublishModalDescription,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const UpdateLibraryPublishModalFilterTextCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateLibraryPublishModalFilterText,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const UpdateLibraryPublishModalScrollTopCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateLibraryPublishModalScrollTop,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_UpdateLibraryPublishModalScrollTop,
}
export const UpdateLibraryPublishModalScrollableSizeCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateLibraryPublishModalScrollableSize,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_UpdateLibraryPublishModalScrollableSize,
}
export const UpdateLibraryReplaceModalCheckedWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateLibraryReplaceModalChecked,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_UpdateLibraryReplaceModalChecked,
}
export const UpdateLibraryReplaceModalScrollTopCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateLibraryReplaceModalScrollTop,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_UpdateVirtualScrollTop,
}
export const UpdateLibraryReplaceModalScrollableSizeCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateLibraryReplaceModalScrollableSize,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_UpdateVirtualScrollableSize,
}
export const UpdateLibraryReplaceModalTargetLibraryWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateLibraryReplaceModalTargetLibrary,
    returnType: Void,
    argumentType: Wukong.DocumentProto.LibraryIdInfo,
}
export const UpdateSelectionTextNodeLineHeightCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateLineHeight,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateSelectionTextNodeLineHeightParam,
}
export const UpdateMainComponent  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateMainComponent,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateMainComponentCommandSerializedParam,
}
export const UpdateMainMenuVisibleCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateMainMenuVisible,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoBoolean,
}
export const UpdateSelectionTextNodeMaxLinesCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateMaxLines,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateSelectionTextNodeMaxLinesParam,
}
export const UpdateMotiffScopeSettingsWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateMotiffScopeSettings,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateMotiffScopeSettingsCommandParam,
}
export const UpdateMouseCameraPositionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateMouseCameraPosition,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_cmdUpdateMouseCameraPosition,
}
export const UpdateOthersRemoteLibraryChangesCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateOthersRemoteLibraryChanges,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateOthersRemoteLibraryChangesParam,
}
export const UpdateOverlayBackgroundAppearance  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateOverlayBackgroundAppearance,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_UpdateOverlayBackgroundAppearance,
}
export const UpdateOverlayBackgroundInteraction  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateOverlayBackgroundInteraction,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_UpdateOverlayBackgroundInteraction,
}
export const UpdateOverlayPositionType  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateOverlayPositionType,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_UpdateOverlayPositionType,
}
export const UpdatePageName  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdatePageName,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdatePageNameCommandParam,
}
export const UpdatePopupStateCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdatePopupState,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdatePopupStateParam,
}
export const UpdatePrototypeSelectedInteractionKeys  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdatePrototypeSelectedInteractionKeys,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_UpdatePrototypeSelectedInteractionKeys,
}
export const UpdatePrototypeStartingPointDescriptionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdatePrototypeStartingPointDescription,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const UpdatePrototypeStartingPointNameCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdatePrototypeStartingPointName,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const UpdatePrototypeStartingPointNameByIdCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdatePrototypeStartingPointNameById,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_cmdUpdatePrototypeStartingPointNameById,
}
export const UpdateSelectedGradientColorStopIndexCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateSelectedGradientColorStopIndex,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateSelectedGradientColorStopIndexParam,
}
export const UpdateSelectedNodeDescriptionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateSelectedNodeDescription,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const UpdateSelectedVectorNetworkRegionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateSelectedVectorNetworkRegion,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateSelectedVectorNetworkRegionParam,
}
export const UpdateSelectedVectorNodeStyleOverrideTableCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateSelectedVectorNodeStyleOverrideTable,
    returnType: Void,
    argumentType: Wukong.DocumentProto.StyleOverride,
}
export const UpdateSelectionArcStartingAngleCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateSelectionArcStartingAngle,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoFloat,
}
export const UpdateSelectionArcSweepCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateSelectionArcSweep,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoFloat,
}
export const UpdateSelectionBaseAttributeCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateSelectionBaseAttribut,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateSelectionBaseAttributeParam,
}
export const UpdateSelectionClipsContentCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateSelectionClipsContent,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoBoolean,
}
export const UpdateSelectionConstrainProportionsCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateSelectionConstrainProportions,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoBoolean,
}
export const UpdateSelectionConstraintsCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateSelectionConstraints,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateSelectionConstraintsCommandParam,
}
export const UpdateSelectionCornerRadiusCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateSelectionCornerRadius,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateSelectionCornerRadiusParam,
}
export const UpdateSelectionCornerSmoothingCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateSelectionCornerSmoothing,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateSelectionCornerSmoothingParam,
}
export const UpdateSelectionFillPaintForSameOrAddCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateSelectionFillPaintForSameOrAdd,
    returnType: Void,
    argumentType: Void,
}
export const UpdateSelectionFillPaintsCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateSelectionFillPaints,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateSelectionPaintParam,
}
export const UpdateSelectionFillPaintsForEmptyCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateSelectionFillPaintsForEmpty,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateSelectionPaintParam,
}
export const UpdateSelectionFillStyleIdCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateSelectionFillStyleId,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const UpdateSelectionFrameDirectionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateSelectionFrameDirection,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateSelectionFrameDirectionCommandParam,
}
export const UpdateSelectionIndependentCornerRadiusCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateSelectionIndependentCornerRadius,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateSelectionIndependentCornerRadiusParam,
}
export const UpdateSelectionInnerRadiusCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateSelectionInnerRadius,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateSelectionInnerRadiusCommandParam,
}
export const UpdateSelectionPointCountCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateSelectionPointCount,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoInt,
}
export const UpdateSelectionStrokeAlignCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateSelectionStrokeAlign,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateSelectionStrokeAlignCommandParam,
}
export const UpdateSelectionStrokeDashCapCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateSelectionStrokeDashCap,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateSelectionStrokeDashCapCommandParam,
}
export const UpdateSelectionStrokeDashGapCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateSelectionStrokeDashGap,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateSelectionStrokeDashGapCommandParam,
}
export const UpdateSelectionStrokeDashLenCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateSelectionStrokeDashLen,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateSelectionStrokeDashLenCommandParam,
}
export const UpdateSelectionStrokeJoinCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateSelectionStrokeJoin,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateSelectionStrokeJoinCommandParam,
}
export const UpdateSelectionStrokeMiterAngleCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateSelectionStrokeMiterAngle,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateSelectionStrokeMiterAngleCommandParam,
}
export const UpdateSelectionStrokeModeCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateSelectionStrokeMode,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateSelectionStrokeModeCommandParam,
}
export const UpdateSelectionStrokePaintsCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateSelectionStrokePaints,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateSelectionStrokePaintsParam,
}
export const UpdateSizeHoverMenuItemWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateSizeHoverMenuItem,
    returnType: Void,
    argumentType: Wukong.DocumentProto.SizeHoverMenuItemParam,
}
export const UpdateSubscribedLibraryIdsCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateSubscribedLibraryIds,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_updateSubscribedLibraryIds,
}
export const UpdateSelectionTextNodeTextCaseCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateTextCase,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateSelectionTextNodeTextCaseParam,
}
export const UpdateSelectionTextNodeTextDecorationCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateTextDecoration,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateSelectionTextNodeTextDecorationParam,
}
export const UpdateSelectionTextStyleIdCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateTextStyleId,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateSelectionTextStyleIdParam,
}
export const UpdateSelectionTextNodeTextTruncationCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateTextTruncation,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateSelectionTextNodeTextTruncationParam,
}
export const UpdateTopExtendBoolMenuVisibleCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateTopExtendBoolMenuVisible,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoBoolean,
}
export const UpdateTriggerFilterWithinMotiffScopeCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateTriggerFilterWithinMotiffScope,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateTriggerFilterWithinMotiffScopeCommandParam,
}
export const UpdateUsableFontInfoCommandForJs  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateUsableFontInfos,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateUsableFontInfoParam,
}
export const UpdateUserPreferrence2  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateUserConfigV2,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UserConfig2,
}
export const UpdateVectorPanelCornerRadiusCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateVectorPanelCornerRadius,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoFloat,
}
export const updateVectorPanelHandleMirrorCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateVectorPanelHandleMirror,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Args_updateVectorPanelHandleMirrorCommand,
}
export const updateVectorPanelXCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateVectorPanelX,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_updateVectorPanelXYCommand,
}
export const updateVectorPanelYCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateVectorPanelY,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_updateVectorPanelXYCommand,
}
export const UpdateWindowInnerHeightWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdUpdateWindowInnerHeight,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoInt,
}
export const ValidateAIReplaceSceneCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdValidateAIReplaceScene,
    returnType: Wukong.DocumentProto.ValidateAIReplaceSceneCommandRet,
    argumentType: Wukong.DocumentProto.ValidateAIReplaceSceneCommandParam,
}
export const ClearEditingVectorWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdVectorClearEditing,
    returnType: Void,
    argumentType: Void,
}
export const ViewLibraryUpgradeNotificationCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdViewLibraryUpgradeNotification,
    returnType: Void,
    argumentType: Void,
}
export const ViewNextFrameCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdViewNextFrame,
    returnType: Void,
    argumentType: Void,
}
export const ViewPrevFrameCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdViewPrevFrame,
    returnType: Void,
    argumentType: Void,
}
export const ZoomAtViewportCenterWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdZoomAtViewportCenter,
    returnType: Void,
    argumentType: Wukong.DocumentProto.ZoomAtViewportCenterCommandParam,
}
export const ZoomInByLevelAtViewportCenterWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdZoomInByLevelAtViewportCenter,
    returnType: Void,
    argumentType: Void,
}
export const ZoomOutByLevelAtViewportCenterWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdZoomOutByLevelAtViewportCenter,
    returnType: Void,
    argumentType: Void,
}
export const ZoomToAllCommandForJs  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdZoomToAll,
    returnType: Void,
    argumentType: Void,
}
export const ZoomToFitNodesAtMaxScaleByIdsCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdZoomToFitNodesAndScreen,
    returnType: Void,
    argumentType: Wukong.DocumentProto.ZoomToFitNodesAtMaxScaleByIdsCommandParam,
}
export const ZoomToNextFrameCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdZoomToNextFrame,
    returnType: Void,
    argumentType: Void,
}
export const ZoomToPrevFrameCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdZoomToPrevFrame,
    returnType: Void,
    argumentType: Void,
}
export const ZoomToSelectionWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdZoomToSelection,
    returnType: Void,
    argumentType: Void,
}
export const SetSelectionMaybeJumpPageCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_cmdsetSelectionMaybeJumpPage,
    returnType: Void,
    argumentType: Wukong.DocumentProto.SetSelectionMaybeJumpPageParam,
}
export const CommitSynergyStore  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_commitSynergyStore,
    returnType: Void,
    argumentType: Void,
}
export const ComponentSelectionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_componentSelection,
    returnType: Void,
    argumentType: Void,
}
export const ComponentSetSelectionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_componentSetSelection,
    returnType: Void,
    argumentType: Void,
}
export const CompoundAdjustLayoutWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_compoundAdjustLayout,
    returnType: Void,
    argumentType: Wukong.DocumentProto.CompoundAdjustLayoutCommandParam,
}
export const ConfirmTitleInputCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_confirmTitleInput,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Args_ConfirmTitleInputCommand,
}
export const ConvertCompSetIdToPrimaryCompId  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_convertCompSetIdToPrimaryCompId,
    returnType: Wukong.DocumentProto.BridgeProtoIdRef,
    argumentType: Wukong.DocumentProto.BridgeProtoIdRef,
}
export const ConvertFrameToGroupCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_convertFrameToGroup,
    returnType: Void,
    argumentType: Void,
}
export const ConvertGroupToFrameWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_convertGroupToFrame,
    returnType: Void,
    argumentType: Void,
}
export const CopyCommentLinkCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_copyCommentLink,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const CreateAIGenBaseFrameCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_createAIGenBaseFrame,
    returnType: Wukong.DocumentProto.CreateAIGenBaseFrameReturn,
    argumentType: Wukong.DocumentProto.CreateAIGenBaseFrameParam,
}
export const CreateColorVariableCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_createColorVariable,
    returnType: Wukong.DocumentProto.BridgeProtoString,
    argumentType: Wukong.DocumentProto.Arg_CreateColorVariable,
}
export const CreateEffectStyleNodeCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_createEffectStyleNode,
    returnType: Wukong.DocumentProto.CreateEffectStyleNodeResult,
    argumentType: Wukong.DocumentProto.CreateEffectStyleNodeParam,
}
export const CreateEffectStylePreviewCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_createEffectStylePreview,
    returnType: Wukong.DocumentProto.Ret_createImage,
    argumentType: Wukong.DocumentProto.Arg_createEffectStylePreview,
}
export const CreateFloatVariableCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_createFloatVariable,
    returnType: Wukong.DocumentProto.BridgeProtoString,
    argumentType: Wukong.DocumentProto.Arg_CreateFloatVariable,
}
export const CreateGradientThumbnailCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_createGradientThumbnail,
    returnType: Wukong.DocumentProto.Ret_createImage,
    argumentType: Wukong.DocumentProto.Arg_createGradientThumbnail,
}
export const CreateImageThumbnailCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_createImageThumbnail,
    returnType: Wukong.DocumentProto.Ret_createImage,
    argumentType: Wukong.DocumentProto.Arg_createImageThumbnail,
}
export const CreateNodeFromSvgCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_createNodeFromSvg,
    returnType: Wukong.DocumentProto.Ret_ApiIdRefWithError,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const CreatePaintStyleNodeCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_createPaintStyleNode,
    returnType: Wukong.DocumentProto.CreatePaintStyleNodeResult,
    argumentType: Wukong.DocumentProto.CreatePaintStyleNodeParam,
}
export const CreatePaintStylePreviewCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_createPaintStylePreview,
    returnType: Wukong.DocumentProto.Ret_createImage,
    argumentType: Wukong.DocumentProto.Arg_createPaintStylePreview,
}
export const CreatePaintStyleThumbnailCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_createPaintStyleThumbnail,
    returnType: Wukong.DocumentProto.Ret_createImage,
    argumentType: Wukong.DocumentProto.Arg_createPaintStyleThumbnail,
}
export const CreateTextStyleNodeCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_createTextStyleNode,
    returnType: Wukong.DocumentProto.CreateTextStyleNodeResult,
    argumentType: Wukong.DocumentProto.CreateTextStyleNodeParam,
}
export const CreateTextStylePreviewCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_createTextStylePreview,
    returnType: Wukong.DocumentProto.Ret_createImage,
    argumentType: Wukong.DocumentProto.Arg_createTextStylePreview,
}
export const CreateTextStyleThumbnailCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_createTextStyleThumbnail,
    returnType: Wukong.DocumentProto.Ret_createImage,
    argumentType: Wukong.DocumentProto.Arg_createTextStyleThumbnail,
}
export const CreateVariableSetCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_createVariableSet,
    returnType: Wukong.DocumentProto.BridgeProtoString,
    argumentType: Wukong.DocumentProto.Arg_CreateVariableSetCommandParam,
}
export const CreateVariantPropWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_createVariantProp,
    returnType: Void,
    argumentType: Void,
}
export const DeactiveCommentCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_deactiveComment,
    returnType: Void,
    argumentType: Void,
}
export const DebouncedMovingBoundsPositionHoldTimerCallback  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_debouncedMovingBoundsPositionHoldTimerCallback,
    returnType: Void,
    argumentType: Void,
}
export const DebouncedShowAutoLayoutHandlerTimerCallback  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_debouncedShowAutoLayoutHandlerTimerCallback,
    returnType: Void,
    argumentType: Void,
}
export const DebouncedShowGuidelineTimerCallback  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_debouncedShowGuidelineTimerCallback,
    returnType: Void,
    argumentType: Void,
}
export const DelayCheckAllImagesLoadedCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_delayCheckAllImagesLoaded,
    returnType: Void,
    argumentType: Void,
}
export const DeleteLocalStyleSelectionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_deleteLocalStyleSelection,
    returnType: Void,
    argumentType: Void,
}
export const DeleteVariableSetModeCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_deleteVariableSetMode_deprecated,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_RemoveVariableSetModeCommandParam,
}
export const DestroyEditorCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_destroyEditor,
    returnType: Void,
    argumentType: Void,
}
export const DetachEffectStyleForSelectionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_detachEffectStyleForSelection,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const DetachSelectedInstanceWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_detachSelectedInstance,
    returnType: Void,
    argumentType: Void,
}
export const DevModeAvailabilityVerificationWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_devModeAvailiablityVerificatoin,
    returnType: Wukong.DocumentProto.BridgeProtoBoolean,
    argumentType: Wukong.DocumentProto.DevModeAuthMessage,
}
export const DeleteAllExportSettingsToSelectionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_devModeDeleteAllExportSettingsToSelection,
    returnType: Void,
    argumentType: Void,
}
export const DevModeExportChangeScaleCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_devModeExportChangeScale,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_DevModeExportChangeScale,
}
export const DevModeExportSelectFileTypeCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_devModeExportSelectFileType,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_DevModeExportSelectFileType,
}
export const DevModeExportSelectPresetCodeTypeCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_devModeExportSelectPresetCodeType,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_DevModeExportSelectPresetCodeType,
}
export const DevModeLayerPanelCancelHoverCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_devModeLayerPanelCancelHover,
    returnType: Void,
    argumentType: Void,
}
export const DevModeLayerPanelEnterHoverCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_devModeLayerPanelEnterHover,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoLayoutMixin,
}
export const DevModeSelectColorsPanelEnterHoverCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_devModeSelectColorsPanelEnterHover,
    returnType: Void,
    argumentType: Wukong.DocumentProto.ApiIds,
}
export const DisableSingleViewStateCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_disableSingleViewState,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const DispatchKeyboardEventCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_dispatchKeyboardEvent,
    returnType: Wukong.DocumentProto.KeyboardEventContinuation,
    argumentType: Wukong.DocumentProto.KeyboardEvent,
}
export const DispatchMouseEventCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_dispatchMouseEvent,
    returnType: Wukong.DocumentProto.Ret_dispatchMouseEvent,
    argumentType: Wukong.DocumentProto.Arg_dispatchMouseEvent,
}
export const DispatchWheelEventCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_dispatchWheelEvent,
    returnType: Void,
    argumentType: Wukong.DocumentProto.WheelEvent,
}
export const DownloadAllFailedImageRetCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_downloadAllFailedImageRet,
    returnType: Void,
    argumentType: Void,
}
export const DownloadImageRetCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_downloadImageRet,
    returnType: Void,
    argumentType: Wukong.DocumentProto.DownloadImageBatchResult,
}
export const DuplicateVariableCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_duplicateVariable_deprecated,
    returnType: Wukong.DocumentProto.BridgeProtoString,
    argumentType: Wukong.DocumentProto.Arg_DuplicateVariableCommandParam,
}
export const cmdGetDocumentNodeId  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_emDocuemntGetDocumentNodeId,
    returnType: Wukong.DocumentProto.BridgeProtoString,
    argumentType: Void,
    skipRecording: true,
}
export const DocumentGetNodeCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_emDocumentGetNode,
    returnType: Wukong.DocumentProto.BridgeProtoString,
    argumentType: Wukong.DocumentProto.Arg_ApiSearchNodeWithSkip,
}
export const DocumentGetNodeAttrCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_emDocumentGetNodeAttr,
    returnType: Wukong.DocumentProto.BridgeProtoString,
    argumentType: Wukong.DocumentProto.DocumentGetNodeAttrParam,
}
export const EmojiTimerCallback  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_emojiTimerCallback,
    returnType: Void,
    argumentType: Void,
}
export const EnableSingleViewStateCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_enableSingleViewState,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const EndEditingPrototypeInteraction  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_endEditingPrototypeInteraction,
    returnType: Void,
    argumentType: Void,
}
export const EndHyperlinkEditing  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_endHyperlinkEditing,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const EnterHistoryModeCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_enterHistoryMode,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Args_EnterHistoryModeCommand,
}
export const ColorPickCommandWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_exeColorPickCommand,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_ColorPickCommand,
}
export const ExecuteRecoverToRemoteDefaultVariantCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_executeRecoverToRemoteDefaultVariant,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_ExecuteRecoverToRemoteDefaultVariantCommand,
}
export const ExportComponentLibraryDataWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_exportComponentLibraryData,
    returnType: Wukong.DocumentProto.Ret_exportComponentLibraryData,
    argumentType: Wukong.DocumentProto.Arg_IdRef,
}
export const ExportDocumentCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_exportDocument,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_exportDocument,
}
export const ExportDocumentJsonCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_exportDocumentJSON,
    returnType: Wukong.DocumentProto.Ret_exportJson,
    argumentType: Wukong.DocumentProto.Arg_exportJson,
}
export const ExportFigJsonCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_exportFigJson,
    returnType: Wukong.DocumentProto.BridgeProtoString,
    argumentType: Wukong.DocumentProto.BridgeProtoBaseNode,
}
export const ExportHTMLForChatBot  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_exportHTMLForChatBot,
    returnType: Wukong.DocumentProto.Ret_HTMLForChatBot,
    argumentType: Void,
}
export const ExportStyleLibraryDataWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_exportStyleLibraryData,
    returnType: Wukong.DocumentProto.Ret_exportStyleLibraryData,
    argumentType: Wukong.DocumentProto.Arg_IdRef,
}
export const ExportSynergyDocument  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_exportSynergyDocument,
    returnType: Wukong.DocumentProto.Ret_exportBinaryData,
    argumentType: Void,
}
export const ExportToHTML  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_exportToHTML,
    returnType: Wukong.DocumentProto.Ret_exportHTML,
    argumentType: Wukong.DocumentProto.Arg_exportHTML,
}
export const ExportVariableCollectionLibraryDataWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_exportVariableCollectionLibraryData,
    returnType: Wukong.DocumentProto.Ret_exportVariableCollectionLibraryData,
    argumentType: Wukong.DocumentProto.Arg_IdRef,
}
export const ExportVariableLibraryDataWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_exportVariableLibraryData,
    returnType: Wukong.DocumentProto.Ret_exportVariableLibraryData,
    argumentType: Wukong.DocumentProto.Arg_IdRef,
}
export const ExtendSimplifyInstanceRightPanel  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_extendSimplifyInstanceRightPanel,
    returnType: Void,
    argumentType: Void,
}
export const FinishMovingCommentCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_finishMovingComment,
    returnType: Void,
    argumentType: Void,
}
export const ForceRecoverFigmaComponentForSelectedInstanceCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_forceRecoverFigmaComponentForSelectedInstance,
    returnType: Void,
    argumentType: Void,
}
export const ForceUpdateTextLayoutData  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_forceUpdateTextLayoutData,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoBaseNode,
}
export const ForwardPresentFrameCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_forwardPresentFrame,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoIdRef,
}
export const FrameResizeToFitWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_frameResizeToFit,
    returnType: Void,
    argumentType: Void,
}
export const FrameNodesCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_frameSelection,
    returnType: Void,
    argumentType: Void,
}
export const GCTexturesCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_gcTextures,
    returnType: Void,
    argumentType: Void,
}
export const GenerateCandidateComponentThumbnailCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_generateCandidateComponentThumbnail,
    returnType: Wukong.DocumentProto.GenerateCandidateComponentThumbnailRet,
    argumentType: Wukong.DocumentProto.Arg_generatePublishMixinThumbnail,
}
export const GeneratePublishMixinThumbnailCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_generateLibraryPublishMixinThumbnail,
    returnType: Wukong.DocumentProto.Ret_generatePublishMixinThumbnail,
    argumentType: Wukong.DocumentProto.Arg_generatePublishMixinThumbnail,
}
export const GenerateNewVariableNameForCollectionAndTypeCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_generateNewVariableNameForCollectionAndType,
    returnType: Wukong.DocumentProto.BridgeProtoString,
    argumentType: Wukong.DocumentProto.Arg_GenerateNewVariableNameForCollectionAndType,
}
export const GeneratePrototypePresentUrlOptionsForCopyFLowLink  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_generatePrototypeUrlForCopyFlowLink,
    returnType: Wukong.DocumentProto.PrototypePresentUrlOption,
    argumentType: Void,
}
export const GeneratePrototypePresentUrlOptionsForOpenInEditor  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_generatePrototypeUrlForOpenInEditor,
    returnType: Wukong.DocumentProto.PrototypePresentUrlOption,
    argumentType: Void,
}
export const GenerateStyleCandidateComponentThumbnailCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_generateStyleCandidateComponentThumbnail,
    returnType: Wukong.DocumentProto.GenerateCandidateComponentThumbnailRet,
    argumentType: Wukong.DocumentProto.GenerateStyleCandidateComponentThumbnailParam,
}
export const GetAIComponentIdMap  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_getAIComponentIdMap,
    returnType: Wukong.DocumentProto.Ret_AIComponentIdMap,
    argumentType: Void,
}
export const GetAISearchNodeDataCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_getAISearchNodeData,
    returnType: Wukong.DocumentProto.AISearchSelection,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const GetAiCopilotInfoWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_getAiCopilotInfo,
    returnType: Wukong.DocumentProto.Ret_AICopilotInfo,
    argumentType: Wukong.DocumentProto.Rect,
}
export const GetAllLocalStyleAndComponentsCountCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_getAllLocalStyleAndComponentsCount,
    returnType: Wukong.DocumentProto.Ret_getAllLocalStyleAndComponentsCountCommand,
    argumentType: Void,
}
export const GetAndClearRenderDurationBuckets  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_getAndClearRenderDurationBuckets,
    returnType: Wukong.DocumentProto.RenderDurationBuckets,
    argumentType: Void,
}
export const GetBestFullFontNameCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_getBestFullFontName,
    returnType: Wukong.DocumentProto.GetBestFullFontNameResult,
    argumentType: Wukong.DocumentProto.GetBestFullFontNameParam,
}
export const GetComputedColorVariableCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_getComputedColorVariable,
    returnType: Wukong.DocumentProto.Ret_GetComputedColorVariable,
    argumentType: Wukong.DocumentProto.Arg_GetComputedColorVariable,
}
export const GetComputedFills  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_getComputedFills,
    returnType: Wukong.DocumentProto.Ret_getComputedFills,
    argumentType: Wukong.DocumentProto.BridgeProtoLayoutMixin,
}
export const GetComputedPropsReadCountAndRestCounterCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_getComputedPropsReadCountAndRestCounter,
    returnType: Wukong.DocumentProto.ComputedPropsReadCounter,
    argumentType: Void,
}
export const GetComputedStrokes  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_getComputedStrokes,
    returnType: Wukong.DocumentProto.Ret_getComputedStrokes,
    argumentType: Wukong.DocumentProto.BridgeProtoLayoutMixin,
}
export const GetCoverImageCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_getCoverImageData,
    returnType: Wukong.DocumentProto.Ret_exportBinaryData,
    argumentType: Void,
}
export const GetCurrentEditorTypeCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_getCurrentEditorType,
    returnType: Wukong.DocumentProto.Ret_getLocalEdtiorType,
    argumentType: Void,
}
export const GetCurrentPageClipboardData  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_getCurrentPageClipboardData,
    returnType: Wukong.DocumentProto.BridgeProtoString,
    argumentType: Void,
}
export const GetCurrentPageIdCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_getCurrentPageId,
    returnType: Wukong.DocumentProto.BridgeProtoString,
    argumentType: Void,
}
export const GetCurrentSchemaVersionWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_getCurrentSchemaVersion,
    returnType: Wukong.DocumentProto.BridgeProtoInt,
    argumentType: Void,
}
export const GetDeviceOptionsCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_getDeviceOptions,
    returnType: Wukong.DocumentProto.Ret_GetDeviceOptionsCommand,
    argumentType: Void,
}
export const GetDocReadonlyCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_getDocReadonly,
    returnType: Wukong.DocumentProto.BridgeProtoBoolean,
    argumentType: Void,
}
export const GetDocumentColorProfileCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_getDocumentColorProfile,
    returnType: Wukong.DocumentProto.Ret_GetDocumentColorProfile,
    argumentType: Void,
}
export const GetFocusViewCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_getFocusView,
    returnType: Wukong.DocumentProto.Arg_focusView,
    argumentType: Void,
}
export const GetFullStyledTextSegments  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_getFullStyledTextSegments,
    returnType: Wukong.DocumentProto.Ret_getFullStyledTextSegments,
    argumentType: Wukong.DocumentProto.BridgeProtoTextNode,
}
export const PluginApiGetImageByHash  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_getImageByHash,
    returnType: Wukong.DocumentProto.Ret_GetImageByHash,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const PluginApiGetImageBytesAsync  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_getImageBytesAsync,
    returnType: Wukong.DocumentProto.ReturnTypeWithError,
    argumentType: Wukong.DocumentProto.Arg_GetImageBytesAsync,
}
export const GetImageOfFigNodeWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_getImageOfFigNode,
    returnType: Wukong.DocumentProto.Ret_getImageOfFigNode,
    argumentType: Wukong.DocumentProto.Arg_getImageOfFigNode,
}
export const PluginApiGetImageSizeAsync  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_getImageSizeAsync,
    returnType: Wukong.DocumentProto.ReturnTypeWithError,
    argumentType: Wukong.DocumentProto.Arg_GetImageSizeAsync,
}
export const GetLastOperationIndex  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_getLastOperationIndex,
    returnType: Wukong.DocumentProto.SynergyOperationIndex,
    argumentType: Void,
}
export const GetLineHeightCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_getLineHeight,
    returnType: Wukong.DocumentProto.BridgeProtoInt,
    argumentType: Wukong.DocumentProto.Arg_getLineHeight,
}
export const GetNotLoadedImageCountCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_getNotLoadedImageCount,
    returnType: Wukong.DocumentProto.BridgeProtoInt,
    argumentType: Void,
}
export const GetOriginWasmStatusCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_getOriginWasmStatus,
    returnType: Wukong.DocumentProto.Args_ResetWasmStatusCommand,
    argumentType: Void,
}
export const GetPageWorldBoundsCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_getPageWorldBounds,
    returnType: Wukong.DocumentProto.Rect,
    argumentType: Void,
}
export const GetProtoTypeCoverImageCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_getPrototypeCoverImageData,
    returnType: Wukong.DocumentProto.Ret_exportBinaryData,
    argumentType: Wukong.DocumentProto.Arg_exportPrototypeCoverImage,
}
export const GeneratePrototypePresentUrlOptionsForOpenPresent  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_getPrototypePresentUrlOptions,
    returnType: Wukong.DocumentProto.PrototypePresentUrlOption,
    argumentType: Void,
}
export const GetSingleViewStateValueCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_getSingleViewStateValue,
    returnType: Wukong.DocumentProto.ViewState,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const GetTangentCameraPositionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_getTangentCameraPosition,
    returnType: Wukong.DocumentProto.Ret_GetVertexCameraPosition,
    argumentType: Wukong.DocumentProto.BridgeProtoInt,
}
export const GetUndoStatus  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_getUndoStatus,
    returnType: Wukong.DocumentProto.UndoStatus,
    argumentType: Void,
}
export const GetUsedMemoryCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_getUsedMemory,
    returnType: Wukong.DocumentProto.Ret_GetUsedMemoryCommand,
    argumentType: Void,
}
export const GetVRAM  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_getVRAM,
    returnType: Wukong.DocumentProto.Ret_GetVRAM,
    argumentType: Void,
}
export const GetVertexCameraPositionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_getVertexCameraPosition,
    returnType: Wukong.DocumentProto.Ret_GetVertexCameraPosition,
    argumentType: Wukong.DocumentProto.BridgeProtoInt,
}
export const PluginApiGetViewport  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_getViewport,
    returnType: Wukong.DocumentProto.Ret_Viewport,
    argumentType: Void,
}
export const GroupLocalStyleSelectionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_groupLocalStyleSelection,
    returnType: Void,
    argumentType: Void,
}
export const GroupSelectionWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_groupSelection,
    returnType: Void,
    argumentType: Void,
}
export const HideOtherLayersCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_hideOtherLayers,
    returnType: Void,
    argumentType: Void,
}
export const HighlightNodesByIdsWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_highlightNodesByIdsWasmCall,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_cmdHoverMultiNodes,
}
export const HistoryModeReloadFailedResourcesCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_historyModeReloadFailedResources,
    returnType: Void,
    argumentType: Void,
}
export const UpdateAutoLayoutHoverItemWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_hoverAutoLayoutWHItem,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateAutoLayoutHoverItemParam,
}
export const HoverNodesBySpecificVariantValueWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_hoverNodesBySpecificVariantValueWasmCall,
    returnType: Void,
    argumentType: Wukong.DocumentProto.HoverOrSelectNodesBySpecificVariantValueParam,
}
export const ImportNodeFromLibraryCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_importNodeFromLibray,
    returnType: Wukong.DocumentProto.ImportNodeFromLibraryResult,
    argumentType: Wukong.DocumentProto.ImportNodeFromLibraryParam,
}
export const ImportLibraryNodesCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_importNodesFromLibray,
    returnType: Wukong.DocumentProto.ImportLibraryNodesCommandResult,
    argumentType: Wukong.DocumentProto.ImportLibraryNodesCommandParam,
}
export const InsertPageAfterCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_insertPageAfter,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_insertPageAfter,
}
export const InsertPageBeforeCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_insertPageBefore,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_insertPageBefore,
}
export const InsertVariableBetweenCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_insertVariableBetween_deprecated,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_InsertVariableBetweenCommandParam,
}
export const IsFocusViewEnabled  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_isFocusViewEnabled,
    returnType: Wukong.DocumentProto.BridgeProtoBoolean,
    argumentType: Void,
}
export const IsThisNameInvalidForCreatingVariable  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_isThisNameInvalidForCreatingVariable,
    returnType: Wukong.DocumentProto.BridgeProtoBoolean,
    argumentType: Wukong.DocumentProto.Arg_IsRepeatVariableNameCommandParam,
}
export const LeaveHistoryModeWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_leaveHistoryMode,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Args_LeaveHistoryModeCommand,
}
export const LoadFontInJsCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_loadFontInJs,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_loadFontInJs,
}
export const LoadFontInJsFailedCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_loadFontInJsFailed,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_loadFontInJsFailed,
}
export const LooperApplyCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_looperApply,
    returnType: Void,
    argumentType: Wukong.DocumentProto.PluginLooperData,
}
export const LoseGpu  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_loseGpu,
    returnType: Void,
    argumentType: Void,
}
export const LowPerfResizeCanvasForPresentCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_lowPerfResizeCanvasForPresent,
    returnType: Void,
    argumentType: Wukong.DocumentProto.WCanvasResized,
}
export const MakeHoverPopupHyperlinkActive  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_makeHoverPopupHyperlinkActive,
    returnType: Void,
    argumentType: Void,
}
export const MarkControlFontCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_markControlFontLoaded,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoInt,
}
export const MarkEvalJsBeginCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_markEvalJsBegin,
    returnType: Void,
    argumentType: Void,
}
export const MarkEvalJsEndCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_markEvalJsEnd,
    returnType: Void,
    argumentType: Void,
}
export const MouseEnterCommentCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_mouseEnterComment,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const MouseLeaveCommentCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_mouseLeaveComment,
    returnType: Void,
    argumentType: Void,
}
export const OnMovingCommentCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_movingComment,
    returnType: Void,
    argumentType: Wukong.DocumentProto.CommentMovingParam,
}
export const NavigateToComponentPropOnComponentWasmcall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_navigateToComponentPropOnComponent,
    returnType: Wukong.DocumentProto.BridgeProtoBoolean,
    argumentType: Wukong.DocumentProto.Arg_navigatoToComponentPropOnComponent,
}
export const NavigateToComponentPropOnInstanceWasmcall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_navigateToComponentPropOnInstance,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_navigatoToComponentPropOnInstance,
}
export const NextAutoLayoutFloatInputCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_nextAutoLayoutFloatInput,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoBoolean,
}
export const NotifyCanvasSizeChanged  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_notifyCanvasSizeChanged,
    returnType: Void,
    argumentType: Void,
}
export const OnAnimationFrame  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_onAnimationFrame,
    returnType: Void,
    argumentType: Wukong.DocumentProto.WasmCall,
}
export const OnBatchOperation  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_onBatchOperation,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_onBatchOperation,
}
export const OnBatchOperationAck  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_onBatchOperationAck,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_onBatchOperationAck,
}
export const OnBatchOperationsUnderHistoryModeCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_onBatchOperationsUnderHistoryMode,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_onBatchOperations,
}
export const OnBridgeStatusChange  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_onBridgeStatusChange,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoInt,
}
export const OnClipboardReadProxyFilesCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_onClipboardReadProxyFiles,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_onClipboardReadProxyFiles,
}
export const OnVRAMCompactCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_onCompactVRAM,
    returnType: Void,
    argumentType: Void,
}
export const OnDocIdChange  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_onDocIdChange,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const OnDocumentCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_onDocument,
    returnType: Wukong.DocumentProto.BridgeProtoBoolean,
    argumentType: Wukong.DocumentProto.Arg_onDocument,
}
export const OnGetLoadedImageBitmapBytesCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_onGetLoadedImageBitmapBytesCommand,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_OnGetBitmapBytes,
}
export const OnMembershipChange  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_onMembershipChange,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoBytes,
}
export const OnMicroTask  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_onMicroTask,
    returnType: Void,
    argumentType: Wukong.DocumentProto.WasmCall,
}
export const OnPreparedUploadTextureCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_onPreparedUploadTexture,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_OnPreparedUploadTexture,
}
export const OnPrototypeDeviceFrameDownloadCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_onPrototypeDeviceFrameDownload,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_OnPrototypeDeviceFrameDownload,
}
export const OnSessionIdChange  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_onSessionIdChange,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoInt,
}
export const OnSyncUserProperties  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_onSyncUserProperties,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoBytes,
}
export const OnUserEnter  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_onUserEnter,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoBytes,
}
export const OnUserIdChange  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_onUserIdChange,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoInt64,
}
export const OnUserQuit  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_onUserQuit,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoBytes,
}
export const OnUserRoleChange  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_onUserRoleChange,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoInt,
}
export const OnUserSynergyRoleChange  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_onUserSynergyRoleChange,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoBytes,
}
export const OpenHyperlinkNodeWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_openHyperlinkNode,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Hyperlink,
}
export const PluginApiAddComponentProperty  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiAddComponentProperty,
    returnType: Wukong.DocumentProto.Ret_APiStringWithError,
    argumentType: Wukong.DocumentProto.Req_ApiAddComponentProperty,
}
export const PluginApiAppendChild  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiAppendChild,
    returnType: Wukong.DocumentProto.ReturnTypeWithError,
    argumentType: Wukong.DocumentProto.Arg_ApiAppendChild,
}
export const PluginApiClone  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiCloneNodes,
    returnType: Wukong.DocumentProto.Arg_ApiGetNodeIdRef,
    argumentType: Wukong.DocumentProto.Arg_ApiNodeIdRef,
}
export const PluginApiCombineAsVariants  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiCombineAsVariants,
    returnType: Wukong.DocumentProto.Ret_ApiIdRefWithError,
    argumentType: Wukong.DocumentProto.Arg_PluginApiMoveNodes,
}
export const PluginApiComponentPropertyDefinitions  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiComponentPropertyDefinitions,
    returnType: Wukong.DocumentProto.Ret_ApiComponentPropertyDefinitionsWithError,
    argumentType: Wukong.DocumentProto.BridgeProtoIdRef,
}
export const PluginApiCreateComponentFromNode  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiCreateComponentFromNode,
    returnType: Wukong.DocumentProto.Arg_ApiGetNodeIdRef,
    argumentType: Wukong.DocumentProto.Arg_ApiNodeIdRef,
}
export const PluginApiCreateExternalPromise  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiCreateExternalPromise,
    returnType: Wukong.DocumentProto.BridgeProtoInt,
    argumentType: Void,
}
export const PluginApiCreateImage  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiCreateImage,
    returnType: Wukong.DocumentProto.Ret_CreateImage,
    argumentType: Wukong.DocumentProto.BridgeProtoBytes,
}
export const PluginApiCreateInstance  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiCreateInstance,
    returnType: Wukong.DocumentProto.Ret_ApiIdRefWithError,
    argumentType: Wukong.DocumentProto.Arg_ApiSearchNodeWithSkip,
}
export const PluginApiCreateShapeNode  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiCreateNode,
    returnType: Wukong.DocumentProto.Ret_ApiIdRefWithError,
    argumentType: Wukong.DocumentProto.Arg_ApiCreateShapeNode,
}
export const PluginApiCreatePage  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiCreatePage,
    returnType: Wukong.DocumentProto.Ret_ApiIdRefWithError,
    argumentType: Void,
}
export const PluginApiCreateStyleNode  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiCreateStyleNode,
    returnType: Wukong.DocumentProto.Ret_ApiIdRefWithError,
    argumentType: Wukong.DocumentProto.Arg_ApiCreateStyleNode,
}
export const PluginApiCurrentUser  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiCurrentUser,
    returnType: Wukong.DocumentProto.Ret_ApiCurrentUser,
    argumentType: Void,
}
export const PluginApiDefaultVariant  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiDefaultVariant,
    returnType: Wukong.DocumentProto.Ret_ApiIdRefWithError,
    argumentType: Wukong.DocumentProto.BridgeProtoIdRef,
}
export const PluginApiDeleteCharacters  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiDeleteCharacters,
    returnType: Wukong.DocumentProto.ReturnTypeWithError,
    argumentType: Wukong.DocumentProto.Arg_ApiDeleteCharacters,
}
export const PluginApiDeleteComponentProperty  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiDeleteComponentProperty,
    returnType: Wukong.DocumentProto.ReturnTypeWithError,
    argumentType: Wukong.DocumentProto.Req_ApiDeleteComponentProperty,
}
export const PluginApiDetachInstance  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiDetachInstance,
    returnType: Wukong.DocumentProto.Arg_ApiGetNodeIdRef,
    argumentType: Wukong.DocumentProto.Arg_ApiNodeIdRef,
}
export const PluginApiDropEventNeeded  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiDropEventNeeded,
    returnType: Wukong.DocumentProto.BridgeProtoBoolean,
    argumentType: Void,
}
export const PluginApiDropEventPickInsertionLocation  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiDropEventPickInsertionLocation,
    returnType: Wukong.DocumentProto.Ret_PluginDropEventPickInsertionLocation,
    argumentType: Wukong.DocumentProto.Arg_PluginDropEventPickInsertionLocation,
}
export const PluginApiEditComponentProperty  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiEditComponentProperty,
    returnType: Wukong.DocumentProto.Ret_APiStringWithError,
    argumentType: Wukong.DocumentProto.Req_ApiEditComponentProperty,
}
export const PluginApiEventNotifyCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiEventNotify,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_PluginCallbackEventCollection,
}
export const PluginApiExportAsync  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiExportAsync,
    returnType: Wukong.DocumentProto.BridgeProtoInt,
    argumentType: Wukong.DocumentProto.Arg_PluginApiExportSetting,
}
export const PluginApiFindAll  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiFindAll,
    returnType: Wukong.DocumentProto.BridgeProtoStringArrayWithError,
    argumentType: Wukong.DocumentProto.Arg_ApiSearchNodeWithSkip,
}
export const PluginApiFindAllCriteria  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiFindAllCriteria,
    returnType: Wukong.DocumentProto.BridgeProtoStringArrayWithError,
    argumentType: Wukong.DocumentProto.Arg_FindAllCriteria,
}
export const PluginApiFindChildren  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiFindChildren,
    returnType: Wukong.DocumentProto.BridgeProtoStringArrayWithError,
    argumentType: Wukong.DocumentProto.Arg_ApiSearchNodeWithSkip,
}
export const PluginApiFlatten  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiFlatten,
    returnType: Wukong.DocumentProto.Ret_ApiIdRefWithError,
    argumentType: Wukong.DocumentProto.Arg_PluginApiMoveNodes,
}
export const PluginApiGetChildrenCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiGetChildren,
    returnType: Wukong.DocumentProto.BridgeProtoStringArray,
    argumentType: Wukong.DocumentProto.Arg_ApiNodeId,
}
export const PluginApiGetClipsContent  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiGetClipsContent,
    returnType: Wukong.DocumentProto.BridgeProtoBoolean,
    argumentType: Wukong.DocumentProto.Arg_ApiGetClipsContent,
}
export const PluginApiGetComponentPropertiesOfInstance  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiGetComponentPropertiesOfInstance,
    returnType: Wukong.DocumentProto.Ret_ApiComponentPropertiesWithError,
    argumentType: Wukong.DocumentProto.BridgeProtoIdRef,
}
export const PluginApiGetComponentPropertyReferences  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiGetComponentPropertyReferences,
    returnType: Wukong.DocumentProto.Ret_ApiGetComponentPropertyReferencesWithError,
    argumentType: Wukong.DocumentProto.BridgeProtoIdRef,
}
export const PluginApiGetConstraintsCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiGetConstraints,
    returnType: Wukong.DocumentProto.Constraints,
    argumentType: Wukong.DocumentProto.Arg_ApiNodeId,
}
export const PluginApiGetCurrentPage  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiGetCurrentPage,
    returnType: Wukong.DocumentProto.Arg_ApiNodeIdRef,
    argumentType: Void,
}
export const PluginApiGetDimensionAndPositionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiGetDimensionAndPosition,
    returnType: Wukong.DocumentProto.Rect,
    argumentType: Wukong.DocumentProto.Arg_ApiNodeId,
}
export const PluginApiGetExposedInstances  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiGetExposedInstances,
    returnType: Wukong.DocumentProto.BridgeProtoStringArrayWithError,
    argumentType: Wukong.DocumentProto.BridgeProtoIdRef,
}
export const PluginApiGetFileKey  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiGetFileKey,
    returnType: Wukong.DocumentProto.Ret_GetFileKey,
    argumentType: Void,
}
export const PluginApiGetHandleMirroring  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiGetHandleMirroring,
    returnType: Wukong.DocumentProto.Ret_ApiGetMixedHandleMirror,
    argumentType: Wukong.DocumentProto.BridgeProtoLayoutMixin,
}
export const PluginApiGetInstancesAsync  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiGetInstancesAsync,
    returnType: Wukong.DocumentProto.BridgeProtoStringArrayWithError,
    argumentType: Wukong.DocumentProto.BridgeProtoIdRef,
}
export const PluginApiGetIsExposedInstance  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiGetIsExposedInstance,
    returnType: Wukong.DocumentProto.Ret_ApiGetIsExposedInstance,
    argumentType: Wukong.DocumentProto.BridgeProtoIdRef,
}
export const PluginApiGetLocalEffectStyles  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiGetLocalEffectStyles,
    returnType: Wukong.DocumentProto.Ret_APiIdRefWithError,
    argumentType: Void,
}
export const PluginApiGetLocalGridStyles  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiGetLocalGridStyles,
    returnType: Wukong.DocumentProto.Ret_ApiStyleMixins,
    argumentType: Void,
}
export const PluginApiGetLocalPaintStyles  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiGetLocalPaintStyles,
    returnType: Wukong.DocumentProto.Ret_APiIdRefWithError,
    argumentType: Void,
}
export const PluginApiGetLocalTextStyles  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiGetLocalTextStyles,
    returnType: Wukong.DocumentProto.Ret_APiIdRefWithError,
    argumentType: Void,
}
export const PluginApiGetName  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiGetName,
    returnType: Wukong.DocumentProto.BridgeProtoString,
    argumentType: Wukong.DocumentProto.Arg_ApiGetName,
}
export const PluginApiGetOpacityCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiGetOpacity,
    returnType: Wukong.DocumentProto.BridgeProtoFloat,
    argumentType: Wukong.DocumentProto.BridgeProtoLayoutMixin,
}
export const PluginApiGetParentCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiGetParent,
    returnType: Wukong.DocumentProto.BridgeProtoString,
    argumentType: Wukong.DocumentProto.Arg_ApiNodeId,
}
export const PluginApiGetPluginData  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiGetPluginData,
    returnType: Wukong.DocumentProto.Ret_ApiGetPluginData,
    argumentType: Wukong.DocumentProto.Arg_ApiGetPluginData,
}
export const PluginApiGetPluginDataKeys  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiGetPluginDataKeys,
    returnType: Wukong.DocumentProto.Ret_ApiGetPluginDataKeys,
    argumentType: Wukong.DocumentProto.Arg_ApiGetPluginDataKeys,
}
export const PluginApiGetPublishStatusCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiGetPublishStatus,
    returnType: Wukong.DocumentProto.Ret_ApiGetPublishStatus,
    argumentType: Wukong.DocumentProto.Arg_ApiNodeIdRef,
}
export const PluginApiGetRangeAllFontNames  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiGetRangeAllFontNames,
    returnType: Wukong.DocumentProto.Ret_ApiGetAllFontNameWithError,
    argumentType: Wukong.DocumentProto.Arg_ApiTextRange,
}
export const PluginApiGetRangeFillStyleId  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiGetRangeFillStyleId,
    returnType: Wukong.DocumentProto.Ret_ApiGetMixedStringWithError,
    argumentType: Wukong.DocumentProto.Arg_ApiTextRange,
}
export const PluginApiGetRangeFills  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiGetRangeFills,
    returnType: Wukong.DocumentProto.Ret_ApiGetFillsWithError,
    argumentType: Wukong.DocumentProto.Arg_ApiTextRange,
}
export const PluginApiGetRangeFontName  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiGetRangeFontName,
    returnType: Wukong.DocumentProto.Ret_ApiGetMixedFontNameWithError,
    argumentType: Wukong.DocumentProto.Arg_ApiTextRange,
}
export const PluginApiGetRangeFontSize  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiGetRangeFontSize,
    returnType: Wukong.DocumentProto.Ret_ApiGetMixedFloatWithError,
    argumentType: Wukong.DocumentProto.Arg_ApiTextRange,
}
export const PluginApiGetRangeFontWeight  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiGetRangeFontWeight,
    returnType: Wukong.DocumentProto.Ret_ApiGetMixedFloatWithError,
    argumentType: Wukong.DocumentProto.Arg_ApiTextRange,
}
export const PluginApiGetRangeHyperlink  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiGetRangeHyperlink,
    returnType: Wukong.DocumentProto.Ret_ApiGetHyperlinkWithError,
    argumentType: Wukong.DocumentProto.Arg_ApiTextRange,
}
export const PluginApiGetRangeIndentation  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiGetRangeIndentation,
    returnType: Wukong.DocumentProto.Ret_ApiGetMixedIntWithError,
    argumentType: Wukong.DocumentProto.Arg_ApiTextRange,
}
export const PluginApiGetRangeLetterSpacing  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiGetRangeLetterSpacing,
    returnType: Wukong.DocumentProto.Ret_ApiGetLetterSpacingWithError,
    argumentType: Wukong.DocumentProto.Arg_ApiTextRange,
}
export const PluginApiGetRangeLineHeight  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiGetRangeLineHeight,
    returnType: Wukong.DocumentProto.Ret_ApiGetLineHeightWithError,
    argumentType: Wukong.DocumentProto.Arg_ApiTextRange,
}
export const PluginApiGetRangeLineType  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiGetRangeLineType,
    returnType: Wukong.DocumentProto.Ret_ApiGetListOptionsWithError,
    argumentType: Wukong.DocumentProto.Arg_ApiTextRange,
}
export const PluginApiGetRangeTextCase  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiGetRangeTextCase,
    returnType: Wukong.DocumentProto.Ret_ApiGetTextCaseWithError,
    argumentType: Wukong.DocumentProto.Arg_ApiTextRange,
}
export const PluginApiGetRangeTextDecoration  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiGetRangeTextDecoration,
    returnType: Wukong.DocumentProto.Ret_ApiGetTextDecorationWithError,
    argumentType: Wukong.DocumentProto.Arg_ApiTextRange,
}
export const PluginApiGetRangeTextStyleId  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiGetRangeTextStyleId,
    returnType: Wukong.DocumentProto.Ret_ApiGetMixedStringWithError,
    argumentType: Wukong.DocumentProto.Arg_ApiTextRange,
}
export const PluginApiGetRelativeTransformCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiGetRelativeTransform,
    returnType: Wukong.DocumentProto.Transform,
    argumentType: Wukong.DocumentProto.BridgeProtoLayoutMixin,
}
export const PluginApiGetSelectionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiGetSelection,
    returnType: Wukong.DocumentProto.BridgeProtoStringArray,
    argumentType: Wukong.DocumentProto.Arg_ApiNodeId,
}
export const PluginApiGetSelectionColors  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiGetSelectionColors,
    returnType: Wukong.DocumentProto.Ret_APiGetSelectionColors,
    argumentType: Void,
}
export const PluginApiGetSharedPluginData  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiGetSharedPluginData,
    returnType: Wukong.DocumentProto.Ret_ApiGetSharedPluginData,
    argumentType: Wukong.DocumentProto.Arg_ApiGetSharedPluginData,
}
export const PluginApiGetSharedPluginDataKeys  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiGetSharedPluginDataKeys,
    returnType: Wukong.DocumentProto.Ret_ApiGetSharedPluginDataKeys,
    argumentType: Wukong.DocumentProto.Arg_ApiGetSharedPluginDataKeys,
}
export const PluginApiGetStyledTextSegments  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiGetStyledTextSegments,
    returnType: Wukong.DocumentProto.Ret_ApiStyledTextSegmentsWithError,
    argumentType: Wukong.DocumentProto.Arg_ApiTextRangeWithBool,
}
export const PluginApiGetTypeCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiGetType,
    returnType: Wukong.DocumentProto.Ret_ApiGetNodeType,
    argumentType: Wukong.DocumentProto.Arg_ApiNodeId,
}
export const PluginApiGetVisibleCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiGetVisible,
    returnType: Wukong.DocumentProto.BridgeProtoBoolean,
    argumentType: Wukong.DocumentProto.BridgeProtoLayoutMixin,
}
export const PluginApiGroup  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiGroup,
    returnType: Wukong.DocumentProto.Ret_ApiIdRefWithError,
    argumentType: Wukong.DocumentProto.Arg_PluginApiMoveNodes,
}
export const PluginApiImportRemoteLibNodeByKeyAsyncCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiImportRemoteLibNodeByKeyAsync,
    returnType: Wukong.DocumentProto.ReturnTypeWithError,
    argumentType: Wukong.DocumentProto.Arg_ImportRemoteLibNodeByKeyAsyncCommand,
}
export const PluginApiImportRemoteLibNodeByKeyAsyncCallbackCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiImportRemoteLibNodeByKeyAsyncCallback,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_ImportRemoteLibNodeByKeyAsyncCallbackCommand,
}
export const PluginApiInsertCharacters  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiInsertCharacters,
    returnType: Wukong.DocumentProto.ReturnTypeWithError,
    argumentType: Wukong.DocumentProto.Arg_ApiInsertCharacters,
}
export const PluginApiIsRemoteLibraryNode  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiIsRemoteLibraryNode,
    returnType: Wukong.DocumentProto.Ret_APiBoolWithError,
    argumentType: Wukong.DocumentProto.BridgeProtoIdRef,
}
export const PluginApiListAvailableFontsAsync  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiListAvailableFontsAsync,
    returnType: Void,
    argumentType: Void,
}
export const PluginApiLoadFont  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiLoadFont,
    returnType: Wukong.DocumentProto.Ret_APILoadFont,
    argumentType: Wukong.DocumentProto.FontNameRef,
}
export const PluginApiRectToFrame  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiRectToFrame,
    returnType: Wukong.DocumentProto.BridgeProtoString,
    argumentType: Wukong.DocumentProto.Arg_ApiRectToFrame,
}
export const PluginApiRemoveNode  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiRemoveNode,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_ApiRemoveNode,
}
export const PluginApiRescaleCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiRescaleCommand,
    returnType: Wukong.DocumentProto.ReturnTypeWithError,
    argumentType: Wukong.DocumentProto.Arg_ApiScaleNodes,
}
export const PluginApiResetSymbolOverrides  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiResetSymbolOverrides,
    returnType: Wukong.DocumentProto.ReturnTypeWithError,
    argumentType: Wukong.DocumentProto.BridgeProtoIdRef,
}
export const PluginApiResizeNode  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiResizeNode,
    returnType: Wukong.DocumentProto.ReturnTypeWithError,
    argumentType: Wukong.DocumentProto.Arg_ApiResize,
}
export const PluginApiResizeWithoutConstraints  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiResizeWithoutConstraints,
    returnType: Wukong.DocumentProto.ReturnTypeWithError,
    argumentType: Wukong.DocumentProto.Arg_ApiResize,
}
export const PluginApiResolveExternalPromiseCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiResolveExternalPromise,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_ResolveExternalPromise,
}
export const PluginApiSetClipsContent  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiSetClipsContent,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_ApiSetClipsContent,
}
export const PluginApiSetComponentPropertiesOfInstance  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiSetComponentPropertiesOfInstance,
    returnType: Wukong.DocumentProto.ReturnTypeWithError,
    argumentType: Wukong.DocumentProto.Arg_ApiSetInstancePropertiesWithError,
}
export const PluginApiSetComponentPropertyReferences  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiSetComponentPropertyReferences,
    returnType: Wukong.DocumentProto.ReturnTypeWithError,
    argumentType: Wukong.DocumentProto.Arg_ApiSetComponentPropertyReferences,
}
export const PluginApiSetConstraintsCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiSetConstraints,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_ApiSetConstraints,
}
export const PluginApiSetCurrentPage  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiSetCurrentPage,
    returnType: Wukong.DocumentProto.ReturnTypeWithError,
    argumentType: Wukong.DocumentProto.Arg_ApiNodeIdRef,
}
export const PluginApiSetDimensionAndPositionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiSetDimensionAndPosition,
    returnType: Wukong.DocumentProto.ReturnTypeWithError,
    argumentType: Wukong.DocumentProto.Arg_ApiDimensionAndPosition,
}
export const PluginApiSetEventNotificationStatus  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiSetEventNotificationStatus,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_PluginEventNotificationStatus,
}
export const PluginApiSetHandleMirroring  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiSetHandleMirroring,
    returnType: Void,
    argumentType: Wukong.DocumentProto.ApiSetHandleMirror,
}
export const PluginApiSetIsExposedInstance  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiSetIsExposedInstance,
    returnType: Wukong.DocumentProto.Ret_ApiSetProps,
    argumentType: Wukong.DocumentProto.Arg_ApiSetBoolean,
}
export const PluginApiSetName  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiSetName,
    returnType: Wukong.DocumentProto.ReturnTypeWithError,
    argumentType: Wukong.DocumentProto.Arg_ApiSetName,
}
export const PluginApiSetNameAutoRename  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiSetNameAutoRename,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_ApiSetName,
}
export const PluginApiSetOpacityCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiSetOpacity,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_ApiSetFloat,
}
export const PluginApiSetPluginData  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiSetPluginData,
    returnType: Wukong.DocumentProto.ReturnTypeWithError,
    argumentType: Wukong.DocumentProto.Arg_ApiSetPluginData,
}
export const PluginApiSetRangeFillStyleId  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiSetRangeFillStyleId,
    returnType: Wukong.DocumentProto.ReturnTypeWithError,
    argumentType: Wukong.DocumentProto.Arg_ApiTextRangeWithString,
}
export const PluginApiSetRangeFills  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiSetRangeFills,
    returnType: Wukong.DocumentProto.ReturnTypeWithError,
    argumentType: Wukong.DocumentProto.Arg_ApiTextRangeWithFills,
}
export const PluginApiSetRangeFontName  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiSetRangeFontName,
    returnType: Wukong.DocumentProto.ReturnTypeWithError,
    argumentType: Wukong.DocumentProto.Arg_ApiTextRangeWithFontName,
}
export const PluginApiSetRangeFontSize  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiSetRangeFontSize,
    returnType: Wukong.DocumentProto.ReturnTypeWithError,
    argumentType: Wukong.DocumentProto.Arg_ApiTextRangeWithFloat,
}
export const PluginApiSetRangeHyperlink  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiSetRangeHyperlink,
    returnType: Wukong.DocumentProto.ReturnTypeWithError,
    argumentType: Wukong.DocumentProto.Arg_ApiTextRangeWithHyperlinkTarget,
}
export const PluginApiSetRangeIndentation  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiSetRangeIndentation,
    returnType: Wukong.DocumentProto.ReturnTypeWithError,
    argumentType: Wukong.DocumentProto.Arg_ApiTextRangeWithInt,
}
export const PluginApiSetRangeLetterSpacing  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiSetRangeLetterSpacing,
    returnType: Wukong.DocumentProto.ReturnTypeWithError,
    argumentType: Wukong.DocumentProto.Arg_ApiTextRangeWithLetterSpacing,
}
export const PluginApiSetRangeLineHeight  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiSetRangeLineHeight,
    returnType: Wukong.DocumentProto.ReturnTypeWithError,
    argumentType: Wukong.DocumentProto.Arg_ApiTextRangeWithLineHeight,
}
export const PluginApiSetRangeLineType  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiSetRangeLineType,
    returnType: Wukong.DocumentProto.ReturnTypeWithError,
    argumentType: Wukong.DocumentProto.Arg_ApiTextRangeWithLineType,
}
export const PluginApiSetRangeTextCase  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiSetRangeTextCase,
    returnType: Wukong.DocumentProto.ReturnTypeWithError,
    argumentType: Wukong.DocumentProto.Arg_ApiTextRangeWithTextCase,
}
export const PluginApiSetRangeTextDecoration  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiSetRangeTextDecoration,
    returnType: Wukong.DocumentProto.ReturnTypeWithError,
    argumentType: Wukong.DocumentProto.Arg_ApiTextRangeWithTextDecoration,
}
export const PluginApiSetRangeTextStyleId  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiSetRangeTextStyleId,
    returnType: Wukong.DocumentProto.ReturnTypeWithError,
    argumentType: Wukong.DocumentProto.Arg_ApiTextRangeWithString,
}
export const PluginApiSetRelativeTransformCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiSetRelativeTransform,
    returnType: Wukong.DocumentProto.ReturnTypeWithError,
    argumentType: Wukong.DocumentProto.Arg_ApiSetTransform,
}
export const PluginApiSetSharedPluginData  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiSetSharedPluginData,
    returnType: Wukong.DocumentProto.ReturnTypeWithError,
    argumentType: Wukong.DocumentProto.Arg_ApiSetSharedPluginData,
}
export const PluginApiSetVisibleCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiSetVisible,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_ApiSetBoolean,
}
export const PluginApiShowUICommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiShowUI,
    returnType: Wukong.DocumentProto.ReturnTypeWithError,
    argumentType: Wukong.DocumentProto.Arg_ShowUI,
}
export const PluginApiSubtract  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiSubtract,
    returnType: Wukong.DocumentProto.Ret_ApiIdRefWithError,
    argumentType: Wukong.DocumentProto.Arg_PluginApiMoveNodes,
}
export const PluginApiSymbolOverrides  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiSymbolOverrides,
    returnType: Wukong.DocumentProto.Ret_ApiSymbolOverridesWithError,
    argumentType: Wukong.DocumentProto.BridgeProtoIdRef,
}
export const PluginApiUngroup  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiUngroup,
    returnType: Wukong.DocumentProto.Ret_ApiUngroup,
    argumentType: Wukong.DocumentProto.Arg_ApiNodeIdRef,
}
export const PluginApiUpdateTextNodeContent  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiUpdateTextNodeContent,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_ApiUpdateTextNodeContent,
}
export const PluginApiUpdateTextProps  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginApiUpdateTextProps,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_ApiTextProps,
}
export const PluginCancelEventNotifierCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginCancelEventNotifier,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_MonitorEvents,
}
export const PluginApiCancelNotify  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginCancelNotify,
    returnType: Wukong.DocumentProto.ReturnTypeWithError,
    argumentType: Wukong.DocumentProto.BridgeProtoInt,
}
export const PluginApiNotify  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginNotify,
    returnType: Wukong.DocumentProto.ReturnTypeWithError,
    argumentType: Wukong.DocumentProto.Arg_ApiNotify,
}
export const PluginApiNotifyCallback  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginNotifyCallback,
    returnType: Wukong.DocumentProto.ReturnTypeWithError,
    argumentType: Wukong.DocumentProto.Arg_ApiNotifyCallback,
}
export const PluginRegisterEventNotifierCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginRegisterEventNotifier,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_MonitorEvents,
}
export const PluginApiUIClose  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginUIClose,
    returnType: Wukong.DocumentProto.ReturnTypeWithError,
    argumentType: Void,
}
export const PluginApiUIHide  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginUIHide,
    returnType: Wukong.DocumentProto.ReturnTypeWithError,
    argumentType: Void,
}
export const PluginApiUIReposition  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginUIReposition,
    returnType: Wukong.DocumentProto.ReturnTypeWithError,
    argumentType: Wukong.DocumentProto.Arg_reposition,
}
export const PluginApiUIResize  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginUIResize,
    returnType: Wukong.DocumentProto.ReturnTypeWithError,
    argumentType: Wukong.DocumentProto.Arg_Resize,
}
export const PluginApiUIShow  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_pluginUIShow,
    returnType: Wukong.DocumentProto.ReturnTypeWithError,
    argumentType: Void,
}
export const PreviewServerOnQueryReply  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_previewServerOnQueryReply,
    returnType: Void,
    argumentType: Void,
}
export const PrintImageDiagnosisCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_printImageDiagnosis,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_PrintImageDiagnosis,
}
export const PrintUsageCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_printUsage,
    returnType: Void,
    argumentType: Void,
}
export const PrototypeAnimationFrameCallback  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_prototypeAnimationFrame,
    returnType: Void,
    argumentType: Void,
}
export const ReadMagnifierBufferCallbackCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_readMagnifierBufferCallback,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_ReadMagnifierBufferCallback,
}
export const RecoverComponentForSelectedInstanceCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_recoverComponentForSelectedInstance,
    returnType: Void,
    argumentType: Void,
}
export const RecoverToDefaultVarientCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_recoverToDefaultVarient,
    returnType: Void,
    argumentType: Void,
}
export const ReloadImageTimerCallback  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_reloadImageTimerCallback,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_setTimeoutToReloadImage,
}
export const RemoveAllAutoLayoutWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_removeAllAutoLayout,
    returnType: Void,
    argumentType: Void,
}
export const RemoveAllPrototypeInteractions  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_removeAllPrototypeInteractions,
    returnType: Void,
    argumentType: Void,
}
export const RemoveAutoLayoutWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_removeAutoLayout,
    returnType: Void,
    argumentType: Void,
}
export const RemoveCopiedNodesTimerCallback  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_removeCopiedNodesTimerCallback,
    returnType: Void,
    argumentType: Void,
}
export const RemoveDraftCommentCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_removeDraftComment,
    returnType: Void,
    argumentType: Void,
}
export const RemoveEffectStyleForSelectionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_removeEffectStyleForSelection,
    returnType: Void,
    argumentType: Void,
}
export const RemoveSelectionAllPrototypeInteractions  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_removeSelectionAllPrototypeInteractions,
    returnType: Void,
    argumentType: Void,
}
export const RemoveSelectionPrototypeInteraction  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_removeSelectionPrototypeInteraction,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_RemoveSelectionPrototypeInteraction,
}
export const RemoveVariableCodeSyntaxCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_removeVariableCodeSyntax,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_RemoveVariableCodeSyntaxCommandParam,
}
export const RemoveVariableSetCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_removeVariableSet_deprecated,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const RemoveVariableCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_removeVariable_deprecated,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const RenameSelectedComponentSetPropWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_renameSelectedComponentSetProp,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_RenameComponentPropProp,
}
export const RenameVariableSetCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_renameVariableSet_deprecated,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_RenameVariableSetCommandParam,
}
export const RenameVariableCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_renameVariable_deprecated,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_RenameVariableCommandParam,
}
export const RenderCursorRecord  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_renderCursorRecord,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_RenderCursorRecord,
}
export const ReorderComponentPropDefs  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_reorderComponentPropDefs,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_cmdReorderComponentPropDefs,
}
export const ReorderSelectedComponentSetPropOptionsCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_reorderSelectedComponentSetPropOptions,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_reorderSelectedComponentSetPropOptions,
}
export const ReplaceSelectedInstanceMainCompWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_replaceSelectedInstanceMainComp,
    returnType: Wukong.DocumentProto.Ret_ReplaceSelectedInstanceMainComp,
    argumentType: Wukong.DocumentProto.BridgeProtoBaseNode,
}
export const ResetDefaultVariableModeBySetForPageCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_resetDefaultVariableModeBySetForPage,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_ResetDefaultVariableModeBySetCommandParam,
}
export const ResetDefaultVariableModeBySetForSelectionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_resetDefaultVariableModeBySetForSelection,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_ResetDefaultVariableModeBySetCommandParam,
}
export const ResetGeometryCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_resetGeometry,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoBoolean,
}
export const ResetGpuStateCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_resetGpuState,
    returnType: Void,
    argumentType: Void,
}
export const ResetSelectionAndHistoryVersionIdCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_resetSelectionAndHistoryVersionId,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Args_ResetSelectionAndHistoryVersionIdCommand,
}
export const ResetWasmStatusCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_resetWasmStatus,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Args_ResetWasmStatusCommand,
}
export const ResizeCanvasCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_resizeCanvas,
    returnType: Void,
    argumentType: Wukong.DocumentProto.WCanvasResized,
}
export const RestartPresentFlowCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_restartPresentFlow,
    returnType: Void,
    argumentType: Void,
}
export const RestartPresentFlowFromFirstOneCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_restartPresentFlowFromFirstOne,
    returnType: Void,
    argumentType: Void,
}
export const RestoreGpu  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_restoreGpu,
    returnType: Void,
    argumentType: Void,
}
export const RGBAtoCode  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_rgbaToCode,
    returnType: Wukong.DocumentProto.Ret_RGBAtoCode,
    argumentType: Wukong.DocumentProto.Arg_RGBAtoCode,
}
export const RotateCopyPreviewCommandForJs  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_rotateCopyPreview,
    returnType: Void,
    argumentType: Wukong.DocumentProto.PluginRotateCopyData,
}
export const RotateCopyStartPreviewCommandForJs  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_rotateCopyStartPreview,
    returnType: Void,
    argumentType: Wukong.DocumentProto.PluginRotateCopyData,
}
export const RunPluginScriptCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_runPluginScript,
    returnType: Wukong.DocumentProto.Ret_evalJsScript,
    argumentType: Wukong.DocumentProto.Arg_evalJsScript,
}
export const PluginApiSaveVersionHistoryCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_saveVersionHistoryAsync,
    returnType: Wukong.DocumentProto.ReturnTypeWithError,
    argumentType: Wukong.DocumentProto.Arg_saveVersionHistory,
}
export const PluginApiSaveVersionHistoryCallbackCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_saveVersionHistoryAsyncCallback,
    returnType: Wukong.DocumentProto.ReturnTypeWithError,
    argumentType: Wukong.DocumentProto.Arg_saveVersionHistoryCallback,
}
export const PluginApiScrollAndZoomIntoView  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_scrollAndZoomIntoView,
    returnType: Wukong.DocumentProto.ReturnTypeWithError,
    argumentType: Wukong.DocumentProto.Arg_ScrollAndZoomIntoView,
}
export const SearchLocateNodeWithinMotiffScopeWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_searchLocateNodeWithinMotiffScope,
    returnType: Wukong.DocumentProto.BridgeProtoBoolean,
    argumentType: Wukong.DocumentProto.BridgeProtoIdRef,
}
export const SelectConflictVariantsCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_selectConflictVariants,
    returnType: Void,
    argumentType: Wukong.DocumentProto.CompSetPropConflict,
}
export const SelectNodesBySpecificVariantValueWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_selectNodesBySpecificVariantValueWasmCall,
    returnType: Void,
    argumentType: Wukong.DocumentProto.HoverOrSelectNodesBySpecificVariantValueParam,
}
export const SelectPresentScalingForJS  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_selectPresentScaling,
    returnType: Void,
    argumentType: Wukong.DocumentProto.PrototypePresentScalingParam,
}
export const SelectionAttachMinMaxWidthHeightFloatVarCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_selectionAttachMinMaxWidthHeightFloatVar,
    returnType: Void,
    argumentType: Wukong.DocumentProto.SelectionAttachMinMaxWidthHeightFloatVarParams,
}
export const SelectionAttachWidthHeightFloatVarCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_selectionAttachWidthHeightFloatVar,
    returnType: Void,
    argumentType: Wukong.DocumentProto.SelectionAttachWidthHeightFloatVarParams,
}
export const SelectionDetachMinMaxWidthHeightFloatVarCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_selectionDetachMinMaxWidthHeightFloatVar,
    returnType: Void,
    argumentType: Wukong.DocumentProto.SelectionDetachMinMaxWidthHeightFloatVarParams,
}
export const SelectionDetachWidthHeightFloatVarCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_selectionDetachWidthHeightFloatVar,
    returnType: Void,
    argumentType: Wukong.DocumentProto.SelectionDetachWidthHeightFloatVarParams,
}
export const SetActivedCommentIdCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_setActivedCommentId,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const SetAliasForVariableCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_setAliasForVariable,
    returnType: Wukong.DocumentProto.Ret_SetAliasForVariableCommand,
    argumentType: Wukong.DocumentProto.Arg_SetAliasForVariableCommandParam,
}
export const SetAutoLayoutAdvancePanelCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_setAutoLayoutAdvancePanel,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoBoolean,
}
export const UpdateSelectedAutoLayoutWHValueWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_setAutoLayoutWH,
    returnType: Void,
    argumentType: Wukong.DocumentProto.SetSelectedAutoLayoutWHParam,
}
export const SetBigNudgeAmountCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_setBigNudgeAmount,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoFloat,
}
export const SetDevicePixelRatioCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_setDevicePixelRatio,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoFloat,
}
export const SetDocumentWatchpointsCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_setDocumentWatchpoints,
    returnType: Void,
    argumentType: Wukong.DocumentProto.DocumentWatchpoints,
}
export const SetIndependentStrokeTypeForSelectionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_setIndependentStrokeTypeForSelection,
    returnType: Void,
    argumentType: Wukong.DocumentProto.SetIndependentStrokeTypeForSelectionCommandParams,
}
export const SetMirrorViewportScalingCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_setMirrorViewportScaling,
    returnType: Void,
    argumentType: Wukong.DocumentProto.MirrorViewportScalingParam,
}
export const SetNotificationMovedComponentStyleNodeIdsCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_setNotificationMovedComponentStyleNodeIds,
    returnType: Void,
    argumentType: Wukong.DocumentProto.NotificationMovedComponentStyleNodeIds,
}
export const SetReleaseVersion  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_setReleaseVersion,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const SetSmallNudgeAmountCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_setSmallNudgeAmount,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoFloat,
}
export const SetStrokeWeightForSelectionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_setStrokeWeightForSelection,
    returnType: Void,
    argumentType: Wukong.DocumentProto.SetStrokeWeightForSelectionCommandParams,
}
export const SetTextValueForSelectionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_setTextValueForSelected,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_cmdSetTextValue,
}
export const SetVariableModeBySetForPageCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_setVariableModeBySetForPage,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_SetVariableModeBySetCommandParam,
}
export const SetVariableModeBySetForSelectionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_setVariableModeBySetForSelection,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_SetVariableModeBySetCommandParam,
}
export const SetVariableSetModeNameCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_setVariableSetModeName_deprecated,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_SetVariableSetModeNameCommandParam,
}
export const SetViewportByAnimationKeyframeCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_setViewportByAnimationKeyframe,
    returnType: Void,
    argumentType: Wukong.DocumentProto.ViewportAnimationKeyframe,
}
export const PluginApiSetViewportCenter  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_setViewportCenter,
    returnType: Wukong.DocumentProto.ReturnTypeWithError,
    argumentType: Wukong.DocumentProto.Arg_SetViewportCenter,
}
export const PluginApiSetViewportZoom  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_setViewportZoom,
    returnType: Wukong.DocumentProto.ReturnTypeWithError,
    argumentType: Wukong.DocumentProto.Arg_SetViewportZoom,
}
export const SetComponentConfigPopupWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_setomponentConfigPopup,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoBoolean,
}
export const ShowMarkInfoWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_showMarkInfo,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_AiMarkInfo,
}
export const SkewSelectedNodesCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_skewSelectedNodes,
    returnType: Wukong.DocumentProto.Ret_SkewSelectedNodes,
    argumentType: Wukong.DocumentProto.Arg_SkewSelectedNodes,
}
export const StartEditingPrototypeInteraction  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_startEditingPrototypeInteraction,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_StartEditingPrototypeInteraction,
}
export const StartHoveredHyperlinkEditing  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_startHoveredHyperlinkEditing,
    returnType: Void,
    argumentType: Void,
}
export const StartHyperlinkEditing  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_startHyperlinkEditing,
    returnType: Void,
    argumentType: Void,
}
export const StartLibraryPublishModalPublishCheckedWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_startLibraryPublishModalPublishChecked,
    returnType: Wukong.DocumentProto.Arg_publishLibraryManifest,
    argumentType: Wukong.DocumentProto.Arg_cmdLibraryPublishModalPublishChecked,
}
export const StartMovingCommentCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_startMovingComment,
    returnType: Void,
    argumentType: Wukong.DocumentProto.CommentStartMovingParam,
}
export const StartRecordingAllocationJsStack  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_startRecordingAllocationJsStack,
    returnType: Void,
    argumentType: Void,
}
export const StopRecordingAllocationJsStack  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_stopRecordingAllocationJsStack,
    returnType: Void,
    argumentType: Void,
}
export const SubmitDraftCommentCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_submitDraftComment,
    returnType: Void,
    argumentType: Void,
}
export const SwitchEditorTypeCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_switchEditorType,
    returnType: Void,
    argumentType: Void,
}
export const SwitchPrototypeHotspotHintCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_switchPrototypeHotspotHint,
    returnType: Void,
    argumentType: Void,
}
export const SwitchShowDeviceCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_switchShowDevice,
    returnType: Void,
    argumentType: Void,
}
export const SyncCommentsMetaCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_syncCommentsMeta,
    returnType: Void,
    argumentType: Wukong.DocumentProto.CommentsMetaSyncParam,
}
export const TabHiddenCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_tabHidden,
    returnType: Void,
    argumentType: Void,
}
export const TabVisibleCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_tabVisible,
    returnType: Void,
    argumentType: Void,
}
export const ToggleComponentPickerWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_toggleComponentPicker,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_toggleComponentPicker,
}
export const ToggleComponentPropPopupWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_toggleComponentPropPopup,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_toggleComponentPropPopup,
}
export const ToggleEnablePixelGridWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_toggleEnablePixelGrid,
    returnType: Void,
    argumentType: Void,
}
export const ToggleEnabledLayoutGridWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_toggleEnabledLayoutGrid,
    returnType: Void,
    argumentType: Void,
}
export const ToggleFullScreenCommandWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_toggleFullscreen,
    returnType: Void,
    argumentType: Void,
}
export const ToggleInverseZoomDirectionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_toggleInverseZoomDirection,
    returnType: Void,
    argumentType: Void,
}
export const ToggleReplaceInstancePanelWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_toggleReplaceInstancePanel,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoBoolean,
}
export const ToggleShowCommentCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_toggleShowComment,
    returnType: Void,
    argumentType: Void,
}
export const ToggleShowRulerWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_toggleShowRuler,
    returnType: Void,
    argumentType: Void,
}
export const ToggleShowShortcutPanelWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_toggleShowShortcutPanel,
    returnType: Void,
    argumentType: Void,
}
export const ToggleShowSidebarCommandForJs  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_toggleShowSidebar,
    returnType: Void,
    argumentType: Void,
}
export const ToggleShowUsedMemory  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_toggleShowUsedMemory,
    returnType: Void,
    argumentType: Void,
}
export const ToggleSnapToPixelWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_toggleSnapToPixel,
    returnType: Void,
    argumentType: Void,
}
export const ToggleSystemMetricCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_toggleSystemMetric,
    returnType: Wukong.DocumentProto.Ret_ToggleSystemMetric,
    argumentType: Wukong.DocumentProto.BridgeProtoBoolean,
}
export const ToggleTextCursorSpriteVisible  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_toggleTextCursorSpriteVisible,
    returnType: Void,
    argumentType: Void,
}
export const TriggerAllKeyUpBindingsCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_triggerAllKeyUpBindings,
    returnType: Void,
    argumentType: Void,
}
export const TriggerImagePaintPresenter  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_triggerImagePaintPresenter,
    returnType: Void,
    argumentType: Void,
}
export const TryHoverOffTargetCommentCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_tryHoverOffTargetComment,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const UngroupLocalStyleSelectionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_ungroupLocalStyleSelection,
    returnType: Void,
    argumentType: Void,
}
export const UngroupSelectionForJSCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_ungroupSelection,
    returnType: Void,
    argumentType: Void,
}
export const UpdateActivatedPluginCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updateActivatedPluginType,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Args_UpdateActivatedPluginCommand,
}
export const UpdateBatchAutoLayoutCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updateAutoLayoutBatch,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BatchAutoLayoutUpdatedParam,
}
export const UpdateBatchAutoLayoutChildCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updateAutoLayoutChildBatch,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BatchAutoLayoutChildUpdatedParam,
}
export const UpdateColorVariableValueCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updateColorVariableValue,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_UpdateColorVariableValueCommandParam,
}
export const UpdateComponentDescWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updateComponentDesc,
    returnType: Void,
    argumentType: Wukong.DocumentProto.VComponentDescUnit,
}
export const UpdateCopilotCursorCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updateCopilotCursor,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Cursor,
}
export const UpdateDevModeInspectExportCommandWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updateDevModeInspectExportConfig,
    returnType: Void,
    argumentType: Wukong.DocumentProto.DevModeInspectExportUserConfig,
}
export const UpdateDocumentColorProfileCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updateDocumentColorProfile,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_UpdateDocumentColorProfile,
}
export const UpdateEffectStyleNodeDescriptionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updateEffectStyleNodeDescription,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateEffectStyleNodeDescriptionParam,
}
export const UpdateEffectStyleNodeEffectsCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updateEffectStyleNodeEffects,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateEffectStyleNodeEffectsParam,
}
export const UpdateEffectStyleNodeNameCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updateEffectStyleNodeName,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateEffectStyleNodeNameParam,
}
export const UpdateEffectsForSelectionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updateEffectsForSelection,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateEffectsParam,
}
export const UpdateFloatVariableValueCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updateFloatVariableValue,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_UpdateFloatVariableValueCommandParam,
}
export const UpdateFromCreatingFileCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updateFromCreatingFile,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoBoolean,
}
export const UpdateInstanceBubbledWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updateInstanceBubbled,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_UpdateInstanceBubble,
}
export const UpdateIsViewportZoomingCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updateIsViewportZooming,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoBoolean,
}
export const UpdateLocalStyleEditingNameCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updateLocalStyleEditingName,
    returnType: Void,
    argumentType: Wukong.DocumentProto.LocalStyleEditingName,
}
export const UpdateLocalStyleEditingStyleIdCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updateLocalStyleEditingStyleId,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const UpdateLocalStyleFolderNameCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updateLocalStyleFolderName,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateLocalStyleFolderNameCommandParam,
}
export const UpdateLocalStyleNameCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updateLocalStyleNodeName,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateLocalStyleNameCommandParam,
}
export const UpdateLocalStyleSelectionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updateLocalStyleSelection,
    returnType: Void,
    argumentType: Wukong.DocumentProto.LocalStyleSelection,
}
export const UpdateNodeValAboutCompPropInInstanceWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updateNodeValAboutCompPropInInstance,
    returnType: Wukong.DocumentProto.BridgeProtoBoolean,
    argumentType: Wukong.DocumentProto.Arg_updateNodeValAboutCompPropInInstance,
}
export const UpdatePaintStyleNodeDescriptionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updatePaintStyleNodeDescription,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdatePaintStyleNodeDescriptionParam,
}
export const UpdatePaintStyleNodeNameCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updatePaintStyleNodeName,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdatePaintStyleNodeNameParam,
}
export const UpdatePaintStyleNodePaintsCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updatePaintStyleNodePaints,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdatePaintStyleNodePaintsParam,
}
export const UpdateImagePreivewHash  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updatePreviewHash,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdatePreviewHashParam,
}
export const UpdatePrototypeBackgroundColorCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updatePrototypeBackgroundColor,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_cmdUpdatePrototypeBackgroundColor,
}
export const UpdatePrototypeDeviceCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updatePrototypeDevice,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_UpdatePrototypeDevice,
}
export const UpdatePrototypeFlowOrderIndexCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updatePrototypeFlowOrderIndex,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_cmdUpdatePrototypeFlowOrderIndex,
}
export const UpdatePrototypingVisibleCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updatePrototypingVisible,
    returnType: Void,
    argumentType: Void,
}
export const UpdateScrollBehavior  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updateScrollBehavior,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_UpdateScrollBehavior,
}
export const UpdateScrollOverflow  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updateScrollOverflow,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_UpdateScrollOverflow,
}
export const UpdateSelectedComponentNameWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updateSelectedComponentName,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_updateSelectedComponentName,
}
export const UpdateSelectedComponentSetPropsWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updateSelectedComponentSetProps,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_UpdateComponentSetProps,
}
export const UpdateSelectedVariantsPropsCommandWasmCall  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updateSelectedVariantsProps,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_UpdateVariantProps,
}
export const UpdateSelectionPrototypeInteraction  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updateSelectionPrototypeInteraction,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_UpdateSelectionPrototypeInteraction,
}
export const ShowSliceCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updateShowSlice,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoBoolean,
}
export const ExpandSpecificSimplifyInstance  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updateSimplifyInstanceIdsSeeingAllLayers,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoIdRef,
}
export const UpdateSimplifyInstancePanels  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updateSimplifyInstancePanels,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoBoolean,
}
export const UpdateTextSettingTabKeyCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updateTextSettingTabKey,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateTextSettingTabKeyCommandParam,
}
export const UpdateTextStyleNodeDescriptionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updateTextStyleNodeDescription,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateTextStyleNodeDescriptionParam,
}
export const UpdateTextStyleNodeDetachOpsizeFromFontSizeCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updateTextStyleNodeDetachOpsizeFromFontSizeCommand,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateTextStyleNodeDetachOpsizeFromFontSizeParam,
}
export const UpdateTextStyleNodeFontFamilyCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updateTextStyleNodeFontFamily,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateTextStyleNodeFontFamilyParam,
}
export const UpdateTextStyleNodeFontNameCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updateTextStyleNodeFontName,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateTextStyleNodeFontNameParam,
}
export const UpdateTextStyleNodeFontSizeCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updateTextStyleNodeFontSize,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateTextStyleNodeFontSizeParam,
}
export const UpdateTextStyleNodeFontVariationCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updateTextStyleNodeFontVariationCommand,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateTextStyleNodeFontVariationParam,
}
export const UpdateTextStyleNodeLetterSpacingCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updateTextStyleNodeLetterSpacing,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateTextStyleNodeLetterSpacingParam,
}
export const UpdateTextStyleNodeLineHeightCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updateTextStyleNodeLineHeight,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateTextStyleNodeLineHeightParam,
}
export const UpdateTextStyleNodeNameCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updateTextStyleNodeName,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateTextStyleNodeNameParam,
}
export const UpdateTextStyleNodeParagraphSpacingCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updateTextStyleNodeParagraphSpacing,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateTextStyleNodeParagraphSpacingParam,
}
export const UpdateTextStyleNodeTextDecorationCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updateTextStyleNodeTextDecoration,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UpdateTextStyleNodeTextDecorationParam,
}
export const UpdateUserPlanStatus  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updateUserPlanStatus,
    returnType: Void,
    argumentType: Wukong.DocumentProto.PaymentUserPlanStatus,
}
export const UpdateVariableCodeSyntaxCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updateVariableCodeSyntax,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_UpdateVariableCodeSyntaxCommandParam,
}
export const UpdateVariableDescriptionCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updateVariableDescription,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_UpdateVariableDescriptionCommandParam,
}
export const UpdateVariantPropForPublicInstance  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_updateVariantPropForPublicInstance,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_UpdateVariantPropsForPublicInstance,
}
export const UploadTextureForMotiffLibCommand  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_uploadTextureForMotiffLib,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_UploadTextureForMotiffLib,
}
export const ValidateInstanceSwapValue  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_validateInstanceSwapValue,
    returnType: Wukong.DocumentProto.BridgeProtoBoolean,
    argumentType: Wukong.DocumentProto.Arg_validateInstanceSwapValue,
}
export const WindowEventTrace  = {
    code: Wukong.DocumentProto.WasmCallCode.WCC_windowEventTrace,
    returnType: Void,
    argumentType: Wukong.DocumentProto.WindowEventTraceParam,
}

export const WasmCallSignatures = [
LocalVariableEditor_AbortRenameCollectionCommand,
LocalVariableEditor_AbortRenameVariableCommand,
LocalVariableEditor_AddSelectedVariablesIntoGroupCommand,
LocalVariableEditor_ClearGroupSelectionCommand,
LocalVariableEditor_ClearVariableSelectionCommand,
LocalVariableEditor_CloseVariableEditingPopupCommand,
LocalVariableEditor_CollapseGroupCommand,
LocalVariableEditor_CreateCollectionCommand,
LocalVariableEditor_CreateCollectionModeCommand,
LocalVariableEditor_CreateVariableCommand,
LocalVariableEditor_DeleteCollectionCommand,
LocalVariableEditor_DeleteCollectionModeCommand,
LocalVariableEditor_DeleteSelectedGroupsCommand,
LocalVariableEditor_DeleteSelectedVariablesCommand,
LocalVariableEditor_DirectRenameVariableCommand,
LocalVariableEditor_DragGroupCommand,
LocalVariableEditor_DragSelectedVariablesCommand,
LocalVariableEditor_DragSelectedVariablesIntoGroupCommand,
LocalVariableEditor_DuplicateCollectionModeCommand,
LocalVariableEditor_DuplicateSelectedGroupsCommand,
LocalVariableEditor_DuplicateSelectedVariablesCommand,
LocalVariableEditor_ExpandGroupCommand,
LocalVariableEditor_ForceSelectGroupCommand,
LocalVariableEditor_ForceSelectVariableCommand,
LocalVariableEditor_GetSelectionScrollIndexInTableCommand,
LocalVariableEditor_MultiSelectGroupCommand,
LocalVariableEditor_MultiSelectVariableCommand,
LocalVariableEditor_RangeSelectGroupCommand,
LocalVariableEditor_RangeSelectVariableCommand,
LocalVariableEditor_RenameCollectionModeCommand,
LocalVariableEditor_RenameGroupCommand,
LocalVariableEditor_SafeSelectGroupCommand,
LocalVariableEditor_SafeSelectVariableCommand,
LocalVariableEditor_SelectCollectionCommand,
LocalVariableEditor_StartRenameCollectionCommand,
LocalVariableEditor_StartRenameVariableCommand,
LocalVariableEditor_SubmitRenameCollectionCommand,
LocalVariableEditor_SubmitRenameVariableCommand,
LocalVariableEditor_TogglePanelCommand,
LocalVariableEditor_ToggleVariableDetailPopupCommand,
LocalVariableEditor_ToggleVariableValuePopupCommand,
LocalVariableEditor_UngroupSelectedGroupsCommand,
MoveSelectionNodesFrontOrBackWasmCall,
NodePreviewSubscribeCommand,
NodePreviewUnsubscribeCommand,
PluginApiExclude,
PluginApiGetProps,
PluginApiIntersect,
PluginApiSetProps,
PluginApiSwapMainComponent,
PluginApiUnion,
PrototypeDelayedInteractionsCallback,
AddAutoLayoutCommand,
AddSelectionPrototypeInteraction,
AddVariableSetModeCommand,
AddVariantForSelectedComponentWasmCall,
AnimateHotspotHintForJs,
ApplyAIGenCommand,
ApplyAISearchInsertNodeCommand,
ApplyAiCandidate,
ApplyChatBotAIGenCommand,
ApplyEffectStyleForSelectionCommand,
ApplyHtmlAIGenCommand,
BackwardPresentFrameCommand,
BatchAttachAutoLayoutVarCommand,
BatchComponentSelectionCommand,
BatchDetachAutoLayoutVarCommand,
BatchUpdateVariablePublishHiddenCommand,
BatchUpdateVariableScopesCommand,
BeginPreviewCommand,
BoolSelectionNodesCommand,
BuildLibraryComponentUpdateVOWasmCall,
BuildLibraryStyleUpdateVOWasmCall,
BuildLibraryVariableSetUpdateVOWasmCall,
BuildLibraryVariableUpdateVOWasmCall,
CanOpenPresentCommand,
CancelHyperlinkEditing,
ChangeEditorModeCommandForDevMode,
SelectPresentFlowCommand,
ChangePreviewFrameIdCommand,
CheckCanvasRightMenuOpenable,
CheckContextLostCommand,
CheckShowRestoreComponentWarningCommand,
CheckTextStyleNodeMissFontInfoCommand,
CleanAiCopilotPreviewNode,
ClearAutoLayoutFloatInputForJs,
ClearHoverPopupHyperlink,
ClickCommentClusterCommand,
ClickOnCommentCommand,
ClickOnDraftCommentCommand,
AIDesignLintDeDuplicateNodeIdsCommand,
AIDesignLintPaintStyleCommand,
AIDesignLintTextStyleCommand,
AIDesignLintUpdateAndZoomToSelectionCommand,
AIDesignLintUpdatePaintStyleIdCommand,
AIDesignLintUpdateTextStyleIdCommand,
AIReplacePreviewGenerateDataCommand,
AIReplacePreviewSnapshotCommand,
AddExportSettingsToSelectionCommand,
AddImageSourceCommand,
AddLayoutGridForSelectionCommandForWasm,
AddPrototypeStartingPointCommand,
AddStrokePaintsForSelectionCommandForWasm,
AddStrokeStyleIdForSelectionCommandForWasm,
AeExportCommand,
AeRasterizationCommand,
AiAlignCommand,
AiCreateAutoLayoutCommand,
AiLibraryMaintainCommand,
AimLibraryNodeWasmCall,
AppendInuseOtherDocStyleNodes2StyleContainerCommand,
ApplyAIComponentReplaceCommand,
ApplyAIComponentRecognizeResultCommand,
ApplyAIRecognizeResultCommand,
ApplyAiDuplicateCommand,
ApplyArcTextToSelectionCommand,
ApplyComponentPropRefForSelectedCommand,
ApplyCreateStyle,
ApplyPickedColorCommand,
ArrangeAlignCommand,
ArrangeDistributeCommand,
ArrangePackCommand,
RoundToPixelCommand,
AttachCornerRadiusFloatVarCommand,
AttachStrokeWeightFloatVarCommand,
AutoLayoutMoveChildren,
BatchUpdateArcStartingAngleCommand,
BatchUpdateArcSweepCommand,
BatchUpdateMinSizeAndMaxSizeWasmCall,
BatchUpdateBaseAttributeCommand,
BatchUpdateBlendModeCommand,
BatchUpdateInnerRadiusCommand,
BatchUpdateNodesKeyCommand,
BatchUpdateOpacityCommand,
BatchUpdatePointCountCommand,
BatchUpdateVisibleCommand,
BatchUpgradePublishMixinsVersionV3Command,
BuildAIComponentDataCommand,
BuildAIReplaceNodesDataCommand,
BuildAISelectedStyleNodeDataCommand,
CancelCreateStyle,
CancelPlacingImageCommandWasmCall,
AICandidateComponentCancelReplaceCommand,
AICandidateComponentConvertCommand,
AICandidateComponentPublishCommand,
AICandidateComponentReplaceCommand,
CanvasClipboardCopyCommand,
CanvasClipboardCopyPropertiesCommand,
CanvasClipboardCopySelectionAsLinkCommand,
CanvasClipboardCopySelectionAsPNGCommand,
CanvasClipboardCopySelectionAsSVGCommand,
CanvasClipboardCopySelectionAsTextCommand,
CanvasClipboardCutCommand,
CanvasClipboardPasteCommand,
CanvasClipboardPasteHereCommand,
CanvasClipboardPasteOverSelectionCommand,
CanvasClipboardPastePropertiesCommand,
CanvasClipboardPasteToReplaceCommand,
CanvasSearchDeleteSelectedCommand,
CanvasSearchEnterTempMultiSelectionMode,
CanvasSearchLayerItemClickCommand,
CanvasSearchLayerItemCommandClickCommand,
CanvasSearchLayerItemEnterHoverCommand,
CanvasSearchLayerItemLeaveHoverCommand,
CanvasSearchLayerItemOnEnter,
CanvasSearchLayerItemOnShiftEnter,
CanvasSearchLayerItemShiftClickCommand,
CanvasSearchLeaveTempMultiSelectionMode,
CanvasSearchNextLayerItem,
CanvasSearchPageItemClickCommand,
CanvasSearchPrevLayerItem,
CanvasSearchReplaceAllCommand,
CanvasSearchReplaceSelectedCommand,
CanvasSearchResultScrollToLayerItemCommand,
CanvasSearchSelectAllCommand,
CanvasSearchTogglePageExpand,
ChangeEditorModeCommandForJs,
ChangeLibraryMainModalRoutePathCommand,
ChangeLibraryPublishModalControlExpandCommand,
ChangeOrderedTextListCommand,
ChangePlainTextCommand,
ChangeScaleToolPanelCommand,
ChangeSmartSelectionHorizontalCommand,
ChangeSmartSelectionVerticalCommand,
ChangeTextSearchCommand,
ChangeUnorderedTextListCommand,
CleanAIRecognizeDataCommand,
ClearHoverProtoTypeInteractionWasmCall,
ClearPrototypeStartingPointEditingStateCommand,
ClearSelectedReversedIndexesCommandForJs,
CloseBackToInstanceToastCommand,
CloseLibraryPublishModalCommand,
CloseLibraryReplaceModalWasmCall,
CloseMultipleRenameModalCommand,
CommitUndo,
CommitUndoOrSquash,
CreateAndApplyComponentPropForSelectedCommand,
CreateComponentPropDefineForSelectedCommand,
CreateFrameTemplateCommand,
CreateLayoutGridStyleNode,
CreatePageCommandWasmCall,
ChangeCurrentPageBackgroundCommandWasmCall,
CurrentPageSetSelectionCommandWasmCall,
DecreaseSelectionFontSizeCommandForWasm,
DecreaseSelectionFontWeightCommand,
DecreaseSelectionLetterSpacingCommandForWasm,
DecreaseSelectionLineHeightCommandForWasm,
DeleteComponentPropDefinitionForSelectedCommand,
DeleteExportSettingsToSelectionCommand,
DeletePageCommandWasmCall,
DeletePrototypeStartingPointByIdCommand,
DeletePrototypeStartingPointsCommand,
DeleteSelectionFillStyleIdCommandWasmCall,
DeleteStyleNodeCommand,
DeleteTextStyleNode,
DetachComponentPropRefCommand,
DetachCornerRadiusFloatVarCommand,
DetachSelectionTextNodeHyperlinkCommand,
DetachLayoutGridStyleForSelectionCommand,
DetachSelectedInstanceRecurCommand,
DetachSelectionFillStyleIdCommandWasmCall,
DetachSelectionPaintStyleIdCommandForEnterCrop,
DetachStrokeWeightFloatVarCommand,
DetachSelectionTextStyleIdCommand,
DevModeEnterRightClickSelectionCommand,
DevModeLayerPanelCollapseAllChildrenWasmCall,
DevModeLayerPanelCollapseAllNodesWasmCall,
DevModeLayerPanelCommandClickWasmCall,
DevModeLayerPanelExpandAllChildrenWasmCall,
DevModeLayerPanelLeftClickWasmCall,
DevModeLayerPanelMoveToSelectedNode,
DevModeLayerPanelRightClickWasmCall,
DevModeLayerPanelShiftClickWasmCall,
DevModeLeaveRightClickSelectionCommand,
DevModeToggleIconAutoDetection,
DevModeZoomToFitSelectionWasmCall,
DismissComponentMoveNotificationCommand,
DismissLibraryUpgradeNotificationCommandWasmCall,
DragLocalSelectionStyleCommand,
LayerPanelDragSelectionToNodeIndentCommand,
DropComponentV2Command,
DropImageCommand,
DropImageToCenterCommand,
DuplicatePageCommand,
DuplicateSelection,
EditComponentPropWasmCall,
EditImageScaleModeWasmCall,
EnableAIPoweredCommand,
EndEditingGradientWasmCall,
EndEditingImageWasmCall,
EndLibraryPublishModalPublishCheckedWasmCall,
EnterAILayoutCommand,
ToggleEditingVectorStateCommandForJs,
ExitAILayoutCommand,
ExitCanvasSearchCommand,
ExpandSelectionIndependentCornerRadiusCommand,
ExportCanvasPngToBase64,
ExportCanvasPngToDataWasmCall,
ExportEachAsPDFPage,
ExportImagePixels,
ExportImageToBinaryData,
ExportImageToCanvas,
ExportToPDF,
ExportPngToData,
ExportToSvg,
FillNodesWithImagesCommand,
FilterPageNodesCommand,
FindClosestComponentByVariantCommand,
FlattenCommand,
FlipHorizontalCommand,
FlipVerticalCommand,
GetMarkInfoCommand,
BatchExportConfigsCommand,
GetClipboardStyleNodeCountCommand,
GetContainerIdByIndentCommand,
GetImageDownloadSuccessRatioCommand,
GetLatestPopupStateCommand,
GetPagesCountCommand,
GetPaintStyleNodeInfoByNodeIdCommand,
GetSearchedSortedListCommand,
GetSelectionNodeIdsCommandForWasm,
GetSingleMissLibraryContentCommand,
GetTextStyleNodeInfoByNodeIdCommand,
GetTopFrameWhichSortByArrange,
GoToSelectedInstanceMainComponentCommand,
HandleNeverMovableNodesCommand,
IncreaseSelectionFontSizeCommandForWasm,
IncreaseSelectionFontWeightCommand,
IncreaseSelectionLetterSpacingCommandForWasm,
IncreaseSelectionLineHeightCommandForWasm,
InspectMissingFontsCommand,
LayerPanelCancelEditingWasmCall,
LayerPanelCancelHoverCommandWasmCall,
LayerPanelClickBlankCommandWasmCall,
LayerPanelCollapseWasmCall,
LayerPanelCommandClickCommand,
LayerPanelEnterAreaCommandWasmCall,
LayerPanelEnterEditingCommandWasmCall,
LayerPanelEnterHoverCommandWasmCall,
LayerPanelExpandClickCommand,
LayerPanelLeftClickCommandWasmCall,
LayerPanelOptionExpandClickCommand,
LayerPanelRenameCommandWasmCall,
LayerPanelRightClickCommandWasmCall,
LayerPanelShiftClickCommand,
LayerPanelShiftTabRenameCommand,
LayerPanelTabRenameCommand,
LayerPanelUpdateLockCommand,
LayerPanelUpdateVisibleCommand,
LibraryPublishModalPublishCheckedCommand,
LocateNodeInDocumentForDebugOnly,
MoveNodesToOtherPage,
MoveSelectionForwardOrBackwardWasmCall,
MoveViewportCommand,
MoveViewportWhenOutsideCanvasCommandForJs,
MultipleRenameCommand,
OnClickBackToInstanceToastCommand,
OpenLibraryDetailPageCommand,
OpenLibraryPublishModalWasmCall,
OpenLibraryReplaceModalWasmCall,
OutlineStrokeWasmCall,
PageCancelEditing,
PageDownCommandWasmCall,
PageEnterEditing,
PageUpCommandWasmCall,
PasteProtoAsNode,
PlaceAllImagesCommand,
PlaceImageCommand,
PreExportCommand,
PrepareExportImageCommand,
PrintCurrentTreeRenderObject,
PrototypeInteractionTargetCancelHover,
PrototypeInteractionTargetEnterHover,
UpdatePublishHiddenCommand,
PublishMovedComponentNodesCommand,
PublishSelectedNodesCommand,
PushSelectedInstanceChangesToMainComponentCommand,
ReGenerateSnapshotCommand,
RecoverComponentCommand,
RegenerateContentHashCommand,
RemoveFillForSelectedNodesWasmCall,
RemoveLayoutGridStyleForSelectionCommand,
RemoveSelectedNodesCommand,
RemoveStrokeForSelectedNodesWasmCall,
RenameWasmCall,
RenderCursorCommand,
TextReplaceFontsCommand,
ReplaceLibraryItemCommand,
ResetComponentPropOnInstanceCommand,
ResetLibraryAssetPanelExpandKeysCommand,
ResetSelectedInstancesByTypeCommand,
ResetSelectionNodeInInstanceCommand,
ResizeViewportCommand,
RewritePublishPropertiesCommand,
RotateCopyEndPreviewCommandForJs,
SaveExitAILayoutCommand,
ScaleViewportByWheelWasmCall,
ScrollSelectionIntoView,
SearchReplaceInstanceWasmCall,
SelectDisplayPanelWasmCall,
SelectInverseCommand,
SelectSidebarPanelCommand,
SelectionAllNodesCommand,
SelectionColorsDetachStyleWasmCall,
SelectionColorsDetachVariableWasmCall,
SelectionColorsInspectColorWasmCall,
SelectionColorsInspectStyleWasmCall,
SelectionColorsReplaceColorWasmCall,
SelectionColorsReplaceColorWithStyleWasmCall,
SelectionColorsReplaceStyleWasmCall,
SelectionColorsReplaceStyleWithColorWasmCall,
SetComponentThumbnailDraggingCommand,
SetCurrentPageWasmCall,
SetCurrentPageInHistoryWasmCommand,
SetCurrentUserActiveStateWasmCall,
SetDevModeDisplayPanelsWasmCall,
SetDisplayPanelsWasmCall,
SetHoverProtoTypeInteractionWasmCall,
SetMultipleRenameParamsWasmCall,
SetOperateSystemWasmCall,
SetPrototypeAnimationEasingFunctionEditHistory,
SetPrototypeAnimationEasingTypeEditHistory,
SetPrototypeAnimationTransitionDurationEditHistory,
SetPrototypeAnimationTransitionShouldSmartAnimateEditHistory,
SetPrototypeAnimationTransitionTypeEditHistory,
SetPrototypeTransitionResetScrollPositionEditHistory,
SetSelectedReveredIndexesWasmCall,
SetStrokeCapForSelectionWasmCall,
SetThumbnailIdWasmCall,
SetViewportCommandForJs,
ShouldMoveViewportWhenOutsideCanvasCommand,
ShowStartEditingHandlerForPaintStyleCommand,
StartCanvasSearchAndReplaceCommand,
StartCanvasSearchCommand,
StartEditingGradientCommand,
StartEditingImageCommand,
StartLibraryReplaceWasmCall,
SwapFillStrokeForSelectedNodes,
SwitchDeliveryUnitByIndex,
SwitchPrototypePresentDisableDefaultKeyboardNavCommand,
SwitchPrototypePresentHideUICommand,
SwitchPrototypePresentShowProtoSidebar,
SwitchStrokeEndPointCapCommand,
SyncDocumentColorProfileMetaCommand,
TextEndEditing,
TextInputCommand,
TextKeyboardOperationCommand,
UpdateSelectionTextNodeAutoResizeCommand,
UpdateSelectionTextNodeParagraphIndentCommand,
UpdateSelectionTextNodeParagraphSpacingCommand,
UpdateSelectionTextNodeAlignHorizontalCommand,
UpdateSelectionTextNodeAlignVerticalCommand,
TidyUpCommand,
ToggleAiLayoutEntryMenuCommand,
ToggleLockedForSelectedNodesCommand,
ToggleMaskSelectionCommand,
ToggleMotiffScopeNodeExpandedWasmCall,
ToggleRightClickMenuCommand,
ToggleSelectionChildrenCommandForJs,
ToggleSelectionTextNodeDeleteLineCommand,
ToggleSelectionFontBoldCommand,
ToggleSelectionFontItalicCommand,
ToggleSelectionMaskOutline,
ToggleSelectionNodesCommand,
ToggleSelectionParentCommand,
ToggleSelectionTextNodeUnderLineCommand,
ToggleShowMultiplayerCursorWasmCall,
ToggleShownForSelectedNodesWasmCall,
TryExitScaleToolOnInputEnteredCommand,
UndoRedoCommand,
UnlockAILayoutCommand,
UpdateExportSettingsByIdsCommand,
UpdateAttrPanelEditorStateCommand,
UpdateBatchExportPopupStateCommandWasmCall,
UpdateCanvasSearchParamCommand,
UpdateCanvasSearchResultItemHeight,
UpdateCanvasSearchResultViewportHeight,
UpdateCanvasSearchResultViewportScrollTop,
UpdateCenterPointPositionCommandForJs,
UpdateCoactorObservingCommand,
UpdateColorModeCommand,
UpdateComponentPropSelectionStateWasmCall,
UpdateCursorWithinCanvasCommand,
UpdateSelectionTextNodeDetachOpsizeFromFontSizeCommand,
UpdateDevModeBatchExportPopupStateCommandWasmCall,
UpdateDevModeCodeConfigCommand,
UpdateDevModeExportCompressCommand,
UpdateDevModeInspectCodeTypeCommand,
UpdateDevModeInspectTypeCommand,
UpdateDocReadonlyCommand,
UpdateExportSettingsToSelectionCommand,
UpdateSelectionTextNodeFontFamilyCommand,
UpdateSelectionTextNodeFontNameCommand,
UpdateSelectionTextNodeFontSizeCommand,
UpdateSelectionTextNodeFontVariationCommand,
UpdateFrameTemplateCommand,
UpdateFreezeSelectionPaintCommand,
UpdateSelectionTextNodeHyperlinkCommand,
UpdateInfiniteSelectionPaintWasmCall,
UpdateInspectCodeTypeCommand,
InspectStateCommand,
UpdateLayerPanelClientHeightCommand,
UpdateLayerPanelScrollTopWasmCall,
UpdateLayotuGridStyleForSelectionCommandForWasm,
UpdateLayoutGridStyleLayoutGridsCommand,
UpdateLayoutGridStyleDescriptionCommand,
UpdateLayoutGridStyleNameCommand,
UpdateLayoutGridsCommand,
UpdateSelectionTextNodeLetterSpacingCommand,
UpdateLibraryAssetPanelDisplayModeCommand,
UpdateLibraryAssetPanelFilterTextCommand,
UpdateLibraryAssetPanelScrollTopCommand,
UpdateLibraryAssetPanelScrollableSizeCommand,
UpdateLibraryContentVOCommand,
UpdateLibraryPublishModalAllChangesCheckedWasmCall,
UpdateLibraryPublishModalChangeCheckedCommand,
UpdateLibraryPublishModalDescriptionCommand,
UpdateLibraryPublishModalFilterTextCommand,
UpdateLibraryPublishModalScrollTopCommand,
UpdateLibraryPublishModalScrollableSizeCommand,
UpdateLibraryReplaceModalCheckedWasmCall,
UpdateLibraryReplaceModalScrollTopCommand,
UpdateLibraryReplaceModalScrollableSizeCommand,
UpdateLibraryReplaceModalTargetLibraryWasmCall,
UpdateSelectionTextNodeLineHeightCommand,
UpdateMainComponent,
UpdateMainMenuVisibleCommand,
UpdateSelectionTextNodeMaxLinesCommand,
UpdateMotiffScopeSettingsWasmCall,
UpdateMouseCameraPositionCommand,
UpdateOthersRemoteLibraryChangesCommand,
UpdateOverlayBackgroundAppearance,
UpdateOverlayBackgroundInteraction,
UpdateOverlayPositionType,
UpdatePageName,
UpdatePopupStateCommand,
UpdatePrototypeSelectedInteractionKeys,
UpdatePrototypeStartingPointDescriptionCommand,
UpdatePrototypeStartingPointNameCommand,
UpdatePrototypeStartingPointNameByIdCommand,
UpdateSelectedGradientColorStopIndexCommand,
UpdateSelectedNodeDescriptionCommand,
UpdateSelectedVectorNetworkRegionCommand,
UpdateSelectedVectorNodeStyleOverrideTableCommand,
UpdateSelectionArcStartingAngleCommand,
UpdateSelectionArcSweepCommand,
UpdateSelectionBaseAttributeCommand,
UpdateSelectionClipsContentCommand,
UpdateSelectionConstrainProportionsCommand,
UpdateSelectionConstraintsCommand,
UpdateSelectionCornerRadiusCommand,
UpdateSelectionCornerSmoothingCommand,
UpdateSelectionFillPaintForSameOrAddCommand,
UpdateSelectionFillPaintsCommand,
UpdateSelectionFillPaintsForEmptyCommand,
UpdateSelectionFillStyleIdCommand,
UpdateSelectionFrameDirectionCommand,
UpdateSelectionIndependentCornerRadiusCommand,
UpdateSelectionInnerRadiusCommand,
UpdateSelectionPointCountCommand,
UpdateSelectionStrokeAlignCommand,
UpdateSelectionStrokeDashCapCommand,
UpdateSelectionStrokeDashGapCommand,
UpdateSelectionStrokeDashLenCommand,
UpdateSelectionStrokeJoinCommand,
UpdateSelectionStrokeMiterAngleCommand,
UpdateSelectionStrokeModeCommand,
UpdateSelectionStrokePaintsCommand,
UpdateSizeHoverMenuItemWasmCall,
UpdateSubscribedLibraryIdsCommand,
UpdateSelectionTextNodeTextCaseCommand,
UpdateSelectionTextNodeTextDecorationCommand,
UpdateSelectionTextStyleIdCommand,
UpdateSelectionTextNodeTextTruncationCommand,
UpdateTopExtendBoolMenuVisibleCommand,
UpdateTriggerFilterWithinMotiffScopeCommand,
UpdateUsableFontInfoCommandForJs,
UpdateUserPreferrence2,
UpdateVectorPanelCornerRadiusCommand,
updateVectorPanelHandleMirrorCommand,
updateVectorPanelXCommand,
updateVectorPanelYCommand,
UpdateWindowInnerHeightWasmCall,
ValidateAIReplaceSceneCommand,
ClearEditingVectorWasmCall,
ViewLibraryUpgradeNotificationCommand,
ViewNextFrameCommand,
ViewPrevFrameCommand,
ZoomAtViewportCenterWasmCall,
ZoomInByLevelAtViewportCenterWasmCall,
ZoomOutByLevelAtViewportCenterWasmCall,
ZoomToAllCommandForJs,
ZoomToFitNodesAtMaxScaleByIdsCommand,
ZoomToNextFrameCommand,
ZoomToPrevFrameCommand,
ZoomToSelectionWasmCall,
SetSelectionMaybeJumpPageCommand,
CommitSynergyStore,
ComponentSelectionCommand,
ComponentSetSelectionCommand,
CompoundAdjustLayoutWasmCall,
ConfirmTitleInputCommand,
ConvertCompSetIdToPrimaryCompId,
ConvertFrameToGroupCommand,
ConvertGroupToFrameWasmCall,
CopyCommentLinkCommand,
CreateAIGenBaseFrameCommand,
CreateColorVariableCommand,
CreateEffectStyleNodeCommand,
CreateEffectStylePreviewCommand,
CreateFloatVariableCommand,
CreateGradientThumbnailCommand,
CreateImageThumbnailCommand,
CreateNodeFromSvgCommand,
CreatePaintStyleNodeCommand,
CreatePaintStylePreviewCommand,
CreatePaintStyleThumbnailCommand,
CreateTextStyleNodeCommand,
CreateTextStylePreviewCommand,
CreateTextStyleThumbnailCommand,
CreateVariableSetCommand,
CreateVariantPropWasmCall,
DeactiveCommentCommand,
DebouncedMovingBoundsPositionHoldTimerCallback,
DebouncedShowAutoLayoutHandlerTimerCallback,
DebouncedShowGuidelineTimerCallback,
DelayCheckAllImagesLoadedCommand,
DeleteLocalStyleSelectionCommand,
DeleteVariableSetModeCommand,
DestroyEditorCommand,
DetachEffectStyleForSelectionCommand,
DetachSelectedInstanceWasmCall,
DevModeAvailabilityVerificationWasmCall,
DeleteAllExportSettingsToSelectionCommand,
DevModeExportChangeScaleCommand,
DevModeExportSelectFileTypeCommand,
DevModeExportSelectPresetCodeTypeCommand,
DevModeLayerPanelCancelHoverCommand,
DevModeLayerPanelEnterHoverCommand,
DevModeSelectColorsPanelEnterHoverCommand,
DisableSingleViewStateCommand,
DispatchKeyboardEventCommand,
DispatchMouseEventCommand,
DispatchWheelEventCommand,
DownloadAllFailedImageRetCommand,
DownloadImageRetCommand,
DuplicateVariableCommand,
cmdGetDocumentNodeId,
DocumentGetNodeCommand,
DocumentGetNodeAttrCommand,
EmojiTimerCallback,
EnableSingleViewStateCommand,
EndEditingPrototypeInteraction,
EndHyperlinkEditing,
EnterHistoryModeCommand,
ColorPickCommandWasmCall,
ExecuteRecoverToRemoteDefaultVariantCommand,
ExportComponentLibraryDataWasmCall,
ExportDocumentCommand,
ExportDocumentJsonCommand,
ExportFigJsonCommand,
ExportHTMLForChatBot,
ExportStyleLibraryDataWasmCall,
ExportSynergyDocument,
ExportToHTML,
ExportVariableCollectionLibraryDataWasmCall,
ExportVariableLibraryDataWasmCall,
ExtendSimplifyInstanceRightPanel,
FinishMovingCommentCommand,
ForceRecoverFigmaComponentForSelectedInstanceCommand,
ForceUpdateTextLayoutData,
ForwardPresentFrameCommand,
FrameResizeToFitWasmCall,
FrameNodesCommand,
GCTexturesCommand,
GenerateCandidateComponentThumbnailCommand,
GeneratePublishMixinThumbnailCommand,
GenerateNewVariableNameForCollectionAndTypeCommand,
GeneratePrototypePresentUrlOptionsForCopyFLowLink,
GeneratePrototypePresentUrlOptionsForOpenInEditor,
GenerateStyleCandidateComponentThumbnailCommand,
GetAIComponentIdMap,
GetAISearchNodeDataCommand,
GetAiCopilotInfoWasmCall,
GetAllLocalStyleAndComponentsCountCommand,
GetAndClearRenderDurationBuckets,
GetBestFullFontNameCommand,
GetComputedColorVariableCommand,
GetComputedFills,
GetComputedPropsReadCountAndRestCounterCommand,
GetComputedStrokes,
GetCoverImageCommand,
GetCurrentEditorTypeCommand,
GetCurrentPageClipboardData,
GetCurrentPageIdCommand,
GetCurrentSchemaVersionWasmCall,
GetDeviceOptionsCommand,
GetDocReadonlyCommand,
GetDocumentColorProfileCommand,
GetFocusViewCommand,
GetFullStyledTextSegments,
PluginApiGetImageByHash,
PluginApiGetImageBytesAsync,
GetImageOfFigNodeWasmCall,
PluginApiGetImageSizeAsync,
GetLastOperationIndex,
GetLineHeightCommand,
GetNotLoadedImageCountCommand,
GetOriginWasmStatusCommand,
GetPageWorldBoundsCommand,
GetProtoTypeCoverImageCommand,
GeneratePrototypePresentUrlOptionsForOpenPresent,
GetSingleViewStateValueCommand,
GetTangentCameraPositionCommand,
GetUndoStatus,
GetUsedMemoryCommand,
GetVRAM,
GetVertexCameraPositionCommand,
PluginApiGetViewport,
GroupLocalStyleSelectionCommand,
GroupSelectionWasmCall,
HideOtherLayersCommand,
HighlightNodesByIdsWasmCall,
HistoryModeReloadFailedResourcesCommand,
UpdateAutoLayoutHoverItemWasmCall,
HoverNodesBySpecificVariantValueWasmCall,
ImportNodeFromLibraryCommand,
ImportLibraryNodesCommand,
InsertPageAfterCommand,
InsertPageBeforeCommand,
InsertVariableBetweenCommand,
IsFocusViewEnabled,
IsThisNameInvalidForCreatingVariable,
LeaveHistoryModeWasmCall,
LoadFontInJsCommand,
LoadFontInJsFailedCommand,
LooperApplyCommand,
LoseGpu,
LowPerfResizeCanvasForPresentCommand,
MakeHoverPopupHyperlinkActive,
MarkControlFontCommand,
MarkEvalJsBeginCommand,
MarkEvalJsEndCommand,
MouseEnterCommentCommand,
MouseLeaveCommentCommand,
OnMovingCommentCommand,
NavigateToComponentPropOnComponentWasmcall,
NavigateToComponentPropOnInstanceWasmcall,
NextAutoLayoutFloatInputCommand,
NotifyCanvasSizeChanged,
OnAnimationFrame,
OnBatchOperation,
OnBatchOperationAck,
OnBatchOperationsUnderHistoryModeCommand,
OnBridgeStatusChange,
OnClipboardReadProxyFilesCommand,
OnVRAMCompactCommand,
OnDocIdChange,
OnDocumentCommand,
OnGetLoadedImageBitmapBytesCommand,
OnMembershipChange,
OnMicroTask,
OnPreparedUploadTextureCommand,
OnPrototypeDeviceFrameDownloadCommand,
OnSessionIdChange,
OnSyncUserProperties,
OnUserEnter,
OnUserIdChange,
OnUserQuit,
OnUserRoleChange,
OnUserSynergyRoleChange,
OpenHyperlinkNodeWasmCall,
PluginApiAddComponentProperty,
PluginApiAppendChild,
PluginApiClone,
PluginApiCombineAsVariants,
PluginApiComponentPropertyDefinitions,
PluginApiCreateComponentFromNode,
PluginApiCreateExternalPromise,
PluginApiCreateImage,
PluginApiCreateInstance,
PluginApiCreateShapeNode,
PluginApiCreatePage,
PluginApiCreateStyleNode,
PluginApiCurrentUser,
PluginApiDefaultVariant,
PluginApiDeleteCharacters,
PluginApiDeleteComponentProperty,
PluginApiDetachInstance,
PluginApiDropEventNeeded,
PluginApiDropEventPickInsertionLocation,
PluginApiEditComponentProperty,
PluginApiEventNotifyCommand,
PluginApiExportAsync,
PluginApiFindAll,
PluginApiFindAllCriteria,
PluginApiFindChildren,
PluginApiFlatten,
PluginApiGetChildrenCommand,
PluginApiGetClipsContent,
PluginApiGetComponentPropertiesOfInstance,
PluginApiGetComponentPropertyReferences,
PluginApiGetConstraintsCommand,
PluginApiGetCurrentPage,
PluginApiGetDimensionAndPositionCommand,
PluginApiGetExposedInstances,
PluginApiGetFileKey,
PluginApiGetHandleMirroring,
PluginApiGetInstancesAsync,
PluginApiGetIsExposedInstance,
PluginApiGetLocalEffectStyles,
PluginApiGetLocalGridStyles,
PluginApiGetLocalPaintStyles,
PluginApiGetLocalTextStyles,
PluginApiGetName,
PluginApiGetOpacityCommand,
PluginApiGetParentCommand,
PluginApiGetPluginData,
PluginApiGetPluginDataKeys,
PluginApiGetPublishStatusCommand,
PluginApiGetRangeAllFontNames,
PluginApiGetRangeFillStyleId,
PluginApiGetRangeFills,
PluginApiGetRangeFontName,
PluginApiGetRangeFontSize,
PluginApiGetRangeFontWeight,
PluginApiGetRangeHyperlink,
PluginApiGetRangeIndentation,
PluginApiGetRangeLetterSpacing,
PluginApiGetRangeLineHeight,
PluginApiGetRangeLineType,
PluginApiGetRangeTextCase,
PluginApiGetRangeTextDecoration,
PluginApiGetRangeTextStyleId,
PluginApiGetRelativeTransformCommand,
PluginApiGetSelectionCommand,
PluginApiGetSelectionColors,
PluginApiGetSharedPluginData,
PluginApiGetSharedPluginDataKeys,
PluginApiGetStyledTextSegments,
PluginApiGetTypeCommand,
PluginApiGetVisibleCommand,
PluginApiGroup,
PluginApiImportRemoteLibNodeByKeyAsyncCommand,
PluginApiImportRemoteLibNodeByKeyAsyncCallbackCommand,
PluginApiInsertCharacters,
PluginApiIsRemoteLibraryNode,
PluginApiListAvailableFontsAsync,
PluginApiLoadFont,
PluginApiRectToFrame,
PluginApiRemoveNode,
PluginApiRescaleCommand,
PluginApiResetSymbolOverrides,
PluginApiResizeNode,
PluginApiResizeWithoutConstraints,
PluginApiResolveExternalPromiseCommand,
PluginApiSetClipsContent,
PluginApiSetComponentPropertiesOfInstance,
PluginApiSetComponentPropertyReferences,
PluginApiSetConstraintsCommand,
PluginApiSetCurrentPage,
PluginApiSetDimensionAndPositionCommand,
PluginApiSetEventNotificationStatus,
PluginApiSetHandleMirroring,
PluginApiSetIsExposedInstance,
PluginApiSetName,
PluginApiSetNameAutoRename,
PluginApiSetOpacityCommand,
PluginApiSetPluginData,
PluginApiSetRangeFillStyleId,
PluginApiSetRangeFills,
PluginApiSetRangeFontName,
PluginApiSetRangeFontSize,
PluginApiSetRangeHyperlink,
PluginApiSetRangeIndentation,
PluginApiSetRangeLetterSpacing,
PluginApiSetRangeLineHeight,
PluginApiSetRangeLineType,
PluginApiSetRangeTextCase,
PluginApiSetRangeTextDecoration,
PluginApiSetRangeTextStyleId,
PluginApiSetRelativeTransformCommand,
PluginApiSetSharedPluginData,
PluginApiSetVisibleCommand,
PluginApiShowUICommand,
PluginApiSubtract,
PluginApiSymbolOverrides,
PluginApiUngroup,
PluginApiUpdateTextNodeContent,
PluginApiUpdateTextProps,
PluginCancelEventNotifierCommand,
PluginApiCancelNotify,
PluginApiNotify,
PluginApiNotifyCallback,
PluginRegisterEventNotifierCommand,
PluginApiUIClose,
PluginApiUIHide,
PluginApiUIReposition,
PluginApiUIResize,
PluginApiUIShow,
PreviewServerOnQueryReply,
PrintImageDiagnosisCommand,
PrintUsageCommand,
PrototypeAnimationFrameCallback,
ReadMagnifierBufferCallbackCommand,
RecoverComponentForSelectedInstanceCommand,
RecoverToDefaultVarientCommand,
ReloadImageTimerCallback,
RemoveAllAutoLayoutWasmCall,
RemoveAllPrototypeInteractions,
RemoveAutoLayoutWasmCall,
RemoveCopiedNodesTimerCallback,
RemoveDraftCommentCommand,
RemoveEffectStyleForSelectionCommand,
RemoveSelectionAllPrototypeInteractions,
RemoveSelectionPrototypeInteraction,
RemoveVariableCodeSyntaxCommand,
RemoveVariableSetCommand,
RemoveVariableCommand,
RenameSelectedComponentSetPropWasmCall,
RenameVariableSetCommand,
RenameVariableCommand,
RenderCursorRecord,
ReorderComponentPropDefs,
ReorderSelectedComponentSetPropOptionsCommand,
ReplaceSelectedInstanceMainCompWasmCall,
ResetDefaultVariableModeBySetForPageCommand,
ResetDefaultVariableModeBySetForSelectionCommand,
ResetGeometryCommand,
ResetGpuStateCommand,
ResetSelectionAndHistoryVersionIdCommand,
ResetWasmStatusCommand,
ResizeCanvasCommand,
RestartPresentFlowCommand,
RestartPresentFlowFromFirstOneCommand,
RestoreGpu,
RGBAtoCode,
RotateCopyPreviewCommandForJs,
RotateCopyStartPreviewCommandForJs,
RunPluginScriptCommand,
PluginApiSaveVersionHistoryCommand,
PluginApiSaveVersionHistoryCallbackCommand,
PluginApiScrollAndZoomIntoView,
SearchLocateNodeWithinMotiffScopeWasmCall,
SelectConflictVariantsCommand,
SelectNodesBySpecificVariantValueWasmCall,
SelectPresentScalingForJS,
SelectionAttachMinMaxWidthHeightFloatVarCommand,
SelectionAttachWidthHeightFloatVarCommand,
SelectionDetachMinMaxWidthHeightFloatVarCommand,
SelectionDetachWidthHeightFloatVarCommand,
SetActivedCommentIdCommand,
SetAliasForVariableCommand,
SetAutoLayoutAdvancePanelCommand,
UpdateSelectedAutoLayoutWHValueWasmCall,
SetBigNudgeAmountCommand,
SetDevicePixelRatioCommand,
SetDocumentWatchpointsCommand,
SetIndependentStrokeTypeForSelectionCommand,
SetMirrorViewportScalingCommand,
SetNotificationMovedComponentStyleNodeIdsCommand,
SetReleaseVersion,
SetSmallNudgeAmountCommand,
SetStrokeWeightForSelectionCommand,
SetTextValueForSelectionCommand,
SetVariableModeBySetForPageCommand,
SetVariableModeBySetForSelectionCommand,
SetVariableSetModeNameCommand,
SetViewportByAnimationKeyframeCommand,
PluginApiSetViewportCenter,
PluginApiSetViewportZoom,
SetComponentConfigPopupWasmCall,
ShowMarkInfoWasmCall,
SkewSelectedNodesCommand,
StartEditingPrototypeInteraction,
StartHoveredHyperlinkEditing,
StartHyperlinkEditing,
StartLibraryPublishModalPublishCheckedWasmCall,
StartMovingCommentCommand,
StartRecordingAllocationJsStack,
StopRecordingAllocationJsStack,
SubmitDraftCommentCommand,
SwitchEditorTypeCommand,
SwitchPrototypeHotspotHintCommand,
SwitchShowDeviceCommand,
SyncCommentsMetaCommand,
TabHiddenCommand,
TabVisibleCommand,
ToggleComponentPickerWasmCall,
ToggleComponentPropPopupWasmCall,
ToggleEnablePixelGridWasmCall,
ToggleEnabledLayoutGridWasmCall,
ToggleFullScreenCommandWasmCall,
ToggleInverseZoomDirectionCommand,
ToggleReplaceInstancePanelWasmCall,
ToggleShowCommentCommand,
ToggleShowRulerWasmCall,
ToggleShowShortcutPanelWasmCall,
ToggleShowSidebarCommandForJs,
ToggleShowUsedMemory,
ToggleSnapToPixelWasmCall,
ToggleSystemMetricCommand,
ToggleTextCursorSpriteVisible,
TriggerAllKeyUpBindingsCommand,
TriggerImagePaintPresenter,
TryHoverOffTargetCommentCommand,
UngroupLocalStyleSelectionCommand,
UngroupSelectionForJSCommand,
UpdateActivatedPluginCommand,
UpdateBatchAutoLayoutCommand,
UpdateBatchAutoLayoutChildCommand,
UpdateColorVariableValueCommand,
UpdateComponentDescWasmCall,
UpdateCopilotCursorCommand,
UpdateDevModeInspectExportCommandWasmCall,
UpdateDocumentColorProfileCommand,
UpdateEffectStyleNodeDescriptionCommand,
UpdateEffectStyleNodeEffectsCommand,
UpdateEffectStyleNodeNameCommand,
UpdateEffectsForSelectionCommand,
UpdateFloatVariableValueCommand,
UpdateFromCreatingFileCommand,
UpdateInstanceBubbledWasmCall,
UpdateIsViewportZoomingCommand,
UpdateLocalStyleEditingNameCommand,
UpdateLocalStyleEditingStyleIdCommand,
UpdateLocalStyleFolderNameCommand,
UpdateLocalStyleNameCommand,
UpdateLocalStyleSelectionCommand,
UpdateNodeValAboutCompPropInInstanceWasmCall,
UpdatePaintStyleNodeDescriptionCommand,
UpdatePaintStyleNodeNameCommand,
UpdatePaintStyleNodePaintsCommand,
UpdateImagePreivewHash,
UpdatePrototypeBackgroundColorCommand,
UpdatePrototypeDeviceCommand,
UpdatePrototypeFlowOrderIndexCommand,
UpdatePrototypingVisibleCommand,
UpdateScrollBehavior,
UpdateScrollOverflow,
UpdateSelectedComponentNameWasmCall,
UpdateSelectedComponentSetPropsWasmCall,
UpdateSelectedVariantsPropsCommandWasmCall,
UpdateSelectionPrototypeInteraction,
ShowSliceCommand,
ExpandSpecificSimplifyInstance,
UpdateSimplifyInstancePanels,
UpdateTextSettingTabKeyCommand,
UpdateTextStyleNodeDescriptionCommand,
UpdateTextStyleNodeDetachOpsizeFromFontSizeCommand,
UpdateTextStyleNodeFontFamilyCommand,
UpdateTextStyleNodeFontNameCommand,
UpdateTextStyleNodeFontSizeCommand,
UpdateTextStyleNodeFontVariationCommand,
UpdateTextStyleNodeLetterSpacingCommand,
UpdateTextStyleNodeLineHeightCommand,
UpdateTextStyleNodeNameCommand,
UpdateTextStyleNodeParagraphSpacingCommand,
UpdateTextStyleNodeTextDecorationCommand,
UpdateUserPlanStatus,
UpdateVariableCodeSyntaxCommand,
UpdateVariableDescriptionCommand,
UpdateVariantPropForPublicInstance,
UploadTextureForMotiffLibCommand,
ValidateInstanceSwapValue,
WindowEventTrace,
]
export const AddExportImage = {
    code: Wukong.DocumentProto.JsCallCode.JCC_AddExportImage,
    returnType: Void,
    argumentType: Wukong.DocumentProto.ExportImagesNumParams,
}
export const GetShowEditorUnAccessibleBannerFromJs = {
    code: Wukong.DocumentProto.JsCallCode.JCC_GetShowEditorUnAccessibleBannerFromJs,
    returnType: Wukong.DocumentProto.BridgeProtoBoolean,
    argumentType: Wukong.DocumentProto.BridgeProtoBoolean,
}
export const LocalVariableEditor_GetCollectionCollapsedGroupKeysCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_LocalVariableEditor_GetCollectionCollapsedGroupKeys,
    returnType: Wukong.DocumentProto.LocalVariableCollectionCollapsedGroupKeys,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const LocalVariableEditor_GetLatestSelectedCollectionCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_LocalVariableEditor_GetLatestSelectedCollection,
    returnType: Wukong.DocumentProto.BridgeProtoString,
    argumentType: Void,
}
export const LocalVariableEditor_SetCollectionCollapsedGroupKeysCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_LocalVariableEditor_SetCollectionCollapsedGroupKeys,
    returnType: Void,
    argumentType: Wukong.DocumentProto.LocalVariableCollectionCollapsedGroupKeys,
}
export const LocalVariableEditor_SetLatestSelectedCollectionCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_LocalVariableEditor_SetLatestSelectedCollection,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const PreExportFinish = {
    code: Wukong.DocumentProto.JsCallCode.JCC_PreExportFinish,
    returnType: Void,
    argumentType: Void,
}
export const UpdateExportImageNum = {
    code: Wukong.DocumentProto.JsCallCode.JCC_UpdateExportImageNum,
    returnType: Void,
    argumentType: Void,
}
export const CallJsToAbortDrawingCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_abortDrawingComment,
    returnType: Void,
    argumentType: Void,
}
export const AddFrog = {
    code: Wukong.DocumentProto.JsCallCode.JCC_addFrog,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_FrogParam,
}
export const AddImagesInSVG = {
    code: Wukong.DocumentProto.JsCallCode.JCC_addImagesInSVG,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_uploadImages,
}
export const ApplyCursorCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_applyCursor,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_ApplyCursor,
}
export const CallJsToAsyncFocusAndActiveCommentWithAnimationCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_asyncFocusAndActiveCommentWithAnimation,
    returnType: Void,
    argumentType: Wukong.DocumentProto.FocusAndActiveCommentWithAnimationParam,
}
export const BenchmarkImageDownloadCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_benchmarkImageDownloadTime,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Args_BenchmarkImageDownloadCommand,
}
export const CallAiLayoutCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_callAiAlign,
    returnType: Void,
    argumentType: Wukong.DocumentProto.CallAiAlignRequest,
}
export const CallAiCreateAutoLayoutCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_callAiCreateAutolayout,
    returnType: Void,
    argumentType: Wukong.DocumentProto.CallAiAlignRequest,
}
export const CallAiDuplicateCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_callAiDuplicate,
    returnType: Void,
    argumentType: Wukong.DocumentProto.AiDuplicateParam,
}
export const CallAiLayout = {
    code: Wukong.DocumentProto.JsCallCode.JCC_callAiLayout,
    returnType: Void,
    argumentType: Wukong.DocumentProto.CallAILayoutRequest,
}
export const CancelLastOpenHyperlink = {
    code: Wukong.DocumentProto.JsCallCode.JCC_cancelLastOpenHyperlink,
    returnType: Void,
    argumentType: Void,
}
export const CanvasSearchInputFocusAndSelectAll = {
    code: Wukong.DocumentProto.JsCallCode.JCC_canvasSearchInputFocusAndSelectAll,
    returnType: Void,
    argumentType: Void,
}
export const CanvasSearchResultListScrollTopTo = {
    code: Wukong.DocumentProto.JsCallCode.JCC_canvasSearchResultListScrollTopTo,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoFloat,
}
export const ChangeCanvasColorSpaceCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_changeCanvasColorSpace,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_ChangeCanvasColorSpace,
}
export const ChangeExceedEditorToastStatus = {
    code: Wukong.DocumentProto.JsCallCode.JCC_changeExceedEditorToastStatus,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoBoolean,
}
export const SelectionCountChange = {
    code: Wukong.DocumentProto.JsCallCode.JCC_changeSelection,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoInt,
}
export const ChangeSynergyStateCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_changeSynergyState,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_changeSynergyState,
}
export const CheckAiCreditsCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_checkAiCredits,
    returnType: Wukong.DocumentProto.BridgeProtoBoolean,
    argumentType: Wukong.DocumentProto.AiCheckCreditsParam,
}
export const CheckComponentStyleMovement = {
    code: Wukong.DocumentProto.JsCallCode.JCC_checkComponentStyleMovement,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_checkComponentStyleMovement,
}
export const CheckIsAIRecognizeDocumentCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_checkIsAIRecognizeDocument,
    returnType: Void,
    argumentType: Void,
}
export const CheckShouldBlockDevMode = {
    code: Wukong.DocumentProto.JsCallCode.JCC_checkShouldBlockDevMode,
    returnType: Wukong.DocumentProto.BridgeProtoBoolean,
    argumentType: Void,
}
export const ClearIntervalForWasm = {
    code: Wukong.DocumentProto.JsCallCode.JCC_clearInterval,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoUint,
    skipRecording: true,
}
export const ClearRepositoryCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_clearRepository,
    returnType: Void,
    argumentType: Void,
}
export const ClearTimeoutForWasm = {
    code: Wukong.DocumentProto.JsCallCode.JCC_clearTimeout,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoUint,
    skipRecording: true,
}
export const ClipboardReadFilesCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_clipboardReadFiles,
    returnType: Wukong.DocumentProto.Ret_clipboardReadFiles,
    argumentType: Void,
}
export const ClipboardReadImageInfosCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_clipboardReadImageInfos,
    returnType: Wukong.DocumentProto.Ret_clipboardReadImageInfos,
    argumentType: Void,
}
export const ClipboardReadItemsCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_clipboardReadItems,
    returnType: Wukong.DocumentProto.Ret_clipboardReadItems,
    argumentType: Void,
}
export const ClipboardReadProxyFiles = {
    code: Wukong.DocumentProto.JsCallCode.JCC_clipboardReadProxyFiles,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_clipboardReadProxyFiles,
}
export const ClipboardWriteCanvasImageBlob = {
    code: Wukong.DocumentProto.JsCallCode.JCC_clipboardWriteCanvasImageBlob,
    returnType: Wukong.DocumentProto.BridgeProtoBoolean,
    argumentType: Wukong.DocumentProto.Arg_clipboardWriteCanvasImageBlobs,
}
export const ClipboardWriteFilesCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_clipboardWriteFiles,
    returnType: Wukong.DocumentProto.BridgeProtoBoolean,
    argumentType: Wukong.DocumentProto.Arg_clipboardWriteFiles,
}
export const ClipboardWriteItemsCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_clipboardWriteItems,
    returnType: Wukong.DocumentProto.BridgeProtoBoolean,
    argumentType: Wukong.DocumentProto.Arg_clipboardWriteItems,
}
export const CloseLibraryMainModal = {
    code: Wukong.DocumentProto.JsCallCode.JCC_closeLibraryMainModal,
    returnType: Void,
    argumentType: Void,
}
export const CallCloseLibraryModalAfterPublish = {
    code: Wukong.DocumentProto.JsCallCode.JCC_closeLibraryModalAfterPublish,
    returnType: Void,
    argumentType: Void,
}
export const PluginApiClosePluginCommandJS = {
    code: Wukong.DocumentProto.JsCallCode.JCC_closePlugin,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const CmdCheckFontMissing = {
    code: Wukong.DocumentProto.JsCallCode.JCC_cmdCheckFontMissing,
    returnType: Wukong.DocumentProto.BridgeProtoBoolean,
    argumentType: Wukong.DocumentProto.FontName,
}
export const CmdSetURLSearchParams = {
    code: Wukong.DocumentProto.JsCallCode.JCC_cmdSetURLSearchParams,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_SetURLSearchParams,
}
export const CallJsToAskForEscHandled = {
    code: Wukong.DocumentProto.JsCallCode.JCC_commentEsc,
    returnType: Wukong.DocumentProto.BridgeProtoBoolean,
    argumentType: Void,
}
export const CopyWasmMemToWebBlob = {
    code: Wukong.DocumentProto.JsCallCode.JCC_copyWasmMemToWebBlob,
    returnType: Wukong.DocumentProto.Ret_CopyWasmMemToWebBlob,
    argumentType: Wukong.DocumentProto.Param_CopyWasmMemToWebBlob,
}
export const CreateCanvasToRender = {
    code: Wukong.DocumentProto.JsCallCode.JCC_createCanvasToRender,
    returnType: Wukong.DocumentProto.Ret_createCanvasToRender,
    argumentType: Wukong.DocumentProto.Arg_createCanvasToRender,
}
export const CallJsToCreateCommentCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_createComment,
    returnType: Void,
    argumentType: Wukong.DocumentProto.CommentCreateParam,
}
export const CreateControlFontCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_createControlFont,
    returnType: Void,
    argumentType: Wukong.DocumentProto.ControlParagraphFont,
}
export const CreateControlTextBitmapCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_createControlTextBitmap,
    returnType: Wukong.DocumentProto.SerBitmap,
    argumentType: Wukong.DocumentProto.Arg_GetCanvasGlyphBitmap,
}
export const DecodeJsonAIRecognizeResultWithEncodedNodesV2 = {
    code: Wukong.DocumentProto.JsCallCode.JCC_decodeJsonAIRecognizeResultWithEncodedNodesV2,
    returnType: Wukong.DocumentProto.AIRecognizeResultWithEncodedNodesV2,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const DecodeJsonAiDuplicateResult = {
    code: Wukong.DocumentProto.JsCallCode.JCC_decodeJsonAiDuplicateResult,
    returnType: Wukong.DocumentProto.AiDuplicateResult,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const DecodeJsonApplyAiDuplicateResultDataV2 = {
    code: Wukong.DocumentProto.JsCallCode.JCC_decodeJsonApplyAiDuplicateResultDataV2,
    returnType: Wukong.DocumentProto.ApplyAiDuplicateResultDataV2,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const DecodeJsonNodeBlobInfoV2 = {
    code: Wukong.DocumentProto.JsCallCode.JCC_decodeJsonNodeBlobInfoV2,
    returnType: Wukong.DocumentProto.NodeBlobInfoV2,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const DecodeJsonPluginArcTextState = {
    code: Wukong.DocumentProto.JsCallCode.JCC_decodeJsonPluginArcTextState,
    returnType: Wukong.DocumentProto.PluginArcTextState,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const DetachSelectedInstanceReport = {
    code: Wukong.DocumentProto.JsCallCode.JCC_detachSelectedInstanceReport,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_DetachSelectedInstanceReport,
}
export const DocModeCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_docMode,
    returnType: Wukong.DocumentProto.DocModeCommandParam,
    argumentType: Void,
}
export const DownLoadBase64 = {
    code: Wukong.DocumentProto.JsCallCode.JCC_downloadBase64,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Args_DownloadBase64,
}
export const DownloadFont = {
    code: Wukong.DocumentProto.JsCallCode.JCC_downloadFont,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_downloadFont,
    skipRecording: true,
}
export const RequestDownloadImageCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_downloadImage,
    returnType: Void,
    argumentType: Wukong.DocumentProto.ImageId,
    skipRecording: true,
}
export const DownloadPrototypeDeviceFrameCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_downloadPrototypeDeviceFrame,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_DownloadPrototypeDeviceFrame,
}
export const EnqueueMicrotaskForWasm = {
    code: Wukong.DocumentProto.JsCallCode.JCC_enqueueMicrotask,
    returnType: Void,
    argumentType: Wukong.DocumentProto.WasmCall,
    skipRecording: true,
}
export const EvalJsScriptCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_evalJsScript,
    returnType: Wukong.DocumentProto.Ret_evalJsScript,
    argumentType: Wukong.DocumentProto.Arg_evalJsScript,
}
export const FetchRemoteComponentSetDataForRecover = {
    code: Wukong.DocumentProto.JsCallCode.JCC_fetchRemoteComponentSetDataForRecover,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_FetchRemoteComponentSetDataForRecover,
}
export const FetchRemoteLibraryChanges = {
    code: Wukong.DocumentProto.JsCallCode.JCC_fetchRemoteLibraryChanges,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_fetchRemoteLibraryChanges,
}
export const FinishRendering = {
    code: Wukong.DocumentProto.JsCallCode.JCC_finishRendering,
    returnType: Wukong.DocumentProto.Ret_finishRendering,
    argumentType: Wukong.DocumentProto.Arg_finishRendering,
}
export const FreeBitmapCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_freeBitmap,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const GenerateDocumentNodeUrl = {
    code: Wukong.DocumentProto.JsCallCode.JCC_generateDocumentNodeUrl,
    returnType: Wukong.DocumentProto.BridgeProtoString,
    argumentType: Wukong.DocumentProto.Arg_GenerateDocumentNodeUrl,
}
export const GenerateLibraryThumbnail = {
    code: Wukong.DocumentProto.JsCallCode.JCC_generateLibraryThumbnail,
    returnType: Wukong.DocumentProto.Ret_generateThumbnail,
    argumentType: Wukong.DocumentProto.Arg_generateThumbnail,
}
export const CallJsToGenerateTempCommentIdForCreating = {
    code: Wukong.DocumentProto.JsCallCode.JCC_generateTempCommentIdForCreating,
    returnType: Wukong.DocumentProto.BridgeProtoString,
    argumentType: Void,
}
export const GenerateUrlWithSearchParams = {
    code: Wukong.DocumentProto.JsCallCode.JCC_generateUrlWithSearchParams,
    returnType: Wukong.DocumentProto.BridgeProtoString,
    argumentType: Wukong.DocumentProto.URLSearchParams,
}
export const BackendTypeCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_getBackendType,
    returnType: Wukong.DocumentProto.Ret_BackendTypeCommand,
    argumentType: Void,
}
export const GetCanvasInfoCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_getCanvasInfo,
    returnType: Wukong.DocumentProto.CanvasInfo,
    argumentType: Void,
}
export const GetImageCdnCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_getCdnImageUrl,
    returnType: Wukong.DocumentProto.BridgeProtoString,
    argumentType: Void,
}
export const GetCodeConfigFromJS = {
    code: Wukong.DocumentProto.JsCallCode.JCC_getDevModeCodeConfigFromJS,
    returnType: Wukong.DocumentProto.UpdateDevModeCodeConfigCommandParam,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const GetExportUserConfigFromJS = {
    code: Wukong.DocumentProto.JsCallCode.JCC_getDevModeExportUserConfigFromJS,
    returnType: Wukong.DocumentProto.DevModeInspectExportUserConfig,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const CallJsToAskForEditingContentProtected = {
    code: Wukong.DocumentProto.JsCallCode.JCC_getEditingContentProtected,
    returnType: Wukong.DocumentProto.BridgeProtoBoolean,
    argumentType: Void,
}
export const GetGPUWorkaroundCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_getGPUWorkaround,
    returnType: Wukong.DocumentProto.GPUWorkaround,
    argumentType: Void,
}
export const PluginApiGetImageBytesAsyncCallback = {
    code: Wukong.DocumentProto.JsCallCode.JCC_getImageBytesAsyncCallback,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_GetImageBytesAsyncCallback,
}
export const PluginApiGetImageSizeAsyncCallback = {
    code: Wukong.DocumentProto.JsCallCode.JCC_getImageSizeAsyncCallback,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_GetImageSizeAsyncCallback,
}
export const GetJsStack = {
    code: Wukong.DocumentProto.JsCallCode.JCC_getJsStack,
    returnType: Wukong.DocumentProto.BridgeProtoInt,
    argumentType: Void,
    skipRecording: true,
}
export const GetLeftPanelWidthUserConfig = {
    code: Wukong.DocumentProto.JsCallCode.JCC_getLeftPanelWidthUserConfig,
    returnType: Wukong.DocumentProto.BridgeProtoInt,
    argumentType: Void,
}
export const GetLoadedImageBitmapBytesCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_getLoadedImageBitmapBytes,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_GetBitmapBytes,
}
export const GetLocalEditorTypeCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_getLocalEditorType,
    returnType: Wukong.DocumentProto.Ret_getLocalEdtiorType,
    argumentType: Void,
}
export const GetMicrosecondTimestampForPerformanceMeasurement = {
    code: Wukong.DocumentProto.JsCallCode.JCC_getMicrosecondTimestampForPerformanceMeasurement,
    returnType: Wukong.DocumentProto.BridgeProtoUint64,
    argumentType: Void,
    skipRecording: true,
}
export const GetMotiffScopeConfigCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_getMotiffScopeConfig,
    returnType: Wukong.DocumentProto.MotiffScopeConfig,
    argumentType: Void,
}
export const GetPrototypePresentUrlParams = {
    code: Wukong.DocumentProto.JsCallCode.JCC_getPrototypePresentUrlParams,
    returnType: Wukong.DocumentProto.PrototypePresentUrlParam,
    argumentType: Void,
}
export const RenderTypeCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_getRenderType,
    returnType: Wukong.DocumentProto.Ret_RenderTypeCommand,
    argumentType: Void,
}
export const GetRightPanelWidthUserConfig = {
    code: Wukong.DocumentProto.JsCallCode.JCC_getRightPanelWidthUserConfig,
    returnType: Wukong.DocumentProto.BridgeProtoInt,
    argumentType: Void,
}
export const GetTimestampForDurationCalculation = {
    code: Wukong.DocumentProto.JsCallCode.JCC_getTimestampForDurationCalculation,
    returnType: Wukong.DocumentProto.BridgeProtoUint64,
    argumentType: Void,
}
export const GetTimestampForPerformanceMeasurement = {
    code: Wukong.DocumentProto.JsCallCode.JCC_getTimestampForPerformanceMeasurement,
    returnType: Wukong.DocumentProto.BridgeProtoUint64,
    argumentType: Void,
    skipRecording: true,
}
export const GetURLSearchParams = {
    code: Wukong.DocumentProto.JsCallCode.JCC_getURLSearchParams,
    returnType: Wukong.DocumentProto.URLSearchParams,
    argumentType: Void,
}
export const GetUserConfigCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_getUserConfig2,
    returnType: Wukong.DocumentProto.UserConfig2,
    argumentType: Void,
}
export const GetValidTransitionTypesCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_getValidTransitionTypes,
    returnType: Wukong.DocumentProto.Ret_GetValidTransitionTypes,
    argumentType: Wukong.DocumentProto.Arg_GetValidTransitionTypes,
}
export const GetVectorPanelParsedValueCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_getVectorPanelParsedValue,
    returnType: Wukong.DocumentProto.BridgeProtoFloat,
    argumentType: Wukong.DocumentProto.BridgeProtoFloat,
}
export const GetWallClock = {
    code: Wukong.DocumentProto.JsCallCode.JCC_getWallClock,
    returnType: Wukong.DocumentProto.BridgeProtoInt64,
    argumentType: Void,
    skipRecording: true,
}
export const GetWindowClientInnerSize = {
    code: Wukong.DocumentProto.JsCallCode.JCC_getWindowClientInnerSize,
    returnType: Wukong.DocumentProto.WindowClientInnerSize,
    argumentType: Void,
}
export const HandleTextMissingFontCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_handleTextMissingFont,
    returnType: Void,
    argumentType: Void,
}
export const HideTooltip = {
    code: Wukong.DocumentProto.JsCallCode.JCC_hideTooltip,
    returnType: Void,
    argumentType: Void,
}
export const HistoryModeGetPageArchive = {
    code: Wukong.DocumentProto.JsCallCode.JCC_historyModeGetPageArchive,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const PluginApiImportRemoteLibNodeByKeyAsyncCommandJs = {
    code: Wukong.DocumentProto.JsCallCode.JCC_importRemoteLibNodeByKeyAsync,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_ImportRemoteLibNodeByKeyAsyncCommandJs,
}
export const PluginApiImportRemoteLibNodeByKeyAsyncCallbackCommandJs = {
    code: Wukong.DocumentProto.JsCallCode.JCC_importRemoteLibNodeByKeyAsyncCallback,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_ImportRemoteLibNodeByKeyAsyncCallbackCommandJs,
}
export const GetIsCreatingFile = {
    code: Wukong.DocumentProto.JsCallCode.JCC_isCreatingFile,
    returnType: Wukong.DocumentProto.BridgeProtoBoolean,
    argumentType: Void,
}
export const IsFeatureEnabled = {
    code: Wukong.DocumentProto.JsCallCode.JCC_isFeatureEnabled,
    returnType: Wukong.DocumentProto.Ret_isFeatureEnabled,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const IsTabVisible = {
    code: Wukong.DocumentProto.JsCallCode.JCC_isTabVisible,
    returnType: Wukong.DocumentProto.BridgeProtoBoolean,
    argumentType: Void,
}
export const IsValidUrl = {
    code: Wukong.DocumentProto.JsCallCode.JCC_isValidUrl,
    returnType: Wukong.DocumentProto.BridgeProtoBoolean,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const JumpToOriginDocument = {
    code: Wukong.DocumentProto.JsCallCode.JCC_jumpToOriginDocument,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_JumpToOriginDocument,
}
export const LoadBenchResult = {
    code: Wukong.DocumentProto.JsCallCode.JCC_loadBenchResult,
    returnType: Wukong.DocumentProto.BenchResult,
    argumentType: Void,
}
export const LoadFallbackFontSync = {
    code: Wukong.DocumentProto.JsCallCode.JCC_loadFallbackFontSync,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_LoadFallbackFont,
}
export const LogPluginScriptErrorJS = {
    code: Wukong.DocumentProto.JsCallCode.JCC_logPluginScriptError,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const MeasureControlTextWidthCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_measureControlTextWidth,
    returnType: Wukong.DocumentProto.BridgeProtoFloat,
    argumentType: Wukong.DocumentProto.Arg_MeasureControlTextWidth,
}
export const MetricRenderDurationCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_metricRenderDuration,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_MetricRenderDuration,
}
export const MouseEnterHyperlink = {
    code: Wukong.DocumentProto.JsCallCode.JCC_mouseEnterHyperlink,
    returnType: Void,
    argumentType: Void,
}
export const MouseLeaveHyperlink = {
    code: Wukong.DocumentProto.JsCallCode.JCC_mouseLeaveHyperlink,
    returnType: Void,
    argumentType: Void,
}
export const NaturalSortStringArray = {
    code: Wukong.DocumentProto.JsCallCode.JCC_naturalSortStringArray,
    returnType: Wukong.DocumentProto.Arg_naturalSortStringArray,
    argumentType: Wukong.DocumentProto.Arg_naturalSortStringArray,
}
export const OnObservingModeChange = {
    code: Wukong.DocumentProto.JsCallCode.JCC_onObservingModeChange,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoBoolean,
}
export const OpenChatbotModal = {
    code: Wukong.DocumentProto.JsCallCode.JCC_openChatbotModal,
    returnType: Void,
    argumentType: Void,
}
export const OpenHyperlinkDelayCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_openHyperlinkDelay,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Hyperlink,
}
export const OpenLink = {
    code: Wukong.DocumentProto.JsCallCode.JCC_openLink,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_OpenLink,
}
export const OpenPresentInEditor = {
    code: Wukong.DocumentProto.JsCallCode.JCC_openPresentInEditor,
    returnType: Void,
    argumentType: Void,
}
export const ParseHyperlink = {
    code: Wukong.DocumentProto.JsCallCode.JCC_parseHyperlink,
    returnType: Wukong.DocumentProto.Hyperlink,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const PickImages = {
    code: Wukong.DocumentProto.JsCallCode.JCC_pickImages,
    returnType: Void,
    argumentType: Void,
}
export const PluginApiCreateExternalPromiseJS = {
    code: Wukong.DocumentProto.JsCallCode.JCC_pluginApiCreateExternalPromise,
    returnType: Wukong.DocumentProto.BridgeProtoInt,
    argumentType: Void,
}
export const PluginApiCreateImageJS = {
    code: Wukong.DocumentProto.JsCallCode.JCC_pluginApiCreateImage,
    returnType: Wukong.DocumentProto.Ret_CreateImage,
    argumentType: Wukong.DocumentProto.BridgeProtoBytes,
}
export const PluginApiExportAsyncJS = {
    code: Wukong.DocumentProto.JsCallCode.JCC_pluginApiExportAsync,
    returnType: Wukong.DocumentProto.BridgeProtoInt,
    argumentType: Wukong.DocumentProto.Arg_PluginApiExportSetting,
}
export const PluginApiGetFullAvatarImageUrlJS = {
    code: Wukong.DocumentProto.JsCallCode.JCC_pluginApiGetFullAvatarImageUrl,
    returnType: Wukong.DocumentProto.BridgeProtoString,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const PluginApiListAvailableFontsAsyncCallback = {
    code: Wukong.DocumentProto.JsCallCode.JCC_pluginApiListAvailableFontsAsyncCallback,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Ret_ApiListAvailableFontsAsync,
}
export const PluginApiLoadFontCallback = {
    code: Wukong.DocumentProto.JsCallCode.JCC_pluginApiLoadFontCallback,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Ret_APILoadFont,
}
export const PluginApiResolveExternalPromiseCommandJS = {
    code: Wukong.DocumentProto.JsCallCode.JCC_pluginApiResolveExternalPromise,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_ResolveExternalPromise,
}
export const PluginApiCancelNotifyJS = {
    code: Wukong.DocumentProto.JsCallCode.JCC_pluginCancelNotify,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoInt,
}
export const PluginApiDropEventNeededJS = {
    code: Wukong.DocumentProto.JsCallCode.JCC_pluginDropEventNeeded,
    returnType: Wukong.DocumentProto.BridgeProtoBoolean,
    argumentType: Void,
}
export const PluginApiEventNotifyCommandJS = {
    code: Wukong.DocumentProto.JsCallCode.JCC_pluginEventNotify,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_PluginCallbackEventCollection,
}
export const PluginApiEventNotifyCommandJSV2 = {
    code: Wukong.DocumentProto.JsCallCode.JCC_pluginEventNotifyV2,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_PluginCallbackEventCollection,
}
export const PluginApiNotifyJS = {
    code: Wukong.DocumentProto.JsCallCode.JCC_pluginNotify,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_ApiNotify,
}
export const PluginApiNotifyCallbackJS = {
    code: Wukong.DocumentProto.JsCallCode.JCC_pluginNotifyCallback,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_ApiNotifyCallback,
}
export const PluginApiSaveEventsToHostServiceContextCommandJS = {
    code: Wukong.DocumentProto.JsCallCode.JCC_pluginSaveEventsToHostServiceContext,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_PluginCallbackEventCollection,
}
export const PluginShowToast = {
    code: Wukong.DocumentProto.JsCallCode.JCC_pluginShowToast,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const PluginApiShowUICommandJS = {
    code: Wukong.DocumentProto.JsCallCode.JCC_pluginShowUI,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_ShowUI,
}
export const PluginApiUICloseJS = {
    code: Wukong.DocumentProto.JsCallCode.JCC_pluginUIClose,
    returnType: Void,
    argumentType: Void,
}
export const PluginApiUIHideJS = {
    code: Wukong.DocumentProto.JsCallCode.JCC_pluginUIHide,
    returnType: Void,
    argumentType: Void,
}
export const PluginApiUIRepositionJS = {
    code: Wukong.DocumentProto.JsCallCode.JCC_pluginUIReposition,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_reposition,
}
export const PluginApiUIResizeJS = {
    code: Wukong.DocumentProto.JsCallCode.JCC_pluginUIResize,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_Resize,
}
export const PluginApiUIShowJS = {
    code: Wukong.DocumentProto.JsCallCode.JCC_pluginUIShow,
    returnType: Void,
    argumentType: Void,
}
export const PrepareUploadTextureCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_prepareUploadTexture,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_PrepareUploadTexture,
}
export const PreviewServerQuery = {
    code: Wukong.DocumentProto.JsCallCode.JCC_previewServerQuery,
    returnType: Void,
    argumentType: Wukong.DocumentProto.SceneGraphQueriesProto,
}
export const PrototypePresentClickRootFrameShowBar = {
    code: Wukong.DocumentProto.JsCallCode.JCC_prototypePresentClickRootFrameShowBar,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoBoolean,
}
export const PrototypePresentHideUI = {
    code: Wukong.DocumentProto.JsCallCode.JCC_prototypePresentHideUI,
    returnType: Void,
    argumentType: Void,
}
export const PrototypePresentShowUI = {
    code: Wukong.DocumentProto.JsCallCode.JCC_prototypePresentShowUI,
    returnType: Void,
    argumentType: Void,
}
export const PublishLibrary = {
    code: Wukong.DocumentProto.JsCallCode.JCC_publishLibrary,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_publishLibraryManifest,
}
export const PushBitmapCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_pushBitmap,
    returnType: Wukong.DocumentProto.Ret_PushBitmap,
    argumentType: Wukong.DocumentProto.Arg_PushBitmap,
}
export const PushMetric = {
    code: Wukong.DocumentProto.JsCallCode.JCC_pushMetric,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_Metric,
    skipRecording: true,
}
export const ReadFontInJs = {
    code: Wukong.DocumentProto.JsCallCode.JCC_readFontInJs,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_readFontInJs,
    skipRecording: true,
}
export const ReadMagnifierBufferCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_readMagnifierBuffer,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_ReadMagnifierBuffer,
}
export const ReadSyncWGPUCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_readSyncWGPU,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_ReadSyncWGPU,
}
export const RefreshPage = {
    code: Wukong.DocumentProto.JsCallCode.JCC_refreshPage,
    returnType: Void,
    argumentType: Void,
}
export const RefreshPageWithDialog = {
    code: Wukong.DocumentProto.JsCallCode.JCC_refreshPageWithDialog,
    returnType: Void,
    argumentType: Void,
}
export const CallJsToRemoveCommentCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_removeComment,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const CallJsToRemoveDraftCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_removeDraftComment,
    returnType: Void,
    argumentType: Void,
}
export const RemoveNullContent = {
    code: Wukong.DocumentProto.JsCallCode.JCC_removeNullContent,
    returnType: Wukong.DocumentProto.BridgeProtoString,
    argumentType: Wukong.DocumentProto.BridgeProtoString,
}
export const RenderToCanvas = {
    code: Wukong.DocumentProto.JsCallCode.JCC_renderToCanvas,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_renderToCanvas,
}
export const ReplayCanvasEvent = {
    code: Wukong.DocumentProto.JsCallCode.JCC_replayCanvasEvent,
    returnType: Void,
    argumentType: Void,
}
export const ReplayServiceReplay = {
    code: Wukong.DocumentProto.JsCallCode.JCC_replayServiceReplay,
    returnType: Void,
    argumentType: Void,
}
export const RequestAnimationFrameCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_requestAnimationFrame,
    returnType: Void,
    argumentType: Wukong.DocumentProto.WasmCall,
    skipRecording: true,
}
export const SaveBenchResult = {
    code: Wukong.DocumentProto.JsCallCode.JCC_saveBenchResult,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BenchResult,
}
export const SaveRepositoryCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_saveRepository,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_autoSaveRepository,
}
export const PluginApiSaveVersionHistoryCommandJS = {
    code: Wukong.DocumentProto.JsCallCode.JCC_saveVersionHistoryAsync,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_saveVersionHistory,
}
export const PluginApiSaveVersionHistoryCallbackCommandJS = {
    code: Wukong.DocumentProto.JsCallCode.JCC_saveVersionHistoryAsyncCallback,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_saveVersionHistoryCallback,
}
export const ScrollSelectedNodeIntoLayerPanelView = {
    code: Wukong.DocumentProto.JsCallCode.JCC_scrollSelectedNodeIntoLayerPanelView,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoInt,
}
export const SendBatchOperationCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_sendBatchOperation,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_sendBatchOperations,
}
export const SendEditorTypeToSynergyCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_sendEditorTypeToSynergy,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_setLocalEditorType,
}
export const SendPrototypePresentOpenRecord = {
    code: Wukong.DocumentProto.JsCallCode.JCC_sendPrototypePresentOpenRecord,
    returnType: Void,
    argumentType: Wukong.DocumentProto.PrototypePresentOpenRecordOption,
}
export const SetIntervalForWasm = {
    code: Wukong.DocumentProto.JsCallCode.JCC_setInterval,
    returnType: Wukong.DocumentProto.BridgeProtoUint,
    argumentType: Wukong.DocumentProto.Arg_setInterval,
    skipRecording: true,
}
export const SetLocalEditorTypeCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_setLocalEditorType,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_setLocalEditorType,
}
export const SetPrototypePresentUrlParams = {
    code: Wukong.DocumentProto.JsCallCode.JCC_setPrototypePresentUrlParams,
    returnType: Void,
    argumentType: Wukong.DocumentProto.PrototypePresentUrlParam,
}
export const SetTimeoutForCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_setTimeoutForCommand,
    returnType: Wukong.DocumentProto.BridgeProtoUint,
    argumentType: Wukong.DocumentProto.Arg_setTimeoutForCommand,
    skipRecording: true,
}
export const SetTimeoutToReloadImage = {
    code: Wukong.DocumentProto.JsCallCode.JCC_setTimeoutToReloadImage,
    returnType: Wukong.DocumentProto.BridgeProtoUint,
    argumentType: Wukong.DocumentProto.Arg_setTimeoutToReloadImage,
    skipRecording: true,
}
export const SetUserConfigCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_setUserConfig2,
    returnType: Void,
    argumentType: Wukong.DocumentProto.UserConfig2,
}
export const ShowLastUsedPlugin = {
    code: Wukong.DocumentProto.JsCallCode.JCC_showLastUsedPlugin,
    returnType: Void,
    argumentType: Void,
}
export const ShowRecoverComponentModal = {
    code: Wukong.DocumentProto.JsCallCode.JCC_showRecoverComponentModal,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_ShowRecoverComponentModal,
}
export const ShowTitleInput = {
    code: Wukong.DocumentProto.JsCallCode.JCC_showTitleInput,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_showTitleInput,
}
export const ShowTooltip = {
    code: Wukong.DocumentProto.JsCallCode.JCC_showTooltip,
    returnType: Void,
    argumentType: Wukong.DocumentProto.ShowTooltipParams,
}
export const StartCopy = {
    code: Wukong.DocumentProto.JsCallCode.JCC_startClipboardCopy,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_startClipboardCopy,
}
export const StartClipboardPaste = {
    code: Wukong.DocumentProto.JsCallCode.JCC_startClipboardPaste,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_startClipboardPaste,
}
export const StartLibraryPublishModalPublishChecked = {
    code: Wukong.DocumentProto.JsCallCode.JCC_startLibraryPublishModalPublishChecked,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_publishLibraryComponents,
}
export const StartLibraryReplace = {
    code: Wukong.DocumentProto.JsCallCode.JCC_startLibraryReplace,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_startLibraryReplace,
}
export const StartViewportAnimationCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_startViewportAnimation,
    returnType: Void,
    argumentType: Wukong.DocumentProto.StartViewportAnimationParam,
}
export const SwitchBrowserFullScreenCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_switchBrowserFullScreen,
    returnType: Void,
    argumentType: Void,
}
export const SyncRemoteDocLibraryNodeKeys = {
    code: Wukong.DocumentProto.JsCallCode.JCC_syncRemoteDocLibraryNodeKeys,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_syncRemoteDocLibraryNodeKeys,
}
export const ToastShow = {
    code: Wukong.DocumentProto.JsCallCode.JCC_toastShow,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_toastInfo,
}
export const ToggleBackToInstanceToastCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_toggleBackToInstanceToast,
    returnType: Void,
    argumentType: Wukong.DocumentProto.BridgeProtoBoolean,
}
export const ToggleLibraryMainModal = {
    code: Wukong.DocumentProto.JsCallCode.JCC_toggleLibraryMainModal,
    returnType: Void,
    argumentType: Void,
}
export const TraceEventForWasm = {
    code: Wukong.DocumentProto.JsCallCode.JCC_traceEvent,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_traceEvent,
    skipRecording: true,
}
export const TraceGpuLostAndCrashCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_traceGpuLostAndCrash,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_TraceGpuLostAndCrash,
}
export const TrySwitchToAiDuplicateCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_trySwitchToAiDuplicate,
    returnType: Void,
    argumentType: Void,
}
export const CallJsToUpdateCommentMetaPositionCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_updateCommentMetaPosition,
    returnType: Void,
    argumentType: Wukong.DocumentProto.CommentMetaPositionUpdateParam,
}
export const UpdateExportUserConfigToJS = {
    code: Wukong.DocumentProto.JsCallCode.JCC_updateDevModeExportUserConfigToJS,
    returnType: Void,
    argumentType: Wukong.DocumentProto.DevModeInspectExportUserConfig,
}
export const UpdateFocusViewCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_updateFocusView,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_focusView,
}
export const UpdateSyncCurrentUsedPublishMixinInFileLibraryList = {
    code: Wukong.DocumentProto.JsCallCode.JCC_updateSyncCurrentUsedPublishMixinInFileLibraryList,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_updateSyncCurrentUsedPublishMixinInFileLibraryList,
}
export const UploadBitmapToGPUCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_uploadBitmapToGPU,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_UploadBitmapToGPU,
}
export const SendViewStateToJs = {
    code: Wukong.DocumentProto.JsCallCode.JCC_viewState,
    returnType: Void,
    argumentType: Wukong.DocumentProto.ViewState,
}
export const WriteExportDocumentResultCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_writeExportDocument,
    returnType: Void,
    argumentType: Wukong.DocumentProto.Arg_writeExportDocumentResult,
}
export const WsUpdateCurrentActiveCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_wsUpdateCurrentActive,
    returnType: Void,
    argumentType: Wukong.DocumentProto.SendCooperationProto,
}
export const WsUpdateCurrentCursorModeCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_wsUpdateCurrentCursorMode,
    returnType: Void,
    argumentType: Wukong.DocumentProto.SendCooperationProto,
}
export const WsUpdateCurrentInactiveCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_wsUpdateCurrentInactive,
    returnType: Void,
    argumentType: Wukong.DocumentProto.SendCooperationProto,
}
export const WsUpdateCurrentMousePositionCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_wsUpdateCurrentMousePosition,
    returnType: Void,
    argumentType: Wukong.DocumentProto.SendCooperationProto,
}
export const WsUpdateCurrentObservingCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_wsUpdateCurrentObserving,
    returnType: Void,
    argumentType: Wukong.DocumentProto.SendCooperationProto,
}
export const WsUpdateCurrentPageIdCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_wsUpdateCurrentPageId,
    returnType: Void,
    argumentType: Wukong.DocumentProto.SendCooperationProto,
}
export const WsUpdateCurrentSelectionsCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_wsUpdateCurrentSelections,
    returnType: Void,
    argumentType: Wukong.DocumentProto.SendCooperationProto,
}
export const WsUpdateCurrentViewportCommand = {
    code: Wukong.DocumentProto.JsCallCode.JCC_wsUpdateCurrentViewport,
    returnType: Void,
    argumentType: Wukong.DocumentProto.SendCooperationProto,
    skipRecording: true,
}

export const JsCallSignatures = [
AddExportImage,
GetShowEditorUnAccessibleBannerFromJs,
LocalVariableEditor_GetCollectionCollapsedGroupKeysCommand,
LocalVariableEditor_GetLatestSelectedCollectionCommand,
LocalVariableEditor_SetCollectionCollapsedGroupKeysCommand,
LocalVariableEditor_SetLatestSelectedCollectionCommand,
PreExportFinish,
UpdateExportImageNum,
CallJsToAbortDrawingCommand,
AddFrog,
AddImagesInSVG,
ApplyCursorCommand,
CallJsToAsyncFocusAndActiveCommentWithAnimationCommand,
BenchmarkImageDownloadCommand,
CallAiLayoutCommand,
CallAiCreateAutoLayoutCommand,
CallAiDuplicateCommand,
CallAiLayout,
CancelLastOpenHyperlink,
CanvasSearchInputFocusAndSelectAll,
CanvasSearchResultListScrollTopTo,
ChangeCanvasColorSpaceCommand,
ChangeExceedEditorToastStatus,
SelectionCountChange,
ChangeSynergyStateCommand,
CheckAiCreditsCommand,
CheckComponentStyleMovement,
CheckIsAIRecognizeDocumentCommand,
CheckShouldBlockDevMode,
ClearIntervalForWasm,
ClearRepositoryCommand,
ClearTimeoutForWasm,
ClipboardReadFilesCommand,
ClipboardReadImageInfosCommand,
ClipboardReadItemsCommand,
ClipboardReadProxyFiles,
ClipboardWriteCanvasImageBlob,
ClipboardWriteFilesCommand,
ClipboardWriteItemsCommand,
CloseLibraryMainModal,
CallCloseLibraryModalAfterPublish,
PluginApiClosePluginCommandJS,
CmdCheckFontMissing,
CmdSetURLSearchParams,
CallJsToAskForEscHandled,
CopyWasmMemToWebBlob,
CreateCanvasToRender,
CallJsToCreateCommentCommand,
CreateControlFontCommand,
CreateControlTextBitmapCommand,
DecodeJsonAIRecognizeResultWithEncodedNodesV2,
DecodeJsonAiDuplicateResult,
DecodeJsonApplyAiDuplicateResultDataV2,
DecodeJsonNodeBlobInfoV2,
DecodeJsonPluginArcTextState,
DetachSelectedInstanceReport,
DocModeCommand,
DownLoadBase64,
DownloadFont,
RequestDownloadImageCommand,
DownloadPrototypeDeviceFrameCommand,
EnqueueMicrotaskForWasm,
EvalJsScriptCommand,
FetchRemoteComponentSetDataForRecover,
FetchRemoteLibraryChanges,
FinishRendering,
FreeBitmapCommand,
GenerateDocumentNodeUrl,
GenerateLibraryThumbnail,
CallJsToGenerateTempCommentIdForCreating,
GenerateUrlWithSearchParams,
BackendTypeCommand,
GetCanvasInfoCommand,
GetImageCdnCommand,
GetCodeConfigFromJS,
GetExportUserConfigFromJS,
CallJsToAskForEditingContentProtected,
GetGPUWorkaroundCommand,
PluginApiGetImageBytesAsyncCallback,
PluginApiGetImageSizeAsyncCallback,
GetJsStack,
GetLeftPanelWidthUserConfig,
GetLoadedImageBitmapBytesCommand,
GetLocalEditorTypeCommand,
GetMicrosecondTimestampForPerformanceMeasurement,
GetMotiffScopeConfigCommand,
GetPrototypePresentUrlParams,
RenderTypeCommand,
GetRightPanelWidthUserConfig,
GetTimestampForDurationCalculation,
GetTimestampForPerformanceMeasurement,
GetURLSearchParams,
GetUserConfigCommand,
GetValidTransitionTypesCommand,
GetVectorPanelParsedValueCommand,
GetWallClock,
GetWindowClientInnerSize,
HandleTextMissingFontCommand,
HideTooltip,
HistoryModeGetPageArchive,
PluginApiImportRemoteLibNodeByKeyAsyncCommandJs,
PluginApiImportRemoteLibNodeByKeyAsyncCallbackCommandJs,
GetIsCreatingFile,
IsFeatureEnabled,
IsTabVisible,
IsValidUrl,
JumpToOriginDocument,
LoadBenchResult,
LoadFallbackFontSync,
LogPluginScriptErrorJS,
MeasureControlTextWidthCommand,
MetricRenderDurationCommand,
MouseEnterHyperlink,
MouseLeaveHyperlink,
NaturalSortStringArray,
OnObservingModeChange,
OpenChatbotModal,
OpenHyperlinkDelayCommand,
OpenLink,
OpenPresentInEditor,
ParseHyperlink,
PickImages,
PluginApiCreateExternalPromiseJS,
PluginApiCreateImageJS,
PluginApiExportAsyncJS,
PluginApiGetFullAvatarImageUrlJS,
PluginApiListAvailableFontsAsyncCallback,
PluginApiLoadFontCallback,
PluginApiResolveExternalPromiseCommandJS,
PluginApiCancelNotifyJS,
PluginApiDropEventNeededJS,
PluginApiEventNotifyCommandJS,
PluginApiEventNotifyCommandJSV2,
PluginApiNotifyJS,
PluginApiNotifyCallbackJS,
PluginApiSaveEventsToHostServiceContextCommandJS,
PluginShowToast,
PluginApiShowUICommandJS,
PluginApiUICloseJS,
PluginApiUIHideJS,
PluginApiUIRepositionJS,
PluginApiUIResizeJS,
PluginApiUIShowJS,
PrepareUploadTextureCommand,
PreviewServerQuery,
PrototypePresentClickRootFrameShowBar,
PrototypePresentHideUI,
PrototypePresentShowUI,
PublishLibrary,
PushBitmapCommand,
PushMetric,
ReadFontInJs,
ReadMagnifierBufferCommand,
ReadSyncWGPUCommand,
RefreshPage,
RefreshPageWithDialog,
CallJsToRemoveCommentCommand,
CallJsToRemoveDraftCommand,
RemoveNullContent,
RenderToCanvas,
ReplayCanvasEvent,
ReplayServiceReplay,
RequestAnimationFrameCommand,
SaveBenchResult,
SaveRepositoryCommand,
PluginApiSaveVersionHistoryCommandJS,
PluginApiSaveVersionHistoryCallbackCommandJS,
ScrollSelectedNodeIntoLayerPanelView,
SendBatchOperationCommand,
SendEditorTypeToSynergyCommand,
SendPrototypePresentOpenRecord,
SetIntervalForWasm,
SetLocalEditorTypeCommand,
SetPrototypePresentUrlParams,
SetTimeoutForCommand,
SetTimeoutToReloadImage,
SetUserConfigCommand,
ShowLastUsedPlugin,
ShowRecoverComponentModal,
ShowTitleInput,
ShowTooltip,
StartCopy,
StartClipboardPaste,
StartLibraryPublishModalPublishChecked,
StartLibraryReplace,
StartViewportAnimationCommand,
SwitchBrowserFullScreenCommand,
SyncRemoteDocLibraryNodeKeys,
ToastShow,
ToggleBackToInstanceToastCommand,
ToggleLibraryMainModal,
TraceEventForWasm,
TraceGpuLostAndCrashCommand,
TrySwitchToAiDuplicateCommand,
CallJsToUpdateCommentMetaPositionCommand,
UpdateExportUserConfigToJS,
UpdateFocusViewCommand,
UpdateSyncCurrentUsedPublishMixinInFileLibraryList,
UploadBitmapToGPUCommand,
SendViewStateToJs,
WriteExportDocumentResultCommand,
WsUpdateCurrentActiveCommand,
WsUpdateCurrentCursorModeCommand,
WsUpdateCurrentInactiveCommand,
WsUpdateCurrentMousePositionCommand,
WsUpdateCurrentObservingCommand,
WsUpdateCurrentPageIdCommand,
WsUpdateCurrentSelectionsCommand,
WsUpdateCurrentViewportCommand,
]