import {
    AIDesignLintUpdateAndZoomToSelectionCommand,
    GetPaintStyleNodeInfoByNodeIdCommand,
    Wukong,
} from '@wukong/bridge-proto'

import React, { useCallback, useEffect, useState } from 'react'
import { IconArrowDown12, MonoIconCommonQuestion16, Tooltip, WKButton, WKToast } from '../../../../../../../ui-lib/src'
import { useAIDesignLintService } from '../../../../../main/app-context'
import { useCommand } from '../../../../context/document-context'
import { useRenderColorSpace } from '../../../color-profile'
import { rgb2hex } from '../../../design/blend/color-picker/utils/color-translate'
import { PaintIcon } from '../../../paint-icon-color/paint-icon/paint-icon'
import {
    Card,
    CardInnerNavigation,
    CardProp,
    ImportedPaintStyleNode,
    roundTo2DecimalPlaces,
    useCardInnerNavigation,
    useSameResAndSimilarResProcess,
} from './card'
import classes from './card.module.less'
import { ExclusiveList } from './exclusive-list'

import classnames from 'classnames'
import { NodeId, Paint } from '../../../../../document/node/node'
import { PaintStyleThumbnail } from '../../../design/styles/style-color/style-thumbnail'
import { translation } from './color-card-list.translation'
import { EmptyPrompt } from './empty-prompt'
import { useStyleSelect } from './style-select'

export interface ColorSingleCardInfo {
    styleNode: ImportedPaintStyleNode
    nodePaintsInfoList: Wukong.DocumentProto.INodePaintsInfo[]
    type: Wukong.DocumentProto.PaintStyleMatchType
}

interface ColorCardProp extends CardProp {
    nodePaintsInfoList: Wukong.DocumentProto.INodePaintsInfo[]
    type: Wukong.DocumentProto.PaintStyleMatchType
}

const generatePaintDescription = (paint: Paint) => {
    const PaintType = Wukong.DocumentProto.PaintType
    let desc: string

    switch (paint.type) {
        case PaintType.PAINT_TYPE_SOLID_PAINT:
            desc = '#' + rgb2hex(paint.color.r, paint.color.g, paint.color.b).toUpperCase()
            break
        case PaintType.PAINT_TYPE_IMAGE_PAINT:
            desc = translation('Image')
            break
        case PaintType.PAINT_TYPE_GRADIENT_LINEAR:
            desc = translation('LinearGradient')
            break
        case PaintType.PAINT_TYPE_GRADIENT_RADIAL:
            desc = translation('RadialGradient')
            break
        case PaintType.PAINT_TYPE_GRADIENT_ANGULAR:
            desc = translation('AngularGradient')
            break
        case PaintType.PAINT_TYPE_GRADIENT_DIAMOND:
            desc = translation('DiamondGradient')
            break
        default:
            return ''
    }

    if (paint.opacity && paint.opacity !== 1) {
        desc += ' ' + roundTo2DecimalPlaces(paint.opacity * 100) + '%'
    }

    return desc
}
const generateStyleNodeDescription = (paintStyle: Wukong.DocumentProto.VPaintStyleNode | null) => {
    return paintStyle?.node !== '' ? paintStyle?.name : '-'
}

function ColorCard(prop: ColorCardProp) {
    const { deleteCard, nodePaintsInfoList, expanded, type, targetStyleNodeId, setTargetStyleNodeId } = prop
    const total = nodePaintsInfoList.length

    const colorSpace = useRenderColorSpace()
    const command = useCommand()
    const aiDesignLintService = useAIDesignLintService()

    const { curIdx, handleArrowDownClick, handleArrowUpClick, showCurrentIdx } = useCardInnerNavigation(
        total,
        expanded,
        (curIdx_) => {
            command.DEPRECATED_invokeBridge(AIDesignLintUpdateAndZoomToSelectionCommand, {
                nodeIds: [nodePaintsInfoList[curIdx_].nodeId] as NodeId[],
            })
        }
    )

    const curPaints = nodePaintsInfoList[curIdx].paints as Paint[]

    const { StyleSelect, refToAttachStyleSelect, closeStyleSelectionPanel } = useStyleSelect<HTMLDivElement>(
        'COLOR',
        setTargetStyleNodeId,
        targetStyleNodeId
    )

    // 展开时, 全选相关图层
    useEffect(() => {
        if (expanded) {
            command.DEPRECATED_invokeBridge(AIDesignLintUpdateAndZoomToSelectionCommand, {
                nodeIds: nodePaintsInfoList.map((info) => info.nodeId) as NodeId[],
            })

            command.commitUndo()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expanded])

    // 非展开状态下, 关闭样式选择面板
    useEffect(() => {
        if (!expanded) {
            closeStyleSelectionPanel()
        }
    }, [closeStyleSelectionPanel, expanded])

    // 全部替换
    const replaceAll = useCallback(
        (e: React.MouseEvent) => {
            const nodeIds = nodePaintsInfoList.map((info) => {
                return info.nodeId
            }) as NodeId[]

            aiDesignLintService.updatePaintStyle(command, nodeIds, targetStyleNodeId, type)
            if (deleteCard) {
                deleteCard()
            }

            WKToast.show(
                translation('ToastMessageBegin') +
                    nodeIds.length +
                    (nodeIds.length > 1 ? translation('ToastMessagePluralEnd') : translation('ToastMessageSingleEnd'))
            )
            e.stopPropagation()
        },
        [aiDesignLintService, command, deleteCard, nodePaintsInfoList, targetStyleNodeId, type]
    )

    const [currentSelectedStyldNodePaintStyle, setCurrentSelectedStyldNodePaintStyle] =
        useState<Wukong.DocumentProto.VPaintStyleNode | null>(null)

    useEffect(() => {
        setCurrentSelectedStyldNodePaintStyle(
            command.DEPRECATED_invokeBridge(GetPaintStyleNodeInfoByNodeIdCommand, {
                value: targetStyleNodeId,
            }) as Wukong.DocumentProto.VPaintStyleNode
        )
    }, [command, targetStyleNodeId])

    return (
        <div>
            <Card testId={'paint-style-card-' + type + '-' + nodePaintsInfoList[0].nodeId} expanded={expanded}>
                <>
                    <div
                        className={classes.card_container_item}
                        style={{
                            height: 'auto',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                        }}
                    >
                        <div className="flex content-center flex-col space-y-2">
                            {curPaints
                                .filter((paint) => paint.visible)
                                .map((paint, idx) => (
                                    <div key={idx} className="flex items-center space-x-2">
                                        {/* 缩略图 */}
                                        <PaintIcon
                                            className="!w-4 !h-4"
                                            focusNoneBorder
                                            paint={paint}
                                            colorSpace={colorSpace}
                                        ></PaintIcon>
                                        {/* 描述 */}
                                        <div>{generatePaintDescription(paint)}</div>
                                    </div>
                                ))}
                        </div>
                        <CardInnerNavigation
                            curIdx={curIdx}
                            showCurrentIdx={showCurrentIdx}
                            expanded={expanded}
                            total={total}
                            handleArrowDownClick={handleArrowDownClick}
                            handleArrowUpClick={handleArrowUpClick}
                        ></CardInnerNavigation>
                    </div>

                    {expanded && (
                        <div className={classnames(classes.card_container_item, 'space-x-2')}>
                            <div className={classes.text}>{translation('ReplaceWith')}</div>
                            <div
                                ref={refToAttachStyleSelect}
                                onClick={(e) => {
                                    e.stopPropagation()
                                }}
                                className={classnames(classes.target_desc, 'truncate')}
                            >
                                <div className="flex items-center space-x-3.5 truncate w-full">
                                    {currentSelectedStyldNodePaintStyle?.node !== '' && (
                                        <PaintStyleThumbnail
                                            paints={currentSelectedStyldNodePaintStyle?.paints as Paint[]}
                                            width={16}
                                            height={16}
                                            mode={'thumbnail'}
                                            testId={
                                                'paint-style-thumbnail-image-' +
                                                type +
                                                '-' +
                                                nodePaintsInfoList[0].nodeId
                                            }
                                        ></PaintStyleThumbnail>
                                    )}
                                    <div className="truncate w-full">
                                        {generateStyleNodeDescription(currentSelectedStyldNodePaintStyle)}
                                    </div>
                                </div>
                                <div className={classes.icon_container} style={{ pointerEvents: 'none' }}>
                                    <IconArrowDown12 color={'var(--wk-l2-label-color-gray-8)'} />
                                </div>
                            </div>
                            <WKButton
                                className={classes.replace_all_btn}
                                onClick={replaceAll}
                                type={'secondary'}
                                disabled={currentSelectedStyldNodePaintStyle?.node === ''}
                            >
                                {translation('ReplaceAll')}
                            </WKButton>
                        </div>
                    )}
                </>
            </Card>
            <StyleSelect />
        </div>
    )
}

export function ColorStyleCardList(prop: {
    cardInfoList: { sameStyleCardInfos: ColorSingleCardInfo[]; similarStyleCardInfos: ColorSingleCardInfo[] }
}) {
    const aiDesignLintService = useAIDesignLintService()
    const command = useCommand()

    const { cardInfoList } = prop

    const sameStyleCardInfos = cardInfoList.sameStyleCardInfos
        .slice()
        .sort((lhs, rhs) => rhs.nodePaintsInfoList.length - lhs.nodePaintsInfoList.length)
        .map((info) => {
            return { info: info, targetStyleNodeId: info.styleNode.node }
        })

    const similarStyleCardInfos = cardInfoList.similarStyleCardInfos
        .slice()
        .sort((lhs, rhs) => rhs.nodePaintsInfoList.length - lhs.nodePaintsInfoList.length)
        .map((info) => {
            return { info: info, targetStyleNodeId: info.styleNode.node }
        })

    const { ignoreItemByIndex, setTargetStyleNodeIdByIndex, allInfos, avaliableSimilarIndexes, avaliableSameIndexes } =
        useSameResAndSimilarResProcess(sameStyleCardInfos, similarStyleCardInfos)

    const sameStyleCountToReplace = avaliableSameIndexes.reduce((num, index) => {
        if (allInfos[index].targetStyleNodeId === '') {
            return num
        } else {
            return num + allInfos[index].info.nodePaintsInfoList.length
        }
    }, 0)

    const similarStyleCountToReplace = avaliableSimilarIndexes.reduce((num, index) => {
        if (allInfos[index].targetStyleNodeId === '') {
            return num
        } else {
            return num + allInfos[index].info.nodePaintsInfoList.length
        }
    }, 0)

    const replaceAll = useCallback(
        (indexes: number[]) => {
            let num = 0

            const batch = indexes
                .filter((index) => allInfos[index].targetStyleNodeId !== '')
                .map((index) => {
                    ignoreItemByIndex(index)

                    const card = allInfos[index].info
                    const nodeIds = card.nodePaintsInfoList.map((info) => info.nodeId) as NodeId[]

                    num += nodeIds.length

                    return { nodeIds: nodeIds, paintStyleId: card.styleNode.node, type: card.type }
                })

            aiDesignLintService.batchUpdatePaintStyle(command, batch)

            WKToast.show(
                translation('ToastMessageBegin') +
                    num +
                    (num ? translation('ToastMessagePluralEnd') : translation('ToastMessageSingleEnd'))
            )
        },
        [aiDesignLintService, allInfos, command, ignoreItemByIndex]
    )

    const replaceAllWithSameStyleAsTemplace = useCallback(
        (e: React.MouseEvent) => {
            replaceAll(avaliableSameIndexes)
            e.stopPropagation()
        },
        [avaliableSameIndexes, replaceAll]
    )

    const replaceAllWithSimilarStyleAsTemplace = useCallback(
        (e: React.MouseEvent) => {
            replaceAll(avaliableSimilarIndexes)
            e.stopPropagation()
        },
        [avaliableSimilarIndexes, replaceAll]
    )

    const renderItem = useCallback(
        (index: number, expanded: boolean) => {
            const cardInfo = allInfos[index].info
            return (
                <>
                    <ColorCard
                        deleteCard={() => {
                            ignoreItemByIndex(index)
                        }}
                        nodePaintsInfoList={cardInfo.nodePaintsInfoList}
                        expanded={expanded}
                        type={cardInfo.type}
                        targetStyleNodeId={allInfos[index].targetStyleNodeId}
                        setTargetStyleNodeId={(styleNodeId) => {
                            setTargetStyleNodeIdByIndex(index, styleNodeId)
                        }}
                    ></ColorCard>
                </>
            )
        },
        [allInfos, ignoreItemByIndex, setTargetStyleNodeIdByIndex]
    )

    const [expandedId, setExpandedId] = useState<number | null>(null)

    return (
        <>
            {avaliableSameIndexes.length === 0 && avaliableSimilarIndexes.length === 0 && (
                <EmptyPrompt texts={[translation('EmptyPrompt')]} />
            )}

            {avaliableSameIndexes.length > 0 && (
                <>
                    <div className={classes.result_tips}>
                        <div>
                            <div className="color-$wk-l2-label-color-gray-13 wk-text-12 wk-font-medium">
                                {translation('Tip1')}
                            </div>
                            <Tooltip title={translation('Tip1Extra')} firstDelay={100}>
                                <MonoIconCommonQuestion16 data-testid="ai-design-lint-same-style-tooltip" />
                            </Tooltip>
                        </div>
                        <a
                            data-testid="ai-design-lint-replace-all-same-items"
                            onClick={replaceAllWithSameStyleAsTemplace}
                            style={{ pointerEvents: sameStyleCountToReplace === 0 ? 'none' : 'auto' }}
                        >
                            {translation('ReplaceAll')} {' ' + sameStyleCountToReplace}
                        </a>
                    </div>
                    <div className={classes.card_list_container_container}>
                        <ExclusiveList
                            renderItem={renderItem}
                            expandedId={expandedId}
                            setExpandedId={setExpandedId}
                            indexes={avaliableSameIndexes}
                        ></ExclusiveList>
                    </div>
                </>
            )}

            {avaliableSimilarIndexes.length > 0 && avaliableSameIndexes.length > 0 && (
                <div className={classes.horizontal_line}></div>
            )}

            {avaliableSimilarIndexes.length > 0 && (
                <>
                    <div className={classes.result_tips}>
                        <div>
                            <div className="color-$wk-l2-label-color-gray-13 wk-text-12 wk-font-medium">
                                {translation('Tip2')}
                            </div>
                            <Tooltip title={translation('Tip2Extra')} firstDelay={100}>
                                <MonoIconCommonQuestion16 data-testid="ai-design-lint-similar-style-tooltip" />
                            </Tooltip>
                        </div>
                        <a
                            data-testid="ai-design-lint-replace-all-similar-items"
                            onClick={replaceAllWithSimilarStyleAsTemplace}
                            style={{ pointerEvents: similarStyleCountToReplace === 0 ? 'none' : 'auto' }}
                        >
                            {translation('ReplaceAll')}
                            {' ' + similarStyleCountToReplace}
                        </a>
                    </div>
                    <div className={classes.card_list_container_container}>
                        <ExclusiveList
                            renderItem={renderItem}
                            title={''}
                            expandedId={expandedId}
                            setExpandedId={setExpandedId}
                            indexes={avaliableSimilarIndexes}
                        ></ExclusiveList>
                    </div>
                </>
            )}
        </>
    )
}
