import { MouseEventHandler, useCallback, useEffect, useMemo, useState } from 'react'
import { MonoIconCommonArrowRight16, WKButton, WKTypography } from '../../../../../ui-lib/src'
import { isEnglishLanguage } from '../../../../../util/src'
import type { LibraryContentVO, LibraryVO } from '../../../kernel/interface/library'
import { LibraryTestId } from '../../../window'
import { useLibraryRemoteSearchService } from '../hook/use-library-service-remote-search'
import {
    LibraryContentComponentDetailViewer,
    LibraryContentStyleDetailViewer,
    LibraryContentVariableDetailViewer,
    LibraryContentVariableSetDetailViewer,
} from '../library-control-list-item-detail/library-control-list-item-detail'
import { LibrarySubscribeSwitch } from '../library-subscribe-switch/library-subscribe-switch'
import classes from './library-control-list-item.module.less'
import { translation } from './library-control-list-item.translation'

// 单组件库 ListItem（有订阅、打开详情、跳转发布等操作按钮）
export function LibraryControlListItem(props: { library: LibraryVO }) {
    const { states: libraryRemoteSearchServiceStates } = useLibraryRemoteSearchService()
    const librarySearchKeyword = libraryRemoteSearchServiceStates.use.librarySearchKeywordState()
    const libraryQueryResponse = libraryRemoteSearchServiceStates.use.libraryQueryResponseState()

    const matchedLibraryContent = useMemo<LibraryContentVO>(
        () => ({
            library: libraryRemoteSearchServiceStates.getState().librarySearchKeywordState
                ? {
                      ...props.library,
                      componentCount: (libraryQueryResponse?.libraryId2MatchedComponentList[props.library.id] ?? [])
                          .length,
                      styleCount: (libraryQueryResponse?.libraryId2MatchedStyleList[props.library.id] ?? []).length,
                      variableCount: (libraryQueryResponse?.libraryId2MatchedVariableList[props.library.id] ?? [])
                          .length,
                      variableCollectionCount: (
                          libraryQueryResponse?.libraryId2MatchedVariableCollectionList[props.library.id] ?? []
                      ).length,
                  }
                : props.library,
            components: libraryQueryResponse?.libraryId2MatchedComponentList[props.library.id] ?? [],
            styles: libraryQueryResponse?.libraryId2MatchedStyleList[props.library.id] ?? [],
            componentSets: [],
            componentSetId2ChildrenComponents: {},
            libraryMovedInfos: {
                componentMoveRemappings: {},
                styleMoveRemappings: {},
                libraryId2LibraryInfo: {},
            },
            variableSets: libraryQueryResponse?.libraryId2MatchedVariableCollectionList?.[props.library.id] ?? [],
            variables: libraryQueryResponse?.libraryId2MatchedVariableList?.[props.library.id] ?? [],
        }),
        [libraryRemoteSearchServiceStates, libraryQueryResponse, props.library]
    )

    return matchedLibraryContent ? (
        <LibraryContentListItem matchedLibraryContent={matchedLibraryContent} isSearching={!!librarySearchKeyword} />
    ) : (
        <></>
    )
}

export function LibraryContentListItem(props: { matchedLibraryContent: LibraryContentVO; isSearching: boolean }) {
    const { jump2DetailDoc: jump2DetailDoc_, openDetail: openDetail_ } = useLibraryRemoteSearchService()

    // 跳转组件母版 doc
    const jump2DetailDoc: MouseEventHandler = useCallback(
        (event) => {
            event.stopPropagation()
            if (props.matchedLibraryContent.library.document?.id) {
                jump2DetailDoc_(props.matchedLibraryContent.library.document.id)
            }
        },
        [jump2DetailDoc_, props.matchedLibraryContent.library.document?.id]
    )

    // 打开详情页
    const openDetail = useCallback(() => {
        if (props.matchedLibraryContent.library.document?.id) {
            openDetail_({
                libraryId: props.matchedLibraryContent.library.id,
                docId: props.matchedLibraryContent.library.document.id,
            })
        }
    }, [openDetail_, props.matchedLibraryContent.library.document?.id, props.matchedLibraryContent.library.id])

    const libraryName = props.matchedLibraryContent.library.document?.name || translation('UnknownLibrary')

    return (
        <>
            <div
                className={classes.container}
                onClick={openDetail}
                data-testid={LibraryTestId.HomeModal.RemoteLibraryItem(
                    props.matchedLibraryContent.library.document?.name ?? ''
                )}
            >
                <div className={classes.leftWrapper}>
                    <LibrarySubscribeSwitch
                        libraryId={props.matchedLibraryContent.library.id}
                        libraryName={libraryName}
                    />
                    <WKTypography.Text className={classes.name}>{libraryName}</WKTypography.Text>
                </div>
                <div className={classes.rightWrapper}>
                    <WKButton size="small" type="secondary" className={classes.detailBtn} onClick={jump2DetailDoc}>
                        {translation('OpenFile')}
                    </WKButton>
                    <LibraryComponentStyleCountInfo
                        variableCount={props.matchedLibraryContent.library.variableCount ?? 0}
                        variableCollectionCount={props.matchedLibraryContent.library.variableCollectionCount ?? 0}
                        componentCount={props.matchedLibraryContent.library.componentCount ?? 0}
                        styleCount={props.matchedLibraryContent.library.styleCount ?? 0}
                        isSearch={props.isSearching}
                    />
                    <MonoIconCommonArrowRight16 className="color-$wk-l2-label-color-gray-8" />
                </div>
            </div>
            {props.isSearching && (
                <>
                    {props.matchedLibraryContent.variables.length ? (
                        <LibraryContentVariableDetailViewer styles={props.matchedLibraryContent.variables} />
                    ) : props.matchedLibraryContent.variableSets.length ? (
                        <LibraryContentVariableSetDetailViewer styles={props.matchedLibraryContent.variableSets} />
                    ) : null}
                    <LibraryContentStyleDetailViewer styles={props.matchedLibraryContent.styles} />
                    <LibraryContentComponentDetailViewer components={props.matchedLibraryContent.components} />
                </>
            )}
        </>
    )
}

// 单组件库展示内容数目信息
export function LibraryComponentStyleCountInfo(props: {
    componentCount: number
    styleCount: number
    variableCount: number
    variableCollectionCount: number
    isSearch: boolean
}) {
    const [countDesc, setCountDesc] = useState('')
    useEffect(() => {
        const texts: string[] = []

        if (props.componentCount) {
            texts.push(
                `${props.componentCount} ${
                    isEnglishLanguage() ? (props.componentCount === 1 ? 'component' : 'components') : '个组件'
                }`
            )
        }
        if (props.styleCount) {
            texts.push(
                `${props.styleCount} ${isEnglishLanguage() ? (props.styleCount === 1 ? 'style' : 'styles') : '个样式'}`
            )
        }
        if (props.variableCount) {
            texts.push(
                `${props.variableCount} ${
                    isEnglishLanguage() ? (props.variableCount === 1 ? 'variable' : 'variables') : '个变量'
                }`
            )
        } else if (props.variableCollectionCount) {
            texts.push(
                `${props.variableCollectionCount} ${
                    isEnglishLanguage()
                        ? props.variableCollectionCount === 1
                            ? 'variable collection'
                            : 'variable collections'
                        : '个变量合集'
                }`
            )
        }
        setCountDesc(
            texts.length
                ? `${props.isSearch ? translation('MatchTo') : ''}${texts.join(isEnglishLanguage() ? ', ' : '，')}`
                : ''
        )
    }, [props.componentCount, props.styleCount, props.isSearch, props.variableCollectionCount, props.variableCount])

    return (
        <WKTypography.Text color="placeholder" className={classes.countInfo}>
            {countDesc}
        </WKTypography.Text>
    )
}
