import { MonoIconCommonArrowRight16, WKDivider, WKTypography } from '../../../../../../ui-lib/src'
import { useLibraryRemoteSearchService } from '../../../../share/component-style-library/hook/use-library-service-remote-search'
import { LibraryContentListItem } from '../../../../share/component-style-library/library-control-list-item/library-control-list-item'
import classes from '../../../../share/component-style-library/library-control-list-item/library-control-list-item.module.less'
import styles from '../../../../share/component-style-library/library-team-info-list-item/library-team-info-list-item.module.less'
import { LibraryTestId } from '../../../../window'
import { translation } from './library-remote-used-list.translation'
import { useLibraryRemoteUsedAndMissingListContext } from './use-library-remote-used-hook'

// 当前文档引用到的所有组件库（包括组件、组件集、样式）
export function LibraryRemoteUsedList(props: { isSearching: boolean }) {
    const libraryRemoteSearchService = useLibraryRemoteSearchService()
    const librarySearchError = libraryRemoteSearchService.states.use.librarySearchErrorState()
    const librarySearchLoading = libraryRemoteSearchService.states.use.librarySearchLoadingState()
    const { inUsedLibraryContentList, missingLibraryInfoList, onClickMissingJumpBtn } =
        useLibraryRemoteUsedAndMissingListContext()

    return !props.isSearching &&
        !librarySearchLoading &&
        !librarySearchError &&
        (inUsedLibraryContentList.length || missingLibraryInfoList.length) ? (
        <div data-testid={LibraryTestId.HomeModal.InUsedList}>
            <WKTypography.Paragraph color="placeholder" className={styles.title}>
                {translation('LibrariesUsedIn')}
            </WKTypography.Paragraph>
            {inUsedLibraryContentList.map((libraryContent) => (
                <LibraryContentListItem
                    key={libraryContent.library.id}
                    matchedLibraryContent={libraryContent}
                    isSearching={props.isSearching}
                />
            ))}
            {!!missingLibraryInfoList.length && (
                <div
                    className={classes.container}
                    onClick={onClickMissingJumpBtn}
                    data-testid={LibraryTestId.HomeModal.OpenMissingListBtn}
                >
                    <span className={classes.leftWrapper}>
                        {translation('Includes')} {missingLibraryInfoList.length}{' '}
                        {translation('MissingLibrary', {
                            library: missingLibraryInfoList.length === 1 ? 'library' : 'libraries',
                        })}
                    </span>
                    <MonoIconCommonArrowRight16 className="color-$wk-l2-label-color-gray-8" />
                </div>
            )}
            <WKDivider className={styles.divider} />
        </div>
    ) : null
}
