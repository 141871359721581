import { Wukong } from '@wukong/bridge-proto'
import { useMemo } from 'react'
import { SolidPaint } from '../../../../document/node/node'
import { environment } from '../../../../environment'
import { useViewState } from '../../../../view-state-bridge'
import { usePrototypeDevice } from './hook'
import { translation } from './prototype-device-thumbnail.translation'

export const PrototypeDeviceThumbnail = () => {
    const paint = useViewState('prototypeBackgroundColor')?.paint as SolidPaint
    const deviceConfig = useViewState('prototypeDevice')?.config
    const { deviceOptions } = usePrototypeDevice()

    const rotate = deviceConfig?.rotation == Wukong.DocumentProto.DeviceRotation.CCW_90 ? -90 : 0

    const deviceInfo = useMemo(() => {
        if (!deviceConfig) {
            return undefined
        }
        return deviceOptions.find((device) => device.presetIdentifier === deviceConfig.presetIdentifier)
    }, [deviceConfig, deviceOptions])
    if (!deviceInfo) {
        return <></>
    }
    if (deviceInfo.type != Wukong.DocumentProto.PrototypeDeviceType.PROTOTYPE_DEVICE_TYPE_PRESET) {
        return <></>
    }
    // 此处忽略透明度
    const rgb = paint.color
    return (
        <div data-testid="prototype-device-thumbnial" className="py-2">
            <div className="wk-text-12 color-$wk-l2-label-color-gray-8 mb-1">{translation('Preview')}</div>
            <div
                style={{ backgroundColor: `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})` }}
                className="flex items-center justify-between rounded-3px h-130px box-border overflow-hidden p-10px"
            >
                <img
                    style={{
                        transform: `rotate(${rotate}deg)`,
                    }}
                    data-testid="prototype-device-thumbnial-img"
                    src={`${environment.ossCdnPath}${environment.publicResourcePrefix}/device/${deviceInfo.thumbnailUrl}`}
                    className="h-full m-auto transition"
                />
            </div>
        </div>
    )
}
