import { ApplyHtmlAIGenCommand, Wukong } from '@wukong/bridge-proto'
import { CommandInvoker } from '../../../../document/command/command-invoker'
import { CommitType } from '../../../../document/command/commit-type'
import { readFromFile } from '../../../../document/util/file'
import { environment, HttpPrefixKey } from '../../../../environment'
import { FontInfoExt } from '../../../../main/font/interface'
import { getInlineNodeFills } from './nodes/inline-node'
import { isTextLikeInput, makeCheckbox, makeColor, makeDate, makeRadio, makeRange } from './nodes/input'
import { makePseudoNode } from './nodes/pseudo-node'
import { getFontName, getReverseText, makeTextNode, parseTextDecoration } from './nodes/text-node'
import { ARROW_DOWN_SVG } from './static/icons'
import { addEffects, tryAddStrokes } from './utils/attrs-modify'
import { dom2ImageScript } from './utils/dom-to-image'
import { getJustifyContent, isExtractFlex, isFlex } from './utils/flex-utils'
import { parseGradient } from './utils/gradient'
import {
    convertBorderRadius,
    getActiveBorderColor,
    getMargin,
    getNextNodeId,
    getPadding,
    getShadow,
    getSVGString,
    getTransformMatrix,
    hasPseudoElement,
    isElement,
    isRotate,
    OrderIndexUtil,
    parentInfo,
    parseRGBA,
} from './utils/utils'

type Position = 'static' | 'relative' | 'absolute' | 'fixed' | 'sticky'

const LAYOUT_NODE_NAMES = [
    'BODY',
    'DIV',
    'NAV',
    'HEADER',
    'SECTION',
    'FOOTER',
    'P',
    'H1',
    'H2',
    'H3',
    'H4',
    'H5',
    'H6',
    'BUTTON',
    'SPAN',
    'A',
    'UL',
    'OL',
    'LI',
    'svg',
    'IMG',
    'ASIDE',
    'MAIN',
    'ARTICLE',
    'TABLE',
    'THEAD',
    'TBODY',
    'TFOOT',
    'TR',
    'TD',
    'TH',
    'SELECTION',
    'INPUT',
    'TEXTAREA',
    'LABEL',
    'FORM',
    'SELECT',
    'BLOCKQUOTE',
    'SUP',
    'BDI',
    'STRONG',
]

const positionOrder: Record<Position, number> = {
    static: 0,
    relative: 1,
    absolute: 1,
    fixed: 2,
    sticky: 3,
}

const overflowHiddenLike = ['hidden', 'scroll', 'auto', 'overlay', 'clip']

function getSvgRatio(svg: SVGSVGElement) {
    const viewBox = svg.viewBox.baseVal
    return viewBox.width / viewBox.height
}

function getSubsetRect(rectA: DOMRect, rectB: DOMRect) {
    // 判断 rectA 是否完全在 rectB 内
    const isAInsideB =
        rectA.left >= rectB.left && rectA.right <= rectB.right && rectA.top >= rectB.top && rectA.bottom <= rectB.bottom

    // 判断 rectB 是否完全在 rectA 内
    const isBInsideA =
        rectB.left >= rectA.left && rectB.right <= rectA.right && rectB.top >= rectA.top && rectB.bottom <= rectA.bottom

    // 根据子集关系返回子集的矩形
    if (isAInsideB) {
        return rectA
    } else if (isBInsideA) {
        return rectB
    } else {
        return null
    }
}

function getUnionRect(rect1: DOMRect | null, rect2: DOMRect | null) {
    if (!rect1) {
        return rect2
    }
    if (!rect2) {
        return rect1
    }
    const x = Math.min(rect1.x, rect2.x) // 左边界取最小值
    const y = Math.min(rect1.y, rect2.y) // 上边界取最小值
    const right = Math.max(rect1.x + rect1.width, rect2.x + rect2.width) // 右边界取最大值
    const bottom = Math.max(rect1.y + rect1.height, rect2.y + rect2.height) // 下边界取最大值

    return new DOMRect(x, y, right - x, bottom - y) // 返回新的DOMRect对象
}

function getClientRect(range: Range) {
    const rects = range.getClientRects()

    // 存在包含关系时, 认为子集是被截断的, 返回子集(91247)
    if (rects.length === 2) {
        const subsetRect = getSubsetRect(rects[0], rects[1])
        if (subsetRect) {
            return new DOMRect(subsetRect.x, subsetRect.y, subsetRect.width, subsetRect.height)
        }
    }
    // 否则是多行的情况, 返回全集
    return range.getBoundingClientRect()
}
function splitPolygonString(str: string) {
    const calcRegex = /calc\(([^)]+)\)/g
    str = str.replace(calcRegex, (match, p1) => {
        // 替换calc内的空格为逗号
        return `calc(${p1.replace(/\s+/g, ',')})`
    })

    // 使用空格拆分字符串
    const parts = str.split(' ')

    // 如果有多个部分，将其分为前后两段
    return [parts[0], parts.slice(1).join(' ')]
}

function parseCalcToPx(calc: string, total: number) {
    const calcStr = calc.match(/^calc\((.*?)\)$/)?.[1]
    if (!calcStr) {
        return calc
    }
    // 分割字符串并提取运算符和数值
    const parts = calcStr.split(',')

    // 提取百分比部分并转换为px
    const num1 = parts[0].trim()
    const operator = parts[1].trim()
    const num2 = parts[2].trim()

    let float1: number
    let float2: number

    // 判断第一个数值是否是百分比
    if (num1.endsWith('%')) {
        float1 = (parseFloat(num1) / 100) * total
    } else {
        float1 = parseFloat(num1)
    }

    // 处理第二个数值的单位
    if (num2.endsWith('%')) {
        float2 = (parseFloat(num2) / 100) * total
    } else {
        float2 = parseFloat(num2)
    }

    // 执行运算
    let result
    switch (operator) {
        case '+':
            result = float1 + float2
            break
        case '-':
            result = float1 - float2
            break
        case '*':
            result = float1 * float2
            break
        case '/':
            result = float1 / float2
            break
        default:
            result = 0
    }

    return result + 'px'
}

function parseClipPath(clipPath: string, width: number, height: number) {
    // 提取 polygon 的点字符串
    const pointsStr = clipPath.match(/^polygon\((.*?)\)$/)?.[1]
    if (!pointsStr) {
        return null
    }

    // 分割成每个点的坐标
    const points = pointsStr.split(', ').map((point) => {
        let [x, y] = splitPolygonString(point)
        x = parseCalcToPx(x, width)
        y = parseCalcToPx(y, height)
        if (x.includes('px')) {
            x = ((parseFloat(x.replace('px', '')) / width) * 100).toString()
        }
        if (y.includes('px')) {
            y = ((parseFloat(y.replace('px', '')) / height) * 100).toString()
        }
        return { x: parseFloat(x) / 100, y: parseFloat(y) / 100 } // 转为 0-1 的比例
    })

    // 计算 x, y, width, height
    const xMin = Math.min(...points.map((p) => p.x))
    const xMax = Math.max(...points.map((p) => p.x))
    const yMin = Math.min(...points.map((p) => p.y))
    const yMax = Math.max(...points.map((p) => p.y))

    return {
        x: xMin,
        y: yMin,
        width: xMax - xMin,
        height: yMax - yMin,
    }
}

function parseClip(rectStr: string) {
    const match = rectStr.match(/rect\(\s*(\d+)(?:px)?\s*,\s*(\d+)(?:px)?\s*,\s*(\d+)(?:px)?\s*,\s*(\d+)(?:px)?\s*\)/)
    if (!match) {
        return null
    }

    const [_, top, right, bottom, left] = match.map(Number)
    return {
        x: left,
        y: top,
        width: right - left,
        height: bottom - top,
    }
}

interface ImportHTMLContext {
    allFonts: FontInfoExt[]
}

let importHTMLContext: ImportHTMLContext = {
    allFonts: [],
}
function nodeHasRotate(window: Window, node: Element) {
    const transform = window.getComputedStyle(node, null).transform
    const matrix = getTransformMatrix(transform)
    if (!matrix) {
        return false
    }
    return isRotate(matrix)
}
function getActualDimensions(window: Window, node: Element, parentHasRotate: boolean) {
    if (!parentHasRotate && !nodeHasRotate(window, node)) {
        const boundingRect = node.getBoundingClientRect()
        return { width: boundingRect.width, height: boundingRect.height }
    }

    if (Object.prototype.toString.call(node).startsWith('[object HTML')) {
        return { width: (node as HTMLElement).offsetWidth, height: (node as HTMLElement).offsetHeight }
    }
    return { width: node.clientWidth, height: node.clientHeight }
}

const getWidth = (window: Window, node: Element, parentHasRotate: boolean) => {
    return getActualDimensions(window, node, parentHasRotate).width
}

const getHeight = (window: Window, node: Element, parentHasRotate: boolean) => {
    return getActualDimensions(window, node, parentHasRotate).height
}

function clacOffset(window: Window, node: HTMLElement) {
    const parent = node.parentElement
    if (!parent) {
        throw new Error('No parent element')
    }
    return {
        left: node.getBoundingClientRect().left - parent.getBoundingClientRect().left,
        top: node.getBoundingClientRect().top - parent.getBoundingClientRect().top,
    }
}

class SVGProcessor {
    private _outerHTMLBackup: string

    constructor(private _root: SVGElement) {
        this._outerHTMLBackup = this._root.outerHTML
    }

    public process(): SVGElement {
        this._processInlineComputedStyle()
        return this._root
    }

    public destroy() {
        this._root.outerHTML = this._outerHTMLBackup
    }

    private _processInlineComputedStyle() {
        this._traverseSvgTree((el) => {
            const computedStyle = getComputedStyle(el)

            const width = this._convertLength(computedStyle.width)
            const paddingLeft = +computedStyle.paddingLeft.replace('px', '')
            const paddingRight = +computedStyle.paddingRight.replace('px', '')
            const paddingTop = +computedStyle.paddingTop.replace('px', '')
            const paddingBottom = +computedStyle.paddingBottom.replace('px', '')
            const fontWeight = computedStyle.fontWeight
            const widthValue = width ? +width.replace('px', '') - paddingLeft - paddingRight + 'px' : null
            const height = this._convertLength(computedStyle.height)
            const heightValue = height ? +height.replace('px', '') - paddingTop - paddingBottom + 'px' : null
            const fontSize = this._convertLength(computedStyle.fontSize)
            const fill = this._convertColor(computedStyle.fill)
            const stroke = this._convertColor(computedStyle.stroke)
            const opacity = this._convertFloat(computedStyle.opacity)
            // const transform = computedStyle.transform
            if (widthValue) {
                el.setAttribute('width', widthValue)
            }
            if (heightValue) {
                el.setAttribute('height', heightValue)
            }
            if (fontSize) {
                el.setAttribute('font-size', fontSize)
            }
            if (opacity !== null) {
                const n = Math.max(0, Math.min(1, opacity))
                el.setAttribute('opacity', `${n}`)
            }
            el.setAttribute('fill', fill)
            el.setAttribute('stroke', stroke)
            if (paddingTop || paddingLeft) {
                el.setAttribute('transform', `translate(${paddingLeft || 0}, ${paddingTop || 0})`)
            }
            if (el.tagName === 'tspan' || el.tagName === 'text') {
                const fontName = getFontName(
                    computedStyle.fontFamily,
                    +computedStyle.fontWeight,
                    computedStyle.fontStyle,
                    computedStyle.fontStretch,
                    importHTMLContext.allFonts,
                    false
                )
                if (fontName.family?.length) {
                    el.setAttribute('font-family', fontName.family)
                }
                if (fontName.style) {
                    el.setAttribute('font-weight', fontWeight)
                }
            }
            // el.setAttribute('transform', transform)
        })
    }

    private _traverseSvgTree(on: (el: Element) => void) {
        function work(node: Element) {
            on(node)

            for (const child of node.children) {
                work(child)
            }
        }
        work(this._root)
    }

    private _convertLength(s: string): string | null {
        if (s.endsWith('px')) {
            return s
        }
        return null
    }
    private _convertColor(col: string): string {
        return col
    }
    private _convertFloat(n: string): number | null {
        const f = parseFloat(n)
        if (!Number.isNaN(f)) {
            return f
        }
        return null
    }
}

function hasPreInAncestors(node: HTMLElement) {
    if (node.parentElement) {
        const parentComputedStyle = window.getComputedStyle(node.parentElement, null)
        if (parentComputedStyle.whiteSpace.includes('pre')) {
            return true
        }
    }

    while (node.parentElement) {
        if (node.parentElement.nodeName === 'PRE') {
            return true
        }
        node = node.parentElement
    }
    return false
}

function getRelativeTransform(element: HTMLElement) {
    const parent = element.parentElement
    if (!parent) {
        return new DOMMatrix()
    }

    const childWorld = calculateWorldTransform(element)
    const parentWorld = calculateWorldTransform(parent)
    const parentInverse = parentWorld.inverse()
    return parentInverse.multiply(childWorld)
}

function calculateWorldTransform(element: HTMLElement): DOMMatrix {
    // 1. 收集所有原始变换矩阵
    const transforms: DOMMatrix[] = []
    let currentElement: HTMLElement | null = element

    while (currentElement) {
        const style = getComputedStyle(currentElement)
        const transform = style.transform
        transforms.push(transform !== 'none' ? new DOMMatrix(transform) : new DOMMatrix())
        currentElement = currentElement.parentElement
    }

    // 2. 累积变换矩阵（从根到当前元素）
    const cumulativeTransform = transforms.reverse().reduce((acc, matrix) => acc.multiply(matrix), new DOMMatrix())

    // 3. 统一移除累积平移
    cumulativeTransform.e = 0
    cumulativeTransform.f = 0

    // 4. 计算补偿平移
    const rect = element.getBoundingClientRect()
    const actualCenter = {
        x: rect.left + rect.width / 2,
        y: rect.top + rect.height / 2,
    }
    const localCenter = new DOMPoint(
        (element.offsetWidth ?? element.clientWidth) / 2,
        (element.offsetHeight ?? element.clientHeight) / 2
    )
    const transformedCenter = cumulativeTransform.transformPoint(localCenter)
    const compensationX = actualCenter.x - transformedCenter.x
    const compensationY = actualCenter.y - transformedCenter.y

    return new DOMMatrix([
        cumulativeTransform.a,
        cumulativeTransform.b,
        cumulativeTransform.c,
        cumulativeTransform.d,
        cumulativeTransform.e + compensationX,
        cumulativeTransform.f + compensationY,
    ])
}

// 计算node受矩阵影响后的相对位置
function computeRelativeTransformMatrixEffect(node: HTMLElement, matrix: DOMMatrix) {
    const worldTransform = calculateWorldTransform(node)
    const newWorldTransform = worldTransform.multiply(matrix)
    if (node.parentElement) {
        const parentInverseWorldTransform = calculateWorldTransform(node.parentElement as HTMLElement).inverse()
        const newRelativeTransform = parentInverseWorldTransform.multiply(newWorldTransform)
        return newRelativeTransform
    }
    return newWorldTransform
}

function traverseDom(
    window: Window,
    nodes: Wukong.DocumentProto.ISynergyNode[],
    svgData: { parentNodeId: string; data: string }[],
    node: HTMLElement,
    parentId: string,
    taskId?: number,
    mergedChildrenForText?: ChildNode[],
    parentHasRotate = false,
    useFlex = false,
    useTextRealSize = false,
    isOnlyChild = false
): void {
    if (node.nodeName === 'HEAD' || node.nodeName === 'STYLE' || node.nodeName === 'OPTION') {
        return
    }

    let id = parentId
    let hasRotate = false
    if (node.nodeType === Node.TEXT_NODE || node.nodeType === Node.COMMENT_NODE) {
        if (!node.textContent?.trim()) {
            return
        }
        const range = document.createRange()
        range.selectNodeContents(node)

        let extra: {
            text: string
            rect: DOMRect | null
        } = {
            text: '',
            rect: null,
        }
        const textSegments: Wukong.DocumentProto.IStyledTextSegment[] = []

        const isPre = hasPreInAncestors(node)

        let finalText = isPre
            ? (node.textContent + extra.text).trim()
            : ((node.textContent ?? '') + extra.text).replace(/\s+/g, ' ').trim()
        finalText = getReverseText(window, node, finalText)

        if (mergedChildrenForText && mergedChildrenForText.length > 0) {
            extra = mergedChildrenForText.reduce((acc, child) => {
                let text =
                    (child.nodeType === Node.TEXT_NODE ? child.textContent : (child as HTMLElement).innerText) ?? ''
                let rect
                if (child.nodeType === Node.TEXT_NODE) {
                    const childRange = document.createRange()
                    childRange.selectNodeContents(child)
                    rect = getClientRect(childRange)
                } else {
                    rect = (child as HTMLElement).getBoundingClientRect()
                }

                text = getReverseText(window, child, text)

                return {
                    text: acc.text + text,
                    rect: getUnionRect(acc.rect, rect),
                }
            }, extra)

            finalText = ((node.textContent ?? '') + extra.text).replace(/\s+/g, ' ').trim()

            const textInfo = mergedChildrenForText.map((child) => {
                let cleanCharacters = (
                    (child.nodeType === Node.TEXT_NODE ? child.textContent : (child as HTMLElement).innerText) ?? ''
                )
                    .replace(/\s+/g, ' ')
                    .trim()
                const fills = getInlineNodeFills(window, child)
                let childElement = child as HTMLElement
                if (child.nodeType === Node.TEXT_NODE) {
                    childElement = child.parentElement!
                }
                const computedStyle = window.getComputedStyle(childElement, null)
                const fontSize = computedStyle.fontSize

                cleanCharacters = getReverseText(window, child, cleanCharacters)

                return {
                    cleanCharacters,
                    fills,
                    fontSize: +fontSize.replace('px', ''),
                    fontName: getFontName(
                        computedStyle.fontFamily,
                        +computedStyle.fontWeight,
                        computedStyle.fontStyle,
                        computedStyle.fontStretch,
                        importHTMLContext.allFonts,
                        useTextRealSize
                    ),
                    textDecoration: parseTextDecoration(computedStyle.textDecoration),
                }
            })

            let startIndex = (node.textContent ?? '').replace(/\s+/g, ' ').trim().length

            for (const character of textInfo) {
                const start = finalText.indexOf(character.cleanCharacters, startIndex)
                const end = start + character.cleanCharacters.length
                startIndex = end
                textSegments.push({
                    start,
                    end,
                    fills: character.fills,
                    textStyleEditedProps: [
                        Wukong.DocumentProto.NodeProps.NP_fills,
                        Wukong.DocumentProto.NodeProps.NP_fontSize,
                        Wukong.DocumentProto.NodeProps.NP_fontName,
                        Wukong.DocumentProto.NodeProps.NP_textDecoration,
                    ],
                    fontSize: character.fontSize,
                    fontName: character.fontName,
                    textDecoration: character.textDecoration,
                })
            }
        }

        let rect: DOMRect | null = null

        rect = getUnionRect(getClientRect(range), extra.rect)!

        // 普通#text
        const textNode = makeTextNode(
            window,
            node.parentElement!,
            getNextNodeId(),
            id,
            rect,
            finalText,
            importHTMLContext.allFonts,
            textSegments,
            '',
            useFlex,
            useTextRealSize
        )
        setImportFromHtmlNodeId(textNode, node)
        nodes.push(textNode)
        return
    }

    if (node.nodeName !== 'SCRIPT' && node.nodeName !== 'HTML') {
        id = getNextNodeId()

        const synergyNode: Wukong.DocumentProto.ISynergyNode = {
            nodeId: id,
            partialNode: {
                type: Wukong.DocumentProto.NodeType.NODE_TYPE_FRAME,
                id: id,
                parentInfo: parentInfo.getParentInfo(parentId),
                name:
                    node.nodeName === 'BODY'
                        ? taskId
                            ? '[' + taskId + ']'
                            : '0'
                        : taskId === 0
                        ? 'FRAME'
                        : node.nodeName + (useFlex ? '-flex' : ''),
            },
        }

        if (LAYOUT_NODE_NAMES.includes(node.nodeName)) {
            const computedStyle = window.getComputedStyle(node, null)
            if (computedStyle.display === 'none' || computedStyle.visibility === 'hidden') {
                return
            }
            const backgroundColor = computedStyle.backgroundColor
            const backgroundImage = computedStyle.backgroundImage
            const borderColor = getActiveBorderColor(computedStyle)

            synergyNode.partialNode!.opacity = +computedStyle.opacity

            synergyNode.partialNode!.relativeTransform = {
                translateX: clacOffset(window, node).left,
                translateY: clacOffset(window, node).top,
                scaleX: 1,
                scaleY: 1,
            }
            synergyNode.partialNode!.fills = [
                ...(synergyNode.partialNode!.fills ?? []),
                {
                    type: Wukong.DocumentProto.PaintType.PAINT_TYPE_SOLID_PAINT,
                    visible: true,
                    opacity: parseRGBA(backgroundColor).a,
                    blendMode: Wukong.DocumentProto.BlendMode.BLEND_MODE_NORMAL,
                    color: {
                        r: parseRGBA(backgroundColor).r,
                        g: parseRGBA(backgroundColor).g,
                        b: parseRGBA(backgroundColor).b,
                    },
                },
            ]
            if (node.nodeName === 'INPUT' && node.getAttribute('type') === 'range') {
                // range 的背景色无论取到多少, 浏览器里都展示成透明
                synergyNode.partialNode!.fills = []
            }
            let resourceId = node.getAttribute('data-resource-id')
            if (backgroundImage?.includes('https://motiff-dev.fbcontent.cn/private/resource/image/')) {
                const imageHash = backgroundImage
                    .substring(backgroundImage.lastIndexOf('/') + 1)
                    .replace('"', '')
                    .replace(')', '')
                resourceId = imageHash
            }
            if (resourceId) {
                synergyNode.partialNode!.fills.push({
                    type: Wukong.DocumentProto.PaintType.PAINT_TYPE_IMAGE_PAINT,
                    scaleMode:
                        computedStyle.backgroundSize === 'contain' || computedStyle.backgroundSize === 'contain'
                            ? Wukong.DocumentProto.ScaleMode.SCALE_MODE_FIT
                            : Wukong.DocumentProto.ScaleMode.SCALE_MODE_FILL,
                    visible: true,
                    opacity: 1,
                    blendMode: Wukong.DocumentProto.BlendMode.BLEND_MODE_NORMAL,
                    imageHash: resourceId,
                })
            }
            // 这些元素的渐变色应该在文本上, 所以自身没有渐变(87783)
            if (computedStyle.backgroundClip !== 'text') {
                if (backgroundImage.includes('linear-gradient') || backgroundImage.includes('radial-gradient')) {
                    const gradient = parseGradient(backgroundImage, node.offsetWidth ?? node.clientWidth)
                    if (gradient.length) {
                        synergyNode.partialNode!.fills = [...(synergyNode.partialNode!.fills ?? []), ...gradient]
                    }
                }
            }
            tryAddStrokes(synergyNode, borderColor, computedStyle)
            const borderTopLeftRadius = convertBorderRadius(
                window.getComputedStyle(node).borderTopLeftRadius,
                Math.min(node.offsetWidth, node.offsetHeight)
            )
            const borderTopRightRadius = convertBorderRadius(
                window.getComputedStyle(node).borderTopRightRadius,
                Math.min(node.offsetWidth, node.offsetHeight)
            )
            const borderBottomLeftRadius = convertBorderRadius(
                window.getComputedStyle(node).borderBottomLeftRadius,
                Math.min(node.offsetWidth, node.offsetHeight)
            )
            const borderBottomRightRadius = convertBorderRadius(
                window.getComputedStyle(node).borderBottomRightRadius,
                Math.min(node.offsetWidth, node.offsetHeight)
            )
            if (borderTopLeftRadius || borderTopRightRadius || borderBottomLeftRadius || borderBottomRightRadius) {
                synergyNode.partialNode!.independentCorners = true
                synergyNode.partialNode!.topLeftRadius = borderTopLeftRadius
                synergyNode.partialNode!.topRightRadius = borderTopRightRadius
                synergyNode.partialNode!.bottomLeftRadius = borderBottomLeftRadius
                synergyNode.partialNode!.bottomRightRadius = borderBottomRightRadius
            }
            // img的parent同时存在内描边和radius,则img继承radius:99249
            if (node.nodeName === 'IMG') {
                const parent = node.parentElement
                if (parent) {
                    const parentStyle = getComputedStyle(parent)
                    if (parentStyle.boxSizing === 'border-box') {
                        const parentBorderTopWidth = +parentStyle.borderTopWidth.replace('px', '')
                        const parentBorderRightWidth = +parentStyle.borderRightWidth.replace('px', '')
                        const parentBorderBottomWidth = +parentStyle.borderBottomWidth.replace('px', '')
                        const parentBorderLeftWidth = +parentStyle.borderLeftWidth.replace('px', '')
                        if (
                            parentBorderTopWidth ||
                            parentBorderRightWidth ||
                            parentBorderBottomWidth ||
                            parentBorderLeftWidth
                        ) {
                            const parentWidth = Math.min(parent.offsetWidth, parent.offsetHeight)
                            synergyNode.partialNode!.independentCorners = true
                            synergyNode.partialNode!.topLeftRadius =
                                convertBorderRadius(parentStyle.borderTopLeftRadius, parentWidth) || 0
                            synergyNode.partialNode!.topRightRadius =
                                convertBorderRadius(parentStyle.borderTopRightRadius, parentWidth) || 0
                            synergyNode.partialNode!.bottomLeftRadius =
                                convertBorderRadius(parentStyle.borderBottomLeftRadius, parentWidth) || 0
                            synergyNode.partialNode!.bottomRightRadius =
                                convertBorderRadius(parentStyle.borderBottomRightRadius, parentWidth) || 0
                        }
                    }
                }
            }

            const zIndex = +window.getComputedStyle(node, null).zIndex
            if (zIndex < 0) {
                synergyNode.partialNode!.visible = false
            }
            if (
                node.parentElement?.nodeName === 'BODY' ||
                computedStyle.overflow === 'hidden' ||
                (node.scrollWidth > node.clientWidth && overflowHiddenLike.includes(computedStyle.overflowX)) ||
                (node.scrollHeight > node.clientHeight && overflowHiddenLike.includes(computedStyle.overflowY))
            ) {
                synergyNode.partialNode!.clipsContent = true
            }
            if (computedStyle.clipPath.startsWith('polygon') || computedStyle.clip.startsWith('rect')) {
                const clipPath = computedStyle.clipPath !== 'none' ? computedStyle.clipPath : computedStyle.clip
                let clipRect
                if (computedStyle.clipPath.startsWith('polygon')) {
                    clipRect = parseClipPath(
                        clipPath,
                        getWidth(window, node, parentHasRotate),
                        getHeight(window, node, parentHasRotate)
                    )
                } else {
                    clipRect = parseClip(clipPath)
                }
                const groupNode: Wukong.DocumentProto.ISynergyNode = {
                    nodeId: id,
                    partialNode: {
                        type: Wukong.DocumentProto.NodeType.NODE_TYPE_GROUP,
                        id: id,
                        parentInfo: parentInfo.getParentInfo(parentId),
                        name: 'CLIP_GROUP',
                        relativeTransform: synergyNode.partialNode!.relativeTransform,
                    },
                }
                nodes.push(groupNode)
                synergyNode.partialNode!.relativeTransform = {
                    translateX: 0,
                    translateY: 0,
                    scaleX: 1,
                    scaleY: 1,
                }
                synergyNode.partialNode!.type = Wukong.DocumentProto.NodeType.NODE_TYPE_RECTANGLE
                synergyNode.nodeId = getNextNodeId()
                synergyNode.partialNode!.id = synergyNode.nodeId
                synergyNode.partialNode!.parentInfo = parentInfo.getParentInfo(id)

                synergyNode.partialNode!.clipsContent = false

                if (clipRect) {
                    const maskId = getNextNodeId()
                    const maskNode: Wukong.DocumentProto.ISynergyNode = {
                        nodeId: maskId,
                        partialNode: {
                            type: Wukong.DocumentProto.NodeType.NODE_TYPE_FRAME,
                            id: maskId,
                            parentInfo: parentInfo.getParentInfo(id, true),
                            name: 'CLIP_RECT',
                            mask: true,
                            relativeTransform: {
                                translateX: clipRect.x,
                                translateY: clipRect.y,
                                scaleX: 1,
                                scaleY: 1,
                            },
                            width: clipRect.width * getWidth(window, node, parentHasRotate),
                            height: clipRect.height * getHeight(window, node, parentHasRotate),
                            fills: [
                                {
                                    type: Wukong.DocumentProto.PaintType.PAINT_TYPE_SOLID_PAINT,
                                    visible: true,
                                    opacity: 1,
                                    blendMode: Wukong.DocumentProto.BlendMode.BLEND_MODE_NORMAL,
                                    color: { r: 0, g: 0, b: 0 },
                                },
                            ],
                        },
                    }
                    nodes.push(maskNode)
                }
            }
            if (computedStyle.boxShadow !== 'none') {
                addEffects(synergyNode.partialNode!, computedStyle.boxShadow)
            }
            if (computedStyle.filter !== 'none') {
                if (computedStyle.filter.includes('blur')) {
                    const regex = /blur\((\d+)\s*px\)/
                    const match = computedStyle.filter.match(regex)
                    if (match) {
                        const blurRadius = +match[1]
                        synergyNode.partialNode!.effects = synergyNode.partialNode!.effects || []
                        synergyNode.partialNode!.effects.push({
                            type: Wukong.DocumentProto.EffectType.EFFECT_TYPE_LAYER_BLUR,
                            radius: blurRadius * 2,
                            visible: true,
                        })
                    }
                }
                if (computedStyle.filter.includes('drop-shadow')) {
                    const regex = /^drop-shadow\((.*)\)$/
                    const match = computedStyle.filter.match(regex)
                    if (match) {
                        const shadows = getShadow(match[1])
                        if (shadows.length > 0) {
                            synergyNode.partialNode!.effects = synergyNode.partialNode!.effects || []
                            synergyNode.partialNode!.effects = [
                                ...synergyNode.partialNode!.effects,
                                ...shadows
                                    .map((shadow) => {
                                        return {
                                            type: shadow.isInsert
                                                ? Wukong.DocumentProto.EffectType.EFFECT_TYPE_INNER_SHADOW
                                                : Wukong.DocumentProto.EffectType.EFFECT_TYPE_DROP_SHADOW,
                                            visible: true,
                                            opacity: parseRGBA(shadow.color).a,
                                            color: {
                                                r: parseRGBA(shadow.color).r,
                                                g: parseRGBA(shadow.color).g,
                                                b: parseRGBA(shadow.color).b,
                                                a: parseRGBA(shadow.color).a * 255,
                                            },
                                            offset: {
                                                x: +shadow.offsetX.replace('px', ''),
                                                y: +shadow.offsetY.replace('px', ''),
                                            },
                                            radius: +shadow.blurRadius.replace('px', ''),
                                            spread: +shadow.spreadRadius.replace('px', ''),
                                        }
                                    })
                                    .reverse(),
                            ]
                        }
                    }
                }
            }
            if (computedStyle.mixBlendMode === 'overlay') {
                synergyNode.partialNode!.blendMode = Wukong.DocumentProto.BlendMode.BLEND_MODE_OVERLAY
            }
            const transform = computedStyle.transform

            if (transform?.includes('matrix') || parentHasRotate) {
                const matrix = getTransformMatrix(transform)
                hasRotate = !!matrix && isRotate(matrix)
                // 只处理有旋转的情况
                const result = getRelativeTransform(node)
                synergyNode.partialNode!.relativeTransform.scaleX = result.a
                synergyNode.partialNode!.relativeTransform.skewY = result.b
                synergyNode.partialNode!.relativeTransform.skewX = result.c
                synergyNode.partialNode!.relativeTransform.scaleY = result.d
                synergyNode.partialNode!.relativeTransform.translateX = result.e
                synergyNode.partialNode!.relativeTransform.translateY = result.f
            }

            if (useFlex) {
                if (isFlex(node)) {
                    synergyNode.partialNode!.stackVerticalPadding =
                        +computedStyle.paddingTop.replace('px', '') + parseFloat(computedStyle.borderTopWidth)
                    synergyNode.partialNode!.stackPaddingRight =
                        +computedStyle.paddingRight.replace('px', '') + parseFloat(computedStyle.borderRightWidth)
                    synergyNode.partialNode!.stackPaddingBottom =
                        +computedStyle.paddingBottom.replace('px', '') + parseFloat(computedStyle.borderBottomWidth)
                    synergyNode.partialNode!.stackHorizontalPadding =
                        +computedStyle.paddingLeft.replace('px', '') + parseFloat(computedStyle.borderLeftWidth)

                    const isHorizontal = computedStyle.flexDirection.includes('row') || computedStyle.display === 'grid'
                    synergyNode.partialNode!.stackMode = isHorizontal
                        ? Wukong.DocumentProto.StackMode.STACK_MODE_HORIZONTAL
                        : Wukong.DocumentProto.StackMode.STACK_MODE_VERTICAL
                    synergyNode.partialNode!.stackSpacing = +computedStyle.columnGap.replace('px', '') || 0
                    synergyNode.partialNode!.stackCounterSpacing = +computedStyle.rowGap.replace('px', '') || 0
                    if (computedStyle.flexWrap === 'wrap' || computedStyle.display === 'grid') {
                        synergyNode.partialNode!.stackWrap = Wukong.DocumentProto.StackWrap.STACK_WRAP_WRAP
                    }
                    switch (getJustifyContent(computedStyle.justifyContent)) {
                        case 'center':
                            synergyNode.partialNode!.stackPrimaryAlignItems =
                                Wukong.DocumentProto.StackJustify.STACK_JUSTIFY_STACK_JUSTIFY_CENTER
                            break
                        case 'end':
                            synergyNode.partialNode!.stackPrimaryAlignItems =
                                Wukong.DocumentProto.StackJustify.STACK_JUSTIFY_STACK_JUSTIFY_MAX
                            break
                        case 'space-between':
                            synergyNode.partialNode!.stackPrimaryAlignItems =
                                Wukong.DocumentProto.StackJustify.STACK_JUSTIFY_STACK_JUSTIFY_SPACE_EVENTLY
                            break
                        case 'space-evenly':
                            {
                                synergyNode.partialNode!.stackPrimaryAlignItems =
                                    Wukong.DocumentProto.StackJustify.STACK_JUSTIFY_STACK_JUSTIFY_SPACE_EVENTLY
                                const firstChild = node.firstElementChild
                                const lastChild = node.lastElementChild
                                if (firstChild && lastChild) {
                                    const nodeRect = node.getBoundingClientRect()
                                    const firstChildRect = firstChild.getBoundingClientRect()
                                    const lastChildRect = lastChild.getBoundingClientRect()
                                    synergyNode.partialNode!.stackHorizontalPadding =
                                        firstChildRect.left - nodeRect.left
                                    synergyNode.partialNode!.stackPaddingRight = nodeRect.right - lastChildRect.right
                                }
                            }
                            break
                        case 'stretch':
                            {
                                synergyNode.partialNode!.stackPrimaryAlignItems =
                                    Wukong.DocumentProto.StackJustify.STACK_JUSTIFY_STACK_JUSTIFY_SPACE_EVENTLY
                                const lastChild = node.lastElementChild
                                if (lastChild) {
                                    const nodeRect = node.getBoundingClientRect()
                                    const lastChildRect = lastChild.getBoundingClientRect()
                                    synergyNode.partialNode!.stackPaddingRight = nodeRect.right - lastChildRect.right
                                }
                            }
                            break
                        case 'start':
                            synergyNode.partialNode!.stackPrimaryAlignItems =
                                Wukong.DocumentProto.StackJustify.STACK_JUSTIFY_STACK_JUSTIFY_MIN
                    }
                    switch (computedStyle.alignItems) {
                        case 'center':
                            synergyNode.partialNode!.stackCounterAlignItems =
                                Wukong.DocumentProto.StackAlign.STACK_ALIGN_STACK_ALIGN_CENTER
                            break
                        case 'end':
                        case 'flex-end':
                            synergyNode.partialNode!.stackCounterAlignItems =
                                Wukong.DocumentProto.StackAlign.STACK_ALIGN_STACK_ALIGN_MAX
                            break
                        case 'baseline':
                            synergyNode.partialNode!.stackCounterAlignItems =
                                Wukong.DocumentProto.StackAlign.STACK_ALIGN_STACK_ALIGN_CENTER
                            break
                        default:
                            synergyNode.partialNode!.stackCounterAlignItems =
                                Wukong.DocumentProto.StackAlign.STACK_ALIGN_STACK_ALIGN_MIN
                    }
                } else if (node.nodeName === 'TBODY' || node.nodeName === 'TR' || node.nodeName === 'THEAD') {
                    synergyNode.partialNode!.stackVerticalPadding = +computedStyle.paddingTop.replace('px', '')
                    synergyNode.partialNode!.stackPaddingRight = +computedStyle.paddingRight.replace('px', '')
                    synergyNode.partialNode!.stackPaddingBottom = +computedStyle.paddingBottom.replace('px', '')
                    synergyNode.partialNode!.stackHorizontalPadding = +computedStyle.paddingLeft.replace('px', '')
                    const isHorizontal = node.nodeName === 'TR' || node.nodeName === 'THEAD'
                    synergyNode.partialNode!.stackMode = isHorizontal
                        ? Wukong.DocumentProto.StackMode.STACK_MODE_HORIZONTAL
                        : Wukong.DocumentProto.StackMode.STACK_MODE_VERTICAL
                    synergyNode.partialNode!.stackSpacing = 0
                    synergyNode.partialNode!.stackCounterSpacing = 0
                } else if (isExtractFlex(node)) {
                    const parentRect = node.getBoundingClientRect()
                    const firstChildRect = node.children[0].getBoundingClientRect()
                    const secondChildRect = node.children[1].getBoundingClientRect()
                    const topRect = firstChildRect.top < secondChildRect.top ? firstChildRect : secondChildRect
                    const bottomRect = firstChildRect.top > secondChildRect.top ? firstChildRect : secondChildRect
                    if (topRect.x === bottomRect.x) {
                        synergyNode.partialNode!.stackMode = Wukong.DocumentProto.StackMode.STACK_MODE_VERTICAL
                        const paddingLeft = topRect.left - parentRect.left
                        const paddingRight = parentRect.right - Math.max(bottomRect.right, topRect.right)
                        const paddingTop = topRect.top - parentRect.top
                        const paddingBottom = parentRect.bottom - bottomRect.bottom
                        const gap = bottomRect.top - topRect.bottom
                        synergyNode.partialNode!.stackVerticalPadding = paddingTop
                        synergyNode.partialNode!.stackPaddingRight = paddingRight
                        synergyNode.partialNode!.stackPaddingBottom = paddingBottom
                        synergyNode.partialNode!.stackHorizontalPadding = paddingLeft
                        synergyNode.partialNode!.stackSpacing = gap
                        synergyNode.partialNode!.name = synergyNode.partialNode!.name + '-extra'
                    }
                }
                synergyNode.partialNode!.stackPrimarySizing = Wukong.DocumentProto.StackSize.STACK_SIZE_STACK_SIZE_FIXED
                synergyNode.partialNode!.stackCounterSizing = Wukong.DocumentProto.StackSize.STACK_SIZE_STACK_SIZE_FIXED

                // 如果设置成了自动间距, 而且子节点无法放的下, 此时html中的表现是gap间距(上面已经设置过了), 而我们是负间距, 要在这种情况下需要处理成左对齐92479
                if (
                    synergyNode.partialNode!.stackPrimaryAlignItems ===
                    Wukong.DocumentProto.StackJustify.STACK_JUSTIFY_STACK_JUSTIFY_SPACE_EVENTLY
                ) {
                    // 现在简单判断的规则是, 每个element子元素的right都不能超出父元素的right, #text先不管
                    const elementChildren = Array.prototype.slice.call(node.children)
                    const parentRect = node.getBoundingClientRect()
                    const isOverflow = elementChildren.some((child) => {
                        const childRect = child.getBoundingClientRect()
                        return childRect.right > parentRect.right
                    })
                    if (isOverflow) {
                        synergyNode.partialNode!.stackPrimaryAlignItems =
                            Wukong.DocumentProto.StackJustify.STACK_JUSTIFY_STACK_JUSTIFY_MIN
                    }
                }
            }
        }

        synergyNode.partialNode!.width =
            node.nodeName === 'BODY' && node.children.length > 0
                ? getWidth(window, node.children[0], parentHasRotate)
                : getWidth(window, node, parentHasRotate)
        if (isElement(node) && getComputedStyle(node).flexWrap === 'wrap') {
            // 如果会换行, 容器宽度 + 0.1, 防止精度问题导致异常换行 99746
            synergyNode.partialNode!.width! += 0.1
        }
        synergyNode.partialNode!.height =
            node.nodeName === 'BODY' && node.children.length > 0
                ? getHeight(window, node.children[0], parentHasRotate)
                : getHeight(window, node, parentHasRotate)
        if (node.nodeName === 'svg') {
            const aspectRatio = (node as unknown as SVGSVGElement).preserveAspectRatio.baseVal
            if (aspectRatio.align !== SVGPreserveAspectRatio.SVG_PRESERVEASPECTRATIO_NONE) {
                const svgRatio = getSvgRatio(node as unknown as SVGSVGElement)
                const width =
                    synergyNode.partialNode!.width -
                    +getComputedStyle(node).paddingLeft.replace('px', '') -
                    +getComputedStyle(node).paddingRight.replace('px', '')
                const height =
                    synergyNode.partialNode!.height -
                    +getComputedStyle(node).paddingTop.replace('px', '') -
                    +getComputedStyle(node).paddingBottom.replace('px', '')
                if (!isNaN(svgRatio) && width !== svgRatio * height) {
                    const offsetMatrix = new DOMMatrix([1, 0, 0, 1, 0, 0])
                    if (width > height * svgRatio) {
                        // 不知道为什么, 宽度修正后96863是不对的, 而注释后是正确的
                        // synergyNode.partialNode!.width = svgRatio * height
                        // offsetMatrix.e = (width - svgRatio * height) / 2
                    } else {
                        const originalHeight = synergyNode.partialNode!.height
                        // 高度修正的case: 93763
                        synergyNode.partialNode!.height = width / svgRatio
                        offsetMatrix.f = (originalHeight - synergyNode.partialNode!.height) / 2
                    }
                    const newRelativeTransform = computeRelativeTransformMatrixEffect(node, offsetMatrix)
                    synergyNode.partialNode!.relativeTransform = {
                        scaleX: newRelativeTransform.a,
                        scaleY: newRelativeTransform.d,
                        translateX: newRelativeTransform.e,
                        translateY: newRelativeTransform.f,
                        skewX: newRelativeTransform.c,
                        skewY: newRelativeTransform.b,
                    }
                }
            }
        }

        if (useFlex) {
            if (node.parentElement && isFlex(node.parentElement)) {
                const position = getComputedStyle(node).position
                if (position === 'absolute' || position === 'fixed') {
                    // 父节点是flex时, 字节点的绝对定位处理
                    synergyNode.partialNode!.stackPositioning =
                        Wukong.DocumentProto.StackPositioning.STACK_POSITIONING_ABSOLUTE
                }

                const margin = getMargin(getComputedStyle(node))
                // 父节点是flex时, 给自己的margin额外包装一层: 87784
                if (margin.hasMargin) {
                    const marginWrapper: Wukong.DocumentProto.ISynergyNode = {
                        nodeId: id,
                        partialNode: {
                            type: Wukong.DocumentProto.NodeType.NODE_TYPE_FRAME,
                            id: id,
                            parentInfo: synergyNode.partialNode!.parentInfo,
                            name: 'MARGIN_WRAPPER',
                            width: synergyNode.partialNode!.width + margin.left + margin.right,
                            height: synergyNode.partialNode!.height + margin.top + margin.bottom,
                            relativeTransform: synergyNode.partialNode!.relativeTransform,
                            stackMode: Wukong.DocumentProto.StackMode.STACK_MODE_HORIZONTAL,
                            stackVerticalPadding: margin.top,
                            stackPaddingRight: margin.right,
                            stackPaddingBottom: margin.bottom,
                            stackHorizontalPadding: margin.left,
                        },
                    }
                    // 如果有margin-wrapper, 则绝对定位被wrapper继承, 自己回到auto: 100197
                    if (
                        synergyNode.partialNode!.stackPositioning ===
                        Wukong.DocumentProto.StackPositioning.STACK_POSITIONING_ABSOLUTE
                    ) {
                        marginWrapper.partialNode!.stackPositioning =
                            Wukong.DocumentProto.StackPositioning.STACK_POSITIONING_ABSOLUTE
                        marginWrapper.partialNode!.stackVerticalPadding = 0
                        marginWrapper.partialNode!.stackPaddingRight = 0
                        marginWrapper.partialNode!.stackPaddingBottom = 0
                        marginWrapper.partialNode!.stackHorizontalPadding = 0
                        synergyNode.partialNode!.stackPositioning =
                            Wukong.DocumentProto.StackPositioning.STACK_POSITIONING_AUTO
                    }
                    nodes.push(marginWrapper)
                    const innerId = getNextNodeId()
                    synergyNode.nodeId = innerId
                    synergyNode.partialNode!.parentInfo = parentInfo.getParentInfo(id)
                    synergyNode.partialNode!.id = innerId
                    id = innerId
                }
            }
        }

        setImportFromHtmlNodeId(synergyNode, node)
        nodes.push(synergyNode)
        if (node.nodeName === 'svg') {
            const svgProcessor = new SVGProcessor(node as unknown as SVGElement)
            const svg = svgProcessor.process()
            const svgString = getSVGString(svg)
            // 还原被修改的 SVG 元素
            svgProcessor.destroy()
            svgData.push({
                parentNodeId: id,
                data: svgString,
            })
            return
        }
        if (hasPseudoElement(node, 'before')) {
            const pseudoElementBefore = window.getComputedStyle(node, '::before')
            const pseudoElement = makePseudoNode(pseudoElementBefore, node, id, '::before')
            nodes.push(pseudoElement)
            if (pseudoElementBefore.content !== '""') {
                const rect = new DOMRect(
                    node.getBoundingClientRect().left,
                    node.getBoundingClientRect().top,
                    +pseudoElementBefore.width.replace('px', ''),
                    +pseudoElementBefore.height.replace('px', '')
                )
                // 伪元素的content中的text
                const textElement = makeTextNode(
                    window,
                    node,
                    getNextNodeId(),
                    pseudoElement.nodeId!,
                    rect,
                    pseudoElementBefore.content.replace(/^"|"$/g, ''),
                    importHTMLContext.allFonts,
                    [],
                    '::before',
                    useFlex,
                    useTextRealSize
                )
                nodes.push(textElement)
            }
        }
        if (hasPseudoElement(node, 'after')) {
            const pseudoElementAfter = window.getComputedStyle(node, '::after')
            const pseudoElement = makePseudoNode(pseudoElementAfter, node, id, '::after')
            // 保证after在所有子元素最后, +1是因为可能有before
            let childrenLength = node.childNodes.length + 1
            while (childrenLength--) {
                pseudoElement.partialNode!.parentInfo!.orderIndex = OrderIndexUtil.greater(
                    pseudoElement.partialNode!.parentInfo!.orderIndex!
                )
            }
            nodes.push(pseudoElement)
            if (pseudoElementAfter.content !== '""') {
                const rect = new DOMRect(
                    node.getBoundingClientRect().left,
                    node.getBoundingClientRect().top,
                    +pseudoElementAfter.width.replace('px', ''),
                    +pseudoElementAfter.height.replace('px', '')
                )
                // 伪元素的content中的text
                const textElement = makeTextNode(
                    window,
                    node,
                    getNextNodeId(),
                    pseudoElement.nodeId!,
                    rect,
                    pseudoElementAfter.content.replace(/^"|"$/g, ''),
                    importHTMLContext.allFonts,
                    [],
                    '::after',
                    useFlex,
                    useTextRealSize
                )
                nodes.push(textElement)
            }
        }

        if (node.nodeName === 'LI') {
            const computedStyle = window.getComputedStyle(node, null)
            if (
                computedStyle.listStyleType === 'disc' &&
                (computedStyle.display === 'list-item' || computedStyle.display === 'revert')
            ) {
                const isMarkerOutside = computedStyle.listStylePosition === 'outside'
                const pointId = getNextNodeId()
                const point: Wukong.DocumentProto.ISynergyNode = {
                    nodeId: pointId,
                    partialNode: {
                        type: Wukong.DocumentProto.NodeType.NODE_TYPE_FRAME,
                        id: pointId,
                        parentInfo: parentInfo.getParentInfo(id),
                        name: 'LIST_POINT',
                        width: 4,
                        height: 4,
                        cornerRadius: 999,
                        topLeftRadius: 999,
                        topRightRadius: 999,
                        bottomLeftRadius: 999,
                        bottomRightRadius: 999,
                        fills: [
                            {
                                type: Wukong.DocumentProto.PaintType.PAINT_TYPE_SOLID_PAINT,
                                visible: true,
                                opacity: 1,
                                blendMode: Wukong.DocumentProto.BlendMode.BLEND_MODE_NORMAL,
                                color: { r: 77, g: 85, b: 98 },
                            },
                        ],
                        relativeTransform: {
                            translateX: isMarkerOutside ? -16 : 0,
                            translateY: node.getBoundingClientRect().height / 2 - 2,
                            scaleX: 1,
                            scaleY: 1,
                        },
                    },
                }
                nodes.push(point)
            }
        }
        if (node.nodeName === 'INPUT' || node.nodeName === 'TEXTAREA' || node.nodeName === 'SELECT') {
            let value = (node as HTMLInputElement).value.trim()
            if (node.nodeName === 'SELECT' && !value) {
                if (node.children.length > 0) {
                    value = (node.children[0] as HTMLElement).textContent?.trim() || ''
                }
            }
            let placeholder = (node as HTMLInputElement).placeholder?.trim()
            if (node.nodeName === 'INPUT' && (node as HTMLInputElement).type === 'date' && !placeholder) {
                placeholder = 'yyyy / mm / dd'
            }
            if (value || placeholder) {
                if (isTextLikeInput((node as HTMLInputElement).type) || node.nodeName === 'SELECT') {
                    let rect = node.getBoundingClientRect()
                    // 输入框里文本的实际位置会受到padding影响
                    const padding = getPadding(window.getComputedStyle(node, null))
                    if (padding.hasPadding) {
                        rect = {
                            ...rect,
                            left: rect.left,
                            top: rect.top,
                            x: rect.x,
                            y: rect.y,
                            width: rect.width - padding.left - padding.right,
                            height: rect.height - padding.top - padding.bottom,
                            bottom: rect.bottom,
                            right: rect.right,
                        }
                    }
                    const textWrapperId = getNextNodeId()
                    const textWrapper: Wukong.DocumentProto.ISynergyNode = {
                        nodeId: textWrapperId,
                        partialNode: {
                            type: Wukong.DocumentProto.NodeType.NODE_TYPE_FRAME,
                            id: textWrapperId,
                            parentInfo: parentInfo.getParentInfo(id),
                            name: 'TEXT_WRAPPER',
                            width: rect.width,
                            height: rect.height,
                            relativeTransform: {
                                translateX: padding.left,
                                translateY: padding.top,
                                scaleX: 1,
                                scaleY: 1,
                            },
                            clipsContent: true,
                        },
                    }
                    nodes.push(textWrapper)
                    // 输入框里的textNode
                    const textNode = makeTextNode(
                        window,
                        node,
                        getNextNodeId(),
                        textWrapperId,
                        rect,
                        value || placeholder,
                        importHTMLContext.allFonts,
                        [],
                        !value && !!placeholder ? '::placeholder' : '',
                        useFlex,
                        useTextRealSize
                    )
                    nodes.push(textNode)
                } else if (node.nodeName === 'TEXTAREA') {
                    let rect = node.getBoundingClientRect()
                    // textarea 的文本位置会受到padding影响
                    const padding = getPadding(window.getComputedStyle(node, null))
                    if (padding.left || padding.top) {
                        rect = {
                            ...rect,
                            left: rect.left + padding.left,
                            top: rect.top + padding.top,
                            x: rect.x,
                            y: rect.y,
                            width: rect.width - padding.left - padding.right,
                            height: rect.height - padding.top - padding.bottom,
                            bottom: rect.bottom,
                            right: rect.right,
                        }
                    }
                    // TextArea里的textNode
                    const textAreaNode = makeTextNode(
                        window,
                        node,
                        getNextNodeId(),
                        id,
                        rect,
                        value || placeholder,
                        importHTMLContext.allFonts,
                        [],
                        !value && !!placeholder ? '::placeholder' : '',
                        useFlex,
                        useTextRealSize
                    )
                    textAreaNode.partialNode!.textAlignVertical =
                        Wukong.DocumentProto.TextAlignVertical.TEXT_ALIGN_VERTICAL_TOP
                    textAreaNode.partialNode!.relativeTransform!.translateY =
                        textAreaNode.partialNode!.relativeTransform!.translateY! + 6
                    nodes.push(textAreaNode)
                } else if (node.getAttribute('type') === 'checkbox') {
                    const { checkbox, iconData } = makeCheckbox(
                        id,
                        node.clientWidth,
                        (node as HTMLInputElement).checked
                    )
                    nodes.push(checkbox)
                    if (iconData) {
                        svgData.push(iconData)
                    }
                } else if (node.getAttribute('type') === 'range') {
                    const { range, rangeInner, process } = makeRange(
                        node,
                        id,
                        getWidth(window, node, parentHasRotate),
                        value
                    )
                    nodes.push(range)
                    nodes.push(rangeInner)
                    nodes.push(process)
                } else if (node.getAttribute('type') === 'radio') {
                    const { radio, radioInner } = makeRadio(id, (node as HTMLInputElement).checked)
                    nodes.push(radio)
                    if (radioInner) {
                        nodes.push(radioInner)
                    }
                } else if (node.getAttribute('type') === 'color') {
                    const color = makeColor(id, (node as HTMLInputElement).value)
                    nodes.push(color)
                }
                // date除了要显示value/ placeholder, 还要额外增加图标
                if (node.getAttribute('type') === 'date') {
                    const { iconWrapper, iconData } = makeDate(id, node.getBoundingClientRect())
                    nodes.push(iconWrapper)
                    svgData.push(iconData)
                }
            }
        }

        if (node.nodeName === 'SELECT') {
            const iconId = getNextNodeId()
            const iconWrapper: Wukong.DocumentProto.ISynergyNode = {
                nodeId: iconId,
                partialNode: {
                    type: Wukong.DocumentProto.NodeType.NODE_TYPE_FRAME,
                    id: iconId,
                    parentInfo: parentInfo.getParentInfo(id),
                    name: 'ICON_WRAPPER',
                    width: 14,
                    height: 14,
                    relativeTransform: {
                        translateX: node.getBoundingClientRect().width - 14 - 2,
                        translateY: node.getBoundingClientRect().height / 2 - 7,
                        scaleX: 1,
                        scaleY: 1,
                    },
                },
            }
            nodes.push(iconWrapper)
            if (getComputedStyle(node).appearance !== 'none') {
                svgData.push({
                    parentNodeId: iconId,
                    data: ARROW_DOWN_SVG.replace('currentColor', getComputedStyle(node).color),
                })
            }
        }

        let children = Array.from(node.childNodes)
        for (const child of children) {
            if (child.nodeType === Node.COMMENT_NODE) {
                node.removeChild(child)
            }
        }
        node.normalize()
        children = Array.from(node.childNodes)

        children = children.filter((child) => {
            return child.nodeType !== Node.TEXT_NODE || child.textContent?.trim()
        })

        const mergedChildren = []
        const mergedChildrenMap = new Map<ChildNode, ChildNode[]>()
        if (useFlex && isElement(node) && (isFlex(node) || isExtractFlex(node))) {
            // flex 布局下, 不合并行内元素, 并且需要将绝对定位的元素放在最后
            const absoluteChildren = []
            const flexChildren = []
            for (const child of children) {
                if (isElement(child) && window.getComputedStyle(child as Element).position === 'absolute') {
                    absoluteChildren.push(child)
                } else {
                    flexChildren.push(child)
                }
            }

            // 先根据order排序, #text视为0
            flexChildren.sort((a, b) => {
                const orderA = isElement(a) ? +window.getComputedStyle(a as Element).order || 0 : 0
                const orderB = isElement(b) ? +window.getComputedStyle(b as Element).order || 0 : 0
                return orderA - orderB
            })

            // 如果反转, 需要重拍flexChildren
            if (
                getComputedStyle(node).flexDirection === 'column-reverse' ||
                getComputedStyle(node).flexDirection === 'row-reverse'
            ) {
                flexChildren.reverse()
            }

            children = [...flexChildren, ...absoluteChildren]
        } else {
            let holdChild = null
            for (const child of children) {
                let isInline = false
                if (!isElement(child)) {
                    isInline = true
                } else {
                    const childStyle = window.getComputedStyle(child as Element)
                    isInline =
                        childStyle.display === 'inline' &&
                        child.nodeName !== 'BR' &&
                        child.nodeName !== 'svg' &&
                        childStyle.backgroundColor === 'rgba(0, 0, 0, 0)'
                }
                if (isInline) {
                    if (holdChild) {
                        mergedChildrenMap.set(holdChild, [...(mergedChildrenMap.get(holdChild) || []), child])
                    } else {
                        mergedChildren.push(child)
                        holdChild = child
                    }
                } else {
                    mergedChildren.push(child)
                    holdChild = null
                }
            }

            children = mergedChildren

            children.sort((a, b) => {
                if (!isElement(a)) {
                    return -1
                }
                if (!isElement(b)) {
                    return 1
                }
                const zIndexA = window.getComputedStyle(a as Element).zIndex
                const zIndexB = window.getComputedStyle(b as Element).zIndex
                const aPos = window.getComputedStyle(a as Element).position as Position
                const bPos = window.getComputedStyle(b as Element).position as Position
                if (zIndexA === 'auto' && zIndexB !== 'auto') {
                    return -zIndexB
                } else if (zIndexA !== 'auto' && zIndexB === 'auto') {
                    return +zIndexA
                } else if (zIndexA !== 'auto' && zIndexB !== 'auto') {
                    if (Number(zIndexA) === Number(zIndexB)) {
                        return (positionOrder[aPos] ?? -1) - (positionOrder[bPos] ?? -1)
                    }
                    return Number(zIndexA) - Number(zIndexB)
                } else {
                    return (positionOrder[aPos] ?? -1) - (positionOrder[bPos] ?? -1)
                }
            })
        }

        // 递归遍历包含text的子节点
        for (const child of children) {
            traverseDom(
                window,
                nodes,
                svgData,
                child as HTMLElement,
                id,
                taskId,
                [...(mergedChildrenForText || []), ...(mergedChildrenMap.get(child) || [])],
                hasRotate || parentHasRotate,
                useFlex,
                useTextRealSize,
                children.length === 1
            )
        }
    } else {
        // 递归遍历子节点
        for (const child of node.children) {
            traverseDom(
                window,
                nodes,
                svgData,
                child as HTMLElement,
                id,
                taskId,
                [],
                false,
                useFlex,
                useTextRealSize,
                node.children.length === 1
            )
        }
    }
}

function loadHtmlInIframe(
    nodes: Wukong.DocumentProto.ISynergyNode[],
    svgData: { parentNodeId: string; data: string }[],
    htmlContent: string,
    taskId?: number,
    width?: number,
    useFlex = false,
    useTextRealSize = false
): Promise<HTMLIFrameElement> {
    return new Promise((resolve) => {
        // 创建 iframe 元素
        const iframe = document.createElement('iframe')

        // 设置 iframe 的样式，例如大小和边框
        iframe.style.width = width === undefined ? '40%' : `${width}px`
        iframe.style.height = '1000px'
        iframe.style.border = '1px solid #ccc'
        iframe.style.top = '0'
        iframe.style.left = '0'
        iframe.style.zIndex = '1000'
        iframe.name = 'ai-iframe'

        // 确保 iframe 加载完成后设置内容
        iframe.onload = () => {
            if (iframe.contentDocument) {
                // 将 HTML 内容写入 iframe 的 document
                iframe.contentDocument.open()
                // 确保内容写入后监听 iframe 内部文档的加载
                iframe.contentWindow?.addEventListener('DOMContentLoaded', () => {
                    const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document

                    if (iframeDoc) {
                        // 遍历 iframe 中的 DOM 树
                        setTimeout(() => {
                            ensureNodeHasId(0, iframeDoc.documentElement)
                            traverseDom(
                                iframe.contentWindow!,
                                nodes,
                                svgData,
                                iframeDoc.documentElement,
                                '0:1',
                                taskId,
                                [],
                                false,
                                useFlex,
                                useTextRealSize,
                                false
                            )
                            resolve(iframe)
                        }, 2000)
                    } else {
                        alert('no document')
                    }
                })
                iframe.contentDocument.write(htmlContent)
                iframe.contentDocument.close()
            } else {
                console.error('无法访问 iframe 的 contentDocument')
            }
        }

        // 将 iframe 添加到页面中
        document.body.appendChild(iframe)
    })
}

const fetchResult = async (taskIds: number[]) => {
    const url = `${environment.httpPrefixes[HttpPrefixKey.AI_GEN_API]}/result/html?taskIds=${taskIds.join(',')}`

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })

        if (!response.ok) {
            throw new Error(`请求失败，状态码: ${response.status}`)
        }

        return await response.json()
    } catch (error) {
        console.error('请求出错:', error)
    }
}

export async function importHTML(
    command: CommandInvoker,
    allFonts: FontInfoExt[],
    ids?: number[],
    needScreenshot = true,
    width?: number,
    useFlex = false,
    useTextRealSize = false
): Promise<void> {
    if (!ids) {
        const userHTML = await readFromFile('.html,text/html')
        await importSingleHTML(userHTML, command, allFonts, {
            taskId: -1,
            needScreenshot,
            needProtoData: false,
            width,
            useFlex,
            useTextRealSize,
        })
    } else {
        const result = await fetchResult(ids)
        const keys = Object.keys(result)
        for (const key of keys) {
            const userHTML = result[key]
            await importSingleHTML(userHTML, command, allFonts, {
                taskId: +key,
                needScreenshot,
                needProtoData: false,
                width,
                useFlex,
                useTextRealSize,
            })
        }
    }
}

export async function importHTMLFromString(
    command: CommandInvoker,
    allFonts: FontInfoExt[],
    userHTMLs: string[]
): Promise<void> {
    for (const html of userHTMLs) {
        await importSingleHTML(html, command, allFonts, {
            taskId: -1,
            needScreenshot: false,
            needProtoData: false,
            width: undefined,
            useFlex: false,
        })
    }
}

export async function parseHTMLToProto(
    command: CommandInvoker,
    allFonts: FontInfoExt[],
    userHTML: string,
    width?: number,
    useFlex = true,
    useTextRealSize = true
): Promise<string> {
    const { protoData } = await importSingleHTML(userHTML, command, allFonts, {
        taskId: 0,
        needScreenshot: false,
        needProtoData: true,
        width,
        useFlex,
        useTextRealSize,
    })
    const proto = new Uint8Array(Buffer.from(protoData, 'base64').buffer)
    const serializedDoc = Wukong.DocumentProto.SerializedExportedDocument.decode(proto)
    if (serializedDoc) {
        const doc = Wukong.DocumentProto.SynergyDocument.create()
        doc.nodes = serializedDoc.allNodes.map((serializedNode) => {
            return {
                nodeId: serializedNode.nodeProps!.id,
                partialNode: {
                    ...serializedNode.nodeProps,
                },
            }
        })
        doc.schemaVersion = serializedDoc.schemaVersion
        doc.blobs = serializedDoc.blobs
        const encoded = Wukong.DocumentProto.SynergyDocument.encode(doc).finish()
        return Buffer.from(encoded).toString('base64')
    }
    return ''
}

export async function importSingleHTML(
    userHTML: string,
    command: CommandInvoker,
    allFonts: FontInfoExt[],
    config: {
        taskId?: number
        needScreenshot?: boolean
        needProtoData?: boolean
        width?: number
        useFlex?: boolean
        useTextRealSize?: boolean
    } = {
        needScreenshot: true,
        needProtoData: false,
        width: undefined,
        useFlex: false,
        useTextRealSize: false,
    }
): Promise<Wukong.DocumentProto.IHtmlAIGenReturn> {
    parentInfo.reset()
    if (!allFonts.length) {
        throw new Error('字体列表为空，无法导入，请稍后再试')
    }

    importHTMLContext = {
        allFonts,
    }

    const nodes: Wukong.DocumentProto.ISynergyNode[] = [
        {
            fields: [],
            nodeId: '0:0',
            partialNode: {
                type: Wukong.DocumentProto.NodeType.NODE_TYPE_DOCUMENT, // Document type
                id: '0:0',
                currentPageId: '0:1',
            },
        },
        {
            fields: [],
            nodeId: '0:1',
            partialNode: {
                type: Wukong.DocumentProto.NodeType.NODE_TYPE_PAGE,
                id: '0:1',
                parentInfo: {
                    parentId: '0:0',
                    orderIndex: new Uint8Array([0x80]),
                },
            },
        },
        {
            fields: [],
            nodeId: '0:2',
            partialNode: {
                type: Wukong.DocumentProto.NodeType.NODE_TYPE_STYLE_CONTAINER,
                id: '0:2',
                parentInfo: {
                    parentId: '0:0',
                    orderIndex: new Uint8Array([0x80]),
                },
            },
        },
    ]

    const svgData: {
        parentNodeId: string
        data: string
    }[] = []

    const iframe = await loadHtmlInIframe(
        nodes,
        svgData,
        userHTML,
        config.taskId,
        config.width,
        config.useFlex,
        config.useTextRealSize
    )
    const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document
    const ret = command.invokeBridge(CommitType.CommitUndo, ApplyHtmlAIGenCommand, {
        svgData,
        part: {
            schemaVersion: 11,
            nodes,
            blobs: {},
        },
        from: [],
        to: [],
        needProtoData: config.needProtoData ?? false,
        originalHtml: iframeDoc!.documentElement.outerHTML,
    })
    const { rootId, protoData } = ret

    if (!config.needProtoData && config.needScreenshot && iframe && rootId) {
        const iframeWindow = iframe.contentWindow
        const iframeDocument = iframe.contentDocument || iframeWindow!.document
        // 注入脚本以截图
        const script = iframeDocument.createElement('script')
        script.src = dom2ImageScript
        script.onload = function () {
            setTimeout(() => {
                const html2canvas = (iframeWindow as any).domtoimage
                html2canvas
                    .toPng(iframeDocument.body.children[0])
                    .then(function (dataUri: any) {
                        // 通过postMessage将base64格式的PNG数据发送回主页面
                        window.postMessage({ type: 'screenshot', data: dataUri, id: rootId }, '*')
                    })
                    .catch(function (error: any) {
                        console.error('截图失败:', error)
                    })
            }, 2000)
        }
        const listener = (event: MessageEvent) => {
            if (event.data.type === 'screenshot' && event.data.id === rootId) {
                const base64Image = event.data.data

                const newImage = motiff.createImage(
                    new Uint8Array(Buffer.from(base64Image.replace('data:image/png;base64,', ''), 'base64'))
                )
                const frame = motiff.getNodeById(rootId)
                if (frame && frame.type === 'FRAME') {
                    const newFills = [
                        {
                            type: 'IMAGE',
                            blendMode: 'NORMAL',
                            opacity: 0.35,
                            visible: true,
                            scaleMode: 'FILL',
                            imageHash: newImage.hash,
                            scalingFactor: 1,
                        },
                    ]
                    const rect = motiff.createRectangle()
                    rect.x = frame.x
                    rect.y = frame.y
                    rect.resize(frame.width, frame.height)
                    rect.fills = newFills as any
                }
                window.removeEventListener('message', listener)
            }
        }

        window.addEventListener('message', listener)
        iframeDocument.head.appendChild(script) // 将脚本插入到iframe文档中
    } else if (iframe) {
        iframe.remove()
    }
    return ret
}

function ensureNodeHasId(nextId: number, element: HTMLElement) {
    if (!element.id) {
        nextId++
        element.id = `h${nextId}`
    }
    for (const child of element.children) {
        nextId = ensureNodeHasId(nextId, child as HTMLElement)
    }
    return nextId
}

function setImportFromHtmlNodeId(synergyNode: Wukong.DocumentProto.ISynergyNode, htmlElement?: HTMLElement) {
    if (htmlElement?.id) {
        synergyNode.partialNode!.pluginData = {
            store: {
                HTML: {
                    data: {
                        htmlNodeId: htmlElement.id,
                    },
                    id: 'HTML',
                },
            },
        }
    }
}
