import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    Language: '代码',
    Preferences: '偏好设置',
    SnapToPixel: '对齐像素网格',
    UnitAndScale: '单位与倍率...',
    InvertZoomDirection: '翻转画布缩放方向',
    ClickOpensRight: '点击唤起上下文菜单',
    ColorProfile: '颜色配置文件',
    NudgeAmount: '微调量',
    AiDuplicateReferenceSelect: 'AI 复制参考范围',
    OpenLinksInDesktopApp: '在桌面客户端打开文件',
    Theme: '主题',
    Dark: '深色',
    Light: '浅色',
} as const

export const enTranslation = {
    Language: 'Language',
    Preferences: 'Preferences',
    SnapToPixel: 'Snap to pixel grid',
    UnitAndScale: 'Unit and scale...',
    InvertZoomDirection: 'Invert zoom direction',
    ClickOpensRight: 'click opens right click menus',
    ColorProfile: 'Color profile',
    NudgeAmount: 'Nudge amount',
    AiDuplicateReferenceSelect: 'Reference range of AI Reduplication',
    OpenLinksInDesktopApp: 'Open links in desktop app',
    Theme: 'Theme',
    Dark: 'Dark',
    Light: 'Light',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
