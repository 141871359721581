import { GetComputedColorVariableCommand, IsThisNameInvalidForCreatingVariable, Wukong } from '@wukong/bridge-proto'
import classnames from 'classnames'
import { HTMLTextAreaElement } from 'happy-dom'
import { useCallback, useMemo, useRef, useState } from 'react'
import {
    InputV2,
    MonoIconPanelLink16,
    Position,
    Select,
    Tooltip,
    WKButton,
    WKIconButton,
} from '../../../../../../../ui-lib/src'
import { isEnglishLanguage } from '../../../../../../../util/src/i18n'
import { CommitType } from '../../../../../document/command/commit-type'
import { SolidPaint } from '../../../../../document/node/node'
import { useAppContext } from '../../../../../main/app-context'
import { useViewState } from '../../../../../view-state-bridge/use-view-state'
import { LocalStorageKey } from '../../../../../web-storage/local-storage/config'
import { enhancedLocalStorage } from '../../../../../web-storage/local-storage/storage'
import { useCommand } from '../../../../context/document-context'
import { ScrubbableInputPercent } from '../../../atom/inputs/scrubbable-input-percent'
import { useRenderColorSpace } from '../../../color-profile/hook'
import { ColorVarIcon } from '../../../color-var-item/color-var-icon'
import { ColorVarName } from '../../../color-var-item/color-var-name'
import { PaintIconColor } from '../../../paint-icon-color/paint-icon-color'
import { ColorPanelSubset } from '../color-panel/color-panel-subset'
import { ColorInteractionFrom, ColorVariableCreateProps } from '../type'
import { getDefaultSolidPaint } from '../utils'
import classes from './color-variable-create.module.less'
import { translation } from './color-variable-create.translation'

function getInitPaint(
    paints: Wukong.DocumentProto.IPaint[],
    selectedGradientColorStopIndex?: number
): Wukong.DocumentProto.IPaint {
    if (paints.length === 1) {
        const paint = paints[0]
        if (paint.type === Wukong.DocumentProto.PaintType.PAINT_TYPE_SOLID_PAINT) {
            return { ...paint, visible: true }
        } else if (
            paint.type === Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_LINEAR ||
            paint.type === Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_RADIAL ||
            paint.type === Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_ANGULAR ||
            paint.type === Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_DIAMOND
        ) {
            const colorStop =
                selectedGradientColorStopIndex !== undefined
                    ? paint.gradientStops?.[selectedGradientColorStopIndex]
                    : [...(paint?.gradientStops ?? [])]?.sort((a, b) => (a.position ?? 0) - (b.position ?? 0))[0]
            if (colorStop) {
                return {
                    type: Wukong.DocumentProto.PaintType.PAINT_TYPE_SOLID_PAINT,
                    color: { r: colorStop.color!.r, g: colorStop.color!.g, b: colorStop.color!.b },
                    opacity: colorStop.color!.a / 255,
                    colorVar: colorStop.colorVar,
                    visible: true,
                }
            }
        }
    }
    return getDefaultSolidPaint()
}

export function getVariableCreatePreselectColllection(
    collections: Wukong.DocumentProto.IVariableCreateLocalVisibleCollections[]
) {
    const collectionPreselected = enhancedLocalStorage.getSerializedItem(LocalStorageKey.CollectionPreselected)
    if (collectionPreselected && collections.some((v) => v.id === collectionPreselected)) {
        return collectionPreselected
    }
    return collections[0]?.id
}

export function ColorVariableCreate(props: ColorVariableCreateProps) {
    const variableCreate = useViewState('variableCreate')
    const selectedGradientColorStopIndex = useViewState('selectedGradientColorStopIndex')
    const command = useCommand()
    const colorSpace = useRenderColorSpace()
    const service = useAppContext().variableService
    const [paint, setPaint] = useState(getInitPaint(props.paints, selectedGradientColorStopIndex))
    const [collectionId, setCollectionId] = useState(
        getVariableCreatePreselectColllection(variableCreate?.localVisibleCollections ?? [])
    )
    const localVariableEditorService = useAppContext().variableService.localVariableEditorService
    const [variableName, setVariableName] = useState(
        localVariableEditorService.getNewVariableNameInCollectionAndType(
            collectionId,
            Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_COLOR
        )
    )
    const [variableNameInvalid, setVariableNameInvalid] = useState(false)
    const [variableDescription, setVariableDescription] = useState('')
    const [isOpen, setIsOpen] = useState(false)
    const [position, setPosition] = useState<Position>()
    const colorBlockRef = useRef<HTMLDivElement>(null)
    const containerRef = useRef<HTMLDivElement>(null)

    const colorVariableInfo = useMemo(() => {
        const nodeId = paint.colorVar?.value.alias
        if (!nodeId) {
            return
        }
        const res = command.invokeBridge(CommitType.Noop, GetComputedColorVariableCommand, {
            nodeId,
        })
        if (res.color) {
            setPaint((v) => ({ ...v, color: res.color, opacity: (res.color?.a ?? 255) / 255 }))
        }
        return res
    }, [command, paint.colorVar?.value.alias])

    const checkAndSetVariableNameRepeatState = useCallback(
        (_variableSetId: string, _variableName: string) => {
            const res = command.invokeBridge(CommitType.Noop, IsThisNameInvalidForCreatingVariable, {
                variableSetId: _variableSetId,
                variableName: _variableName,
            })
            setVariableNameInvalid(!!res.value)
            return !!res.value
        },
        [command]
    )

    const onChangeCollectionId = useCallback(
        (v: string) => {
            enhancedLocalStorage.setSerializedItem(LocalStorageKey.CollectionPreselected, v)
            setCollectionId(v)
            checkAndSetVariableNameRepeatState(v, variableName)
        },
        [checkAndSetVariableNameRepeatState, variableName]
    )

    const onChangeVariableName = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value
            setVariableName(value)
            if (collectionId) {
                checkAndSetVariableNameRepeatState(collectionId, value)
            }
        },
        [checkAndSetVariableNameRepeatState, collectionId]
    )

    const onClickCreateVariable = () => {
        const targetCollectionId = collectionId
            ? collectionId
            : service.createCollection(translation('Collection')).value
        if (targetCollectionId) {
            if (checkAndSetVariableNameRepeatState(targetCollectionId, variableName)) {
                return
            }
            const colorVarId = service.preCreateColorVariable({
                variableSetId: targetCollectionId,
                name: variableName,
                description: variableDescription,
                alias: paint.colorVar?.value.alias,
                colorValue: {
                    r: paint.color!.r,
                    g: paint.color!.g,
                    b: paint.color!.b,
                    a: paint.opacity! * 255,
                },
            })
            if (!colorVarId) {
                return
            }
            props.onClickCreateColorVariable({
                dataType: Wukong.DocumentProto.VariableDataType.VARIABLE_DATA_TYPE_ALIAS,
                resolvedDataType: Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_COLOR,
                value: { alias: colorVarId.value },
            })
        }
    }

    const onClickColorBlock = useCallback(() => {
        setIsOpen((v) => !v)
        const containerRect = containerRef.current?.getBoundingClientRect()
        const colorBlockRect = colorBlockRef.current?.getBoundingClientRect()
        setPosition(colorBlockRect && containerRect ? { left: containerRect.left, top: colorBlockRect.top } : undefined)
    }, [])

    const onChangeColor = (v: Wukong.DocumentProto.IRGB) => {
        setPaint({ ...paint, color: v, colorVar: null })
    }

    const onChangeOpacity = (v: number) => {
        setPaint({ ...paint, opacity: v, colorVar: null })
    }

    const onChangeColorVar = (colorVar: Wukong.DocumentProto.IVariableAliasData) => {
        setPaint({ ...paint, colorVar })
    }

    const onClickCutLink = () => {
        setPaint({ ...paint, colorVar: null })
    }

    return (
        <div ref={containerRef} data-testid="color-variable-create">
            <div className={classes.group}>
                {variableCreate?.localVisibleCollections?.length ? (
                    <>
                        <span className={classes.label}>{translation('Collection')}</span>
                        <Select.NormalSingleLevel
                            value={collectionId}
                            label={variableCreate?.localVisibleCollections.find((v) => v.id === collectionId)?.name}
                            onChange={onChangeCollectionId}
                            height="32px"
                            className={classes.value}
                            dataTestIds={{
                                triggerFocus: 'color-variable-create-collection-dropdown-trigger-focus',
                                container: 'color-variable-create-collection-dropdown-container',
                            }}
                        >
                            {variableCreate?.localVisibleCollections.map((v) => (
                                <Select.NormalSingleLevel.Option key={v.id} value={v.id} data-testid="collection-name">
                                    {v.name}
                                </Select.NormalSingleLevel.Option>
                            ))}
                        </Select.NormalSingleLevel>
                    </>
                ) : null}
                <span className={classes.label}>{translation('Name')}</span>
                <InputV2
                    value={variableName}
                    onChange={onChangeVariableName}
                    autoFocus
                    placeholder={translation('NamePlaceholder')}
                ></InputV2>
                <span className={classes.label}>{translation('Description')}</span>
                <InputV2.Textarea
                    value={variableDescription}
                    onChange={(e) => setVariableDescription((e.target as unknown as HTMLTextAreaElement).value)}
                    placeholder={translation('DescriptionPlaceholder')}
                    autoHeight
                ></InputV2.Textarea>
                <span className={classes.divider} />
                <span className={classes.label}>{translation('Value')}</span>
                {paint.colorVar ? (
                    <div
                        className={classnames(classes.color, isOpen && !!position)}
                        ref={colorBlockRef}
                        data-testid="color-variable-create-color-line"
                    >
                        <div className={classes.varIconName}>
                            <div className={classes.colorVarBlockContainer}>
                                <ColorVarIcon
                                    rgb={paint.color!}
                                    colorSpace={colorSpace}
                                    opacity={paint.opacity ?? undefined}
                                    onMouseDown={onClickColorBlock}
                                />
                            </div>
                            <ColorVarName
                                name={colorVariableInfo?.name ?? ''}
                                onClick={onClickColorBlock}
                                className={classes.colorVarTag}
                                nameClassName={isOpen && !!position ? undefined : classes.tagName}
                                selected={isOpen && !!position}
                            />
                        </div>
                        <Tooltip title={translation('DetachVariable')}>
                            <WKIconButton
                                preventFocus
                                icon={<MonoIconPanelLink16 />}
                                onClick={onClickCutLink}
                                className={classes.button}
                                data-testid="color-var-cut-link-button"
                            />
                        </Tooltip>
                    </div>
                ) : (
                    <div className={classes.color} ref={colorBlockRef} data-testid="color-variable-create-color-line">
                        <PaintIconColor
                            paint={paint as SolidPaint}
                            colorSpace={colorSpace}
                            onChangeColor={onChangeColor}
                            onMouseDownIcon={onClickColorBlock}
                            isWidth={isEnglishLanguage() ? 84 : 92}
                            onMouseDown={(e) => e.stopPropagation()}
                        />
                        <ScrubbableInputPercent
                            className={classes.opcityInput}
                            value={paint.opacity ?? undefined}
                            onChange={(v) => onChangeOpacity((v as number) / 100)}
                            min={0}
                            max={100}
                        />
                    </div>
                )}
            </div>
            <div className={classes.footer}>
                <WKButton
                    type="primary"
                    onClick={onClickCreateVariable}
                    disabled={!variableName || variableNameInvalid}
                    dataTestId="color-variable-create-button"
                >
                    {translation('CreateVariable')}
                </WKButton>
            </div>

            {isOpen && position && (
                <ColorPanelSubset
                    from={ColorInteractionFrom.COLOR_STOP}
                    position={position}
                    onCancel={onClickColorBlock}
                    paint={paint}
                    onChangeColor={onChangeColor}
                    onChangeOpacity={onChangeOpacity}
                    onChangeColorVar={onChangeColorVar}
                    styleId={undefined}
                    onChangePaintType={() => {}}
                    onChangeBlendMode={() => {}}
                    onChangeImagePaint={() => {}}
                    onChangeColorStops={() => {}}
                    onChangeTransform={() => {}}
                    enterClose={() => {}}
                    onClickAddIcon={() => {}}
                    onClickEditStyle={() => {}}
                    onChangePaints={undefined}
                    onClickCreateStyleButton={undefined}
                />
            )}
        </div>
    )
}
