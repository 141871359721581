import { memo, useMemo, useState } from 'react'
import { v4 } from 'uuid'
import {
    InputV2,
    MonoIconCommonError16,
    MonoIconCommonLoading16,
    WKAlert,
    WKButton,
} from '../../../../../../ui-lib/src'
import { useFeatureSwitch } from '../../../../kernel/switch/hooks'
import { getFileInfo, imgFileUrl } from './ai-gen-file-info'
import style from './ai-gen-ui-body.module.less'
import { translation } from './ai-gen-ui-body.translation'
import { AIGenUIByCodeFooter } from './ai-gen-ui-footer'
import { useAIGenUIInContext } from './use-ai-gen-ui'
import { useAiGenUiModalMaxHeight } from './use-ai-gen-ui-modal'

function CustomConfig({
    id,
    changeConfigId,
    removeOneConfig,
}: {
    id: string
    changeConfigId: (e: React.FocusEvent<HTMLTextAreaElement>) => void
    removeOneConfig: () => void
}) {
    const [configId, setConfigId] = useState(id)
    return (
        <div className="shrink-0 w-full flex flex-row gap-2">
            <InputV2.Textarea
                placeholder="config id"
                value={configId}
                onInput={(e) => {
                    setConfigId(e.currentTarget.value)
                    changeConfigId(e)
                }}
            />
            <WKButton className="w-1/2" type="text" onClick={() => removeOneConfig()}>
                remove {configId}
            </WKButton>
        </div>
    )
}

function AIGenUICustomConfigs() {
    const { changeConfigId, appendConfig, configs, removeOneConfig } = useAIGenUIInContext()

    const enableAIGenCustomConfig = useFeatureSwitch('ai-gen-custom-config')

    if (enableAIGenCustomConfig) {
        return (
            <div className="flex flex-col w-full h-auto gap-1 yb-2">
                {configs.map((v, i) => {
                    return (
                        <CustomConfig
                            id={v ? v.toString() : ''}
                            changeConfigId={(e) => changeConfigId(e, i)}
                            removeOneConfig={() => removeOneConfig(i)}
                            key={v4()}
                        />
                    )
                })}
                <WKButton type="text" onClick={() => appendConfig()}>
                    add new config
                </WKButton>
            </div>
        )
    } else {
        return <></>
    }
}

const UploadedImageContent = memo(function UploadedImageContent({
    imageFile,
    uploadedStatus,
}: {
    imageFile: File
    uploadedStatus: string | null
}) {
    if (imageFile) {
        switch (uploadedStatus) {
            case 'success':
            case 'over-size':
                return (
                    <img
                        src={imgFileUrl(imageFile)}
                        alt={imageFile.name}
                        data-testid="Image uploaded for AI generation"
                        className="w-full h-full object-contain bg-transparent"
                    />
                )
            case 'pending':
                return (
                    <div className="flex justify-center items-center w-full h-full">
                        <MonoIconCommonLoading16 className="animate-spin color-[var(--wk-l2-label-color-gray-8)]" />
                    </div>
                )
            default:
                return <></>
        }
    }
    return null
})

function UploadedImage() {
    const { imageFile, triggerRemoveImage, uploadedStatus } = useAIGenUIInContext()

    const fileInfo = useMemo(() => {
        return imageFile ? getFileInfo(imageFile, uploadedStatus) : ''
    }, [imageFile, uploadedStatus])

    if (imageFile) {
        return (
            <div className="w-auto h-auto wk-gray-border rounded flex flex-row gap-2 mt-2 p-2">
                <div
                    style={{
                        backgroundColor:
                            uploadedStatus === 'failed' ||
                            uploadedStatus === 'over-size' ||
                            uploadedStatus === 'file-format-restricted'
                                ? '#FFF3F0'
                                : '#F4F5F5',
                    }}
                    className="w-54px h-54px shrink-0 rounded-[3px]"
                >
                    <UploadedImageContent imageFile={imageFile} uploadedStatus={uploadedStatus} />
                </div>
                <div className="flex flex-col w-full p-1.75 overflow-hidden">
                    <span data-testid="Image name" className="wk-font-regular line-clamp-1 break-after-all">
                        {imageFile.name}
                    </span>
                    <span
                        data-testid="Image size"
                        className={`${
                            uploadedStatus === 'success' || uploadedStatus === 'pending'
                                ? 'text-gray'
                                : 'text-[var(--wk-red-8)]'
                        }`}
                    >
                        {fileInfo}
                    </span>
                </div>
                <div className="flex flex-col justify-center items-center shrink-0">
                    <div
                        data-testid="Remove Image"
                        className={style['ai-gen-ui-remove-image-btn']}
                        onClick={triggerRemoveImage}
                    >
                        <MonoIconCommonError16 />
                    </div>
                </div>
            </div>
        )
    }
    return null
}

export function AIGenUIBody() {
    const {
        updatePrompt,
        inputPrompt,
        shouldRetry,
        ref,
        MAX_PROMPT_LENGTH,
        triggerPasteImage,
        triggerDragImage,
        imageFile,
    } = useAIGenUIInContext()
    const maxHeight = useAiGenUiModalMaxHeight(imageFile !== null, shouldRetry)

    return (
        <div className={style['ai-gen-ui-body']} onPasteCapture={triggerPasteImage} onDragOver={triggerDragImage}>
            <AIGenUICustomConfigs />
            <InputV2.Textarea
                ref={ref}
                placeholder={translation('PlaceHolder')}
                autoFocus={true}
                minHeight={148}
                maxHeight={maxHeight}
                count={{
                    show: inputPrompt.length >= MAX_PROMPT_LENGTH,
                    max: MAX_PROMPT_LENGTH,
                }}
                autoHeight={true}
                value={inputPrompt}
                onInput={updatePrompt}
            />
            <UploadedImage />
            {shouldRetry ? (
                <div className={style['ai-gen-ui-alert']}>
                    <WKAlert.WithoutTitle rounded color={'error'}>
                        {translation(shouldRetry === 'either-failed' ? 'RetryText' : 'RetryTextSubset')}
                    </WKAlert.WithoutTitle>
                </div>
            ) : (
                <></>
            )}
            <AIGenUIByCodeFooter />
        </div>
    )
}
