import { Wukong } from '@wukong/bridge-proto'
import { multiplyMatrices, parseRGBA } from './utils'

function rotateAroundPoint(transformMatrix: number[][], x1: number, y1: number, radian: number) {
    // 平移到原点的矩阵 T1
    const translateToOrigin = [
        [1, 0, -x1],
        [0, 1, -y1],
        [0, 0, 1],
    ]

    // 旋转矩阵 R
    const rotationMatrix = [
        [Math.cos(radian), -Math.sin(radian), 0],
        [Math.sin(radian), Math.cos(radian), 0],
        [0, 0, 1],
    ]

    // 平移回原位置的矩阵 T2
    const translateBack = [
        [1, 0, x1],
        [0, 1, y1],
        [0, 0, 1],
    ]

    // 最终的变换矩阵 M = T2 * R * T1
    const rotatedMatrix = multiplyMatrices(multiplyMatrices(translateBack, rotationMatrix), translateToOrigin)

    // 将旋转矩阵应用到传入的变换矩阵
    return multiplyMatrices(rotatedMatrix, transformMatrix)
}

export function parseLinearGradient(input: string) {
    // 定义输出数据结构
    const gradient = {
        gradientTransform: {
            scaleX: 1,
            scaleY: 1,
            skewX: 0,
            skewY: 0,
            translateX: 0,
            translateY: 0,
        },
        gradientStops: [] as { position: number; color: { r: number; g: number; b: number; a: number } }[],
    }

    // 正则匹配 linear-gradient 参数
    const match = input.match(/linear-gradient\((?:(to [a-z\s]+|\d+deg|rad|turn),\s*)?(.+)\)/)
    if (!match) {
        throw new Error('Invalid linear-gradient syntax')
    }

    const [_, direction = '', stops] = match

    // 解析方向 (例如: 45deg, to left top, .25turn)
    const angle = parseGradientDirection(direction.trim())
    const radian = (angle * Math.PI) / 180

    const transformMatrix = [
        [1, 0, 0],
        [0, 1, 0],
        [0, 0, 1],
    ]
    const rotatedMatrix = rotateAroundPoint(transformMatrix, 0.5, 0.5, radian)

    gradient.gradientTransform = {
        scaleX: rotatedMatrix[0][0],
        scaleY: rotatedMatrix[1][1],
        skewX: rotatedMatrix[0][1],
        skewY: rotatedMatrix[1][0],
        translateX: rotatedMatrix[0][2],
        translateY: rotatedMatrix[1][2],
    }
    const stopArray = stops.split(/,(?![^(]*\))/)
    const totalStops = stopArray.length
    stopArray.forEach((stop, index) => {
        const parts = stop.trim().match(/(rgba?\(\d+,\s*\d+,\s*\d+(?:,\s*\d*\.?\d+)?\))\s*(\d+%?)?/)

        if (parts) {
            const color = parts[1].trim()
            const position = parts[2] ? parts[2].trim() : ''
            const rgba = parseRGBA(color)
            rgba.a *= 255
            // 如果没有位置，按顺序分配
            const pos = position ? parsePosition(position) : index / (totalStops - 1)

            gradient.gradientStops.push({
                position: pos,
                color: rgba,
            })
        }
    })

    return {
        type: Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_LINEAR,
        visible: true,
        opacity: 1,
        blendMode: Wukong.DocumentProto.BlendMode.BLEND_MODE_NORMAL,
        gradientStops: gradient.gradientStops,
        gradientTransform: gradient.gradientTransform,
    }
}

export function parseGradient(input: string, width: number): Wukong.DocumentProto.IPaint[] {
    const gradientRegex = /((?:linear|radial|conic)-gradient\([^()]*(?:\([^()]*\)[^()]*)*\))/g
    const arr = Array.from(input.matchAll(gradientRegex)).map((match) => match[0])

    const result: Wukong.DocumentProto.IPaint[] = []
    for (const item of arr) {
        if (item.includes('linear-gradient')) {
            result.push(parseLinearGradient(item))
        } else if (item.includes('radial-gradient')) {
            const radialGradient = parseRadialGradient(item, width)
            if (radialGradient) {
                result.push(radialGradient)
            }
        }
    }
    return result
}

// 解析角度 (方向关键字或角度值)
function parseGradientDirection(direction: string) {
    if (direction.endsWith('deg')) {
        return parseFloat(direction)
    }
    if (direction.endsWith('turn')) {
        return parseFloat(direction) * 360
    }
    if (direction.startsWith('to ')) {
        const keywords = {
            'to right': 0,
            'to right bottom': 45,
            'to bottom': 90,
            'to left bottom': 135,
            'to left': 180,
            'to left top': 225,
            'to top': 270,
            'to right top': 315,
        }
        return keywords[direction as keyof typeof keywords] ?? 90
    }
    return 90
}

// 解析位置百分比或绝对值
function parsePosition(position: string) {
    if (position.endsWith('%')) {
        return parseFloat(position) / 100
    }
    if (position.endsWith('px')) {
        return parseFloat(position) / 1000
    }
    return parseFloat(position)
}

export function parseRadialGradient(gradientStr: string, width: number) {
    // 改进的正则表达式：支持动态捕获形状/位置和多个颜色
    const regex = /^radial-gradient\((?<shapeAndPosition>[^,]+?),\s*(?<colors>.+)\)$/

    // 匹配 radial-gradient 表达式
    const match = gradientStr.match(regex)
    if (!match?.groups) {
        console.error('字符串不是有效的 radial-gradient 表达式')
        return null
    }

    const { shapeAndPosition, colors } = match.groups

    // 使用子正则进一步解析形状和位置
    const shapePositionRegex = /^(?<shape>\w+)(?:\s+at\s+(?<position>.+))?$/
    const shapeMatch = shapeAndPosition.trim().match(shapePositionRegex)

    if (!shapeMatch?.groups) {
        console.error('无法解析形状和位置部分')
        return null
    }

    const { shape, position } = shapeMatch.groups

    // 分割颜色部分：支持 rgba()、rgb() 等括号中的逗号
    const colorList = colors.split(/,(?![^(]*\))/).map((color) => color.trim())

    let resultColors = colorList.map((color) => {
        const colorMatch = color.match(/^(rgba?\([^)]*\))\s*(\d+px|\d+%)?$/)
        let pos = ''
        if (colorMatch) {
            color = colorMatch[1] // 提取颜色值
            pos = colorMatch[2] ?? '' // 提取 px 或 %
        }
        const rgba = parseRGBA(color)
        rgba.a *= 255
        let posPercent = undefined
        if (pos?.endsWith('px')) {
            posPercent = parseFloat(pos) / width
        } else if (pos?.endsWith('%')) {
            posPercent = parseFloat(pos) / 100
        }
        return { rgba, posPercent }
    })

    resultColors = resultColors.filter((color, index) => {
        if (index === 0) {
            return true
        }
        const prevColor = resultColors[index - 1]
        if (prevColor.posPercent !== undefined && color.posPercent !== undefined) {
            return color.posPercent >= prevColor.posPercent
        }
        return true
    })
    // css中如果没有到100%, 相当于同位置有个透明的[91976]
    if (resultColors.length > 0) {
        const lastColor = resultColors[resultColors.length - 1]
        if (lastColor.posPercent !== undefined && lastColor.posPercent < 1) {
            resultColors.push({
                rgba: { r: 0, g: 0, b: 0, a: 0 },
                posPercent: lastColor.posPercent,
            })
        }
    }

    const gradientInfo = {
        type: 'radial-gradient',
        shape: shape || 'circle',
        position: position ? position.split(/\s+/) : ['50%', '50%'],
        colors: resultColors,
    }

    return {
        type: Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_RADIAL,
        visible: true,
        opacity: 1,
        blendMode: Wukong.DocumentProto.BlendMode.BLEND_MODE_NORMAL,
        gradientStops: gradientInfo.colors.map((item, index) => ({
            position: item.posPercent ?? index / (gradientInfo.colors.length - 1),
            color: item.rgba,
        })),
    }
}
