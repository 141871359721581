import { Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { useCallback, useState } from 'react'
import { MonoIconCommonArrowLeft16, Scrollbar, WKButton, WKDivider, WKTypography } from '../../../../../../ui-lib/src'
import { useLibraryComponentService } from '../../../../main/app-context'
import { useViewState } from '../../../../view-state-bridge'
import { LibraryRemoteChangeListItem } from '../library-remote-change-list-item/library-remote-change-list-item'
import classes from './library-remote-change-list.module.less'
import { translation } from './library-remote-change-list.translation'

// 引用组件库的更新列表
export function LibraryRemoteChangeList(props: { className?: string }) {
    const {
        libraryModalRouterService: { goToRemoteLibraryHome },
    } = useLibraryComponentService()
    const libraryUpgradeModal = useViewState('libraryUpgradeModal')
    const remoteOperations = libraryUpgradeModal?.items ?? []

    return (
        <div className={classNames(classes.container, props.className)}>
            <div className={classes.topContainer}>
                <div className="flex items-center" onClick={goToRemoteLibraryHome}>
                    <MonoIconCommonArrowLeft16 className="color-$wk-l2-label-color-gray-8" />
                    <span className={classes.docNameContainer}>{translation('Update')}</span>
                </div>
            </div>
            <WKDivider className={classes.divider} />
            <Scrollbar className={classes.scrollWrapper}>
                {remoteOperations.length ? (
                    remoteOperations.map((remoteLibraryChange, index) => (
                        <div key={remoteLibraryChange.operationId}>
                            <LibraryRemoteChangeListItem remoteChange={remoteLibraryChange} />
                            {index !== remoteOperations.length - 1 && <WKDivider className={classes.divider} />}
                        </div>
                    ))
                ) : (
                    <WKTypography.Paragraph color="placeholder" className={classes.emptyContainer}>
                        {translation('NoUpdatesAvailable')}
                    </WKTypography.Paragraph>
                )}
            </Scrollbar>
            {remoteOperations.length > 0 && (
                <div className={classes.footerContainer}>
                    <WKDivider className={classes.divider} />
                    <div className={classes.footerRightContainer}>
                        <LibraryRemoteChangeListAllUpdateBtnV2 operations={remoteOperations} />
                    </div>
                </div>
            )}
        </div>
    )
}

// 按钮-全部更新
function LibraryRemoteChangeListAllUpdateBtnV2({
    operations,
}: {
    operations: Wukong.DocumentProto.IVLibraryComponentUpdateModalItem[]
}) {
    const { upgradeComponentsExecutor } = useLibraryComponentService()
    const [loading, setLoading] = useState(false)

    const updateAllComponentVersion = useCallback(async () => {
        setLoading(true)
        await upgradeComponentsExecutor.batchRun(operations)
        setLoading(false)
    }, [operations, upgradeComponentsExecutor])

    return (
        <WKButton
            type="primary"
            loading={loading}
            onClick={updateAllComponentVersion}
            data-testid="library-all-update-btn"
        >
            {translation('UpdateAll')}
        </WKButton>
    )
}
