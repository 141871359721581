import { forwardRef, Ref, useEffect, useImperativeHandle, useRef } from 'react'
import { Scrollbar, ScrollbarRef } from '../../../../../../ui-lib/src'
import { featureSwitchManager } from '../../../../kernel/switch/core'
import { ConversationType, DialogueData, MessageItemContentProps } from '../../../../kernel/interface/chatbot'
import { useAppContext } from '../../../../main/app-context'
import { AgentAnswer } from './chatbot-agent-answer'
import { EditInitSuggestMessage } from './chatbot-suggest-dialog'
import { ChatbotTextArea } from './chatbot-text-area'
import { ChatbotConversationRef } from './type'

export interface ChatbotConversationProps {
    dialogueDataList: DialogueData[]
    conversationId: string | undefined
}

const _ChatbotConversation = (
    { dialogueDataList, conversationId }: ChatbotConversationProps,
    ref: Ref<ChatbotConversationRef>
) => {
    const chatbotService = useAppContext().chatbotService
    const pendingMessages = chatbotService.stateStore.use.pendingMessages()
    const pendingMessageThumbnailBase64 = chatbotService.stateStore.use.pendingMessageThumbnailBase64()
    const conversationType = chatbotService.getCurrentConversationType() ?? ConversationType.ASK

    const scrollbarRef = useRef<ScrollbarRef>(null)

    useEffect(() => {
        scrollbarRef.current?.scrollToBottom()
    }, [pendingMessages])

    useImperativeHandle(ref, () => ({
        scrollBy(y: number) {
            if (!scrollbarRef.current) return
            const top = scrollbarRef.current?.getScrollTop()
            const maxScrollTop = scrollbarRef.current?.getScrollHeight() - scrollbarRef.current?.getClientHeight()
            if (top >= maxScrollTop) {
                scrollbarRef.current.scrollToBottom()
            } else {
                scrollbarRef.current.scrollTop(top + y)
            }
        },
    }))

    return (
        <Scrollbar height="100%" onPointerDown={(e) => e.stopPropagation()} ref={scrollbarRef}>
            <div className="px-6 pt-4 pb-1 flex flex-col gap-5">
                {featureSwitchManager.isEnabled('chatbot-v02') && conversationType === ConversationType.EXECUTE && (
                    <EditInitSuggestMessage />
                )}
                {dialogueDataList.map((dialogueData, index) => (
                    <div key={index} className="flex flex-col gap-5">
                        <UserMessage
                            message={dialogueData.questionInput.messages[0]?.content ?? []}
                            selectedThumbnail={dialogueData.questionInput.selectionThumbnail}
                        />
                        {dialogueData.questionAnswer && (
                            <AgentAnswer
                                pending={false}
                                answer={dialogueData.questionAnswer}
                                conversationType={undefined}
                                conversationId={conversationId}
                                dialogueId={dialogueData.dialogueId}
                            />
                        )}
                    </div>
                ))}
                {pendingMessages && (
                    <PendingDialogue
                        conversationType={conversationType}
                        pendingMessages={pendingMessages}
                        pendingMessageThumbnailBase64={pendingMessageThumbnailBase64}
                    />
                )}
            </div>
        </Scrollbar>
    )
}

export const ChatbotConversation = forwardRef(_ChatbotConversation)

const UserMessage = ({
    message,
    selectedThumbnail,
}: {
    message: MessageItemContentProps[]
    selectedThumbnail?: string
}) => {
    return (
        <div className="flex-self-end box-border py-10px px-4 rounded-1 max-w-320px bg-$wk-l2-fill-color-gray-1 flex flex-col gap-2">
            {message.map((item, index) => {
                if (item.image) {
                    return <img src={item.image} alt="agent-message" key={index} />
                }
                if (item.text) {
                    return <ChatbotTextArea text={item.text} key={index} />
                }
                return null
            })}
            {selectedThumbnail && (
                <img
                    className="box-border max-h-200px max-w-200px flex-self-end border-12px border-solid border-$wk-l2-stroke-color-gray-2"
                    src={selectedThumbnail}
                    data-testid="selection-preview-message"
                />
            )}
        </div>
    )
}

const PendingDialogue = ({
    conversationType,
    pendingMessages,
    pendingMessageThumbnailBase64,
}: {
    conversationType: ConversationType
    pendingMessages: MessageItemContentProps[]
    pendingMessageThumbnailBase64?: string
}) => {
    return (
        <div key={`pending-${conversationType}`} className="flex flex-col gap-5">
            <UserMessage message={pendingMessages} selectedThumbnail={pendingMessageThumbnailBase64} />
            <AgentAnswer
                pending={true}
                conversationType={conversationType}
                answer={undefined}
                dialogueId={undefined}
                conversationId={undefined}
            />
        </div>
    )
}
