export const GroupNameItem = ({ name }: { name: string }) => {
    return (
        <div className="flex items-center h-28px px-16px">
            <div
                data-testid="float-variable-group-name-item"
                className="wk-font-medium wk-text-12 overflow-hidden text-ellipsis text-nowrap text-gray"
            >
                {name}
            </div>
        </div>
    )
}
