import classNames from 'classnames'
import { useParams } from 'react-router'
import { BlockUnit } from './block-unit'

export const Admin = () => {
    const pathname = useParams()['*'] ?? ''
    return (
        <div className="px-8">
            <BlockUnit className="w-140px mt-13px h-34px" />
            <div className="pt-33px pb-11px gap-40px flex b-b border-color-$wk-l2-stroke-color-gray-2">
                <BlockUnit className={classNames('relative w-9 h-7')} />
                <BlockUnit className={classNames('relative w-9 h-7')} />
                <BlockUnit className={classNames('relative w-9 h-7')} />
                <BlockUnit className={classNames('relative w-9 h-7')} />
                <BlockUnit className={classNames('relative w-9 h-7')} />
                <BlockUnit className={classNames('relative w-9 h-7')} />
                <BlockUnit className={classNames('relative w-9 h-7')} />
                <BlockUnit className={classNames('relative w-9 h-6')} />
            </div>
        </div>
    )
}
