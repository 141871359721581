export type Nil = null | undefined

// 主要为了收窄 switch case 中的类型，避免到达 default 逻辑
export const assertNever = (value: never, errorMsg?: string): never => {
    throw new Error(errorMsg ?? 'Unexpected object: ' + value)
}

export function assert(value: unknown, message: string): asserts value {
    if (!value) {
        throw Error(message)
    }
}

export const tupleNum = <T extends number[]>(...args: T) => args

export const isNotNullOrUndefined = <T>(value: T | null | undefined): value is T => {
    return value !== null && value !== undefined
}

// 将 T 中所有 readonly 转成非 readonly
export type DRType<T> = Partial<{
    -readonly [P in keyof T]: T[P]
}>

export function isString(value: any): value is string {
    return typeof value === 'string'
}

export type DeepRequired<T> = T extends (...args: any[]) => any
    ? T
    : T extends object
    ? {
          [K in keyof T]-?: NonNullable<DeepRequired<T[K]>>
      }
    : T extends any[]
    ? Array<DeepRequired<T[number]>>
    : NonNullable<T>

export type DeepRequiredExcept<T, K extends keyof T> = DeepRequired<Omit<T, K>> & Partial<Pick<T, K>>
