import { UpdateScrollBehavior, UpdateScrollOverflow, Wukong } from '@wukong/bridge-proto'
import { useCallback } from 'react'
import { MonoIconCommonWarningLine16, Select, WKPopover } from '../../../../../../ui-lib/src'
import { useViewState } from '../../../../view-state-bridge'
import { useCommand } from '../../../context/document-context'
import style from '../prototype.module.less'
import { IconHorizonalWarning, IconVerticalWarning } from './icon'
import { translation } from './index.translation'

const ScrollBehaviorInSelector = new Map<Wukong.DocumentProto.ScrollBehavior, string>([
    [Wukong.DocumentProto.ScrollBehavior.SCROLL_BEHAVIOR_SCROLLS, translation('ScrollWithParent')],
    [
        Wukong.DocumentProto.ScrollBehavior.SCROLL_BEHAVIOR_FIXED_WHEN_CHILD_OF_SCROLLING_FRAME,
        translation('FixedStayInPlace'),
    ],
    [Wukong.DocumentProto.ScrollBehavior.SCROLL_BEHAVIOR_STICKY_SCROLLS, translation('StickyTop')],
])

const ScrollBehavior = new Map<Wukong.DocumentProto.ScrollBehavior, string>([
    [Wukong.DocumentProto.ScrollBehavior.SCROLL_BEHAVIOR_SCROLLS, translation('ScrollWithParent')],
    [Wukong.DocumentProto.ScrollBehavior.SCROLL_BEHAVIOR_FIXED_WHEN_CHILD_OF_SCROLLING_FRAME, translation('Fixed')],
    [Wukong.DocumentProto.ScrollBehavior.SCROLL_BEHAVIOR_STICKY_SCROLLS, translation('Sticky')],
])

const ScrollPostionType = new Map<Wukong.DocumentProto.ScrollDirection, string>([
    [Wukong.DocumentProto.ScrollDirection.SCROLL_DIRECTION_NONE, translation('NoScrolling')],
    [Wukong.DocumentProto.ScrollDirection.SCROLL_DIRECTION_HORIZONTAL, translation('Horizontal')],
    [Wukong.DocumentProto.ScrollDirection.SCROLL_DIRECTION_VERTICAL, translation('Vertical')],
    [Wukong.DocumentProto.ScrollDirection.SCROLL_DIRECTION_BOTH, translation('BothDirections')],
])

export function ProtoTypeScrollBehavior() {
    const state = useViewState('prototypePanelScrollBehaviorViewState')
    const scrollPosition = state?.scrollPosition
    const overflow = state?.overflow
    const command = useCommand()

    const onChangeBehavior = useCallback(
        (behavior: Wukong.DocumentProto.ScrollBehavior) => {
            command.DEPRECATED_invokeBridge(UpdateScrollBehavior, { behavior })
            command.commitUndo()
        },
        [command]
    )

    const onChangeOverflow = useCallback(
        (direction: Wukong.DocumentProto.ScrollDirection) => {
            command.DEPRECATED_invokeBridge(UpdateScrollOverflow, { direction })
            command.commitUndo()
        },
        [command]
    )

    if (!state?.show) {
        return null
    }

    return (
        <div className={style.panel} data-testid={'prototype-scroll-behavior'}>
            <div className={style.panelTitle}>
                <div className={style.title}>{translation('ScrollBehavior')}</div>
            </div>
            {scrollPosition?.isShow && (
                <div className={style.scrollSinleRow} data-testid={'prototype-scroll-position'}>
                    <div className={style.scrollLabelContainer}>
                        <span className={style.scrollLabel}>{translation('Position')}</span>
                    </div>
                    <div className={style.selectContainer}>
                        <Select.NormalSingleLevel
                            value={scrollPosition.scrollBehavior}
                            disabled={!scrollPosition.isActive}
                            isDisabledStyle={false}
                            label={ScrollBehavior.get(scrollPosition.scrollBehavior)}
                            onChange={onChangeBehavior}
                            minWidth={124}
                            isMixed={scrollPosition.isMixed}
                        >
                            {[...ScrollBehaviorInSelector.entries()].map((value) => {
                                const type = value[0]
                                return (
                                    <Select.NormalSingleLevel.Option
                                        value={type}
                                        key={type}
                                        backwardIcon={''}
                                        disabled={
                                            !scrollPosition.isFixedActive &&
                                            type ===
                                                Wukong.DocumentProto.ScrollBehavior
                                                    .SCROLL_BEHAVIOR_FIXED_WHEN_CHILD_OF_SCROLLING_FRAME
                                        }
                                        dataTestId={`scroll-position-${type}`}
                                    >
                                        {ScrollBehaviorInSelector.get(type)}
                                    </Select.NormalSingleLevel.Option>
                                )
                            })}
                        </Select.NormalSingleLevel>
                    </div>
                </div>
            )}
            {overflow?.isShow && (
                <div className={style.scrollSinleRow} data-testid={'prototype-scroll-overflow'}>
                    <div className={style.scrollLabelContainer}>
                        <span className={style.scrollLabel}>{translation('Overflow')}</span>
                    </div>
                    <div className={style.selectContainer}>
                        <Select.NormalSingleLevel
                            value={overflow.direction}
                            isMixed={overflow.isMixed}
                            disabled={!overflow.isActive}
                            isDisabledStyle={false}
                            label={ScrollPostionType.get(overflow.direction)}
                            onChange={onChangeOverflow}
                            minWidth={124}
                            dataTestIds={{ triggerContainer: 'overflow-select' }}
                        >
                            {[...ScrollPostionType.entries()].map((value) => {
                                const type = value[0]
                                return (
                                    <Select.NormalSingleLevel.Option
                                        value={type}
                                        key={type}
                                        backwardIcon={''}
                                        disabled={false}
                                    >
                                        {ScrollPostionType.get(type)}
                                    </Select.NormalSingleLevel.Option>
                                )
                            })}
                        </Select.NormalSingleLevel>
                    </div>
                    {!overflow.isValid && (
                        <WKPopover
                            placement="bottom-left"
                            contents={
                                <div className="flex">
                                    <div className={style.iconCenter}>
                                        {overflow.direction ===
                                        Wukong.DocumentProto.ScrollDirection.SCROLL_DIRECTION_HORIZONTAL ? (
                                            <IconHorizonalWarning />
                                        ) : (
                                            <IconVerticalWarning />
                                        )}
                                    </div>

                                    <span className="pl-2 w-46">{translation('OverflowWarning')}</span>
                                </div>
                            }
                            contentClassName="p-4"
                            className={style.scrollInfoIconTrigger}
                        >
                            <div
                                className="color-$wk-l2-label-color-gray-8"
                                data-testid={'prototype-scroll-overflow-warning'}
                            >
                                <MonoIconCommonWarningLine16 />
                            </div>
                        </WKPopover>
                    )}
                </div>
            )}
        </div>
    )
}
