import { Wukong } from '@wukong/bridge-proto'
import { useLayoutEffect, useRef } from 'react'
import { MonoIconCommonUpload16, WKButton } from '../../../../../../ui-lib/src'
import {
    AISearchFileOwnerCondition,
    AiSearchResourceType,
    AISearchSortingRule,
} from '../../../../kernel/interface/ai-search'
import { DeepRequired, useViewState } from '../../../../view-state-bridge'
import { useCommand } from '../../../context/document-context'
import { nodeToCompressPNGBlob } from '../../design/export/util'
import { translation } from './ai-search-init-body.translation'
import { AISearchNodeIcon } from './ai-search-node-icon'
import { SelectNodeIcon } from './select-node-icon'
import { AiSearchInitModel } from './use-ai-search'

function AISearchNode({
    node,
    model,
}: {
    node: DeepRequired<Wukong.DocumentProto.IAISearchNode>
    model: AiSearchInitModel
}) {
    const command = useCommand()
    const selected = useRef(false)
    const handleSelectNode = async () => {
        if (selected.current) {
            // 避免重复点击
            return
        }

        selected.current = true
        const blob = await nodeToCompressPNGBlob(
            command,
            [node.nodeId],
            {
                type: Wukong.DocumentProto.ExportConstraintType.EXPORT_CONSTRAINT_TYPE_SCALE,
                value: 1,
            },
            Wukong.DocumentProto.DocumentColorProfile.DOCUMENT_COLOR_PROFILES_R_G_B
        )
        selected.current = false

        if (blob) {
            const file = new File([blob], 'node.png', { type: 'image/png' })
            model.setSearchInfo({
                type: 'NODE',
                file,
                node,
                searchFilter: {
                    resourceType: AiSearchResourceType.ORGANIZATION,
                    fileOwnerCondition: AISearchFileOwnerCondition.ANY_USER,
                    sortingRule: AISearchSortingRule.SIMILARITY,
                },
            })
        }
    }

    const containerRef = useRef<HTMLDivElement>(null)
    const quotRef = useRef<HTMLSpanElement>(null)
    useLayoutEffect(() => {
        if (containerRef.current && quotRef.current) {
            const isEllipsis = containerRef.current.scrollHeight > containerRef.current.clientHeight
            quotRef.current.style.display = isEllipsis ? 'block' : 'none'
        }
    }, [node.name])

    return (
        <div className="w-full h-346px flex flex-col items-center">
            <div className="grow-3"></div>
            <div className="flex-none w-full box-border flex flex-col items-center justify-center gap-32px p-40px">
                <div
                    ref={containerRef}
                    className="max-h-192px wk-font-regular wk-text-12 color-$wk-l2-label-color-gray-11 overflow-hidden text-ellipsis break-words relative"
                    style={{
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 8,
                        overflow: 'hidden',
                    }}
                >
                    <div className="inline-flex flex-row items-center mr-2px">
                        {translation('SelectedNode')}
                        <AISearchNodeIcon node={node} />
                    </div>
                    <span className={`${node.isPurple ? 'color-$wk-purple-7' : 'text-black'}`}>
                        <span>{node.name}</span>
                        <span className="color-$wk-l2-label-color-gray-11">{translation('SelectedNodeSuffix')}</span>
                    </span>
                    <span ref={quotRef} className="absolute right-0 top-140px">
                        &quot;
                    </span>
                </div>
                <div className="w-full flex flex-row justify-center items-center">
                    <WKButton
                        type="primary"
                        onClick={handleSelectNode}
                        className="w-120px"
                        dataTestId="ai-search-start-search"
                    >
                        {translation('StartSearch')}
                    </WKButton>
                </div>
            </div>
            <div className="grow-7"></div>
        </div>
    )
}

export function AiSearchInitBody({ model }: { model: AiSearchInitModel }) {
    const aiSearchSelection = useViewState('aiSearchSelection')

    const handleUploadImage = () => {
        const input = document.createElement('input')
        input.type = 'file'
        input.accept = 'image/*'

        input.onchange = (e) => {
            const file = (e.target as HTMLInputElement).files?.[0]
            if (file) {
                model.setSearchInfo({
                    type: 'IMAGE',
                    file,
                    searchFilter: {
                        resourceType: AiSearchResourceType.ORGANIZATION,
                        fileOwnerCondition: AISearchFileOwnerCondition.ANY_USER,
                        sortingRule: AISearchSortingRule.SIMILARITY,
                    },
                })
            }
        }

        input.click()
    }

    if (aiSearchSelection?.node) {
        return <AISearchNode node={aiSearchSelection.node} model={model} />
    }

    return (
        <div className="w-full box-border flex-1 flex flex-col items-center gap-32px pt-30px pb-60px">
            <div className="flex flex-col items-center gap-20px">
                <div className="w-160px h-96px">
                    <SelectNodeIcon />
                </div>
                <div className="wk-font-medium wk-text-14 text-black">{translation('SelectNode')}</div>
            </div>
            <div className="w-full flex flex-row items-center justify-center h-22px gap-12px">
                <div className="w-50px border-t border-$wk-l2-stroke-color-gray-2"></div>
                <div className="wk-font-regular wk-text-14 text-gray">{translation('Or')}</div>
                <div className="w-50px border-t border-$wk-l2-stroke-color-gray-2"></div>
            </div>
            <WKButton
                type="secondary"
                size="medium"
                icon={<MonoIconCommonUpload16 />}
                onClick={handleUploadImage}
                dataTestId="ai-search-upload-image"
            >
                {translation('UploadImage')}
            </WKButton>
        </div>
    )
}
