import { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { useEvent } from 'react-use'
import {
    DraggablePopupV2,
    DropdownV2,
    MonoIconCommonAdd24,
    MonoIconCommonClose16,
    MonoIconCommonCodeConsole16,
    MonoIconCommonImportPlugin24,
    MonoIconCommonWarningLine16,
    MonoIconPanelAdjust16,
    Position,
    Scrollbar,
    Tooltip,
    WKIconButton,
    WKTextButton,
} from '../../../../../../../ui-lib/src'
import { getMotiffName } from '../../../../../../../util/src'
import { usePluginService } from '../../../../../main/app-context'
import { PluginDevStatus, usePluginDevelopment } from '../use-plugin-development'
import { PluginDevelopmentItem } from './plugin-dev-main-item'
import { translation } from './plugin-dev-main.translation'
import { PluginTextButton } from './text-button'

export function PluginDevelopmentMain() {
    const pluginService = usePluginService()
    const { pluginDevStatus, setPluginDevStatus, importCustomPlugin, localPluginsWithPublishInfo } =
        usePluginDevelopment()

    const [initPosition, setInitPosition] = useState<Position>()
    const [_position, _setPosition] = useState<Position>()

    const { autoHeightMax } = useAutoScrollbarHeight()

    useEffect(() => {
        // 打开了其他弹窗时，记录当前位置并暂时隐藏，实现再打开时恢复位置
        if (pluginDevStatus !== PluginDevStatus.Main) {
            setInitPosition(_position)
        }
    }, [pluginDevStatus, _position])

    return (
        <DraggablePopupV2
            visible={pluginDevStatus === PluginDevStatus.Main}
            position={initPosition}
            onMove={_setPosition}
            closable={false}
            header={
                <PluginDevMainHeader
                    onClose={() => {
                        pluginService.states.getState().updateIsOpenPluginDevelopment(false)
                        setPluginDevStatus(PluginDevStatus.Main)
                    }}
                />
            }
            width={536}
            footer={
                <div className="py-4 px-6 text-right flex justify-start items-center">
                    <MonoIconCommonWarningLine16 className="color-$wk-l2-label-color-gray-6 mr-1" />
                    <div className="wk-text-12 wk-font-regular color-$wk-l2-label-color-gray-13">
                        {translation('MoreInformation')}
                        <WKTextButton
                            type="primary"
                            size={12}
                            onClick={() => {
                                if (window.localBridge?.openExternal) {
                                    window.localBridge.openExternal(`${window.location.origin}/plugin-docs`)
                                } else {
                                    window.open(`/plugin-docs`, '_blank')
                                }
                            }}
                        >
                            {translation('DeveloperDocument', { name: getMotiffName() })}
                        </WKTextButton>
                    </div>
                </div>
            }
            bodyClassName="p-0"
        >
            <div className="flex box-border mx-24px py-24px border-b-1 border-b-solid border-$wk-l2-stroke-color-gray-2">
                <PluginTextButton
                    onClick={() => {
                        setPluginDevStatus(PluginDevStatus.Create)
                    }}
                    icon={<MonoIconCommonAdd24 className="color-$wk-l2-label-color-gray-13 mr-2" />}
                    label={translation('CreatePlugin')}
                />

                <PluginTextButton
                    className="ml-16px"
                    onClick={importCustomPlugin}
                    icon={<MonoIconCommonImportPlugin24 className="color-$wk-l2-label-color-gray-13 mr-2" />}
                    label={translation('ImportPlugin')}
                />
            </div>
            <Scrollbar autoHeight autoHeightMax={autoHeightMax}>
                <div className="p-24px">
                    {localPluginsWithPublishInfo.length == 0 ? (
                        <div className="flex justify-center items-center h-112px wk-text-12 wk-font-medium color-$wk-l2-label-color-gray-6">
                            {translation('NoPlugin')}
                        </div>
                    ) : (
                        <div className="flex flex-col [&>*:not(:last-child)]:mb-20px">
                            {localPluginsWithPublishInfo.map((plugin) => (
                                <PluginDevelopmentItem
                                    key={`${plugin.path}_${plugin.publishInfo?.id}`}
                                    plugin={plugin}
                                />
                            ))}
                        </div>
                    )}
                </div>
            </Scrollbar>
        </DraggablePopupV2>
    )
}

function useAutoScrollbarHeight() {
    const [autoHeightMax, setAutoHeightMax] = useState<number | undefined>()

    const calcAutoHeightMax = useCallback(() => {
        const popupMaxHeight = Math.min(800, window.innerHeight - 48 - 8 * 2)
        const scrollMaxHeight = popupMaxHeight - 236
        setAutoHeightMax(scrollMaxHeight)
    }, [])

    useLayoutEffect(() => {
        calcAutoHeightMax()
    }, [calcAutoHeightMax])

    useEvent('resize', () => {
        calcAutoHeightMax()
    })

    return { autoHeightMax }
}

enum PluginDevSettingType {
    UseSandbox = 'useSandbox',
    UseHotReload = 'useHotReload',
}

function PluginDevMainHeader({ onClose }: { onClose: () => void }) {
    const pluginService = usePluginService()
    const { pluginDevUseHotReload, pluginDevUseSandbox, setPluginDevUseHotReload, setPluginDevUseSandbox } =
        usePluginDevelopment()

    return (
        <div className="py-13px justify-between pr-4 pl-6 flex items-center">
            <div className="wk-text-14 wk-font-medium flex-1" data-testid="dialog-title">
                {translation('DevelopPrivatePluginPanel')}
            </div>
            <Tooltip title={translation('OpenOrCloseConsole')}>
                <WKIconButton
                    data-testid="code-console-icon"
                    className="shrink-0 mr-2"
                    onClick={() => {
                        window.localBridge?.toggleDevTools?.()
                    }}
                    icon={<MonoIconCommonCodeConsole16 />}
                />
            </Tooltip>
            <Tooltip title={translation('AdvancedOptions')} triggerRefKey="getTriggerElement">
                <DropdownV2.IconSingleLevel
                    classNameTriggerFocus="color-$wk-l2-label-color-gray-8"
                    label={<MonoIconPanelAdjust16 />}
                    onChange={(value: PluginDevSettingType) => {
                        switch (value) {
                            case PluginDevSettingType.UseSandbox:
                                setPluginDevUseSandbox(!pluginDevUseSandbox)
                                break
                            case PluginDevSettingType.UseHotReload: {
                                const lastedIsUseHotReload = !pluginDevUseHotReload
                                setPluginDevUseHotReload(lastedIsUseHotReload)
                                if (lastedIsUseHotReload) {
                                    // 开启热重载时，如果插件正在运行，则直接绑定本地插件监听
                                    pluginService.openLocalPluginHotReload()
                                }
                                break
                            }
                        }
                    }}
                    dataTestIds={{
                        triggerFocus: 'plugin-dev-setting-icon',
                    }}
                >
                    <DropdownV2.IconSingleLevel.Option
                        isSelect={!pluginDevUseSandbox}
                        value={PluginDevSettingType.UseSandbox}
                        dataTestId="plugin-dev-use-developer-vm"
                    >
                        {translation('UseDeveloperVM')}
                    </DropdownV2.IconSingleLevel.Option>
                    <DropdownV2.IconSingleLevel.Option
                        isSelect={pluginDevUseHotReload}
                        value={PluginDevSettingType.UseHotReload}
                        dataTestId="plugin-dev-use-hot-reload"
                    >
                        {translation('HotReload')}
                    </DropdownV2.IconSingleLevel.Option>
                </DropdownV2.IconSingleLevel>
            </Tooltip>
            <WKIconButton
                data-testid="close-icon"
                className="shrink-0 ml-2"
                onClick={onClose}
                icon={<MonoIconCommonClose16 />}
            />
        </div>
    )
}
