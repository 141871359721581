/* eslint-disable import/no-deprecated */
import {
    ExecuteRecoverToRemoteDefaultVariantCommand,
    FetchRemoteComponentSetDataForRecover,
    GetDocReadonlyCommand,
    RecoverComponentCommand,
    ShowRecoverComponentModal,
    type Wukong,
} from '@wukong/bridge-proto'
import type { HTMLAttributes } from 'react'
import { wkDialogConfirm, WKToast } from '../../../../../../ui-lib/src'
import { signalTimeout } from '../../../../../../util/src/abort-controller/timers'
import { TraceableAbortSignal } from '../../../../../../util/src/abort-controller/traceable-abort-controller'
import type { CommandInvoker } from '../../../../document/command/command-invoker'
import type { NodeId } from '../../../../document/node/node'
import { Bridge } from '../../../../kernel/bridge/bridge'
import { ComponentGetVO, LibraryId } from '../../../../kernel/interface/component-style'
import { LibraryContentVO } from '../../../../kernel/interface/library'
import { featureSwitchManager } from '../../../../kernel/switch'
import { ServiceClass } from '../../../../kernel/util/service-class'
import { LibraryResourceOssClientType } from '../../../../share/component-style-library/service/library-resource-downloader'
import { LibraryTestId } from '../../../../window'
import { ComponentService } from '../../component/component-service'
import type { LibraryNodeDataService } from './library-node-data-service'
import type { LibraryNotifySyncService } from './library-notify-sync-service'
import { translation } from './library-recover-service.translation'

// 恢复组件相关
export class LibraryRecoverService extends ServiceClass {
    constructor(
        protected override readonly bridge: Bridge,
        private readonly commandInvoker: CommandInvoker,
        private readonly libraryNotifySyncService: LibraryNotifySyncService,
        private readonly libraryNodeDataService: LibraryNodeDataService,
        private readonly componentService: ComponentService,
        private readonly signal: TraceableAbortSignal
    ) {
        super(bridge)
        this.initBindJsCall()
    }

    private initBindJsCall = () => {
        this.bridge.bind(ShowRecoverComponentModal, this.onShowRecoverComponentModal, {
            signal: this.signal,
        })
        this.bridge.bind(FetchRemoteComponentSetDataForRecover, this.onFetchRemoteComponentSetDataForRecover, {
            signal: this.signal,
        })
    }

    private onShowRecoverComponentModal = async ({
        componentId,
        fromFig,
    }: Wukong.DocumentProto.IArg_ShowRecoverComponentModal) => {
        if (!componentId) {
            return
        }

        const args = await this.getShowRecoverComponentModalArgs(componentId)
        if (this.isDestroy) {
            return
        }
        if (args.isMoved) {
            if (this.commandInvoker.DEPRECATED_invokeBridge(GetDocReadonlyCommand).value) {
                WKToast.show(translation('MovedTo', { moveTo: args.docNameOfMovedTo }))
            } else {
                wkDialogConfirm.show({
                    title: translation('RestoreComponent'),
                    content: translation('MovedToLong', { moveTo: args.docNameOfMovedTo }),
                    cancelText: translation('Cancel'),
                    okText: translation('Restore'),
                    okButtonProps: {
                        type: 'primary',
                        ...({
                            ['data-testid']: LibraryTestId.RecoverModal.MovedComponentModal,
                        } as HTMLAttributes<HTMLButtonElement>),
                    },
                    onOk: () =>
                        this.commandInvoker.DEPRECATED_invokeBridge(RecoverComponentCommand, {
                            componentId,
                        }),
                })
            }
        } else {
            if (this.commandInvoker.DEPRECATED_invokeBridge(GetDocReadonlyCommand).value) {
                WKToast.show(translation('ComponentRemoved'))
            } else {
                if (fromFig && featureSwitchManager.isEnabled('asset-migration-mode')) {
                    this.componentService.showJumpToFigmaModal({
                        publishFile: '',
                        componentName: '',
                    })
                } else {
                    wkDialogConfirm.show({
                        title: translation('RestoreComponent'),
                        content: `${translation('ComponentRemoved_synonyms1')}`,
                        cancelText: translation('Cancel'),
                        okText: translation('Restore'),
                        okButtonProps: {
                            type: 'primary',
                            ...({
                                ['data-testid']: LibraryTestId.RecoverModal.RemovedComponentModal,
                            } as HTMLAttributes<HTMLButtonElement>),
                        },
                        onOk: () =>
                            this.commandInvoker.DEPRECATED_invokeBridge(RecoverComponentCommand, {
                                componentId: componentId,
                            }),
                    })
                }
            }
        }
    }

    private getMovedComponent = async (movedComponentKey: string): Promise<ComponentGetVO | null> => {
        const convert = (allRemoteLibraryContentMap: Map<LibraryId, LibraryContentVO>) => {
            for (const libraryContent of allRemoteLibraryContentMap.values()) {
                const movedVO = [
                    ...libraryContent.components,
                    ...libraryContent.componentSets,
                    ...Object.values(libraryContent.componentSetId2ChildrenComponents).flat(),
                ].find(({ id }) => id === movedComponentKey)
                if (movedVO) {
                    return movedVO
                }
            }
            return null
        }
        return new Promise<ComponentGetVO | null>((resolve) => {
            this.libraryNotifySyncService
                .getAllRemoteLibraryContentPromise((allRemoteLibraryContentMap) => {
                    const movedComponent = convert(allRemoteLibraryContentMap)
                    return !!movedComponent
                })
                .then((allRemoteLibraryContentMap) => {
                    resolve(convert(allRemoteLibraryContentMap))
                })
        })
    }

    private getShowRecoverComponentModalArgs = async (
        componentId: NodeId
    ): Promise<
        | {
              isMoved: false
          }
        | {
              isMoved: true
              docNameOfMovedTo: string
          }
    > => {
        const argShowRecoverDeletedComponentModal: { isMoved: false } = {
            isMoved: false,
        }

        const currentLibraryContent = await Promise.race([
            this.libraryNotifySyncService.getCurrentRemoteLibraryContentPromise(),
            new Promise<LibraryContentVO | null>((resolve) => {
                signalTimeout(
                    () => {
                        resolve(null)
                    },
                    3000,
                    {
                        signal: this.signal,
                    }
                )
            }),
        ])

        if (!currentLibraryContent) {
            return argShowRecoverDeletedComponentModal
        }

        const component =
            currentLibraryContent.components.find((c) => c.nodeId === componentId) ??
            Object.values(currentLibraryContent.componentSetId2ChildrenComponents)
                .flat()
                .find((c) => c.nodeId === componentId)

        if (!component) {
            return argShowRecoverDeletedComponentModal
        }

        const movedComponentKey = currentLibraryContent.libraryMovedInfos.componentMoveRemappings[component.id]
        if (!movedComponentKey) {
            return argShowRecoverDeletedComponentModal
        }

        const movedComponent = await Promise.race([
            this.getMovedComponent(movedComponentKey),
            new Promise<ComponentGetVO | null>((resolve) => {
                signalTimeout(
                    () => {
                        resolve(null)
                    },
                    1000,
                    {
                        signal: this.signal,
                    }
                )
            }),
        ])

        return {
            isMoved: true,
            docNameOfMovedTo:
                (movedComponent
                    ? currentLibraryContent.libraryMovedInfos.libraryId2LibraryInfo[movedComponent.libraryId]
                          .documentName
                    : undefined) || translation('UnknownLibrary'),
        }
    }

    private onFetchRemoteComponentSetDataForRecover = async (
        recoverInfo: Wukong.DocumentProto.IArg_FetchRemoteComponentSetDataForRecover
    ) => {
        const fetchData = await this.libraryNodeDataService.fetchRemoteExportedDocument({
            ossClientType: LibraryResourceOssClientType.Component,
            isLocal: false,
            localNodeId: null,
            remoteDocId: recoverInfo.comSetPublishFile,
            remoteNodeId: recoverInfo.comSetPublishId,
            nodeDataPath: recoverInfo.comSetNodeDataPath,
        })

        this.commandInvoker.DEPRECATED_invokeBridge(ExecuteRecoverToRemoteDefaultVariantCommand, {
            recoverInfo,
            componentSetExportedDocument: fetchData.exportedDocument,
        })
        this.commandInvoker.commitUndo()
    }
}
