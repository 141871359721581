import { useUserInfoContext } from '../../../../auth'

export const ChatWelcome = () => {
    const { userInfo } = useUserInfoContext()
    return (
        <div className="w-full box-border flex flex-col items-center justify-center pt-57px px-8px gap-8px">
            <div className="text-center wk-text-28 text-black wk-font-semibold">Hi, {userInfo.userBrief.nickname}</div>
            <div className="text-center wk-text-12 text-gray wk-font-regular">
                {`I'm Motiff AI. I can help you generate UI interfaces, edit UI interfaces, generate images, and assist you with specific tasks. What do you need help with today?`}
            </div>
        </div>
    )
}
