import { MotiffApi } from '@motiffcom/plugin-api-types'
/* eslint-disable no-restricted-imports */
import {
    AddExportSettingsToSelectionCommand,
    ExportImageToCanvas,
    ExportToHTML,
    ForceUpdateTextLayoutData,
    PrepareExportImageCommand,
    Wukong,
} from '@wukong/bridge-proto'
import JSZip from 'jszip'
import { useCallback, useEffect, useRef } from 'react'
import { WKToast } from '../../../../../../../ui-lib/src'
import { downloadBlob } from '../../../../../../../util/src'
import { CanvasBoundsExceedError, CanvasPutImageDataError } from '../../../../../document/bridge/canvas-render-bridge'
import { CommandInvoker } from '../../../../../document/command/command-invoker'
import { CommitType } from '../../../../../document/command/commit-type'
import { usePageSignal } from '../../../../../external-store/atoms/page-signal'
import { featureSwitchManager } from '../../../../../kernel/switch/core'
import { useColorProfileService, useCommand, useRawMemAccessService } from '../../../../../main/app-context'
import { FontInfoExt } from '../../../../../main/font/interface'
import { WK } from '../../../../../window'
import { useCanvasRenderBridge, useFontManagerService } from '../../../../context/document-context'
import { importHTML, importHTMLFromString, importSingleHTML, parseHTMLToProto } from '../../html-importer/import-html'
import { type ExportConfig, multiNodesOneExportSettings, nodeToBlob, singleNodeMultiExportConfig } from '../util'
import { useExportPreparation } from './use-export-preparation'
import { useExportSettings } from './use-export-settings'
import { translation } from './use-export.translation'
import ExportOperationTarget = Wukong.DocumentProto.ExportOperationTarget

interface Job {
    fileName: string
    blob: Blob | null
    config: ExportConfig
}

async function callDesktopDownload(jobs: (() => Promise<Job>)[]) {
    const deskTopExportFiles: {
        name: string
        blob: Blob
    }[] = []
    await Promise.all(
        jobs.map(async (job) => {
            const result = await job()
            if (result.blob) {
                deskTopExportFiles.push({
                    name: result.fileName,
                    blob: result.blob,
                })
            }
        })
    )
    window.localBridge?.exportFiles(deskTopExportFiles)
}

async function zipAndDownloadBlobs(packName: string, jobs: (() => Promise<Job>)[]) {
    const zip = new JSZip()
    await Promise.all(
        jobs.map(async (job) => {
            const result = await job()
            if (result.blob) {
                zip.file(result.fileName, result.blob, { base64: result.config.base64 ?? true })
            }
        })
    )
    const blob = await zip.generateAsync({ type: 'blob' })
    downloadBlob(blob, packName)
    return [blob, packName] as const
}

const simplifyOne = async (
    command: CommandInvoker,
    allFonts: FontInfoExt[],
    frame: MotiffApi.SceneNode,
    overlap = false
) => {
    const { data: rawHtml } = command.invokeBridge(CommitType.Noop, ExportToHTML, {
        nodeIds: [frame.id],
        enableFlex: true,
        withJpg: false,
        extraMetaInfoKeys: ['export-cli-version'],
        extraMetaInfoValues: ['1.0.0'],
        withVariants: false,
    })

    const resp = await fetch('https://motiff-ai-gen.yuanfudao.biz/api/simplify', {
        // const resp = await fetch('http://localhost:8800/api/simplify', {
        method: 'POST',
        body: JSON.stringify({
            html: rawHtml,
            debug: true,
        }),
    })

    const simplifiedHtml = await resp.text()
    const { rootId } = await importSingleHTML(simplifiedHtml, command, allFonts, {
        taskId: -1,
        needScreenshot: false,
        needProtoData: false,
        width: undefined,
        useFlex: true,
        useTextRealSize: true,
    })
    const reimportedNode = motiff.getNodeById(rootId!)! as MotiffApi.BaseFrameMixin
    reimportedNode.setPluginData('SIMPLIFY-ALL-GENERATED', 'TRUE')
    // Set position to match original frame
    reimportedNode.x = overlap ? frame.x : frame.x + frame.width + 32
    reimportedNode.y = frame.y
    if (overlap) {
        reimportedNode.blendMode = 'DIFFERENCE'
    }
}

function useExportHTML() {
    const command = useCommand()
    const fontManagerService = useFontManagerService()
    useEffect(() => {
        WK.exportDiff = async () => {
            const fonts = await fontManagerService.getAllFonts()
            const frames = motiff.currentPage.selection.filter(
                (child) => child.type === 'FRAME' && child.getPluginData('SIMPLIFY-ALL-GENERATED') !== 'TRUE'
            )

            frames.sort((a, b) => a.x - b.x)

            const concurrencyLimit = 2
            for (let i = 0; i < frames.length; i += concurrencyLimit) {
                const chunk = frames.slice(i, i + concurrencyLimit)
                await Promise.all(
                    chunk.map(async (frame) => {
                        await simplifyOne(command, fonts, frame, true)
                    })
                )
            }
            console.warn('exportDiff completed!')
        }
        WK.simplifyAll = async () => {
            const fonts = await fontManagerService.getAllFonts()
            const frames = motiff.currentPage.children.filter(
                (child) => child.type === 'FRAME' && child.getPluginData('SIMPLIFY-ALL-GENERATED') !== 'TRUE'
            )
            frames.sort((a, b) => a.y - b.y)

            const total = frames.length
            let completed = 0

            // Create a progress bar function
            const updateProgress = () => {
                completed++
                const percentage = Math.round((completed / total) * 100)
                const progressBar = '█'.repeat(percentage / 2) + '░'.repeat(50 - percentage / 2)
                console.warn(`Progress: ${progressBar} ${percentage}% (${completed}/${total})`)
            }

            const concurrencyLimit = 2
            for (let i = 0; i < frames.length; i += concurrencyLimit) {
                const chunk = frames.slice(i, i + concurrencyLimit)
                await Promise.all(
                    chunk.map(async (frame) => {
                        await simplifyOne(command, fonts, frame)
                        updateProgress()
                    })
                )
            }

            console.warn('simplifyAll completed!')
        }
        WK.showOriginalHTML = () => {
            const originalHtml = WK.currentSelection()[0].pluginData?.store?.HTML?.data?.originalHtml
            if (originalHtml) {
                const newWindow = window.open()
                if (newWindow) {
                    newWindow.document.write(originalHtml)
                    newWindow.document.close()
                }
            }
        }
        WK.downloadNodeHTML = async (enableFlex = true, nodes?: MotiffApi.FrameNode[]) => {
            command.invokeBridge(CommitType.Noop, PrepareExportImageCommand)
            if (nodes) {
                const zip = new JSZip()
                const metas: {
                    name: string
                    id: string
                }[] = []
                for (const node of nodes) {
                    const { data } = command.invokeBridge(CommitType.Noop, ExportToHTML, {
                        nodeIds: [node.id],
                        enableFlex,
                        withJpg: false,
                        extraMetaInfoKeys: ['export-cli-version'],
                        extraMetaInfoValues: ['1.0.0'],
                        withVariants: false,
                    })
                    metas.push({ name: node.name, id: node.id })
                    const blob = new Blob([data], { type: 'text/html' })
                    zip.file(node.name, blob)
                }
                const metaBlob = new Blob([JSON.stringify(metas)], { type: 'application/json' })
                zip.file('meta.json', metaBlob)
                const content = await zip.generateAsync({ type: 'blob' })
                downloadBlob(content, 'htmls.zip')
            } else {
                const { data } = command.invokeBridge(CommitType.Noop, ExportToHTML, {
                    nodeIds: motiff.currentPage.selection.map((node) => node.id),
                    enableFlex,
                    withJpg: false,
                    extraMetaInfoKeys: ['export-cli-version'],
                    extraMetaInfoValues: ['1.0.0'],
                    withVariants: false,
                })
                const blob = new Blob([data], { type: 'text/html' })
                downloadBlob(blob, 'index.html')
            }
        }
        WK.forceUpdateTextLayoutData = (nodeId) => {
            if (nodeId) {
                command.invokeBridge(CommitType.CommitUndo, ForceUpdateTextLayoutData, { value: nodeId })
            } else {
                for (const node of motiff.currentPage.selection) {
                    command.invokeBridge(CommitType.CommitUndo, ForceUpdateTextLayoutData, { value: node.id })
                }
            }
        }
        return () => {
            delete WK.exportDiff
            delete WK.importHTML
            delete WK.importHTMLFromString
            delete WK.parseHTMLToProto
            delete WK.downloadNodeHTML
            delete WK.simplifyAll
            delete WK.showOriginalHTML
            delete WK.forceUpdateTextLayoutData
        }
    }, [command, fontManagerService])
}

export function useExportByConfigs<T extends ExportConfig = ExportConfig>() {
    const command = useCommand()
    const canvasRenderBridge = useCanvasRenderBridge()
    const rawMemAcc = useRawMemAccessService()
    const service = useColorProfileService()
    const colorProfile = service.states.use.colorProfileState()
    const fontManagerService = useFontManagerService()

    const getNodeSVGText = useCallback(
        async (nodeId: string) => {
            const blob = await nodeToBlob(
                command,
                canvasRenderBridge,
                rawMemAcc,
                [nodeId],
                Wukong.DocumentProto.ExportFormatType.EXPORT_FORMAT_TYPE_SVG,
                {
                    type: Wukong.DocumentProto.ExportConstraintType.EXPORT_CONSTRAINT_TYPE_SCALE,
                    value: 1,
                },
                colorProfile,
                false,
                false // 与 figma 不同, 目前 SVG 导出文本时不支持包含 bounding box
            )
            if (blob) {
                return await blob.text()
            } else {
                throw Error('getNodeSVGText blob is null')
            }
        },
        [canvasRenderBridge, colorProfile, command, rawMemAcc]
    )

    useExportHTML()

    useEffect(() => {
        if (featureSwitchManager.isEnabled('import-html')) {
            WK.importHTML = async (
                ids: number[],
                useFlex: boolean,
                useTextRealSize: boolean,
                width: number,
                needScreenshot: boolean
            ) => {
                const allFonts = await fontManagerService.getAllFonts()
                return importHTML(command, allFonts, ids, needScreenshot, width, useFlex, useTextRealSize)
            }

            WK.importHTMLFromString = async (userHTMLs: string[]) => {
                const allFonts = await fontManagerService.getAllFonts()
                return importHTMLFromString(command, allFonts, userHTMLs)
            }

            WK.parseHTMLToProto = async (
                userHTML: string,
                width: number,
                useFlex: boolean,
                useTextRealSize: boolean
            ) => {
                const allFonts = await fontManagerService.getAllFonts()
                return parseHTMLToProto(command, allFonts, userHTML, width, useFlex, useTextRealSize)
            }
        }
        WK.uploadNodeAsHTML = async (enableFlex = false) => {
            const nodeIds = motiff.currentPage.selection.map((node) => node.id)
            command.DEPRECATED_invokeBridge(PrepareExportImageCommand)
            const images = await Promise.all(
                nodeIds.map(async (node) => {
                    const { id } = command.DEPRECATED_invokeBridge(ExportImageToCanvas, {
                        nodeIds: [node],
                        imageFormat: Wukong.DocumentProto.ImageFormat.IMAGE_FORMAT_PNG,
                        isCompress: false,
                        forceClip: true,
                        constraint: {
                            type: Wukong.DocumentProto.ExportConstraintType.EXPORT_CONSTRAINT_TYPE_SCALE,
                            value: 2,
                        },
                        colorProfile,
                    })
                    if (!id) {
                        return null
                    }

                    const image = await canvasRenderBridge.fetchImageBlob(id)
                    if (image === null) {
                        return null
                    }
                    return [node, image] as const
                })
            )
            const maybeJobs = images.filter((v) => v !== null) as unknown as [string, Blob][]
            const jobs = maybeJobs.map(async ([node, image]) => {
                const { data } = command.invokeBridge(CommitType.Noop, ExportToHTML, {
                    nodeIds: [node],
                    enableFlex,
                    withJpg: false,
                    extraMetaInfoKeys: [],
                    extraMetaInfoValues: [],
                    withVariants: false,
                })

                const urlRes = await fetch(
                    'https://wukong.yuanfudao.biz/wukong-api/api/admin/ai/ai-gen/upload-auth?format=png',
                    {
                        method: 'get',
                    }
                )
                const { url, contentType, resourceId } = await urlRes.json()
                const uploadRes = await fetch(url, {
                    method: 'put',
                    headers: {
                        'Content-Type': contentType,
                    },
                    body: image,
                })
                await uploadRes.text()
                const img_url = `https://motiff-dev.fbcontent.cn/private/resource/image/${resourceId}`
                return { html: data, node_id: node, img_url }
            })
            const data = await Promise.all(jobs)
            const url = new URL(window.location.href)
            const pathParts = url.pathname.split('/')
            const docId = pathParts[pathParts.length - 1].split('?')[0]
            await fetch('https://motiff-ai-gen.yuanfudao.biz/api/prototype/upload', {
                method: 'post',
                body: JSON.stringify({
                    docId: docId,
                    pageId: motiff.currentPage.id,
                    data: data,
                }),
            })
        }
    }, [canvasRenderBridge, command, colorProfile, fontManagerService])

    useEffect(() => {
        WK.getNodeSVGText = getNodeSVGText
        return () => {
            delete WK.getNodeSVGText
        }
    }, [getNodeSVGText])

    const exportByConfigs = useCallback(
        async (configs: T[]) => {
            const jobs = configs.map((config) => async () => {
                const { node, fileName, type, format, value, isCompress, useAbsoluteBounds } = config
                const blob = await nodeToBlob(
                    command,
                    canvasRenderBridge,
                    rawMemAcc,
                    node,
                    format,
                    { type, value },
                    colorProfile,
                    isCompress ?? false,
                    useAbsoluteBounds ?? false
                )
                return {
                    fileName,
                    blob,
                    config,
                }
            })
            try {
                if (jobs.length === 1) {
                    const res = await jobs[0]()
                    if (res.blob) {
                        downloadBlob(res.blob, res.fileName)
                        return [res.blob, res.fileName] as const
                    }
                } else {
                    if (window.localBridge) {
                        callDesktopDownload(jobs)
                        return null
                    }
                    return await zipAndDownloadBlobs(translation('Untitled'), jobs)
                }
            } catch (error) {
                if (error instanceof CanvasBoundsExceedError || error instanceof CanvasPutImageDataError) {
                    WKToast.error(translation('FailedTo'), {
                        duration: -1,
                        firstButton: { type: 'x' },
                    })
                } else if (error instanceof Error) {
                    throw error
                }
            }
            return null
        },
        [canvasRenderBridge, colorProfile, command, rawMemAcc]
    )
    return exportByConfigs
}

export function useExport() {
    const { selectLayer, nodeIds, names, exportSettings, exportTarget, pageName, pageId } = useExportSettings()
    const command = useCommand()
    const pageSignal = usePageSignal()
    const exportByConfigs = useExportByConfigs()
    const currentExported = useRef<readonly [Blob, string] | null>(null)
    const { prepare, done } = useExportPreparation()

    const addExportSetting = useCallback(() => {
        command.DEPRECATED_invokeBridge(AddExportSettingsToSelectionCommand)
        command.commitUndo()
    }, [command])

    const exportSelection = useCallback(async () => {
        const nameCount = new Map()
        const nonUndefExportSettings = exportSettings ?? []
        const configs = multiNodesOneExportSettings(
            nameCount,
            nodeIds.map((id) => [id]),
            names,
            nonUndefExportSettings
        )
        const id = await prepare(pageSignal)
        currentExported.current = await exportByConfigs(configs)
        if (id) {
            done(id)
        }
    }, [done, exportByConfigs, exportSettings, names, nodeIds, prepare, pageSignal])

    const exportWholePage = useCallback(async () => {
        if (!pageId) {
            return
        }
        const nameCount = new Map()
        const nonUndefExportSettings = exportSettings ?? []
        const configs = singleNodeMultiExportConfig(nameCount, [pageId], pageName ?? '', nonUndefExportSettings)
        const id = await prepare(pageSignal, [pageId])
        currentExported.current = await exportByConfigs(configs)
        if (id) {
            done(id)
        }
    }, [done, exportByConfigs, exportSettings, pageId, pageName, prepare, pageSignal])

    return {
        addExportSetting,
        exportSettings,
        nodeIds,
        names,
        selectLayer,
        exportSelection,
        exportWholePage,
        exportByConfigs,
        currentExported,
        exportPage: exportTarget === ExportOperationTarget.EXPORT_OPERATION_TARGET_WHOLE_PAGE,
    } as const
}
