import { translation } from './detail.translation'
/* eslint-disable no-restricted-imports */
import { SelectDisplayPanelWasmCall, Wukong } from '@wukong/bridge-proto'
import { isNil } from 'lodash-es'
import { useMemo } from 'react'
import { isNotNullOrUndefined } from '../../../../../util/src'
import { EditorMode, PopupStateType } from '../../../document/node/node'
import { featureSwitchManager } from '../../../kernel/switch'
import { useCommand, useHistoryService } from '../../../main/app-context'
import { useViewState } from '../../../view-state-bridge'
import { InlineTabs } from '../atom/inline-tabs/inline-tabs'
import { CommentPanel } from '../comment/comment-panel/comment-panel'
import { ChatBot } from '../design/chatbot-ai/chatbot'
import { Design } from '../design/design'
import { ExportPanel } from '../design/export/export-panel'
import { HistoryPanel } from '../history-file/history-panel/history-panel'
import { Inspect } from '../inspect/inspect'
import { Prototype } from '../prototype/prototype'
import style from './detail.module.less'
import { useDetail } from './use-detail'
import DisplayPanelType = Wukong.DocumentProto.DisplayPanelType

export interface DetailProps {
    className?: string
}

export function Detail(props: DetailProps) {
    const { className } = props
    const { onMouseDown, containerRef } = useDetail()

    const isScaleMode = useViewState('editorModeState')?.editorMode === EditorMode.EM_Scale

    const _displayPanels = useViewState('displayPanels', { displayPanels: [] }).displayPanels
    const displayPanels = useMemo(() => {
        return _displayPanels.filter((_) => {
            return true
        })
    }, [_displayPanels])

    const docReadonly = useViewState('docReadonly')

    const popUpState = useViewState('popupState')

    const historyService = useHistoryService()
    const isHistoryMode = historyService.useZustandStore.use.isHistoryMode()

    const isAIEnabled = featureSwitchManager.isEnabled('ai2')
    const displayPanelType2TitleAndComponent: Partial<
        Record<DisplayPanelType, { title: string; comp: JSX.Element; testId?: string; id?: string }>
    > = isAIEnabled
        ? {
              [DisplayPanelType.DISPLAY_PANEL_TYPE_DESIGN]: {
                  title: translation('Design'),
                  comp: <Design />,
              },
              [DisplayPanelType.DISPLAY_PANEL_TYPE_PROTOTYPE]: {
                  title: translation('Prototype'),
                  comp: <Prototype />,
                  id: 'wk-prototype-tab',
              },
              [DisplayPanelType.DISPLAY_PANEL_TYPE_INSPECT]: {
                  title: translation('Properties'),
                  comp: <Inspect />,
                  testId: 'inspect-text',
              },
              [DisplayPanelType.DISPLAY_PANEL_TYPE_COMMENT]: {
                  title: translation('Comments'),
                  comp: <CommentPanel />,
              },
              [DisplayPanelType.DISPLAY_PANEL_TYPE_EXPORT]: {
                  title: translation('Export'),
                  comp: <ExportPanel />,
              },
              [DisplayPanelType.DISPLAY_PANEL_TYPE_AI]: {
                  title: translation('AI'),
                  comp: <ChatBot />,
              },
          }
        : {
              [DisplayPanelType.DISPLAY_PANEL_TYPE_DESIGN]: {
                  title: translation('Design'),
                  comp: <Design />,
              },
              [DisplayPanelType.DISPLAY_PANEL_TYPE_PROTOTYPE]: {
                  title: translation('Prototype'),
                  comp: <Prototype />,
                  id: 'wk-prototype-tab',
              },
              [DisplayPanelType.DISPLAY_PANEL_TYPE_INSPECT]: {
                  title: translation('Properties'),
                  comp: <Inspect />,
                  testId: 'inspect-text',
              },
              [DisplayPanelType.DISPLAY_PANEL_TYPE_COMMENT]: {
                  title: translation('Comments'),
                  comp: <CommentPanel />,
              },
              [DisplayPanelType.DISPLAY_PANEL_TYPE_EXPORT]: {
                  title: translation('Export'),
                  comp: <ExportPanel />,
              },
          }

    const selectedPanelType = useMemo(() => displayPanels.find(({ isSelected }) => isSelected)?.type, [displayPanels])

    const command = useCommand()

    const handleDisplayPanelSelect = (type: DisplayPanelType) => {
        command.DEPRECATED_invokeBridge(
            SelectDisplayPanelWasmCall,
            Wukong.DocumentProto.Args_SelectDisplayPanelCommand.create({
                value: type,
            })
        )
    }
    const onlyCommentPanel = useMemo(() => {
        return !docReadonly && selectedPanelType === DisplayPanelType.DISPLAY_PANEL_TYPE_COMMENT
    }, [docReadonly, selectedPanelType])

    if (isHistoryMode) {
        return (
            <div
                ref={containerRef}
                className={`${style.container} ${className ?? ''}`}
                data-testid="history-panel-container"
            >
                <HistoryPanel />
            </div>
        )
    }

    if (isNil(selectedPanelType) || !displayPanelType2TitleAndComponent[selectedPanelType]) {
        return null
    }

    return (
        <div
            ref={containerRef}
            className={`${style.container} ${className ?? ''}`}
            onDragStart={(e) => {
                e.preventDefault() // 禁用可拖拽选中内容
            }}
        >
            {onlyCommentPanel ? (
                displayPanelType2TitleAndComponent[selectedPanelType]!.comp
            ) : (
                <>
                    {displayPanels.length > 1 && (
                        <InlineTabs
                            isHideSplitLine={
                                selectedPanelType == DisplayPanelType.DISPLAY_PANEL_TYPE_DESIGN && !isScaleMode
                            }
                            dataTestId={'right-panel-top-selector'}
                        >
                            {displayPanels.map(({ type, isSelected }) => {
                                return (
                                    <InlineTabs.Item
                                        id={displayPanelType2TitleAndComponent[type]!.id}
                                        key={type}
                                        isActive={isSelected}
                                        onClick={() => {
                                            handleDisplayPanelSelect(type)
                                        }}
                                        dataTestId={displayPanelType2TitleAndComponent[type]!.testId}
                                    >
                                        {displayPanelType2TitleAndComponent[type]!.title}
                                    </InlineTabs.Item>
                                )
                            })}
                        </InlineTabs>
                    )}

                    <div
                        data-testid="attribute-panel-close-popstate"
                        className={style.tabContent}
                        onMouseDown={(event) => {
                            const noFoldDom = document.getElementById('instance-no-fold')
                            if (noFoldDom?.contains(event.target as Node)) {
                                return
                            }

                            if (popUpState?.type === PopupStateType.POPUP_STATE_TYPE_BATCH_EXPORT) {
                                return
                            }

                            if (
                                selectedPanelType == DisplayPanelType.DISPLAY_PANEL_TYPE_DESIGN ||
                                selectedPanelType == DisplayPanelType.DISPLAY_PANEL_TYPE_PROTOTYPE
                            ) {
                                onMouseDown()
                            }
                        }}
                    >
                        {isNotNullOrUndefined(selectedPanelType) &&
                            (selectedPanelType === DisplayPanelType.DISPLAY_PANEL_TYPE_DESIGN ? (
                                <Design isSignlePanel={displayPanels.length === 1} />
                            ) : (
                                displayPanelType2TitleAndComponent[selectedPanelType]!.comp
                            ))}
                    </div>
                </>
            )}
        </div>
    )
}
