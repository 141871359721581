import {
    AIDesignLintDeDuplicateNodeIdsCommand,
    AIDesignLintPaintStyleCommand,
    AIDesignLintTextStyleCommand,
    GetPaintStyleNodeInfoByNodeIdCommand,
    GetTextStyleNodeInfoByNodeIdCommand,
    ImportNodeFromLibraryCommand,
    Wukong,
} from '@wukong/bridge-proto'
import classnames from 'classnames'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useEffectOnce, useUnmount } from 'react-use'
import { WKFrog } from '../../../../kernel/frog'
import { StyleGetVO } from '../../../../kernel/interface/component-style'
import { LibraryContentVO } from '../../../../kernel/interface/library'
import { GetLibraryContentMap } from '../../../../kernel/request/library'
import {
    useAIDesignLintService,
    useAppContext,
    useCommand,
    useLibraryComponentService,
} from '../../../../main/app-context'
import { LibraryResourceOssClientType } from '../../../../share/component-style-library/service/library-resource-downloader'
import classes from './ai-design-lint-modal.module.less'
import {
    AIComponentItem,
    AiDesignInspectionRuleEnum,
    CancelAIDesignLintTask,
    ComponentItemBasicInfo,
} from './ai-design-lint-request'
import { NetworkStatus, View } from './ai-design-lint-service'
import { ColorSingleCardInfo } from './common/color-card-list'
import { TextSingleCardInfo } from './common/text-card-list'
// eslint-disable-next-line wukong/restrict-translation-import
import { translation } from './translation/pending-page.translation'

function useProgressBar() {
    const minProgress = 0.05
    const [innerProgress, setInnerProgress] = useState(minProgress)

    const timerRef = useRef<number | null>(null)
    const isManuallyUpdated = useRef(false)

    const updateProgress = useCallback((num: number) => {
        isManuallyUpdated.current = true
        setInnerProgress(num)
    }, [])

    useEffect(() => {
        // 每秒更新一次，120秒内自动增长到90%
        const startTime = Date.now()
        // 120秒
        const duration = 120_000
        // 90%
        const maxProgress = 0.9
        const updateAutoProgress = () => {
            if (isManuallyUpdated.current) {
                return
            }

            const elapsed = Date.now() - startTime
            let progress = (elapsed / duration) * maxProgress

            if (progress < minProgress) {
                progress = minProgress
            }

            if (progress > maxProgress) {
                progress = maxProgress
            }

            setInnerProgress(progress)

            if (progress < maxProgress) {
                timerRef.current = window.setTimeout(updateAutoProgress, 1000)
            }
        }

        updateAutoProgress()

        return () => {
            if (timerRef.current !== null) {
                clearTimeout(timerRef.current)
            }
        }
    }, [])

    return { updateProgress, progress: innerProgress }
}

function useTaskStatusPoller(updateProgress: (num: number) => void) {
    const command = useCommand()

    const aiDesignLintService = useAIDesignLintService()
    const setCurrentView = aiDesignLintService.setCurrentView
    const aiService = useAppContext().aiService

    const libraryComponentService = useLibraryComponentService()

    const latestLibraryConfig = aiDesignLintService.states.use.latestLibraryConfigState()

    const latestRuleConfig = aiDesignLintService.states.use.latestRuleConfigState()

    const taskStatus = aiDesignLintService.states.use.finishedTaskStatusResponseState()

    // polling task status with interval 2s
    useEffectOnce(() => {
        aiDesignLintService.startPollingQueryLintTask()
        return () => {
            aiDesignLintService.stopPollingQueryLintTask()
        }
    })

    // 1. fetch library content
    // 2. create styleNode from library content
    // 3. invoke matching with textNodeIds and styleNodeIds

    const fetchLibraryContent = useCallback(async () => {
        const record = await new GetLibraryContentMap(
            latestLibraryConfig.value ?? [],
            aiDesignLintService.docID
        ).start()
        return Object.values(record)
    }, [aiDesignLintService, latestLibraryConfig])

    const importLibStyleAsLocalStyleNode = useCallback(
        async (libContentList: LibraryContentVO[], record: (localStyleId: string, libStyleId: string) => void) => {
            const createStyleNodesFromLibraryContent = async (_libraryContent: LibraryContentVO) => {
                const localStyleNodeIds = _libraryContent.styles.map(async (style) => {
                    const fetchData = await libraryComponentService.libraryNodeDataService.fetchRemoteExportedDocument({
                        isLocal: false,
                        localNodeId: null,
                        ossClientType: LibraryResourceOssClientType.Style,
                        remoteDocId: _libraryContent.library.document?.id,
                        remoteNodeId: style.nodeId,
                        nodeDataPath: style.nodeDataPath as string,
                    })

                    const { id: localStyleId } = command.DEPRECATED_invokeBridge(ImportNodeFromLibraryCommand, {
                        exportedDocument: fetchData.exportedDocument,
                        toCreateNodeId: fetchData.toCreateNodeId,
                        key: style.id,
                    })

                    record(localStyleId as string, style.id)

                    return localStyleId
                })

                return (await Promise.all(localStyleNodeIds)).filter((value) => value != null && value != undefined)
            }
            return (await Promise.all(libContentList.map(createStyleNodesFromLibraryContent))).flat()
        },
        [command, libraryComponentService.libraryNodeDataService]
    )

    useEffect(() => {
        if (taskStatus) {
            updateProgress(0.9)

            const localStyleNodeIdToLibStyleId = new Map<string, string>()
            const libStyleId2StyleGetVO = new Map<string, StyleGetVO>()

            const recordLocalStyleNodeId2LibStyleId = (localStyleNodeId: string, libStyleId: string) => {
                localStyleNodeIdToLibStyleId.set(localStyleNodeId, libStyleId)
            }

            fetchLibraryContent()
                .then((libContentList) => {
                    // record libStyleId => StyleGetVO
                    libContentList.forEach((libContent) => {
                        libContent.styles.forEach((style) => {
                            libStyleId2StyleGetVO.set(style.id, style)
                        })
                    })

                    importLibStyleAsLocalStyleNode(libContentList, recordLocalStyleNodeId2LibStyleId)
                        .then((localStyleNodeIds) => {
                            // color
                            {
                                const colorRes = command.DEPRECATED_invokeBridge(AIDesignLintPaintStyleCommand, {
                                    nodeIds: [...new Set(taskStatus.colorStyleInspectionNodeIds)],
                                    templatePaintStyleIds: localStyleNodeIds,
                                } as unknown as Wukong.DocumentProto.IArg_AIDesignLintPaintStyleCommand)

                                const similarColorStyleCardList = colorRes.similarMatchInfos.map(
                                    ({ matchedStyleNodeId, type, group }) => {
                                        const styleNodeId = matchedStyleNodeId
                                        const libStyleId = localStyleNodeIdToLibStyleId.get(styleNodeId as string)
                                        const style = libStyleId2StyleGetVO.get(libStyleId as string) as StyleGetVO
                                        return {
                                            styleNode: {
                                                ...command.DEPRECATED_invokeBridge(
                                                    GetPaintStyleNodeInfoByNodeIdCommand,
                                                    {
                                                        value: styleNodeId,
                                                    }
                                                ),
                                                styleGetVO: style,
                                            },
                                            nodePaintsInfoList: group,
                                            type: type,
                                        } as ColorSingleCardInfo
                                    }
                                )
                                const sameColorStyleCardList = colorRes.sameMatchInfos.map(
                                    ({ matchedStyleNodeId, type, group }) => {
                                        const styleNodeId = matchedStyleNodeId
                                        const libStyleId = localStyleNodeIdToLibStyleId.get(styleNodeId as string)
                                        const style = libStyleId2StyleGetVO.get(libStyleId as string) as StyleGetVO
                                        return {
                                            styleNode: {
                                                ...command.DEPRECATED_invokeBridge(
                                                    GetPaintStyleNodeInfoByNodeIdCommand,
                                                    {
                                                        value: styleNodeId,
                                                    }
                                                ),
                                                styleGetVO: style,
                                            },
                                            nodePaintsInfoList: group,
                                            type: type,
                                        } as ColorSingleCardInfo
                                    }
                                )

                                aiDesignLintService.updateColorCardInfoList(
                                    sameColorStyleCardList,
                                    similarColorStyleCardList
                                )
                            }

                            updateProgress(0.933)

                            // text
                            {
                                const textRes = command.DEPRECATED_invokeBridge(AIDesignLintTextStyleCommand, {
                                    textNodeIds: [...new Set(taskStatus.textStyleInspectionNodeIds)],
                                    templateTextStyleIds: localStyleNodeIds,
                                    ignoreLineHeight: !latestRuleConfig?.includes(
                                        AiDesignInspectionRuleEnum.RULE_STRICT_TEXT_HEIGHT
                                    ),
                                } as Wukong.DocumentProto.IArg_AIDesignLintTextStyleCommand)

                                const similarTextStyleCardList = textRes.similarMatchInfos.map(
                                    ({ group, matchedStyleNodeId }) => {
                                        const styleNodeId = matchedStyleNodeId
                                        const libStyleId = localStyleNodeIdToLibStyleId.get(styleNodeId as string)
                                        const style = libStyleId2StyleGetVO.get(libStyleId as string) as StyleGetVO
                                        return {
                                            styleNode: {
                                                ...command.DEPRECATED_invokeBridge(
                                                    GetTextStyleNodeInfoByNodeIdCommand,
                                                    {
                                                        value: styleNodeId,
                                                    }
                                                ),
                                                styleGetVO: style,
                                            },
                                            segmentList: group,
                                        } as TextSingleCardInfo
                                    }
                                )

                                const sameTextStyleCardList = textRes.sameMatchInfos.map(
                                    ({ group, matchedStyleNodeId }) => {
                                        const styleNodeId = matchedStyleNodeId
                                        const libStyleId = localStyleNodeIdToLibStyleId.get(styleNodeId as string)
                                        const style = libStyleId2StyleGetVO.get(libStyleId as string) as StyleGetVO
                                        return {
                                            styleNode: {
                                                ...command.DEPRECATED_invokeBridge(
                                                    GetTextStyleNodeInfoByNodeIdCommand,
                                                    {
                                                        value: styleNodeId,
                                                    }
                                                ),
                                                styleGetVO: style,
                                            },
                                            segmentList: group,
                                        } as TextSingleCardInfo
                                    }
                                )

                                aiDesignLintService.updateTextCardInfoList(
                                    sameTextStyleCardList,
                                    similarTextStyleCardList
                                )
                            }

                            updateProgress(0.966)

                            // component
                            {
                                taskStatus.componentItems = taskStatus.componentItems.map((componentItem) => {
                                    return {
                                        ...componentItem,

                                        component: {
                                            nodeIds: command.DEPRECATED_invokeBridge(
                                                AIDesignLintDeDuplicateNodeIdsCommand,
                                                {
                                                    nodeIds: componentItem.component.nodeIds,
                                                }
                                            ).nodeIds,
                                        } as ComponentItemBasicInfo,

                                        repeats: componentItem.repeats
                                            ? (componentItem.repeats.map(
                                                  (repeatComponentItem: ComponentItemBasicInfo) =>
                                                      ({
                                                          nodeIds: command.DEPRECATED_invokeBridge(
                                                              AIDesignLintDeDuplicateNodeIdsCommand,
                                                              {
                                                                  nodeIds: repeatComponentItem.nodeIds,
                                                              }
                                                          ).nodeIds,
                                                      } as ComponentItemBasicInfo)
                                              ) as ComponentItemBasicInfo[])
                                            : [],
                                    } as AIComponentItem
                                })

                                aiDesignLintService.updateComponentCardInfoList(taskStatus.componentItems)
                            }

                            updateProgress(1)

                            setCurrentView(View.Result)
                            aiService.decreaseAiDesignSystemCredits(true)

                            WKFrog.addFrogRecord({
                                url: '/event/AIConsistencyChecker/getResult',
                                eventId: 26798,
                                eventAction: 'event',
                                eventName: 'getResult',
                            })
                        })
                        .catch((_) => {
                            aiDesignLintService.updateNetworkStatus(NetworkStatus.Error)
                        })
                })
                .catch((_) => {
                    aiDesignLintService.updateNetworkStatus(NetworkStatus.Error)
                })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskStatus])
}

function useCancelPendingTask() {
    const aiDesignLintService = useAIDesignLintService()
    const shouldCancel = useRef(true)
    const taskStatus = aiDesignLintService.states.use.finishedTaskStatusResponseState()
    const taskId = aiDesignLintService.states.use.latestTaskIDState()

    useEffect(() => {
        if (taskStatus) {
            shouldCancel.current = !taskStatus.finished
        }
    }, [taskStatus])

    useUnmount(() => {
        if (shouldCancel.current && taskId) {
            new CancelAIDesignLintTask(taskId).start()
        }
    })
}

export function PendingContainer() {
    const { updateProgress, progress } = useProgressBar()

    useTaskStatusPoller(updateProgress)
    useCancelPendingTask()

    return (
        <div className={classnames(classes.pending_container, 'flex flex-col')}>
            <span className="color-$wk-l2-label-color-gray-13 wk-text-14 wk-font-regular pb-3">
                {translation('PendingTipAboveProgressBar')}
            </span>
            <div className={classes.prograss_bar}>
                <div
                    className={classes.inside}
                    style={{ width: progress * 100 + '%' }}
                    data-testid={'ai-design-lint-pending-page-progress-bar'}
                >
                    <div className={classes.flight}></div>
                </div>
            </div>
            <span className="color-$wk-l2-label-color-gray-8 wk-text-12 wk-font-regular pt-2">
                {translation('PendingTipUnderProgressBar')}
            </span>
        </div>
    )
}
