import { ExportHTMLForChatBot } from '@wukong/bridge-proto'
import constate from 'constate'
import { useCallback, useState } from 'react'
import { CommitType } from '../../../../document/command/commit-type'
import { useViewState } from '../../../../view-state-bridge'
import { useCommand } from '../../../context/document-context'
import { useAIGenUIInputs } from '../../ai/ai-gen-ui/use-ai-gen-inputs'

function useChatbotInput() {
    const images = useAIGenUIInputs({})
    const state = useViewState('aiChatSelectedNodeSystem')
    const command = useCommand()

    const getSelectionHTMLs = useCallback(() => {
        return command.invokeBridge(CommitType.Noop, ExportHTMLForChatBot)
    }, [command])

    const [styleConfig, setStyleConfig] = useState<number | null>(null)
    return {
        selectedNodes: state?.nodeInfos ?? [],
        ...images,
        getSelectionHTMLs,
        styleConfig,
        setStyleConfig,
    } as const
}

export const [ChatbotInputProvider, useChatbotAIInputInContext] = constate(useChatbotInput)
