import { Wukong } from '@wukong/bridge-proto'
import { buildTextDataFromSegments } from '@wukong/importers'
import { FontInfoExt, FontNameExt } from '../../../../../main/font/interface'
import { getJustifyContent, isFlex } from '../utils/flex-utils'
import { parseGradient } from '../utils/gradient'
import { getShadow, parentInfo, parseRGBA } from '../utils/utils'

export function getReverseText(window: Window, node: ChildNode, text: string) {
    // 看起来阿拉伯语有复杂的反转规则, 比如混合英文, 带空格时有不同变化, 先没有处理
    return text
    let currentNode = node
    if (currentNode.nodeType === Node.TEXT_NODE) {
        currentNode = currentNode.parentElement!
    }
    if (window.getComputedStyle(currentNode as Element).direction === 'rtl') {
        text = text.split(' ').reverse().join(' ')
    }
    return text
}

// FontWidth 枚举值映射
const FONT_STRETCH_TO_WIDTH: { [key: string]: Wukong.DocumentProto.FontWidth } = {
    // 百分比值映射
    '50%': Wukong.DocumentProto.FontWidth.ULTRA_CONDENSED_WIDTH,
    '62.5%': Wukong.DocumentProto.FontWidth.EXTRA_CONDENSED_WIDTH,
    '75%': Wukong.DocumentProto.FontWidth.CONDENSED_WIDTH,
    '87.5%': Wukong.DocumentProto.FontWidth.SEMI_CONDENSED_WIDTH,
    '100%': Wukong.DocumentProto.FontWidth.NORMAL_WIDTH,
    '112.5%': Wukong.DocumentProto.FontWidth.SEMI_EXPANDED_WIDTH,
    '125%': Wukong.DocumentProto.FontWidth.EXPANDED_WIDTH,
    '150%': Wukong.DocumentProto.FontWidth.EXTRA_EXPANDED_WIDTH,
    '200%': Wukong.DocumentProto.FontWidth.ULTRA_EXPANDED_WIDTH,
}

function getDefaultFontStyle(fontWeight: number) {
    switch (fontWeight) {
        case 100:
            return 'Thin'
        case 200:
            return 'Extra Light'
        case 300:
            return 'Light'
        case 400:
            return 'Regular'
        case 500:
            return 'Medium'
        case 600:
            return 'Semi Bold'
        case 700:
            return 'Bold'
        case 800:
            return 'Extra Bold'
        case 900:
            return 'Black'
        default:
            return 'Regular'
    }
}

function getLineHeight(lineHeight: string) {
    const value = lineHeight.replace('px', '')
    if (+value) {
        return {
            value: +value,
            unit: Wukong.DocumentProto.NumberUnit.NUMBER_UNIT_PIXELS,
        }
    }

    return {
        value: 100,
        unit: Wukong.DocumentProto.NumberUnit.NUMBER_UNIT_AUTO,
    }
}

function parseFontFamily(fontFamily: string): string[] {
    return fontFamily
        .split(',')
        .map((font) =>
            // 去除首尾空格并移除引号
            font.trim().replace(/^["']|["']$/g, '')
        )
        .filter(Boolean) // 移除空字符串
}

function getFallbackFont(fontWeight: number, useTextRealSize: boolean): Wukong.DocumentProto.IFontName {
    return {
        family: useTextRealSize ? 'Noto Sans' : 'Inter',
        style: getDefaultFontStyle(fontWeight),
    }
}

function findNearestStyleByWeight(fonts: FontNameExt[], fontWeight: number) {
    if (fonts.length === 0) {
        return null
    }
    fonts.sort((a, b) => a.weight - b.weight)

    for (let i = 0; i < fonts.length; i++) {
        const currentFont = fonts[i]
        if (currentFont.weight === fontWeight) {
            return {
                family: currentFont.family,
                style: currentFont.style,
            }
        } else if (currentFont.weight > fontWeight) {
            if (i > 0) {
                return {
                    family: fonts[i - 1].family,
                    style: fonts[i - 1].style,
                }
            } else {
                return {
                    family: currentFont.family,
                    style: currentFont.style,
                }
            }
        } else {
            if (i < fonts.length - 1) {
                continue
            } else {
                return {
                    family: currentFont.family,
                    style: currentFont.style,
                }
            }
        }
    }
}

function findNearestWidth(styles: FontNameExt[], width: Wukong.DocumentProto.FontWidth) {
    const nearestWidth = styles.reduce((prev, current) => {
        return Math.abs(current.width - width) < Math.abs(prev.width - width) ? current : prev
    })
    return nearestWidth.width
}

function findValidFontName(
    fontFamily: string,
    fontWeight: number,
    fontStyle: string,
    fontStretch: string,
    allFonts: FontInfoExt[]
): Wukong.DocumentProto.IFontName | null {
    const isItalic = fontStyle === 'italic' || fontStyle === 'oblique'
    const width = FONT_STRETCH_TO_WIDTH[fontStretch] ?? Wukong.DocumentProto.FontWidth.NORMAL_WIDTH

    const fontInfo = allFonts.find((font) => font.family === fontFamily)
    if (!fontInfo) {
        return null
    }

    if (fontFamily === 'MiSans') {
        if (fontWeight <= 250) {
            return {
                family: 'MiSans',
                style: 'Thin',
            }
        } else if (fontWeight <= 350) {
            return {
                family: 'MiSans',
                style: 'Normal',
            }
        } else if (fontWeight <= 450) {
            return {
                family: 'MiSans',
                style: 'Regular',
            }
        } else if (fontWeight <= 599) {
            return {
                family: 'MiSans',
                style: 'Medium',
            }
        } else {
            return {
                family: 'MiSans',
                style: 'Bold',
            }
        }
    }

    let fontStyles = fontInfo.styles.filter((style) => style.italic === isItalic)

    const nearestWidth = findNearestWidth(fontStyles, width)
    fontStyles = fontStyles.filter((style) => style.width === nearestWidth)

    const font = findNearestStyleByWeight(fontStyles, fontWeight)
    if (!font) {
        return null
    }

    return {
        family: font.family,
        style: font.style,
    }
}

export function getFontName(
    fontFamily: string,
    fontWeight: number,
    fontStyle: string,
    fontStretch: string,
    allFonts: FontInfoExt[],
    useTextRealSize: boolean
): Wukong.DocumentProto.IFontName {
    const families = parseFontFamily(fontFamily)
    for (const family of families) {
        const fontName = findValidFontName(family, fontWeight, fontStyle, fontStretch, allFonts)
        if (fontName) {
            return fontName
        }
    }
    return getFallbackFont(fontWeight, useTextRealSize)
}

export function parseTextAlign(textAlign: string) {
    switch (textAlign) {
        case 'left':
        case 'start':
            return Wukong.DocumentProto.TextAlignHorizontal.TEXT_ALIGN_HORIZONTAL_LEFT
        case 'right':
        case 'end':
            return Wukong.DocumentProto.TextAlignHorizontal.TEXT_ALIGN_HORIZONTAL_RIGHT
        case 'center':
            return Wukong.DocumentProto.TextAlignHorizontal.TEXT_ALIGN_HORIZONTAL_CENTER
        case 'justify':
            return Wukong.DocumentProto.TextAlignHorizontal.TEXT_ALIGN_HORIZONTAL_JUSTIFIED
        default:
            return Wukong.DocumentProto.TextAlignHorizontal.TEXT_ALIGN_HORIZONTAL_LEFT
    }
}

export function parseTextDecoration(textDecoration: string) {
    if (textDecoration.startsWith('underline')) {
        return Wukong.DocumentProto.TextDecoration.TEXT_DECORATION_UNDERLINE
    }
    if (textDecoration.startsWith('line-through')) {
        return Wukong.DocumentProto.TextDecoration.TEXT_DECORATION_STRIKETHROUGH
    }
    return Wukong.DocumentProto.TextDecoration.TEXT_DECORATION_NONE
}

export function getTextExtendWidth(width: number) {
    return width * 0.14 > 10 ? width * 0.14 : 10
}

function getHeightGap(computedStyle: CSSStyleDeclaration, height: number) {
    if (!computedStyle.lineHeight.endsWith('px')) {
        return 0
    }
    // 每行行高四舍五入: 92463
    const lineHeight = Math.round(+computedStyle.lineHeight.replace('px', ''))
    const reservedHeight = Math.ceil(height / lineHeight) * lineHeight
    return reservedHeight - height
}

export function makeTextNode(
    window: Window,
    parent: HTMLElement,
    textId: string,
    parentId: string,
    domRect: DOMRect,
    text: string,
    allFonts: FontInfoExt[],
    textSegments: Wukong.DocumentProto.IStyledTextSegment[] = [],
    pseudoName = '',
    useFlex = false,
    useTextRealSize = false
) {
    const computedStyle = window.getComputedStyle(parent, null)
    const currentColor = pseudoName ? window.getComputedStyle(parent, pseudoName).color : computedStyle.color
    if (computedStyle.textTransform === 'uppercase') {
        text = text.toUpperCase()
    }
    // const textId = id
    const textNode: Wukong.DocumentProto.ISynergyNode = {
        nodeId: textId,
    }
    let textAlign = computedStyle.textAlign
    // *2的话在90163下不对，所以这里取1.8
    const isMultipleLine = domRect.height >= +computedStyle.lineHeight.replace('px', '') * 1.8

    if (useFlex && isFlex(parent)) {
        if (!isMultipleLine) {
            // flex 布局下, 如果只有一行, 则按照 justifyContent 对齐, 91247里两种情况都有
            switch (getJustifyContent(computedStyle.justifyContent)) {
                case 'start':
                    textAlign = 'left'
                    break
                case 'end':
                    textAlign = 'right'
                    break
                case 'center':
                    textAlign = 'center'
                    break
                case 'space-between':
                case 'space-evenly':
                    textAlign = 'justify'
                    break
                default:
                    break
            }
        }
    }
    const extendWidth = useTextRealSize ? 1 : isMultipleLine ? 6 : getTextExtendWidth(domRect.width)
    // 只有多行时这样处理: 99278
    const extendHeight = useTextRealSize ? (isMultipleLine ? getHeightGap(computedStyle, domRect.height) : 0) : 12
    textNode.partialNode! = {
        type: Wukong.DocumentProto.NodeType.NODE_TYPE_TEXT,
        name: 'labels',
        id: textId,
        parentInfo: parentInfo.getParentInfo(parentId),
        parent: parentId,
        visible: true,
        locked: false,
        scrollBehavior: Wukong.DocumentProto.ScrollBehavior.SCROLL_BEHAVIOR_SCROLLS,
        width: domRect.width + extendWidth,
        height: domRect.height + extendHeight,
        constrainProportions: false,
        relativeTransform: {
            scaleX: 1,
            scaleY: 1,
            translateX:
                domRect.left -
                parent.getBoundingClientRect().left -
                (textAlign === 'right' ? extendWidth : textAlign === 'center' ? extendWidth / 2 : 0),
            translateY: domRect.top - parent.getBoundingClientRect().top - extendHeight / 2,
        },
        constraints: {},
        // 如果父节点是flex,且多行, 默认stackChildPrimaryGrow为1, case:91336
        // 如果父节点justifyContent为center, 则不能设置stackChildPrimaryGrow为1, case:91980
        stackChildPrimaryGrow: useFlex && isFlex(parent) && isMultipleLine ? 1 : 0,
        stackChildAlignSelf: Wukong.DocumentProto.StackCounterAlign.STACK_COUNTER_ALIGN_STRETCH,
        stackPositioning: Wukong.DocumentProto.StackPositioning.STACK_POSITIONING_AUTO,
        fills: [
            {
                type: Wukong.DocumentProto.PaintType.PAINT_TYPE_SOLID_PAINT,
                visible: true,
                opacity: parseRGBA(currentColor).a,
                blendMode: Wukong.DocumentProto.BlendMode.BLEND_MODE_NORMAL,
                color: {
                    r: parseRGBA(currentColor).r,
                    g: parseRGBA(currentColor).g,
                    b: parseRGBA(currentColor).b,
                },
            },
        ],

        fillStyleId: null,
        lastEditedStrokeCap: Wukong.DocumentProto.StrokeCap.STROKE_CAP_NONE,
        // strokeWeight: textBorderWidth,
        strokeJoin: Wukong.DocumentProto.StrokeJoin.STROKE_JOIN_MITER,
        strokeAlign: Wukong.DocumentProto.StrokeAlign.STROKE_ALIGN_CENTER,
        strokeMiterAngle: 4,
        dashCap: Wukong.DocumentProto.StrokeCap.STROKE_CAP_NONE,
        borderStrokeWeightsIndependent: false,
        startCap: Wukong.DocumentProto.StrokeCap.STROKE_CAP_NONE,
        endCap: Wukong.DocumentProto.StrokeCap.STROKE_CAP_NONE,
        cornerRadius: 0,
        cornerSmoothing: 0,
        topLeftRadius: 0,
        topRightRadius: 0,
        bottomLeftRadius: 0,
        bottomRightRadius: 0,
        independentCorners: false,
        opacity: 1,
        blendMode: Wukong.DocumentProto.BlendMode.BLEND_MODE_PASS_THROUGH,
        mask: false,
        maskOutline: false,
        pointCount: 0,
        innerRadius: 0,
        startingAngle: 0,
        endingAngle: 0,
        strokeGeometry: {
            vector: [
                {
                    id: 2,
                },
            ],
        },
        textAlignHorizontal: parseTextAlign(textAlign),
        textAlignVertical: Wukong.DocumentProto.TextAlignVertical.TEXT_ALIGN_VERTICAL_CENTER,
        textAutoResize: Wukong.DocumentProto.TextAutoResize.TEXT_AUTO_RESIZE_NONE,
        // textAutoResize: Wukong.DocumentProto.TextAutoResize.TEXT_AUTO_RESIZE_WIDTH_AND_HEIGHT,
        paragraphIndent: 0,
        paragraphSpacing: 0,
        autoRename: false,
        textData: buildTextDataFromSegments(textSegments, text),
        styleId: 0,
        textTruncation: Wukong.DocumentProto.TextTruncation.TEXT_TRUNCATION_ENDING,
        maxLines: 1,
        handleMirroring: Wukong.DocumentProto.VectorHandleMirror.VECTOR_HANDLE_MIRROR_NONE,
        vectorData: {},
        clipsContent: false,
        stackVerticalPadding: 0,
        stackPaddingBottom: 0,
        stackHorizontalPadding: 0,
        stackPaddingRight: 0,
        stackReverseZIndex: false,
        stackSpacing: 0,
        scrollDirection: Wukong.DocumentProto.ScrollDirection.SCROLL_DIRECTION_NONE,
        stackPrimaryAlignItems: Wukong.DocumentProto.StackJustify.STACK_JUSTIFY_STACK_JUSTIFY_MIN,
        stackCounterAlignItems: Wukong.DocumentProto.StackAlign.STACK_ALIGN_STACK_ALIGN_MIN,
        stackPrimarySizing: Wukong.DocumentProto.StackSize.STACK_SIZE_STACK_SIZE_RESIZE_TO_FIT_WITH_IMPLICIT_SIZE,
        stackCounterSizing: Wukong.DocumentProto.StackSize.STACK_SIZE_STACK_SIZE_FIXED,
        stackMode: Wukong.DocumentProto.StackMode.STACK_MODE_NONE,
        numberOfFixedChildren: 0,
        prototypeStartingPoint: null,
        stackWrap: Wukong.DocumentProto.StackWrap.STACK_WRAP_NO_WRAP,
        stackCounterSpacing: 0,
        stackCounterAlignContent: Wukong.DocumentProto.StackCounterAlignContent.STACK_COUNTER_ALIGN_CONTENT_AUTO,
        overlayPositionType: Wukong.DocumentProto.OverlayPositionType.OVERLAY_POSITION_TYPE_CENTER,
        overlayBackgroundInteraction:
            Wukong.DocumentProto.OverlayBackgroundInteraction.OVERLAY_BACKGROUND_INTERACTION_NONE,
        booleanOperation: Wukong.DocumentProto.BooleanOperation.BOOLEAN_OPERATION_UNION,
        description: '',
        sizeOverride: Wukong.DocumentProto.SizeOverride.SIZE_OVERRIDE_NONE,
        fromFig: false,
        fromSketch: false,
        fontSize: computedStyle.fontSize ? Number(computedStyle.fontSize.replace('px', '')) : 10,
        fontName: getFontName(
            computedStyle.fontFamily,
            +computedStyle.fontWeight,
            computedStyle.fontStyle,
            computedStyle.fontStretch,
            allFonts,
            useTextRealSize
        ),
        textDecoration: parseTextDecoration(computedStyle.textDecoration),
        textCase: Wukong.DocumentProto.TextCase.TEXT_CASE_ORIGINAL,
        lineHeight: getLineHeight(computedStyle.lineHeight),
        letterSpacing: {
            unit: Wukong.DocumentProto.NumberUnit.NUMBER_UNIT_PIXELS,
            value: +computedStyle.letterSpacing.replace('px', '') || 0,
        },
        hyperlink: {
            nodeId: '',
        },
        detachOpticalSizeFromFontSize: false,
        borderTopWeight: 0,
        borderRightWeight: 0,
        borderBottomWeight: 0,
        borderLeftWeight: 0,
        aiPoweredReason: Wukong.DocumentProto.AIPoweredReason.A_I_POWERED_REASON_NONE,
        aiCandidateComponentData: null,
        editInfo: {},
        propsAreBubbled: false,
        simplifyInstancePanels: false,
        variableResolvedType: Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_BOOLEAN,
    }
    if (computedStyle.backgroundClip === 'text') {
        if (
            computedStyle.backgroundImage.includes('linear-gradient') ||
            computedStyle.backgroundImage.includes('radial-gradient')
        ) {
            const gradient = parseGradient(computedStyle.backgroundImage, domRect.width)
            if (gradient.length) {
                textNode.partialNode!.fills = gradient
            }
        }
    }
    if (computedStyle.textShadow !== 'none') {
        const shadows = getShadow(computedStyle.textShadow)
        textNode.partialNode!.effects = shadows
            .map((shadow) => {
                return {
                    type: Wukong.DocumentProto.EffectType.EFFECT_TYPE_DROP_SHADOW,
                    visible: true,
                    opacity: parseRGBA(shadow.color).a,
                    color: {
                        r: parseRGBA(shadow.color).r,
                        g: parseRGBA(shadow.color).g,
                        b: parseRGBA(shadow.color).b,
                        a: parseRGBA(shadow.color).a * 255,
                    },
                    offset: {
                        x: +shadow.offsetX.replace('px', ''),
                        y: +shadow.offsetY.replace('px', ''),
                    },
                    radius: +shadow.blurRadius.replace('px', ''),
                    spread: +shadow.spreadRadius.replace('px', ''),
                }
            })
            .reverse()
    }
    return textNode
}
