import { Wukong } from '@wukong/bridge-proto'
import {
    MonoIconPanelCoordinateH16,
    MonoIconPanelCoordinateW16,
    MonoIconPanelOrientationLandscape16,
    MonoIconPanelOrientationPortrait16,
    Select,
    Tooltip,
    WKIconButton,
} from '../../../../../../ui-lib/src'
import classNames from 'classnames'
import { ScrubbableInputNumber } from '../../atom/inputs/scrubbable-input-number'
import { getBackwardIcon, isNotRotatable, PrototypeDeviceProvider, usePrototypeDevice } from './hook'
import { PrototypeDeviceThumbnail } from './prototype-device-thumbnail'
import { translation } from './prototype-device.translation'

const RotationButtons = () => {
    const { rotation, selectedItem, changeDeviceProp } = usePrototypeDevice()

    if (isNotRotatable(selectedItem.deviceName)) {
        return <></>
    }
    return (
        <div className="p-0.5 flex rounded-3px bg-$wk-l2-fill-color-gray-1">
            <Tooltip title={translation('Portrait')}>
                <WKIconButton
                    data-testid="prototype-device-rotation-none"
                    onClick={(e) => {
                        e.currentTarget.blur()
                        changeDeviceProp({
                            rotation: Wukong.DocumentProto.DeviceRotation.DEVICE_ROTATION_NONE,
                        })
                    }}
                    className={classNames(
                        'p-0.5',
                        rotation == Wukong.DocumentProto.DeviceRotation.DEVICE_ROTATION_NONE ? '!bg-white' : ''
                    )}
                    type={rotation == Wukong.DocumentProto.DeviceRotation.DEVICE_ROTATION_NONE ? 'border' : undefined}
                    icon={<MonoIconPanelOrientationPortrait16 />}
                />
            </Tooltip>
            <Tooltip title={translation('Landscape')}>
                <WKIconButton
                    data-testid="prototype-device-rotation-90"
                    onClick={(e) => {
                        e.currentTarget.blur()
                        changeDeviceProp({
                            rotation: Wukong.DocumentProto.DeviceRotation.CCW_90,
                        })
                    }}
                    className={classNames(
                        'p-0.5 !ml-0.5',
                        rotation == Wukong.DocumentProto.DeviceRotation.CCW_90 ? '!bg-white' : ''
                    )}
                    type={rotation == Wukong.DocumentProto.DeviceRotation.CCW_90 ? 'border' : undefined}
                    icon={<MonoIconPanelOrientationLandscape16 />}
                />
            </Tooltip>
        </div>
    )
}

const DeviceNameSelect = () => {
    // 选择设备 而不是 id
    const { changeDeviceByDeviceName, selectedItem, deviceOptionsUniqByDeviceName } = usePrototypeDevice()
    return (
        <Select.MinimalSingleLevel
            label={selectedItem.deviceName}
            value={selectedItem.deviceName}
            onChange={changeDeviceByDeviceName}
            dataTestIds={{
                triggerFocus: 'prototype-device-selector',
            }}
        >
            {deviceOptionsUniqByDeviceName.map((item) => {
                return (
                    <Select.MinimalSingleLevel.Option
                        backwardIcon={getBackwardIcon(item)}
                        key={item.deviceName}
                        value={item.deviceName}
                        splitLineBottom={item.splitLineBottom}
                    >
                        {item.deviceName}
                    </Select.MinimalSingleLevel.Option>
                )
            })}
        </Select.MinimalSingleLevel>
    )
}

const DeviceModelSelect = () => {
    // 这里才是选择 id
    const { selectedItem, changeDeviceByPresetId, deviceOptions } = usePrototypeDevice()
    const options = deviceOptions.filter((o) => o.deviceName == selectedItem.deviceName)
    if (options.length <= 1) {
        return <></>
    }
    return (
        <div className="py-2">
            <div className="wk-text-12 color-$wk-l2-label-color-gray-8 mb-1">{translation('Model')}</div>
            <Select.MinimalSingleLevel
                value={selectedItem.presetIdentifier}
                label={selectedItem.styleName}
                onChange={changeDeviceByPresetId}
            >
                {options.map((item) => {
                    return (
                        <Select.MinimalSingleLevel.Option key={item.presetIdentifier} value={item.presetIdentifier}>
                            {item.styleName}
                        </Select.MinimalSingleLevel.Option>
                    )
                })}
            </Select.MinimalSingleLevel>
        </div>
    )
}

const DeviceCustomSize = () => {
    const { selectedItem, frameSize, changeDeviceProp } = usePrototypeDevice()
    if (selectedItem.type == Wukong.DocumentProto.PrototypeDeviceType.PROTOTYPE_DEVICE_TYPE_CUSTOM) {
        return (
            <div className="py-1 flex h-7 gap-2">
                <ScrubbableInputNumber
                    icon={<MonoIconPanelCoordinateW16 />}
                    testId={'prototype-device-custom-size-x'}
                    value={frameSize?.x}
                    min={0}
                    className="w-88px"
                    onChange={(value) => {
                        if (typeof value === 'number') {
                            changeDeviceProp({
                                size: { x: value, y: frameSize?.y },
                            })
                        }
                    }}
                />
                <ScrubbableInputNumber
                    className="w-88px"
                    testId={'prototype-device-custom-size-y'}
                    icon={<MonoIconPanelCoordinateH16 />}
                    value={frameSize?.y}
                    min={0}
                    onChange={(value) => {
                        if (typeof value === 'number') {
                            changeDeviceProp({
                                size: { x: frameSize?.x, y: value },
                            })
                        }
                    }}
                />
            </div>
        )
    }
    return <></>
}

export const PrototypeDevice = () => {
    return (
        <PrototypeDeviceProvider>
            <div className="pt-2 pb-3 px-4 b-b border-color-$wk-l2-stroke-color-gray-3">
                <div className="wk-text-12 py-1.5 wk-font-semibold py-1.5">{translation('Device')}</div>
                <div className="py-1.5 h-6 flex justify-between items-center">
                    <DeviceNameSelect />
                    <RotationButtons />
                </div>
                <DeviceCustomSize />
                <DeviceModelSelect />
                <PrototypeDeviceThumbnail />
            </div>
        </PrototypeDeviceProvider>
    )
}
