import { ApplyAIGenCommand, Wukong } from '@wukong/bridge-proto'
import { isNil } from 'lodash-es'
import { useCallback, useRef, useState } from 'react'
import { domLocation, generateRouterPath, RouteToken, sleep } from '../../../../../../util/src'
import { CommitType } from '../../../../document/command/commit-type'
import { WkCLog } from '../../../../kernel/clog/wukong/instance'
import {
    AIGenAddTask,
    AIGenAllInOnePlayground,
    AIGenFeatures,
    AIGenGetTaskStatus,
} from '../../../../kernel/request/ai-gen'
import { featureSwitchManager } from '../../../../kernel/switch/core'
import { useAppContext } from '../../../../main/app-context'
import { LocalStorageKey } from '../../../../web-storage/local-storage/config'
import { enhancedLocalStorage } from '../../../../web-storage/local-storage/storage'
import { useCommand } from '../../../context/document-context'
import { AutoStylingBorderRadiusMap, Platform } from './constants'

export async function fetchAIGenResult(
    taskId: string,
    signal: AbortSignal,
    timeoutDuration?: number,
    duration = 2000
): Promise<Wukong.DocumentProto.AIGenResult> {
    let networkErrorTimes = 0
    const finalTimeout = timeoutDuration ?? 300000

    for (let i = 0; i < finalTimeout / duration; i += 1) {
        if (signal.aborted) {
            throw new Error('Operation aborted')
        }

        const statusReq = new AIGenGetTaskStatus(taskId)
        const onAbort = () => statusReq.cancel()
        signal.addEventListener('abort', onAbort)

        const timeout = sleep(duration)
        const res = await Promise.race([statusReq.start(), timeout]).catch(async (e) => {
            if (networkErrorTimes > 10) {
                throw e
            }
            networkErrorTimes += 1
            return { success: false, finished: false, result: null }
        })
        signal.removeEventListener('abort', onAbort)

        if (res === 'sleep') {
            statusReq.cancel()
        } else {
            if (res.finished) {
                if (res.success && res.result) {
                    const doc_res = await fetch(res.result, { signal })
                    const buf = await doc_res.arrayBuffer()
                    const arr_buf = new Uint8Array(buf)
                    return Wukong.DocumentProto.AIGenResult.decode(arr_buf)
                }
                throw new Error('ai gen failed')
            } else {
                await timeout
            }
        }
    }
    throw new Error('time limit exceed')
}

export function shouldJumpToAIPlayGround(): boolean {
    const storedItem = enhancedLocalStorage.getSerializedItem(LocalStorageKey.LandingPagePrompt)
    const storedOption = enhancedLocalStorage.getSerializedItem(LocalStorageKey.AIGenWebSiteConfig)
    return typeof storedItem === 'string' || storedOption !== null
}

export async function getAIPlayGroundDocId(): Promise<string | null> {
    try {
        const req = new AIGenAllInOnePlayground()
        const { docId } = await req.start()
        return docId
    } catch (e) {
        enhancedLocalStorage.removeItem(LocalStorageKey.LandingPagePrompt)
        return null
    }
}

export async function tryJump2AIPlayground(jumpType: 'sign up' | 'login' | 'reset-pass' | 'google'): Promise<boolean> {
    const shouldJump = shouldJumpToAIPlayGround()
    if (shouldJump) {
        const aiPlayGroundDocId = await getAIPlayGroundDocId().catch((e) => {
            WkCLog.log(`[AI GEN UI] Failed to get AI playground doc id: ${e}`)
            return null
        })
        if (aiPlayGroundDocId) {
            const aiPlayGroundUrl = `/${generateRouterPath(`${RouteToken.File}/${aiPlayGroundDocId}`)}`
            WkCLog.log('[AI GEN UI] User redirect use AI playground', { jumpType })
            domLocation().replace(aiPlayGroundUrl)
            return true
        }
        return false
    }
    WkCLog.log('[AI GEN UI] Sign up with normal flow')
    return false
}

export const useAIGenUIConfigId = () => {
    const [configs, setConfig] = useState<Array<number | undefined>>([])

    const changeConfigId = useCallback((e: React.FormEvent<HTMLTextAreaElement>, i: number) => {
        const value = e.currentTarget.value
        try {
            const id = Number.parseInt(value)
            setConfig((v) => {
                v[i] = id
                return v
            })
        } catch (err) {
            console.error(err)
        }
    }, [])

    const removeOneConfig = useCallback((i: number) => {
        setConfig((v) => v.filter((_, index) => index !== i))
    }, [])

    const appendConfig = useCallback(() => {
        setConfig(() => [...configs, undefined])
    }, [configs])

    return { changeConfigId, configs, appendConfig, removeOneConfig } as const
}

export const useAIGenUIUsedPrompts = () => {
    const usedPrompts = useRef<Set<string>>(new Set())

    const recordUsedPrompt = useCallback((prompt: string) => {
        usedPrompts.current.add(prompt)
    }, [])

    return { recordUsedPrompt, usedPrompts }
}

export const useAIGenUITextArea = () => {
    const ref = useRef<any | null>(null)
    const selectInput = useCallback(() => {
        if (ref.current) {
            ref.current.focus()
            ref.current.selectAll()
        }
    }, [])
    return { ref, selectInput } as const
}

export const useAIGenUICredits = () => {
    const ctx = useAppContext()
    const afterOneSuccessGen = useCallback(() => {
        ctx.aiService.decreaseAiLabCredits(true)
    }, [ctx.aiService])
    return { afterOneSuccessGen }
}

export interface AIGenStreamInput {
    docId: string
    prompt: string | undefined
    baseFrame?: string
    configId?: number
    version?: string
    promptImageUrl: string | null
    isBaseFrameCreated?: () => readonly string[]
    createBaseFrames?: (width: number, height: number) => Promise<void>
    ith?: number
    prefix?: string
    styleOption: { id: number; platform: Platform } | null
}

export const useAIGenUITasks = () => {
    const command = useCommand()
    const tasksAbortControllers = useRef<AbortController[]>([])
    const [finishedTaskCount, setFinishedTaskCount] = useState(0)
    const [taskQuantity, setTaskQuantity] = useState(0)

    const addTask = useCallback(async (input: AIGenStreamInput, signal: AbortSignal) => {
        // 有ConfigID就用, 没有且有Image时用403
        signal.throwIfAborted()

        const aiFeatures = new AIGenFeatures(input.docId, input.prompt, input.promptImageUrl, input.version)
        const abortFetchFeatures = () => {
            aiFeatures.cancel()
        }
        signal.addEventListener('abort', abortFetchFeatures)

        const features = await aiFeatures.start()
        const { default_config_id: defaultConfigId, web_or_app } = JSON.parse(features.result ?? '{}')
        const styleConfigID = input.styleOption?.id ?? undefined
        const webOrApp = input.styleOption?.platform ?? web_or_app
        const addTaskReq = new AIGenAddTask(
            input.docId,
            input.prompt,
            styleConfigID,
            input.configId ? input.configId : defaultConfigId,
            input.version,
            input.promptImageUrl ?? undefined,
            features.result
        )
        const onAbort = () => {
            addTaskReq.cancel()
        }
        signal.addEventListener('abort', onAbort)
        const removeAbortListener = () => {
            signal.removeEventListener('abort', onAbort)
        }
        return { taskRes: await addTaskReq.start(), webOrApp, removeAbortListener, styleConfigID } as const
    }, [])

    const fetchAIGenRes = useCallback(
        async (input: AIGenStreamInput, signal: AbortSignal) => {
            signal.throwIfAborted()
            const { taskRes, removeAbortListener, webOrApp } = await addTask(input, signal)
            if (taskRes) {
                if (signal.aborted) {
                    return null
                }
                if (input.isBaseFrameCreated?.().length === 0 && isNil(input.baseFrame)) {
                    if (webOrApp === 'web') {
                        await input.createBaseFrames?.(1440, 900)
                    } else if (webOrApp === 'app') {
                        await input.createBaseFrames?.(390, 844)
                    }
                }
                const doc = fetchAIGenResult(taskRes.taskId, signal)
                let baseFrame = input.baseFrame
                if (input.isBaseFrameCreated?.().length && isNil(input.baseFrame)) {
                    baseFrame = input.isBaseFrameCreated?.()[input.ith ?? 0]
                }
                removeAbortListener()
                return {
                    doc,
                    taskId: taskRes.taskId,
                    baseFrame,
                    prompt: input.prompt,
                    prefix: input.prefix,
                    configId: input.configId,
                } as const
            }
            removeAbortListener()
            return null
        },
        [addTask]
    )

    const applyAIGenRes = useCallback(
        async (input: Awaited<ReturnType<typeof fetchAIGenRes>>, signal: AbortSignal) => {
            signal.throwIfAborted()
            if (isNil(input)) {
                return
            }

            function getBaseFrameNode() {
                if (input?.baseFrame) {
                    const baseFrameNode = motiff.getNodeById(input.baseFrame)
                    if (baseFrameNode) {
                        return baseFrameNode
                    }
                }
                return null
            }

            if (signal.aborted) {
                return
            }

            const value = await input.doc
            if (value && !signal.aborted) {
                const baseNode = getBaseFrameNode()
                if (baseNode) {
                    const baseFrameNode = baseNode as ReturnType<typeof motiff.createFrame>
                    for (const child of baseFrameNode.children) {
                        child.remove()
                    }
                    baseFrameNode.fills = []
                    baseFrameNode.primaryAxisSizingMode = 'AUTO'
                    const pageNode = value.nodes.find(
                        (node) => node.partialNode?.type === Wukong.DocumentProto.NodeType.NODE_TYPE_PAGE
                    )
                    const pageNodeFirstChild = value.nodes.find(
                        (node) => node.partialNode?.parentInfo?.parentId === pageNode?.nodeId
                    )
                    const name = pageNodeFirstChild?.partialNode?.name
                    if (name && featureSwitchManager.isEnabled('ai-gen-change-base-frame-name')) {
                        baseNode.name = `${input.prefix ?? ''} ${name}`
                    }
                }
                if (!signal.aborted) {
                    command.invokeBridge(CommitType.Noop, ApplyAIGenCommand, {
                        part: value,
                        alreadyPlacedFrame: input.baseFrame,
                    })
                }
            }
        },
        [command]
    )

    const spawnATask = useCallback(
        async (input: AIGenStreamInput, inputAbortController: AbortController) => {
            tasksAbortControllers.current.push(inputAbortController)

            setTaskQuantity((v) => v + 1)

            const res = await fetchAIGenRes(input, inputAbortController.signal)
            try {
                await applyAIGenRes(res, inputAbortController.signal)
                setFinishedTaskCount((v) => v + 1)
                return res?.taskId
            } catch (e) {
                if (res?.taskId) {
                    WkCLog.log(`[AI_GEN_UI] AI GEN FAILED: ${e}`, { taskId: res?.taskId })
                }
                throw e
            }
        },
        [applyAIGenRes, fetchAIGenRes]
    )

    const cancelAllRunningTasks = useCallback(() => {
        for (const abc of tasksAbortControllers.current) {
            abc.abort()
        }
        tasksAbortControllers.current = []
        setFinishedTaskCount(0)
        setTaskQuantity(0)
    }, [])

    const clearMetrics = useCallback(() => {
        setFinishedTaskCount(0)
        setTaskQuantity(0)
    }, [])

    return {
        spawnATask,
        cancelAllRunningTasks,
        fetchAIGenRes,
        applyAIGenRes,
        taskQuantity,
        finishedTaskCount,
        clearMetrics,
    } as const
}

export interface ThemeProps {
    primaryColor?: string
    onPrimaryColor?: string
    backgroundColor?: string
    surfaceColor?: string
    textPrimaryColor?: string
    textSecondaryColor?: string
    dividerColor?: string
    mode?: 'light' | 'dark'
}

/**
 * Generates a complete color configuration based on a primary color and mode
 * @param primaryColor - The primary color in hex format (e.g. '#007AFF')
 * @param mode - The color mode, either 'light' or 'dark'
 * @returns A complete theme configuration object
 */
export function generateColorConfig(primaryColor: string, mode: 'light' | 'dark' = 'light'): ThemeProps {
    // Convert hex to RGB
    const hexToRgb = (hex: string): { r: number; g: number; b: number } => {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
        return result
            ? {
                  r: parseInt(result[1], 16),
                  g: parseInt(result[2], 16),
                  b: parseInt(result[3], 16),
              }
            : { r: 0, g: 0, b: 0 }
    }

    // Convert RGB to HSL
    const rgbToHsl = (r: number, g: number, b: number): { h: number; s: number; l: number } => {
        r /= 255
        g /= 255
        b /= 255

        const max = Math.max(r, g, b)
        const min = Math.min(r, g, b)
        let h = 0,
            s = 0
        const l = (max + min) / 2

        if (max !== min) {
            const d = max - min
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min)

            switch (max) {
                case r:
                    h = (g - b) / d + (g < b ? 6 : 0)
                    break
                case g:
                    h = (b - r) / d + 2
                    break
                case b:
                    h = (r - g) / d + 4
                    break
            }

            h /= 6
        }

        return { h, s, l }
    }

    // Convert HSL to RGB
    const hslToRgb = (h: number, s: number, l: number): { r: number; g: number; b: number } => {
        let r, g, b

        if (s === 0) {
            r = g = b = l
        } else {
            const hue2rgb = (p: number, q: number, t: number): number => {
                if (t < 0) t += 1
                if (t > 1) t -= 1
                if (t < 1 / 6) return p + (q - p) * 6 * t
                if (t < 1 / 2) return q
                if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6
                return p
            }

            const q = l < 0.5 ? l * (1 + s) : l + s - l * s
            const p = 2 * l - q

            r = hue2rgb(p, q, h + 1 / 3)
            g = hue2rgb(p, q, h)
            b = hue2rgb(p, q, h - 1 / 3)
        }

        return {
            r: Math.round(r * 255),
            g: Math.round(g * 255),
            b: Math.round(b * 255),
        }
    }

    // Convert RGB to hex
    const rgbToHex = (r: number, g: number, b: number): string => {
        return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`
    }

    // Calculate color brightness (0-255)
    const getBrightness = (hex: string): number => {
        const rgb = hexToRgb(hex)
        return (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000
    }

    // Generate colors based on primary
    const rgb = hexToRgb(primaryColor)
    const hsl = rgbToHsl(rgb.r, rgb.g, rgb.b)

    if (mode === 'light') {
        // Light mode (existing logic)
        // Generate background color (very light tint)
        const bgHsl = { ...hsl, s: Math.min(hsl.s, 0.1), l: 0.97 }
        //const bgRgb = hslToRgb(bgHsl.h, bgHsl.s, bgHsl.l)
        //const backgroundColor = rgbToHex(bgRgb.r, bgRgb.g, bgRgb.b)

        // Generate divider color (slightly more saturated background)
        const dividerHsl = { ...bgHsl, s: Math.min(hsl.s, 0.15), l: 0.93 }
        const dividerRgb = hslToRgb(dividerHsl.h, dividerHsl.s, dividerHsl.l)
        const dividerColor = rgbToHex(dividerRgb.r, dividerRgb.g, dividerRgb.b)

        // Determine text color on primary based on brightness
        const brightness = getBrightness(primaryColor)
        const onPrimaryColor = brightness > 180 ? '#1A1A1A' : 'white'

        // Return the complete light mode color configuration
        return {
            primaryColor,
            onPrimaryColor,
            backgroundColor: 'white',
            surfaceColor: 'white',
            textPrimaryColor: '#1A1A1A',
            textSecondaryColor: '#8E8E93',
            dividerColor,
            mode: 'light',
        }
    } else {
        // Dark mode configuration
        // Generate background color (very dark)
        const bgHsl = { ...hsl, s: Math.min(hsl.s, 0.15), l: 0.1 }
        const bgRgb = hslToRgb(bgHsl.h, bgHsl.s, bgHsl.l)
        const backgroundColor = rgbToHex(bgRgb.r, bgRgb.g, bgRgb.b)

        // Generate surface color (slightly lighter than background)
        const surfaceHsl = { ...bgHsl, l: 0.15 }
        const surfaceRgb = hslToRgb(surfaceHsl.h, surfaceHsl.s, surfaceHsl.l)
        const surfaceColor = rgbToHex(surfaceRgb.r, surfaceRgb.g, surfaceRgb.b)

        // Generate divider color (subtle divider for dark mode)
        const dividerHsl = { ...bgHsl, l: 0.2 }
        const dividerRgb = hslToRgb(dividerHsl.h, dividerHsl.s, dividerHsl.l)
        const dividerColor = rgbToHex(dividerRgb.r, dividerRgb.g, dividerRgb.b)

        // Text colors for dark mode
        const textPrimaryColor = '#FFFFFF'
        const textSecondaryColor = '#AEAEB2'

        // On primary color (always ensure good contrast)
        const brightness = getBrightness(primaryColor)
        const onPrimaryColor = brightness > 180 ? '#1A1A1A' : 'white'

        // Return the complete dark mode color configuration
        return {
            primaryColor,
            onPrimaryColor,
            backgroundColor,
            surfaceColor,
            textPrimaryColor,
            textSecondaryColor,
            dividerColor,
            mode: 'dark',
        }
    }
}

export function generateCornerSize(cornerSize: string) {
    const size = AutoStylingBorderRadiusMap[cornerSize as keyof typeof AutoStylingBorderRadiusMap]
    const number = Number.parseInt(size.replace('px', ''))
    if (Number.isNaN(number)) {
        return {
            xl: 0,
            lg: 0,
            md: 0,
            sm: 0,
            xs: 0,
        }
    }
    return {
        full: number * 2,
        '2xl': number * 1.8,
        xl: number * 1.6,
        lg: number * 1.3,
        md: number * 1,
        sm: number * 0.8,
        xs: number * 0.6,
    }
}
