import { useParams } from 'react-router'

import styles from './team.module.less'
import classNames from 'classnames'
import { BlockUnit } from '../../../../../space/app/loading-container/layout/block-unit'
import { useListShowType } from '../../../../../space/util/view-show-type'
import { RouteToken } from '../../../../../../../util/src/routes'
import { ShowType } from '../../../../../space/util/types'
import { usePageType } from '../../../../../space/app/loading-container/use-page-type'

const FolderItemBlock = () => {
    return (
        <div className="rounded-3px border border-color-$wk-l2-stroke-color-gray-2 p-5 box-border">
            <div className="grid gap-3 grid-cols-2 mb-5">
                <BlockUnit className="pt-60%" />
                <BlockUnit className="pt-60%" />
                <BlockUnit className="pt-60%" />
                <BlockUnit className="pt-60%" />
            </div>
            <BlockUnit className="h-4 w-36% mb-11px" />
            <BlockUnit className="h-14px w-65% mb-2px" />
        </div>
    )
}

export const TeamPageSkeleton = () => {
    const teamId = useParams().teamId
    const { showType } = useListShowType(`${RouteToken.Team}-${teamId}`, ShowType.Grid)
    const { isTeam, isTeamMembers, isTeamFonts, isTeamSetting, isTeamsettlement } = usePageType()

    return (
        <div className="px-8 pt-13px">
            <BlockUnit className="w-140px h-4 mb-13px" />
            <div className="h-12 flex items-center justify-between mb-33px">
                <div className="flex items-center">
                    <BlockUnit className="h-12 w-12 mr-4" />
                    <BlockUnit className="w-140px h-34px" />
                </div>
                <div className="flex items-center">
                    <BlockUnit className="w-23 h-8 mr-2" />
                    <BlockUnit className="w-23 h-8 mr-2" />
                    <BlockUnit className="w-21 h-8 mr-2" />
                    <BlockUnit className="w-8 h-8" />
                </div>
            </div>
            <div className="flex items-center mb-11px">
                <BlockUnit className="w-9 h-6 mr-40px"></BlockUnit>
                <BlockUnit className="w-9 h-6 mr-40px"></BlockUnit>
                <BlockUnit className="w-9 h-6 mr-40px"></BlockUnit>
            </div>
            <BlockUnit className="h-1px"></BlockUnit>
            {showType == ShowType.Grid &&
                isTeam &&
                !isTeamFonts &&
                !isTeamMembers &&
                !isTeamSetting &&
                !isTeamsettlement && (
                    <div className="flex">
                        <div className="flex-1">
                            <BlockUnit className="h-4 w-16 my-23px"></BlockUnit>
                            <div className={classNames(styles.folders_grid, '856:mr-8')}>
                                <FolderItemBlock />
                                <FolderItemBlock />
                                <FolderItemBlock />
                                <FolderItemBlock />
                                <FolderItemBlock />
                                <FolderItemBlock />
                                <FolderItemBlock />
                                <FolderItemBlock />
                                <FolderItemBlock />
                                <FolderItemBlock />
                                <FolderItemBlock />
                                <FolderItemBlock />
                            </div>
                        </div>
                        <div className="hidden 856:block px-6 w-248px box-border">
                            <BlockUnit className="mt-5 w-16 h-4" />
                            <div className="mt-5.5 flex items-center">
                                <BlockUnit className="!rounded-full w-6 h-6 mr-2" />
                                <BlockUnit className="w-78px h-3.5" />
                            </div>
                            <div className="mt-4 flex items-center">
                                <BlockUnit className="!rounded-full w-6 h-6 mr-2" />
                                <BlockUnit className="w-40px h-3.5" />
                            </div>
                            <div className="mt-4 flex items-center">
                                <BlockUnit className="!rounded-full w-6 h-6 mr-2" />
                                <BlockUnit className="w-78px h-3.5" />
                            </div>
                            <div className="mt-4 flex items-center">
                                <BlockUnit className="!rounded-full w-6 h-6 mr-2" />
                                <BlockUnit className="w-40px h-3.5" />
                            </div>
                            <div className="mt-4 flex items-center">
                                <BlockUnit className="!rounded-full w-6 h-6 mr-2" />
                                <BlockUnit className="w-78px h-3.5" />
                            </div>
                            <div className="mt-4 flex items-center">
                                <BlockUnit className="!rounded-full w-6 h-6 mr-2 shrink-0" />
                                <BlockUnit className="w-full h-3.5" />
                            </div>
                        </div>
                    </div>
                )}
        </div>
    )
}
