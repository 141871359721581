import { GetDeviceOptionsCommand, Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { WKTextButton, WKToast } from '../../../../../../ui-lib/src'
import { execCommandCopy } from '../../../../../../util/src'
import { useViewState } from '../../../../view-state-bridge'
import { useCommand } from '../../../context/document-context'
import { toFixed } from '../../../utils/to-fixed'
import { getAnimationEasingTypeOption } from '../prototype-interaction-popup/interaction-action-animation/animation-easing-type/animation-easing-type'
import { getTransitionTypeLabel } from '../prototype-interaction-popup/interaction-action-animation/animation-transition-type/animation-transition-type'
import {
    InteractionActionType,
    InteractionActionType2Label,
    InteractionType2DestopShortLabel,
    InteractionType2MobileShortLabel,
} from '../prototype-interaction/constants'
import { getSingleActionType } from '../prototype-interaction/utils'
import { useInteractionDevContext } from './hook'
import { translation } from './prototype-interaction-dev.translation'
import styles from './style.module.less'

const CopyableValue: FC<{ value: string }> = ({ value }) => {
    const onClick = () => {
        execCommandCopy(value)
        WKToast.show(translation('CopiedToClipboard'))
    }
    return (
        <div onClick={onClick} className={classNames('truncate wk-text-12', styles['copy-hover'])}>
            {value}
        </div>
    )
}

const LineTitle: FC<{ title: string }> = ({ title }) => {
    return <div className="w-80px color-$wk-l2-label-color-gray-8 shrink-0">{title}</div>
}

const ActionItem: FC<{ action: Wukong.DocumentProto.IPrototypeAction }> = ({ action }) => {
    const targetNodes = useViewState('selectionPrototypeInteraction')?.targetNodes

    const { move, showToast } = useInteractionDevContext()

    const actionName = useMemo(() => {
        // 有目标图层时直接展示目标图层名称（可点击）
        // 如果是 Open link，未设置目标网址时，展示为 none（无），设置了展示网址即可（纯文本不可点击）
        // 无目标图层时按 「Action 动作名称」展示，其中动作名称为绿色，点击可复制动作名称
        // 使用的是不可设置目标图层的动作时（back、Close overlay），按照「Action 动作名称」格式展示，动作名称不可点击
        const transitionNodeID = action.transitionNodeID
        const actionType = getSingleActionType(action)
        if (transitionNodeID || actionType == InteractionActionType.OpenLink) {
            return InteractionActionType2Label[getSingleActionType(action)]
        } else {
            return translation('Action')
        }
    }, [action])

    const actionValue = useMemo(() => {
        const transitionNodeID = action.transitionNodeID
        const actionType = getSingleActionType(action)
        if (transitionNodeID) {
            return (
                <WKTextButton
                    onClick={() => {
                        move(transitionNodeID)
                        showToast()
                    }}
                    className="truncate"
                    size={12}
                    type="primary"
                >
                    <span className="truncate">{targetNodes?.find((o) => o.id == transitionNodeID)?.name}</span>
                </WKTextButton>
            )
        } else if (actionType == InteractionActionType.OpenLink) {
            return <CopyableValue value={action.connectionURL || translation('None')} />
        } else {
            return <CopyableValue value={InteractionActionType2Label[getSingleActionType(action)]} />
        }
    }, [action, move, showToast, targetNodes])

    return (
        <div className="flex gap-4">
            <LineTitle title={actionName} />
            {actionValue}
        </div>
    )
}

const ActionSpringProperty: FC<{ action: Wukong.DocumentProto.IPrototypeAction }> = ({ action }) => {
    if (action.easingFunction && action.easingFunction.length !== 4) {
        return null
    }

    const [mass, stiffness, damping, _] = action.easingFunction

    return (
        <>
            <div className="flex gap-4">
                <LineTitle title={translation('Curve')} />
                <CopyableValue value={translation('Spring')} />
            </div>
            <div className="flex gap-4">
                <LineTitle title={translation('Mass')} />
                <CopyableValue value={`${toFixed(mass, 2)}`} />
            </div>
            <div className="flex gap-4">
                <LineTitle title={translation('Stiffness')} />
                <CopyableValue value={`${toFixed(stiffness, 2)}`} />
            </div>
            <div className="flex gap-4">
                <LineTitle title={translation('Damping')} />
                <CopyableValue value={`${toFixed(damping, 2)}`} />
            </div>
        </>
    )
}

const ActionEasingProperty: FC<{ action: Wukong.DocumentProto.IPrototypeAction }> = ({ action }) => {
    const curveValue =
        action.easingType == Wukong.DocumentProto.EasingType.EASING_TYPE_CUSTOM_CUBIC
            ? action.easingFunction?.map((n) => Number(n.toFixed(2))).join(', ')
            : getAnimationEasingTypeOption(action.easingType!).label

    return (
        <>
            {curveValue && (
                <div className="flex gap-4">
                    <LineTitle title={translation('Curve')} />
                    <CopyableValue value={curveValue} />
                </div>
            )}
            {!!action.transitionDuration && (
                <div className="flex gap-4">
                    <LineTitle title={translation('Duration')} />
                    <CopyableValue value={`${action.transitionDuration}ms`} />
                </div>
            )}
        </>
    )
}
const Action: FC<{ action: Wukong.DocumentProto.IPrototypeAction; timeout: number }> = ({ action, timeout }) => {
    const easingItem = useMemo(() => {
        if (action.transitionType === Wukong.DocumentProto.TransitionType.TRANSITION_TYPE_INSTANT_TRANSITION) {
            return null
        }

        switch (action.easingType) {
            case Wukong.DocumentProto.EasingType.EASING_TYPE_OUT_CUBIC:
            case Wukong.DocumentProto.EasingType.EASING_TYPE_IN_CUBIC:
            case Wukong.DocumentProto.EasingType.EASING_TYPE_INOUT_CUBIC:
            case Wukong.DocumentProto.EasingType.EASING_TYPE_LINEAR:
            case Wukong.DocumentProto.EasingType.EASING_TYPE_IN_BACK_CUBIC:
            case Wukong.DocumentProto.EasingType.EASING_TYPE_OUT_BACK_CUBIC:
            case Wukong.DocumentProto.EasingType.EASING_TYPE_INOUT_BACK_CUBIC:
            case Wukong.DocumentProto.EasingType.EASING_TYPE_CUSTOM_CUBIC:
                return <ActionEasingProperty action={action} />
            case Wukong.DocumentProto.EasingType.EASING_TYPE_GENTLE_SPRING:
            case Wukong.DocumentProto.EasingType.EASING_TYPE_CUSTOM_SPRING:
            case Wukong.DocumentProto.EasingType.EASING_TYPE_SPRING_PRESET_ONE:
            case Wukong.DocumentProto.EasingType.EASING_TYPE_SPRING_PRESET_TWO:
            case Wukong.DocumentProto.EasingType.EASING_TYPE_SPRING_PRESET_THREE:
                return <ActionSpringProperty action={action} />
            case Wukong.DocumentProto.EasingType.EASING_TYPE_SPRING:
                return null
        }
    }, [action])

    return (
        <>
            {!!timeout && (
                <div className="flex gap-4">
                    <LineTitle title={translation('Delay')} />
                    <CopyableValue value={`${timeout}ms`} />
                </div>
            )}
            <ActionItem action={action} />
            <div className="flex gap-4">
                <LineTitle title={translation('Animate')} />
                <CopyableValue value={getTransitionTypeLabel(action.transitionType!)} />
            </div>
            {easingItem}
        </>
    )
}

const getTimeout = (interaction: Wukong.DocumentProto.IPrototypeInteractionWithNodeId) => {
    // 如果 type = ‘延时’, 那么延时必有值，如果为 0 责返回默认的 0.8 event.transitionTimeout
    // 其他情况，用 event.interactionDuration 返回多少就是多少
    if (interaction.event.interactionType == Wukong.DocumentProto.InteractionType.INTERACTION_TYPE_AFTER_TIMEOUT) {
        return interaction.event.transitionTimeout || 0.8
    } else {
        return interaction.event.interactionDuration || 0
    }
}

const PrototypeInteractionDevItem: FC<{ interaction: Wukong.DocumentProto.IPrototypeInteractionWithNodeId }> = ({
    interaction,
}) => {
    const interactionType = interaction.event.interactionType!
    const timeout = Math.round(getTimeout(interaction) * 1000)
    const actions = interaction.actions

    const deviceState = useViewState('prototypeDevice')
    const command = useCommand()
    const getDeviceOptions = useCallback(() => {
        return command.DEPRECATED_invokeBridge(GetDeviceOptionsCommand).options
    }, [command])
    const isMobile = useMemo(() => {
        return (
            getDeviceOptions().find((d) => d.presetIdentifier == deviceState?.config.presetIdentifier)
                ?.isMobileDevice ?? false
        )
    }, [deviceState?.config.presetIdentifier, getDeviceOptions])
    // actions 暂时只有一个
    return (
        <div className="flex w-text-12 flex-col gap-2">
            <div className="color-$wk-l2-label-color-gray-13 wk-font-medium shrink-0">
                {isMobile
                    ? InteractionType2MobileShortLabel[interactionType]
                    : InteractionType2DestopShortLabel[interactionType]}
            </div>
            {actions.map((action, index) => {
                return <Action key={index} action={action} timeout={timeout} />
            })}
        </div>
    )
}

export const PrototypeInteractionDev: FC<{ borderTop: boolean }> = ({ borderTop }) => {
    const interactions = useViewState('selectionPrototypeInteraction')?.interactions
    // 首先过滤掉 interactionType 为 none 的交互  然后再看里面的 actions，如果第一条 action 是 none，也过滤掉
    const validatedInteractions =
        interactions
            ?.filter(
                (interaction) =>
                    interaction.event.interactionType != Wukong.DocumentProto.InteractionType.INTERACTION_TYPE_NONE
            )
            .filter((interaction) => getSingleActionType(interaction.actions[0]) != InteractionActionType.None) ?? []
    const MAX_SHOW_LENGTH = 3
    const [showLength, setShowLength] = useState(Math.min(MAX_SHOW_LENGTH, validatedInteractions.length))

    // 选取改变重置展示更多状态
    useEffect(() => {
        setShowLength(Math.min(MAX_SHOW_LENGTH, validatedInteractions.length))
    }, [interactions, validatedInteractions.length])

    if (!validatedInteractions?.length) return <></>
    return (
        <div
            data-testid="prototype-interaction-dev"
            className={classNames('pt-2 pb-4 b-b b-color-$wk-gray-3', borderTop && 'b-t')}
        >
            <div className="h-8 px-4 flex items-center justify-between mb-2">
                <span className="wk-font-semibold">{translation('Interactions')}</span>
            </div>
            <div className="flex flex-col gap-4 px-4">
                {validatedInteractions.slice(0, showLength).map((interaction) => {
                    return <PrototypeInteractionDevItem interaction={interaction} key={interaction.id} />
                })}
            </div>
            {validatedInteractions.length > showLength && (
                <div className="px-4 mt-3">
                    <WKTextButton onClick={() => setShowLength(validatedInteractions.length)} type="primary" size={12}>
                        {translation('Show', { count: validatedInteractions.length - showLength })}
                    </WKTextButton>
                </div>
            )}
        </div>
    )
}
