import { ApplyChatBotAIGenCommand, Wukong } from '@wukong/bridge-proto'
import { createSelectors, createStore } from '../../../../../../util/src'
import { anySignal } from '../../../../../../util/src/abort-controller/abort-any'
import {
    TraceableAbortController,
    TraceableAbortSignal,
} from '../../../../../../util/src/abort-controller/traceable-abort-controller'
import { CommandInvoker } from '../../../../document/command/command-invoker'
import { CommitType } from '../../../../document/command/commit-type'
import { IMAGE_PATH_PREFIX } from '../../../../document/config/image-config'
import { environment, HttpPrefixKey } from '../../../../environment'
import { WkCLog } from '../../../../kernel/clog/wukong/instance'
import { AIGenChatSession, AIGenFeatures } from '../../../../kernel/request/ai-gen'
import { GetPrivateUploadAuthorization } from '../../../../kernel/request/upload'
import { nodeToCompressPNGBlob } from '../export/util'
import { AssistantMessage, ChatMessage, ChatMessageEvent } from './common'

export interface MessageParam {
    prompt: string
    selection_htmls: {
        html: string
        selectionIds: string[]
    }[]
    styleConfigId: number | null
    version: string
    promptImageUrl: string
}

const safeJsonParse = (str: string) => {
    try {
        return JSON.parse(str)
    } catch (error) {
        return null
    }
}

const INITIAL_STATE = {
    messages: [],
    sessionId: '',
    isSending: false,
    isFirstTokenReceived: false,
    prevChatId: '',
}

/**
 * Motiff AI 2.0 ChatService
 */
export class ChatService {
    private sessionAbortController = new TraceableAbortController('ChatSession')
    public states = createSelectors(
        createStore<{
            messages: ChatMessage[]
            sessionId: string
            isSending: boolean
            isFirstTokenReceived: boolean
            prevChatId: string
        }>(() => INITIAL_STATE, environment.isDev)
    )

    constructor(
        private readonly signal: TraceableAbortSignal,
        private readonly command: CommandInvoker,
        private readonly docId: string
    ) {}

    public loadHistorySession = (_: string) => {
        // TODO(jiangzg): impl
        this.abortLastSession()
    }

    public createSession = () => {
        this.abortLastSession()
        this.states.setState(INITIAL_STATE)
    }

    /**
     * TODO(jiangzg):
     * 1. 支持自动切换到AI属性面板
     */
    public async sendMessage(message: MessageParam) {
        if (this.states.getState().isSending) {
            return
        }

        this.states.setState({
            isSending: true,
            messages: [
                ...this.states.getState().messages,
                { role: 'user', content: message.prompt, imageUploadedUrl: '' },
            ],
        })

        const sessionSignal = this.getSessionAbortSignal()
        const sessionId = await this.getOrCreateSessionId(sessionSignal)

        const response = await this.sendChatMessage(sessionSignal, {
            sid: sessionId,
            docId: this.docId,
            prompt: message.prompt,
            selection_htmls: message.selection_htmls,
            styleConfigId: message.styleConfigId,
            version: 'V1',
            promptImageUrl: message.promptImageUrl ?? '',
            prevChatId: this.states.getState().prevChatId,
        })

        this.handleResponse(sessionSignal, response)
    }

    private async handleResponse(signal: TraceableAbortSignal, response: Response) {
        if (!response.ok) {
            WkCLog.log('[AI_CHAT_BOT] Failed to send message')
            this.states.setState({ isSending: false })
            return
        }

        if (!response.body) {
            WkCLog.log('[AI_CHAT_BOT] Response body is null')
            this.states.setState({ isSending: false })
            return
        }

        const reader = response.body.getReader()
        const decoder = new TextDecoder()
        const accumulatedResponse: AssistantMessage = {
            role: 'assistant',
            content: '',
            thinking: '',
            thinking_duration: 0,
            ui: [],
            chatId: '',
        }

        try {
            let isFirstChunk = true

            while (true) {
                const { done, value } = await reader.read()
                signal.throwIfAborted()

                if (done) {
                    break
                }

                if (isFirstChunk) {
                    this.states.setState({
                        isFirstTokenReceived: true,
                        messages: [
                            ...this.states.getState().messages,
                            {
                                role: 'assistant',
                                content: '',
                                thinking: '',
                                thinking_duration: 0,
                                ui: [],
                                chatId: '',
                            },
                        ],
                    })
                    isFirstChunk = false
                }

                const chunk = decoder.decode(value)
                const events = chunk.split('data: ')

                for (const event of events) {
                    const eventJson: ChatMessageEvent | null = safeJsonParse(event)
                    if (!eventJson) {
                        continue
                    }

                    switch (eventJson.type) {
                        case 'thinking':
                            accumulatedResponse.thinking = eventJson.content
                            break
                        case 'thinking_duration':
                            accumulatedResponse.thinking_duration = eventJson.content
                            break
                        case 'text':
                            accumulatedResponse.content += eventJson.content
                            break
                        case 'ui': {
                            const parser = new DOMParser()
                            const content = parser.parseFromString('<root>' + eventJson.content + '</root>', 'text/xml')
                            const url = content.querySelector('resource_url')?.textContent ?? ''
                            const uuid = content.querySelector('uuid')?.textContent ?? ''

                            const proto = await this.downloadProto(signal, url)
                            const applyResult = this.command.invokeBridge(
                                CommitType.CommitUndo,
                                ApplyChatBotAIGenCommand,
                                Wukong.DocumentProto.Arg_ApplyChatBot.create({
                                    data: [{ part: proto }],
                                })
                            )

                            for (const frame of applyResult.rootFrames) {
                                const blob = await nodeToCompressPNGBlob(
                                    this.command,
                                    [frame],
                                    {
                                        type: Wukong.DocumentProto.ExportConstraintType.EXPORT_CONSTRAINT_TYPE_SCALE,
                                        value: 1,
                                    },
                                    Wukong.DocumentProto.DocumentColorProfile.DOCUMENT_COLOR_PROFILES_R_G_B
                                )
                                signal.throwIfAborted()

                                if (blob) {
                                    const { method, resourceId, contentType, ossUrl } =
                                        await new GetPrivateUploadAuthorization({
                                            format: 'png',
                                            fileName: uuid,
                                        }).startWithSignal(signal)

                                    await fetch(ossUrl, {
                                        method,
                                        headers: {
                                            'Content-Type': contentType,
                                        },
                                        body: blob,
                                        signal,
                                    })

                                    accumulatedResponse.ui.push({
                                        state: 'created',
                                        url: url,
                                        uuid: uuid,
                                        preview: URL.createObjectURL(blob),
                                        previewResourceId: IMAGE_PATH_PREFIX + resourceId,
                                        nodeId: frame,
                                    })
                                } else {
                                    accumulatedResponse.ui.push({
                                        state: 'creating',
                                        url: url,
                                        uuid: uuid,
                                    })
                                }
                            }
                            break
                        }
                        case 'chat_id':
                            accumulatedResponse.chatId = eventJson.content
                            this.states.setState({
                                prevChatId: eventJson.content,
                            })
                            break
                    }

                    this.states.setState({
                        messages: [...this.states.getState().messages.slice(0, -1), accumulatedResponse],
                    })
                }
            }
        } finally {
            reader.releaseLock()
            this.states.setState({ isSending: false })
        }
    }

    private getOrCreateSessionId = async (signal: TraceableAbortSignal) => {
        if (this.states.getState().sessionId) {
            return this.states.getState().sessionId
        }

        const sessionId = await new AIGenChatSession(this.docId).startWithSignal(signal)
        this.states.setState({
            sessionId: sessionId,
        })

        return sessionId
    }

    private sendChatMessage = async (
        signal: TraceableAbortSignal,
        params: {
            sid: string
            docId: string
            prompt: string
            selection_htmls: {
                html: string
                selectionIds: string[]
            }[]
            styleConfigId: number | null
            version: string
            promptImageUrl: string
            prevChatId: string
        }
    ) => {
        const aiFeatures = new AIGenFeatures(params.docId, params.prompt, null, params.version)
        const features = await aiFeatures.start().catch(() => {
            return {
                result: '{}',
            }
        })
        const { default_config_id: defaultConfigId } = safeJsonParse(features.result ?? '{}') ?? {}

        WkCLog.log(`[AI_CHAT_BOT] ADD MESSAGE TO CHAT SESSION`, {
            sessionId: params.sid,
            docId: params.docId,
            prompt: params.prompt,
            styleConfigId: params.styleConfigId ?? 'unspecified',
            version: params.version,
            promptImageUrl: params.promptImageUrl,
        })

        const response = await fetch(
            environment.httpPrefixes[HttpPrefixKey.COMMON_API] +
                `/ai/ai-gen/chat-sessions/${params.sid}/chat?_debug_user_=12`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    doc_id: params.docId,
                    user_requirement: {
                        prompt: params.prompt,
                        params: {
                            version: params.version,
                            selection_htmls: params.selection_htmls,
                            styleConfigId: params.styleConfigId ?? undefined,
                            promptImageUrl: params.promptImageUrl,
                            configId: defaultConfigId,
                            features: features.result,
                            prevChatId: params.prevChatId,
                        },
                    },
                }),
                signal,
            }
        )

        return response
    }

    private downloadProto = async (signal: TraceableAbortSignal, url: string) => {
        const doc_res = await fetch(url, { signal })
        const buf = await doc_res.arrayBuffer()
        signal.throwIfAborted()
        const arr_buf = new Uint8Array(buf)
        return Wukong.DocumentProto.AIGenResult.decode(arr_buf)
    }

    private abortLastSession = () => {
        this.sessionAbortController.abort('abort by abortLastSession')
        this.sessionAbortController = new TraceableAbortController('ChatSession')
    }

    private getSessionAbortSignal = () => {
        return anySignal([this.signal, this.sessionAbortController.signal])
    }
}
