import {
    CreateColorVariableCommand,
    CreateFloatVariableCommand,
    CreateVariableSetCommand,
    ResetDefaultVariableModeBySetForPageCommand,
    ResetDefaultVariableModeBySetForSelectionCommand,
    SetVariableModeBySetForPageCommand,
    SetVariableModeBySetForSelectionCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { TraceableAbortSignal } from '../../../../util/src/abort-controller/traceable-abort-controller'
import { CommandInvoker } from '../../document/command/command-invoker'
import { CommitType } from '../../document/command/commit-type'
import { Bridge } from '../../kernel/bridge/bridge'
import { LocalVariableEditorService } from './local-variable-editor-service'

export class VariableService {
    readonly localVariableEditorService: LocalVariableEditorService
    constructor(signal: TraceableAbortSignal, bridge: Bridge, private readonly commandInvoker: CommandInvoker) {
        this.localVariableEditorService = new LocalVariableEditorService(signal, bridge, commandInvoker)
    }
    createCollection = (name: string) => {
        return this.commandInvoker.invokeBridge(CommitType.CommitUndo, CreateVariableSetCommand, { name })
    }
    preCreateColorVariable = (params: Wukong.DocumentProto.IArg_CreateColorVariable) => {
        return this.commandInvoker.invokeBridge(CommitType.Noop, CreateColorVariableCommand, params)
    }
    preCreateFloatVariable = (params: Wukong.DocumentProto.IArg_CreateFloatVariable) => {
        return this.commandInvoker.invokeBridge(CommitType.Noop, CreateFloatVariableCommand, params)
    }
    setVariableModeBySetForPage = (variableSetIds: string[], variableSetModeId: string) => {
        return this.commandInvoker.invokeBridge(CommitType.CommitUndo, SetVariableModeBySetForPageCommand, {
            variableSetIds,
            variableSetModeId,
        })
    }
    resetDefaultVariableModeBySetForPage = (variableSetIds: string[]) => {
        return this.commandInvoker.invokeBridge(CommitType.CommitUndo, ResetDefaultVariableModeBySetForPageCommand, {
            variableSetIds,
        })
    }
    setVariableModeBySetForSelection = (variableSetIds: string[], variableSetModeId: string) => {
        return this.commandInvoker.invokeBridge(CommitType.CommitUndo, SetVariableModeBySetForSelectionCommand, {
            variableSetIds,
            variableSetModeId,
        })
    }
    resetDefaultVariableModeBySetForSelection = (variableSetIds: string[]) => {
        return this.commandInvoker.invokeBridge(
            CommitType.CommitUndo,
            ResetDefaultVariableModeBySetForSelectionCommand,
            {
                variableSetIds,
            }
        )
    }
}
