import { useMemo } from 'react'
import { DropdownV2, MonoIconCommonInfoLine16, Tooltip } from '../../../../../ui-lib/src'
import { RoleStatus, UserMemberVO } from '../../../kernel/interface/type'
import { compareRole, RoleStatusWeight } from '../../../kernel/interface/user-roles'
import { AccessDropdownType, AccessMenuKey, AccessMenuLabel, ClickAccessMenuParams, ShareDialogType } from '../types'
import classes from './index.module.less'
import { getAccessMenuV2 } from './util'

interface AccessDropdownProps {
    type: AccessDropdownType
    role: RoleStatus
    member: UserMemberVO
    tooltipContent?: string
    isDraft: boolean
    onClickAccessMenu?: (p: ClickAccessMenuParams) => void
    shareDialogType: ShareDialogType
    dimission: boolean
    hidePrototype: boolean
}

export const AccessDropdown = ({
    type,
    role,
    member,
    tooltipContent,
    isDraft,
    onClickAccessMenu,
    shareDialogType,
    dimission,
    hidePrototype,
}: AccessDropdownProps) => {
    const itemsV2 = useMemo(() => {
        const accessMenuV2 = getAccessMenuV2({ type, role, isDraft, shareDialogType, dimission, hidePrototype })
        const result: { label: string; value: AccessMenuKey; splitLineBottom: boolean }[] = []
        accessMenuV2.reduce((prev, next) => {
            if (next === 'divider') {
                prev.length && (prev[prev.length - 1].splitLineBottom = true)
            } else {
                prev.push({
                    label: AccessMenuLabel[next],
                    value: next,
                    splitLineBottom: false,
                })
            }
            return prev
        }, result)
        return result
    }, [dimission, hidePrototype, isDraft, role, shareDialogType, type])

    const isDropdown = useMemo(() => {
        switch (type) {
            case AccessDropdownType.Parent:
            case AccessDropdownType.Public:
                return compareRole(role, RoleStatus.Viewer) > 0
            case AccessDropdownType.Member:
            case AccessDropdownType.Invited:
                if (role) {
                    return (
                        member.role !== RoleStatus.Owner &&
                        RoleStatusWeight[role] >= RoleStatusWeight[member.actualRole]
                    )
                }
                break
            case AccessDropdownType.Self:
                return !isDraft || (isDraft && member.role !== RoleStatus.Owner)
            default:
                break
        }
        return false
    }, [type, member, role, isDraft])

    return (
        <>
            {type !== AccessDropdownType.Parent && tooltipContent?.length ? (
                <Tooltip overlay={tooltipContent}>
                    <span className={classes.tip}>
                        <MonoIconCommonInfoLine16
                            className="color-$wk-l2-label-color-gray-8"
                            data-testid="icon-hint-svg"
                        />
                    </span>
                </Tooltip>
            ) : null}
            {isDropdown ? (
                <DropdownV2.MinimalSingleLevel
                    label={AccessMenuLabel[member.role]}
                    onChange={(v) => {
                        onClickAccessMenu?.({ key: v, member, type })
                    }}
                    dataTestIds={{
                        container: 'access-dropdown-container',
                        triggerFocus: 'access-dropdown-trigger-focus',
                    }}
                >
                    {itemsV2.map((v, index) => (
                        <DropdownV2.MinimalSingleLevel.Option
                            key={index}
                            value={v.value}
                            splitLineBottom={v.splitLineBottom}
                            role="menuitem"
                            isSelect={v.value === member.role}
                        >
                            {v.label}
                        </DropdownV2.MinimalSingleLevel.Option>
                    ))}
                </DropdownV2.MinimalSingleLevel>
            ) : (
                <div className={classes.content}>
                    <span>{AccessMenuLabel[member.role]}</span>
                    <span style={{ width: '16px' }}></span>
                </div>
            )}
        </>
    )
}
