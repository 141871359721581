import { FeatureSwitchConfig, SwitchEnvironmentScopeType, SwitchStrategyType } from '../feature-switch-config'
const Config: FeatureSwitchConfig = {
    name: 'comment-perf-viewport-filter',
    owner: '',
    description: '',
    strategies: [
        {
            config: {
                type: SwitchStrategyType.SPECIFIC_USER_LIST,
                userList: ['42dfaf3c6e424285078d88146c7e8083e4f41f67'],
            },
            env: SwitchEnvironmentScopeType.TESTING,
        },
    ],
    alwaysDisableInTests: false,
}
export default Config
