/**
 * @owner: chenxiangbj@kanyun.com
 */
import classnames from 'classnames'
import { FC, ReactNode, useRef } from 'react'
import { createRoot } from 'react-dom/client'
import { useMount } from 'react-use'
import {
    MonoIconCommonClose16,
    MonoIconCommonError24,
    MonoIconCommonInfo24,
    MonoIconCommonSuccess24,
    MonoIconCommonWarning24,
} from '../../icons-v2'
import { useUnmountHref } from '../../utils/hook/use-unmount-href'
import { randomString } from '../../utils/random'
import { WKButton, WKButtonProps } from '../wk-button/button'
import { WKIconButton } from '../wk-button/icon-button'
import classes from './index.module.less'
import { translation } from './index.translation'

export interface WKconfirmProps {
    title: string
    icon?: ReactNode
    content?: ReactNode
    okText?: string
    cancelText?: string
    closable?: boolean
    okButtonProps?: Partial<WKButtonProps>
    cancelButtonProps?: Partial<WKButtonProps>
    onClose?: () => void
    onOk?: () => void | Promise<unknown>
    width?: number
    className?: string
    wrapperTestId?: string
    showButton?: boolean
    hideCancelButton?: boolean
    showDividerLine?: boolean
    closeWhenHrefChanged?: boolean
    showLoadingWhenOk?: boolean
}

const WKconfirm: FC<WKconfirmProps> = (props) => {
    const {
        icon,
        title,
        okText,
        cancelText,
        onClose,
        onOk,
        content,
        cancelButtonProps,
        okButtonProps,
        width,
        className,
        closable = true,
        showButton = true,
        hideCancelButton,
        wrapperTestId,
        showDividerLine,
        closeWhenHrefChanged,
    } = props
    useUnmountHref(() => closeWhenHrefChanged && onClose?.())

    // confirm 打开的时候要获取焦点，防止交单在外面被 enter 键重复激活
    const ref = useRef<HTMLDivElement>(null)
    useMount(() => {
        ref.current?.focus()
    })

    return (
        <div className="w-full h-full bg-$wk-gray-13-60 relative flex justify-around" data-testid={wrapperTestId}>
            <div
                role="dialog"
                ref={ref}
                tabIndex={-1}
                className={classnames('w-400px bg-white rounded-4px absolute top-15vh box-border', className)}
                style={{ width }}
            >
                <div className={classnames('px-6 flex', showDividerLine ? 'pt-4' : 'pt-6')}>
                    {icon && <div className="mr-2 flex">{icon}</div>}
                    <div className="flex-1 w-0">
                        {(!!title || closable) && (
                            <div className="wk-break-word wk-text-14 wk-font-semibold flex justify-between min-h-6">
                                <span
                                    data-testid="confirm-title"
                                    className="color-$wk-l2-label-color-gray-13 h-min p-1px"
                                >
                                    {title}
                                </span>
                                {closable && (
                                    <div className="h-6 w-6 relative -top-3px -right-4px shrink-0">
                                        <WKIconButton
                                            onClick={onClose}
                                            icon={<MonoIconCommonClose16 />}
                                            data-testid={'wk-confirm-close-button'}
                                        ></WKIconButton>
                                    </div>
                                )}
                            </div>
                        )}
                        {showDividerLine && <span className={classes.headerDivider} />}
                        {content && (
                            <div
                                className="mt-3 color-$wk-l2-label-color-gray-11 wk-text-12"
                                data-testid="confirm-content"
                            >
                                {content}
                            </div>
                        )}
                    </div>
                </div>
                {showDividerLine && <span className={classes.footerDivider} />}
                {showButton ? (
                    <div className="px-6 py-4 mt-2 flex justify-end">
                        {hideCancelButton ? null : (
                            <WKButton
                                onClick={onClose}
                                type="secondary"
                                data-testid="confirm-cancel"
                                {...cancelButtonProps}
                            >
                                {cancelText ?? translation('Cancel')}
                            </WKButton>
                        )}
                        <WKButton onClick={onOk} type="primary" data-testid="confirm-ok" {...okButtonProps}>
                            {okText ?? translation('WFgTEW')}
                        </WKButton>
                    </div>
                ) : (
                    <div className="pb-5"></div>
                )}
            </div>
        </div>
    )
}

const createRootDiv = () => {
    const confirmContainer = document.createElement('div')
    confirmContainer.style.position = 'absolute'
    confirmContainer.style.width = '100vw'
    confirmContainer.style.height = '100vh'
    confirmContainer.style.inset = '0'
    confirmContainer.style.zIndex = '999'
    document.body.appendChild(confirmContainer)
    return [createRoot(confirmContainer), confirmContainer] as const
}

interface WKDialogConfirmConfig {
    onVisibleChange?: (
        v: boolean | undefined,
        opt: {
            id: string
            title?: string
        }
    ) => void
}
class WKDialogConfirm {
    private config?: WKDialogConfirmConfig
    public init(config?: { onVisibleChange: WKDialogConfirmConfig['onVisibleChange'] }) {
        this.config = config
    }
    public show(param: WKconfirmProps) {
        const id = randomString(6)
        const [root, container] = createRootDiv()
        this?.config?.onVisibleChange?.(true, { id, title: param.title })

        const destroy = () => {
            this?.config?.onVisibleChange?.(true, { id, title: param.title })
            root.unmount()
            container.remove()
        }

        const update = (p: Partial<WKconfirmProps>) => {
            root.render(<WKconfirm {...param} {...p} onOk={onOk} onClose={onClose} />)
        }
        const onOk = async () => {
            if (param.showLoadingWhenOk) {
                update({ okButtonProps: { ...param.okButtonProps, loading: true } })
                await param.onOk?.()
                update({ okButtonProps: { ...param.okButtonProps, loading: false } })
                destroy()
            } else {
                destroy()
                param.onOk?.()
            }
        }
        const onClose = () => {
            destroy()
            param.onClose?.()
        }

        root.render(<WKconfirm {...param} onOk={onOk} onClose={onClose} />)
        return { destroy }
    }
    public info(param: WKconfirmProps) {
        return this.show({ ...param, icon: <MonoIconCommonInfo24 className="color-$wk-brand-7" /> })
    }
    public warning(param: WKconfirmProps) {
        return this.show({ ...param, icon: <MonoIconCommonWarning24 className="color-$wk-yellow-7" /> })
    }
    public success(param: WKconfirmProps) {
        return this.show({ ...param, icon: <MonoIconCommonSuccess24 className="color-$wk-green-7" /> })
    }
    public error(param: WKconfirmProps) {
        return this.show({ ...param, icon: <MonoIconCommonError24 className="color-$wk-red-7" /> })
    }
}

export const wkDialogConfirm = new WKDialogConfirm()
