import { useMemo } from 'react'
import { useAppContext } from '../../../../main/app-context'
import { ChatAssistantMessage } from './chat-assistant-message'
import { ChatHeader } from './chat-header'
import { ChatbotInput, InputStates } from './chat-input'
import { ChatMessageLoading } from './chat-message-loading'
import { ChatUserMessage } from './chat-user-message'
import { ChatWelcome } from './chat-welcome'
import { ChatbotInputProvider } from './use-chat-input'

export const ChatBot = () => {
    const { chatService } = useAppContext()
    const messages = chatService.states.use.messages()
    const isSending = chatService.states.use.isSending()
    const isFirstTokenReceived = chatService.states.use.isFirstTokenReceived()

    const messageItems = useMemo(() => {
        return messages.map((message, index) => {
            switch (message.role) {
                case 'user':
                    return <ChatUserMessage message={message} key={index} />
                case 'assistant':
                    return <ChatAssistantMessage message={message} key={index} />
            }
        })
    }, [messages])

    const onSend = async (states: InputStates) => {
        chatService.sendMessage({
            prompt: states.prompt,
            selection_htmls: states.selection_htmls,
            styleConfigId: states.styleConfigId,
            version: 'V1',
            promptImageUrl: states.imageUploadedUrl ?? '',
        })
    }

    return (
        <ChatbotInputProvider>
            <div className="w-full h-full flex flex-col box-border">
                <ChatHeader onCreate={() => chatService.createSession()} />
                <div className="flex-1 overflow-x-hidden overflow-y-auto">
                    {messageItems.length === 0 ? <ChatWelcome /> : null}
                    {messageItems}
                    {isSending && !isFirstTokenReceived && <ChatMessageLoading />}
                </div>
                <div className="shrink-0 p-10px">
                    <ChatbotInput triggerGen={onSend} />
                </div>
            </div>
        </ChatbotInputProvider>
    )
}
