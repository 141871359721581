import {
    MonoIconCommonClose16,
    MonoIconCommonSuccess24,
    WKButton,
    WKDialog,
    WKIconButton,
    WKTextButton,
} from '../../../../../../../ui-lib/src'
import { getMotiffName } from '../../../../../../../util/src'
import { PluginDevStatus, usePluginDevelopment } from '../use-plugin-development'
import { translation } from './plugin-dev-create-success.translation'

export function PluginDevelopmentCreateSuccess() {
    const { setPluginDevStatus, createdPluginPath } = usePluginDevelopment()

    return (
        <WKDialog
            visible={true}
            showTitle={false}
            footer={null}
            width={400}
            onCancel={() => {
                setPluginDevStatus(PluginDevStatus.Main)
            }}
            bodyStyle={{ padding: 0 }}
        >
            <div className="flex pt-25px pl-24px pr-20px justify-start items-start">
                <MonoIconCommonSuccess24 className="color-$wk-green-7 mr-2" />
                <div className="w-320px h-64px flex flex-col">
                    <div className="flex justify-between items-center">
                        <div className="py-1px wk-text-14 wk-font-medium color-$wk-l2-label-color-gray-13">
                            {translation('PluginCreateSuccess')}
                        </div>
                        <WKIconButton
                            size="small"
                            icon={<MonoIconCommonClose16 className="color-$wk-l2-label-color-gray-8" />}
                            onClick={() => {
                                setPluginDevStatus(PluginDevStatus.Main)
                            }}
                        />
                    </div>
                    <div className="mt-12px">
                        <span>{translation('YouCan')}</span>
                        <WKTextButton
                            type="primary"
                            size={12}
                            onClick={() => {
                                if (window.localBridge?.openExternal) {
                                    window.localBridge.openExternal(`${window.location.origin}/plugin-docs`)
                                } else {
                                    window.open(`/plugin-docs`, '_blank')
                                }
                            }}
                        >
                            {translation('DeveloperDocument', { name: getMotiffName() })}
                        </WKTextButton>
                        {translation('StartDeveloping')}
                    </div>
                </div>
            </div>
            <div className="px-24px py-16px flex justify-end items-center">
                <WKButton
                    onClick={() => {
                        window.localBridge?.openLocalFileFolder?.(createdPluginPath)
                    }}
                    type="secondary"
                >
                    {translation('ViewLocalFile')}
                </WKButton>
                <WKButton
                    onClick={() => {
                        setPluginDevStatus(PluginDevStatus.Main)
                    }}
                    type="primary"
                >
                    {translation('Done')}
                </WKButton>
            </div>
        </WKDialog>
    )
}
