import { Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { useCallback, useMemo } from 'react'
import { useAsync } from 'react-use'
import {
    MonoIconCommonArrowLeft16,
    MonoIconCommonLoading16,
    Scrollbar,
    WKButton,
    WKDivider,
    WKTypography,
} from '../../../../../ui-lib/src'
import { isEnglishLanguage } from '../../../../../util/src'
import { LibraryContentVO } from '../../../kernel/interface/library'
import { ResourceType } from '../../../kernel/interface/type'
import { GetDefaultLibraryContentMap, GetLibraryContentMap } from '../../../kernel/request/library'
import { useLibraryComponentService } from '../../../main/app-context'
import { useViewState } from '../../../view-state-bridge'
import { LibraryTestId } from '../../../window'
import { useLibraryRemoteSearchService } from '../hook/use-library-service-remote-search'
import {
    buildComponentViewerItemFromVO,
    LibraryComponentListLayout,
    LibraryComponentViewer,
} from '../library-component-viewer/library-component-viewer'
import {
    buildStyleViewerItemFromVO,
    LibraryStyleListLayout,
    LibraryStyleViewer,
    LibraryVariableGridViewer,
    LibraryVariableSetGridViewer,
    LibraryVariableSetViewer,
} from '../library-style-viewer/library-style-viewer'
import { LibrarySubscribeSwitch } from '../library-subscribe-switch/library-subscribe-switch'
import {
    DivideSortLibraryComponentDisplayItem,
    DivideSortLibraryComponentDisplayType,
    getDivideSortLibraryContent,
} from '../util/sort'
import classes from './library-control-list-item-detail.module.less'
import { translation } from './library-control-list-item-detail.translation'
import {
    ComponentGetVO,
    ComponentSetGetVO,
    LibraryId,
    StyleGetVO,
    VariableGetVO,
    VariableSetGetVO,
} from '../../../kernel/interface/component-style'

// 组件库详情
export function LibraryControlListItemDetailContainer(props: { libraryId?: LibraryId; className?: string }) {
    return props.libraryId ? (
        <LibraryContentDetailViewerContainer className={props.className} libraryId={props.libraryId} />
    ) : null
}

function LibraryContentDetailViewerContainer(props: { className?: string; libraryId: LibraryId }) {
    const {
        states: libraryRemoteSearchStates,
        resourceId,
        resourceType,
        switchDesc,
        goBackHome,
    } = useLibraryRemoteSearchService()
    const librarySearchKeyword = libraryRemoteSearchStates.use.librarySearchKeywordState()
    const libraryQueryResponse = libraryRemoteSearchStates.use.libraryQueryResponseState()
    const searchMatchedComponents = libraryQueryResponse?.libraryId2MatchedComponentList[props.libraryId] ?? []
    const searchMatchedStyles = libraryQueryResponse?.libraryId2MatchedStyleList[props.libraryId] ?? []
    const searchMatchedVariableSets = useMemo(
        () => libraryQueryResponse?.libraryId2MatchedVariableCollectionList?.[props.libraryId] ?? [],
        [libraryQueryResponse?.libraryId2MatchedVariableCollectionList, props.libraryId]
    )
    const searchMatchedVariables = useMemo(
        () => libraryQueryResponse?.libraryId2MatchedVariableList?.[props.libraryId] ?? [],
        [libraryQueryResponse?.libraryId2MatchedVariableList, props.libraryId]
    )

    const { value: libraryContent, loading } = useAsync(
        () =>
            (resourceType === ResourceType.Document
                ? new GetLibraryContentMap([props.libraryId], resourceId).start()
                : new GetDefaultLibraryContentMap(resourceId, resourceType, [props.libraryId]).start()
            ).then((libraryContentMap) => libraryContentMap[props.libraryId]),
        [props.libraryId, resourceId, resourceType]
    )

    const showVariableSets = useMemo(() => {
        const result: Array<VariableSetGetVO> = []
        if (!libraryContent?.variableSets) {
            return result
        }
        const collectionUseMap: Map<string, number> = new Map()
        searchMatchedVariables.forEach((item) => {
            collectionUseMap.set(
                item.collectionId,
                collectionUseMap.get(item.collectionId) ? collectionUseMap.get(item.collectionId)! + 1 : 1
            )
        })
        for (const [collectionId, count] of collectionUseMap.entries()) {
            const variableSet = libraryContent.variableSets.find((item: VariableSetGetVO) => item.id === collectionId)
            if (variableSet) {
                result.push({ ...variableSet, variableCount: count })
            }
        }
        searchMatchedVariableSets.forEach((item) => {
            if (!result.find((resultItem) => resultItem.id === item.id)) {
                result.push({ ...item, variableCount: 0 })
            }
        })
        result.sort((a, b) => a.name?.localeCompare(b.name ?? '') ?? 0)
        return result
    }, [libraryContent?.variableSets, searchMatchedVariableSets, searchMatchedVariables])

    return (
        <div
            className={classNames(classes.container, props.className)}
            data-testid={LibraryTestId.RemoteDetailModal.Body}
        >
            <div className={classes.topContainer}>
                <div
                    className="inline-flex items-center"
                    onClick={goBackHome}
                    data-testid={LibraryTestId.RemoteDetailModal.BackBtn}
                >
                    <MonoIconCommonArrowLeft16 className="color-$wk-l2-label-color-gray-8" />
                    <span className={classes.docNameContainer}>{libraryContent?.library.document?.name}</span>
                </div>
                <div className="inline-flex items-center">
                    {!!switchDesc && (
                        <span className="color-$wk-l2-label-color-gray-8 wk-text-12 mr-2">{switchDesc}</span>
                    )}
                    <LibrarySubscribeSwitch libraryId={props.libraryId} />
                </div>
            </div>
            <WKDivider className={classes.divider} />
            <Scrollbar className={classes.scrollWrapper}>
                {!!(
                    librarySearchKeyword &&
                    (searchMatchedComponents?.length || searchMatchedStyles?.length || showVariableSets.length)
                ) && (
                    <div className={classes.searchResultContainer}>
                        <WKTypography.Paragraph color="placeholder" className={classes.infoContainer}>
                            {translation('BestMatchesFor', { keyword: librarySearchKeyword })}
                        </WKTypography.Paragraph>
                        {showVariableSets.length ? (
                            <div className={classNames(classes.libraryVariableSetViewerContainer)}>
                                {showVariableSets.map((variableCollection) => (
                                    <LibraryVariableSetViewer
                                        key={variableCollection.id}
                                        style={variableCollection}
                                        isSearchResult={true}
                                    />
                                ))}
                            </div>
                        ) : null}
                        {!!searchMatchedStyles?.length && (
                            <LibraryContentStyleDetailViewer styles={searchMatchedStyles} />
                        )}
                        {!!searchMatchedComponents?.length && (
                            <LibraryContentComponentDetailViewer components={searchMatchedComponents} />
                        )}
                        <WKDivider className={classes.divider} />
                    </div>
                )}
                {loading ? (
                    <MonoIconCommonLoading16 className={classes.loading} />
                ) : (
                    <div className="mt-4">
                        <RemoteLibraryContentViewerV2 libraryContent={libraryContent} />
                    </div>
                )}
            </Scrollbar>
            {resourceType === ResourceType.Document && (
                <div className={classes.footerWrapper}>
                    <RemoteLibraryDetailFooter
                        libraryId={libraryContent?.library.id}
                        documentId={libraryContent?.library.document?.id}
                        docName={libraryContent?.library.document?.name}
                    />
                </div>
            )}
        </div>
    )
}

function ViewerTitle(props: { title: string }) {
    return (
        <div className={classes.viewerTitleWrapper}>
            <div className={classes.viewerTitle}>{props.title}</div>
            <div className={classes.titleDivider}></div>
        </div>
    )
}

export function RemoteLibraryContentViewerV2(props: {
    libraryContent: LibraryContentVO | undefined
    hideVariable?: boolean // 隐藏变量，组件库使用统计场景
    size?: 'default' | 'large' // 默认 92px，large 98px
}) {
    const divideSortLibraryContent = useMemo(
        () => (props.libraryContent ? getDivideSortLibraryContent(props.libraryContent) : undefined),
        [props.libraryContent]
    )

    const sortedVariableList = divideSortLibraryContent?.variableCollectionList
    if (sortedVariableList) {
        sortedVariableList.sort((a, b) => a.name?.localeCompare(b.name ?? '') ?? 0)
    }

    return divideSortLibraryContent?.componentDisplayList.length ||
        divideSortLibraryContent?.styleList.length ||
        divideSortLibraryContent?.variableCollectionList.length ? (
        <div className={classes.libraryContentViewerV2Container}>
            {!props.hideVariable && !!divideSortLibraryContent.variableCollectionList.length && (
                <div>
                    <ViewerTitle title={translation('VariableCollection')} />
                    <div className={classNames(classes.libraryVariableSetViewerContainer)}>
                        {divideSortLibraryContent.variableCollectionList.map((variableCollection) => (
                            <LibraryVariableSetViewer key={variableCollection.id} style={variableCollection} />
                        ))}
                    </div>
                </div>
            )}
            {!!divideSortLibraryContent.styleList.length && (
                <div>
                    <ViewerTitle title={translation('Style')} />
                    <LibraryContentStyleDetailViewer
                        className={classes.styleDetailContainer}
                        styles={divideSortLibraryContent.styleList}
                    />
                </div>
            )}
            {!!divideSortLibraryContent.componentDisplayList.length && (
                <div>
                    <ViewerTitle title={translation('Component')} />
                    {divideSortLibraryContent.componentDisplayList.map((displayItem) => (
                        <LibraryComponentDisplayItemV2
                            key={displayItem.id}
                            componentDisplayItem={displayItem}
                            size={props.size}
                        />
                    ))}
                </div>
            )}
        </div>
    ) : (
        <WKTypography.Paragraph color="placeholder" className="text-center mt-4">
            {translation('NoContent')}（{translation('PleaseUnpublishAnd')}）
        </WKTypography.Paragraph>
    )
}

function LibraryComponentDisplayItemV2(props: {
    componentDisplayItem: DivideSortLibraryComponentDisplayItem
    size?: 'default' | 'large'
}) {
    return (
        <>
            {!!props.componentDisplayItem.name && (
                <div
                    className={classNames({
                        [classes.pageTitleV2]:
                            props.componentDisplayItem.displayType === DivideSortLibraryComponentDisplayType.Page ||
                            props.componentDisplayItem.displayType === DivideSortLibraryComponentDisplayType.OnlyPage,
                        [classes.frameTitleV2]:
                            props.componentDisplayItem.displayType === DivideSortLibraryComponentDisplayType.Frame,
                    })}
                >
                    {props.componentDisplayItem.name}
                </div>
            )}
            <LibraryContentComponentDetailViewer components={props.componentDisplayItem.children} size={props.size} />
        </>
    )
}

// 组件库详情-组件列表预览
export function LibraryContentComponentDetailViewer({
    components,
    size,
}: {
    components: ReadonlyArray<ComponentGetVO | ComponentSetGetVO>
    size?: 'default' | 'large' // 默认 92px，large 98px
}) {
    const itemSize = size === 'large' ? 98 : 92
    return (
        <LibraryComponentListLayout
            maxChildrenLength={components.length}
            className={classNames(classes.libraryComponentListContainer, size === 'large' && classes.large)}
        >
            {components.map((component) => (
                <LibraryComponentViewer
                    key={component.id}
                    component={buildComponentViewerItemFromVO(component)}
                    innerStyle={{ width: itemSize, height: itemSize }}
                />
            ))}
        </LibraryComponentListLayout>
    )
}

// 组件库详情-样式列表预览
export function LibraryContentStyleDetailViewer(props: { styles: ReadonlyArray<StyleGetVO>; className?: string }) {
    return (
        <LibraryStyleListLayout maxChildrenLength={props.styles.length} className={props.className}>
            {props.styles.map((style) => (
                <LibraryStyleViewer key={style.id} style={buildStyleViewerItemFromVO(style)} />
            ))}
        </LibraryStyleListLayout>
    )
}

// 组件库详情-变量列表预览
export function LibraryContentVariableDetailViewer(props: {
    styles: ReadonlyArray<VariableGetVO>
    className?: string
}) {
    return (
        <LibraryStyleListLayout maxChildrenLength={props.styles.length} className={props.className}>
            {props.styles.map((style) => (
                <LibraryVariableGridViewer key={style.id} style={style} />
            ))}
        </LibraryStyleListLayout>
    )
}

// 组件库详情合集列表预览
export function LibraryContentVariableSetDetailViewer(props: {
    styles: ReadonlyArray<VariableSetGetVO>
    className?: string
}) {
    return (
        <LibraryStyleListLayout maxChildrenLength={props.styles.length} className={props.className}>
            {props.styles.map((style) => (
                <LibraryVariableSetGridViewer key={style.id} style={style} />
            ))}
        </LibraryStyleListLayout>
    )
}

// 组件库详情- footer 当前文件使用情况
function RemoteLibraryDetailFooter(props: Wukong.DocumentProto.ILibraryIdInfo & { docName: string | undefined }) {
    const openingLibraryInUseCountInfo = useViewState('openingLibraryInUseCountInfo')
    const {
        libraryModalRouterService: { goToLibraryReplaceDetail },
    } = useLibraryComponentService()

    const text = useMemo(() => {
        const texts = []
        if (openingLibraryInUseCountInfo?.componentCount) {
            texts.push(
                `${openingLibraryInUseCountInfo.componentCount}${
                    isEnglishLanguage()
                        ? openingLibraryInUseCountInfo.componentCount === 1
                            ? ' component'
                            : ' components'
                        : '个组件'
                }`
            )
        }

        if (openingLibraryInUseCountInfo?.styleCount) {
            texts.push(
                `${openingLibraryInUseCountInfo.styleCount}${
                    isEnglishLanguage()
                        ? openingLibraryInUseCountInfo.styleCount === 1
                            ? ' style'
                            : ' styles'
                        : '个样式'
                }`
            )
        }

        if (texts.length) {
            return `${texts.join(`${translation('And')} `)}${translation('UsedInThis')}`
        }
        return ''
    }, [openingLibraryInUseCountInfo?.componentCount, openingLibraryInUseCountInfo?.styleCount])

    const onClick = useCallback(() => {
        if (props.documentId) {
            goToLibraryReplaceDetail(props.documentId, props.docName, props.libraryId ?? undefined)
        }
    }, [goToLibraryReplaceDetail, props.docName, props.documentId, props.libraryId])

    return text ? (
        <div className={classNames(classes.countInfo, 'flex', 'justify-between', 'items-center')}>
            <span className="wk-text-12 text-black">{text}</span>
            <WKButton type="secondary" onClick={onClick}>
                {translation('SwapLibrary')}
            </WKButton>
        </div>
    ) : (
        <></>
    )
}
