import { wkDialogConfirm, WKToast } from '../../../../../ui-lib/src'
import { WKLoading } from '../../../../../ui-lib/src/components/wk-loading/wk-loading'
import { environment } from '../../../environment'
import { ContractVO, ResourceType, TeamID } from '../../../kernel/interface/type'
import { CancelTerminationAppoint, QueryAllContracts } from '../../../kernel/request/contract'
import { getSpaceStore, setSpaceStore, type PlanSubscriptionStatusStore } from './types'

// 预约订阅状态
export const generatePlanSubscriptionStatusStore = (
    set: setSpaceStore,
    get: getSpaceStore
): PlanSubscriptionStatusStore => {
    let lastCallId = 0

    return {
        data: {},
        loading: false,
        _fetchData: async (teamId?: TeamID) => {
            const currentCallId = ++lastCallId
            const orgId = get().organizationStore.organization.id
            const _teamId = teamId ?? get().teamPageStore.teamId

            if (!_teamId || orgId !== '-1') {
                return
            }

            if (environment.isAbroad) {
                const contractLists = await new QueryAllContracts(ResourceType.Team, _teamId)
                    .start()
                    .catch(() => [] as ContractVO[])
                const effectiveContract = contractLists.find((contract) => contract.status === 'EFFECTIVE')
                const hasTerminationAppointment = effectiveContract?.terminationAppointed ?? false
                const expireTimeMills = effectiveContract?.expireTimeMills ?? 0

                if (currentCallId !== lastCallId) {
                    return
                }

                set((state) => {
                    state.planSubscriptionStatusStore.data[_teamId] = {
                        contractLists: contractLists,
                        hasTerminationAppointment,
                        expireTimeMills,
                    }
                })
                return { contractLists }
            } else {
                set((state) => {
                    state.planSubscriptionStatusStore.data[_teamId] = {
                        contractLists: [],
                        hasTerminationAppointment: false,
                    }
                })
                return { contractLists: [] }
            }
        },
        fetchData: async (teamId?: TeamID) => {
            set((state) => {
                state.planSubscriptionStatusStore.loading = true
            })
            await get().planSubscriptionStatusStore._fetchData(teamId)
            set((state) => {
                state.planSubscriptionStatusStore.loading = false
            })
        },
        onReactiveAbroadProfessionalPlan: async () => {
            const _teamId = get().teamPageStore.teamId
            const orgId = get().organizationStore.organization.id

            if (!_teamId || orgId !== '-1') {
                return
            }

            const modalDestroyFn = wkDialogConfirm.show({
                title: '',
                showButton: false,
                closable: false,
                showDividerLine: false,
                content: (
                    <div className="flex flex-col items-center mb-10 -mt-2">
                        <span className="wk-text-18 wk-font-semibold color-$wk-l2-label-color-gray-13 mb-12">{`Updating your subscription`}</span>
                        <WKLoading size="default" noText />
                    </div>
                ),
            }).destroy

            try {
                await new CancelTerminationAppoint(ResourceType.Team, _teamId).start()
                await get().planSubscriptionStatusStore._fetchData(_teamId)
                modalDestroyFn()
                WKToast.show('Successfully reactivated your subscription.')
            } catch {
                modalDestroyFn()
                WKToast.error('Failed to reactive plan')
            }
        },
    }
}
