import { ScrollView, WKDivider, WKUserAvatar } from '../../../../../../ui-lib/src'
import { timeDesc } from '../../../../../../util/src'
import { AISearchDocumentResult } from '../../../../kernel/interface/ai-search'
import { translation } from './ai-search-result-file-detail.translation'
import { AISearchResultFileNode } from './ai-search-result-file-node'
import { AiSearchResultModel } from './use-ai-search'

export function AiSearchResultFileDetail({
    file,
    model,
}: {
    file: AISearchDocumentResult
    model: AiSearchResultModel
}) {
    const ownerInfo = model.getFileOwnerInfo(file)

    return (
        <div data-testid="ai-search-result-file-detail">
            <div className="h-48px px-24px flex flex-row items-center gap-48px justify-between">
                <div className="flex-none wk-font-regular wk-text-12 text-gray">
                    {translation('TotalResult', { count: String(file.candidates.length) })}
                </div>
                <div className="flex flex-row items-center gap-16px overflow-hidden">
                    <div className="flex-none wk-font-regular wk-text-12 text-gray whitespace-pre">
                        {translation('LastModified')}
                        <span className="color-$wk-l2-label-color-gray-11">
                            {timeDesc(file.documentVO.lastEditedTime)}
                        </span>
                    </div>
                    <div className="flex-1 flex flex-row items-center overflow-hidden">
                        <div className="flex-none wk-font-regular wk-text-12 text-gray whitespace-pre">
                            {translation('Owner')}
                        </div>
                        <div className="flex flex-row items-center gap-4px overflow-hidden">
                            {ownerInfo && <WKUserAvatar userInfo={ownerInfo} size={24} />}
                            {ownerInfo && (
                                <div className="color-$wk-l2-label-color-gray-11 overflow-hidden text-ellipsis whitespace-nowrap">
                                    {ownerInfo.nickname}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <WKDivider />
            <div className="h-696px">
                <ScrollView>
                    <div className="grid grid-cols-3 gap-y-24px gap-x-20px px-24px py-24px pb-32px">
                        {file.candidates.map((candidate) => {
                            return <AISearchResultFileNode key={candidate.nodeId} file={file} candidate={candidate} />
                        })}
                    </div>
                </ScrollView>
            </div>
        </div>
    )
}
