import { Wukong } from '@wukong/bridge-proto'
import { CHECKBOX_SVG, DATE_SVG } from '../static/icons'
import { getNextNodeId, hexToRgb, parentInfo, parseRGBA } from '../utils/utils'

export function isTextLikeInput(type: string) {
    return [
        'text',
        'number',
        'password',
        'email',
        'tel',
        'search',
        'url',
        'date',
        'time',
        'datetime-local',
        'month',
        'week',
    ].includes(type)
}

export function makeCheckbox(id: string, size: number, checked: boolean) {
    const checkboxId = getNextNodeId()
    const checkbox: Wukong.DocumentProto.ISynergyNode = {
        nodeId: checkboxId,
        partialNode: {
            type: Wukong.DocumentProto.NodeType.NODE_TYPE_FRAME,
            id: checkboxId,
            parentInfo: parentInfo.getParentInfo(id),
            name: 'CHECKBOX',
            width: size,
            height: size,
            cornerRadius: 2,
            topLeftRadius: 2,
            topRightRadius: 2,
            bottomLeftRadius: 2,
            bottomRightRadius: 2,
            fills: [
                {
                    type: Wukong.DocumentProto.PaintType.PAINT_TYPE_SOLID_PAINT,
                    visible: true,
                    opacity: 1,
                    blendMode: Wukong.DocumentProto.BlendMode.BLEND_MODE_NORMAL,
                    color: { r: 255, g: 255, b: 255 },
                },
            ],
            strokes: [
                {
                    type: Wukong.DocumentProto.PaintType.PAINT_TYPE_SOLID_PAINT,
                    visible: true,
                    opacity: 1,
                    blendMode: Wukong.DocumentProto.BlendMode.BLEND_MODE_NORMAL,
                    color: { r: 118, g: 118, b: 118 },
                },
            ],
            strokeWeight: 1,
            strokeAlign: Wukong.DocumentProto.StrokeAlign.STROKE_ALIGN_INSIDE,
        },
    }
    let iconData: { parentNodeId: string; data: string } | null = null
    if (checked) {
        checkbox.partialNode!.fills = [
            {
                type: Wukong.DocumentProto.PaintType.PAINT_TYPE_SOLID_PAINT,
                visible: true,
                opacity: 1,
                blendMode: Wukong.DocumentProto.BlendMode.BLEND_MODE_NORMAL,
                color: { r: 42, g: 114, b: 251 },
            },
        ]
        checkbox.partialNode!.strokes = []
        iconData = {
            parentNodeId: checkboxId,
            data: CHECKBOX_SVG,
        }
    }
    return { checkbox, iconData }
}

export function makeRange(node: HTMLElement, id: string, width: number, value: string) {
    const max = (node as HTMLInputElement).max || 100

    const rangeId = getNextNodeId()
    const range: Wukong.DocumentProto.ISynergyNode = {
        nodeId: rangeId,
        partialNode: {
            type: Wukong.DocumentProto.NodeType.NODE_TYPE_FRAME,
            id: rangeId,
            parentInfo: parentInfo.getParentInfo(id),
            name: 'RANGE',
            width: width,
            height: 8,
            cornerRadius: 999,
            topLeftRadius: 999,
            topRightRadius: 999,
            bottomLeftRadius: 999,
            bottomRightRadius: 999,
            fills: [
                {
                    type: Wukong.DocumentProto.PaintType.PAINT_TYPE_SOLID_PAINT,
                    visible: true,
                    opacity: 1,
                    blendMode: Wukong.DocumentProto.BlendMode.BLEND_MODE_NORMAL,
                    color: { r: 239, g: 239, b: 239 },
                },
            ],
            strokes: [
                {
                    type: Wukong.DocumentProto.PaintType.PAINT_TYPE_SOLID_PAINT,
                    visible: true,
                    opacity: 1,
                    blendMode: Wukong.DocumentProto.BlendMode.BLEND_MODE_NORMAL,
                    color: { r: 178, g: 178, b: 178 },
                },
            ],
            relativeTransform: {
                translateX: 0,
                translateY: 6,
                scaleX: 1,
                scaleY: 1,
            },
            strokeWeight: 1,
        },
    }

    const rangeInnerId = getNextNodeId()
    const percent = +value / +max

    let color = { r: 45, g: 114, b: 215 }
    const accentColor = getComputedStyle(node).accentColor
    if (accentColor.startsWith('rgb')) {
        color = parseRGBA(accentColor)
    }
    const rangeInner: Wukong.DocumentProto.ISynergyNode = {
        nodeId: rangeInnerId,
        partialNode: {
            type: Wukong.DocumentProto.NodeType.NODE_TYPE_FRAME,
            id: rangeInnerId,
            parentInfo: parentInfo.getParentInfo(rangeId),
            name: 'RANGE_INNER',
            width: width * percent,
            height: 8,
            cornerRadius: 999,
            topLeftRadius: 999,
            topRightRadius: 999,
            bottomLeftRadius: 999,
            bottomRightRadius: 999,
            fills: [
                {
                    type: Wukong.DocumentProto.PaintType.PAINT_TYPE_SOLID_PAINT,
                    visible: true,
                    opacity: 1,
                    blendMode: Wukong.DocumentProto.BlendMode.BLEND_MODE_NORMAL,
                    color,
                },
            ],
            strokes: [
                {
                    type: Wukong.DocumentProto.PaintType.PAINT_TYPE_SOLID_PAINT,
                    visible: true,
                    opacity: 1,
                    blendMode: Wukong.DocumentProto.BlendMode.BLEND_MODE_NORMAL,
                    color: { r: 81, g: 116, b: 184 },
                },
            ],
            strokeWeight: 1,
        },
    }

    const processId = getNextNodeId()

    const process: Wukong.DocumentProto.ISynergyNode = {
        nodeId: processId,
        partialNode: {
            type: Wukong.DocumentProto.NodeType.NODE_TYPE_FRAME,
            id: processId,
            parentInfo: parentInfo.getParentInfo(rangeId),
            name: 'PROCESS',
            width: 14,
            height: 14,
            cornerRadius: 999,
            topLeftRadius: 999,
            topRightRadius: 999,
            bottomLeftRadius: 999,
            bottomRightRadius: 999,
            fills: [
                {
                    type: Wukong.DocumentProto.PaintType.PAINT_TYPE_SOLID_PAINT,
                    visible: true,
                    opacity: 1,
                    blendMode: Wukong.DocumentProto.BlendMode.BLEND_MODE_NORMAL,
                    color,
                },
            ],
            relativeTransform: {
                translateX: width * percent - 7,
                translateY: -3,
                scaleX: 1,
                scaleY: 1,
            },
        },
    }
    return {
        range,
        rangeInner,
        process,
    }
}

export function makeRadio(id: string, checked: boolean) {
    const radioId = getNextNodeId()
    const radio: Wukong.DocumentProto.ISynergyNode = {
        nodeId: radioId,
        partialNode: {
            type: Wukong.DocumentProto.NodeType.NODE_TYPE_FRAME,
            id: radioId,
            parentInfo: parentInfo.getParentInfo(id),
            name: 'RADIO',
            width: 16,
            height: 16,
            cornerRadius: 999,
            topLeftRadius: 999,
            topRightRadius: 999,
            bottomLeftRadius: 999,
            bottomRightRadius: 999,
            fills: [
                {
                    type: Wukong.DocumentProto.PaintType.PAINT_TYPE_SOLID_PAINT,
                    visible: true,
                    opacity: 1,
                    blendMode: Wukong.DocumentProto.BlendMode.BLEND_MODE_NORMAL,
                    color: { r: 255, g: 255, b: 255 },
                },
            ],
            strokes: [
                {
                    type: Wukong.DocumentProto.PaintType.PAINT_TYPE_SOLID_PAINT,
                    visible: true,
                    opacity: 1,
                    blendMode: Wukong.DocumentProto.BlendMode.BLEND_MODE_NORMAL,
                    color: checked ? { r: 64, g: 112, b: 247 } : { r: 152, g: 152, b: 152 },
                },
            ],
            relativeTransform: {
                translateX: 0,
                translateY: 0,
                scaleX: 1,
                scaleY: 1,
            },
            strokeWeight: 1,
        },
    }

    let radioInner: Wukong.DocumentProto.ISynergyNode | null = null
    if (checked) {
        const radioInnerId = getNextNodeId()
        radioInner = {
            nodeId: radioInnerId,
            partialNode: {
                type: Wukong.DocumentProto.NodeType.NODE_TYPE_FRAME,
                id: radioInnerId,
                parentInfo: parentInfo.getParentInfo(radioId),
                name: 'RADIO_INNER',
                width: 10,
                height: 10,
                cornerRadius: 999,
                topLeftRadius: 999,
                topRightRadius: 999,
                bottomLeftRadius: 999,
                bottomRightRadius: 999,
                fills: [
                    {
                        type: Wukong.DocumentProto.PaintType.PAINT_TYPE_SOLID_PAINT,
                        visible: true,
                        opacity: 1,
                        blendMode: Wukong.DocumentProto.BlendMode.BLEND_MODE_NORMAL,
                        color: { r: 64, g: 112, b: 247 },
                    },
                ],
                relativeTransform: {
                    translateX: 3,
                    translateY: 3,
                    scaleX: 1,
                    scaleY: 1,
                },
            },
        }
    }
    return {
        radio,
        radioInner,
    }
}

export function makeColor(id: string, value: string) {
    const colorId = getNextNodeId()
    const color: Wukong.DocumentProto.ISynergyNode = {
        nodeId: colorId,
        partialNode: {
            type: Wukong.DocumentProto.NodeType.NODE_TYPE_FRAME,
            id: colorId,
            parentInfo: parentInfo.getParentInfo(id),
            name: 'COLOR',
            width: 26,
            height: 24,
            fills: [
                {
                    type: Wukong.DocumentProto.PaintType.PAINT_TYPE_SOLID_PAINT,
                    visible: true,
                    opacity: 1,
                    blendMode: Wukong.DocumentProto.BlendMode.BLEND_MODE_NORMAL,
                    color: { r: hexToRgb(value).r, g: hexToRgb(value).g, b: hexToRgb(value).b },
                },
            ],
            relativeTransform: {
                translateX: 3,
                translateY: 4,
                scaleX: 1,
                scaleY: 1,
            },
        },
    }
    return color
}

export function makeDate(id: string, rect: DOMRect) {
    const iconId = getNextNodeId()
    const iconWrapper: Wukong.DocumentProto.ISynergyNode = {
        nodeId: iconId,
        partialNode: {
            type: Wukong.DocumentProto.NodeType.NODE_TYPE_FRAME,
            id: iconId,
            parentInfo: parentInfo.getParentInfo(id),
            name: 'ICON_WRAPPER',
            width: 16,
            height: 16,
            relativeTransform: {
                translateX: rect.width - 16 - 11,
                translateY: rect.height / 2 - 8,
                scaleX: 1,
                scaleY: 1,
            },
        },
    }
    return {
        iconWrapper,
        iconData: {
            parentNodeId: iconId,
            data: DATE_SVG,
        },
    }
}
