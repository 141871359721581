import { useState } from 'react'
import { InputV2, WKDialog, WKTextButton, WKToast } from '../../../../../../../ui-lib/src'
import { usePluginService } from '../../../../../main/app-context'
import { fetchPublishPlugin } from '../plugin-request'
import { PluginIconInfo, PluginId } from '../template/type'
import { assertManifest, getLocalPluginData, PluginDevStatus, usePluginDevelopment } from '../use-plugin-development'
import { IconImageUploader } from './icon-image-uploader'
import { translation } from './plugin-dev-republish.translation'
import { usePluginDevPublishValidate, ValidStatus } from './use-plugin-dev-publish-validate'

export function PluginDevelopmentRepublish() {
    const pluginService = usePluginService()
    const { setPluginDevStatus, currentEditorPublishInfoPlugin, openPublishSuccessDialog } = usePluginDevelopment()
    const { validStatus, validate, setValidStatus } = usePluginDevPublishValidate()

    const [edtioredIconInfo, setEditorIconInfo] = useState<PluginIconInfo | undefined>()
    const [edtioredName, setEditorName] = useState<string>(currentEditorPublishInfoPlugin?.publishInfo?.name ?? '')
    const [loading, setLoading] = useState<boolean>(false)

    const iconSrc = edtioredIconInfo
        ? `data:${edtioredIconInfo.format};base64,${edtioredIconInfo.imageData}`
        : currentEditorPublishInfoPlugin?.publishInfo?.iconUrl

    if (!currentEditorPublishInfoPlugin?.publishInfo) {
        return null
    }

    const handleIconChange = async (iconInfo: PluginIconInfo) => {
        setEditorIconInfo(iconInfo)
    }

    const handleNameChange = (name: string) => {
        setEditorName(name)
        setValidStatus((status) => ({
            ...status,
            nameErrorText: undefined,
        }))
    }

    const republishPlugin = async () => {
        if (!validate(edtioredName, iconSrc)) {
            return
        }

        const localPluginData = await getLocalPluginData(currentEditorPublishInfoPlugin.path!)
        if (!localPluginData) {
            WKToast.error(translation('ReadPluginFailed'), {
                secondButton: {
                    type: 'button',
                    text: translation('ShowConsole'),
                    onClick: () => {
                        window.localBridge?.toggleDevTools?.(true)
                    },
                },
            })
            return
        }

        const { manifest, codeContent } = localPluginData
        const { error } = assertManifest(manifest)
        if (error) {
            WKToast.error(translation('ManiFestError'), {
                duration: -1,
                secondButton: {
                    type: 'button',
                    text: translation('ViewDetails'),
                    onClick: () => {
                        setPluginDevStatus(PluginDevStatus.Main)
                    },
                },
            })
            return
        }

        if (manifest.id !== currentEditorPublishInfoPlugin.id) {
            setValidStatus((status) => ({
                ...status,
                idError: true,
            }))
            return
        }

        const publishOptions = {
            updateRequest: {
                name: edtioredName,
                iconData: edtioredIconInfo?.imageData!,
                format: edtioredIconInfo?.format!,
            },
            manifestContent: JSON.stringify(manifest),
            codeContent,
        }

        setLoading(true)
        try {
            const newPublishedPlugin = await fetchPublishPlugin(currentEditorPublishInfoPlugin.id, publishOptions)

            pluginService.fetchEditablePublishedPlugins()
            pluginService.fetchPublishedPlugins()

            openPublishSuccessDialog(newPublishedPlugin)
        } catch (e) {
            WKToast.error(translation('PublishNewVersionFailed'))
        } finally {
            setLoading(false)
        }
    }

    return (
        <WKDialog
            title={translation('PublishNewVersion')}
            visible={true}
            showTitle={true}
            width={400}
            onCancel={() => {
                setPluginDevStatus(PluginDevStatus.Main)
            }}
            onCloseIconCancel={() => {
                setPluginDevStatus(PluginDevStatus.Main)
            }}
            onOk={republishPlugin}
            okText={translation('Publish')}
            bodyStyle={{ padding: 0 }}
            okButtonProps={{ loading }}
        >
            <div className="p-24px grid auto-rows-min gap-row-24px gap-col-16px grid-cols-[auto_1fr] wk-text-12 wk-font-regular color-$wk-l2-label-color-gray-13">
                <div className="mt-10px">{translation('Icon')}</div>
                <div>
                    <IconImageUploader
                        iconSrc={iconSrc}
                        onChange={handleIconChange}
                        error={{
                            show: !!validStatus.iconErrorText,
                            tipMessage: validStatus.iconErrorText,
                        }}
                        dataTestIds={{
                            clickButton: 'plugin-dev-icon-uploader',
                        }}
                    />
                </div>
                <div className="mt-6px">{translation('Name')}</div>
                <div>
                    <InputV2
                        dataTestIds={{
                            input: 'plugin-dev-description-input',
                        }}
                        value={edtioredName}
                        onChange={(e) => {
                            handleNameChange(e.target.value)
                        }}
                        error={{
                            show: !!validStatus.nameErrorText,
                            tipMessage: validStatus.nameErrorText,
                        }}
                    />
                </div>
                <div>ID</div>
                <div>
                    <RepublishPluginIdInput
                        pendingPluginId={currentEditorPublishInfoPlugin.id}
                        setValidStatus={setValidStatus}
                        error={validStatus.idError}
                    />
                </div>
            </div>
        </WKDialog>
    )
}

const RepublishPluginIdInput = ({
    pendingPluginId,
    setValidStatus,
    error,
}: {
    pendingPluginId: PluginId
    setValidStatus: React.Dispatch<React.SetStateAction<ValidStatus>>
    error?: boolean
}) => {
    const [hasCopied, setHasCopied] = useState<boolean>(false)

    const clickCopyPluginId = () => {
        navigator.clipboard.writeText(pendingPluginId)
        setValidStatus((status) => ({
            ...status,
            idError: false,
        }))
        setHasCopied(true)
        WKToast.show(translation('CopyDone'))
    }

    if (!error && !hasCopied) {
        return <span>{pendingPluginId}</span>
    }

    return (
        <div className="w-full flex flex-row items-start justify-start">
            <div className="flex-1 mr-4">
                <div>{pendingPluginId}</div>
                {error && <div className="color-$wk-red-8">{translation('ManifestIdInvalidPleaseCopy')}</div>}
            </div>

            <WKTextButton type="primary" size={12} onClick={clickCopyPluginId}>
                {translation('CopyId')}
            </WKTextButton>
        </div>
    )
}
