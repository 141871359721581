import { translation } from './preference.translation'
/* eslint-disable no-restricted-imports */
import { ToggleInverseZoomDirectionCommand, ToggleSnapToPixelWasmCall, Wukong } from '@wukong/bridge-proto'
import { useCallback } from 'react'
import { WKItemType, WKMenuConfig, WKMenuItem } from '../../../../../../../ui-lib/src'
import { CommandInvoker } from '../../../../../document/command/command-invoker'
import { cmdChangePopupState } from '../../../../../document/command/document-command'
import { ShortcutKey, shortcutLabelMap } from '../../../../../kernel/interface/shortcut-key'
import { isWindows } from '../../../../../kernel/util/ua'
import { useAppContext } from '../../../../../main/app-context'
import { useUserConfigStateV2 } from '../../../../../main/user-config/user-config-hook'
import { useViewState } from '../../../../../view-state-bridge'
import { LocalStorageKey } from '../../../../../web-storage/local-storage/config'
import { enhancedLocalStorage } from '../../../../../web-storage/local-storage/storage'
import { DevModeCodeTypeLabelsV2, useChangeDevModeInspectCodeType } from '../../../dev-mode/inspect/common/code-data'
import { useMenuContextState } from '../context/menu-context'
import CodeType = Wukong.DocumentProto.DevModeInspectCodeType
import { featureSwitchManager } from '../../../../../kernel/switch'
import { useSwitchTheme } from '../../../../../../../util/src/hooks/use-switch-theme'

export const useDesignModeMenuPreference = (command: CommandInvoker): WKMenuConfig => {
    const globalState = useViewState('globalState')
    const snapToPixel = !!globalState?.snapToPixel
    const [inverseZoomDirection2, setInverseZoomDirection2] = useUserConfigStateV2('inverseZoomDirection')
    const { setIsOpenFineTuning, setIsOpenPreferenceColorProfileDialog } = useMenuContextState()
    const [ctrlClickTriggerContextMenu, setCtrlClickTriggerContextMenu] =
        useUserConfigStateV2('ctrlClickTriggerContextMenu')

    const appContext = useAppContext()
    const darkMode = featureSwitchManager.isEnabled('dark-mode')
    const { toggleTheme } = useSwitchTheme()

    const children: WKMenuConfig = [
        {
            name: translation('SnapToPixel'),
            shortCut: shortcutLabelMap[ShortcutKey.SnapToPixelGrid],
            activity: snapToPixel,
            disable: false,
            handler: () => command.DEPRECATED_invokeBridge(ToggleSnapToPixelWasmCall),
        },
        {
            name: translation('InvertZoomDirection'),
            shortCut: '',
            activity: inverseZoomDirection2,
            disable: false,
            handler: () => {
                command.DEPRECATED_invokeBridge(ToggleInverseZoomDirectionCommand)
                setInverseZoomDirection2((state) => !state)
            },
        },
        {
            name: `Ctrl+${translation('ClickOpensRight')}`,
            shortCut: '',
            activity: ctrlClickTriggerContextMenu,
            disable: false,
            hidden: isWindows(),
            handler: () => {
                setCtrlClickTriggerContextMenu((state) => !state)
            },
            testId: 'ctrl--click-trigger-context-menu',
        },
        { type: WKItemType.Divider },
        ...(darkMode
            ? [
                  {
                      name: `${translation('Theme')}`,
                      shortCut: '',
                      activity: false,
                      children: [
                          {
                              name: `${translation('Dark')}`,
                              shortCut: '',
                              activity: false,
                              disable: false,
                              handler: toggleTheme,
                              testId: '',
                          },
                          {
                              name: `${translation('Light')}`,
                              shortCut: '',
                              activity: false,
                              disable: false,
                              handler: toggleTheme,
                              testId: '',
                          },
                      ],
                      disable: false,
                      handler: () => {},
                  },
              ]
            : []),
        {
            name: `${translation('ColorProfile')}...`,
            shortCut: '',
            activity: false,
            disable: false,
            handler: () => setIsOpenPreferenceColorProfileDialog(true),
            testId: 'main-menu-preference-color-profile',
        },
        {
            name: `${translation('NudgeAmount')}...`,
            shortCut: '',
            activity: false,
            disable: false,
            handler: () => setIsOpenFineTuning(true),
            testId: 'main-menu-preference-fine-tuning',
        },
    ]

    children.push(
        { type: WKItemType.Divider },
        {
            name: `${translation('AiDuplicateReferenceSelect')}...`,
            shortCut: '',
            activity: false,
            disable: false,
            handler: () => {
                appContext.aiService.setShowAiDuplicateReferencesModal(true)
            },
            testId: 'ai-duplicate-reference-menu',
        }
    )

    if (!window.localBridge) {
        children.splice(3, 0, {
            name: `${translation('OpenLinksInDesktopApp')}`,
            shortCut: '',
            activity: !!enhancedLocalStorage.getSerializedItem(LocalStorageKey.AlwaysOpenInApp),
            disable: false,
            handler: () => {
                enhancedLocalStorage.setSerializedItem(
                    LocalStorageKey.AlwaysOpenInApp,
                    !enhancedLocalStorage.getSerializedItem(LocalStorageKey.AlwaysOpenInApp)
                )
            },
            testId: 'invoke-desktop-preference-menu',
        })
    }

    return [
        {
            name: translation('Preferences'),
            position: 'right top',
            testId: 'main-menu-preference',
            children,
        },
    ]
}

export const useDevModeMenuPreference = (command: CommandInvoker): WKMenuConfig => {
    const [inverseZoomDirection2, setInverseZoomDirection2] = useUserConfigStateV2('inverseZoomDirection')
    const [ctrlClickTriggerContextMenu, setCtrlClickTriggerContextMenu] =
        useUserConfigStateV2('ctrlClickTriggerContextMenu')

    const { changeType } = useChangeDevModeInspectCodeType()

    const devModeInspectCodeTypeViewState = useViewState('devModeInspectCodeTypeViewState')
    const darkMode = featureSwitchManager.isEnabled('dark-mode')
    const { toggleTheme } = useSwitchTheme()

    const newCodeOptions = [
        {
            name: DevModeCodeTypeLabelsV2[CodeType.DEV_MODE_INSPECT_CODE_TYPE_CSS],
            shortCut: '',
            disable: false,
            handler: () => {
                changeType(Wukong.DocumentProto.DevModeInspectCodeType.DEV_MODE_INSPECT_CODE_TYPE_CSS)
            },
            activity:
                devModeInspectCodeTypeViewState?.codeType ==
                Wukong.DocumentProto.DevModeInspectCodeType.DEV_MODE_INSPECT_CODE_TYPE_CSS,
            testId: 'main-menu-preference-code-css',
        },
        {
            name: 'iOS',
            position: 'right top',
            testId: 'main-menu-preference-code-ios',
            children: [
                {
                    name: DevModeCodeTypeLabelsV2[CodeType.iOS_Swift_UI],
                    handler: () => {
                        changeType(Wukong.DocumentProto.DevModeInspectCodeType.iOS_Swift_UI)
                    },
                    activity:
                        devModeInspectCodeTypeViewState?.codeType ==
                        Wukong.DocumentProto.DevModeInspectCodeType.iOS_Swift_UI,
                    testId: 'main-menu-preference-code-ios-swift',
                },
                {
                    name: DevModeCodeTypeLabelsV2[CodeType.iOS_UI_Kit],
                    handler: () => {
                        changeType(Wukong.DocumentProto.DevModeInspectCodeType.iOS_UI_Kit)
                    },
                    activity:
                        devModeInspectCodeTypeViewState?.codeType ==
                        Wukong.DocumentProto.DevModeInspectCodeType.iOS_UI_Kit,
                    testId: 'main-menu-preference-code-ios-ui-kit',
                },
            ],
        },
        {
            name: 'Android',
            position: 'right top',
            testId: 'main-menu-preference-code-android',
            children: [
                {
                    name: DevModeCodeTypeLabelsV2[CodeType.Android_XML],
                    handler: () => {
                        changeType(Wukong.DocumentProto.DevModeInspectCodeType.Android_XML)
                    },
                    activity:
                        devModeInspectCodeTypeViewState?.codeType ==
                        Wukong.DocumentProto.DevModeInspectCodeType.Android_XML,
                    testId: 'main-menu-preference-code-android-xml',
                },
                {
                    name: DevModeCodeTypeLabelsV2[CodeType.Android_Compose],
                    handler: () => {
                        changeType(Wukong.DocumentProto.DevModeInspectCodeType.Android_Compose)
                    },
                    activity:
                        devModeInspectCodeTypeViewState?.codeType ==
                        Wukong.DocumentProto.DevModeInspectCodeType.Android_Compose,
                    testId: 'main-menu-preference-code-ios-android-compose',
                },
            ],
        },
    ]

    const openCodeConfigDialog = useCallback(() => {
        command.invoke(cmdChangePopupState, {
            type: Wukong.DocumentProto.PopupStateType.POPUP_STATE_TYPE_DEV_MODE_CODE_CONFIG_MAIN_MENU,
            reciprocalIndex: -1,
            multiPopup: [],
        })
    }, [command])

    const result = [
        {
            name: translation('Preferences'),
            position: 'right top',
            testId: 'main-menu-preference',
            children: [
                {
                    name: translation('Language'),
                    shortCut: '',
                    disable: false,
                    position: 'right top',
                    handler: () => {},
                    children: newCodeOptions,
                },
                {
                    name: translation('UnitAndScale'),
                    shortCut: '',
                    disable: false,
                    handler: openCodeConfigDialog,
                },
                { type: WKItemType.Divider },
                {
                    name: translation('InvertZoomDirection'),
                    shortCut: '',
                    activity: inverseZoomDirection2,
                    disable: false,
                    handler: () => {
                        command.DEPRECATED_invokeBridge(ToggleInverseZoomDirectionCommand)
                        setInverseZoomDirection2((state) => !state)
                    },
                },
                {
                    name: `Ctrl+${translation('ClickOpensRight')}`,
                    shortCut: '',
                    activity: ctrlClickTriggerContextMenu,
                    disable: false,
                    hidden: isWindows(),
                    handler: () => {
                        setCtrlClickTriggerContextMenu((state) => !state)
                    },
                    testId: 'ctrl--click-trigger-context-menu',
                },
            ],
        },
    ] as WKMenuConfig

    if (!window.localBridge) {
        ;(result[0] as WKMenuItem).children.push({
            name: `${translation('OpenLinksInDesktopApp')}`,
            shortCut: '',
            activity: !!enhancedLocalStorage.getSerializedItem(LocalStorageKey.AlwaysOpenInApp),
            disable: false,
            handler: () => {
                enhancedLocalStorage.setSerializedItem(
                    LocalStorageKey.AlwaysOpenInApp,
                    !enhancedLocalStorage.getSerializedItem(LocalStorageKey.AlwaysOpenInApp)
                )
            },
            testId: 'invoke-desktop-preference-menu',
        })
    }

    if (darkMode) {
        ;(result[0] as WKMenuItem).children.push({
            type: WKItemType.Divider,
        })
        ;(result[0] as WKMenuItem).children.push({
            name: `${translation('Theme')}`,
            shortCut: '',
            activity: false,
            disable: false,
            handler: () => {},
            children: [
                {
                    name: `${translation('Dark')}`,
                    shortCut: '',
                    activity: false,
                    disable: false,
                    handler: toggleTheme,
                    testId: '',
                },
                {
                    name: `${translation('Light')}`,
                    shortCut: '',
                    activity: false,
                    disable: false,
                    handler: toggleTheme,
                    testId: '',
                },
            ],
        })
    }

    return result
}
