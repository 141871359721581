import { Wukong } from '@wukong/bridge-proto'
import { parseGradient } from '../utils/gradient'
import { parseRGBA } from '../utils/utils'

export function getInlineNodeFills(window: Window, node: ChildNode) {
    let currentNode = node
    if (currentNode.nodeType === Node.TEXT_NODE) {
        currentNode = node.parentElement!
    }
    const computedStyle = window.getComputedStyle(currentNode as Element)
    const color = computedStyle.color
    const backgroundImage = computedStyle.backgroundImage
    let fills: Wukong.DocumentProto.IPaint[] = [
        {
            type: Wukong.DocumentProto.PaintType.PAINT_TYPE_SOLID_PAINT,
            visible: true,
            opacity: parseRGBA(color).a,
            blendMode: Wukong.DocumentProto.BlendMode.BLEND_MODE_NORMAL,
            color: {
                r: parseRGBA(color).r,
                g: parseRGBA(color).g,
                b: parseRGBA(color).b,
            },
        },
    ]
    if (computedStyle.backgroundClip === 'text') {
        if (backgroundImage.includes('linear-gradient') || backgroundImage.includes('radial-gradient')) {
            const gradient = parseGradient(backgroundImage, +computedStyle.width.replace('px', ''))
            if (gradient.length) {
                fills = gradient
            }
        }
    }
    return fills
}
