import { Wukong } from '@wukong/bridge-proto'
import { useEffect, useMemo, useState } from 'react'
import { useViewState } from '../../../view-state-bridge'
import { getExceedUsageLimitForFreeTeamShown, getFreezeAlertInEditorShown } from '../top-area/payment-alert-banner'
import {
    getAlertTypeByUsage,
    MemoryUsageAlertType,
    MEMORY_USAGE_THRESHOLD_DANGER,
    MEMORY_USAGE_THRESHOLD_WARNING,
    useMemoryUsageValue,
} from './use-memory-usage-value'
import { useSetTopBannerShown } from '../../../main/layout/layout-context'
import { useTicket } from '../../../external-store/atoms/ticket'

type AlertBannerInfo =
    | {
          alertType: MemoryUsageAlertType.NONE
          display: false
      }
    | {
          alertType: MemoryUsageAlertType.WARNING
          threshold: number
          display: true
          backgroundColor: string
          textColor: string
          closable: true
      }
    | {
          alertType: MemoryUsageAlertType.DANGER
          threshold: number
          display: true
          backgroundColor: string
          textColor: string
          closable: false
      }
    | {
          alertType: MemoryUsageAlertType.FORBIDDEN
          display: false
      }

export function useMemoryUsageAlertBanner(uesdMemory: number):
    | {
          show: false
      }
    | {
          show: true
          textColor: string
          backgroundColor: string
          threshold: number
          closable: boolean
          memoryUsedPercent: number
          closeBanner: () => void
      } {
    const { memoryUsedPercent } = useMemoryUsageValue(uesdMemory)
    const isDevMode = useViewState('editorType') == Wukong.DocumentProto.EditorType.EDITOR_TYPE_DEV
    const ticket = useTicket()

    // 当出现冲突时，不显示内存警告
    const hideIfConflict = useMemo(() => {
        return getFreezeAlertInEditorShown(isDevMode, ticket) || getExceedUsageLimitForFreeTeamShown(isDevMode, ticket)
    }, [isDevMode, ticket])

    const alertType = hideIfConflict ? MemoryUsageAlertType.NONE : getAlertTypeByUsage(memoryUsedPercent)

    const alertInfo: AlertBannerInfo = useMemo(() => {
        switch (alertType) {
            case MemoryUsageAlertType.NONE:
                return {
                    alertType,
                    display: false,
                }
            case MemoryUsageAlertType.WARNING:
                return {
                    alertType,
                    threshold: MEMORY_USAGE_THRESHOLD_WARNING,
                    display: true,
                    backgroundColor: 'var(--wk-yellow-7)',
                    textColor: 'var(--wk-black)',
                    closable: true,
                }
            case MemoryUsageAlertType.DANGER:
                return {
                    alertType,
                    threshold: MEMORY_USAGE_THRESHOLD_DANGER,
                    display: true,
                    backgroundColor: 'var(--wk-red-8)',
                    textColor: 'var(--wk-white)',
                    closable: false,
                }
            case MemoryUsageAlertType.FORBIDDEN:
                return {
                    alertType,
                    display: false,
                }
        }
    }, [alertType])

    const [hasClosedWarningAlertManually, closeWarningAlertManully] = useState(false)

    const setShowTopBanner = useSetTopBannerShown()

    const bannerState = useMemo(() => {
        if (!alertInfo.display) {
            return null
        }
        if (alertInfo.alertType === MemoryUsageAlertType.WARNING && hasClosedWarningAlertManually) {
            return null
        }
        return alertInfo
    }, [alertInfo, hasClosedWarningAlertManually])

    useEffect(() => {
        setShowTopBanner(!!bannerState)
    }, [bannerState, setShowTopBanner])

    const closeBanner = () => closeWarningAlertManully(true)

    if (!bannerState) {
        return { show: false }
    }

    return {
        show: true,
        textColor: bannerState.textColor,
        backgroundColor: bannerState.backgroundColor,
        threshold: bannerState.threshold,
        memoryUsedPercent,
        closable: bannerState.closable,
        closeBanner,
    }
}
