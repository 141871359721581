import { UserMessage } from './common'

interface ChatUserMessageProps {
    message: UserMessage
}

export const ChatUserMessage = (props: ChatUserMessageProps) => {
    const { message } = props
    return (
        <div className="flex flex-row items-center justify-end px-16px py-8px">
            <div className="px-12px py-10px bg-$wk-v2-gray-opacity-1 rounded-$wk-radius-default">
                <div>{message.content}</div>
                {message.imageUploadedUrl && (
                    <img
                        src={message.imageUploadedUrl}
                        className="max-w-140px max-h-140px object-contain rounded-$wk-radius-default"
                    />
                )}
            </div>
        </div>
    )
}
