import { Wukong } from '@wukong/bridge-proto'
import { TextData } from '@wukong/fig-parser'
import { getEmojiCodePointsSet } from './emoji'
import { Matrix, Point, Rect } from './math'
import { OrderIndexUtil } from './order-index'
import { XXHash32Writer } from './xxhash32'

export function getVectorDataHash(vectorData: Wukong.DocumentProto.VectorData) {
    const writer = new XXHash32Writer()
    writer.beginObject()

    const vectorNetwork = vectorData.vectorNetwork!
    // vectorNetwork
    writer.beginObject()
    // vertices
    writer.beginArray()
    for (const vertex of vectorNetwork.vertices!) {
        writer.beginObject()
        // x
        writer.addFloat(vertex.x!)
        // y
        writer.addFloat(vertex.y!)
        // cornerRadius
        writer.addFloat(vertex.cornerRadius!)
        // strokeCap
        writer.addSint32(vertex.strokeCap!)
        // strokeJoin
        writer.addSint32(vertex.strokeJoin!)
        // handleMirroring
        writer.addSint32(vertex.handleMirroring!)
        writer.endObject()
    }
    writer.endArray()

    // segments
    writer.beginArray()
    for (const segment of vectorNetwork.segments!) {
        writer.beginObject()
        // start
        writer.addUint32(segment.start!)
        // end
        writer.addUint32(segment.end!)
        // tangentStart
        if (!segment.tangentStart) {
            writer.addNull()
        } else {
            writer.beginObject()
            writer.addFloat(segment.tangentStart!.x!)
            writer.addFloat(segment.tangentStart!.y!)
            writer.endObject()
        }
        // tangentEnd
        if (!segment.tangentEnd) {
            writer.addNull()
        } else {
            writer.beginObject()
            writer.addFloat(segment.tangentEnd!.x!)
            writer.addFloat(segment.tangentEnd!.y!)
            writer.endObject()
        }
        writer.endObject()
    }
    writer.endArray()

    // regions
    writer.beginArray()
    for (const region of vectorNetwork.regions!) {
        writer.beginObject()
        // loops
        writer.beginArray()
        for (const loop of region.loops!) {
            writer.beginObject()
            writer.beginArray()
            for (const segmentIndices of loop.segmentIndices!) {
                writer.addUint32(segmentIndices)
            }
            writer.endArray()
            writer.endObject()
        }
        writer.endArray()
        // windingRule
        writer.addSint32(region.windingRule!)
        // styleId
        writer.addUint32(region.styleId ?? 0)
        writer.endObject()
    }
    writer.endArray()
    writer.endObject()

    // normalizedSize
    writer.beginObject()
    if (vectorData.normalizedSize?.x === undefined) {
        writer.addFloat(0)
    } else {
        writer.addFloat(vectorData.normalizedSize!.x!)
    }
    if (vectorData.normalizedSize?.y === undefined) {
        writer.addFloat(0)
    } else {
        writer.addFloat(vectorData.normalizedSize!.y!)
    }
    writer.endObject()
    writer.endObject()
    return writer.hash()
}

function buildStyleTableFromTextStyle(
    style: Wukong.DocumentProto.ITextStyle,
    styleId: number
): Wukong.DocumentProto.ISynergyNode {
    // 构造 motiff styleOverrideTable
    type Prop2FieldMap = { [K in keyof typeof Wukong.DocumentProto.NodeProps]?: number }

    const prop2FieldMap: Prop2FieldMap = {
        [Wukong.DocumentProto.NodeProps.NP_fills]: 301,
        [Wukong.DocumentProto.NodeProps.NP_fillStyleId]: 302,
        [Wukong.DocumentProto.NodeProps.NP_textStyleId]: 3010,
        [Wukong.DocumentProto.NodeProps.NP_fontSize]: 9002,
        [Wukong.DocumentProto.NodeProps.NP_fontName]: 9003,
        [Wukong.DocumentProto.NodeProps.NP_textDecoration]: 9004,
        [Wukong.DocumentProto.NodeProps.NP_textCase]: 9005,
        [Wukong.DocumentProto.NodeProps.NP_lineHeight]: 9006,
        [Wukong.DocumentProto.NodeProps.NP_letterSpacing]: 9007,
        [Wukong.DocumentProto.NodeProps.NP_fontVariations]: 9008,
        [Wukong.DocumentProto.NodeProps.NP_hyperlink]: 9009,
        [Wukong.DocumentProto.NodeProps.NP_detachOpticalSizeFromFontSize]: 9010,
    }

    const getFieldId = (prop: Wukong.DocumentProto.NodeProps): number => prop2FieldMap[prop]!
    const fields: number[] = []

    const { textStyleEditedProps } = style
    const partialNode: Wukong.DocumentProto.IPartialNode = {
        styleId,
    }

    textStyleEditedProps?.forEach((prop: Wukong.DocumentProto.NodeProps) => {
        fields.push(getFieldId(prop))

        switch (prop) {
            case Wukong.DocumentProto.NodeProps.NP_fontName:
                partialNode.fontName = style.fontName
                break

            case Wukong.DocumentProto.NodeProps.NP_fontSize:
                partialNode.fontSize = style.fontSize
                break

            case Wukong.DocumentProto.NodeProps.NP_fontVariations:
                partialNode.fontVariations = style.fontVariations
                break

            case Wukong.DocumentProto.NodeProps.NP_textDecoration:
                partialNode.textDecoration = style.textDecoration
                break

            case Wukong.DocumentProto.NodeProps.NP_lineHeight:
                partialNode.lineHeight = style.lineHeight
                break

            case Wukong.DocumentProto.NodeProps.NP_letterSpacing:
                partialNode.letterSpacing = style.letterSpacing
                break

            case Wukong.DocumentProto.NodeProps.NP_textCase:
                partialNode.textCase = style.textCase
                break

            case Wukong.DocumentProto.NodeProps.NP_fills:
                partialNode.fills = style.fills
                break

            case Wukong.DocumentProto.NodeProps.NP_fillStyleId:
                partialNode.fillStyleId = style.fillStyleId
                break

            case Wukong.DocumentProto.NodeProps.NP_textStyleId:
                partialNode.textStyleId = style.textStyleId
                break

            case Wukong.DocumentProto.NodeProps.NP_hyperlink:
                partialNode.hyperlink = style.hyperlink
                break
            case Wukong.DocumentProto.NodeProps.NP_detachOpticalSizeFromFontSize:
                partialNode.detachOpticalSizeFromFontSize = style.detachOpticalSizeFromFontSize
                break
            default:
                // 其它属性对 textStyle 无效
                break
        }
    })

    return { fields, partialNode }
}

function createEmojiPaint(emojiCodePoints: number[]): Wukong.DocumentProto.IPaint {
    return {
        type: Wukong.DocumentProto.PaintType.PAINT_TYPE_EMOJI,
        visible: true,
        opacity: 1,
        blendMode: Wukong.DocumentProto.BlendMode.BLEND_MODE_NORMAL,
        emojiCodePoints,
    }
}

export function buildTextDataFromSegments(
    rawStyledTextSegments: Wukong.DocumentProto.IStyledTextSegment[],
    characters: string
): Wukong.DocumentProto.ITextData {
    const ret: Wukong.DocumentProto.ITextData = {
        characters: characters,
        characterStyleIDs: [],
        styleOverrideTable: {},
    }

    let lastEnd = 0
    let lastStyleId = 0
    for (const seg of rawStyledTextSegments) {
        while (lastEnd < seg.start!) {
            ret.characterStyleIDs!.push(0)
            lastEnd++
        }

        const styleId = lastStyleId + 1
        const style = buildStyleTableFromTextStyle(seg, styleId)
        ret.styleOverrideTable![styleId] = style

        while (lastEnd < seg.end!) {
            ret.characterStyleIDs!.push(styleId)
            lastEnd++
        }

        lastEnd = seg.end!
        lastStyleId++
    }

    const emojiCodePointsSet = getEmojiCodePointsSet(characters)
    for (const emojiCodePoints of emojiCodePointsSet) {
        const styleId = lastStyleId + 1

        const style = buildStyleTableFromTextStyle(
            {
                textStyleEditedProps: [Wukong.DocumentProto.NodeProps.NP_fills],
                fills: [createEmojiPaint(emojiCodePoints)],
            },
            styleId
        )
        ret.styleOverrideTable![styleId] = style

        lastStyleId++
    }

    return ret
}

export function fixEmojiCodePointsInTextData(
    textData: Wukong.DocumentProto.ITextData,
    figTextData: TextData
): Wukong.DocumentProto.ITextData {
    const ret = { ...textData }
    // 根据 characters 计算所有 codePoints
    const arrayToString = (arr: number[]): string => {
        return JSON.stringify(arr)
    }

    const codePointsMap = new Map<string, number[]>()

    for (const codePoints of getEmojiCodePointsSet(textData.characters || '')) {
        codePointsMap.set(arrayToString(codePoints), codePoints)
    }

    // 从 glyph 中提取必要的 codePoints
    for (const glyph of figTextData.glyphs ?? []) {
        const codePoints = glyph.emojiCodePoints
        if (codePoints) {
            codePointsMap.set(arrayToString(codePoints), codePoints)
        }
    }

    if (!codePointsMap.size) {
        return ret
    }

    ret.styleOverrideTable = ret.styleOverrideTable ?? {}

    // 计算需要添加的 codePoints （如果 textData 中已有，则删除）
    let maxStyleId = 0
    for (const [styleId, style] of Object.entries(textData.styleOverrideTable ?? {})) {
        maxStyleId = Math.max(maxStyleId, Number(styleId))

        const fills = style.partialNode?.fills ?? []
        if (fills?.[0]?.type == Wukong.DocumentProto.PaintType.PAINT_TYPE_EMOJI) {
            const codePoints = fills[0].emojiCodePoints ?? []
            codePointsMap.delete(arrayToString(codePoints))
        }
    }

    // 添加到 textData 中
    for (const [_, emojiCodePoints] of codePointsMap) {
        const styleId = maxStyleId + 1

        const style = buildStyleTableFromTextStyle(
            {
                textStyleEditedProps: [Wukong.DocumentProto.NodeProps.NP_fills],
                fills: [createEmojiPaint(emojiCodePoints)],
            },
            styleId
        )
        ret.styleOverrideTable![styleId] = style

        maxStyleId++
    }

    return ret
}

export const getBoundsFromTransformWidthHeight = (transform: Matrix, width: number, height: number): Rect => {
    const points: Point[] = [
        { x: 0, y: 0 },
        { x: 0, y: height },
        { x: width, y: 0 },
        { x: width, y: height },
    ]
    const mappedPoints = points.map((p) => transform.transformPoint(p))

    let left = mappedPoints[0].x
    let right = mappedPoints[0].x
    let top = mappedPoints[0].y
    let bottom = mappedPoints[0].y

    for (const point of mappedPoints) {
        left = Math.min(left, point.x)
        right = Math.max(right, point.x)
        top = Math.min(top, point.y)
        bottom = Math.max(bottom, point.y)
    }

    return new Rect(left, top, right, bottom)
}

export const tidyUpNodes = <T>(nodes: T[], getBounds: (node: T) => Rect, startOffset: Point) => {
    const HORIZONTAL_GAP = 100
    const VERTICAL_GAP = 32

    const info = nodes.map((n) => [n, getBounds(n)] as const)

    const widthMap: Map<number, Array<[T, Rect]>> = new Map()
    for (const [node, bounds] of info) {
        const width = Math.round(bounds.width() * 1000) / 1000
        const list = widthMap.get(width) ?? []
        list.push([node, bounds])
        widthMap.set(width, list)
    }

    const retMap: Map<T, Point> = new Map()

    const sortedWidths = Array.from(widthMap.keys()).sort((a, b) => a - b)
    let offsetX = startOffset.x
    let maxY = startOffset.y
    for (const width of sortedWidths) {
        const list = widthMap.get(width)!

        let offsetY = startOffset.y
        for (const [node, bounds] of list) {
            const translatedX = offsetX - bounds.left
            const translatedY = offsetY - bounds.top
            retMap.set(node, {
                x: translatedX,
                y: translatedY,
            })

            offsetY += bounds.height() + VERTICAL_GAP
        }

        offsetX += width + HORIZONTAL_GAP

        maxY = Math.max(offsetY - VERTICAL_GAP, maxY)
    }

    const maxX = offsetX - HORIZONTAL_GAP

    return {
        translatedMap: retMap,
        width: maxX - startOffset.x,
        height: maxY - startOffset.y,
    }
}

export function getFirstPageBackroundColor(nodes: Wukong.DocumentProto.ISynergyNode[]) {
    const buildBackgroundColor = (color: Wukong.DocumentProto.IRGB, opacity: number | undefined | null) => {
        const toFixed = (x: number) => Math.round(x * 100) / 100
        return JSON.stringify({
            r: toFixed((color.r ?? 0) / 255),
            g: toFixed((color.g ?? 0) / 255),
            b: toFixed((color.b ?? 0) / 255),
            a: toFixed(opacity ?? 0),
            width: toFixed(0),
            height: toFixed(0),
        })
    }
    let backgroundColor = buildBackgroundColor({ r: 0xf5, g: 0xf5, b: 0xf5 }, 1)
    {
        const pages = nodes.filter(
            (node) =>
                node.partialNode?.type === Wukong.DocumentProto.NodeType.NODE_TYPE_PAGE &&
                node.partialNode.parentInfo?.orderIndex &&
                node.partialNode.backgrounds?.length &&
                node.partialNode.backgrounds[0].type === Wukong.DocumentProto.PaintType.PAINT_TYPE_SOLID_PAINT &&
                node.partialNode.backgrounds[0].color
        )
        pages.sort((a, b) => {
            const lhs = a.partialNode?.parentInfo?.orderIndex!
            const rhs = b.partialNode?.parentInfo?.orderIndex!
            return OrderIndexUtil.compare(lhs, rhs)
        })
        if (pages.length > 0) {
            const color = pages[0].partialNode?.backgrounds![0]!
            backgroundColor = buildBackgroundColor(color.color!, color.opacity)
        }
    }
    return backgroundColor
}

export function getRect(rect: { x: number; y: number; w: number; h: number }): Wukong.DocumentProto.ISkRect {
    return { left: rect.x, top: rect.y, right: rect.x + rect.w, bottom: rect.y + rect.h }
}

export function getMaxSessionIdFromDocument(doc: Wukong.DocumentProto.ISynergyDocument) {
    let maxSessionId = 0

    const parseSessionId = (id: string) => {
        const list = id.split(':')
        if (list.length === 1) {
            const v = parseInt(list[0])

            if (!Number.isNaN(v) && Number.isFinite(v)) {
                return v
            }
        }
        return 0
    }

    for (const node of doc.nodes ?? []) {
        if (node.nodeId) {
            maxSessionId = Math.max(maxSessionId, parseSessionId(node.nodeId))
        }
    }

    return maxSessionId
}
