import { useCallback, useState } from 'react'
import { MonoIconCommonaddPicture16, MonoIconPanelArrowUp16, Tooltip, WKIconButton } from '../../../../../../ui-lib/src'
import { ConfigDesignSystemAndPlatform } from '../../ai/ai-gen-ui/ai-gen-ui-config-design-system'
import { PlatformAndDesignSystemProvider } from '../../ai/ai-gen-ui/use-platform-and-design-system-context'
import { UploadedImageAndSelectedNode } from './chat-input-image-and-node'
import style from './chat-input.module.less'
import { useChatbotAIInputInContext } from './use-chat-input'

function UploadImageButton({ onClick, disabled }: { onClick: () => void; disabled: boolean }) {
    return (
        <Tooltip title="upload image">
            <WKIconButton
                data-testid="upload image"
                type="secondary"
                className="w-6 h-6 p-1 shrink-0 mr-1"
                icon={
                    <MonoIconCommonaddPicture16
                        className={disabled ? '!color-[var(--wk-v2-gray-6)]' : 'color-[var(--wk-v2-gray-11)]'}
                    />
                }
                onClick={onClick}
            />
        </Tooltip>
    )
}

export interface InputStates {
    prompt: string
    imageUploadedUrl: string | null
    imageUploadedStatus: string | null
    selection_htmls: {
        html: string
        selectionIds: string[]
    }[]
    nodes: string[] | null
    styleConfigId: number | null
}

export function ChatbotInput({
    triggerGen,
}: {
    triggerGen: (states: InputStates, e: React.MouseEvent<HTMLButtonElement>) => Promise<void>
}) {
    const [isFocus, setIsFocus] = useState(false)
    const {
        inputPrompt,
        updatePrompt,
        triggerPasteImage,
        triggerDragImage,
        triggerUploadImage,
        disableUploadImage,
        disableGen,
        uploadedImageUrl,
        uploadedStatus,
        getSelectionHTMLs,
        selectedNodes,
        styleConfig, // styleConfigId
        setStyleConfig,
    } = useChatbotAIInputInContext()

    const triggerGenWrapper = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            triggerGen(
                {
                    prompt: inputPrompt,
                    imageUploadedUrl: uploadedImageUrl,
                    imageUploadedStatus: uploadedStatus,
                    selection_htmls: getSelectionHTMLs().selectionHTMLs,
                    nodes: selectedNodes.map((node) => node.node),
                    styleConfigId: styleConfig,
                },
                e
            )
        },
        [triggerGen, inputPrompt, uploadedImageUrl, uploadedStatus, getSelectionHTMLs, selectedNodes, styleConfig]
    )

    // TODO(chaibowen): drag need hint
    return (
        <div
            className={`flex flex-col p-3 rounded-0.75 ${isFocus ? style.Focus : style.ChatInput}`}
            onPasteCapture={triggerPasteImage}
            onDragOver={triggerDragImage}
        >
            <UploadedImageAndSelectedNode />
            <textarea
                autoFocus={true}
                onFocus={() => setIsFocus(true)}
                onBlur={() => setIsFocus(false)}
                className="h-17 max-h-30 w-full outline-none border-none resize-none"
                placeholder="Describe what you want to generate"
                onChange={updatePrompt}
                value={inputPrompt}
            ></textarea>
            <div className="flex flex-row justify-start items-center">
                <UploadImageButton onClick={triggerUploadImage} disabled={disableUploadImage} />
                <PlatformAndDesignSystemProvider onSelectRemix={(remix) => setStyleConfig(remix.id)}>
                    <ConfigDesignSystemAndPlatform placement="top left" />
                </PlatformAndDesignSystemProvider>
                <div className="flex-1"></div>
                <WKIconButton
                    type="primary"
                    disabled={disableGen}
                    onClick={triggerGenWrapper}
                    className={`w-7 h-7 p-1.5 shrink-0 rounded-full ${
                        disableGen
                            ? '!bg-[var(--wk-v2-brand-3)]'
                            : 'bg-[var(--wk-v2-brand-7)] hover:bg-[var(--wk-v2-brand-7)]'
                    }`}
                    icon={<MonoIconPanelArrowUp16 className="text-white" />}
                />
            </div>
        </div>
    )
}
