export const FeatureSwitchKeys = [
	'abroad-plugin',
	'ai-align',
	'ai-gen-change-base-frame-name',
	'ai-gen-custom-config',
	'ai-gen-onboarding-design-system',
	'ai-gen-prototype',
	'ai-gen-ui-batch-op',
	'ai-gen-ui-max-limit-prompt',
	'ai-recognize-debug',
	'ai-search',
	'ai2',
	'allow-debug-log',
	'allow-trace-log',
	'asset-migration-mode',
	'async-request-schema-version',
	'auto-redirect-to-beta-help-center',
	'beta-force-upgrade',
	'bg-white-help-icon',
	'change-payment-method',
	'chatbot',
	'chatbot-debug',
	'chatbot-v02',
	'color-variable-apply-type',
	'comment-perf-debug-log',
	'comment-perf-viewport-filter',
	'comment-position-transform',
	'compute-and-check-document-hash',
	'cursor-log',
	'dark-mode',
	'desktop-update',
	'dev-mode-availability-verification-test-env',
	'dev-mode-track-cursor-intercepter',
	'dev-mode-track-hittest',
	'dev-mode-track-mouse-event',
	'dev-mode-track-mouse-event-processor',
	'dev-prototype',
	'dev-tool',
	'dev-webgpu',
	'do-not-set-canvas-size-on-after-editor-setup',
	'do-not-update-editor-type-in-storage-on-bootstrap',
	'export-ano',
	'feat-better-visibility-enterprise-switcher',
	'feat-chatbot-focus-v2',
	'feat-owner-page-old-value-for-render-system',
	'feat-plugin-api-export-async-useabsolutebounds',
	'feat-report-to-unsplash',
	'float-variable',
	'focus-view',
	'focus-view-debug-log',
	'font-size-select',
	'handle-wheel-debugger',
	'import-html',
	'js-image-post-await',
	'library-publish',
	'library-reduce-calc-further-optimize',
	'motiff-debugger',
	'motiff-scope-pretty-props',
	'native-image-drop',
	'new-doc-share-dialog',
	'no-selection-with-copy-as-png',
	'not-recal-layout-when-apply-remote',
	'open-mouse-scale-speed',
	'organization-plus',
	'paste-from-figma',
	'paste-proto-to-ui',
	'payment-bank',
	'payment-tax',
	'perf-edit-vector',
	'perf-stroke-geometry',
	'perf-stroke-join',
	'plugin-api-font-load-check',
	'plugin-perf',
	'present-comment',
	'prototype-debug-log',
	'prototype-overlay-position',
	'prototype-scale-size',
	'prototype-smart-animation',
	'prototype-spring',
	'prototype-switch-device-frame',
	'prototype-tlframe-offset',
	'refactor-create-copy-file',
	'refactor-new-guide',
	'refine-mode-in-variable',
	'render-webgpu',
	'sandbox',
	'show-performance-score',
	'storage-event-as-channel',
	'switch-language',
	'test-socket-connectivity',
	'vector-edit-gradient',
	'webgpu-blacklist',
	'wk-43991',
	'wk-44571',
	'wk-admin',
	'zoom-at-mouse'
] as const